import React from "react"

import { PersonManagement_user$data } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"

import { Group } from "~/helpers/group-helpers"

import PeoplePlaceholderGroup from "./PeopleGroup/PeoplePlaceholderGroup"

type Person = PersonManagement_user$data["account"]["people"][0]
type ProjectList = PersonManagement_user$data["account"]["projects"]

type PersonGroupListProps = {
  personCount: number
  favouritePersonIds: number[]
  personGroupList: Group<Person>[]
  allProjects: ProjectList
  defaultFullTimeMinutes: number
}

const PersonGroupList = (props: PersonGroupListProps) => {
  const {
    personCount,
    personGroupList,
    favouritePersonIds,
    allProjects,
    defaultFullTimeMinutes,
  } = props

  const onlyOneResult = personCount === 1

  return (
    <>
      {personGroupList.map((personGroup: Group<Person>) => {
        if (personGroup.items.length === 0) {
          return null
        }

        if (onlyOneResult && personGroup.groupName === "Favorites") {
          // Hide favourites if there is only one result to avoid double up
          return null
        }

        return (
          <PeoplePlaceholderGroup
            key={personGroup.groupName}
            groupName={personGroup.groupName}
            favouritePersonIds={favouritePersonIds}
            visiblePeople={personGroup.items}
            onlyOneResult={onlyOneResult}
            projects={allProjects}
            companyDefaultMinutes={defaultFullTimeMinutes}
          />
        )
      })}
    </>
  )
}

export default PersonGroupList
