import orderBy from "lodash-es/orderBy"
import React, { useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./PersonSidePanelNotes.module.css"

import { PersonSidePanelNotes_person$key } from "./__generated__/PersonSidePanelNotes_person.graphql"

import { track } from "~/helpers/analytics"

import Button from "~/common/buttons/Button"
import * as icons from "~/common/react-icons"

import { personNoteCreateRelay } from "~/mutations/PersonNote"

import { usePermissions } from "~/Permissions/usePermissions"
import PersonNote from "~/PersonDashboard/Body/PersonNotes/PersonNote"
import { useEffectSkipFirstRender } from "~/hooks/useEffectSkipFirstRender"

type Props = {
  person: PersonSidePanelNotes_person$key
}

const PersonNotesBox = ({ personId }: { personId: number }) => {
  const [note, setNote] = useState("")

  const createNote = async () => {
    const trimmedNote = note.trim()

    if (!trimmedNote) {
      return
    }

    await personNoteCreateRelay({
      note: trimmedNote,
      personId: personId,
    })
    track("Person Note Created")
    setNote("")
  }

  const handleShortcut = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      await createNote()
    }
  }

  return (
    <div className={styles.commentBox}>
      <textarea
        className={styles.commentTextArea}
        placeholder="Add notes, important links, etc."
        data-test="comment-input"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        onSubmit={createNote}
        onKeyDown={handleShortcut}
      />
      <Button
        className={styles.sendBtn}
        onClick={createNote}
        outlined={false}
        icon={<icons.Send />}
      />
    </div>
  )
}

const PersonSidePanelNotes = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonSidePanelNotes_person on people {
        id
        email
        is_placeholder
        people_notes {
          id
          created_at
          user_id
          ...PersonNote_note
        }
      }
    `,
    props.person,
  )

  const sortedNotes = orderBy(
    person.people_notes,
    (note) => note.created_at,
    "asc",
  )

  const containerRef = useRef<HTMLDivElement>(null)

  useEffectSkipFirstRender(() => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [person.people_notes?.length])

  const { can, subject } = usePermissions()
  const canCreateNote = can(
    "create",
    subject("PersonNote", {
      // new note, type requires a user
      user: { id: null },
      person: person,
    }),
  )

  return (
    <section>
      <div className={styles.container} ref={containerRef}>
        {sortedNotes.map((note) => (
          <PersonNote
            key={note.id}
            note={note}
            readonly
            style={{
              container: {
                borderBottom: "none",
                paddingBottom: "24px",
                paddingTop: 0,
              },
              createdBy: {
                marginTop: "4px",
                fontSize: "12px",
              },
              note: {
                margin: 0,
                wordBreak: "break-word",
              },
            }}
            timeShown
          />
        ))}
      </div>
      {canCreateNote && <PersonNotesBox personId={person.id} />}
    </section>
  )
}

export default PersonSidePanelNotes
