import React from "react"

import TitledHelpTooltip from "~/common/TitledHelpTooltip"

type Props = {
  personName: string
  currentHolidayName: string
  newHolidayName: string
  className?: string
}
const HolidayRemovalWarning = (props: Props) => {
  const { personName, currentHolidayName, newHolidayName, className } = props

  if (!currentHolidayName || currentHolidayName === newHolidayName) {
    return null
  }

  return (
    <div className={className}>
      <TitledHelpTooltip
        title={`${personName} will be removed from ${currentHolidayName}`}
        tooltipContent={
          <>
            {newHolidayName && (
              <p>
                {personName} will be added to {newHolidayName}
              </p>
            )}
            <span>
              Any future {currentHolidayName} holidays will be removed.
            </span>
          </>
        }
      />
    </div>
  )
}

export default HolidayRemovalWarning
