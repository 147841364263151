import { dateHelpers } from "@runn/calculations"
import {
  addBusinessDays,
  addDays,
  differenceInBusinessDays,
  isWeekend,
  isWithinInterval,
} from "date-fns"

import {
  TimeOffWithMinutesPerDay,
  getClosestNextWorkingDay,
  getMergedHolidays,
} from "./holiday-helpers"

type isANonWorkingDayOptions = {
  nonWorkingDays: Date[]
  date: Date
}
/**
 * checks if the given date is a nonworking day
 */
const isANonWorkingDay = (options: isANonWorkingDayOptions): boolean => {
  const newSet = new Set(
    options.nonWorkingDays.map((date) => date.toISOString()),
  )

  return newSet.has(options.date.toISOString())
}

type DifferenceInWorkingDaysOptions = {
  dateLeft: Date
  dateRight: Date
  nonWorkingDays: Date[] //  An array of nonworking days such as holidays
}
/**
 * Get the number of working days between the two given days
 * Working days are days that are not a weekend and not in the nonWorkingDays list
 */
export const getDifferenceInWorkingDays = (
  options: DifferenceInWorkingDaysOptions,
): number => {
  const { dateLeft, dateRight, nonWorkingDays } = options

  const totalDays = Math.abs(differenceInBusinessDays(dateLeft, dateRight))

  const totalExcludedDays = nonWorkingDays.filter(
    (date) =>
      // only count dates that are not within the interval
      isWithinInterval(date, { start: dateLeft, end: dateRight }) &&
      // exclude dates that are on the weekend as differenceInBusinessDays handles this
      !isWeekend(date),
  ).length

  return totalDays - totalExcludedDays
}

type AddWorkingDaysOptions = {
  date: Date
  amount: number //The number of working days to add to the given date
  nonWorkingDays: Date[] //An array of nonworking days such as holidays
}

/**
 * Adds the specified number of working days (mon-fri) or nonWorking days
 */
export const addWorkingDays = (options: AddWorkingDaysOptions): Date => {
  const { date, amount, nonWorkingDays } = options
  const sign = options.amount < 0 ? -1 : 1

  let restDays = Math.abs(amount)
  let newDate = date
  while (restDays > 0) {
    newDate = addDays(newDate, sign)

    if (
      isWeekend(newDate) ||
      isANonWorkingDay({
        nonWorkingDays: nonWorkingDays,
        date: newDate,
      })
    ) {
      continue
    }

    restDays -= 1
  }

  return newDate
}

type ShiftIntervalByOptions = {
  interval: {
    start: Date
    end: Date
  }
  amount: number // The number of days to move the interval by
  timeOffs?: Array<TimeOffWithMinutesPerDay>
}
/**
 * Shift an interval by a number of day while maintaining the number of working days
 */
export const shiftIntervalByAmount = (
  options: ShiftIntervalByOptions,
): { start: Date; end: Date } => {
  const { interval, amount, timeOffs = [] } = options
  if (!amount) {
    return interval
  }

  const { start, end } = interval

  const holidayTimeOffs = getMergedHolidays(timeOffs) || []
  const nonWorkingDays = holidayTimeOffs?.map((h) =>
    dateHelpers.parseRunnDate(h.start_date),
  )

  const totalWorkingDays = getDifferenceInWorkingDays({
    dateLeft: start,
    dateRight: end,
    nonWorkingDays,
  })

  const actualStart = addBusinessDays(start, amount)
  const adjustedStart = getClosestNextWorkingDay(
    holidayTimeOffs,
    actualStart,
  ).date

  const adjustedEnd = addWorkingDays({
    date: adjustedStart,
    amount: totalWorkingDays,
    nonWorkingDays,
  })

  return { start: adjustedStart, end: adjustedEnd }
}
