/**
 * @generated SignedSource<<97fe95022d1d9d01ae3e0d0e0cc4e115>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExtPersonLinkBulkCreateInput = {
  clear_existing_links_on_ext_person?: boolean | null | undefined;
  ext_person_links: ReadonlyArray<ExtPersonLinkBulkCreateInputExtPersonLink>;
};
export type ExtPersonLinkBulkCreateInputExtPersonLink = {
  ext_person_id: number;
  person_id: number;
};
export type ExtPersonLinkCreateMutation$variables = {
  input: ExtPersonLinkBulkCreateInput;
};
export type ExtPersonLinkCreateMutation$data = {
  readonly action_ext_person_link_bulk_create: ReadonlyArray<{
    readonly ext_person: {
      readonly ext_person_links: ReadonlyArray<{
        readonly active: boolean;
        readonly ext_person_id: number;
        readonly person_id: number;
      }>;
      readonly id: number;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ExtPersonLinkCreateMutation = {
  response: ExtPersonLinkCreateMutation$data;
  variables: ExtPersonLinkCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ExtPersonLinkBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_ext_person_link_bulk_create",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ext_person",
        "kind": "LinkedField",
        "name": "ext_person",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  }
                }
              }
            ],
            "concreteType": "ext_person_link",
            "kind": "LinkedField",
            "name": "ext_person_links",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ext_person_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              }
            ],
            "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtPersonLinkCreateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExtPersonLinkCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d55520a7bac71d255a5c289fd01d661f",
    "id": null,
    "metadata": {},
    "name": "ExtPersonLinkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ExtPersonLinkCreateMutation(\n  $input: ExtPersonLinkBulkCreateInput!\n) {\n  action_ext_person_link_bulk_create(input: $input) {\n    ext_person {\n      id\n      ext_person_links(where: {active: {_eq: true}}) {\n        person_id\n        ext_person_id\n        active\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "832cf3df431495f817b3319436220027";

export default node;
