/**
 * @generated SignedSource<<6497c447cff20edf19575c993eced5b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStatsComponentQuery$variables = {
  projectId: number;
};
export type ProjectStatsComponentQuery$data = {
  readonly current_user: {
    readonly id: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetLine_user">;
  } | null | undefined;
  readonly projects_by_pk: {
    readonly id: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetLine_project" | "ProjectGroupStats_project">;
  } | null | undefined;
};
export type ProjectStatsComponentQuery = {
  response: ProjectStatsComponentQuery$data;
  variables: ProjectStatsComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectStatsComponentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "projects_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectGroupStats_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectBudgetLine_project"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectBudgetLine_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectStatsComponentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "projects_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_template",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pricing_model",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "default_full_time_minutes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "clients",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internal",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "v_summary_total",
            "kind": "LinkedField",
            "name": "summary",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstream_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total_billable_minutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total_nonbillable_minutes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_roles",
            "kind": "LinkedField",
            "name": "project_roles",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimated_minutes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_rates",
            "kind": "LinkedField",
            "name": "project_rates",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "rate",
                "args": null,
                "kind": "ScalarField",
                "name": "rate_private",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "total_budget",
            "args": null,
            "kind": "ScalarField",
            "name": "total_budget_private",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "date": "asc"
                }
              }
            ],
            "concreteType": "other_costs",
            "kind": "LinkedField",
            "name": "other_costs",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "charge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cost",
                "storageKey": null
              }
            ],
            "storageKey": "other_costs(order_by:{\"date\":\"asc\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "339a66ae6efdcddb7e39c4eaeebf2f83",
    "id": null,
    "metadata": {},
    "name": "ProjectStatsComponentQuery",
    "operationKind": "query",
    "text": "query ProjectStatsComponentQuery(\n  $projectId: Int!\n) {\n  projects_by_pk(id: $projectId) {\n    id\n    ...ProjectGroupStats_project\n    ...ProjectBudgetLine_project\n  }\n  current_user {\n    id\n    ...ProjectBudgetLine_user\n  }\n}\n\nfragment ProjectBudgetDays_project on projects {\n  id\n  account {\n    id\n    default_full_time_minutes\n  }\n  summary {\n    role_id\n    total_billable_minutes\n    total_nonbillable_minutes\n  }\n  project_roles {\n    id\n    role_id\n    estimated_minutes\n  }\n}\n\nfragment ProjectBudgetFinancial_project on projects {\n  id\n  is_template\n  pricing_model\n  total_budget: total_budget_private\n  summary {\n    role_id\n    total_billable_minutes\n    total_nonbillable_minutes\n  }\n  project_roles {\n    id\n    role_id\n    estimated_minutes\n  }\n  project_rates {\n    role_id\n    rate: rate_private\n  }\n  other_costs(order_by: {date: asc}) {\n    id\n    charge\n    cost\n  }\n}\n\nfragment ProjectBudgetHours_project on projects {\n  id\n  summary {\n    role_id\n    total_billable_minutes\n    total_nonbillable_minutes\n  }\n  project_roles {\n    id\n    role_id\n    estimated_minutes\n  }\n}\n\nfragment ProjectBudgetLine_project on projects {\n  id\n  pricing_model\n  total_budget: total_budget_private\n  client {\n    id\n    internal\n  }\n  ...ProjectBudgetFinancial_project\n  ...ProjectBudgetHours_project\n  ...ProjectBudgetDays_project\n}\n\nfragment ProjectBudgetLine_user on users {\n  id\n  permissions\n}\n\nfragment ProjectGroupStats_project on projects {\n  id\n  is_template\n  pricing_model\n  account {\n    id\n    default_full_time_minutes\n  }\n  client {\n    id\n    internal\n  }\n  summary {\n    role_id\n    workstream_id\n    total_billable_minutes\n    total_nonbillable_minutes\n  }\n  project_roles {\n    id\n    role_id\n    estimated_minutes\n  }\n  project_rates {\n    id\n    role_id\n    rate: rate_private\n  }\n}\n"
  }
};
})();

(node as any).hash = "de6f505ab722eca1e9037d23b827d4b5";

export default node;
