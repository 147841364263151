/**
 * @generated SignedSource<<70aefb777d63bbccd227d90d17d2db16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomFieldSectionHeaderPerson_account$data = {
  readonly custom_checkbox_types_person: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_date_types_person: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_select_types_person: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_text_types_person: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly " $fragmentType": "CustomFieldSectionHeaderPerson_account";
};
export type CustomFieldSectionHeaderPerson_account$key = {
  readonly " $data"?: CustomFieldSectionHeaderPerson_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomFieldSectionHeaderPerson_account">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PERSON"
      }
    }
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomFieldSectionHeaderPerson_account",
  "selections": [
    {
      "alias": "custom_text_types_person",
      "args": (v0/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_date_types_person",
      "args": (v0/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_select_types_person",
      "args": (v0/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_checkbox_types_person",
      "args": (v0/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "6917b562af73c3d75bbf65a56f8c6f16";

export default node;
