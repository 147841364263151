import cc from "classcat"
import React from "react"

type Props = {
  videoId: string
  className?: string
}

export const Wistia = ({ videoId, className }: Props) => {
  return (
    <>
      <iframe
        className={cc([className])}
        src={`https://fast.wistia.net/embed/iframe/${videoId}?seo=true&videoFoam=false`}
        title="Runn Overview"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
    </>
  )
}
