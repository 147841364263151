import { useFeature } from "flagged"
import React from "react"
import { graphql, useFragment } from "react-relay"

import { PersonCustomEditor_person$key } from "./__generated__/PersonCustomEditor_person.graphql"
import { PersonCustomEditor_viewer$key } from "./__generated__/PersonCustomEditor_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getViewsPeopleGroupedOptions } from "~/Views/helpers"
import {
  CustomValuesMap,
  typesMap,
  valuesMap,
} from "~/helpers/custom-field-helpers"

import { Option } from "~/common/Select"

import CustomEditor from "../CustomEditor"

const viewerFragment = graphql`
  fragment PersonCustomEditor_viewer on users {
    id
    view {
      id
      name
      people_filters
    }
    account {
      id
      use_week_numbers
      custom_text_types_person: custom_text_types(
        where: { model: { _eq: "PERSON" } }
      ) {
        id
        name
        description
        sort_order
      }
      custom_date_types_person: custom_date_types(
        where: { model: { _eq: "PERSON" } }
      ) {
        id
        name
        description
        sort_order
      }
      custom_select_types_person: custom_select_types(
        where: { model: { _eq: "PERSON" } }
      ) {
        id
        name
        single_select
        description
        sort_order
        options: custom_select_options {
          id
          name
        }
      }
      custom_checkbox_types_person: custom_checkbox_types(
        where: { model: { _eq: "PERSON" } }
      ) {
        id
        name
        description
        sort_order
      }
    }
  }
`

const personFragment = graphql`
  fragment PersonCustomEditor_person on people {
    id
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      value
      typeId: custom_checkbox_type_id
    }
  }
`

type Props = {
  person: PersonCustomEditor_person$key
  onUpdate: (values: CustomValuesMap) => void
  itemClassName?: string
}

const PersonCustomEditor = ({ person, onUpdate, itemClassName }: Props) => {
  const { account, view } = useFragment<PersonCustomEditor_viewer$key>(
    viewerFragment,
    // Used globally since it's too much noise to prop drill down the component tree
    useHasuraContext(),
  )
  const personData = useFragment<PersonCustomEditor_person$key>(
    personFragment,
    person,
  )

  const defaultView =
    useFeature("pre_filtered_views") && view ? view : undefined

  const handleGroupedOptions = (
    options: Option[],
    customSelectName: string,
  ) => {
    return getViewsPeopleGroupedOptions(
      defaultView,
      options,
      "person_custom_select",
      customSelectName,
    )
  }

  return (
    <CustomEditor
      typesMap={typesMap({
        custom_text_types: account.custom_text_types_person,
        custom_date_types: account.custom_date_types_person,
        custom_select_types: account.custom_select_types_person,
        custom_checkbox_types: account.custom_checkbox_types_person,
      })}
      valuesMap={valuesMap(personData)}
      onUpdate={onUpdate}
      itemClassName={itemClassName}
      formatSelectOptions={handleGroupedOptions}
      showWeekNumbers={account.use_week_numbers}
    />
  )
}

export default PersonCustomEditor
