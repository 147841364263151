import { Icon, Tooltip } from "@blueprintjs/core"
import { addBusinessDays, differenceInCalendarDays, isWeekend } from "date-fns"
import React, { useEffect, useState } from "react"

import styles from "./PhaseForm.module.css"

import { getDaysInRange } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"

import DatePicker from "~/common/DatePicker/DatePicker"
import Dialog from "~/common/Dialog"
import Input from "~/common/Input"
import PlannerDatePickerDayStats from "~/common/PlannerDatePicker/PlannerDatePickerDayStats"
import { Popover2 } from "~/common/Popover2"
import BlueLink from "~/common/buttons/BlueLink"
import Button from "~/common/buttons/Button"

import { setSetting } from "~/localsettings"

import PhaseColorPicker from "./PhaseColorPicker"

type Props = {
  phaseName: string
  phaseColor?: string
  dates: { start: Date; end: Date }
  renderFullForm?: boolean
  onCancel: () => void
  onDelete?: () => void
  onSubmit: (phaseData?: {
    start: Date
    end: Date
    name: string
    color: string
  }) => void
}

const PhaseForm = (props: Props) => {
  const {
    phaseName,
    renderFullForm,
    onCancel,
    onSubmit,
    onDelete,
    dates,
    phaseColor,
  } = props

  const [selectedColor, setSelectedColor] = useState(phaseColor)
  const [name, setName] = useState(phaseName)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [showFullForm, setShowFullForm] = useState(renderFullForm || false)
  const [totalWorkingDays, setTotalWorkingDays] = useState(1)
  const [selectedDates, setSelectedDates] = useState(dates)

  const submitDisabled =
    !name || (showFullForm && !selectedDates.start && !selectedDates.end)

  useEffect(() => {
    setSelectedDates(dates)
  }, [dates])

  useEffect(() => {
    if (selectedDates) {
      const totalBusinessDays = getDaysInRange({
        start: selectedDates.start,
        end: selectedDates.end,
        includeWeekends: false, // always false as calculating business days only
      })
      setTotalWorkingDays(totalBusinessDays || 1)
    }
  }, [selectedDates])

  const handleSubmit = () => {
    track("Project Phase Created", {
      days_count:
        differenceInCalendarDays(
          selectedDates.end || selectedDates.start,
          selectedDates.start,
        ) + 1,
    })
    onSubmit({
      start: selectedDates.start,
      end: selectedDates.end || selectedDates.start,
      name,
      color: selectedColor,
    })
  }

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker)
  }

  const handleChangeColor = (color) => {
    setSelectedColor(color)
    setSetting("phaseColorLastUsed", color)
  }

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleSelectedDates = (values: Date[]) => {
    // Check if two dates are selected. If only 1, exit.
    if (values.includes(null)) {
      return setSelectedDates({ start: values[0], end: null })
    }
    setSelectedDates({ start: values[0], end: values[1] })
  }

  const closePopover = () => {
    props.onCancel()
    setName(phaseName)
  }
  const toggleForm = () => {
    setShowFullForm(!showFullForm)
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !submitDisabled) {
      handleSubmit()
    }
  }

  const renderNameInput = () => (
    <Input
      name="phase-name"
      placeholder="Enter Phase Name"
      value={name || ""}
      onChange={handleChangeName}
      autoComplete="off"
      defaultSelectAll
      onKeyDown={handleKeyDown}
    />
  )

  const renderSaveButton = () => (
    <Button
      text="Save"
      outlined={false}
      onClick={handleSubmit}
      disabled={submitDisabled}
    />
  )

  const renderPopoverContent = () => (
    <div className={styles.popoverContent}>
      <div className={styles.body}>{renderNameInput()}</div>
      <div
        className={`${styles.colorsContainer} ${
          showColorPicker && styles.show
        }`}
      >
        <PhaseColorPicker
          selectedColor={selectedColor}
          changeColor={handleChangeColor}
        />
      </div>
      <div className={styles.footer}>
        <section>
          <BlueLink
            text={!!onDelete ? "Delete" : "Cancel"}
            onClick={onDelete || onCancel}
          />
        </section>
        <section>
          <Tooltip
            content={`${showColorPicker ? "Hide Colors" : "Show Colors"}`}
            hoverOpenDelay={500}
          >
            <Button
              icon={
                <div
                  className={styles.colorIcon}
                  style={{ backgroundColor: selectedColor }}
                />
              }
              onClick={toggleColorPicker}
              style={{ padding: "0 10px" }}
              data-test={"phase-color-picker"}
            />
          </Tooltip>
          <Tooltip content="Show Full Calendar" hoverOpenDelay={500}>
            <Button
              icon={<Icon icon="calendar" size={12} />}
              onClick={toggleForm}
              style={{ padding: "0 10px" }}
            />
          </Tooltip>
          {renderSaveButton()}
        </section>
      </div>
    </div>
  )

  const onChangeWorkingDays = (value: number) => {
    if (!value) {
      return
    }

    let daysToAdd = Math.min(value, 365) - 1 // minus one so we don't include existing day
    const startDate = selectedDates.start

    if (isWeekend(startDate)) {
      // if start date lands on weekend we need to add another business day
      daysToAdd = daysToAdd + 1
    }

    const newDates = [startDate, addBusinessDays(startDate, daysToAdd)]

    handleSelectedDates(newDates)
  }

  return !showFullForm ? (
    <div className={styles.phasePopover}>
      <Popover2
        content={renderPopoverContent()}
        isOpen
        onClose={closePopover}
        hasBackdrop
      >
        <div style={{ height: "20px" }} />
      </Popover2>
    </div>
  ) : (
    <Dialog isOpen onClose={onCancel}>
      <div className={styles.phaseDialog}>
        <div className={styles.header}>{renderNameInput()}</div>
        <DatePicker
          mode="range"
          modifiers={{ notAllowed: null }}
          onChange={handleSelectedDates}
          value={[selectedDates.start, selectedDates.end]}
        />
        <div className={styles.statsWrapper}>
          <PlannerDatePickerDayStats
            selectedDates={[selectedDates.start, selectedDates.end]}
            totalWorkingDays={totalWorkingDays}
            onChangeWorkingDays={onChangeWorkingDays}
            handleDatePickerChange={handleSelectedDates}
          />
        </div>
        <div className={styles.footer}>
          <section>
            {!!onDelete && <BlueLink text="Delete" onClick={onDelete} />}
            <Button text="Cancel" onClick={onCancel} />
          </section>
          <section>
            <PhaseColorPicker
              selectedColor={selectedColor}
              changeColor={handleChangeColor}
            />
            {renderSaveButton()}
          </section>
        </div>
      </div>
    </Dialog>
  )
}

export default PhaseForm
