import * as fe from "@runn/filter-engine"
import React from "react"

import { getViewConfig } from "./helpers"
import { AllowedPeopleFilterSet } from "~/helpers/filter-engine"

import { usePersonSearchConfig } from "~/common/SuperSearch"
import SuperSearch from "~/common/SuperSearch/SuperSearch"

type Props = {
  filterSet: AllowedPeopleFilterSet
  setPeopleFilterSet: (set: AllowedPeopleFilterSet) => void
}

const singleValueFilterTypes = [
  "person_team_id",
  "person_role_id",
  "person_employment_type",
  "person_type",
  "person_project_status",
]

const ViewFormPeopleFilter = (props: Props) => {
  const { setPeopleFilterSet, filterSet } = props

  const selectedFilterSets = fe.filters.flat(filterSet.filters)

  const selectedSingleFilters = selectedFilterSets.reduce(
    (acc, filter) =>
      singleValueFilterTypes.includes(filter.type)
        ? [...acc, filter.type]
        : acc,
    [],
  )

  const selectedCustomFieldTypeIds = selectedFilterSets.reduce(
    (acc, filter) => {
      if (!filter.type.includes("person_custom_select")) {
        return acc
      }

      const customFieldFilter = filter as fe.filters.FilterPersonCustomSelect
      return [...acc, customFieldFilter.options.typeId]
    },
    [],
  )

  const defaultConfig = usePersonSearchConfig()
  const hiddenFilters = [
    "person_is_favourite",
    "person_project_id",
    "person_id",
    "person_state",
    ...selectedSingleFilters,
  ]

  const config = getViewConfig({
    defaultConfig,
    hiddenFilters,
    hiddenCustomFieldTypeIds: selectedCustomFieldTypeIds,
    filterMenuButtonLabel: "People Filters",
  })
  const changeFilterSet = (set: AllowedPeopleFilterSet) => {
    setPeopleFilterSet(set)
  }

  return (
    <SuperSearch
      location="views"
      disabled={false}
      config={config}
      filterSet={filterSet}
      onChangeFilterSet={changeFilterSet}
      showSavedFilterSets={false}
    />
  )
}

export default ViewFormPeopleFilter
