/**
 * @generated SignedSource<<f5b10a4895a1a6311927451f895acd3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonPlannerMenu_person$data = {
  readonly id: number;
  readonly people_notes_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly project_memberships: ReadonlyArray<{
    readonly has_actuals: boolean | null | undefined;
    readonly id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_person" | "ChangeWorkstreamModal_person" | "PersonSidePanel_person">;
  readonly " $fragmentType": "PersonPlannerMenu_person";
};
export type PersonPlannerMenu_person$key = {
  readonly " $data"?: PersonPlannerMenu_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonPlannerMenu_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonPlannerMenu_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_actuals",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people_notes_aggregate",
      "kind": "LinkedField",
      "name": "people_notes_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "people_notes_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeProjectRoleModal_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeWorkstreamModal_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonSidePanel_person"
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "f06def7400df1ffdc07a18061d2d3856";

export default node;
