import { dateHelpers } from "@runn/calculations"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PersonSidePanel.module.css"

import { PersonSidePanelContract_account$key } from "./__generated__/PersonSidePanelContract_account.graphql"
import { PersonSidePanelContract_person$key } from "./__generated__/PersonSidePanelContract_person.graphql"

import { groupItemsByEndDate } from "~/PersonDashboard/Body/helpers"
import { formatCurrency } from "~/helpers/CurrencyHelper"
import DurationHelper from "~/helpers/DurationHelper"
import { containsDate } from "~/helpers/date-range"

import { usePermissions } from "~/Permissions/usePermissions"
import {
  formatRunnDate,
  toTitleCase,
} from "~/PersonDashboard/Body/PersonContracts/PersonContractTable/format"

type Props = {
  person: PersonSidePanelContract_person$key
  account: PersonSidePanelContract_account$key
}

const Contract = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonSidePanelContract_person on people {
        id
        contracts {
          id
          cost: cost_private
          employment_type
          job_title
          minutes_per_day
          rostered_days
          start_date: start_date_runn
          end_date: end_date_runn
          role {
            name
            default_hour_cost: default_hour_cost_private
          }
        }
      }
    `,
    props.person,
  )

  const account = useFragment(
    graphql`
      fragment PersonSidePanelContract_account on accounts {
        id
        business_days
      }
    `,
    props.account,
  )

  const { can, subject } = usePermissions()
  const allowedToViewSalaries = can("view", subject("Salary"))

  const { upcoming: currentAndUpcomingContracts } = groupItemsByEndDate(
    person.contracts,
  )

  return (
    <>
      {currentAndUpcomingContracts.length > 0 ? (
        currentAndUpcomingContracts.map((contract, index) => {
          const startDate = contract.start_date
            ? formatRunnDate(contract.start_date)
            : "--"
          const endDate = contract.end_date
            ? formatRunnDate(contract.end_date)
            : "--"
          const jobTitle = contract.job_title ? contract.job_title : "--"
          const employmentType = toTitleCase(contract.employment_type)
          const hoursPerDay = DurationHelper.formatDuration(
            contract.minutes_per_day ?? 0,
          )
          const costToBusiness = formatCurrency(
            contract.cost ?? contract.role.default_hour_cost ?? 0,
            { optionalMantissa: false },
          )
          const currentDate = dateHelpers.formatToRunnDate(new Date())
          // Contracts are sorted by end date, so only the first one can be current
          const isCurrentContract =
            index === 0 &&
            contract.start_date &&
            containsDate(
              { start: contract.start_date, end: contract.end_date },
              currentDate,
            )

          return (
            <div key={contract.id} className={styles.contractContainer}>
              <h3 className={styles.contractLabel}>
                {isCurrentContract ? "Current" : "Upcoming"}
              </h3>
              <div>
                <h4>Default Role</h4>
                <p>{contract.role.name}</p>
              </div>
              <div>
                <h4>Job Title</h4>
                <p>{jobTitle}</p>
              </div>
              <div>
                <h4>Start Date</h4>
                <p>{startDate}</p>
              </div>
              <div>
                <h4>End Date</h4>
                <p>{endDate}</p>
              </div>
              <div>
                <h4>Employment Type</h4>
                <p>{employmentType}</p>
              </div>
              <div>
                <h4>Work Days</h4>
                <div className={styles.rosteredDaysWrapper}>
                  {(contract.rostered_days ?? []).map((minutes, daysIndex) => {
                    const defaultIsSelected = minutes > 0
                    const day = account.business_days[daysIndex]
                    const firstLetterOfDay = day ? day[0] : ""

                    return (
                      <div
                        key={daysIndex}
                        className={`${styles.rosteredDay} ${
                          defaultIsSelected ? "" : styles.deselected
                        }`}
                      >
                        {firstLetterOfDay}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <h4>Hours per Day</h4>
                <p>{hoursPerDay}</p>
              </div>
              {allowedToViewSalaries && (
                <div>
                  <h4>Cost to Business</h4>
                  <p>{`${costToBusiness} / hour`}</p>
                </div>
              )}
            </div>
          )
        })
      ) : (
        <div className={styles.empty}>No current or upcoming contracts</div>
      )}
    </>
  )
}

export default Contract
