import cc from "classcat"
import React, { useCallback, useEffect, useState } from "react"
import { UnmountClosed as ReactCollapse } from "react-collapse"
import isDeeplyEqual from "react-fast-compare"

import styles from "./PersonRow.module.css"

import { PersonManagement_user$data } from "./__generated__/PersonManagement_user.graphql"

import { Assignment } from "~/helpers/planner-helpers"

import { isSplitScreenMode } from "~/Mode.reducer"
import SplitRow from "~/Planner/PlannerLayout/SplitRow"
import { useAppSelector } from "~/hooks/redux"
import { useIsInViewport } from "~/hooks/useIsInViewport"
import { getSettings, setSetting } from "~/localsettings"

import PersonDetails from "./PersonDetails/PersonDetails"
import PersonSummaryRow from "./PersonSummaryRow/PersonSummaryRow"
import PlaceholderSummaryRow from "./PlaceholderRow/PlaceholderSummaryRow"

type Person = PersonManagement_user$data["account"]["people"][0]

type Props = {
  personId: number
  person: Person
  favourites: PersonManagement_user$data["favourite_people"]
  onlyOneResult?: boolean
  groupCount: number
  groupName: string
  noPeopleHeadingBar?: boolean
}

const PersonRow = (props: Props) => {
  const {
    personId,
    person,
    favourites,
    onlyOneResult,
    groupCount,
    groupName,
    noPeopleHeadingBar,
  } = props

  const localStorageExpanded = getSettings().plannerPeopleExpanded?.some(
    (p) => p.id === person.id && p.groupName === groupName,
  )
  const [personExpanded, setPersonExpanded] = useState(localStorageExpanded)

  const saveToLocalStorage = (expanded) => {
    const plannerPeopleExpanded = getSettings()?.plannerPeopleExpanded || []

    if (expanded) {
      // add expanded row
      const newPlannerPeopleExpanded = [
        ...plannerPeopleExpanded,
        { id: person.id, groupName },
      ]
      setSetting("plannerPeopleExpanded", newPlannerPeopleExpanded)
    } else {
      // remove expanded row
      const newPlannerPeopleExpanded = plannerPeopleExpanded.filter(
        (row) => row.id !== person.id,
      )
      setSetting("plannerPeopleExpanded", newPlannerPeopleExpanded)
    }
  }

  const handleToggleRow = useCallback(() => {
    setPersonExpanded(!personExpanded)
    saveToLocalStorage(!personExpanded)
  }, [personExpanded]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onlyOneResult) {
      setPersonExpanded(true)
    }
  }, [onlyOneResult])

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const multiSelectItem = useAppSelector(
    (state) => state.multiSelect.items[0],
  ) as Assignment

  const disabledRow =
    splitScreenMode &&
    multiSelectItem &&
    multiSelectItem?.person_id !== person.id

  const ref = React.useRef<HTMLDivElement>(null)
  const isInViewport = useIsInViewport(ref, {
    delay: 0,
    offsetY: 1000,
    rootQuerySelector: "#people-planner-list",
    keepVisible: true,
  })
  const personSku = [person.first_name, person.last_name].join("-")
  return (
    <div
      data-test={`PersonRow-${personSku}`}
      ref={ref}
      className={`${personExpanded ? "" : styles.backgroundBorderBottomOnly}`}
    >
      {isInViewport && (
        <>
          {person.is_placeholder ? (
            <PlaceholderSummaryRow
              personId={personId}
              placeholder={person}
              personExpanded={personExpanded}
              handleToggleRow={handleToggleRow}
              disabled={disabledRow}
            />
          ) : (
            <PersonSummaryRow
              personId={personId}
              person={person}
              personExpanded={personExpanded}
              handleToggleRow={handleToggleRow}
              favourites={favourites}
              groupCount={groupCount}
              disabled={disabledRow}
              noPeopleHeadingBar={noPeopleHeadingBar}
            />
          )}
          <ReactCollapse
            isOpened={personExpanded}
            theme={{
              collapse: cc([
                styles.collapse,
                {
                  [styles.disabled]: disabledRow,
                },
              ]),
            }}
          >
            <PersonDetails person={person} />
            <SplitRow />
          </ReactCollapse>
        </>
      )}
    </div>
  )
}

export default React.memo(PersonRow, isDeeplyEqual)
