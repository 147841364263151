/**
 * @generated SignedSource<<115bf1bde45bc6d8c30d9e7810c0282e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroup_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRow_account">;
  readonly " $fragmentType": "ProjectGroup_account";
};
export type ProjectGroup_account$key = {
  readonly " $data"?: ProjectGroup_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroup_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroup_account",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectRow_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "0860f43452eeb8e473d079b5493ec867";

export default node;
