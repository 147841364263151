/**
 * @generated SignedSource<<60a007d0162a5264da0f9baf65966364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RateTypeProjectUpdateMutation$variables = {
  id: number;
  rateType: string;
};
export type RateTypeProjectUpdateMutation$data = {
  readonly updateProjectRateType: {
    readonly project: {
      readonly id: number;
      readonly rate_type: string;
    } | null | undefined;
  };
};
export type RateTypeProjectUpdateMutation = {
  response: RateTypeProjectUpdateMutation$data;
  variables: RateTypeProjectUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rateType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "rateType",
        "variableName": "rateType"
      }
    ],
    "concreteType": "ProjectResult",
    "kind": "LinkedField",
    "name": "updateProjectRateType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate_type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateTypeProjectUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateTypeProjectUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7dd8740f4e010fc419f17dad0faa8c4a",
    "id": null,
    "metadata": {},
    "name": "RateTypeProjectUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation RateTypeProjectUpdateMutation(\n  $id: Int!\n  $rateType: String!\n) {\n  updateProjectRateType(id: $id, rateType: $rateType) {\n    project {\n      id\n      rate_type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b09a40e0a9d255662fabfca19105168";

export default node;
