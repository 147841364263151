import { dateHelpers } from "@runn/calculations"
import { startOfISOWeek } from "date-fns"
import React, { Suspense } from "react"

import { CreateProjectFromTemplateForm_project$data } from "../CreateProject/__generated__/CreateProjectFromTemplateForm_project.graphql"
import { CreateTemplateFromProjectForm_project$data } from "../ProjectTemplateForm/__generated__/CreateTemplateFromProjectForm_project.graphql"

import { track } from "../../helpers/analytics"
import { reportError } from "~/helpers/error-helpers"

import {
  projectCreateRelay,
  projectDuplicateRelay,
} from "../../mutations/Project"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import ProjectForm, { OnSubmitResponse } from "~/forms/ProjectForm/ProjectForm"

export type ProjectReturn = {
  id: number
  name: string
  team_id: number
  confirmed: boolean
}

type Props = {
  onClose: (event: { projectId: number; extProjectId: number }) => void
  onSuccess?: (project: ProjectReturn) => void
  setIsDatePickerForm?: (arg: boolean) => void
  activateAnimation: () => void
  templateBlueprint?:
    | CreateProjectFromTemplateForm_project$data
    | CreateTemplateFromProjectForm_project$data // Passed if creating a project from a template or a template from a project
  isTemplate?: boolean
}

const CreateProjectForm = (props: Props) => {
  const {
    onSuccess,
    onClose,
    activateAnimation,
    templateBlueprint,
    isTemplate,
    setIsDatePickerForm,
  } = props

  const onSubmit = async ({ input, duplicateInput }: OnSubmitResponse) => {
    window.userflow?.track("Project Create")

    try {
      if (duplicateInput) {
        const { name, ...newProjectInput } = input
        const duplicatedProject = await projectDuplicateRelay({
          variables: {
            input: {
              original_project_id:
                duplicateInput?.templateId || templateBlueprint.id,
              date:
                duplicateInput.startDate ||
                dateHelpers.formatToRunnDate(startOfISOWeek(new Date())),
              name,
              new_project_input: newProjectInput,
            },
            todaysDate: dateHelpers.getTodaysDate(),
            plannerStartDate: PLANNER_INITIAL_DATE,
            includeProjectTemplates: true,
          },
        })

        onSuccess(duplicatedProject)
        // DO WE NEED THIS?
        // onClose()

        return { projectId: duplicatedProject.id }
      } else {
        track("Project Created", {
          confirmed: input.confirmed,
          pricing_model: input.pricing_model,
          tags: input.tags,
          emoji: input.emoji,
        })
        const createdProject = await projectCreateRelay({
          variables: {
            input,
            todaysDate: dateHelpers.getTodaysDate(),
          },
        })
        onSuccess(createdProject)
        // DO WE NEED THIS?
        // onClose()

        return { projectId: createdProject.id }
      }
    } catch (error: any) {
      void reportError(
        `Error ${duplicateInput ? "duplicating" : "creating"} project`,
        error,
      )
    }
  }

  // Stops the screen jumping as data is loaded into the project form
  const LoadingFallback = () => <div style={{ height: "800px" }}></div>

  return (
    <Suspense fallback={<LoadingFallback />}>
      <ProjectForm
        onClose={onClose}
        onSubmit={onSubmit}
        activateAnimation={activateAnimation}
        setIsDatePickerForm={setIsDatePickerForm}
        templateBlueprint={templateBlueprint}
        isTemplate={isTemplate}
      />
    </Suspense>
  )
}

export default CreateProjectForm
