/**
 * @generated SignedSource<<b01e5a90e5916323e44677f44dcd0abf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderCapacityIndicator_projects$data = ReadonlyArray<{
  readonly confirmed: boolean;
  readonly id: number;
  readonly is_template: boolean;
  readonly " $fragmentType": "PlaceholderCapacityIndicator_projects";
}>;
export type PlaceholderCapacityIndicator_projects$key = ReadonlyArray<{
  readonly " $data"?: PlaceholderCapacityIndicator_projects$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderCapacityIndicator_projects">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlaceholderCapacityIndicator_projects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "a847c68289758047c43b9f0d482729ea";

export default node;
