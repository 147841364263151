import { graphql, useFragment } from "react-relay"

import type { useTags_account$key } from "./__generated__/useTags_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useTags_account on accounts {
    tags {
      id
      name
      model
      archived
    }
  }
`

type Options = {
  model: "person" | "project"
  user: SuperSearch_user$data
}

const useTags = (options: Options) => {
  const { model, user } = options

  const { tags } = useFragment<useTags_account$key>(fragment, user.account)

  const availableOptions = tags
    .filter((tag) => tag.model === model && !tag.archived)
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    .map((tag) => ({
      value: tag.name,
      label: tag.name,
    }))

  return availableOptions
}

export default useTags
