/**
 * @generated SignedSource<<a5c078e418cdb888d5d54e2810c1a494>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MilestoneUpdateMutation$variables = {
  date: any;
  icon?: string | null | undefined;
  id: number;
  note?: string | null | undefined;
  project_id: number;
  title?: string | null | undefined;
};
export type MilestoneUpdateMutation$data = {
  readonly updateMilestone: {
    readonly milestone: {
      readonly date: string | null | undefined;
      readonly icon: string | null | undefined;
      readonly id: number;
      readonly note: string | null | undefined;
      readonly project: {
        readonly calc_end_date: string | null | undefined;
        readonly calc_start_date: string | null | undefined;
        readonly id: number;
      };
      readonly title: string | null | undefined;
    } | null | undefined;
  };
};
export type MilestoneUpdateMutation = {
  response: MilestoneUpdateMutation$data;
  variables: MilestoneUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "icon"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "icon",
        "variableName": "icon"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "project_id"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "UpdateMilestoneResult",
    "kind": "LinkedField",
    "name": "updateMilestone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "milestones",
        "kind": "LinkedField",
        "name": "milestone",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": "date",
            "args": null,
            "kind": "ScalarField",
            "name": "date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_start_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_end_date",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MilestoneUpdateMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "MilestoneUpdateMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "e7ffd145156cdb2d182fa627f96c436f",
    "id": null,
    "metadata": {},
    "name": "MilestoneUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MilestoneUpdateMutation(\n  $id: Int!\n  $icon: String\n  $note: String\n  $title: String\n  $date: Date!\n  $project_id: Int!\n) {\n  updateMilestone(icon: $icon, note: $note, title: $title, date: $date, projectId: $project_id, id: $id) {\n    milestone {\n      id\n      title\n      icon\n      note\n      date: date_runn\n      project {\n        id\n        calc_start_date\n        calc_end_date\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da8c9fcee5b32408ddaa9bb0f6675ffa";

export default node;
