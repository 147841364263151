import { Button, InputGroup } from "@blueprintjs/core"
import cc from "classcat"
import React, { ChangeEventHandler, ReactNode } from "react"

import styles from "./FilterMenuSearch.module.css"

type Props = {
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  clear: () => void
  children?: ReactNode
}

const FilterMenuSearch = ({ value, onChange, children, clear }: Props) => {
  return (
    <div className={styles.search}>
      <InputGroup
        type="search"
        leftIcon="search"
        required
        rightElement={
          <Button
            className={cc([
              styles.closeButton,
              {
                [styles.hidden]: value.length === 0,
              },
            ])}
            intent="primary"
            icon="small-cross"
            minimal
            onClick={clear}
          />
        }
        value={value}
        onChange={onChange}
        placeholder="Search"
        fill
        className={styles.input}
        autoFocus
      />
      {children}
    </div>
  )
}

export default FilterMenuSearch
