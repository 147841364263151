import cc from "classcat"
import React, { useEffect, useMemo, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { match } from "ts-pattern"

import styles from "./ProjectClientIcon.module.css"

import { buildImageUrl } from "~/helpers/image"
import { isEmoji } from "~/helpers/text-helpers"

import Emoji from "./Emoji/Emoji"
import {
  ArchiveIcon,
  CompanyDefault,
  ProjectTemplateAvatarBlueIcon,
} from "./react-icons"

const getDomainFromURL = (urlString: string): string => {
  try {
    // make sure that the urlString starts with `https://`
    if (/^\https?:\/\//.test(urlString) === false) {
      urlString = `https://${urlString}`
    }
    return new URL(urlString).hostname
  } catch (error) {
    return urlString
  }
}

type BaseProps = {
  client: {
    image_key: string
    website: string
    name?: string
  }
  border?: boolean
  size?: number
  style?: React.CSSProperties
  className?: string
  isArchived?: boolean
}

type ProjectProps = BaseProps & {
  project: {
    emoji: string | null
    is_template?: boolean
    name?: string
  }
  clientLogoOnly?: never
}

type ClientOnlyProps = BaseProps & {
  clientLogoOnly: boolean
  project?: never
}

type Props = ProjectProps | ClientOnlyProps

const ProjectClientIcon = (props: Props) => {
  const { project, client, border, className, isArchived, size = 30 } = props

  const src: string = useMemo(() => {
    if (client?.image_key != null) {
      return buildImageUrl(client.image_key, {
        width: size,
        height: size,
      })
    }

    if (typeof client?.website === "string" && client?.website) {
      return "https://logo.clearbit.com/" + getDomainFromURL(client.website)
    }

    return undefined
  }, [client?.website, client?.image_key]) // eslint-disable-line react-hooks/exhaustive-deps

  const [useLogo, setUseLogo] = useState(false)
  const style = props.style || {}

  useEffect(() => {
    if (src) {
      const image = new Image()
      image.onload = () => {
        setUseLogo(true)
      }
      image.src = src
    }
  }, [src])

  const sizePx = `${size}px`
  const divStyle = {
    border: border ? "1px solid var(--winter)" : "none",
    ...style,
    width: sizePx,
    height: sizePx,
    minWidth: sizePx,
    minHeight: sizePx,
  }

  const Content = () =>
    match({
      isTemplate: project?.is_template,
      hasEmoji: isEmoji(project?.emoji),
      hasLogo: src && useLogo,
      isArchived: isArchived,
    })
      .with({ isArchived: true }, () => <ArchiveIcon color="var(--snow)" />)
      .with({ isTemplate: true }, () => <ProjectTemplateAvatarBlueIcon />)
      .with({ hasEmoji: true }, () => (
        <Emoji style={{ fontSize: size - size / 5 }} emoji={project.emoji} />
      ))
      .with({ hasLogo: true }, () => (
        <img src={src} alt={project?.emoji || client.name} />
      ))
      .otherwise(() => (
        <div className={styles.defaultClient}>
          <CompanyDefault />
        </div>
      ))

  return (
    <div
      className={cc([
        styles.wrapper,
        className,
        {
          [styles.archivedIcon]: isArchived,
        },
      ])}
      style={divStyle}
      data-test="project-client-icon"
    >
      <Content />
    </div>
  )
}

export default React.memo(ProjectClientIcon, isDeeplyEqual)
export { getDomainFromURL }
