/**
 * @generated SignedSource<<2367e9c404fd46e9b544b5c2d785b241>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectMemberBulkCreateInput = {
  project_members: ReadonlyArray<ProjectMember>;
};
export type ProjectMember = {
  is_placeholder: boolean;
  person_id: number;
  project_id: number;
  role_id: number;
  workstream_id?: number | null | undefined;
};
export type ProjectMemberBulkCreateMutation$variables = {
  input: ProjectMemberBulkCreateInput;
};
export type ProjectMemberBulkCreateMutation$data = {
  readonly action_project_member_bulk_create: ReadonlyArray<{
    readonly id: number;
    readonly person: {
      readonly id: number;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly project: {
      readonly ext_project_links: ReadonlyArray<{
        readonly ext_project: {
          readonly ext_project_members: ReadonlyArray<{
            readonly ext_person: {
              readonly active: boolean;
              readonly email: string | null | undefined;
              readonly ext_person_links: ReadonlyArray<{
                readonly active: boolean;
                readonly ext_person_id: number;
                readonly person_id: number;
              }>;
              readonly first_name: string;
              readonly id: number;
              readonly is_placeholder: boolean;
              readonly last_name: string;
            };
            readonly id: number;
          }>;
          readonly id: number;
          readonly integration: {
            readonly id: number;
            readonly integration_service: {
              readonly id: number;
              readonly name: string;
            };
          };
        };
        readonly ext_project_id: number;
        readonly project_id: number;
      }>;
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly person: {
          readonly first_name: string;
          readonly id: number;
          readonly last_name: string;
        };
        readonly person_id: number;
        readonly project: {
          readonly id: number;
          readonly name: string;
        };
        readonly project_id: number;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly role_id: number;
        readonly workstream: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly project_rates: ReadonlyArray<{
        readonly id: number;
        readonly project_id: number;
        readonly rate: number | null | undefined;
        readonly role_id: number;
      }>;
    } | null | undefined;
    readonly project_member: {
      readonly has_actuals: boolean | null | undefined;
      readonly has_assignments: boolean | null | undefined;
      readonly id: number;
      readonly just_added_timestamp: number | null | undefined;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly workstream_id: number | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ProjectMemberBulkCreateMutation = {
  response: ProjectMemberBulkCreateMutation$data;
  variables: ProjectMemberBulkCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_placeholder",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v9 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "active": {
        "_eq": true
      }
    }
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProjectMemberBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_project_member_bulk_create",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "project_memberships",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_rates",
            "kind": "LinkedField",
            "name": "project_rates",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "rate",
                "args": null,
                "kind": "ScalarField",
                "name": "rate_private",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "roles",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "workstreams",
                "kind": "LinkedField",
                "name": "workstream",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "ext_project_link",
            "kind": "LinkedField",
            "name": "ext_project_links",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ext_project_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ext_project",
                "kind": "LinkedField",
                "name": "ext_project",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "integration",
                    "kind": "LinkedField",
                    "name": "integration",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "integration_service",
                        "kind": "LinkedField",
                        "name": "integration_service",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "deleted": {
                            "_eq": false
                          }
                        }
                      }
                    ],
                    "concreteType": "ext_project_member",
                    "kind": "LinkedField",
                    "name": "ext_project_members",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ext_person",
                        "kind": "LinkedField",
                        "name": "ext_person",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "ext_person_link",
                            "kind": "LinkedField",
                            "name": "ext_person_links",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ext_person_id",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "ext_project_members(where:{\"deleted\":{\"_eq\":false}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ext_project_links(where:{\"active\":{\"_eq\":true}})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "project_members",
        "kind": "LinkedField",
        "name": "project_member",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "has_actuals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "has_assignments",
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "just_added_timestamp",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectMemberBulkCreateMutation",
    "selections": (v12/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectMemberBulkCreateMutation",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "322f2d0cb0261efffd0ab6461b8448f5",
    "id": null,
    "metadata": {},
    "name": "ProjectMemberBulkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectMemberBulkCreateMutation(\n  $input: ProjectMemberBulkCreateInput!\n) {\n  action_project_member_bulk_create(input: $input) {\n    id\n    person {\n      id\n      project_memberships {\n        id\n      }\n    }\n    project {\n      id\n      project_rates {\n        id\n        role_id\n        project_id\n        rate: rate_private\n      }\n      members {\n        id\n        project_id\n        role_id\n        person_id\n        workstream_id\n        is_placeholder\n        person {\n          id\n          first_name\n          last_name\n        }\n        project {\n          id\n          name\n        }\n        role {\n          id\n          name\n        }\n        workstream {\n          id\n          name\n        }\n      }\n      ext_project_links(where: {active: {_eq: true}}) {\n        project_id\n        ext_project_id\n        ext_project {\n          id\n          integration {\n            id\n            integration_service {\n              id\n              name\n            }\n          }\n          ext_project_members(where: {deleted: {_eq: false}}) {\n            id\n            ext_person {\n              id\n              first_name\n              last_name\n              email\n              is_placeholder\n              active\n              ext_person_links(where: {active: {_eq: true}}) {\n                ext_person_id\n                person_id\n                active\n              }\n            }\n          }\n        }\n      }\n    }\n    project_member {\n      id\n      project_id\n      person_id\n      role_id\n      workstream_id\n      has_actuals\n      has_assignments\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7082267b2e4f85fa0d869d3ef0a8fe8";

export default node;
