import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { FixedSizeList } from "react-window"

import styles from "./AddProjectToPerson.module.css"

import {
  AddProjectToPerson_user$data,
  AddProjectToPerson_user$key,
} from "./__generated__/AddProjectToPerson_user.graphql"

import { track } from "~/helpers/analytics"
import { prepareDataForFilterProjectList } from "~/helpers/filter-engine"
import { formatName, getCurrentContract } from "~/helpers/person"
import { pluralize } from "~/helpers/plural"
import { Member, forceShowMembers } from "~/helpers/project-member-helpers"
import { sortProjectsByProjectName } from "~/helpers/sorting-helpers"

import ClientDetails from "~/common/ClientDetails"
import PersonWorkstreamSelector from "~/common/SelectPersonList/PersonWorkstreamSelector"
import {
  SelectorFooter,
  SelectorHeader,
  SelectorListContainer,
  SelectorListItem,
  SelectorModal,
} from "~/common/SelectorModal"
import { toOptionIncludingArchived } from "~/common/SelectorModal/SelectorHelpers"
import SuperSearch, { useProjectSearchConfig } from "~/common/SuperSearch"
import AddButton from "~/common/buttons/AddButton"
import Checkbox from "~/common/inputs/Checkbox"
import viewsReducer from "~/common/views.reducer"

import { projectMemberBulkCreateRelay } from "~/mutations/ProjectMember"

import { usePermissions } from "~/Permissions/usePermissions"
import RoleSelector from "~/Planner/RoleSelector"
import { buildProjectsFilter } from "~/Planner/buildHasuraFilter"
import { enableProject } from "~/Planner/reducer2/scenarioPlanningSlice"
import {
  addAdditionalPlannerIds,
  resetTemporaryPlannerFilter,
  setTemporaryPlannerFilter,
  setTemporaryPlannerFilterToAll,
} from "~/Planner/reducer2/viewsSlice"
import { useAppSelector } from "~/hooks/redux"
import { useFilterSets } from "~/queries/FilterSet"

type Props = {
  personId: number
  user: AddProjectToPerson_user$key
}

type Project = AddProjectToPerson_user$data["account"]["projects"][0]
type Contract =
  AddProjectToPerson_user$data["account"]["people"][0]["contracts"][0]
type Person = AddProjectToPerson_user$data["account"]["people"][0]

type ProjectWorkstream = {
  projectId: number
  workstreamId: number
}

type RoleOption = {
  value: number
  label: string
  active: boolean
}

const RowRenderer = ({
  index,
  style,
  data,
}: {
  index: number
  style: any
  data: {
    projects: Project[]
    contract: Contract
    person: Person
    roleOptions: RoleOption[]
    options: State
    handlePersonRoleSelect: (input: {
      personId: number
      role: SelectableRole
      projectId: number
    }) => void
    handleProjectWorkstreamSelect: (pw: ProjectWorkstream[]) => void
    onProjectSelectionChange: (project: Project) => void
    selectedProjectWorkstreams: ProjectWorkstream[]
    workstreamsEnabled: boolean
    selectedProjects: Project[]
  }
}) => {
  const {
    contract,
    options,
    person,
    projects,
    roleOptions,
    handlePersonRoleSelect,
    handleProjectWorkstreamSelect,
    onProjectSelectionChange,
    selectedProjectWorkstreams,
    workstreamsEnabled,
    selectedProjects,
  } = data

  const project = projects[index]
  const workstreamOptions =
    project.project_workstreams.map((pw) =>
      toOptionIncludingArchived(pw.workstream),
    ) || []

  const isMember = project.members?.some((m) => m?.person_id === person.id)

  const client = project.client
  const defaultRole = useMemo(() => {
    return contract?.role.name
      ? {
          value: contract.role.id,
          label: contract.role.name,
          active: contract.role.active,
        }
      : roleOptions[0]
  }, [contract, roleOptions])

  const defaultRoleValue = React.useMemo(
    () => ({
      ...defaultRole,
      archived: !defaultRole.active,
      disabled:
        !defaultRole.active &&
        !project.members.some((m) => m.role_id === defaultRole.value),
    }),
    [defaultRole, project.members],
  )

  const currentProjectWorkstream =
    selectedProjectWorkstreams?.find((pw) => pw.projectId === project.id) ||
    null

  const selectedWorkstream = workstreamOptions.find(
    (w) => w.value === currentProjectWorkstream?.workstreamId,
  )

  const handleWorkstreamSelect = (e) => {
    const newProjectWorkstream = {
      projectId: project.id,
      workstreamId: e.value,
    }
    const projectWorkstreams = currentProjectWorkstream
      ? selectedProjectWorkstreams?.filter((pw) => pw.projectId !== project.id)
      : selectedProjectWorkstreams || []

    handleProjectWorkstreamSelect([newProjectWorkstream, ...projectWorkstreams])
  }

  const isSelected = selectedProjects.some(({ id }) => id === project.id)

  const isSelectedRoleArchived = (() => {
    const selectedRole = options[project.id]?.role

    return selectedRole ? !selectedRole.active : !defaultRoleValue.active
  })()

  const isSelectedRoleDisabled = useMemo(() => {
    return (
      isSelectedRoleArchived &&
      !project.members.some((m) => m.role_id === defaultRole.value)
    )
  }, [isSelectedRoleArchived, project.members, defaultRole.value])

  const filterRolePredicate = (role: { id: number; active: boolean }) => {
    const rolesInProject = project.members.map((m) => m.role_id)

    return role.active === false ? rolesInProject.includes(role.id) : true
  }

  return (
    <div key={project.id} style={style}>
      <SelectorListItem
        key={project.id}
        onClick={() =>
          !isSelectedRoleDisabled && onProjectSelectionChange(project)
        }
        style={{
          padding: !project.confirmed ? "5px 10px" : "auto",
          height: "100%",
        }}
      >
        <div className={styles.itemLeft}>
          <ClientDetails
            title={project.name}
            subtitle={client.name}
            isMember={isMember}
            imageKey={client.image_key}
            website={client.website}
            tentative={!project.confirmed}
            projectEmoji={project.emoji}
          />
        </div>
        {/* TODO(workstreams): Delete this once workstreams is released */}
        {!workstreamsEnabled && isMember && (
          <span className={styles.memberText}>On project</span>
        )}
        <div
          className={cc([styles.select, styles.roleSelector])}
          onClick={(e) => e.stopPropagation()}
        >
          <RoleSelector
            dataTest="role-select"
            name={`${project.name}-role-select`}
            defaultValue={defaultRoleValue}
            value={options[project.id]}
            onChange={(role) =>
              handlePersonRoleSelect({
                personId: person.id,
                projectId: project.id,
                role: role,
              })
            }
            placeholder="Select role"
            menuShouldBlockScroll={true}
            height={30}
            filterNonActive={false}
            filterPredicate={filterRolePredicate}
          />
        </div>
        {workstreamsEnabled && !!workstreamOptions?.length && (
          <div
            className={cc([styles.select, styles.workstreamSelector])}
            onClick={(e) => e.stopPropagation()}
          >
            <PersonWorkstreamSelector
              onChange={handleWorkstreamSelect}
              workstreamOptions={workstreamOptions}
              selectedWorkstream={selectedWorkstream}
              width={200}
            />
          </div>
        )}

        <div className={styles.checkboxContainer}>
          <Checkbox
            id={`SelectorListItem_Checkbox_isSelected_${project.id}`}
            value={project.id}
            onChange={() => onProjectSelectionChange(project)}
            checked={isSelected}
            disabled={isSelectedRoleDisabled}
          />
        </div>
      </SelectorListItem>
    </div>
  )
}

type ProjectID = number

type SelectableRole = { value: number; label: string; active: boolean }
type State = Record<ProjectID, { personId: number; role: SelectableRole }>

type Action =
  | {
      type: "ADD_ROLE"
      person: { [key: ProjectID]: { personId: number; role: SelectableRole } }
    }
  | { type: "CLEAR" }

const AddProjectToPerson = (props: Props) => {
  const user = useFragment(
    graphql`
      fragment AddProjectToPerson_user on users
      @argumentDefinitions(
        peopleFilter: { type: "people_bool_exp" }
        projectsFilter: { type: "projects_bool_exp" }
      ) {
        id
        view_id
        favourite_projects
        account {
          id
          secondary_person_field
          views {
            id
            project_filters
          }
          projects(where: $projectsFilter) {
            id
            name
            confirmed
            team_id
            active
            is_template
            emoji
            tags_computed
            pricing_model
            client {
              id
              name
              image_key
              website
            }
            members {
              id
              project_id
              person_id
              role_id
              workstream_id
            }
            managers {
              id
              user_id
            }
            team {
              id
              name
            }
            custom_select_values {
              id
              custom_select_option_id
              custom_select_type_id
            }
            custom_date_values {
              id
              value
              custom_date_type_id
            }
            custom_text_values {
              id
              value
              custom_text_type_id
            }
            custom_checkbox_values {
              id
              value
              custom_checkbox_type_id
            }
            project_workstreams {
              workstream {
                id
                name
                archived
              }
            }
          }
          people(where: $peopleFilter) {
            id
            first_name
            last_name
            email
            image_key
            is_placeholder
            tags
            time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              person_id
              minutes_per_day
              ...ExtLinks_TimeOff @relay(mask: false)
            }
            current_contract {
              id
              job_title
            }
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              minutes_per_day
              job_title
              role {
                id
                name
                active
              }
            }
            team {
              id
              name
            }
            competencies {
              id
              level
              skill {
                id
                name
              }
            }
            project_memberships {
              id
              project_id
            }
            person_requests {
              id
              status
            }
            custom_select_values {
              id
              custom_select_option_id
              custom_select_type_id
            }
            custom_text_values {
              id
              value
              custom_text_type_id
            }
            custom_checkbox_values {
              id
              value
              custom_checkbox_type_id
            }
            custom_date_values {
              id
              value
              custom_date_type_id
            }
            managers {
              id
              user_id
            }
          }
          roles {
            id
            name
            active
          }
        }
      }
    `,
    props.user,
  )
  const { personId } = props
  const { view_id: defaultViewId, account, favourite_projects } = user
  const { projects: allProjects, people, roles, views } = account

  const isFeatureViewEnabled = useFeature("pre_filtered_views")
  const [viewState, setViewState] = useReducer(viewsReducer, {
    showAllView: !defaultViewId,
    viewId: defaultViewId ?? null,
  })

  const { viewId, showAllView } = viewState
  const [showModal, setShowModal] = useState(false)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [wildSearchQuery, setWildSearchQuery] = useState("")
  const [localFilterSet, setLocalFilterSet] =
    useState<fe.engines.local.AllowedFilterSet>({ name: "", filters: null })

  const roleReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "ADD_ROLE":
        return { ...state, ...action.person }
      case "CLEAR":
        return {}
      default: {
        return state
      }
    }
  }

  const [options, setOption] = useReducer(roleReducer, {})
  const [selectedProjectWorkstreams, setSelectedProjectWorkstreams] =
    useState(null)
  const dispatch = useDispatch()
  const { can, subject } = usePermissions()
  const projectSearchConfig = useProjectSearchConfig({
    excludedFilters: ["project_template_id", "project_id", "project_state"],
  })

  const superSearchConfig = {
    ...projectSearchConfig,
    autoFocusWildSearch: true,
  }

  const savedFilterSets = useFilterSets({ type: "project" })

  const person = people.find((p) => p.id === personId)
  const contract = getCurrentContract(person.contracts)

  const validProjects = allProjects.filter(
    (p) =>
      !p.is_template &&
      p.active &&
      can(
        "create",
        subject("ProjectMember", {
          project: { id: p.id, isTemplate: p.is_template },
          person,
        }),
      ),
  )
  const sortedProjects = sortProjectsByProjectName(validProjects) as Project[]

  const viewProjectFilters =
    isFeatureViewEnabled && viewId && !showAllView
      ? views.find((view) => view.id === viewId)?.project_filters
      : undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterSet = {
    name: "",
    filters: fe.filters.simplify(
      fe.filters.and([
        viewProjectFilters,
        localFilterSet?.filters,
        fe.filters.projectIsActive({ value: true }),
        fe.filters.projectIsTemplate({ value: false }),
        wildSearchQuery
          ? fe.filters.projectWildSearch({ query: wildSearchQuery })
          : undefined,
      ]),
    ),
  }

  const filterTypeList = fe.filters.asTypeList(filterSet.filters)
  const calStartNum = useAppSelector((state) => state.calendar.calStartNum)

  const preparedList = useMemo(
    () => {
      return prepareDataForFilterProjectList({
        filterTypeList,
        projects: sortedProjects,
        people,
        favouriteProjectIds: favourite_projects,
        calStartNum,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterTypeList.join(","),
      sortedProjects,
      people,
      calStartNum,
    ],
  )

  const filteredProjects = useMemo(() => {
    const filteredProjectList = fe.engines.local.filterProjectList(
      preparedList,
      filterSet,
    )

    const filteredProjectIds = Object.fromEntries(
      filteredProjectList.map((project) => [project.id, true]),
    )

    return sortedProjects.filter((project) => filteredProjectIds[project.id])
  }, [preparedList, filterSet, sortedProjects]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddPersonToProject = (projects: Project[]) => {
    const { newMembers, existingMembers } = projects.reduce(
      (acc, project) => {
        if (!project.confirmed) {
          dispatch(enableProject(project.id))
        }

        const projectWorkstreamId = selectedProjectWorkstreams?.find(
          (pw) => pw.projectId === project.id,
        )?.workstreamId

        const workstreamId =
          typeof projectWorkstreamId === "number" ? projectWorkstreamId : null

        const role = (() => {
          const selectedRole = options[project.id]?.role

          return selectedRole
            ? roles.find((r) => r.id === selectedRole.value)
            : getCurrentContract(person.contracts)?.role
        })()

        const existingMember = project.members?.find(
          (m) =>
            m?.person_id === person.id &&
            m?.role_id === role.id &&
            m?.workstream_id === workstreamId,
        )

        if (existingMember) {
          acc.existingMembers.push(existingMember)
        } else {
          track("Person Project Added", {
            role: role?.name,
            workstreams: project.project_workstreams
              .map((w) => w.workstream)
              ?.find((w) => w.id === workstreamId)?.name,
          })

          acc.newMembers.push({
            person_id: person.id,
            project_id: project.id,
            role_id: role.id,
            workstream_id: workstreamId,
            is_placeholder: person.is_placeholder,
          })
        }
        return acc
      },
      {
        newMembers: [] as Member[],
        existingMembers: [] as { id: number }[],
      },
    )

    if (existingMembers.length) {
      forceShowMembers(existingMembers)
    }

    if (newMembers.length) {
      void projectMemberBulkCreateRelay({
        input: { project_members: newMembers },
      })
    }

    if (defaultViewId !== viewId) {
      dispatch(
        addAdditionalPlannerIds({
          viewId: defaultViewId,
          projectIds: projects.map(({ id }) => id),
        }),
      )
    }
  }

  const handlePersonRoleSelect = (input: {
    personId: number
    projectId: number
    role: SelectableRole
  }) => {
    setOption({
      type: "ADD_ROLE",
      person: {
        [input.projectId]: {
          personId: input.personId,
          role: input.role,
        },
      },
    })
  }

  const handleProjectWorkstreamSelect = (projectWorkstreams) => {
    setSelectedProjectWorkstreams(projectWorkstreams)
  }

  const closeDialog = () => {
    setSelectedProjects([])
    setShowModal(false)
    setOption({ type: "CLEAR" })

    setViewState({
      type: "RESET",
      payload: {
        viewId: defaultViewId,
        showAllView: defaultViewId ? false : true,
      },
    })

    setSelectedProjectWorkstreams([])
    dispatch(resetTemporaryPlannerFilter())
  }

  const handleSubmit = () => {
    handleAddPersonToProject(selectedProjects)
    closeDialog()
  }

  const handleChangeViewId = (nextViewId: number) => {
    setViewState({ type: "VIEW_SET", payload: { viewId: nextViewId } })
    if (nextViewId !== user.view_id) {
      const projectFilters = buildProjectsFilter(
        fe.filters.flat(
          views.find((view) => view.id === nextViewId)?.project_filters,
        ),
      )
      dispatch(
        setTemporaryPlannerFilter({
          projectFilters,
        }),
      )
    }
  }

  const handleChangeShowAllView = () => {
    setViewState({ type: "VIEW_SHOW_ALL" })
    dispatch(setTemporaryPlannerFilterToAll())
  }

  const roleOptions = roles
    .filter((r) => r.active)
    .map((r) => ({ value: r.id, label: r.name }))

  const noResultsFound = !filteredProjects.length

  // Set the height for the virtual list
  const [listContainerHeight, setListContainerHeight] = useState(315) // standard height
  const listContainerRef = useRef(null)
  useEffect(() => {
    // Handles resized height
    if (listContainerRef?.current?.clientHeight) {
      setListContainerHeight(listContainerRef.current.clientHeight)
    }
  }, [listContainerRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeFilterSet = (
    selectedFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    setLocalFilterSet(selectedFilterSet)
  }

  const workstreamsEnabled = useFeature("workstreams")

  const onProjectSelectionChange = (projectSelected: Project) => {
    selectedProjects.some(
      (selectedProject) => selectedProject.id === projectSelected.id,
    )
      ? setSelectedProjects(
          selectedProjects.filter(({ id }) => id !== projectSelected.id),
        )
      : setSelectedProjects([...selectedProjects, projectSelected])
  }

  const addButtonText = `Add to ${selectedProjects.length ? selectedProjects.length : ""} ${pluralize(selectedProjects.length, "Project")}`

  // Can we create *some* project member for *this* person
  const canCreateSomeProjectMember = useMemo(
    () =>
      account.projects.some((p) =>
        can(
          "create",
          subject("ProjectMember", {
            person,
            project: { id: p.id, isTemplate: p.is_template },
          }),
        ),
      ),
    [account.projects, person], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <>
      {canCreateSomeProjectMember && (
        <AddButton
          text="Assign Project"
          data-test="assign-person-button"
          onClick={() => setShowModal(true)}
          active={showModal}
        />
      )}
      <SelectorModal isOpen={showModal} onClose={closeDialog} modalWidth={800}>
        <>
          <SelectorHeader
            title={"Add to project(s)"}
            subtitle={formatName(person.first_name, person.last_name)}
            onClose={closeDialog}
          />
          <div className={styles.filterContainer}>
            <SuperSearch
              config={superSearchConfig}
              location="planner"
              wildSearchQuery={wildSearchQuery}
              onChangeWildSearchQuery={setWildSearchQuery}
              filterSet={localFilterSet}
              onChangeFilterSet={handleChangeFilterSet}
              savedFilterSets={savedFilterSets}
              viewState={viewState}
              handleChangeViewId={handleChangeViewId}
              handleChangeShowAllView={handleChangeShowAllView}
            />
          </div>
          <div ref={listContainerRef}>
            <SelectorListContainer
              type="projects"
              noResultsFound={noResultsFound}
              filterValue={wildSearchQuery}
              disableOverflow={true}
            >
              <FixedSizeList
                height={listContainerHeight}
                itemCount={filteredProjects.length}
                itemSize={workstreamsEnabled ? 65 : 50}
                width={"100%"}
                itemData={{
                  projects: filteredProjects,
                  contract,
                  person,
                  roleOptions,
                  options,
                  handlePersonRoleSelect,
                  handleProjectWorkstreamSelect,
                  onProjectSelectionChange,
                  selectedProjectWorkstreams,
                  workstreamsEnabled,
                  selectedProjects,
                }}
              >
                {RowRenderer}
              </FixedSizeList>
            </SelectorListContainer>
          </div>
        </>
        <SelectorFooter>
          <AddButton
            text={addButtonText}
            data-test="add-selected-projects-button"
            outlined={false}
            disabled={!selectedProjects.length}
            onClick={handleSubmit}
            style={{ marginLeft: 10 }}
          />
        </SelectorFooter>
      </SelectorModal>
    </>
  )
}

export default AddProjectToPerson
