import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./TentativeProjectEnabledSwitch.module.css"

type Props = {
  /**
   * Display in a minimal style swapping the label for a tooltip
   */
  minimal?: boolean

  checked: boolean
  onChange: () => void

  disabled?: boolean
  tooltipContent?: React.ReactElement | string

  style?: React.CSSProperties
}

const TentativeProjectEnabledSwitch = (props: Props) => {
  const {
    minimal = false,
    checked,
    onChange,
    disabled,
    tooltipContent,
    style,
  } = props

  const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation()
  }

  // Custom input instead of blueprintJs Switch to allow stopping propagation onClick
  return (
    <Tooltip
      placement="top"
      disabled={!minimal}
      hoverOpenDelay={800}
      content={tooltipContent}
    >
      <label
        onClick={handleClick}
        className={cc([
          "bp5-control",
          "bp5-switch",
          styles.tentative,
          {
            [styles.unchecked]: !checked,
          },
        ])}
        style={style}
      >
        <input
          aria-label="Tentative"
          onClick={handleClick}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="bp5-control-indicator"></span>
        {!minimal && "Tentative"}
      </label>
    </Tooltip>
  )
}

export { TentativeProjectEnabledSwitch }
