/**
 * @generated SignedSource<<7b4ef664ec9b7f4585410724c11d50db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectSchedulePanel_user$data = {
  readonly account: {
    readonly projects: ReadonlyArray<{
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"PhasesWrapper_project" | "ProjectAddPersonRow_project" | "ProjectDetailsSection_project" | "ProjectRowSummary_project">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"AddPersonToProject_account" | "ProjectAddPersonRow_account" | "ProjectDetailsSection_account" | "ProjectRowSummary_account">;
  };
  readonly favouriteProjectIds: any;
  readonly userId: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsSection_user">;
  readonly " $fragmentType": "ProjectSchedulePanel_user";
};
export type ProjectSchedulePanel_user$key = {
  readonly " $data"?: ProjectSchedulePanel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectSchedulePanel_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "peopleFilter",
  "variableName": "peopleFilter"
},
v1 = {
  "kind": "Variable",
  "name": "plannerStartDate",
  "variableName": "plannerStartDate"
},
v2 = {
  "kind": "Variable",
  "name": "projectsFilter",
  "variableName": "projectsFilter"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "todaysDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectSchedulePanel_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsSection_user"
    },
    {
      "alias": "favouriteProjectIds",
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_projects",
      "storageKey": null
    },
    {
      "alias": "userId",
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectRowSummary_account"
        },
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "ProjectAddPersonRow_account"
        },
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "ProjectDetailsSection_account"
        },
        {
          "args": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "AddPersonToProject_account"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectAddPersonRow_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectDetailsSection_project"
            },
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "todaysDate",
                  "variableName": "todaysDate"
                }
              ],
              "kind": "FragmentSpread",
              "name": "ProjectRowSummary_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PhasesWrapper_project"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "8bd9374b579fc70324b61c9c3c6894c1";

export default node;
