/**
 * @generated SignedSource<<afa432800c750957135f22e53e79f35a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSidePanelContract_account$data = {
  readonly business_days: ReadonlyArray<string>;
  readonly id: number;
  readonly " $fragmentType": "PersonSidePanelContract_account";
};
export type PersonSidePanelContract_account$key = {
  readonly " $data"?: PersonSidePanelContract_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanelContract_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSidePanelContract_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "business_days",
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "9baec23dbc674528f0ddad0e1ac6f187";

export default node;
