import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { startOfISOWeek } from "date-fns"
import { useFeature } from "flagged"
import React, { useMemo, useRef } from "react"
import { connect } from "react-redux"
import { match } from "ts-pattern"

import styles from "./ConflictWarning.module.css"

import { getConsecutiveContractDates } from "~/helpers/contract-helpers"

import { itemsOverlap, sortByStartDate } from "./Pill/action_helpers"
import { moveDateRange } from "./calendar.reducer"

type Range = {
  id: number
  start_date: string
  end_date: string
}

type Conflict =
  | { type: "time-off" }
  | { type: "no-contract" }
  | { type: "time-off-and-no-contract" }

type RenderProps = {
  conflict: Conflict
  jumpToAssignment: () => void
  assignmentsOverlappingWithTimeOff: ReadonlyArray<
    Range & { project_id: number }
  >
  assignmentsOutOfContract: ReadonlyArray<Range & { project_id: number }>
}

type Props = {
  assignments: ReadonlyArray<
    Range & {
      project_id: number
      is_template: boolean
      non_working_day: boolean
    }
  >
  timeOffs: ReadonlyArray<
    Range & { leave_type: string; minutes_per_day?: number }
  >
  contracts: ReadonlyArray<Range>
  moveDateRangeDispatch: (date: Date) => void
  className?: string
  children?: (props: RenderProps) => React.ReactElement
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  customLeftPosition?: string
}

const ConflictWarning = (props: Props) => {
  const {
    assignments,
    timeOffs,
    moveDateRangeDispatch,
    className,
    children,
    onClick,
    customLeftPosition,
    contracts,
  } = props

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const jumpToNumber = useRef(0)

  const assignmentsOnWorkingDays = useMemo(
    () =>
      assignments.filter((a) => !a.is_template && a.non_working_day === false),
    [assignments],
  )

  const assignmentsOverlappingWithTimeOff = useMemo(
    () =>
      assignmentsOnWorkingDays
        .filter(
          (a) =>
            !!timeOffs.find(
              (to) =>
                to.leave_type !== "holiday" &&
                itemsOverlap(a, to) &&
                !to.minutes_per_day,
            ),
        )
        .sort(sortByStartDate),
    [assignmentsOnWorkingDays, timeOffs],
  )

  const assignmentsOutOfContract: (Range & {
    project_id: number
    is_template: boolean
    non_working_day: boolean
  })[] = useMemo(() => {
    return assignmentsOnWorkingDays.filter((a) =>
      getConsecutiveContractDates(contracts).every(
        (c) =>
          a.start_date < c.start_date ||
          (c.end_date && a.end_date > c.end_date),
      ),
    )
  }, [assignmentsOnWorkingDays, contracts])

  if (isConsistentTimeOffEnabled) {
    return null
  }

  const conflict: Conflict | null = (() => {
    if (
      assignmentsOverlappingWithTimeOff.length > 0 &&
      assignmentsOutOfContract.length > 0
    ) {
      return { type: "time-off-and-no-contract" }
    } else if (assignmentsOverlappingWithTimeOff.length > 0) {
      return { type: "time-off" }
    } else if (assignmentsOutOfContract.length > 0) {
      return { type: "no-contract" }
    } else {
      return null
    }
  })()

  if (conflict == null) {
    return null
  }

  const overlappingAssignments = assignmentsOverlappingWithTimeOff.concat(
    assignmentsOutOfContract,
  )

  const jumpToAssignment = () => {
    // If the user deletes timeoffs and presses again. Our state won't have reset
    // If this happens. We get undefined. So we just use the first one instead
    const assignment = overlappingAssignments[jumpToNumber.current]
      ? overlappingAssignments[jumpToNumber.current]
      : overlappingAssignments[0]

    const jumpToDate = startOfISOWeek(
      dateHelpers.parseRunnDate(assignment.start_date),
    )
    moveDateRangeDispatch(jumpToDate)
    if (jumpToNumber.current + 1 < overlappingAssignments.length) {
      jumpToNumber.current++
    } else {
      jumpToNumber.current = 0
    }
  }

  return (
    <div
      data-component="TimeoffOverlapWarning"
      className={cc([styles.container, className])}
      style={{ left: customLeftPosition }}
    >
      <div className={styles.warning} onClick={onClick}>
        {children &&
          children({
            jumpToAssignment,
            assignmentsOverlappingWithTimeOff,
            assignmentsOutOfContract,
            conflict,
          })}
      </div>
    </div>
  )
}

export const conflictToLabel = (conflict: Conflict, personName: string) =>
  match(conflict)
    .with({ type: "time-off" }, () => "Assignments conflicting with time off.")
    .with(
      { type: "no-contract" },
      () => `Assignment scheduled while ${personName} has no contract.`,
    )
    .with(
      { type: "time-off-and-no-contract" },
      () =>
        `Assignment scheduled while ${personName} has time off and no contract.`,
    )
    .exhaustive()

const mapStateToProps = (state) => ({
  calendar: state.calendar,
})

const connector = connect(mapStateToProps, {
  moveDateRangeDispatch: moveDateRange,
})

export default connector(ConflictWarning)
