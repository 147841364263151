/**
 * @generated SignedSource<<a9ee610df088de71bc0bf869b6a2f473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectPlanner_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlannerHeader_account">;
  readonly " $fragmentType": "ProjectPlanner_account";
};
export type ProjectPlanner_account$key = {
  readonly " $data"?: ProjectPlanner_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPlanner_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPlanner_account",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PlannerHeader_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "9f88fd4c41435ba60515e12be5ee4bb5";

export default node;
