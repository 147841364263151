import * as relayids from "~/helpers/relayids"

const project = (pluralField: string) => ({
  getDataId: (row) => relayids.projects.encode(row.project_id),
  pluralField,
})

const person = (pluralField: string) => ({
  getDataId: (row) => relayids.people.encode(row.person_id),
  pluralField,
})

export { person, project }
