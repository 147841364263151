import {
  formatISO,
  isValid as isValidDate,
  isWeekend,
  parseISO,
} from "date-fns"
import React, { useEffect, useState } from "react"

import styles from "./PlannerDatePicker.module.css"

import NumericInput from "~/common/NumericInput"

type Props = {
  selectedDates: any
  totalWorkingDays: string | number
  tabIndex?: number
  onChangeWorkingDays: (days: number) => void
  handleDatePickerChange: (range) => void
}

const MIN_DATE = "2018-01-01"
const MAX_DATE = "2035-01-01"

const PlannerDatePickerDayStats = (props: Props) => {
  const {
    selectedDates,
    totalWorkingDays,
    tabIndex,
    onChangeWorkingDays,
    handleDatePickerChange,
  } = props

  const startDateString = selectedDates[0]
    ? formatISO(selectedDates[0], { representation: "date" })
    : ""
  const endDateString = selectedDates[1]
    ? formatISO(selectedDates[1], { representation: "date" })
    : ""

  const [startDate, setStartDate] = useState(startDateString)
  const [endDate, setEndDate] = useState(endDateString)

  const handleStartChange = (e) => {
    setStartDate(e.target.value)

    const newStartDate = parseISO(e.target.value)

    if (isValidDate(newStartDate) && e.target.value >= MIN_DATE) {
      handleDatePickerChange([
        newStartDate,
        e.target.value > endDateString ? null : selectedDates[1],
      ])
    }
  }

  const handleEndChange = (e) => {
    setEndDate(e.target.value)
    const newEndDate = parseISO(e.target.value)

    if (
      isValidDate(newEndDate) &&
      e.target.value <= MAX_DATE &&
      e.target.value >= startDate
    ) {
      handleDatePickerChange([selectedDates[0], newEndDate])
    }
  }

  useEffect(() => {
    const updatedStartDateString = selectedDates[0]
      ? formatISO(selectedDates[0], { representation: "date" })
      : ""
    const updatedEndDateString = selectedDates[1]
      ? formatISO(selectedDates[1], { representation: "date" })
      : ""
    setStartDate(updatedStartDateString)
    setEndDate(updatedEndDateString)
  }, [selectedDates])

  const isStartDateWeekend = isWeekend(parseISO(startDate))
  const isEndDateWeekend = isWeekend(parseISO(endDate))

  const startDateError =
    startDate > endDate || startDate < MIN_DATE || isStartDateWeekend

  const endDateError =
    endDate < startDate || endDate > MAX_DATE || isEndDateWeekend

  return (
    <div className={styles.stats}>
      <div>
        <label>Start Date</label>
        <input
          type="date"
          value={startDate}
          onChange={handleStartChange}
          min={MIN_DATE}
          max={MAX_DATE}
          className={startDateError ? styles.dateError : ""}
        />
        {isStartDateWeekend && (
          <label className={styles.errorLabel}>Select a week day</label>
        )}
      </div>
      <div>
        <label>Work Days</label>
        <NumericInput
          min={1}
          max={365 * 10}
          value={totalWorkingDays || ""}
          onValueChange={onChangeWorkingDays}
          selectAllOnFocus
          disabled={!selectedDates[0] && !selectedDates[1]}
          className={styles.workingDaysInput}
          tabIndex={tabIndex}
          integer
          showArrows
        />
      </div>
      <div>
        <label>End Date</label>
        <input
          type="date"
          value={endDate}
          onChange={handleEndChange}
          min={startDateString}
          max={MAX_DATE}
          className={endDateError ? styles.dateError : ""}
        />
        {isEndDateWeekend && (
          <label className={styles.errorLabel}>Select a week day</label>
        )}
      </div>
    </div>
  )
}

export default PlannerDatePickerDayStats
