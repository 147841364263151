/**
 * @generated SignedSource<<e801bdcc7ab577f1b263f4a529ae8a5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderPlannerMenu_placeholder$data = {
  readonly first_name: string;
  readonly id: number;
  readonly last_name: string;
  readonly project_memberships: ReadonlyArray<{
    readonly id: number;
    readonly workstream: {
      readonly id: number;
    } | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_person" | "ChangeWorkstreamModal_person" | "PlaceholderSidePanel_placeholder" | "PlaceholderWorkflowMenu_placeholder">;
  readonly " $fragmentType": "PlaceholderPlannerMenu_placeholder";
};
export type PlaceholderPlannerMenu_placeholder$key = {
  readonly " $data"?: PlaceholderPlannerMenu_placeholder$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderPlannerMenu_placeholder">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderPlannerMenu_placeholder",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstream",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderWorkflowMenu_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeWorkstreamModal_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderSidePanel_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeProjectRoleModal_person"
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "04ee5d98e782651e2f7b7f738a5992df";

export default node;
