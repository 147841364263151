/**
 * @generated SignedSource<<6a2cda4533d8d6ad8a03bce0a559df94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CapacityIndicator_account$data = {
  readonly default_full_time_minutes: number;
  readonly " $fragmentType": "CapacityIndicator_account";
};
export type CapacityIndicator_account$key = {
  readonly " $data"?: CapacityIndicator_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"CapacityIndicator_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CapacityIndicator_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "c597d1b322cd82d4661af74d64f8f877";

export default node;
