import React from "react"

export type FilteredPlannerContextData = {
  projectIDs: number[]
  peopleIDs: number[]
  totalProjectCount: number
  totalPeopleCount: number
}

const FilteredPlannerContext = React.createContext<FilteredPlannerContextData>({
  projectIDs: [],
  peopleIDs: [],
  totalProjectCount: 0,
  totalPeopleCount: 0,
})

export default FilteredPlannerContext
