import { graphql } from "react-relay"

import { PersonRequestCreateMutation } from "./__generated__/PersonRequestCreateMutation.graphql"
import { PersonRequestUpdateMutation } from "./__generated__/PersonRequestUpdateMutation.graphql"
import { PersonRequestDeleteMutation } from "~/mutations/__generated__/PersonRequestDeleteMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const personRequestCreateMutation = graphql`
  mutation PersonRequestCreateMutation($input: CreatePersonRequestInput!) {
    createPersonRequest(input: $input) {
      person_request {
        id
        status
        project_id
        person_id
        person {
          id
          person_requests {
            id
            status
            updated_at
          }
        }
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const personRequestCreateRelay = (
  variables: PersonRequestCreateMutation["variables"]["input"],
) => {
  return commitMutationPromise<PersonRequestCreateMutation>(environment, {
    mutation: personRequestCreateMutation,
    variables: {
      input: variables,
    },
  })
}

const personRequestUpdateMutation = graphql`
  mutation PersonRequestUpdateMutation($input: UpdatePersonRequestInput!) {
    updatePersonRequest(input: $input) {
      person_request {
        id
        status
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const personRequestUpdateRelay = (
  variables: PersonRequestUpdateMutation["variables"]["input"],
) => {
  return commitMutationPromise<PersonRequestUpdateMutation>(environment, {
    mutation: personRequestUpdateMutation,
    variables: {
      input: variables,
    },
  })
}

const personRequestDeleteMutation = graphql`
  mutation PersonRequestDeleteMutation($id: Int!) {
    deletePersonRequest(id: $id) {
      id
      person {
        id
        person_requests {
          id
        }
      }
    }
  }
`

export const personRequestDeleteMutationRelay = async (
  variables: PersonRequestDeleteMutation["variables"],
) => {
  return await commitMutationPromise<PersonRequestDeleteMutation>(environment, {
    mutation: personRequestDeleteMutation,
    variables,
  })
}
