import { reportError } from "./error-helpers"
import * as relayids from "./relayids"

const parseFavouriteList = (
  input: unknown,
  typename: keyof typeof relayids,
): number[] => {
  if (!Array.isArray(input)) {
    return []
  }

  const output = input
    .map((id: unknown) => {
      if (typeof id === "string") {
        try {
          return relayids[typename].decode(id)[0]
        } catch (error) {
          void reportError(error)
          return undefined
        }
      }
      return id
    })
    .filter((item: unknown) => typeof item === "number")

  return output
}

const parseFavouriteProjectIds = (input: unknown) => {
  return parseFavouriteList(input, "projects")
}

const parseFavouritePersonIds = (input: unknown) => {
  return parseFavouriteList(input, "people")
}

export { parseFavouritePersonIds, parseFavouriteProjectIds }
