import { Icon, IconName, MenuDivider } from "@blueprintjs/core"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { PlaceholderWorkflowMenu_account$key } from "./__generated__/PlaceholderWorkflowMenu_account.graphql"
import { PlaceholderWorkflowMenu_placeholder$key } from "./__generated__/PlaceholderWorkflowMenu_placeholder.graphql"

import { track } from "~/helpers/analytics"
import { getResourceMenuItems } from "~/helpers/placeholder-helpers"

import MenuItem from "~/common/MenuItem"
import { useSidePanel } from "~/common/SidePanel/SidePanel"

import { PERSON_REQUEST_STATUSES as STATUSES } from "~/ENUMS"

import PlaceholderSidePanel from "../PlaceholderSidePanel/PlaceholderSidePanel"

import { PlaceholderPersonMenuType } from "./ContextMenu"
import { Placeholder } from "./PlannerLeftColumn"

type Props = {
  disabled?: boolean
  placeholder: PlaceholderWorkflowMenu_placeholder$key
  availableMenuItems: Pick<
    PlaceholderPersonMenuType,
    "canComment" | "canRequest"
  >
  /**
   * @deprecated define data requirements in the fragment
   */
  placeholderLegacy: {
    id: number
    roleName: string
    personRequest: Placeholder["person_requests"][0] | null
    notes: Placeholder["people_notes"]
  }
  project: { id: number; name: string; is_template: boolean }
  closeMenu: () => void
  account: PlaceholderWorkflowMenu_account$key
}

export const getRequestItemName = (
  personRequestStatus: STATUSES | null,
): string => {
  return match({ personRequestStatus })
    .with({ personRequestStatus: STATUSES.REQUESTED }, () => "Request Raised")
    .with({ personRequestStatus: STATUSES.NEED_TO_HIRE }, () => "Hire Proposed")
    .with({ personRequestStatus: STATUSES.PENDING }, () => "Request Responded")
    .otherwise(() => "Request")
}

// TODO(permissions): Add granular permission for placeholder workflow menu
const PlaceholderWorkflowMenu = (props: Props) => {
  const {
    disabled,
    placeholderLegacy: { roleName, personRequest, notes },
    project,
    closeMenu,
    availableMenuItems,
  } = props

  const placeholder = useFragment(
    graphql`
      fragment PlaceholderWorkflowMenu_placeholder on people {
        id
        ...PlaceholderCommentModal_placeholder
        ...PlaceholderSidePanel_placeholder
      }
    `,
    props.placeholder,
  )

  const account = useFragment<PlaceholderWorkflowMenu_account$key>(
    graphql`
      fragment PlaceholderWorkflowMenu_account on accounts {
        id
        ...PlaceholderSidePanel_account
      }
    `,
    props.account,
  )

  const personRequestStatus = personRequest?.status || null

  const { openPanel } = useSidePanel()

  const requestItemName = getRequestItemName(personRequestStatus)

  const allMenuItems = getResourceMenuItems(roleName)
  const menuItems = [
    {
      ...allMenuItems.PENDING,
      hidden: !personRequestStatus || personRequestStatus === STATUSES.PENDING,
    },
    {
      ...allMenuItems.REQUESTED,
      hidden: personRequestStatus === STATUSES.REQUESTED,
    },
    {
      ...allMenuItems.NEED_TO_HIRE,
      hidden: personRequestStatus === STATUSES.NEED_TO_HIRE,
    },
    {
      ...allMenuItems.CANCEL,
      hidden: !personRequestStatus,
    },
  ]

  const commentCount = notes?.length ? `(${notes.length})` : ""

  const handleMenuItemClick = (menuType: STATUSES | "COMMENT") => {
    if (menuType === "COMMENT") {
      track("Placeholder Context Menu Item Selected", {
        item: "Comment",
      })
      track("Placeholder Side Panel Opened")

      openPanel(
        <PlaceholderSidePanel
          placeholder={placeholder}
          closePanel={closeMenu}
          account={account}
          project={project}
          initialTab="comments"
        />,
      )
    } else {
      const selectedMenuItem = menuItems.find(
        (item) => item.status === menuType,
      )
      track("Placeholder Context Menu Item Selected", {
        item: selectedMenuItem.text,
      })
      track("Placeholder Side Panel Opened")
      openPanel(
        <PlaceholderSidePanel
          placeholder={placeholder}
          closePanel={closeMenu}
          account={account}
          project={project}
          requestStatus={selectedMenuItem.status}
        />,
      )
    }
  }

  return (
    <>
      {availableMenuItems.canComment && (
        <>
          <MenuItem
            text={<>Comments {commentCount}</>}
            icon={<Icon icon="comment" />}
            onClick={() => handleMenuItemClick("COMMENT")}
            shouldDismissPopover
            disabled={disabled}
          />
          <MenuDivider />
        </>
      )}
      {availableMenuItems.canRequest && (
        <MenuItem
          text={requestItemName}
          icon={<Icon icon="person" />}
          disabled={disabled}
          style={{ fontWeight: personRequestStatus && 700 }}
          data-test="workflow-menu"
        >
          {menuItems.map((item) => {
            const icon = item.icon as IconName
            const status = item.status
            return (
              <MenuItem
                text={item.text}
                icon={<Icon icon={icon} />}
                onClick={() => handleMenuItemClick(status)}
                shouldDismissPopover
                key={item.text}
                hidden={item.hidden}
              />
            )
          })}
        </MenuItem>
      )}
    </>
  )
}

export default PlaceholderWorkflowMenu
