import { useFeature } from "flagged"
import { P, match } from "ts-pattern"

import { sortAlphaNumeric } from "~/helpers/sorting-helpers"

import { GroupOption } from "~/common/PageControls/GroupControl"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import { useEntitlements } from "~/Entitlements/useEntitlements"
import { checkSwitchOrQuantityAsBoolean } from "~/Entitlements/utils"
import { PRICING_MODELS } from "~/GLOBALVARS"

import { getCurrentContract } from "./person"

type Person = {
  id: number
  is_favourite: boolean
  first_name: string
  last_name: string
  is_placeholder: boolean
  team?: {
    name: string
  }
  contracts?: ReadonlyArray<{
    employment_type: string
    start_date: string
    end_date: string
    role: {
      name: string
      active: boolean
    }
  }>
  competencies: ReadonlyArray<{
    skill: {
      id: number
      name: string
    }
  }>
  tags: readonly string[]
  project_memberships: ReadonlyArray<{
    project_id: number
    project: {
      name: string
    }
    workstream_id: number
    workstream: {
      name: string
    }
  }>
  custom_select_values: ReadonlyArray<{ optionId: number; typeId: number }>
}

type Project = {
  id: number
  is_favourite: boolean
  name: string
  confirmed: boolean
  pricing_model_readable: string
  client: {
    name: string
  }
  team: {
    name: string
  }
  tags_computed: readonly string[]
  workstreams_computed: readonly string[]
  custom_select_values: ReadonlyArray<{ optionId: number; typeId: number }>
}

export type GroupableCustomField<T extends "PERSON" | "PROJECT"> = {
  id: number
  name: string
  model: T
  options: ReadonlyArray<{
    id: number
    name: string
  }>
}

type CustomFieldConsumer = {
  custom_select_values: ReadonlyArray<{ optionId: number; typeId: number }>
}

export type Group<P> = {
  groupName: string
  items: readonly P[]
}

const sortGroupsByName = <G extends Group<unknown>>(groups: G[]): G[] =>
  groups.sort((a, b) =>
    a.groupName.localeCompare(b.groupName, "en", { numeric: true }),
  )

const peopleAndPlaceholdersInGroupType = [
  "role",
  "team",
  "tags",
  "skills",
  "projects",
]

export const allPlaceholdersGroupName = "Placeholders"

export type ProjectType = "project" | "project template"

export type ProjectGroupByOption =
  | "default"
  | "status"
  | "client"
  | "team"
  | "pricingModel"
  | "tags"
  | "workstreams"
  | `custom_${number}`

const projectGroupByOptions: {
  value: ProjectGroupByOption
  label: string
}[] = [
  { value: "default", label: "All" },
  { value: "status", label: "Status" },
  { value: "client", label: "Client" },
  { value: "team", label: "Primary Team" },
  { value: "pricingModel", label: "Pricing Model" },
  { value: "tags", label: "Tags" },
]

export type PeopleGroupByOption =
  | "default"
  | "role"
  | "team"
  | "relationship"
  | "skills"
  | "tags"
  | "projects"
  | "workstreams"
  | `custom_${number}`

const peopleGroupByOptions: {
  value: PeopleGroupByOption
  label: string
}[] = [
  { value: "default", label: "All" },
  { value: "role", label: "Default Role" },
  { value: "team", label: "Team" },
  { value: "relationship", label: "Employment Type" },
  { value: "skills", label: "Skills" },
  { value: "tags", label: "Tags" },
  { value: "projects", label: "Projects" },
]

const getCustomGroupByOptions = <T extends "PERSON" | "PROJECT">(
  model: T,
  customFields: ReadonlyArray<GroupableCustomField<T>>,
) => {
  return customFields
    .filter((f) => f.model === model)
    .map((f) => ({
      value: `custom_${f.id}`,
      label: f.name,
    }))
    .sort((a, b) => sortAlphaNumeric(a.label, b.label))
}
export const getPeopleGroupByOptions = (
  customFields: ReadonlyArray<GroupableCustomField<"PERSON">> = [],
  workstreamsEnabled?: boolean,
): GroupOption[] => [
  ...peopleGroupByOptions,
  ...(workstreamsEnabled
    ? [
        {
          value: "workstreams",
          label: "Workstreams",
          featureEntitlementId: ChargebeeFeatures.workstreams,
        },
      ]
    : []),
  ...getCustomGroupByOptions("PERSON", customFields),
]

export const getProjectGroupByOptions = (
  customFields: ReadonlyArray<GroupableCustomField<"PROJECT">> = [],
  workstreamsEnabled?: boolean,
): GroupOption[] => [
  ...projectGroupByOptions,
  ...(workstreamsEnabled
    ? [
        {
          value: "workstreams",
          label: "Workstreams",
          featureEntitlementId: ChargebeeFeatures.workstreams,
        },
      ]
    : []),
  ...getCustomGroupByOptions("PROJECT", customFields),
]

export const useGroupByEntitlement = (
  options: GroupOption[],
): { entitledOptions: GroupOption[]; unentitledOptions: GroupOption[] } => {
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitlements = useEntitlements()

  // Entitled to all when entitlements are disabled
  if (!entitlementsEnabled) {
    return { entitledOptions: options, unentitledOptions: [] }
  }

  const groupedEntitlements = options.reduce<{
    entitledOptions: GroupOption[]
    unentitledOptions: GroupOption[]
  }>(
    (acc, option) => {
      // Always entitled to features with no featureEntitlementId
      if (!option.featureEntitlementId) {
        acc.entitledOptions.push(option)
        return acc
      }

      checkSwitchOrQuantityAsBoolean(entitlements, option.featureEntitlementId)
        ? acc.entitledOptions.push(option)
        : acc.unentitledOptions.push(option)

      return acc
    },
    { entitledOptions: [], unentitledOptions: [] },
  )

  // XXX: Manually remove workstreams from unentitled options when grouping. We don't want to show this as an option in our upsells
  groupedEntitlements.unentitledOptions =
    groupedEntitlements.unentitledOptions.filter(
      (option) => option.featureEntitlementId !== ChargebeeFeatures.workstreams,
    )

  return groupedEntitlements
}

export const groupItemsAll = <I>(items: readonly I[]): Group<I>[] => {
  return [
    {
      groupName: "All",
      items,
    },
  ]
}

type GroupProjectsByStatusOptions = {
  includeTentative?: boolean
}

export const groupProjectsByStatus = <P extends Project>(
  projects: readonly P[],
  options: GroupProjectsByStatusOptions = {},
) => {
  const { includeTentative = true } = options
  const groups = includeTentative ? ["Confirmed", "Tentative"] : ["Confirmed"]

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter((p) => {
      const status = p.confirmed ? "Confirmed" : "Tentative"
      return status === group
    })
    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return groupedProjects
}

const groupProjectsByClient = <P extends Project>(
  projects: readonly P[],
): Group<P>[] => {
  // either get clients this way, or just pull clients from graphql
  // have done it this way, so that only gets clients in view vs all clients.
  const clients = projects.map((p) => p.client.name)
  const groups = [...new Set(clients)]

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter((p) => p.client.name === group)
    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return sortGroupsByName(groupedProjects)
}

const groupProjectsByTeam = <P extends Project>(
  projects: readonly P[],
): Group<P>[] => {
  const projectsWithTeam = projects.filter((p) => p.team)
  const teams = projectsWithTeam.map((project) => project.team.name)
  const groups = [...new Set(teams)]

  const noTeamProjects = projects.filter((project) => !project.team)

  const unTaggedProjectsGroup = {
    groupName: "No Team",
    items: noTeamProjects,
  }

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter(
      (p) => p.team && p.team.name === group,
    )
    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return [...sortGroupsByName(groupedProjects), unTaggedProjectsGroup]
}

const groupProjectsByPricingModel = <P extends Project>(
  projects: readonly P[],
): Group<P>[] => {
  const groups = PRICING_MODELS.map((model) => model.label)

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter(
      (p) => p.pricing_model_readable === group,
    )

    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return groupedProjects
}

const groupProjectsByTags = <P extends Project>(
  projects: readonly P[],
): Group<P>[] => {
  const tags = projects.flatMap((project) => project.tags_computed)
  const groups = [...new Set(tags)]

  const unTaggedProjects = projects.filter(
    (project) => project.tags_computed.length === 0,
  )

  const unTaggedProjectsGroup = {
    groupName: "No Tags",
    items: unTaggedProjects,
  }

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter((p) =>
      p.tags_computed.includes(group),
    )
    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return [...sortGroupsByName(groupedProjects), unTaggedProjectsGroup].filter(
    (g) => g.items.length,
  )
}

const groupProjectsByWorkstreams = <P extends Project>(
  projects: readonly P[],
): Group<P>[] => {
  const workstreams = projects.flatMap(
    (project) => project.workstreams_computed,
  )
  const groups = [...new Set(workstreams)]

  const noWorkstreamProjects = projects.filter(
    (project) => project.workstreams_computed.length === 0,
  )

  const noWorkstreamProjectsGroup = {
    groupName: "No Workstreams",
    items: noWorkstreamProjects,
  }

  const groupedProjects = groups.map((group) => {
    const filteredProjects = projects.filter((p) =>
      p.workstreams_computed.includes(group),
    )
    return {
      groupName: group,
      items: filteredProjects,
    }
  })

  return [
    ...sortGroupsByName(groupedProjects),
    noWorkstreamProjectsGroup,
  ].filter((g) => g.items.length)
}

const groupByCustomField = <P extends CustomFieldConsumer>(
  items: ReadonlyArray<P>,
  identifier: `custom_${number}`,
  customFields: ReadonlyArray<GroupableCustomField<"PERSON" | "PROJECT">>,
) => {
  const typeId = identifier.split("_")[1]
  const customField = customFields.find((f) => f.id === Number(typeId))
  if (!customField) {
    return []
  }
  const groupedItems = customField.options.map((option) => {
    const filteredItems = items.filter((p) =>
      p.custom_select_values.some(
        (f) => f.typeId === customField.id && f.optionId === option.id,
      ),
    )
    return {
      groupName: option.name,
      items: filteredItems,
    }
  })

  const sortedGroups = [...sortGroupsByName(groupedItems)].filter(
    (g) => g.items.length,
  )
  const noMatchItems = items.filter(
    (item) =>
      !item.custom_select_values.some((f) => f.typeId === customField.id),
  )

  if (noMatchItems.length) {
    sortedGroups.push({
      groupName: "(No value)",
      items: noMatchItems,
    })
  }

  return sortedGroups
}

export const groupProjectsBy = <P extends Project>(
  projects: readonly P[],
  groupByOption: ProjectGroupByOption,
  customFields: ReadonlyArray<GroupableCustomField<"PROJECT">> = [],
): Group<P>[] => {
  return match(groupByOption)
    .with("default", () => groupItemsAll(projects))
    .with("status", () => groupProjectsByStatus(projects))
    .with("client", () => groupProjectsByClient(projects))
    .with("team", () => groupProjectsByTeam(projects))
    .with("pricingModel", () => groupProjectsByPricingModel(projects))
    .with("tags", () => groupProjectsByTags(projects))
    .with("workstreams", () => groupProjectsByWorkstreams(projects))
    .with(
      P.when((id) => id.startsWith("custom_")),
      (customName) => {
        const grouping = groupByCustomField(projects, customName, customFields)
        if (grouping.length) {
          return grouping
        } else {
          // If no group is returned because custom field isn't found
          return groupItemsAll(projects)
        }
      },
    )
    .otherwise(() => groupItemsAll(projects))
}

const groupPeopleByAll = <P extends Person>(
  people: readonly P[],
): Group<P>[] => {
  const peopleOnly = people.filter((p) => !p.is_placeholder)
  const peopleGroup = {
    groupName: "People",
    items: peopleOnly,
  }

  const placeholders = people.filter((p) => p.is_placeholder)
  const placeholderGroup = {
    groupName: allPlaceholdersGroupName,
    items: placeholders,
  }

  return [placeholderGroup, peopleGroup].filter((g) => g.items.length)
}

const groupPeopleByRelationship = <P extends Person>(
  people: readonly P[],
): Group<P>[] => {
  const groups = ["Employee", "Contractor"]
  const peopleWithContracts = people.filter(
    (p) => getCurrentContract(p.contracts) && p.is_placeholder === false,
  )

  const noContractPeople = {
    groupName: "No Contract",
    items: people.filter((p) => !getCurrentContract(p.contracts)),
  }

  const groupedPeople = groups.map((group) => {
    const filteredPeople = peopleWithContracts.filter(
      (p) =>
        getCurrentContract(p.contracts).employment_type.toLowerCase() ===
        group.toLowerCase(),
    )

    return {
      groupName: group,
      items: filteredPeople,
    }
  })
  const placeholders = people.filter((p) => p.is_placeholder)
  const placeholderGroup = {
    groupName: allPlaceholdersGroupName,
    items: placeholders,
  }

  return [placeholderGroup, ...groupedPeople, noContractPeople].filter(
    (g) => g.items.length,
  )
}

const groupPeopleByTeam = <P extends Person>(
  people: readonly P[],
): Group<P>[] => {
  const peopleWithTeams = people.filter((p) => p.team)
  const teams = people.filter((p) => p.team).map((p) => p.team.name)
  const groups = [...new Set(teams)]

  const noTeamPeople = {
    groupName: "No Team",
    items: people.filter((p) => !p.team),
  }

  const groupedPeople = sortGroupsByName(
    groups.map((group) => {
      const filteredPeople = peopleWithTeams.filter(
        (p) => p.team.name === group,
      )

      return {
        groupName: group,
        items: filteredPeople,
      }
    }),
  )

  return [...sortGroupsByName(groupedPeople), noTeamPeople].filter(
    (g) => g.items.length,
  )
}

const groupPeopleByRole = <P extends Person>(
  people: readonly P[],
): Group<P>[] => {
  // either get roles this way, or just pull roles from graphql
  // have done it this way, so that only gets roles in view vs all roles.
  const peopleWithRole = people.map((p) => ({
    ...p,
    role: getCurrentContract(p.contracts)?.role ?? null,
  }))

  const roles = peopleWithRole
    .filter((p) => p.role?.active)
    .map((pr) => pr.role.name)
  const groups = [...new Set(roles)]

  const noRolePeople = {
    groupName: "No Role",
    items: peopleWithRole.filter((p) => !p.role),
  }

  const archivedRolePeople = {
    groupName: "Archived Roles",
    items: peopleWithRole.filter((p) => p.role?.active === false),
  }

  const groupedPeople = groups.map((group) => {
    const filteredPeople = peopleWithRole.filter((p) => p.role?.name === group)

    return {
      groupName: group,
      items: filteredPeople,
    }
  })

  return [
    ...sortGroupsByName(groupedPeople),
    archivedRolePeople,
    noRolePeople,
  ].filter((g) => g.items.length)
}

const groupPeopleByTags = <T extends Person>(people: readonly T[]) => {
  const tags = people.flatMap((person) => person.tags)
  const groups = [...new Set(tags)]

  const unTaggedPeople = people.filter((p) => p.tags.length === 0)

  const unTaggedPeopleGroup = {
    groupName: "No Tags",
    items: unTaggedPeople,
  }

  const groupedPeople = groups.map((group) => {
    const filteredPeople = people.filter((p) => p.tags.includes(group))
    return {
      groupName: group,
      items: filteredPeople,
    }
  })

  return [...sortGroupsByName(groupedPeople), unTaggedPeopleGroup].filter(
    (g) => g.items.length,
  )
}

const groupPeopleBySkills = <T extends Person>(people: readonly T[]) => {
  const skills = people.flatMap((person) =>
    person.competencies.map((c) => c.skill.name),
  )
  const groups = [...new Set(skills)]

  const peopleWithNoSkills = people.filter((p) => p.competencies.length === 0)

  const noSkillsPeopleGroup = {
    groupName: "No Skills",
    items: peopleWithNoSkills,
  }

  const groupedPeople = groups.map((group) => {
    const filteredPeople = people.filter((p) =>
      p.competencies.some((c) => c.skill.name === group),
    )
    return {
      groupName: group,
      items: filteredPeople,
    }
  })

  return [...sortGroupsByName(groupedPeople), noSkillsPeopleGroup].filter(
    (g) => g.items.length,
  )
}

const groupPeopleByProjects = <T extends Person>(
  people: readonly T[],
  visibleProjectIds: number[],
) => {
  const projects = people.flatMap((person) =>
    person.project_memberships
      .filter((pm) => visibleProjectIds.includes(pm.project_id))
      .map((p) => p.project.name),
  )

  const projectGroups = [...new Set(projects)]

  const peopleWithNoProjects = people.filter(
    (p) => p.project_memberships.length === 0,
  )

  const noProjectsPeopleGroup = {
    groupName: "No Projects",
    items: peopleWithNoProjects,
  }

  const groupedPeople = projectGroups.map((group) => {
    const filteredPeople = people.filter((p) =>
      p.project_memberships.some((pm) => pm.project.name === group),
    )
    return {
      groupName: group,
      items: filteredPeople,
    }
  })

  return [...sortGroupsByName(groupedPeople), noProjectsPeopleGroup].filter(
    (g) => g.items.length,
  )
}

const groupPeopleByWorkstreams = <T extends Person>(
  people: readonly T[],
  visibleProjectIds: number[],
) => {
  const peopleWithNoWorkstreams: T[] = []
  const peopleWithWorkstreams: T[] = []

  people.forEach((person) => {
    if (
      person.project_memberships.every(
        (pm) =>
          pm.workstream_id === null ||
          !visibleProjectIds.includes(pm.project_id),
      )
    ) {
      peopleWithNoWorkstreams.push(person)
    } else {
      peopleWithWorkstreams.push(person)
    }
  })

  const workstreams = peopleWithWorkstreams.flatMap((person) =>
    person.project_memberships
      .filter((pm) => pm.workstream_id !== null)
      .map((p) => p.workstream.name),
  )

  const workstreamGroups = [...new Set(workstreams)]

  const noWorkstreamsPeopleGroup = {
    groupName: "No Workstreams",
    items: peopleWithNoWorkstreams,
  }

  const groupedPeople = workstreamGroups.map((group) => {
    const filteredPeople = peopleWithWorkstreams.filter((p) =>
      p.project_memberships.some(
        (pm) =>
          pm.workstream?.name === group &&
          visibleProjectIds.includes(pm.project_id),
      ),
    )
    return {
      groupName: group,
      items: filteredPeople,
    }
  })

  return [...sortGroupsByName(groupedPeople), noWorkstreamsPeopleGroup].filter(
    (g) => g.items.length,
  )
}

export const groupPeopleBy = <P extends Person>(
  people: readonly P[],
  groupByOption: PeopleGroupByOption,
  visibleProjectIds: number[],
  customFields: ReadonlyArray<GroupableCustomField<"PERSON">> = [],
): Group<P>[] => {
  return match(groupByOption)
    .with("default", () => groupPeopleByAll(people))
    .with("role", () => groupPeopleByRole(people))
    .with("team", () => groupPeopleByTeam(people))
    .with("relationship", () => groupPeopleByRelationship(people))
    .with("skills", () => groupPeopleBySkills(people))
    .with("tags", () => groupPeopleByTags(people))
    .with("projects", () => groupPeopleByProjects(people, visibleProjectIds))
    .with("workstreams", () =>
      groupPeopleByWorkstreams(people, visibleProjectIds),
    )
    .with(
      P.when((id) => id.startsWith("custom_")),
      (customName) => {
        const grouping = groupByCustomField(people, customName, customFields)
        if (grouping.length) {
          return grouping
        } else {
          // If no group is returned because custom field isn't found
          return groupPeopleByAll(people)
        }
      },
    )
    .otherwise(() => groupPeopleByAll(people))
}

// Showing both people and placeholder under a group
export const showPeopleAndPlaceholdersInGroup = (groupName) =>
  peopleAndPlaceholdersInGroupType.includes(groupName)
