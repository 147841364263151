import React from "react"

import Legend from "~/Planner/ChartPanel/Chart/common/Legend"

const LegendAvailability = () => {
  return (
    <Legend>
      <Legend.Item background="var(--mint)" square>
        Available
      </Legend.Item>
      <Legend.Item background="var(--alert)" square>
        Overbooked
      </Legend.Item>
    </Legend>
  )
}

export { LegendAvailability }
