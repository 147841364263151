import React, { useEffect, useMemo, useState } from "react"
import { graphql, useSubscription } from "react-relay"

import styles from "./LoadingSeedDataModal.module.css"

import { LoadingSeedDataModalSubscription } from "./__generated__/LoadingSeedDataModalSubscription.graphql"

import Tangram, { balance, handstand, march } from "~/common/Tangram"

import { blockPage, unblockPage } from "~/containers/RunnApp"
import { showToast } from "~/containers/ToasterContainer"

const COPY_STATUS_SUBSCRIPTION = graphql`
  subscription LoadingSeedDataModalSubscription {
    current_account {
      id
      copy_status
      copy_started_at
    }
  }
`

const LoadingSeedDataModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState(
    "Loading your personalised demo...",
  )

  useSubscription<LoadingSeedDataModalSubscription>(
    useMemo(
      () => ({
        subscription: COPY_STATUS_SUBSCRIPTION,
        variables: {},
        onNext: (data) => {
          const copyStartedAt = data.current_account?.copy_started_at
          const copyStatus = data.current_account?.copy_status
          if (
            !showModal &&
            copyStatus === "in_progress" &&
            copyStartedAt &&
            copyStartedAt - Date.now() / 1000 < 180
          ) {
            setModalText("Copying data from your Live Account...")
            setShowModal(true)
            blockPage()
          } else if (showModal) {
            // assume copy is successful if copy_status is null
            if (!copyStatus) {
              window.location.reload()
            } else if (
              copyStatus === "failed" ||
              // incase the node container gets killed and we miss the copy_status update
              (copyStartedAt && copyStartedAt - Date.now() / 1000 > 180)
            ) {
              unblockPage()
              setShowModal(false)
              showToast({
                message:
                  "Failed to copy account data. Please try again or contact support if the issue persists.",
              })
            }
          }
        },
      }),
      [showModal],
    ),
  )

  useEffect(() => {
    // When we get a loading request from the server. Usually to reload demo data.

    // @ts-ignore
    App.workers_status = App.cable.subscriptions.create(
      {
        channel: "LoadingDataChannel",
      },
      {
        connected: () => {
          // connect to rails
        },
        received: (data) => {
          if (data.message) {
            setModalText(data.message)
          }
          blockPage()
          setShowModal(true)
        },
      },
    )

    return () => {
      // @ts-ignore
      App.workers_status.unsubscribe()
    }
  }, [])

  if (!showModal) {
    return null
  }

  return (
    <div className={styles.container} data-test="loading-seed-modal">
      <div className={styles.messageBox}>
        <Tangram
          tangrams={[balance, handstand, march]}
          initialTangramIndex={0}
          intervalMs={3000}
          className="heartbeat"
          style={{ width: 60 }}
        />

        <div>
          <div className={styles.textBold}>{modalText}</div>
          <div> Breathe... Stretch... Touch your toes </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingSeedDataModal
