import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PersonSidePanel.module.css"

import { PersonSidePanelDetails_account$key } from "./__generated__/PersonSidePanelDetails_account.graphql"
import { PersonSidePanelDetails_person$key } from "./__generated__/PersonSidePanelDetails_person.graphql"

import {
  hasCustomFieldValues,
  typesMap,
  valuesMap,
} from "~/helpers/custom-field-helpers"
import routes from "~/helpers/routes"
import { References } from "~/helpers/unknown-types"

import SidePanelCustomFields from "~/common/SidePanel/SidePanelCustomFields"
import SidePanelReferences from "~/common/SidePanel/SidePanelReferences"

import SkillLabel from "~/Skills/SkillsLabelsList/SkillLabel"
import TagList from "~/dashboard/Detail/DashboardDetailSelectItems"

type Props = {
  person: PersonSidePanelDetails_person$key
  account: PersonSidePanelDetails_account$key
}

const Details = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonSidePanelDetails_person on people {
        id
        first_name
        last_name
        email
        tags
        references
        holidays_group {
          id
          name
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        team {
          id
          name
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        links {
          id
          href
          name
          show_in_planner
        }
      }
    `,
    props.person,
  )

  const account = useFragment(
    graphql`
      fragment PersonSidePanelDetails_account on accounts {
        id
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          description
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
      }
    `,
    props.account,
  )

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
    custom_date_types: account.custom_date_types_person,
  })
  const customFieldValues = valuesMap(person)

  const competencies = (person.competencies || []).map((competency) => ({
    ...competency,
    person: {
      id: person.id,
      first_name: person.first_name,
      last_name: person.last_name,
    },
    person_id: person.id,
  }))

  const references = person.references as References

  const hasCustomFields = hasCustomFieldValues(customFieldValues)
  const hasReferences = Boolean(Object.keys(references).length)
  const hasTags = Boolean(person.tags.length)
  const hasHolidayGroup = Boolean(person.holidays_group)
  const hasCompetencies = Boolean(competencies.length)
  const hasEmail = Boolean(person.email)
  const hasLinks = Boolean(person.links.length)

  const hasDetails =
    hasEmail ||
    hasCompetencies ||
    hasTags ||
    hasHolidayGroup ||
    hasCustomFields ||
    hasLinks ||
    hasReferences

  return (
    <>
      {hasDetails ? (
        <div className={styles.detailsContainer}>
          {hasEmail && (
            <>
              <h4>Email</h4>
              <a
                className={styles.detailsValue}
                href={`mailto:${person.email}`}
              >
                {person.email}
              </a>
            </>
          )}
          {hasCompetencies && (
            <>
              <h4>Skills</h4>
              <div className={styles.skillsContainer}>
                {competencies.map((competency) => (
                  <SkillLabel
                    key={competency.id}
                    competency={competency}
                    canEdit={false}
                    style={{ marginTop: 0 }}
                  />
                ))}
              </div>
            </>
          )}
          {hasTags && (
            <>
              <h4>People Tags</h4>
              <div className={styles.tagsContainer}>
                <TagList
                  href={routes.tagsListUrl()}
                  selectValues={person.tags}
                />
              </div>
            </>
          )}
          {hasHolidayGroup && (
            <>
              <h4>Public Holidays</h4>
              <p className={styles.detailsValue}>
                {person.holidays_group.name}
              </p>
            </>
          )}
          <SidePanelCustomFields
            customFieldTypes={customFieldTypes}
            customFieldValues={customFieldValues}
          />
          {hasLinks &&
            person.links.map((link) => (
              <div key={link.id}>
                <h4>{link.name}</h4>
                <a
                  className={styles.detailsValue}
                  href={link.href}
                  target="_blank"
                  rel="noopener"
                >
                  {link.href}
                </a>
              </div>
            ))}
          {hasReferences && <SidePanelReferences references={references} />}
        </div>
      ) : (
        <div className={styles.empty}>No details</div>
      )}
    </>
  )
}

export default Details
