import { Tooltip } from "@blueprintjs/core"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetLine.module.css"

import { ProjectBudgetHours_project$key } from "~/ProjectPlanner/ProjectBudgetLine/__generated__/ProjectBudgetHours_project.graphql"

import { formatMinuteToHours } from "~/helpers/time"

type Props = {
  isBillable: boolean
  project: ProjectBudgetHours_project$key
}

const ProjectBudgetHours = (props: Props) => {
  const { isBillable } = props

  const project = useFragment(
    graphql`
      fragment ProjectBudgetHours_project on projects {
        id
        summary {
          role_id
          total_billable_minutes
          total_nonbillable_minutes
        }
        project_roles {
          id
          role_id
          estimated_minutes
        }
      }
    `,
    props.project,
  )

  const budgetMinutes = project.project_roles.reduce(
    (acc, pr) => (pr.estimated_minutes ?? 0) + acc,
    0,
  )
  const billableMinutes = project.summary.reduce(
    (acc, s) => (s.total_billable_minutes ?? 0) + acc,
    0,
  )
  const nonbillableMinutes = project.summary.reduce(
    (acc, s) => (s.total_nonbillable_minutes ?? 0) + acc,
    0,
  )

  const minutes = isBillable ? billableMinutes : nonbillableMinutes
  const timeBudgetUsed = (minutes / budgetMinutes) * 100
  const budgetUsedPercentage = timeBudgetUsed > 100 ? 100 : timeBudgetUsed
  const overBudgetPercentage = timeBudgetUsed > 100 ? timeBudgetUsed - 100 : 0
  const overBudgetPercentageFixed =
    overBudgetPercentage > 100 ? 100 : overBudgetPercentage

  const TooltipContent = () => (
    <div>
      <b>{isBillable ? "Billable" : "Non-billable"} Hours</b>
      <br />
      Actual + Scheduled: {formatMinuteToHours(minutes)}
      {!!budgetMinutes && (
        <>
          <br />
          Budget: {formatMinuteToHours(budgetMinutes)}
        </>
      )}
      {!!budgetMinutes && (
        <>
          <br />
          Budget Allocated: {timeBudgetUsed.toFixed(2)}%
        </>
      )}
    </div>
  )

  return (
    <>
      <div className={styles.lineContainer}>
        {!!budgetMinutes && (
          <Tooltip content={<TooltipContent />} placement="top">
            <div className={styles.budgetLine}>
              <div
                className={styles.budgetUsedLine}
                style={{ width: `${budgetUsedPercentage}%` }}
              ></div>
              <div
                className={styles.budgetOverLine}
                style={{ width: `${overBudgetPercentageFixed}%` }}
              ></div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.textContainer}>
        <Tooltip content={<TooltipContent />}>
          <span className={styles.text}>
            <span
              className={`${timeBudgetUsed > 100 ? styles.overbudgetText : ""}`}
            >
              {formatMinuteToHours(minutes)}
            </span>
            {!!budgetMinutes && <> / {formatMinuteToHours(budgetMinutes)}</>}
          </span>
        </Tooltip>
      </div>
    </>
  )
}

export default ProjectBudgetHours
