import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./TagList.module.css"

type Props = {
  tags: ReadonlyArray<string>
  children: React.ReactNode
}

const TagTooltip = (props: { tags: readonly string[] }) => (
  <div className={styles.popoverContainer}>
    <span className={styles.tagTitle}>Tags:</span>
    <div className={styles.tagsContainer}>
      {props.tags.map((tag, idx) => (
        <div className={styles.tag} key={`${tag}-${idx}`}>
          {tag}
        </div>
      ))}
    </div>
  </div>
)

const TagsTooltip = (props: Props) => {
  const { tags, children } = props

  if (tags.length === 0) {
    return null
  }

  return (
    <Tooltip lazy content={<TagTooltip tags={tags} />}>
      {children}
    </Tooltip>
  )
}

export default TagsTooltip
