import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./TimeOffSummaryAddRow.module.css"

import { TimeOffSummaryAddRow_account$key } from "./__generated__/TimeOffSummaryAddRow_account.graphql"
import { TimeOffSummary_account$data } from "./__generated__/TimeOffSummary_account.graphql"

import { track } from "~/helpers/analytics"
import routes from "~/helpers/routes"

import AddButton from "~/common/buttons/AddButton"

import { usePermissions } from "~/Permissions/usePermissions"
import { PlannerLeftColumn } from "~/Planner/PlannerLayout"

import AddTimeOffModal from "./AddTimeOffModal"

type Props = {
  account: TimeOffSummaryAddRow_account$key
  people: TimeOffSummary_account$data["people"]
}

const TimeOffSummaryAddRow = (props: Props) => {
  const { people } = props

  const account = useFragment(
    graphql`
      fragment TimeOffSummaryAddRow_account on accounts {
        ...AddTimeOffModal_account
      }
    `,
    props.account,
  )
  const [showModal, setShowModal] = useState(false)

  const { somePermissionExistsFor } = usePermissions()
  const canCreate = somePermissionExistsFor("create", "TimeOff")
  if (!canCreate) {
    return null
  }

  const handleNewScheduledLeave = () => {
    track("New Scheduled Leave Button in Planner Opened")
    setShowModal(true)
  }

  return (
    <>
      <PlannerLeftColumn isCallToAction>
        <div className={styles.buttons}>
          <AddButton
            text="New Scheduled Leave"
            data-test="assign-person-button"
            onClick={handleNewScheduledLeave}
            active={showModal}
          />
          <a
            href={routes.holidaysGroupListUrl()}
            target="_blank"
            className={styles.holidaysLink}
            rel="noopener"
            onClick={() => track("Public Holidays Planner Clicked")}
          >
            Public Holidays
          </a>
        </div>
        <AddTimeOffModal
          account={account}
          showModal={showModal}
          setShowModal={setShowModal}
          people={people}
        />
      </PlannerLeftColumn>
      <div className={styles.empty} />
    </>
  )
}

export default TimeOffSummaryAddRow
