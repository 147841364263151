import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import * as fe from "@runn/filter-engine"

import { AllowedProjectFilterSet } from "~/helpers/filter-engine"

import { getSettings, setSetting } from "~/localsettings"

type PlannerProjectFilterState = {
  search: string
  filterSet: AllowedProjectFilterSet
}

const getInitialState = (): PlannerProjectFilterState => {
  const settings = getSettings()
  return {
    search: settings.projectWildSearch ?? "",
    filterSet: settings.projectFilterSet ?? {
      name: "All Projects",
      filters: null,
    },
  }
}

const plannerProjectFilter = createSlice({
  name: "plannerProjectFilter",
  initialState: getInitialState,
  reducers: {
    searchChanged: (state, action: PayloadAction<string>) => {
      // TODO remove set settings with persistance
      setSetting("projectWildSearch", action.payload)
      state.search = action.payload
    },
    searchCleared: (state) => {
      state.search = ""
      // TODO remove when persisted
      setSetting("projectWildSearch", "")
    },
    filteredByProject: (state, action: PayloadAction<number>) => {
      state.search = ""
      state.filterSet.name = ""
      state.filterSet.filters = fe.filters.projectId({ list: [action.payload] })

      // TODO remove when persisted
      setSetting("projectFilterSet", state.filterSet)
      setSetting("projectWildSearch", "")
    },
    filteredByTemplate: (state, action: PayloadAction<number>) => {
      state.search = ""
      state.filterSet.name = ""
      state.filterSet.filters = fe.filters.projectTemplateId({
        list: [action.payload],
      })

      // TODO remove when persisted
      setSetting("projectFilterSet", state.filterSet)
      setSetting("projectWildSearch", "")
    },
    filtersCleared: (state) => {
      state.filterSet.name = ""
      state.filterSet.filters = null
      // TODO remove when persisted
      setSetting("projectFilterSet", state.filterSet)
    },
    // TODO split into usages instead of simple setter
    setFilterSet: (state, action: PayloadAction<AllowedProjectFilterSet>) => {
      state.filterSet = action.payload
      // TODO remove when persisted
      setSetting("projectFilterSet", state.filterSet)
    },
  },
})

const { actions, reducer } = plannerProjectFilter

export const {
  searchChanged,
  searchCleared,
  filteredByProject,
  filteredByTemplate,
  filtersCleared,
  setFilterSet,
} = actions
export default reducer
