/**
 * @generated SignedSource<<9c8b6943e1381ad032a506f128429bde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageControls_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PeoplePlannerOptions_account" | "ProjectPlannerOptions_account" | "TentativeProjectsToggle_account">;
  readonly " $fragmentType": "PageControls_account";
};
export type PageControls_account$key = {
  readonly " $data"?: PageControls_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageControls_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageControls_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectPlannerOptions_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeoplePlannerOptions_account"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TentativeProjectsToggle_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "676d9c9b6e4cfc88d952c7cc82acc566";

export default node;
