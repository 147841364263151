/**
 * @generated SignedSource<<83991aff39451ee2cd13343f0f111dea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectMemberBulkCreateInput = {
  project_members: ReadonlyArray<ProjectMember>;
};
export type ProjectMember = {
  is_placeholder: boolean;
  person_id: number;
  project_id: number;
  role_id: number;
  workstream_id?: number | null | undefined;
};
export type ProjectMemberBulkCreateMyTimesheetsMutation$variables = {
  input: ProjectMemberBulkCreateInput;
};
export type ProjectMemberBulkCreateMyTimesheetsMutation$data = {
  readonly action_project_member_bulk_create: ReadonlyArray<{
    readonly id: number;
    readonly person: {
      readonly id: number;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly project: {
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly person_id: number;
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ProjectMemberBulkCreateMyTimesheetsMutation = {
  response: ProjectMemberBulkCreateMyTimesheetsMutation$data;
  variables: ProjectMemberBulkCreateMyTimesheetsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProjectMemberBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_project_member_bulk_create",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "project_memberships",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstream_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_placeholder",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectMemberBulkCreateMyTimesheetsMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectMemberBulkCreateMyTimesheetsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dce2b2074857945b95f3bea3a511bebd",
    "id": null,
    "metadata": {},
    "name": "ProjectMemberBulkCreateMyTimesheetsMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectMemberBulkCreateMyTimesheetsMutation(\n  $input: ProjectMemberBulkCreateInput!\n) {\n  action_project_member_bulk_create(input: $input) {\n    id\n    person {\n      id\n      project_memberships {\n        id\n      }\n    }\n    project {\n      id\n      members {\n        id\n        project_id\n        role_id\n        person_id\n        workstream_id\n        is_placeholder\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c34aae00fee8d0f1ed695c34d53ac215";

export default node;
