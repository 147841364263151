import { Icon, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { differenceInBusinessDays, isWeekend } from "date-fns"
import { getISODay } from "date-fns"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./MiniPicker.module.css"
import plannerDatePickerStyles from "./PlannerDatePicker.module.css"

import { MiniPicker_account$key } from "./__generated__/MiniPicker_account.graphql"

import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"
import { getCurrentContract } from "~/helpers/person"

import { EffortInput } from "~/common/EffortInput/EffortInput"
import NumericInput from "~/common/NumericInput"
import BlueLink from "~/common/buttons/BlueLink"
import Button from "~/common/buttons/Button"
import Checkbox from "~/common/inputs/Checkbox"

import { setHighlightedItemData as setHighlightedItemDataDispatch } from "~/GlobalState"

import EditAssignmentNote from "../EditAssignmentNote"
import { NonWorkingDayInput } from "../EffortInput/NonWorkingDayInput"
import { TotalEffortInput } from "../EffortInput/TotalEffortInput"
import { IconThreeDot } from "../IconThreeDot"
import CloneMenuItem from "../PillActions/MenuItems/CloneMenuItem"
import TransferMenuItem from "../PillActions/MenuItems/TransferMenuItem"
import { PopoverContextMenu } from "../PillActions/PillActions"

import ItemRepeat from "./ItemRepeat"
import PhaseSelector from "./PhaseSelector"

const MiniPicker = (props: Props) => {
  const {
    assignment,
    minutesPerDay,
    setMinutesPerDay,
    totalWorkingDays,
    selectedDates,
    handleCancel,
    onDelete,
    setShowFullCalendar,
    setHighlightedItemData,
    isPopoverOpen,
    handleSubmit,
    closePopover,
    isBillable,
    setIsBillable,
    editNote,
    itemNote,
    setRepeat,
    isNonBillableProject,
    currentPhase,
    setCurrentPhase,
    phases,
    person,
    onChangeWorkingDays,
    nonWorkingDay,
    contextMenu,
  } = props

  const account = useFragment(
    graphql`
      fragment MiniPicker_account on accounts {
        id
        default_full_time_minutes
        ...EffortInput_account
        ...TotalEffortInput_account
        ...NonWorkingDayInput_account
      }
    `,
    props.account,
  )

  const isWeekday = !nonWorkingDay

  const [hoursPerDayError, setHoursPerDayError] = useState(false)
  const [initialAssignment, setInitialAssignment] =
    useState<Assignment>(undefined)
  const [total, setTotal] = useState<{
    value: number
    label: string
  }>({
    value: totalWorkingDays.value * minutesPerDay,
    label: totalWorkingDays.label,
  })

  const [repeatOn, setRepeatOn] = useState(false)

  const isSaveDisabled = React.useMemo(() => {
    return Number.isNaN(minutesPerDay)
  }, [minutesPerDay])

  const businessDays =
    differenceInBusinessDays(selectedDates[1], selectedDates[0]) + 1

  const showRepeatCheckbox = businessDays < 21

  useEffect(() => {
    if (!showRepeatCheckbox) {
      setRepeatOn(false)
    }
  }, [showRepeatCheckbox])

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Esc
      closePopover()
    }

    if (e.keyCode === 13 && e.target && e.target.nodeName === "INPUT") {
      document.getElementById("save-assignment").focus()
    }
  }

  const handlePressEnter = (minutes) => {
    setMinutesPerDay(minutes)
  }

  const handleDelete = () => {
    if (assignment) {
      if (onDelete) {
        onDelete()
      }
      setHighlightedItemData(null)
    }
  }

  useEffect(() => {
    if (!repeatOn) {
      setRepeat(undefined)
    }
  }, [repeatOn]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      document.addEventListener("keydown", onKeyDown)
      setHighlightedItemData(assignment)
      setTotal({
        value: totalWorkingDays.value * minutesPerDay,
        label: totalWorkingDays.label,
      })

      if (!initialAssignment) {
        // set initial assignment before any changes are made
        setInitialAssignment(assignment)
      }

      return () => {
        if (hoursPerDayError) {
          handlePressEnter(minutesPerDay)
        }
        document.removeEventListener("keydown", onKeyDown)
        setHoursPerDayError(false)
        setHighlightedItemData(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPopoverOpen, totalWorkingDays, minutesPerDay],
  )

  const handleMinutesChange = (minutes, error) => {
    setMinutesPerDay(Math.round(minutes))
    setHoursPerDayError(error)
  }

  const handleShowFullCalendar = () => {
    setShowFullCalendar(true)
  }

  const handleBillableToggle = () => {
    setIsBillable(!isBillable)
  }

  const contract = getCurrentContract(person.contracts)

  const [showWeekendForm, setShowWeekendForm] = useState(false)

  const openWeekendForm = () => {
    setTotal({
      value: 1,
      label: "1",
    }) // can only do 1 day for weekend
    setShowWeekendForm(true)
  }

  if (nonWorkingDay && !showWeekendForm && !assignment) {
    return (
      <div data-test="schedule-confirm-container">
        <div className={styles.miniPicker}>
          <div className={styles.title}>
            Schedule on a{" "}
            {isWeekend(selectedDates[0]) ? "non-working day" : "public holiday"}
            ?
          </div>
        </div>
        <div
          className={styles.footer}
          style={{ justifyContent: "space-between" }}
        >
          <BlueLink onClick={handleCancel} text="Cancel" />
          <Button
            text="Schedule"
            onClick={openWeekendForm}
            autoFocus
            data-test="schedule-confirm"
          />
        </div>
      </div>
    )
  }

  const contractMinutes = getMinutesPerDayForDate(
    dateHelpers.formatToRunnDate(selectedDates[0]),
    person.contracts,
    account.default_full_time_minutes,
  )
  const minutesInDay = 24 * 60

  return (
    <div data-component="MiniPicker">
      <div className={styles.miniPicker}>
        <div className={plannerDatePickerStyles.stats}>
          <>
            <div className={styles.effortContainer} data-test="effortContainer">
              {nonWorkingDay ? (
                <NonWorkingDayInput
                  account={account}
                  isoDay={getISODay(selectedDates[0])}
                  minutesPerDay={minutesPerDay}
                  totalForPercentage={contract?.minutes_per_day}
                  maxMinutes={person?.is_placeholder ? 99999999 : minutesInDay}
                  onChange={handleMinutesChange}
                />
              ) : (
                <EffortInput
                  account={account}
                  minutesPerDay={minutesPerDay}
                  onChange={handleMinutesChange}
                  maxMinutes={person?.is_placeholder ? 99999999 : minutesInDay}
                  contractMinutes={contractMinutes}
                />
              )}
            </div>
            {isWeekday && (
              <>
                <div data-test="workingDaysContainer">
                  <label>Work Days</label>
                  <div>
                    <NumericInput
                      id="workingDays"
                      min={1}
                      max={365 * 10}
                      value={totalWorkingDays.label || ""}
                      onValueChange={onChangeWorkingDays}
                      disabled={!selectedDates[0] && !selectedDates[1]}
                      className={plannerDatePickerStyles.workingDaysInput}
                      integer
                      showArrows
                      invokeChangeOnClamp={true}
                    />
                  </div>
                </div>
                <TotalEffortInput
                  account={account}
                  minutesPerDay={total.value}
                  workingDayCount={totalWorkingDays.value}
                  onMinutesPerDayChange={(minutes) =>
                    handleMinutesChange(minutes, false)
                  }
                  maxMinutes={
                    person?.is_placeholder
                      ? 99999999
                      : minutesInDay * totalWorkingDays.value
                  }
                  contractMinutes={contractMinutes}
                />
              </>
            )}
          </>
        </div>
        {phases.length ? (
          <PhaseSelector
            currentPhase={currentPhase}
            phases={phases}
            onPhaseChange={setCurrentPhase}
          />
        ) : null}
        {(!isNonBillableProject || (!assignment && showRepeatCheckbox)) && (
          <div style={{ display: "flex" }}>
            {!isNonBillableProject && (
              <>
                <Checkbox
                  id="MiniPicker_Checkbox_nonBillable"
                  onChange={handleBillableToggle}
                  checked={!isBillable}
                  value="nonBillable"
                  label="Non-Billable"
                />
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            {!assignment && showRepeatCheckbox && (
              <Checkbox
                id="MiniPicker_Checkbox_repeat"
                onChange={() => {
                  setRepeatOn(!repeatOn)
                }}
                checked={!!repeatOn}
                value="repeat"
                label="Repeat"
              />
            )}
          </div>
        )}
        {repeatOn && (
          <ItemRepeat
            onChange={setRepeat}
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            nonWorkingDay={nonWorkingDay}
            businessDays={businessDays}
          />
        )}
        <EditAssignmentNote note={itemNote} editNote={editNote} />
      </div>
      <div className={styles.footer}>
        {assignment && onDelete ? (
          <BlueLink
            text="Delete"
            onClick={handleDelete}
            style={{ marginRight: "auto" }}
          />
        ) : (
          <BlueLink
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "auto" }}
          />
        )}
        {isWeekday && (
          <Tooltip content="View Full Calendar" hoverOpenDelay={500}>
            <Button
              icon={<Icon icon="calendar" size={12} />}
              onClick={handleShowFullCalendar}
            />
          </Tooltip>
        )}
        {contextMenu && (
          <Popover
            usePortal={true}
            isOpen={contextMenu.isContextMenuOpen}
            placement="right-start"
            modifiers={{ arrow: { enabled: false } }}
            interactionKind="click"
            fill={true}
            content={
              <Menu>
                {contextMenu.onSplit && (
                  <MenuItem text="Split" onClick={contextMenu.onSplit} />
                )}
                <TransferMenuItem
                  text="Transfer"
                  onClick={contextMenu.onTransfer}
                />
                <CloneMenuItem text="Clone" onClick={contextMenu.onClone} />
                <MenuItem
                  text="Select All to Right"
                  onClick={contextMenu.onSelectAllToRight}
                />
                <MenuItem
                  text="Enable Multi-Select Mode"
                  onClick={contextMenu.onEnableMultiSelect}
                />
              </Menu>
            }
          >
            <Button
              icon={<IconThreeDot size={12} />}
              onClick={() =>
                contextMenu.setIsContextMenuOpen(!contextMenu.isContextMenuOpen)
              }
            />
          </Popover>
        )}
        <Button
          id="save-assignment"
          outlined={false}
          text="Save"
          onClick={handleSubmit}
          disabled={isSaveDisabled}
        />
      </div>
    </div>
  )
}

type Props = {
  account: MiniPicker_account$key
  assignment: Assignment
  nonWorkingDay?: boolean
  totalWorkingDays: {
    value: number
    label: string
  }
  minutesPerDay: number
  setMinutesPerDay: (number) => void
  selectedDates: Date[]
  closePopover: () => void
  handleCancel: () => void
  onDelete?: () => void
  person: Person
  setShowFullCalendar: (boolean) => void
  setHighlightedItemData: (any) => void
  handleSubmit: () => void
  isBillable: boolean
  setIsBillable: (boolean) => void
  editNote: (note: string) => void
  itemNote: string
  isPopoverOpen: boolean
  setRepeat: (repeat: {
    repeatEvery: number
    numberOfAssignments: number
  }) => void
  isNonBillableProject: boolean
  currentPhase: Phase
  setCurrentPhase: (phase: Phase) => void
  phases: {
    id: number
    name: string
    color: string
  }[]
  onChangeWorkingDays: (days: number) => void
  contextMenu?: PopoverContextMenu
}

type Assignment = {
  id: number
  minutes_per_day: string
  start_date: string
  end_date: string
  is_billable: boolean
  note: string
  phase_id?: number
}

type Person = {
  id: number
  is_placeholder?: boolean
  contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
  }>
}

type Phase = {
  value: number
  label: string
  color: string
}

const mapDispatchToProps = {
  setHighlightedItemData: setHighlightedItemDataDispatch,
}

export default connect<any, any, any>(undefined, mapDispatchToProps)(MiniPicker)
