import { Tab } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { Suspense, useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./CreatePersonForm.module.css"

import { CreatePersonForm_viewer$key } from "~/forms/CreatePerson/__generated__/CreatePersonForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { ModalFormWrapper } from "~/common/ModalForm"
import SidePanelHeader from "~/common/SidePanel/SidePanelHeader"
import Tabs from "~/common/Tabs"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementQuantity,
  useIsInFreePlan,
} from "~/Entitlements/useEntitlements"
import { SUBSCRIPTIONS } from "~/GLOBALVARS"
import { usePermissions } from "~/Permissions/usePermissions"
import CreateBulkPeopleForm from "~/forms/CreateBulkPeople/CreateBulkPeopleForm"
import UpgradePlanModal from "~/forms/CreatePerson/UpgradePlanModal"
import ImportForm from "~/forms/ImportForm/ImportForm"
import PersonForm, {
  PersonFormLocation,
  PersonReturn,
} from "~/forms/PersonForm/PersonForm"

import PersonRunningTangram from "./PersonRunningTangram"

const fragment = graphql`
  fragment CreatePersonForm_viewer on users {
    account {
      id
      subscription
      people_aggregate(
        where: { active: { _eq: true }, is_placeholder: { _eq: false } }
      ) {
        aggregate {
          count
        }
      }
    }
    ...CreateBulkPeopleForm_user
  }
`

type Props = {
  formType: "single" | "bulk"
  onClose?: () => void
  onSuccess?: (person: PersonReturn) => void
  location: PersonFormLocation
}

const CreatePersonForm = (props: Props) => {
  const { onClose, onSuccess, formType, location } = props
  const { can, subject } = usePermissions()

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<CreatePersonForm_viewer$key>(fragment, viewerQuery)
  const { account } = viewer

  const activePeopleCount = account.people_aggregate.aggregate.count

  const { isAdmin } = usePermissions()

  const [activateAnimation, setActivateAnimation] = useState(false)

  const onSubmit = () => setActivateAnimation(true)
  const isFreePlan = useIsInFreePlan()
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const peopleEntitlement = useEntitlementQuantity(ChargebeeFeatures.people)
  const peopleLimit = entitlementsEnabled
    ? peopleEntitlement
    : SUBSCRIPTIONS.free_plan_limit

  if (isFreePlan && activePeopleCount >= peopleLimit) {
    return (
      <UpgradePlanModal
        closeDialog={onClose}
        isAdmin={isAdmin}
        isInPanel={formType === "single"}
        onSuccess={onClose}
        intent="ResourceLimit"
      />
    )
  }

  // Stops the screen jumping as data is loaded into the project form
  const LoadingFallback = () => <div style={{ height: "800px" }}></div>

  if (formType === "single") {
    return (
      <>
        <SidePanelHeader title="New Person" type="person" />
        <Suspense fallback={<LoadingFallback />}>
          <PersonForm
            onSuccess={onSuccess}
            onClose={onClose}
            location={location}
          />
        </Suspense>
      </>
    )
  }
  return (
    <ModalFormWrapper
      wide
      tangram={<PersonRunningTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <Tabs large id="CrPersonTabs" className={styles.wrapper}>
        <Tab
          id="bulk"
          title="Bulk Add"
          hidden={!can("create", subject("Person"))}
          panel={
            <CreateBulkPeopleForm
              closeDialog={onClose}
              user={viewer}
              onSubmit={onSubmit}
            />
          }
        />
        <Tab
          hidden={!can("create", subject("Import"))}
          id="import"
          title="Import"
          panel={<ImportForm importType="people" onClose={onClose} />}
        />
      </Tabs>
    </ModalFormWrapper>
  )
}

export default CreatePersonForm
