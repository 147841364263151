import * as fe from "@runn/filter-engine"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import type { SuperSearch_user$data } from "./__generated__/SuperSearch_user.graphql"

import { track } from "~/helpers/analytics"

import {
  LOCAL_SEARCH_DEBOUNCE_MS,
  SERVER_SEARCH_DEBOUNCE_MS,
} from "~/common/SuperSearch/SuperSearch"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"

import { FilterSelections, UnknownFilter } from "./Filters"
import type { FilterConfig, FilterSet } from "./types"
import type { FilterListItem, Path } from "./types"

type Props = {
  gql: SuperSearch_user$data
  disabled?: boolean
  filterSet: FilterSet
  onChangeFilterSet: (filterSet: FilterSet) => void
  activePath: Path | undefined
  onChangeActivePath: (path: Path) => void
  disabledDropdowns?: Array<FilterConfig["filter"]["type"]>
}

const FilterSetEditor = (props: Props) => {
  const {
    gql,
    disabled,
    filterSet,
    activePath,
    onChangeActivePath,
    onChangeFilterSet,
    disabledDropdowns,
  } = props

  const [localFilterSet, setLocalFilterSet] = useState(filterSet)

  useEffect(() => {
    setLocalFilterSet(filterSet)
  }, [filterSet])

  const queryLimitState = useQueryLimit()
  const debounceTime = queryLimitState.limit
    ? SERVER_SEARCH_DEBOUNCE_MS
    : LOCAL_SEARCH_DEBOUNCE_MS

  const onChangeFilterSetDebounced = useDebouncedCallback(
    onChangeFilterSet,
    debounceTime,
  )

  const handleChange = (path: number[], filter: FilterListItem) => {
    const filters = fe.filters.simplify(
      fe.filters.replace(localFilterSet.filters, path, () => filter),
    )
    const nextFilterSet = { name: localFilterSet.name, filters }
    setLocalFilterSet(nextFilterSet)
    onChangeFilterSetDebounced(nextFilterSet)
  }

  const handleChangeDropdownOpen = (path: Path) => (isOpen: boolean) => {
    onChangeActivePath(isOpen ? path : undefined)
  }

  const handleRemove = (path: number[]) => {
    const filters = fe.filters.simplify(
      fe.filters.replace(localFilterSet.filters, path, () => undefined),
    )

    const nextFilterSet = { name: localFilterSet.name, filters }
    setLocalFilterSet(nextFilterSet)
    onChangeFilterSetDebounced(nextFilterSet)
    track("Search Filter Removed")
  }

  const activeKey = activePath?.join(".")

  return (
    <>
      {fe.filters.flatMap(localFilterSet.filters, (filter, path) => {
        const key = path.join(".")
        const isDropdownOpen = key === activeKey
        const preventDropdown = disabledDropdowns.includes(filter.type)

        const FilterComponent = FilterSelections[filter.type]?.Component
        if (!FilterComponent) {
          return (
            <UnknownFilter
              key={key}
              path={path}
              filter={filter}
              onRemove={handleRemove}
            />
          )
        }

        return (
          <FilterComponent
            user={gql}
            key={key}
            filter={filter}
            path={path}
            disabled={disabled}
            isDropdownOpen={isDropdownOpen}
            onChange={handleChange}
            onChangeDropdownOpen={
              preventDropdown ? () => null : handleChangeDropdownOpen(path)
            }
            onRemove={handleRemove}
          />
        )
      })}
    </>
  )
}

export default FilterSetEditor
