import {
  ButtonGroup,
  Icon,
  MenuDivider,
  MenuItem,
  Tooltip,
} from "@blueprintjs/core"
import cc from "classcat"
import { addWeeks, startOfISOWeek } from "date-fns"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { match } from "ts-pattern"

import styles from "./CalendarAdjustment.module.css"

import { getCalendarEndDate } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"
import { ignoreHotKeyEvent } from "~/helpers/hotkeys"

import { Dropdown } from "~/common/Dropdown"
import Button from "~/common/buttons/Button"
import {
  setCalendarWeekendsExpanded,
  setDateRange,
  setViewInMonths,
  setViewInWeeks,
} from "~/common/calendar.reducer"

import { useAppSelector } from "~/hooks/redux"
import { getSettings, setSetting } from "~/localsettings"

import { useSidePanel } from "../SidePanel/SidePanel"

import ShowWeekendsToggle from "./ShowWeekendsToggle"

const CalendarAdjustment = (props: { disabled?: boolean }) => {
  const dispatch = useDispatch()
  const calendarView = useAppSelector((state) => state.calendar.calendarView)
  const calendarStartDate = useAppSelector(
    (state) => state.calendar.calendarStartDate,
  )
  const calendarEndDate = useAppSelector(
    (state) => state.calendar.calendarEndDate,
  )

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { disabled } = props

  const { isOpen } = useSidePanel()

  const setToday = () => {
    const start = startOfISOWeek(new Date())
    const end =
      calendarView.type === "weeks"
        ? getCalendarEndDate(start, calendarView.amount - 1, "weeks")
        : getCalendarEndDate(start, calendarView.amount, "months")

    dispatch(setDateRange({ start, end }))
  }

  // useCallback not required as this function will always be recreated anyway
  // due to calendarStartDate & calendarEndDate changing with every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setWeeksView = (weeks: number) => {
    dispatch(
      setDateRange({
        start: startOfISOWeek(addWeeks(calendarStartDate, weeks)),
        end: getCalendarEndDate(calendarEndDate, weeks, "weeks"),
      }),
    )
  }

  const setCalendarViewInWeeks = (weeks: 1) => {
    setSetting("calendarView", { type: "weeks", amount: weeks })
    dispatch(setViewInWeeks(weeks))
    dispatch(
      setDateRange({
        start: calendarStartDate,
        end: getCalendarEndDate(calendarStartDate, weeks - 1, "weeks"),
      }),
    )

    track("Planner Calendar View Changed", {
      type: "weeks",
      value: weeks,
    })
  }

  const setCalendarViewInMonths = (months: 1 | 3 | 6 | 12) => {
    setSetting("calendarView", { type: "months", amount: months })
    dispatch(setViewInMonths(months))
    dispatch(
      setDateRange({
        start: calendarStartDate,
        end: getCalendarEndDate(calendarStartDate, months, "months"),
      }),
    )

    const weekendsExpanded =
      months > 1
        ? false // always false if not on month view
        : getSettings().expandWeekendsOnMonthView || false // revert to last view on month

    dispatch(setCalendarWeekendsExpanded(weekendsExpanded))
    track("Planner Calendar View Changed", {
      type: "months",
      value: months,
    })
  }

  useEffect(() => {
    const keydownListener = (e) => {
      const modalIsOpen =
        !!document.querySelectorAll(".bp5-overlay-open").length
      if (ignoreHotKeyEvent(e) || modalIsOpen) {
        return
      }

      // In 6month view, jump 1 month
      const weeksToChange =
        calendarView.type === "months" && calendarView.amount < 6 ? 1 : 4

      switch (e.key) {
        case "ArrowRight":
          setWeeksView(weeksToChange)
          break
        case "ArrowLeft":
          setWeeksView(-weeksToChange)
          break
      }
    }

    window.addEventListener("keydown", keydownListener, true)
    return () => window.removeEventListener("keydown", keydownListener, true)
  }, [calendarView, setWeeksView])

  const viewPeriod = match(calendarView)
    .with({ type: "months", amount: 1 }, () => "Month")
    .with({ type: "months", amount: 3 }, () => "Quarter")
    .with({ type: "months", amount: 6 }, () => "Half Year")
    .with({ type: "months", amount: 12 }, () => "Year")
    .with({ type: "weeks", amount: 1 }, () => "Week")
    .otherwise(() => "Month")

  const DropdownTarget = () => (
    <Button
      text={viewPeriod}
      rightIcon={
        <Icon icon={dropdownOpen ? "chevron-up" : "chevron-down"} size={14} />
      }
      className={cc([styles.dropdownButton, styles.button])}
      disabled={disabled}
    />
  )

  return (
    <div
      className={styles.container}
      style={{
        marginRight: isOpen ? "32px" : null,
        transition: "margin-right 0.3s ease",
      }}
    >
      <ButtonGroup className={styles.buttonGroup}>
        <Tooltip
          content="Previous Month [Left arrow key ←]"
          hoverOpenDelay={1000}
          position="top"
        >
          <Button
            icon={<Icon icon="double-chevron-left" size={14} />}
            onClick={() => setWeeksView(-4)}
            className={styles.button}
            disabled={disabled}
            data-test="prevMonth"
          />
        </Tooltip>
        <Tooltip
          content="Previous Week [Left arrow key ←]"
          hoverOpenDelay={1000}
          position="top"
        >
          <Button
            icon={<Icon icon="chevron-left" size={14} />}
            onClick={() => setWeeksView(-1)}
            className={styles.button}
            disabled={disabled}
            data-test="prev"
          />
        </Tooltip>
        <Button
          text="Today"
          onClick={setToday}
          data-test="this-week"
          disabled={disabled}
          className={styles.button}
        />
        <Tooltip
          content="Next Week [Right arrow key →]"
          hoverOpenDelay={1000}
          position="top"
        >
          <Button
            icon={<Icon icon="chevron-right" size={14} />}
            onClick={() => setWeeksView(1)}
            className={styles.button}
            disabled={disabled}
            data-test="nextWeek"
          />
        </Tooltip>
        <Tooltip
          content="Next Month [Right arrow key →]"
          hoverOpenDelay={1000}
          position="top"
        >
          <Button
            icon={<Icon icon="double-chevron-right" size={14} />}
            onClick={() => setWeeksView(4)}
            className={styles.button}
            disabled={disabled}
            data-test="nextMonth"
          />
        </Tooltip>
      </ButtonGroup>
      <Dropdown
        Target={DropdownTarget}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      >
        <MenuItem text="Week" onClick={() => setCalendarViewInWeeks(1)} />
        <MenuItem text="Month" onClick={() => setCalendarViewInMonths(1)} />
        <MenuItem text="Quarter" onClick={() => setCalendarViewInMonths(3)} />
        <MenuItem text="Half Year" onClick={() => setCalendarViewInMonths(6)} />
        <MenuItem text="Year" onClick={() => setCalendarViewInMonths(12)} />
        <MenuDivider />
        <Tooltip
          disabled={calendarView.amount === 1}
          content="Not available if period is set to quarter, half year or year"
          position="left"
        >
          <ShowWeekendsToggle disabled={calendarView.amount > 1} />
        </Tooltip>
      </Dropdown>
    </div>
  )
}

export default CalendarAdjustment
