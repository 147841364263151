import * as fe from "@runn/filter-engine"
import { useFeature } from "flagged"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectPlannerList.module.css"

import type { ProjectPlannerList_user$key } from "./__generated__/ProjectPlannerList_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { prepareDataForFilterProjectList } from "~/helpers/filter-engine"

import { selectProjectFilterSet } from "~/Planner/reducer2/selectFilteredProjects"
import { useAppSelector } from "~/hooks/redux"
import type { ReduxState } from "~/rootReducer"

import ProjectManagement from "./ProjectManagement"

const fragment = graphql`
  fragment ProjectPlannerList_user on users
  @argumentDefinitions(
    todaysDate: { type: "date!" }
    peopleFilter: { type: "people_bool_exp" }
    projectsFilter: { type: "projects_bool_exp" }
  ) {
    id
    favourite_projects
    view {
      id
      project_filters
    }
    account {
      id
      name
      people(where: $peopleFilter) {
        id
        first_name
        last_name
        email
        is_placeholder
        tags
        team {
          id
          name
        }
        project_memberships {
          id
          project_id
        }
        person_requests {
          id
          status
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        current_contract {
          id
          job_title
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        managers {
          id
          user_id
        }
      }
      projects(where: $projectsFilter) {
        id
        name
        confirmed
        pricing_model
        tags_computed
        active
        is_template
        team {
          id
          name
        }
        client {
          id
          name
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        managers {
          id
          user_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        members {
          id
          person_id
          role_id
          workstream_id
        }
        project_workstreams {
          workstream {
            id
          }
        }
      }
    }

    ...ProjectManagement_user
      @arguments(projectsFilter: $projectsFilter, todaysDate: $todaysDate)
  }
`

const ProjectPlannerList = () => {
  const user = useFragment<ProjectPlannerList_user$key>(
    fragment,
    useHasuraContext(),
  )

  const { favourite_projects: favouriteProjectIds, account, view } = user
  const { people: allPeople, projects: allProjects } = account

  const showAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )

  const userDefaultView =
    useFeature("pre_filtered_views") && !showAllView ? view : null

  const calStartNum = useSelector(
    (state: ReduxState) => state.calendar.calStartNum,
  )

  const filterSet = useAppSelector((state) =>
    selectProjectFilterSet(state.plannerV2),
  )

  const filterSetWithDefaultView = useMemo(() => {
    if (userDefaultView) {
      return {
        ...filterSet,
        filters: fe.filters.simplify(
          fe.filters.and([filterSet.filters, userDefaultView.project_filters]),
        ),
      }
    }

    return filterSet
  }, [userDefaultView, filterSet])

  const preparedList = useMemo(() => {
    const filterTypeList = fe.filters.asTypeList(
      filterSetWithDefaultView.filters,
    )
    return prepareDataForFilterProjectList({
      filterTypeList,
      projects: allProjects,
      people: allPeople,
      favouriteProjectIds,
      calStartNum,
    })
  }, [
    filterSetWithDefaultView.filters,
    allProjects,
    allPeople,
    favouriteProjectIds,
    calStartNum,
  ])

  const filteredProjectIds = useMemo(
    () =>
      new Set<number>(
        fe.engines.local
          .filterProjectList(preparedList, filterSetWithDefaultView)
          .map((project) => project.id),
      ),
    [preparedList, filterSetWithDefaultView],
  )

  return (
    <div className={styles.container} id="project-planner-list">
      <ProjectManagement user={user} filteredProjectIds={filteredProjectIds} />
    </div>
  )
}

export default ProjectPlannerList
