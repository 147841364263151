import React from "react"
import isDeeplyEqual from "react-fast-compare"
import { graphql, useFragment } from "react-relay"

import { PersonRows_user$key } from "./__generated__/PersonRows_user.graphql"
import {
  PersonRows_people$data,
  PersonRows_people$key,
} from "~/ProjectPlanner/ProjectRow/__generated__/PersonRows_people.graphql"
import { PersonRows_project$key } from "~/ProjectPlanner/ProjectRow/__generated__/PersonRows_project.graphql"

import { getMemberKey, isJustAdded } from "~/helpers/project-member-helpers"
import { sortPeopleByPersonName } from "~/helpers/sorting-helpers"

import { PlannerGroupByType } from "~/Planner/reducer2/plannerStatsSlice"
import { useAppSelector } from "~/hooks/redux"
import { useProjectMembersList } from "~/hooks/useProjectMembersList"

import ProjectDetailsPersonRow from "./ProjectDetailsPersonRow"
import { ProjectPersonGroup } from "./ProjectDetailsSection"

type Props = {
  user: PersonRows_user$key
  companyDefaultMinutes: number
  groupId: number
  groupName: string
  people: PersonRows_people$key
  project: PersonRows_project$key
  delayAssignmentRender: boolean
  locatedInSchedulePanel?: boolean
  justAddedTimestamp?: number
}

type Person = {
  id: number
  first_name: string
  last_name: string
  project_memberships: ReadonlyArray<{
    id: number
    role_id: number
    workstream_id: number
    project_id: number
  }>
}
export type Assignment = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  project_id: number
  role_id: number
  workstream_id: number
}

type AssignmentMap<A extends Assignment> = Map<number, A[]>

const getPeopleGroup = <P extends Person, A extends Assignment>(
  people: ReadonlyArray<P> = [],
  assignmentMap: AssignmentMap<A>,
  groupByType: PlannerGroupByType,
  projectId: number,
  groupId: number,
  visibleMemberKeys: string[],
): ProjectPersonGroup<P, A> => {
  const peopleGroup = new Map<number, { person: P; assignments: A[] }>()

  people.forEach((person) => {
    person.project_memberships.forEach((member) => {
      const isValidMember =
        member.project_id === projectId &&
        ((groupByType === "roles" && member.role_id === groupId) ||
          (groupByType === "workstreams" && member.workstream_id === groupId))

      if (
        isValidMember &&
        visibleMemberKeys.includes(
          getMemberKey({ ...member, person_id: person.id }),
        )
      ) {
        peopleGroup.set(member.id, {
          person: person,
          assignments: assignmentMap.get(member.id) || [],
        })
      }
    })
  })

  return Object.fromEntries(peopleGroup)
}

const PersonRows = (props: Props) => {
  const {
    companyDefaultMinutes,
    groupId,
    groupName,
    delayAssignmentRender,
    locatedInSchedulePanel,
  } = props

  const groupByType = useAppSelector(
    (state) => state.plannerV2.plannerStats.groupByType,
  )

  const user = useFragment(
    graphql`
      fragment PersonRows_user on users {
        id
        ...ProjectDetailsPersonRow_user
        account {
          id
          ...ProjectDetailsPersonRow_account
        }
      }
    `,
    props.user,
  )

  const project = useFragment(
    graphql`
      fragment PersonRows_project on projects {
        id
        members {
          id
          person_id
          project_id
          role_id
          workstream_id
          is_placeholder
          just_added_timestamp
          person {
            id
            active
          }
        }
        ...ProjectDetailsPersonRow_project
      }
    `,
    props.project,
  )

  const people = useFragment(
    graphql`
      fragment PersonRows_people on people
      @relay(plural: true)
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        id
        first_name
        last_name
        is_placeholder
        active
        project_memberships {
          id
          role_id
          workstream_id
          project_id
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          role_id
          person_id
          workstream_id
          start_date: start_date_runn
          end_date: end_date_runn
        }

        ...ProjectDetailsPersonRow_person
          @arguments(plannerStartDate: $plannerStartDate)
      }
    `,
    props.people,
  )

  const assignments = people
    .flatMap((p) => p.assignments)
    .filter((a) => a?.project_id === project.id)

  const members = (project.members ?? []).map(({ person, ...membership }) => ({
    ...membership,
    active: person.active,
  }))

  const { inactive, visible } = useProjectMembersList(project.id, {
    members,
    assignments,
  })

  const inactiveKeys = inactive.map(getMemberKey)
  const visibleKeys = visible.map(getMemberKey)

  const getProjectDetailsPersonRow = (
    peopleGroup: ProjectPersonGroup<PersonRows_people$data[number], Assignment>,
  ) => {
    const rows = sortPeopleByPersonName(
      Object.entries(peopleGroup),
      ([_, item]) => item.person,
    ).map(([key, pg]) => {
      const projectMemberId = Number(key)
      const memberRole = project.members?.find((m) => m.id === projectMemberId)
      const person = people.find((p) => p.id === pg.person.id)

      if (!memberRole || !person) {
        return null
      }

      const isInactive = inactiveKeys.includes(getMemberKey(memberRole))
      const showHighlight = isJustAdded(memberRole)

      return (
        <ProjectDetailsPersonRow
          key={`${pg.person.id}-${key}`}
          projectMemberId={projectMemberId}
          account={user.account}
          user={user}
          archived={!person.active}
          roleId={groupByType === "roles" ? groupId : memberRole.role_id}
          workstreamId={
            groupByType === "workstreams" ? groupId : memberRole.workstream_id
          }
          person={pg.person}
          groupName={groupName}
          project={project}
          justAdded={showHighlight}
          isInactivePerson={isInactive}
          companyDefaultMinutes={companyDefaultMinutes}
          delayAssignmentRender={delayAssignmentRender}
          locatedInSchedulePanel={locatedInSchedulePanel}
        />
      )
    })

    return rows
  }
  const realPeople = people.filter((p) => !p.is_placeholder)
  const placeholders = people.filter((p) => p.is_placeholder)
  const assignmentMap = assignments.reduce((map, a) => {
    const existingAssignments = map.get(a.id) || []
    return new Map(map.set(a.id, [...existingAssignments, a]))
  }, new Map<number, Assignment[]>())

  const peopleGroup = getPeopleGroup(
    realPeople,
    assignmentMap,
    groupByType,
    project.id,
    groupId,
    visibleKeys,
  )
  const placeholderGroup = getPeopleGroup(
    placeholders,
    assignmentMap,
    groupByType,
    project.id,
    groupId,
    visibleKeys,
  )
  const components = (
    <>
      {getProjectDetailsPersonRow(peopleGroup)}
      {getProjectDetailsPersonRow(placeholderGroup)}
    </>
  )

  return components
}

export default React.memo(PersonRows, isDeeplyEqual)
