import * as fe from "@runn/filter-engine"
import React from "react"
import { useDispatch } from "react-redux"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"

import {
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "~/Planner/Planner.actions"
import { addAdditionalPlannerIds } from "~/Planner/reducer2/viewsSlice"
import { showToast } from "~/containers/ToasterContainer"
import { PersonReturn } from "~/forms/PersonForm/PersonForm"
import { useAppSelector } from "~/hooks/redux"

import CreatePersonForm from "./CreatePersonForm"

type Props = {
  onClose: () => void
  formType: "single" | "bulk"
}

const CreatePersonFormPlanner = (props: Props) => {
  const { onClose, formType } = props

  const currentViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )
  const dispatch = useDispatch()

  const filterToNewPerson = (person: PersonReturn) => {
    dispatch(
      setPeopleFilterSet({
        name: "",
        filters: fe.filters.personId({
          list: [person.id],
        }),
      }),
    )
    dispatch(setPeopleWildSearch(""))
    dispatch(changeActivePage("people"))
    dispatch(
      addAdditionalPlannerIds({
        viewId: currentViewId,
        peopleIds: [person.id],
      }),
    )
  }

  const onSuccess = (newlyCreatedPerson: PersonReturn) => {
    const { first_name, last_name } = newlyCreatedPerson

    showToast({
      message: "New person created",
      actions: [{ onClick: () => filterToNewPerson(newlyCreatedPerson) }],
      description: `${first_name} ${last_name} `,
      type: "success",
    })

    dispatch(
      addAdditionalPlannerIds({
        viewId: currentViewId,
        peopleIds: [newlyCreatedPerson.id],
      }),
    )

    dispatch(setPeopleFilterSet({ name: "", filters: null }))
    onClose()
  }

  return (
    <CreatePersonForm
      onSuccess={onSuccess}
      onClose={onClose}
      formType={formType}
      location="planner"
    />
  )
}

export default CreatePersonFormPlanner
