import { useFeature } from "flagged"
import React, { useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PersonSummaryGraph.module.css"

import { PersonSummaryGraph_account$key } from "./__generated__/PersonSummaryGraph_account.graphql"
import { PersonSummaryRow_person$data } from "./__generated__/PersonSummaryRow_person.graphql"
import { PersonSummaryRow_user$data } from "./__generated__/PersonSummaryRow_user.graphql"
import { SplitScreenRow_person$data } from "~/Planner/SplitScreenPanel/SplitScreenRow/__generated__/SplitScreenRow_person.graphql"

import {
  getDailyAssignmentData,
  getDaysInRange,
} from "~/helpers/CalendarHelper"
import {
  getDailyRangesCapacity,
  getTimeOffMinutes,
  getWeeklyRangesCapacity,
} from "~/helpers/daysObjectHelpers"
import { Assignment } from "~/helpers/planner-helpers"

import { getPillPosition } from "~/common/Pill/PillHelpers"

import CapacityIndicator from "~/PeoplePlanner/CapacityIndicator"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

type Range = {
  startDate: string
  endDate: string
  assignedMinutes: number
  contractedMinutes: number
  timeOffMinutes: number
  timeOffConflicts?: {
    startDate: string
    endDate: string
  }[]
  weekHasConflict?: boolean
  isWeekend?: boolean
  rosteredDays: number[]
}

const dailyRanges = (
  dailyAssignmentData,
  calendarWeekendsExpanded: boolean,
): Range[] => {
  const formattedRanges = getDailyRangesCapacity(
    dailyAssignmentData,
    calendarWeekendsExpanded,
  ).map((data) => ({
    startDate: data.startDate,
    endDate: data.endDate,
    assignedMinutes: data.assignedMinutes,
    contractedMinutes: data.contractedMinutes,
    timeOffMinutes: data.timeOff
      ? getTimeOffMinutes(data.contractedMinutes, data.timeOffMinutes)
      : 0,
    timeOffConflicts: data.timeOffConflicts,
    isWeekend: data.isWeekend,
    isANonWorkingDay: data.isANonWorkingDay,
    rosteredDays: data.rosteredDays,
  }))

  return formattedRanges
}

type Props = {
  person: PersonSummaryRow_person$data | SplitScreenRow_person$data
  projects: PersonSummaryRow_user$data["account"]["projects"]
  calendarStartDate: Date
  calendarEndDate: Date
  dayWidth: number
  calendarWeekendsExpanded: boolean
  expandRow: () => void
  delayRender: boolean
  splitScreenImpactAssignments?:
    | Array<Assignment & { minutes: number }>
    | undefined

  account: PersonSummaryGraph_account$key
}

const PersonSummaryGraph = (props: Props) => {
  const {
    person,
    projects,
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    expandRow,
    calendarWeekendsExpanded,
    delayRender,
    splitScreenImpactAssignments = [],
  } = props

  const account = useFragment(
    graphql`
      fragment PersonSummaryGraph_account on accounts {
        ...CapacityIndicator_account
      }
    `,
    props.account,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const [renderCapacityIndicator, setRenderCapacityIndicator] =
    useState(!delayRender)

  useLayoutEffect(() => {
    const timeOut = setTimeout(() => {
      setRenderCapacityIndicator(true)
    }, 10)
    return () => clearTimeout(timeOut)
  }, [])

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const { summaryUnit, showWeekly } = useAppSelector(
    (state) => state.plannerV2.peopleSummary,
  )

  if (!renderCapacityIndicator) {
    return null
  }

  const visibleProjectIds = projects
    .filter(
      (p) =>
        !p.is_template &&
        (p.confirmed ||
          isTentativeProjectEnabled(enabledTentativeProjects, p.id)),
    )
    .map((p) => p.id)
  const visibleAssignments = person.assignments.filter((a) =>
    visibleProjectIds.includes(a.project_id),
  )

  const dailyAssignmentData = getDailyAssignmentData(
    person,
    [...visibleAssignments, ...splitScreenImpactAssignments],
    calendarStartDate,
    calendarEndDate,
    isConsistentTimeOffEnabled,
  )

  const dateRangeCapacities = showWeekly
    ? getWeeklyRangesCapacity({ dailyAssignmentData, summaryUnit })
    : dailyRanges(dailyAssignmentData, calendarWeekendsExpanded)

  return (
    <div className={styles.personSummaryGraph}>
      {dateRangeCapacities.map((cr) => {
        const days = getDaysInRange({ start: cr.startDate, end: cr.endDate })
        const weekDays = getDaysInRange({
          start: cr.startDate,
          end: cr.endDate,
          includeWeekends: false,
        })
        const isCollapsedWeekend = !calendarWeekendsExpanded && cr.isWeekend

        const { width, offset } = getPillPosition({
          item: { start_date: cr.startDate, end_date: cr.endDate },
          calendarStartDate,
          calendarEndDate,
          calendarWeekendsExpanded,
          dayWidth,
          isCollapsedWeekend,
        })

        const collapsedWidth = 4
        const widthPx = isCollapsedWeekend ? collapsedWidth : days * dayWidth

        return (
          <div
            key={`conflict-${person.id}-${cr.startDate}`}
            style={{
              width: isCollapsedWeekend ? `${collapsedWidth}px` : `${width}%`,
              left: isCollapsedWeekend
                ? `calc(${offset}% - ${collapsedWidth / 2}px)`
                : "",
            }}
            className={`${isCollapsedWeekend ? styles.collapsedWeekend : ""}`}
          >
            <div className={styles.capacitySection}>
              <CapacityIndicator
                account={account}
                width={widthPx}
                contracted={cr.contractedMinutes}
                assigned={cr.assignedMinutes} // Total
                timeOff={cr.timeOffMinutes}
                isANonWorkingDay={cr.isANonWorkingDay}
                rosteredDays={cr.rosteredDays}
                isWeekend={cr.isWeekend}
                summaryUnit={summaryUnit}
                showWeekly={showWeekly}
                enabledDaysInRange={days}
                expandRow={expandRow}
                hasTimeOffConflict={
                  !!cr.timeOffConflicts?.length || cr.weekHasConflict
                }
                weekHasNonWorkingDay={cr.weekHasNonWorkingDay}
                enabledWeekDaysInRange={weekDays}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  dayWidth: state.calendar.dayWidth,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
})

const connector = connect(mapStateToProps)
export default connector(PersonSummaryGraph)
