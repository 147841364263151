import React, { useEffect, useState } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

import styles from "./ExternalProjectMemberForm.module.css"

import type { SingleExternalProjectMemberFormQuery } from "./__generated__/SingleExternalProjectMemberFormQuery.graphql"
import type { SingleExternalProjectMemberForm_viewer$key } from "./__generated__/SingleExternalProjectMemberForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getCurrentContract } from "~/helpers/person"
import { sortByString } from "~/helpers/sorting-helpers"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Select from "~/common/Select"
import Button from "~/common/buttons/Button"

import useCreateExtPersonLinks from "~/mutations/ExtPersonLink"
import { personBulkCreateRelay } from "~/mutations/Person"
import { projectMemberBulkCreateRelay } from "~/mutations/ProjectMember"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"

import RocketTakeOffTangram from "../CreateProject/RocketTakeOffTangram"

import SelectLinkedPerson from "./SelectLinkedPerson"
import { isMatchingPerson } from "./is-matching-person"

type State = {
  personId: number | undefined
  email: string | undefined
  // TODO: should be `role: { id: number } | { name: string } | undefined`
  role: string | undefined
  roleId?: number | undefined
}

const QUERY = graphql`
  query SingleExternalProjectMemberFormQuery(
    $ext_project_id: Int!
    $ext_person_id: Int!
  ) {
    current_user {
      id
      account {
        id
        ext_projects(where: { id: { _eq: $ext_project_id } }) {
          id
          name
          ext_project_members(
            where: {
              deleted: { _eq: false }
              ext_person_id: { _eq: $ext_person_id }
            }
          ) {
            id
            ext_person {
              id
              first_name
              last_name
              email
            }
          }
        }
      }
    }
  }
`

const fragment = graphql`
  fragment SingleExternalProjectMemberForm_viewer on users
  @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
    account {
      id
      people(where: $peopleFilter) {
        id
        email
        first_name
        last_name
        active
        is_placeholder
        current_contract {
          id
          role {
            id
            name
          }
        }
      }
      roles {
        id
        name
      }
    }
  }
`

type Props = {
  projectId: number
  extPersonId: number
  extProjectId: number
  closeDialog: () => void
}

const SingleExternalProjectMemberForm = (props: Props) => {
  const { closeDialog, projectId, extProjectId, extPersonId } = props

  // === react hooks :: start ==================================================

  const [state, setState] = useState<State>({
    personId: undefined,
    email: undefined,
    role: undefined,
    roleId: undefined,
  })
  const [isInFlight, setInFlight] = useState(false)

  const response = useLazyLoadQuery<SingleExternalProjectMemberFormQuery>(
    QUERY,
    {
      ext_project_id: extProjectId,
      ext_person_id: extPersonId,
    },
  )

  const [createExtPersonLinks] = useCreateExtPersonLinks()

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<SingleExternalProjectMemberForm_viewer$key>(
    fragment,
    viewerQuery,
  )

  // matchExtProjectMembersToPeople
  useEffect(() => {
    const extProject = response.current_user?.account?.ext_projects?.at(0)
    if (!extProject) {
      return
    }

    const extProjectMember = extProject.ext_project_members.find(
      (member) => member.ext_person.id === extPersonId,
    )
    if (!extProjectMember) {
      return
    }

    const extPerson = extProjectMember.ext_person

    const personList = viewer.account.people.filter(
      (person) => person.active && !person.is_placeholder,
    )

    const matchedPerson = personList.find((person) => {
      return isMatchingPerson(extPerson, person)
    })

    if (matchedPerson) {
      const defaultRole = matchedPerson.current_contract?.at(0)?.role
      setState({
        personId: matchedPerson.id,
        email: matchedPerson.email,
        roleId: defaultRole?.id,
        role: defaultRole?.name,
      })
    } else {
      setState({
        personId: undefined,
        email: extPerson.email ?? undefined,
        role: undefined,
        roleId: undefined,
      })
    }
  }, [response, extPersonId]) // eslint-disable-line react-hooks/exhaustive-deps

  // === react hooks :: end ====================================================

  const extProject = response.current_user?.account?.ext_projects?.[0]

  if (!extProject) {
    return <div>⚠️ Project with ID "{extProjectId}" not found</div>
  }

  const extProjectMember = extProject?.ext_project_members.find(
    (member) => member.ext_person.id === extPersonId,
  )

  if (!extProjectMember) {
    return (
      <div>
        ⚠️ External project member with Person ID "{extPersonId}" and Project ID
        "{extProjectId}" not found
      </div>
    )
  }

  const extPerson = extProjectMember.ext_person

  // if the extPerson already has an email, we can't change it
  // if the extPerson is being linked to an existing person, we can't change it
  const canEditEmail =
    typeof state.personId !== "number" &&
    (typeof extPerson.email !== "string" || extPerson.email.trim().length === 0)

  const personList = viewer.account.people
    .filter((person) => person.active && !person.is_placeholder)
    .sort((a, b) => sortByString(a.first_name, b.first_name))

  const optionList = personList.map((person) => ({
    id: person.id,
    firstName: person.first_name,
    lastName: person.last_name,
    isLinked: false,
  }))

  const roles = viewer.account.roles

  const handlePersonChange = (option: { value: number | undefined }) => {
    if (!option.value) {
      // The user has selected "Create new"

      setState((prevValue) => ({
        ...prevValue,
        personId: undefined,
        email: undefined,
      }))

      return
    }

    const selectedPerson = personList.find(
      (person) => person.id === option.value,
    )

    if (!selectedPerson) {
      // this shouldn't happen, but if it does try not to crash the app…
      console.error(`Person with ID ${option.value} not found`)
      return
    }

    const selectedPersonRole = selectedPerson.current_contract?.at(0)?.role
    setState({
      personId: selectedPerson.id,
      email: selectedPerson.email,
      roleId: selectedPersonRole?.id,
      role: selectedPersonRole?.name,
    })
  }

  const handleProjectCreation = async () => {
    try {
      setInFlight(true)

      let personId: number | undefined = state.personId
      let roleId: number | undefined = state.roleId

      // If `state.personId` is blank, then we need to create a new person
      if (!personId) {
        const result = await personBulkCreateRelay({
          variables: {
            input: {
              people: [
                {
                  email: state.email,
                  first_name: extPerson.first_name,
                  last_name: extPerson.last_name,
                  role_name: state.role,
                },
              ],
            },
            plannerStartDate: PLANNER_INITIAL_DATE,
          },
        })
        personId = result.at(0).person.id
        roleId = getCurrentContract(result.at(0).person.contracts)?.role.id
      }
      if (!roleId) {
        throw new Error("Role ID is missing")
      }

      await projectMemberBulkCreateRelay({
        input: {
          project_members: [
            {
              role_id: state.roleId,
              is_placeholder: false,
              workstream_id: null,
              person_id: personId,
              project_id: projectId,
            },
          ],
        },
      })

      createExtPersonLinks({
        extPersonLinks: [
          {
            ext_person_id: extPersonId,
            person_id: personId,
          },
        ],
        clearExistingLinksOnExtPerson: true,
      })

      setInFlight(false)
      closeDialog()
    } catch (e) {
      console.error(e)
      setInFlight(false)
    }
  }

  const handleRoleChange = (option: { label: string; value: number }) => {
    setState((prevValue) => ({
      ...prevValue,
      roleId: option.value,
      role: option.label,
    }))
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setState((prevValue) => ({
      ...prevValue,
      email: value,
    }))
  }

  // Ensure that every person has a role
  const addToProjectDisabled = isInFlight || (!state.role && !state.roleId)

  return (
    <ModalFormWrapper
      wide
      tangram={<RocketTakeOffTangram activate={false} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <ModalBody style={{ maxWidth: "1800px" }}>
        <h5>Activate Person</h5>
        <p>Link an existing person in Runn or create a new person</p>
        <table className={styles.tableContainer}>
          <thead className={styles.tableHeader}>
            <tr>
              <th>Name</th>
              <th>Email (optional)</th>
              <th style={{ textAlign: "left" }}>Create or Link</th>
              <th>Default Role</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.tableRow}>
              <td>
                {extPerson.first_name} {extPerson.last_name}
              </td>
              <td>
                {canEditEmail ? (
                  <Input
                    name={`person-${extPersonId}-email`}
                    id={`person-${extPersonId}-email`}
                    label=""
                    style={{ width: "180px", margin: 0 }}
                    value={state.email}
                    onChange={handleEmailChange}
                  />
                ) : (
                  <span>{state.email || "--"}</span>
                )}
              </td>
              <td>
                <SelectLinkedPerson
                  options={optionList}
                  value={state.personId}
                  onChange={handlePersonChange}
                />
              </td>
              <td>
                <Select
                  id={`role-${extPersonId}`}
                  required={true}
                  options={roles.map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
                  value={
                    typeof state.roleId !== "undefined"
                      ? {
                          label: state.role,
                          value: state.roleId,
                        }
                      : null
                  }
                  placeholder="Select or create role"
                  width="200px"
                  onChange={handleRoleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={closeDialog} />
        <Button
          text="Activate"
          loading={isInFlight}
          id="add-ext-person-to-project-button"
          outlined={false}
          onClick={handleProjectCreation}
          disabled={addToProjectDisabled}
          style={{ marginLeft: 7 }}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}
export default SingleExternalProjectMemberForm
