/**
 * @generated SignedSource<<02b732f729454693fe9a632beea9014b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCustomEditor_viewer$data = {
  readonly account: {
    readonly custom_checkbox_types_project: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly sort_order: number | null | undefined;
    }>;
    readonly custom_date_types_project: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly sort_order: number | null | undefined;
    }>;
    readonly custom_select_types_project: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
      readonly single_select: boolean;
      readonly sort_order: number | null | undefined;
    }>;
    readonly custom_text_types_project: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly sort_order: number | null | undefined;
    }>;
    readonly id: number;
    readonly use_week_numbers: boolean | null | undefined;
  };
  readonly id: number;
  readonly view: {
    readonly id: number;
    readonly name: string;
    readonly project_filters: any;
  } | null | undefined;
  readonly " $fragmentType": "ProjectCustomEditor_viewer";
};
export type ProjectCustomEditor_viewer$key = {
  readonly " $data"?: ProjectCustomEditor_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCustomEditor_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PROJECT"
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_order",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCustomEditor_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "use_week_numbers",
          "storageKey": null
        },
        {
          "alias": "custom_text_types_project",
          "args": (v2/*: any*/),
          "concreteType": "custom_text_types",
          "kind": "LinkedField",
          "name": "custom_text_types",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
        },
        {
          "alias": "custom_date_types_project",
          "args": (v2/*: any*/),
          "concreteType": "custom_date_types",
          "kind": "LinkedField",
          "name": "custom_date_types",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
        },
        {
          "alias": "custom_select_types_project",
          "args": (v2/*: any*/),
          "concreteType": "custom_select_types",
          "kind": "LinkedField",
          "name": "custom_select_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "single_select",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": "options",
              "args": null,
              "concreteType": "custom_select_options",
              "kind": "LinkedField",
              "name": "custom_select_options",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
        },
        {
          "alias": "custom_checkbox_types_project",
          "args": (v2/*: any*/),
          "concreteType": "custom_checkbox_types",
          "kind": "LinkedField",
          "name": "custom_checkbox_types",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "8b580681a4de91c68f38dc4e2039df2c";

export default node;
