/**
 * @generated SignedSource<<6c5769e00788846d15304ff2ddbc4834>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonRows_people$data = ReadonlyArray<{
  readonly active: boolean;
  readonly assignments: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly person_id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly start_date: string | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly first_name: string;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly project_memberships: ReadonlyArray<{
    readonly id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_person">;
  readonly " $fragmentType": "PersonRows_people";
}>;
export type PersonRows_people$key = ReadonlyArray<{
  readonly " $data"?: PersonRows_people$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonRows_people">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PersonRows_people",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_gte",
                  "variableName": "plannerStartDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "end_date_iso"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "assignments",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPersonRow_person"
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "a0042d76bb794f18cd5d8c35d9153949";

export default node;
