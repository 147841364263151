import React from "react"

import styles from "./PlannerDatePickerHeader.module.css"

import { getCurrentContract } from "~/helpers/person"

import ClientDetails from "~/common/ClientDetails"
import MultiPersonDetails from "~/common/MultiPersonDetails"
import PersonDetails from "~/common/PersonDetails"
import { ArrowConnect, Clock } from "~/common/react-icons"

type Props = {
  multiPerson: Person[]
  client: {
    image_key: string
    name: string
  }
  project: {
    name: string
    emoji: string | null
  }
  person: Person
  roleName: string
  isTimeOff: boolean
}

type Person = {
  id: number
  first_name: string
  last_name: string
  is_placeholder?: boolean
  email?: string
  image_key?: string
  contracts: ReadonlyArray<{
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      id: number
      name: string
    }
  }>
}
const PlannerDatePickerHeader = (props: Props) => {
  const { multiPerson, client, project, person, roleName, isTimeOff } = props

  const clientLogo = client ? client.image_key : ""
  const clientName = client ? client.name : ""
  const projectName = project ? project.name : ""

  const placeholderRole =
    person.is_placeholder && getCurrentContract(person.contracts)?.role?.name
  const hasMultiplePeople = multiPerson && multiPerson.length > 1
  const singlePerson =
    multiPerson && multiPerson.length === 1 ? multiPerson[0] : person
  return (
    <div className={styles.header}>
      <section>
        <div className={styles.leftSide}>
          {hasMultiplePeople ? (
            <MultiPersonDetails people={multiPerson} reverse />
          ) : (
            <PersonDetails
              large
              reverse
              person={singlePerson}
              subtitle={roleName || placeholderRole || ""}
            />
          )}
        </div>
      </section>
      <div className={styles.arrow}>
        <ArrowConnect />
      </div>
      <section>
        <div className={styles.rightSide}>
          {!isTimeOff ? (
            <ClientDetails
              large
              imageKey={clientLogo}
              title={projectName}
              subtitle={clientName}
              projectEmoji={project.emoji}
            />
          ) : (
            <>
              <div className={styles.clock}>
                <Clock alt="Time Off" />
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Time Off</div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  )
}

export default PlannerDatePickerHeader
