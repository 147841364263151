/**
 * @generated SignedSource<<945724ea6b672ee106dd2f2985477cb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCustomEditor_project$data = {
  readonly custom_checkbox_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: boolean;
  }>;
  readonly custom_date_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: any | null | undefined;
  }>;
  readonly custom_select_values: ReadonlyArray<{
    readonly optionId: number;
    readonly typeId: number;
  }>;
  readonly custom_text_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: string;
  }>;
  readonly id: number;
  readonly " $fragmentType": "ProjectCustomEditor_project";
};
export type ProjectCustomEditor_project$key = {
  readonly " $data"?: ProjectCustomEditor_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCustomEditor_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCustomEditor_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_values",
      "kind": "LinkedField",
      "name": "custom_text_values",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_text_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_values",
      "kind": "LinkedField",
      "name": "custom_date_values",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_date_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_values",
      "kind": "LinkedField",
      "name": "custom_select_values",
      "plural": true,
      "selections": [
        {
          "alias": "optionId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_option_id",
          "storageKey": null
        },
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_values",
      "kind": "LinkedField",
      "name": "custom_checkbox_values",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_checkbox_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "36e922d044b5e4a8b05536625b5a4a4d";

export default node;
