/**
 * @generated SignedSource<<78161c4d81b8da1209fbe787ba49b379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentActionPill_account$data = {
  readonly default_full_time_minutes: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerDatePicker_account">;
  readonly " $fragmentType": "AssignmentActionPill_account";
};
export type AssignmentActionPill_account$key = {
  readonly " $data"?: AssignmentActionPill_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentActionPill_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentActionPill_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerDatePicker_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "d605218fc2329261d8fb8a0b002b1da4";

export default node;
