import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { CreateProjectFromTemplateForm_project$key } from "./__generated__/CreateProjectFromTemplateForm_project.graphql"

import { ModalFormWrapper } from "~/common/ModalForm"

import CreateProjectForm, {
  ProjectReturn,
} from "../CreateProjectForm/CreateProjectForm"

import RocketTakeOffTangram from "./RocketTakeOffTangram"

type Props = {
  templateQuery: CreateProjectFromTemplateForm_project$key
  closeDialog: () => void
  onSuccess: (project: ProjectReturn) => void
}

const fragment = graphql`
  fragment CreateProjectFromTemplateForm_project on projects {
    id
    name
    tags_computed
    workstreams_computed
    pricing_model
    client_id
    rate_card_id
    team_id
    # Referenced directly in <ProjectForm> as we need it in addition to the fragment inclusion
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      typeId: custom_checkbox_type_id
      value
    }
    ...ProjectCustomEditor_project
  }
`

const CreateProjectFromTemplate = (props: Props) => {
  const { closeDialog, templateQuery, onSuccess } = props

  const template = useFragment<CreateProjectFromTemplateForm_project$key>(
    fragment,
    templateQuery,
  )

  const [activateAnimation, setActivateAnimation] = useState(false)
  const [isDatePickerForm, setIsDatePickerForm] = useState(false)

  return (
    <ModalFormWrapper
      headerTitle={
        isDatePickerForm ? "Select Start Week" : "New Project from Template"
      }
      headerSubTitle={template.name}
      wide={!isDatePickerForm}
      tangram={<RocketTakeOffTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <CreateProjectForm
        onClose={closeDialog}
        onSuccess={onSuccess}
        templateBlueprint={template}
        setIsDatePickerForm={setIsDatePickerForm}
        activateAnimation={() => setActivateAnimation(true)}
      />
    </ModalFormWrapper>
  )
}

export default CreateProjectFromTemplate
