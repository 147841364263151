/**
 * @generated SignedSource<<79f95ae2afd8e48cb06283892971ff00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CompetencyToCreate = {
  level?: number | null | undefined;
  personId: number;
  skillId: number;
};
export type CompetencyCreateMutation$variables = {
  competencies: ReadonlyArray<CompetencyToCreate>;
};
export type CompetencyCreateMutation$data = {
  readonly createCompetency: {
    readonly account: {
      readonly competencies: ReadonlyArray<{
        readonly id: number;
        readonly level: number | null | undefined;
        readonly person: {
          readonly first_name: string;
          readonly id: number;
          readonly is_placeholder: boolean;
          readonly last_name: string;
        };
        readonly person_id: number;
        readonly skill: {
          readonly id: number;
          readonly name: string;
        };
        readonly skill_id: number;
      }>;
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly competencies: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type CompetencyCreateMutation = {
  response: CompetencyCreateMutation$data;
  variables: CompetencyCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "competencies"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "competencies",
        "variableName": "competencies"
      }
    ],
    "concreteType": "CreateCompetencyResult",
    "kind": "LinkedField",
    "name": "createCompetency",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "competencies",
                "kind": "LinkedField",
                "name": "competencies",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "competencies",
            "kind": "LinkedField",
            "name": "competencies",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "skill_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "skills",
                "kind": "LinkedField",
                "name": "skill",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "is_placeholder",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetencyCreateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetencyCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "659bcb67f6063b54b3c8800f3571b12b",
    "id": null,
    "metadata": {},
    "name": "CompetencyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CompetencyCreateMutation(\n  $competencies: [CompetencyToCreate!]!\n) {\n  createCompetency(competencies: $competencies) {\n    account {\n      id\n      people {\n        id\n        competencies {\n          id\n        }\n      }\n      competencies {\n        id\n        level\n        person_id\n        skill_id\n        skill {\n          id\n          name\n        }\n        person {\n          id\n          is_placeholder\n          first_name\n          last_name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1d5963c24624adcb54745f82c08b35e";

export default node;
