/**
 * @generated SignedSource<<bd9ca16cc6b685f6e64691b503297890>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OtherCostBulkCreateInput = {
  charge: number;
  cost: number;
  date: string;
  name: string;
};
export type OtherCostsBulkCreateMutation$variables = {
  input: ReadonlyArray<OtherCostBulkCreateInput>;
  project_id: number;
};
export type OtherCostsBulkCreateMutation$data = {
  readonly action_other_cost_bulk_create: {
    readonly account: {
      readonly id: number;
    } | null | undefined;
    readonly project: {
      readonly id: number;
      readonly other_costs: ReadonlyArray<{
        readonly charge: number;
        readonly cost: number;
        readonly date: string | null | undefined;
        readonly id: number;
        readonly name: string;
      }>;
    } | null | undefined;
  };
};
export type OtherCostsBulkCreateMutation = {
  response: OtherCostsBulkCreateMutation$data;
  variables: OtherCostsBulkCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "project_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "project_id"
      }
    ],
    "concreteType": "OtherCostBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_other_cost_bulk_create",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "date": "asc"
                }
              }
            ],
            "concreteType": "other_costs",
            "kind": "LinkedField",
            "name": "other_costs",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": "date",
                "args": null,
                "kind": "ScalarField",
                "name": "date_runn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "charge",
                "storageKey": null
              }
            ],
            "storageKey": "other_costs(order_by:{\"date\":\"asc\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OtherCostsBulkCreateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OtherCostsBulkCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7cd1fe276f1e981f57c67df404d44566",
    "id": null,
    "metadata": {},
    "name": "OtherCostsBulkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation OtherCostsBulkCreateMutation(\n  $input: [OtherCostBulkCreateInput!]!\n  $project_id: Int!\n) {\n  action_other_cost_bulk_create(input: $input, project_id: $project_id) {\n    project {\n      id\n      other_costs(order_by: {date: asc}) {\n        id\n        name\n        date: date_runn\n        cost\n        charge\n      }\n    }\n    account {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3459b20d4226fbb47562e64b91dedb10";

export default node;
