import { Menu } from "@blueprintjs/core"
import { some } from "lodash-es"
import React, { useState } from "react"

import styles from "./OmniButton.module.css"

import { projectTemplateListUrl } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import MenuItem from "~/common/MenuItem"
import { Popover2 } from "~/common/Popover2"
import AddButton from "~/common/buttons/AddButton"
import * as icons from "~/common/react-icons"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementSwitch,
  useShowUpsellingSection,
} from "~/Entitlements/useEntitlements"
import { usePermissions } from "~/Permissions/usePermissions"
import CreateClientForm from "~/forms/CreateClient/CreateClientForm"
import CreatePersonFormPlanner from "~/forms/CreatePerson/CreatePersonFormPlanner"
import CreateProjectFormPlanner from "~/forms/CreateProject/CreateProjectPlanner"
import RoleForm from "~/forms/RoleForm/RoleForm"

import PaidFeatureMenuItemContainer from "../PaidFeatureMenuItemContainer"
import { useSidePanel } from "../SidePanel/SidePanel"

enum DialogType {
  BULK_PEOPLE = "bulk-people",
  BULK_PROJECTS = "bulk-projects",
  PROJECT = "project",
  CLIENT = "client",
  ROLE = "role",
  PROJECT_TEMPLATE = "project template",
}

const OmniButton = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [dialogType, setDialogType] = useState<DialogType>()

  const { subject, can } = usePermissions()

  const canCreateProject = can("create", subject("Project"))
  const canCreatePerson = can("create", subject("Person"))
  const canCreateClient = can("create", subject("Client"))
  const canCreateRole = can("create", subject("Role"))
  const canCreateTemplate = can("create", subject("ProjectTemplate"))

  const entitledToProjectTemplates = useEntitlementSwitch(
    ChargebeeFeatures.projectTemplates,
  )

  // useEntitlementSwitch will return true if the feature flag is disabled
  const showUpsellingSection = useShowUpsellingSection()

  const { openPanel, closePanel } = useSidePanel()

  if (
    !some([
      canCreateProject,
      canCreatePerson,
      canCreateClient,
      canCreateRole,
      canCreateTemplate,
    ])
  ) {
    return null
  }

  const setDialog = (type) => {
    setShowDialog(true)
    setDialogType(type)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const renderDialog = () => {
    switch (dialogType) {
      case DialogType.BULK_PEOPLE:
        return (
          <CreatePersonFormPlanner
            formType="bulk"
            onClose={handleCloseDialog}
          />
        )
      case DialogType.PROJECT:
      case DialogType.BULK_PROJECTS:
        return (
          <CreateProjectFormPlanner
            projectType="project"
            defaultSelectedTabId={
              dialogType === DialogType.PROJECT ? "single" : "bulk"
            }
            closeDialog={handleCloseDialog}
          />
        )
      case DialogType.CLIENT:
        return <CreateClientForm closeDialog={handleCloseDialog} />
      case DialogType.ROLE:
        return <RoleForm closeDialog={handleCloseDialog} />
      case DialogType.PROJECT_TEMPLATE:
        return (
          <CreateProjectFormPlanner
            projectType="project template"
            closeDialog={handleCloseDialog}
          />
        )
      default:
        break
    }
  }

  const handleCreatePerson = () => {
    openPanel(
      <CreatePersonFormPlanner
        formType="single"
        onClose={() => closePanel()}
      />,
    )
  }

  return (
    <>
      <Popover2
        content={
          <Menu className={styles.omniButton} data-test="omni-button-menu">
            {canCreateProject && (
              <MenuItem
                id="create-project"
                text="Project"
                icon={<icons.Assignment />}
                onClick={() => setDialog("project")}
              />
            )}
            {canCreatePerson && (
              <MenuItem
                id="create-person"
                text="Person"
                icon={<icons.Face />}
                onClick={handleCreatePerson}
              />
            )}
            {canCreateClient && (
              <MenuItem
                id="create-client"
                text="Client"
                icon={<icons.Client />}
                onClick={() => setDialog("client")}
              />
            )}
            {canCreateRole && (
              <MenuItem
                id="create-role"
                text="Role"
                icon={<icons.PersonCircle />}
                onClick={() => setDialog("role")}
              />
            )}
            {entitledToProjectTemplates &&
              !showUpsellingSection &&
              canCreateTemplate && (
                <MenuItem
                  id="create-project-template"
                  text="Project Template"
                  icon={<icons.ProjectTemplateIcon />}
                  onClick={() => setDialog("project template")}
                />
              )}
            {(canCreatePerson || canCreateProject) && (
              <MenuItem
                id="bulk"
                text="Bulk Add"
                icon={<icons.Plus color="var(--midnight)" />}
              >
                {canCreateProject && (
                  <MenuItem
                    id="bulk-projects"
                    text="Projects"
                    icon={<icons.Assignment />}
                    onClick={() => setDialog("bulk-projects")}
                  />
                )}
                {canCreatePerson && (
                  <MenuItem
                    id="bulk-people"
                    text="People"
                    icon={<icons.Face />}
                    onClick={() => setDialog("bulk-people")}
                  />
                )}
              </MenuItem>
            )}
            {/* TODO: change to real plan ids */}
            {showUpsellingSection && (
              <PaidFeatureMenuItemContainer roundedButton>
                <MenuItem
                  id="create-project-template"
                  text="Project Template"
                  icon={<icons.ProjectTemplateIcon />}
                  onClick={() =>
                    entitledToProjectTemplates
                      ? setDialog("project template")
                      : (window.location.href = projectTemplateListUrl())
                  }
                  softDisabled={!entitledToProjectTemplates}
                />
              </PaidFeatureMenuItemContainer>
            )}
          </Menu>
        }
        placement="bottom-end"
      >
        <AddButton
          className={styles.createButton}
          text="New"
          id="omni-button"
        />
      </Popover2>
      <Dialog isOpen={showDialog} onClose={handleCloseDialog}>
        {renderDialog()}
      </Dialog>
    </>
  )
}

export default OmniButton
