import { graphql } from "react-relay"

import { PlannerChangeDateMutation } from "./__generated__/PlannerChangeDateMutation.graphql"

import { environment } from "~/store/hasura"

import { addLinkedRecord, commitMutationPromise } from "./helpers"

import { RELAY_ASSIGNMENT_LOCATION, RELAY_TIMEOFF_LOCATION } from "~/GLOBALVARS"

const plannerChangeDateMutation = graphql`
  mutation PlannerChangeDateMutation($fromDate: date, $toDate: date) {
    action_planner_change_date {
      account {
        id
        people {
          id
          assignments(
            where: { end_date_iso: { _gte: $fromDate, _lte: $toDate } }
          ) {
            id
            person_id
            project_id
            phase_id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            total_minutes
            is_billable
            is_template
            note
            role_id
            updated_at
            non_working_day
            workstream_id
          }
          time_offs(
            where: { end_date_iso: { _gte: $fromDate, _lte: $toDate } }
          ) {
            id
            person_id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            leave_type
            ...ExtLinks_TimeOff @relay(mask: false)
          }
        }
      }
    }
  }
`

export const plannerChangeDateRelay = (
  variables: PlannerChangeDateMutation["variables"],
) => {
  return commitMutationPromise<PlannerChangeDateMutation>(environment, {
    mutation: plannerChangeDateMutation,
    variables,
    updater: (store) => {
      const payload = store.getRootField("action_planner_change_date")
      const account = payload.getLinkedRecord("account")
      const people = account.getLinkedRecords("people")

      people.forEach((person) => {
        const assignments = person.getLinkedRecords(
          `assignments(where:{"end_date_iso":{"_gte":"${variables.fromDate}","_lte":"${variables.toDate}"}})`,
        )

        assignments.forEach((a) => {
          addLinkedRecord(person, RELAY_ASSIGNMENT_LOCATION, a)
        })

        const time_offs = person.getLinkedRecords(
          `time_offs(where:{"end_date_iso":{"_gte":"${variables.fromDate}","_lte":"${variables.toDate}"}})`,
        )

        time_offs.forEach((a) => {
          addLinkedRecord(person, RELAY_TIMEOFF_LOCATION, a)
        })
      })
    },
  })
}
