import * as fe from "@runn/filter-engine"
import capitalize from "lodash-es/capitalize"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import styles from "./SplitScreenHeader.module.css"

import { SplitScreenPanel_user$data } from "./__generated__/SplitScreenPanel_user.graphql"

import { track } from "~/helpers/analytics"

import Select from "~/common/Select"
import SuperSearch, { usePersonSearchConfig } from "~/common/SuperSearch"
import { ViewState } from "~/common/views.reducer"

import { createFilterSet, deleteFilterSet } from "~/mutations/FilterSet"

import { setSplitScreenFilterSet } from "~/Planner/Planner.actions"
import { listTypeChanged } from "~/Planner/reducer2/splitScreenSlice"
import { useAppSelector } from "~/hooks/redux"
import { SavedFilterSet, useFilterSets } from "~/queries/FilterSet"

type Props = {
  filterSet: fe.engines.local.AllowedFilterSet
  wildSearchQuery: string | undefined
  onChangeWildSearchQuery: (query: string | undefined) => void
  allowSave?: boolean
  listType: "people" | "placeholders"
  viewState: ViewState
  handleChangeViewId: (viewId: number) => void
  handleChangeShowAllView: () => void
  project: SplitScreenPanel_user$data["account"]["projects"][0]
}

const SplitScreenHeader = (props: Props) => {
  const {
    onChangeWildSearchQuery,
    wildSearchQuery,
    filterSet,
    allowSave = false,
    listType,
    handleChangeViewId,
    viewState,
    handleChangeShowAllView,
    project,
  } = props

  const savedFilterSets = useFilterSets({ type: "person" })
  const dispatch = useDispatch()

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const modeCapitalized = capitalize(modeAction)

  const handleChangeFilterSet = (
    updatedFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    dispatch(setSplitScreenFilterSet(updatedFilterSet))
  }

  const handleCreateSavedFilterSet = async (
    newFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    if (!allowSave) {
      return null
    }
    await createFilterSet({
      filterSet: {
        type: "person",
        name: newFilterSet.name,
        filters: newFilterSet.filters,
      },
    })
  }

  const handleDeleteSavedFilterSet = async (
    selectedFilterSet: SavedFilterSet,
  ) => {
    if (!allowSave) {
      return null
    }
    await deleteFilterSet({ filterSetId: selectedFilterSet.id })
  }

  const personSearchConfig = usePersonSearchConfig({
    excludedFilters: ["person_state"],
  })

  const superSearchConfig = useMemo(
    () => ({
      ...personSearchConfig,
      features: {
        menu: listType === "people",
        saveFilter: allowSave,
        editFilter: listType === "people",
        wildSearch: true,
        editWildSearch: true,
        clearable: listType === "people",
        tentativeProjectsToggle: false,
      },
      filterMenuButtonLabel: "Filter",
      wildSearchPlaceholder: `Search ${listType}...`,
      autoFocusWildSearch: true,
    }),
    [personSearchConfig, allowSave, listType],
  )

  const listOptions = [
    { value: "people", label: "People" },
    { value: "placeholders", label: "Placeholders" },
  ]

  const [selectedListType, setSelectedListType] = useState(listOptions[0])

  const handleSelect = (e) => {
    setSelectedListType(e)
    dispatch(listTypeChanged(e.value))

    const value: "people" | "placeholders" = e.value

    switch (modeAction) {
      case "transfer":
        switch (value) {
          case "people":
            track("Transfer People Viewed")
            break
          case "placeholders":
            track("Transfer Placeholders Viewed")
            break
          default:
            value satisfies never
            break
        }
        break
      case "clone":
        switch (value) {
          case "people":
            track("Clone People Viewed")
            break
          case "placeholders":
            track("Clone Placeholders Viewed")
            break
          default:
            value satisfies never
            break
        }
        break
      case "delete":
        switch (value) {
          case "people":
            track("Delete People Viewed")
            break
          case "placeholders":
            track("Delete Placeholders Viewed")
            break
          default:
            value satisfies never
            break
        }
        break
      case "move":
        switch (value) {
          case "people":
            track("Move People Viewed")
            break
          case "placeholders":
            track("Move Placeholders Viewed")
            break
          default:
            value satisfies never
            break
        }
        break
      case "addPhase":
        switch (value) {
          case "people":
            track("AddPhase People Viewed")
            break
          case "placeholders":
            track("AddPhase Placeholders Viewed")
            break
          default:
            value satisfies never
            break
        }
        break
      default:
        modeAction satisfies never
        break
    }
  }

  return (
    <div className={styles.container}>
      <label htmlFor="listType">{modeCapitalized} to</label>
      <div className={styles.dropdownWrapper}>
        <Select
          isSearchable={false}
          name="listType"
          value={selectedListType}
          onChange={handleSelect}
          options={listOptions}
          fontSize={14}
          width={150}
          height={30}
          controlStyles={{ borderColor: "var(--winter)" }}
        />
      </div>
      <SuperSearch
        location={modeCapitalized}
        config={superSearchConfig}
        wildSearchQuery={wildSearchQuery}
        onChangeWildSearchQuery={onChangeWildSearchQuery}
        filterSet={filterSet}
        onChangeFilterSet={handleChangeFilterSet}
        savedFilterSets={savedFilterSets}
        onCreateSavedFilterSet={handleCreateSavedFilterSet}
        onDeleteSavedFilterSet={handleDeleteSavedFilterSet}
        viewState={viewState}
        handleChangeViewId={handleChangeViewId}
        handleChangeShowAllView={handleChangeShowAllView}
        project={project}
      />
    </div>
  )
}

export default SplitScreenHeader
