import { combineReducers } from "redux"

import activePage from "./common/ActivePage/ActivePage.slice"
import calendar from "./common/calendar.reducer"

import global from "./GlobalState"
import multiSelect from "./Mode.reducer"
import charts from "./Planner/ChartPanel/Charts.slice"
import planner from "./Planner/Planner.reducer"
import plannerV2 from "./Planner/reducer2/index"
import panel from "./Planner/reducer2/panelSlice"
import projectDashboard from "./ProjectDashboard/reducer"
import displayUnit from "./display_units"

const rootReducer = combineReducers({
  activePage,
  calendar,
  panel,
  charts,
  global,
  multiSelect,
  permissions: (state = {}, action: any) => state,
  planner,
  plannerV2,
  trial: (state = {}, action: any) => state,
  plannerLimit: (state = null, action: any) => state,
  displayUnit,
  projectDashboard,
})

export type ReduxState = ReturnType<typeof rootReducer>

export default rootReducer
