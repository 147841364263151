import { graphql } from "react-relay"

import SessionStore from "~/store/SessionStore"

import Subscription from "~/subscriptions/Subscription"

import applyAction from "./apply-action"

type ActionHandler = typeof applyAction

class AuditLogSubscription extends Subscription {
  private seen: Set<number>
  private sessionStore: SessionStore
  private onAction: ActionHandler

  constructor(
    sessionStore: SessionStore,
    onAction: ActionHandler = applyAction,
  ) {
    super()
    this.seen = new Set<number>()
    this.sessionStore = sessionStore
    this.onAction = onAction
  }

  public subscription = graphql`
    subscription AuditLogSubscription(
      $where: audit_recently_logged_actions_bool_exp
    ) {
      audit_recently_logged_actions(
        where: $where
        order_by: { action_tstamp_stm: asc }
      ) {
        event_id
        table_name
        action
        row_data
        changed_fields
      }
    }
  `

  public getSubscriptionConfig = async () => {
    const sessionId = await this.sessionStore.getSessionId()

    return {
      subscription: this.subscription,
      variables: {
        where: {
          session_id: { _neq: sessionId },
        },
      },
      updater: (store, response) => {
        const { audit_recently_logged_actions: actions } = response
        for (const action of actions) {
          if (!this.seen.has(action.event_id)) {
            this.onAction(store, action)
            this.seen.add(action.event_id)
          }
        }
      },
    }
  }
}

export default AuditLogSubscription
