/**
 * @generated SignedSource<<58ad979b83cb6bc462de5909d068e000>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PersonRows_user">;
  readonly " $fragmentType": "ProjectDetails_user";
};
export type ProjectDetails_user$key = {
  readonly " $data"?: ProjectDetails_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonRows_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "90d78295e7eea796f85294111d77ce4b";

export default node;
