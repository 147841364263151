import { graphql, useFragment } from "react-relay"

import type { usePeople_account$key } from "./__generated__/usePeople_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import { formatName } from "~/helpers/person"

// @growing-pains-todo: Applying the filter here is self-referential insanity.
// Should be all_people, but need a new approach for UX.
const fragment = graphql`
  fragment usePeople_account on accounts
  @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
    people(where: $peopleFilter) {
      id
      first_name
      last_name
      is_placeholder
      active
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const usePeople = (options: Options) => {
  const { user } = options
  const { people } = useFragment<usePeople_account$key>(fragment, user.account)

  const availableOptions = people
    .filter((person) => {
      return person.active && !person.is_placeholder
    })
    .map((person) => ({
      value: person.id,
      label: formatName(person.first_name, person.last_name),
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default usePeople
