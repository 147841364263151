import { addLinkedRecord } from "~/mutations/helpers"

import setValuesOnRecord from "./set-values-on-record"
import type { DefaultRowData, Model, Store } from "./types"

const applyInsertAction = (
  store: Store,
  model: Model,
  rowData: DefaultRowData,
) => {
  const globalId = model.getDataId(rowData)
  const $recordProxy = store.get(globalId)
  if (!$recordProxy) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const $recordProxy = store.create(globalId, model.tableName)
    setValuesOnRecord($recordProxy, model, rowData)

    for (const relationship of model.relationships) {
      const relatedId = relationship.getDataId(rowData)
      const $relatedRecord = store.get(relatedId)
      if ($relatedRecord) {
        addLinkedRecord($relatedRecord, relationship.pluralField, $recordProxy)
      }
    }
    if (model.postInsert) {
      model.postInsert($recordProxy, rowData)
    }
  }
}

export default applyInsertAction
