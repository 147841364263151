/**
 * @generated SignedSource<<a43677b8ad818dce6fbfdcc7ea6d57af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonNoteCreateMutation$variables = {
  note: string;
  personId: number;
};
export type PersonNoteCreateMutation$data = {
  readonly addPersonNote: {
    readonly personNote: {
      readonly created_at: any;
      readonly created_by: string | null | undefined;
      readonly creator_email: string | null | undefined;
      readonly id: number;
      readonly note: string;
      readonly person: {
        readonly id: number;
        readonly people_notes: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly people_notes_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      };
      readonly updated_at: any;
      readonly user_id: number | null | undefined;
    } | null | undefined;
  };
};
export type PersonNoteCreateMutation = {
  response: PersonNoteCreateMutation$data;
  variables: PersonNoteCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "note"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      }
    ],
    "concreteType": "PersonNote",
    "kind": "LinkedField",
    "name": "addPersonNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people_notes",
        "kind": "LinkedField",
        "name": "personNote",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_by",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creator_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people_notes",
                "kind": "LinkedField",
                "name": "people_notes",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "people_notes_aggregate",
                "kind": "LinkedField",
                "name": "people_notes_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "people_notes_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonNoteCreateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonNoteCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "40ab7f0956f9ef086df9c5d0d59f52d3",
    "id": null,
    "metadata": {},
    "name": "PersonNoteCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonNoteCreateMutation(\n  $note: String!\n  $personId: Int!\n) {\n  addPersonNote(note: $note, personId: $personId) {\n    personNote {\n      id\n      note\n      created_at\n      updated_at\n      created_by\n      creator_email\n      person {\n        id\n        people_notes {\n          id\n        }\n        people_notes_aggregate {\n          aggregate {\n            count\n          }\n        }\n      }\n      user_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4952c91d92dcd7dc1e9bccfcdbd5aad8";

export default node;
