import { Icon } from "@blueprintjs/core"
import React from "react"
import { ControlProps, OptionProps, components } from "react-select"

import Select, { ReactSelectProps } from "~/common/Select"

type Person = {
  id: number
  firstName: string
  lastName: string
  isLinked: boolean
}

type PersonOption = {
  label: string
  value: number | undefined
  isLinked: boolean
}

const Option = (props: OptionProps<PersonOption>) => {
  const { data } = props
  const { isLinked } = data

  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isLinked ? (
          <Icon icon="link" style={{ marginRight: 8 }} />
        ) : !data.value ? (
          <Icon icon="plus" style={{ marginRight: 8 }} />
        ) : null}
        <span>{data.label}</span>
      </div>
    </components.Option>
  )
}

const Control = (props: ControlProps<PersonOption>) => {
  const selectedPerson = props.getValue().at(0)

  return (
    <components.Control {...props}>
      {selectedPerson?.isLinked ? (
        <Icon icon="link" style={{ marginLeft: 10, marginRight: 8 }} />
      ) : (
        <Icon icon="add" style={{ marginLeft: 10, marginRight: 8 }} />
      )}
      {props.children}
    </components.Control>
  )
}

type SelectLinkedPersonProps = {
  options: Person[]
  value: number | undefined
} & Omit<ReactSelectProps, "options">

const SelectLinkedPerson = (props: SelectLinkedPersonProps) => {
  const { options, value, ...rest } = props

  const createNewOption: PersonOption = {
    label: "Create new",
    value: undefined,
    isLinked: false,
  }

  const modifiedOptions = options.map(
    (person): PersonOption => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person.id,
      isLinked: value === person.id,
    }),
  )

  // display the "Create New" option at the top of the list
  modifiedOptions.unshift(createNewOption)

  const modifiedValue = modifiedOptions.find((option) => {
    return option.value === value
  })

  return (
    <Select
      options={modifiedOptions}
      value={modifiedValue}
      defaultValue={createNewOption}
      components={{ Option, Control }}
      doNotSortOptions
      {...rest}
    />
  )
}

export default SelectLinkedPerson
