import { Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import React, { useEffect, useRef } from "react"

import styles from "./FilterBlockMenu.module.css"

import Select from "~/common/Select"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import { Cross as CrossIcon } from "~/common/react-icons"

type Props = {
  label: string
  onChange: (query: string, searchType: fe.filters.TextSearchType) => void
  searchType: fe.filters.TextSearchType
  onClose: () => void
  query: string
}

const FilterBlockTextMenu = (props: Props) => {
  const { query, onChange, onClose, searchType } = props

  const searchRef = useRef<HTMLInputElement>()

  const hasSearchValue = Boolean(query.trim())

  useEffect(() => {
    // auto-focus search when mounting component
    searchRef.current.focus()
  }, [])

  const handleApply = () => {
    onClose()
  }

  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApply()
    }
  }

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target

    onChange(value, searchType)
  }

  const handleResetSearch = () => {
    onChange("", searchType)
    searchRef.current.focus()
  }

  const setSearchType = (updatedSearchType: {
    label: string
    value: fe.filters.TextSearchType
  }) => {
    onChange(query, updatedSearchType.value)
  }

  return (
    <div className={styles.container}>
      <div
        className={cc([
          styles.search,
          {
            [styles.hasSearchValue]: hasSearchValue,
          },
        ])}
        style={{ borderBottom: "none" }}
      >
        <Select
          height={30}
          width={110}
          value={{
            value: searchType,
            label: searchType === "includes" ? "Includes" : "Exact",
          }}
          options={[
            {
              value: "includes",
              label: "Includes",
            },
            {
              value: "exact",
              label: "Exact",
            },
          ]}
          onChange={setSearchType}
        />
        <input
          className={styles.searchInput}
          type="text"
          placeholder="Search"
          onChange={handleChangeSearchValue}
          onKeyDown={onEnter}
          value={query}
          ref={searchRef}
        />
        <button
          onClick={handleResetSearch}
          className={styles.resetSearchButton}
        >
          <CrossIcon />
        </button>
      </div>

      <Tooltip
        content="Please type in a search term"
        placement="bottom"
        disabled={hasSearchValue}
      >
        <button
          className={styles.doneButton}
          onClick={handleApply}
          disabled={!hasSearchValue}
          data-test="SuperSearch_FilterBlockTextMenu_doneButton"
        >
          <TitledHelpTooltip
            title={hasSearchValue ? `Filter by "${query}"` : "Filter"}
            iconPosition="left"
            tooltipContent="Hint: you can use vertical bars (|) to create 'or' options e.g. One|Two|Three..."
          />
        </button>
      </Tooltip>
    </div>
  )
}

export default FilterBlockTextMenu
