import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { panelClosed } from "./panelSlice"

export type schedulePreviewState = {
  selectedPersonId: number | null
  selectedProjectId: number | null
}

const initialState: schedulePreviewState = {
  selectedPersonId: null,
  selectedProjectId: null,
}

const schedulePreviewSlice = createSlice({
  name: "schedulePreview",
  initialState,
  reducers: {
    personIdSelected: (state, action: PayloadAction<number>) => {
      state.selectedPersonId = action.payload
    },
    projectIdSelected: (state, action: PayloadAction<number>) => {
      state.selectedProjectId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(panelClosed, (state) => {
      state.selectedPersonId = null
      state.selectedProjectId = null
    })
  },
})

const { actions, reducer } = schedulePreviewSlice
export const { personIdSelected, projectIdSelected } = actions
export default reducer
