import { Icon, Menu, MenuDivider } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { format } from "date-fns"
import React, { useState } from "react"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { PlannerLeftColumn_account$data } from "./__generated__/PlannerLeftColumn_account.graphql"
import { PlannerLeftColumn_project$data } from "./__generated__/PlannerLeftColumn_project.graphql"
import { EditProjectForm_project$key } from "~/forms/EditProjectForm/__generated__/EditProjectForm_project.graphql"
import { CreateTemplateFromProjectForm_project$key } from "~/forms/ProjectTemplateForm/__generated__/CreateTemplateFromProjectForm_project.graphql"

import { track } from "~/helpers/analytics"
import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import * as hashids from "~/helpers/hashids"
import { PROJECT_DELETE_HINT } from "~/helpers/hints"
import routes, { viewProjectUrl } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import MenuItem from "~/common/MenuItem"
import { ModalFormWrapper } from "~/common/ModalForm"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import TentativeMenuItem from "~/common/TentativeMenuItem"
import { BookmarkOutline, DashboardIcon } from "~/common/react-icons"
import { TangramRocket } from "~/common/tangrams"

import {
  projectDuplicateRelay,
  projectRescheduleRelay,
} from "~/mutations/Project"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementSwitch,
  useShowUpsellingSection,
} from "~/Entitlements/useEntitlements"
import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import ArchiveModal from "~/Modals/ArchiveModal"
import DeleteModal from "~/Modals/Delete/DeleteModal"
import { usePermissions } from "~/Permissions/usePermissions"
import EditProjectBudget from "~/ProjectDashboard/Header/ProjectBudget/EditProjectBudget"
import { ViewExternalProjectLink } from "~/ProjectDashboard/Header/ViewExternalProjectLink"
import DashboardModalDuplicate from "~/dashboard/DashboardModalDuplicate"
import ModalReschedule from "~/dashboard/ModalReschedule"
import CreateProjectFormPlanner from "~/forms/CreateProject/CreateProjectPlanner"
import EditProjectForm from "~/forms/EditProjectForm/EditProjectForm"

import { ProjectMenuType } from "./ContextMenu"

type Props = {
  project: PlannerLeftColumn_project$data
  account: PlannerLeftColumn_account$data
  editProjectQuery: EditProjectForm_project$key
  createTemplateFromProjectQuery: CreateTemplateFromProjectForm_project$key
  closeMenu: () => void
  setDialogIsOpen?: (val: boolean) => void
  locatedInSchedulePanel?: boolean
  availableMenuItems: ProjectMenuType
}

const DIALOG_TYPE = {
  ARCHIVE: "ARCHIVE",
  RESCHEDULE: "RESCHEDULE",
  DUPLICATE: "DUPLICATE",
  EDIT: "EDIT",
  EDIT_BUDGET: "EDIT_BUDGET",
  SAVE_AS_TEMPLATE: "SAVE_AS_TEMPLATE",
  DELETE: "DELETE",
}

const MENU_ITEM_TYPE = {
  ...DIALOG_TYPE,
  OPEN_DASHBOARD: "OPEN_DASHBOARD",
}

const MENU_ITEM_TEXT = {
  [MENU_ITEM_TYPE.OPEN_DASHBOARD]: "Open Dashboard",
  [MENU_ITEM_TYPE.EDIT]: "Edit Details",
  [MENU_ITEM_TYPE.EDIT_BUDGET]: "Edit Budget",
  [MENU_ITEM_TYPE.RESCHEDULE]: "Reschedule",
  [MENU_ITEM_TYPE.DUPLICATE]: "Duplicate",
  [MENU_ITEM_TYPE.SAVE_AS_TEMPLATE]: "Save as Template",
  [MENU_ITEM_TYPE.ARCHIVE]: "Archive",
  [MENU_ITEM_TYPE.DELETE]: "Delete",
}

const ProjectPlannerMenu = (props: Props) => {
  const {
    project,
    account,
    editProjectQuery,
    createTemplateFromProjectQuery,
    closeMenu,
    setDialogIsOpen,
    locatedInSchedulePanel,
    availableMenuItems,
  } = props

  const [showDialog, setShowDialog] = useState(false)
  const [dialogType, setDialogType] = useState<keyof typeof DIALOG_TYPE>(null)
  const { isAdminWithManageAccount } = usePermissions()

  const {
    canViewDashboard,
    canEditDetails,
    canEditBudget,
    canTentative,
    canReschedule,
    canDuplicate,
    canSaveAsTemplate,
    canArchive,
    canDelete,
  } = availableMenuItems

  const entitledToProjectTemplates = useEntitlementSwitch(
    ChargebeeFeatures.projectTemplates,
  )
  const showUpsellingSection = useShowUpsellingSection()

  const openDialog = (selectedDialogType) => {
    setShowDialog(true)
    setDialogIsOpen(true)
    setDialogType(selectedDialogType)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
    setDialogIsOpen(false)
    closeMenu()
  }

  const handleReschedule = async (date: Date) => {
    await projectRescheduleRelay({
      variables: {
        id: project.id,
        date: format(date, "yyyy-MM-dd"),
      },
    })
    handleCloseDialog()
  }

  const handleDuplicate = async (date: string, name: string) => {
    await projectDuplicateRelay({
      variables: {
        input: {
          original_project_id: project.id,
          date,
          name,
        },
        todaysDate: dateHelpers.getTodaysDate(),
        plannerStartDate: PLANNER_INITIAL_DATE,
        includeProjectTemplates: false,
      },
    })
    handleCloseDialog()
  }

  const handleDelete = async () => {
    const hashid = hashids.projects.encode(project.id)
    const projectsListUrl = routes.projectsListUrl()
    const projectUrl = `${projectsListUrl}/${hashid}`
    await fetchWithCsrf(projectUrl, { method: "DELETE", redirect: "manual" })

    window.location.reload()
  }

  const renderDialog = () => {
    switch (dialogType) {
      case "ARCHIVE":
        const archive_hint = `You cannot archive projects with future assignments.
Please delete all future assignments.
If this project never occurred, use delete instead.`

        return (
          <ArchiveModal
            type="project"
            name={project.name}
            closeDialog={handleCloseDialog}
            customMessage={!project.archivable ? archive_hint : ""}
            archivable={project.archivable}
            hashid={hashids.projects.encode(project.id)}
            id={project.id}
            active={project.active}
            inPlanner={true}
          />
        )
      case "RESCHEDULE":
        return (
          <ModalReschedule
            closeDialog={handleCloseDialog}
            onSubmit={handleReschedule}
            currentStartDate={project.calc_start_date}
            hasActuals={project.has_actuals}
            projectName={project.name}
          />
        )
      case "DUPLICATE":
        return (
          <DashboardModalDuplicate
            closeDialog={handleCloseDialog}
            onSubmit={handleDuplicate}
            projectName={project.name}
          />
        )
      case "EDIT":
        return (
          <ModalFormWrapper
            headerTitle={`Edit ${project.name}`}
            wide
            tangram={<TangramRocket />}
            tangramStyles={{ marginBottom: 15 }}
          >
            <EditProjectForm
              projectQuery={editProjectQuery}
              onClose={handleCloseDialog}
            />
          </ModalFormWrapper>
        )
      case "EDIT_BUDGET":
        return (
          <EditProjectBudget
            projectId={project.id}
            onClose={handleCloseDialog}
          />
        )
      case "SAVE_AS_TEMPLATE":
        return (
          <CreateProjectFormPlanner
            closeDialog={handleCloseDialog}
            createTemplateFromProjectQuery={createTemplateFromProjectQuery}
            projectType="project template"
          />
        )
      case "DELETE":
        return (
          <DeleteModal
            type="project"
            name={project.name}
            isAdmin={isAdminWithManageAccount}
            adminCanDelete
            deletable={
              project.assignments_aggregate.aggregate.count === 0 &&
              project.actuals_aggregate.aggregate.count === 0
            }
            closeDialog={closeMenu}
            onSubmit={handleDelete}
            hint={PROJECT_DELETE_HINT}
          />
        )
      default:
        break
    }
  }

  const handleMenuItemClick = (menuType: keyof typeof MENU_ITEM_TYPE, e?) => {
    track("Project Context Menu Item Selected", {
      item: MENU_ITEM_TEXT[menuType],
    })

    if (menuType === "OPEN_DASHBOARD") {
      return true // Return true to execute the href
    }

    openDialog(menuType)
  }

  return (
    <>
      <Menu className={dropdownStyles.dropdown} style={{ padding: 0 }}>
        {canEditDetails && (
          <MenuItem
            text={MENU_ITEM_TEXT.EDIT}
            icon={<Icon icon="cog" />}
            onClick={() => handleMenuItemClick("EDIT")}
            shouldDismissPopover={false}
          />
        )}
        {canEditBudget && (
          <MenuItem
            text={MENU_ITEM_TEXT.EDIT_BUDGET}
            icon={<Icon icon="dollar" />}
            onClick={() => handleMenuItemClick("EDIT_BUDGET")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
        <MenuDivider />
        {canViewDashboard && (
          <MenuItem
            text={MENU_ITEM_TEXT.OPEN_DASHBOARD}
            icon={<DashboardIcon />}
            onClick={() => handleMenuItemClick("OPEN_DASHBOARD")}
            // Use href so users have the ability to Right Click -> Open Link in new Tab
            href={viewProjectUrl(hashids.projects.encode(project.id))}
          />
        )}
        <MenuDivider />
        {canTentative && (
          <TentativeMenuItem
            project={project}
            closeMenu={closeMenu}
            hidden={locatedInSchedulePanel}
          />
        )}
        {canReschedule && (
          <MenuItem
            text={MENU_ITEM_TEXT.RESCHEDULE}
            icon={<Icon icon="calendar" />}
            onClick={() => handleMenuItemClick("RESCHEDULE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
        {canEditDetails && canDuplicate && (
          <MenuItem
            text={MENU_ITEM_TEXT.DUPLICATE}
            icon={<Icon icon="duplicate" />}
            onClick={() => handleMenuItemClick("DUPLICATE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
        {canSaveAsTemplate &&
          canEditDetails &&
          entitledToProjectTemplates &&
          !showUpsellingSection && (
            <MenuItem
              text={MENU_ITEM_TEXT.SAVE_AS_TEMPLATE}
              icon={<BookmarkOutline />}
              onClick={() => handleMenuItemClick("SAVE_AS_TEMPLATE")}
              shouldDismissPopover={false}
              hidden={locatedInSchedulePanel}
            />
          )}
        <ViewExternalProjectLink
          accountRef={account}
          projectRef={project}
          menuItem={true}
        />
        <MenuDivider />
        {canArchive && (
          <MenuItem
            text={MENU_ITEM_TEXT.ARCHIVE}
            icon={<Icon icon="archive" />}
            onClick={() => handleMenuItemClick("ARCHIVE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
        {canDelete && (
          <MenuItem
            text={MENU_ITEM_TEXT.DELETE}
            icon={<Icon icon="trash" />}
            onClick={() => handleMenuItemClick("DELETE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}

        {canEditDetails && canSaveAsTemplate && showUpsellingSection && (
          <>
            <PaidFeatureMenuItemContainer roundedButton>
              <MenuItem
                text={MENU_ITEM_TEXT.SAVE_AS_TEMPLATE}
                icon={<BookmarkOutline />}
                onClick={() => handleMenuItemClick("SAVE_AS_TEMPLATE")}
                shouldDismissPopover={false}
                hidden={locatedInSchedulePanel}
                disabled={!entitledToProjectTemplates}
              />
            </PaidFeatureMenuItemContainer>
            <MenuDivider />
          </>
        )}
      </Menu>
      <Dialog isOpen={showDialog} onClose={handleCloseDialog}>
        {renderDialog()}
      </Dialog>
    </>
  )
}

export default ProjectPlannerMenu
