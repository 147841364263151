import { ConcreteRequest } from "relay-runtime"

import { HasuraSuccessResponse, fetchQuery } from "~/store/hasura"

import { useSuspendedPromise } from "~/hooks/useSuspendedPromise"

export const useHasuraQuery = <T extends object>(
  query: ConcreteRequest,
  variables: Record<string, any> = {},
) => {
  const document = {
    ...query,
    text: query.params.text,
  }
  const key = `${query}${JSON.stringify(variables)}`.replace(/\r?\n|\r/g, "")

  const result = useSuspendedPromise(key, () =>
    fetchQuery<T>(document, variables),
  )

  return result as HasuraSuccessResponse<T>
}
