/**
 * @generated SignedSource<<eded97a4d34387b550ed32e2f6d014f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhasesWrapper_project$data = {
  readonly id: number;
  readonly is_template: boolean;
  readonly phases: ReadonlyArray<{
    readonly color: string;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly name: string;
    readonly start_date: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PhaseItem_phase">;
  }>;
  readonly " $fragmentType": "PhasesWrapper_project";
};
export type PhasesWrapper_project$key = {
  readonly " $data"?: PhasesWrapper_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhasesWrapper_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhasesWrapper_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "phases",
      "kind": "LinkedField",
      "name": "phases",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PhaseItem_phase"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "e5c70218ae461bd0f5cc635b74dce497";

export default node;
