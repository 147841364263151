/**
 * @generated SignedSource<<bc563513b9e5ef448d89d98b794c13a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTags_account$data = {
  readonly tags: ReadonlyArray<{
    readonly archived: boolean;
    readonly id: number;
    readonly model: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "useTags_account";
};
export type useTags_account$key = {
  readonly " $data"?: useTags_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTags_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTags_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "tags",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "fd86831ba75d7ab72fc693aecf0a06b5";

export default node;
