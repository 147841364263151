import * as fe from "@runn/filter-engine"
import React from "react"

import FilterBlockCheckbox from "./common/FilterBlockCheckbox"
import type { FilterComponentProps } from "~/common/SuperSearch/types"
import { Tick as Icon } from "~/common/react-icons"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

type BaseProps = {
  filter: fe.filters.FilterPersonProjectCustomCheckbox
}

const OPTIONS = [
  {
    value: "YES" as const,
    label: "Yes",
  },
  {
    value: "NO" as const,
    label: "No",
  },
]

type Props = FilterComponentProps & BaseProps

const useValues = (props: BaseProps) => {
  const { filter } = props

  const selectedOption = filter.options.query

  return {
    name: filter.options.name,
    availableOptions: OPTIONS,
    selectedOption,
  }
}

const Component = (props: Props) => {
  const {
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (selection: boolean) => {
    onChange(
      path,
      fe.filters.personProjectCustomCheckbox({
        name: filter.options.name,
        typeId: filter.options.typeId,
        query: selection,
      }),
    )
  }

  const handleRemove = () => onRemove(path)

  const { selectedOption, name } = useValues({
    filter,
  })

  return (
    <FilterBlockCheckbox
      icon={<Icon width={16} height={16} />}
      label={name}
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
      selectedOption={selectedOption}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { filter } = props

  const { name, availableOptions } = useValues({
    filter,
  })

  return <FilterBlockReadonly name={name} selectedOptions={availableOptions} />
}

export const PersonProjectCustomCheckbox = {
  Component,
  ReadonlyComponent,
}
