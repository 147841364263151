import numbro from "numbro"

export const formatTime = (hours, abbrev = "h") => {
  if (!hours) {
    return `0${abbrev}`
  }
  return `${numbro(hours).format({
    trimMantissa: true,
    mantissa: 2,
    thousandSeparated: true,
  })}${abbrev}`
}

export const formatMinuteToHours = (minutes: number, abbrev = "h") => {
  if (!minutes) {
    return `0${abbrev}`
  }
  return `${numbro(minutes / 60).format({
    trimMantissa: true,
    mantissa: 2,
    thousandSeparated: true,
  })}${abbrev}`
}
