import { Icon, Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useCallback, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./SuperSearch.module.css"

import type { SuperSearch_user$key } from "./__generated__/SuperSearch_user.graphql"
import { SplitScreenPanel_user$data } from "~/Planner/SplitScreenPanel/__generated__/SplitScreenPanel_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { countPeoplePlaceholderMembers } from "~/helpers/project-member-helpers"

import { CrossIcon } from "~/common/react-icons"

import ViewsDropdown from "~/Views/Planner/ViewsDropdown"

import TentativeProjectsToggle, {
  TentativeProjectsToggleControlled,
} from "../PageControls/TentativeProjectsToggle"
import { ViewState } from "../views.reducer"

import FilterDropdownMenu from "./FilterDropdownMenu"
import FilterNameBadge from "./FilterNameBadge"
import FilterSaveButton from "./FilterSaveButton"
import FilterSetEditor from "./FilterSetEditor"
import WildSearchInput from "./WildSearchInput"
import type { Filter, FilterSet, Path, SuperSearchConfig } from "./types"

export const SERVER_SEARCH_DEBOUNCE_MS = 1000
export const LOCAL_SEARCH_DEBOUNCE_MS = 250

const fragment = graphql`
  fragment SuperSearch_user on users
  @argumentDefinitions(
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    account {
      id
      projects(where: $projectsFilter) {
        id
        name
        confirmed
        active
      }
      ...useClients_account
      ...usePeople_account @arguments(peopleFilter: $peopleFilter)
      ...useProjects_account @arguments(projectsFilter: $projectsFilter)
      ...useProjectTemplates_account @arguments(projectsFilter: $projectsFilter)
      ...useRoles_account
      ...useSkills_account
      ...useTags_account
      ...useTeams_account
      ...useCustomSelect_account
      ...useWorkstreams_account
      ...useManagers_account
      ...TentativeProjectsToggle_account
        @arguments(projectsFilter: $projectsFilter)
    }
    ...ViewsDropdown_user
      @arguments(peopleFilter: $peopleFilter, projectsFilter: $projectsFilter)
  }
`

type Props<TFilterSet extends FilterSet> = {
  children?: React.ReactNode
  filterDropdownChildren?: React.ReactNode
  config: SuperSearchConfig
  location: string

  wildSearchQuery?: string | undefined
  onChangeWildSearchQuery?: (query: string | undefined) => void

  filterSet: TFilterSet
  onChangeFilterSet?: (filterSet: TFilterSet | typeof emptyFilterSet) => void

  savedFilterSets?: TFilterSet[]
  onCreateSavedFilterSet?: (filterSet: TFilterSet) => void
  onDeleteSavedFilterSet?: (filterSet: TFilterSet) => void

  viewState?: ViewState
  handleChangeViewId?: (viewId: number) => void
  handleChangeShowAllView?: () => void

  project?: SplitScreenPanel_user$data["account"]["projects"][0]
  disabled?: boolean
  user?: SuperSearch_user$key
  showIncludeArchivedProjectsToggle?: boolean
  showSavedFilterSets?: boolean
} & (
  | {
      enabledTentativeProjects: Array<number>
      onTentativeChange?: (projectIDs: Array<number>) => void
      onTentativeApply?: (projectIDs: Array<number>) => void
      onEnableTentativeProjects?: (projectIDs: Array<number>) => void
      onDisableTentativeProjects?: (projectIDs: Array<number>) => void
      onToggleTentativeProject?: (projectID: number) => void
    }
  | {
      enabledTentativeProjects?: never
      onTentativeChange?: never
      onTentativeApply?: never
      onEnableTentativeProjects?: never
      onDisableTentativeProjects?: never
      onToggleTentativeProject?: never
    }
)
export const emptyFilterSet = {
  name: "",
  filters: null,
} as const

const noop = () => void 0

const isPersonProjectFilter = (filterTypeList: string[]) =>
  filterTypeList.some(
    (filterType) =>
      filterType === "person_wild_search" ||
      (filterType.startsWith("person_project_") &&
        filterType !== "person_project_id"),
  )

const SuperSearch = <TFilterSet extends FilterSet>(
  props: Props<TFilterSet>,
) => {
  const {
    children,
    filterDropdownChildren,
    config,
    location,
    wildSearchQuery,
    filterSet,
    savedFilterSets = [],
    viewState,
    onChangeWildSearchQuery = noop,
    onChangeFilterSet = noop,
    onCreateSavedFilterSet = noop,
    onDeleteSavedFilterSet = noop,
    handleChangeViewId,
    handleChangeShowAllView,
    enabledTentativeProjects,
    onTentativeChange,
    onEnableTentativeProjects,
    onDisableTentativeProjects,
    onToggleTentativeProject,
    onTentativeApply,
    disabled,
    project,
    user,
    showIncludeArchivedProjectsToggle,
    showSavedFilterSets,
  } = props

  const hasura = useHasuraContext({ strict: false })
  const gql = useFragment<SuperSearch_user$key>(fragment, user ?? hasura)

  // path to filter that is currently active (i.e. the dropdown is open)
  const [activePath, setActivePath] = useState<Path | undefined>(undefined)
  const [isExpanded, setIsExpanded] = useState(false)
  const [query, setQuery] = useState(wildSearchQuery)

  useEffect(() => {
    setQuery(wildSearchQuery)
  }, [wildSearchQuery])

  const handleExpand = () => {
    setActivePath(undefined)
    setIsExpanded(!isExpanded)
  }

  const handleClickAvailableFilter = (selectedFilter: Filter) => {
    track("Search Filter Selected", { value: selectedFilter.type, location })
    const nextFilterList = fe.filters.simplify(
      fe.filters.and([filterSet.filters, selectedFilter]),
    ) as TFilterSet["filters"]

    // now we need to find the path to this filter
    const path = fe.filters.findPath(nextFilterList, (f) => {
      return f === selectedFilter
    })

    onChangeFilterSet({ name: "", filters: nextFilterList })
    setActivePath(path || undefined)
    setIsExpanded(true)
  }

  const handleClickSavedFilterSet = (selectedFilterSet: TFilterSet) => {
    track("Search Saved Filter Selected")
    onChangeFilterSet(selectedFilterSet)
    setIsExpanded(false)
  }

  const handleSaveFilterSet = (newFilterSet: TFilterSet) => {
    track("Search Filter Saved")
    onChangeFilterSet(newFilterSet)
    onCreateSavedFilterSet(newFilterSet)
    setIsExpanded(false)
  }

  const handleRemoveSavedFilterSet = (selectedFilterSet: TFilterSet) => {
    onDeleteSavedFilterSet(selectedFilterSet)
    track("Search Saved Filter Removed")
  }

  const handleResetSavedFilter = () => {
    onChangeFilterSet({
      name: "",
      filters: null,
    })
    setActivePath(undefined)
    setIsExpanded(true)
  }

  const handleProjectMembersFilter = () => {
    track("Search Split Screen Project Members Filter Selected")

    const newFilterSet = {
      name: "",
      filters: fe.filters.simplify(
        fe.filters.and([
          filterSet.filters,
          fe.filters[
            project?.is_template
              ? "personProjectTemplateId"
              : "personMembership"
          ]({
            list: project ? [project.id] : [],
          }),
        ]),
      ),
    } as TFilterSet

    onChangeFilterSet(newFilterSet)
    setIsExpanded(true)
  }

  const allSavedFilterSets = [...config.savedFilterSets, ...savedFilterSets]
  const isSavedFilterSet = allSavedFilterSets.some((item) => {
    return (
      item.name === filterSet.name &&
      fe.filters.isEqual(item.filters, filterSet.filters)
    )
  })

  const handleReset = () => {
    setQuery("")
    onChangeWildSearchQuery("")
    onChangeFilterSet({ name: "", filters: null })
    track("Search Cleared")
  }

  const handleWildQueryChange = useCallback(
    (q) => {
      setQuery(q)
      onChangeWildSearchQuery(q)
    },
    [onChangeWildSearchQuery],
  )

  const filterTypeList = fe.filters.asTypeList(filterSet.filters)
  const hasPersonProjectFilter = isPersonProjectFilter(filterTypeList)

  const hasPersonProjectFilterSearch =
    query?.trim().length > 0 || hasPersonProjectFilter

  const hasMultipleMembers =
    project && countPeoplePlaceholderMembers(project.members).people > 1

  const showSplitScreenMembersFilter =
    config.splitScreenIsEnabled && hasMultipleMembers
  const showViewsDropdown =
    location !== "views" && location !== "chart" && location !== "activity"

  return (
    <div className={cc([styles.container, { [styles.disabled]: disabled }])}>
      {useFeature("pre_filtered_views") && showViewsDropdown && (
        <ViewsDropdown
          user={gql}
          viewState={viewState}
          handleChangeViewId={handleChangeViewId}
          handleShowAllView={handleChangeShowAllView}
          hideEdit={location !== "planner"}
        />
      )}
      {config.features.tentativeProjectsToggle && (
        <div className={styles.tentativeProjectsToggle}>
          {Array.isArray(enabledTentativeProjects) ? (
            <TentativeProjectsToggleControlled
              projects={gql.account.projects}
              enabledTentativeProjects={enabledTentativeProjects}
              onEnableProjects={onEnableTentativeProjects}
              onDisableProjects={onDisableTentativeProjects}
              onToggleProject={onToggleTentativeProject}
              onApply={onTentativeApply}
              onChange={onTentativeChange}
            />
          ) : (
            <TentativeProjectsToggle account={gql.account} />
          )}
        </div>
      )}
      {config.features.menu && (
        <FilterDropdownMenu
          savedFilterSets={allSavedFilterSets}
          config={config}
          onClickSavedFilterSet={handleClickSavedFilterSet}
          onClickAvailableFilter={handleClickAvailableFilter}
          onRemoveSavedFilterSet={handleRemoveSavedFilterSet}
          onClickProjectMembersFilter={handleProjectMembersFilter}
          showSplitScreenMembersFilter={showSplitScreenMembersFilter}
          projectName={project?.name}
          isTemplate={project?.is_template}
          customPlacement={location === "views" ? "right" : undefined}
          showIncludeArchivedProjectsToggle={showIncludeArchivedProjectsToggle}
          isSearchFilterActive={hasPersonProjectFilterSearch}
          showSavedFilterSets={showSavedFilterSets}
        >
          {filterDropdownChildren}
        </FilterDropdownMenu>
      )}

      {config.features.clearable &&
      ((config.features.menu && filterSet.filters) || query) ? (
        <div
          className={cc([
            styles.resetButtonWrapper,
            {
              [styles.disabled]: !config.features.editFilter,
            },
          ])}
        >
          <Tooltip content="Clear all" placement="top">
            <button
              onClick={handleReset}
              className={styles.resetButton}
              data-test="SuperSearch_clearAll_Button"
            >
              <CrossIcon size={14} />
            </button>
          </Tooltip>
        </div>
      ) : (
        <>
          {config.features.wildSearch && (
            <div className={styles.resetButtonWrapper}>
              <Icon icon="search" color={"var(--shadow)"} />
            </div>
          )}
        </>
      )}

      {children}

      {config.features.saveFilter && !isSavedFilterSet && (
        <FilterSaveButton
          disabled={!config.features.editFilter}
          filterSet={filterSet}
          inputPlaceholder={config.saveFilterPlaceholder}
          onSave={handleSaveFilterSet}
        />
      )}

      {isSavedFilterSet && (
        <FilterNameBadge
          disabled={!config.features.editFilter}
          filterSet={filterSet}
          isExpanded={isExpanded}
          onClick={handleExpand}
          onReset={handleResetSavedFilter}
        />
      )}

      {(!isSavedFilterSet || isExpanded) && (
        <FilterSetEditor
          gql={gql}
          disabled={!config.features.editFilter}
          filterSet={filterSet}
          activePath={activePath}
          onChangeActivePath={setActivePath}
          onChangeFilterSet={onChangeFilterSet}
          disabledDropdowns={config.availableFilters
            .filter((f) => f.disableDropdown)
            .map((f) => f.filter.type)}
        />
      )}

      {config.features.wildSearch && (
        <>
          <WildSearchInput
            disabled={!config.features.editWildSearch}
            placeholder={config.wildSearchPlaceholder}
            value={query}
            onChange={handleWildQueryChange}
            location={location}
            autoFocus={config.autoFocusWildSearch}
          />
        </>
      )}
    </div>
  )
}

export default SuperSearch
