import * as fe from "@runn/filter-engine"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { PeoplePlanner_account$key } from "~/PeoplePlanner/__generated__/PeoplePlanner_account.graphql"

import OverflowBanner from "~/common/Banner/OverflowBanner"
import { usePersonSearchConfig } from "~/common/SuperSearch"

import { createFilterSet, deleteFilterSet } from "~/mutations/FilterSet"

import ChartPanel from "~/Planner/ChartPanel"
import { useActiveQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import {
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "~/Planner/Planner.actions"
import { PlannerHeader } from "~/Planner/PlannerLayout"
import { PersonSchedulePanel } from "~/Planner/SchedulePanels/PersonSchedulePanel"
import { ProjectSchedulePanel } from "~/Planner/SchedulePanels/ProjectSchedulePanel"
import SplitScreenPanel from "~/Planner/SplitScreenPanel"
import { useAppSelector } from "~/hooks/redux"
import { useMigrateSavedFilterSetsToDatabase } from "~/hooks/useMigrateSavedFilterSetsToDatabase"
import { SavedFilterSet, useFilterSets } from "~/queries/FilterSet"

import PeoplePlannerList from "./PeoplePlannerList"

const PeoplePlanner = (props: { account: PeoplePlanner_account$key }) => {
  const dispatch = useDispatch()

  useMigrateSavedFilterSetsToDatabase({
    localStorageKey: "savedPeopleFilterSetList",
    filterSetType: "person",
  })

  const multiSelectEnabled = useAppSelector(
    (state) => state.multiSelect.isEnabled,
  )
  const showPanel = useAppSelector((state) => state.plannerV2.panel.showPanel)
  const queryState = useActiveQueryLimit()
  const isOverflow = queryState.isOverflow

  const wildSearchQuery = useAppSelector(
    (state) => state.planner.peopleWildSearch,
  )

  const filterSet = useAppSelector((state) => state.planner.peopleFilterSet)

  const savedFilterSets = useFilterSets({ type: "person" })

  const account = useFragment(
    graphql`
      fragment PeoplePlanner_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        ...PlannerHeader_account @arguments(projectsFilter: $projectsFilter)
      }
    `,
    props.account,
  )
  const handleChangeWildSearchQuery = (query: string | undefined) => {
    return dispatch(setPeopleWildSearch(query))
  }

  const handleChangeFilterSet = (
    updatedFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    return dispatch(setPeopleFilterSet(updatedFilterSet))
  }

  const handleCreateSavedFilterSet = async (
    newFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    await createFilterSet({
      filterSet: {
        type: "person",
        name: newFilterSet.name,
        filters: filterSet.filters,
      },
    })
  }

  const handleDeleteSavedFilterSet = async (savedFilterSet: SavedFilterSet) => {
    await deleteFilterSet({ filterSetId: savedFilterSet.id })
  }

  const personSearchConfig = usePersonSearchConfig({
    excludedFilters: ["person_state"],
  })

  return (
    <>
      <PlannerHeader
        account={account}
        superSearchConfig={personSearchConfig}
        wildSearchQuery={wildSearchQuery}
        filterSet={filterSet}
        savedFilterSets={savedFilterSets}
        onChangeWildSearchQuery={handleChangeWildSearchQuery}
        onChangeFilterSet={handleChangeFilterSet}
        onCreateSavedFilterSet={handleCreateSavedFilterSet}
        onDeleteSavedFilterSet={handleDeleteSavedFilterSet}
        showIncludeArchivedProjectsToggle
      />
      <PeoplePlannerList />
      {match({ showPanel, isOverflow, multiSelectEnabled })
        .with(
          { isOverflow: true, showPanel: null, multiSelectEnabled: false },
          () => <OverflowBanner />,
        )
        .with({ showPanel: "charts" }, () => <ChartPanel />)
        .with({ showPanel: "projectSchedule" }, () => <ProjectSchedulePanel />)
        .with({ showPanel: "personSchedule" }, () => <PersonSchedulePanel />)
        .with({ showPanel: "splitScreen" }, () => <SplitScreenPanel />)
        .otherwise(() => null)}
    </>
  )
}

export default PeoplePlanner
