/**
 * @generated SignedSource<<3710abdedeb16bce47cfd90661dc014f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectAddPersonRow_account$data = {
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly assignments: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly email: string | null | undefined;
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AddPersonToProject_account">;
  readonly " $fragmentType": "ProjectAddPersonRow_account";
};
export type ProjectAddPersonRow_account$key = {
  readonly " $data"?: ProjectAddPersonRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectAddPersonRow_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectAddPersonRow_account",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "_gte",
                      "variableName": "plannerStartDate"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "end_date_iso"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "assignments",
          "kind": "LinkedField",
          "name": "assignments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "start_date",
              "args": null,
              "kind": "ScalarField",
              "name": "start_date_runn",
              "storageKey": null
            },
            {
              "alias": "end_date",
              "args": null,
              "kind": "ScalarField",
              "name": "end_date_runn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "person_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "project_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workstream_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AddPersonToProject_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "e0090af8a0b9d5204c3021fa365e8081";

export default node;
