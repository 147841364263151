import {
  people_bool_exp,
  projects_bool_exp,
} from "./__generated__/PlannerContainerQuery.graphql"

import { getPeopleFilter, getProjectsFilter } from "~/helpers/planner-helpers"

import { useAppSelector } from "~/hooks/redux"
import useDeferredValueStringified from "~/hooks/useDeferredValueStringified"

import { useFilteredPlanner } from "./FilteredQuery/useFilteredPlanner"

const getFilter = <BoolExp extends people_bool_exp | projects_bool_exp>(
  temporaryFilter: BoolExp | "all" | null,
  existingFilter: BoolExp | null,
): BoolExp => {
  if (temporaryFilter === "all") {
    return null
  }

  if (temporaryFilter) {
    return {
      _or: [...existingFilter._or, temporaryFilter],
    } as unknown as BoolExp
  }

  return existingFilter
}

const usePlannerFilters = () => {
  let projectsFilter: projects_bool_exp | null = null
  let peopleFilter: people_bool_exp | null = null

  const currentViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )
  const { peopleIDs: viewPeopleIDs, projectIDs: viewProjectIDs } =
    useFilteredPlanner()
  const additionalPlannerIds = useAppSelector(
    (state) => state.plannerV2.views.additionalPlannerIds,
  )[currentViewId] ?? { projectIds: [], peopleIds: [] }
  const deferredAdditionalPlannerIds =
    useDeferredValueStringified(additionalPlannerIds)

  const showAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )
  const temporaryPlannerFilter = useAppSelector(
    (state) => state.plannerV2.views.temporaryPlannerFilter,
  )
  const viewsEnabled = useAppSelector((state) => state.plannerV2.views.enabled)

  const allProjectIDs = [
    ...deferredAdditionalPlannerIds.projectIds,
    ...viewProjectIDs,
  ]
  const allPeopleIDs = [
    ...deferredAdditionalPlannerIds.peopleIds,
    ...viewPeopleIDs,
  ]
  const temporaryProjectsFilters = useDeferredValueStringified(
    temporaryPlannerFilter.projectFilters,
  )
  const temporaryPeopleFilters = useDeferredValueStringified(
    temporaryPlannerFilter.peopleFilters,
  )
  const allProjectPeopleShown = !viewsEnabled || showAllView

  if (!allProjectPeopleShown && (allProjectIDs.length || allPeopleIDs.length)) {
    projectsFilter = getFilter(
      temporaryProjectsFilters,
      getProjectsFilter(allProjectIDs, allPeopleIDs),
    )
    peopleFilter = getFilter(
      temporaryPeopleFilters,
      getPeopleFilter(allProjectIDs, allPeopleIDs),
    )
  }

  return {
    projectsFilter,
    peopleFilter,
  }
}

export default usePlannerFilters
