import React, { useMemo } from "react"
import {
  Bar,
  Cell,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import styles from "~/Planner/ChartPanel/Chart/common.module.css"

import { roundTwoDecimals } from "~/helpers/DurationHelper"

import TooltipContent from "~/Planner/ChartPanel/Chart/common/TooltipContent"

import { useChartData } from "~/Planner/ChartPanel/Chart/useChartData"
import type { ChartProps } from "~/Planner/ChartPanel/types"
import { useAppSelector } from "~/hooks/redux"

type Payload = {
  effectiveCapacity: number
  workloadTotal: number
  availability: number
}

const getRoundedValue = (value: number): string => roundTwoDecimals(value) + "h"

const ChartAvailability = (props: ChartProps) => {
  const {
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
    enableAnimations,
    formatFTE,
    getTicks,
  } = props

  const barSize = period === "weekly" ? 250 : 25

  const data = useChartData({
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
  })

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const showFTE = effortDisplayUnit === "fullTimeEquivalent"

  const formatText = (label: string, val: number) => {
    if (showFTE) {
      return { label, value: `${formatFTE(val)} FTE` }
    }
    return { label, value: getRoundedValue(val) }
  }

  const getTooltipRows = (payload: Payload) => {
    return [
      formatText("Effective Capacity", payload.effectiveCapacity),
      formatText("Workload Total", payload.workloadTotal),
      formatText("Availability", payload.availability),
    ]
  }

  const getYAxisValue = (val: number): string => {
    if (!showFTE) {
      return `${val}h`
    }
    return `${formatFTE(val)} FTE`
  }
  const [smallestValue, largestValue] = useMemo(
    () =>
      data.reduce(
        ([min, max], { availability }) => [
          Math.min(min, availability),
          Math.max(max, availability),
        ],
        [0, 0],
      ),
    [data],
  )
  const ticks = useMemo(
    () => getTicks(smallestValue, largestValue, showFTE),
    [getTicks, smallestValue, largestValue, showFTE],
  )

  // We create a YAxis separately so that we can move it around
  return (
    <>
      <div className={styles.yAxis}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: -10, bottom: 0 }}
          >
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              interval="preserveStartEnd"
              dataKey="availability"
              ticks={ticks}
              tickFormatter={getYAxisValue}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.chartContainer}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip
              content={
                <TooltipContent
                  data={data}
                  getRows={getTooltipRows}
                  period={period}
                />
              }
              isAnimationActive={false}
              offset={10}
            />
            <XAxis dataKey="formattedDate" hide />
            {/* Y Axis */}
            <YAxis type="number" ticks={ticks} hide />
            <Bar
              dataKey="availability"
              barSize={barSize}
              fill="var(--lavender)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.availability > 0 ? "var(--mint)" : "var(--alert)"}
                />
              ))}
            </Bar>
            <ReferenceLine y={0} stroke="var(--sea-blue)" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export { ChartAvailability }
