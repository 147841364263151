import React from "react"
import { graphql, useFragment } from "react-relay"

import plannerDatePickerStyles from "./PlannerDatePicker.module.css"

import { TimeOffEffortInputs_account$key } from "./__generated__/TimeOffEffortInputs_account.graphql"

import { EffortInput } from "~/common/EffortInput/EffortInput"
import NumericInput from "~/common/NumericInput"

import { TotalEffortInput } from "../EffortInput/TotalEffortInput"

type Props = {
  account: TimeOffEffortInputs_account$key
  totalWorkingDays: {
    value: number
    label: string
  }
  minutesPerDay: number
  maxMinutesPerDay: number
  onMinutesPerDayChange: (mins: number, err: any) => void
  selectedDates: Date[]
  onChangeWorkingDays: (days: number) => void
  personFirstName: string
  total: {
    value: number
    label: string
  }
  hasWorkingDays?: boolean
}

const TimeOffEffortInputs = (props: Props) => {
  const {
    minutesPerDay,
    maxMinutesPerDay,
    onMinutesPerDayChange,
    totalWorkingDays,
    selectedDates,
    onChangeWorkingDays,
    personFirstName,
    total,
    hasWorkingDays = true,
  } = props

  const account = useFragment(
    graphql`
      fragment TimeOffEffortInputs_account on accounts {
        id
        ...EffortInput_account
        ...TotalEffortInput_account
      }
    `,
    props.account,
  )

  const maxMinutesPerDayLabel = `${personFirstName}'s full time hours`
  const subLabel =
    minutesPerDay === maxMinutesPerDay ? "Full Day Off" : "Partial Day Off"
  const minMinutes = 15

  return (
    <div className={plannerDatePickerStyles.stacking}>
      <div className={plannerDatePickerStyles.stats}>
        <div data-test="effortContainer">
          <EffortInput
            account={account}
            minutesPerDay={minutesPerDay}
            onChange={onMinutesPerDayChange}
            minMinutes={minMinutes}
            maxMinutes={maxMinutesPerDay}
            maxMinutesLabel={maxMinutesPerDayLabel}
            effortDisplayUnit="hoursPerDay"
            label="Hours Off per Day"
            hideInfoTooltip
            isTimeOff
            contractMinutes={maxMinutesPerDay}
          />
        </div>
        {hasWorkingDays && (
          <div data-test="workingDaysContainer">
            <label>Work Days</label>
            <div>
              <NumericInput
                id="workingDays"
                min={1}
                max={365 * 10}
                value={totalWorkingDays.label || ""}
                onValueChange={onChangeWorkingDays}
                disabled={!selectedDates[0] && !selectedDates[1]}
                className={plannerDatePickerStyles.workingDaysInput}
                integer
                showArrows
                invokeChangeOnClamp={true}
              />
            </div>
          </div>
        )}
        <TotalEffortInput
          account={account}
          minutesPerDay={total.value}
          workingDayCount={totalWorkingDays.value}
          onMinutesPerDayChange={(minutes) =>
            onMinutesPerDayChange(minutes, false)
          }
          maxMinutes={maxMinutesPerDay * totalWorkingDays.value}
          minMinutes={minMinutes}
          hideSelect
          label="Total Hours"
          contractMinutes={maxMinutesPerDay}
          isTimeOff
        />
      </div>
      <p className={plannerDatePickerStyles.subLabel}>{subLabel}</p>
    </div>
  )
}

export default TimeOffEffortInputs
