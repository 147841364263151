import { graphql } from "react-relay"

import { useBulkImportExtProjectListMutation as Mutation } from "./__generated__/useBulkImportExtProjectListMutation.graphql"

import { useMutationPromise } from "~/mutations/helpers"

const useBulkImportExtProjectList = () => {
  return useMutationPromise<Mutation>(graphql`
    mutation useBulkImportExtProjectListMutation(
      $input: BulkImportExtProjectListInput!
    ) {
      bulkImportExtProjectList(input: $input) {
        accountId
      }
    }
  `)
}

export { useBulkImportExtProjectList }
