/**
 * @generated SignedSource<<e708a0a90978f2597782b07cebfd7930>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageManager_user$data = {
  readonly account: {
    readonly account_type: string;
    readonly " $fragmentSpreads": FragmentRefs<"PeoplePlanner_account" | "ProjectPlanner_account">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AccountWelcome_user">;
  readonly " $fragmentType": "PageManager_user";
};
export type PageManager_user$key = {
  readonly " $data"?: PageManager_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageManager_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "projectsFilter",
  "variableName": "projectsFilter"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageManager_user",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "AccountWelcome_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_type",
          "storageKey": null
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "ProjectPlanner_account"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "PeoplePlanner_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "9d331c3f7490f16262efecba0c76eb1b";

export default node;
