/**
 * @generated SignedSource<<d977e5fdee28b44d2e45a5350d3e3cfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSidePanelNotes_person$data = {
  readonly email: string | null | undefined;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly people_notes: ReadonlyArray<{
    readonly created_at: any;
    readonly id: number;
    readonly user_id: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PersonNote_note">;
  }>;
  readonly " $fragmentType": "PersonSidePanelNotes_person";
};
export type PersonSidePanelNotes_person$key = {
  readonly " $data"?: PersonSidePanelNotes_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanelNotes_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSidePanelNotes_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people_notes",
      "kind": "LinkedField",
      "name": "people_notes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user_id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonNote_note"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "ad6118391139a651a75a03f6eb7d2eb0";

export default node;
