import { partition } from "lodash-es"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import type { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"
import { ProjectManagement_user$key } from "~/ProjectPlanner/__generated__/ProjectManagement_user.graphql"

import { groupProjectsBy } from "~/helpers/group-helpers"
import { sortProjectsBy } from "~/helpers/sorting-helpers"

import AddSection from "~/common/AddSection"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import NoResultsForFilter from "~/Planner/NoResultsForFilter"
import {
  filtersCleared,
  searchCleared,
} from "~/Planner/reducer2/projectFilterSlice"
import {
  selectGroupProjectBy,
  selectSortProjectBy,
} from "~/Planner/reducer2/projectSortSlice"
import { useAppSelector } from "~/hooks/redux"

import AddNewProject from "./AddNewProject"
import ProjectGroupList from "./ProjectGroupList"
import ProjectTemplateGroup from "./ProjectTemplateGroup"

type Project = ProjectManagement_user$data["account"]["projects"][0]

type WithFavourite<T> = T & { is_favourite: boolean }

const withFavourites = (
  favouriteProjectIds: number[],
  projectList: Project[],
): WithFavourite<Project>[] =>
  projectList.map((project) => ({
    ...project,
    is_favourite: favouriteProjectIds.includes(project.id),
  }))

type Props = {
  user: ProjectManagement_user$key
  filteredProjectIds: Set<number>
}

const ProjectManagement = (props: Props) => {
  const { filteredProjectIds } = props
  const {
    projects: { isOverflow },
  } = useQueryLimit()

  const user = useFragment(
    graphql`
      fragment ProjectManagement_user on users
      @argumentDefinitions(
        todaysDate: { type: "date!" }
        projectsFilter: { type: "projects_bool_exp" }
      ) {
        ...ProjectTemplateGroup_user
        ...ProjectGroupList_user
        id
        favourite_projects
        account {
          id
          name
          views {
            id
          }
          custom_select_types {
            id
            name
            model
            options: custom_select_options {
              id
              name
            }
          }
          projects(where: $projectsFilter) {
            id
            priority
            name
            confirmed
            is_template
            tags_computed
            workstreams_computed
            created_at
            calc_start_date
            calc_end_date
            references
            pricing_model_readable
            active
            team_id
            has_actuals
            created_at
            team {
              id
              name
            }
            client {
              id
              name
              active
              image_key
            }
            members {
              id
              person_id
              role_id
              workstream_id
            }
            custom_select_values {
              typeId: custom_select_type_id
              optionId: custom_select_option_id
            }
            ...ProjectRowSummary_project @arguments(todaysDate: $todaysDate)
            ...PhasesWrapper_project
            ...ProjectDetailsSection_project
            ...ProjectAddPersonRow_project
            ...ProjectGroupRow_project
            ...DetectedPeopleSection_project
          }
          ...ProjectGroupList_account
            @arguments(
              plannerStartDate: $plannerStartDate
              projectsFilter: $projectsFilter
              peopleFilter: $peopleFilter
            )
          ...ProjectTemplateGroup_account
            @arguments(
              plannerStartDate: $plannerStartDate
              projectsFilter: $projectsFilter
              peopleFilter: $peopleFilter
            )
        }
      }
    `,
    props.user,
  )

  const { id: userId, favourite_projects: favouriteProjectIds, account } = user
  const { projects: allProjects } = account

  const dispatch = useDispatch()
  const projectSort = useAppSelector((state) =>
    selectSortProjectBy(state.plannerV2.project),
  )

  const projectGroupBy = useAppSelector((state) =>
    selectGroupProjectBy(state.plannerV2.project),
  )

  const filterSet = useAppSelector((state) => state.planner.projectFilterSet)

  const handleClearFilters = () => {
    dispatch(filtersCleared())
    dispatch(searchCleared())
  }

  if (filteredProjectIds.size === 0 && allProjects.length > 0) {
    return (
      <NoResultsForFilter
        subject="projects"
        onClearFilters={filterSet.filters && handleClearFilters}
        accountHasViews={account.views.length > 0}
      />
    )
  }

  const withFavs = withFavourites(
    favouriteProjectIds,
    allProjects.filter((project) => filteredProjectIds.has(project.id)),
  )

  const allProjectsList = sortProjectsBy(withFavs, projectSort)
  const [projectList, templateList] = partition(allProjectsList, {
    is_template: false,
  })
  const customFields = account.custom_select_types.filter(
    (f) => f.model === "PROJECT",
  )
  const projectGroupList = groupProjectsBy(
    projectList,
    projectGroupBy,
    customFields,
  )

  return (
    <>
      {!isOverflow && !!templateList?.length && (
        <ProjectTemplateGroup
          account={account}
          userId={userId}
          user={user}
          projectTemplateCount={templateList?.length}
          projectTemplateList={templateList}
          favouriteProjectIds={favouriteProjectIds}
        />
      )}
      <ProjectGroupList
        account={account}
        userId={userId}
        user={user}
        projectCount={projectList.length}
        projectGroupList={projectGroupList}
        favouriteProjectIds={favouriteProjectIds}
      />
      <div
        style={{
          borderBottom: "1px solid var(--winter)",
          gridColumn: "1 / -1",
        }}
      />
      {!isOverflow && (
        <AddNewProject hasNoProjects={allProjects.length === 0} />
      )}
      <div style={{ flex: 1, display: "flex" }}>
        <AddSection />
      </div>
    </>
  )
}

export default ProjectManagement
