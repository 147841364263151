import { Tab } from "@blueprintjs/core"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { match } from "ts-pattern"

import styles from "./PersonSidePanel.module.css"

import { PersonSidePanel_person$key } from "./__generated__/PersonSidePanel_person.graphql"
import { PersonSidePanel_user$key } from "./__generated__/PersonSidePanel_user.graphql"

import { track } from "../../helpers/analytics"
import { formatName, getSecondaryPersonField } from "~/helpers/person"

import { ModalBody, ModalFooter } from "~/common/ModalForm"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import SidePanelHeader from "~/common/SidePanel/SidePanelHeader"
import Tabs from "~/common/Tabs"
import Button from "~/common/buttons/Button"

import { usePermissions } from "~/Permissions/usePermissions"
import EditPersonForm from "~/forms/EditPersonForm/EditPersonForm"

import PersonActionButtons from "./PersonActionButtons"
import Contract from "./PersonSidePanelContract"
import Details from "./PersonSidePanelDetails"
import PersonSidePanelNotes from "./PersonSidePanelNotes"

type TabType = "details" | "contract" | "notes"

type Props = {
  person: PersonSidePanel_person$key
  user: PersonSidePanel_user$key
  initialTab?: TabType
}

const PersonSidePanel = ({ initialTab, ...props }: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonSidePanel_person on people {
        id
        first_name
        last_name
        email
        image_key
        team {
          id
          name
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          role {
            id
            name
          }
          job_title
        }
        people_notes_aggregate {
          aggregate {
            count
          }
        }
        ...PersonSidePanelDetails_person
        ...PersonCustomEditor_person
        ...PersonActionButtons_person
        ...EditPersonForm_person
        ...PersonSidePanelContract_person
        ...PersonSidePanelNotes_person
      }
    `,
    props.person,
  )

  const user = useFragment(
    graphql`
      fragment PersonSidePanel_user on users {
        id
        favourite_people
        account {
          id
          secondary_person_field
          ...PersonSidePanelDetails_account
          ...PersonSidePanelContract_account
        }
        ...PersonActionButtons_user
        ...EditPersonForm_user
      }
    `,
    props.user,
  )

  const { account } = user

  const { openPanel } = useSidePanel()

  const { can, subject } = usePermissions()
  const canEditPerson = can("edit", subject("Person", person))

  const [activeTab, setActiveTab] = useState(initialTab ?? "details")

  const notesCount = person.people_notes_aggregate?.aggregate.count

  const name = formatName(person.first_name, person.last_name)

  const secondaryPersonField = getSecondaryPersonField({
    contracts: person.contracts,
    secondaryPersonField: account.secondary_person_field,
  })

  const teamName = person.team?.name

  const subtitle = match({
    hasSecondaryPersonField: Boolean(secondaryPersonField),
    hasTeam: Boolean(teamName),
  })
    .with(
      { hasSecondaryPersonField: true, hasTeam: true },
      () => `${secondaryPersonField} | ${teamName}`,
    )
    .with(
      { hasSecondaryPersonField: true, hasTeam: false },
      () => secondaryPersonField,
    )
    .with({ hasSecondaryPersonField: false, hasTeam: true }, () => teamName)
    .otherwise(() => "")

  const openPersonDetails = () => {
    // We are not tracking this open because it's only used on edit form close
    openPanel(
      <PersonSidePanel
        person={props.person}
        user={props.user}
        initialTab={activeTab}
      />,
    )
  }

  const openPersonEditForm = () => {
    openPanel(
      <EditPersonForm
        personQuery={person}
        user={user}
        onClose={openPersonDetails}
        initialTab={activeTab}
        location="planner"
      />,
    )
  }

  return (
    <>
      <PersonActionButtons
        person={person}
        user={user}
        openPersonEditForm={openPersonEditForm}
        inPlanner
      />
      <section className={styles.container} data-test="person-side-panel">
        <SidePanelHeader
          title={name}
          subtitle={subtitle}
          type="person"
          person={person}
        />
        <>
          <ModalBody wide className={styles.tabContainer}>
            <Tabs
              large
              id="personTabs"
              onChange={(tab: TabType) => {
                track("Person Side Panel Tab Clicked", { tab })
                setActiveTab(tab)
              }}
              selectedTabId={activeTab}
              className={styles.personTabs}
            >
              <Tab
                id="details"
                title="Details"
                panel={<Details person={person} account={account} />}
              />
              <Tab
                id="contract"
                title="Contract"
                panel={<Contract person={person} account={account} />}
              />
              <Tab
                id="notes"
                title={notesCount ? `Notes (${notesCount})` : "Notes"}
                disabled={!can("view", subject("PersonNote"))}
                panel={<PersonSidePanelNotes person={person} />}
              />
            </Tabs>
          </ModalBody>
          {canEditPerson && activeTab !== "notes" ? (
            <ModalFooter className={styles.footer}>
              <Button onClick={openPersonEditForm} icon="edit">
                Edit Details
              </Button>
            </ModalFooter>
          ) : null}
        </>
      </section>
    </>
  )
}

export default PersonSidePanel
