import React, { useEffect, useMemo, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import styles from "./ReferencesTable.module.css"

import { submitOnEnterOrSpace } from "~/helpers/hotkeys"

import Select from "~/common/Select"

import Input from "./Input"
import AddButton from "./buttons/AddButton"
import { Delete } from "./react-icons"

type References = {
  [key: string]: {
    external_id: string
  }
}

type ReferenceName = {
  label: string
  value: string
}

type ReferenceOptions = {
  name: ReferenceName
  external_id: string
}

type Props = {
  dataType: "RateCard" | "Role" | "Project" | "Person" | "Client"
  references: References
  setUpdatedReferences: (references: References) => void
}

const ReferencesTable = (props: Props) => {
  const { dataType, references, setUpdatedReferences } = props

  const defaultReferenceOptions = [
    { value: "Clockify", label: "Clockify" },
    { value: "Harvest", label: "Harvest" },
    { value: "Workflowmax", label: "Workflowmax" },
    { value: `${dataType}-id`, label: `${dataType}-id` },
  ]

  const [availableReferenceOptions, setAvailableReferenceOptions] = useState(
    defaultReferenceOptions,
  )

  const [existingReferenceNames, setExistingReferenceNames] = useState(
    Object.keys(references),
  )

  useEffect(() => {
    setAvailableReferenceOptions(
      defaultReferenceOptions.filter(
        (ref) => !existingReferenceNames.includes(ref.value),
      ),
    )
  }, [existingReferenceNames]) // eslint-disable-line react-hooks/exhaustive-deps

  const initialData: ReferenceOptions[] = useMemo(
    () =>
      Object.entries(references).map((r) => ({
        name: { value: r[0], label: r[0] },
        external_id: r[1].external_id,
      })),
    [references],
  )

  const emptyReference = {
    name: null,
    external_id: "",
  }

  const [data, setData] = useState(initialData)

  const debouncedConvertToReferencesFormat = useDebouncedCallback(
    (refs: ReferenceOptions[]) => {
      if (!refs) {
        return null
      }

      const formatted = Object.assign(
        {},
        ...refs
          ?.filter((r) => r.external_id)
          .filter((rn) => rn.name)
          .map((x) => ({
            [x.name.value]: {
              external_id: x.external_id,
            },
          })),
      )
      setExistingReferenceNames(refs.map((r) => r.name?.value))
      return setUpdatedReferences(formatted)
    },
    300,
  )

  useEffect(() => {
    debouncedConvertToReferencesFormat(data)
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => {
    setData([...data, emptyReference])
  }

  const handleChange = (value, field, idx) => {
    const newReferenceValue =
      field === "name"
        ? value
          ? {
              label: value.label.replaceAll(" ", "-"),
              value: value.value.replaceAll(" ", "-"),
            }
          : null
        : value.replaceAll(" ", "")

    const updatedReferences = [...data]
    updatedReferences[idx][field] = newReferenceValue

    setData(updatedReferences)
  }

  const handleDelete = (idx) => {
    const updatedReferences = data.filter((p, index) => index !== idx)

    setData(updatedReferences)
  }

  const numberOfReferences = data.filter((d) => d.external_id).length

  const ReferenceInputs = (idx) => (
    <div key={`reference-${idx}`} className={styles.tableRow}>
      <Select
        isCreatable
        name={`reference-name-${idx}`}
        id={`referenceName-${idx}`}
        options={availableReferenceOptions}
        value={data[idx].name}
        onChange={(e) => handleChange(e, "name", idx)}
        placeholder="Select reference or type to create new"
        autoFocus={!data[idx].name}
      />
      <Input
        name={`external-id-${idx}`}
        data-idx={idx}
        value={data[idx].external_id}
        onChange={(e) => handleChange(e.target.value, "external_id", idx)}
        style={{ marginBottom: 0 }}
      />
      <div
        id={`referenceDelete-${idx}`}
        onClick={() => handleDelete(idx)}
        onKeyDown={(e) => submitOnEnterOrSpace(e, () => handleDelete(idx))}
        className={styles.delete}
        tabIndex={0}
      >
        <Delete color="var(--winter)" />
      </div>
    </div>
  )

  return (
    <details className={styles.references}>
      <summary>
        <span>
          {/* span needed for focus */}
          External References{" "}
          {numberOfReferences ? `(${numberOfReferences})` : ""}
        </span>
      </summary>
      <div>
        <div className={styles.description}>
          External references are used to store the ID of this item in other
          applications. These can then be used with the Runn API.
        </div>
        {!!data.length && (
          <div className={styles.wrapper}>
            <div className={`${styles.tableRow} ${styles.tableRowHeader}`}>
              <div>Reference Name</div>
              <div>External ID</div>
              <div>{/* leave empty for delete*/}</div>
            </div>
            {data.map((val, idx) => ReferenceInputs(idx))}
          </div>
        )}
        <AddButton text="New Reference" onClick={() => handleAdd()} />
      </div>
    </details>
  )
}

export default ReferencesTable
