import { Tab, Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import React, { useRef, useState } from "react"
import { useEffect } from "react"
import { flushSync } from "react-dom"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectBudget.module.css"

import { EditProjectBudgetQuery$data } from "./__generated__/EditProjectBudgetQuery.graphql"
import { ProjectBudget_viewer$key } from "./__generated__/ProjectBudget_viewer.graphql"
import { OtherCostBulkCreateInput } from "~/mutations/__generated__/OtherCostsBulkCreateMutation.graphql"
import { OtherCostBulkUpdateInput } from "~/mutations/__generated__/OtherCostsBulkUpdateMutation.graphql"

import { useHasuraContext } from "~/store/hasura"

import currency, { formatCurrency } from "~/helpers/CurrencyHelper"
import { track } from "~/helpers/analytics"
import {
  OTHER_EXPENSES_ROLE,
  OtherCostWithCalculations,
  addDerivedFields,
  extractRoles,
  getAllocationMessage,
  getTotalExpenses,
} from "~/helpers/otherCosts"
import { FinancialType } from "~/helpers/permissions"
import {
  getBudgetedRoles,
  getProjectTotalAllocation,
  isBillableProject,
} from "~/helpers/project-helpers"
import { getDefaultRate } from "~/helpers/rate-helpers"

import ProjectClientIcon from "~/common/ProjectClientIcon"
import Tabs from "~/common/Tabs"
import Button from "~/common/buttons/Button"

import {
  otherCostsBulkCreateRelay,
  otherCostsBulkUpdateRelay,
} from "~/mutations/OtherCosts"
import { projectBudgetUpdateRelay } from "~/mutations/ProjectBudget"

import {
  PRICING_MODELS,
  PricingModelSelection,
  RATE_TYPES,
  RateTypeSelection,
} from "~/GLOBALVARS"
import { Card } from "~/dashboard/Card"
import { getSettings, setSetting } from "~/localsettings"

import BudgetEditor, { BudgetedRole } from "./BudgetEditor"
import BudgetSummary from "./BudgetSummary"
import BudgetTotalsRow from "./BudgetTotalsRow"
import ExpenseBudgetInput from "./ExpenseBudgetInput"
import ExpenseTotalsRow from "./ExpenseTotalsRow"
import NonBillableWarning from "./NonBillableWarning"
import OtherCostEditor, { createEmptyCost } from "./OtherCostsEditor"
import RoleAdder from "./RoleAdder"
import Column from "./TableColumn"
import TableInfo from "./TableInfo"
import Row from "./TableRow"

type Props = {
  project: EditProjectBudgetQuery$data["projects_by_pk"]
  onClose: () => void
}

type BudgetTabID = "expenses" | "budget"

export type ProjectRate =
  EditProjectBudgetQuery$data["projects_by_pk"]["project_rates"][number]

export type RateCardOption = { value: number; label: string }

const fragment = graphql`
  fragment ProjectBudget_viewer on users {
    id
    permissions
    account {
      id
      default_full_time_minutes
      roles {
        id
        name
        active
      }
      rate_cards {
        id
        name
        blended_rate: blended_rate_private
        blended_rate_card
        role_charge_out_rates {
          role_id
          rate_card_id
          charge_out_rate: charge_out_rate_private
        }
      }
    }
  }
`

const ProjectBudget = (props: Props) => {
  const { project, onClose } = props
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<ProjectBudget_viewer$key>(fragment, viewerQuery)
  const {
    account,
    account: { default_full_time_minutes },
  } = viewer

  const [isSaving, setIsSaving] = useState(false)

  const isBillable = isBillableProject(
    project.pricing_model,
    project.client.internal,
  )

  const getExistingBudgetedRole = (roleId: number) =>
    project.project_roles.find((pr) => roleId === pr.role_id)

  const initialVisibleRoles = getBudgetedRoles(
    project,
    account.roles,
    isBillable,
  )

  const initialProjectRates = project.project_rates

  const [roles, setRoles] =
    useState<ReadonlyArray<BudgetedRole>>(initialVisibleRoles)

  const [deletedRoles, setDeletedRoles] = useState<number[]>([])

  const [projectRates, setProjectRates] = useState<ProjectRate[]>([
    ...initialProjectRates,
  ])
  const financialPermission = viewer.permissions.financial as FinancialType
  const hideFinancials = financialPermission === FinancialType.None

  const [showNonBillableView, setShowNonBillableView] = useState(!isBillable)

  const [billingMethod, setBillingMethod] = useState<PricingModelSelection>(
    PRICING_MODELS.find((pm) => pm.value === project.pricing_model),
  )
  const updatingToNonBillable = isBillable && billingMethod.value === "nb"

  const rateCards: RateCardOption[] = account.rate_cards.map((rateCard) => ({
    value: rateCard.id,
    label: rateCard.name,
  }))

  const [selectedRateCard, setSelectedRateCard] = useState<RateCardOption>({
    value: project.rate_card.id,
    label: project.rate_card.name,
  })

  const [selectedRateType, setSelectedRateType] = useState<RateTypeSelection>(
    RATE_TYPES.find((rt) => rt.value === project.rate_type) || RATE_TYPES[0],
  )

  let defaultTabId = getSettings().projectBudgetTabId ?? "budget"
  // Edge case where an unpermitted tab was persisted
  if (defaultTabId === "expenses" && hideFinancials) {
    defaultTabId = "budget"
  }

  const [budgetTabId, setBudgetTabId] = useState<BudgetTabID>(defaultTabId)
  const isExpenses = budgetTabId === "expenses"

  useEffect(() => {
    track("Project Budget Tab Changed", { budgetTabId })
    setSetting("projectBudgetTabId", budgetTabId)
  }, [budgetTabId])

  const projectOtherCosts = (project.other_costs ?? []).map((cost) => {
    return {
      ...cost,
      date: dateHelpers.parseRunnDate(cost.date),
    }
  })

  const initialCosts = projectOtherCosts.length
    ? projectOtherCosts.map(addDerivedFields)
    : [createEmptyCost()]

  const [otherCosts, setOtherCosts] =
    useState<Array<OtherCostWithCalculations>>(initialCosts)

  const totalMinutes = extractRoles(roles).people.reduce(
    (prev, curr) =>
      curr.estimated_minutes ? prev + curr.estimated_minutes : prev,
    0,
  )

  const totalAllocated = getProjectTotalAllocation(roles, projectRates)
  const totalExpenses = getTotalExpenses(otherCosts)
  const [totalBudget, setTotalBudget] = useState(project.total_budget)
  const [itemNameRequiredError, setItemNameRequiredError] = useState(false)

  useEffect(() => {
    const expenseRole = extractRoles(roles).expenses
    // if you've added expenses and "other expenses" is not in your budget, add it implicitly.
    if (totalExpenses.charge && !expenseRole && !showNonBillableView) {
      setRoles([
        ...roles,
        {
          ...OTHER_EXPENSES_ROLE,
          estimated_minutes: 0,
          project_role_id: OTHER_EXPENSES_ROLE.id,
          implicit: true,
          isScheduled: true,
        },
      ])
    }
    // If you have no expenses and "other expenses" is sitting empty in your budget, assume it was added implicitly and clean it up.
    else if (
      !totalExpenses.charge &&
      expenseRole &&
      expenseRole.implicit &&
      !expenseRole.estimated_minutes
    ) {
      setRoles(roles.filter((r) => r.id !== OTHER_EXPENSES_ROLE.id))
    }
    // If you've switched to NB, remove Other Expenses from the list of roles. It doesn't apply.
    else if (showNonBillableView && expenseRole) {
      setRoles(roles.filter((r) => r.id !== OTHER_EXPENSES_ROLE.id))
    }
    // Ensure the isScheduled property of the expense role is set correctly. The role can be added before or after the existence of expenses, so these need to be in sync.
    else if (expenseRole) {
      const index = roles.findIndex((r) => r.id === OTHER_EXPENSES_ROLE.id)
      if (index !== -1) {
        const shouldBeScheduled = totalExpenses.charge > 0
        if (expenseRole.isScheduled !== shouldBeScheduled) {
          const newRoles = [...roles]
          newRoles[index].isScheduled = shouldBeScheduled
          setRoles(newRoles)
        }
      }
    }
  }, [totalExpenses.charge, roles, showNonBillableView])

  const unallocatedRoles = account.roles
    .filter((r) => r.active && !roles.map((x) => x.id).includes(r.id))
    .map((r) => ({
      ...r,
      estimated_minutes: 0,
      project_role_id: getExistingBudgetedRole(r.id)?.id,
    }))

  const allocatedRoleData = extractRoles(roles)

  const isCompleteCost = (cost: OtherCostWithCalculations) =>
    cost.name.trim() || cost.charge !== 0 || cost.cost !== 0

  if (
    !allocatedRoleData.expenses &&
    !otherCosts.filter(isCompleteCost).length &&
    !showNonBillableView
  ) {
    unallocatedRoles.push({
      ...OTHER_EXPENSES_ROLE,
      estimated_minutes: 0,
      project_role_id: OTHER_EXPENSES_ROLE.id,
    })
  }
  const expensesBudget = allocatedRoleData.expenses?.estimated_minutes ?? null

  const expensesAllocationDifference = expensesBudget - totalExpenses.charge
  const budgetAllocationDifference = totalBudget - totalAllocated

  const allocationDifference = isExpenses
    ? expensesAllocationDifference
    : budgetAllocationDifference
  const showDifference = isExpenses
    ? !!expensesBudget && totalExpenses.charge > 0
    : !!totalBudget

  const overAllocated = allocationDifference < 0

  const rateCard = account.rate_cards.find(
    (rc) => rc.id === selectedRateCard.value,
  )

  const handleBillingMethod = (selection: PricingModelSelection): void => {
    setBillingMethod(selection)
    setShowNonBillableView(selection.value === "nb")
  }

  const handleShowAllRoles = () => {
    setRoles([...roles, ...unallocatedRoles])
  }

  const handleAddRole = (roleId: number) => {
    const selectedRole = unallocatedRoles.find((r) => r.id === roleId)
    setRoles([...roles, selectedRole])
  }

  const handleItemNameRequired = (costs: OtherCostWithCalculations[]) => {
    const hasError = costs.some((cost) => !cost.name || cost.name.trim() === "")
    setItemNameRequiredError(hasError)
  }

  const handleCostUpdate = (id: number, cost: OtherCostWithCalculations) => {
    flushSync(() => {
      const updatedCosts = [...otherCosts]
      const index = updatedCosts.findIndex((c) => c.id === id)
      if (index !== -1) {
        updatedCosts[index] = { ...cost }
        handleItemNameRequired(updatedCosts)
        setOtherCosts(updatedCosts)
      }
    })
  }

  const handleCostDelete = (id: number) => {
    const cost = otherCosts.find((c) => c.id === id)
    if (!cost) {
      return
    }
    track("Other Cost Deleted", {
      cost: cost.cost,
      charge: cost.charge,
    })
    const updatedCosts = otherCosts.filter((c) => c.id !== id)
    if (!updatedCosts.length) {
      updatedCosts.push(createEmptyCost())
    }
    setOtherCosts(updatedCosts)
  }

  const handleCostAdd = () => {
    track("Other Cost Added")
    const costs = [...otherCosts, createEmptyCost()]
    handleItemNameRequired(costs)
    setOtherCosts(costs)
  }

  const handleBudgetUpdateRole = (updatedRole: BudgetedRole) => {
    flushSync(() => {
      const index = roles.findIndex((r) => r.id === updatedRole.id)
      if (index !== -1) {
        const newRoles = [...roles]
        newRoles[index] = updatedRole
        setRoles(newRoles)
      }
    })
  }

  const handleDeleteRole = (roleId: number) => {
    setRoles([...roles.filter((r) => r.id !== roleId)])
    setDeletedRoles([...deletedRoles, roleId])
  }

  const handleBudgetUpdateRate = (updatedRates: ProjectRate[]) => {
    setProjectRates((prevRates) => {
      const newRates = [...prevRates]

      updatedRates.forEach((updatedRate) => {
        const index = newRates.findIndex(
          (r) => r.role_id === updatedRate.role_id,
        )
        if (index === -1) {
          newRates.push(updatedRate)
        } else {
          newRates[index] = updatedRate
        }
      })

      return newRates
    })
  }

  const saveBudget = async () => {
    setIsSaving(true)
    track("Project Budget Edited", {
      pricing_model: billingMethod.value,
      rate_type: selectedRateType.value,
      roles: roles,
      other_costs: otherCosts.map((c) => ({
        cost: c.cost,
        charge: c.charge,
      })),
    })
    roles.forEach((r) => {
      delete r.isScheduled
    })

    const unallocated_project_role_ids = deletedRoles
      .map(
        (role_id) =>
          project.project_roles.find((pr) => pr.role_id === role_id)?.id,
      )
      .filter((id) => id)
    const unallocated_project_rate_ids = deletedRoles
      .map(
        (role_id) =>
          project.project_rates.find((pr) => pr.role_id === role_id)?.id,
      )
      .filter((id) => id)

    const rates = projectRates.map((r) => {
      let rate = r.rate
      const roleExists = !!roles.find((role) => role.id === r.role_id)
      if (showNonBillableView) {
        rate = 0
      } else if (!roleExists) {
        // Reset the rate to the default rate if the role has been removed
        rate = getDefaultRate(rateCard, r.role_id)
      }
      return {
        ...r,
        project_id: project.id,
        rate,
      }
    })

    const promises = []

    if (hideFinancials) {
      // Do not send any financial data - as it has been zeroed
      promises.push(
        projectBudgetUpdateRelay({
          input: {
            project_id: project.id,
            unallocated_project_role_ids,
            unallocated_project_rate_ids,
            roles: allocatedRoleData.people,
          },
          todaysDate: dateHelpers.getTodaysDate(),
        }),
      )
    } else {
      promises.push(
        projectBudgetUpdateRelay({
          input: {
            project_id: project.id,
            unallocated_project_role_ids,
            unallocated_project_rate_ids,
            roles: allocatedRoleData.people,
            rates,
            pricing_model: billingMethod.value,
            total_budget: !!totalBudget ? Number(totalBudget) : totalAllocated,
            expenses_budget: expensesBudget,
            rate_type: selectedRateType.value,
            rate_card_id: selectedRateCard.value,
          },
          todaysDate: dateHelpers.getTodaysDate(),
        }),
      )
    }
    const newCosts: OtherCostBulkCreateInput[] = []
    const updatedCosts: OtherCostBulkUpdateInput[] = []

    otherCosts.filter(isCompleteCost).forEach((cost) => {
      const c = {
        name: cost.name.trim(),
        date: dateHelpers.formatToRunnDate(cost.date),
        cost: cost.cost,
        charge: cost.charge,
      }
      if (String(cost.id).startsWith("new--")) {
        newCosts.push(c)
      } else {
        updatedCosts.push({ ...c, id: Number(cost.id) })
      }
    })

    // If there are updated costs, we need to run this first. Otherwise, it will delete any costs that are created here when it finds IDs in the DB that are not in its payload.
    const isFullDeletion = initialCosts.length && !updatedCosts.length
    if (updatedCosts.length || isFullDeletion) {
      await otherCostsBulkUpdateRelay({
        variables: {
          input: updatedCosts,
          project_id: project.id,
          todaysDate: dateHelpers.getTodaysDate(),
        },
      })
    }

    if (newCosts.length) {
      promises.push(
        otherCostsBulkCreateRelay({
          variables: {
            input: newCosts,
            project_id: project.id,
          },
        }),
      )
    }

    // Update budget and create costs can execute in any order.
    await Promise.all(promises)

    setIsSaving(false)
    onClose()
  }

  const tableFooterRef = useRef(null)
  const footerButtonsRef = useRef(null)

  const footerHeight =
    tableFooterRef.current?.clientHeight +
    footerButtonsRef.current?.clientHeight

  const showDayView = selectedRateType.value === "days"
  const invalidBudget = Number(totalBudget) < 0

  const showTotal = (!!totalAllocated || !!totalMinutes) && !isExpenses
  const totalsOffset = 0

  const handleExpensesUpdate = (val: number) => {
    const { expenses } = extractRoles(roles)
    if (expenses) {
      const updatedExpenses = {
        ...expenses,
        estimated_minutes: val,
      }
      handleBudgetUpdateRole(updatedExpenses)
    } else {
      setRoles([
        ...roles,
        {
          ...OTHER_EXPENSES_ROLE,
          estimated_minutes: val,
          project_role_id: OTHER_EXPENSES_ROLE.id,
          implicit: true,
          isScheduled: true,
        },
      ])
    }
  }

  const Summary = (
    <>
      <BudgetSummary
        rateCards={rateCards}
        rateCard={selectedRateCard}
        billingMethod={billingMethod}
        rateType={selectedRateType}
        totalBudget={totalBudget}
        invalid={invalidBudget}
        currency={currency}
        showNonBillableView={showNonBillableView}
        allowBillingMethodEdit={!project.client.internal}
        onChangeRateType={setSelectedRateType}
        onChangeRateCard={setSelectedRateCard}
        onChangeBillingMethod={handleBillingMethod}
        onChangeTotalBudget={setTotalBudget}
      />
      {updatingToNonBillable && <NonBillableWarning />}
    </>
  )

  const BudgetPanel = (
    <>
      {!hideFinancials && Summary}
      <BudgetEditor
        rateType={selectedRateType}
        showNonBillableView={showNonBillableView}
        roles={roles}
        hideFinancials={hideFinancials}
        onUpdateRole={handleBudgetUpdateRole}
        onUpdateRates={handleBudgetUpdateRate}
        onDeleteRole={handleDeleteRole}
        rateCard={rateCard}
        projectRates={projectRates}
        projectId={project.id}
        defaultFullTimeMinutes={default_full_time_minutes}
        expensesAllocationDifference={expensesAllocationDifference}
        expensesBudget={expensesBudget}
      />
      {unallocatedRoles.length > 0 && (
        <div className={styles.tableWidget}>
          <RoleAdder
            roles={unallocatedRoles}
            onAddRole={handleAddRole}
            onShowAllRoles={handleShowAllRoles}
          />
        </div>
      )}
    </>
  )

  const ExpensesPanel = (
    <>
      {billingMethod.value !== "nb" && (
        <TableInfo className={styles.expensesHeader}>
          <Row>
            <Column textAlign="left">Expenses Budget</Column>
            <Column>
              <ExpenseBudgetInput
                dataTest="expenses-budget_dollars"
                name="expenses-budget_dollars"
                currency={currency}
                onUpdate={handleExpensesUpdate}
                value={expensesBudget || 0}
                maxWidth={113}
              />
            </Column>
          </Row>
        </TableInfo>
      )}
      <OtherCostEditor
        showNonBillableView={showNonBillableView}
        costs={otherCosts}
        onUpdate={handleCostUpdate}
        onDelete={handleCostDelete}
        onAdd={handleCostAdd}
      />
    </>
  )
  return (
    <Card style={{ width: "900px" }}>
      <div
        className={`
          ${styles.budgetContainer}
          ${hideFinancials || showNonBillableView ? styles.hideFinancials : ""}
        `}
        style={{
          maxHeight: `calc(100% - ${footerHeight}px)`,
          minHeight: "200px",
        }}
        data-test="project-budget"
      >
        <div className={styles.client}>
          <ProjectClientIcon project={project} client={project.client} border />
          <span>{project.name}</span>
        </div>
        <Tabs
          defaultSelectedTabId={budgetTabId}
          large
          id="panelBudget"
          onChange={(tabId: BudgetTabID) => setBudgetTabId(tabId)}
          rightTabs
        >
          <Tab
            id="budget"
            title="Budget"
            panel={BudgetPanel}
            style={{ paddingBottom: "18px" }}
          />
          {!hideFinancials && (
            <Tab
              id="expenses"
              title="Other Expenses"
              panel={ExpensesPanel}
              style={{ paddingBottom: "18px" }}
            />
          )}
        </Tabs>
      </div>
      {!isExpenses && showTotal && !hideFinancials && (
        <TableInfo
          sticky
          className={styles.budgetTotalsRow}
          offset={totalsOffset}
        >
          <BudgetTotalsRow
            totalDollars={totalAllocated}
            totalMinutes={totalMinutes}
            showDayView={showDayView}
            defaultFullTimeMinutes={default_full_time_minutes}
            showNonBillableView={showNonBillableView}
            title="Total Allocation"
          />
        </TableInfo>
      )}
      {isExpenses && (
        <TableInfo
          sticky
          offset={totalsOffset}
          className={styles.expensesTotalsRow}
        >
          <ExpenseTotalsRow
            showNonBillableView={showNonBillableView}
            totalExpenses={totalExpenses}
            title="Total Expenses"
          />
        </TableInfo>
      )}

      <div className={styles.footerButtons} ref={footerButtonsRef}>
        {!showNonBillableView && showDifference && (
          <div
            data-test="budget-difference"
            className={cc([
              styles.footerMessage,
              {
                [styles.over]: overAllocated,
              },
            ])}
          >
            {getAllocationMessage(allocationDifference)}
            {allocationDifference !== 0 && (
              <span>{formatCurrency(Math.abs(allocationDifference))}</span>
            )}
          </div>
        )}
        <Button
          text="Cancel"
          onClick={onClose}
          style={{ marginLeft: "auto" }}
        />
        <Tooltip
          content="Other expense must have an item name"
          autoFocus={false}
          disabled={!itemNameRequiredError}
        >
          <Button
            id="save-budget-button"
            loading={isSaving}
            text="Save"
            outlined={false}
            onClick={saveBudget}
            disabled={
              isSaving ||
              (totalBudget && Number(totalBudget) < 0) ||
              itemNameRequiredError
            }
          />
        </Tooltip>
      </div>
    </Card>
  )
}

export default ProjectBudget
