import React, { useState } from "react"

import styles from "./Onboarding.module.css"

import { ModalFormWrapper } from "~/common/ModalForm"

import CreateBulkProjectsForm from "~/forms/CreateProject/CreateBulkProjectsForm"
import RocketTakeOffTangram from "~/forms/CreateProject/RocketTakeOffTangram"

type Props = {
  closeDialog: () => void
  nextStep: () => void
  accountName: string
  totalSteps: number
}

const OnboardingProjects = (props: Props) => {
  const { closeDialog, nextStep, accountName, totalSteps } = props

  const [activateAnimation, setActivateAnimation] = useState(false)

  return (
    <ModalFormWrapper
      wide
      tangram={<RocketTakeOffTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <div className={styles.onboardingProjects}>
        <div className={styles.onboardingTextContainer}>
          <div className={styles.heading}>
            Let's set up the basics of your {accountName} account!
          </div>
          <div className={styles.subheading}>
            First, let’s add your projects.
          </div>
        </div>
        <CreateBulkProjectsForm
          activateAnimation={() => setActivateAnimation(true)}
          onSuccess={nextStep}
          closeDialog={closeDialog}
          saveText="Next"
          isOnboarding={true}
          totalSteps={totalSteps}
        />
      </div>
    </ModalFormWrapper>
  )
}

export default OnboardingProjects
