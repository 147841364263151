import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { ProjectAddPersonRow_account$key } from "./__generated__/ProjectAddPersonRow_account.graphql"
import { ProjectAddPersonRow_project$key } from "./__generated__/ProjectAddPersonRow_project.graphql"

import { track } from "~/helpers/analytics"
import { dashify } from "~/helpers/general-helpers"

import AddButton from "~/common/buttons/AddButton"
import CalendarOutline from "~/common/calendar/CalendarOutline"

import { isSplitScreenMode } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import {
  hideInactiveMembersInProject,
  showAllMembersInProject,
} from "~/Planner/reducer2/projectMembersViewSlice"
import { selectIsAllMembersShown } from "~/Planner/reducer2/projectMembersViewSlice"
import AddPersonToProject from "~/ProjectPlanner/AddPersonToProject/AddPersonToProject"
import { useAppSelector } from "~/hooks/redux"
import { useExtProjectMembersList } from "~/hooks/useExtProjectMembersList"
import { useProjectMembersList } from "~/hooks/useProjectMembersList"

import ToggleProjectMembersView from "../ProjectMembers/ToggleProjectMembersView"

const ProjectAddPersonRow = (props: Props) => {
  const account = useFragment(
    graphql`
      fragment ProjectAddPersonRow_account on accounts
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        plannerStartDate: { type: "date!" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        people(where: $peopleFilter) {
          id
          active
          email
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            start_date: start_date_runn
            end_date: end_date_runn
            person_id
            project_id
            role_id
            workstream_id
          }
        }
        ...AddPersonToProject_account
          @arguments(
            projectsFilter: $projectsFilter
            plannerStartDate: $plannerStartDate
          )
      }
    `,
    props.account,
  )

  const project = useFragment(
    graphql`
      fragment ProjectAddPersonRow_project on projects {
        id
        name
        is_template
        members {
          id
          person_id
          project_id
          role_id
          workstream_id
          is_placeholder
          just_added_timestamp
          person {
            id
            active
          }
        }
        ...useExtProjectMembersList_project
      }
    `,
    props.project,
  )

  const assignments = account.people
    .flatMap((p) => p.assignments)
    .filter((a) => a?.project_id === project.id)

  const [showAddPersonModal, setShowAddPersonModal] = useState(false)
  const { can, subject } = usePermissions()
  // const { can, subject, Can } = usePermissions()
  const canCreatePerson = can("create", subject("Person"))
  // const canEditProject = can("edit", subject("Project", project))
  // const canEditTemplate = can("edit", subject("ProjectTemplate"))

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const dispatch = useDispatch()

  const members = (project.members ?? []).map(({ person, ...membership }) => ({
    ...membership,
    active: person.active,
  }))

  const projectMembersList = useProjectMembersList(project.id, {
    members,
    assignments,
  })
  const totals = {
    all: projectMembersList.all.length,
    visible: projectMembersList.visible.length,
    inactive: projectMembersList.inactive.length,
  }

  const extProjectMembersList = useExtProjectMembersList({ project })

  const isAllMembersShown = useAppSelector((state) =>
    selectIsAllMembersShown(state.plannerV2.projectMembersView, project.id),
  )

  if (canCreatePerson) {
    const visibleExtProjectMembersCount = isAllMembersShown
      ? extProjectMembersList.length
      : 0
    totals.all += extProjectMembersList.length
    totals.inactive += extProjectMembersList.length
    totals.visible += visibleExtProjectMembersCount
  }

  const handleToggle = (shouldShowAll: boolean) => {
    track("All Project Members Toggled", {
      projectId: project.id,
      value: shouldShowAll,
    })
    if (shouldShowAll) {
      dispatch(showAllMembersInProject(project.id))
    } else {
      dispatch(hideInactiveMembersInProject(project.id))
    }
  }

  // NOTE: Project memberships isn't perfectly encapsulated by the "ProjectMember" subject, this subject
  // exludes project templates and placeholders from consideration so we have to reach out to those subjects to determine
  // if the user can add a membership
  const canAddProjectTemplateMembership = can(
    "edit",
    subject("ProjectTemplate"),
  )
  const canCreatePlaceholder = can(
    "create",
    subject("Placeholder", {
      project: { id: project.id, isTemplate: project.is_template },
    }),
  )

  // Can we create *some* project member for *this* project
  const canCreateSomeProjectMember = useMemo(
    () =>
      account.people.some(
        (p) =>
          p.active &&
          can(
            "create",
            subject("ProjectMember", {
              person: p,
              project: { id: project.id, isTemplate: project.is_template },
            }),
          ),
      ),
    [account.people, project], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const canAddPerson = project.is_template
    ? canAddProjectTemplateMembership && canCreateSomeProjectMember
    : canCreateSomeProjectMember
  const canAddBothPeopleAndPlaceholders = canCreatePlaceholder && canAddPerson

  return (
    <>
      <PlannerGrid disabled={isSplitScreenMode(modeAction)} stickyBottom>
        <PlannerLeftColumn
          isCallToAction
          style={{ justifyContent: "space-between" }}
        >
          <div>
            {(canCreatePlaceholder || canAddPerson) && (
              <AddButton
                text={`Add ${canAddPerson ? "Person" : ""}${canAddBothPeopleAndPlaceholders ? " or " : ""}${canCreatePlaceholder ? "Placeholder" : ""}`}
                data-test={`add-person-to-${dashify(project.name)}`}
                onClick={() => setShowAddPersonModal(true)}
                active={showAddPersonModal}
              />
            )}
          </div>
          <ToggleProjectMembersView
            totals={totals}
            onToggle={handleToggle}
            showAllText={(count) => `Show all (${count})`}
            hideInactiveText={(count) => `Hide inactive (${count})`}
            showAllTooltip="Show all project team members"
            hideInactiveTooltip={
              <span>
                Hide project team members who
                <br />
                are not active in the current view
              </span>
            }
            dataTestName={project.name.toLocaleLowerCase().replaceAll(" ", "-")}
          />
        </PlannerLeftColumn>
        <PlannerRightColumn showHighlight={false}>
          <CalendarOutline type="standard" />
        </PlannerRightColumn>
      </PlannerGrid>
      {showAddPersonModal && (
        <AddPersonToProject
          account={account}
          projectId={project.id}
          hideModal={() => setShowAddPersonModal(false)}
        />
      )}
    </>
  )
}

type Props = {
  account: ProjectAddPersonRow_account$key
  project: ProjectAddPersonRow_project$key
}

export default ProjectAddPersonRow
