import { DatePicker } from "@blueprintjs/datetime"
import { dateHelpers } from "@runn/calculations"
import {
  addYears,
  format as formatDate,
  startOfISOWeek,
  subYears,
} from "date-fns"
import React, { useState } from "react"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

type Props = {
  closeDialog: () => void
  onSubmit: (date: string, name: string) => void
  projectName: string
}

const DashboardModalDuplicate = (props: Props) => {
  const { closeDialog, onSubmit, projectName } = props

  const [name, setName] = useState("")
  const [duplicateDate, setDuplicateDate] = useState(startOfISOWeek(new Date()))
  const [isDuplicating, setIsDuplicating] = useState(false)
  const minDate = subYears(new Date(), 1)
  const maxDate = addYears(new Date(), 3)

  const handleClose = () => {
    closeDialog()
  }

  const handleDateChange = (date) => {
    setDuplicateDate(startOfISOWeek(date))
  }

  const handleSubmit = () => {
    onSubmit(dateHelpers.formatToRunnDate(duplicateDate), name)
    setIsDuplicating(true)
  }

  const dayPickerProps = {
    disabledDays: [{ daysOfWeek: [0, 2, 3, 4, 5, 6] }],
    firstDayOfWeek: 1,
  }

  return (
    <ModalFormWrapper
      headerTitle={`Duplicate ${projectName}`}
      helpLink="https://help.runn.io/en/articles/4560021-duplicate-a-project-reoccurring-projects"
    >
      <ModalBody>
        <p>
          This will duplicate the project and move all related data to start in
          a new week.
        </p>
        <p>
          You may only choose the Monday for the week. If the first assignment
          starts on Wednesday, it will move to Wednesday of the chosen week.
        </p>
        <br />
        <div style={{ marginBottom: "10px" }}>
          <Input
            label="Project Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            dataTest="duplicate-project-name"
          />
        </div>
        <p>
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Starting Week: {formatDate(duplicateDate, "EEEE, d MMMM y")}
          </span>
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DatePicker
            onChange={handleDateChange}
            highlightCurrentDay={true}
            defaultValue={duplicateDate}
            dayPickerProps={dayPickerProps}
            minDate={minDate}
            maxDate={maxDate}
            canClearSelection={false}
            value={duplicateDate}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={handleClose} tabIndex={-1} />
        <Button
          text="Duplicate"
          intent="primary"
          loading={isDuplicating}
          onClick={handleSubmit}
          outlined={false}
          disabled={isDuplicating || !name.trim()}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default DashboardModalDuplicate
