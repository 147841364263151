/**
 * @generated SignedSource<<6df5f099d52acb6bf0922b0a5cb6825d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewExternalProjectLink_project$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ExtLinks_Project">;
  readonly " $fragmentType": "ViewExternalProjectLink_project";
};
export type ViewExternalProjectLink_project$key = {
  readonly " $data"?: ViewExternalProjectLink_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewExternalProjectLink_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewExternalProjectLink_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExtLinks_Project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "ecd6f9381403cc3bdc6d0c542330aa00";

export default node;
