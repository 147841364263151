import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./RoleForm.module.css"

import { RoleForm_viewer$key } from "./__generated__/RoleForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import * as hashids from "~/helpers/hashids"
import { FinancialType } from "~/helpers/permissions"
import { RateType, canonicalRate, companyRate } from "~/helpers/rate-helpers"
import { viewRoleUrl } from "~/helpers/routes"

import CurrencyInput from "~/common/CurrencyInput"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import { Permission } from "~/common/Permission"
import ReferencesTable from "~/common/ReferencesTable"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import UniqueInput from "~/common/UniqueInput"
import SmallAlert from "~/common/alerts/SmallAlert"
import Button from "~/common/buttons/Button"

import { roleCreateRelay, roleUpdateRelay } from "~/mutations/Role"

type EditRole = {
  id: number
  name: string
  default_hour_cost: number
  references: any
}

type Props = {
  closeDialog?: () => void
  role?: EditRole
  roleRate?: number
  todaysDate?: string
}

const fragment = graphql`
  fragment RoleForm_viewer on users {
    account {
      currency
      id
      rate_type
      all_roles: roles {
        id
        name
        active
        default_hour_cost: default_hour_cost_private
      }
    }
    permissions
  }
`

const RoleForm = (props: Props) => {
  const { closeDialog, role, roleRate, todaysDate } = props

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<RoleForm_viewer$key>(fragment, viewerQuery)

  const {
    account: { rate_type: rate_type_string, all_roles, currency },
    permissions,
  } = viewer
  const rate_type = rate_type_string as RateType

  const [isUpdating, setIsUpdating] = useState(false)
  const [roleName, setRoleName] = useState(role?.name ?? "")
  const initialCost = companyRate(role?.default_hour_cost, rate_type)
  const initialRate = roleRate ? companyRate(roleRate, rate_type) : null

  const [defaultCost, setDefaultCost] = useState(initialCost)
  const [standardRate, setStandardRate] = useState(initialRate || 0)

  const [updatedReferences, setUpdatedReferences] = useState(
    role?.references ?? [],
  )
  const formattedRateType = rate_type === "hours" ? "Hourly" : "Daily"
  const handleSuccess = () => {
    setIsUpdating(false)
    closeDialog()
  }

  const handleSubmit = async () => {
    setIsUpdating(true)

    const cost = defaultCost ? canonicalRate(Number(defaultCost), rate_type) : 0
    const rate = standardRate
      ? canonicalRate(Number(standardRate), rate_type)
      : 0

    const defaultInput = {
      name: roleName,
      defaultHourCost: cost,
      chargeOutRate: rate,
      references: updatedReferences,
    }

    if (role) {
      await roleUpdateRelay({
        variables: {
          input: {
            ...defaultInput,
            roleId: role.id,
          },
        },
      })
      track("Role Updated", { name: roleName })
    } else {
      await roleCreateRelay({
        variables: {
          input: defaultInput,
          includeAggregateFields: false,
          ...(todaysDate && { todaysDate, includeAggregateFields: true }),
        },
      })
      track("Role Created", { name: roleName })
    }
    handleSuccess()
  }

  const [existingRole, setExistingRole] = useState(undefined)
  const handleRoleName = (e) => {
    const name = e.target.value
    const allRolesExcludingCurrent = role
      ? all_roles.filter((r) => r.name !== role.name)
      : all_roles
    const duplicateRole =
      allRolesExcludingCurrent &&
      allRolesExcludingCurrent.find(
        (c) => c.name.toLowerCase().trim() === name.toLowerCase().trim(),
      )

    setRoleName(name)
    setExistingRole(duplicateRole)
  }

  const handleStandardRateChange = (value: number) => {
    // Clamp undefined inputs to zero
    if (value === undefined) {
      setStandardRate(0)
      return
    }

    setStandardRate(value)
  }

  const handleDefaultCostChange = (value: number) => {
    // Clamp undefined inputs to zero
    if (value === undefined) {
      setDefaultCost(0)
      return
    }

    setDefaultCost(value)
  }
  const existingRoleHashid =
    existingRole != null ? hashids.roles.encode(existingRole.id) : null

  const renderTitledTooltip = (title: string, type: "rate" | "cost") => {
    const message =
      type === "rate" ? (
        <div className={styles.tooltipMessage}>
          The default {formattedRateType.toLowerCase()} rate is the rate that
          this role is charged to clients.
          <br />
          <br />
          It can be updated on each project or on rate cards.
          <br />
          <br />
          Click to learn more.
        </div>
      ) : (
        <div className={styles.tooltipMessage}>
          The default {formattedRateType.toLowerCase()} cost is the cost per
          {rate_type === "hours" ? " hour" : " day"} that this role costs your
          business, including salary and overheads.
          <br />
          <br />
          It can be changed under Roles, or an individual person can have a cost
          associated with them instead.
          <br />
          <br />
          Click to learn more.
        </div>
      )

    const url =
      type === "cost"
        ? "https://help.runn.io/en/articles/2876186-what-s-the-default-hourly-role-cost"
        : "https://help.runn.io/en/articles/2876188-what-s-the-standard-role-charge-out-rate"

    return (
      <TitledHelpTooltip title={title} tooltipContent={message} url={url} />
    )
  }

  const isEditForm = Boolean(role)

  return (
    <ModalFormWrapper
      headerTitle={isEditForm ? `Edit "${role.name}"` : `New role`}
    >
      {isEditForm &&
        (initialCost !== defaultCost || initialRate !== standardRate) && (
          <SmallAlert intent="warning" className={styles.banner}>
            Updates to rates or costs will only affect new people and
            placeholders.
          </SmallAlert>
        )}
      <ModalBody>
        <UniqueInput
          name="role-name"
          id="role-name"
          duplicate={
            existingRole && {
              name: existingRole.name,
              link: viewRoleUrl(existingRoleHashid),
            }
          }
          label="Role Name"
          placeholder="e.g. Designer, Project Manager"
          value={roleName}
          onChange={handleRoleName}
          autoComplete="off"
          autoFocus
          style={{ marginBottom: "15px" }}
        />

        <Permission permissions={permissions} financial={[FinancialType.All]}>
          <CurrencyInput
            name="role-standard-rate"
            label={renderTitledTooltip(
              `Default ${formattedRateType} Rate`,
              "rate",
            )}
            placeholder={`Enter default ${formattedRateType.toLowerCase()} rate`}
            value={standardRate}
            onChange={handleStandardRateChange}
            style={{ marginBottom: "15px" }}
            error={Number(standardRate) < 0}
            currency={currency}
          />
        </Permission>

        <Permission permissions={permissions} financial={[FinancialType.All]}>
          <CurrencyInput
            name="role-default-internal-cost"
            label={renderTitledTooltip(
              `Default ${formattedRateType} Cost`,
              "cost",
            )}
            placeholder={`Enter default ${formattedRateType.toLowerCase()} cost`}
            value={defaultCost}
            onChange={handleDefaultCostChange}
            style={{ marginBottom: "15px" }}
            error={Number(defaultCost) < 0}
          />
          <em className={styles.note}>
            The{" "}
            <a
              target="_blank"
              rel="noopener"
              title="More information on default role cost"
              href="https://help.runn.io/en/articles/2876186-what-s-the-default-hourly-role-cost"
            >
              default role cost
            </a>{" "}
            is used to calculate placeholder and default people costs.
          </em>
        </Permission>
        <ReferencesTable
          dataType="Role"
          references={updatedReferences}
          setUpdatedReferences={setUpdatedReferences}
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={() => closeDialog()} />
        <Button
          text={role ? "Update" : "Create"}
          loading={isUpdating}
          id="role-form-button"
          outlined={false}
          onClick={handleSubmit}
          disabled={
            isUpdating ||
            !roleName.trim().length ||
            !!existingRole ||
            Number(standardRate) < 0 ||
            Number(defaultCost) < 0
          }
          style={{ marginLeft: 7 }}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default RoleForm
