import React from "react"

import { Permissions, UserType } from "~/helpers/permissions"

import Button from "~/common/buttons/Button"

type Props = {
  permissions: Permissions
}

export default (props: Props) => {
  if (props.permissions.type === UserType.Admin) {
    return (
      <Button
        href="https://www.runn.io/demo"
        target="_blank"
        text="Book a demo"
      />
    )
  }

  return null
}
