/**
 * @generated SignedSource<<db46334c888a57e6df1bf53844af51d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterSetQuery_user$data = {
  readonly filter_sets: ReadonlyArray<{
    readonly filters: any;
    readonly id: number;
    readonly name: string;
    readonly type: string;
  }>;
  readonly " $fragmentType": "FilterSetQuery_user";
};
export type FilterSetQuery_user$key = {
  readonly " $data"?: FilterSetQuery_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterSetQuery_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterSetQuery_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_filter_sets",
      "kind": "LinkedField",
      "name": "filter_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "c2f83720d4bb5041550fbd6c6d8a976e";

export default node;
