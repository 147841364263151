/**
 * @generated SignedSource<<0edf466deda9a51afd985f88fd4e4b68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTemplateGroup_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRow_user">;
  readonly " $fragmentType": "ProjectTemplateGroup_user";
};
export type ProjectTemplateGroup_user$key = {
  readonly " $data"?: ProjectTemplateGroup_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectTemplateGroup_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectTemplateGroup_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectRow_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "6f3f904d08e0925762bf7f21a22ea966";

export default node;
