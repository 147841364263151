/**
 * @generated SignedSource<<81cc0eb23e526735a4d9db40cc9bd6b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePersonForm_viewer$data = {
  readonly account: {
    readonly id: number;
    readonly people_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
    readonly subscription: any | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CreateBulkPeopleForm_user">;
  readonly " $fragmentType": "CreatePersonForm_viewer";
};
export type CreatePersonForm_viewer$key = {
  readonly " $data"?: CreatePersonForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatePersonForm_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatePersonForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                },
                "is_placeholder": {
                  "_eq": false
                }
              }
            }
          ],
          "concreteType": "people_aggregate",
          "kind": "LinkedField",
          "name": "people_aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "people_aggregate_fields",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "people_aggregate(where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateBulkPeopleForm_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "359062d54e69f3975b8ea5a2077eaf85";

export default node;
