/**
 * @generated SignedSource<<dae35c0012896a3f2ca75f442fcc5fae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRowSummary_project$data = {
  readonly active: boolean;
  readonly actuals_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly archivable: boolean | null | undefined;
  readonly assignments_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly calc_start_date: string | null | undefined;
  readonly client: {
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly internal: boolean | null | undefined;
    readonly name: string;
    readonly real_client: boolean | null | undefined;
    readonly website: string | null | undefined;
  };
  readonly confirmed: boolean;
  readonly created_at: string;
  readonly custom_checkbox_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: boolean;
  }>;
  readonly custom_date_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: any | null | undefined;
  }>;
  readonly custom_select_values: ReadonlyArray<{
    readonly optionId: number;
    readonly typeId: number;
  }>;
  readonly custom_text_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: string;
  }>;
  readonly emoji: string | null | undefined;
  readonly expenses_budget: number | null | undefined;
  readonly future_assignments_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly has_actuals: boolean | null | undefined;
  readonly id: number;
  readonly is_template: boolean;
  readonly links: ReadonlyArray<{
    readonly href: string;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly name: string;
  readonly pricing_model: string;
  readonly priority: string | null | undefined;
  readonly project_roles: ReadonlyArray<{
    readonly estimated_minutes: number | null | undefined;
    readonly id: number;
    readonly role_id: number;
  }>;
  readonly tags_computed: any | null | undefined;
  readonly total_budget: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CreateProjectFromTemplateForm_project" | "CreateTemplateFromProjectForm_project" | "EditProjectForm_project" | "PhasesWrapper_project" | "PlannerLeftColumn_project" | "ProjectLine_project" | "ProjectMilestones_project">;
  readonly " $fragmentType": "ProjectRowSummary_project";
};
export type ProjectRowSummary_project$key = {
  readonly " $data"?: ProjectRowSummary_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRowSummary_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "assignments_aggregate_fields",
    "kind": "LinkedField",
    "name": "aggregate",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "todaysDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRowSummary_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calc_start_date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_actuals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags_computed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": "total_budget",
      "args": null,
      "kind": "ScalarField",
      "name": "total_budget_private",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expenses_budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "real_client",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_roles",
      "kind": "LinkedField",
      "name": "project_roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimated_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_values",
      "kind": "LinkedField",
      "name": "custom_text_values",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_text_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_values",
      "kind": "LinkedField",
      "name": "custom_date_values",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_date_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_values",
      "kind": "LinkedField",
      "name": "custom_select_values",
      "plural": true,
      "selections": [
        {
          "alias": "optionId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_option_id",
          "storageKey": null
        },
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_values",
      "kind": "LinkedField",
      "name": "custom_checkbox_values",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_checkbox_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "actuals_aggregate",
      "kind": "LinkedField",
      "name": "actuals_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "actuals_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "assignments_aggregate",
      "kind": "LinkedField",
      "name": "assignments_aggregate",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_links",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "href",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show_in_planner",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "future_assignments_aggregate",
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_gte",
                  "variableName": "todaysDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "end_date_iso"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "assignments_aggregate",
      "kind": "LinkedField",
      "name": "assignments_aggregate",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectLine_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectMilestones_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditProjectForm_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateProjectFromTemplateForm_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateTemplateFromProjectForm_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PhasesWrapper_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "40893939cba4bc5870c1f9bc634be7fe";

export default node;
