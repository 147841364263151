import { combineReducers } from "redux"

import plannerStats from "~/Planner/reducer2/plannerStatsSlice"

import panel from "./panelSlice"
import peopleFilter from "./peopleFilterSlice"
import people from "./peopleSortSlice"
import peopleSummary from "./peopleSummarySlice"
import projectFilter from "./projectFilterSlice"
import projectMembersView from "./projectMembersViewSlice"
import project from "./projectSortSlice"
import scenarioPlanning from "./scenarioPlanningSlice"
import schedulePreview from "./schedulePreviewSlice"
import splitScreen from "./splitScreenSlice"
import views from "./viewsSlice"

const planner2 = combineReducers({
  panel,
  people,
  project,
  projectFilter,
  projectMembersView,
  scenarioPlanning,
  splitScreen,
  schedulePreview,
  peopleSummary,
  peopleFilter,
  plannerStats,
  views,
})

export default planner2
