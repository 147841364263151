import React, { useState } from "react"

import styles from "./EffortInput.module.css"

import { showToast } from "~/containers/ToasterContainer"

type Props = {
  minutesPerDay: number
  onChange: (minutes: number, error?: any) => void
  maxMinutes: number
  totalForPercentage: number
}

export const roundToHalf = (num: number) => {
  const floor = Math.floor(num)
  const decimal = num - floor

  if (decimal < 0.25) {
    return floor
  } else if (decimal < 0.75) {
    return floor + 0.5
  } else {
    return floor + 1
  }
}

export const CapacityPercentageInput = (props: Props) => {
  const { minutesPerDay, onChange, maxMinutes, totalForPercentage } = props

  const [value, setValue] = useState(
    roundToHalf((minutesPerDay / totalForPercentage) * 100),
  )

  return (
    <input
      id="effort-percentage"
      type="number"
      min={0}
      max={100}
      step={1}
      value={value}
      onChange={(e) => {
        const newPercentage = e.target.valueAsNumber
        const minRoundedPercentage =
          newPercentage === 0 ? 0 : Math.max(newPercentage, 0.5)

        const newMinutes = (totalForPercentage / 100) * minRoundedPercentage

        if (newMinutes > maxMinutes) {
          const maxPercentage = (maxMinutes / totalForPercentage) * 100

          setValue(maxPercentage)
          onChange(maxMinutes, true)

          showToast({
            message: "Capacity cannot exceed minutes per day",
            type: "warning",
          })
        } else {
          setValue(newPercentage)
          onChange(newMinutes)
        }
      }}
      className={styles.duration}
    />
  )
}
