import { ResizeObserver } from "@juggle/resize-observer"
import debounce from "lodash-es/debounce"
import { useMemo, useState } from "react"
import useResizeObserver from "use-resize-observer"

const isSafari = navigator.vendor === "Apple Computer, Inc."

// https://codesandbox.io/s/use-resize-observer-throttle-and-debounce-8uvsg?file=/src/index.js
type Size = {
  width?: number
  height?: number
}
export default (wait, opts: Parameters<typeof useResizeObserver>[0]) => {
  // Safari 15.3 and below only have partial support for ResizeObserver
  // Use (slower) polyfill for any Safari version until we can require >15.3 as a baseline.
  // https://caniuse.com/mdn-api_resizeobserverentry_borderboxsize
  if (isSafari) {
    window.ResizeObserver = ResizeObserver
  }

  const [size, setSize] = useState<Size>({})
  const onResize = useMemo(
    () => debounce(setSize, wait, { leading: true }),
    [wait],
  )
  const { ref } = useResizeObserver({ onResize, ...opts })

  return { ref, ...size }
}
