/**
 * @generated SignedSource<<9f379eefc9b254e7126ba7c778cc78e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExternalProjectMemberFormQuery$variables = {
  ext_project_id?: number | null | undefined;
};
export type ExternalProjectMemberFormQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly ext_projects: ReadonlyArray<{
        readonly ext_project_links: ReadonlyArray<{
          readonly ext_project_id: number;
          readonly project: {
            readonly id: number;
            readonly members: ReadonlyArray<{
              readonly id: number;
              readonly person_id: number;
            }>;
          };
          readonly project_id: number;
        }>;
        readonly ext_project_members: ReadonlyArray<{
          readonly ext_person: {
            readonly email: string | null | undefined;
            readonly ext_person_links: ReadonlyArray<{
              readonly active: boolean;
              readonly ext_person_id: number;
              readonly external_id: number;
              readonly person_id: number;
            }>;
            readonly first_name: string;
            readonly id: number;
            readonly last_name: string;
          };
          readonly id: number;
        }>;
        readonly id: number;
        readonly name: string;
      }>;
      readonly id: number;
    };
    readonly id: number;
  } | null | undefined;
};
export type ExternalProjectMemberFormQuery = {
  response: ExternalProjectMemberFormQuery$data;
  variables: ExternalProjectMemberFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ext_project_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "active": {
        "_eq": true
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "ext_project_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "where"
              }
            ],
            "concreteType": "ext_project",
            "kind": "LinkedField",
            "name": "ext_projects",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ext_project_link",
                "kind": "LinkedField",
                "name": "ext_project_links",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ext_project_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "project_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "projects",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_members",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ext_project_links(where:{\"active\":{\"_eq\":true}})"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "deleted": {
                        "_eq": false
                      }
                    }
                  }
                ],
                "concreteType": "ext_project_member",
                "kind": "LinkedField",
                "name": "ext_project_members",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ext_person",
                    "kind": "LinkedField",
                    "name": "ext_person",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "concreteType": "ext_person_link",
                        "kind": "LinkedField",
                        "name": "ext_person_links",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ext_person_id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          },
                          {
                            "alias": "external_id",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ext_person_id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ext_project_members(where:{\"deleted\":{\"_eq\":false}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalProjectMemberFormQuery",
    "selections": (v4/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalProjectMemberFormQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1702ba4a92a3d17b17a76cd20549e208",
    "id": null,
    "metadata": {},
    "name": "ExternalProjectMemberFormQuery",
    "operationKind": "query",
    "text": "query ExternalProjectMemberFormQuery(\n  $ext_project_id: Int\n) {\n  current_user {\n    id\n    account {\n      id\n      ext_projects(where: {id: {_eq: $ext_project_id}}) {\n        id\n        name\n        ext_project_links(where: {active: {_eq: true}}) {\n          ext_project_id\n          project_id\n          project {\n            id\n            members {\n              id\n              person_id\n            }\n          }\n        }\n        ext_project_members(where: {deleted: {_eq: false}}) {\n          id\n          ext_person {\n            id\n            first_name\n            last_name\n            email\n            ext_person_links(where: {active: {_eq: true}}) {\n              person_id\n              ext_person_id\n              active\n              external_id: ext_person_id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ddb8377126baa38350a78dd846a2c9f";

export default node;
