import React from "react"

import * as reactIcons from "~/common/react-icons"

const icons = [
  { name: "dollar", component: <reactIcons.Dollar /> },
  { name: "warning", component: <reactIcons.Warning color="#fff" /> },
  { name: "start", component: <reactIcons.Start color="#fff" /> },
  { name: "end", component: <reactIcons.End color="#fff" /> },
  { name: "flag", component: <reactIcons.Flag /> },
  { name: "plus", component: <reactIcons.Plus alt="Add Milestone" /> },
]

export default icons
