import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PeoplePlannerList.module.css"

import type { PeoplePlannerList_user$key } from "./__generated__/PeoplePlannerList_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { prepareDataForFilterPersonList } from "~/helpers/filter-engine"

import usePeoplePlannerFilters from "~/PeoplePlanner/usePeoplePlannerFilters"
import { useAppSelector } from "~/hooks/redux"
import TimeOffSummary from "~/time_off/TimeOffSummary"

import PersonManagement from "./PersonManagement"

const fragment = graphql`
  fragment PeoplePlannerList_user on users
  @argumentDefinitions(
    peopleFilter: { type: "people_bool_exp" }
    projectsFilter: { type: "projects_bool_exp" }
    plannerStartDate: { type: "date!" }
  ) {
    id
    favourite_people
    view {
      id
      people_filters
    }
    account {
      projects(where: $projectsFilter) {
        id
        name
        active
        confirmed
        is_template
        tags_computed
        client {
          id
          name
        }
        team {
          id
          name
        }
        members {
          id
          person_id
          role_id
          workstream_id
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        managers {
          id
          user_id
        }
      }
      people(where: $peopleFilter) {
        id
        is_placeholder
        first_name
        last_name
        email
        active
        tags
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          employment_type
          job_title
          role {
            id
            name
          }
        }
        team {
          id
          name
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          end_date: end_date_runn
          project {
            id
            confirmed
          }
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        person_requests {
          id
          status
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        links {
          id
          name
          href
          show_in_planner
        }
        managers {
          id
          user_id
        }
      }
      ...TimeOffSummary_account
        @arguments(
          plannerStartDate: $plannerStartDate
          peopleFilter: $peopleFilter
        )
      ...TimeOffSummaryCounter_account
        @arguments(
          plannerStartDate: $plannerStartDate
          peopleFilter: $peopleFilter
        )
    }
    ...PersonManagement_user
      @arguments(
        plannerStartDate: $plannerStartDate
        projectsFilter: $projectsFilter
        peopleFilter: $peopleFilter
      )
  }
`

const PeoplePlannerList = () => {
  const user = useFragment<PeoplePlannerList_user$key>(
    fragment,
    useHasuraContext(),
  )

  const { favourite_people: favouritePersonIds, account, view } = user
  const { people: allPeople, projects: allProjects } = account
  const showAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )

  const userDefaultView =
    useFeature("pre_filtered_views") && !showAllView ? view : null

  const projects = allProjects.filter((project) => !project.is_template)

  const timeOffBarCollapsed = useAppSelector(
    (state) => state.planner.timeOffBarCollapsed,
  )
  const calStartNum = useAppSelector((state) => state.calendar.calStartNum)

  const filterSet = usePeoplePlannerFilters(userDefaultView?.people_filters)

  const filterTypeList = fe.filters.asTypeList(filterSet.filters)

  const includeArchivedProjectsInSearch = useAppSelector(
    (state) => state.plannerV2.peopleFilter.includeArchivedProjects,
  )

  const preparedList = useMemo(
    () => {
      return prepareDataForFilterPersonList({
        filterTypeList,
        projects,
        people: allPeople,
        favouritePersonIds,
        calStartNum,
        includeArchivedProjects: includeArchivedProjectsInSearch,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterTypeList.join(","),
      projects,
      allPeople,
      favouritePersonIds,
      calStartNum,
    ],
  )

  const filteredPersonList = useMemo(() => {
    return fe.engines.local.filterPersonList(preparedList, filterSet)
  }, [preparedList, filterSet])

  const filteredPersonIds = new Set<number>(
    filteredPersonList.map((person) => person.id),
  )

  return (
    <div
      className={cc([
        styles.container,
        {
          [styles.timeOffBarOpen]: !timeOffBarCollapsed,
        },
      ])}
    >
      <TimeOffSummary account={account} filteredPersonIds={filteredPersonIds} />
      {timeOffBarCollapsed && (
        <div
          data-component="PeoplePlannerList"
          className={styles.peoplePlannerList}
        >
          <PersonManagement user={user} filteredPersonIds={filteredPersonIds} />
        </div>
      )}
    </div>
  )
}

export default PeoplePlannerList
