import { ButtonGroup } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./ChartHeader.module.css"

import SuperSearch, { usePersonSearchConfig } from "~/common/SuperSearch"
import Button from "~/common/buttons/Button"

import { createFilterSet, deleteFilterSet } from "~/mutations/FilterSet"

import type { ChartPeriod, ChartType } from "~/Planner/ChartPanel/types"
import { setPeopleWildSearch } from "~/Planner/Planner.actions"
import { useAppSelector } from "~/hooks/redux"
import { SavedFilterSet, useFilterSets } from "~/queries/FilterSet"

type Props = {
  filterSet: fe.engines.local.AllowedFilterSet
  onChangeFilterSet: (filterSet: fe.engines.local.AllowedFilterSet) => void
  type: ChartType
  onChangeType: (type: ChartType) => void
  period: ChartPeriod
  onChangePeriod: (period: ChartPeriod) => void
}

const ChartHeader = (props: Props) => {
  const {
    filterSet,
    onChangeFilterSet,
    type,
    onChangeType,
    period,
    onChangePeriod,
  } = props

  const savedFilterSets = useFilterSets({ type: "person" })

  const handleCreateSavedFilterSet = async (
    newFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    await createFilterSet({
      filterSet: {
        type: "person",
        name: newFilterSet.name,
        filters: newFilterSet.filters,
      },
    })
  }

  const handleDeleteSavedFilterSet = async (
    selectedFilterSet: SavedFilterSet,
  ) => {
    await deleteFilterSet({ filterSetId: selectedFilterSet.id })
  }

  const personSearchConfig = usePersonSearchConfig({
    location: "charts",
    excludedFilters: ["person_state"],
    saveFilter: false,
  })

  const dispatch = useDispatch()

  const wildSearchQuery = useAppSelector(
    (state) => state.planner.peopleWildSearch,
  )
  const handleChangeWildSearchQuery = (query: string | undefined) => {
    return dispatch(setPeopleWildSearch(query))
  }

  return (
    <div className={styles.container}>
      <SuperSearch
        config={personSearchConfig}
        location="chart"
        wildSearchQuery={wildSearchQuery}
        onChangeWildSearchQuery={handleChangeWildSearchQuery}
        filterSet={filterSet}
        onChangeFilterSet={onChangeFilterSet}
        savedFilterSets={savedFilterSets}
        onCreateSavedFilterSet={handleCreateSavedFilterSet}
        onDeleteSavedFilterSet={handleDeleteSavedFilterSet}
      />

      <ButtonGroup className={styles.buttonGroup}>
        <Button
          text="Daily"
          onClick={() => onChangePeriod("daily")}
          active={period === "daily"}
        />
        <Button
          text="Weekly"
          onClick={() => onChangePeriod("weekly")}
          active={period === "weekly"}
        />
      </ButtonGroup>

      <ButtonGroup className={styles.buttonGroup}>
        <Button
          text="Capacity"
          onClick={() => onChangeType("capacity")}
          active={type === "capacity"}
        />
        <Button
          text="Availability"
          onClick={() => onChangeType("availability")}
          active={type === "availability"}
        />
        <Button
          text="Utilization"
          onClick={() => onChangeType("utilization")}
          active={type === "utilization"}
        />
      </ButtonGroup>
    </div>
  )
}

export { ChartHeader }
