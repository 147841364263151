import { createSlice } from "@reduxjs/toolkit"

const showTotalEffortSlice = createSlice({
  name: "showTotalEffort",
  initialState: false,
  reducers: {
    toggledShowTotalEffort: (state) => !state,
  },
})

export const { toggledShowTotalEffort } = showTotalEffortSlice.actions

export default showTotalEffortSlice.reducer
