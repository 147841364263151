import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./FilterBlock.module.css"

import type { Option } from "~/types/helpers"

import { Cross as CrossIcon } from "~/common/react-icons"

import FilterBlockMenu from "./FilterBlockMenu"
import FilterDropdown from "./FilterDropdown"

type ClassNames = {
  targetButton?: string
  targetSelected?: string

  tooltip?: string
  tooltipItem?: string
}

type TooltipContentProps<Value> = {
  classNames?: ClassNames
  selectedOptions: Option<Value>[]
  children: (option: Option<Value>) => React.ReactNode
}

const TooltipContent = <Value,>(props: TooltipContentProps<Value>) => {
  const { classNames = {}, selectedOptions, children } = props

  return (
    <div className={cc([styles.tooltip, classNames.tooltip])}>
      {selectedOptions.length === 0 ? "⚠️ Missing value" : null}
      {selectedOptions.map((option, index) => (
        <div
          key={index}
          className={cc([styles.tooltipItem, classNames?.tooltipItem])}
        >
          {children(option)}
        </div>
      ))}
    </div>
  )
}

type TargetProps<Value> = {
  classNames?: ClassNames
  icon: React.ReactNode
  label: React.ReactNode
  disabled?: boolean
  isDropdownOpen: boolean
  selectedOptions: Option<Value>[]
  renderTargetOptionLabel?: (option: Option<Value>) => React.ReactNode
  onRemove: () => void
}

export const Target = <Value,>(props: TargetProps<Value>) => {
  const {
    classNames = {},
    icon,
    label,
    disabled,
    isDropdownOpen,
    selectedOptions,
    renderTargetOptionLabel = (option) => option.label,
    onRemove,
  } = props

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!disabled) {
      onRemove()
    }
  }

  return (
    <button
      disabled={disabled}
      className={cc({
        [styles.targetButton]: true,
        [classNames.targetButton]: true,
        [styles.empty]: selectedOptions.length === 0,
        [styles.isDropdownOpen]: isDropdownOpen,
      })}
      data-test={`SuperSearch_FilterBlock_${label}_target`}
    >
      <Tooltip
        disabled={disabled}
        content={
          <TooltipContent
            classNames={classNames}
            selectedOptions={selectedOptions}
          >
            {renderTargetOptionLabel}
          </TooltipContent>
        }
        placement="top"
      >
        <div className={styles.targetButtonText}>
          <span className={styles.targetIcon}>{icon}</span>
          <span className={styles.targetLabel}>{label}</span>
          <span
            className={cc([styles.targetSelected, classNames.targetSelected])}
          >
            {selectedOptions.length > 0
              ? renderTargetOptionLabel(selectedOptions[0])
              : null}
          </span>

          <span className={styles.targetSelectedCount}>
            {selectedOptions.length > 1
              ? `+${selectedOptions.length - 1}`
              : null}
          </span>
        </div>
      </Tooltip>

      {!disabled && (
        <Tooltip
          className={styles.removeButtonTooltip}
          content="Remove filter"
          placement="top"
        >
          <div
            className={styles.removeButton}
            onClick={handleRemove}
            data-test={`SuperSearch_FilterBlock_${label}_removeButton`}
          >
            <CrossIcon />
          </div>
        </Tooltip>
      )}
    </button>
  )
}

type Props<Value> = {
  classNames?: ClassNames

  icon: React.ReactNode
  label: React.ReactNode
  disabled?: boolean
  selectedOptions: Option<Value>[]
  availableOptions: Option<Value>[]
  isDropdownOpen: boolean

  filterLabelPlural: React.ReactNode

  renderTargetOptionLabel?: (option: Option<Value>) => React.ReactNode
  renderFilterBlockOption?: (option: Option<Value>) => React.ReactNode

  onChange: (list: Value[]) => void
  onChangeDropdownOpen: (isOpen: boolean) => void
  onRemove: () => void
}

const FilterBlock = <Value,>(props: Props<Value>) => {
  const {
    classNames = {},
    icon,
    label,
    disabled,
    isDropdownOpen,
    selectedOptions,
    availableOptions,
    filterLabelPlural,
    renderFilterBlockOption,
    renderTargetOptionLabel = (option) => option.label,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const onCloseDropdown = () => onChangeDropdownOpen(false)
  const onOpenDropdown = () => onChangeDropdownOpen(true)

  return (
    <FilterDropdown
      disabled={disabled}
      isOpen={isDropdownOpen}
      target={
        <Target
          classNames={classNames}
          icon={icon}
          label={label}
          selectedOptions={selectedOptions}
          disabled={disabled}
          onRemove={onRemove}
          renderTargetOptionLabel={renderTargetOptionLabel}
          isDropdownOpen={isDropdownOpen}
        />
      }
      onOpen={onOpenDropdown}
      onClose={onCloseDropdown}
    >
      <FilterBlockMenu
        filterLabelPlural={filterLabelPlural}
        selectedOptions={selectedOptions}
        availableOptions={availableOptions}
        onChange={onChange}
        onDone={onCloseDropdown}
      >
        {renderFilterBlockOption}
      </FilterBlockMenu>
    </FilterDropdown>
  )
}

export default FilterBlock
