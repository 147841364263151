import { Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { format, parseISO } from "date-fns"
import React from "react"
import { P, match } from "ts-pattern"

import styles from "./FilterBlock.module.css"

import { Cross as CrossIcon } from "~/common/react-icons"

import FilterBlockDateMenu from "./FilterBlockDateMenu"
import FilterDropdown from "./FilterDropdown"

export type DatePayload =
  | {
      type: "range"
      start: string | null
      end: string | null
    }
  | {
      type: "exact"
      exact: string | null
    }

type Props = {
  icon: React.ReactNode
  label: string
  disabled?: boolean
  datePayload: DatePayload
  onRemove: () => void
  onChange: (datePayload: DatePayload) => void
  isDropdownOpen: boolean
  onChangeDropdownOpen: (isOpen: boolean) => void
}

export const formatDate = (date: string | Date): string | null => {
  if (!date) {
    return null
  }
  if (typeof date === "string") {
    date = parseISO(date)
  }
  return format(date, "yyyy-MM-dd")
}

export const toString = (date: DatePayload): string => {
  return match(date)
    .with({ type: "exact" }, ({ exact }) =>
      exact ? formatDate(dateHelpers.parseDatabaseDate(exact)) : "",
    )
    .with(
      { type: "range" },
      ({ start, end }) =>
        `${start ? formatDate(dateHelpers.parseDatabaseDate(start)) : ""} - ${end ? formatDate(dateHelpers.parseDatabaseDate(end)) : ""}`,
    )
    .exhaustive()
}

type TooltipProps = {
  datePayload: DatePayload
}

const TooltipContent = (props: TooltipProps) => {
  const { datePayload } = props

  const tooltipText = match(datePayload)
    .with({ type: "exact", exact: P.nullish }, () => "⚠️ Missing date")
    .with(
      { type: "range", start: P.nullish, end: P.not(P.nullish) },
      () => "⚠️ Missing start date",
    )
    .with(
      { type: "range", start: P.not(P.nullish), end: P.nullish },
      () => "⚠️ Missing end date",
    )
    .with(
      { type: "range", start: P.nullish, end: P.nullish },
      () => "⚠️ Missing start and end date",
    )
    .with({ type: "exact", exact: P.not(P.nullish) }, (d) =>
      toString(datePayload),
    )
    .with(
      { type: "range", start: P.not(P.nullish), end: P.not(P.nullish) },
      () => toString(datePayload),
    )
    .otherwise(() => null)

  return <div className={styles.tooltip}>{tooltipText}</div>
}

type TargetProps = {
  icon: React.ReactNode
  label: string
  disabled?: boolean
  isDropdownOpen: boolean
  datePayload: DatePayload
  onRemove: () => void
}

const Target = (props: TargetProps) => {
  const { icon, label, disabled, isDropdownOpen, datePayload, onRemove } = props

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!disabled) {
      onRemove()
    }
  }

  return (
    <button
      disabled={disabled}
      className={cc([
        styles.targetButton,
        {
          [styles.empty]:
            !datePayload ||
            (datePayload.type === "exact" && !datePayload.exact) ||
            (datePayload.type === "range" &&
              (!datePayload.start || !datePayload.end)),
        },
        { [styles.isDropdownOpen]: isDropdownOpen },
      ])}
      data-test={`SuperSearch_FilterBlock_${label}_target`}
    >
      <Tooltip
        disabled={disabled}
        content={<TooltipContent datePayload={datePayload} />}
        placement="top"
      >
        <div className={styles.targetButtonText}>
          <span className={styles.targetIcon}>{icon}</span>
          <span className={styles.targetLabel}>{label}</span>
          <span className={styles.targetSelected}>{toString(datePayload)}</span>
        </div>
      </Tooltip>

      {!disabled && (
        <Tooltip
          className={styles.removeButtonTooltip}
          content="Remove filter"
          placement="top"
        >
          <div
            className={styles.removeButton}
            onClick={handleRemove}
            data-test={`SuperSearch_FilterBlock_${label}_removeButton`}
          >
            <CrossIcon />
          </div>
        </Tooltip>
      )}
    </button>
  )
}

const FilterBlockDate = (props: Props) => {
  const {
    icon,
    label,
    disabled,
    datePayload,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleOpenDropdown = () => onChangeDropdownOpen(true)
  const handleCloseDropdown = () => onChangeDropdownOpen(false)

  return (
    <FilterDropdown
      disabled={disabled}
      placement="bottom-start"
      isOpen={isDropdownOpen}
      target={
        <Target
          icon={icon}
          label={label}
          disabled={disabled}
          datePayload={datePayload}
          onRemove={onRemove}
          isDropdownOpen={isDropdownOpen}
        />
      }
      onOpen={handleOpenDropdown}
      onClose={handleCloseDropdown}
    >
      <FilterBlockDateMenu
        label={label}
        datePayload={datePayload}
        onChange={onChange}
        onClose={handleCloseDropdown}
      />
    </FilterDropdown>
  )
}

export default FilterBlockDate
