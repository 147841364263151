import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import numbro from "numbro"
import React from "react"

import styles from "./HeadingBar.module.css"

import { IconRotatingChevron } from "./IconRotatingChevron"
import TooltipEllipsis from "./TooltipEllipsis"
import { EmptyCircleIcon, FaceIcon } from "./react-icons"

type Props = {
  id?: string
  text: string | React.ReactElement
  peopleCount: string | number
  placeholderCount: string | number
  isCollapsed: boolean
  onClick: () => void
  children?: React.ReactElement
}

type TextProps = {
  peopleCount: string | number
  placeholderCount: string | number
  text: string | React.ReactElement
  isCollapsed: boolean
  showIcon?: boolean
}

const formatCount = (count) =>
  numbro(count).format({
    thousandSeparated: true,
    mantissa: 0,
  })

export const PeopleHeadingBarText = ({
  peopleCount,
  placeholderCount,
  text,
  isCollapsed,
  showIcon = true,
}: TextProps) => {
  const showPlaceholders = Boolean(placeholderCount)
  const showPeople = Boolean(peopleCount)

  return (
    <div className={styles.text}>
      {showIcon && (
        <IconRotatingChevron
          direction={isCollapsed ? "right" : "down"}
          color="var(--shadow)"
          style={{ marginRight: 5 }}
        />
      )}
      <TooltipEllipsis text={text} />
      <div className={styles.peopleGrouping}>
        {showPlaceholders && (
          <div className={cc([styles.count, styles.peopleHeadingCount])}>
            <Tooltip placement="top" content="Placeholders">
              <EmptyCircleIcon />
            </Tooltip>
            {formatCount(placeholderCount)}
          </div>
        )}
        {showPeople && (
          <div className={cc([styles.count, styles.peopleHeadingCount])}>
            <Tooltip placement="top" content="People">
              <FaceIcon size={12} color="var(--shadow)" />
            </Tooltip>
            {formatCount(peopleCount)}
          </div>
        )}
      </div>
    </div>
  )
}

const PeopleHeadingBar = (props: Props) => {
  const {
    text,
    isCollapsed,
    peopleCount,
    placeholderCount,
    onClick,
    children,
    ...rest
  } = props

  return (
    <div
      className={cc([
        styles.headingBar,
        {
          [styles.collapsible]: onClick,
          [styles.sticky]: onClick,
        },
      ])}
      onClick={onClick}
      {...rest}
    >
      <PeopleHeadingBarText
        peopleCount={peopleCount}
        placeholderCount={placeholderCount}
        text={text}
        isCollapsed={isCollapsed}
        showIcon={Boolean(onClick)}
      />
      {children}
    </div>
  )
}

export default PeopleHeadingBar
