/**
 * @generated SignedSource<<a93f716689465adff1f18d69584f55d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_project$data = {
  readonly id: number;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"PersonRows_project" | "ProjectGroupRow_project">;
  readonly " $fragmentType": "ProjectDetails_project";
};
export type ProjectDetails_project$key = {
  readonly " $data"?: ProjectDetails_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonRows_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectGroupRow_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "51035f790633d1d97d5b902988c7eb25";

export default node;
