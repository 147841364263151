import { combineReducers } from "redux"

import effortDisplayUnitSlice from "./effortDisplayUnitSlice"
import showTotalEffortSlice from "./showTotalEffortSlice"
import totalEffortDisplayUnitSlice from "./totalEffortDisplayUnitSlice"

export default combineReducers({
  effort: effortDisplayUnitSlice,
  totalEffort: totalEffortDisplayUnitSlice,
  showTotalEffort: showTotalEffortSlice,
})
