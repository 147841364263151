/**
 * @generated SignedSource<<3f2caf8ffcf9b127071647860e705edc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitScreenRow_person$data = {
  readonly assignments: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly is_billable: boolean | null | undefined;
    readonly is_template: boolean;
    readonly minutes_per_day: number;
    readonly non_working_day: boolean;
    readonly note: string | null | undefined;
    readonly person_id: number;
    readonly phase_id: number | null | undefined;
    readonly project_id: number;
    readonly role_id: number;
    readonly start_date: string | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly competencies: ReadonlyArray<{
    readonly id: number;
    readonly level: number | null | undefined;
    readonly skill: {
      readonly id: number;
      readonly name: string;
    };
  }>;
  readonly contracts: ReadonlyArray<{
    readonly cost: number | null | undefined;
    readonly employment_type: string;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly minutes_per_day: number | null | undefined;
    readonly role: {
      readonly id: number;
      readonly name: string | null | undefined;
    };
    readonly rostered_days: ReadonlyArray<number> | null | undefined;
    readonly start_date: string | null | undefined;
  }>;
  readonly custom_checkbox_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: boolean;
  }>;
  readonly custom_date_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: any | null | undefined;
  }>;
  readonly custom_select_values: ReadonlyArray<{
    readonly optionId: number;
    readonly typeId: number;
  }>;
  readonly custom_text_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: string;
  }>;
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly id: number;
  readonly image_key: string | null | undefined;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly person_requests: ReadonlyArray<{
    readonly id: number;
    readonly status: any;
  }>;
  readonly project_memberships: ReadonlyArray<{
    readonly id: number;
    readonly project: {
      readonly id: number;
      readonly is_template: boolean;
      readonly name: string;
    };
  }>;
  readonly references: any;
  readonly tags: any;
  readonly time_offs: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly ext_time_off_links: ReadonlyArray<{
      readonly active: boolean;
      readonly ext_time_off_id: number;
      readonly external: {
        readonly __typename: "ext_time_off";
        readonly id: number;
        readonly integration_id: number;
      } | null | undefined;
      readonly external_id: number;
      readonly time_off_id: number;
    }>;
    readonly id: number;
    readonly leave_type: string;
    readonly minutes_per_day: number | null | undefined;
    readonly person_id: number;
    readonly start_date: string | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PersonDetails_person" | "PersonSidePanel_person" | "PersonSummaryRow_person" | "PlaceholderSidePanel_placeholder">;
  readonly " $fragmentType": "SplitScreenRow_person";
};
export type SplitScreenRow_person$key = {
  readonly " $data"?: SplitScreenRow_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitScreenRow_person">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "plannerStartDate",
    "variableName": "plannerStartDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v4 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v5 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_template",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v1/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitScreenRow_person",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PersonSummaryRow_person"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PersonDetails_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderSidePanel_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonSidePanel_person"
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "references",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "time_offs",
      "kind": "LinkedField",
      "name": "time_offs",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leave_type",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                }
              }
            }
          ],
          "concreteType": "ext_time_off_link",
          "kind": "LinkedField",
          "name": "ext_time_off_links",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time_off_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "external_id",
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            {
              "alias": "external",
              "args": null,
              "concreteType": "ext_time_off",
              "kind": "LinkedField",
              "name": "ext_time_off",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "integration_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "assignments",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_billable",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "non_working_day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phase_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rostered_days",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employment_type",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": "cost",
          "args": null,
          "kind": "ScalarField",
          "name": "cost_private",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": (v9/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "competencies",
      "kind": "LinkedField",
      "name": "competencies",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "skills",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v9/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_values",
      "kind": "LinkedField",
      "name": "custom_text_values",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_text_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_values",
      "kind": "LinkedField",
      "name": "custom_date_values",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_date_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_values",
      "kind": "LinkedField",
      "name": "custom_select_values",
      "plural": true,
      "selections": [
        {
          "alias": "optionId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_option_id",
          "storageKey": null
        },
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_values",
      "kind": "LinkedField",
      "name": "custom_checkbox_values",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_checkbox_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "person_requests",
      "kind": "LinkedField",
      "name": "person_requests",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v8/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "f8b9f255f97ce96e6d4fc073e21e29b8";

export default node;
