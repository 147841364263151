/**
 * @generated SignedSource<<3ff7f48dc445f4e9b9314b33f95c2f31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Timeline_user$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly id: number;
    readonly use_week_numbers: boolean | null | undefined;
  };
  readonly id: number;
  readonly " $fragmentType": "Timeline_user";
};
export type Timeline_user$key = {
  readonly " $data"?: Timeline_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Timeline_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Timeline_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "use_week_numbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "2e6a42e2656633cfa4acc212c1e92622";

export default node;
