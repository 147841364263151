import uniqueId from "lodash-es/uniqueId"
import React, { useState } from "react"

import styles from "./CreateNoteForm.module.css"

import Button from "~/common/buttons/Button"

type Props = {
  savedNote?: string
  onSubmit: (note: string) => void
  closeForm: () => void
}

const CreateNoteForm = (props: Props) => {
  const { closeForm, onSubmit, savedNote } = props

  const [note, setNote] = useState(savedNote || "")
  const [inputId] = useState(uniqueId("create-note-"))

  const handleSubmit = () => {
    onSubmit(note)
    closeForm()
  }

  return (
    <div className={styles.container}>
      <label htmlFor={inputId} className={styles.visuallyHidden}>
        Create a Note
      </label>
      <textarea
        id={inputId}
        placeholder="Add notes, important links, etc."
        value={note}
        onChange={(e) => setNote(e.target.value)}
        autoFocus
      />
      <div className={styles.footer}>
        <Button text="Cancel" onClick={closeForm} />
        <Button
          text="Save"
          outlined={false}
          onClick={handleSubmit}
          disabled={!note}
        />
      </div>
    </div>
  )
}

export default CreateNoteForm
