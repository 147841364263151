import React from "react"
import { connect } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { PersonDetails_person$key } from "./__generated__/PersonDetails_person.graphql"
import {
  PersonDetails_user$data,
  PersonDetails_user$key,
} from "~/PeoplePlanner/PersonDetails/__generated__/PersonDetails_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getTimeOffWeekends } from "~/helpers/CalendarHelper"
import { getVisibleAssignments } from "~/helpers/assignment-helpers"
import { getHolidaysOverlappingTimeOffs } from "~/helpers/holiday-helpers"
import {
  Membership,
  getMemberKey,
  isJustAdded,
} from "~/helpers/project-member-helpers"

import PersonAddProjectRow from "~/PeoplePlanner/PersonAddProjectRow/PersonAddProjectRow"
import PersonProjectRow from "~/PeoplePlanner/PersonProjectRow"
import PersonTimeOffRow from "~/PeoplePlanner/PersonTimeOffRow/PersonTimeOffRow"
import { selectAnyTentativeProjectsEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { useProjectMembershipsList } from "~/hooks/useProjectMembersList"

import PlaceholderProjectRow from "../PlaceholderRow/PlaceholderProjectRow"

import ProjectStatusRow from "./ProjectStatusRow"

type ProjectRoleWorkstream = {
  person_id: number
  project_id: number
  role_id: number
  workstream_id: number
  just_added_timestamp?: number
}
type Project = PersonDetails_user$data["account"]["projects"][number]
type Role = PersonDetails_user$data["account"]["roles"][number]

type DetailedMembership = Membership & {
  client_id: number
  project: Project
  role: Role
  active: boolean
}

type MemberRows = Record<
  string,
  {
    projectId: number
    roleId: number
    workstreamId: number
    just_added_timestamp?: number
  }
>

const sortAlpha = (a: string, b: string) => {
  const valueA = a.toLowerCase()
  const valueB = b.toLowerCase()
  if (valueA < valueB) {
    return -1
  }
  if (valueA > valueB) {
    return 1
  }
  return 0
}

export const sortProjects = <
  M extends {
    project_id: number
    role_id: number
    workstream_id: number
    client_id: number
  },
>(
  memberships: ReadonlyArray<M>,
  options: SortProjectsOptions,
) => {
  const sortable = [...memberships]
  sortable.sort((a, b) => {
    const ProjectA = options.projects.find((p) => p.id === a.project_id)
    const ProjectB = options.projects.find((p) => p.id === b.project_id)

    if (ProjectA.client.id !== ProjectB.client.id) {
      return sortAlpha(ProjectA.client.name, ProjectB.client.name)
    }

    if (a.project_id !== b.project_id) {
      const projectA = ProjectA.name
      const projectB = ProjectB.name
      return sortAlpha(projectA, projectB)
    }

    if (a.role_id !== b.role_id) {
      const roleA = options.roles.find((c) => a.role_id === c.id).name
      const roleB = options.roles.find((c) => b.role_id === c.id).name
      return sortAlpha(roleA, roleB)
    }

    if (a.workstream_id !== b.workstream_id) {
      if (!a.workstream_id) {
        return -1
      }

      if (!b.workstream_id) {
        return 1
      }

      const workstreamA = options.workstreams.find(
        (w) => a.workstream_id === w.id,
      )?.name

      const workstreamB = options.workstreams.find(
        (w) => b.workstream_id === w.id,
      )?.name

      return sortAlpha(workstreamA, workstreamB)
    }

    return 0
  })
  return sortable
}

export const rowsFromMemberships = (
  memberships: ReadonlyArray<ProjectRoleWorkstream>,
): MemberRows => {
  return memberships.reduce((acc, m) => {
    const key = getMemberKey(m)
    return {
      ...acc,
      [key]: {
        projectId: m.project_id,
        roleId: m.role_id,
        workstreamId: m.workstream_id,
        just_added_timestamp: m.just_added_timestamp,
      },
    }
  }, {})
}

type Props = {
  person: PersonDetails_person$key
  calStartNum: number
  calEndNum: number
}

const PersonDetails = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonDetails_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        ...PersonTimeOffRow_person
          @arguments(plannerStartDate: $plannerStartDate)
        ...ProjectStatusRow_person
        ...PersonAddProjectRow_person
          @arguments(plannerStartDate: $plannerStartDate)
        ...Overbookings_person @arguments(plannerStartDate: $plannerStartDate)
        id
        first_name
        last_name
        email
        image_key
        is_placeholder
        active
        tags
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        archivable
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          is_template
          note
          role_id
          workstream_id
          person_id
          project_id
          phase_id
          updated_at
          total_minutes
          non_working_day
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          note
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          cost: cost_private
          role {
            id
            name
          }
        }
        people_notes {
          id
          note
          created_at
          ...PersonNote_note
        }
        team {
          id
          name
        }
        project_memberships {
          id
          person_id
          project_id
          role_id
          workstream_id
          just_added_timestamp
          workstream {
            id
            name
          }
        }
        person_requests {
          id
          status
          updated_at
          user {
            id
            first_name
            last_name
            role_name
          }
          person {
            id
            first_name
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              cost: cost_private
              minutes_per_day
              role {
                id
                name
              }
            }
          }
        }
        placeholder_suggestions {
          suggested_person_id
        }
        ...EditPersonForm_person
        ...PlaceholderProjectRow_placeholder
      }
    `,
    props.person,
  )

  const user = useFragment<PersonDetails_user$key>(
    graphql`
      fragment PersonDetails_user on users
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        account {
          id
          default_full_time_minutes
          projects(where: $projectsFilter) {
            id
            name
            confirmed
            active
            pricing_model
            is_template
            priority
            emoji
            phases {
              id
              name
              color
              start_date: start_date_runn
              end_date: end_date_runn
            }
            client {
              id
              name
              image_key
              website
            }
            links {
              id
              name
              href
              show_in_planner
            }
            custom_text_values {
              value
              typeId: custom_text_type_id
            }
            custom_select_values {
              optionId: custom_select_option_id
              typeId: custom_select_type_id
            }
            custom_checkbox_values {
              typeId: custom_checkbox_type_id
              value
            }
            custom_date_values {
              value
              typeId: custom_date_type_id
            }
            tags_computed
          }
          roles {
            id
            name
          }
          workstreams {
            id
            name
            archived
          }
          ...PlaceholderProjectRow_account
          ...PersonTimeOffRow_account
            @arguments(projectsFilter: $projectsFilter)
          ...PersonProjectRow_account
        }
      }
    `,
    useHasuraContext(),
  )
  const { account } = user
  const { calStartNum, calEndNum } = props
  const { roles, projects, default_full_time_minutes, workstreams } = account

  const anyTentativeProjectsEnabled = useAppSelector((state) =>
    selectAnyTentativeProjectsEnabled(state.plannerV2.scenarioPlanning),
  )

  const visibleAssignments = getVisibleAssignments(
    person.assignments,
    calStartNum,
    calEndNum,
  )
  const timeOffsWithWeekend = getTimeOffWeekends(
    person.time_offs,
    calStartNum,
    calEndNum,
  )

  const holidaysOverlappingTimeOffs = getHolidaysOverlappingTimeOffs({
    timeOffs: person.time_offs,
    range: { start: calStartNum, end: calEndNum },
  })

  const delayAssignmentRender = visibleAssignments.length > 100
  const timeOffs = person.time_offs

  const membershipRows = rowsFromMemberships(person.project_memberships)

  const memberships: ReadonlyArray<DetailedMembership> = Object.values(
    membershipRows,
  )
    .map((row) => {
      const project = projects.find((p) => p.id === row.projectId)
      const role = roles.find((r) => row.roleId === r.id)
      if (!project || !role) {
        return
      }
      return {
        project_id: row.projectId,
        person_id: person.id,
        role_id: row.roleId,
        workstream_id: row.workstreamId,
        client_id: project.client.id,
        is_template: project.is_template,
        active: project.active,
        just_added_timestamp: row.just_added_timestamp,
        role,
        project,
      }
    })
    .filter(Boolean)

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const { visible, inactive } = useProjectMembershipsList(person.id, {
    members: memberships,
    assignments: person.assignments,
  })
  const visibleKeys = visible.map(getMemberKey)
  const inactiveKeys = inactive.map(getMemberKey)
  const getPersonRows = (
    rows: ReadonlyArray<DetailedMembership>,
    confirmed: boolean,
  ) =>
    sortProjects(rows, { projects, roles, workstreams })
      .filter((row) => row.project.confirmed === confirmed)
      .filter((row) => visibleKeys.includes(getMemberKey(row)))
      .map((row) => {
        const { project, role, workstream_id: workstreamId } = row
        const personAssignments = visibleAssignments.filter(
          (a) =>
            a.project_id === row.project_id &&
            a.role_id === role.id &&
            a.workstream_id === workstreamId,
        )

        const key = workstreamId
          ? `${project.id}-${role.id}-${workstreamId}`
          : `${project.id}-${role.id}`

        if (person.is_placeholder) {
          return (
            <PlaceholderProjectRow
              account={account}
              key={key}
              project={project}
              placeholder={person}
              placeholderLegacy={person}
              enabledTentativeProjects={enabledTentativeProjects}
              companyDefaultMinutes={default_full_time_minutes}
              delayAssignmentRendering={delayAssignmentRender}
            />
          )
        } else {
          return (
            <PersonProjectRow
              key={key}
              account={account}
              person={person}
              client={project.client}
              project={project}
              assignments={personAssignments}
              timeOffs={timeOffs}
              role={role}
              workstreamId={workstreamId}
              justAdded={isJustAdded(row)}
              isInactive={inactiveKeys.includes(getMemberKey(row))}
              companyDefaultMinutes={default_full_time_minutes}
              delayAssignmentRender={delayAssignmentRender}
              timeOffsWithWeekend={timeOffsWithWeekend}
              holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
            />
          )
        }
      })
      .filter(Boolean)

  const confirmedPersonRows = getPersonRows(memberships, true)
  const unconfirmedPersonRows = anyTentativeProjectsEnabled
    ? getPersonRows(memberships, false)
    : []

  const hasConfirmedProjects = confirmedPersonRows.length > 0
  const hasUnconfirmedProjects = unconfirmedPersonRows.length > 0
  return (
    <div>
      {!person.is_placeholder && (
        <PersonTimeOffRow
          account={account}
          person={person}
          showBottomBorder={hasConfirmedProjects}
          timeOffs={timeOffs}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          defaultFullTimeMinutes={account.default_full_time_minutes}
        />
      )}
      {hasConfirmedProjects && hasUnconfirmedProjects && (
        <ProjectStatusRow
          projectStatus="Confirmed Projects"
          person={person}
          timeOffs={timeOffs}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
        />
      )}
      {confirmedPersonRows}
      {hasUnconfirmedProjects && (
        <>
          <ProjectStatusRow
            projectStatus="Tentative Projects"
            person={person}
            timeOffs={timeOffs}
            holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          />
        </>
      )}
      {unconfirmedPersonRows}
      {!person.is_placeholder && (
        <PersonAddProjectRow
          person={person}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          acountDefaultFullTimeMinutes={account.default_full_time_minutes}
        />
      )}
    </div>
  )
}

type SortProjectsOptions = {
  projects: ReadonlyArray<{
    id: number
    name: string
    client: { id: number; name: string }
  }>
  roles: ReadonlyArray<{ id: number; name: string }>
  workstreams: ReadonlyArray<{ id: number; name: string }>
}

const mapStateToProps = (state) => ({
  calStartNum: state.calendar.calStartNum,
  calEndNum: state.calendar.calEndNum,
})

const connector = connect(mapStateToProps)
export default connector(PersonDetails)
