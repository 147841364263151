import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { addBusinessDays, isMonday, subBusinessDays } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import { connect } from "react-redux"

import styles from "./TimeOffCurtain.module.css"

import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"

import { getPillPosition } from "~/common/Pill/PillHelpers"

const renderCurtainBorders = (
  visibleDays: number,
  mondayIndices: number[],
  lineInterval: number,
) => (
  <div className={styles.borderContainer}>
    {Array.from({ length: visibleDays + 1 }, (_, i) => {
      const isBold = mondayIndices.includes(i)

      return (
        <div
          key={i}
          className={cc([
            styles.border,
            {
              [styles.boldBorder]: isBold,
            },
          ])}
          style={{
            left: `calc(${i} * ${lineInterval}% - ${isBold ? "1px" : "0px"})`,
          }}
        />
      )
    })}
  </div>
)

const TimeOffCurtain = (props: Props) => {
  const {
    timeOff,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
    contracts,
    accountDefaultFullTimeMinutes,
  } = props

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const calendarStartDateNum = parseInt(
    dateHelpers.formatToRunnDate(calendarStartDate),
    10,
  )
  const calendarEndDateNum = parseInt(
    dateHelpers.formatToRunnDate(calendarEndDate),
    10,
  )

  // Exclude assignments outside the calendar date
  if (
    parseInt(timeOff.end_date, 10) < calendarStartDateNum ||
    parseInt(timeOff.start_date, 10) > calendarEndDateNum
  ) {
    return null
  }

  // There is a chance that a time off crosses multiple contracts and that a
  // partial time off ends up being greater than the total minutes of the contract.
  // If this the case we mimic multiple time offs and showing a different curtain
  // for each one

  // Make sure we correctly calculate the curtain types even if the planner
  // calendar starts part way through the time off.
  const timeOffStartDateNum = parseInt(timeOff.start_date, 10)
  const days = dateHelpers.getAllWeekdaysDatesBetween(
    calendarStartDateNum > timeOffStartDateNum
      ? dateHelpers.formatToRunnDate(calendarStartDate)
      : timeOff.start_date,
    timeOff.end_date,
  )
  // Store the minutes of the current contract so that as we iterate we can
  // check if the contract has changed and we, potentially, have to mimic a
  // new time off for the curtain
  let currentContractMinutes = getMinutesPerDayForDate(
    days[0],
    contracts,
    accountDefaultFullTimeMinutes,
  )

  const timeOffs = [
    {
      ...timeOff,
      // Take care of the starting case where the time off is saved as PTO but should
      // be shown as FTO
      minutes_per_day:
        timeOff.minutes_per_day >= currentContractMinutes
          ? null
          : timeOff.minutes_per_day,
    },
  ]
  // Ignore the first day. The first day is effectively handled by the time off above
  for (const day of days.slice(1)) {
    const currentTimeOff = timeOffs[timeOffs.length - 1]

    const minutes = getMinutesPerDayForDate(
      day,
      contracts,
      accountDefaultFullTimeMinutes,
    )

    if (minutes <= timeOff.minutes_per_day) {
      if (timeOffs.length === 1) {
        // This is the first time off. We need to make sure it gets cut up or
        // else it will render on top of the rest of the time offs
        timeOffs[timeOffs.length - 1] = {
          ...currentTimeOff,
          end_date: dateHelpers.formatToRunnDate(
            subBusinessDays(dateHelpers.parseRunnDate(day), 1),
          ),
        }
      }
      timeOffs.push({
        ...timeOff,
        start_date: day,
        end_date: day,
        minutes_per_day: null, // Simulate full time off
      })

      currentContractMinutes = minutes
    } else {
      // Extend the current time off and keep iterating
      timeOffs[timeOffs.length - 1] = {
        ...currentTimeOff,
        end_date: day,
      }
    }
  }

  return timeOffs.map((to) => {
    const { offset, width, visibleDays } = getPillPosition({
      item: to,
      calendarStartDate,
      calendarEndDate,
      calendarWeekendsExpanded,
    })

    const lineInterval = 100 / visibleDays
    const startDate = dateHelpers.parseRunnDate(to.start_date)

    const mondayIndices: number[] = !calendarWeekendsExpanded
      ? Array.from({ length: visibleDays + 1 }).reduce<number[]>(
          (acc, _, i) => {
            const currentDate = addBusinessDays(startDate, i)

            if (isMonday(currentDate)) {
              acc.push(i)
            }
            return acc
          },
          [],
        )
      : []

    const isFullDayTimeOff = !Boolean(to.minutes_per_day)

    const className = isFullDayTimeOff
      ? isConsistentTimeOffEnabled
        ? styles.fullDay
        : styles.fullDayLegacy
      : styles.partialDay

    return (
      <div
        key={to.start_date}
        className={cc([styles.curtain, className])}
        style={{
          left: `${offset}%`,
          width: `${width}%`,
        }}
      >
        {isConsistentTimeOffEnabled &&
          isFullDayTimeOff &&
          renderCurtainBorders(visibleDays, mondayIndices, lineInterval)}
      </div>
    )
  })
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
})

const connector = connect(mapStateToProps)
export default connector(TimeOffCurtain)

type Props = {
  timeOff: TimeOff
  calendarStartDate: Date
  calendarEndDate: Date
  calendarWeekendsExpanded: boolean
  contracts: readonly Contract[]
  accountDefaultFullTimeMinutes: number
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  minutes_per_day?: number
}

type Contract = {
  readonly id: number
  readonly start_date: string
  readonly end_date?: string
  readonly minutes_per_day?: number
}
