import React from "react"

import styles from "./PhaseColorPicker.module.css"

import { PHASE_COLORS } from "~/helpers/colors"

type Props = {
  selectedColor: string
  changeColor: (color: string) => void
}

const PhaseColorPicker = (props: Props) => (
  <div className={styles.phaseColorPicker}>
    {PHASE_COLORS.map((c, idx) => (
      <div
        key={idx}
        className={`
            ${styles.iconBox} 
            ${c.color === props.selectedColor ? styles.active : ""}
          `}
        style={{ backgroundColor: c.color }}
        onClick={() => props.changeColor(c.color)}
        data-test={`phase-color-${c.color}`}
      />
    ))}
  </div>
)

export default PhaseColorPicker
