/**
 * @generated SignedSource<<33a3cbb6b24586352ad8934104e10d12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerDatePickerHourStats_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EffortInput_account" | "TotalEffortInput_account">;
  readonly " $fragmentType": "PlannerDatePickerHourStats_account";
};
export type PlannerDatePickerHourStats_account$key = {
  readonly " $data"?: PlannerDatePickerHourStats_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerDatePickerHourStats_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerDatePickerHourStats_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EffortInput_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TotalEffortInput_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "bdcb7f669969ce1b96368b307158e024";

export default node;
