import React from "react"

type Props = { limit: number | string; functionality: string }

const FilterDownMessage = ({ limit, functionality }: Props) => (
  <>
    Filter results to {limit} or fewer to enable {functionality}
  </>
)

export default FilterDownMessage
