/**
 * @generated SignedSource<<5cbd2ec46e847ae48fcf556defaff75d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBulkPeopleForm_user$data = {
  readonly account: {
    readonly id: number;
    readonly people_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
  };
  readonly id: number;
  readonly permissions: any;
  readonly " $fragmentType": "CreateBulkPeopleForm_user";
};
export type CreateBulkPeopleForm_user$key = {
  readonly " $data"?: CreateBulkPeopleForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateBulkPeopleForm_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateBulkPeopleForm_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                },
                "is_placeholder": {
                  "_eq": false
                }
              }
            }
          ],
          "concreteType": "people_aggregate",
          "kind": "LinkedField",
          "name": "people_aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "people_aggregate_fields",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "people_aggregate(where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "ea7e228be571c4ff2f34006f0cd4754e";

export default node;
