import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { GroupUtilizationBarRelayWrapperQuery } from "./__generated__/GroupUtilizationBarRelayWrapperQuery.graphql"

import { getCurrentContract } from "~/helpers/person"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

import GroupUtilizationBar from "./GroupUtilizationBar"

type Props = {
  groupName: string
  groupType: string
  isTransparent: boolean
  projects: readonly {
    id: number
    confirmed: boolean
    is_template: boolean
  }[]
  peopleListIds: number[]
  sidePanelIsOpen: boolean
}

const GroupUtilizationBarRelayWrapper = (props: Props) => {
  const {
    groupName,
    groupType,
    peopleListIds,
    isTransparent,
    sidePanelIsOpen,
  } = props

  const query = useLazyLoadQuery<GroupUtilizationBarRelayWrapperQuery>(
    graphql`
      query GroupUtilizationBarRelayWrapperQuery(
        $ids: [Int!]
        $plannerStartDate: date!
      ) {
        people(where: { id: { _in: $ids } }) {
          id
          is_placeholder
          active
          tags
          team {
            id
            name
          }
          contracts {
            id
            minutes_per_day
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            employment_type
            role_id
            role {
              id
              name
            }
          }
          time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            person_id
            start_date: start_date_runn
            end_date: end_date_runn
            leave_type
            minutes_per_day
            ...ExtLinks_TimeOff @relay(mask: false)
          }
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            project_id
            person_id
            role_id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            is_billable
            non_working_day
          }
        }
      }
    `,
    {
      ids: peopleListIds,
      plannerStartDate: PLANNER_INITIAL_DATE,
    },
  )

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const people = query.people
    .filter((p) => p !== null)
    .filter((p) => peopleListIds.includes(p.id))
  const projects = props.projects

  const hasTempAssignments = people.some((p) =>
    p.assignments.some((a) => a.id < 0),
  )

  let selectedPeople = people

  switch (groupType) {
    case "role":
      selectedPeople = people.filter(
        (person) =>
          getCurrentContract(person.contracts)?.role?.name === groupName ||
          !getCurrentContract(person.contracts),
      )
      break
    case "team":
      selectedPeople = people.filter(
        (person) => person.team?.name === groupName || person.team === null,
      )
      break
    case "relationship":
      selectedPeople = people.filter(
        (person) =>
          getCurrentContract(person.contracts)?.employment_type ===
            groupName.toLowerCase() || !getCurrentContract(person.contracts),
      )
      break
    case "tags":
      selectedPeople = people.filter((person) =>
        person.tags.length ? person.tags.includes(groupName) : person,
      )
      break
  }

  const validProjects = projects.filter(
    (p) =>
      !p.is_template &&
      (p.confirmed ||
        isTentativeProjectEnabled(enabledTentativeProjects, p.id)),
  )

  return (
    <GroupUtilizationBar
      isTransparent={isTransparent}
      people={selectedPeople}
      projects={validProjects}
      doNotRerender={hasTempAssignments}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  )
}

export default GroupUtilizationBarRelayWrapper
