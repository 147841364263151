import { useFeature } from "flagged"
import { useMemo } from "react"

import {
  calcCapacityData,
  calcWorkloadData,
  getCapacityPeople,
  getMergedDailyData,
  getMergedWeeklyData,
  getWorkloadAssignments,
} from "~/helpers/workload-calculations"

import { TIME_OFF_TYPES } from "~/ENUMS"
import type { ChartPeople, ChartProjects } from "~/Planner/ChartPanel/types"

type Options = {
  people: ChartPeople
  projects: ChartProjects
  startDate: Date
  endDate: Date
  period: "weekly" | "daily"
  includeWeekends?: boolean
}

type TimeOffs = ChartPeople[0]["time_offs"]

const useChartData = (options: Options) => {
  const { people, projects, startDate, endDate, period, includeWeekends } =
    options

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  return useMemo(() => {
    const projectIds = projects.filter((p) => !p.is_template).map((pr) => pr.id)

    // Filter assignments to remove assignments that are not included
    const peopleWithVisibleAssignments = people.map((p) => ({
      ...p,
      assignments: p.assignments.filter((a) =>
        projectIds.includes(a.project_id),
      ),
    }))

    const timeOffs = people.reduce<TimeOffs>(
      (acc, p) => [...acc, ...p.time_offs],
      [],
    )

    const timeOffData = isConsistentTimeOffEnabled
      ? timeOffs.filter((t) => !t.minutes_per_day)
      : timeOffs.filter((t) => t.leave_type === TIME_OFF_TYPES.HOLIDAY)

    const workloadAssignments = getWorkloadAssignments(
      peopleWithVisibleAssignments,
      [],
      startDate,
    )

    const workloadGraphData = calcWorkloadData(
      startDate,
      endDate,
      workloadAssignments,
      timeOffData,
      projects,
    )

    // For capacity
    const capacityPeople = getCapacityPeople(
      peopleWithVisibleAssignments,
      [],
      startDate,
      endDate,
    )

    const capacityGraphData = calcCapacityData(
      startDate,
      endDate,
      capacityPeople,
    )

    const mergedData =
      period === "weekly"
        ? getMergedWeeklyData(workloadGraphData, capacityGraphData)
        : getMergedDailyData(workloadGraphData, capacityGraphData).filter(
            (d) => (includeWeekends ? true : !d.isWeekend),
          )

    return mergedData
  }, [
    projects,
    people,
    startDate,
    endDate,
    period,
    includeWeekends,
    isConsistentTimeOffEnabled,
  ])
}

export { useChartData }
