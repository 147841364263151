/**
 * @generated SignedSource<<7e9a817137fb0cd06dae7141255286ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectPersonList_account$data = {
  readonly custom_checkbox_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_date_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_select_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_text_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly id: number;
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly assignments: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly is_billable: boolean | null | undefined;
      readonly minutes_per_day: number;
      readonly person_id: number;
      readonly phase_id: number | null | undefined;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly competencies: ReadonlyArray<{
      readonly id: number;
      readonly level: number | null | undefined;
      readonly skill: {
        readonly id: number;
        readonly name: string;
      };
    }>;
    readonly contracts: ReadonlyArray<{
      readonly employment_type: string;
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly job_title: string | null | undefined;
      readonly minutes_per_day: number | null | undefined;
      readonly role: {
        readonly id: number;
        readonly name: string | null | undefined;
      };
      readonly role_id: number;
      readonly start_date: string | null | undefined;
    }>;
    readonly custom_checkbox_values: ReadonlyArray<{
      readonly custom_checkbox_type_id: number;
      readonly id: number;
      readonly typeId: number;
      readonly value: boolean;
    }>;
    readonly custom_date_values: ReadonlyArray<{
      readonly custom_date_type_id: number;
      readonly id: number;
      readonly typeId: number;
      readonly value: any | null | undefined;
    }>;
    readonly custom_select_values: ReadonlyArray<{
      readonly custom_select_option_id: number;
      readonly custom_select_type_id: number;
      readonly id: number;
      readonly optionId: number;
      readonly typeId: number;
    }>;
    readonly custom_text_values: ReadonlyArray<{
      readonly custom_text_type_id: number;
      readonly id: number;
      readonly typeId: number;
      readonly value: string;
    }>;
    readonly email: string | null | undefined;
    readonly first_name: string;
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly is_placeholder: boolean;
    readonly last_name: string;
    readonly managers: ReadonlyArray<{
      readonly id: number;
      readonly user_id: number;
    }>;
    readonly project_memberships: ReadonlyArray<{
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly just_added_timestamp: number | null | undefined;
      readonly project_id: number;
      readonly role_id: number;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly tags: any;
    readonly team: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
    readonly team_id: number | null | undefined;
    readonly time_offs: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly ext_time_off_links: ReadonlyArray<{
        readonly active: boolean;
        readonly ext_time_off_id: number;
        readonly external: {
          readonly __typename: "ext_time_off";
          readonly id: number;
          readonly integration_id: number;
        } | null | undefined;
        readonly external_id: number;
        readonly time_off_id: number;
      }>;
      readonly id: number;
      readonly person_id: number;
      readonly start_date: string | null | undefined;
    }>;
  }>;
  readonly projects: ReadonlyArray<{
    readonly active: boolean;
    readonly client: {
      readonly id: number;
      readonly name: string;
    };
    readonly confirmed: boolean;
    readonly custom_checkbox_values: ReadonlyArray<{
      readonly custom_checkbox_type_id: number;
      readonly id: number;
      readonly value: boolean;
    }>;
    readonly custom_date_values: ReadonlyArray<{
      readonly custom_date_type_id: number;
      readonly id: number;
      readonly value: any | null | undefined;
    }>;
    readonly custom_select_values: ReadonlyArray<{
      readonly custom_select_option_id: number;
      readonly custom_select_type_id: number;
      readonly id: number;
    }>;
    readonly custom_text_values: ReadonlyArray<{
      readonly custom_text_type_id: number;
      readonly id: number;
      readonly value: string;
    }>;
    readonly id: number;
    readonly is_template: boolean;
    readonly managers: ReadonlyArray<{
      readonly id: number;
      readonly user_id: number;
    }>;
    readonly members: ReadonlyArray<{
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly name: string;
    readonly tags_computed: any | null | undefined;
    readonly team: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
  }>;
  readonly roles: ReadonlyArray<{
    readonly active: boolean;
    readonly default_hour_cost: number | null | undefined;
    readonly id: number;
    readonly name: string | null | undefined;
  }>;
  readonly views: ReadonlyArray<{
    readonly id: number;
    readonly people_filters: any;
  }>;
  readonly " $fragmentType": "SelectPersonList_account";
};
export type SelectPersonList_account$key = {
  readonly " $data"?: SelectPersonList_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectPersonList_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_placeholder",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v4 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v5 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "teams",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_select_option_id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_select_type_id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_text_type_id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_checkbox_type_id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_date_type_id",
  "storageKey": null
},
v20 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user_id",
    "storageKey": null
  }
],
v21 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PERSON"
      }
    }
  }
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v24 = [
  (v0/*: any*/),
  (v7/*: any*/),
  (v22/*: any*/),
  (v23/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectPersonList_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "views",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "people_filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "team_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "contracts",
          "kind": "LinkedField",
          "name": "contracts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "employment_type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "job_title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "roles",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": (v10/*: any*/),
          "concreteType": "time_offs",
          "kind": "LinkedField",
          "name": "time_offs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v11/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "where",
                  "value": {
                    "active": {
                      "_eq": true
                    }
                  }
                }
              ],
              "concreteType": "ext_time_off_link",
              "kind": "LinkedField",
              "name": "ext_time_off_links",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time_off_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ext_time_off_id",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": "external_id",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ext_time_off_id",
                  "storageKey": null
                },
                {
                  "alias": "external",
                  "args": null,
                  "concreteType": "ext_time_off",
                  "kind": "LinkedField",
                  "name": "ext_time_off",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "integration_id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v10/*: any*/),
          "concreteType": "assignments",
          "kind": "LinkedField",
          "name": "assignments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phase_id",
              "storageKey": null
            },
            (v13/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_billable",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "competencies",
          "kind": "LinkedField",
          "name": "competencies",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "skills",
              "kind": "LinkedField",
              "name": "skill",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_select_values",
          "kind": "LinkedField",
          "name": "custom_select_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            {
              "alias": "optionId",
              "args": null,
              "kind": "ScalarField",
              "name": "custom_select_option_id",
              "storageKey": null
            },
            {
              "alias": "typeId",
              "args": null,
              "kind": "ScalarField",
              "name": "custom_select_type_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_text_values",
          "kind": "LinkedField",
          "name": "custom_text_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            {
              "alias": "typeId",
              "args": null,
              "kind": "ScalarField",
              "name": "custom_text_type_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_checkbox_values",
          "kind": "LinkedField",
          "name": "custom_checkbox_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v18/*: any*/),
            {
              "alias": "typeId",
              "args": null,
              "kind": "ScalarField",
              "name": "custom_checkbox_type_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_date_values",
          "kind": "LinkedField",
          "name": "custom_date_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v19/*: any*/),
            {
              "alias": "typeId",
              "args": null,
              "kind": "ScalarField",
              "name": "custom_date_type_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "project_members",
          "kind": "LinkedField",
          "name": "project_memberships",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v12/*: any*/),
            (v2/*: any*/),
            (v13/*: any*/),
            (v3/*: any*/),
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "just_added_timestamp",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "people_managers",
          "kind": "LinkedField",
          "name": "managers",
          "plural": true,
          "selections": (v20/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "roles",
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v1/*: any*/),
        {
          "alias": "default_hour_cost",
          "args": null,
          "kind": "ScalarField",
          "name": "default_hour_cost_private",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "confirmed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_template",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags_computed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "clients",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "project_members",
          "kind": "LinkedField",
          "name": "members",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v11/*: any*/),
            (v3/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_select_values",
          "kind": "LinkedField",
          "name": "custom_select_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_text_values",
          "kind": "LinkedField",
          "name": "custom_text_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_checkbox_values",
          "kind": "LinkedField",
          "name": "custom_checkbox_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v18/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_date_values",
          "kind": "LinkedField",
          "name": "custom_date_values",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "project_managers",
          "kind": "LinkedField",
          "name": "managers",
          "plural": true,
          "selections": (v20/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "custom_text_types_person",
      "args": (v21/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v24/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_select_types_person",
      "args": (v21/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        (v22/*: any*/),
        (v23/*: any*/)
      ],
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_checkbox_types_person",
      "args": (v21/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v24/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_date_types_person",
      "args": (v21/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v24/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "7f3665a16f0af5aba169dd8b51fade44";

export default node;
