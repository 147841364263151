import { dateHelpers } from "@runn/calculations"
import { startOfWeek } from "date-fns"
import { useDispatch } from "react-redux"

import { getCalendarEndDate } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"

import {
  setCalendarWeekendsExpanded,
  setDateRange,
  setViewInMonths,
} from "~/common/calendar.reducer"

import { setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

import { useAppSelector } from "./redux"

const useCalendarWeekends = () => {
  const { calendarWeekendsExpanded, calendarView } = useAppSelector(
    (state: ReduxState) => state.calendar,
  )

  const dispatch = useDispatch()

  const toggleWeekends = () => {
    track("Planner Weekend Toggled", {
      value: !calendarWeekendsExpanded,
    })
    dispatch(setCalendarWeekendsExpanded(!calendarWeekendsExpanded))
    setSetting("expandWeekendsOnMonthView", !calendarWeekendsExpanded)
  }

  const expandWeekends = (itemStartDate: string) => {
    dispatch(setCalendarWeekendsExpanded(true))

    if (calendarView.amount === 1) {
      setSetting("expandWeekendsOnMonthView", true)
    }
    if (calendarView.type === "months" && calendarView.amount !== 1) {
      setSetting("calendarView", { type: "months", amount: 1 })
      dispatch(setViewInMonths(1))
      // move calendar start date to start of week before weekend
      const newCalStart = startOfWeek(
        dateHelpers.parseRunnDate(itemStartDate),
        {
          weekStartsOn: 1,
        },
      )
      dispatch(
        setDateRange({
          start: newCalStart,
          end: getCalendarEndDate(newCalStart, 1, "months"),
        }),
      )
    }
  }

  return {
    toggleWeekends,
    expandWeekends,
  }
}

type useCalendarWeekends = {
  toggleWeekends: () => void
  expandWeekends: () => void
}

export default useCalendarWeekends
