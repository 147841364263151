/**
 * @generated SignedSource<<eb77cf9a876b619276663ee5203f0b7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingDialog_user$data = {
  readonly account: {
    readonly account_type: string;
    readonly name: string;
    readonly people: ReadonlyArray<{
      readonly first_name: string;
      readonly id: number;
      readonly last_name: string;
    }>;
    readonly projects: ReadonlyArray<{
      readonly client: {
        readonly id: number;
      };
      readonly id: number;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingFinancials_account">;
  };
  readonly email: string;
  readonly first_name: string | null | undefined;
  readonly id: number;
  readonly last_name: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingPeople_user">;
  readonly " $fragmentType": "OnboardingDialog_user";
};
export type OnboardingDialog_user$key = {
  readonly " $data"?: OnboardingDialog_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingDialog_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingDialog_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "projectsFilter",
              "variableName": "projectsFilter"
            }
          ],
          "kind": "FragmentSpread",
          "name": "OnboardingFinancials_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingPeople_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "d03fe4eaa29ccd1afb9d7aa8cb63408b";

export default node;
