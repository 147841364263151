import { NumericInputProps } from "@blueprintjs/core"
import React from "react"

import styles from "./CurrencyInput.module.css"

import InputLabel from "./InputLabel"
import NumericInput from "./NumericInput"

// @ts-ignore
const windowCurrency = window.currency

type Props = NumericInputProps & {
  id?: string
  value: string | number
  name: string
  onChange: (value: number, stringValue?: string) => void
  // optional
  currency?: string
  type?: string
  label?: string | React.ReactElement
  optional?: boolean
  customErrorMessage?: string
  error?: boolean
  height?: number
  fontSize?: number
  stepSize?: number
  showArrows?: boolean
  min?: number
  placeholder?: string
  maxWidth?: number
  disabled?: boolean
  style?: Record<string, any>
  integer?: boolean
  tabIndex?: number
  onBlur?: (e) => void
  autoComplete?: string
  autoFocus?: boolean
  dataTest?: string
  rateUnit?: string
  selectAllOnFocus?: boolean
}

const CurrencyInput = (props: Props) => {
  const {
    dataTest,
    currency,
    value,
    name,
    onChange,
    label,
    optional,
    error,
    customErrorMessage,
    style,
    maxWidth,
    min,
    rateUnit,
    ...rest
  } = props

  const inputHeight = props.height || 40

  const parsedValue = value === 0 || value ? value : ""

  return (
    <div
      style={{
        fontSize: props.fontSize || "",
        width: maxWidth,
        ...style,
      }}
      className={props.disabled ? styles.disabled : ""}
    >
      {label && (
        <InputLabel
          label={label}
          optional={optional}
          error={error}
          customErrorMessage={customErrorMessage}
        />
      )}
      <div
        className={`${styles.currencyInputWrapper} ${
          props.showArrows ? "" : styles.hideArrows
        }`}
        style={{ height: inputHeight }}
      >
        <div
          className={styles.currency}
          style={{
            fontSize: props.fontSize || "",
            padding: `0 ${inputHeight ? inputHeight / 3.5 : 9}px`,
          }}
        >
          {currency || windowCurrency}
        </div>
        <NumericInput
          stepSize={props.stepSize || 1}
          style={{
            ...props.style,
            fontSize: props.fontSize || "",
            width: "100%",
          }}
          value={parsedValue}
          name={name}
          selectAllOnFocus={props.selectAllOnFocus}
          placeholder={props.placeholder}
          onValueChange={onChange}
          error={error}
          mantissa={2}
          trimMantissa
          dataTest={dataTest}
          min={min || 0}
          {...rest}
        />
        {rateUnit && <div className={styles.rateUnit}>{rateUnit}</div>}
      </div>
    </div>
  )
}

export default CurrencyInput
