import React from "react"

import { Permissions, UserType } from "~/helpers/permissions"
import routes from "~/helpers/routes"

import Button from "~/common/buttons/Button"

import BookADemoButton from "./BookADemoButton"

type Props = {
  permissions: Permissions
  hasOtherAccounts: boolean
}

export default (props: Props) => {
  if (props.permissions.type === UserType.Admin) {
    return (
      <>
        <Button
          href={routes.accountBilling({ openSubscription: true })}
          text="Subscribe"
          active
        />
        {!props.hasOtherAccounts && (
          <BookADemoButton permissions={props.permissions} />
        )}
      </>
    )
  }
  return <div>Please contact your admin to subscribe to Runn.</div>
}
