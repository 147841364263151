/**
 * @generated SignedSource<<fe1c670f1bf766d13029fba575aab19d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectLine_project$data = {
  readonly calc_end_date: string | null | undefined;
  readonly calc_start_date: string | null | undefined;
  readonly confirmed: boolean;
  readonly id: number;
  readonly pricing_model: string;
  readonly " $fragmentType": "ProjectLine_project";
};
export type ProjectLine_project$key = {
  readonly " $data"?: ProjectLine_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectLine_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectLine_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calc_start_date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calc_end_date",
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "6771b84a83caaa7b10d2279f21d3d1d8";

export default node;
