import React from "react"

import filterBlockStyles from "./FilterBlockMenu.module.css"

import { Target } from "./FilterBlock"
import FilterBlockListItem from "./FilterBlockListItem"
import FilterDropdown from "./FilterDropdown"

type Props = {
  icon: React.ReactNode
  label: string
  disabled?: boolean
  onRemove: () => void
  selectedOption: boolean
  onChange: (selection: boolean) => void
  isDropdownOpen: boolean
  onChangeDropdownOpen: (isOpen: boolean) => void
}

const FilterBlockCheckbox = (props: Props) => {
  const {
    icon,
    label,
    disabled,
    isDropdownOpen,
    onChange,
    selectedOption,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const onCloseDropdown = () => onChangeDropdownOpen(false)
  const onOpenDropdown = () => onChangeDropdownOpen(true)

  return (
    <FilterDropdown
      disabled={disabled}
      isOpen={isDropdownOpen}
      target={
        <Target
          icon={icon}
          label={label}
          selectedOptions={
            selectedOption
              ? [{ value: "YES", label: "Yes" }]
              : [{ value: "NO", label: "No" }]
          }
          disabled={disabled}
          onRemove={onRemove}
          isDropdownOpen={isDropdownOpen}
        />
      }
      onOpen={onOpenDropdown}
      onClose={onCloseDropdown}
    >
      <div
        className={filterBlockStyles.container}
        style={{
          minWidth: 200,
        }}
      >
        <div
          className={filterBlockStyles.list}
          data-test="SuperSearch_FilterBlockMenu_list"
        >
          <FilterBlockListItem
            label={"Yes"}
            onChange={() => {
              onChange(true)
            }}
            value={"Yes"}
            checked={selectedOption === true}
          />
          <FilterBlockListItem
            label={"No"}
            onChange={() => {
              onChange(false)
            }}
            value={"No"}
            checked={selectedOption != null && selectedOption === false}
          />
        </div>

        <button
          className={filterBlockStyles.doneButton}
          onClick={onCloseDropdown}
          data-test="SuperSearch_FilterBlockMenu_doneButton"
        >
          Done
        </button>
      </div>
    </FilterDropdown>
  )
}

export default FilterBlockCheckbox
