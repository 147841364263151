import React from "react"
import { graphql, useFragment } from "react-relay"

import { ProjectGroupList_account$key } from "./__generated__/ProjectGroupList_account.graphql"
import { ProjectGroupList_user$key } from "./__generated__/ProjectGroupList_user.graphql"
import { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"

import { Group } from "~/helpers/group-helpers"

import ProjectGroup from "./ProjectGroup"

type Project = ProjectManagement_user$data["account"]["projects"][0]

type ProjectGroupListProps = {
  account: ProjectGroupList_account$key
  user: ProjectGroupList_user$key
  userId: number
  projectCount: number
  favouriteProjectIds: string[]
  projectGroupList: readonly Group<Project>[]
}

const ProjectGroupList = (props: ProjectGroupListProps) => {
  const { projectCount, projectGroupList, userId, favouriteProjectIds } = props

  const user = useFragment(
    graphql`
      fragment ProjectGroupList_user on users {
        id
        ...ProjectGroup_user
      }
    `,
    props.user,
  )

  const account = useFragment(
    graphql`
      fragment ProjectGroupList_account on accounts
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        ...ProjectGroup_account
          @arguments(
            plannerStartDate: $plannerStartDate
            projectsFilter: $projectsFilter
            peopleFilter: $peopleFilter
          )
      }
    `,
    props.account,
  )

  const onlyOneResult = projectCount === 1

  return (
    <>
      {projectGroupList.map((projectGroup) => {
        if (projectGroup.items.length === 0) {
          return null
        }

        if (onlyOneResult && projectGroup.groupName === "Favorites") {
          // Hide favourites if there is only one result to avoid double up
          return null
        }

        return (
          <ProjectGroup
            account={account}
            user={user}
            userId={userId}
            key={projectGroup.groupName}
            groupName={projectGroup.groupName}
            visibleProjects={projectGroup.items}
            favourites={favouriteProjectIds}
            onlyOneResult={onlyOneResult}
          />
        )
      })}
    </>
  )
}

export default ProjectGroupList
