import { useDeferredValue } from "react"

// useDeferredValue uses Object.is to determine if the value has changed.
// In order to avoid unnecessary re-renders, we marshal to JSON.
// See: https://react.dev/reference/react/useDeferredValue
const useDeferredValueStringified = <T extends object | string>(
  value: T,
): T => {
  const deferredValue = useDeferredValue(JSON.stringify(value))
  return JSON.parse(deferredValue)
}

export default useDeferredValueStringified
