import { graphql } from "react-relay"

import { ProjectBudgetUpdateMutation } from "./__generated__/ProjectBudgetUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

const projectBudgetUpdateMutation = graphql`
  mutation ProjectBudgetUpdateMutation(
    $input: ProjectBudgetUpdateInput!
    $todaysDate: date!
  ) {
    action_project_budget_update(input: $input) {
      project {
        id
        name
        pricing_model
        pricing_model_readable
        rate_card_id
        rate_type
        total_budget: total_budget_private
        expenses_budget
        project_roles {
          id
          role_id
          estimated_minutes
        }
        project_rates {
          id
          role_id
          project_id
          rate: rate_private
        }
        ...ProjectDashboardHeader_project @arguments(todaysDate: $todaysDate)
        ...ProjectDashboardBody_project
      }
    }
  }
`

export const projectBudgetUpdateRelay = async (
  variables: ProjectBudgetUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectBudgetUpdateMutation>(
    environment,
    {
      mutation: projectBudgetUpdateMutation,
      variables,
    },
  )

  const project = data.action_project_budget_update.project
  if (project) {
    showToast({
      message: `${project.name} budget successfully saved`,
      type: "success",
    })
  }

  return project
}
