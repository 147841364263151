import { Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React, { useEffect, useRef, useState } from "react"

import styles from "./FilterBlockMenu.module.css"

import { sortAlphaNumeric } from "~/helpers/sorting-helpers"
import type { Option } from "~/types/helpers"

import { Cross as CrossIcon, Search as SearchIcon } from "~/common/react-icons"

import Checkbox from "./Checkbox"
import FilterBlockListItem from "./FilterBlockListItem"

type Props<Value> = {
  availableOptions: Option<Value>[]
  selectedOptions: Option<Value>[]

  // used to display message when no options match search query
  filterLabelPlural: React.ReactNode

  children?: (option: Option<Value>) => React.ReactNode

  onChange: (list: Value[]) => void
  onDone: () => void
}

const FilterBlockMenu = <Value,>(props: Props<Value>) => {
  const {
    filterLabelPlural,
    availableOptions,
    selectedOptions,
    onChange,
    onDone,
    children,
  } = props

  const searchRef = useRef<HTMLInputElement>()
  const [searchValue, setSearchValue] = useState("")
  const hasSearchValue = Boolean(searchValue)

  useEffect(() => {
    // auto-focus search when mounting component
    searchRef.current.focus()
  }, [])

  const searchValueLC = searchValue.toLowerCase().trim()
  const visibleOptions = availableOptions
    .filter((option) => {
      return fe.utils
        .removeAccentsRegex(option.label.toLowerCase())
        .includes(fe.utils.removeAccentsRegex(searchValueLC))
    })
    .sort((a, b) => sortAlphaNumeric(a.label, b.label))

  const selectedValueSet = new Set(selectedOptions.map(({ value }) => value))
  const hasNoSelectedOptions = selectedOptions.length === 0

  const allVisibleSelected =
    visibleOptions.length &&
    visibleOptions.every((option) => {
      return selectedValueSet.has(option.value)
    })

  const handleChange = (event: { value: Value; checked: boolean }) => {
    const { value, checked } = event

    const list = new Set(selectedValueSet)
    if (checked) {
      list.add(value)
    } else {
      list.delete(value)
    }

    onChange([...list])
  }

  const handleSelectVisible = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target

    const list = new Set(selectedValueSet)
    if (checked) {
      visibleOptions.forEach((option) => {
        list.add(option.value)
      })
    } else {
      visibleOptions.forEach((option) => {
        list.delete(option.value)
      })
    }

    onChange([...list])
  }

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    setSearchValue(value)
  }

  const handleResetSearch = () => {
    setSearchValue("")
    searchRef.current.focus()
  }

  const hasNoFilterOptions = !availableOptions.length
  const hasNoSearchResults = Boolean(searchValueLC && !visibleOptions.length)

  return (
    <div className={styles.container}>
      <div
        className={`${styles.search} ${
          hasSearchValue ? styles.hasSearchValue : ""
        }`}
      >
        <SearchIcon className={styles.searchIcon} />
        <input
          className={styles.searchInput}
          type="text"
          placeholder="Search"
          onChange={handleChangeSearchValue}
          value={searchValue}
          ref={searchRef}
        />
        <button
          onClick={handleResetSearch}
          className={styles.resetSearchButton}
        >
          <CrossIcon />
        </button>
        <Checkbox
          className={styles.searchCheckbox}
          checked={allVisibleSelected}
          onChange={handleSelectVisible}
        />
      </div>
      <div className={styles.list} data-test="SuperSearch_FilterBlockMenu_list">
        {visibleOptions.map((option, index) => {
          const { value, label: optionLabel } = option
          return (
            <FilterBlockListItem
              key={index}
              label={optionLabel}
              onChange={handleChange}
              value={value}
              checked={selectedValueSet.has(value)}
            >
              {children?.(option)}
            </FilterBlockListItem>
          )
        })}
      </div>

      {(hasNoFilterOptions || hasNoSearchResults) && (
        <div className={styles.emptyStateWrapper}>
          {hasNoFilterOptions ? (
            <>No {filterLabelPlural} to display</>
          ) : hasNoSearchResults ? (
            <>
              No {filterLabelPlural} matching "{searchValue}"
            </>
          ) : null}
        </div>
      )}

      <Tooltip
        content="Please select at least 1 item"
        placement="bottom"
        disabled={hasNoFilterOptions || !hasNoSelectedOptions}
      >
        <button
          className={styles.doneButton}
          onClick={onDone}
          disabled={hasNoSelectedOptions}
          data-test="SuperSearch_FilterBlockMenu_doneButton"
        >
          Done
        </button>
      </Tooltip>
    </div>
  )
}

export default FilterBlockMenu
