import { addWeeks, addYears, differenceInWeeks, getISODay } from "date-fns"
import React, { useEffect, useState } from "react"

import styles from "./ItemRepeat.module.css"

import DateInput from "~/common/DateInput"
import Select from "~/common/Select"

import NumericInput from "../NumericInput"

const ItemRepeat = (props: Props) => {
  const { onChange, startDate, endDate, nonWorkingDay, businessDays } = props
  const [repeatEvery, setRepeatEvery] = useState(1)
  const [numberOfAssignments, setNumberOfAssignments] = useState(1)
  const [toDate, setToDate] = useState(endDate)
  const [maxValue, setMaxValue] = useState(52)

  useEffect(
    () => {
      const correctedNumOfAssignments = numberOfAssignments || 1
      onChange({
        repeatEvery,
        numberOfAssignments: correctedNumOfAssignments,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [repeatEvery, numberOfAssignments],
  )

  useEffect(() => {
    // Ensure no one can choose a weekly repeat on a multi week assignment
    if (businessDays > 10) {
      return setRepeatEvery(4)
    }
    if (businessDays > 5) {
      return setRepeatEvery(2)
    }
  }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onOccurrenceChange = (value) => {
    let val = value
    if (!val || val < 1) {
      val = ""
    }
    if (repeatEvery === 1 && value > 52) {
      val = 52
    }
    if (repeatEvery === 2 && value > 26) {
      val = 26
    }
    if (repeatEvery === 4 && value > 13) {
      val = 13
    }
    setNumberOfAssignments(val)

    // Update the date
    if (!val) {
      return setToDate(undefined)
    }
    setToDate(addWeeks(endDate, (val - 1) * repeatEvery))
  }

  const onDateChange = (date) => {
    setToDate(date)

    // Update assignment count
    const numAssignmentsFromDate =
      Math.floor(differenceInWeeks(date, endDate) / repeatEvery) + 1

    if (numAssignmentsFromDate !== numberOfAssignments) {
      setNumberOfAssignments(numAssignmentsFromDate)
    }
  }

  const disabledDays = nonWorkingDay
    ? [0, 1, 2, 3, 4, 5, 6].filter((d) => {
        let isoDay = getISODay(startDate)

        // getISODay outputs Sunday as 7 but dayPickerProps outputs Sunday as 0
        if (isoDay === 7) {
          isoDay = 0
        }
        return d !== isoDay
      })
    : [0, 6]

  const dayPickerProps = {
    disabledDays: [{ daysOfWeek: disabledDays }],
    firstDayOfWeek: 1,
  }

  const options = [
    businessDays < 6 && { value: 1, label: "Week" },
    businessDays < 11 && { value: 2, label: "2 Weeks" },
    businessDays < 21 && { value: 4, label: "4 Weeks" },
  ].filter(Boolean)

  const handleRepeatEvery = (e) => {
    setRepeatEvery(e.value)
    if (e.value === 1) {
      setMaxValue(52)
      if (numberOfAssignments > 52) {
        setNumberOfAssignments(52)
      }
    }
    if (e.value === 2) {
      setMaxValue(26)
      if (numberOfAssignments > 26) {
        setNumberOfAssignments(26)
      }
    }
    if (e.value === 4) {
      setMaxValue(12)
      if (numberOfAssignments > 12) {
        setNumberOfAssignments(12)
      }
    }
  }

  return (
    <div data-component="ItemRepeat" className={styles.container}>
      <div>
        <Select
          label="Repeat Every"
          options={options}
          onChange={handleRepeatEvery}
          value={options.find((o) => o.value === repeatEvery)}
          doNotSortOptions
          height={30}
          width={120}
        />
      </div>
      <div>
        <label>Ending</label>
        <div className={styles.endingTypeContainer}>
          <div>on</div>
          <DateInput
            canClearSelection={false}
            value={toDate}
            onChange={onDateChange}
            minDate={startDate}
            maxDate={addYears(endDate, 1)}
            dayPickerProps={dayPickerProps}
            className={styles.dateInput}
          />
        </div>
        <div className={styles.endingTypeContainer}>
          <div>after</div>
          <NumericInput
            data-component="ItemRepeat/numberAssignmentsInput"
            value={numberOfAssignments}
            onValueChange={onOccurrenceChange}
            invokeChangeOnClamp
            max={maxValue}
            min={1}
            inputClassName={styles.numberAssignmentsInput}
            className={styles.numberAssignments}
            showArrows
            selectAllOnFocus
          />
          &nbsp;{numberOfAssignments > 1 ? "times" : "time"}
        </div>
      </div>
    </div>
  )
}

type Props = {
  onChange: (repeat: {
    repeatEvery: number
    numberOfAssignments: number
  }) => void
  startDate: Date
  endDate: Date
  businessDays: number
  nonWorkingDay?: boolean
}

export default ItemRepeat
