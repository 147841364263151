import * as fe from "@runn/filter-engine"
import { graphql, useFragment } from "react-relay"

import type {
  FilterSetQuery_user$data as Query,
  FilterSetQuery_user$key as QueryKey,
} from "./__generated__/FilterSetQuery_user.graphql"

import { useHasuraContext } from "~/store/hasura"

type SavedFilterSet = Query["filter_sets"][0] & {
  filters: fe.engines.local.AllowedFilter
}

const QUERY = graphql`
  fragment FilterSetQuery_user on users {
    filter_sets {
      id
      type
      name
      filters
    }
  }
`

type Options = {
  type: string
  user?: QueryKey
}

const useFilterSets = (options: Options): SavedFilterSet[] => {
  const { type, user } = options

  const hasura = useHasuraContext({ strict: false })
  const result = useFragment<QueryKey>(QUERY, user ?? hasura)
  const filterSetList = result.filter_sets as SavedFilterSet[]

  return filterSetList.filter((filter) => {
    return filter.type === type
  })
}

export { useFilterSets, SavedFilterSet }
