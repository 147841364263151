import React from "react"

import styles from "./FilterBlockListItem.module.css"

import Checkbox from "./Checkbox"

type Props<Value> = {
  label: string
  checked: boolean
  value: Value
  children?: React.ReactNode
  onChange?: (event: { value: Value; checked: boolean }) => void
}

const FilterBlockListItem = <Value,>(props: Props<Value>) => {
  const { label, children, value, checked, onChange } = props

  // generate unique id for each checkbox
  const id = React.useId()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ value, checked: event.target.checked })
  }

  return (
    <label className={styles.container} htmlFor={id}>
      <span className={styles.label}>{label}</span>
      {children}
      <Checkbox
        id={id}
        checked={checked}
        onChange={handleChange}
        className={styles.checkbox}
      />
    </label>
  )
}

export default FilterBlockListItem
