import React, { Suspense } from "react"
import { graphql, useFragment } from "react-relay"

import { EditPersonForm_person$key } from "./__generated__/EditPersonForm_person.graphql"
import { EditPersonForm_user$key } from "./__generated__/EditPersonForm_user.graphql"

import SidePanelHeader from "~/common/SidePanel/SidePanelHeader"

import PersonActionButtons from "~/Planner/PersonSidePanel/PersonActionButtons"
import PersonForm, {
  PersonFormLocation,
  PersonFormSelectedTab,
} from "~/forms/PersonForm/PersonForm"

type Props = {
  onClose: () => void
  onSaveClose?: () => void
  personQuery: EditPersonForm_person$key
  initialTab?: PersonFormSelectedTab
  location: PersonFormLocation
  user: EditPersonForm_user$key
}

const fragment = graphql`
  fragment EditPersonForm_person on people {
    id
    first_name
    last_name
    managers {
      id
      user_id
      person {
        id
      }
    }
    email
    image_key
    tags
    references
    active
    archivable
    team {
      name
      id
    }
    contracts {
      id
      rostered_days
      employment_type
      minutes_per_day
      cost: cost_private
      start_date: start_date_runn
      end_date: end_date_runn
      role {
        id
        name
        active
      }
      job_title
    }
    competencies {
      id
      level
      skill {
        id
        name
      }
    }
    holidays_group {
      id
      name
    }
    # Referenced directly in <PersonForm> as we need it in addition to the fragment inclusion
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      value
      typeId: custom_checkbox_type_id
    }
    assignments_aggregate {
      aggregate {
        count
      }
    }
    actuals_aggregate {
      aggregate {
        count
      }
    }
    links {
      id
      name
      href
      show_in_planner
    }
    ...PersonCustomEditor_person
    ...ExtLinks_Person
    ...PersonActionButtons_person
    ...ConfirmContractForm_person
  }
`

const EditPersonForm = (props: Props) => {
  const {
    onClose,
    personQuery,
    onSaveClose,
    initialTab = "details",
    location,
  } = props

  const person = useFragment<EditPersonForm_person$key>(fragment, personQuery)
  const user = useFragment(
    graphql`
      fragment EditPersonForm_user on users {
        id
        ...PersonActionButtons_user
      }
    `,
    props.user,
  )

  // Stops the screen jumping as data is loaded into the project form
  const LoadingFallback = () => <div style={{ height: "800px" }}></div>

  return (
    <>
      <PersonActionButtons
        person={person}
        user={user}
        inPlanner={location === "planner"}
      />
      <SidePanelHeader
        title={`Edit ${person.first_name} ${person.last_name}`}
        type="person"
        person={person}
      />
      <Suspense fallback={<LoadingFallback />}>
        <PersonForm
          onClose={onClose}
          person={person}
          onSaveClose={onSaveClose}
          initialTab={initialTab}
          location={location}
        />
      </Suspense>
    </>
  )
}

export default EditPersonForm
