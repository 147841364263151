import React from "react"

import styles from "./CustomTooltip.module.css"

type Props = {
  name: string
  href: string
}

const CustomTooltipLink = ({ name, href }: Props) => {
  return (
    <div className={styles.fieldContainer}>
      <span className={styles.title}>{name}</span>

      <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
        <span className={styles.linkText}>{href}</span>
      </a>
    </div>
  )
}

export default CustomTooltipLink
