import React, { useState } from "react"
import { useDispatch } from "react-redux"

import styles from "./ClonePlaceholderForm.module.css"

import {
  CustomCheckboxValue,
  CustomDateValue,
  CustomFieldsMap,
  CustomSelectValue,
  CustomTextValue,
} from "~/helpers/custom-field-helpers"
import { getCurrentContract } from "~/helpers/person"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import NumericInput from "~/common/NumericInput"
import PermissionsTooltip from "~/common/PermissionsTooltip"
import { cloneMultipleAssignments } from "~/common/Pill/AssignmentActionHelpers"
import Button from "~/common/buttons/Button"
import Checkbox from "~/common/inputs/Checkbox"

import { placeholderBulkCreateRelay } from "~/mutations/Placeholder"
import { projectMemberBulkCreateRelay } from "~/mutations/ProjectMember"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { usePermissions } from "~/Permissions/usePermissions"
import { Person } from "~/Planner/PlannerLayout/PlannerLeftColumn"
import { showAllMembersInProject } from "~/Planner/reducer2/projectMembersViewSlice"
import usePlannerFilters from "~/Planner/usePlannerFilters"

// TODO: Use relay instead
type Project = {
  id: number
  is_template: boolean
}

type Props = {
  placeholder: Person
  project: Project
  closeDialog: () => void
  workstreamId?: number
}

const ClonePlaceholderForm = (props: Props) => {
  const { placeholder, project, workstreamId, closeDialog } = props

  const hasAssignments = placeholder.assignments.length > 0

  const [placeholderCount, setPlaceholderCount] = useState(1)
  const [includeAssignments, setIncludeAssignments] = useState(hasAssignments)
  const includeAssignmentsToggle = () => {
    setIncludeAssignments(!includeAssignments)
  }

  const { peopleFilter } = usePlannerFilters()

  const dispatch = useDispatch()
  const { can, subject } = usePermissions()
  const canCreatePlacholder = can(
    "create",
    subject("Placeholder", {
      project: {
        id: project.id,
        isTemplate: project.is_template,
      },
    }),
  )

  const canCreateAssignments = can(
    "create",
    subject("Assignment", {
      project: {
        id: project.id,
        isTemplate: project.is_template,
      },
    }),
  )

  if (!canCreatePlacholder) {
    return
  }

  const handleClone = async () => {
    const placeholderCost = can("view", subject("Salary"))
      ? getCurrentContract(placeholder.contracts)?.cost
      : undefined

    const placeholdersToCreate = Array(Number(placeholderCount)).fill({
      role_id: placeholder.contracts[0].role.id,
      team_id: placeholder.team?.id,
      tags: placeholder.tags,
      cost: placeholderCost,
      competencies: placeholder.competencies.map((s) => {
        return {
          skill: { name: s.skill.name },
          level: s.level || null,
        }
      }),
      custom_values: {
        select: placeholder.custom_select_values.map((v) => ({
          optionId: v.optionId,
          typeId: v.typeId,
        })),
        text: placeholder.custom_text_values.map((v) => ({
          value: v.value,
          typeId: v.typeId,
        })),
        checkbox: placeholder.custom_checkbox_values.map((v) => ({
          value: v.value,
          typeId: v.typeId,
        })),
        date: placeholder.custom_date_values.map((v) => ({
          value: v.value,
          typeId: v.typeId,
        })),
      } as CustomFieldsMap<
        CustomTextValue[],
        CustomSelectValue[],
        CustomCheckboxValue[],
        CustomDateValue[]
      >,
    })

    try {
      const placeholders = await placeholderBulkCreateRelay({
        input: { placeholders: placeholdersToCreate },
        plannerStartDate: PLANNER_INITIAL_DATE,
        _peopleFilter: peopleFilter ? JSON.stringify(peopleFilter) : null,
      })

      void projectMemberBulkCreateRelay({
        input: {
          project_members: placeholders.map((ph) => ({
            person_id: ph.id,
            project_id: project.id,
            role_id: ph.contracts[0].role.id,
            is_placeholder: true,
            workstream_id: workstreamId ?? null,
          })),
        },
      })

      placeholders.forEach((ph) => {
        if (includeAssignments) {
          const assignments = placeholder.assignments.map((a) => ({
            ...a,
            id: undefined,
            person_id: ph.id,
          }))

          void cloneMultipleAssignments(assignments, ph, true)
        } else {
          dispatch(showAllMembersInProject(project.id))
        }
      })
    } finally {
      closeDialog()
    }
  }

  return (
    <ModalFormWrapper headerTitle="Clone Placeholder">
      <ModalBody>
        <p>How many new placeholders would you like to create?</p>
        <div className={styles.numericInput}>
          <NumericInput
            integer
            showArrows
            min={1}
            max={50}
            value={placeholderCount || ""}
            onValueChange={(e) => setPlaceholderCount(e)}
            dataTest="clone-placeholder-count"
          />
        </div>
        {hasAssignments && (
          <PermissionsTooltip
            action="create"
            subject="Assignments"
            disabled={canCreateAssignments}
          >
            <Checkbox
              id="include-assignments"
              containerClassName={styles.checkbox}
              onChange={includeAssignmentsToggle}
              checked={includeAssignments}
              label="Include assignments"
            />
          </PermissionsTooltip>
        )}
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={closeDialog} />
        <Button
          text="Clone"
          outlined={false}
          onClick={handleClone}
          disabled={!placeholderCount}
          data-test="clone-placeholder-button"
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default ClonePlaceholderForm
