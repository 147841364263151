/**
 * @generated SignedSource<<26022cd5dd99fc06cd76304edb538810>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeWorkstreamModal_project$data = {
  readonly id: number;
  readonly name: string;
  readonly project_workstreams: ReadonlyArray<{
    readonly workstream: {
      readonly archived: boolean;
      readonly id: number;
      readonly name: string;
    };
  }>;
  readonly " $fragmentType": "ChangeWorkstreamModal_project";
};
export type ChangeWorkstreamModal_project$key = {
  readonly " $data"?: ChangeWorkstreamModal_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeWorkstreamModal_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeWorkstreamModal_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "project_workstreams",
      "kind": "LinkedField",
      "name": "project_workstreams",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstream",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archived",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "1028ddeb6b4a4a740147da7273a64ab3";

export default node;
