/**
 * @generated SignedSource<<c15814834c81d3830f5b0b2edffdc2eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonRows_user$data = {
  readonly account: {
    readonly id: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_account">;
  };
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_user">;
  readonly " $fragmentType": "PersonRows_user";
};
export type PersonRows_user$key = {
  readonly " $data"?: PersonRows_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonRows_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonRows_user",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPersonRow_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectDetailsPersonRow_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "169a8affe1690bba0aba544b1b6211f5";

export default node;
