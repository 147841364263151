/**
 * @generated SignedSource<<1eaa51cc559d477e9baea52b582df01a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeoplePlannerList_user$data = {
  readonly account: {
    readonly people: ReadonlyArray<{
      readonly active: boolean;
      readonly assignments: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly project: {
          readonly confirmed: boolean;
          readonly id: number;
        };
        readonly project_id: number;
      }>;
      readonly competencies: ReadonlyArray<{
        readonly id: number;
        readonly level: number | null | undefined;
        readonly skill: {
          readonly id: number;
          readonly name: string;
        };
      }>;
      readonly contracts: ReadonlyArray<{
        readonly employment_type: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly job_title: string | null | undefined;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly start_date: string | null | undefined;
      }>;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly last_name: string;
      readonly links: ReadonlyArray<{
        readonly href: string;
        readonly id: number;
        readonly name: string;
        readonly show_in_planner: boolean;
      }>;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly person_requests: ReadonlyArray<{
        readonly id: number;
        readonly status: any;
      }>;
      readonly tags: any;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
    }>;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly client: {
        readonly id: number;
        readonly name: string;
      };
      readonly confirmed: boolean;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly id: number;
      readonly is_template: boolean;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly person_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly name: string;
      readonly tags_computed: any | null | undefined;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"TimeOffSummaryCounter_account" | "TimeOffSummary_account">;
  };
  readonly favourite_people: any;
  readonly id: number;
  readonly view: {
    readonly id: number;
    readonly people_filters: any;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"PersonManagement_user">;
  readonly " $fragmentType": "PeoplePlannerList_user";
};
export type PeoplePlannerList_user$key = {
  readonly " $data"?: PeoplePlannerList_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeoplePlannerList_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmed",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "teams",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_select_values",
  "kind": "LinkedField",
  "name": "custom_select_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_option_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_date_values",
  "kind": "LinkedField",
  "name": "custom_date_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_date_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_text_values",
  "kind": "LinkedField",
  "name": "custom_text_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_text_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_checkbox_values",
  "kind": "LinkedField",
  "name": "custom_checkbox_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_checkbox_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user_id",
    "storageKey": null
  }
],
v12 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v13 = {
  "kind": "Variable",
  "name": "peopleFilter",
  "variableName": "peopleFilter"
},
v14 = {
  "kind": "Variable",
  "name": "plannerStartDate",
  "variableName": "plannerStartDate"
},
v15 = [
  (v13/*: any*/),
  (v14/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeoplePlannerList_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "people_filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "members",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "person_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workstream_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v6/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v11/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_placeholder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "contracts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "start_date",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start_date_runn",
                  "storageKey": null
                },
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employment_type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "job_title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "roles",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "fields": [
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "_gte",
                          "variableName": "plannerStartDate"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "end_date_iso"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "where"
                }
              ],
              "concreteType": "assignments",
              "kind": "LinkedField",
              "name": "assignments",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "project_id",
                  "storageKey": null
                },
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "projects",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "competencies",
              "kind": "LinkedField",
              "name": "competencies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "skills",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "person_requests",
              "kind": "LinkedField",
              "name": "person_requests",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v6/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "people_links",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "href",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "show_in_planner",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "people_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v11/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": (v15/*: any*/),
          "kind": "FragmentSpread",
          "name": "TimeOffSummary_account"
        },
        {
          "args": (v15/*: any*/),
          "kind": "FragmentSpread",
          "name": "TimeOffSummaryCounter_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PersonManagement_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "6d31d12db666550e703115ee5b08b8e3";

export default node;
