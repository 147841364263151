import { P, match } from "ts-pattern"
import { z } from "zod"

export enum Mode {
  INVITE,
  EDIT,
}

export type User = {
  account_id: number
  email: string
  first_name: string
  id: number
  image_key: string
  last_name: string
  manageable_people_count: number
  manageable_projects_count: number
  permissions: any
}

export const schema = z.object({
  avatar: z.string().nullable().optional(),
  firstName: z.string().max(50, "First name must be 50 characters or less"),
  lastName: z.string().max(50, "Last name must be 50 characters or less"),
  email: z.string().trim().min(1, "Email is required").email(),
  permissions: z.object({
    type: z.enum(["admin", "manager", "contributor"]),
    financial: z.enum(["all", "restricted", "none"]),
    manage_projects: z.enum(["all", "restricted", "none"]),
    manage_people: z.enum(["all", "restricted", "none"]),
    add_all_people_to_projects: z.boolean(),
    manage_account: z.boolean(),
    view_planner: z.boolean(),
  }),
  manageablePeople: z.array(z.number()),
  manageableProjects: z.array(z.number()),
})

export type UserForm = Required<z.infer<typeof schema>>

const blankPermissions = {
  manage_account: false,
  manage_projects: "none" as const,
  manage_people: "none" as const,
  financial: "none" as const,
  view_planner: true,
  add_all_people_to_projects: false,
}

export const sanitizeFormData = (formData: UserForm) => {
  const {
    avatar,
    permissions,
    manageablePeople,
    manageableProjects,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    email,
    ...rest
  } = formData

  const imageDataURI = match(avatar)
    .with(null, () => "") // empty string - Delete the existing avatar
    .with(P.string, (str) => encodeURIComponent(str)) // encoded data URI - Upload the new avatar
    .otherwise(() => avatar) // undefined - Don't update the avatar

  return match(formData.permissions.type)
    .with("admin", () => ({
      ...rest,
      imageDataURI,
      permissions: {
        ...blankPermissions,
        type: permissions.type,
        manage_account: permissions.manage_account,
        manage_projects: "all" as const,
        manage_people: "all" as const,
        financial: permissions.manage_account ? "all" : permissions.financial,
        add_all_people_to_projects: true,
      },
      manageablePeople: [],
      manageableProjects: [],
    }))
    .with("contributor", () => ({
      ...rest,
      imageDataURI,
      permissions: {
        ...blankPermissions,
        type: permissions.type,
        view_planner: permissions.view_planner,
      },
      manageablePeople: [],
      manageableProjects: [],
    }))
    .with("manager", () => ({
      ...rest,
      imageDataURI,
      permissions: {
        ...blankPermissions,
        type: permissions.type,
        financial: permissions.financial,
        add_all_people_to_projects: match(permissions)
          .with({ manage_people: "all" }, () => true)
          .with(
            { manage_people: "restricted", manage_projects: "none" },
            () => true,
          )
          .with({ manage_projects: "none" }, () => false)
          .otherwise(() => permissions.add_all_people_to_projects),
        manage_people: permissions.manage_people,
        manage_projects: permissions.manage_projects,
      },
      manageablePeople:
        permissions.manage_people === "restricted" ? manageablePeople : [],
      manageableProjects:
        permissions.manage_projects === "restricted" ? manageableProjects : [],
    }))
    .exhaustive()
}

export const splitByComma = (str: string): string[] => {
  return str
    .trim()
    .split(/\s*,\s*/) // Split by comma with optional whitespace
    .filter(Boolean)
}
