import { graphql, useFragment } from "react-relay"

import type { useClients_account$key } from "./__generated__/useClients_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useClients_account on accounts {
    clients {
      id
      name
      active
      real_client
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useClients = (options: Options) => {
  const { user } = options

  const { clients } = useFragment<useClients_account$key>(
    fragment,
    user.account,
  )

  const availableOptions = clients
    // Do not include the project template default client
    .filter((client) => {
      return client.real_client && client.active
    })
    .map((client) => ({
      value: client.id,
      label: client.name,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useClients
