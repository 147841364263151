import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import styles from "./WildSearchInput.module.css"

import { track } from "~/helpers/analytics"

import {
  LOCAL_SEARCH_DEBOUNCE_MS,
  SERVER_SEARCH_DEBOUNCE_MS,
} from "~/common/SuperSearch/SuperSearch"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"

type Props = {
  disabled?: boolean
  placeholder?: string
  autoFocus?: boolean
  location: string
  value: string | undefined
  onChange: (value: string | undefined) => void
}

const WildSearchInput = (props: Props) => {
  const { disabled, placeholder, value, onChange, autoFocus, location } = props

  const [query, setQuery] = useState(value)

  const queryLimitState = useQueryLimit()
  const debounceTime = queryLimitState.limit
    ? SERVER_SEARCH_DEBOUNCE_MS
    : LOCAL_SEARCH_DEBOUNCE_MS

  const handleSearch = useDebouncedCallback(() => {
    onChange(query)
    track("Search Wildcard Selected", { value: query, location })
  }, debounceTime)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setQuery(inputValue)
    handleSearch()
  }

  useEffect(() => {
    // Only reset internal search when the input has been cleared.
    if (value === "") {
      setQuery(value)
    }
  }, [value])

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
      <input
        className={styles.input}
        value={query}
        onChange={handleChangeInput}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        data-test="SuperSearch_WildSearchInput_input"
      />
    </div>
  )
}

export default WildSearchInput
