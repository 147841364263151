/**
 * @generated SignedSource<<e825fb614d7ba0763e37aa3585d90103>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectViewDialog_user$data = {
  readonly account: {
    readonly id: number;
    readonly views: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
    }>;
  };
  readonly id: number;
  readonly view: {
    readonly id: number;
  } | null | undefined;
  readonly " $fragmentType": "SelectViewDialog_user";
};
export type SelectViewDialog_user$key = {
  readonly " $data"?: SelectViewDialog_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectViewDialog_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectViewDialog_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "b4d0c326beec190ddee06e5a99be3682";

export default node;
