import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { getSettings } from "~/localsettings"

type PeopleFilterReducerState = {
  includeArchivedProjects: boolean
}

const getInitialState = (): PeopleFilterReducerState => {
  const settings = getSettings()
  return {
    includeArchivedProjects:
      settings?.peopleFilterIncludeArchivedProjects ?? false,
  }
}

const peopleSummarySlice = createSlice({
  name: "peopleSummary",
  initialState: getInitialState,
  reducers: {
    setIncludeArchivedProjects: (state, action: PayloadAction<boolean>) => {
      state.includeArchivedProjects = action.payload
    },
  },
})

const { actions, reducer } = peopleSummarySlice
export const { setIncludeArchivedProjects } = actions
export default reducer
