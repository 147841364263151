import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"
import { ProjectTemplateGroup_account$key } from "./__generated__/ProjectTemplateGroup_account.graphql"
import { ProjectTemplateGroup_user$key } from "./__generated__/ProjectTemplateGroup_user.graphql"

import HeadingBar from "~/common/HeadingBar"

import { SET_PROJECT_TEMPLATE_BAR_COLLAPSED } from "~/Planner/Planner.actions"
import { getSettings, setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

import ProjectRow from "./ProjectRow"

type ProjectTemplateGroup = {
  account: ProjectTemplateGroup_account$key
  user: ProjectTemplateGroup_user$key
  userId: number
  projectTemplateCount: number
  favouriteProjectIds: string[]
  projectTemplateList: ProjectManagement_user$data["account"]["projects"]
}

const closeOpenedProjects = () => {
  const plannerProjectsExpanded = getSettings()?.plannerProjectsExpanded || []
  // remove expanded row
  const newPlannerProjectExpanded = plannerProjectsExpanded.filter(
    (row) => row.groupName !== "templates",
  )
  setSetting("plannerProjectsExpanded", newPlannerProjectExpanded)
}

const ProjectTemplateGroup = (props: ProjectTemplateGroup) => {
  const {
    projectTemplateCount,
    projectTemplateList,
    userId,
    favouriteProjectIds,
  } = props

  const account = useFragment(
    graphql`
      fragment ProjectTemplateGroup_account on accounts
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        ...ProjectRow_account
          @arguments(
            plannerStartDate: $plannerStartDate
            projectsFilter: $projectsFilter
            peopleFilter: $peopleFilter
          )
      }
    `,
    props.account,
  )

  const user = useFragment(
    graphql`
      fragment ProjectTemplateGroup_user on users {
        id
        ...ProjectRow_user
      }
    `,
    props.user,
  )

  const dispatch = useDispatch()
  const projectTemplateBarCollapsed = useSelector(
    (state: ReduxState) => state.planner.projectTemplateBarCollapsed,
  )

  const toggleGroup = () => {
    if (!projectTemplateBarCollapsed) {
      closeOpenedProjects()
    }

    dispatch({
      type: SET_PROJECT_TEMPLATE_BAR_COLLAPSED,
      payload: !projectTemplateBarCollapsed,
    })
  }

  return (
    <div>
      <HeadingBar
        text="Templates"
        count={projectTemplateCount}
        id="planner-heading-bar"
        onClick={toggleGroup}
        isCollapsed={projectTemplateBarCollapsed}
        data-test={`project-templates-${
          projectTemplateBarCollapsed ? "collapsed" : "expanded"
        }`}
      />
      {!projectTemplateBarCollapsed &&
        projectTemplateList.map((template) => (
          <ProjectRow
            key={template.id}
            account={account}
            user={user}
            project={template}
            favourites={favouriteProjectIds}
            userId={userId}
            groupName={"templates"}
          />
        ))}
    </div>
  )
}

export default ProjectTemplateGroup
