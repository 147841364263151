import { graphql, useFragment } from "react-relay"

import type {
  Account_Integrations$data,
  Account_Integrations$key,
} from "./__generated__/Account_Integrations.graphql"

const accountIntegrationsFragment = graphql`
  fragment Account_Integrations on accounts {
    integrationsV2(where: { state: { _neq: "PENDING" } }) {
      id
      integration_service {
        id
        square_image_url
        name
      }
    }
  }
`
type AccountRef = Account_Integrations$key
type Integration = Account_Integrations$data["integrationsV2"][number]

const useAccountIntegrations = (
  accountRef: AccountRef,
): ReadonlyArray<Integration> => {
  const data = useFragment(accountIntegrationsFragment, accountRef)
  return data?.integrationsV2 ?? []
}

export { useAccountIntegrations }
export type { AccountRef, Integration }
