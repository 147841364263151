import * as fe from "@runn/filter-engine"
import React from "react"

import { getViewConfig } from "./helpers"
import { AllowedProjectFilterSet } from "~/helpers/filter-engine"

import { useProjectSearchConfig } from "~/common/SuperSearch"
import SuperSearch from "~/common/SuperSearch/SuperSearch"

type Props = {
  filterSet: AllowedProjectFilterSet
  setProjectFilterSet: (set: AllowedProjectFilterSet) => void
}

const singleValueFilterTypes = [
  "project_client_id",
  "project_team_id",
  "project_person_placeholder_status",
  "project_status",
]

const ViewFormProjectsFilter = (props: Props) => {
  const { setProjectFilterSet, filterSet } = props

  const selectedFilterSets = fe.filters.flat(filterSet.filters)

  const selectedSingleFilters = selectedFilterSets.reduce(
    (acc, filter) =>
      singleValueFilterTypes.includes(filter.type)
        ? [...acc, filter.type]
        : acc,
    [],
  )

  const selectedCustomFieldTypeIds = selectedFilterSets.reduce(
    (acc, filter) => {
      if (!filter.type.includes("person_custom_select")) {
        return acc
      }

      const customFieldFilter = filter as fe.filters.FilterProjectCustomSelect
      return [...acc, customFieldFilter.options.typeId]
    },
    [],
  )

  const defaultConfig = useProjectSearchConfig()
  const hiddenFilters = [
    "project_is_favourite",
    "project_id",
    "project_person_id",
    "project_template_id",
    "project_state",
    ...selectedSingleFilters,
  ]

  const config = getViewConfig({
    defaultConfig,
    hiddenFilters,
    hiddenCustomFieldTypeIds: selectedCustomFieldTypeIds,
    filterMenuButtonLabel: "Project Filters",
  })

  const changeFilterSet = (set: AllowedProjectFilterSet) => {
    setProjectFilterSet(set)
  }

  return (
    <SuperSearch
      location="views"
      disabled={false}
      config={config}
      filterSet={filterSet}
      onChangeFilterSet={changeFilterSet}
      showSavedFilterSets={false}
    />
  )
}

export default ViewFormProjectsFilter
