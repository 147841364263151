/**
 * @generated SignedSource<<77f1a62cad4a8874ea96d93040a0822f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRoles_account$data = {
  readonly roles: ReadonlyArray<{
    readonly active: boolean;
    readonly id: number;
    readonly name: string | null | undefined;
  }>;
  readonly " $fragmentType": "useRoles_account";
};
export type useRoles_account$key = {
  readonly " $data"?: useRoles_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRoles_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRoles_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "roles",
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "1f009e0668abade9e63c79aa18b4aab0";

export default node;
