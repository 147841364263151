import { graphql } from "react-relay"

import { PhaseCreateMutation } from "./__generated__/PhaseCreateMutation.graphql"
import { PhaseDeleteMutation } from "./__generated__/PhaseDeleteMutation.graphql"
import { PhaseUpdateMutation } from "./__generated__/PhaseUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise, removeLinkedRecord } from "./helpers"
import * as relayids from "~/helpers/relayids"

const phaseCreateMutation = graphql`
  mutation PhaseCreateMutation(
    $name: String!
    $color: String
    $start_date: Date!
    $end_date: Date!
    $project_id: Int!
  ) {
    createPhase(
      name: $name
      color: $color
      startDate: $start_date
      endDate: $end_date
      projectId: $project_id
    ) {
      phase {
        id
        name
        color
        start_date: start_date_runn
        end_date: end_date_runn
        project_id
        project {
          id
          calc_start_date
          calc_end_date
          phases {
            id
          }
          phases_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const phaseCreateRelay = async (
  variables: PhaseCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<PhaseCreateMutation>(environment, {
    mutation: phaseCreateMutation,
    variables,
  })
  return data.createPhase.phase
}

const phaseUpdateMutation = graphql`
  mutation PhaseUpdateMutation(
    $id: Int!
    $color: String!
    $start_date: Date!
    $end_date: Date!
    $name: String!
    $project_id: Int!
  ) {
    updatePhase(
      id: $id
      color: $color
      startDate: $start_date
      endDate: $end_date
      name: $name
      projectId: $project_id
    ) {
      phase {
        id
        name
        color
        start_date: start_date_runn
        end_date: end_date_runn
        project {
          id
          calc_start_date
          calc_end_date
        }
      }
    }
  }
`

export const phaseUpdateRelay = async (
  variables: PhaseUpdateMutation["variables"],
) => {
  await commitMutationPromise<PhaseUpdateMutation>(environment, {
    mutation: phaseUpdateMutation,
    variables,
  })
  return undefined
}

const phaseDeleteMutation = graphql`
  mutation PhaseDeleteMutation($id: Int!) {
    deletePhase(id: $id) {
      project {
        id
        calc_start_date
        calc_end_date
        phases {
          id
        }
        phases_aggregate {
          aggregate {
            count
          }
        }
        actuals {
          id
          phase_id
        }
        assignments {
          id
          phase_id
        }
      }
    }
  }
`

export const phaseDeleteRelay = async (
  variables: PhaseDeleteMutation["variables"],
) => {
  await commitMutationPromise<PhaseDeleteMutation>(environment, {
    mutation: phaseDeleteMutation,
    variables,
    optimisticUpdater: (store) => {
      const phase = store.get(relayids.phases.encode(variables.id))
      const project_id = phase.getValue("project_id") as number
      const project = store.get(relayids.projects.encode(project_id))

      removeLinkedRecord(project, "phases", phase)
      store.delete(phase.getDataID())
    },
  })

  return undefined
}
