import { Tooltip } from "@blueprintjs/core"
import { DatePicker } from "@blueprintjs/datetime"
import { dateHelpers } from "@runn/calculations"
import { addYears, format, subYears } from "date-fns"
import capitalize from "lodash-es/capitalize"
import isEqual from "lodash-es/isEqual"
import React, { useState } from "react"

import styles from "./MilestoneForm.module.css"
import datepickerStyles from "~/common/DatePicker/DatePicker.module.css"

import { ProjectMilestonesCard_project$data } from "~/ProjectDashboard/Body/ProjectMilestones/__generated__/ProjectMilestonesCard_project.graphql"
import { ProjectMilestones_project$data } from "~/ProjectPlanner/ProjectOverview/__generated__/ProjectMilestones_project.graphql"

import { track } from "~/helpers/analytics"
import { isEmoji } from "~/helpers/text-helpers"

import Input from "~/common/Input"
import BlueLink from "~/common/buttons/BlueLink"
import Button from "~/common/buttons/Button"

import {
  milestoneCreateRelay,
  milestoneDeleteRelay,
  updateMilestoneRelay,
} from "~/mutations/Milestone"

import MilestoneEmojiPicker from "./MilestoneEmojiPicker"
import { MilestoneIcon, MilestoneIcons } from "./index"

type Props = {
  type: string
  milestone?:
    | ProjectMilestones_project$data["milestones"][0]
    | ProjectMilestonesCard_project$data["milestones"][0]
  projectId?: number
  hideCalendar?: boolean
  hideDate?: boolean
  onClose: () => void
}

const MilestoneForm = (props: Props) => {
  const { type, milestone, hideCalendar, hideDate } = props

  const [selectedIcon, setSelectedIcon] = useState<string | null>(
    milestone?.icon || "dollar",
  )
  const [selectedDate, setSelectedDate] = useState(
    milestone ? dateHelpers.parseRunnDate(milestone.date) : new Date(),
  )

  const [title, setTitle] = useState(milestone?.title || "")
  const [note, setNote] = useState(milestone?.note || "")
  const iconIsEmoji = isEmoji(selectedIcon)
  const selectNewEmoji = (emoji: string | null) => {
    setSelectedIcon(emoji ? emoji : "dollar")
  }

  const existingMilestone = milestone && {
    ...milestone,
    date: milestone.date,
  }

  const selectedData = milestone && {
    id: milestone.id,
    title,
    note,
    icon: selectedIcon,
    date: selectedDate,
    project_id: props.projectId,
  }

  const noChangesMade = isEqual(existingMilestone, selectedData)

  const handleSelectIcon = (name) => {
    setSelectedIcon(name)
  }

  const createMilestone = async () => {
    track("Project Milestone Created", {
      title: title,
    })
    const data = {
      title,
      note: note || "",
      icon: selectedIcon,
      date: format(selectedDate, "yyyy-MM-dd"),
      project_id: props.projectId,
    }
    await milestoneCreateRelay(data)
    props.onClose()
  }

  const updateMilestone = async () => {
    track("Project Milestone Edited")
    const data = {
      id: milestone.id,
      title,
      note: note || "",
      icon: selectedIcon,
      date: format(selectedDate, "yyyy-MM-dd"),
      project_id: props.projectId,
    }

    await updateMilestoneRelay(data)
    props.onClose()
  }

  const deleteMilestone = () => {
    track("Project Milestone Deleted")
    props.onClose()
    void milestoneDeleteRelay({ id: milestone.id })
  }

  const changeTitle = (e) => {
    setTitle(e.target.value)
  }

  const changeNote = (e) => {
    track("Project Milestone Note Changed")
    setNote(e.target.value)
  }

  const onSelectDate = (date) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  return (
    <div className={styles.milestoneForm}>
      <div className={styles.header}>
        <div className={styles.title}>{type} Milestone</div>
        {!hideDate && (
          <div className={styles.date}>
            {selectedDate ? format(selectedDate, "d MMMM yyyy") : ""}
          </div>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.formContainer}>
          <div className={styles.iconContainer}>
            {MilestoneIcons.filter((icon) => icon.name !== "plus").map(
              (i, idx) => (
                <div key={idx} className={styles.iconDiv}>
                  <Tooltip placement="top" content={capitalize(i.name)}>
                    <MilestoneIcon
                      iconOrEmoji={i.name}
                      deselected={selectedIcon !== i.name}
                      key={i.name}
                      size={28}
                      className={styles.icon}
                      onClick={() => handleSelectIcon(i.name)}
                    />
                  </Tooltip>
                </div>
              ),
            )}
            <MilestoneEmojiPicker
              deselected={!iconIsEmoji}
              onClick={selectNewEmoji}
              emoji={iconIsEmoji ? selectedIcon : null}
            />
          </div>
          <Input
            name="milestone-title"
            placeholder="Name"
            value={title}
            onChange={changeTitle}
            autoComplete="off"
            autoFocus={type === "Add"}
          />
          <textarea
            className={`${styles.noteInput} ${
              hideCalendar ? styles.noteInputSmall : ""
            }`}
            placeholder="Note (optional)"
            value={note}
            onChange={changeNote}
          />
        </div>
        {!hideCalendar && (
          <div className={datepickerStyles.datePicker}>
            <DatePicker
              defaultValue={selectedDate}
              minDate={subYears(new Date(), 5)}
              maxDate={addYears(new Date(), 5)}
              onChange={onSelectDate}
              dayPickerProps={{ firstDayOfWeek: 1 }}
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        {type === "Edit" && (
          <BlueLink
            text="Delete"
            onClick={deleteMilestone}
            style={{ marginRight: "auto" }}
          />
        )}
        {type === "Edit" ? (
          <Button
            text="Save Changes"
            outlined={false}
            disabled={!title || !selectedDate || noChangesMade}
            onClick={updateMilestone}
          />
        ) : (
          <Button
            text="Create"
            outlined={false}
            disabled={!title || !selectedDate}
            onClick={createMilestone}
          />
        )}
      </div>
    </div>
  )
}

export default MilestoneForm
