import React, { useCallback, useState } from "react"

import { CustomTextType, CustomTextValue } from "~/helpers/custom-field-helpers"

import Input from "~/common/Input"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"

import { CustomEditorFieldProps } from "~/CustomEditor/types"

type Props = CustomEditorFieldProps<CustomTextType, CustomTextValue>

const CustomEditorTextField = ({ id, type, value, onChange }: Props) => {
  const [localValue, setLocalValue] = useState<Props["value"]>(value)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedValue = {
        ...localValue,
        value: e.currentTarget.value,
        typeId: type.id,
      }
      onChange({ type, value: updatedValue })
      setLocalValue(updatedValue)
    },
    [type, localValue, onChange],
  )
  return (
    <Input
      label={
        <TitledHelpTooltip
          title={type.name}
          tooltipContent={type.description}
        />
      }
      name={`${id}-field`}
      value={localValue?.value}
      onChange={handleChange}
    />
  )
}

export default CustomEditorTextField
