import * as fe from "@runn/filter-engine"

import {
  AllowedPeopleFilterSet,
  AllowedProjectFilterSet,
} from "~/helpers/filter-engine"
import { defaultToFalse } from "~/helpers/general-helpers"
import {
  getPeopleGroupByOptions,
  getProjectGroupByOptions,
} from "~/helpers/group-helpers"

import { getSettings, setSetting } from "~/localsettings"

import {
  CLEAR_PERSON_FILTER,
  EXPAND_ALL_PROJECTS,
  SET_EXPANDED_PEOPLE_GROUPS,
  SET_PEOPLE_FILTER_SET,
  SET_PEOPLE_WILD_SEARCH,
  SET_PLACEHOLDER_BAR_COLLAPSED,
  SET_PROJECT_GROUP_BY_EXPANDED,
  SET_PROJECT_TEMPLATE_BAR_COLLAPSED,
  SET_SELECTED_PLACEHOLDER,
  SET_SHOW_PROJECT_PHASES,
  SET_SPLIT_SCREEN_FILTER_SET,
  SET_TIME_OFF_BAR_COLLAPSED,
  TOGGLE_EXPAND_ALL,
} from "./Planner.actions"

const settings = getSettings()

export const expandedPeopleInitial = getPeopleGroupByOptions().map((group) => ({
  groupByFilter: group.value,
  expanded: group.value === "default" ? ["All"] : [],
}))

export const expandedProjectInitial = getProjectGroupByOptions().map(
  (group) => ({
    groupByFilter: group.value,
    expanded: group.value === "default" ? ["All"] : [],
  }),
)

export type PlannerReducerState = {
  // showCharts: boolean

  peopleWildSearch: string

  projectFilterSet: AllowedProjectFilterSet
  peopleFilterSet: AllowedPeopleFilterSet
  splitScreenFilterSet: fe.engines.local.AllowedFilterSet
  expandAllProjects: boolean | null
  selectedPlaceholder: number | null
  expandedProjectGroups: {
    groupByFilter: string
    expanded: string[]
  }[]
  expandedPeopleGroups: {
    groupByFilter: string
    expanded: string[]
  }[]
  timeOffBarCollapsed: boolean
  placeholderBarCollapsed: boolean
  projectTemplateBarCollapsed: boolean
  expandAll: boolean
  showProjectPhases: boolean
  personFilterValue?: string
}

const INITIAL_STATE: PlannerReducerState = {
  // showCharts: false,
  peopleWildSearch: settings.peopleWildSearch,
  peopleFilterSet: settings.peopleFilterSet || {
    name: "All People",
    filters: null,
  },
  projectFilterSet: settings.projectFilterSet || {
    name: "All Projects",
    filters: null,
  },
  splitScreenFilterSet: {
    name: "Split Screen",
    filters: null,
  },
  expandAllProjects: null,
  selectedPlaceholder: null,
  expandedPeopleGroups: settings.expandedPeopleGroups || expandedPeopleInitial,
  expandedProjectGroups:
    settings.expandedProjectGroups || expandedProjectInitial,
  timeOffBarCollapsed: true,
  placeholderBarCollapsed: true,
  projectTemplateBarCollapsed: true,
  expandAll: false,
  showProjectPhases: defaultToFalse(settings.showProjectPhases),
}

export default (
  state: PlannerReducerState = INITIAL_STATE,
  action: any,
): PlannerReducerState => {
  switch (action.type) {
    case SET_PEOPLE_WILD_SEARCH:
      setSetting("peopleWildSearch", action.payload)
      return {
        ...state,
        peopleWildSearch: action.payload,
      }

    case SET_PEOPLE_FILTER_SET:
      setSetting("peopleFilterSet", action.payload)
      return {
        ...state,
        peopleFilterSet: action.payload,
      }

    case SET_SPLIT_SCREEN_FILTER_SET:
      return {
        ...state,
        splitScreenFilterSet: action.payload,
      }

    case CLEAR_PERSON_FILTER:
      return {
        ...state,
        personFilterValue: "",
      }

    case EXPAND_ALL_PROJECTS:
      return {
        ...state,
        expandAllProjects: action.payload,
      }
    case SET_SELECTED_PLACEHOLDER:
      return {
        ...state,
        selectedPlaceholder: action.payload.placeholderId,
      }
    case SET_PROJECT_GROUP_BY_EXPANDED:
      return {
        ...state,
        expandedProjectGroups: action.payload,
      }
    case SET_EXPANDED_PEOPLE_GROUPS:
      return {
        ...state,
        expandedPeopleGroups: action.payload,
      }
    case SET_PLACEHOLDER_BAR_COLLAPSED:
      return {
        ...state,
        placeholderBarCollapsed: action.payload,
      }
    case SET_PROJECT_TEMPLATE_BAR_COLLAPSED:
      setSetting("projectTemplateBarCollapsed", action.payload)
      return {
        ...state,
        projectTemplateBarCollapsed: action.payload,
      }
    case SET_TIME_OFF_BAR_COLLAPSED:
      setSetting("timeOffBarCollapsed", action.payload)
      return {
        ...state,
        timeOffBarCollapsed: action.payload,
      }
    case TOGGLE_EXPAND_ALL:
      return {
        ...state,
        expandAll: !state.expandAll,
      }

    case SET_SHOW_PROJECT_PHASES:
      return {
        ...state,
        showProjectPhases: action.payload,
      }
    default:
      return state
  }
}
