import { dateHelpers } from "@runn/calculations"
import { addBusinessDays, isWeekend, subBusinessDays } from "date-fns"
import { format } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"

import styles from "./PhaseItem.module.css"

import { getNewlyMovedDate, getTimeFrames } from "~/helpers/CalendarHelper"
import { getGridAttributes } from "~/helpers/PhaseHelper"
import { PHASE_COLORS } from "~/helpers/colors"

import { phaseCreateRelay } from "~/mutations/Phase"

import { setHighlightedItemData } from "~/GlobalState"
import { usePermissions } from "~/Permissions/usePermissions"
import { getSettings, setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

import PhaseForm from "./PhaseForm"
import PhaseHighlightedArea from "./PhaseHighlightedArea"
import PhaseName from "./PhaseName"

type Props = {
  phasesWrapperRef: React.RefObject<HTMLElement>
  projectId: number
  setOverlayCursor?: (cursorType: "grabbing" | "ew-resize") => void
  phaseCount: number
  isTemplate: boolean
}

type TempPill = {
  name: string
  start: Date
  end: Date
  color: string
}

const getNextPhaseColor = (colorLastUsed: string) => {
  const indexLastColorUsed = PHASE_COLORS.findIndex(
    (c) => c.color === colorLastUsed,
  )

  const indexNextColor = indexLastColorUsed + 1
  const indexLength = PHASE_COLORS.length - 1

  return (
    PHASE_COLORS[indexNextColor <= indexLength ? indexLastColorUsed + 1 : 0]
      .color || PHASE_COLORS[0].color
  )
}

const NewPhaseItem = (props: Props) => {
  const {
    setOverlayCursor,
    phasesWrapperRef,
    projectId,
    phaseCount,
    isTemplate,
  } = props

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )
  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
  } = calendar

  const { can, subject } = usePermissions()
  const canCreate = can(
    "create",
    subject("Phase", {
      project: { id: projectId, isTemplate },
    }),
  )

  const { dailyDates: calendarDates } = getTimeFrames({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const dispatch = useDispatch()
  const emptyDates = { start: null, end: null }

  // State does not update in event listener functions. Refs are used to fix this.
  const refHoverDay = useRef(null)
  const refDates = useRef(emptyDates)
  const refFirstSelectedDay = useRef(null)

  const [hoverDay, setHoverDay] = useState(null)
  const [firstSelectedDay, setFirstSelectedDay] = useState(null)
  const [dates, setDates] = useState(emptyDates)
  const [showForm, setShowForm] = useState(false)
  const [tempPhase, setTempPhase] = useState<TempPill>()
  const [hoverDayIsWeekend, setHoverDayIsWeekend] = useState(false)

  const phaseColor = getNextPhaseColor(getSettings().phaseColorLastUsed)
  const phaseName = "Phase"

  const resetValues = () => {
    setFirstSelectedDay(null)
    setShowForm(false)
    setOverlayCursor(null)
    setHoverDay(null)
    setDates(emptyDates)
    dispatch(setHighlightedItemData(null))
  }

  const createPhase = async ({ name, start, end, color }: TempPill) => {
    const newPhase = {
      name,
      start_date: format(start, "yyyy-MM-dd"),
      end_date: format(end, "yyyy-MM-dd"),
      project_id: projectId,
      color,
    }

    setTempPhase({ name, start, end, color })
    resetValues()
    setSetting("phaseColorLastUsed", color)
    await phaseCreateRelay(newPhase)
    setTempPhase(undefined)
  }

  useEffect(() => {
    setTempPhase(undefined)
  }, [phaseCount])

  useEffect(
    () => {
      refDates.current = dates
      refHoverDay.current = hoverDay
      refFirstSelectedDay.current = firstSelectedDay

      const weekend =
        calendarWeekendsExpanded && (hoverDay % 7 === 0 || hoverDay % 7 === 6)

      setHoverDayIsWeekend(weekend)
      if (weekend) {
        dispatch(setHighlightedItemData(null))
        return
      }

      if (hoverDay && calendarDates[hoverDay - 1]) {
        const hoveredDate = dateHelpers.formatToRunnDate(
          calendarDates[hoverDay - 1],
        )
        dispatch(
          setHighlightedItemData({
            type: "phase",
            start_date:
              (dates.start && dateHelpers.formatToRunnDate(dates.start)) ||
              hoveredDate,
            end_date:
              (dates.end && dateHelpers.formatToRunnDate(dates.end)) ||
              hoveredDate,
            color: phaseColor,
          }),
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dates, hoverDay, firstSelectedDay],
  )

  const onMouseOver = (e) => {
    const mousePositionOffset = e.pageX - phasesWrapperRef.current.offsetLeft
    const hoveredDay = Math.ceil(mousePositionOffset / dayWidth)
    setHoverDay(hoveredDay)
  }

  const onMouseMove = (e) => {
    if (phasesWrapperRef.current == null) {
      // we need to know where the phasesWrapper is before we can handle the
      // mouse movement
      return
    }

    const mousePositionOffset = e.pageX - phasesWrapperRef.current.offsetLeft
    const mouseIsWithinRange =
      mousePositionOffset < phasesWrapperRef.current.offsetWidth &&
      mousePositionOffset > 0
    const hoveredDay = Math.ceil(mousePositionOffset / dayWidth)
    const hoveredDayHasChanged = hoveredDay !== refHoverDay.current

    // Check if mouse position is within phasesWrapper
    // Only make calculations if day is different to previously hovered day
    if (mouseIsWithinRange && hoveredDayHasChanged) {
      setHoverDay(hoveredDay)

      if (refDates.current.start && refFirstSelectedDay.current) {
        const cellsMoved = hoveredDay - refFirstSelectedDay.current
        const firstSelectedDate = calendarDates[refFirstSelectedDay.current - 1]

        if (cellsMoved >= 0) {
          // Moved mouse to right
          const { date: endDate } = getNewlyMovedDate({
            originalDate: dateHelpers.formatToRunnDate(refDates.current.start),
            cellsMoved,
            calendarWeekendsExpanded,
          })
          setDates({ start: firstSelectedDate, end: endDate })
        } else {
          // If user moves mouse to the left (cellsMoved are negative) - swap start and end dates
          const { date: endDate } = getNewlyMovedDate({
            originalDate: dateHelpers.formatToRunnDate(refDates.current.end),
            cellsMoved,
            calendarWeekendsExpanded,
          })
          setDates({ start: endDate, end: firstSelectedDate })
        }
      }
    }
  }

  const onMouseUp = () => {
    setOverlayCursor(null)
    if (refDates.current.start && refDates.current.end) {
      setShowForm(true)
    } else {
      resetValues()
    }
    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)
  }

  const onMouseDown = (e) => {
    if (hoverDayIsWeekend) {
      return
    }
    if (e.button === 0 && !showForm) {
      const selectedDate = calendarDates[refHoverDay.current - 1]
      setDates({ start: selectedDate, end: selectedDate })
      setFirstSelectedDay(refHoverDay.current)
      setOverlayCursor("ew-resize")
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    }
  }

  useEffect(() => {
    let updatedEndDate = dates.end
    let updatedStartDate = dates.start

    if (isWeekend(dates.end)) {
      updatedEndDate = subBusinessDays(dates.end, 1)
    }

    if (isWeekend(dates.start)) {
      updatedStartDate = addBusinessDays(dates.start, 1)
    }

    setDates({ start: updatedStartDate, end: updatedEndDate })
  }, [showForm]) // eslint-disable-line react-hooks/exhaustive-deps

  const preventContextMenu = (e) => {
    e.preventDefault()
  }

  const onMouseLeave = () => {
    if (!showForm && !dates.start) {
      resetValues()
    }
  }

  const renderTempPhase = () => {
    const tempPill = getGridAttributes({
      start: tempPhase.start,
      end: tempPhase.end,
      calendarStartDate,
      calendarEndDate,
      dayWidth,
      calendarWeekendsExpanded,
    })

    return (
      <div
        style={{
          gridColumn: `${tempPill.gridStart} / span ${tempPill.gridSpan}`,
        }}
        className={styles.phaseItem}
      >
        <div
          className={styles.pill}
          style={{ backgroundColor: tempPhase.color }}
        >
          <PhaseName phase={tempPhase} pillLength={tempPill.lengthInPx} />
        </div>
      </div>
    )
  }

  if (!canCreate) {
    return null
  }

  const pill = getGridAttributes({
    start: dates.start,
    end: dates.end,
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
  })

  return (
    <>
      <div
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        className={styles.hoverArea}
        onContextMenu={preventContextMenu}
        style={{ cursor: hoverDayIsWeekend ? "not-allowed" : "copy" }}
      >
        {/* this is the area that people can hover over to show the highlighted area*/}
      </div>
      {!!hoverDay && !hoverDayIsWeekend && (
        <PhaseHighlightedArea
          gridStart={pill.gridStart || hoverDay}
          gridSpan={pill.gridSpan}
          hideHoverPill={firstSelectedDay}
          pillLength={dayWidth}
        />
      )}
      {!!tempPhase && renderTempPhase()}
      {firstSelectedDay && (
        <div
          style={{ gridColumn: `${pill.gridStart} / span ${pill.gridSpan}` }}
          className={styles.phaseItem}
        >
          <div className={styles.pill} style={{ backgroundColor: phaseColor }}>
            <PhaseName
              phase={{ name: phaseName, color: phaseColor }}
              pillLength={pill.lengthInPx}
            />
          </div>
          {showForm && (
            <PhaseForm
              phaseName={phaseName}
              dates={dates}
              onCancel={resetValues}
              onSubmit={createPhase}
              phaseColor={phaseColor}
            />
          )}
        </div>
      )}
    </>
  )
}

export default NewPhaseItem
