/**
 * @generated SignedSource<<e6b4ec9550861ff7f18980655813991f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroup_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRow_user">;
  readonly " $fragmentType": "ProjectGroup_user";
};
export type ProjectGroup_user$key = {
  readonly " $data"?: ProjectGroup_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroup_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroup_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectRow_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "2c5b38ae023615771efbd7681b1a048e";

export default node;
