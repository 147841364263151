import React from "react"

import CalendarOutline from "~/common/calendar/CalendarOutline"

import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerRightColumn } from "~/Planner/PlannerLayout"
import PlannerLeftColumn from "~/Planner/PlannerLayout/PlannerLeftColumn"
import ProjectStatsComponent from "~/ProjectPlanner/ProjectStatsComponent"

type Props = {
  projectId: number
}

const ProjectStatsSection = (props: Props) => {
  const { projectId } = props

  return (
    <PlannerGrid>
      <PlannerLeftColumn paddingHeight={0} style={{ minHeight: "30px" }}>
        <ProjectStatsComponent projectId={projectId} type="ProjectBudgetLine" />
      </PlannerLeftColumn>
      <PlannerRightColumn showHighlight={false}>
        <CalendarOutline type="standard" />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default ProjectStatsSection
