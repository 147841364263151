import cc from "classcat"
import { useFeature } from "flagged"
import { capitalize } from "lodash-es"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ModeMessage.module.css"

import { ModeMessage_viewer$key } from "./__generated__/ModeMessage_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { formatDuration } from "~/helpers/DurationHelper"
import { formatName } from "~/helpers/person"
import {
  Assignment,
  determineItemType,
  getReadablePillType,
} from "~/helpers/planner-helpers"
import { pluralize } from "~/helpers/plural"
import {
  MultiSelectAssignments,
  getPastMinutes,
  getPresentAction,
} from "~/helpers/split-screen-helpers"

import { SplitScreenModeAction, isSplitScreenMode } from "~/Mode.reducer"
import { withRelayPageContainer } from "~/RelayPageContainer"
import { useAppSelector } from "~/hooks/redux"

import ModeMessageWarning from "./ModeMessageWarning"

const fragment = graphql`
  fragment ModeMessage_viewer on users
  @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
    account {
      id
      people(where: $peopleFilter) {
        id
        first_name
        last_name
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
        }
      }
      roles {
        id
        name
      }
      workstreams {
        id
        name
      }
    }
  }
`

const ModeMessage = ({ isSidePanelOpen }: { isSidePanelOpen?: boolean }) => {
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<ModeMessage_viewer$key>(fragment, viewerQuery)

  const { people, roles, workstreams } = viewer.account

  const isWorkstreamsEnabled = useFeature("workstreams")
  const multiSelect = useAppSelector((state) => state.multiSelect)
  const splitScreenListType = useAppSelector(
    (state) => state.plannerV2.splitScreen.listType,
  )
  const calendar = useAppSelector((state) => state.calendar)
  const transferEntirePlaceholder = useAppSelector(
    (state) => state.plannerV2.splitScreen.transferEntirePlaceholder,
  )
  const { modeAction, items, combinedMinutes, combinedMinutesLessTimeOff } =
    multiSelect
  const { calStartNum, calEndNum } = calendar
  const firstSelectedItem = items[0]

  const isAssignment =
    firstSelectedItem !== undefined &&
    determineItemType(firstSelectedItem) === "assignment"

  const workstreamName = isAssignment
    ? workstreams?.find(
        (w) => w.id === (firstSelectedItem as Assignment).workstream_id,
      )?.name
    : null

  const roleName = isAssignment
    ? roles.find((r) => r.id === (firstSelectedItem as Assignment).role_id).name
    : ""

  const hasItemsOutOfView = items.some(
    (i) =>
      Number(i.start_date) < calStartNum ||
      Number(i.end_date) < calStartNum ||
      Number(i.start_date) > calEndNum ||
      Number(i.end_date) > calEndNum,
  )

  const multiSelectPillType = getReadablePillType(firstSelectedItem)
  const splitScreenMode = isSplitScreenMode(modeAction)
  const notInViewMessage = "Selection is not fully in view"

  if (!items.length) {
    if (splitScreenMode) {
      return <>Please select an assignment(s) to {modeAction}</>
    }
    return <>No items selected</>
  }

  if (splitScreenMode) {
    const assignment = firstSelectedItem as Assignment // Can only be assignment in transfer mode

    const person = people.find((p) => p.id === assignment.person_id)
    const personName = formatName(person.first_name, person.last_name)

    const pillType = transferEntirePlaceholder
      ? "assignment"
      : multiSelectPillType
    const multiSelectAssignments = items as MultiSelectAssignments
    const pastMinutes = getPastMinutes(multiSelectAssignments)

    const presentAction = getPresentAction(modeAction as SplitScreenModeAction)

    return (
      <div className={styles.splitScreenMessage}>
        <div className={cc({ [styles.isSidePanelOpen]: isSidePanelOpen })}>
          <b>
            {items.length} {pluralize(items.length, pillType)}
          </b>
          <span>from</span>
          <b>{personName}</b>
          <span>|</span>
          <span>{roleName}</span>
          <span>|</span>
          {isWorkstreamsEnabled && (
            <>
              <span>{workstreamName}</span>
              <span>|</span>
            </>
          )}
          <span>
            {capitalize(presentAction)} {formatDuration(combinedMinutes)}
          </span>
        </div>
        {hasItemsOutOfView &&
          !(pastMinutes && splitScreenListType === "people") && (
            <ModeMessageWarning message={notInViewMessage} />
          )}

        {pastMinutes && splitScreenListType === "people" && (
          <div className={styles.pastMinutesWrapper}>
            <ModeMessageWarning
              message={`You are ${presentAction} ${formatDuration(pastMinutes)}
              from the past. These hours will be included in the project total
              hours.`}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <b>
      {hasItemsOutOfView && <ModeMessageWarning message={notInViewMessage} />}
      {items.length} {pluralize(items.length, multiSelectPillType)} selected.{" "}
      Total: {formatDuration(combinedMinutesLessTimeOff)}
    </b>
  )
}

export default withRelayPageContainer(ModeMessage)
