import React, { useState } from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import { PRICING_MODELS, PricingModelSelection } from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  value?: PricingModelSelection
}

const SelectPricingModel = (props: Props) => {
  const {
    onChange,
    defaultValue,
    value,
    label = "",
    name = "project-pricing-models",
    id = "project-pricing-models",
    ...rest
  } = props

  const [selectedPricingModel, setSelectedPricingModel] = useState(
    defaultValue || PRICING_MODELS[0],
  )

  const handleChange = (e) => {
    onChange(e)
    setSelectedPricingModel(e)
  }
  return (
    <Select
      name={name}
      id={id}
      value={value ?? selectedPricingModel}
      onChange={handleChange}
      label={label}
      options={PRICING_MODELS}
      placeholder="Select Pricing Model"
      isDisabled
      {...rest}
    />
  )
}

export default SelectPricingModel
