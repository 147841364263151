import cloneDeep from "lodash-es/cloneDeep"
import React from "react"

type LimitedQuerySection = {
  isOverflow: boolean
  limit: number
}

export type LimitedQueryState = {
  projects: LimitedQuerySection
  people: LimitedQuerySection
}

const defaultSectionState = {
  isOverflow: false,
  idList: [],
  limit: 0,
}
export const defaultLimitedQueryState = {
  projects: { ...defaultSectionState },
  people: { ...defaultSectionState },
}

export const LimitedQueryContext = React.createContext<LimitedQueryState>(
  cloneDeep(defaultLimitedQueryState),
)
