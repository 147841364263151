import { Tab } from "@blueprintjs/core"
import React, { useState } from "react"

import styles from "./CreateProject.module.css"

import { ModalFormWrapper } from "~/common/ModalForm"
import Tabs from "~/common/Tabs"

import ImportForm from "~/forms/ImportForm/ImportForm"

import CreateProjectForm, {
  ProjectReturn,
} from "../CreateProjectForm/CreateProjectForm"

import CreateBulkProjectsForm from "./CreateBulkProjectsForm"
import RocketTakeOffTangram from "./RocketTakeOffTangram"

type TabId = "single" | "bulk" | "import"

type Props = {
  selectedTab?: TabId
  onChangeTab?: (tabId: TabId) => void

  onClose?: () => void
  onSuccess?: (project: ProjectReturn) => void
  onLinkExtProject?: (options: {
    projectId: number
    extProjectId: number
  }) => void
  onOpenIntegration?: (options: { integrationId: number }) => void
}

const CreateProjectWizardModal = (props: Props) => {
  const {
    onSuccess,
    onClose,
    onLinkExtProject,
    onOpenIntegration,
    selectedTab,
    onChangeTab,
  } = props

  const [activateAnimation, setActivateAnimation] = useState(false)
  const [isDatePickerForm, setIsDatePickerForm] = useState(false)

  return (
    <ModalFormWrapper
      wide={!isDatePickerForm}
      tangram={<RocketTakeOffTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <Tabs
        large
        id="CrProjectTabs"
        renderActiveTabPanelOnly={true}
        className={isDatePickerForm ? styles.noTabs : null}
        selectedTabId={selectedTab}
        onChange={onChangeTab}
      >
        <Tab
          id="single"
          title={isDatePickerForm ? "Select Start Date" : "New Project"}
          panel={
            <CreateProjectForm
              setIsDatePickerForm={setIsDatePickerForm}
              onSuccess={onSuccess}
              activateAnimation={() => setActivateAnimation(true)}
              onClose={({ projectId, extProjectId }) => {
                if (
                  typeof projectId === "number" &&
                  typeof extProjectId === "number"
                ) {
                  // hide the create project form,
                  // but show the external project member form
                  onLinkExtProject({ projectId, extProjectId })
                } else {
                  onClose?.()
                }
              }}
            />
          }
          style={{ paddingBottom: "18px" }}
        />
        <Tab
          id="bulk"
          title="Bulk Add"
          hidden={isDatePickerForm}
          panel={
            <CreateBulkProjectsForm
              closeDialog={onClose}
              activateAnimation={() => setActivateAnimation(true)}
            />
          }
          style={{ paddingBottom: "18px" }}
        />
        <Tab
          id="import"
          title="Import"
          hidden={isDatePickerForm}
          panel={
            <ImportForm
              importType="projects"
              onClose={onClose}
              onOpenIntegration={onOpenIntegration}
            />
          }
          style={{ paddingBottom: "18px" }}
        />
      </Tabs>
    </ModalFormWrapper>
  )
}

export default CreateProjectWizardModal
