import React from "react"

import styles from "./ExternalProjectMemberForm.module.css"

import Input from "~/common/Input"
import { ModalBody } from "~/common/ModalForm"
import Select from "~/common/Select"
import Checkbox from "~/common/inputs/Checkbox"
import { ArrowRight as ArrowRightIcon } from "~/common/react-icons"

type Person = {
  id: number
  first_name: string
  last_name: string
  email: string
  role?: string
  role_id?: number
  checked?: boolean
}

export type SelectedExternalMember = {
  readonly id: number
  readonly ext_person: {
    readonly id: number
    readonly email: string | null
    readonly first_name: string
    readonly last_name: string
    readonly ext_person_links: readonly {
      readonly person_id: number
      readonly ext_person_id: number
      readonly active: boolean
      readonly external_id: number
    }[]
  }
}

export type SelectedExternalMembers = readonly SelectedExternalMember[]

export type Role = { readonly id: number; readonly name: string }

type Props = {
  extProjectMembers: SelectedExternalMembers
  extPersonToPersonMap: Record<string, Person>
  selectedExternalMembers: SelectedExternalMembers
  sortedPeople: Person[]
  roles: Role[]
  toggleAllMembers: (event: React.ChangeEvent<HTMLInputElement>) => void
  handlePersonCheckboxChange: (extPersonId: number) => void
  handleEmailChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    extPersonId: number,
  ) => void
  handleRunnPersonChange: (
    option: { label: string; value: number },
    extPersonId: number,
  ) => void
  handleRoleChange: (
    option: { label: string; value: number },
    extPersonId: number,
  ) => void
  getPersonValue: (
    extPersonId: number,
  ) => { label: string; value: any } | undefined
}

const ActivateExternalMembersForm = ({
  extProjectMembers,
  extPersonToPersonMap,
  sortedPeople,
  roles,
  toggleAllMembers,
  handlePersonCheckboxChange,
  handleEmailChange,
  handleRunnPersonChange,
  handleRoleChange,
  getPersonValue,
}: Props) => (
  <ModalBody style={{ maxWidth: "1200px" }}>
    <h5>Import people</h5>
    <p>
      The following people are assigned to the project in Jira. Would you like
      to import them and assign them to the project in Runn?
    </p>
    <table className={styles.tableContainer}>
      <thead className={styles.tableHeader}>
        <tr>
          <th>
            <Checkbox id="ext-project-member-all" onChange={toggleAllMembers} />
          </th>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>
            <ArrowRightIcon />
          </th>
          <th>Person in Runn</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        {extProjectMembers.map((member) => {
          const roleValue = extPersonToPersonMap[member.ext_person.id]?.role_id
          return (
            <tr key={member.id} className={styles.tableRow}>
              <td>
                <Checkbox
                  id={`ext-project-member-${member.ext_person.id}`}
                  checked={extPersonToPersonMap[member.ext_person.id]?.checked}
                  onChange={() =>
                    handlePersonCheckboxChange(member.ext_person.id)
                  }
                />
              </td>
              <td>{member.ext_person.id}</td>
              <td>
                {member.ext_person.first_name} {member.ext_person.last_name}
              </td>
              <td>
                {member.ext_person.email ? (
                  member.ext_person.email
                ) : (
                  <Input
                    name={`person-${member.ext_person.id}-email`}
                    id={`person-${member.ext_person.id}-email`}
                    label=""
                    style={{ width: "180px", margin: 0 }}
                    value={member.ext_person.email}
                    onChange={(e) => handleEmailChange(e, member.ext_person.id)}
                  />
                )}
              </td>
              <td>
                <ArrowRightIcon />
              </td>
              <td>
                {extPersonToPersonMap[member.ext_person.id]?.checked ? (
                  <Select
                    id={`person-${member.ext_person.id}`}
                    options={[
                      { label: "Create new", value: null },
                      ...sortedPeople.map((person) => ({
                        label: `${person.first_name} ${person.last_name}`,
                        value: person.id,
                      })),
                    ]}
                    onChange={(option) =>
                      handleRunnPersonChange(option, member.ext_person.id)
                    }
                    width="180px"
                    value={getPersonValue(member.ext_person.id)}
                    doNotSortOptions
                  />
                ) : (
                  <span className={styles.uncheckedText}>Skip</span>
                )}
              </td>
              <td>
                <td>
                  {extPersonToPersonMap[member.ext_person.id]?.checked && (
                    <Select
                      id={`role-${member.ext_person.id}`}
                      required={true}
                      controlStyles={{
                        backgroundColor: !roleValue && "var(--alert-light)",
                        border: !roleValue && "1px solid var(--alert)",
                      }}
                      options={roles.map((role) => ({
                        label: role.name,
                        value: role.id,
                      }))}
                      value={{
                        label: extPersonToPersonMap[member.ext_person.id]?.role,
                        value: roleValue,
                      }}
                      width="180px"
                      onChange={(option) =>
                        handleRoleChange(option, member.ext_person.id)
                      }
                    />
                  )}
                </td>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </ModalBody>
)

export default ActivateExternalMembersForm
