/**
 * @generated SignedSource<<9387a0e0b1fcbd31113c73c48c23af04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsPersonRow_project$data = {
  readonly client: {
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly name: string;
    readonly website: string | null | undefined;
  };
  readonly confirmed: boolean;
  readonly emoji: string | null | undefined;
  readonly id: number;
  readonly is_template: boolean;
  readonly members: ReadonlyArray<{
    readonly id: number;
    readonly person_id: number;
    readonly role: {
      readonly id: number;
      readonly name: string | null | undefined;
    };
    readonly role_id: number;
    readonly workstream: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly name: string;
  readonly phases: ReadonlyArray<{
    readonly color: string;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly name: string;
    readonly start_date: string | null | undefined;
  }>;
  readonly pricing_model: string;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_project">;
  readonly " $fragmentType": "ProjectDetailsPersonRow_project";
};
export type ProjectDetailsPersonRow_project$key = {
  readonly " $data"?: ProjectDetailsPersonRow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsPersonRow_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "phases",
      "kind": "LinkedField",
      "name": "phases",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstream",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "d764ef214636db6e8c4b0a45a43b79e8";

export default node;
