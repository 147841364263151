/**
 * @generated SignedSource<<9360aba687f7ca668e1e591e43c37a6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectForm_viewer$data = {
  readonly account: {
    readonly billing_method: string;
    readonly clients: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly internal: boolean | null | undefined;
      readonly name: string;
      readonly real_client: boolean | null | undefined;
      readonly website: string | null | undefined;
    }>;
    readonly ext_projects: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly id: number;
    readonly projectTemplates: ReadonlyArray<{
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"TemplateNameUniqueInput_project" | "TemplateSelector_project">;
    }>;
    readonly project_tags: ReadonlyArray<{
      readonly archived: boolean;
      readonly id: number;
      readonly name: string;
    }>;
    readonly rate_cards: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly teams: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly views: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
      readonly project_filters: any;
    }>;
    readonly workstreams: ReadonlyArray<{
      readonly archived: boolean;
      readonly id: number;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"CustomFieldSectionHeaderProject_account" | "TeamSelector_account">;
  };
  readonly first_name: string | null | undefined;
  readonly id: number;
  readonly last_name: string | null | undefined;
  readonly permissions: any;
  readonly view: {
    readonly id: number;
    readonly name: string;
    readonly project_filters: any;
  } | null | undefined;
  readonly " $fragmentType": "ProjectForm_viewer";
};
export type ProjectForm_viewer$key = {
  readonly " $data"?: ProjectForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectForm_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "project_filters",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archived",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectForm_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billing_method",
          "storageKey": null
        },
        {
          "alias": "project_tags",
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "model": {
                  "_eq": "project"
                }
              }
            }
          ],
          "concreteType": "tags",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": "tags(where:{\"model\":{\"_eq\":\"project\"}})"
        },
        {
          "alias": "projectTemplates",
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "is_template": {
                  "_eq": true
                }
              }
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TemplateSelector_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TemplateNameUniqueInput_project"
            }
          ],
          "storageKey": "projects(where:{\"is_template\":{\"_eq\":true}})"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "rate_cards",
          "kind": "LinkedField",
          "name": "rate_cards",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "clients",
          "kind": "LinkedField",
          "name": "clients",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image_key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "real_client",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ext_project",
          "kind": "LinkedField",
          "name": "ext_projects",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "teams",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstreams",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TeamSelector_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CustomFieldSectionHeaderProject_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "9ecb9d2b23111e2b1e04a5e6c2c85672";

export default node;
