import * as fe from "@runn/filter-engine"
import React from "react"

import FilterBlockText from "./common/FilterBlockText"
import type { FilterComponentProps } from "~/common/SuperSearch/types"
import { FilterIcon as Icon } from "~/common/react-icons"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

type BaseProps = {
  filter: fe.filters.FilterProjectCustomText
}

type Props = FilterComponentProps & BaseProps

const Component = (props: Props) => {
  const {
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (
    query: string,
    searchType: fe.filters.TextSearchType,
  ) => {
    onChange(
      path,
      fe.filters.projectCustomText({
        name: filter.options.name,
        typeId: filter.options.typeId,
        query: query,
        searchType,
      }),
    )
  }

  const handleRemove = () => onRemove(path)

  return (
    <FilterBlockText
      icon={<Icon />}
      label={filter.options.name}
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      query={filter.options.query}
      searchType={filter.options.searchType}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { name, query } = props.filter.options

  return (
    <FilterBlockReadonly name={name} selectedOptions={[{ label: query }]} />
  )
}

export const ProjectCustomText = {
  Component,
  ReadonlyComponent,
}
