import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./SchedulePanels.module.css"

import { ProjectSchedulePanel_user$key } from "./__generated__/ProjectSchedulePanel_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import ResizablePanel from "~/Planner/ResizablePanel"
import PhasesWrapper from "~/ProjectPlanner/Phases/PhasesWrapper"
import ProjectAddPersonRow from "~/ProjectPlanner/ProjectRow/ProjectAddPersonRow"
import ProjectDetailsSection from "~/ProjectPlanner/ProjectRow/ProjectDetailsSection"
import ProjectStatsSection from "~/ProjectPlanner/ProjectRow/ProjectStatsSection"
import ProjectRowSummary from "~/ProjectPlanner/ProjectRowSummary"
import { useAppSelector } from "~/hooks/redux"

import SplitRow from "../PlannerLayout/SplitRow"

const fragment = graphql`
  fragment ProjectSchedulePanel_user on users
  @argumentDefinitions(
    todaysDate: { type: "date!" }
    plannerStartDate: { type: "date!" }
    peopleFilter: { type: "people_bool_exp" }
    projectsFilter: { type: "projects_bool_exp" }
  ) {
    ...ProjectDetailsSection_user
    favouriteProjectIds: favourite_projects
    userId: id
    account {
      ...ProjectRowSummary_account
      ...ProjectAddPersonRow_account
        @arguments(
          projectsFilter: $projectsFilter
          plannerStartDate: $plannerStartDate
          peopleFilter: $peopleFilter
        )
      ...ProjectDetailsSection_account
        @arguments(
          peopleFilter: $peopleFilter
          plannerStartDate: $plannerStartDate
        )
      ...AddPersonToProject_account
        @arguments(
          projectsFilter: $projectsFilter
          plannerStartDate: $plannerStartDate
        )
      projects(where: $projectsFilter) {
        id
        ...ProjectAddPersonRow_project
        ...ProjectDetailsSection_project
        ...ProjectRowSummary_project @arguments(todaysDate: $todaysDate)
        ...PhasesWrapper_project
      }
    }
  }
`

const ProjectSchedulePanel = () => {
  const data = useFragment<ProjectSchedulePanel_user$key>(
    fragment,
    useHasuraContext(),
  )
  const selectedProjectId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedProjectId,
  )

  const { favouriteProjectIds, account, userId } = data
  const project = account.projects.find((p) => p.id === selectedProjectId)

  return (
    <ResizablePanel minHeight={150} maxHeight={800} isScrollable>
      <div className={styles.schedulePanels}>
        <ProjectRowSummary
          userId={userId}
          project={project}
          account={account}
          projectExpanded={true}
          handleToggleRow={null}
          favourites={favouriteProjectIds}
          locatedInSchedulePanel
        />
        <PhasesWrapper project={project} />
        <ProjectStatsSection projectId={project.id} />
        <ProjectDetailsSection
          user={data}
          account={account}
          projectId={project.id}
          project={project}
          locatedInSchedulePanel
        />
        <ProjectAddPersonRow account={account} project={project} />
        <SplitRow />
      </div>
    </ResizablePanel>
  )
}

export { ProjectSchedulePanel }
