import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectStatusRow.module.css"

import { PersonDetails_person$data } from "./__generated__/PersonDetails_person.graphql"
import { ProjectStatusRow_person$key } from "./__generated__/ProjectStatusRow_person.graphql"

import OutOfContractCurtains from "~/common/OutOfContractCurtains/OutOfContractCurtains"
import TimeOffCurtain from "~/common/TimeOffCurtain/TimeOffCurtain"
import CalendarOutline from "~/common/calendar/CalendarOutline"

import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"

type Props = {
  projectStatus: string
  person: ProjectStatusRow_person$key
  timeOffs: PersonDetails_person$data["time_offs"]
  holidaysOverlappingTimeOffs?: string[]
}

const ProjectStatusRow = (props: Props) => {
  const { projectStatus, timeOffs, holidaysOverlappingTimeOffs } = props

  const person = useFragment(
    graphql`
      fragment ProjectStatusRow_person on people {
        id
        account {
          id
          default_full_time_minutes
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
        }
      }
    `,
    props.person,
  )

  const nonHolidayTimeOffs = timeOffs.filter(
    (to) => to.leave_type !== "holiday",
  )

  return (
    <>
      <PlannerGrid gridRow100>
        <PlannerLeftColumn paddingHeight={5} className={styles.sidebar}>
          {projectStatus}
        </PlannerLeftColumn>
        <PlannerRightColumn showHighlight={false}>
          <CalendarOutline
            type="standard"
            timeOffs={timeOffs}
            holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          />
          {nonHolidayTimeOffs.map((to) => (
            <TimeOffCurtain
              timeOff={to}
              key={to.id}
              contracts={person.contracts}
              accountDefaultFullTimeMinutes={
                person.account.default_full_time_minutes
              }
            />
          ))}
          {!!person.contracts.length && (
            <OutOfContractCurtains contracts={person.contracts} />
          )}
        </PlannerRightColumn>
      </PlannerGrid>
    </>
  )
}

export default ProjectStatusRow
