import React from "react"
import { graphql, useFragment } from "react-relay"

import { PersonTagSelector_user$key } from "~/Planner/__generated__/PersonTagSelector_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { AllowedPeopleFilter } from "~/helpers/filter-engine"

import { GroupedOption, Option } from "~/common/Select"
import { toOption } from "~/common/SelectorModal/SelectorHelpers"

import TagSelector, { TagSelectorProps } from "~/Planner/TagSelector"

type Props = Omit<TagSelectorProps, "options"> & {
  formatSelectOptions?: (
    options,
    filterType: AllowedPeopleFilter["type"],
  ) => Option[] | GroupedOption[]
}

const PersonTagSelector = (props: Props) => {
  const { formatSelectOptions } = props
  const person = useFragment<PersonTagSelector_user$key>(
    graphql`
      fragment PersonTagSelector_user on users {
        id
        account {
          id
          people_tags: tags(where: { model: { _eq: "person" } }) {
            id
            name
            model
            archived
          }
        }
      }
    `,
    useHasuraContext(),
  )

  const tagsOptions = person.account.people_tags
    .filter((tag) => !tag.archived)
    .map(toOption)

  const formattedOptions = formatSelectOptions
    ? formatSelectOptions(tagsOptions, "person_tag_id")
    : tagsOptions

  return <TagSelector options={formattedOptions} {...props} />
}

export default PersonTagSelector
