import { Icon, Menu, MenuDivider, Tooltip } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { Suspense, useState } from "react"
import { graphql, useFragment } from "react-relay"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { PersonPlannerMenu_person$key } from "./__generated__/PersonPlannerMenu_person.graphql"
import { PersonPlannerMenu_project$key } from "./__generated__/PersonPlannerMenu_project.graphql"
import { PersonPlannerMenu_user$key } from "./__generated__/PersonPlannerMenu_user.graphql"
import { PersonSummaryRow_person$data } from "~/PeoplePlanner/PersonSummaryRow/__generated__/PersonSummaryRow_person.graphql"
import { EditPersonForm_person$key } from "~/forms/EditPersonForm/__generated__/EditPersonForm_person.graphql"

import { PERSON_ARCHIVE_HINT, PERSON_DELETE_HINT } from "../../helpers/hints"
import { track } from "~/helpers/analytics"
import * as hashids from "~/helpers/hashids"
import { formatName } from "~/helpers/person"
import { viewPersonUrl } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import Loading from "~/common/Loading"
import MenuItem from "~/common/MenuItem"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import { WorkstreamIcon } from "~/common/react-icons"
import { DashboardIcon, PersonCircleIcon } from "~/common/react-icons"

import { useDeletePersonMutation } from "~/mutations/useDeletePersonMutation"

import {
  useEntitlementSwitch,
  useIsInFreePlan,
} from "~/Entitlements/useEntitlements"
import ArchiveModal from "~/Modals/ArchiveModal"
import DeleteModal from "~/Modals/Delete/DeleteModal"
import { usePermissions } from "~/Permissions/usePermissions"
import ChangeWorkstreamModal from "~/Planner/PlannerLayout/ChangeWorkstreamModal"
import { Account } from "~/Planner/PlannerLayout/PlannerLeftColumn"
import UserModal from "~/Users/UserModal"
import { Mode } from "~/Users/userForm"
import { showToast } from "~/containers/ToasterContainer"
import EditPersonForm from "~/forms/EditPersonForm/EditPersonForm"
import InviteUserForm from "~/forms/UserForms/InviteUserForm"
import { useAppSelector } from "~/hooks/redux"

import InvitationMenu from "../InvitationMenu"
import PersonSidePanel from "../PersonSidePanel/PersonSidePanel"

import { ChangeProjectRoleModal } from "./ChangeProjectRoleModal/ChangeProjectRoleModal"
import { PersonMenuType } from "./ContextMenu"
import { RemoveMembershipFromProjectMenuItem } from "./RemoveMembershipFromProjectMenuItem"

type Props = {
  user: PersonPlannerMenu_user$key
  person: PersonPlannerMenu_person$key
  project: PersonPlannerMenu_project$key
  /** @deprecated */
  personLegacy: PersonSummaryRow_person$data
  /**@deprecated */
  personQuery: EditPersonForm_person$key
  /** @deprecated */
  accountLegacy: Account
  roleId?: number
  workstreamId: number
  viewPerson: (e) => void
  closeMenu: () => void
  locatedInSchedulePanel: boolean
  onDeleteConflicts?: (person: PersonSummaryRow_person$data) => void
  availableMenuItems: PersonMenuType
}

const DIALOG_TYPE = {
  ARCHIVE: "ARCHIVE",
  DELETE: "DELETE",
  EDIT: "EDIT",
  INVITE: "INVITE",
  CHANGE_ROLE: "CHANGE_ROLE",
  CHANGE_WORKSTREAM: "CHANGE_WORKSTREAM",
}

const MENU_ITEM_TYPE = {
  ...DIALOG_TYPE,
  VIEW_SCHEDULE: "VIEW_SCHEDULE",
  HIDE_SCHEDULE: "HIDE_SCHEDULE",
  OPEN_DASHBOARD: "OPEN_DASHBOARD",
  VIEW_DETAILS: "VIEW_DETAILS",
  NOTES: "NOTES",
}

const MENU_ITEM_TEXT = {
  [MENU_ITEM_TYPE.VIEW_SCHEDULE]: "View Schedule",
  [MENU_ITEM_TYPE.HIDE_SCHEDULE]: "Hide Schedule",
  [MENU_ITEM_TYPE.OPEN_DASHBOARD]: "Open Dashboard",
  [MENU_ITEM_TYPE.VIEW_DETAILS]: "View Details",
  [MENU_ITEM_TYPE.EDIT]: "Edit Details",
  [MENU_ITEM_TYPE.ARCHIVE]: "Archive",
  [MENU_ITEM_TYPE.DELETE]: "Delete",
  [MENU_ITEM_TYPE.CHANGE_ROLE]: "Change Project Role",
  [MENU_ITEM_TYPE.CHANGE_WORKSTREAM]: "Change Workstream",
}

const PersonPlannerMenu = (props: Props) => {
  const {
    personLegacy,
    roleId,
    workstreamId,
    personQuery,
    viewPerson,
    closeMenu,
    locatedInSchedulePanel,
    availableMenuItems,
    accountLegacy,
  } = props

  const user = useFragment(
    graphql`
      fragment PersonPlannerMenu_user on users {
        id
        ...PersonSidePanel_user
        ...EditPersonForm_user
        account {
          id
          roles {
            id
            ...ChangeProjectRoleModal_roles
          }
        }
      }
    `,
    props.user,
  )

  const person = useFragment(
    graphql`
      fragment PersonPlannerMenu_person on people {
        id
        project_memberships {
          id
          role_id
          project_id
          workstream_id
          has_actuals
        }
        people_notes_aggregate {
          aggregate {
            count
          }
        }
        ...ChangeProjectRoleModal_person
        ...ChangeWorkstreamModal_person
        ...PersonSidePanel_person
      }
    `,
    props.person,
  )

  const project = useFragment(
    graphql`
      fragment PersonPlannerMenu_project on projects {
        id
        name
        project_workstreams {
          workstream_id
        }
        ...ChangeProjectRoleModal_project
        ...RemoveMembershipFromProjectMenuItem_project
        ...ChangeWorkstreamModal_project
      }
    `,
    props.project,
  )

  const {
    canViewDashboard,
    canEditDetails,
    canViewDetails,
    canRemoveMembershipFromProject,
    canInvite,
    canChangeWorkstream,
    canChangeProjectRole,
    canArchive,
    canDelete,
  } = availableMenuItems

  const showPersonSidePanel = canViewDetails && user

  const { isAdminWithManageAccount } = usePermissions()

  const personName = formatName(personLegacy.first_name, personLegacy.last_name)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogType, setDialogType] = useState<keyof typeof DIALOG_TYPE>(null)

  const openDialog = (selectedDialogType) => {
    setShowDialog(true)
    setDialogType(selectedDialogType)
  }

  const { openPanel, closePanel } = useSidePanel()
  const isWorkstreamsEnabled = useFeature("workstreams")
  const isEntitlementsEnabled = useFeature("subscription_entitlements")
  const permissionsUIEnabled = useFeature("permissions_ui")
  const entitledToWorkstreams = useEntitlementSwitch("workstreams")

  const isInFreePlan = useIsInFreePlan()

  const onViewDetails = () => {
    track("Person Side Panel Opened")
    openPanel(<PersonSidePanel user={user} person={person} />)
  }

  const onEditDetails = () => {
    openPanel(
      <EditPersonForm
        personQuery={personQuery}
        user={user}
        onClose={() => closePanel()}
        onSaveClose={() =>
          openPanel(<PersonSidePanel user={user} person={person} />)
        }
        location="planner"
      />,
    )
    closeMenu()
  }

  const onViewNotes = () => {
    track("Person Side Panel Opened")
    openPanel(
      <PersonSidePanel user={user} person={person} initialTab="notes" />,
    )
  }
  const notesCount = person.people_notes_aggregate?.aggregate.count

  const handleCloseDialog = () => {
    setShowDialog(false)
    closeMenu()
  }

  const hashid = hashids.people.encode(personLegacy.id)

  const schedulePanelPersonId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedPersonId,
  )

  const [deletePerson] = useDeletePersonMutation()

  const handleDelete = async () => {
    try {
      await deletePerson({ personId: personLegacy.id })
      showToast({
        message: `${personName} has been deleted`,
        type: "success",
      })
    } catch (error: unknown) {
      showToast({
        message: `Failed to delete "${personName}".`,
        description: error instanceof Error ? error.message : undefined,
        type: "error",
      })
    }
    closePanel()
  }

  const canShowProjectMembershipRemoval = !!project && !!roleId
  const projectMembership = person.project_memberships?.find(
    (pm) =>
      pm.role_id === roleId &&
      pm.project_id === project.id &&
      pm.workstream_id === workstreamId,
  )
  const personHasActuals = projectMembership?.has_actuals ?? false

  const renderDialog = () => {
    switch (dialogType) {
      case "ARCHIVE":
        return (
          <ArchiveModal
            type="person"
            name={`${personLegacy.first_name} ${personLegacy.last_name}`}
            closeDialog={handleCloseDialog}
            customMessage={!personLegacy.archivable ? PERSON_ARCHIVE_HINT : ""}
            archivable={personLegacy.archivable}
            hashid={hashid}
            id={personLegacy.id}
            active={personLegacy.active}
            inPlanner={true}
          />
        )
      case "DELETE":
        return (
          <DeleteModal
            type="person"
            name={personName}
            isAdmin={isAdminWithManageAccount}
            adminCanDelete
            deletable={
              personLegacy.assignments_aggregate.aggregate.count === 0 &&
              personLegacy.actuals_aggregate.aggregate.count === 0
            }
            closeDialog={handleCloseDialog}
            onSubmit={handleDelete}
            hint={PERSON_DELETE_HINT}
          />
        )
      case "INVITE":
        return permissionsUIEnabled ? (
          <Suspense fallback={<Loading delayMs={50} embed />}>
            <UserModal
              mode={Mode.INVITE}
              email={personLegacy.email}
              onClose={handleCloseDialog}
            />
          </Suspense>
        ) : (
          <InviteUserForm
            email={personLegacy.email}
            closeDialog={handleCloseDialog}
          />
        )
      case "CHANGE_ROLE":
        return (
          <ChangeProjectRoleModal
            currentRoleId={roleId}
            roles={user.account.roles}
            person={person}
            project={project}
            workstreamId={workstreamId}
            closeDialog={handleCloseDialog}
          />
        )
      case "CHANGE_WORKSTREAM":
        return (
          <ChangeWorkstreamModal
            person={person}
            workstreamId={workstreamId}
            project={project}
            closeDialog={handleCloseDialog}
          />
        )
      default:
        break
    }
  }

  const handleMenuItemClick = (menuType: keyof typeof MENU_ITEM_TYPE, e?) => {
    track("Person Context Menu Item Selected", {
      item: MENU_ITEM_TEXT[menuType],
    })

    switch (menuType) {
      case "VIEW_SCHEDULE":
      case "HIDE_SCHEDULE":
        viewPerson(e)
        break
      case "OPEN_DASHBOARD":
        return true // Return true to execute the href
      case "VIEW_DETAILS":
        onViewDetails()
        break
      case "EDIT":
        onEditDetails()
        break
      case "INVITE":
      case "ARCHIVE":
      case "DELETE":
      case "CHANGE_ROLE":
      case "CHANGE_WORKSTREAM":
        openDialog(menuType)
        break
      case "NOTES":
        onViewNotes()
        break
      default:
        break
    }
  }

  /** This helps figure out where to render the workstream menu item. It's placement
   * depends on a couple of feature flags, an entitlement check, whether they are
   * currently on a plan, _and_ whether they are on the free plan. Jeez.
   * */
  const shouldRenderWorkstreams =
    isWorkstreamsEnabled &&
    ((Boolean(project && project.project_workstreams.length) &&
      entitledToWorkstreams &&
      canChangeProjectRole) ||
      isInFreePlan)

  /** If the account can access workstreams, render the workstream menu up the top when
   *  - the subscription entitlement flag is off
   *  - they are entitled to workstreams and not on the free plan
   * */
  const shouldRenderWorkstreamsUpTop =
    shouldRenderWorkstreams &&
    (!isEntitlementsEnabled || (isEntitlementsEnabled && !isInFreePlan))

  /** If the account can access workstreams, render the workstream menu down the bottom
   *  when
   *  - the subscription entitlement flag is on
   *  - they are not entitled to workstreams and on the free plan
   *
   * The paid feature plan container deals with checking whether the current account
   * is on the free plan so we don't have to do that check here
   * */
  const shouldRenderWorkstreamsDownBottom =
    !shouldRenderWorkstreamsUpTop && shouldRenderWorkstreams

  return (
    <>
      <Menu className={dropdownStyles.dropdown} style={{ padding: 0 }}>
        {canEditDetails && (
          <MenuItem
            text={MENU_ITEM_TEXT.EDIT}
            icon={<Icon icon="cog" />}
            onClick={() => handleMenuItemClick("EDIT")}
            shouldDismissPopover={false}
          />
        )}
        <MenuDivider />
        {showPersonSidePanel && (
          <MenuItem
            text={MENU_ITEM_TEXT.VIEW_DETAILS}
            icon={<Icon icon="list-detail-view" />}
            onClick={() => handleMenuItemClick("VIEW_DETAILS")}
          />
        )}
        {!locatedInSchedulePanel && (
          <MenuItem
            text={
              schedulePanelPersonId === personLegacy.id
                ? MENU_ITEM_TEXT.HIDE_SCHEDULE
                : MENU_ITEM_TEXT.VIEW_SCHEDULE
            }
            icon={<Icon icon="horizontal-bar-chart-asc" />}
            onClick={(e) =>
              handleMenuItemClick(
                schedulePanelPersonId === personLegacy.id
                  ? "HIDE_SCHEDULE"
                  : "VIEW_SCHEDULE",
                e,
              )
            }
          />
        )}
        {canViewDashboard && (
          <MenuItem
            text={MENU_ITEM_TEXT.OPEN_DASHBOARD}
            icon={<DashboardIcon />}
            onClick={() => handleMenuItemClick("OPEN_DASHBOARD")}
            // Use href so users have the ability to Right Click -> Open Link in new Tab
            href={viewPersonUrl(hashid)}
          />
        )}
        {showPersonSidePanel && (
          <MenuItem
            text={notesCount ? `Notes (${notesCount})` : "Notes"}
            icon={<Icon icon="comment" />}
            onClick={() => handleMenuItemClick("NOTES")}
            shouldDismissPopover
          />
        )}
        <MenuDivider />
        {project && roleId && canChangeProjectRole && (
          <MenuItem
            text={MENU_ITEM_TEXT.CHANGE_ROLE}
            icon={<PersonCircleIcon color={"var(--midnight)"} />}
            onClick={() => handleMenuItemClick("CHANGE_ROLE")}
            shouldDismissPopover={false}
          />
        )}
        {shouldRenderWorkstreamsUpTop && canEditDetails && (
          <>
            <MenuItem
              text={MENU_ITEM_TEXT.CHANGE_WORKSTREAM}
              icon={<WorkstreamIcon color={"var(--midnight)"} />}
              onClick={() => handleMenuItemClick("CHANGE_WORKSTREAM")}
              shouldDismissPopover={false}
              disabled={isInFreePlan || !canChangeWorkstream}
            />
            <MenuDivider />
          </>
        )}{" "}
        {shouldRenderWorkstreamsDownBottom && canEditDetails && (
          <PaidFeatureMenuItemContainer roundedButton>
            <>
              <Tooltip
                content="Cannot change workstreams when a person has actuals"
                usePortal
                position="top"
                disabled={!personHasActuals}
              >
                <MenuItem
                  text={MENU_ITEM_TEXT.CHANGE_WORKSTREAM}
                  icon={
                    <WorkstreamIcon
                      color={
                        personHasActuals
                          ? "rgba(var(--midnight_rgb), 0.6)"
                          : "var(--midnight)"
                      }
                    />
                  }
                  onClick={() => handleMenuItemClick("CHANGE_WORKSTREAM")}
                  shouldDismissPopover={false}
                  disabled={
                    personHasActuals || isInFreePlan || !canChangeWorkstream
                  }
                />
              </Tooltip>
            </>
          </PaidFeatureMenuItemContainer>
        )}
        {canInvite && (
          <InvitationMenu
            person={personLegacy}
            account={accountLegacy}
            handleMenuClick={() => handleMenuItemClick("INVITE")}
          />
        )}
        <MenuDivider />
        {canShowProjectMembershipRemoval && canRemoveMembershipFromProject ? (
          <RemoveMembershipFromProjectMenuItem
            person={personLegacy}
            project={project}
            roleId={roleId}
            workstreamId={workstreamId}
          />
        ) : null}
        {canArchive && !Boolean(project) && (
          <MenuItem
            text={MENU_ITEM_TEXT.ARCHIVE}
            icon={<Icon icon="archive" />}
            onClick={() => handleMenuItemClick("ARCHIVE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
        {canDelete && !Boolean(project) && (
          <MenuItem
            text={MENU_ITEM_TEXT.DELETE}
            icon={<Icon icon="trash" />}
            onClick={() => handleMenuItemClick("DELETE")}
            shouldDismissPopover={false}
            hidden={locatedInSchedulePanel}
          />
        )}
      </Menu>
      {showDialog && (
        <Dialog isOpen={showDialog} onClose={handleCloseDialog}>
          {renderDialog()}
        </Dialog>
      )}
    </>
  )
}

export default PersonPlannerMenu
