import { dateHelpers } from "@runn/calculations"

import * as relayids from "~/helpers/relayids"

import type { TransformFieldFn } from "~/subscriptions/AuditLogSubscription/types"

const id = (plural: keyof typeof relayids) => {
  const fn: TransformFieldFn<{ id: number }> = (row) => [
    { key: "id", value: row.id },
  ]
  return fn
}

const date: TransformFieldFn<{ date: string }> = (row) => [
  {
    key: "date_runn",
    value: dateHelpers.formatDatabaseDateToRunnDate(row.date),
  },
]

const startDate: TransformFieldFn<{ start_date: string }> = (row) => [
  {
    key: "start_date_runn",
    value: dateHelpers.formatDatabaseDateToRunnDate(row.start_date),
  },
]

const endDate: TransformFieldFn<{ end_date: string }> = (row) => [
  {
    key: "end_date_runn",
    value: dateHelpers.formatDatabaseDateToRunnDate(row.end_date),
  },
]

export { id, date, endDate, startDate }
