import React from "react"
import { graphql, useFragment } from "react-relay"
import { MultiValueGenericProps, components } from "react-select"

import styles from "./SkillsSelector.module.css"

import { SkillsSelector_viewer$key } from "./__generated__/SkillsSelector_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { AllowedPeopleFilter } from "~/helpers/filter-engine"
import { dashify } from "~/helpers/general-helpers"

import Select, { GroupedOption, Option } from "~/common/Select"
import { CompetencyIcon } from "~/common/react-icons"

import { MAX_SKILL_NAME_LENGTH } from "~/GLOBALVARS"
import { usePermissions } from "~/Permissions/usePermissions"
import CompetencyDropdown from "~/Skills/Competency/CompetencyDropdown"

type Props = {
  selectedSkills: SkillOption[]
  setSelectedSkills: (updatedSkills: SkillOption[]) => void
  tabIndex?: number
  formatSelectOptions?: (
    options,
    filterType: AllowedPeopleFilter["type"],
  ) => Option[] | GroupedOption[]
}

export type SkillOption = {
  value: number
  label: string
  level: number
  __isNew__?: boolean
}

const fragment = graphql`
  fragment SkillsSelector_viewer on users {
    permissions
    account {
      id
      allSkills: skills {
        id
        name
      }
    }
  }
`

const SkillsSelector = (props: Props) => {
  const { selectedSkills, setSelectedSkills, formatSelectOptions, ...rest } =
    props
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<SkillsSelector_viewer$key>(fragment, viewerQuery)
  const { can, subject } = usePermissions()

  const { allSkills } = viewer.account

  const handleOnChange = (value: SkillOption[]) => {
    setSelectedSkills(value)
  }

  const skillOptions: SkillOption[] = allSkills.map((s) => {
    return {
      value: s.id,
      label: s.name,
      level: null,
    }
  })

  const MultiValueLabel = (labelProps: MultiValueGenericProps) => {
    const { label, level, value } = labelProps.data

    const changeCompetencyLevel = (updatedLevel) => {
      const updatedSkills = selectedSkills.map((s) => {
        if (s.value === value && s.level !== updatedLevel) {
          return { ...s, level: updatedLevel }
        }
        return s
      })

      setSelectedSkills(updatedSkills)
    }

    return (
      <components.MultiValueLabel
        {...labelProps}
        innerProps={{ className: styles.multiValueLabelWrapper }}
      >
        <div
          // This prevents Select menu from opening everytime we click on icon
          onMouseDown={(e) => e.stopPropagation()}
        >
          <CompetencyDropdown
            onSelectLevel={changeCompetencyLevel}
            defaultLevel={level}
          >
            <div className={styles.competencyTarget}>
              <div className={styles.skillLabel}>{label}</div>
              <div className={styles.divider} />
              <div
                data-test={`${dashify(label)}-competency-button`}
                className={styles.competencyIconWrapper}
              >
                <CompetencyIcon level={level} />
              </div>
              <div className={styles.divider} />
            </div>
          </CompetencyDropdown>
        </div>
      </components.MultiValueLabel>
    )
  }

  const formattedOptions = formatSelectOptions
    ? formatSelectOptions(skillOptions, "person_skill_id")
    : skillOptions

  const canCreateSkill = can("create", subject("Skill"))
  return (
    <Select
      id="skills-selector"
      label="Skills (optional)"
      isCreatable={canCreateSkill}
      isMulti
      onChange={handleOnChange}
      options={formattedOptions}
      value={selectedSkills}
      placeholder="Select or type to create new..."
      components={{ MultiValueLabel }}
      isClearable={false}
      createOptionPosition="first"
      maxLabelLength={MAX_SKILL_NAME_LENGTH}
      noOptionsMessage={() =>
        `No options. ${canCreateSkill ? "Type to create a new skill" : ""}`
      }
      {...rest}
    />
  )
}

export default SkillsSelector
