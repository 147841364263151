import { Icon } from "@blueprintjs/core"
import { format } from "date-fns"
import { useFeature } from "flagged"
import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./TestAccountActions.module.css"

import { TestAccountActionsQuery } from "./__generated__/TestAccountActionsQuery.graphql"

import { isAltAccount } from "~/helpers/general-helpers"
import { toggleTestAccount } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import Button from "~/common/buttons/Button"
import { AddCircle, CloudDownload, InfoCircle } from "~/common/react-icons"

import CopyFromLiveModal from "~/Account/Modal/CopyFromLiveModal"
import DeleteTestAccountDataModal from "~/Account/Modal/DeleteTestAccountDataModal"
import ResetTestAccountModal from "~/Account/Modal/ResetTestAccountModal"
import LoadingSeedDataModal from "~/Planner/LoadingSeedDataModal/LoadingSeedDataModal"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import { Card, CardHeader } from "~/dashboard"
import AutofillTimesheetsForm from "~/forms/AccountForms/AutofillTimesheetsForm"

const TEST_ACCOUNT_ACTIONS_QUERY = graphql`
  query TestAccountActionsQuery($accountId: Int!) {
    account: accounts_by_pk(id: $accountId) {
      id
      account_type
      last_copied
      alternative_account {
        id
        account_type
      }
    }
  }
`

type Props = { accountId: number }

const TestAccountActions = (props: Props) => {
  const { accountId } = props
  const iscopyFromLiveDisabled = !!useFeature("disable_copy_live_account")
  const { account } = useLazyLoadQuery<TestAccountActionsQuery>(
    TEST_ACCOUNT_ACTIONS_QUERY,
    { accountId },
  )

  const [copyDialogOpen, setCopyDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [deleteDialogIsOpen, setDeleteDialogOpen] = useState(false)
  const [showAutofillModal, setShowAutofillModal] = useState(false)

  const handleOpenCopyDialog = () => {
    setCopyDialogOpen(true)
  }
  const handleCloseCopyDialog = () => {
    setCopyDialogOpen(false)
  }

  const handleOpenResetDialog = () => {
    setResetDialogOpen(true)
  }
  const handleCloseResetDialog = () => {
    setResetDialogOpen(false)
  }

  const handleOpenDeleteTestDataDialog = () => {
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteTestDataDialog = () => {
    setDeleteDialogOpen(false)
  }

  if (
    isAltAccount(account.account_type) &&
    account.alternative_account.account_type !== "demo" &&
    !iscopyFromLiveDisabled
  ) {
    return (
      <div
        className="mv4 bg-white br2 ba b--mid-gray-2 shadow-18 w-100 w-50-l"
        data-test="api"
      >
        <Card>
          <CardHeader noSticky>Test Account Actions</CardHeader>
          <>
            <div className={styles.cardBody}>
              <b>
                These actions only affect your Test Account. They cannot be
                undone.
              </b>
              <p>
                <br />
                If you wish to delete your entire Runn account please{" "}
                <a href={toggleTestAccount()}>switch to the Live Account</a>.
              </p>
              <br />
              <div className={styles.actionsContainer}>
                <Button
                  text="Copy Live Account data"
                  onClick={handleOpenCopyDialog}
                  icon={<CloudDownload size={18} />}
                />
                {account.account_type === "test" && (
                  <Button
                    text="Reset Test Account"
                    onClick={handleOpenResetDialog}
                    icon={<AddCircle size={18} />}
                  />
                )}
                <Button
                  text="Delete all Test Account data"
                  onClick={handleOpenDeleteTestDataDialog}
                  icon={<Icon icon="trash" />}
                />
              </div>
              <br />
              {account.last_copied && (
                <>
                  <div className={styles.lastCopiedContainer}>
                    <InfoCircle />
                    <b>
                      {format(
                        new Date(account.last_copied),
                        "'Last copy of Live Account data was on' EEEE do LLL yyyy 'at' h:mma",
                      )}
                    </b>
                  </div>
                </>
              )}
              <p>
                <b>Copy Live Account data:</b> This action copies all data from
                your Live Account.
                <br />
                <b>Reset Test Account:</b> This action resets your Test Account
                with demo data.
                <br />
                <b>Delete all Test Account data:</b> This action deletes all
                data from your test account.
                <br />
                <b>Autofill with zero:</b> This action fills all missing
                timesheets entries with 0.
              </p>
            </div>
          </>
        </Card>
        <Dialog isOpen={copyDialogOpen} enforceFocus>
          <CopyFromLiveModal onClose={handleCloseCopyDialog} />
        </Dialog>
        <Dialog isOpen={resetDialogOpen} enforceFocus>
          <ResetTestAccountModal onClose={handleCloseResetDialog} />
        </Dialog>
        <Dialog isOpen={deleteDialogIsOpen} enforceFocus>
          <DeleteTestAccountDataModal
            onClose={handleCloseDeleteTestDataDialog}
          />
        </Dialog>
        <Dialog isOpen={showAutofillModal} enforceFocus>
          <AutofillTimesheetsForm onClose={() => setShowAutofillModal(false)} />
        </Dialog>
        <LoadingSeedDataModal />
      </div>
    )
  }

  return null
}

export default withRelayPageContainerNoLoading(TestAccountActions)
