import { Icon, MenuDivider } from "@blueprintjs/core"
import React from "react"

import styles from "./AvailableFilterList.module.css"

import MenuItem from "~/common/MenuItem"
import * as icons from "~/common/react-icons"

import type { FilterSet, SuperSearchConfig } from "./types"

type SavedFilterSet = SuperSearchConfig["savedFilterSets"][0]

const isSavedFilterSet = (
  filterSet: FilterSet | SavedFilterSet,
): filterSet is SavedFilterSet => {
  return filterSet.hasOwnProperty("icon")
}

type SavedFilterSetListItemProps = {
  filterSet: SavedFilterSet | FilterSet
  onClick: (filterSet: FilterSet) => void
  onRemove: (filterSet: FilterSet) => void
}

const SavedFilterSetListItem = (props: SavedFilterSetListItemProps) => {
  const { filterSet, onClick, onRemove } = props

  const handleClick = () => {
    onClick(filterSet)
  }

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onRemove(filterSet)
  }

  const icon = isSavedFilterSet(filterSet) ? (
    filterSet.icon
  ) : (
    <icons.BookmarkFilled color="var(--midnight)" />
  )

  return (
    <MenuItem
      text={
        <div className={styles.filterItem}>
          <span>{filterSet.name}</span>
          {!isSavedFilterSet(filterSet) && (
            <button className={styles.removeButton} onClick={handleRemove}>
              <Icon icon="trash" />
            </button>
          )}
        </div>
      }
      icon={<div className={styles.icon}>{icon}</div>}
      onClick={handleClick}
      data-component="SavedFilterSetListItem"
    />
  )
}

type SavedFilterSetListProps = {
  savedFilterSets: FilterSet[]
  config: SuperSearchConfig
  onClick: (filterSet: FilterSet) => void
  onRemove: (filterSet: FilterSet) => void
}

const SavedFilterSetList = (props: SavedFilterSetListProps) => {
  const { savedFilterSets, config, onClick, onRemove } = props

  if (savedFilterSets.length === 0) {
    return null
  }

  return (
    <>
      <MenuItem
        className={styles.savedFilterMenuItem}
        text={`${config.savedFiltersTitle} (${savedFilterSets.length})`}
        icon={<icons.BookmarkFilled color="var(--midnight)" />}
      >
        <div
          className={styles.savedFilterList}
          data-test="SuperSearch_SavedFilterSetList"
        >
          {savedFilterSets.map((filterSet) => (
            <SavedFilterSetListItem
              key={filterSet.name}
              filterSet={filterSet}
              onClick={onClick}
              onRemove={onRemove}
            />
          ))}
        </div>
      </MenuItem>
      <MenuDivider />
    </>
  )
}

export default SavedFilterSetList
