/**
 * @generated SignedSource<<51d793af11fb5966365b2c22caf9c626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerLeftColumn_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderPlannerMenu_account" | "ViewExternalProjectLink_account">;
  readonly " $fragmentType": "PlannerLeftColumn_account";
};
export type PlannerLeftColumn_account$key = {
  readonly " $data"?: PlannerLeftColumn_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerLeftColumn_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderPlannerMenu_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewExternalProjectLink_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "ff78620d828c414e2ed6798035ac9a7c";

export default node;
