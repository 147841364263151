/**
 * @generated SignedSource<<25c857339db67cc22c6d6e35f248df30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useProjectTemplates_account$data = {
  readonly projects: ReadonlyArray<{
    readonly id: number;
    readonly is_template: boolean;
    readonly name: string;
  }>;
  readonly " $fragmentType": "useProjectTemplates_account";
};
export type useProjectTemplates_account$key = {
  readonly " $data"?: useProjectTemplates_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProjectTemplates_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useProjectTemplates_account",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_template",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "7b44e51ba06d294bd98fb4982ef05360";

export default node;
