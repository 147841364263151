import { toFullTimeEquivalentEffort } from "@runn/calculations"
import React from "react"
import { P, match } from "ts-pattern"

import DurationHelper from "~/helpers/DurationHelper"

import { useAppSelector } from "../../../hooks/redux"

import { CapacityPercentageLabel } from "./CapacityPercentageLabel"
import { FTELabel } from "./FTELabel"
import { HoursPerDayLabel } from "./HoursPerDayLabel"
import { HoursPerWeekLabel } from "./HoursPerWeekLabel"

type AssignmentLabelProps = {
  days: number
  non_working_day: boolean
  minutes_per_day: number
  isHovering: boolean
  pillWidth: number
  defaultFullTimeMinutes: number
  contractMinutesPerDay?: number
}

export const AssignmentLabel = ({
  days,
  non_working_day,
  minutes_per_day,
  isHovering,
  pillWidth,
  defaultFullTimeMinutes,
  contractMinutesPerDay,
}: AssignmentLabelProps): JSX.Element => {
  const showTotalEffort = useAppSelector(
    (state) => state.displayUnit.showTotalEffort,
  )
  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const totalEffortDisplayUnit = useAppSelector(
    (state) => state.displayUnit.totalEffort,
  )

  if (minutes_per_day === 0) {
    return effortDisplayUnit === "capacityPercentage" ? <>0%</> : <>{"0h"}</>
  }

  if (!minutes_per_day) {
    return <>{""}</>
  }

  const dayCount = non_working_day ? 1 : days

  const totalHours = (minutes_per_day / 60) * dayCount

  const totalEffortDays = toFullTimeEquivalentEffort({
    minutesOfEffort: totalHours * 60,
    fulltimeMinutesPerDay: defaultFullTimeMinutes,
  })

  const totalEffortValue = match(totalEffortDisplayUnit)
    .with("hours", () => DurationHelper.formatHours(totalHours))
    .with("days", () => `${totalEffortDays}d`)
    .exhaustive()

  let effortLabel: React.JSX.Element

  if (effortDisplayUnit === "fullTimeEquivalent") {
    effortLabel = (
      <FTELabel
        minutesPerDay={minutes_per_day}
        defaultFullTimeMinutes={defaultFullTimeMinutes}
        pillWidth={pillWidth}
      />
    )
  }

  if (effortDisplayUnit === "hoursPerDay" || non_working_day) {
    effortLabel = (
      <HoursPerDayLabel minutesPerDay={minutes_per_day} pillWidth={pillWidth} />
    )
  }

  if (effortDisplayUnit === "hoursPerWeek") {
    effortLabel = (
      <HoursPerWeekLabel
        minutesPerDay={minutes_per_day}
        pillWidth={pillWidth}
      />
    )
  }

  if (effortDisplayUnit === "capacityPercentage") {
    effortLabel = (
      <CapacityPercentageLabel
        minutesPerDay={minutes_per_day}
        defaultFullTimeMinutes={defaultFullTimeMinutes}
        contractMinutesPerDay={contractMinutesPerDay}
      />
    )
  }

  if (showTotalEffort) {
    return match(pillWidth)
      .with(P.number.lt(80), () => <span>{totalEffortValue}</span>)
      .with(P.number.lt(120), () => <span>{totalEffortValue} Total</span>)
      .otherwise(() => (
        <span>
          {totalEffortValue} Total | {effortLabel}
        </span>
      ))
  }

  return (
    <span>
      {effortLabel}
      {isHovering && pillWidth > 200 ? ` | ${totalEffortValue} Total` : ""}
    </span>
  )
}
