import { useFeature } from "flagged"
import React, { useEffect, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { PersonManagement_user$data } from "./__generated__/PersonManagement_user.graphql"
import { PersonManagement_user$key } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"

import { groupPeopleBy } from "~/helpers/group-helpers"
import {
  getSortedAvailabilityIds,
  sortPeopleBy,
  sortPlaceholders,
} from "~/helpers/sorting-helpers"

import AddSection from "~/common/AddSection"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import NoResultsForFilter from "~/Planner/NoResultsForFilter"
import {
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "~/Planner/Planner.actions"
import {
  selectGroupPeopleBy,
  selectPeopleLastSorted,
  selectSortByOrderChanged,
  selectSortPeopleBy,
  selectSortPlaceholdersBy,
} from "~/Planner/reducer2/peopleSortSlice"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import AddNewPerson from "./AddNewPerson"
import PersonGroupList from "./PersonGroupList"

type Person = PersonManagement_user$data["account"]["people"][0]

type WithFavourite<T> = T & { is_favourite: boolean }

const withFavourites = (
  favouritePersonIds: number[],
  personList: Person[],
): WithFavourite<Person>[] =>
  personList.map((person) => ({
    ...person,
    is_favourite: favouritePersonIds.includes(person.id),
  }))

type PersonManagementProps = {
  user: PersonManagement_user$key
  filteredPersonIds: Set<number>
}

const PersonManagement = (props: PersonManagementProps) => {
  const { filteredPersonIds } = props

  const { favourite_people: favouritePersonIds, account } = useFragment(
    graphql`
      fragment PersonManagement_user on users
      @argumentDefinitions(
        plannerStartDate: { type: "date!" }
        peopleFilter: { type: "people_bool_exp" }
        projectsFilter: { type: "projects_bool_exp" }
      ) {
        favourite_people
        account {
          default_full_time_minutes
          views {
            id
          }
          custom_select_types {
            id
            name
            model
            options: custom_select_options {
              id
              name
            }
          }
          projects(where: $projectsFilter) {
            ...PlaceholderCapacityIndicator_projects
            id
            name
            active
            confirmed
            pricing_model
            is_template
            priority
            emoji
            client {
              id
              image_key
              name
              website
            }
            custom_text_values {
              value
              typeId: custom_text_type_id
            }
            custom_select_values {
              optionId: custom_select_option_id
              typeId: custom_select_type_id
            }
            custom_checkbox_values {
              typeId: custom_checkbox_type_id
              value
            }
            custom_date_values {
              value
              typeId: custom_date_type_id
            }
            phases {
              id
              name
              color
              start_date: start_date_runn
              end_date: end_date_runn
            }
            tags_computed
          }
          teams {
            id
            name
          }
          people(where: $peopleFilter) {
            ...PersonSummaryRow_person
              @arguments(plannerStartDate: $plannerStartDate)
            ...PlaceholderSummaryRow_person
              @arguments(plannerStartDate: $plannerStartDate)
            ...PersonDetails_person
              @arguments(plannerStartDate: $plannerStartDate)
            id
            is_placeholder
            first_name
            last_name
            email
            active
            tags
            created_at
            image_key
            archivable
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              employment_type
              minutes_per_day
              role_id
              cost: cost_private
              role {
                id
                name
                active
              }
            }
            time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              person_id
              leave_type
              minutes_per_day
              ...ExtLinks_TimeOff @relay(mask: false)
            }
            team {
              id
              name
            }
            assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
              id
              project_id
              person_id
              role_id
              phase_id
              workstream_id
              end_date: end_date_runn
              start_date: start_date_runn
              minutes_per_day
              is_billable
              is_template
              note
              total_minutes
              non_working_day
            }
            references
            competencies {
              id
              level
              skill {
                id
                name
              }
            }
            project_memberships {
              id
              project_id
              person_id
              role_id
              workstream_id
              is_placeholder
              just_added_timestamp
              project {
                id
                name
              }
              workstream {
                id
                name
              }
            }
            people_notes {
              id
              note
              created_at
              ...PersonNote_note
            }
            custom_text_values {
              value
              typeId: custom_text_type_id
            }
            custom_select_values {
              optionId: custom_select_option_id
              typeId: custom_select_type_id
            }
            custom_checkbox_values {
              typeId: custom_checkbox_type_id
              value
            }
            custom_date_values {
              value
              typeId: custom_date_type_id
            }
            person_requests {
              id
              status
              project_id
              updated_at
              user {
                id
                first_name
                last_name
                role_name
              }
              person {
                id
                first_name
                contracts {
                  id
                  start_date: start_date_runn
                  end_date: end_date_runn
                  cost: cost_private
                  minutes_per_day
                  role {
                    id
                    name
                    active
                  }
                }
              }
            }
            placeholder_suggestions {
              suggested_person_id
            }
            ...PersonCustomEditor_person
            ...PersonDetails_person
              @arguments(plannerStartDate: $plannerStartDate)
          }
        }
      }
    `,
    props.user,
  )

  const {
    people: allPeople,
    default_full_time_minutes: defaultFullTimeMinutes,
    projects: allProjects,
  } = account

  const dispatch = useDispatch()

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const {
    people: { isOverflow },
  } = useQueryLimit()

  const [visiblePersonIds, setVisiblePersonIds] = useState(filteredPersonIds)

  useEffect(() => {
    // Check if the visible people have changed - so we know if we need to re-order or not.
    if (!isDeeplyEqual(filteredPersonIds, visiblePersonIds)) {
      setVisiblePersonIds(filteredPersonIds)
    }
  }, [filteredPersonIds]) // eslint-disable-line react-hooks/exhaustive-deps

  const peopleSort = useAppSelector((state) =>
    selectSortPeopleBy(state.plannerV2.people),
  )

  const placeholderSortBy = useAppSelector((state) =>
    selectSortPlaceholdersBy(state.plannerV2.people),
  )

  const sortByOrder = useAppSelector((state) =>
    selectSortByOrderChanged(state.plannerV2.people),
  )

  const peopleLastSorted = useAppSelector((state) =>
    selectPeopleLastSorted(state.plannerV2.people),
  )

  const calendarStartDate = useSelector(
    (state: ReduxState) => state.calendar.calendarStartDate,
  )
  const calendarEndDate = useSelector(
    (state: ReduxState) => state.calendar.calendarEndDate,
  )

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const visibleProjects = allProjects.filter(
    (p) =>
      p.confirmed || isTentativeProjectEnabled(enabledTentativeProjects, p.id),
  )
  const filterSet = useAppSelector((state) => state.planner.peopleFilterSet)

  const filteredPeople = withFavourites(
    favouritePersonIds,
    allPeople.filter((person) => filteredPersonIds.has(person.id)),
  )

  const [availabilityOrderIds, setAvailabilityOrderIds] =
    useState<number[]>(undefined)

  useEffect(
    () => {
      if (peopleSort === "availability") {
        const sortedIds = getSortedAvailabilityIds(
          filteredPeople,
          calendarStartDate,
          calendarEndDate,
          visibleProjects.map((p) => p.id),
          isConsistentTimeOffEnabled,
          sortByOrder,
        )
        setAvailabilityOrderIds(sortedIds)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      peopleSort,
      peopleLastSorted,
      peopleGroupBy,
      visiblePersonIds,
      allPeople.length,
    ],
  )

  const handleClearFilters = () => {
    dispatch(setPeopleFilterSet({ name: "", filters: null }))
    dispatch(setPeopleWildSearch(""))
  }

  if (filteredPersonIds.size === 0 && allPeople.length > 0) {
    return (
      <NoResultsForFilter
        subject="people"
        onClearFilters={filterSet.filters && handleClearFilters}
        accountHasViews={account.views.length > 0}
      />
    )
  }

  const onlyPeople = allPeople.filter((person) => !person.is_placeholder)
  const onlyPlaceholders = allPeople.filter((person) => person.is_placeholder)

  const sortedPeople = sortPeopleBy(
    withFavourites(
      favouritePersonIds,
      onlyPeople.filter((person) => filteredPersonIds.has(person.id)),
    ),
    peopleSort,
  )

  const sortedPlaceholders = sortPlaceholders(
    withFavourites(
      favouritePersonIds,
      onlyPlaceholders.filter((placeholder) =>
        filteredPersonIds.has(placeholder.id),
      ),
    ),
    allProjects,
    placeholderSortBy,
  )

  const peopleList = [...sortedPlaceholders, ...sortedPeople]

  const getAvailabilitySortedPeople = () => {
    return [...sortedPeople].sort((a, b) => {
      return (
        availabilityOrderIds.indexOf(a.id) - availabilityOrderIds.indexOf(b.id)
      )
    })
  }

  const customFields = account.custom_select_types.filter(
    (f) => f.model === "PERSON",
  )

  const activeVisibleProjectIds = visibleProjects
    .filter((p) => p.active)
    .map((p) => p.id)

  const personGroupList =
    peopleSort === "availability" && availabilityOrderIds
      ? groupPeopleBy(
          [...sortedPlaceholders, ...getAvailabilitySortedPeople()],
          peopleGroupBy,
          activeVisibleProjectIds,
          customFields,
        )
      : groupPeopleBy(
          peopleList,
          peopleGroupBy,
          activeVisibleProjectIds,
          customFields,
        )

  return (
    <>
      <PersonGroupList
        personCount={peopleList.length}
        personGroupList={personGroupList}
        favouritePersonIds={favouritePersonIds}
        defaultFullTimeMinutes={defaultFullTimeMinutes}
        allProjects={allProjects}
      />

      {!isOverflow && <AddNewPerson hasNoPeople={allPeople.length === 0} />}

      <div style={{ flex: 1, display: "flex" }}>
        <AddSection />
      </div>
    </>
  )
}

export default PersonManagement
