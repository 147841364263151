import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { CreateTemplateFromProjectForm_project$key } from "./__generated__/CreateTemplateFromProjectForm_project.graphql"

import ModalFormWrapper from "../../common/ModalForm/ModalFormWrapper"

import RocketTakeOffTangram from "../CreateProject/RocketTakeOffTangram"
import CreateProjectForm, {
  ProjectReturn,
} from "../CreateProjectForm/CreateProjectForm"

type Props = {
  closeDialog: () => void
  onSuccess: (project: ProjectReturn) => void
  createTemplateFromProjectQuery: CreateTemplateFromProjectForm_project$key
}

const fragment = graphql`
  fragment CreateTemplateFromProjectForm_project on projects {
    id
    name
    tags_computed
    workstreams_computed
    active
    pricing_model
    client_id
    rate_card_id
    team_id
    # Referenced directly in <ProjectForm> as we need it in addition to the fragment inclusion
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      typeId: custom_checkbox_type_id
      value
    }
    ...ProjectCustomEditor_project
  }
`

const CreateTemplateFromProjectForm = (props: Props) => {
  const { closeDialog, createTemplateFromProjectQuery, onSuccess } = props

  const project = useFragment<CreateTemplateFromProjectForm_project$key>(
    fragment,
    createTemplateFromProjectQuery,
  )

  const [activateAnimation, setActivateAnimation] = useState(false)

  return (
    <ModalFormWrapper
      headerTitle="New Template from Project"
      headerSubTitle={project.name}
      wide
      tangram={<RocketTakeOffTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <CreateProjectForm
        onSuccess={onSuccess}
        onClose={closeDialog}
        templateBlueprint={project}
        activateAnimation={() => setActivateAnimation(true)}
        isTemplate
      />
    </ModalFormWrapper>
  )
}

export default CreateTemplateFromProjectForm
