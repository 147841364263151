import { Icon } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"

import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import FilterBlockText from "~/common/SuperSearch/Filters/common/FilterBlockText"
import type { FilterComponentProps } from "~/common/SuperSearch/types"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

type BaseProps = {
  user: SuperSearch_user$data
  filter: fe.filters.FilterProjectPersonJobTitleName
}

type Props = FilterComponentProps & BaseProps

const Component = (props: Props) => {
  const {
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (
    query: string,
    searchType: fe.filters.TextSearchType,
  ) => {
    return onChange(
      path,
      fe.filters.projectPersonJobTitleName({
        query,
        searchType,
      }),
    )
  }

  const handleRemove = () => onRemove(path)

  return (
    <FilterBlockText
      icon={<Icon icon="briefcase" />}
      label="Job Title"
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      query={filter.options.query}
      searchType={filter.options.searchType}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { query } = props.filter.options

  return (
    <FilterBlockReadonly
      name="Job Title"
      selectedOptions={[{ label: query }]}
    />
  )
}

export const ProjectPersonJobTitleName = {
  Component,
  ReadonlyComponent,
}
