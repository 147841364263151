import React from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import { RemoveMembershipFromProjectMenuItem_AddMutation } from "./__generated__/RemoveMembershipFromProjectMenuItem_AddMutation.graphql"
import { RemoveMembershipFromProjectMenuItem_DeleteMutation } from "./__generated__/RemoveMembershipFromProjectMenuItem_DeleteMutation.graphql"
import { RemoveMembershipFromProjectMenuItem_project$key } from "./__generated__/RemoveMembershipFromProjectMenuItem_project.graphql"

import { track } from "~/helpers/analytics"
import { formatName } from "~/helpers/person"

import MenuItem from "~/common/MenuItem"
import { DeleteIcon } from "~/common/react-icons"

import { showToast } from "~/containers/ToasterContainer"

type Props = {
  person: {
    id: number
    first_name: string
    last_name: string
    is_placeholder: boolean
  }
  project: RemoveMembershipFromProjectMenuItem_project$key
  roleId: number
  workstreamId: number
}

const projectFragment = graphql`
  fragment RemoveMembershipFromProjectMenuItem_project on projects {
    id
    name
    members {
      id
      project_id
      person_id
      workstream_id
      role_id
      just_added_timestamp
    }
  }
`
/*
  This component used to check before hand if the person has actual or assignments.
  Use member.actuals_count and member.assignments_count to check.
  However this made a pretty massive performance impact
  (on some accounts removing this changed response from 5s to 2.5s.)
  So now we just show a warning. Potentionally in the future we could have a modal
  that checks the values only after pressing Remove from Project.
  TPX account was by far the worst affected.
 */

export const RemoveMembershipFromProjectMenuItem = (props: Props) => {
  const { person, roleId, workstreamId } = props
  const project = useFragment(projectFragment, props.project)
  const personName = formatName(person.first_name, person.last_name)

  const [commitDelete] =
    useMutation<RemoveMembershipFromProjectMenuItem_DeleteMutation>(graphql`
      mutation RemoveMembershipFromProjectMenuItem_DeleteMutation(
        $input: ProjectMember!
      ) {
        action_project_member_delete(input: $input) {
          status
          message
          project {
            id
            members {
              id
              project_id
              role_id
              person_id
              is_placeholder
              workstream_id
              assignments_count
              actuals_count
              person {
                id
                first_name
                last_name
              }
            }
          }
          person {
            id
            project_memberships {
              id
              project_id
              role_id
              person_id
              workstream_id
              is_placeholder
              project {
                id
                name
              }
            }
          }
        }
      }
    `)

  const [commitUndo] =
    useMutation<RemoveMembershipFromProjectMenuItem_AddMutation>(graphql`
      mutation RemoveMembershipFromProjectMenuItem_AddMutation(
        $input: ProjectMemberBulkCreateInput!
      ) {
        action_project_member_bulk_create(input: $input) {
          person {
            id
            project_memberships {
              id
            }
          }
          project {
            id
            members {
              id
              project_id
              role_id
              workstream_id
              person_id
              person {
                id
              }
            }
          }
        }
      }
    `)

  const undoRemoveFromProject = () => {
    commitUndo({
      variables: {
        input: {
          project_members: [
            {
              person_id: person.id,
              project_id: project.id,
              role_id: roleId,
              workstream_id: workstreamId,
              is_placeholder: person.is_placeholder,
            },
          ],
        },
      },
    })
  }

  const menuItemName = "Remove from Project"
  const removeFromProject = () => {
    commitDelete({
      variables: {
        input: {
          person_id: person.id,
          project_id: project.id,
          role_id: roleId,
          workstream_id: workstreamId,
          is_placeholder: person.is_placeholder,
        },
      },
      onCompleted: (data) => {
        if (data.action_project_member_delete.status === "failed") {
          showToast({
            type: "warning",
            message: `${personName} cannot be removed from the project.`,
            description: `To remove a person from a project, please delete their assignments and actuals.`,
          })
        } else {
          track("Project Member Removed")
          track("Person Context Menu Item Selected", {
            item: menuItemName,
          })
          showToast({
            type: "success",
            message: `${personName} has been removed`,
            description: project.name,
            actions: [
              {
                text: "Undo",
                onClick: undoRemoveFromProject,
              },
            ],
          })
        }
      },
    })
  }

  return (
    <MenuItem
      text={menuItemName}
      icon={<DeleteIcon color="var(--midnight)" />}
      onClick={removeFromProject}
      shouldDismissPopover={false}
    />
  )
}
