import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./styles.module.css"

import type { ImportProjectViaIntegrationListQuery } from "./__generated__/ImportProjectViaIntegrationListQuery.graphql"

import { useIntegrationList } from "~/hooks/useIntegrationList"

import { ActionItem } from "./ActionItem"

const query = graphql`
  query ImportProjectViaIntegrationListQuery {
    current_user {
      id
      account {
        id
        ...useIntegrationList_account
      }
    }
  }
`

type Props = {
  onOpen?: (options: { integrationId: number }) => void
}

const ImportProjectViaIntegrationList = (props: Props) => {
  const { onOpen } = props

  const data = useLazyLoadQuery<ImportProjectViaIntegrationListQuery>(
    query,
    {},
    {
      fetchPolicy: "store-or-network",
    },
  )

  const account = data.current_user ? data.current_user.account : null

  const integrationList = useIntegrationList({ account })

  return integrationList
    .filter((integration) => {
      // Only show integrations that are for ticketing (i.e. project management)
      return integration.category === "TICKETING"
    })
    .map((integration) => {
      const name = integration.integration_service.name
      const imageUrl = integration.integration_service.square_image_url

      return (
        <ActionItem
          key={integration.id}
          href="#"
          icon={
            <img
              alt={name}
              src={imageUrl ?? undefined}
              className={styles.integrationImage}
            />
          }
          label={name}
          onClick={() => onOpen?.({ integrationId: integration.id })}
        />
      )
    })
}

export { ImportProjectViaIntegrationList }
