import React from "react"

import styles from "./SidePanel.module.css"

type Props = {
  children: React.ReactNode
}

const SidePanelActionButtons = (props: Props) => (
  <>
    <section className={styles.actionBtnContainer}>{props.children}</section>
  </>
)

export default SidePanelActionButtons
