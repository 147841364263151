/**
 * @generated SignedSource<<bba688599fcd697c7f0f3ec06278f92b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonNoteDeleteMutation$variables = {
  id: number;
};
export type PersonNoteDeleteMutation$data = {
  readonly deletePersonNote: {
    readonly person: {
      readonly id: number;
      readonly people_notes: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly people_notes_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
    } | null | undefined;
  };
};
export type PersonNoteDeleteMutation = {
  response: PersonNoteDeleteMutation$data;
  variables: PersonNoteDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "noteId",
        "variableName": "id"
      }
    ],
    "concreteType": "PersonNote",
    "kind": "LinkedField",
    "name": "deletePersonNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people_notes",
            "kind": "LinkedField",
            "name": "people_notes",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people_notes_aggregate",
            "kind": "LinkedField",
            "name": "people_notes_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "people_notes_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonNoteDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonNoteDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c8c76b12fdbeb97787440d0b4c5fc0b0",
    "id": null,
    "metadata": {},
    "name": "PersonNoteDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PersonNoteDeleteMutation(\n  $id: Int!\n) {\n  deletePersonNote(noteId: $id) {\n    person {\n      id\n      people_notes {\n        id\n      }\n      people_notes_aggregate {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e247775872a3d4a9bf9317b56e1e54e";

export default node;
