type Assignment = {
  id: number
  project_id: number
  person_id: number
  role_id: number
  start_date: string
  end_date: string
  is_placeholder: boolean
  minutes_per_day: number
  is_billable: boolean
  note: string
  updated_at?: string
  non_working_day: boolean
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
  note: string
}

/*
 * Used to represent a react-select dropdown option.
 */
export type Option<Value = string, Label = string> = {
  value: Value
  label: Label
}

export const isAssignment = (item): item is Assignment => "role_id" in item
export const isTimeOff = (item): item is TimeOff => !("role_id" in item)

export default {
  isAssignment,
  isTimeOff,
}
