import { Tooltip, TooltipProps } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { addBusinessDays, addDays, format, isEqual, parseISO } from "date-fns"
import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"

import styles from "./CalendarMilestone.module.css"

import { getItemOffsetPercentNum } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"

import { Popover2 } from "~/common/Popover2"
import { CalendarPeriods } from "~/common/calendar.reducer"

import { updateMilestoneRelay } from "~/mutations/Milestone"

import { setHighlightedCellData } from "~/GlobalState"
import { usePermissions } from "~/Permissions/usePermissions"
import MilestoneDetailsForm from "~/ProjectPlanner/ProjectOverview/MilestoneDetailsForm"
import useCalendarWeekends from "~/hooks/useCalendarWeekends"
import { MilestoneForm, MilestoneIcon } from "~/milestones"
import { ReduxState } from "~/rootReducer"

import ProjectItem from "./ProjectItem"

export type Milestone = {
  id: number
  project_id: number
  icon?: string
  date?: string
  note?: string
  title?: string
}

type Props = {
  milestones: Milestone[]
  projectConfirmed: boolean
  calendarPeriods?: CalendarPeriods
  projectPricingModel: string
  isTemplate: boolean
  isWeekend: boolean
  milestoneWrapperRef: React.RefObject<HTMLElement>
  setDragging: Dispatch<SetStateAction<boolean>>
}

type MilestoneTooltipProps = TooltipProps & { hideTooltip?: boolean }

const MilestoneTooltip = (props: MilestoneTooltipProps) => {
  const { hideTooltip, ...rest } = props
  if (hideTooltip) {
    return <>{props.children}</>
  }

  return (
    <Tooltip hoverOpenDelay={1000} placement="top" {...rest}>
      {props.children}
    </Tooltip>
  )
}

const CalendarMilestone = (props: Props) => {
  const {
    milestones,
    calendarPeriods,
    projectConfirmed,
    projectPricingModel,
    isWeekend,
    milestoneWrapperRef,
    setDragging,
  } = props

  const dispatch = useDispatch()

  const [milestoneToEdit, setMilestoneToEdit] = useState(null)
  const [addMilestone, setAddMilestone] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)

  const milestoneProjectId = milestones[0].project_id

  const { can, subject } = usePermissions()
  const milestoneSubject = subject("Milestone", {
    project: { id: milestoneProjectId, isTemplate: props.isTemplate },
  })
  const canEdit = can("edit", milestoneSubject)

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )
  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
  } = calendar

  const dragState = useRef({
    hoveredDay: null,
    startDay: null,
    newDate: null,
  })

  const resetDragState = () => {
    dragState.current = {
      hoveredDay: null,
      startDay: null,
      newDate: null,
    }
  }

  const [tempDate, setTempDate] = useState<string | null>(null)
  const date = tempDate ?? milestones[0].date

  const isWeekendInCollapsedView = isWeekend && !calendarWeekendsExpanded

  const milestoneOffset = getItemOffsetPercentNum(
    calendarStartDate,
    calendarEndDate,
    { start_date: date },
    calendarWeekendsExpanded,
    isWeekendInCollapsedView,
  )
  const { expandWeekends } = useCalendarWeekends()

  if (isWeekendInCollapsedView) {
    // Show small weekend milestone
    return (
      <ProjectItem
        width={dayWidth}
        offset={milestoneOffset}
        isCollapsedWeekend
        onClick={() => expandWeekends(milestones[0].date)}
      >
        <Tooltip
          content={`Click to expand weekends${
            calendar.calendarView.amount !== 1 ? " in month view" : ""
          }`}
          placement="top"
        >
          <div className={styles.weekendsCollapsedIcon} />
        </Tooltip>
      </ProjectItem>
    )
  }

  const iconSize = calendarPeriods === "weeks" ? 15 : 20
  const iconClasses = cc({
    [styles.tentativeMilestone]: !projectConfirmed,
    [styles.nonBillableMilestone]: projectPricingModel === "nb",
  })

  const handleEdit = (milestone) => {
    setIsFormOpen(true)
    setMilestoneToEdit(milestone)
  }

  const handleAdd = () => {
    setIsFormOpen(true)
    setAddMilestone(true)
  }

  const closeForm = () => {
    setMilestoneToEdit(null)
    setAddMilestone(false)
    setIsFormOpen(false)
  }

  const onMouseMove = (e) => {
    if (milestoneWrapperRef.current == null) {
      return
    }

    const mousePositionOffset =
      e.pageX -
      (milestoneWrapperRef.current.offsetParent as HTMLElement).offsetLeft
    const hoveredDay = Math.ceil(mousePositionOffset / dayWidth)
    const hoveredDayHasChanged = hoveredDay !== dragState.current.hoveredDay

    if (!hoveredDayHasChanged) {
      return
    }

    dragState.current.hoveredDay = hoveredDay

    // Set starting day of the drag
    if (!dragState.current.startDay) {
      dragState.current.startDay = hoveredDay
    }

    const daysMoved = dragState.current.startDay - hoveredDay
    const newDate = calendarWeekendsExpanded
      ? addDays(parseISO(milestones[0].date), -daysMoved)
      : addBusinessDays(parseISO(milestones[0].date), -daysMoved)

    // Highlight date on calendar
    dispatch(setHighlightedCellData({ type: "milestone", day: newDate }))

    if (isEqual(dateHelpers.parseRunnDate(milestones[0].date), newDate)) {
      dragState.current.newDate = null
      setTempDate(null)
      return
    }

    if (
      Number(dateHelpers.formatToRunnDate(newDate)) >=
        Number(dateHelpers.formatToRunnDate(calendarStartDate)) &&
      Number(dateHelpers.formatToRunnDate(newDate)) <=
        Number(dateHelpers.formatToRunnDate(calendarEndDate))
    ) {
      // Save to ref for use in mutation
      dragState.current.newDate = newDate
      // Save to state to trigger re-render
      setTempDate(dateHelpers.formatToRunnDate(newDate))
    }
  }

  const onMouseUp = (e) => {
    setDragging(false)

    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)

    if (!dragState.current.newDate) {
      setIsFormOpen(true)
      resetDragState()
      return
    }

    track("Project Milestone Edited")

    milestones.forEach(
      (milestone) =>
        void updateMilestoneRelay({
          id: milestone.id,
          date: format(dragState.current.newDate, "yyyy-MM-dd"),
          project_id: milestone.project_id,
        }),
    )
    resetDragState()
  }

  const onMouseDown = (e) => {
    if (e.button === 0 && !isFormOpen) {
      // Don't show the add milestone button while dragging
      setDragging(true)
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    }
  }

  const popoverContent = () => {
    if (addMilestone) {
      return (
        <MilestoneForm
          type="Add"
          onClose={closeForm}
          hideCalendar
          projectId={milestoneProjectId}
          milestone={{
            id: undefined,
            date: milestones[0].date,
            title: "",
            icon: "dollar",
            note: "",
            project_id: milestoneProjectId,
          }}
        />
      )
    }

    if (milestoneToEdit) {
      return (
        <div
          className={cc[(styles.calendarMilestone, styles.editFormContainer)]}
        >
          <MilestoneForm
            type="Edit"
            hideDate
            milestone={milestoneToEdit}
            onClose={closeForm}
            projectId={milestoneProjectId}
          />
        </div>
      )
    }

    return (
      <MilestoneDetailsForm
        milestones={milestones}
        iconClasses={iconClasses}
        canEdit={canEdit}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
      />
    )
  }

  const milestoneContent =
    milestones.length === 1 ? (
      milestones[0].title
    ) : (
      <ol className={styles.multipleMilestonesTooltip}>
        {milestones.map((m) => {
          return <li key={m.id}>{m.title}</li>
        })}
      </ol>
    )

  return (
    <ProjectItem width={dayWidth} offset={milestoneOffset}>
      <MilestoneTooltip content={milestoneContent} hideTooltip={isFormOpen}>
        <Popover2
          content={popoverContent()}
          isOpen={isFormOpen}
          placement="bottom"
          onClose={closeForm}
          hasBackdrop
          className={styles.iconPopover}
        >
          <div className={styles.iconWrapper} onMouseDown={onMouseDown}>
            {milestones.length === 1 ? (
              <MilestoneIcon
                iconOrEmoji={milestones[0].icon}
                size={iconSize}
                className={iconClasses}
              />
            ) : (
              <div className={styles.numberIcon}>
                {milestones.length.toString()}
              </div>
            )}
          </div>
        </Popover2>
      </MilestoneTooltip>
    </ProjectItem>
  )
}

export default CalendarMilestone
