/**
 * @generated SignedSource<<9e42c918d2df641601334bb400c61127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderPlannerMenu_project$data = {
  readonly id: number;
  readonly is_template: boolean;
  readonly name: string;
  readonly project_workstreams: ReadonlyArray<{
    readonly workstream_id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_project" | "ChangeWorkstreamModal_project">;
  readonly " $fragmentType": "PlaceholderPlannerMenu_project";
};
export type PlaceholderPlannerMenu_project$key = {
  readonly " $data"?: PlaceholderPlannerMenu_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderPlannerMenu_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderPlannerMenu_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_workstreams",
      "kind": "LinkedField",
      "name": "project_workstreams",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeWorkstreamModal_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeProjectRoleModal_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "078429363f3ecba111254fb9da2b7202";

export default node;
