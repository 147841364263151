import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React, { useState } from "react"

import avatarStyles from "../Avatar.module.css"
import styles from "./SelectPersonItem.module.css"

import { SelectPersonList_account$data } from "~/common/SelectPersonList/__generated__/SelectPersonList_account.graphql"

import { CustomType, valuesMap } from "~/helpers/custom-field-helpers"
import { dashify } from "~/helpers/general-helpers"
import { buildImageUrl } from "~/helpers/image"
import {
  formatName,
  formatNameAsInitials,
  getCurrentOrLastContract,
} from "~/helpers/person"

import IconPlus from "~/common/IconPlus"
import { SelectorListItem } from "~/common/SelectorModal"
import Checkbox from "~/common/inputs/Checkbox"

import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import RoleSelector from "~/Planner/RoleSelector"

import Avatar from "../Avatar"
import { ArchiveIcon } from "../react-icons"

type Person = SelectPersonList_account$data["people"][0]

type Props = {
  person?: Person
  defaultRoleValue?: RoleOption & { archived: boolean; disabled: boolean }
  selected?: boolean
  isPlaceholder: boolean
  selectedPeople: number[]
  options?: any
  placeholderSelected?: boolean
  filteredRoleIds?: number[]
  onPersonSelectionChange?: (person: Person) => void
  onPlaceholderSelectionChange?: () => void
  handleAdd: (person: Person) => void
  selectedPlaceholderRole?: RoleOption
  setSelectedPlaceholderRole?: (number) => void
  setSelectedPeople: (any) => void
  handlePersonRoleSelect?: (role: RoleOption, person: Person) => void
  setPlaceholderCreateCount?: (count: number) => void
  placeholderCreateCount?: number
  isMember: boolean
  customFieldTypes: CustomType[]
  rolesInProject: number[]
}

type RoleOption = {
  value: number
  label: string
  active: boolean
}

const SelectPersonItem = (props: Props) => {
  const {
    person,
    defaultRoleValue,
    selected,
    isPlaceholder,
    options,
    selectedPeople,
    filteredRoleIds,
    onPersonSelectionChange,
    handleAdd,
    selectedPlaceholderRole,
    setSelectedPeople,
    handlePersonRoleSelect,
    isMember,
    customFieldTypes,
    rolesInProject,
  } = props
  const [renderDropdown, setRenderDropdown] = useState(false)
  const [hasOpened, setHasOpened] = useState(null)

  const handleQuickAdd = (e) => {
    e.stopPropagation()
    if (isPlaceholder && !selectedPlaceholderRole) {
      return
    }

    setSelectedPeople([])
    handleAdd(person)
  }

  const onRoleSelect = (value) => {
    handlePersonRoleSelect(value, person)
  }
  const onMouseEnter = () => {
    setRenderDropdown(true)
  }
  const onMouseLeave = () => {
    // Handles safari bug where clicking a select box triggers mouse leave.
    // Now if you open a select, we always leave it as 'isHovering'
    if (!hasOpened) {
      setRenderDropdown(false)
    }
  }

  const onOpen = () => {
    setHasOpened(true)
  }

  const onCheck = (e) => {
    onPersonSelectionChange(person)
  }

  const personFormattedName = formatName(person.first_name, person.last_name)

  const currentContract = getCurrentOrLastContract(person.contracts)

  const personSubTitle = (() => {
    let str = ""

    if (currentContract?.job_title) {
      str += currentContract.job_title
    }

    if (person.team) {
      str += str ? ` | ${person.team.name}` : person.team.name
    }

    return str
  })()

  const isDefaultRoleArchived = defaultRoleValue.archived
  const canAddRole = !defaultRoleValue.disabled

  const filterRolePredicate = (role: { id: number; active: boolean }) => {
    if (role.active === false) {
      return rolesInProject.includes(role.id)
    } else {
      return Array.isArray(filteredRoleIds)
        ? filteredRoleIds.includes(role.id)
        : true
    }
  }

  return (
    <div
      style={{ position: "relative" }}
      data-test={`${dashify(`${person.first_name} ${person.last_name}`)}-row`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onOpen}
    >
      <SelectorListItem
        onClick={() => canAddRole && onPersonSelectionChange(person)}
      >
        {renderDropdown && selectedPeople.length === 0 && canAddRole && (
          <div className={styles.quickAdd} onClick={handleQuickAdd}>
            <Tooltip content="Quick Add" placement="top">
              <IconPlus customPlusColor="white" />
            </Tooltip>
          </div>
        )}
        <Avatar
          email={person.email}
          initials={formatNameAsInitials(person.first_name, person.last_name)}
          avatar={buildImageUrl(person.image_key)}
          is_placeholder={person.is_placeholder}
          size={28}
          name={
            <>
              <span className={avatarStyles.name}>{personFormattedName}</span>
              {Boolean(personSubTitle) && (
                <span className={avatarStyles.subtitleText}>
                  | {personSubTitle}
                </span>
              )}
            </>
          }
          maxTextWidth="250px"
        />

        <PlannerTooltipIcons
          icons={{
            tags: person.tags,
            competencies: person.competencies,
            customFields: {
              values: valuesMap(person),
              types: customFieldTypes,
            },
          }}
        />
        {isMember && <span className={styles.memberText}>On project</span>}
      </SelectorListItem>
      {renderDropdown ? (
        <RoleSelector
          onFocus={onOpen}
          name={`person-select-${person.id}`}
          value={options[person.id]}
          defaultValue={defaultRoleValue}
          onChange={onRoleSelect}
          filterPredicate={filterRolePredicate}
          placeholder="Select role"
          className={styles.select}
          menuShouldBlockScroll={false}
          height={30}
          filterNonActive={false}
        />
      ) : (
        <div
          className={cc([
            styles.fakeRoleDropdown,
            {
              [styles.fakeRoleDisabled]: !canAddRole,
            },
          ])}
        >
          <span className={styles.fakeRoleText}>
            {isDefaultRoleArchived && <ArchiveIcon />}
            {defaultRoleValue?.label || ""}
          </span>
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </div>
      )}
      <div className={styles.checkboxContainer}>
        <Checkbox
          id={`SelectorListItem_Checkbox_isSelected_${person.id}`}
          value={person.id}
          onChange={onCheck}
          checked={selected}
          disabled={!canAddRole}
        />
      </div>
    </div>
  )
}

export default SelectPersonItem
