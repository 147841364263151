import { Icon } from "@blueprintjs/core"
import { Feature } from "flagged"
import React from "react"

import styles from "./styles.module.css"

import HRTrio from "@/images/integrations/hr-trio.png"
import PMTrio from "@/images/integrations/pm-trio.png"

import {
  accountAPIUrl,
  developerV1Url,
  importsV2Url,
  integrationsUrl,
} from "~/helpers/routes"

import { ModalBody, ModalFooter } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { WidgetsIcon } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"

import { ActionItem } from "./ActionItem"
import { ImportProjectViaIntegrationList } from "./ImportProjectViaIntegrationList"

type ImportType = "people" | "projects"

type ImportConfig = {
  title: string
  integrationsIcon: string
  IntegrationList:
    | React.FC<{
        onOpen: (options: { integrationId: number }) => void
      }>
    | undefined
}

const importConfigRecord: Record<ImportType, ImportConfig> = {
  people: {
    title: "Import People via",
    integrationsIcon: HRTrio,
    IntegrationList: undefined,
  },
  projects: {
    title: "Import Projects via",
    integrationsIcon: PMTrio,
    IntegrationList: ImportProjectViaIntegrationList,
  },
}

type Props = {
  importType: ImportType
  onClose?: () => void
  onOpenIntegration?: (options: { integrationId: number }) => void
}

const ImportForm = (props: Props) => {
  const { importType, onClose, onOpenIntegration } = props

  const { title, integrationsIcon, IntegrationList } =
    importConfigRecord[importType]

  const { can, subject } = usePermissions()
  const isAdmin = can("manage", subject("Account"))

  return (
    <>
      <ModalBody wide>
        <header className={styles.title}>{title}</header>

        <main className={styles.actionList}>
          <ActionItem
            href={isAdmin ? accountAPIUrl() : developerV1Url()}
            icon={
              <Icon
                icon="layout-hierarchy"
                color="var(--runn-blue)"
                size={32}
              />
            }
            label="API"
          />

          <ActionItem
            href={
              isAdmin
                ? importsV2Url()
                : "https://help.runn.io/en/articles/9238158-csv-import-export-overview"
            }
            icon={<WidgetsIcon size={32} color="var(--runn-blue)" />}
            label="CSV"
          />

          <ActionItem
            href={
              isAdmin ? integrationsUrl() : "https://www.runn.io/integrations"
            }
            icon={<img src={integrationsIcon} width={70} />}
            label="Connect New App"
          />

          <Feature name="project_integration_bulk_import">
            <React.Suspense fallback={null}>
              {IntegrationList ? (
                <IntegrationList onOpen={onOpenIntegration} />
              ) : null}
            </React.Suspense>
          </Feature>
        </main>
      </ModalBody>
      <ModalFooter>
        {onClose ? <Button text="Cancel" onClick={onClose} /> : null}
      </ModalFooter>
    </>
  )
}

export default ImportForm
