import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type PanelReducerState = {
  showPanel: PanelType
}

type PanelType =
  | "charts"
  | "splitScreen"
  | "personSchedule"
  | "projectSchedule"
  | null

const initialState: PanelReducerState = {
  showPanel: null,
}

const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    panelOpened: (state, action: PayloadAction<PanelType>) => {
      state.showPanel = action.payload
    },
    panelClosed: (state) => {
      state.showPanel = null
    },
  },
})

const { actions, reducer } = panelSlice
export const { panelOpened, panelClosed } = actions
export default reducer
