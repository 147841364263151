import { Icon, Tooltip } from "@blueprintjs/core"
import React from "react"

import MenuItem from "~/common/MenuItem"

type Props = {
  person: {
    readonly email: string | null
    readonly first_name: string
  }
  account: {
    readonly users: readonly { readonly email: string }[]
    readonly invitations: readonly { readonly email: string }[]
  }
  handleMenuClick: () => void
}

const InvitationMenu = (props: Props) => {
  const { person, account, handleMenuClick } = props

  if (
    account.users.some(
      (personUser) =>
        personUser.email.toLowerCase() === person.email?.toLowerCase(),
    )
  ) {
    return (
      <MenuItem
        disabled={true}
        text="User"
        icon={<Icon icon="tick-circle" />}
        shouldDismissPopover={false}
      />
    )
  }

  if (
    account.invitations.some(
      (invitation) =>
        invitation.email.toLowerCase() === person.email?.toLowerCase(),
    )
  ) {
    return (
      <MenuItem
        disabled={true}
        text="Pending Invite"
        icon={<Icon icon="envelope" />}
        shouldDismissPopover={false}
      />
    )
  }

  return (
    <MenuItem
      disabled={!person.email}
      text={
        <Tooltip
          disabled={Boolean(person.email)}
          content={`Add ${person.first_name}'s email to invite them as a user`}
          openOnTargetFocus={false}
        >
          Invite to Runn
        </Tooltip>
      }
      icon={<Icon icon="new-person" />}
      onClick={handleMenuClick}
      shouldDismissPopover={false}
    />
  )
}

export default InvitationMenu
