import { dateHelpers } from "@runn/calculations"
import { startOfISOWeek } from "date-fns"

import { getCalendarEndDate } from "~/helpers/CalendarHelper"
import { defaultToFalse } from "~/helpers/general-helpers"

import { getSettings } from "~/localsettings"

export const CALENDAR_SET_DATE_RANGE = "CALENDAR_SET_DATE_RANGE"
export const CALENDAR_MOVE_DATE_RANGE = "CALENDAR_MOVE_DATE_RANGE"
export const CALENDAR_SET_DAY_WIDTH = "CALENDAR_SET_DAY_WIDTH"
export const CALENDAR_SET_VIEW_IN_MONTHS = "CALENDAR_SET_VIEW_IN_MONTHS"
export const CALENDAR_SET_VIEW_IN_WEEKS = "CALENDAR_SET_VIEW_IN_WEEKS"
export const CALENDAR_SET_WEEKENDS_EXPANDED = "CALENDAR_WEEKENDS_EXPANDED"
export const CALENDAR_HIGHLIGHT_ASSIGNMENTS = "CALENDAR_HIGHLIGHT_ASSIGNMENTS"

export const setDateRange = (dateRange: { start: Date; end: Date }) => ({
  type: CALENDAR_SET_DATE_RANGE,
  payload: dateRange,
})

export const moveDateRange = (startDate: Date) => ({
  type: CALENDAR_MOVE_DATE_RANGE,
  payload: startDate,
})

export const setDayWidth = (dayWidth: number) => ({
  type: CALENDAR_SET_DAY_WIDTH,
  payload: dayWidth,
})

export const setViewInMonths = (months: number) => ({
  type: CALENDAR_SET_VIEW_IN_MONTHS,
  payload: months,
})

export const setViewInWeeks = (weeks: number) => ({
  type: CALENDAR_SET_VIEW_IN_WEEKS,
  payload: weeks,
})

export const setCalendarWeekendsExpanded = (expanded: boolean) => ({
  type: CALENDAR_SET_WEEKENDS_EXPANDED,
  payload: expanded,
})

export const highlightAssignments = (ids: Array<number>) => ({
  type: CALENDAR_HIGHLIGHT_ASSIGNMENTS,
  payload: ids,
})

const settings = getSettings()

const calendarView = settings.calendarView || { type: "months", amount: 1 }

const calendarStartDate = startOfISOWeek(new Date())
const calendarEndDate =
  calendarView.type === "months"
    ? getCalendarEndDate(calendarStartDate, calendarView.amount, "months")
    : getCalendarEndDate(calendarStartDate, calendarView.amount - 1, "weeks")

export enum CalendarPeriods {
  days = "days",
  weeks = "weeks",
}

export type CalendarReducerState = {
  calendarStartDate: Date
  calendarEndDate: Date
  calStartNum: number
  calEndNum: number
  dayWidth: number
  calendarView:
    | { type: "months"; amount: 1 | 3 | 6 | 12 }
    | { type: "weeks"; amount: 1 }
  calendarWeekendsExpanded: boolean
  periods: CalendarPeriods
  highlightedAssignments: Array<number>
}

export const initialState = {
  calendarStartDate,
  calendarEndDate,
  calStartNum: Number(dateHelpers.formatToRunnDate(calendarStartDate)),
  calEndNum: Number(dateHelpers.formatToRunnDate(calendarEndDate)),
  dayWidth: 0,
  calendarView,
  periods:
    calendarView.type === "months" && calendarView.amount >= 6
      ? CalendarPeriods.weeks
      : CalendarPeriods.days,
  calendarWeekendsExpanded:
    calendarView.type === "months" && calendarView.amount === 1
      ? defaultToFalse(settings.expandWeekendsOnMonthView)
      : false,
  highlightedAssignments: [],
}

export default (
  state: CalendarReducerState = initialState,
  action: { type: string; payload: any },
): CalendarReducerState => {
  switch (action.type) {
    case CALENDAR_SET_DATE_RANGE:
      return {
        ...state,
        calendarStartDate: action.payload.start,
        calendarEndDate: action.payload.end,
        calStartNum: Number(dateHelpers.formatToRunnDate(action.payload.start)),
        calEndNum: Number(dateHelpers.formatToRunnDate(action.payload.end)),
      }
    case CALENDAR_SET_VIEW_IN_MONTHS:
      return {
        ...state,
        calendarView: {
          type: "months",
          amount: action.payload,
        },
        periods:
          action.payload >= 6 ? CalendarPeriods.weeks : CalendarPeriods.days,
      }
    case CALENDAR_SET_VIEW_IN_WEEKS:
      return {
        ...state,
        calendarView: {
          type: "weeks",
          amount: action.payload,
        },
        periods: CalendarPeriods.days,
      }
    case CALENDAR_SET_DAY_WIDTH:
      return {
        ...state,
        dayWidth: action.payload,
      }
    case CALENDAR_MOVE_DATE_RANGE:
      const startDate = action.payload
      const endDate = getCalendarEndDate(
        startDate,
        state.calendarView.amount,
        calendarView.type,
      )

      return {
        ...state,
        calendarStartDate: startDate,
        calendarEndDate: endDate,
        calStartNum: Number(dateHelpers.formatToRunnDate(startDate)),
        calEndNum: Number(dateHelpers.formatToRunnDate(endDate)),
      }
    case CALENDAR_SET_WEEKENDS_EXPANDED:
      return {
        ...state,
        calendarWeekendsExpanded: action.payload,
      }
    case CALENDAR_HIGHLIGHT_ASSIGNMENTS:
      return {
        ...state,
        highlightedAssignments: action.payload,
      }
    default:
      return state
  }
}
