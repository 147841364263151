export type ViewState = {
  viewId: number | null
  showAllView: boolean
}

type VIEW_SET = "VIEW_SET"
type VIEW_SHOW_ALL = "VIEW_SHOW_ALL"
type RESET = "RESET"

type Actions =
  | {
      type: VIEW_SET
      payload: Pick<ViewState, "viewId">
    }
  | {
      type: VIEW_SHOW_ALL
      payload?: never
    }
  | {
      type: RESET
      payload: ViewState
    }

export default (state: ViewState, action: Actions): ViewState => {
  switch (action.type) {
    case "VIEW_SET":
      return {
        viewId: action.payload.viewId,
        showAllView: false,
      }
    case "VIEW_SHOW_ALL":
      return {
        showAllView: true,
        viewId: null,
      }
    case "RESET":
      const viewState = action.payload
      if (viewState.showAllView) {
        return {
          showAllView: true,
          viewId: null,
        }
      }
      if (action.payload.viewId) {
        return {
          viewId: action.payload.viewId,
          showAllView: false,
        }
      }

      return state
    default:
      throw new Error("Invalid action type")
  }
}
