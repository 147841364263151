import * as React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ChangeWorkstreamModal.module.css"

import { ChangeWorkstreamModal_person$key } from "~/Planner/PlannerLayout/__generated__/ChangeWorkstreamModal_person.graphql"
import { ChangeWorkstreamModal_project$key } from "~/Planner/PlannerLayout/__generated__/ChangeWorkstreamModal_project.graphql"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"
import { formatName } from "~/helpers/person"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Select from "~/common/Select"
import { toOption } from "~/common/SelectorModal/SelectorHelpers"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import Button from "~/common/buttons/Button"
import { WarningIcon } from "~/common/react-icons"

import { usePersonChangeWorkstreamMutation } from "~/mutations/Person"

import { showToast } from "~/containers/ToasterContainer"

type ChangeWorkstreamModalProps = {
  workstreamId: number | null
  closeDialog: () => void
  person: ChangeWorkstreamModal_person$key
  project: ChangeWorkstreamModal_project$key
}

const ChangeWorkstreamModal = (props: ChangeWorkstreamModalProps) => {
  const { closeDialog } = props

  const person = useFragment(
    graphql`
      fragment ChangeWorkstreamModal_person on people {
        id
        first_name
        last_name
        project_memberships {
          id
          role_id
          workstream_id
          project_id
          has_assignments
        }
      }
    `,
    props.person,
  )

  const project = useFragment(
    graphql`
      fragment ChangeWorkstreamModal_project on projects {
        id
        name
        project_workstreams {
          workstream {
            id
            name
            archived
          }
        }
      }
    `,
    props.project,
  )

  const currentWorkstream =
    project.project_workstreams.find(
      ({ workstream }) => workstream.id === props.workstreamId,
    )?.workstream || null
  const currentProjectMembership = person.project_memberships.find(
    (pm) =>
      (currentWorkstream ? pm.workstream_id === currentWorkstream.id : true) &&
      pm.project_id === project.id,
  )

  const noWorkstreamOption = {
    value: null,
    label: "No Workstream",
  }

  const [selectedWorkstream, setSelectedWorkstream] = React.useState(
    currentWorkstream ? toOption(currentWorkstream) : noWorkstreamOption,
  )

  const [personChangeWorkstreamRelay] = usePersonChangeWorkstreamMutation()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (selectedWorkstream.value === (currentWorkstream?.id ?? null)) {
      closeDialog()
      return
    }

    try {
      await personChangeWorkstreamRelay({
        variables: {
          id: person.id,
          projectId: project.id,
          roleId: currentProjectMembership.role_id,
          currentWorkstreamId: currentWorkstream?.id || null,
          newWorkstreamId: selectedWorkstream?.value,
        },
      })

      track("Person Workstream Changed")

      showToast({
        type: "success",
        message: `${formatName(
          person.first_name,
          person.last_name,
        )}'s workstream has been updated to ${selectedWorkstream.label}`,
        description: project.name,
      })
    } catch (error) {
      void reportError("Error changing workstream", error)
    } finally {
      closeDialog()
    }
  }

  const { existingWorkstreamOptions, workstreamOptions } =
    project.project_workstreams.reduce(
      (acc, { workstream }) => {
        const isPersonInWorkstream = person.project_memberships.some(
          (pm) =>
            pm.project_id === project.id &&
            pm.workstream_id === workstream.id &&
            pm.role_id === currentProjectMembership.role_id,
        )

        const option = {
          value: workstream.id,
          label: workstream.name,
          isDisabled:
            workstream.id === currentWorkstream?.id
              ? false
              : isPersonInWorkstream,
        }

        if (isPersonInWorkstream) {
          acc.existingWorkstreamOptions.push(option)
        } else {
          acc.workstreamOptions.push(option)
        }

        return acc
      },
      { existingWorkstreamOptions: [], workstreamOptions: [] },
    )

  const hasMembershipWithoutWorkstream = person.project_memberships.some(
    (pm) => pm.project_id === project.id && pm.workstream_id === null,
  )
  const options = [
    ...(!hasMembershipWithoutWorkstream
      ? [
          {
            label: "",
            options: [noWorkstreamOption],
            pinned: true,
          },
        ]
      : []),
    {
      label: "",
      options: workstreamOptions,
    },
    {
      label: (
        <TitledHelpTooltip
          title="Existing Workstreams"
          tooltipContent="People cannot be transferred to a Workstream that they have already been assigned to"
        />
      ),
      options: [
        ...(hasMembershipWithoutWorkstream
          ? [{ ...noWorkstreamOption, isDisabled: true }]
          : []),
        ...existingWorkstreamOptions,
      ],
    },
  ]

  const hasAssignments = currentProjectMembership.has_assignments

  return (
    <ModalFormWrapper headerTitle={`Update ${person.first_name}'s Workstream`}>
      <form onSubmit={handleSubmit}>
        <ModalBody className={styles.modalBody}>
          <Select
            label="Workstream"
            placeholder="Select a Workstream"
            value={selectedWorkstream}
            options={options}
            menuPortalTarget={document.body}
            noOptionsMessage={() => <>No workstreams</>}
            onChange={setSelectedWorkstream}
            required
          />
          {hasAssignments && (
            <p className={styles.assignmentUpdateWarning}>
              <WarningIcon />
              All assignments and actuals will be updated to the new Workstream
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit" outlined={false}>
            Update
          </Button>
        </ModalFooter>
      </form>
    </ModalFormWrapper>
  )
}

export default ChangeWorkstreamModal
