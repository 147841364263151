import { Radio, RadioGroup } from "@blueprintjs/core"
import { format as formatDate } from "date-fns"
import React, { useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ConfirmContractForm.module.css"

import { ConfirmContractForm_person$key } from "./__generated__/ConfirmContractForm_person.graphql"

import DateInput from "~/common/DateInput"

import { usePermissions } from "~/Permissions/usePermissions"

const CONTRACT_TYPE = {
  NEW: "new",
  EDIT: "edit",
}

type Props = {
  person: ConfirmContractForm_person$key
  selectedContractType: string
  showDateInput: boolean
  contractStartDate: Date
  selectedStartDate: Date
  setContractType: (contractType: string) => void
  setContractStartDate: (contractStartDate: Date | null) => void
}

const ConfirmContractForm = (props: Props) => {
  const {
    setContractType,
    setContractStartDate,
    selectedContractType,
    selectedStartDate,
    contractStartDate,
    showDateInput,
  } = props

  const person = useFragment(
    graphql`
      fragment ConfirmContractForm_person on people {
        id
        email
      }
    `,
    props.person,
  )

  const { can, subject } = usePermissions()
  const contractPermissions = {
    create: can("create", subject("Contract", { person })),
    edit: can("edit", subject("Contract", { person })),
  }
  const [startDate, setStartDate] = useState<Date | null>(
    contractStartDate || null,
  )
  const [dateInputError, setDateInputError] = useState("")

  useEffect(() => {
    setContractStartDate(startDate)

    if (selectedContractType === CONTRACT_TYPE.NEW && !startDate) {
      return setDateInputError("Please select a start date")
    }

    setDateInputError("")
  }, [selectedContractType, startDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e) => {
    setContractType(e.target.value)
  }

  return (
    <RadioGroup
      onChange={onChange}
      label="Confirm contract"
      className={styles.radioGroup}
      selectedValue={selectedContractType}
    >
      <Radio value={CONTRACT_TYPE.NEW} disabled={!contractPermissions.create}>
        <p className={styles.radioTitle}>
          Save as a new contract
          {showDateInput
            ? ""
            : ` starting on ${formatDate(selectedStartDate, "d MMM yyyy")}`}
        </p>
        <p className={styles.radioText}>
          This will create a new contract effective from the selected start
          date. <br />
          Any existing contracts overlapping this date will be automatically
          ended.
        </p>
      </Radio>
      {showDateInput && (
        <div className={styles.daterPickerWrapper}>
          <p>Start date: </p>
          <DateInput
            className={styles.dateInput}
            onChange={(date) => setStartDate(date)}
            placeholder="Type or select a date"
            value={startDate}
            error={dateInputError}
            inputProps={{ id: "contract-start-date" }}
            disabled={!contractPermissions.create}
          />
        </div>
      )}
      <Radio value={CONTRACT_TYPE.EDIT} disabled={!contractPermissions.edit}>
        <p className={styles.radioTitle}>Update the existing contract</p>
        <p className={styles.radioText}>
          This will change all historical data. <br />
          Choose this if you're fixing a mistake or adding an end date to a
          contract.
        </p>
      </Radio>
    </RadioGroup>
  )
}

export default ConfirmContractForm
