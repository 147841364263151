/**
 * @generated SignedSource<<6122ea29edd11249b0b734900c4357de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderForm_user$data = {
  readonly account: {
    readonly id: number;
    readonly real_active_people: ReadonlyArray<{
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"PersonSelector_people">;
    }>;
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly contracts: ReadonlyArray<{
        readonly person: {
          readonly created_at: string;
          readonly first_name: string;
          readonly last_name: string;
          readonly placeholder_count: number;
        };
      }>;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"CustomFieldSectionHeaderPerson_account" | "TeamSelector_account">;
  };
  readonly " $fragmentType": "PlaceholderForm_user";
};
export type PlaceholderForm_user$key = {
  readonly " $data"?: PlaceholderForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderForm_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "_eq": true
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "default_hour_cost",
              "args": null,
              "kind": "ScalarField",
              "name": "default_hour_cost_private",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "limit",
                  "value": 1
                },
                {
                  "kind": "Literal",
                  "name": "order_by",
                  "value": {
                    "created_at": "desc"
                  }
                },
                {
                  "kind": "Literal",
                  "name": "where",
                  "value": {
                    "person": {
                      "is_placeholder": (v1/*: any*/)
                    }
                  }
                }
              ],
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "contracts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "people",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "first_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "placeholder_count",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "created_at",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "contracts(limit:1,order_by:{\"created_at\":\"desc\"},where:{\"person\":{\"is_placeholder\":{\"_eq\":true}}})"
            }
          ],
          "storageKey": null
        },
        {
          "alias": "real_active_people",
          "args": [
            {
              "kind": "Literal",
              "name": "order_by",
              "value": {
                "first_name": "asc",
                "last_name": "asc"
              }
            },
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": (v1/*: any*/),
                "is_placeholder": {
                  "_eq": false
                }
              }
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PersonSelector_people"
            }
          ],
          "storageKey": "people(order_by:{\"first_name\":\"asc\",\"last_name\":\"asc\"},where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TeamSelector_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CustomFieldSectionHeaderPerson_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "07182d3bc4232282f3666f17b3ec5a40";

export default node;
