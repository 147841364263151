import React, { Suspense } from "react"
import { graphql, useFragment } from "react-relay"

import { EditProjectForm_project$key } from "./__generated__/EditProjectForm_project.graphql"

import { track } from "../../helpers/analytics"
import { reportError } from "~/helpers/error-helpers"

import { projectUpdateRelay } from "../../mutations/Project"

import ProjectForm, { OnSubmitResponse } from "~/forms/ProjectForm/ProjectForm"

export type ProjectReturn = {
  id: number
  name: string
  team_id: number
  confirmed: boolean
}

type Props = {
  projectQuery: EditProjectForm_project$key
  onClose: () => void
  onSuccess?: (project: ProjectReturn) => void
}

const fragment = graphql`
  fragment EditProjectForm_project on projects {
    id
    name
    managers {
      id
      user_id
      project {
        id
      }
    }
    tags_computed
    workstreams_computed
    confirmed
    active
    pricing_model
    references
    client_id
    rate_card_id
    team_id
    is_template
    emoji
    # Referenced directly in <ProjectForm> as we need it in addition to the fragment inclusion
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      typeId: custom_checkbox_type_id
      value
    }
    members {
      id
      project_id
      workstream_id
    }
    links {
      id
      name
      href
      show_in_planner
    }
    ...ProjectCustomEditor_project
    ...ExtLinks_Project
  }
`

const EditProjectForm = (props: Props) => {
  const { onSuccess, onClose, projectQuery } = props

  const project = useFragment<EditProjectForm_project$key>(
    fragment,
    projectQuery,
  )

  const onSubmit = async ({ input }: OnSubmitResponse) => {
    const trackProperties = {
      confirmed: input.confirmed,
      pricing_model: input.pricing_model,
      tags: input.tags,
      emoji: input.emoji,
      workstreams: input.workstreams,
    }

    if (input.is_template) {
      track("Project Template Edited", trackProperties)
    } else {
      track("Project Edited", trackProperties)
    }

    try {
      const editedProject = await projectUpdateRelay({
        variables: {
          input: { ...input, project_id: project.id },
        },
      })

      onSuccess && onSuccess(editedProject)

      return { projectId: editedProject.id }
    } catch (error) {
      void reportError(`editing project`, error)
    }
  }

  // Stops the screen jumping as data is loaded into the project form
  const LoadingFallback = () => <div style={{ height: "800px" }}></div>

  return (
    <Suspense fallback={<LoadingFallback />}>
      <ProjectForm
        onClose={onClose}
        onSubmit={onSubmit}
        project={project}
        isTemplate={project.is_template}
      />
    </Suspense>
  )
}

export default EditProjectForm
