import { omit } from "lodash-es"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

import type { ExternalProjectMemberFormQuery } from "./__generated__/ExternalProjectMemberFormQuery.graphql"
import type { ExternalProjectMemberForm_viewer$key } from "./__generated__/ExternalProjectMemberForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getCurrentContract } from "~/helpers/person"
import { sortByString } from "~/helpers/sorting-helpers"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import { ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

import useCreateExtPersonLinks from "~/mutations/ExtPersonLink"
import {
  type PersonBulkCreateResponse,
  personBulkCreateRelay,
} from "~/mutations/Person"
import { projectMemberBulkCreateRelay } from "~/mutations/ProjectMember"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { filteredByProject } from "~/Planner/reducer2/projectFilterSlice"
import { showAllMembersInProject } from "~/Planner/reducer2/projectMembersViewSlice"

import RocketTakeOffTangram from "../CreateProject/RocketTakeOffTangram"

import ActivateExternalMembersForm from "./ActivateExternalMembersForm"
import ImportExternalMembersForm from "./ImportExternalMembersForm"
import { isMatchingPerson } from "./is-matching-person"

type Person = {
  id: number
  first_name: string
  last_name: string
  email: string
  role?: string
  role_id?: number
  checked?: boolean
}

type Props = {
  projectId: number
  extProjectId: number
  closeDialog: () => void
  formType: "import" | "activate"
}

const QUERY = graphql`
  query ExternalProjectMemberFormQuery($ext_project_id: Int) {
    current_user {
      id
      account {
        id
        ext_projects(where: { id: { _eq: $ext_project_id } }) {
          id
          name
          ext_project_links(where: { active: { _eq: true } }) {
            ext_project_id
            project_id
            project {
              id
              members {
                id
                person_id
              }
            }
          }
          ext_project_members(where: { deleted: { _eq: false } }) {
            id
            ext_person {
              id
              first_name
              last_name
              email
              ext_person_links(where: { active: { _eq: true } }) {
                person_id
                ext_person_id
                active
                external_id: ext_person_id
              }
            }
          }
        }
      }
    }
  }
`

const fragment = graphql`
  fragment ExternalProjectMemberForm_viewer on users
  @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
    account {
      id
      people(where: $peopleFilter) {
        id
        email
        first_name
        last_name
        active
        is_placeholder
        contracts {
          id
          employment_type
          minutes_per_day
          start_date: start_date_runn
          end_date: end_date_runn
          role {
            id
            name
          }
        }
      }
      roles {
        id
        name
      }
      rate_cards {
        id
        name
        active
      }
      clients {
        id
        active
        name
      }
    }
  }
`

const ExternalProjectMemberForm = (props: Props) => {
  const { closeDialog, projectId, extProjectId, formType } = props

  // map an extPersonId to a person
  const [extPersonToPersonMap, setExtPersonToPersonMap] = useState<
    Record<string, Person>
  >({})
  const [isSaving, setIsSaving] = useState(false)

  const response = useLazyLoadQuery<ExternalProjectMemberFormQuery>(QUERY, {
    ext_project_id: extProjectId,
  })
  const [createExtPersonLinks] = useCreateExtPersonLinks()
  const dispatch = useDispatch()

  const selectedExternalProject =
    response.current_user?.account?.ext_projects?.[0]

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<ExternalProjectMemberForm_viewer$key>(
    fragment,
    viewerQuery,
  )
  const people = viewer.account.people.filter(
    (person) => person.active && !person.is_placeholder,
  )
  const sortedPeople = people.sort((a, b) =>
    sortByString(a.first_name, b.first_name),
  )
  const roles = viewer.account.roles

  const matchExtProjectMembersToPeople = () => {
    const extProject = selectedExternalProject

    if (!extProject) {
      return
    }

    const newMap = {}
    extProject.ext_project_members.forEach((member) => {
      const linkedPerson =
        member.ext_person.ext_person_links.length > 0 &&
        people.find((person) => {
          return (
            person.id === member.ext_person.ext_person_links.at(-1).person_id
          )
        })

      const matchedPerson =
        linkedPerson ||
        people.find((person) => isMatchingPerson(member.ext_person, person))

      if (matchedPerson) {
        const defaultRole = getCurrentContract(matchedPerson.contracts)?.role
        newMap[member.ext_person.id] = {
          ...matchedPerson,
          role_id: defaultRole?.id,
          role: defaultRole?.name,
          checked: true,
        }
      }

      if (formType === "activate") {
        newMap[member.ext_person.id] = {
          ...newMap[member.ext_person.id],
          checked: true,
        }
      }
    })

    setExtPersonToPersonMap(newMap)
  }

  useEffect(() => {
    matchExtProjectMembersToPeople()
  }, [selectedExternalProject]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRunnPersonChange = (
    option: { label: string; value: number },
    extPersonId: number,
  ) => {
    const selectedPerson = people.find((person) => person.id === option.value)

    if (selectedPerson) {
      const selectedPersonRole = getCurrentContract(
        selectedPerson.contracts,
      )?.role
      const personWithRole = {
        ...selectedPerson,
        role_id: selectedPersonRole?.id,
        role: selectedPersonRole?.name,
        checked: true,
      }

      setExtPersonToPersonMap((prevMap) => {
        return {
          ...prevMap,
          [extPersonId]: personWithRole,
        }
      })
    } else {
      setExtPersonToPersonMap((prevMap) => {
        return {
          ...prevMap,
          [extPersonId]: {
            role: extPersonToPersonMap[extPersonId]?.role,
            role_id: extPersonToPersonMap[extPersonId]?.role_id,
            checked: true,
          },
        }
      })
    }
  }

  const handleProjectCreation = async () => {
    try {
      setIsSaving(true)

      const selectedMembers =
        selectedExternalProject.ext_project_members.filter(
          (member) =>
            extPersonToPersonMap[member.ext_person.id]?.id &&
            extPersonToPersonMap[member.ext_person.id]?.checked,
        )
      const projectMembers = selectedMembers.map((member) => {
        const person = extPersonToPersonMap[member.ext_person.id]
        return {
          role_id: person.role_id,
          is_placeholder: false,
          workstream_id: null,
          person_id: person.id,
          project_id: projectId,
        }
      })

      await projectMemberBulkCreateRelay({
        input: { project_members: projectMembers },
      })

      // Create new people
      const newPeople = selectedExternalProject.ext_project_members
        .filter(
          (member) =>
            !extPersonToPersonMap[member.ext_person.id]?.id &&
            extPersonToPersonMap[member.ext_person.id]?.checked,
        )
        .map((member) => {
          const person = extPersonToPersonMap[member.ext_person.id]
          return {
            email: member.ext_person.email || person.email,
            first_name: member.ext_person.first_name,
            last_name: member.ext_person.last_name,
            role_name: person.role,
            ext_person_id: member.ext_person.id,
          }
        })

      let createdPeople: PersonBulkCreateResponse[0]["person"][] = []

      if (newPeople.length) {
        const peopleAndAccount = await personBulkCreateRelay({
          variables: {
            input: { people: newPeople.map((p) => omit(p, "ext_person_id")) },
            plannerStartDate: PLANNER_INITIAL_DATE,
          },
        })
        createdPeople = peopleAndAccount.map((r) => r.person)

        const members = createdPeople.map((person) => ({
          role_id: getCurrentContract(person.contracts)?.role?.id,
          is_placeholder: false,
          workstream_id: null,
          person_id: person.id,
          project_id: projectId,
        }))

        await projectMemberBulkCreateRelay({
          input: { project_members: members },
        })
      }

      const createdPeopleWithExtPersonIds = createdPeople.map(
        (person, index) => ({
          person_id: person.id,
          // action_person_bulk_create maintains the order of the input
          // so we can use the index to match the ext_person_id
          ext_person_id: newPeople[index].ext_person_id,
        }),
      )

      const extPersonLinks = Object.keys(extPersonToPersonMap)
        .flatMap((key) => {
          const person = extPersonToPersonMap[key]
          if (!person?.id) {
            return []
          }
          return {
            ext_person_id: Number.parseInt(key, 10),
            person_id: person.id,
          }
        })
        .concat(createdPeopleWithExtPersonIds)

      if (extPersonLinks.length > 0) {
        createExtPersonLinks({
          extPersonLinks,
          clearExistingLinksOnExtPerson: true,
        })
      }

      setIsSaving(false)
      closeDialog()
    } catch (e) {
      console.error(e)
      setIsSaving(false)
    }
  }

  const handleRoleChange = (
    option: { label: string; value: number },
    extPersonId: number,
  ) => {
    setExtPersonToPersonMap((prevMap) => {
      return {
        ...prevMap,
        [extPersonId]: {
          ...prevMap[extPersonId],
          role_id: option.value,
          role: option.label,
        },
      }
    })
  }

  const getPersonValue = (extPersonId: number) => {
    const person = extPersonToPersonMap[extPersonId]

    return person?.id
      ? {
          label: `${person.first_name} ${person.last_name}`,
          value: person.id,
        }
      : person
        ? { ...person, label: "Create new", value: null }
        : { label: "Create new", value: null }
  }

  const handlePersonCheckboxChange = (extPersonId: number) => {
    setExtPersonToPersonMap((prevMap) => {
      return {
        ...prevMap,
        [extPersonId]: {
          ...prevMap[extPersonId],
          checked: !prevMap[extPersonId]?.checked,
        },
      }
    })
  }

  const toggleAllMembers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const newMap = {}
    selectedExternalProject?.ext_project_members.forEach((member) => {
      const extPerson = member.ext_person
      newMap[extPerson.id] = {
        ...extPersonToPersonMap[extPerson.id],
        checked,
      }
    })
    setExtPersonToPersonMap(newMap)
  }

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    extPersonId: number,
  ) => {
    const value = e.target.value
    setExtPersonToPersonMap((prevMap) => {
      return {
        ...prevMap,
        [extPersonId]: {
          ...prevMap[extPersonId],
          email: value,
        },
      }
    })
  }

  // Ensure that every person has a role
  const addToProjectDisabled =
    isSaving ||
    !Object.values(extPersonToPersonMap).every((person) => {
      if (person.checked) {
        return person.role && person.role_id
      }
      return true
    })

  const selectedExtProjectLink =
    selectedExternalProject?.ext_project_links.find(
      (link) => link.project_id === projectId,
    )

  const filteredSelectedExternalMembers =
    selectedExternalProject?.ext_project_members.filter(
      (member) =>
        !member.ext_person.ext_person_links.some((link) =>
          selectedExtProjectLink?.project.members.some(
            (projMember) => projMember.person_id === link.person_id,
          ),
        ),
    )

  const handleCloseDialog = () => {
    dispatch(filteredByProject(projectId))
    dispatch(changeActivePage("projects"))
    dispatch(showAllMembersInProject(projectId))
    closeDialog()
  }

  return (
    <ModalFormWrapper
      tangram={<RocketTakeOffTangram activate={false} />}
      tangramStyles={{ marginBottom: 15 }}
      autoWidth
    >
      {formType === "activate" ? (
        <ActivateExternalMembersForm
          extPersonToPersonMap={extPersonToPersonMap}
          selectedExternalMembers={filteredSelectedExternalMembers}
          sortedPeople={sortedPeople.filter(
            (person) =>
              !selectedExtProjectLink?.project.members.some(
                (projMember) => projMember.person_id === person.id,
              ),
          )}
          roles={[...roles]}
          toggleAllMembers={toggleAllMembers}
          handlePersonCheckboxChange={handlePersonCheckboxChange}
          handleEmailChange={handleEmailChange}
          handleRunnPersonChange={handleRunnPersonChange}
          handleRoleChange={handleRoleChange}
          getPersonValue={getPersonValue}
        />
      ) : (
        <ImportExternalMembersForm
          extProjectMembers={selectedExternalProject?.ext_project_members ?? []}
          extPersonToPersonMap={extPersonToPersonMap}
          selectedExternalMembers={filteredSelectedExternalMembers}
          sortedPeople={sortedPeople.filter(
            (person) =>
              !selectedExtProjectLink?.project.members.some(
                (projMember) => projMember.person_id === person.id,
              ),
          )}
          roles={[...roles]}
          toggleAllMembers={toggleAllMembers}
          handlePersonCheckboxChange={handlePersonCheckboxChange}
          handleEmailChange={handleEmailChange}
          handleRunnPersonChange={handleRunnPersonChange}
          handleRoleChange={handleRoleChange}
          getPersonValue={getPersonValue}
        />
      )}
      <ModalFooter>
        <Button text="Close" onClick={handleCloseDialog} />
        <Button
          text="Add to project"
          loading={isSaving}
          id="create-project-from-integration-button"
          outlined={false}
          onClick={handleProjectCreation}
          disabled={addToProjectDisabled}
          style={{ marginLeft: 7 }}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default ExternalProjectMemberForm
