/**
 * @generated SignedSource<<cc0a752c196694bb03a1ce2d851a22d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectMemberBulkCreateInput = {
  project_members: ReadonlyArray<ProjectMember>;
};
export type ProjectMember = {
  is_placeholder: boolean;
  person_id: number;
  project_id: number;
  role_id: number;
  workstream_id?: number | null | undefined;
};
export type RemoveMembershipFromProjectMenuItem_AddMutation$variables = {
  input: ProjectMemberBulkCreateInput;
};
export type RemoveMembershipFromProjectMenuItem_AddMutation$data = {
  readonly action_project_member_bulk_create: ReadonlyArray<{
    readonly person: {
      readonly id: number;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly project: {
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly person: {
          readonly id: number;
        };
        readonly person_id: number;
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type RemoveMembershipFromProjectMenuItem_AddMutation = {
  response: RemoveMembershipFromProjectMenuItem_AddMutation$data;
  variables: RemoveMembershipFromProjectMenuItem_AddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProjectMemberBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_project_member_bulk_create",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "project_memberships",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstream_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveMembershipFromProjectMenuItem_AddMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveMembershipFromProjectMenuItem_AddMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "90d4a72cc20674d9214cc1b7a97fa0dc",
    "id": null,
    "metadata": {},
    "name": "RemoveMembershipFromProjectMenuItem_AddMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveMembershipFromProjectMenuItem_AddMutation(\n  $input: ProjectMemberBulkCreateInput!\n) {\n  action_project_member_bulk_create(input: $input) {\n    person {\n      id\n      project_memberships {\n        id\n      }\n    }\n    project {\n      id\n      members {\n        id\n        project_id\n        role_id\n        workstream_id\n        person_id\n        person {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a959b76c6212ba7b338b5aa03a4c62a3";

export default node;
