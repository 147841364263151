/**
 * @generated SignedSource<<d42c3a9ec74a53d75b075dc8f82b20f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveMembershipFromProjectMenuItem_project$data = {
  readonly id: number;
  readonly members: ReadonlyArray<{
    readonly id: number;
    readonly just_added_timestamp: number | null | undefined;
    readonly person_id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly name: string;
  readonly " $fragmentType": "RemoveMembershipFromProjectMenuItem_project";
};
export type RemoveMembershipFromProjectMenuItem_project$key = {
  readonly " $data"?: RemoveMembershipFromProjectMenuItem_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveMembershipFromProjectMenuItem_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveMembershipFromProjectMenuItem_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "just_added_timestamp",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "de2a60e4498f9c426acb2a9a40a3a1d5";

export default node;
