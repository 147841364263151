import { Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { useFeature } from "flagged"
import flatten from "lodash-es/flatten"
import React, { useEffect, useState } from "react"
import { shallowEqual } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./AddTimeOffModal.module.css"

import { AddTimeOffModal_account$key } from "./__generated__/AddTimeOffModal_account.graphql"
import { TimeOffSummary_account$data } from "./__generated__/TimeOffSummary_account.graphql"

import { buildImageUrl } from "~/helpers/image"
import {
  formatName,
  formatNameAsInitials,
  getCurrentContract,
} from "~/helpers/person"

import Avatar from "~/common/Avatar"
import HeadingBar from "~/common/HeadingBar"
import IconPlus from "~/common/IconPlus"
import { updateAndCreateTimeOffs } from "~/common/Pill/TimeOffActionHelpers"
import PlannerDatePicker from "~/common/PlannerDatePicker/PlannerDatePicker"
import {
  SelectorFooter,
  SelectorHeader,
  SelectorListContainer,
  SelectorListItem,
  SelectorModal,
  SelectorSearch,
} from "~/common/SelectorModal"
import {
  FilterGroup,
  filterPeopleWithValueGrouped,
} from "~/common/SelectorModal/SelectorHelpers"
import AddButton from "~/common/buttons/AddButton"
import Checkbox from "~/common/inputs/Checkbox"

import { useAppSelector } from "~/hooks/redux"

type Props = {
  account: AddTimeOffModal_account$key
  people: TimeOffSummary_account$data["people"]
  showModal: boolean
  setShowModal: (boolean) => void
}

const AddTimeOffModal = (props: Props) => {
  const { people, setShowModal, showModal } = props

  const account = useFragment(
    graphql`
      fragment AddTimeOffModal_account on accounts {
        ...PlannerDatePicker_account
      }
    `,
    props.account,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const [filterValue, setFilterValue] = useState("")
  const [showTimeOffForm, setShowTimeOffForm] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(undefined)
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<number[]>([])
  const [multiSelect, setMultiSelect] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [isHovering, setIsHovering] = useState(null)

  const addMulti = () => {
    const fakePerson = {
      id: "fakePersonForMultiSelect",
      time_offs: [],
      contracts: [{ start_date: "01012019", end_date: "01012020" }],
      assignments: [],
    }
    setMultiSelect(true)
    setSelectedPerson(fakePerson)
    setShowTimeOffForm(true)
    setSelectAllChecked(false)
  }

  const onKeyPress = (e) => {
    // 13 === enter key
    if (e.keyCode === 13 && selectedPeopleIds.length > 0) {
      addMulti()
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress)
    return () => {
      document.removeEventListener("keypress", onKeyPress)
    }
  })

  const closeDialog = () => {
    setShowModal(false)
    setShowTimeOffForm(false)
    setFilterValue("")
    setSelectedPeopleIds([])
    setSelectAllChecked(false)
  }

  const handleAdd = (person) => {
    setSelectedPerson(person)
    setShowTimeOffForm(true)
    setFilterValue("")
    setSelectAllChecked(false)
  }

  const handleCancel = () => {
    setShowTimeOffForm(false)
    setFilterValue("")
    setSelectAllChecked(false)
  }

  const validPeople = people.filter(
    (p) => getCurrentContract(p.contracts) && !p.is_placeholder && p.active,
  )

  const groupedPeople = filterPeopleWithValueGrouped({
    people: validPeople,
    filterValue,
  })

  const peopleInView = flatten(groupedPeople.map((pg) => pg.people))

  const peopleInViewNotYetSelected = peopleInView
    .filter((person) => !selectedPeopleIds.includes(person?.id))
    .map((p) => {
      return p.id
    })

  const noResultsFound = !groupedPeople.some((group) => !!group.people.length)

  useEffect(() => {
    setSelectAllChecked(!peopleInViewNotYetSelected.length)
  }, [peopleInViewNotYetSelected.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const multiSelectItemIds = useAppSelector(
    (state) => state.multiSelect.items.map((i) => i.id),
    shallowEqual,
  )

  const onCreateTimeOff = (to) => {
    if (multiSelect) {
      selectedPeopleIds.forEach((pId) => {
        const person = people.find((p) => p.id === pId)
        const timeOff = {
          start_date: dateHelpers.formatToRunnDate(to.startDate),
          end_date: dateHelpers.formatToRunnDate(to.endDate),
          person_id: person.id,
          note: to.note,
        }

        void updateAndCreateTimeOffs(
          timeOff,
          person,
          multiSelectItemIds,
          !isConsistentTimeOffEnabled,
        )
      })

      setMultiSelect(false)
      setSelectedPeopleIds([])
    } else {
      const person = people.find((p) => p.id === to.personId)
      const timeOff = {
        start_date: dateHelpers.formatToRunnDate(to.startDate),
        end_date: dateHelpers.formatToRunnDate(to.endDate),
        person_id: person.id,
      }

      void updateAndCreateTimeOffs(
        timeOff,
        person,
        multiSelectItemIds,
        !isConsistentTimeOffEnabled,
      )
    }
    closeDialog()
  }

  const onSelectionChange = (personId) => {
    // If the person is already is the list, remove them.
    // If they aren't add them
    const person = selectedPeopleIds.find((pId) => pId === personId)
    if (person != null) {
      setSelectedPeopleIds(selectedPeopleIds.filter((pId) => pId !== personId))
    } else {
      setSelectedPeopleIds([...selectedPeopleIds, personId])
    }
  }

  const onSelectAllChange = (e) => {
    if (selectAllChecked) {
      // handle deselect all checkbox - only uncheck the people in view
      const peopleInViewIds = peopleInView.map((p) => p.id)
      return setSelectedPeopleIds(
        selectedPeopleIds.filter((p) => !peopleInViewIds.includes(p)),
      )
    }

    // handle select all checkbox - only add people in view not yet selected
    setSelectedPeopleIds([...selectedPeopleIds, ...peopleInViewNotYetSelected])
  }

  const renderPersonItem = (person) => {
    const isSelected =
      selectedPeopleIds.findIndex((personId) => personId === person.id) >= 0
    const personFormattedName = `${formatName(
      person.first_name,
      person.last_name,
    )} ${person.team ? "(" + person.team.name + ")" : ""}`
    return (
      <div
        style={{ position: "relative" }}
        key={person.id}
        onMouseEnter={() => setIsHovering(person.id)}
        onMouseLeave={() => setIsHovering(null)}
      >
        <SelectorListItem onClick={() => onSelectionChange(person.id)}>
          <Avatar
            email={person.email}
            initials={formatNameAsInitials(person.first_name, person.last_name)}
            avatar={buildImageUrl(person.image_key)}
            is_placeholder={person.is_placeholder}
            size={28}
            name={personFormattedName}
          />
        </SelectorListItem>
        {isHovering === person.id && selectedPeopleIds.length < 1 && (
          <div className={styles.quickAdd} onClick={() => handleAdd(person)}>
            <Tooltip content="Quick Add" placement="top">
              <IconPlus customPlusColor="white" />
            </Tooltip>
          </div>
        )}
        <div className={styles.checkboxContainer}>
          <Checkbox
            id={`AddTimeOffModal_Checkbox_isSelected_${person.id}`}
            value={person.id}
            onChange={() => onSelectionChange(person.id)}
            checked={isSelected}
          />
        </div>
      </div>
    )
  }

  const selectedPeopleNames = selectedPeopleIds
    .map((id) => {
      const person = people.find((p) => p.id === id)
      return formatName(person.first_name, person.last_name)
    })
    .join(", ")

  const numberOfPeople = `${selectedPeopleIds.length} ${
    selectedPeopleIds.length === 1 ? "person" : "people"
  }`

  const multiPerson = selectedPeopleIds
    .map((id) => people.find((p) => p.id === id))
    .map((person) => ({
      id: person.id,
      email: person.email,
      first_name: person.first_name,
      last_name: person.last_name,
      is_placeholder: person.is_placeholder,
      contracts: [],
      assignments: [],
    }))

  return (
    <SelectorModal isOpen={showModal} onClose={closeDialog} modalWidth={700}>
      {showTimeOffForm ? (
        <PlannerDatePicker
          account={account}
          person={selectedPerson}
          personId={selectedPerson?.id || ""}
          multiPerson={multiPerson}
          onSubmit={onCreateTimeOff}
          handleCancel={handleCancel}
          isTimeOff
        />
      ) : (
        <>
          <SelectorHeader
            title="Add time off to people"
            onClose={closeDialog}
          />
          <SelectorSearch
            isPersonSearch
            searchValue={filterValue}
            setSearchValue={setFilterValue}
            checkboxProps={{
              id: "AddTimeOffModal_Checkbox_selectAll",
              onChange: onSelectAllChange,
              checked: selectAllChecked && !noResultsFound,
            }}
          />
          <SelectorListContainer
            type="people"
            noActiveItems={!people.some((p) => p.active)}
            filterValue={filterValue}
            noResultsFound={noResultsFound}
          >
            {groupedPeople.map((group) => {
              return (
                <div key={group.type}>
                  {Boolean(group.people.length) &&
                    group.type !== FilterGroup.DEFAULT && (
                      <HeadingBar
                        text={`${group.type} with "${filterValue}"`}
                      />
                    )}
                  {group.people.map(renderPersonItem)}
                </div>
              )
            })}
          </SelectorListContainer>
          <SelectorFooter>
            {selectedPeopleIds.length > 0 && (
              <Tooltip content={selectedPeopleNames}>
                <span>{numberOfPeople} selected</span>
              </Tooltip>
            )}
            <AddButton
              text="Add Selected"
              outlined={false}
              disabled={!selectedPeopleIds.length}
              onClick={addMulti}
              style={{ marginLeft: 10 }}
            />
          </SelectorFooter>
        </>
      )}
    </SelectorModal>
  )
}

export type Role = {
  id: number
  name: string
}
export type Person = {
  id: number
  first_name: string
  last_name: string
  is_placeholder: boolean
  assignments: readonly Assignment[]
  contracts: ReadonlyArray<{
    start_date: string
    end_date: string
    minutes_per_day: number
  }>
}

type Assignment = {
  id?: number
  project_id?: number
  person_id: number
  role_id: number
  phase_id: number
  filter: any
  end_date: string
  is_billable: boolean
  minutes_per_day: number
  project_role_id: number
  start_date: string
  time_offs?: []
  non_working_day: boolean
}

export default AddTimeOffModal
