const getScrollbarSize = () => {
  const el = document.createElement("div")
  el.style.visibility = "hidden"
  el.style.overflow = "scroll"

  document.body.appendChild(el)

  const width = el.offsetWidth - el.clientWidth
  const height = el.offsetHeight - el.clientHeight

  document.body.removeChild(el)

  return {
    width,
    height,
  }
}

const size = getScrollbarSize()

const SCROLLBAR_WIDTH = size.width
const SCROLLBAR_HEIGHT = size.height

export { SCROLLBAR_WIDTH, SCROLLBAR_HEIGHT }
