import React from "react"

import { Warning } from "~/common/react-icons"

export const PERSON_ARCHIVE_HINT = (
  <div>
    You cannot archive someone with future assignments.
    <br />
    <br />
    <Warning /> Remove all future assignments including from{" "}
    <strong>tentative projects</strong> and <strong>project templates</strong>.
  </div>
)

export const RATE_CARDS_DELETE_HINT =
  "You cannot delete a rate card that is used by a project. We recommend you archive this rate card instead."

export const PROJECT_DELETE_HINT =
  "Projects with existing assignments or timesheets can only be deleted by an admin with permission to edit account settings. Please archive instead or contact an admin."

export const PERSON_DELETE_HINT =
  "People with existing assignments or timesheets can only be deleted by an admin with permission to edit account settings. Please archive instead or contact an admin."

export const PROJECT_ARCHIVE_HINT =
  "You cannot archive, as the associated client is archived."

export const PROJECT_ARCHIVE_HAS_FUTURE_ASSIGNMENTS_HINT =
  "You cannot archive projects with future assignments. Please delete all future assignments. If this project never occurred, use delete instead."
