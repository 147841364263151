import * as fe from "@runn/filter-engine"
import React from "react"

import FilterBlockBoolean from "./common/FilterBlockBoolean"
import type { FilterListItem } from "~/common/SuperSearch/types"
import { Star as Icon } from "~/common/react-icons"

type Props = {
  path: number[]
  filter: fe.filters.FilterProjectIsFavourite
  disabled?: boolean
  onChange: (path: number[], filter: FilterListItem) => void
  onRemove: (path: number[]) => void
}

const Component = (props: Props) => {
  const { path, onRemove, disabled } = props

  const handleRemove = () => onRemove(path)

  return (
    <FilterBlockBoolean
      label="Starred"
      icon={<Icon />}
      onRemove={handleRemove}
      disabled={disabled}
    />
  )
}

export const ProjectIsFavourite = {
  Component,
}
