/**
 * @generated SignedSource<<10e2f57e7c43667ed0bda47df652a9d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ManagerSelectorQuery$variables = Record<PropertyKey, never>;
export type ManagerSelectorQuery$data = {
  readonly current_account: {
    readonly id: number;
    readonly user_accounts: ReadonlyArray<{
      readonly id: number;
      readonly permissions: any;
      readonly user: {
        readonly first_name: string | null | undefined;
        readonly id: number;
        readonly last_name: string | null | undefined;
        readonly permissions: any;
      };
    }>;
  } | null | undefined;
};
export type ManagerSelectorQuery = {
  response: ManagerSelectorQuery$data;
  variables: ManagerSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "accounts",
    "kind": "LinkedField",
    "name": "current_account",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user_accounts",
        "kind": "LinkedField",
        "name": "user_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagerSelectorQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManagerSelectorQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "104ebd9ff2139133cbbfa9352c04814f",
    "id": null,
    "metadata": {},
    "name": "ManagerSelectorQuery",
    "operationKind": "query",
    "text": "query ManagerSelectorQuery {\n  current_account {\n    id\n    user_accounts {\n      id\n      permissions\n      user {\n        id\n        permissions\n        first_name\n        last_name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a31bf5ff0cc7b5d976907139be981dce";

export default node;
