import React from "react"

import Legend from "~/Planner/ChartPanel/Chart/common/Legend"

const LegendUtilization = () => {
  return (
    <Legend>
      <Legend.Item background="rgba(var(--runn-blue_rgb), 0.4)">
        Billable
      </Legend.Item>
      <Legend.Item background="rgba(var(--slate_rgb), 0.4)">
        Non Billable
      </Legend.Item>
    </Legend>
  )
}

export { LegendUtilization }
