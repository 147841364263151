import cc from "classcat"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./ProjectRowSummary.module.css"

import { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"
import { ProjectRowSummary_account$key } from "./__generated__/ProjectRowSummary_account.graphql"
import { ProjectRowSummary_project$key } from "./__generated__/ProjectRowSummary_project.graphql"

import { track } from "../helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { parseFavouriteProjectIds } from "~/helpers/favourites"
import * as hashids from "~/helpers/hashids"
import { Assignment } from "~/helpers/planner-helpers"

import ProjectClientIcon from "../common/ProjectClientIcon"
import ClientDetails from "~/common/ClientDetails"
import { SkeletonRow } from "~/common/LoadingSkeleton"

import { userFavouriteProjectsUpdateRelay } from "~/mutations/User"

import { isSplitScreenMode } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn } from "~/Planner/PlannerLayout"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { selectIsTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { useIsInViewport } from "~/hooks/useIsInViewport"
import { ReduxState } from "~/rootReducer"

import { ProjectOverview } from "./ProjectOverview"

type Props = {
  userId: number
  project: ProjectRowSummary_project$key
  account: ProjectRowSummary_account$key
  projectExpanded: boolean
  favourites: ProjectManagement_user$data["favourite_projects"]
  handleToggleRow: () => void
  locatedInSchedulePanel?: boolean
}

const projectFragment = graphql`
  fragment ProjectRowSummary_project on projects
  @argumentDefinitions(todaysDate: { type: "date!" }) {
    id
    name
    confirmed
    is_template
    created_at
    calc_start_date
    archivable
    has_actuals
    tags_computed
    active
    priority
    pricing_model
    total_budget: total_budget_private
    expenses_budget
    emoji
    client {
      id
      name
      image_key
      website
      real_client
      internal
    }
    project_roles {
      id
      role_id
      estimated_minutes
    }
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      value
      typeId: custom_checkbox_type_id
    }
    actuals_aggregate {
      aggregate {
        count
      }
    }
    assignments_aggregate {
      aggregate {
        count
      }
    }
    links {
      id
      name
      href
      show_in_planner
    }
    future_assignments_aggregate: assignments_aggregate(
      where: { end_date_iso: { _gte: $todaysDate } }
    ) {
      aggregate {
        count
      }
    }
    ...PlannerLeftColumn_project
    ...ProjectLine_project
    ...ProjectMilestones_project
    ...EditProjectForm_project
    ...CreateProjectFromTemplateForm_project
    ...CreateTemplateFromProjectForm_project
    ...PhasesWrapper_project
  }
`

const accountFragment = graphql`
  fragment ProjectRowSummary_account on accounts {
    custom_text_types_project: custom_text_types(
      where: { model: { _eq: "PROJECT" } }
    ) {
      id
      name
      show_in_planner
      filterable_in_planner
    }
    custom_date_types_project: custom_date_types(
      where: { model: { _eq: "PROJECT" } }
    ) {
      id
      name
      show_in_planner
      filterable_in_planner
    }
    custom_select_types_project: custom_select_types(
      where: { model: { _eq: "PROJECT" } }
    ) {
      id
      name
      options: custom_select_options {
        id
        name
      }
      show_in_planner
      filterable_in_planner
    }
    custom_checkbox_types_project: custom_checkbox_types(
      where: { model: { _eq: "PROJECT" } }
    ) {
      id
      name
      show_in_planner
      filterable_in_planner
    }
    ...PlannerLeftColumn_account
  }
`

const ProjectRowSummary = (props: Props) => {
  const {
    userId,
    projectExpanded,
    handleToggleRow,
    favourites: rawFavourites,
    locatedInSchedulePanel,
  } = props

  const project = useFragment(projectFragment, props.project)
  const account = useFragment(accountFragment, props.account)
  const collapsible = !locatedInSchedulePanel

  const { can, subject } = usePermissions()
  const userSubject = subject("User", { id: userId })
  const userPermissions = {
    edit: can("edit", userSubject),
  }

  const alwaysShowProjectPhases = useSelector(
    (state: ReduxState) => state.planner.showProjectPhases,
  )

  const favourites = parseFavouriteProjectIds(rawFavourites)
  const [isStarred, setIsStarred] = useState(false)

  useEffect(() => {
    setIsStarred(
      !!favourites.length ? favourites.some((id) => id === project.id) : false,
    )
  }, [favourites]) // eslint-disable-line react-hooks/exhaustive-deps

  const ref = useRef(null)
  const isProjectRowSummaryInViewport = useIsInViewport(ref, {
    delay: 0,
    keepVisible: false,
    offsetY: 400,
    rootQuerySelector: "#project-planner-list",
  })

  const client = project.client

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_project,
    custom_date_types: account.custom_date_types_project,
    custom_select_types: account.custom_select_types_project,
    custom_checkbox_types: account.custom_checkbox_types_project,
  })
  const customFieldValues = valuesMap(project)

  const starProject = (e: React.MouseEvent) => {
    e.stopPropagation()

    const favouriteProjects = isStarred
      ? favourites.filter((id) => id !== project.id)
      : [...favourites, project.id]

    if (!isStarred) {
      if (project.is_template) {
        track("Project Template Starred")
      } else {
        track("Project Starred")
      }
    }

    void userFavouriteProjectsUpdateRelay({ input: { favouriteProjects } })
  }

  const isTentativeProjectEnabled = useAppSelector((state) =>
    selectIsTentativeProjectEnabled(
      state.plannerV2.scenarioPlanning,
      project.id,
    ),
  )

  const tentativeDisabled = !isTentativeProjectEnabled && !project.confirmed

  const noClientProjectTemplate =
    !project.client?.real_client && project.is_template

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  const multiSelectItem = useAppSelector(
    (state) => state.multiSelect.items[0],
  ) as Assignment

  const disableRow =
    tentativeDisabled ||
    (isSplitScreenMode(modeAction) &&
      multiSelectItem &&
      multiSelectItem?.project_id !== project.id)

  const projectURL = `/${
    project.is_template ? "project_templates" : "projects"
  }/${hashids.projects.encode(project.id)}`

  return (
    <PlannerGrid
      ref={ref}
      // the `userflow` classes are used on Userflow to help users onboarding
      className={cc([
        `userflow-project-row`,
        {
          [styles.templateRowSummary]: project.is_template,
          [styles.projectRowSummary]: !project.is_template,
          [styles.rowExpanded]: projectExpanded,
          [styles.alwaysShowProjectPhases]: alwaysShowProjectPhases,
        },
      ])}
      data-test="ProjectPlanner_ProjectRowSummary"
      disabled={disableRow}
      allowInteraction={disableRow}
    >
      {isProjectRowSummaryInViewport ? (
        <PlannerLeftColumn
          type={project.is_template ? "project-template" : "project"}
          editProjectQuery={project}
          createTemplateFromProjectQuery={!project.is_template ? project : null}
          createProjectFromTemplateQuery={project.is_template ? project : null}
          paddingHeight={!project.confirmed ? 8 : 12}
          account={account}
          project={project}
          style={{ cursor: "pointer" }}
          onClick={handleToggleRow}
          isStarred={isStarred}
          handleStar={starProject}
          showMenuDots
          showStar={userPermissions.edit}
          collapsible={collapsible}
          isCollapsed={!projectExpanded}
          data-test={`planner-left-column-${project.name}`}
          locatedInSchedulePanel={locatedInSchedulePanel}
        >
          <>
            <ProjectClientIcon project={project} client={client} />
            <ClientDetails
              id={project.id}
              hideLogo
              title={project.name}
              subtitle={noClientProjectTemplate ? "" : client?.name}
              imageKey={client.image_key}
              website={client.website}
              href={projectURL}
              tentative={!project.confirmed}
              isTemplateRow={project.is_template}
              icons={
                <PlannerTooltipIcons
                  icons={{
                    tags: project.tags_computed,
                    customFields: {
                      values: customFieldValues,
                      types: customFieldTypes,
                    },
                    links: project.links
                      ? project.links.filter((link) => link.show_in_planner)
                      : [],
                  }}
                />
              }
              tentativePlacement="bottom"
              priority={project.priority}
              projectEmoji={project.emoji}
            />
          </>
        </PlannerLeftColumn>
      ) : (
        <SkeletonRow height={57} />
      )}
      {isProjectRowSummaryInViewport && (
        <div style={{ borderLeft: "1px solid var(--cloud)" }}>
          <ProjectOverview project={project} />
        </div>
      )}
    </PlannerGrid>
  )
}

export default React.memo(ProjectRowSummary)
