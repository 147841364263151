import React from "react"
import { graphql, useFragment } from "react-relay"

import { HolidaysGroupSelector_viewer$key } from "./__generated__/HolidaysGroupSelector_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import Select from "~/common/Select"

export type HolidaysOption = {
  value: number
  label: string
}

type Props = {
  selectedHolidaysGroup?: HolidaysOption
  setSelectedHolidaysGroup?: (updatedSkills: HolidaysOption) => void
}

const fragment = graphql`
  fragment HolidaysGroupSelector_viewer on users {
    account {
      id
      holidays_groups(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`

const HolidaysGroupSelector = (props: Props) => {
  const { selectedHolidaysGroup, setSelectedHolidaysGroup, ...rest } = props
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<HolidaysGroupSelector_viewer$key>(
    fragment,
    viewerQuery,
  )
  const { holidays_groups } = viewer.account

  const handleOnChange = (value: HolidaysOption) => {
    setSelectedHolidaysGroup(value)
  }

  const holidaysOptions: HolidaysOption[] = holidays_groups.map((group) => {
    return {
      value: group.id,
      label: group.name,
    }
  })

  return (
    <Select
      id="holidays-group-selector"
      label="Public Holidays (optional)"
      onChange={handleOnChange}
      options={holidaysOptions}
      value={selectedHolidaysGroup}
      placeholder="Select a holiday group"
      isClearable={true}
      {...rest}
    />
  )
}

export default HolidaysGroupSelector
