import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"

import { PersonCustomFieldTypes_accounts$key } from "~/CustomFields/__generated__/PersonCustomFieldTypes_accounts.graphql"

import {
  CustomTypeName,
  sortCustomFields,
} from "~/helpers/custom-field-helpers"

import { UseCustomFieldsOutput } from "~/CustomFields/types"

export const usePersonCustomFieldTypes = (
  account: PersonCustomFieldTypes_accounts$key,
): UseCustomFieldsOutput<"PERSON"> => {
  const data = useFragment<PersonCustomFieldTypes_accounts$key>(
    graphql`
      fragment PersonCustomFieldTypes_accounts on accounts {
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          singleSelect: single_select
          model
          required
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
      }
    `,
    account,
  )

  const {
    custom_text_types_person: text,
    custom_date_types_person: date,
    custom_select_types_person: select,
    custom_checkbox_types_person: checkbox,
  } = data

  return useMemo(
    () => ({
      model: "PERSON" as const,
      text: text.slice().sort(sortCustomFields),
      date: date.slice().sort(sortCustomFields),
      select: select
        .map((item) => ({
          ...item,
          typeName: CustomTypeName.SELECT,
          single_select: item.singleSelect,
        }))
        .sort(sortCustomFields),
      checkbox: checkbox.slice().sort(sortCustomFields),
    }),
    [text, select, checkbox, date],
  )
}
