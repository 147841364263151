import cc from "classcat"
import React from "react"

import styles from "./LegendItem.module.css"

type LegendItemProps = {
  background: string
  children?: React.ReactNode
  square?: boolean
}

const LegendItem = (props: LegendItemProps) => {
  const { background, children, square = false } = props

  return (
    <div className={styles.container}>
      <div
        className={cc([
          styles.colorIcon,
          {
            [styles.square]: square,
          },
        ])}
        style={{ background }}
      />
      {children}
    </div>
  )
}

export { LegendItem }
