import React, { useCallback, useState } from "react"

import {
  CustomSelectType,
  CustomSelectValue,
} from "~/helpers/custom-field-helpers"

import Select from "~/common/Select"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"

import { CustomEditorFieldProps } from "~/CustomEditor/types"

type Props = CustomEditorFieldProps<CustomSelectType, CustomSelectValue[]>

const CustomEditorSelectField = ({
  id,
  type,
  value,
  onChange,
  formatSelectOptions,
}: Props) => {
  const [localValue, setLocalValue] = useState<Props["value"]>(value || [])
  const handleChange = useCallback(
    (updatedLabelledValue) => {
      const updatedValue = (
        Boolean(updatedLabelledValue)
          ? Array.isArray(updatedLabelledValue)
            ? updatedLabelledValue
            : [updatedLabelledValue]
          : []
      ).map((v) => ({ typeId: type.id, optionId: v.value }))

      onChange({ type, value: updatedValue })
      setLocalValue(updatedValue)
    },
    [type, onChange],
  )
  const labelledOptions = type.options.map((o) => ({
    label: o.name,
    value: o.id,
  }))
  const labelledValues = localValue.map((v) => {
    const option = type.options.find((o) => o.id === v.optionId)
    return {
      label: option?.name ?? "",
      value: v.optionId,
    }
  })

  const formattedOptions = formatSelectOptions
    ? formatSelectOptions(labelledOptions, type.name)
    : labelledOptions

  return (
    <Select
      label={
        <TitledHelpTooltip
          title={type.name}
          tooltipContent={type.description}
        />
      }
      name={`${id}-field`}
      value={labelledValues}
      options={formattedOptions}
      isClearable={type.single_select}
      isMulti={!type.single_select}
      onChange={handleChange}
    />
  )
}

export default CustomEditorSelectField
