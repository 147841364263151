import Resource from "~/store/Resource"

type PromiseCreator<T> = () => Promise<T>

type CacheEntry<T> = {
  resource: Resource<T>
  timestamp: number
}

// registry is pinned to the module so that it is resiliant
// against unmounts
const registry: Record<string, CacheEntry<any>> = {}
const CACHE_DURATION = 1000 * 60 * 2 // 2 minutes

// Technically, this isn't a hook, but it's used like one,
// so it gets the prefix
export const useSuspendedPromise = <T>(
  key: string,
  createPromise: PromiseCreator<T>,
): T => {
  const now = Date.now()

  // Check if the cache entry exists and is still valid
  if (!registry[key] || now - registry[key].timestamp > CACHE_DURATION) {
    registry[key] = {
      resource: new Resource(createPromise()),
      timestamp: now,
    }
  }

  const result = registry[key].resource.read()

  return result
}
