import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useReducer, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./SplitScreenPanel.module.css"

import { SplitScreenPanel_user$key } from "./__generated__/SplitScreenPanel_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { Assignment } from "~/helpers/planner-helpers"

import AddSection from "~/common/AddSection"
import viewsReducer from "~/common/views.reducer"

import ResizablePanel from "~/Planner/ResizablePanel"
import { useAppSelector } from "~/hooks/redux"

import { buildPeopleFilter } from "../buildHasuraFilter"
import {
  setTemporaryPlannerFilter,
  setTemporaryPlannerFilterToAll,
} from "../reducer2/viewsSlice"

import SplitScreenHeader from "./SplitScreenHeader"
import SplitScreenMain from "./SplitScreenMain"

const fragment = graphql`
  fragment SplitScreenPanel_user on users
  @argumentDefinitions(
    plannerStartDate: { type: "date!" }
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    ...SplitScreenMain_user
    view {
      id
    }
    favourite_people
    account {
      default_full_time_minutes
      projects(where: $projectsFilter) {
        ...PlaceholderCapacityIndicator_projects
        id
        name
        active
        confirmed
        is_template
        active
        client {
          id
          name
        }
        team {
          id
          name
        }
        tags_computed
        members {
          id
          person_id
          role_id
          project_id
          workstream_id
          is_placeholder
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        managers {
          id
          user_id
        }
      }
      views {
        id
        people_filters
      }
      people(where: $peopleFilter) {
        ...SplitScreenRow_person @arguments(plannerStartDate: $plannerStartDate)
        id
        first_name
        last_name
        email
        tags
        references
        image_key
        is_placeholder
        active
        team {
          name
          id
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          person_id
          leave_type
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          project_id
          person_id
          role_id
          non_working_day
          note
          phase_id
        }
        contracts {
          id
          rostered_days
          employment_type
          minutes_per_day
          cost: cost_private
          start_date: start_date_runn
          end_date: end_date_runn
          job_title
          role {
            id
            name
          }
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        managers {
          id
          user_id
        }
      }
    }
  }
`
const SplitScreenPanel = () => {
  const data = useFragment<SplitScreenPanel_user$key>(
    fragment,
    useHasuraContext(),
  )
  const dispatch = useDispatch()

  const { view } = data
  const { people, projects, default_full_time_minutes } = data.account

  const isFeatureViewEnabled = useFeature("pre_filtered_views")

  const [viewState, setViewState] = useReducer(viewsReducer, {
    showAllView: !view,
    viewId: view?.id ?? null,
  })
  const { viewId, showAllView } = viewState

  const handleChangeViewId = (nextViewId: number) => {
    setViewState({ type: "VIEW_SET", payload: { viewId: nextViewId } })
    if (nextViewId !== data?.view?.id) {
      const peopleFilters = buildPeopleFilter(
        fe.filters.flat(
          data.account.views.find((v) => v.id === nextViewId)?.people_filters,
        ),
      )
      dispatch(setTemporaryPlannerFilter({ peopleFilters }))
    }
  }

  const handleChangeShowAllView = () => {
    setViewState({ type: "VIEW_SHOW_ALL" })
    dispatch(setTemporaryPlannerFilterToAll())
  }

  const [wildSearchQuery, setWildSearchQuery] = useState("")

  const handleChangeWildSearchQuery = (query: string | undefined) => {
    setWildSearchQuery(query)
  }
  const splitScreenFilterSet = useAppSelector(
    (state) => state.planner.splitScreenFilterSet,
  )

  const listType = useAppSelector(
    (state) => state.plannerV2.splitScreen.listType,
  )
  const multiSelectAssignments = useAppSelector(
    (state) => state.multiSelect.items,
  ) as Array<Assignment>

  const projectId = multiSelectAssignments[0]?.project_id
  const project = projects.find((p) => p.id === projectId)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterSet = {
    ...splitScreenFilterSet,
    filters: fe.filters.simplify(
      fe.filters.and([
        splitScreenFilterSet.filters,
        // Filters below will be hidden to user as they are not in "splitScreenFilterSet"
        isFeatureViewEnabled && !showAllView
          ? data.account.views.find((v) => v.id === viewId)?.people_filters
          : undefined,
        fe.filters.personIsActive({ value: true }),
        fe.filters.personIsPlaceholder({ value: listType === "placeholders" }),
        wildSearchQuery
          ? fe.filters.personWildSearch({ query: wildSearchQuery })
          : undefined,
      ]),
    ),
  }

  const disabled = multiSelectAssignments.length === 0

  return (
    <ResizablePanel isScrollable minHeight={200} maxHeight={800}>
      <div
        className={cc([{ [styles.disabled]: disabled }])}
        data-component="split-screen-panel"
      >
        <SplitScreenHeader
          filterSet={splitScreenFilterSet} // Only includes filters that will be seen in the UI
          wildSearchQuery={wildSearchQuery}
          onChangeWildSearchQuery={handleChangeWildSearchQuery}
          viewState={viewState}
          handleChangeViewId={handleChangeViewId}
          handleChangeShowAllView={handleChangeShowAllView}
          listType={listType}
          project={project}
        />
        <SplitScreenMain
          user={data}
          filterSet={filterSet} // Includes pre-defined & hidden filters that users won't see
          people={people}
          projects={projects}
          favouritePersonIds={data.favourite_people}
          onResetFilters={() => setWildSearchQuery("")}
          listType={listType}
          companyDefaultMinutes={default_full_time_minutes}
          onSwitchToAllView={() => setViewState({ type: "VIEW_SHOW_ALL" })}
          viewState={viewState}
        />
        <AddSection>
          <div style={{ minHeight: "60px" }} />
        </AddSection>
      </div>
    </ResizablePanel>
  )
}

export { SplitScreenPanel }
