import cc from "classcat"
import React from "react"

import styles from "./BlueLink.module.css"

import Button from "./Button"

export default (props) => {
  const { className, ...rest } = props
  return <Button className={cc([className, styles.link])} {...rest} />
}
