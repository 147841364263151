import * as fe from "@runn/filter-engine"
import React from "react"
import { useDispatch } from "react-redux"

import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import type {
  FilterComponentProps,
  FilterValues,
} from "~/common/SuperSearch/types"
import { Assignment as Icon } from "~/common/react-icons"

import { enableProjects } from "~/Planner/reducer2/scenarioPlanningSlice"
import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

import {
  FilterBlock,
  useAutoRemoveMissingListValues,
  useProjects,
  useSelectedListOptions,
} from "./common"

type BaseProps = {
  user: SuperSearch_user$data
  filter: fe.filters.FilterProjectId
}

type Props = FilterComponentProps & BaseProps

const useValues = (
  props: BaseProps,
): Omit<FilterValues, "availableOptions"> & {
  availableOptions: {
    value: string | number
    label: string
    confirmed: boolean
  }[]
} => {
  const { user, filter } = props

  const availableOptions = useProjects({ user })
  const selectedOptions = useSelectedListOptions({
    filter,
    availableOptions,
  })

  return {
    name: "Project Name",
    availableOptions,
    selectedOptions,
  }
}

const Component = (props: Props) => {
  const {
    user,
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const dispatch = useDispatch()

  const { availableOptions, selectedOptions, name } = useValues({
    user,
    filter,
  })

  const handleChange = (list: number[]) => {
    const selectedTentativeProjects = availableOptions
      .filter(
        (option) =>
          option.confirmed === false && list.includes(Number(option.value)),
      )
      .map((option) => Number(option.value))

    dispatch(enableProjects(selectedTentativeProjects))

    return onChange(path, fe.filters.projectId({ list }))
  }

  const handleRemove = () => onRemove(path)

  useAutoRemoveMissingListValues({
    filter,
    selectedOptions,
    onChange: handleChange,
  })

  return (
    <FilterBlock
      icon={<Icon />}
      label={name}
      filterLabelPlural="projects"
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { filter, user } = props

  const { selectedOptions, name } = useValues({
    user,
    filter,
  })

  return <FilterBlockReadonly name={name} selectedOptions={selectedOptions} />
}

export const ProjectId = {
  Component,
  ReadonlyComponent,
}
