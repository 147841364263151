import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./FilterNameBadge.module.css"

import { BookmarkFilledIcon, CrossIcon } from "~/common/react-icons"

import type { FilterSet } from "./types"

type FilterNameProps = {
  filterSet: FilterSet
  isExpanded?: boolean
  disabled?: boolean
  onClick?: () => void
  onReset?: () => void
}

const FilterNameBadge = (props: FilterNameProps) => {
  const { filterSet, isExpanded, disabled, onClick, onReset } = props

  const handleClick = () => {
    if (!disabled && typeof onClick === "function") {
      onClick()
    }
  }

  const handleReset = () => {
    if (!disabled && typeof onReset === "function") {
      onReset()
    }
  }

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
      <Tooltip
        disabled={disabled}
        content={isExpanded ? "Collapse filter" : "Expand filter"}
        placement="top"
      >
        <div
          className={styles.label}
          onClick={handleClick}
          data-test="SuperSearch_FilterNameBadge"
        >
          <BookmarkFilledIcon className={styles.bookmarkIcon} color="#FFF" />
          {filterSet.name}
        </div>
      </Tooltip>
      <Tooltip content="Close filter" placement="top" disabled={disabled}>
        <div className={styles.resetButton} onClick={handleReset}>
          <CrossIcon />
        </div>
      </Tooltip>
    </div>
  )
}

export default FilterNameBadge
