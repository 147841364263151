/**
 * @generated SignedSource<<369fec747460f320b88d1b1fe688ea7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsPersonRow_account$data = {
  readonly custom_checkbox_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_date_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_select_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_text_types_person: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly id: number;
  readonly invitations: ReadonlyArray<{
    readonly email: string;
    readonly id: number;
  }>;
  readonly users: ReadonlyArray<{
    readonly email: string;
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerCalendarRow_account" | "PlannerLeftColumn_account">;
  readonly " $fragmentType": "ProjectDetailsPersonRow_account";
};
export type ProjectDetailsPersonRow_account$key = {
  readonly " $data"?: ProjectDetailsPersonRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PERSON"
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsPersonRow_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "custom_text_types_person",
      "args": (v1/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_date_types_person",
      "args": (v1/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_select_types_person",
      "args": (v1/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": "custom_checkbox_types_person",
      "args": (v1/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invitations",
      "kind": "LinkedField",
      "name": "invitations",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerCalendarRow_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "ee686ace2dc1f21b552e3d7cfc60fddf";

export default node;
