import * as fe from "@runn/filter-engine"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { ProjectPlanner_account$key } from "~/ProjectPlanner/__generated__/ProjectPlanner_account.graphql"

import { AllowedProjectFilterSet } from "~/helpers/filter-engine"

import OverflowBanner from "~/common/Banner/OverflowBanner"
import { useProjectSearchConfig } from "~/common/SuperSearch"

import { createFilterSet, deleteFilterSet } from "~/mutations/FilterSet"

import ChartPanel from "~/Planner/ChartPanel"
import { useActiveQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { PlannerHeader } from "~/Planner/PlannerLayout"
import { PersonSchedulePanel } from "~/Planner/SchedulePanels/PersonSchedulePanel"
import SplitScreenPanel from "~/Planner/SplitScreenPanel"
import {
  searchChanged,
  setFilterSet,
} from "~/Planner/reducer2/projectFilterSlice"
import { useAppSelector } from "~/hooks/redux"
import { useMigrateSavedFilterSetsToDatabase } from "~/hooks/useMigrateSavedFilterSetsToDatabase"
import { SavedFilterSet, useFilterSets } from "~/queries/FilterSet"

import ProjectPlannerList from "./ProjectPlannerList"

const ProjectPlanner = (props: { account: ProjectPlanner_account$key }) => {
  const dispatch = useDispatch()
  const account = useFragment(
    graphql`
      fragment ProjectPlanner_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        ...PlannerHeader_account @arguments(projectsFilter: $projectsFilter)
      }
    `,
    props.account,
  )
  useMigrateSavedFilterSetsToDatabase({
    localStorageKey: "savedProjectFilterSetList",
    filterSetType: "project",
  })

  const multiSelectEnabled = useAppSelector(
    (state) => state.multiSelect.isEnabled,
  )
  const showPanel = useAppSelector((state) => state.plannerV2.panel.showPanel)
  const queryState = useActiveQueryLimit()
  const isOverflow = queryState.isOverflow

  const wildSearchQuery = useAppSelector(
    (state) => state.plannerV2.projectFilter.search,
  )

  const filterSet = useAppSelector(
    (state) => state.plannerV2.projectFilter.filterSet,
  )

  const savedFilterSets = useFilterSets({ type: "project" })

  const handleChangeWildSearchQuery = (query: string | undefined) => {
    dispatch(searchChanged(query))
  }

  const handleChangeFilterSet = (
    selectedFilterSet: AllowedProjectFilterSet,
  ) => {
    return dispatch(setFilterSet(selectedFilterSet))
  }

  const handleCreateSavedFilterSet = async (
    newFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    await createFilterSet({
      filterSet: {
        type: "project",
        name: newFilterSet.name,
        filters: newFilterSet.filters,
      },
    })
  }

  const handleDeleteSavedFilterSet = async (
    selectedFilterSet: SavedFilterSet,
  ) => {
    await deleteFilterSet({ filterSetId: selectedFilterSet.id })
  }

  const projectSearchConfig = useProjectSearchConfig({
    excludedFilters: ["project_state"],
  })

  return (
    <>
      <PlannerHeader
        account={account}
        superSearchConfig={projectSearchConfig}
        wildSearchQuery={wildSearchQuery}
        filterSet={filterSet}
        savedFilterSets={savedFilterSets}
        onChangeWildSearchQuery={handleChangeWildSearchQuery}
        onChangeFilterSet={handleChangeFilterSet}
        onCreateSavedFilterSet={handleCreateSavedFilterSet}
        onDeleteSavedFilterSet={handleDeleteSavedFilterSet}
      />
      <ProjectPlannerList />
      {match({ showPanel, isOverflow, multiSelectEnabled })
        .with(
          { isOverflow: true, showPanel: null, multiSelectEnabled: false },
          () => <OverflowBanner />,
        )
        .with({ showPanel: "charts" }, () => <ChartPanel />)
        .with({ showPanel: "personSchedule" }, () => <PersonSchedulePanel />)
        .with({ showPanel: "splitScreen" }, () => <SplitScreenPanel />)
        .otherwise(() => null)}
    </>
  )
}

export default ProjectPlanner
