/**
 * @generated SignedSource<<c59cef5bc7566a79d22930202515b2c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSidePanel_user$data = {
  readonly account: {
    readonly id: number;
    readonly secondary_person_field: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanelContract_account" | "PersonSidePanelDetails_account">;
  };
  readonly favourite_people: any;
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonForm_user" | "PersonActionButtons_user">;
  readonly " $fragmentType": "PersonSidePanel_user";
};
export type PersonSidePanel_user$key = {
  readonly " $data"?: PersonSidePanel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanel_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSidePanel_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondary_person_field",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonSidePanelDetails_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonSidePanelContract_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonActionButtons_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditPersonForm_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "cafed0c08b62099cfe0ae9589e33696f";

export default node;
