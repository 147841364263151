import { some } from "lodash-es"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./CustomFieldSectionHeader.module.css"

import { CustomFieldSectionHeaderPerson_account$key } from "./__generated__/CustomFieldSectionHeaderPerson_account.graphql"
import { CustomFieldSectionHeaderProject_account$key } from "./__generated__/CustomFieldSectionHeaderProject_account.graphql"

type Props = {
  style?: React.CSSProperties
} & (
  | {
      type: "project"
      account: CustomFieldSectionHeaderProject_account$key
    }
  | {
      type: "person"
      account: CustomFieldSectionHeaderPerson_account$key
    }
)

const CustomFieldSectionHeader = (props: Props) => {
  const projectCustomFields = useFragment(
    graphql`
      fragment CustomFieldSectionHeaderProject_account on accounts {
        custom_text_types_project: custom_text_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
        }
        custom_date_types_projects: custom_date_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
        }
        custom_select_types_project: custom_select_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
        }
        custom_checkbox_types_project: custom_checkbox_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
        }
      }
    `,
    props.type === "project" ? props.account : null,
  )

  const peopleCustomFields = useFragment(
    graphql`
      fragment CustomFieldSectionHeaderPerson_account on accounts {
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
        }
      }
    `,
    props.type === "person" ? props.account : null,
  )

  const hasNoCustomFields =
    (props.type === "project" &&
      !some(Object.values(projectCustomFields ?? {}), (xs) => xs.length)) ||
    (props.type === "person" &&
      !some(Object.values(peopleCustomFields ?? {}), (xs) => xs.length))

  if (hasNoCustomFields) {
    return
  }

  return (
    <div
      className={styles.container}
      data-type={props.type}
      style={props.style}
    >
      Custom Fields
    </div>
  )
}

export default CustomFieldSectionHeader
