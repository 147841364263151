/**
 * @generated SignedSource<<66eab4fbc39f76dc6f18448990c32d3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExtLinks_Project$data = {
  readonly ext_project_links: ReadonlyArray<{
    readonly active: boolean;
    readonly ext_project_id: number;
    readonly external: {
      readonly __typename: "ext_project";
      readonly id: number;
      readonly integration_id: number;
      readonly name: string;
      readonly remote_id: string | null | undefined;
      readonly remote_url: string | null | undefined;
    };
    readonly external_id: number;
    readonly project_id: number;
  }>;
  readonly " $fragmentType": "ExtLinks_Project";
};
export type ExtLinks_Project$key = {
  readonly " $data"?: ExtLinks_Project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExtLinks_Project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExtLinks_Project",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "active": {
              "_eq": true
            }
          }
        }
      ],
      "concreteType": "ext_project_link",
      "kind": "LinkedField",
      "name": "ext_project_links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ext_project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": "external_id",
          "args": null,
          "kind": "ScalarField",
          "name": "ext_project_id",
          "storageKey": null
        },
        {
          "alias": "external",
          "args": null,
          "concreteType": "ext_project",
          "kind": "LinkedField",
          "name": "ext_project",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote_url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integration_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ext_project_links(where:{\"active\":{\"_eq\":true}})"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "3632ae5a593c07829f5a5790e0260cde";

export default node;
