/**
 * @generated SignedSource<<49db8377e2f40035677c124e53a50322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NonWorkingDayInput_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FTEInput_account">;
  readonly " $fragmentType": "NonWorkingDayInput_account";
};
export type NonWorkingDayInput_account$key = {
  readonly " $data"?: NonWorkingDayInput_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"NonWorkingDayInput_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NonWorkingDayInput_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FTEInput_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "5f4ab0650c79ad15967607eef18eacdd";

export default node;
