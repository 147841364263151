/**
 * @generated SignedSource<<d2f7dc9775290e45f86b3fd092401358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroupStats_project$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly id: number;
  };
  readonly client: {
    readonly id: number;
    readonly internal: boolean | null | undefined;
  };
  readonly id: number;
  readonly is_template: boolean;
  readonly pricing_model: string;
  readonly project_rates: ReadonlyArray<{
    readonly id: number;
    readonly rate: number | null | undefined;
    readonly role_id: number;
  }>;
  readonly project_roles: ReadonlyArray<{
    readonly estimated_minutes: number | null | undefined;
    readonly id: number;
    readonly role_id: number;
  }>;
  readonly summary: ReadonlyArray<{
    readonly role_id: number | null | undefined;
    readonly total_billable_minutes: number | null | undefined;
    readonly total_nonbillable_minutes: number | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly " $fragmentType": "ProjectGroupStats_project";
};
export type ProjectGroupStats_project$key = {
  readonly " $data"?: ProjectGroupStats_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupStats_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroupStats_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "v_summary_total",
      "kind": "LinkedField",
      "name": "summary",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total_billable_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total_nonbillable_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_roles",
      "kind": "LinkedField",
      "name": "project_roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimated_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_rates",
      "kind": "LinkedField",
      "name": "project_rates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "rate",
          "args": null,
          "kind": "ScalarField",
          "name": "rate_private",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "b967f9cab549374a6890320aa6311fcf";

export default node;
