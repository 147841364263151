import React from "react"
import { v4 as uuid } from "uuid"

import styles from "./ProjectBudget.module.css"

import { OtherCostWithCalculations } from "~/helpers/otherCosts"

import AddButton from "~/common/buttons/AddButton"

import OtherCostEditRow from "./OtherCostEditRow"
import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  costs: Array<OtherCostWithCalculations>
  onUpdate: (id: number | string, cost: OtherCostWithCalculations) => void
  onDelete: (id: number | string) => void
  onAdd: () => void
  showNonBillableView?: boolean
}

export const createEmptyCost = (): OtherCostWithCalculations => ({
  id: `new--${uuid()}`,
  date: new Date(),
  cost: 0,
  charge: 0,
  margin: 0,
  profit: 0,
  name: "",
})

const isEmptyCost = (cost: OtherCostWithCalculations) => {
  return !cost.name?.trim() && cost.cost === 0 && cost.charge === 0
}

const OtherCostEditor = (props: Props) => {
  const {
    costs,
    onUpdate,
    onDelete,
    onAdd,
    showNonBillableView = false,
  } = props

  const canDeleteRow =
    costs.length > 1 || (costs.length === 1 && !isEmptyCost(costs[0]))

  return (
    <>
      <div className={styles.otherCostWrapper}>
        <Row heading>
          <Column>Item</Column>
          <Column textAlign="right">Date</Column>
          {!showNonBillableView && (
            <>
              <Column textAlign="right">Margin</Column>
              <Column textAlign="right">Gross Profit</Column>
            </>
          )}
          <Column textAlign="right">Cost</Column>
          {!showNonBillableView && <Column textAlign="right">Charge</Column>}
        </Row>
        <div className={styles.tableBody}>
          {costs.map((cost) => (
            <OtherCostEditRow
              key={`cost-${cost.id}`}
              cost={cost}
              showNonBillableView={showNonBillableView}
              onUpdate={(updatedCost) => onUpdate(cost.id, updatedCost)}
              canDelete={canDeleteRow}
              onDelete={() => onDelete(cost.id)}
            />
          ))}
        </div>
      </div>
      <div className={styles.tableWidget}>
        <Row style={{ display: "flex", padding: "10px 0" }}>
          <AddButton text="Add item" onClick={onAdd} />
        </Row>
      </div>
    </>
  )
}

export default OtherCostEditor
