import { Icon, Menu, MenuDivider, Tooltip } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { PlaceholderPlannerMenu_account$key } from "./__generated__/PlaceholderPlannerMenu_account.graphql"
import { PlaceholderPlannerMenu_placeholder$key } from "./__generated__/PlaceholderPlannerMenu_placeholder.graphql"
import { PlaceholderPlannerMenu_project$key } from "~/Planner/PlannerLayout/__generated__/PlaceholderPlannerMenu_project.graphql"

import { track } from "~/helpers/analytics"
import { formatName, getCurrentOrLastContract } from "~/helpers/person"

import Dialog from "~/common/Dialog"
import MenuItem from "~/common/MenuItem"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import { getItemTotalMinutes } from "~/common/Pill/PillHelpers"
import TransferMenuItem from "~/common/PillActions/MenuItems/TransferMenuItem"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import { PersonCircleIcon, WorkstreamIcon } from "~/common/react-icons"

import { useDeletePersonMutation } from "~/mutations/useDeletePersonMutation"

import {
  useEntitlementSwitch,
  useShowUpsellingSection,
} from "~/Entitlements/useEntitlements"
import DeleteModal from "~/Modals/Delete/DeleteModal"
import * as ModeReducer from "~/Mode.reducer"
import { ChangeProjectRoleModal } from "~/Planner/PlannerLayout/ChangeProjectRoleModal/ChangeProjectRoleModal"
import ChangeWorkstreamModal from "~/Planner/PlannerLayout/ChangeWorkstreamModal"
import { showToast } from "~/containers/ToasterContainer"
import ClonePlaceholderForm from "~/forms/ClonePlaceholderForm/ClonePlaceholderForm"
import PlaceholderForm from "~/forms/PlaceholderForm/PlaceholderForm"

import PlaceholderSidePanel from "../PlaceholderSidePanel/PlaceholderSidePanel"
import { transferEntirePlaceholderSelected } from "../reducer2/splitScreenSlice"

import { PlaceholderPersonMenuType } from "./ContextMenu"
import PlaceholderWorkflowMenu from "./PlaceholderWorkflowMenu"
import { Placeholder } from "./PlannerLeftColumn"

type Props = {
  account: PlaceholderPlannerMenu_account$key
  placeholder: PlaceholderPlannerMenu_placeholder$key
  project: PlaceholderPlannerMenu_project$key
  /**
   * @deprecated define data requirements in the fragment
   */
  placeholderLegacy: Placeholder
  closeMenu: () => void
  isInProjectTemplate?: boolean
  showRequestMenu: boolean
  showFindPerson: boolean
  availableMenuItems: PlaceholderPersonMenuType
  workstreamId?: number
}

const DIALOG_TYPE = {
  CLONE: "CLONE",
  CHANGE_WORKSTREAM: "CHANGE_WORKSTREAM",
  CHANGE_ROLE: "CHANGE_ROLE",
  DELETE: "DELETE",
} as const

const MENU_ITEM_TYPE = {
  ...DIALOG_TYPE,
  VIEW_DETAILS: "VIEW_DETAILS",
  FIND: "FIND",
  EDIT: "EDIT",
  DELETE: "DELETE",
} as const

const MENU_ITEM_TEXT = {
  [MENU_ITEM_TYPE.FIND]: "Find Person",
  [MENU_ITEM_TYPE.CLONE]: "Clone",
  [MENU_ITEM_TYPE.VIEW_DETAILS]: "View Details",
  [MENU_ITEM_TYPE.EDIT]: "Edit Details",
  [MENU_ITEM_TYPE.CHANGE_WORKSTREAM]: "Change Workstream",
  [MENU_ITEM_TYPE.CHANGE_ROLE]: "Change Project Role",
  [MENU_ITEM_TYPE.DELETE]: "Delete",
} as const

const PlaceholderPlannerMenu = (props: Props) => {
  const {
    placeholderLegacy,
    closeMenu,
    isInProjectTemplate,
    showRequestMenu,
    showFindPerson,
    availableMenuItems,
    workstreamId,
  } = props

  const account = useFragment(
    graphql`
      fragment PlaceholderPlannerMenu_account on accounts {
        id
        ...PlaceholderSidePanel_account
        ...PlaceholderWorkflowMenu_account
        ...PlaceholderSidePanel_account
        roles {
          id
          ...ChangeProjectRoleModal_roles
        }
      }
    `,
    props.account,
  )

  const placeholder = useFragment(
    graphql`
      fragment PlaceholderPlannerMenu_placeholder on people {
        id
        project_memberships {
          id
          workstream {
            id
          }
        }
        first_name
        last_name
        ...PlaceholderWorkflowMenu_placeholder
        ...ChangeWorkstreamModal_person
        ...PlaceholderSidePanel_placeholder
        ...ChangeProjectRoleModal_person
        ...PlaceholderSidePanel_placeholder
      }
    `,
    props.placeholder,
  )

  const project = useFragment(
    graphql`
      fragment PlaceholderPlannerMenu_project on projects {
        id
        name
        is_template
        project_workstreams {
          workstream_id
        }
        ...ChangeWorkstreamModal_project
        ...ChangeProjectRoleModal_project
      }
    `,
    props.project,
  )

  const {
    canRequest,
    canViewDetails,
    canFindPerson,
    canDuplicate,
    canEditDetails,
    canDelete,
    canChangeProjectRole,
    canChangeWorkstream,
  } = availableMenuItems

  const placeholderContract = getCurrentOrLastContract(
    placeholderLegacy.contracts,
  )
  const roleId = placeholderContract.role.id

  const [dialogType, setDialogType] = useState<keyof typeof DIALOG_TYPE>(null)

  const dispatch = useDispatch()
  const { openPanel, closePanel } = useSidePanel()
  const iswWorkstreamsEnabled = useFeature("workstreams")
  const showPlaceholderSidePanel = canViewDetails

  // TODO - update as per https://github.com/Runn-Fast/runn/pull/14383
  const entitledToResourceRequests = useEntitlementSwitch("resourcing-requests")
  const entitledToWorkstreams = useEntitlementSwitch("workstreams")
  const isConsistentTimeOffEnabled = useEntitlementSwitch("consistent_time_off")

  const showUpsellingSection = useShowUpsellingSection()

  const handleCloseDialog = () => {
    setDialogType(null)
    closeMenu()
  }

  const transferPlaceholderAssignments = () => {
    track("Placeholder Context Menu Item Selected", {
      item: "Find Person",
    })

    dispatch(
      ModeReducer.enableMultiSelect(
        placeholderLegacy.person_requests?.[0]?.status,
      ),
    )
    dispatch(transferEntirePlaceholderSelected())

    placeholderLegacy.assignments?.forEach((a) => {
      const { combinedMinutes, combinedMinutesLessTimeOff } =
        getItemTotalMinutes({
          typedItem: { type: "assignment", item: a },
          person: placeholderLegacy,
          isConsistentTimeOffEnabled,
        })

      const formatted = {
        ...a,
        minutes: combinedMinutes,
        minutesLessTimeOff: combinedMinutesLessTimeOff,
      }
      dispatch(ModeReducer.addItemToMultiSelect(formatted))
    })
  }

  const noAssignments = placeholderLegacy.assignments.length === 0

  const openPlaceholderDetails = () => {
    track("Placeholder Side Panel Opened")
    openPanel(
      <PlaceholderSidePanel
        placeholder={placeholder}
        project={project}
        account={account}
        closePanel={closePanel}
      />,
    )
  }

  const placeholderName = formatName(
    placeholder.first_name,
    placeholder.last_name,
  )
  const [deletePerson] = useDeletePersonMutation()

  const handleDelete = async () => {
    track("Placeholder Deleted")
    try {
      await deletePerson({ personId: placeholder.id })
      showToast({
        type: "success",
        message: `${placeholderName} has been deleted`,
        description: project.name || "",
      })
    } catch (error: unknown) {
      showToast({
        message: `Failed to delete "${placeholderName}".`,
        description: error instanceof Error ? error.message : undefined,
        type: "error",
      })
    }
    closePanel()
  }

  const handleMenuItemClick = (menuType: keyof typeof MENU_ITEM_TYPE) => {
    track("Placeholder Context Menu Item Selected", {
      item: MENU_ITEM_TEXT[menuType],
    })

    switch (menuType) {
      case "FIND":
        transferPlaceholderAssignments()
        break
      case "CLONE":
      case "CHANGE_WORKSTREAM":
      case "CHANGE_ROLE":
      case "DELETE":
        setDialogType(menuType)
        break
      case "VIEW_DETAILS":
        openPlaceholderDetails()
        break
      case "EDIT":
        openPanel(
          <PlaceholderForm
            placeholder={placeholderLegacy}
            cost={placeholderContract.cost}
            onClose={closePanel}
            roleId={roleId}
            project={project}
          />,
        )
        break
      default:
        break
    }
  }

  const getDialog = () =>
    match(dialogType)
      .with("CHANGE_ROLE", () => (
        <ChangeProjectRoleModal
          currentRoleId={roleId}
          roles={account.roles}
          person={placeholder}
          project={project}
          workstreamId={workstreamId}
          closeDialog={handleCloseDialog}
        />
      ))
      .with("CHANGE_WORKSTREAM", () => (
        <ChangeWorkstreamModal
          person={placeholder}
          workstreamId={workstreamId}
          project={project}
          closeDialog={handleCloseDialog}
        />
      ))
      .with("CLONE", () => (
        <ClonePlaceholderForm
          placeholder={placeholderLegacy}
          project={project}
          workstreamId={
            placeholder.project_memberships[0]?.workstream?.id ?? null
          }
          closeDialog={handleCloseDialog}
        />
      ))
      .with("DELETE", () => (
        <DeleteModal
          type="placeholder"
          name={`${placeholder.first_name} ${placeholder.last_name}`}
          closeDialog={handleCloseDialog}
          customWarning={
            "All assignments assigned to this placeholder will also be deleted."
          }
          onSubmit={handleDelete}
          isAdmin={true}
          deletable={true}
        />
      ))
      .exhaustive()

  // TODO(permissions): Offload this logic to subject/PersonRequest.ts
  const canSeeRequest = !isInProjectTemplate && showRequestMenu

  return (
    <>
      <Menu className={dropdownStyles.dropdown} style={{ padding: 0 }}>
        <>
          {canEditDetails && (
            <MenuItem
              text={MENU_ITEM_TEXT.EDIT}
              icon={<Icon icon="cog" />}
              onClick={() => handleMenuItemClick("EDIT")}
            />
          )}
          <MenuDivider />
          {showPlaceholderSidePanel && (
            <MenuItem
              text={MENU_ITEM_TEXT.VIEW_DETAILS}
              icon={<Icon icon="list-detail-view" />}
              onClick={() => handleMenuItemClick("VIEW_DETAILS")}
            />
          )}
          {canSeeRequest &&
            entitledToResourceRequests &&
            !showUpsellingSection && (
              <PlaceholderWorkflowMenu
                project={project}
                placeholder={placeholder}
                placeholderLegacy={{
                  id: placeholderLegacy.id,
                  roleName: placeholderContract.role.name,
                  personRequest: placeholderLegacy.person_requests?.[0] || null,
                  notes: placeholderLegacy.people_notes,
                }}
                closeMenu={closeMenu}
                account={account}
                availableMenuItems={availableMenuItems}
              />
            )}
          {showFindPerson && canFindPerson && (
            <Tooltip
              disabled={!noAssignments}
              content="No assignments to transfer"
              placement="top"
            >
              <TransferMenuItem
                text={MENU_ITEM_TEXT.FIND}
                entirePlaceholderTransfer
                icon={<Icon icon="arrow-right" />}
                disabled={noAssignments}
                onClick={() => handleMenuItemClick("FIND")}
              />
            </Tooltip>
          )}
          {canDuplicate && (
            <MenuItem
              text={MENU_ITEM_TEXT.CLONE}
              icon={<Icon icon="duplicate" />}
              shouldDismissPopover={false}
              onClick={() => handleMenuItemClick("CLONE")}
            />
          )}
          {canChangeProjectRole && project && roleId && (
            <>
              <MenuItem
                text={MENU_ITEM_TEXT.CHANGE_ROLE}
                icon={<PersonCircleIcon color="var(--midnight)" />}
                onClick={() => handleMenuItemClick("CHANGE_ROLE")}
                shouldDismissPopover={false}
              />
            </>
          )}
          {canChangeWorkstream &&
            iswWorkstreamsEnabled &&
            entitledToWorkstreams &&
            Boolean(project && project.project_workstreams.length) &&
            canEditDetails && (
              <MenuItem
                text={MENU_ITEM_TEXT.CHANGE_WORKSTREAM}
                icon={<WorkstreamIcon />}
                onClick={() => handleMenuItemClick("CHANGE_WORKSTREAM")}
                shouldDismissPopover={false}
              />
            )}

          {(showRequestMenu || showFindPerson) && <MenuDivider />}
          {showUpsellingSection && canRequest && canSeeRequest && (
            <PaidFeatureMenuItemContainer roundedButton noGap>
              <PlaceholderWorkflowMenu
                disabled={!entitledToResourceRequests}
                project={project}
                placeholder={placeholder}
                placeholderLegacy={{
                  id: placeholderLegacy.id,
                  roleName: placeholderContract.role.name,
                  personRequest: placeholderLegacy.person_requests?.[0] || null,
                  notes: placeholderLegacy.people_notes,
                }}
                closeMenu={closeMenu}
                account={account}
                availableMenuItems={availableMenuItems}
              />
              {entitledToResourceRequests && (
                <MenuItem
                  text={MENU_ITEM_TEXT.CHANGE_WORKSTREAM}
                  icon={<WorkstreamIcon />}
                  onClick={() => handleMenuItemClick("CHANGE_WORKSTREAM")}
                  shouldDismissPopover={false}
                  disabled={!entitledToResourceRequests}
                />
              )}
            </PaidFeatureMenuItemContainer>
          )}
          {canDelete && (
            <MenuItem
              text={MENU_ITEM_TEXT.DELETE}
              icon={<Icon icon="trash" />}
              shouldDismissPopover={false}
              onClick={() => handleMenuItemClick("DELETE")}
            />
          )}
        </>
      </Menu>
      {Boolean(dialogType) && (
        <Dialog isOpen={true} onClose={handleCloseDialog}>
          {getDialog()}
        </Dialog>
      )}
    </>
  )
}

export default PlaceholderPlannerMenu
