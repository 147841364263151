import { dateHelpers } from "@runn/calculations"
import {
  addBusinessDays,
  addDays,
  addMonths,
  addWeeks,
  differenceInBusinessDays,
  differenceInDays,
  endOfISOWeek,
  isBefore,
  isMonday,
  isSameWeek,
  isWeekend,
  startOfDay,
  subBusinessDays,
} from "date-fns"
import flatten from "lodash-es/flatten"
import mem from "mem"
import serializeJavascript from "serialize-javascript"

import { sortByStartDate } from "~/common/Pill/action_helpers"

import {
  calculateAllAssignedMinutesDays,
  calculateAllContractDays,
  calculateAllNonWorkingDays,
  calculateAllTimeOffDays,
  calculateCalendarDays,
  calculateStartAndEndDatesWithinRange,
  mergeDaysObjects,
} from "./daysObjectHelpers"
import { getMergedTimeOffs, groupTimeOffsByType } from "./holiday-helpers"

type DateOrString = Date | string

type getDaysInRangeProps = {
  start: DateOrString
  end: DateOrString
  includeWeekends?: boolean
}

export const getDaysInRange = ({
  start,
  end,
  includeWeekends = true,
}: getDaysInRangeProps): number => {
  const startDate =
    typeof start === "string" ? dateHelpers.parseRunnDate(start) : start
  const endDate = typeof end === "string" ? dateHelpers.parseRunnDate(end) : end

  let first = startDate
  let second = endDate

  if (isBefore(endDate, startDate)) {
    // swap dates if end comes before
    first = endDate
    second = startDate
  }

  if (includeWeekends) {
    return Math.abs(differenceInDays(first, second)) + 1
  }

  if (isWeekend(first)) {
    first = addBusinessDays(first, 1)
  }

  if (isWeekend(second)) {
    second = subBusinessDays(second, 1)
  }

  return Math.abs(differenceInBusinessDays(first, second)) + 1
}

export const getItemOffsetPercent = (
  calendarStartDate: Date,
  calendarEndDate: Date,
  item: {
    start_date: string
    end_date: string
  },
  calendarWeekendsExpanded: boolean,
): string => {
  const totalItemDaysInView = getDaysInRange({
    start: calendarStartDate,
    end: item.start_date,
    includeWeekends: calendarWeekendsExpanded,
  })

  const startIndex = isBefore(
    dateHelpers.parseRunnDate(item.start_date),
    calendarStartDate,
  )
    ? 0
    : totalItemDaysInView - 1

  const calendarDays = getDaysInRange({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const dayWidthPercentage = 100.0 / calendarDays

  return `${startIndex * dayWidthPercentage}%`
}

export const getItemWidthPercent = (
  calendarStartDate: Date,
  calendarEndDate: Date,
  item: {
    start_date: DateOrString
    end_date: DateOrString
  },
  includeWeekends: boolean,
): string => {
  const calendarDays = getDaysInRange({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends,
  })

  const dayWidthPercentage = 100.0 / calendarDays

  const totalItemDays = getDaysInRange({
    start: item.start_date,
    end: item.end_date,
    includeWeekends,
  })

  return `${totalItemDays * dayWidthPercentage}%`
}

export const getItemOffsetPercentNum = (
  calendarStartDate: Date,
  calendarEndDate: Date,
  item: {
    start_date: string
    end_date?: string
  },
  calendarWeekendsExpanded: boolean,
  isCollapsedWeekend?: boolean,
): number => {
  if (!item) {
    return 0
  }

  const totalItemDaysInView = getDaysInRange({
    start: calendarStartDate,
    end: item.start_date,
    includeWeekends: calendarWeekendsExpanded,
  })

  const leftOffset = isCollapsedWeekend ? 0 : 1 // a collapsed weekend has a smaller width so starting offset doesnt change

  const startIndex = isBefore(
    dateHelpers.parseRunnDate(item.start_date),
    calendarStartDate,
  )
    ? 0
    : totalItemDaysInView - leftOffset

  const calendarDays = getDaysInRange({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })
  const dayWidthPercentage = 100.0 / calendarDays

  return startIndex * dayWidthPercentage
}

export const getDateOffsetPercentNum = (
  calendarStartDate: Date,
  calendarEndDate: Date,
  date: string,
  calendarWeekendsExpanded: boolean,
): number => {
  if (!date) {
    return 0
  }
  const totalItemDaysInView = getDaysInRange({
    start: calendarStartDate,
    end: date,
    includeWeekends: calendarWeekendsExpanded,
  })
  const startIndex = isBefore(
    dateHelpers.parseRunnDate(date),
    calendarStartDate,
  )
    ? 0
    : totalItemDaysInView - 1
  const calendarDays = getDaysInRange({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })
  const dayWidthPercentage = 100.0 / calendarDays
  return startIndex * dayWidthPercentage
}

export const getItemPositionWithinRange = (
  rangeStartDate: Date,
  rangeEndDate: Date,
  item: { start_date: string; end_date: string },
  calendarWeekendsExpanded,
) => {
  const datesWithinRange = calculateStartAndEndDatesWithinRange(
    item,
    rangeStartDate,
    rangeEndDate,
  )

  const updatedItem = {
    start_date: datesWithinRange.startDate,
    end_date: datesWithinRange.endDate,
  }

  const width = getItemWidthPercent(
    rangeStartDate,
    rangeEndDate,
    updatedItem,
    calendarWeekendsExpanded,
  )
  const offset = getItemOffsetPercent(
    rangeStartDate,
    rangeEndDate,
    item,
    calendarWeekendsExpanded,
  )

  return { width, marginLeft: offset }
}

export const getCalendarEndDate = (
  date: Date,
  amountOfType: number,
  type: "months" | "weeks",
) => {
  // startOfDay makes sure we use the start of day which eliminates TimeZone problems.
  if (type === "weeks") {
    return startOfDay(endOfISOWeek(addWeeks(date, amountOfType)))
  } else {
    return startOfDay(endOfISOWeek(addMonths(date, amountOfType)))
  }
}

type getTimeFramesProps = {
  start: DateOrString
  end: DateOrString
  includeWeekends?: boolean
}

const getTimeFramesFunc = ({
  start,
  end,
  includeWeekends = true,
}: getTimeFramesProps) => {
  const startDate =
    typeof start === "string" ? dateHelpers.parseRunnDate(start) : start
  const endDate = typeof end === "string" ? dateHelpers.parseRunnDate(end) : end
  let calendarDates: Date[] = []

  if (isBefore(endDate, startDate)) {
    calendarDates = dateHelpers.eachDayOfInterval(
      { start: endDate, end: startDate },
      "getTimeFrames",
    )
  } else {
    calendarDates = dateHelpers.eachDayOfInterval(
      { start: startDate, end: endDate },
      "getTimeFrames",
    )
  }

  if (!includeWeekends) {
    calendarDates = calendarDates.filter((d) => !isWeekend(d))
  }

  const weeklyDates = calendarDates.filter((d) => isMonday(d))

  const runnDailyDates = calendarDates.map((date) => ({
    formattedDate: dateHelpers.formatToRunnDate(date),
  }))

  const runnWeeklyDates = weeklyDates.map((date) => ({
    formattedDate: dateHelpers.formatToRunnDate(date),
  }))

  return {
    dailyDates: calendarDates,
    runnDailyDates,
    weeklyDates,
    runnWeeklyDates,
    totalDays: calendarDates.length,
    totalBusinessDays: calendarDates.filter((d) => !isWeekend(d)).length,
  }
}

export const getTimeFrames = mem(getTimeFramesFunc, {
  cacheKey: serializeJavascript,
})

type getItemMetaDataProps = {
  startDate: Date
  endDate: Date
  timeOffs: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    leave_type: string
    minutes_per_day: number
  }>
  isTimeOff: boolean
  nonWorkingDay: boolean
  includeWeekends?: boolean
}

export const getItemMetaData = ({
  startDate,
  endDate,
  timeOffs,
  isTimeOff,
  includeWeekends = true,
  nonWorkingDay,
}: getItemMetaDataProps): {
  totalDays: number
  totalTimeOffs: number
  totalWorkingDays: number
  timeOffsDaysWithinRange: string[]
} => {
  const { rosteredOffs, mergedHolidays } = groupTimeOffsByType(timeOffs)
  const fallbackEndDate = endDate || startDate
  const totalDays = getDaysInRange({
    start: startDate,
    end: fallbackEndDate,
    includeWeekends,
  })

  const totalBusinessDays = getDaysInRange({
    start: startDate,
    end: fallbackEndDate,
    includeWeekends: false,
  })

  const { runnDailyDates } = getTimeFrames({
    start: startDate,
    end: fallbackEndDate,
    includeWeekends,
  })

  const mergedTimeOffs = isTimeOff
    ? getMergedTimeOffs(
        [...rosteredOffs, ...mergedHolidays],
        startDate,
        fallbackEndDate,
      )
    : getMergedTimeOffs(timeOffs, startDate, fallbackEndDate)

  const timeOffsDaysWithinRange = Object.keys(
    calculateAllTimeOffDays({
      days: runnDailyDates,
      timeOffs: mergedTimeOffs,
      rangeStartDate: startDate,
      rangeEndDate: fallbackEndDate,
      includeHolidays: true,
    }),
  )

  const totalTimeOffsCount = timeOffsDaysWithinRange.length

  // We only allow 1-day non-working days and it takes precedence over time offs
  const totalWorkingDays = nonWorkingDay
    ? 1
    : totalBusinessDays - totalTimeOffsCount

  return {
    totalDays,
    totalTimeOffs: totalTimeOffsCount,
    totalWorkingDays,
    timeOffsDaysWithinRange,
  }
}

type getNewlyMovedDateProps = {
  originalDate: string
  cellsMoved: number
  calendarWeekendsExpanded: boolean
}
export const getNewlyMovedDate = ({
  originalDate,
  cellsMoved,
  calendarWeekendsExpanded,
}: getNewlyMovedDateProps): {
  date: Date
  dateString: string
  dateNumber: number
} => {
  // This function will most likely be used for "resizing"
  // which only adjusts one date
  const newDate = calendarWeekendsExpanded
    ? addDays(dateHelpers.parseRunnDate(originalDate), cellsMoved)
    : addBusinessDays(dateHelpers.parseRunnDate(originalDate), cellsMoved)

  const dateString = dateHelpers.formatToRunnDate(newDate)

  return {
    date: newDate,
    dateString,
    dateNumber: Number(dateString),
  }
}

type timeOffs = ReadonlyArray<{
  readonly start_date: string | null
  readonly end_date: string | null
}>

export const getTimeOffWeekends = (
  timeOffs: timeOffs,
  calStartNum: number,
  calEndNum: number,
): number[] =>
  flatten(
    timeOffs
      // only get timeoffs within calendar range
      .filter((a) => Number(a.end_date) >= calStartNum)
      .filter((a) => Number(a.start_date) <= calEndNum)
      // only get weekends
      .map((x) =>
        dateHelpers
          .eachDayOfInterval({
            start: dateHelpers.parseRunnDate(x.start_date),
            end: dateHelpers.parseRunnDate(x.end_date),
          })
          .filter((d) => isWeekend(d))
          .map((date) => Number(dateHelpers.formatToRunnDate(date))),
      ),
  ) || []

type WeekendAssignment = {
  start_date: string
  non_working_day: boolean
  phase_id: number
  minutes_per_day: number
}
type WeekendData = {
  start_date: string
  non_working_day: boolean
  workloadTotal: number
}

type WeekendItem = WeekendData | WeekendAssignment

export const groupSameWeekends = (
  items: Readonly<WeekendItem[]>,
  calStartNum: number,
  calEndNum: number,
  calendarWeekendsExpanded: boolean,
) => {
  if (calendarWeekendsExpanded) {
    return []
  }
  const groupedWeekends = []
  const sortedAndFilteredWeekends = items
    .filter((a) => a.non_working_day) // Ideally we only pass weekend (non_working_day) assignments. But this is just in case.
    .filter(
      (a) =>
        isWeekend(dateHelpers.parseRunnDate(a.start_date)) &&
        Number(a.start_date) >= calStartNum &&
        Number(a.start_date) <= calEndNum,
    )
    .sort(sortByStartDate)

  sortedAndFilteredWeekends?.forEach((a, i) => {
    const currentItem = a

    if (sortedAndFilteredWeekends.length === 1) {
      return groupedWeekends.push([currentItem])
    }

    // Check if next assignment is in the same weekend
    const nextItem = sortedAndFilteredWeekends[i + 1]
    const nextItemIsSameWeekend = isSameWeek(
      dateHelpers.parseRunnDate(nextItem?.start_date),
      dateHelpers.parseRunnDate(a.start_date),
      {
        weekStartsOn: 1, // monday
      },
    )

    if (nextItemIsSameWeekend) {
      return groupedWeekends.push([currentItem, nextItem])
    }

    // Ignore if was in same weekend, as we handle it above
    const prevItem = sortedAndFilteredWeekends[i - 1]

    const prevItemIsSameWeekend =
      prevItem &&
      isSameWeek(
        dateHelpers.parseRunnDate(a.start_date),
        dateHelpers.parseRunnDate(prevItem?.start_date),
        {
          weekStartsOn: 1,
        },
      )
    // prevItem && Number(a.start_date) - Number(prevItem.start_date) === 1

    if (prevItemIsSameWeekend) {
      return
    } else {
      return groupedWeekends.push([currentItem])
    }
  })

  return groupedWeekends
}

type Contract = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  rostered_days?: readonly number[]
}

type TimeOff = {
  start_date: string
  end_date: string
  leave_type: string
}

type Assignment = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  person_id: number
  role_id: number
  project_id: number
  note: string
  is_billable: boolean
  phase_id: number
  non_working_day: boolean
}

export const getDailyAssignmentData = (
  person: {
    contracts: readonly Contract[]
    time_offs: readonly TimeOff[]
  },
  assignments: readonly Assignment[],
  calStartDate: Date,
  calEndDate: Date,
  isConsistentTimeOffEnabled: boolean,
) => {
  const daysObject = calculateCalendarDays(calStartDate, calEndDate)
  const days = Object.values(daysObject)

  const timeOffDaysObject = calculateAllTimeOffDays({
    days,
    timeOffs: person.time_offs,
    rangeStartDate: calStartDate,
    rangeEndDate: calEndDate,
  })

  const contractDaysObject = calculateAllContractDays({
    days,
    contracts: person.contracts,
    timeOffs: person.time_offs,
    rangeStartDate: calStartDate,
    rangeEndDate: calEndDate,
  })

  const assignmentDaysObject = calculateAllAssignedMinutesDays({
    days,
    assignments,
    timeOffs: person.time_offs,
    rangeStartDate: calStartDate,
    rangeEndDate: calEndDate,
    isConsistentTimeOffEnabled,
  })

  const nonWorkingDaysObject = calculateAllNonWorkingDays({
    days,
    timeOffs: person.time_offs,
    rangeStartDate: calStartDate,
    rangeEndDate: calEndDate,
  })

  const dailyAssignmentData = mergeDaysObjects(daysObject, [
    timeOffDaysObject,
    contractDaysObject,
    assignmentDaysObject,
    nonWorkingDaysObject,
  ])

  return dailyAssignmentData
}

export default class CalendarHelper {
  public static getItemOffsetPercent(
    date1,
    date2,
    item,
    calendarWeekendsExpanded,
  ) {
    return getItemOffsetPercent(date1, date2, item, calendarWeekendsExpanded)
  }

  public static getItemWidthPercent(
    date1,
    date2,
    item,
    calendarWeekendsExpanded,
  ) {
    return getItemWidthPercent(date1, date2, item, calendarWeekendsExpanded)
  }

  public static getItemOffsetPercentNum(
    date1,
    date2,
    item,
    calendarWeekendsExpanded,
  ) {
    return getItemOffsetPercentNum(date1, date2, item, calendarWeekendsExpanded)
  }

  public static getItemPositionWithinRange(
    date1,
    date2,
    item,
    calendarWeekendsExpanded,
  ) {
    return getItemPositionWithinRange(
      date1,
      date2,
      item,
      calendarWeekendsExpanded,
    )
  }

  public static getCalendarEndDate(date, amountOfType, type) {
    return getCalendarEndDate(date, amountOfType, type)
  }

  public static getItemMetaData({
    startDate,
    endDate,
    timeOffs,
    isTimeOff,
    includeWeekends,
    nonWorkingDay,
  }) {
    return getItemMetaData({
      startDate,
      endDate,
      timeOffs,
      isTimeOff,
      includeWeekends,
      nonWorkingDay,
    })
  }
}
