import React from "react"

const fallbackCheck = (entry: IntersectionObserverEntry) => {
  const { rootBounds, boundingClientRect } = entry
  if (!rootBounds) {
    return false
  }

  // Check if the target's bounding box overlaps the root's bounds
  // Addressing a Firefox IntersectionObserver bug - to be removed when possible
  const isWithinViewport =
    boundingClientRect.top < rootBounds.bottom &&
    boundingClientRect.bottom > rootBounds.top &&
    boundingClientRect.left < rootBounds.right &&
    boundingClientRect.right > rootBounds.left

  return isWithinViewport
}

export const isFirefox = () => {
  return (
    typeof navigator !== "undefined" && /firefox/i.test(navigator.userAgent)
  )
}

/**
 *
 * @param delay - delay in milliseconds before setting isInViewport to false
 */
export const useIsInViewport = (
  ref: React.RefObject<HTMLElement>,
  options: {
    delay: number
    rootQuerySelector: any
    offsetY: number
    keepVisible: boolean
    fallbackCheckTrigger?: boolean
  } = {
    delay: 0,
    rootQuerySelector: null,
    offsetY: 0,
    keepVisible: false,
    fallbackCheckTrigger: false,
  },
) => {
  const [isInViewport, setIsInViewport] = React.useState(false)
  const timeoutRef = React.useRef<number | null>(null)
  const observer = React.useRef<IntersectionObserver | null>(null)

  React.useEffect(() => {
    const root =
      typeof options.rootQuerySelector === "string"
        ? document.querySelector(options.rootQuerySelector)
        : options.rootQuerySelector != null
          ? options.rootQuerySelector
          : null

    observer.current = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry) {
          if (
            entry.isIntersecting ||
            (options.fallbackCheckTrigger && fallbackCheck(entry))
          ) {
            setIsInViewport(true)
            if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current)
              timeoutRef.current = null
            }
          } else if (timeoutRef.current === null) {
            timeoutRef.current = window.setTimeout(() => {
              setIsInViewport(false)
              timeoutRef.current = null
            }, options.delay)
          }
        }
      },
      {
        root,
        rootMargin: `${options.offsetY}px 0px`,
        threshold: 0.0,
      },
    )

    if (ref.current) {
      if (options.keepVisible && isInViewport) {
        return
      }

      observer.current.observe(ref.current)
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [ref, options, isInViewport])

  return isInViewport
}
