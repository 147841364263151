import React, { useEffect, useState } from "react"

import styles from "./EditAssignmentNote.module.css"

import TextArea from "./TextArea"

const EditAssignmentNote = (props: Props) => {
  const { note, editNote } = props

  const [initialOpenState, setInitialOpenState] = useState(false)

  useEffect(() => {
    setInitialOpenState(Boolean(note))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onNoteChange = (e) => {
    editNote(e.target.value)
  }

  return (
    <details className={styles.noteTitle} open={initialOpenState}>
      <summary>
        <span>Note</span>
      </summary>
      <TextArea
        id="assignment-note-mini-picker"
        value={note || ""}
        onChange={onNoteChange}
        maxLength={200}
        style={{ margin: 0 }}
      />
    </details>
  )
}

type Props = {
  note: string
  editNote: (string) => void
}

export default EditAssignmentNote
