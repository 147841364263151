import { fetchQuery, graphql } from "react-relay"

import { TimeOffCreateMutation } from "./__generated__/TimeOffCreateMutation.graphql"
import { TimeOffDeleteMutation } from "./__generated__/TimeOffDeleteMutation.graphql"
import { TimeOffUpdateMutation } from "./__generated__/TimeOffUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import {
  addLinkedRecord,
  commitMutationPromise,
  removeLinkedRecord,
} from "./helpers"
import { getLocalId } from "~/helpers/local-id"
import * as relayids from "~/helpers/relayids"

import { RELAY_TIMEOFF_LOCATION } from "~/GLOBALVARS"

const timeOffCreateMutation = graphql`
  mutation TimeOffCreateMutation(
    $personId: Int!
    $startDate: String!
    $endDate: String!
    $note: String
    $minutesPerDay: Int
  ) {
    createTimeOff(
      personId: $personId
      startDate: $startDate
      endDate: $endDate
      note: $note
      minutesPerDay: $minutesPerDay
    ) {
      time_off {
        id
        start_date: start_date_runn
        end_date: end_date_runn
        leave_type
        note
        person_id
        minutes_per_day
        ...ExtLinks_TimeOff @relay(mask: false)
      }
    }
  }
`

// We are fetching the projects of the person whose time off is being changed
// to update the project stats in case the change in time off affected it
const projectsQuery = graphql`
  query TimeOffAffectedProjectsQuery($personId: Int!) {
    current_user {
      id
      account {
        id
        people(where: { id: { _eq: $personId } }) {
          id
          project_memberships {
            id
            project {
              id
              summary {
                project_id
                person_id
                role_id
                minutes_phase_id
                total_billable_minutes
                total_nonbillable_minutes
              }
            }
          }
        }
      }
    }
  }
`

export const timeOffCreateRelay = async (
  variables: TimeOffCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<TimeOffCreateMutation>(environment, {
    mutation: timeOffCreateMutation,
    variables,
    optimisticUpdater: (store) => {
      const id = getLocalId()
      const timeOffId = relayids.timeOffs.encode(id)

      const time_off = store.create(timeOffId, "time_off")
      const personId = variables.personId
      const person = store.get(relayids.people.encode(personId))
      if (!person) {
        // eslint-disable-next-line no-console
        console.debug(`timeOffCreateRelay: person id=${personId} is null`)
        return
      }

      time_off.setValue(id, "id")
      time_off.setValue(`${variables.startDate}`, "start_date_runn")
      time_off.setValue(`${variables.endDate}`, "end_date_runn")
      time_off.setValue(personId, "person_id")
      time_off.setValue(variables.note, "note")
      time_off.setValue(variables.minutesPerDay, "minutes_per_day")
      time_off.setLinkedRecord(person, "person")
      // This mutation is used for "annual" type time offs only
      time_off.setValue("annual", "leave_type")

      // IMPORTANT: must match field and args defined in
      // app/javascript/src/queries/ExtLinks.ts
      time_off.setLinkedRecords([], "ext_time_off_links", {
        where: { active: { _eq: true } },
      })

      addLinkedRecord(person, RELAY_TIMEOFF_LOCATION, time_off)
    },
    updater: (store, { createTimeOff: response }) => {
      const timeOffId = response.time_off.id
      const personId = response.time_off.person_id

      const $person = store.get(relayids.people.encode(personId))
      const $timeOff = store.get(relayids.timeOffs.encode(timeOffId))

      addLinkedRecord($person, RELAY_TIMEOFF_LOCATION, $timeOff)
    },
  })

  const response = data.createTimeOff

  fetchQuery(environment, projectsQuery, {
    personId: response.time_off.person_id,
  }).subscribe({})

  return response
}

const timeOffUpdatesMutation = graphql`
  mutation TimeOffUpdateMutation(
    $id: Int!
    $startDate: String!
    $endDate: String!
    $note: String
    $minutesPerDay: Int
  ) {
    updateTimeOff(
      id: $id
      startDate: $startDate
      endDate: $endDate
      note: $note
      minutesPerDay: $minutesPerDay
    ) {
      time_off {
        id
        person_id
        leave_type
        start_date_iso: start_date_runn
        end_date_iso: end_date_runn
        note
        minutes_per_day
      }
    }
  }
`

export const timeOffUpdateRelay = async (
  options: TimeOffUpdateMutation["variables"],
) => {
  const { id, startDate, endDate, minutesPerDay, note } = options

  const data = await commitMutationPromise<TimeOffUpdateMutation>(environment, {
    mutation: timeOffUpdatesMutation,
    variables: {
      id,
      startDate,
      endDate,
      minutesPerDay,
      note,
    },
    optimisticUpdater: (store) => {
      const time_off = store.get(relayids.timeOffs.encode(id))

      if (!time_off) {
        // eslint-disable-next-line no-console
        console.debug(`timeOffUpdateRelay: time_off id=${id} is null`)
        return
      }
    },
  })

  const response = data.updateTimeOff

  fetchQuery(environment, projectsQuery, {
    personId: response.time_off.person_id,
  }).subscribe({})

  return response
}

const timeOffDeleteMutation = graphql`
  mutation TimeOffDeleteMutation($id: Int!) {
    deleteTimeOff(id: $id) {
      time_off {
        id
      }
    }
  }
`

export const timeOffDeleteRelay = (
  variables: TimeOffDeleteMutation["variables"],
) => {
  return commitMutationPromise<TimeOffDeleteMutation>(environment, {
    mutation: timeOffDeleteMutation,
    variables,
    optimisticUpdater: (store) => {
      const timeoff = store.get(relayids.timeOffs.encode(variables.id))

      // Timeoff deletion can be triggered multiple times for the same record,
      // in which case subsequent calls will return null.
      if (!timeoff) {
        // eslint-disable-next-line no-console
        console.debug(`timeOffDeleteRelay: timeoff id=${variables.id} is null`)
        return
      }

      const person = store.get(
        relayids.people.encode(timeoff.getValue("person_id") as number),
      )

      removeLinkedRecord(person, RELAY_TIMEOFF_LOCATION, timeoff)
      store.delete(timeoff.getDataID())
    },
    updater: (store) => {
      const timeoff = store.get(relayids.timeOffs.encode(variables.id))

      if (!timeoff) {
        return
      }

      const personId = timeoff.getValue("person_id") as number

      const person = store.get(relayids.people.encode(personId))

      removeLinkedRecord(person, RELAY_TIMEOFF_LOCATION, timeoff)
      store.delete(timeoff.getDataID())

      fetchQuery(environment, projectsQuery, {
        personId,
      }).subscribe({})
    },
  })
}
