export const invalidRoles = ["Unknown"]

export const isValidRole = (roleName: string) =>
  !invalidRoles.includes(roleName)

export const DEFAULT_ROLE_TOOLTIP =
  "People can have different roles per project. This will be the default."

export default {
  isValidRole,
  DEFAULT_ROLE_TOOLTIP,
}
