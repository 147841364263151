import React, { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { PersonDetails_person$data } from "../PersonDetails/__generated__/PersonDetails_person.graphql"
import { PersonDetails_user$data } from "../PersonDetails/__generated__/PersonDetails_user.graphql"
import { PlaceholderProjectRow_account$key } from "./__generated__/PlaceholderProjectRow_account.graphql"
import { PlaceholderProjectRow_placeholder$key } from "./__generated__/PlaceholderProjectRow_placeholder.graphql"
import { PersonManagement_user$data } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"

import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { dashify } from "~/helpers/general-helpers"
import { formatName, getCurrentOrLastContract } from "~/helpers/person"
import { Assignment, scrollToRef } from "~/helpers/planner-helpers"

import ClientDetails from "~/common/ClientDetails"

import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import {
  PlannerCalendarRow,
  PlannerLeftColumn,
  PlannerRightColumn,
} from "~/Planner/PlannerLayout"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { panelClosed, panelOpened } from "~/Planner/reducer2/panelSlice"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { projectIdSelected } from "~/Planner/reducer2/schedulePreviewSlice"
import { useAppSelector } from "~/hooks/redux"

type Placeholder =
  | PersonDetails_person$data
  | PersonManagement_user$data["account"]["people"][0]

type Props = {
  account: PlaceholderProjectRow_account$key
  project: PersonDetails_user$data["account"]["projects"][0]
  placeholder: PlaceholderProjectRow_placeholder$key
  /**
   * @deprecated define data requirements in the fragment
   */
  placeholderLegacy: Placeholder
  enabledTentativeProjects: number[]
  delayAssignmentRendering: boolean
  companyDefaultMinutes: number
}

const PlaceholderProjectRow = (props: Props) => {
  const {
    project,
    placeholderLegacy,
    enabledTentativeProjects,
    delayAssignmentRendering,
    companyDefaultMinutes,
  } = props

  const placeholder = useFragment(
    graphql`
      fragment PlaceholderProjectRow_placeholder on people {
        id
        active
        project_memberships {
          id
          workstream {
            id
            name
          }
        }
        ...PlannerLeftColumn_person
      }
    `,
    props.placeholder,
  )

  const account = useFragment(
    graphql`
      fragment PlaceholderProjectRow_account on accounts {
        custom_text_types: custom_text_types {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_date_types: custom_date_types {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_select_types: custom_select_types {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
        }
        custom_checkbox_types: custom_checkbox_types {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        ...PlannerCalendarRow_account
      }
    `,
    props.account,
  )

  const { first_name, last_name, assignments, time_offs, contracts } =
    placeholderLegacy

  const rowRef = useRef()

  const role = getCurrentOrLastContract(contracts)?.role
  const dispatch = useDispatch()

  const multiSelect = useAppSelector((state) => state.multiSelect)

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const multiSelectItem = multiSelect.items[0] as Assignment

  const disabledRow =
    splitScreenMode &&
    Boolean(multiSelectItem) &&
    (multiSelectItem?.person_id !== placeholder.id ||
      multiSelectItem?.project_id !== project.id)

  useEffect(() => {
    if (splitScreenMode && !disabledRow) {
      scrollToRef(rowRef)
    }
  }, [splitScreenMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const name = formatName(first_name, last_name)

  const showPanel = useAppSelector((state) => state.panel.showPanel)

  const projectScheduleOpen = showPanel === "projectSchedule"
  const personScheduleOpen = showPanel === "personSchedule"

  const selectedProjectId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedProjectId,
  )

  const viewProject = () => {
    if (projectScheduleOpen && selectedProjectId === project.id) {
      dispatch(panelClosed())
    } else {
      dispatch(panelOpened("projectSchedule"))
      dispatch(projectIdSelected(project.id))
      track("Project Schedule Panel Opened")
    }
  }

  const tentativeDisabled =
    !isTentativeProjectEnabled(enabledTentativeProjects, project.id) &&
    !project.confirmed

  const visibleAssignments = assignments.filter(
    (a) => a.project_id === project.id,
  )

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types,
    custom_select_types: account.custom_select_types,
    custom_checkbox_types: account.custom_checkbox_types,
    custom_date_types: account.custom_date_types,
  })
  const placeholderCustomFieldValues = valuesMap(placeholderLegacy)
  const projectCustomFieldValues = valuesMap(project)
  const placeholderWorkstream =
    placeholder.project_memberships[0]?.workstream?.name ?? ""

  const clientTitle = (
    <span>
      {project.name} ({project.client.name})
      {!!placeholderWorkstream && ` | ${placeholderWorkstream}`}
    </span>
  )

  const tooltipSecondaryText = personScheduleOpen
    ? null
    : selectedProjectId === project.id
      ? `Hide schedule`
      : `View schedule`

  return (
    <PlannerGrid
      data-test={`placeholder-row-${dashify(name)}`}
      ref={rowRef}
      disabled={tentativeDisabled || disabledRow || !project.active}
      allowInteraction={tentativeDisabled}
      tooltipText={
        !project.active && "Archived projects are not editable in the planner"
      }
    >
      <PlannerLeftColumn
        person={placeholder}
        personLegacy={placeholderLegacy}
        projectId={project.id}
        paddingHeight={7}
        showMenuDots
      >
        <ClientDetails
          id={project.id}
          priority={project.priority}
          isArchived={!project.active}
          title={clientTitle}
          tooltipSecondaryText={tooltipSecondaryText}
          subtitle={null}
          imageKey={project.client.image_key}
          website={project.client.website}
          size={25}
          onClick={viewProject}
          icons={
            <PlannerTooltipIcons
              icons={{
                tags: project.tags_computed,
                customFields: {
                  values: projectCustomFieldValues,
                  types: customFieldTypes,
                },
              }}
            />
          }
          subtitleIcons={
            <PlannerTooltipIcons
              icons={{
                tags: placeholderLegacy.tags,
                competencies: placeholderLegacy.competencies,
                customFields: {
                  values: placeholderCustomFieldValues,
                  types: customFieldTypes,
                },
              }}
            />
          }
          tentative={!project.confirmed}
          tentativePlacement="right"
          placeholder={placeholderLegacy}
          projectEmoji={project.emoji}
        />
      </PlannerLeftColumn>
      <PlannerRightColumn>
        <PlannerCalendarRow
          account={account}
          person={placeholderLegacy}
          project={project}
          assignments={visibleAssignments}
          roleId={role.id}
          workstreamId={
            placeholder.project_memberships[0]?.workstream?.id ?? null
          }
          timeOffs={time_offs}
          contracts={contracts}
          client={project.client}
          companyDefaultMinutes={companyDefaultMinutes}
          delayAssignmentRender={delayAssignmentRendering}
          rowType="placeholder"
        />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default PlaceholderProjectRow
