import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { CSSObjectWithLabel, components } from "react-select"

import { track } from "~/helpers/analytics"

import Select from "~/common/Select"

import {
  EffortDisplayUnit,
  changedEffortDisplayUnit,
} from "../../display_units/effortDisplayUnitSlice"

type EffortOption = {
  readonly value: string
  readonly label: string
  readonly shorthand: string
}

const effortOptions: readonly EffortOption[] = [
  { value: "hoursPerDay" as const, shorthand: "h/d", label: "Hours per day" },
  { value: "hoursPerWeek" as const, shorthand: "h/w", label: "Hours per week" },
  {
    value: "capacityPercentage" as const,
    shorthand: "%",
    label: "Capacity %",
  },
  {
    value: "fullTimeEquivalent" as const,
    shorthand: "FTE",
    label: "FTE",
  },
] as const

const SingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    {props.data.shorthand}
  </components.SingleValue>
)

type Props = {
  effortDisplayUnit: EffortDisplayUnit
  availableOptions?: string[]
}

export const EffortSelect = ({
  effortDisplayUnit,
  availableOptions,
}: Props) => {
  const dispatch = useDispatch()
  const handleEffortDisplayUnitChanged = useCallback(
    (value: EffortDisplayUnit) => {
      track("Effort Unit Changed", { unit: value })
      dispatch(changedEffortDisplayUnit(value))
    },
    [dispatch],
  )
  const options = availableOptions
    ? effortOptions.filter((option) => availableOptions.includes(option.value))
    : effortOptions

  const selectedDisplayOption = effortOptions.find(
    (v) => v.value === effortDisplayUnit,
  )
  return (
    <>
      <Select
        isSearchable={false}
        fontSize={14}
        width={60}
        controlStyles={{
          borderBottomLeftRadius: "var(--rounded-none)",
          borderTopLeftRadius: "var(--rounded-none)",
          maxHeight: 40,
        }}
        optionStyles={{
          padding: "var(--spacing-small)",
          paddingRight: "var(--spacing-medium)",
          whiteSpace: "nowrap",
          fontSize: 13,
        }}
        value={selectedDisplayOption}
        onChange={(newValue) =>
          handleEffortDisplayUnitChanged(newValue.value as EffortDisplayUnit)
        }
        options={options}
        doNotSortOptions={true}
        components={{ SingleValue }}
        styles={{
          singleValue: (base) =>
            ({
              ...base,
              color: "var(--slate)",
            }) as CSSObjectWithLabel,
          valueContainer: (base) =>
            ({
              ...base,
              padding: "0 0 0 5px",
            }) as CSSObjectWithLabel,
          menu: (base) =>
            ({
              ...base,
              width: "auto",
              borderRadius: "var(--rounded)",
            }) as CSSObjectWithLabel,
          menuList: (base) =>
            ({
              ...base,
              padding: 0,
            }) as CSSObjectWithLabel,
          dropdownIndicator: (base) =>
            ({
              ...base,
              paddingLeft: "0",
              paddingRight: "2px",
            }) as CSSObjectWithLabel,
        }}
      />
    </>
  )
}
