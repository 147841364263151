import { dateHelpers } from "@runn/calculations"

type GroupableItem = {
  end_date?: string | null
}

type GroupedItems<T> = {
  upcoming: T[]
  past: T[]
}

const groupItemsByEndDate = <T extends GroupableItem>(
  items: readonly T[],
  currentDate: Date = new Date(),
): GroupedItems<T> =>
  items
    .map((item) => ({
      ...item,
      endDateJS: item.end_date
        ? dateHelpers.parseRunnDate(item.end_date)
        : undefined,
    }))
    .sort((a, b) => {
      const aEndDateJS = a.endDateJS
        ? a.endDateJS.getTime()
        : Number.POSITIVE_INFINITY
      const bEndDateJS = b.endDateJS
        ? b.endDateJS.getTime()
        : Number.POSITIVE_INFINITY
      return aEndDateJS - bEndDateJS
    })
    .reduce(
      (acc, item) => {
        if (!item.endDateJS || item.endDateJS >= currentDate) {
          acc.upcoming.push(item)
        } else {
          acc.past.push(item)
        }
        delete item.endDateJS

        return acc
      },
      {
        upcoming: [],
        past: [],
      } as GroupedItems<T>,
    )

export { groupItemsByEndDate }
