import { graphql } from "react-relay"

import { RateTypeAccountMutation } from "./__generated__/RateTypeAccountMutation.graphql"
import { RateTypeProjectUpdateMutation } from "./__generated__/RateTypeProjectUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

// This file handles ONLY rate types for:
// Account
// Project

const accountRateTypeUpdateMutation = graphql`
  mutation RateTypeAccountMutation($id: Int!, $rate_type: String!) {
    update_accounts_by_pk(
      pk_columns: { id: $id }
      _set: { rate_type: $rate_type }
    ) {
      id
      rate_type
    }
  }
`

export const accountRateTypeUpdateRelay = (
  variables: RateTypeAccountMutation["variables"],
) => {
  return commitMutationPromise<RateTypeAccountMutation>(environment, {
    mutation: accountRateTypeUpdateMutation,
    variables,
  })
}

export const projectRateTypeUpdateRelay = (
  variables: RateTypeProjectUpdateMutation["variables"],
) => {
  return commitMutationPromise<RateTypeProjectUpdateMutation>(environment, {
    mutation: graphql`
      mutation RateTypeProjectUpdateMutation($id: Int!, $rateType: String!) {
        updateProjectRateType(id: $id, rateType: $rateType) {
          project {
            id
            rate_type
          }
        }
      }
    `,
    variables,
  })
}
