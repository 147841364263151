/**
 * @generated SignedSource<<7a6265aedb85bb2c9ed35af80512ea9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonDetails_user$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly id: number;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly client: {
        readonly id: number;
        readonly image_key: string | null | undefined;
        readonly name: string;
        readonly website: string | null | undefined;
      };
      readonly confirmed: boolean;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly optionId: number;
        readonly typeId: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: string;
      }>;
      readonly emoji: string | null | undefined;
      readonly id: number;
      readonly is_template: boolean;
      readonly links: ReadonlyArray<{
        readonly href: string;
        readonly id: number;
        readonly name: string;
        readonly show_in_planner: boolean;
      }>;
      readonly name: string;
      readonly phases: ReadonlyArray<{
        readonly color: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly name: string;
        readonly start_date: string | null | undefined;
      }>;
      readonly pricing_model: string;
      readonly priority: string | null | undefined;
      readonly tags_computed: any | null | undefined;
    }>;
    readonly roles: ReadonlyArray<{
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly workstreams: ReadonlyArray<{
      readonly archived: boolean;
      readonly id: number;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"PersonProjectRow_account" | "PersonTimeOffRow_account" | "PlaceholderProjectRow_account">;
  };
  readonly " $fragmentType": "PersonDetails_user";
};
export type PersonDetails_user$key = {
  readonly " $data"?: PersonDetails_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonDetails_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonDetails_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pricing_model",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "phases",
              "kind": "LinkedField",
              "name": "phases",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                {
                  "alias": "start_date",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start_date_runn",
                  "storageKey": null
                },
                {
                  "alias": "end_date",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end_date_runn",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image_key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "website",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_links",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "href",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "show_in_planner",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "custom_text_values",
              "kind": "LinkedField",
              "name": "custom_text_values",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": "typeId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_text_type_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "custom_select_values",
              "kind": "LinkedField",
              "name": "custom_select_values",
              "plural": true,
              "selections": [
                {
                  "alias": "optionId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_select_option_id",
                  "storageKey": null
                },
                {
                  "alias": "typeId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_select_type_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "custom_checkbox_values",
              "kind": "LinkedField",
              "name": "custom_checkbox_values",
              "plural": true,
              "selections": [
                {
                  "alias": "typeId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_checkbox_type_id",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "custom_date_values",
              "kind": "LinkedField",
              "name": "custom_date_values",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": "typeId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_date_type_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstreams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archived",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlaceholderProjectRow_account"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "projectsFilter",
              "variableName": "projectsFilter"
            }
          ],
          "kind": "FragmentSpread",
          "name": "PersonTimeOffRow_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonProjectRow_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "1b1e4a5583d884cf1a55291d12ebecaf";

export default node;
