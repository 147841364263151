import { graphql, useMutation } from "react-relay"

const extPersonLinkCreateMutation = graphql`
  mutation ExtPersonLinkCreateMutation($input: ExtPersonLinkBulkCreateInput!) {
    action_ext_person_link_bulk_create(input: $input) {
      ext_person {
        id
        ext_person_links(where: { active: { _eq: true } }) {
          person_id
          ext_person_id
          active
        }
      }
    }
  }
`

const useCreateExtPersonLinks = () => {
  const [commit] = useMutation(extPersonLinkCreateMutation)

  const createExtPersonLinks = (args: {
    extPersonLinks: Array<{
      person_id: number
      ext_person_id: number
    }>
    clearExistingLinksOnExtPerson?: boolean
  }) => {
    const { extPersonLinks, clearExistingLinksOnExtPerson } = args
    const input = {
      ext_person_links: extPersonLinks,
      clear_existing_links_on_ext_person: clearExistingLinksOnExtPerson,
    }

    commit({
      variables: {
        input,
      },
    })
  }

  return [createExtPersonLinks]
}

export default useCreateExtPersonLinks
