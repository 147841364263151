import cc from "classcat"
import React, { ReactNode, useCallback, useEffect } from "react"
import { createPortal } from "react-dom"

import styles from "./SidePanel.module.css"

import { mustGetElementHeight } from "~/helpers/layout-helper"

const SidePanelContext = React.createContext<
  | {
      openPanel: (content: ReactNode) => void
      closePanel: () => void
      goBack: () => void
      isOpen: boolean
    }
  | undefined
>(undefined)

export const SidePanelProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [content, setContent] = React.useState(null)
  const [panelHistory, setPanelHistory] = React.useState<ReactNode[]>([])

  const closePanel = useCallback(() => {
    setPanelHistory([])
    setIsOpen(false)
    setTimeout(() => setContent(null), 500)

    // This makes the userflow button appear again once the side panel is closed
    if (window.userflow?.setResourceCenterLauncherHidden) {
      window.userflow.setResourceCenterLauncherHidden(false)
    }
  }, [])

  const openPanel = useCallback(
    (node) => {
      setPanelHistory((prevHistory) => [...prevHistory, node])
      setContent(node)
      // We add this conditional as we want to update the content
      // even if panel is already open
      if (!isOpen) {
        setTimeout(() => setIsOpen(true), 0)

        // This hides the userflow button when the side panel is open
        if (window.userflow?.setResourceCenterLauncherHidden) {
          window.userflow?.setResourceCenterLauncherHidden(true)
        }
      }
    },
    [isOpen],
  )

  const goBack = useCallback(() => {
    if (panelHistory.length > 0) {
      const previousContent = panelHistory.pop()
      setPanelHistory((prevHistory) => prevHistory.slice(0, -1))
      setContent(previousContent)
    } else {
      closePanel()
    }
  }, [panelHistory, closePanel])

  const positionFromTop = mustGetElementHeight("top-bar")

  const Content = () => {
    // Returning it as a component like this, rather than directly in the portal i.e {content}
    // ensures that the content updates when it changes
    return content
  }

  const handleKeyUp = (e) => {
    // Checking if it is focused prevents other components that use Esc to close,
    // from closing i.e. Mode Notifications
    const isFocused =
      document.getElementById("sidePanel")?.contains(document.activeElement) ??
      false

    if (e.key === "Escape" && isFocused) {
      closePanel()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", handleKeyUp)
    } else {
      document.removeEventListener("keyup", handleKeyUp)
    }
    return () => {
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const portal = createPortal(
    <div
      id="sidePanel"
      className={cc([styles.sidePanel, { [styles.isOpen]: isOpen }])}
      style={{ top: `${positionFromTop}px` }}
    >
      <Content />
    </div>,
    document.body,
  )
  return (
    <SidePanelContext.Provider
      value={{ openPanel, closePanel, goBack, isOpen }}
    >
      {portal}
      <div
        className={cc([styles.childrenWrapper, { [styles.isOpen]: isOpen }])}
      >
        {children}
      </div>
    </SidePanelContext.Provider>
  )
}
export const useSidePanel = () => {
  const context = React.useContext(SidePanelContext)
  if (context === undefined) {
    throw new Error("useSidePanel must be used within a SidePanelProvider")
  }
  return context
}
