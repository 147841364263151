import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { Resizable } from "react-resizable"

import styles from "./ResizablePanel.module.css"

import { disableMultiSelectAndResetItems } from "~/Mode.reducer"
import { useAppSelector } from "~/hooks/redux"
import { getSettings, setSetting } from "~/localsettings"

import { panelClosed } from "../reducer2/panelSlice"
import { resetTemporaryPlannerFilter } from "../reducer2/viewsSlice"

const ResizeHandle = () => (
  <div className={styles.resizeHandle}>
    <div className={styles.resizeHandleGrabIcon} />
    <div className={styles.resizeHandleGrabIcon} />
    <div className={styles.resizeHandleGrabIcon} />
  </div>
)

type Props = {
  isScrollable?: boolean
  minHeight?: number
  maxHeight?: number
  onResizeStart?: () => void
  onResizeStop?: () => void
  children: React.ReactNode | ((isResizing: boolean) => React.ReactNode)
}

const DEFAULT_HEIGHT_RATIO = 1 / 3
const DEFAULT_HEIGHT_MAX_PX = 315
const DEFAULT_HEIGHT_MIN_PX = 50

const ResizablePanel = (props: Props) => {
  const {
    isScrollable,
    minHeight = DEFAULT_HEIGHT_MIN_PX,
    maxHeight = DEFAULT_HEIGHT_MAX_PX,
    onResizeStart,
    onResizeStop,
    children,
  } = props
  const dispatch = useDispatch()

  const defaultHeight = useMemo(() => {
    const userHeight = getSettings().plannerGraphHeight
    if (typeof userHeight === "number" && !isNaN(userHeight)) {
      return userHeight
    }

    const maxDefault = Math.round(window.innerHeight * DEFAULT_HEIGHT_RATIO)
    return Math.min(maxHeight, Math.max(minHeight, maxDefault))
  }, [maxHeight, minHeight])

  const [height, setHeight] = useState(defaultHeight)

  const [isResizing, setIsResizing] = useState(false)

  const handleResize = (_event: unknown, { size }) => {
    setHeight(size.height)
  }

  const handleResizeStart = () => {
    setIsResizing(true)
    if (typeof onResizeStart === "function") {
      onResizeStart()
    }
  }

  const handleResizeStop = () => {
    setIsResizing(false)
    setSetting("plannerGraphHeight", height)
    if (typeof onResizeStop === "function") {
      onResizeStop()
    }
  }

  const splitScreenPanel =
    useAppSelector((state) => state.plannerV2.panel.showPanel) === "splitScreen"

  const closePanel = () => {
    if (splitScreenPanel) {
      dispatch(disableMultiSelectAndResetItems())
    }
    dispatch(panelClosed())
    if (splitScreenPanel) {
      dispatch(resetTemporaryPlannerFilter())
    }
  }

  return (
    <div
      className={cc([
        styles.container,
        {
          [styles.isResizing]: isResizing,
        },
      ])}
    >
      <Resizable
        width={300}
        height={height}
        axis="y"
        handle={ResizeHandle}
        resizeHandles={["n"]}
        minConstraints={[0, minHeight]}
        maxConstraints={[Infinity, maxHeight]}
        onResize={handleResize}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
      >
        <div
          className={cc([
            styles.inner,
            {
              [styles.isScrollable]: isScrollable,
            },
          ])}
          style={{ height }}
        >
          <div className={styles.floatingCloseButton} onClick={closePanel}>
            <Icon icon="cross" size={20} />
          </div>
          {typeof children === "function" ? children(isResizing) : children}
        </div>
      </Resizable>
    </div>
  )
}

export { ResizablePanel }
