import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { getSettings } from "~/localsettings"

type PeopleSummaryReducerState = {
  summaryUnit: SummaryUnit
  showWeekly: boolean
  showGroupUtilization: boolean
  isSummaryEffortUnified: boolean
}

export type SummaryUnit =
  | "fullTimeEquivalent"
  | "capacityPercentage"
  | "hoursPerDay"
  | "hoursPerWeek"

const getInitialState = (): PeopleSummaryReducerState => {
  const settings = getSettings()
  return {
    summaryUnit: settings?.peopleSummaryUnit ?? ("hoursPerWeek" as SummaryUnit),
    showWeekly: settings?.peopleSummaryWeekly ?? false,
    showGroupUtilization: settings?.peopleUtilizationGroup ?? true,
    isSummaryEffortUnified: settings?.peopleSummaryEffortUnified ?? false,
  }
}

const peopleSummarySlice = createSlice({
  name: "peopleSummary",
  initialState: getInitialState,
  reducers: {
    setSummaryUnit: (state, action: PayloadAction<SummaryUnit>) => {
      state.summaryUnit = action.payload
    },
    setShowWeekly: (state, action: PayloadAction<boolean>) => {
      state.showWeekly = action.payload
    },
    setShowGroupUtilization: (state, action: PayloadAction<boolean>) => {
      state.showGroupUtilization = action.payload
    },
    setIsSummaryEffortUnified: (state, action: PayloadAction<boolean>) => {
      state.isSummaryEffortUnified = action.payload
    },
  },
})

const { actions, reducer } = peopleSummarySlice
export const {
  setSummaryUnit,
  setShowWeekly,
  setShowGroupUtilization,
  setIsSummaryEffortUnified,
} = actions
export default reducer
