/**
 * @generated SignedSource<<0371d55211b4d7c2d63dc7f6f79a688b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStatusRow_person$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly id: number;
  };
  readonly contracts: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly start_date: string | null | undefined;
  }>;
  readonly id: number;
  readonly " $fragmentType": "ProjectStatusRow_person";
};
export type ProjectStatusRow_person$key = {
  readonly " $data"?: ProjectStatusRow_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStatusRow_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectStatusRow_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "6ad8ebdbdbb3bf9257f8c43905179549";

export default node;
