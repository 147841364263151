import { commitLocalUpdate, graphql } from "react-relay"

import type { ProjectMemberBulkCreateMutation } from "./__generated__/ProjectMemberBulkCreateMutation.graphql"
import type { ProjectMemberChangeRoleMutation } from "./__generated__/ProjectMemberChangeRoleMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise, useMutationPromise } from "./helpers"
import { isSameMember } from "~/helpers/project-member-helpers"
import * as relayids from "~/helpers/relayids"

const projectMemberBulkCreateMutation = graphql`
  mutation ProjectMemberBulkCreateMutation(
    $input: ProjectMemberBulkCreateInput!
  ) {
    action_project_member_bulk_create(input: $input) {
      id
      person {
        id
        project_memberships {
          id
        }
      }
      project {
        id
        project_rates {
          id
          role_id
          project_id
          rate: rate_private
        }
        members {
          id
          project_id
          role_id
          person_id
          workstream_id
          is_placeholder
          person {
            id
            first_name
            last_name
          }
          project {
            id
            name
          }
          role {
            id
            name
          }
          workstream {
            id
            name
          }
        }
        ext_project_links(where: { active: { _eq: true } }) {
          project_id
          ext_project_id
          ext_project {
            id
            integration {
              id
              integration_service {
                id
                name
              }
            }
            ext_project_members(where: { deleted: { _eq: false } }) {
              id
              ext_person {
                id
                first_name
                last_name
                email
                is_placeholder
                active
                ext_person_links(where: { active: { _eq: true } }) {
                  ext_person_id
                  person_id
                  active
                }
              }
            }
          }
        }
      }

      project_member {
        id
        project_id
        person_id
        role_id
        workstream_id
        has_actuals
        has_assignments
        just_added_timestamp
      }
    }
  }
`

export const projectMemberBulkCreateRelay = async (
  variables: ProjectMemberBulkCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectMemberBulkCreateMutation>(
    environment,
    {
      mutation: projectMemberBulkCreateMutation,
      variables,
    },
  )

  commitLocalUpdate(environment, (store) => {
    const currentMembers = data.action_project_member_bulk_create.map(
      (x) => x.project_member,
    )

    for (const memberInput of variables.input.project_members) {
      const member = currentMembers.find((m) => isSameMember(m, memberInput))
      if (member) {
        const projectMember = store.get(
          relayids.projectMembers.encode(member.id),
        )
        if (projectMember) {
          projectMember.setValue(new Date().getTime(), "just_added_timestamp")
        }
      }
    }
  })

  return data.action_project_member_bulk_create
}

const projectMemberBulkCreateMyTimesheetsMutation = graphql`
  mutation ProjectMemberBulkCreateMyTimesheetsMutation(
    $input: ProjectMemberBulkCreateInput!
  ) {
    action_project_member_bulk_create(input: $input) {
      id
      person {
        id
        project_memberships {
          id
        }
      }
      project {
        id
        members {
          id
          project_id
          role_id
          person_id
          workstream_id
          is_placeholder
        }
      }
    }
  }
`

// This is a more limited query run on "My Timesheets" page
// which avoids returning project rates
export const projectMemberBulkCreateMyTimesheetsRelay = async (
  variables: ProjectMemberBulkCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectMemberBulkCreateMutation>(
    environment,
    {
      mutation: projectMemberBulkCreateMyTimesheetsMutation,
      variables,
    },
  )

  return data.action_project_member_bulk_create
}

const projectMemberChangeRoleMutation = graphql`
  mutation ProjectMemberChangeRoleMutation(
    $projectId: Int!
    $personId: Int!
    $workstreamId: Int
    $roleId: Int!
    $newRoleId: Int!
  ) {
    changePersonAssignmentRole(
      projectId: $projectId
      personId: $personId
      workstreamId: $workstreamId
      roleId: $roleId
      newRoleId: $newRoleId
    ) {
      project {
        id
        project_roles {
          id
          role_id
        }
        members {
          id
          role_id
          role {
            id
            name
          }
        }
        assignments {
          id
          role_id
        }
        # Retrieved so ProjectGroupStats updates accordingly
        summary {
          role_id
          workstream_id
          total_billable_minutes
          total_nonbillable_minutes
        }
      }
      account {
        id
        people {
          id
          contracts {
            id
            start_date: start_date_runn
            end_date: end_date_runn
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const useProjectMemberChangeRoleMutation = () => {
  return useMutationPromise<ProjectMemberChangeRoleMutation>(
    projectMemberChangeRoleMutation,
  )
}
