import React from "react"
import isDeeplyEqual from "react-fast-compare"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { ProjectDetailsSection_account$key } from "./__generated__/ProjectDetailsSection_account.graphql"
import { ProjectDetailsSection_project$key } from "./__generated__/ProjectDetailsSection_project.graphql"
import { ProjectDetailsSection_user$key } from "./__generated__/ProjectDetailsSection_user.graphql"

import { getVisibleAssignments } from "~/helpers/assignment-helpers"
import { sortByString } from "~/helpers/sorting-helpers"

import ProjectDetails from "~/ProjectPlanner/ProjectRow/ProjectDetails"
import { useAppSelector } from "~/hooks/redux"

const sortByGroupName = (a: { name: string }, b: { name: string }) => {
  if (a.name === "No workstream") {
    return 1
  }
  if (b.name === "No workstream") {
    return -1
  }

  return sortByString(a.name, b.name)
}

type Props = {
  account: ProjectDetailsSection_account$key
  project: ProjectDetailsSection_project$key
  user: ProjectDetailsSection_user$key
  projectId: number
  locatedInSchedulePanel?: boolean
}

type Person = {
  id: number
  first_name: string
  last_name: string
}

export type Assignment = {
  id: number
  person_id: number
  project_id: number
  role_id: number
  workstream_id: number
  start_date: string
  end_date: string
}

export type ProjectPersonGroup<P extends Person, A extends Assignment> = {
  [key: string]: {
    person: P
    assignments: ReadonlyArray<A>
  }
}

const ProjectDetailsSection = (props: Props) => {
  const { locatedInSchedulePanel } = props

  const account = useFragment(
    graphql`
      fragment ProjectDetailsSection_account on accounts
      @argumentDefinitions(
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        default_full_time_minutes
        people(where: $peopleFilter) {
          id
          first_name
          last_name
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            project_id
            role_id
            workstream_id
            person_id
            start_date: start_date_runn
            end_date: end_date_runn
          }
        }
        roles {
          id
          name
        }
        workstreams {
          id
          name
          archived
        }

        ...ProjectDetails_account
          @arguments(
            peopleFilter: $peopleFilter
            plannerStartDate: $plannerStartDate
          )
      }
    `,
    props.account,
  )

  const user = useFragment(
    graphql`
      fragment ProjectDetailsSection_user on users {
        id
        ...ProjectDetails_user
      }
    `,
    props.user,
  )

  const project = useFragment(
    graphql`
      fragment ProjectDetailsSection_project on projects {
        ...ProjectDetails_project
        id
        name
        confirmed
        pricing_model
        members {
          id
          role_id
          workstream_id
          person_id
          project_id
          is_placeholder
          just_added_timestamp
          person {
            id
            first_name
            last_name
            active
          }
        }
        project_roles {
          id
          role_id
        }
        client {
          id
          name
          image_key
          website
          account_id
          account {
            id
            name
          }
        }
      }
    `,
    props.project,
  )

  const { members, project_roles } = project

  const allPeople = account.people
  const companyDefaultMinutes = account.default_full_time_minutes

  const { calStartNum, calEndNum } = useAppSelector(
    (state) => state.calendar,
    isDeeplyEqual,
  )

  const projectAssignments = allPeople
    .flatMap((p) => p.assignments)
    .filter((a) => a?.project_id === project.id)
  const assignments = getVisibleAssignments(
    projectAssignments,
    calStartNum,
    calEndNum,
  )

  const groupByType = useAppSelector(
    (state) => state.plannerV2.plannerStats.groupByType,
  )

  const allRoles = Array.from(
    new Set([
      ...project_roles.map(({ role_id }) => role_id),
      ...members.map(({ role_id }) => role_id),
    ]),
  )

  const allWorkstreams = Array.from(
    new Set(members.map(({ workstream_id }) => workstream_id)),
  ).filter(Boolean)

  const groups = match(groupByType)
    .with("roles", () =>
      allRoles.map((id) => account.roles.find((role) => role.id === id)),
    )
    .with("workstreams", () => {
      const workstreams = allWorkstreams.map((id) =>
        account.workstreams.find((workstream) => workstream.id === id),
      )

      const membersWithoutWorkstream = members.some(
        (member) => member.workstream_id === null,
      )

      if (membersWithoutWorkstream) {
        workstreams.push({
          id: null,
          name: "No workstream",
          archived: false,
        })
      }

      return workstreams
    })
    .exhaustive()

  const groupsWithContent = groups.map((group) => ({
    ...group,
    assignments: [],
    members: [],
  }))

  members.forEach((member) => {
    const key = groupByType === "roles" ? member.role_id : member.workstream_id
    const matchingGroup = groupsWithContent.find((group) => group.id === key)
    if (matchingGroup) {
      matchingGroup.members.push(member)
    }
  })

  assignments.forEach((assignment) => {
    const key =
      groupByType === "roles" ? assignment.role_id : assignment.workstream_id
    const matchingGroup = groupsWithContent.find((group) => group.id === key)
    if (matchingGroup) {
      matchingGroup.assignments.push(assignment)
    }
  })

  const sortedGroups = Object.values(groupsWithContent).sort((a, b) =>
    sortByGroupName(a, b),
  )

  // Delay the render if over a certain amount of assignments.
  // We used to have this set to 50. But with Virtualization we are trying a much higher number
  const delayAssignmentRender = assignments.length > 1000

  return (
    <>
      {sortedGroups.map((group) => (
        <ProjectDetails
          key={group.id}
          groupId={group.id}
          groupName={group.name}
          account={account}
          user={user}
          project={project}
          assignments={group.assignments}
          members={group.members}
          companyDefaultMinutes={companyDefaultMinutes}
          delayAssignmentRender={delayAssignmentRender}
          locatedInSchedulePanel={locatedInSchedulePanel}
        />
      ))}
    </>
  )
}

export default ProjectDetailsSection
