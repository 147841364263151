/**
 * @generated SignedSource<<034196bea12a50a864bc03bbcc48d21c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonDeleteInput = {
  person_id: number;
};
export type useDeletePersonMutation$variables = {
  input: PersonDeleteInput;
};
export type useDeletePersonMutation$data = {
  readonly action_person_delete: {
    readonly account_id: number;
    readonly affected_projects: ReadonlyArray<{
      readonly project: {
        readonly assignments: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
        readonly members: ReadonlyArray<{
          readonly id: number;
        }>;
      } | null | undefined;
    }>;
  };
};
export type useDeletePersonMutation = {
  response: useDeletePersonMutation$data;
  variables: useDeletePersonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PersonDeleteOutput",
    "kind": "LinkedField",
    "name": "action_person_delete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "account_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PersonDeleteOutputAffectedProject",
        "kind": "LinkedField",
        "name": "affected_projects",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "assignments",
                "kind": "LinkedField",
                "name": "assignments",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project_members",
                "kind": "LinkedField",
                "name": "members",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeletePersonMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeletePersonMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "fcc64c37f25facabae742d0df562b271",
    "id": null,
    "metadata": {},
    "name": "useDeletePersonMutation",
    "operationKind": "mutation",
    "text": "mutation useDeletePersonMutation(\n  $input: PersonDeleteInput!\n) {\n  action_person_delete(input: $input) {\n    account_id\n    affected_projects {\n      project {\n        id\n        assignments {\n          id\n        }\n        members {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "912cdfebb11f4cb2e409894196dc2bf1";

export default node;
