import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React, { ReactNode, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { match } from "ts-pattern"

import styles from "./PlannerLeftColumn.module.css"

import { PlannerLeftColumn_account$key } from "./__generated__/PlannerLeftColumn_account.graphql"
import { PlannerLeftColumn_person$key } from "./__generated__/PlannerLeftColumn_person.graphql"
import { PlannerLeftColumn_project$key } from "./__generated__/PlannerLeftColumn_project.graphql"
import { PlannerLeftColumn_user$key } from "./__generated__/PlannerLeftColumn_user.graphql"
import { PersonSummaryRow_person$data } from "~/PeoplePlanner/PersonSummaryRow/__generated__/PersonSummaryRow_person.graphql"
import { CreateProjectFromTemplateForm_project$key } from "~/forms/CreateProject/__generated__/CreateProjectFromTemplateForm_project.graphql"
import { EditPersonForm_person$key } from "~/forms/EditPersonForm/__generated__/EditPersonForm_person.graphql"
import { EditProjectForm_project$key } from "~/forms/EditProjectForm/__generated__/EditProjectForm_project.graphql"
import { CreateTemplateFromProjectForm_project$key } from "~/forms/ProjectTemplateForm/__generated__/CreateTemplateFromProjectForm_project.graphql"

import { track } from "~/helpers/analytics"
import { CustomCheckboxValue } from "~/helpers/custom-field-helpers"

import { IconRotatingChevron } from "~/common/IconRotatingChevron"
import { IconThreeDot } from "~/common/IconThreeDot"
import { Popover2 } from "~/common/Popover2"

import { PERSON_REQUEST_STATUSES } from "~/ENUMS"
import { isSplitScreenMode } from "~/Mode.reducer"
import { useAppSelector } from "~/hooks/redux"

import {
  MenuType,
  PersonMenuType,
  PlaceholderPersonMenuType,
  ProjectMenuType,
  ProjectTemplateMenuType,
  useAvailableMenu,
} from "./ContextMenu"
import PersonPlannerMenu from "./PersonPlannerMenu"
import PlaceholderPlannerMenu from "./PlaceholderPlannerMenu"
import ProjectPlannerMenu from "./ProjectPlannerMenu"
import ProjectTemplatePlannerMenu from "./ProjectTemplatePlannerMenu"
import SingleExternalPersonPlannerMenu from "./SingleExternalPersonPlannerMenu"

type Props = {
  type?: MenuType
  user?: PlannerLeftColumn_user$key
  account?: PlannerLeftColumn_account$key
  person?: PlannerLeftColumn_person$key
  /**
   * @deprecated define data requirements in the fragment
   */
  personQuery?: EditPersonForm_person$key
  /**
   * @deprecated define data requirements in the fragment
   */
  personLegacy?: Person
  editProjectQuery?: EditProjectForm_project$key
  createTemplateFromProjectQuery?: CreateTemplateFromProjectForm_project$key
  createProjectFromTemplateQuery?: CreateProjectFromTemplateForm_project$key
  projectId?: number
  project?: PlannerLeftColumn_project$key
  roleId?: number
  workstreamId?: number
  className?: string
  children: ReactNode
  onClick?: () => void
  isCallToAction?: boolean
  paddingHeight?: number
  style?: Record<string, any>
  viewPerson?: (e: Event) => void
  showStar?: boolean
  isStarred?: boolean
  handleStar?: (e: React.MouseEvent) => void
  isCollapsed?: boolean
  collapsible?: boolean
  showMenuDots?: boolean
  isInProjectTemplate?: boolean
  locatedInSchedulePanel?: boolean
  options?: React.ReactNode
  showPlaceholderRequestMenu?: boolean
  showFindPerson?: boolean
  extPersonId?: number
  /**
   * @deprecated define data requirements in the fragment
   */
  accountLegacy?: Account
}

export type Person = {
  id: number
  first_name: string
  last_name: string
  is_placeholder: boolean
  active: boolean
  assignments?: readonly Assignments[]
  time_offs: readonly TimeOff[]
  team: {
    id: number
    name: string
  }
  tags?: string[]
  contracts: readonly Contract[]
  archivable: boolean
  competencies?: readonly Competency[]
  custom_text_values?: readonly CustomTextValue[]
  custom_date_values?: readonly CustomDateValue[]
  custom_select_values?: readonly CustomSelectValue[]
  custom_checkbox_values?: readonly CustomCheckboxValue[]
  placeholder_suggestions: readonly { suggested_person_id: number }[]
}

export type Placeholder = Person & {
  person_requests: {
    id: number
    project_id: number
    status: PERSON_REQUEST_STATUSES
  }[]
  people_notes?: ReadonlyArray<{
    id: number
    created_at: string
    note: string
    user: {
      id: number
      first_name: string
      last_name: string
    }
    " $fragmentSpreads": any
  }>
}

type Competency = {
  level: number
  skill: {
    id: number
    name: string
  }
}

type CustomTextValue = {
  value: string
  typeId: number
}

type CustomDateValue = {
  value: string
  typeId: number
}

type CustomSelectValue = {
  optionId: number
  typeId: number
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
  minutes_per_day: number
}

type Assignments = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  non_working_day: boolean
  person_id: number
  role_id: number
  project_id: number
  is_billable: boolean
  is_template: boolean
  phase_id: number
  workstream_id: number
  total_minutes: number
  note: string
}

type Contract = {
  start_date: string
  end_date: string
  role: {
    id: number
    name: string
  }
  minutes_per_day: number
  cost?: number
}

export type Account = {
  invitations: readonly Invitation[]
  users: readonly User[]
}

type User = {
  id: number
  email: string
}

type Invitation = {
  id: number
  email: string
}

const PlannerLeftColumn = (props: Props) => {
  const {
    personLegacy,
    personQuery,
    accountLegacy,
    roleId,
    workstreamId,
    editProjectQuery,
    createTemplateFromProjectQuery,
    createProjectFromTemplateQuery,
    className,
    children,
    type,
    onClick,
    paddingHeight,
    style,
    isCallToAction,
    viewPerson,
    showStar = false,
    isStarred,
    handleStar,
    collapsible = false,
    isCollapsed,
    showMenuDots = false,
    projectId,
    isInProjectTemplate,
    locatedInSchedulePanel,
    options,
    showPlaceholderRequestMenu = true,
    showFindPerson = true,
    extPersonId,
    ...rest
  } = props

  const user = useFragment(
    graphql`
      fragment PlannerLeftColumn_user on users {
        id
        ...PersonSidePanel_user
        ...PersonPlannerMenu_user
      }
    `,
    props.user,
  )

  const account = useFragment(
    graphql`
      fragment PlannerLeftColumn_account on accounts {
        id
        ...PlaceholderPlannerMenu_account
        ...ViewExternalProjectLink_account
      }
    `,
    props.account,
  )

  const person = useFragment(
    graphql`
      fragment PlannerLeftColumn_person on people {
        id
        email
        is_placeholder
        ...PlaceholderPlannerMenu_placeholder
        ...PersonPlannerMenu_person
      }
    `,
    props.person,
  )

  // TODO: there's a lot of data in here that we don't really care about only because
  // <ProjectPlannerMenu /> gets it's data passed in via ...$data instead of ...$key
  // so we need to get all the info that component requires in here. Fixing this would
  // require a refactor for the child to refer to the data it wants via way of ...$key
  // instead of ...$data
  const project = useFragment(
    graphql`
      fragment PlannerLeftColumn_project on projects {
        id
        name
        confirmed
        is_template
        created_at
        calc_start_date
        archivable
        has_actuals
        tags_computed
        active
        priority
        pricing_model
        total_budget: total_budget_private
        expenses_budget
        emoji
        client {
          id
          name
          image_key
          website
          real_client
          internal
        }
        members {
          id
          person_id
        }
        project_roles {
          id
          role_id
          estimated_minutes
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          value
          typeId: custom_checkbox_type_id
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        future_assignments_aggregate: assignments_aggregate(
          where: { end_date_iso: { _gte: $todaysDate } }
        ) {
          aggregate {
            count
          }
        }
        ext_project_links(where: { active: { _eq: true } }) {
          project_id
          ext_project_id
        }
        ...PersonPlannerMenu_project
        ...PlaceholderPlannerMenu_project
        ...ProjectLine_project
        ...ProjectMilestones_project
        ...EditProjectForm_project
        ...CreateProjectFromTemplateForm_project
        ...CreateTemplateFromProjectForm_project
        ...ViewExternalProjectLink_project
      }
    `,
    props.project,
  )

  const [showMenu, setShowMenu] = useState(false)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  const splitScreenEnabled = isSplitScreenMode(modeAction)
  const preventMenu = splitScreenEnabled
  const isTemplate = type === "project-template"
  const menuType =
    type === "person" && personLegacy?.is_placeholder
      ? "placeholder-person"
      : type
  const menuItems = useAvailableMenu({
    type: menuType,
    project: {
      id: project?.id,
      isTemplate: project?.is_template,
      members: project?.members?.map((m) => m.person_id) ?? [],
    },
    person: {
      id: person?.id,
      email: person?.email,
      is_placeholder: person?.is_placeholder,
    },
    user: { id: user?.id },
  })
  const hasMenuItems = Object.values(menuItems).some(Boolean)

  const getMenu = () => {
    const hasMenu = (project || personLegacy) && !preventMenu
    if (!hasMenu || !menuType) {
      return null
    }

    return match(menuType)
      .with("project", () => {
        return hasMenuItems ? (
          <ProjectPlannerMenu
            editProjectQuery={editProjectQuery}
            createTemplateFromProjectQuery={createTemplateFromProjectQuery}
            project={project}
            account={account}
            closeMenu={() => setShowMenu(false)}
            setDialogIsOpen={setDialogIsOpen}
            locatedInSchedulePanel={locatedInSchedulePanel}
            availableMenuItems={menuItems as ProjectMenuType}
          />
        ) : null
      })
      .with("project-template", () => {
        return hasMenuItems ? (
          <ProjectTemplatePlannerMenu
            editTemplateQuery={editProjectQuery}
            createProjectFromTemplateQuery={createProjectFromTemplateQuery}
            project={project}
            closeMenu={() => setShowMenu(false)}
            availableMenuItems={menuItems as ProjectTemplateMenuType}
            setDialogIsOpen={setDialogIsOpen}
          />
        ) : null
      })
      .with("person", () => {
        return hasMenuItems ? (
          <PersonPlannerMenu
            user={user}
            person={person}
            project={project}
            personLegacy={personLegacy as PersonSummaryRow_person$data}
            accountLegacy={accountLegacy}
            personQuery={personQuery}
            roleId={roleId}
            workstreamId={workstreamId ?? null}
            viewPerson={viewPerson}
            closeMenu={() => setShowMenu(false)}
            locatedInSchedulePanel={locatedInSchedulePanel}
            availableMenuItems={menuItems as PersonMenuType}
          />
        ) : null
      })
      .with("placeholder-person", () => {
        return hasMenuItems ? (
          <PlaceholderPlannerMenu
            account={account}
            placeholder={person}
            project={project}
            placeholderLegacy={personLegacy as Placeholder}
            workstreamId={workstreamId ?? null}
            closeMenu={() => setShowMenu(false)}
            isInProjectTemplate={isInProjectTemplate}
            showRequestMenu={showPlaceholderRequestMenu}
            showFindPerson={showFindPerson}
            availableMenuItems={menuItems as PlaceholderPersonMenuType}
          />
        ) : null
      })
      .with("external-person", () => {
        if (typeof extPersonId !== "number") {
          return null
        }

        const extProjectId = project?.ext_project_links?.[0]?.ext_project_id
        if (typeof extProjectId !== "number") {
          return null
        }

        return (
          <SingleExternalPersonPlannerMenu
            projectId={projectId}
            extProjectId={extProjectId}
            extPersonId={extPersonId}
            closeMenu={() => setShowMenu(false)}
            setDialogIsOpen={setDialogIsOpen}
          />
        )
      })
      .exhaustive()
  }

  const menu = getMenu()

  const onMenuClick = (event: React.MouseEvent) => {
    if (!dialogIsOpen) {
      event.stopPropagation()
    }

    if (!menu) {
      return
    }
  }

  const handleOnClick = () => {
    if (showMenu) {
      return null
    }
    if (onClick) {
      onClick()
    }
  }

  const onMenuOpen = () => {
    setShowMenu(true)
    switch (menuType) {
      case "project-template":
        track("Project Template Context Menu Opened")
        break
      case "project":
        track("Project Context Menu Opened")
        break
      case "person":
        track("Person Context Menu Opened")
        break
      case "placeholder-person":
        track("Placeholder Context Menu Opened")
        break
      case "external-person":
        track("External Person Context Menu Opened")
        break
      default:
        menuType satisfies never
    }
  }

  const onMenuClose = () => {
    if (!dialogIsOpen) {
      setShowMenu(false)
    }
  }

  const dataTest = `${
    type === "person" ? personLegacy?.last_name : project?.name
  }`
  const iconColor = isTemplate ? "var(--template-grey)" : "var(--smoke)"

  return (
    <div
      className={cc([
        styles.plannerLeftColumn,
        className,
        {
          [styles.callToAction]: isCallToAction,
          [styles.menuIsOpen]: showMenu,
        },
      ])}
      style={{
        color: showMenu ? "var(--runn-blue)" : "",
        paddingTop: paddingHeight,
        paddingBottom: paddingHeight,
        ...style,
      }}
      onClick={handleOnClick}
      {...rest}
    >
      {children}
      {options}

      {showMenuDots && !preventMenu && menu && hasMenuItems && (
        <div
          className={`row-icons ${showMenu ? "show-icon" : ""}`}
          onClick={onMenuClick}
          data-test={`${dataTest}-menu`}
          data-component="planner-row-dot-menu"
        >
          <Popover2
            isOpen={showMenu}
            content={menu}
            onClose={onMenuClose}
            placement="right-start"
            minimal
          >
            <IconThreeDot
              active={showMenu}
              onClick={onMenuOpen}
              color={iconColor}
              className={styles.moreIcon}
            />
          </Popover2>
        </div>
      )}
      {showStar && !preventMenu && (
        <Icon
          className={`row-icons ${isStarred || showMenu ? "show-icon" : ""}`}
          icon={isStarred ? "star" : "star-empty"}
          onClick={handleStar}
          color={isStarred ? "var(--runn-blue)" : iconColor}
        />
      )}
      {collapsible && (
        <IconRotatingChevron
          direction={isCollapsed ? "right" : "down"}
          size={15}
          title={isCollapsed ? "Expand" : "Collapse"}
          color={iconColor}
        />
      )}
    </div>
  )
}

export default PlannerLeftColumn
