import React, { useEffect, useRef } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./PersonProjectRow.module.css"

import { PersonDetails_person$data } from "./PersonDetails/__generated__/PersonDetails_person.graphql"
import { PersonDetails_user$data } from "./PersonDetails/__generated__/PersonDetails_user.graphql"
import { PersonProjectRow_account$key } from "./__generated__/PersonProjectRow_account.graphql"

import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { getCurrentContract } from "~/helpers/person"
import { Assignment, scrollToRef } from "~/helpers/planner-helpers"

import ClientDetails from "~/common/ClientDetails"

import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import {
  PlannerCalendarRow,
  PlannerLeftColumn,
  PlannerRightColumn,
} from "~/Planner/PlannerLayout"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { panelClosed, panelOpened } from "~/Planner/reducer2/panelSlice"
import { selectIsTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { projectIdSelected } from "~/Planner/reducer2/schedulePreviewSlice"
import ProjectGroupTypeTooltip from "~/ProjectPlanner/ProjectRow/ProjectGroupTypeTooltip"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  account: PersonProjectRow_account$key
  person: PersonDetails_person$data
  client: PersonDetails_user$data["account"]["projects"][0]["client"]
  project: PersonDetails_user$data["account"]["projects"][0]
  role: PersonDetails_user$data["account"]["roles"][0]
  assignments: PersonDetails_person$data["assignments"]
  timeOffs: PersonDetails_person$data["time_offs"]
  justAdded: boolean
  isInactive: boolean
  companyDefaultMinutes: number
  delayAssignmentRender: boolean
  timeOffsWithWeekend?: number[]
  holidaysOverlappingTimeOffs?: string[]
  workstreamId: number
}

const PersonProjectRow = (props: Props) => {
  const {
    person,
    client,
    project,
    role,
    workstreamId,
    assignments,
    timeOffs,
    justAdded,
    isInactive,
    companyDefaultMinutes,
    delayAssignmentRender,
    timeOffsWithWeekend,
    holidaysOverlappingTimeOffs,
  } = props

  const account = useFragment(
    graphql`
      fragment PersonProjectRow_account on accounts {
        secondary_person_field
        custom_text_types_project: custom_text_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_date_types_project: custom_date_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_select_types_project: custom_select_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
        }
        custom_checkbox_types_project: custom_checkbox_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        workstreams {
          id
          name
          archived
        }
        ...PlannerCalendarRow_account
        ...PlannerLeftColumn_account
      }
    `,
    props.account,
  )

  const contracts = person.contracts

  const dispatch = useDispatch()
  const rowRef = useRef()

  const multiSelectItem = useAppSelector(
    (state) => state.multiSelect.items[0],
    isDeeplyEqual,
  ) as Assignment
  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const disabledRow =
    splitScreenMode &&
    Boolean(multiSelectItem) &&
    (multiSelectItem?.person_id !== person.id ||
      multiSelectItem?.project_id !== project.id)

  useEffect(() => {
    if (splitScreenMode && !disabledRow) {
      scrollToRef(rowRef)
    }
  }, [splitScreenMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const showPanel = useAppSelector((state) => state.panel.showPanel)
  const selectedProjectId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedProjectId,
  )

  const projectScheduleOpen = showPanel === "projectSchedule"
  const personScheduleOpen = showPanel === "personSchedule"

  const viewProject = () => {
    if (projectScheduleOpen && selectedProjectId === project.id) {
      dispatch(panelClosed())
    } else {
      dispatch(panelOpened("projectSchedule"))
      dispatch(projectIdSelected(project.id))
      track("Project Schedule Panel Opened")
    }
  }
  const workstream = account.workstreams.find((w) => w.id === workstreamId)

  const testId = (
    `${person.first_name}-${person.last_name}-${project.name}-${role.name}` +
    (workstream ? `-${workstream.name}` : "")
  ).replace(/ /g, "")
  const currentRole = getCurrentContract(person.contracts)?.role?.name

  const isTentativeProjectEnabled = useAppSelector((state) =>
    selectIsTentativeProjectEnabled(
      state.plannerV2.scenarioPlanning,
      project.id,
    ),
  )

  const tentativeDisabled = !isTentativeProjectEnabled && !project.confirmed

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_project,
    custom_date_types: account.custom_date_types_project,
    custom_select_types: account.custom_select_types_project,
    custom_checkbox_types: account.custom_checkbox_types_project,
  })
  const customFieldValues = valuesMap(project)

  const clientTitle = (
    <>
      {project.name}
      <span style={{ fontWeight: "normal" }}> | {client.name}</span>
    </>
  )

  const tooltipSecondaryText =
    personScheduleOpen || justAdded || isInactive
      ? null
      : selectedProjectId === project.id
        ? `Hide schedule`
        : `View schedule`

  const workstreamNameText = workstream?.name ? (
    <ProjectGroupTypeTooltip
      groupNameType="workstreams"
      groupName={workstream.name}
    />
  ) : null
  const alwaysShowRole = account.secondary_person_field === "job_title"
  const roleNameText =
    alwaysShowRole || currentRole !== role.name ? (
      <ProjectGroupTypeTooltip groupNameType="roles" groupName={role.name} />
    ) : null

  const subtitle =
    roleNameText && workstreamNameText ? (
      <div className={styles.subtitleText}>
        {roleNameText}
        &nbsp;|&nbsp;
        {workstreamNameText}
      </div>
    ) : (
      roleNameText || workstreamNameText
    )

  return (
    <PlannerGrid
      data-test={testId}
      data-component="PersonProjectRow"
      ref={rowRef}
      disabled={tentativeDisabled || disabledRow || !project.active}
      allowInteraction={tentativeDisabled}
      tooltipText={
        !project.active && "Archived projects are not editable in the planner"
      }
    >
      <PlannerLeftColumn
        account={account}
        paddingHeight={7}
        className={justAdded ? "fadeInBlueToWhite" : ""}
      >
        <ClientDetails
          id={project.id}
          tentative={!project.confirmed}
          title={clientTitle}
          subtitle={subtitle}
          tooltipSecondaryText={tooltipSecondaryText}
          imageKey={client.image_key}
          website={client.website}
          size={25}
          isInactive={isInactive}
          isArchived={!project.active}
          onClick={personScheduleOpen ? null : viewProject}
          icons={
            <PlannerTooltipIcons
              icons={{
                tags: project.tags_computed,
                customFields: {
                  values: customFieldValues,
                  types: customFieldTypes,
                },
                links: project.links,
              }}
            />
          }
          tentativePlacement="right"
          extended={true}
          priority={project.priority}
          projectEmoji={project.emoji}
        />
      </PlannerLeftColumn>
      <PlannerRightColumn rowId={`${person.id}-${project.id}`}>
        <PlannerCalendarRow
          account={account}
          person={person}
          project={project}
          assignments={assignments}
          roleId={role.id}
          workstreamId={workstreamId}
          timeOffs={timeOffs}
          contracts={contracts}
          client={client}
          companyDefaultMinutes={companyDefaultMinutes}
          delayAssignmentRender={delayAssignmentRender}
          timeOffsWithWeekend={timeOffsWithWeekend}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          rowType="project"
        />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default React.memo(PersonProjectRow, isDeeplyEqual)
