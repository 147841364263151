import { FocusStyleManager } from "@blueprintjs/core"
import React, { Suspense, useEffect } from "react"
import { Provider, useDispatch, useSelector } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { Permissions } from "~/helpers/permissions"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import Loading from "~/common/Loading"
import LoadingSkeleton from "~/common/LoadingSkeleton"

import { disableMultiSelectAndResetItems } from "~/Mode.reducer"
import PageManager from "~/PageManager"
import PlannerFilterQuery from "~/Planner/FilteredQuery/PlannerFilterQuery"
import LoadingSeedDataModal from "~/Planner/LoadingSeedDataModal/LoadingSeedDataModal"
import { setSplitScreenFilterSet } from "~/Planner/Planner.actions"
import PlannerContainer from "~/Planner/PlannerContainer"
import { panelClosed } from "~/Planner/reducer2/panelSlice"
import { withRelayPageContainer } from "~/RelayPageContainer"
import TrialModal from "~/TrialModal/TrialModal"
import { loadStateFromSearchParams } from "~/configureUrlSync"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import NavigationContainer from "./NavigationContainer"
import { getStore } from "./store"

FocusStyleManager.onlyShowFocusOnTabs()

export type Props = Partial<ReduxState> & {
  permissions: Permissions
  trial: {
    account_id: number
    account_name: string
    in_trial: boolean
    subscription: {
      id: string
      status: string
      trial_end: number
      mrr?: number
    }
    subscription_active: boolean
    trial_end: number
    trial_ended: boolean
  }
  timesheetsEnabled: boolean
  reportsV2Enabled: boolean
  viewsEnabled: boolean
  plannerLimit: null
  currentViewId?: number
}

export const blockPage = () => {
  document.getElementById("page-block").style.display = "block"
  document.getElementsByTagName("body")[0].style.overflow = "hidden"
}

export const unblockPage = () => {
  document.getElementById("page-block").style.display = "none"
  document.getElementsByTagName("body")[0].style.overflow = "auto"
}

const PlannerNavigation = (props: Props) => {
  const activePage = useSelector((state: ReduxState) => state.activePage)
  const showPanel = useAppSelector((state) => state.plannerV2.panel.showPanel)

  const dispatch = useDispatch()

  const handlePageToggle = (selectedPage) => {
    dispatch(changeActivePage(selectedPage))

    if (selectedPage !== activePage && showPanel !== "charts") {
      dispatch(disableMultiSelectAndResetItems())
      dispatch(setSplitScreenFilterSet({ name: "", filters: null }))
      dispatch(panelClosed())
    }
  }

  return (
    <NavigationContainer
      handlePageChange={handlePageToggle}
      activePage={activePage}
      settings={{
        timesheetsEnabled: props.timesheetsEnabled,
      }}
      {...props}
    />
  )
}

const RunnApp = (props: Props) => {
  const { trial, permissions, plannerLimit, viewsEnabled, currentViewId } =
    props

  const { store, persistor } = getStore({
    permissions,
    trial,
    plannerLimit,
    plannerV2: {
      views: {
        enabled: viewsEnabled,
        currentViewId,
        showAllView: currentViewId === null,
        temporaryPlannerFilter: { peopleFilters: null, projectFilters: null },
        additionalPlannerIds: {},
      },
    },
  })

  useEffect(() => {
    loadStateFromSearchParams(store.dispatch)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {trial.subscription_active === false && (
          <TrialModal {...trial} permissions={permissions} />
        )}
        <PlannerNavigation {...props} />
        <Suspense fallback={<LoadingSkeleton />}>
          <PlannerFilterQuery>
            <PlannerContainer>
              <PageManager subscriptionActive={trial.subscription_active} />
            </PlannerContainer>
          </PlannerFilterQuery>
        </Suspense>
        <LoadingSeedDataModal />
      </PersistGate>
    </Provider>
  )
}

export default withRelayPageContainer(RunnApp, <Loading />, true)
