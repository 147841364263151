import { Tooltip } from "@blueprintjs/core"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import cc from "classcat"
import React from "react"

import styles from "./EmojiPicker.module.css"

import useDetectOutsideClick from "~/hooks/useDetectOutsideClick"

import { Popover2 } from "../Popover2"
import { DeleteIcon } from "../react-icons"

import Emoji from "./Emoji"

type Props = {
  selectedEmoji: string
  setSelectedEmoji: (emoji: string) => void
  DefaultImage: React.ComponentType
  className?: string
}

const EmojiPicker = (props: Props) => {
  const { setSelectedEmoji, selectedEmoji, DefaultImage, className } = props

  const {
    show: pickerIsOpen,
    setShow: setPickerIsOpen,
    nodeRef,
    triggerRef,
  } = useDetectOutsideClick(false)

  const openPicker = () => {
    setPickerIsOpen(true)
  }

  const removeEmoji = () => {
    setSelectedEmoji(null)
    setPickerIsOpen(false)
  }

  const setEmoji = (emoji: { native: string }) => {
    setSelectedEmoji(emoji.native)
    setPickerIsOpen(false)
  }

  const handleKeyDown = (e) => {
    if (
      e.keyCode === 32 || // space
      e.keyCode === 13 // return/enter
    ) {
      openPicker()
    }

    if (
      e.keyCode === 8 || // backspace
      e.keyCOde === 46 // delete
    ) {
      removeEmoji()
    }
  }

  return (
    <div
      className={cc([styles.wrapper, className])}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <Popover2
        isOpen={pickerIsOpen}
        minimal
        content={
          // * There is a bug with `onClickOutside` when used inside a popover
          // * This event fires as soon as this picker is open which means it
          // * immediatly closes (or doesnt open at all).
          // * Forcing a ref wrapper and handling it with a hook fixes this issue
          <div ref={nodeRef}>
            <Picker
              navPosition="bottom"
              previewPosition="none"
              data={data}
              emoji={selectedEmoji}
              onEmojiSelect={setEmoji}
            />
          </div>
        }
      >
        <div className={`${styles.icon} emoji-icon`} ref={triggerRef}>
          {selectedEmoji ? <Emoji emoji={selectedEmoji} /> : <DefaultImage />}
        </div>
      </Popover2>
      {selectedEmoji && (
        <Tooltip
          content="Remove Emoji"
          placement="top"
          className={`${styles.remove} emoji-remove`}
          hoverOpenDelay={300}
        >
          {/* prevent tabbing to remove button since we can removeEmoji in handleKeyDown */}
          <div onClick={removeEmoji} tabIndex={-1}>
            <DeleteIcon />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default EmojiPicker
