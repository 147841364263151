import { selectGroupPeopleBy } from "~/Planner/reducer2/peopleSortSlice"

import { useAppSelector } from "./redux"

const useGroupUtilizationConfig = () => {
  const groupUtilizationOn = useAppSelector(
    (state) => state.plannerV2.peopleSummary.showGroupUtilization,
  )

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const isDefaultGroupAll = peopleGroupBy === "default"

  return {
    isDefaultGroupAll: isDefaultGroupAll,
    showGroupUtilization: groupUtilizationOn && !isDefaultGroupAll,
  }
}

export default useGroupUtilizationConfig
