import React from "react"

import styles from "./CustomTooltip.module.css"

import {
  CustomSelectType,
  CustomSelectValue,
} from "~/helpers/custom-field-helpers"

type CustomTooltipSelectProps = {
  type: CustomSelectType
  value: CustomSelectValue[]
}
const CustomTooltipSelect = ({ type, value }: CustomTooltipSelectProps) => {
  const values = value.map(
    (v) => type.options.find((o) => o.id === v.optionId)?.name,
  )

  if (!values.length) {
    return null
  }

  return (
    <div className={styles.fieldContainer}>
      <span className={styles.selectTitle}>{type.name}</span>
      <div className={styles.itemContainer}>
        {values.map((selectItem, idx) => (
          <div className={styles.item} key={`${selectItem}-${idx}`}>
            {selectItem}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomTooltipSelect
