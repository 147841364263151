import React from "react"

import { sortByString } from "~/helpers/sorting-helpers"

import Select, { DIVIDER, ReactSelectProps } from "~/common/Select"

import { SelectOption } from "../SelectorModal/SelectorHelpers"

type Props = {
  workstreamOptions: SelectOption[]
  onChange: (SelectOption) => void
  selectedWorkstream: SelectOption
  name?: string
  showWorkstreamsWarning?: boolean
} & ReactSelectProps

const PersonWorkstreamSelector = (props: Props) => {
  const {
    workstreamOptions,
    onChange,
    selectedWorkstream,
    id = "person-workstream-selector",
    name = "person-workstream-selector",
    width = 300,
    showWorkstreamsWarning,
    ...rest
  } = props

  const sortedOptions = workstreamOptions?.sort((a, b) =>
    sortByString(a.label, b.label),
  )

  const noWorkstreamOption = {
    value: null,
    label: "No Workstream",
  }

  const allOptions = [noWorkstreamOption, DIVIDER, ...sortedOptions]

  return (
    <Select
      {...rest}
      classNamePrefix="personWorkstreamSelector"
      name={name}
      id={id}
      placeholder="Select a workstream"
      options={allOptions}
      onChange={onChange}
      value={
        workstreamOptions.find((w) => w.value === selectedWorkstream?.value) ||
        noWorkstreamOption
      }
      height={30}
      width={width}
      controlStyles={
        showWorkstreamsWarning
          ? { border: "1px solid var(--alert)", color: "var(--alert)" }
          : null
      }
    />
  )
}

export default PersonWorkstreamSelector
