import React, { useCallback, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { AccountWelcome_user$key } from "./__generated__/AccountWelcome_user.graphql"

import { track } from "~/helpers/analytics"
import routes from "~/helpers/routes"

import { userViewedWelcomeRelay } from "~/mutations/User"

import OnboardingDialog from "~/Onboarding/OnboardingDialog"
import { withPlannerQuery } from "~/Planner/PlannerContainer"

import WelcomeVideoDialog from "./WelcomeVideoDialog"

type Props = {
  gql: AccountWelcome_user$key
}

type ShowDialog = null | "welcome" | "onboarding"

const AccountWelcome = (props: Props) => {
  const user = useFragment(
    graphql`
      fragment AccountWelcome_user on users
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        id
        account {
          account_type
          alternative_account {
            name
            users_aggregate {
              aggregate {
                count
              }
            }
          }
          name
          users_aggregate {
            aggregate {
              count
            }
          }
        }
        viewed_welcome
        ...WelcomeVideoDialog_user
        ...OnboardingDialog_user
          @arguments(
            projectsFilter: $projectsFilter
            peopleFilter: $peopleFilter
          )
      }
    `,
    props.gql,
  )

  const { account } = user

  const numberOfUsers =
    account.alternative_account.users_aggregate.aggregate.count +
    account.users_aggregate.aggregate.count

  let initialShowDialog: ShowDialog = null
  if (user.account.account_type === "live") {
    initialShowDialog = !user.viewed_welcome ? "welcome" : "onboarding"
  }
  const [showDialog, setShowDialog] = useState<ShowDialog>(initialShowDialog)

  // Tracks every time the modal opens (to determine whether people dismiss it on first view)
  useEffect(() => {
    track("Demo Tour Dialog Opened")
  }, [])

  const saveWelcomeViewed = () => {
    void userViewedWelcomeRelay({
      input: { viewedWelcome: true },
    })
  }

  const onClose = ({
    newShowDialog,
    button,
  }: {
    newShowDialog?: ShowDialog | null
    button?: string
  }) => {
    track("Demo Tour Dialog Closed", { button: button })
    saveWelcomeViewed()
    setShowDialog(newShowDialog || null)
  }

  const onExploreButton = useCallback(async () => {
    onClose({ button: "explore demo" })
    await fetch(routes.toggleTestAccount(), {
      method: "GET",
      redirect: "manual",
    })

    document.location.href = routes.root
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return match(showDialog)
    .with("welcome", () => (
      <WelcomeVideoDialog
        gql={user}
        userCount={numberOfUsers}
        onExploreTest={onExploreButton}
        onSetupAccount={() =>
          onClose({
            button: "skip to setup",
            newShowDialog: "onboarding",
          })
        }
      />
    ))
    .with("onboarding", () => <OnboardingDialog gql={user} />)
    .otherwise(() => null)
}

export default withPlannerQuery(AccountWelcome)
