import { removeLinkedRecord } from "~/mutations/helpers"

import type { DefaultRowData, Model, Store } from "./types"

const applyDeleteAction = (
  store: Store,
  model: Model,
  rowData: DefaultRowData,
) => {
  const globalId = model.getDataId(rowData)
  const $recordProxy = store.get(globalId)
  if ($recordProxy) {
    for (const relationship of model.relationships) {
      const relatedId = relationship.getDataId(rowData)
      const $relatedRecord = store.get(relatedId)
      if ($relatedRecord) {
        removeLinkedRecord(
          $relatedRecord,
          relationship.pluralField,
          $recordProxy,
        )
      }
    }

    store.delete(globalId)

    if (model.postDelete) {
      model.postDelete($recordProxy, rowData)
    }
  }
}

export default applyDeleteAction
