import { Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ViewFieldsTooltip.module.css"

import { ViewFieldsTooltip_user$key } from "~/forms/ProjectForm/__generated__/ViewFieldsTooltip_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import InfoCircle from "~/common/react-icons/infoCircle"

import ViewFilterValues from "~/Views/ViewFilterValues"

type Props = {
  type: "project" | "project template" | "person"
  viewName: string
  nonMatchingFilters: fe.filters.AnyFilter[]
  // TODO: minimal is used in sidePanel, once project details moves to sidePanel
  // we can remove this and use as default
  minimal?: boolean
}

const fragment = graphql`
  fragment ViewFieldsTooltip_user on users
  @argumentDefinitions(
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    id

    ...ViewFilterValues_user
      @arguments(peopleFilter: $peopleFilter, projectsFilter: $projectsFilter)
  }
`

const InfoContainerWrapper = ({
  minimal,
  children,
}: {
  minimal: boolean
  children: React.ReactNode
}) => {
  if (minimal) {
    return <div className={styles.minimal}>{children}</div>
  }

  return (
    <div className={styles.infoContainer}>
      <InfoCircle />
      {children}
    </div>
  )
}

const ViewFieldsTooltip = (props: Props) => {
  const { type, viewName, nonMatchingFilters, minimal } = props

  const user = useFragment<ViewFieldsTooltip_user$key>(
    fragment,
    useHasuraContext(),
  )

  if (nonMatchingFilters.length === 0) {
    return null
  }

  return (
    <InfoContainerWrapper minimal={minimal}>
      <p>This {type} may not be visible in your current</p>
      <Tooltip
        placement="top"
        content={
          <ViewFilterValues
            nonMatchingFilters={nonMatchingFilters}
            user={user}
          />
        }
        openOnTargetFocus={false}
        popoverClassName={styles.tooltip}
      >
        <span className={styles.viewName}>{viewName}</span>
      </Tooltip>
      <p>view</p>
    </InfoContainerWrapper>
  )
}

export default ViewFieldsTooltip
