import numbro from "numbro"

import { DailyActualsAndScheduled } from "~/actuals/helpers/actuals-helpers"

import currency from "./CurrencyHelper"
import { reportError } from "./error-helpers"

type Assignment = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  project_id: number
  person_id: number
  role_id: number
}

const calculateModeMetrics = (
  mode: "hours" | "$",
  assignments: readonly Assignment[],
  combinedHours: DailyActualsAndScheduled,
): number => {
  if (!assignments) {
    return 0
  }

  if (mode === "hours") {
    return combinedHours.reduce(
      (acc, val) => acc + val.combined_billable_minutes,
      0,
    )
  }
  if (mode === "$") {
    return combinedHours.reduce((acc, val) => {
      if (!val.combined_billable_amount) {
        return acc
      }
      return acc + val.combined_billable_amount
    }, 0)
  }

  return 0
}

export const formatDollars = (input) => {
  if (input > 1000) {
    return currency + numbro(input / 1000).format({ mantissa: 2 }) + "k"
  }
  return currency + numbro(input).format({ mantissa: 2 })
}

export const formatToMode = (
  input: number,
  mode: string,
  full = false,
  roundBy = 2,
): string => {
  if (Number.isNaN(input)) {
    void reportError("NaN detected")
    return "$?"
  }

  if (["$", "forecast$"].includes(mode)) {
    if (full) {
      if (input < 0) {
        return (
          "-" +
          currency +
          numbro(-input).format({ mantissa: roundBy, thousandSeparated: true })
        )
      }

      return (
        currency +
        numbro(input).format({ mantissa: roundBy, thousandSeparated: true })
      )
    }

    if (input !== 0) {
      return currency + numbro(input / 1000).format({ mantissa: roundBy }) + "k"
    }
    // if number is negative, move minus sign in front of currency
    if (input < 0) {
      return (
        "-" +
        currency +
        numbro(-input / 1000).format({ mantissa: roundBy }) +
        "k"
      )
    }

    return (
      currency +
      numbro(input).format({ mantissa: roundBy, thousandSeparated: true })
    )
  }

  return numbro(input).format("0,0")
}

export const formatMinutesToDays = (
  minutes: number,
  companyMinutes: number,
  abbrev = "d",
): string => {
  const days = minutes / companyMinutes
  return (
    numbro(days).format({
      trimMantissa: true,
      mantissa: 2,
      thousandSeparated: true,
    }) + abbrev
  )
}

export default calculateModeMetrics
