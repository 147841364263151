/**
 * @generated SignedSource<<f95c9a238ce426abc2a611386c60532a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonManagement_user$data = {
  readonly account: {
    readonly custom_select_types: ReadonlyArray<{
      readonly id: number;
      readonly model: any;
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
    }>;
    readonly default_full_time_minutes: number;
    readonly people: ReadonlyArray<{
      readonly active: boolean;
      readonly archivable: boolean | null | undefined;
      readonly assignments: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly is_billable: boolean | null | undefined;
        readonly is_template: boolean;
        readonly minutes_per_day: number;
        readonly non_working_day: boolean;
        readonly note: string | null | undefined;
        readonly person_id: number;
        readonly phase_id: number | null | undefined;
        readonly project_id: number;
        readonly role_id: number;
        readonly start_date: string | null | undefined;
        readonly total_minutes: number | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly competencies: ReadonlyArray<{
        readonly id: number;
        readonly level: number | null | undefined;
        readonly skill: {
          readonly id: number;
          readonly name: string;
        };
      }>;
      readonly contracts: ReadonlyArray<{
        readonly cost: number | null | undefined;
        readonly employment_type: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly minutes_per_day: number | null | undefined;
        readonly role: {
          readonly active: boolean;
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly role_id: number;
        readonly start_date: string | null | undefined;
      }>;
      readonly created_at: string;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly optionId: number;
        readonly typeId: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: string;
      }>;
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly is_placeholder: boolean;
      readonly last_name: string;
      readonly people_notes: ReadonlyArray<{
        readonly created_at: any;
        readonly id: number;
        readonly note: string;
        readonly " $fragmentSpreads": FragmentRefs<"PersonNote_note">;
      }>;
      readonly person_requests: ReadonlyArray<{
        readonly id: number;
        readonly person: {
          readonly contracts: ReadonlyArray<{
            readonly cost: number | null | undefined;
            readonly end_date: string | null | undefined;
            readonly id: number;
            readonly minutes_per_day: number | null | undefined;
            readonly role: {
              readonly active: boolean;
              readonly id: number;
              readonly name: string | null | undefined;
            };
            readonly start_date: string | null | undefined;
          }>;
          readonly first_name: string;
          readonly id: number;
        };
        readonly project_id: number;
        readonly status: any;
        readonly updated_at: string;
        readonly user: {
          readonly first_name: string | null | undefined;
          readonly id: number;
          readonly last_name: string | null | undefined;
          readonly role_name: string | null | undefined;
        } | null | undefined;
      }>;
      readonly placeholder_suggestions: ReadonlyArray<{
        readonly suggested_person_id: number;
      }>;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly just_added_timestamp: number | null | undefined;
        readonly person_id: number;
        readonly project: {
          readonly id: number;
          readonly name: string;
        };
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly references: any;
      readonly tags: any;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
      readonly time_offs: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly ext_time_off_links: ReadonlyArray<{
          readonly active: boolean;
          readonly ext_time_off_id: number;
          readonly external: {
            readonly __typename: "ext_time_off";
            readonly id: number;
            readonly integration_id: number;
          } | null | undefined;
          readonly external_id: number;
          readonly time_off_id: number;
        }>;
        readonly id: number;
        readonly leave_type: string;
        readonly minutes_per_day: number | null | undefined;
        readonly person_id: number;
        readonly start_date: string | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"PersonCustomEditor_person" | "PersonDetails_person" | "PersonSummaryRow_person" | "PlaceholderSummaryRow_person">;
    }>;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly client: {
        readonly id: number;
        readonly image_key: string | null | undefined;
        readonly name: string;
        readonly website: string | null | undefined;
      };
      readonly confirmed: boolean;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly optionId: number;
        readonly typeId: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly typeId: number;
        readonly value: string;
      }>;
      readonly emoji: string | null | undefined;
      readonly id: number;
      readonly is_template: boolean;
      readonly name: string;
      readonly phases: ReadonlyArray<{
        readonly color: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly name: string;
        readonly start_date: string | null | undefined;
      }>;
      readonly pricing_model: string;
      readonly priority: string | null | undefined;
      readonly tags_computed: any | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"PlaceholderCapacityIndicator_projects">;
    }>;
    readonly teams: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly views: ReadonlyArray<{
      readonly id: number;
    }>;
  };
  readonly favourite_people: any;
  readonly " $fragmentType": "PersonManagement_user";
};
export type PersonManagement_user$key = {
  readonly " $data"?: PersonManagement_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonManagement_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_template",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_text_values",
  "kind": "LinkedField",
  "name": "custom_text_values",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": "typeId",
      "args": null,
      "kind": "ScalarField",
      "name": "custom_text_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_select_values",
  "kind": "LinkedField",
  "name": "custom_select_values",
  "plural": true,
  "selections": [
    {
      "alias": "optionId",
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_option_id",
      "storageKey": null
    },
    {
      "alias": "typeId",
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_checkbox_values",
  "kind": "LinkedField",
  "name": "custom_checkbox_values",
  "plural": true,
  "selections": [
    {
      "alias": "typeId",
      "args": null,
      "kind": "ScalarField",
      "name": "custom_checkbox_type_id",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_date_values",
  "kind": "LinkedField",
  "name": "custom_date_values",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": "typeId",
      "args": null,
      "kind": "ScalarField",
      "name": "custom_date_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v12 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v13 = [
  {
    "kind": "Variable",
    "name": "plannerStartDate",
    "variableName": "plannerStartDate"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_placeholder",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v20 = {
  "alias": "cost",
  "args": null,
  "kind": "ScalarField",
  "name": "cost_private",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "roles",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v22 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonManagement_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_select_types",
          "kind": "LinkedField",
          "name": "custom_select_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "model",
              "storageKey": null
            },
            {
              "alias": "options",
              "args": null,
              "concreteType": "custom_select_options",
              "kind": "LinkedField",
              "name": "custom_select_options",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlaceholderCapacityIndicator_projects"
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pricing_model",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "website",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "phases",
              "kind": "LinkedField",
              "name": "phases",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                (v11/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "teams",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            {
              "args": (v13/*: any*/),
              "kind": "FragmentSpread",
              "name": "PersonSummaryRow_person"
            },
            {
              "args": (v13/*: any*/),
              "kind": "FragmentSpread",
              "name": "PlaceholderSummaryRow_person"
            },
            {
              "args": (v13/*: any*/),
              "kind": "FragmentSpread",
              "name": "PersonDetails_person"
            },
            (v0/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            (v17/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archivable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "contracts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employment_type",
                  "storageKey": null
                },
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v22/*: any*/),
              "concreteType": "time_offs",
              "kind": "LinkedField",
              "name": "time_offs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v23/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "leave_type",
                  "storageKey": null
                },
                (v18/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "active": {
                          "_eq": true
                        }
                      }
                    }
                  ],
                  "concreteType": "ext_time_off_link",
                  "kind": "LinkedField",
                  "name": "ext_time_off_links",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time_off_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ext_time_off_id",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": "external_id",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ext_time_off_id",
                      "storageKey": null
                    },
                    {
                      "alias": "external",
                      "args": null,
                      "concreteType": "ext_time_off",
                      "kind": "LinkedField",
                      "name": "ext_time_off",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "integration_id",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "teams",
              "kind": "LinkedField",
              "name": "team",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v22/*: any*/),
              "concreteType": "assignments",
              "kind": "LinkedField",
              "name": "assignments",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v24/*: any*/),
                (v23/*: any*/),
                (v19/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phase_id",
                  "storageKey": null
                },
                (v25/*: any*/),
                (v12/*: any*/),
                (v11/*: any*/),
                (v18/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "is_billable",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v26/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total_minutes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "non_working_day",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "references",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "competencies",
              "kind": "LinkedField",
              "name": "competencies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "skills",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "project_memberships",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v24/*: any*/),
                (v23/*: any*/),
                (v19/*: any*/),
                (v25/*: any*/),
                (v14/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "projects",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "workstreams",
                  "kind": "LinkedField",
                  "name": "workstream",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "just_added_timestamp",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "people_notes",
              "kind": "LinkedField",
              "name": "people_notes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v26/*: any*/),
                (v17/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonNote_note"
                }
              ],
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "person_requests",
              "kind": "LinkedField",
              "name": "person_requests",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                (v24/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "users",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role_name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "people",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v15/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "contracts",
                      "kind": "LinkedField",
                      "name": "contracts",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/),
                        (v20/*: any*/),
                        (v18/*: any*/),
                        (v21/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "placeholder_suggestions",
              "kind": "LinkedField",
              "name": "placeholder_suggestions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "suggested_person_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PersonCustomEditor_person"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "f7e8dab8088527546847ef74e5d14155";

export default node;
