/**
 * @generated SignedSource<<86d68cdea2f79343df1af0838b5a71c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PhaseDeleteMutation$variables = {
  id: number;
};
export type PhaseDeleteMutation$data = {
  readonly deletePhase: {
    readonly project: {
      readonly actuals: ReadonlyArray<{
        readonly id: number;
        readonly phase_id: number | null | undefined;
      }>;
      readonly assignments: ReadonlyArray<{
        readonly id: number;
        readonly phase_id: number | null | undefined;
      }>;
      readonly calc_end_date: string | null | undefined;
      readonly calc_start_date: string | null | undefined;
      readonly id: number;
      readonly phases: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly phases_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
    } | null | undefined;
  };
};
export type PhaseDeleteMutation = {
  response: PhaseDeleteMutation$data;
  variables: PhaseDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phase_id",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeletePhaseResult",
    "kind": "LinkedField",
    "name": "deletePhase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_start_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_end_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "phases",
            "kind": "LinkedField",
            "name": "phases",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "phases_aggregate",
            "kind": "LinkedField",
            "name": "phases_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "phases_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "actuals",
            "kind": "LinkedField",
            "name": "actuals",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "assignments",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhaseDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhaseDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cd7223fb59a035051effa6416d2d9782",
    "id": null,
    "metadata": {},
    "name": "PhaseDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PhaseDeleteMutation(\n  $id: Int!\n) {\n  deletePhase(id: $id) {\n    project {\n      id\n      calc_start_date\n      calc_end_date\n      phases {\n        id\n      }\n      phases_aggregate {\n        aggregate {\n          count\n        }\n      }\n      actuals {\n        id\n        phase_id\n      }\n      assignments {\n        id\n        phase_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed0fec7549b768c38735aaf40257d2a4";

export default node;
