import { Switch, Tooltip } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./FilterDropdownMenu.module.css"

import { setIncludeArchivedProjects } from "~/Planner/reducer2/peopleFilterSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

type Props = {
  disabled: boolean
}

const IncludeArchivedProjectsToggle = (props: Props) => {
  const { disabled } = props

  const dispatch = useDispatch()

  const includeArchivedProjects = useAppSelector(
    (state) => state.plannerV2.peopleFilter.includeArchivedProjects,
  )

  const toggleIncludeArchivedProjects = () => {
    dispatch(setIncludeArchivedProjects(!includeArchivedProjects))
    setSetting("peopleFilterIncludeArchivedProjects", !includeArchivedProjects)
  }

  return (
    <div className={styles.toggle}>
      <Tooltip
        content="Only available when searching or filtering people using project filters"
        disabled={!disabled}
        placement="top"
      >
        <Switch
          style={disabled ? { color: "var(--winter)" } : {}}
          checked={includeArchivedProjects}
          onChange={toggleIncludeArchivedProjects}
          label="Include archived projects"
          alignIndicator="left"
          disabled={disabled}
        />
      </Tooltip>
    </div>
  )
}

export default IncludeArchivedProjectsToggle
