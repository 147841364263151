import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ViewExternalProjectLink.module.css"

import type { ViewExternalProjectLink_account$key } from "./__generated__/ViewExternalProjectLink_account.graphql"
import type { ViewExternalProjectLink_project$key } from "./__generated__/ViewExternalProjectLink_project.graphql"

import MenuItem from "~/common/MenuItem"
import { Launch } from "~/common/react-icons"

import { useAccountIntegrations } from "~/queries/Account"
import { useExtProjectLinks } from "~/queries/ExtLinks"

type ViewExternalProjectLinkProps = {
  accountRef: ViewExternalProjectLink_account$key
  projectRef: ViewExternalProjectLink_project$key
  menuItem?: boolean
}

const ViewExternalProjectLink = (props: ViewExternalProjectLinkProps) => {
  const { accountRef, projectRef, menuItem } = props

  const account = useFragment(
    graphql`
      fragment ViewExternalProjectLink_account on accounts {
        id
        ...Account_Integrations
      }
    `,
    accountRef,
  )

  const project = useFragment(
    graphql`
      fragment ViewExternalProjectLink_project on projects {
        id
        ...ExtLinks_Project
      }
    `,
    projectRef,
  )

  const integrationList = useAccountIntegrations(account)
  const externalLinkList = useExtProjectLinks(project)

  return externalLinkList.map((externalLink) => {
    const hasRemoteUrl = typeof externalLink.external.remote_url === "string"
    if (!hasRemoteUrl) {
      return null
    }

    const parentIntegration = integrationList.find(
      (integration) => integration.id === externalLink.external.integration_id,
    )
    if (!parentIntegration) {
      return null
    }

    const serviceName = parentIntegration.integration_service.name

    return !menuItem ? (
      <a
        className={styles.viewExternalProjectLink}
        href={externalLink.external.remote_url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Launch /> Open in {serviceName}
      </a>
    ) : (
      <MenuItem
        text={`Open in ${serviceName}`}
        href={externalLink.external.remote_url}
        target="_blank"
        icon={<Launch fill="var(--midnight)" />}
        shouldDismissPopover={false}
      />
    )
  })
}

export { ViewExternalProjectLink }
