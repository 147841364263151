import React, { useState } from "react"

import styles from "./AddNewProject.module.css"

import AddSection from "~/common/AddSection"
import Dialog from "~/common/Dialog"
import AddButton from "~/common/buttons/AddButton"
import { TangramAdventure } from "~/common/tangrams"

import { usePermissions } from "~/Permissions/usePermissions"
import CreateProjectFormPlanner from "~/forms/CreateProject/CreateProjectPlanner"

type AddNewProjectButtonProps = {
  hasNoProjects: boolean
}

const AddNewProjectButton = (props: AddNewProjectButtonProps) => {
  const { hasNoProjects } = props

  const { subject, Can } = usePermissions()

  const [isDialogOpen, setDialogOpen] = useState(false)

  const showDialog = () => setDialogOpen(true)
  const hideDialog = () => setDialogOpen(false)

  return (
    <Can I="create" this={subject("Project")}>
      <AddSection className={styles.container}>
        {hasNoProjects && (
          <div className={styles.hasNoProjects}>
            <TangramAdventure />
            <span>Start your adventure by adding some projects!</span>
          </div>
        )}

        <AddButton
          text={hasNoProjects ? "Add my first project" : "New Project"}
          onClick={showDialog}
          data-test="create-project-button"
          stretch={!hasNoProjects}
        />

        <Dialog
          isOpen={isDialogOpen}
          onClose={hideDialog}
          style={{ width: "auto" }}
        >
          <CreateProjectFormPlanner
            projectType="project"
            closeDialog={hideDialog}
          />
        </Dialog>
      </AddSection>
    </Can>
  )
}

export default AddNewProjectButton
