type Milestone = {
  id: number
  date: string
  title: string
  project_id: number
  icon: string
  note: string
}

export const getMilestonesInRange = (
  milestones: readonly Milestone[],
  range: [number, number],
): Milestone[] => {
  return milestones.filter((m) => {
    const formattedDate = Number(m.date.replace(/-/g, ""))
    return range[0] <= formattedDate && range[1] >= formattedDate
  })
}
