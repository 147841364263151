import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { PeopleGroupByOption } from "~/helpers/group-helpers"
import {
  PeopleSortOption,
  PlaceholderSortOption,
  SortByOrder,
} from "~/helpers/sorting-helpers"

import { getSettings } from "~/localsettings"

type PlannerPeopleSortingState = {
  sortPeopleBy: PeopleSortOption
  sortPlaceholdersBy: PlaceholderSortOption
  groupBy: PeopleGroupByOption
  peopleLastSorted: number
  sortByOrder: SortByOrder
}

const getInitialState = (): PlannerPeopleSortingState => {
  const settings = getSettings()
  return {
    sortPeopleBy: settings?.peoplePlannerSort?.value ?? "personName",
    sortPlaceholdersBy: settings?.placeholderPlannerSort?.value ?? "project",
    groupBy: settings?.peoplePlannerGroupBy?.value || "default",
    peopleLastSorted: new Date().getTime(),
    sortByOrder: "desc",
  }
}

const plannerPeopleSorting = createSlice({
  name: "plannerPeopleSorting",
  initialState: getInitialState,
  reducers: {
    sortPeopleChanged: (state, action: PayloadAction<PeopleSortOption>) => {
      const sortPeopleBy = action.payload
      // reset sortByOrder between sortPeopleBy type change
      if (sortPeopleBy !== state.sortPeopleBy) {
        state.sortByOrder = "desc"
      }

      state.sortPeopleBy = sortPeopleBy
      state.peopleLastSorted = new Date().getTime()
    },
    sortPlaceholdersChanged: (
      state,
      action: PayloadAction<PlaceholderSortOption>,
    ) => {
      const sortPlaceholdersBy = action.payload
      state.sortPlaceholdersBy = sortPlaceholdersBy
      state.peopleLastSorted = new Date().getTime()
    },
    sortByOrderChanged: (state, action: PayloadAction<SortByOrder>) => {
      const sortByOrder = action.payload
      state.sortByOrder = sortByOrder
      state.peopleLastSorted = new Date().getTime()
    },
    groupByChanged: (state, action: PayloadAction<PeopleGroupByOption>) => {
      const groupBy = action.payload
      state.groupBy = groupBy
    },
  },
})

const { actions, reducer } = plannerPeopleSorting
export const {
  sortPeopleChanged,
  sortPlaceholdersChanged,
  sortByOrderChanged,
  groupByChanged,
} = actions
export default reducer

export const selectSortPeopleBy = (state: PlannerPeopleSortingState) =>
  state.sortPeopleBy
export const selectSortPlaceholdersBy = (state: PlannerPeopleSortingState) =>
  state.sortPlaceholdersBy
export const selectGroupPeopleBy = (state: PlannerPeopleSortingState) =>
  state.groupBy
export const selectSortByOrderChanged = (state: PlannerPeopleSortingState) =>
  state.sortByOrder
export const selectPeopleLastSorted = (state: PlannerPeopleSortingState) =>
  state.peopleLastSorted
