/**
 * @generated SignedSource<<62a99d9f675afd84349bf4d2b9f6b1cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useWorkstreams_account$data = {
  readonly workstreams: ReadonlyArray<{
    readonly archived: boolean;
    readonly id: number;
    readonly name: string;
  }>;
  readonly " $fragmentType": "useWorkstreams_account";
};
export type useWorkstreams_account$key = {
  readonly " $data"?: useWorkstreams_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useWorkstreams_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useWorkstreams_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "workstreams",
      "kind": "LinkedField",
      "name": "workstreams",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "a406f18eabd9ef50691446fa4dc7169b";

export default node;
