import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import { hoursToMinutes } from "date-fns"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./EffortInput.module.css"

import { EffortInput_account$key } from "./__generated__/EffortInput_account.graphql"

import { EffortDisplayUnit } from "../../display_units/effortDisplayUnitSlice"
import { useAppSelector } from "../../hooks/redux"

import Duration from "../Duration"
import { Help } from "../react-icons"

import { CapacityPercentageInput } from "./CapacityPercentageInput"
import { EffortSelect } from "./EffortSelect"
import { FTEInput } from "./FTEInput"

/**
 * Assumes a five day work week for historical reasons
 */
export const WORK_DAYS_PER_WEEK = 5

type EffortInputProps = {
  minutesPerDay: number
  onChange: (minutes: number, error?: any) => void
  minMinutes?: number
  maxMinutes?: number
  maxMinutesLabel?: string
  account: EffortInput_account$key
  effortDisplayUnit?: EffortDisplayUnit
  label?: React.ReactNode
  hideInfoTooltip?: boolean
  isTimeOff?: boolean
  contractMinutes: number
}

export const EffortInput = (props: EffortInputProps) => {
  const {
    minutesPerDay,
    onChange,
    minMinutes,
    maxMinutes = 24 * hoursToMinutes(1),
    maxMinutesLabel,
    label,
    hideInfoTooltip,
    isTimeOff,
    contractMinutes,
  } = props

  const account = useFragment(
    graphql`
      fragment EffortInput_account on accounts {
        ...FTEInput_account
      }
    `,
    props.account,
  )

  let effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  if (props.effortDisplayUnit) {
    // Lets the user override the default. If this is overridden then we also
    // don't show the select dropdown later on
    effortDisplayUnit = props.effortDisplayUnit
  }

  return (
    <>
      <div className={styles.tooltip}>
        <label htmlFor="effort" className={styles.tooltipSpacing}>
          {label || "Effort"}
        </label>
        {!hideInfoTooltip && (
          <Tooltip
            placement="bottom"
            content={`Effort can be a number or\na percentage e.g. 50%`}
            popoverClassName={styles.tooltipLineBreak}
          >
            <Help />
          </Tooltip>
        )}
      </div>
      <div className={styles.effort}>
        {effortDisplayUnit === "capacityPercentage" ? (
          <CapacityPercentageInput
            minutesPerDay={minutesPerDay}
            onChange={onChange}
            maxMinutes={maxMinutes}
            totalForPercentage={contractMinutes}
          />
        ) : null}
        {effortDisplayUnit === "fullTimeEquivalent" ? (
          <FTEInput
            account={account}
            minutesPerDay={minutesPerDay}
            onChange={onChange}
            maxMinutes={maxMinutes}
          />
        ) : null}
        {effortDisplayUnit === "hoursPerWeek" ? (
          <Duration
            handleMinutesPerDay={(minutes) =>
              onChange(minutes / WORK_DAYS_PER_WEEK)
            }
            minutesPerDay={minutesPerDay * WORK_DAYS_PER_WEEK}
            className={cc([
              styles.duration,
              {
                [styles.noSelect]: !!props.effortDisplayUnit,
              },
            ])}
            autoFocus
            totalForPercentage={contractMinutes * WORK_DAYS_PER_WEEK}
            maxMinutes={maxMinutes * WORK_DAYS_PER_WEEK}
            maxMinutesLabel={maxMinutesLabel}
            allowZero
            allowEmpty={false}
            effortDisplayUnit={effortDisplayUnit}
            defaultDuration={contractMinutes * WORK_DAYS_PER_WEEK}
          />
        ) : null}
        {effortDisplayUnit === "hoursPerDay" ? (
          <Duration
            handleMinutesPerDay={onChange}
            minutesPerDay={minutesPerDay}
            maxMinutesLabel={maxMinutesLabel}
            className={cc([
              styles.duration,
              {
                [styles.noSelect]: !!props.effortDisplayUnit,
              },
            ])}
            autoFocus
            totalForPercentage={contractMinutes}
            minMinutes={minMinutes}
            maxMinutes={maxMinutes}
            allowZero
            allowEmpty={false}
            effortDisplayUnit={effortDisplayUnit}
            isTimeOff={isTimeOff}
            defaultDuration={contractMinutes}
          />
        ) : null}
        {!props.effortDisplayUnit && (
          <EffortSelect effortDisplayUnit={effortDisplayUnit} />
        )}
      </div>
    </>
  )
}
