import { Icon } from "@blueprintjs/core"
import React from "react"

import FilterDownMessage from "~/Planner/LimitedQuery/FilterDownMessage"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  functionality: string
}
const OverflowTooltipContent = ({ functionality }: Props) => {
  const limit = useAppSelector((state: { plannerLimit }) => state.plannerLimit)

  return (
    <div style={{ maxWidth: 230, display: "flex", alignItems: "center" }}>
      <Icon icon="info-sign" style={{ marginRight: "var(--spacing-xs)" }} />
      <FilterDownMessage limit={limit} functionality={functionality} />
    </div>
  )
}

export default OverflowTooltipContent
