import planner2 from "."
import { createSelector } from "@reduxjs/toolkit"
import * as fe from "@runn/filter-engine"

import { selectAnyTentativeProjectsEnabled } from "./scenarioPlanningSlice"

type PlannerState = ReturnType<typeof planner2>

const selectFilterSet = (state: PlannerState) => state.projectFilter.filterSet

const selectSearch = (state: PlannerState) => state.projectFilter.search

const activeProjects = fe.filters.projectIsActive({ value: true })

export const selectProjectFilterSet = createSelector(
  [
    selectFilterSet,
    selectSearch,
    (state) => selectAnyTentativeProjectsEnabled(state.scenarioPlanning),
  ],
  (filterSet, search, anyTentativeEnabled) => {
    const filters = [activeProjects, filterSet.filters]

    // Only filter by search when is not an empty string
    if (search !== "") {
      const wildSearch = fe.filters.projectWildSearch({ query: search })
      filters.push(wildSearch)
    }

    // Only show tentative projects when any are enabled
    if (!anyTentativeEnabled) {
      const onlyConfirmed = fe.filters.projectIsConfirmed({ value: true })
      filters.push(onlyConfirmed)
    }

    return { name: filterSet.name, filters: fe.filters.and(filters) }
  },
)
