import truncate from "lodash-es/truncate"
import React from "react"

import styles from "./CustomTooltip.module.css"

import { CustomTextType, CustomTextValue } from "~/helpers/custom-field-helpers"

type CustomTooltipTextProps = {
  type: CustomTextType
  value: CustomTextValue
}
const CustomTooltipText = ({ type, value }: CustomTooltipTextProps) => {
  if (!value?.value.trim()) {
    return null
  }

  const textValue =
    value?.value.length > 280
      ? `${truncate(value?.value, { length: 280, separator: " " })}`
      : value?.value

  return (
    <div className={styles.fieldContainer}>
      <span className={styles.title}>{type.name}</span>
      <div className={styles.itemContainer}>{textValue}</div>
    </div>
  )
}

export default CustomTooltipText
