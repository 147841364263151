type GetFirstActiveExtTimeOffOptions<ExtTimeOff> = {
  readonly ext_time_off_links: ReadonlyArray<{
    active: boolean
    external: ExtTimeOff
  }>
}

const getFirstActiveExtTimeOff = <T>(
  timeOff: GetFirstActiveExtTimeOffOptions<T>,
): T | undefined => {
  const extTimeOffLink = timeOff.ext_time_off_links.find((link) => {
    return link.active
  })
  if (!extTimeOffLink) {
    return undefined
  }
  return extTimeOffLink.external
}

export { getFirstActiveExtTimeOff }
