import { Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { format as formatDate, parseISO } from "date-fns"
import { format } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PhaseItem.module.css"

import { PhaseItem_phase$key } from "./__generated__/PhaseItem_phase.graphql"

import { getGridAttributes } from "~/helpers/PhaseHelper"
import { findColor } from "~/helpers/colors"
import { shiftIntervalByAmount } from "~/helpers/workingDays-helpers"

import { phaseDeleteRelay, phaseUpdateRelay } from "~/mutations/Phase"

import { setHighlightedItemData } from "~/GlobalState"
import { usePermissions } from "~/Permissions/usePermissions"
import { ReduxState } from "~/rootReducer"

import PhaseForm from "./PhaseForm"
import PhaseName from "./PhaseName"

type Props = {
  phase: PhaseItem_phase$key
  phasesWrapperRef: React.RefObject<HTMLElement>
  setOverlayCursor?: (cursorType: "grabbing" | "ew-resize") => void
  calendarWeekendsExpanded: boolean
  isTemplate: boolean
}

type TooltipProps = {
  children: React.ReactElement
  disabled?: boolean
  canEditPhase: boolean
  phase: {
    name: string
    start_date: string
    end_date: string
  }
}

const PhaseTooltip = (props: TooltipProps) => {
  const { phase, canEditPhase, disabled } = props

  const startDate = formatDate(
    dateHelpers.parseRunnDate(phase.start_date),
    "dd MMM",
  )
  const endDate = formatDate(
    dateHelpers.parseRunnDate(phase.end_date),
    "dd MMM",
  )

  const content = !canEditPhase ? (
    <div>You do not have permission to edit phases for this project</div>
  ) : (
    <div>
      <div>
        <b>Name:</b> {phase.name}
      </div>
      <div>
        <b>Dates:</b> {startDate} - {endDate}
      </div>
    </div>
  )

  return (
    <Tooltip
      content={content}
      hoverOpenDelay={300}
      hoverCloseDelay={100}
      interactionKind="hover"
      disabled={disabled}
    >
      {props.children}
    </Tooltip>
  )
}

const PhaseItem = (props: Props) => {
  const {
    phasesWrapperRef,
    setOverlayCursor,
    calendarWeekendsExpanded,
    isTemplate,
  } = props
  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )
  const {
    calendarStartDate,
    calendarEndDate,
    calStartNum,
    calEndNum,
    dayWidth,
  } = calendar

  const phase = useFragment(
    graphql`
      fragment PhaseItem_phase on phases {
        id
        name
        start_date: start_date_runn
        end_date: end_date_runn
        color
        project_id
      }
    `,
    props.phase,
  )

  const { can, subject } = usePermissions()
  const phaseSubject = subject("Phase", {
    project: { id: phase.project_id, isTemplate },
  })
  const phasePermissions = {
    edit: can("edit", phaseSubject),
  }

  const dispatch = useDispatch()
  const initialDates = {
    start: dateHelpers.parseRunnDate(phase.start_date),
    end: dateHelpers.parseRunnDate(phase.end_date),
  }

  const [showForm, setShowForm] = useState(false)
  const [active, setActive] = useState(false)

  // State does not update in event listener functions. Refs are used to fix this.
  const refHoverDay = useRef(null)
  const refTempDates = useRef(null)
  const refFirstSelectedDay = useRef(null)
  const refMode = useRef(null)

  const [hoverDay, setHoverDay] = useState(null)
  const [firstSelectedDay, setFirstSelectedDay] = useState(null)
  const [mode, setMode] = useState(null)
  const [tempDates, setTempDates] = useState(null)

  const dates = tempDates || initialDates

  useEffect(
    () => {
      refTempDates.current = dates
      refHoverDay.current = hoverDay
      refFirstSelectedDay.current = firstSelectedDay
      refMode.current = mode
      dispatch(
        setHighlightedItemData({
          type: "phase",
          start_date: dates.start && dateHelpers.formatToRunnDate(dates.start),
          end_date: dates.end && dateHelpers.formatToRunnDate(dates.end),
          color: phase.color,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverDay, firstSelectedDay, active, mode, tempDates],
  )

  const resetValues = () => {
    setActive(false)
    setShowForm(false)
    setHoverDay(null)
    setFirstSelectedDay(null)
    setMode(null)
    setTempDates(null)
    dispatch(setHighlightedItemData(null))
  }

  const updatePhase = async (phaseData: { name; start; end; color }) => {
    await phaseUpdateRelay({
      id: phase.id,
      name: phaseData.name,
      start_date: format(phaseData.start, "yyyy-MM-dd"),
      end_date: format(phaseData.end, "yyyy-MM-dd"),
      color: phaseData.color,
      project_id: phase.project_id,
    })
    resetValues()
  }

  const deletePhase = () => {
    void phaseDeleteRelay({ id: phase.id })
  }

  const onMouseEnter = () => {
    setActive(true)
  }

  const onMouseLeave = () => {
    if (!mode) {
      resetValues()
    }
  }

  const preventContextMenu = (e) => {
    e.preventDefault()
  }

  const onMouseMove = (e) => {
    if (phasesWrapperRef.current == null) {
      // we need to know where the phasesWrapper is before we can handle the
      // mouse movement
      return
    }

    const mousePositionOffset = e.pageX - phasesWrapperRef.current.offsetLeft
    const hoveredDay = Math.ceil(mousePositionOffset / dayWidth)
    const hoveredDayHasChanged = hoveredDay !== refHoverDay.current

    if (hoveredDayHasChanged) {
      setHoverDay(hoveredDay)
      setActive(true)

      if (!refFirstSelectedDay.current) {
        setFirstSelectedDay(hoveredDay)
      }

      const daysMoved = (refFirstSelectedDay.current || hoveredDay) - hoveredDay

      const { start, end } = shiftIntervalByAmount({
        interval: {
          start: parseISO(phase.start_date),
          end: parseISO(phase.end_date),
        },
        amount: -daysMoved,
      })

      const mouseIsWithinRange =
        mousePositionOffset < phasesWrapperRef.current.offsetWidth &&
        mousePositionOffset > 0

      // moving phase
      if (refMode.current === "moving") {
        // phase can be moved further than calendar start/end dates - but shows at least one day.
        if (
          Number(dateHelpers.formatToRunnDate(end)) >= calStartNum &&
          Number(dateHelpers.formatToRunnDate(start)) <= calEndNum
        ) {
          setTempDates({ start, end })
        }
      }

      // resizing phase
      // phase can only be resized far as the calendar start and end dates
      if (mouseIsWithinRange) {
        if (
          refMode.current === "resize-start" &&
          Number(dateHelpers.formatToRunnDate(start)) <= Number(phase.end_date)
        ) {
          setTempDates({ ...refTempDates.current, start })
        }
        if (
          refMode.current === "resize-end" &&
          Number(dateHelpers.formatToRunnDate(end)) >= Number(phase.start_date)
        ) {
          setTempDates({ ...refTempDates.current, end })
        }
      }
    }
  }

  const onMouseUp = () => {
    setOverlayCursor(null)

    const daysMoved = refHoverDay.current - refFirstSelectedDay.current
    if (!!daysMoved) {
      void updatePhase({
        name: phase.name,
        start: refTempDates.current.start,
        end: refTempDates.current.end,
        color: phase.color,
      })
    } else {
      setShowForm(true)
    }
    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)
  }

  const onPillMouseDown = (e) => {
    if (e.button === 0 && !showForm) {
      setOverlayCursor("grabbing")
      setMode("moving")
      setActive(true)
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    }
  }

  const handleResizer = (e, type) => {
    if (e.button === 0) {
      setOverlayCursor("ew-resize")
      setActive(true)
      setMode(`resize-${type}`)
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    }
  }

  if (!phasePermissions.edit) {
    const basicPill = getGridAttributes({
      start: dateHelpers.parseRunnDate(phase.start_date),
      end: dateHelpers.parseRunnDate(phase.end_date),
      calendarStartDate,
      calendarEndDate,
      dayWidth,
      calendarWeekendsExpanded,
    })

    return (
      <>
        <div
          className={styles.phaseItem}
          style={{
            gridColumn: `${basicPill.gridStart} / span ${basicPill.gridSpan}`,
          }}
        >
          <PhaseTooltip phase={phase} canEditPhase={phasePermissions.edit}>
            <div
              className={styles.pill}
              style={{
                backgroundColor: phase.color,
                borderTopLeftRadius:
                  Number(phase.start_date) < calStartNum ? 0 : "10px",
                borderTopRightRadius:
                  Number(phase.end_date || phase.start_date) > calEndNum
                    ? 0
                    : "10px",
                cursor: "auto",
              }}
            >
              <PhaseName phase={phase} pillLength={basicPill.lengthInPx} />
            </div>
          </PhaseTooltip>
        </div>
      </>
    )
  }

  const pill = getGridAttributes({
    start: dates.start,
    end: dates.end,
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
  })
  const halfSizeResizer = pill.lengthInPx < 25

  return (
    <div
      data-component="PhaseItem"
      className={styles.phaseItem}
      style={{ gridColumn: `${pill.gridStart} / span ${pill.gridSpan}` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onContextMenu={preventContextMenu}
      data-color={phase.color}
      data-phase-id={phase.id}
    >
      <div
        onMouseDown={(e) => handleResizer(e, "start")}
        className={styles.phaseResizer}
        style={{ width: halfSizeResizer ? "3px" : "10px", left: 0 }}
      />
      <PhaseTooltip
        phase={phase}
        canEditPhase={phasePermissions.edit}
        disabled={showForm}
      >
        <div
          className={styles.pill}
          style={{
            backgroundColor: active
              ? findColor(phase.color).darker
              : phase.color,
            borderTopLeftRadius:
              Number(dateHelpers.formatToRunnDate(dates.start)) < calStartNum
                ? 0
                : "10px",
            borderTopRightRadius:
              Number(dateHelpers.formatToRunnDate(dates.end || dates.start)) >
              calEndNum
                ? 0
                : "10px",
          }}
          onMouseDown={onPillMouseDown}
        >
          <PhaseName phase={phase} pillLength={pill.lengthInPx} />
          {showForm && (
            <PhaseForm
              phaseName={phase.name}
              dates={dates}
              onCancel={resetValues}
              onDelete={deletePhase}
              onSubmit={updatePhase}
              phaseColor={phase.color}
            />
          )}
        </div>
      </PhaseTooltip>
      <div
        onMouseDown={(e) => handleResizer(e, "end")}
        className={styles.phaseResizer}
        style={{ width: halfSizeResizer ? "3px" : "10px", right: 0 }}
      />
    </div>
  )
}

export default PhaseItem
