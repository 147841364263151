/**
 * @generated SignedSource<<b0a16517985c6b003185e04af3e9a3ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsDetectedPersonRow_extPerson$data = {
  readonly active: boolean;
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly " $fragmentType": "ProjectDetailsDetectedPersonRow_extPerson";
};
export type ProjectDetailsDetectedPersonRow_extPerson$key = {
  readonly " $data"?: ProjectDetailsDetectedPersonRow_extPerson$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_extPerson">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsDetectedPersonRow_extPerson",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "type": "ext_person",
  "abstractKey": null
};

(node as any).hash = "2742e7ec5d970990c746abb38d65ec37";

export default node;
