/**
 * @generated SignedSource<<162f58f37b12b1232672dea015b88a04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonNoteUpdateMutation$variables = {
  note: string;
  noteId: number;
};
export type PersonNoteUpdateMutation$data = {
  readonly updatePersonNote: {
    readonly personNote: {
      readonly created_by: string | null | undefined;
      readonly creator_email: string | null | undefined;
      readonly id: number;
      readonly note: string;
      readonly updated_at: any;
      readonly user_id: number | null | undefined;
    } | null | undefined;
  };
};
export type PersonNoteUpdateMutation = {
  response: PersonNoteUpdateMutation$data;
  variables: PersonNoteUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noteId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "noteId",
        "variableName": "noteId"
      }
    ],
    "concreteType": "PersonNote",
    "kind": "LinkedField",
    "name": "updatePersonNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people_notes",
        "kind": "LinkedField",
        "name": "personNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_by",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creator_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonNoteUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PersonNoteUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "44175e76305ef0f6e44545e067ce40d8",
    "id": null,
    "metadata": {},
    "name": "PersonNoteUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonNoteUpdateMutation(\n  $noteId: Int!\n  $note: String!\n) {\n  updatePersonNote(noteId: $noteId, note: $note) {\n    personNote {\n      id\n      note\n      updated_at\n      created_by\n      creator_email\n      user_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1531fb297e8bc14d288ea8442d531b90";

export default node;
