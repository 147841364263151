/**
 * @generated SignedSource<<0a3d3ecddf3f6a8e24af8c5d19e29fca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePeople_account$data = {
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly first_name: string;
    readonly id: number;
    readonly is_placeholder: boolean;
    readonly last_name: string;
  }>;
  readonly " $fragmentType": "usePeople_account";
};
export type usePeople_account$key = {
  readonly " $data"?: usePeople_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePeople_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePeople_account",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_placeholder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "3ff7177ebd64fb6851ca0d7149442cbf";

export default node;
