import React from "react"

import styles from "./PeopleGroupHeadingContainer.module.css"

import PeopleHeadingBar, {
  PeopleHeadingBarText,
} from "~/common/PeopleHeadingBar"

import { PlannerLeftColumn } from "~/Planner/PlannerLayout"

type Props = {
  id?: string
  text: string
  onClick: () => void
  isCollapsed: boolean
  style?: Record<string, any>
  isMiniHeading?: boolean
  children?: React.ReactNode
  placeholderCount?: number | string
  peopleCount?: number | string
  dataTest?: string
}

const PeopleGroupHeadingContainer = (props: Props) => {
  const {
    text,
    isCollapsed,
    style,
    isMiniHeading,
    onClick,
    placeholderCount,
    peopleCount,
    dataTest,
  } = props

  if (isMiniHeading) {
    return (
      <PeopleHeadingBar
        text={text}
        placeholderCount={placeholderCount}
        peopleCount={peopleCount}
        isCollapsed={isCollapsed}
        onClick={onClick}
        data-test={dataTest}
      />
    )
  }

  return (
    <div
      className={styles.groupHeader}
      onClick={onClick}
      data-test={dataTest}
      style={style}
    >
      <PlannerLeftColumn paddingHeight={0}>
        <PeopleHeadingBarText
          placeholderCount={placeholderCount}
          peopleCount={peopleCount}
          text={text}
          isCollapsed={isCollapsed}
        />
      </PlannerLeftColumn>
      {props.children}
    </div>
  )
}

export default PeopleGroupHeadingContainer
