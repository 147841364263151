import React from "react"

import styles from "./SkillLabel.module.css"

import { SkillsLabelsList_viewer$data } from "./__generated__/SkillsLabelsList_viewer.graphql"

import CompetencyPicker from "~/Skills/Competency/CompetencyPicker"

import SkillLabelMore from "./SkillLabelMore"

type Props = {
  competency: SkillsLabelsList_viewer$data["account"]["competencies"][0]
  canEdit: boolean
  style?: React.CSSProperties
}

const SkillLabel = (props: Props) => {
  const { competency, canEdit, style } = props
  return (
    <div className={styles.wrapper} style={style}>
      <CompetencyPicker competency={competency} canEdit={canEdit} hideLevel />
      {canEdit && (
        <div className={styles.moreButton}>
          <span className={styles.divider} />
          <SkillLabelMore competency_id={competency.id} />
        </div>
      )}
    </div>
  )
}

export default SkillLabel
