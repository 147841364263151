/**
 * @generated SignedSource<<13b8fe9c8bd6161ff7d6d04bfc232be0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewExternalProjectLink_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"Account_Integrations">;
  readonly " $fragmentType": "ViewExternalProjectLink_account";
};
export type ViewExternalProjectLink_account$key = {
  readonly " $data"?: ViewExternalProjectLink_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewExternalProjectLink_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewExternalProjectLink_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Account_Integrations"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "af7a6c2236da09288c6383559e11b76e";

export default node;
