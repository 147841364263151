/**
 * @generated SignedSource<<b734e1c9d71d0b8270c83b09c7088263>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonAddProjectRow_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddProjectToPerson_user">;
  readonly " $fragmentType": "PersonAddProjectRow_user";
};
export type PersonAddProjectRow_user$key = {
  readonly " $data"?: PersonAddProjectRow_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonAddProjectRow_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonAddProjectRow_user",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AddProjectToPerson_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "429ae0816559e5ac72f3829524548067";

export default node;
