import React from "react"

import styles from "./Onboarding.module.css"

type Props = {
  step: number
  totalSteps: number
}

const OnboardingStep = (props: Props) => {
  return (
    <span className={styles.onboardingStep}>
      STEP {props.step} OF {props.totalSteps}
    </span>
  )
}

export default OnboardingStep
