import { TooltipProps } from "@blueprintjs/core"
import React from "react"

import { sortByString } from "~/helpers/sorting-helpers"

import TooltipList, { TooltipListLabel } from "~/common/TooltipList"
import { CompetencyIcon, SkillIcon } from "~/common/react-icons"

type Competency = {
  level: number
  skill: {
    name: string
  }
}

type Props = {
  competencies: ReadonlyArray<Competency> | undefined
  children?: React.ReactNode
  tooltipProps?: Omit<TooltipProps, "content">
}

const CompetencyTooltipList = (props: Props) => {
  const { competencies, children, tooltipProps } = props

  if (!competencies?.length) {
    return null
  }
  const labels = [...competencies]
    .sort((a, b) => sortByString(a.skill.name, b.skill.name))
    .map((c) => (
      <TooltipListLabel
        title={c.skill.name}
        icon={c.level && <CompetencyIcon level={c.level} darkMode />}
        key={c.skill.name}
      />
    ))
  return (
    <TooltipList
      tooltipProps={tooltipProps}
      title="Skills"
      content={<>{labels}</>}
    >
      {children || <SkillIcon />}
    </TooltipList>
  )
}

export default CompetencyTooltipList
