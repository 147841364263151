import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { PersonAddProjectRow_person$key } from "./__generated__/PersonAddProjectRow_person.graphql"
import { PersonAddProjectRow_user$key } from "~/PeoplePlanner/PersonAddProjectRow/__generated__/PersonAddProjectRow_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { formatName, getCurrentContract } from "~/helpers/person"
import { isValidRole } from "~/helpers/role-helpers"

import OutOfContractCurtains from "~/common/OutOfContractCurtains/OutOfContractCurtains"
import TimeOffCurtain from "~/common/TimeOffCurtain/TimeOffCurtain"
import CalendarOutline from "~/common/calendar/CalendarOutline"

import { isSplitScreenMode } from "~/Mode.reducer"
import AddProjectToPerson from "~/PeoplePlanner/AddProjectToPerson/AddProjectToPerson"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import {
  hideInactiveMembershipsInPerson,
  showAllMembershipsInPerson,
} from "~/Planner/reducer2/projectMembersViewSlice"
import { selectAnyTentativeProjectsEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import ToggleProjectMembersView from "~/ProjectPlanner/ProjectMembers/ToggleProjectMembersView"
import { useAppSelector } from "~/hooks/redux"
import { useProjectMembershipsList } from "~/hooks/useProjectMembersList"

type Props = {
  person: PersonAddProjectRow_person$key
  holidaysOverlappingTimeOffs?: string[]
  acountDefaultFullTimeMinutes: number
}

const PersonAddProjectRow = (props: Props) => {
  const { holidaysOverlappingTimeOffs, acountDefaultFullTimeMinutes } = props

  const person = useFragment(
    graphql`
      fragment PersonAddProjectRow_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        id
        first_name
        last_name
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          role {
            name
          }
        }
        project_memberships {
          id
          project_id
          person_id
          just_added_timestamp
          role_id
          workstream_id
          project {
            is_template
            confirmed
            active
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          person_id
          role_id
          workstream_id
          start_date: start_date_runn
          end_date: end_date_runn
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          note
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
      }
    `,
    props.person,
  )

  const {
    id: personId,
    time_offs: timeOffs,
    contracts,
    project_memberships,
    assignments,
  } = person
  const personDataTestName = formatName(person.first_name, person.last_name)
    .toLocaleLowerCase()
    .replace(" ", "-")

  const user = useFragment<PersonAddProjectRow_user$key>(
    graphql`
      fragment PersonAddProjectRow_user on users
      @argumentDefinitions(
        peopleFilter: { type: "people_bool_exp" }
        projectsFilter: { type: "projects_bool_exp" }
      ) {
        ...AddProjectToPerson_user
          @arguments(
            peopleFilter: $peopleFilter
            projectsFilter: $projectsFilter
          )
      }
    `,
    useHasuraContext(),
  )

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const dispatch = useDispatch()
  const anyTentativeProjectsEnabled = useAppSelector((state) =>
    selectAnyTentativeProjectsEnabled(state.plannerV2.scenarioPlanning),
  )

  const memberships = (project_memberships ?? [])
    .map(({ project, ...membership }) => ({
      ...membership,
      confirmed: project.confirmed,
      is_template: project.is_template,
      active: project.active,
    }))
    .filter((m) => m.confirmed || anyTentativeProjectsEnabled)

  const { all, visible, inactive } = useProjectMembershipsList(personId, {
    members: memberships,
    assignments: assignments.filter(Boolean) ?? [],
  })

  const contract = getCurrentContract(contracts)

  let invalidReason = ""

  if (!contract) {
    invalidReason = "No valid contract"
  }

  if (contract && (!contract.role || !isValidRole(contract.role.name))) {
    invalidReason = "Invalid Role"
  }

  const nonHolidayTimeOffs = timeOffs.filter(
    (to) => to.leave_type !== "holiday",
  )

  const handleToggle = (shouldShowAll: boolean) => {
    track("Person Memberships Toggled", {
      personId,
      value: shouldShowAll,
    })
    if (shouldShowAll) {
      dispatch(showAllMembershipsInPerson(personId))
    } else {
      dispatch(hideInactiveMembershipsInPerson(personId))
    }
  }

  return (
    <PlannerGrid disabled={splitScreenMode} stickyBottom>
      <PlannerLeftColumn
        isCallToAction
        style={{ justifyContent: "space-between" }}
      >
        {invalidReason ? (
          <span>{invalidReason}</span>
        ) : (
          <>
            <AddProjectToPerson personId={personId} user={user} />
            <ToggleProjectMembersView
              totals={{
                all: all.length,
                visible: visible.length,
                inactive: inactive.length,
              }}
              onToggle={handleToggle}
              showAllText={(count) => `Show all (${count})`}
              hideInactiveText={(count) => `Hide inactive (${count})`}
              showAllTooltip="Show all projects"
              hideInactiveTooltip={
                <span>
                  Hide projects without assignments
                  <br />
                  in the current view
                </span>
              }
              dataTestName={personDataTestName}
            />
          </>
        )}
      </PlannerLeftColumn>
      <PlannerRightColumn showHighlight={false}>
        <CalendarOutline
          type="standard"
          timeOffs={timeOffs}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
        />
        {nonHolidayTimeOffs.map((to) => (
          <TimeOffCurtain
            timeOff={to}
            key={to.id}
            contracts={contracts}
            accountDefaultFullTimeMinutes={acountDefaultFullTimeMinutes}
          />
        ))}
        <OutOfContractCurtains contracts={contracts} />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default PersonAddProjectRow
