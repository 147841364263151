import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React, { useEffect, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch } from "react-redux"
import { match } from "ts-pattern"

import styles from "./ModeNotifications.module.css"

import { useSidePanel } from "~/common/SidePanel/SidePanel"
import Button from "~/common/buttons/Button"

import { disableMultiSelectAndResetItems } from "~/Mode.reducer"
import { useAppSelector } from "~/hooks/redux"

import ModeMessage from "./ModeMessage"
import { setSplitScreenFilterSet } from "./Planner.actions"
import { panelClosed } from "./reducer2/panelSlice"

const ModeNotifications = () => {
  const multiSelect = useAppSelector(
    (state) => state.multiSelect,
    isDeeplyEqual,
  )
  const showPanel = useAppSelector((state) => state.plannerV2.panel.showPanel)

  const [showMode, setShowMode] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setShowMode(multiSelect.isEnabled)
  }, [multiSelect.isEnabled])

  const exitMode = () => {
    dispatch(disableMultiSelectAndResetItems())
    dispatch(setSplitScreenFilterSet({ name: "", filters: null }))
    if (
      ((showPanel === "personSchedule" || showPanel === "projectSchedule") &&
        !multiSelect.isEnabled) ||
      showPanel === "splitScreen"
    ) {
      dispatch(panelClosed())
    }
  }

  const handleModeKeyUp = (e) => {
    if (e.key === "Escape") {
      exitMode()
    }
  }

  useEffect(() => {
    if (showMode) {
      document.addEventListener("keyup", handleModeKeyUp)
    } else {
      document.removeEventListener("keyup", handleModeKeyUp)
    }
    return () => {
      document.removeEventListener("keyup", handleModeKeyUp)
    }
  }, [showMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const modeTitle = match({
    modeAction: multiSelect.modeAction,
    entirePlaceholderTransfer: multiSelect.entirePlaceholderTransfer,
    scheduleView:
      showPanel === "personSchedule" || showPanel === "projectSchedule",
    multiSelectEnabled: multiSelect.isEnabled,
  })
    .with({ scheduleView: true, multiSelectEnabled: false }, () => null)
    .with(
      { modeAction: "transfer", entirePlaceholderTransfer: true },
      () => "Find Person",
    )
    .with(
      { modeAction: "transfer", entirePlaceholderTransfer: false },
      () => "Transfer Mode",
    )
    .with({ modeAction: "clone" }, () => "Clone Mode")
    .otherwise(() => "Multi-Select Mode")

  const { isOpen } = useSidePanel()

  return (
    <>
      {showMode && (
        <div
          className={cc([
            styles.modeWrapper,
            { [styles.sidePanelOpen]: isOpen },
          ])}
        >
          {modeTitle && <div className={styles.modeTitle}>{modeTitle}</div>}
          <div className={styles.modeMessage}>
            <ModeMessage isSidePanelOpen={isOpen} />
          </div>
          <div>
            <Tooltip content="or press [Esc] key" position="top">
              <Button
                text="Exit"
                style={{ marginLeft: "auto" }}
                onClick={exitMode}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  )
}

export default ModeNotifications
