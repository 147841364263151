type getUtilizationPercentageProps = {
  assignedHours: number
  contractedHours: number
  enabledDays?: number
  timeOff?: number
  round?: boolean
  isANonWorkingDay: boolean
}

export const getUtilizationPercentage = ({
  assignedHours,
  contractedHours,
  enabledDays = 1,
  timeOff = 0,
  round = false,
  isANonWorkingDay,
}: getUtilizationPercentageProps) => {
  // Return 0 when there is no contract.
  if (contractedHours <= 0) {
    return 0
  }

  /* This is based on available hours */
  const effectiveCapacity = contractedHours - timeOff

  let percentage = (assignedHours / effectiveCapacity) * 100

  const fullTimeOff = timeOff === contractedHours
  const fullTimeOffPercent = 100

  const contractedPerDay = contractedHours / enabledDays
  if (fullTimeOff) {
    percentage = (assignedHours / contractedPerDay) * 100 + fullTimeOffPercent
  }

  if (isANonWorkingDay) {
    percentage = percentage + 100
  }

  return round ? Math.round(percentage) : percentage
}
