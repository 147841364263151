import { Icon, MenuDivider, Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import { startsWith } from "lodash-es"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./SplitScreenRole.module.css"

import { SplitScreenRole_user$key } from "./__generated__/SplitScreenRole_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { isValidRole } from "~/helpers/role-helpers"
import { sortByString } from "~/helpers/sorting-helpers"

import { Dropdown } from "~/common/Dropdown"
import MenuItem from "~/common/MenuItem"
import TooltipEllipsis from "~/common/TooltipEllipsis"
import { WarningInfoIcon } from "~/common/react-icons"

import { SplitScreenModeAction } from "~/Mode.reducer"

const fragment = graphql`
  fragment SplitScreenRole_user on users {
    account {
      roles {
        id
        active
        name
      }
    }
  }
`

type Role = { id: number; name: string }

type Props = {
  listType: "people" | "placeholders"
  selectedRoleId: number
  modeAction?: SplitScreenModeAction
  defaultRole?: Role
  setSelectedRole?: (val: Role) => void
  personName?: string
  preventRoleChange?: boolean
}

const SplitScreenRole = (props: Props) => {
  const {
    defaultRole,
    selectedRoleId,
    setSelectedRole,
    personName,
    listType,
    modeAction,
    preventRoleChange,
  } = props

  const data = useFragment<SplitScreenRole_user$key>(
    fragment,
    useHasuraContext(),
  )

  const { roles } = data.account

  const selectedRole = roles.find((r) => r.id === selectedRoleId)
  const [search, setSearch] = useState("")

  if (listType === "placeholders") {
    return (
      <div>
        <TooltipEllipsis text={selectedRole?.name} customMaxWidth="120px" />
      </div>
    )
  }

  const activeRoles = roles
    .filter((r) => r.active && isValidRole(r.name) && r.id !== defaultRole.id)
    .filter((r) => startsWith(r.name.toLowerCase(), search.toLowerCase()))
    .sort((a, b) => sortByString(a.name, b.name))

  const onChangeRole = (role) => {
    if (preventRoleChange) {
      return
    }

    setSelectedRole(role)

    switch (modeAction) {
      case "transfer":
        track("Role Change Transfer")
        break
      case "clone":
        track("Role Change Clone")
        break
      default:
        modeAction satisfies never
        break
    }
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleOnClick = (e) => {
    e.stopPropagation()
    setSearch("")
  }

  return (
    <div className={styles.wrapper}>
      {selectedRole.id !== defaultRole.id && (
        <Tooltip
          content={`${personName}'s default role is ${defaultRole.name}`}
          placement="top"
          className={styles.roleTooltip}
        >
          <WarningInfoIcon color="var(--slate)" />
        </Tooltip>
      )}
      <Dropdown
        Target={() => (
          <div className={styles.dropdownTarget}>
            <TooltipEllipsis text={selectedRole?.name} customMaxWidth="160px" />
            {!preventRoleChange && (
              <div className={styles.iconWrapper}>
                <Icon icon="chevron-down" size={14} color="var(--shadow)" />
              </div>
            )}
          </div>
        )}
        disabled={preventRoleChange}
        onClick={handleOnClick}
        hideArrow
        minWidth={280}
        maxHeight={218}
      >
        <div className={styles.dropdownHeader}>{modeAction} As</div>
        <MenuDivider />
        <div className={cc([styles.dropdownHeader, styles.dropdownInput])}>
          <Icon icon="search" />
          <input
            placeholder="Search Roles"
            onInput={handleSearchChange}
            defaultValue={search}
            autoFocus
          />
        </div>
        <MenuItem
          text={`${defaultRole.name} (default)`}
          active={selectedRole?.id === defaultRole.id}
          onClick={() => setSelectedRole(defaultRole)}
        />
        {activeRoles.map((role) => {
          return (
            <MenuItem
              key={role.id}
              text={role.name}
              active={selectedRole?.id === role.id}
              onClick={() => onChangeRole(role)}
            />
          )
        })}
      </Dropdown>
    </div>
  )
}

export default SplitScreenRole
