import { dateHelpers } from "@runn/calculations"
import { capitalize, flatten } from "lodash-es"

import { SplitScreenRow_person$data } from "~/Planner/SplitScreenPanel/SplitScreenRow/__generated__/SplitScreenRow_person.graphql"

import { pluralize } from "~/helpers/plural"

import {
  cloneMultipleAssignments,
  transferMultipleAssignments,
} from "~/common/Pill/AssignmentActionHelpers"
import { itemsOverlap } from "~/common/Pill/action_helpers"
import { Assignment } from "~/common/PillActions/PillActions"

import { SplitScreenModeAction } from "~/Mode.reducer"
import { showToast } from "~/containers/ToasterContainer"

import { getItemMetaData } from "./CalendarHelper"
import DurationHelper from "./DurationHelper"
import { differenceInBusinessDays } from "./date-range"

export type MultiSelectAssignments = Array<
  Assignment & { minutes: number; minutesLessTimeOff: number }
>

export const getPresentAction = (modeAction: SplitScreenModeAction) => {
  return modeAction === "transfer" ? "transferring" : "cloning"
}

export const getPastAction = (modeAction: SplitScreenModeAction) => {
  return modeAction === "transfer" ? "transferred" : "cloned"
}

export const transferOrCloneAssignments = async (
  modeAction: SplitScreenModeAction,
  multiSelectAssignments: MultiSelectAssignments,
  person: SplitScreenRow_person$data,
  roleId: number,
  personLinkFunction: () => void,
  isConsistentTimeOffEnabled: boolean,
  onSuccess?: () => void,
) => {
  const assignmentsSansMinutes = multiSelectAssignments.map(
    ({ minutes, minutesLessTimeOff, ...rest }) => rest,
  )

  const assignments = assignmentsSansMinutes.map((a) => {
    return {
      ...a,
      role_id: roleId || a.role_id,
      person_id: person.id,
    }
  })

  if (modeAction === "transfer") {
    await transferMultipleAssignments(
      assignments,
      person,
      isConsistentTimeOffEnabled,
    )
    onSuccess && onSuccess()
  }

  if (modeAction === "clone") {
    await cloneMultipleAssignments(
      assignments,
      person,
      isConsistentTimeOffEnabled,
    )
    onSuccess && onSuccess()
  }

  showToast({
    type: "success",
    message: `${pluralize(assignments.length, "Assignment")} ${getPastAction(
      modeAction,
    )}`,
    description: `${person.first_name} ${person.last_name}`,
    actions: [{ onClick: personLinkFunction }],
  })
}

export const getOverlappingProjectAssignments = (
  personAssignments: SplitScreenRow_person$data["assignments"],
  multiSelectAssignments: MultiSelectAssignments,
  selectedRoleId: number,
): SplitScreenRow_person$data["assignments"] => {
  return personAssignments
    ?.filter((a) => a.project_id === multiSelectAssignments[0]?.project_id)
    .filter((a) => a.role_id === selectedRoleId)
    .filter(
      (projectAssignment) =>
        !!multiSelectAssignments?.some((a) =>
          itemsOverlap(a, projectAssignment),
        ),
    )
}

export const getMinutesExcludingTimeOffs = (
  multiSelectAssignments: MultiSelectAssignments,
  overlappingTimeoffs: SplitScreenRow_person$data["time_offs"],
) => {
  const minutes = multiSelectAssignments
    .map((a) => {
      const { totalWorkingDays } = getItemMetaData({
        startDate: dateHelpers.parseRunnDate(a.start_date),
        endDate: dateHelpers.parseRunnDate(a.end_date),
        timeOffs: overlappingTimeoffs,
        isTimeOff: false,
        includeWeekends: false,
        nonWorkingDay: a.non_working_day,
      })

      return totalWorkingDays * a.minutes_per_day
    })
    .reduce((acc, a) => acc + a, 0)

  return minutes
}

export const getConflictData = ({
  personName,
  personTimeOffs,
  personAssignments,
  multiSelectAssignments,
  selectedRoleId,
  modeAction,
}: {
  personName: string
  personTimeOffs: SplitScreenRow_person$data["time_offs"]
  personAssignments: SplitScreenRow_person$data["assignments"]
  multiSelectAssignments: MultiSelectAssignments
  modeAction: SplitScreenModeAction
  selectedRoleId: number
}): {
  hasTimeOffOverlap: boolean
  hasProjectOverlap: boolean
  conflictMessage: string
  preventAction: boolean
  overlappingProjectAssignments: SplitScreenRow_person$data["assignments"]
} => {
  const isTemplate = multiSelectAssignments[0]?.is_template
  const overlappingTimeoffs =
    !isTemplate &&
    personTimeOffs.filter(
      (to) => !!multiSelectAssignments?.some((a) => itemsOverlap(a, to)),
    )

  const overlappingProjectAssignments = getOverlappingProjectAssignments(
    personAssignments,
    multiSelectAssignments,
    selectedRoleId,
  )
  const hasTimeOffOverlap = Boolean(overlappingTimeoffs.length)
  const belongsToSameWorkstreamGroup = multiSelectAssignments.some((a) =>
    personAssignments.some((pa) => pa.workstream_id === a.workstream_id),
  )
  const hasProjectOverlap =
    Boolean(overlappingProjectAssignments.length) &&
    belongsToSameWorkstreamGroup

  let conflictMessage = null

  const presentAction = getPresentAction(modeAction)
  const capitalizedPresentAction = capitalize(presentAction)
  const pastAction = getPastAction(modeAction)

  const adjustedMinutes = getMinutesExcludingTimeOffs(
    multiSelectAssignments,
    [],
  )

  const adjustedMinutesExcludingTimeOffs =
    hasTimeOffOverlap &&
    getMinutesExcludingTimeOffs(multiSelectAssignments, overlappingTimeoffs)

  // Minutes that cannot be actioned due to TimeOff overlap
  const nonActionableMinutes =
    adjustedMinutes - adjustedMinutesExcludingTimeOffs

  const adjustedMinutesMessage =
    (adjustedMinutesExcludingTimeOffs &&
      adjustedMinutes &&
      nonActionableMinutes &&
      `${DurationHelper.formatDuration(
        adjustedMinutesExcludingTimeOffs,
      )} out of ${DurationHelper.formatDuration(
        adjustedMinutes,
      )} will be ${pastAction}. (${DurationHelper.formatDuration(nonActionableMinutes)} will not be ${pastAction})`) ||
    ""

  if (hasProjectOverlap) {
    conflictMessage = `${personName} is already assigned to this project during this period. 
    ${capitalizedPresentAction} will override these assignments.`
  }

  if (hasTimeOffOverlap) {
    if (adjustedMinutesExcludingTimeOffs === 0) {
      conflictMessage = `${personName} has time off over the entire assignment period.`
    } else {
      conflictMessage = `${personName} has time off during this period. ${adjustedMinutesMessage}`
    }
  }

  if (hasTimeOffOverlap && hasProjectOverlap) {
    conflictMessage = `${personName} is already assigned to this project and
    has time off booked. ${capitalizedPresentAction} will override existing assignments 
    and exclude time off. ${adjustedMinutesMessage}`
  }

  const hasMultipleConflicts =
    hasProjectOverlap && multiSelectAssignments.length > 1

  if (hasMultipleConflicts) {
    conflictMessage =
      "Multiple conflicts detected. Please delete conflicting assignments and try again."
  }

  return {
    hasTimeOffOverlap,
    hasProjectOverlap,
    conflictMessage,
    preventAction:
      hasMultipleConflicts || adjustedMinutesExcludingTimeOffs === 0,
    overlappingProjectAssignments,
  }
}

export const getAdjustedImpactAssignments = ({
  multiSelectAssignments,
  overlappingProjectAssignments,
  calStartNum,
  calEndNum,
}: {
  multiSelectAssignments: MultiSelectAssignments
  overlappingProjectAssignments: SplitScreenRow_person$data["assignments"]
  calStartNum: number
  calEndNum: number
}) => {
  // We pass multiSelectAssignments into PersonSummaryGraph
  // But if there is a conflict - the original minutes_per_day will
  // ADD onto the existing assignments.

  // This is NOT the expected behaviour. If there is a conflict,
  // the assignment should override the existing assignment.
  // This function adjusts the minutes_per_day when there is a conflict
  // to show the correct impact

  if (!overlappingProjectAssignments.length) {
    return multiSelectAssignments
  }

  const assignmentsWithinCalendarRange = multiSelectAssignments
    .filter((a) => Number(a.end_date) >= calStartNum)
    .filter((a) => Number(a.start_date) <= calEndNum)

  if (assignmentsWithinCalendarRange.length === 0) {
    return []
  }

  const adjustedImpactDayAssignments = flatten(
    assignmentsWithinCalendarRange.map((a) => {
      const startDate =
        Number(a.start_date) < calStartNum ? String(calStartNum) : a.start_date
      const endDate =
        Number(a.end_date) > calEndNum ? String(calEndNum) : a.end_date

      const assignmentAsDays = dateHelpers.getAllWeekdaysDatesBetween(
        startDate,
        endDate,
      )

      return assignmentAsDays.map((day) => {
        const assignmentConflict = overlappingProjectAssignments.find(
          (pa) =>
            Number(pa.start_date) <= Number(day) &&
            Number(pa.end_date) >= Number(day),
        )

        const adjustedMinutes =
          a.minutes_per_day -
          (assignmentConflict ? assignmentConflict.minutes_per_day : 0)

        return {
          ...a,
          minutes_per_day: adjustedMinutes,
          minutes: adjustedMinutes,
          start_date: day,
          end_date: day,
        }
      })
    }),
  )

  return adjustedImpactDayAssignments
}

export const getPastAssignments = (
  multiSelectAssignments: MultiSelectAssignments,
): MultiSelectAssignments => {
  const todaysDate = dateHelpers.getTodaysDate()
  return multiSelectAssignments.filter((item) => item.start_date < todaysDate)
}

export const getPastMinutes = (
  multiSelectAssignments: MultiSelectAssignments,
): number => {
  const todaysDate = dateHelpers.getTodaysDate()
  const pastAssignments = getPastAssignments(multiSelectAssignments)

  if (pastAssignments.length === 0) {
    return null
  }

  const totalMinutes = pastAssignments.reduce(
    (acc, a) => {
      if (a.end_date >= todaysDate) {
        // assignment goes past todays date; only get past days
        const numberOfPastDays = differenceInBusinessDays(
          todaysDate,
          a.start_date,
        )

        return acc + numberOfPastDays * a.minutes_per_day
      }

      return acc + a.minutes
    },

    0,
  )

  return totalMinutes
}
