import * as React from "react"

import DurationHelper from "../../../helpers/DurationHelper"

type HoursPerDayLabelProps = {
  minutesPerDay: number
  pillWidth: number
}
export const HoursPerDayLabel = ({
  minutesPerDay,
  pillWidth,
}: HoursPerDayLabelProps) => {
  const duration = DurationHelper.formatDuration(minutesPerDay)

  if (pillWidth < 80) {
    return <>{duration}</>
  }

  return <>{duration}/day</>
}
