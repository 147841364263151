import { graphql, useFragment } from "react-relay"

import type { User_Account_Integrations$key } from "./__generated__/User_Account_Integrations.graphql"

import { useHasuraContext } from "~/store/hasura"

import { useAccountIntegrations } from "./Account"

const useIntegrationViaHasuraContext = (integrationId: number) => {
  const user = useFragment<User_Account_Integrations$key>(
    graphql`
      fragment User_Account_Integrations on users {
        account {
          id
          ...Account_Integrations
        }
      }
    `,
    useHasuraContext(),
  )

  const integrations = useAccountIntegrations(user.account)
  const integration = integrations.find((item) => item.id === integrationId)
  return integration
}

export { useIntegrationViaHasuraContext }
