import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./NoResultsForFilter.module.css"

import AddSection from "~/common/AddSection"
import CalendarOutline from "~/common/calendar/CalendarOutline"
import { TangramPerson, TangramProject } from "~/common/react-icons"

import { showAllViewSelected as globalShowAllViewSelected } from "~/Planner/reducer2/viewsSlice"
import { useAppSelector } from "~/hooks/redux"

import PlannerGrid from "./PlannerGrid"

type Subject = {
  subject: "projects" | "people"
  accountHasViews: boolean
  localViews?: {
    onSwitchToAllView: () => void
    showAllView: boolean
  }
}

type Minimal = Subject & {
  minimal?: boolean // only show text
  onClearFilters?: never
}

type FilterProps = Subject & {
  onClearFilters: () => void | undefined
  minimal?: never
}

type Props = FilterProps | Minimal

const NoResultsForFilter = (props: Props) => {
  const { subject, onClearFilters, minimal, accountHasViews, localViews } =
    props

  const viewsFeatureEnabled = useFeature("pre_filtered_views")
  const dispatch = useDispatch()

  const globalShowAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )

  const handleSwitchToAllView = () => {
    if (localViews && localViews.onSwitchToAllView) {
      localViews.onSwitchToAllView()
    } else {
      dispatch(globalShowAllViewSelected())
    }
  }

  const showAllView =
    localViews && typeof localViews.showAllView === "boolean"
      ? localViews.showAllView
      : globalShowAllView

  const message =
    viewsFeatureEnabled && accountHasViews && !showAllView ? (
      <>
        <span className={styles.link} onClick={handleSwitchToAllView}>
          Switch view to All
        </span>{" "}
        or change the search criteria
      </>
    ) : (
      "Try changing the filters or search term"
    )

  if (minimal) {
    return <p>{message}</p>
  }

  return (
    <>
      <PlannerGrid>
        <div className={styles.container}>
          {subject === "projects" ? <TangramProject /> : <TangramPerson />}
          <div className={styles.heading}>No {subject} found</div>
          <div className={styles.paragraph}>{message}</div>
          {onClearFilters && (
            <button
              className={styles.clearFiltersButton}
              onClick={onClearFilters}
            >
              Clear Filters
            </button>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <CalendarOutline type="standard" />
        </div>
      </PlannerGrid>
      <div style={{ flex: 1, display: "flex" }}>
        <AddSection />
      </div>
    </>
  )
}

export default NoResultsForFilter
