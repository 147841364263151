/**
 * @generated SignedSource<<96958a39c3c7ababf9ea727fcf50ff82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExtLinks_Person$data = {
  readonly ext_person_links: ReadonlyArray<{
    readonly active: boolean;
    readonly ext_person_id: number;
    readonly external: {
      readonly __typename: "ext_person";
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly integration_id: number;
      readonly last_name: string;
      readonly remote_id: string | null | undefined;
    } | null | undefined;
    readonly external_id: number;
    readonly person_id: number;
  }>;
  readonly " $fragmentType": "ExtLinks_Person";
};
export type ExtLinks_Person$key = {
  readonly " $data"?: ExtLinks_Person$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExtLinks_Person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExtLinks_Person",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "active": {
              "_eq": true
            }
          }
        }
      ],
      "concreteType": "ext_person_link",
      "kind": "LinkedField",
      "name": "ext_person_links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ext_person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": "external_id",
          "args": null,
          "kind": "ScalarField",
          "name": "ext_person_id",
          "storageKey": null
        },
        {
          "alias": "external",
          "args": null,
          "concreteType": "ext_person",
          "kind": "LinkedField",
          "name": "ext_person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integration_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
    }
  ],
  "type": "people",
  "abstractKey": null
};

(node as any).hash = "7bd3805adf9f12c57252e335f2671732";

export default node;
