/**
 * @generated SignedSource<<76aa4f581b07efdde6d92b10185cdf3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModeMessage_viewer$data = {
  readonly account: {
    readonly id: number;
    readonly people: ReadonlyArray<{
      readonly contracts: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly start_date: string | null | undefined;
      }>;
      readonly first_name: string;
      readonly id: number;
      readonly last_name: string;
    }>;
    readonly roles: ReadonlyArray<{
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly workstreams: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
  };
  readonly " $fragmentType": "ModeMessage_viewer";
};
export type ModeMessage_viewer$key = {
  readonly " $data"?: ModeMessage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModeMessage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModeMessage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "contracts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "start_date",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start_date_runn",
                  "storageKey": null
                },
                {
                  "alias": "end_date",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end_date_runn",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstreams",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "9d8b74245d57e1e86d7cbd200ed36a94";

export default node;
