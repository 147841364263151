import React from "react"

import styles from "./ProjectBudget.module.css"

import Select from "~/common/Select"

import { BudgetedRole } from "./BudgetEditor"

type Props = {
  addRole: (role_id: number) => void
  roles: BudgetedRole[]
  placeholder?: string
}

const RoleBasedBudgetSelector = (props: Props) => {
  const { addRole, roles, placeholder = "Add role" } = props

  const roleOptions = roles.map((r) => ({ value: r.id, label: r.name }))

  return (
    <Select
      name="role-select"
      onChange={(selection) => addRole(selection.value)}
      value=""
      options={roleOptions}
      placeholder={placeholder}
      className={styles.select}
      height={30}
      width={"215px"}
    />
  )
}
export default RoleBasedBudgetSelector
