import React from "react"
import isDeeplyEqual from "react-fast-compare"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectGroupRow.module.css"

import { ProjectGroupRow_account$key } from "./__generated__/ProjectGroupRow_account.graphql"
import { ProjectGroupRow_project$key } from "./__generated__/ProjectGroupRow_project.graphql"

import { Member, MemberWithName } from "~/helpers/project-member-helpers"

import CalendarOutline from "~/common/calendar/CalendarOutline"

import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"

import ProjectGroupMembersDisplay from "./ProjectGroupMembersDisplay"

type Props = {
  account: ProjectGroupRow_account$key
  project: ProjectGroupRow_project$key
  groupName: string
  groupId?: number
  visibleMembers: ReadonlyArray<Member>
  groupMembers: ReadonlyArray<MemberWithName>
  showDotsMenu?: boolean
}

const ProjectGroupRow = (props: Props) => {
  const { groupName, groupId, visibleMembers, groupMembers, showDotsMenu } =
    props

  const account = useFragment(
    graphql`
      fragment ProjectGroupRow_account on accounts
      @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
        id
        ...ProjectGroupMembersDisplay_account
          @arguments(peopleFilter: $peopleFilter)
      }
    `,
    props.account,
  )

  const project = useFragment(
    graphql`
      fragment ProjectGroupRow_project on projects {
        id
        ...ProjectGroupMembersDisplay_project
      }
    `,
    props.project,
  )

  return (
    <>
      <PlannerGrid gridRow100 data-test={`project-role-${groupName}`}>
        <PlannerLeftColumn className={styles.roleBar} paddingHeight={5}>
          <ProjectGroupMembersDisplay
            account={account}
            project={project}
            groupName={groupName}
            groupId={groupId}
            visibleMembers={visibleMembers}
            groupMembers={groupMembers}
            showDotsMenu={showDotsMenu}
          />
        </PlannerLeftColumn>
        <PlannerRightColumn showHighlight={false}>
          <CalendarOutline type="standard" />
        </PlannerRightColumn>
      </PlannerGrid>
    </>
  )
}

export default React.memo(ProjectGroupRow, isDeeplyEqual)
