/**
 * @generated SignedSource<<24ba79292c97de2c14dac7e204d70195>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroupMembersDisplay_account$data = {
  readonly id: number;
  readonly people: ReadonlyArray<{
    readonly email: string | null | undefined;
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AddPersonToProject_account">;
  readonly " $fragmentType": "ProjectGroupMembersDisplay_account";
};
export type ProjectGroupMembersDisplay_account$key = {
  readonly " $data"?: ProjectGroupMembersDisplay_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupMembersDisplay_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "kind": "RootArgument",
      "name": "plannerStartDate"
    },
    {
      "kind": "RootArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroupMembersDisplay_account",
  "selections": [
    (v0/*: any*/),
    {
      "args": [
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AddPersonToProject_account"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "8052f484fc4b5b209d90452de8d181d6";

export default node;
