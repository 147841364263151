import { Tooltip } from "@blueprintjs/core"
import { toFullTimeEquivalentEffort } from "@runn/calculations"
import cc from "classcat"
import * as React from "react"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./ProjectGroupStats.module.css"

import { ProjectGroupStats_project$key } from "./__generated__/ProjectGroupStats_project.graphql"

import { formatToMode } from "~/helpers/budgetHelper"
import { isBillableProject } from "~/helpers/project-helpers"
import {
  getStatsByRole,
  getStatsByWorkstream,
} from "~/helpers/project-stats-helpers"
import { formatMinuteToHours } from "~/helpers/time"

import { useAppSelector } from "~/hooks/redux"

type Props = {
  groupId: number
  project: ProjectGroupStats_project$key
}

const ProjectGroupStats = (props: Props) => {
  const project = useFragment(
    graphql`
      fragment ProjectGroupStats_project on projects {
        id
        is_template
        pricing_model
        account {
          id
          default_full_time_minutes
        }
        client {
          id
          internal
        }
        summary {
          role_id
          workstream_id
          total_billable_minutes
          total_nonbillable_minutes
        }
        project_roles {
          id
          role_id
          estimated_minutes
        }
        project_rates {
          id
          role_id
          rate: rate_private
        }
      }
    `,
    props.project,
  )

  const { groupId } = props
  const { statsType, groupByType } = useAppSelector(
    (state) => state.plannerV2.plannerStats,
  )
  const isBillable = isBillableProject(
    project.pricing_model,
    project.client.internal,
  )

  const groupSummary = project.summary.filter(
    (s) =>
      s && (groupByType === "roles" ? s.role_id : s.workstream_id) === groupId,
  )

  const { billableMinutes, nonbillableMinutes } = groupSummary.reduce(
    (acc, s) => ({
      billableMinutes: acc.billableMinutes + s.total_billable_minutes,
      nonbillableMinutes: acc.nonbillableMinutes + s.total_nonbillable_minutes,
    }),
    { billableMinutes: 0, nonbillableMinutes: 0 },
  )
  const allocatedMinutes = isBillable ? billableMinutes : nonbillableMinutes

  const {
    hasBudget,
    isOverBudget,
    allocatedRevenue,
    budgetRevenue,
    budgetMinutes,
  } =
    groupByType === "roles"
      ? getStatsByRole({
          projectRole: project.project_roles.find(
            (pr) => pr.role_id === groupId,
          ),
          projectRoleRate:
            project.project_rates.find((r) => r.role_id === groupId)?.rate ?? 0,
          allocatedMinutes,
          billableMinutes,
        })
      : getStatsByWorkstream({
          groupSummary,
          projectRates: project.project_rates,
        })

  const { allocated, budget, tooltipAllocatedLabel, toolTipHeading } = match(
    statsType,
  )
    .with("revenue", () => ({
      allocated: formatToMode(allocatedRevenue, "$", false, 1),
      budget: formatToMode(budgetRevenue, "$", false, 1),
      tooltipAllocatedLabel: "Predicted",
      toolTipHeading: "Revenue",
    }))
    .with("hours", () => ({
      allocated: formatMinuteToHours(allocatedMinutes),
      budget: formatMinuteToHours(budgetMinutes),
      tooltipAllocatedLabel: project.is_template
        ? "Scheduled"
        : "Actual + Scheduled",
      toolTipHeading: `${isBillable ? "Billable " : ""}Hours${
        hasBudget ? " Budget" : ""
      }`,
    }))
    .with("days", () => ({
      allocated:
        toFullTimeEquivalentEffort({
          minutesOfEffort: allocatedMinutes,
          fulltimeMinutesPerDay: project.account.default_full_time_minutes,
        }) + "d",
      budget:
        hasBudget &&
        toFullTimeEquivalentEffort({
          minutesOfEffort: budgetMinutes,
          fulltimeMinutesPerDay: project.account.default_full_time_minutes,
        }) + "d",
      tooltipAllocatedLabel: project.is_template
        ? "Scheduled"
        : "Actual + Scheduled",
      toolTipHeading: `${isBillable ? "Billable " : ""}Days${
        hasBudget ? " Budget" : ""
      }`,
    }))
    .exhaustive()

  if (statsType === "revenue" && !isBillable) {
    return null
  }

  return (
    <Tooltip
      content={
        <>
          <div>
            <b>{toolTipHeading}</b>
            <br />
            {tooltipAllocatedLabel}: {allocated}
          </div>
          <div>{hasBudget && <>Budget: {budget}</>}</div>
        </>
      }
    >
      <div className={styles.text}>
        <span
          className={cc({
            [styles.overBudget]: isOverBudget,
          })}
          data-component="ProjectRoleStats/allocated"
        >
          {allocated}
        </span>
        {hasBudget && <> / {budget}</>}
      </div>
    </Tooltip>
  )
}

export default ProjectGroupStats
