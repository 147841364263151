import { Icon, MenuDivider, Tooltip } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PlaceholderSidePanel.module.css"

import { PlaceholderActionButtons_placeholder$key } from "./__generated__/PlaceholderActionButtons_placeholder.graphql"

import { track } from "~/helpers/analytics"
import { formatName } from "~/helpers/person"

import Dialog from "~/common/Dialog"
import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"
import IconButton from "~/common/PageControls/IconButton"
import { getItemTotalMinutes } from "~/common/Pill/PillHelpers"
import TransferMenuItem from "~/common/PillActions/MenuItems/TransferMenuItem"
import SidePanelActionButtons from "~/common/SidePanel/SidePanelActionButtons"

import { useDeletePersonMutation } from "~/mutations/useDeletePersonMutation"

import DeleteModal from "~/Modals/Delete/DeleteModal"
import * as ModeReducer from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import { panelClosed, panelOpened } from "~/Planner/reducer2/panelSlice"
import { personIdSelected } from "~/Planner/reducer2/schedulePreviewSlice"
import { showToast } from "~/containers/ToasterContainer"
import ClonePlaceholderForm from "~/forms/ClonePlaceholderForm/ClonePlaceholderForm"
import { ReduxState } from "~/rootReducer"

import { transferEntirePlaceholderSelected } from "../reducer2/splitScreenSlice"

type Props = {
  placeholder: PlaceholderActionButtons_placeholder$key
  project: {
    id: number
    name: string
    is_template: boolean
  }
  onEditPlaceholder?: (openedInRequestForm: boolean) => void
  closePanel: () => void
}

const PlaceholderActionButtons = (props: Props) => {
  const placeholder = useFragment(
    graphql`
      fragment PlaceholderActionButtons_placeholder on people {
        id
        email
        is_placeholder
        active
        archivable
        first_name
        last_name
        tags
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        custom_date_values {
          typeId: custom_date_type_id
          value
        }
        people_notes {
          id
        }
        person_requests {
          id
          status
          updated_at
          user {
            id
            first_name
            last_name
          }
        }
        team {
          id
          name
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          cost: cost_private
          role {
            id
            name
          }
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        project_memberships {
          id
          project {
            id
            is_template
          }
          workstream_id
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          person_id
          phase_id
          role_id
          workstream_id
          start_date: start_date_runn
          end_date: end_date_runn
          is_billable
          is_template
          minutes_per_day
          note
          total_minutes
          non_working_day
        }
        placeholder_suggestions {
          suggested_person_id
        }
      }
    `,
    props.placeholder,
  )
  const { onEditPlaceholder, closePanel } = props

  const { can, subject } = usePermissions()
  const dispatch = useDispatch()

  const [cloneDialogShown, setCloneDialogShown] = useState(false)
  const project = {
    id: props.project.id,
    name: props.project.name,
    isTemplate: props.project.is_template,
  }

  const canDuplicatePlaceholder =
    can(
      "edit",
      subject("ProjectMember", {
        project,
        person: placeholder,
      }),
    ) &&
    can(
      "create",
      subject("Placeholder", {
        project,
      }),
    )

  const canDeletePlaceholder =
    can(
      "delete",
      subject("ProjectMember", {
        project,
        person: placeholder,
      }),
    ) &&
    can(
      "delete",
      subject("Placeholder", {
        project,
      }),
    )

  const canEditPlaceholder = can(
    "edit",
    subject("Placeholder", {
      project,
    }),
  )

  const canEditProject = can("edit", subject("Project", project))

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const showPanel: string | null = useSelector(
    (state: ReduxState) => state.panel.showPanel,
  )
  const selectedPersonId: number = useSelector(
    (state: ReduxState) => state.plannerV2.schedulePreview.selectedPersonId,
  )
  const schedulePanelOpen = showPanel === "personSchedule"

  const placeholderRequestStatus = placeholder.person_requests?.[0]?.status
  const noAssignments = placeholder.assignments.length === 0

  const viewPlaceholder = () => {
    if (schedulePanelOpen && selectedPersonId === placeholder.id) {
      dispatch(panelClosed())
    } else {
      dispatch(panelOpened("personSchedule"))
      dispatch(personIdSelected(placeholder.id))
      track("Person Schedule Panel Opened")
    }
  }

  const transferPlaceholderAssignments = () => {
    track("Placeholder Context Menu Item Selected", {
      item: "Find Person",
    })

    dispatch(ModeReducer.enableMultiSelect(placeholderRequestStatus))
    dispatch(transferEntirePlaceholderSelected())

    placeholder.assignments?.forEach((a) => {
      const { combinedMinutes, combinedMinutesLessTimeOff } =
        getItemTotalMinutes({
          typedItem: { type: "assignment", item: a },
          person: placeholder,
          isConsistentTimeOffEnabled,
        })

      const formatted = {
        ...a,
        minutes: combinedMinutes,
        minutesLessTimeOff: combinedMinutesLessTimeOff,
      }
      dispatch(ModeReducer.addItemToMultiSelect(formatted))
    })
  }

  const closeCloneDialog = () => {
    setCloneDialogShown(false)
  }

  const placeholderName = formatName(
    placeholder.first_name,
    placeholder.last_name,
  )

  const [deletePerson] = useDeletePersonMutation()
  const [showDelete, setShowDelete] = useState(false)

  const handleDelete = async () => {
    track("Placeholder Delete")

    try {
      await deletePerson({ personId: placeholder.id })
      showToast({
        type: "success",
        message: `${placeholderName} has been deleted`,
        description: project.name || "",
      })
    } catch (error: unknown) {
      showToast({
        message: `Failed to delete "${placeholderName}".`,
        description: error instanceof Error ? error.message : undefined,
        type: "error",
      })
    }
    closePanel()
  }

  const deletePlaceholder = () => {
    setShowDelete(true)
  }

  return (
    <>
      {cloneDialogShown && (
        <Dialog isOpen={true} onClose={closeCloneDialog}>
          <ClonePlaceholderForm
            placeholder={placeholder}
            project={props.project}
            workstreamId={
              placeholder.project_memberships[0]?.workstream_id ?? null
            }
            closeDialog={closeCloneDialog}
          />
        </Dialog>
      )}
      <SidePanelActionButtons>
        <IconButton
          icon={<Icon icon="horizontal-bar-chart-asc" size={14} />}
          onClick={viewPlaceholder}
          tooltipPlacement="left"
          tooltipContent={
            selectedPersonId === placeholder.id
              ? "Hide Schedule"
              : "View Schedule"
          }
          className={styles.actionBtn}
        />
        {canEditPlaceholder ? (
          <Dropdown
            Target={() => (
              <IconButton
                icon={<IconThreeDot size={14} />}
                tooltipPlacement="left"
                tooltipContent="More Actions"
                className={styles.actionBtn}
              />
            )}
            placement="left"
          >
            <MenuItem
              text="Edit Details"
              icon={<Icon icon="cog" />}
              hidden={!canEditPlaceholder || !onEditPlaceholder}
              onClick={() => onEditPlaceholder(false)}
            />
            {canEditPlaceholder && onEditPlaceholder ? <MenuDivider /> : null}
            {canEditProject && (
              <Tooltip
                disabled={!noAssignments}
                content="No assignments to transfer"
                placement="top"
              >
                <TransferMenuItem
                  text="Find Person"
                  entirePlaceholderTransfer
                  icon={<Icon icon="arrow-right" />}
                  disabled={noAssignments}
                  onClick={transferPlaceholderAssignments}
                />
              </Tooltip>
            )}
            {canDuplicatePlaceholder && (
              <MenuItem
                text="Clone"
                icon={<Icon icon="duplicate" />}
                onClick={() => setCloneDialogShown(true)}
              />
            )}
            <MenuDivider />
            {canDeletePlaceholder && (
              <MenuItem
                text="Delete"
                icon={<Icon icon="trash" />}
                onClick={deletePlaceholder}
              />
            )}
          </Dropdown>
        ) : null}
      </SidePanelActionButtons>
      <Dialog isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <DeleteModal
          type="placeholder"
          name={`${placeholder.first_name} ${placeholder.last_name}`}
          closeDialog={() => setShowDelete(false)}
          customWarning={
            "All assignments assigned to this placeholder will also be deleted."
          }
          onSubmit={handleDelete}
          isAdmin={true}
          deletable={true}
        />
      </Dialog>
    </>
  )
}

export default PlaceholderActionButtons
