import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { TeamSelector_account$key } from "./__generated__/TeamSelector_account.graphql"

import {
  AllowedPeopleFilter,
  AllowedProjectFilter,
} from "~/helpers/filter-engine"

import Select, { GroupedOption, Option } from "~/common/Select"
import SelectOptionAdd from "~/common/SelectOptionAdd"
import { toOption } from "~/common/SelectorModal/SelectorHelpers"

import { usePermissions } from "~/Permissions/usePermissions"

type teamOption = {
  value: number
  label: string
}

type Props = {
  id: string
  defaultTeam: teamOption
  onChange: (data: teamOption) => void
  account: TeamSelector_account$key
  type: "project" | "person"
  placeholder?: string
  label?: string
  isMulti?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  height?: number
  alwaysShow?: boolean
  disableMenuPortalTarget?: boolean
  includeOnlyIds?: number[]
  formatSelectOptions?: (
    options,
    filterType: AllowedPeopleFilter["type"] | AllowedProjectFilter["type"],
  ) => Option[] | GroupedOption[]
}

const TeamSelector = (props: Props) => {
  const { includeOnlyIds, formatSelectOptions, defaultTeam } = props
  const { can, subject } = usePermissions()

  const account = useFragment(
    graphql`
      fragment TeamSelector_account on accounts {
        teams {
          id
          name
        }
      }
    `,
    props.account,
  )

  const { teams } = account

  const teamOptions = teams
    .filter((t) =>
      includeOnlyIds?.length ? includeOnlyIds.includes(t.id) : true,
    )
    .map(toOption)

  const [selectedTeam, setSelectedTeam] = useState(defaultTeam)

  const isCreatable =
    props.isCreatable !== undefined
      ? props.isCreatable
      : can("create", subject("Team"))

  const handleChange = (target) => {
    setSelectedTeam(target)
    props.onChange(target)
  }

  if (!!teams.length || props.alwaysShow) {
    const formattedOptions = formatSelectOptions
      ? formatSelectOptions([...teamOptions], `${props.type}_team_id`)
      : teamOptions

    return (
      <Select
        {...props}
        name="team-selector"
        isMulti={props.isMulti}
        isCreatable={isCreatable}
        label={props.label || "Teams"}
        placeholder={props.placeholder || "Select team"}
        components={{ Option: SelectOptionAdd }}
        options={formattedOptions}
        onChange={(a) => handleChange(a)}
        value={defaultTeam || selectedTeam}
      />
    )
  }

  return null
}

export default TeamSelector
