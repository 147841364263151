import {
  ContextMenu,
  ContextMenuChildrenProps,
  Menu,
  Popover,
} from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { parseISO } from "date-fns"
import { useFeature } from "flagged"
import capitalize from "lodash-es/capitalize"
import React, { useEffect, useMemo, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { connect, useDispatch } from "react-redux"
import { match } from "ts-pattern"

import styles from "./PillActions.module.css"

import { TimeOffActionPill_account$data } from "../Pill/__generated__/TimeOffActionPill_account.graphql"

import { getNewlyMovedDate } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"
import {
  getClosestNextWorkingDay,
  getClosestPreviousWorkingDay,
  getMergedHolidays,
  getMergedTimeOffs,
} from "~/helpers/holiday-helpers"
import { isLocalId } from "~/helpers/local-id"
import { getCurrentContract } from "~/helpers/person"
import {
  excludeUntoggledAssignments,
  getReasonPillIsDisabled,
} from "~/helpers/planner-helpers"
import { shiftIntervalByAmount } from "~/helpers/workingDays-helpers"
import { isTimeOff } from "~/types/helpers"

import Dialog from "~/common/Dialog"
import MenuItem from "~/common/MenuItem"
import { getItemTotalMinutes, getPillPosition } from "~/common/Pill/PillHelpers"
import * as actionHelpers from "~/common/Pill/action_helpers"
import { WarningIcon } from "~/common/react-icons"

import GLOBAL from "~/GLOBALVARS"
import { SET_HIGHLIGHTED_ITEM_DATA } from "~/GlobalState"
import { setHighlightedItemData as setHighlightedItemDataDispatch } from "~/GlobalState"
import * as ModeReducer from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import { useAppSelector } from "~/hooks/redux"

import ItemTooltip from "./ItemTooltip"
import CloneMenuItem from "./MenuItems/CloneMenuItem"
import PhaseMenuItem from "./MenuItems/PhaseMenuItem"
import TransferMenuItem from "./MenuItems/TransferMenuItem"
import Splitters from "./Splitters"

const PillActions = (props: Props) => {
  const {
    person,
    onItemChange,
    onItemSplit,
    itemEditForm,
    onDelete,
    onDeleteConflict,
    type,
    project,
    phaseId,
    minipickerUpdatedEndDate,
    setHighlightedItemData,
    onPhaseSelect,
    calendarWeekendsExpanded,
    projects,
    isOutOfContract,
  } = props

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const projectConfirmed = project ? project.confirmed : true
  const multiSelect = useAppSelector(
    (state) => state.multiSelect,
    isDeeplyEqual,
  )
  const multiSelectEnabled = multiSelect.isEnabled
  const splitScreenMode = ModeReducer.isSplitScreenMode(multiSelect.modeAction)
  const calendar = useAppSelector((state) => state.calendar, isDeeplyEqual)
  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calStartNum,
    calEndNum,
    periods,
  } = calendar
  const weeklyAssignments = periods === "weeks"
  const daysInWeek = calendarWeekendsExpanded ? 7 : 5

  const isTransferPlaceholderMode = useAppSelector(
    (state) => state.plannerV2.splitScreen.transferEntirePlaceholder,
  )

  const dispatch = useDispatch()

  const originalItem = props.item

  if (type === "assignment") {
    originalItem as Assignment
  } else {
    originalItem as TimeOff
  }

  const affectedTimeOffs = project?.is_template
    ? []
    : type === "timeOff"
      ? person.time_offs.filter((to) => to.id !== originalItem.id)
      : person.time_offs

  const holidayTimeOffs = getMergedHolidays(affectedTimeOffs)

  // With consistent time off, we allow time off and assignment pills to start and end on a holiday
  // Previously, we would adjust the start and end dates to the nearest working day
  const holidaysAffectingPillDates = isConsistentTimeOffEnabled
    ? []
    : holidayTimeOffs

  const mergedTimeOffs = isConsistentTimeOffEnabled
    ? getMergedTimeOffs(
        affectedTimeOffs,
        originalItem.start_date,
        originalItem.end_date,
      )
    : holidayTimeOffs

  const isTemplate = project ? project.is_template : false

  const { can, subject } = usePermissions()
  const pillSubject =
    type === "assignment"
      ? subject("Assignment", {
          project: {
            id: (originalItem as Assignment).project_id,
            isTemplate: isTemplate,
          },
        })
      : subject("TimeOff", { person: person })

  const pillPermissions = {
    edit: can("edit", pillSubject),
  }

  const [item, setItem] = useState(originalItem)
  const [showEditForm, setShowEditForm] = useState(false)
  const [showMiniPickerContextMenu, setShowMiniPickerContextMenu] =
    useState(false)
  const [movementType, setMovementType] = useState("")
  const [isHighlighted, setIsHighlighted] = useState(false)
  const [showSplitMode, setShowSplitMode] = useState(false)
  const [isFloating, setIsFloating] = useState(false)
  const splitterRef = useRef(null)

  const typedItem =
    type === "assignment"
      ? { type, item: item as Assignment }
      : { type, item: item as TimeOff }

  let globalItem = originalItem
  let actionType: ActionType = ""
  let mouseXStartingPosition = 0

  const selectedItemIds = multiSelect.items.map((i) => i.id) || []
  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const [conflictingTimeOff, conflictingAssignments] = useMemo(() => {
    let conflictingTimeOffVal
    let conflictingAssignmentsVal = []

    if (type === "assignment") {
      const assignment = item as Assignment

      conflictingTimeOffVal = person.time_offs.find(
        (to) =>
          !assignment.is_template &&
          to.leave_type !== "holiday" &&
          actionHelpers.itemsOverlap(item, to),
      )
    } else if (type === "timeOff") {
      const assignments = excludeUntoggledAssignments(
        person.assignments,
        projects,
        enabledTentativeProjects,
      )
      conflictingAssignmentsVal = assignments.filter((a) => {
        return !a.is_template && actionHelpers.itemsOverlap(item, a)
      })

      if (conflictingAssignmentsVal.length) {
        conflictingTimeOffVal = item
      }
    }

    return [conflictingTimeOffVal, conflictingAssignmentsVal]
  }, [
    person.time_offs,
    item,
    type,
    person.assignments,
    enabledTentativeProjects,
    projects,
  ])

  const conflictingDeletableAssignments = conflictingAssignments.filter((a) =>
    can(
      "delete",
      subject("Assignment", {
        project: { id: a.project_id, isTemplate: a.is_template },
      }),
    ),
  )

  // We only show items in a conflicted state for full day time offs
  // After consistent_time_off is released, there would be no conflicts
  const isConflict =
    !!conflictingTimeOff &&
    !conflictingTimeOff.minutes_per_day &&
    !isConsistentTimeOffEnabled

  const {
    businessDays,
    visibleDays,
    offset,
    width,
    pillWidthPx,
    pillLabelOffset,
  } = getPillPosition({
    item,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
    dayWidth,
    mergedTimeOffs,
  })

  const halfSizeResizer = pillWidthPx < 30

  const isDisabled = props.disableItemChange || (item.id && isLocalId(item.id))

  const showChangeStartEnd =
    !multiSelectEnabled && !item.non_working_day && pillWidthPx > 15

  const handleClickOutsideSplitter = (event) => {
    if (splitterRef.current && !splitterRef.current.contains(event.target)) {
      setShowSplitMode(false)
    }
  }

  useEffect(() => {
    if (showSplitMode) {
      document.addEventListener("mousedown", handleClickOutsideSplitter)
    } else {
      dispatch({ type: SET_HIGHLIGHTED_ITEM_DATA, payload: null })
      document.removeEventListener("mousedown", handleClickOutsideSplitter)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSplitter)
    }
  }, [showSplitMode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Anytime the item changes from parent, reset it here
    setItem(originalItem)
  }, [originalItem])

  useEffect(
    () => {
      if (
        !multiSelectEnabled &&
        multiSelect.modeAction &&
        selectedItemIds.includes(item.id)
      ) {
        // Caution: TimeOffs are processed twice here in case the "Time Off" group is expanded,
        // since two pills exist for the same record. The effects of this are benign (duplicate requests)
        match({ modeAction: multiSelect.modeAction, type })
          .with({ modeAction: "delete", type: "timeOff" }, () => {
            onDelete()
          })
          .with({ modeAction: "move", type: "timeOff" }, () => {
            onItemChange(item)
          })
          .with({ modeAction: "move", type: "assignment" }, () => {
            dispatch(
              ModeReducer.addUpdatedItems(
                {
                  ...item,
                  person,
                },
                isConsistentTimeOffEnabled,
              ),
            )
          })
          .otherwise(() => null)

        setIsFloating(false)
        setIsHighlighted(false)

        // We don't need to do this for assignments, and its added by the ADD_UPDATED_ITEMS action instead
        if (type === "timeOff") {
          dispatch(ModeReducer.setModeAction({ modeAction: null }))
          dispatch(ModeReducer.setMovedBy(0))
          dispatch(ModeReducer.resetItems())
        }
      }

      const enabled = selectedItemIds.includes(item.id) && multiSelectEnabled
      setIsFloating(enabled)
      setIsHighlighted(enabled)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiSelectEnabled, multiSelect.items, multiSelect.modeAction],
  )

  useEffect(() => {
    // Update the assignment pill if mini picker changes movedBy of days
    if (minipickerUpdatedEndDate) {
      const updatedItem = {
        ...item,
        end_date: dateHelpers.formatToRunnDate(minipickerUpdatedEndDate),
      }

      setItem(updatedItem)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      globalItem = updatedItem
    }
  }, [props.minipickerUpdatedEndDate])

  useEffect(() => {
    // -------
    // IMPORTANT:
    // This ONLY handles multi-select item changes
    // --------
    if (
      selectedItemIds.includes(item.id) &&
      multiSelect.modeAction === "move"
    ) {
      const { start, end } = shiftIntervalByAmount({
        interval: {
          start: parseISO(originalItem.start_date),
          end: parseISO(originalItem.end_date),
        },
        amount: multiSelect.movedBy,
        timeOffs: holidaysAffectingPillDates,
      })
      const updatedItem = {
        ...originalItem,
        start_date: dateHelpers.formatToRunnDate(start),
        end_date: dateHelpers.formatToRunnDate(end),
      }

      setItem(updatedItem)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      globalItem = updatedItem
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [multiSelect.modeAction, multiSelect.movedBy])

  // Exclude items outside the calendar date
  if (
    Number(item.end_date) < calStartNum ||
    Number(item.start_date) > calEndNum
  ) {
    return null
  }

  const pillDisabledMessage = getReasonPillIsDisabled({
    multiSelectItems: multiSelect.items,
    pill: originalItem,
    pillPermissions,
    modeAction: multiSelect.modeAction,
    isTransferPlaceholderMode,
  })

  const formattedMultiSelectItem = (multiSelectItem) => {
    const { combinedMinutes, combinedMinutesLessTimeOff } = getItemTotalMinutes(
      {
        typedItem: { type, item: multiSelectItem },
        person,
        isConsistentTimeOffEnabled,
      },
    )

    return {
      ...multiSelectItem,
      minutes: combinedMinutes,
      minutesLessTimeOff: combinedMinutesLessTimeOff,
    }
  }

  const getMovedBy = (e) => {
    const delta = e.screenX - mouseXStartingPosition
    return Math.round(delta / dayWidth)
  }

  const openEditForm = (e) => {
    e.preventDefault()
    setShowEditForm(true)
  }

  const onMouseMove = (e) => {
    let movedByActual = getMovedBy(e)
    if (weeklyAssignments && movedByActual) {
      const numOfWeeks = Math.round(movedByActual / daysInWeek)
      movedByActual = numOfWeeks * daysInWeek
    }

    if (splitScreenMode) {
      return // don't allow moving in split screen mode
    }

    if (item.non_working_day && !multiSelectEnabled) {
      setMovementType("move") // this just sets it to "floating" so we can update cursor on overlay
      return // do nothing. dont allow them to move non-working days
    }

    let updatedItem = item

    if (actionType !== "changeEndDate" && actionType !== "changeStartDate") {
      setMovementType("move")
      actionType = "move"
      if (multiSelectEnabled) {
        // -------
        // IMPORTANT:
        // Changes made to items in multi select are handled in the useEffect
        // so we actually return this early
        // -------
        if (movedByActual !== 0) {
          // only set to move if actually moved - this is what is checked in the useEffect
          dispatch(ModeReducer.setModeAction({ modeAction: "move" }))
        }

        // If moving a non-working day return early and set action type to null
        if (item.non_working_day) {
          dispatch(ModeReducer.setModeAction({ modeAction: null }))
          return // do nothing. cannot move non-working days.
        }

        dispatch(ModeReducer.setMovedBy(movedByActual))
        return
      } else {
        const { start, end } = shiftIntervalByAmount({
          interval: {
            start: parseISO(item.start_date),
            end: parseISO(item.end_date),
          },
          amount: movedByActual,
          timeOffs: holidaysAffectingPillDates,
        })

        updatedItem = {
          ...item,
          start_date: dateHelpers.formatToRunnDate(start),
          end_date: dateHelpers.formatToRunnDate(end),
        }

        setHighlightedItemData({ ...updatedItem, projectConfirmed, type })
      }
    }

    if (actionType === "changeEndDate") {
      const { dateString: newEndDate } = getNewlyMovedDate({
        originalDate: item.end_date,
        cellsMoved: movedByActual,
        calendarWeekendsExpanded,
      })

      const newStartDate = getClosestNextWorkingDay(
        holidaysAffectingPillDates,
        item.start_date,
      ).stringDate

      updatedItem = {
        ...item,
        // don't allow items to be less than 1 day
        start_date: newStartDate,
        end_date: newEndDate < newStartDate ? newStartDate : newEndDate,
      }
    }

    if (actionType === "changeStartDate") {
      const { dateString: newStartDate } = getNewlyMovedDate({
        originalDate: item.start_date,
        cellsMoved: movedByActual,
        calendarWeekendsExpanded,
      })

      const newEndDate = getClosestPreviousWorkingDay(
        holidaysAffectingPillDates,
        item.end_date,
      ).stringDate

      updatedItem = {
        ...item,
        end_date: newEndDate,
        // don't allow items to be less than 1 day
        start_date: newStartDate > newEndDate ? newEndDate : newStartDate,
      }
    }

    setItem(updatedItem)
    globalItem = updatedItem
  }

  const onMouseUp = (e) => {
    // Only accept left clicks
    if (e.button !== 0) {
      return
    }
    // No/small  movement means its a regular click
    // A larger x mouse movement could be moving the assignment away and back
    const mouseXMovement = Math.abs(mouseXStartingPosition - e.screenX)
    const movedByActual = getMovedBy(e)

    if (
      movedByActual === 0 &&
      mouseXMovement < 5 &&
      !multiSelectEnabled &&
      !isConflict
    ) {
      openEditForm(e)
    }
    let shouldDisable = false

    // Movement means we extended or moved the assignment
    if (movedByActual !== 0 && !multiSelectEnabled && !item.non_working_day) {
      if (actionType === "changeEndDate") {
        globalItem = {
          ...globalItem,
          end_date: getClosestPreviousWorkingDay(
            holidaysAffectingPillDates,
            globalItem.end_date,
          ).stringDate,
        }
        setItem(globalItem)
      }

      if (actionType === "changeStartDate") {
        globalItem = {
          ...globalItem,
          start_date: getClosestNextWorkingDay(
            holidaysAffectingPillDates,
            globalItem.start_date,
          ).stringDate,
        }
        setItem(globalItem)
      }

      onItemChange(globalItem, actionType, setItem)
      shouldDisable = true
    }

    // Moving non-working days has already been prevented in the onMouseMove.
    // No need to disable multi-select here if it's a non-working day.
    if (
      movedByActual !== 0 &&
      multiSelectEnabled &&
      !splitScreenMode &&
      !item.non_working_day
    ) {
      shouldDisable = true
    }

    // If user clicks on a selected item, deselect it
    if (
      isFloating &&
      multiSelectEnabled &&
      !shouldDisable &&
      selectedItemIds.includes(item.id) &&
      multiSelect.modeAction !== "move"
    ) {
      dispatch(
        ModeReducer.removeItemFromMultiSelect(formattedMultiSelectItem(item)),
      )
      if (!splitScreenMode) {
        dispatch(ModeReducer.setModeAction({ modeAction: null }))
      }
    }

    GLOBAL.INTERACTING_WITH_PILL = false
    setMovementType("")
    actionType = ""
    setHighlightedItemData(null)
    setIsHighlighted(false)

    if (shouldDisable) {
      dispatch(ModeReducer.disableMultiSelect())
    }
    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)
  }

  const onMouseDown = (e) => {
    if (
      isDisabled ||
      showSplitMode ||
      Boolean(pillDisabledMessage) ||
      e.target.classList.contains("bp5-overlay-backdrop") ||
      e.target.classList.contains("bp5-popover-backdrop")
    ) {
      // If they are clicking backdrop. Don't add event listeners again.
      return
    }

    if (e.button === 2) {
      // Do nothing on right click
      // handle by ContextMenu
      return
    }

    // e.button is left click
    if (
      multiSelectEnabled &&
      e.button === 0 &&
      !selectedItemIds.includes(item.id)
    ) {
      dispatch(ModeReducer.addItemToMultiSelect(formattedMultiSelectItem(item)))
    }
    mouseXStartingPosition = e.screenX

    GLOBAL.INTERACTING_WITH_PILL = true
    document.addEventListener("mousemove", onMouseMove)
    document.addEventListener("mouseup", onMouseUp)
  }

  const changeEndDate = (e) => {
    if (isDisabled || e.button === 2) {
      return
    }

    actionType = "changeEndDate"
    setMovementType("changeEndDate")
    onMouseDown(e)
  }

  const changeStartDate = (e) => {
    if (isDisabled || e.button === 2) {
      return
    }

    actionType = "changeStartDate"
    setMovementType("changeStartDate")
    onMouseDown(e)
  }

  const onSplit = (day) => {
    onItemSplit(item, day)
    setShowSplitMode(false)
    setIsHighlighted(false)
  }

  const enableSplitMode = () => {
    setShowSplitMode(true)
    setHighlightedItemData({ ...item, projectConfirmed, type })
    setIsHighlighted(true)
    track("Assignment Context Menu Split")
  }

  const enableMultiSelect = () => {
    dispatch(ModeReducer.enableMultiSelect(person.person_requests?.[0]?.status))
    dispatch(ModeReducer.addItemToMultiSelect(formattedMultiSelectItem(item)))
    GLOBAL.INTERACTING_WITH_PILL = true
    track("Assignment Context Menu Multi-Select Enabled")
  }

  const getTimeOffsAfterSelected = () => {
    if (person.id === item.person_id) {
      return person.time_offs.filter((a) => a.start_date >= item.start_date)
    }
  }

  const getAssignmentsAfterSelected = () => {
    if (isTimeOff(item)) {
      return []
    }

    if (person.id === item.person_id) {
      return person.assignments
        .filter((a) => a.project_id === item.project_id)
        .filter((a) => a.role_id === item.role_id)
        .filter((a) => a.start_date >= item.start_date)
        .filter((a) => a.non_working_day === item.non_working_day)
        .filter((a) => a.workstream_id === item.workstream_id)
    }
  }

  const handleSelectAllToRight = (add = true) => {
    GLOBAL.INTERACTING_WITH_PILL = true
    if (!multiSelectEnabled) {
      dispatch(
        ModeReducer.enableMultiSelect(person.person_requests?.[0]?.status),
      )
    }

    const itemsToRight =
      type === "timeOff"
        ? getTimeOffsAfterSelected()
        : getAssignmentsAfterSelected()

    itemsToRight.forEach((localItem) => {
      const formatted = formattedMultiSelectItem(localItem)
      dispatch(
        add
          ? ModeReducer.addItemToMultiSelect(formatted)
          : ModeReducer.removeItemFromMultiSelect(formatted),
      )
    })

    if (add) {
      track("Assignment Context Menu All To Right Selected")
    } else {
      track("Assignment Context Menu All To Right Deselected")
    }
  }

  const handleDeleteAllSelected = (isAssignment: boolean) => {
    dispatch(
      ModeReducer.setModeAction({
        modeAction: "delete",
        type: isAssignment ? "assignment" : "timeOff",
      }),
    )
    dispatch(ModeReducer.disableMultiSelect())

    track("Assignment Context Menu Delete All Selected")
  }

  const handleDelete = () => {
    onDelete()
    track("Assignment Context Menu Deleted")
  }

  const handlePhaseSelect = (selectedPhaseId) => {
    onPhaseSelect(selectedPhaseId)
    track("Assignment Context Menu Phase Selected")
  }

  const handleResolveConflict = () => {
    if (conflictingTimeOff && onDeleteConflict) {
      onDeleteConflict(conflictingTimeOff, person, item)
      track("Assignment Conflict Deleted")
    }
  }

  const allItemsOnSingleRow = () => {
    // Checks to make sure all select items are for the same
    // person, project, role

    if (isTimeOff(item)) {
      return false
    }

    return multiSelect.items.every((i) => {
      const a = person.assignments.find((a1) => a1.id === i.id)
      if (!a) {
        return false
      } // Not this persons assignment
      if (a.project_id !== item.project_id) {
        return false
      } // different project
      if (a.role_id !== item.role_id) {
        return false
      } // different project

      return true // Everything same
    })
  }

  const handleMouseEnter = (e) => {
    if (multiSelectEnabled) {
      setIsHighlighted(true)
    }
    if (movementType || GLOBAL.INTERACTING_WITH_PILL) {
      if (e) {
        e.preventDefault()
      }
    } else {
      setIsHighlighted(true)
    }
  }

  const handleMouseLeave = (e) => {
    if (!isFloating) {
      setIsHighlighted(false)
    }
    if (movementType || GLOBAL.INTERACTING_WITH_PILL || showSplitMode) {
      e.preventDefault()
    } else {
      setHighlightedItemData(null)
    }
  }

  const showSplitMenuItem = businessDays > 1

  const getContextMenu = () => {
    const isAssignment = type === "assignment"

    if (multiSelectEnabled) {
      const assignmentsToRight = getAssignmentsAfterSelected()
      const allAssignmentsToRightAreSelected = !assignmentsToRight.filter(
        (x) => !selectedItemIds.includes(x.id),
      ).length

      const showTransferAndCloneAll = allItemsOnSingleRow()
      const showMenuItem = !splitScreenMode

      return (
        <Menu>
          {allAssignmentsToRightAreSelected ? (
            <MenuItem
              text="Deselect All to right"
              onClick={() => handleSelectAllToRight(false)}
            />
          ) : (
            <MenuItem
              text="Select All to right"
              onClick={() => handleSelectAllToRight(true)}
            />
          )}
          {showTransferAndCloneAll && !splitScreenMode && (
            <TransferMenuItem
              text="Transfer All Selected"
              onClick={() => enableMultiSelect()}
              entirePlaceholderTransfer={
                person.is_placeholder &&
                person.assignments.length === selectedItemIds.length
              }
            />
          )}
          {showTransferAndCloneAll && !splitScreenMode && (
            <CloneMenuItem
              text="Clone All Selected"
              onClick={() => enableMultiSelect()}
            />
          )}
          {onPhaseSelect && project && showMenuItem && (
            <PhaseMenuItem
              phases={project.phases}
              onPhaseSelect={handlePhaseSelect}
            />
          )}
          {showMenuItem && (
            <MenuItem
              text="Delete All Selected"
              onClick={() => handleDeleteAllSelected(isAssignment)}
            />
          )}
          <MenuItem
            text={`Exit ${
              splitScreenMode
                ? `${capitalize(multiSelect.modeAction)}`
                : "Multi-Select"
            } Mode`}
            onClick={() =>
              dispatch(ModeReducer.disableMultiSelectAndResetItems())
            }
          />
        </Menu>
      )
    }

    if (isConflict && isAssignment) {
      return (
        <Menu onMouseEnter={handleMouseEnter}>
          <div style={{ height: "10px" }} />
          <div className={styles.conflictMenuHeading}>
            <WarningIcon />
            &nbsp;Please resolve conflict
          </div>
          <TransferMenuItem
            text="Transfer"
            onClick={() => enableMultiSelect()}
          />
          {showSplitMenuItem && (
            <MenuItem text="Split" onClick={enableSplitMode} />
          )}
          <MenuItem
            text="Select All to Right"
            onClick={() => handleSelectAllToRight(true)}
          />
          <MenuItem
            text="Enable Multi-Select Mode"
            onClick={enableMultiSelect}
          />
          <MenuItem text="Delete" onClick={handleDelete} />
        </Menu>
      )
    }

    return (
      <Menu onMouseEnter={handleMouseEnter}>
        {isConflict && conflictingDeletableAssignments.length > 0 && (
          <MenuItem
            text={
              conflictingAssignments.length > 1
                ? `Delete ${conflictingDeletableAssignments.length} assignment conflicts`
                : `Delete assignment conflict`
            }
            onClick={handleResolveConflict}
          />
        )}

        <MenuItem text="Edit" onClick={() => setShowEditForm(true)} />
        {showSplitMenuItem && (
          <MenuItem text="Split" onClick={enableSplitMode} />
        )}
        {isAssignment && (
          <>
            <TransferMenuItem
              text="Transfer"
              onClick={() => enableMultiSelect()}
              entirePlaceholderTransfer={
                person.is_placeholder && person.assignments.length === 1
              }
            />
            <CloneMenuItem text="Clone" onClick={() => enableMultiSelect()} />
          </>
        )}
        <MenuItem
          text="Select All to Right"
          onClick={() => handleSelectAllToRight(true)}
        />
        {onPhaseSelect && project && !item.non_working_day && (
          <PhaseMenuItem
            phases={project.phases}
            phaseId={phaseId || "No Phase"}
            onPhaseSelect={handlePhaseSelect}
          />
        )}
        <MenuItem text="Enable Multi-Select Mode" onClick={enableMultiSelect} />
        <MenuItem text="Delete" onClick={handleDelete} />
      </Menu>
    )
  }

  const closeForm = () => {
    setShowEditForm(false)
    setItem(originalItem)
    globalItem = originalItem
  }

  const renderEditForm = () => {
    const toggleForm = (state) => {
      if (state === false) {
        if (showMiniPickerContextMenu) {
          setShowMiniPickerContextMenu(false)
        } else {
          closeForm()
        }
      } else {
        setShowEditForm(state)
      }
    }

    if (type === "timeOff") {
      const content = React.cloneElement(itemEditForm, {
        handleCancel: closeForm,
      })
      return (
        <Dialog isOpen={showEditForm} onClose={closeForm}>
          {content}
        </Dialog>
      )
    }

    const withClosePopover = (fn: () => void) => {
      return () => {
        setShowMiniPickerContextMenu(false)
        closeForm()
        fn()
      }
    }

    const renderedPopoverContent = props.popoverContent(
      closeForm,
      showEditForm,
      {
        onSplit: showSplitMenuItem
          ? withClosePopover(enableSplitMode)
          : undefined,
        onTransfer: withClosePopover(enableMultiSelect),
        onClone: withClosePopover(enableMultiSelect),
        onSelectAllToRight: withClosePopover(() =>
          handleSelectAllToRight(true),
        ),
        onEnableMultiSelect: withClosePopover(enableMultiSelect),
        isContextMenuOpen: showMiniPickerContextMenu,
        setIsContextMenuOpen: setShowMiniPickerContextMenu,
      },
    )

    return (
      <Popover
        content={renderedPopoverContent}
        isOpen={showEditForm}
        onInteraction={toggleForm}
        placement="top"
        rootBoundary="viewport"
        lazy
        portalClassName={styles.editFormPortalWrapper}
        fill={true}
      >
        <span />
      </Popover>
    )
  }

  const currentContract = getCurrentContract(person.contracts)

  return (
    <ContextMenu content={getContextMenu()}>
      {(ctxMenuProps: ContextMenuChildrenProps) => {
        const onMenuClick = (e) => {
          e.stopPropagation()
          if (isDisabled || Boolean(pillDisabledMessage) || showEditForm) {
            return
          }
          ctxMenuProps.onContextMenu(e)
        }

        const handleOnMouseDown = (e) => {
          if (ctxMenuProps.contentProps.isOpen) {
            return
          }

          onMouseDown(e)
        }
        return (
          <div
            className={cc([
              "userflow-assignment-pill",
              styles.ActionPillContainer,
              styles.PillContainer,
              {
                [styles.isDisabled]: isDisabled,
                [styles.nonWorkingDay]: item.non_working_day,
              },
            ])}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleOnMouseDown}
            onContextMenu={onMenuClick}
            ref={ctxMenuProps.ref}
            style={{ left: `${offset}%`, width: `${width}%` }}
            data-test={
              type === "assignment" ? "assignment-pill" : "timeoff-pill"
            }
          >
            {ctxMenuProps.popover}
            <ItemTooltip
              typedItem={typedItem}
              phases={project?.phases}
              days={item.non_working_day ? 1 : businessDays}
              overlappingTimeOff={isConflict}
              isOutOfContract={isOutOfContract}
              pillWidthPx={pillWidthPx}
              pillDisabledMessage={pillDisabledMessage}
              hideTooltip={
                (multiSelectEnabled && multiSelect.modeAction === "move") ||
                movementType === "move" ||
                showEditForm
              }
              person={person}
              contractMinutesPerDay={currentContract?.minutes_per_day}
            >
              {props.render({
                item,
                calendarStartDate,
                calendarEndDate,
                dayWidth,
                onMenuClick,
                isSelected: isHighlighted,
                isFloating: movementType === "move" || isFloating,
                isOverlappingTimeOff: isConflict,
                isDisabled: Boolean(pillDisabledMessage),
                pillLabelOffset,
              })}
            </ItemTooltip>
            {showSplitMode && !item.non_working_day && (
              <div ref={splitterRef} className={styles.splitterContainer}>
                <Splitters
                  item={item}
                  calendarStartDate={calendarStartDate}
                  calendarEndDate={calendarEndDate}
                  itemVisibleDays={visibleDays}
                  onClick={onSplit}
                  byWeek={periods === "weeks"}
                  calendarWeekendsExpanded={calendarWeekendsExpanded}
                  timeOffs={holidayTimeOffs}
                />
              </div>
            )}
            {showChangeStartEnd && (
              <div
                onMouseDown={changeStartDate}
                className={cc({
                  [styles.resizer]: !isDisabled,
                  [styles.isDisabled]: isDisabled,
                })}
                style={{ width: halfSizeResizer ? "5px" : "10px" }}
              />
            )}
            <div className={styles.editFormTarget}>{renderEditForm()}</div>
            {showChangeStartEnd && (
              <div
                onMouseDown={changeEndDate}
                className={cc({
                  [styles.resizer]: !isDisabled,
                  [styles.isDisabled]: isDisabled,
                })}
                style={{ right: 0, width: halfSizeResizer ? "5px" : "10px" }}
              />
            )}
            {movementType && (
              // overlay prevents hovers on other assignments while moving
              <div className={styles.overlay} />
            )}
          </div>
        )
      }}
    </ContextMenu>
  )
}

export type PopoverContextMenu = {
  onSplit?: () => void
  onTransfer: () => void
  onClone: () => void
  onSelectAllToRight: () => void
  onEnableMultiSelect: () => void
  isContextMenuOpen: boolean
  setIsContextMenuOpen: (v: boolean) => void
}

type Props = {
  item: Assignment | TimeOff
  person: Person
  project?: {
    confirmed: boolean
    is_template: boolean
    phases: {
      id: number
      color: string
      name: string
    }[]
  }
  projects?: TimeOffActionPill_account$data["projects"]
  phaseId?: number
  type: "assignment" | "timeOff"
  popoverContent?: (
    closePopover,
    isPopoverOpen: boolean,
    contextMenu: PopoverContextMenu,
  ) => React.ReactElement
  onItemChange: (
    assignment: Item,
    movementType?: ActionType,
    resetItem?: (Item) => void,
  ) => void
  onItemSplit: (item: Item, day: number) => void
  itemEditForm?: React.ReactElement
  onDelete: () => void
  onDeleteConflict?: (
    timeOff: TimeOff,
    person: Person,
    source: TimeOff | Assignment,
  ) => void
  minipickerUpdatedEndDate?: Date
  render: (any) => React.ReactElement
  setHighlightedItemData: (any) => void
  onPhaseSelect?: (phase_id: number) => void
  calendarWeekendsExpanded: boolean
  isOutOfContract?: boolean
  disableItemChange?: boolean
}

type Person = {
  id: number
  email: string | null
  first_name: string
  last_name: string
  assignments: ReadonlyArray<Assignment>
  time_offs: ReadonlyArray<TimeOff>
  contracts: ReadonlyArray<Contract>
  is_placeholder: boolean
  person_requests?: readonly {
    status: string
  }[]
}

export type Assignment = {
  id: number
  project_id: number
  person_id: number
  role_id: number
  workstream_id: number
  phase_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  note: string
  non_working_day: boolean
  updated_at?: string
  total_minutes?: number
}

export type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
  note: string
  minutes_per_day: number
  non_working_day?: boolean
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
    readonly external: {
      readonly integration_id: number
    }
  }>
}

type Item = {
  id: number
  person_id: number
  role_id?: number
  project_id?: number
  start_date: string
  end_date: string
  minutes_per_day?: number
  updated_at?: string
  is_billable?: boolean
  note?: string
}

type Contract = {
  start_date: string
  end_date: string
  minutes_per_day: number
}

type ActionType = "" | "move" | "changeStartDate" | "changeEndDate"

const mapDispatchToProps = {
  setHighlightedItemData: setHighlightedItemDataDispatch,
}
const connector = connect(null, mapDispatchToProps)
export default connector(PillActions)
