import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { getSettings } from "~/localsettings"

export type PlannerGroupByType = "roles" | "workstreams"
export type PlannerStatsType = "hours" | "revenue" | "days"

export type PlannerStatsReducerState = {
  statsType: PlannerStatsType
  groupByType: PlannerGroupByType
}

const getInitialState = (): PlannerStatsReducerState => {
  const settings = getSettings()

  return {
    statsType: settings?.plannerStatsType ?? "hours",
    groupByType: settings?.plannerStatsGroupBy ?? "roles",
  }
}

const plannerStatsSlice = createSlice({
  name: "plannerStats",
  initialState: getInitialState,
  reducers: {
    changeStatsType: (state, action: PayloadAction<PlannerStatsType>) => {
      state.statsType = action.payload
    },
    changeGroupByType: (state, action: PayloadAction<PlannerGroupByType>) => {
      state.groupByType = action.payload
    },
  },
})

const { actions, reducer } = plannerStatsSlice
export const { changeStatsType, changeGroupByType } = actions
export default reducer
