import { captureEvent } from "~/helpers/error-helpers"

import applyDeleteAction from "./apply-delete-action"
import applyInsertAction from "./apply-insert-action"
import applyUpdateAction from "./apply-update-action"
import { getModelByTableName } from "./models"
import type { Action, Store } from "./types"

const applyAction = (store: Store, action: Action): boolean => {
  const model = getModelByTableName(action.table_name)
  if (!model) {
    return false
  }

  // Log changes for context in case of errors caused later in execution
  // due to potential state inconsistencies. Limit logging to avoid PII from row_data.
  captureEvent(
    {
      name: "AuditLogSubscription.applyAction",
      action: action.action,
      model,
      id: action.row_data.id,
    },
    "info",
  )

  switch (action.action) {
    case "I": {
      applyInsertAction(store, model, action.row_data)
      return true
    }
    case "U": {
      applyUpdateAction(store, model, action.row_data, action.changed_fields)
      return true
    }
    case "D": {
      applyDeleteAction(store, model, action.row_data)
      return true
    }
    default: {
      return false
    }
  }
}

export default applyAction
