import * as fe from "@runn/filter-engine"
import { Action, ActionCreator, Dispatch } from "redux"

import {
  changeActivePage,
  isValidPage,
} from "./common/ActivePage/ActivePage.slice"
import { moveDateRange } from "./common/calendar.reducer"

import {
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "./Planner/Planner.actions"
import {
  filteredByProject,
  filteredByTemplate,
} from "./Planner/reducer2/projectFilterSlice"
import { showAllViewSelected } from "./Planner/reducer2/viewsSlice"

const config: Record<string, ActionCreator<Action>> = {
  activePage: (page: string) =>
    isValidPage(page) ? changeActivePage(page) : undefined,
  Project: (projectId: string) => {
    const id = parseInt(projectId, 10)
    return filteredByProject(id)
  },
  Template: (templateId: string) => {
    const id = parseInt(templateId, 10)
    return filteredByTemplate(id)
  },
  Person: (personId: string) =>
    setPeopleFilterSet({
      name: "",
      filters: fe.filters.personId({ list: [parseInt(personId, 10)] }),
    }),
  PersonTeam: (teamId: string) =>
    setPeopleFilterSet({
      name: "",
      filters: fe.filters.personTeamId({ list: [parseInt(teamId, 10)] }),
    }),
  PersonRole: (roleId: string) =>
    setPeopleFilterSet({
      name: "",
      filters: fe.filters.personRoleId({ list: [parseInt(roleId, 10)] }),
    }),
  PersonWildSearch: (search: string) => setPeopleWildSearch(search),
  CalendarStartDate: (startDate: unknown) => {
    if (typeof startDate === "string") {
      return moveDateRange(new Date(startDate))
    }

    return undefined
  },
  ShowAllView: (show: string) => {
    return show === "true" ? showAllViewSelected() : undefined
  },
}

const loadStateFromSearchParams = (dispatch: Dispatch) => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  for (const [key, actionCreator] of Object.entries(config)) {
    const value = searchParams.get(key)
    if (typeof value === "string") {
      const action = actionCreator(value)
      if (typeof action !== "undefined") {
        dispatch(action)
      }
    }
    searchParams.delete(key)
  }

  window.history.replaceState(null, "", url)
}

export { loadStateFromSearchParams }
