import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { PeoplePlannerOptions_account$key } from "~/common/PageControls/__generated__/PeoplePlannerOptions_account.graphql"

import { track } from "~/helpers/analytics"
import {
  PeopleGroupByOption,
  getPeopleGroupByOptions,
} from "~/helpers/group-helpers"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { SET_EXPANDED_PEOPLE_GROUPS } from "~/Planner/Planner.actions"
import {
  groupByChanged,
  selectGroupPeopleBy,
  selectSortPeopleBy,
  selectSortPlaceholdersBy,
  sortPeopleChanged,
  sortPlaceholdersChanged,
} from "~/Planner/reducer2/peopleSortSlice"
import { setShowGroupUtilization } from "~/Planner/reducer2/peopleSummarySlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import ButtonGroup from "./ButtonGroup"
import ChartsToggle from "./ChartsToggle"
import GroupControl, { DIVIDER } from "./GroupControl"
import PeopleSortControl from "./PeopleSortControl"
import ViewControls from "./ViewControls"

const PeoplePlannerOptions = (props: {
  account: PeoplePlannerOptions_account$key
}) => {
  const {
    people: { isOverflow },
  } = useQueryLimit()
  const dispatch = useDispatch()
  const showGroupUtilization = useAppSelector(
    (state) => state.plannerV2.peopleSummary.showGroupUtilization,
  )
  const groupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )
  const sortPeopleBy = useAppSelector((state) =>
    selectSortPeopleBy(state.plannerV2.people),
  )

  const sortPlaceholderBy = useAppSelector((state) =>
    selectSortPlaceholdersBy(state.plannerV2.people),
  )

  const account = useFragment(
    graphql`
      fragment PeoplePlannerOptions_account on accounts {
        people_custom_select_types: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          model
          single_select
          options: custom_select_options {
            id
            name
          }
        }
      }
    `,
    props.account,
  )

  const onGroupByChange = (option: {
    value: PeopleGroupByOption
    label: string
  }) => {
    dispatch(groupByChanged(option.value))
    setSetting("peoplePlannerGroupBy", option)

    // set all groups to closed
    dispatch({
      type: SET_EXPANDED_PEOPLE_GROUPS,
      payload: [{ groupByFilter: option.value, expanded: [] }],
    })
    setSetting("expandedPeopleGroups", [
      { groupByFilter: option.value, expanded: [] },
    ])
    setSetting("plannerPeopleExpanded", [])

    if (option.value.startsWith("custom_")) {
      track("Custom Field Groupby Selected", {
        model: "PERSON",
        fieldName: option.label,
      })
    }
  }

  // Reset disabled people planner options to default value when in overflow state
  const resetOptions = () => {
    if (showGroupUtilization) {
      dispatch(setShowGroupUtilization(false))
      setSetting("peopleUtilizationGroup", false)
    }

    if (groupBy !== "default") {
      dispatch(groupByChanged("default"))
      setSetting("peoplePlannerGroupBy", null)
    }

    if (sortPeopleBy !== "availability") {
      dispatch(sortPeopleChanged("availability"))
      setSetting("peoplePlannerSort", {
        value: "availability",
        label: "Availability",
      })
    }

    if (sortPlaceholderBy !== "project") {
      dispatch(sortPlaceholdersChanged("project"))
      setSetting("placeholderPlannerSort", {
        value: "project",
        label: "Project Name",
      })
    }
  }

  if (isOverflow) {
    resetOptions()
  }

  const workstreamsEnabled = useFeature("workstreams")

  const groupingOptions = getPeopleGroupByOptions(
    account.people_custom_select_types,
    !!workstreamsEnabled,
  )
  const customIndex = groupingOptions.findIndex((o) =>
    o.value.startsWith("custom_"),
  )
  if (customIndex > -1) {
    groupingOptions.splice(customIndex, 0, DIVIDER)
  }

  return (
    <ButtonGroup>
      <ChartsToggle />
      <ViewControls dataTest="planner-toggles" />
      <GroupControl
        dataTest="people-group-by"
        onChange={onGroupByChange}
        selectedOption={groupBy}
        options={groupingOptions}
        disabled={isOverflow}
      />
      <PeopleSortControl disabled={isOverflow} />
    </ButtonGroup>
  )
}

export default PeoplePlannerOptions
