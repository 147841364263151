/**
 * @generated SignedSource<<7c7922f2b1c3fc48aed42ec883135ee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmContractForm_person$data = {
  readonly email: string | null | undefined;
  readonly id: number;
  readonly " $fragmentType": "ConfirmContractForm_person";
};
export type ConfirmContractForm_person$key = {
  readonly " $data"?: ConfirmContractForm_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmContractForm_person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmContractForm_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};

(node as any).hash = "cbf3fbc10a49bc7d341862f7d22240a8";

export default node;
