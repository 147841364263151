import { useEffect } from "react"

import { reportError } from "~/helpers/error-helpers"

import { createFilterSet } from "~/mutations/FilterSet"

import { getSettings, removeSetting, setSetting } from "~/localsettings"

type Options = {
  localStorageKey: "savedProjectFilterSetList" | "savedPeopleFilterSetList"
  filterSetType: "project" | "person"
}

const useMigrateSavedFilterSetsToDatabase = (options: Options) => {
  const { localStorageKey, filterSetType } = options

  // TODO(GC): this is temporary code to migrate saved filter sets from localstorage to the database
  useEffect(() => {
    const savedFilterSetList = getSettings()[localStorageKey]
    if (Array.isArray(savedFilterSetList) && savedFilterSetList.length > 0) {
      Promise.allSettled(
        savedFilterSetList.map((filterSet) => {
          return createFilterSet({
            filterSet: {
              type: filterSetType,
              name: filterSet.name,
              filters: filterSet.filters,
            },
          })
        }),
      )
        .then((results) => {
          const failedToCreateFilterSets = savedFilterSetList.filter(
            (_filterSet, index) => {
              const result = results[index]
              return result.status !== "fulfilled"
            },
          )

          if (failedToCreateFilterSets.length > 0) {
            setSetting(localStorageKey, failedToCreateFilterSets)
            void reportError(
              "Failed to migrate Filter Sets from localstorage to database.",
              {
                failedToCreateFilterSets,
                results,
              },
            )
          } else {
            removeSetting(localStorageKey)
          }
        })
        .catch((error) => {
          void reportError(error)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export { useMigrateSavedFilterSetsToDatabase }
