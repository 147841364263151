import cc from "classcat"
import React, { useRef } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PlaceholderSummaryRow.module.css"

import { PlaceholderSummaryRow_person$key } from "./__generated__/PlaceholderSummaryRow_person.graphql"
import { PlaceholderSummaryRow_user$key } from "./__generated__/PlaceholderSummaryRow_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { showPeopleAndPlaceholdersInGroup } from "~/helpers/group-helpers"
import { getCurrentContract } from "~/helpers/person"

import PersonDetails from "~/common/PersonDetails"
import PlaceholderIcon from "~/common/PlaceholderIcon"
import { useSidePanel } from "~/common/SidePanel/SidePanel"

import { useEntitlementSwitch } from "~/Entitlements/useEntitlements"
import { usePermissions } from "~/Permissions/usePermissions"
import PlaceholderSidePanel from "~/Planner/PlaceholderSidePanel/PlaceholderSidePanel"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { selectGroupPeopleBy } from "~/Planner/reducer2/peopleSortSlice"
import { useAppSelector } from "~/hooks/redux"
import useGroupUtilizationConfig from "~/hooks/useGroupUtilizationConfig"

import useFavouritePerson from "../useFavouritePerson"

import PlaceholderCapacityIndicator from "./PlaceholderCapacityIndicator"

type Props = {
  personId: number
  personExpanded: boolean
  handleToggleRow: () => void
  placeholder: PlaceholderSummaryRow_person$key
  disabled?: boolean
  locatedInSchedulePanel?: boolean
}

const PlaceholderSummaryRow = (props: Props) => {
  const placeholder = useFragment(
    graphql`
      fragment PlaceholderSummaryRow_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        id
        is_placeholder
        first_name
        last_name
        email
        active
        tags
        image_key
        archivable
        team {
          id
          name
        }
        project_memberships {
          id
          workstream_id
          project_id
          workstream {
            id
            name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          person_id
          phase_id
          role_id
          workstream_id
          start_date: start_date_runn
          end_date: end_date_runn
          is_billable
          is_template
          minutes_per_day
          note
          total_minutes
          non_working_day
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          cost: cost_private
          role {
            id
            name
          }
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        person_requests {
          id
          status
          updated_at
          user {
            id
            first_name
            last_name
          }
          person {
            id
            first_name
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              cost: cost_private
              minutes_per_day
              role {
                id
                name
              }
            }
          }
        }
        people_notes {
          id
          note
          created_at
          ...PersonNote_note
        }
        placeholder_suggestions {
          suggested_person_id
        }
        ...PlaceholderSidePanel_placeholder
        ...EditPersonForm_person
        ...PersonCustomEditor_person
        ...PersonDetails_person @arguments(plannerStartDate: $plannerStartDate)
        ...PlannerLeftColumn_person
        ...PlaceholderActionButtons_placeholder
      }
    `,
    props.placeholder,
  )

  const user = useFragment<PlaceholderSummaryRow_user$key>(
    graphql`
      fragment PlaceholderSummaryRow_user on users
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        ...PlannerLeftColumn_user
        id
        favouritePersonIds: favourite_people
        account {
          ...PlaceholderSidePanel_account
          ...PlannerLeftColumn_account
          default_full_time_minutes
          projects(where: $projectsFilter) {
            ...PlaceholderCapacityIndicator_projects
            id
            confirmed
            is_template
            name
            ...PlannerLeftColumn_project
          }
          custom_text_types_person: custom_text_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          custom_date_types_person: custom_date_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
          }
          custom_select_types_person: custom_select_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            options: custom_select_options {
              id
              name
            }
            show_in_planner
            filterable_in_planner
          }
          custom_checkbox_types_person: custom_checkbox_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            description
            show_in_planner
            filterable_in_planner
          }
        }
      }
    `,
    useHasuraContext(),
  )
  const { personExpanded, handleToggleRow, disabled, locatedInSchedulePanel } =
    props
  const ref = useRef(null)

  const { account, favouritePersonIds } = user
  const projects = account.projects
  const contract = getCurrentContract(placeholder.contracts)
  const collapsible = !locatedInSchedulePanel

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_date_types: account.custom_date_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
  })
  const customFieldValues = valuesMap(placeholder)

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const placeholdersInGroup = showPeopleAndPlaceholdersInGroup(peopleGroupBy)

  // If placeholders are not shown within a group, the group utilization mini-chart does not affect it
  const showGroupUtilization =
    useGroupUtilizationConfig().showGroupUtilization && placeholdersInGroup

  const { isFavourite, favouritePerson } = useFavouritePerson({
    personId: placeholder.id,
    userId: user.id,
    favouritePersonIds: favouritePersonIds,
  })

  const starPlaceholder = (e: React.MouseEvent) => {
    e.stopPropagation()

    track("Placeholder Starred")

    favouritePerson()
  }

  // Take first project_membership as placeholders can only have one project for now
  // the array shouldn't be empty but is making tests fail in cypress
  const placeholderProject = projects.find(
    (p) => p.id === placeholder.project_memberships[0]?.project_id,
  )
  const placeholderProjectId = placeholderProject?.id
  const placeholderProjectName = placeholderProject?.name ?? ""
  const subtitle = contract?.role?.name ?? "No Contract"

  const { can, subject } = usePermissions()

  const showPlaceholderSidePanel = can(
    "view",
    subject("Placeholder", {
      project: {
        id: placeholderProjectId,
        isTemplate: placeholderProject?.is_template,
      },
    }),
  )

  const { openPanel, closePanel } = useSidePanel()
  const entitledToResourceRequests = useEntitlementSwitch("resourcing-requests")

  const openPlaceholderSidePanel = () => {
    track("Placeholder Side Panel Opened")
    openPanel(
      <PlaceholderSidePanel
        account={account}
        placeholder={placeholder}
        project={placeholderProject}
        closePanel={closePanel}
      />,
    )
  }

  return (
    <PlannerGrid
      ref={ref}
      className={cc([
        "userflow-placeholder-row",
        styles.placeholderSummaryRow,
        {
          [styles.rowExpanded]: personExpanded,
          [styles.utilizationGroup]:
            showGroupUtilization && !locatedInSchedulePanel,
          [styles.miniGroup]: !showGroupUtilization && !locatedInSchedulePanel,
          [styles.inGroup]: placeholdersInGroup,
        },
      ])}
      disabled={disabled}
      allowInteraction={disabled}
    >
      <PlannerLeftColumn
        type="person" // is_placeholder handled in PlannerLeftColumn.tsx
        personLegacy={placeholder}
        personQuery={placeholder}
        project={placeholderProject}
        person={placeholder}
        account={account}
        onClick={handleToggleRow}
        style={{ cursor: collapsible && "pointer" }}
        isCollapsed={!personExpanded}
        collapsible={collapsible}
        showStar
        isStarred={isFavourite}
        projectId={placeholderProjectId}
        handleStar={starPlaceholder}
        showMenuDots
        showPlaceholderRequestMenu={true}
        showFindPerson={true}
        locatedInSchedulePanel={locatedInSchedulePanel}
        data-test={`placeholder-${placeholder.first_name}-${placeholder.last_name}`}
        options={
          entitledToResourceRequests ? (
            <PlaceholderIcon iconSize={15} placeholder={placeholder} />
          ) : null
        }
      >
        <PersonDetails
          person={placeholder}
          subtitle={subtitle}
          size={32}
          icons={
            <PlannerTooltipIcons
              icons={{
                tags: placeholder.tags,
                competencies: placeholder.competencies,
                customFields: {
                  values: customFieldValues,
                  types: customFieldTypes,
                },
              }}
            />
          }
          placeholderProjectName={placeholderProjectName}
          onClick={showPlaceholderSidePanel ? openPlaceholderSidePanel : null}
        />
      </PlannerLeftColumn>
      <PlannerRightColumn showHighlight={false}>
        <PlaceholderCapacityIndicator
          projects={projects}
          placeholders={[placeholder]}
          companyDefaultMinutes={account.default_full_time_minutes}
        />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default React.memo(PlaceholderSummaryRow)
