import { Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch } from "react-redux"

import styles from "./SplitScreenActionButton.module.css"

import { SplitScreenRow_person$data } from "./__generated__/SplitScreenRow_person.graphql"

import { track } from "~/helpers/analytics"
import { formatName } from "~/helpers/person"
import {
  MultiSelectAssignments,
  getPastAction,
  getPastAssignments,
  getPresentAction,
  transferOrCloneAssignments,
} from "~/helpers/split-screen-helpers"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import Dialog from "~/common/Dialog"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import Button from "~/common/buttons/Button"
import { TickCircleIcon, WarningInfoIcon } from "~/common/react-icons"

import { assignmentsTransferredRelay } from "~/mutations/Assignment"
import { useDeletePersonMutation } from "~/mutations/useDeletePersonMutation"

import { SplitScreenModeAction } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import {
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "~/Planner/Planner.actions"
import { showAllMembersInProject } from "~/Planner/reducer2/projectMembersViewSlice"
import {
  disabledActionButtons,
  enabledActionButtons,
  peopleCloned,
} from "~/Planner/reducer2/splitScreenSlice"
import { addAdditionalPlannerIds } from "~/Planner/reducer2/viewsSlice"
import { showToast } from "~/containers/ToasterContainer"
import { useAppSelector } from "~/hooks/redux"

import PlaceholderTransferConfirmationModal from "./PlaceholderTransferConfirmationModal"
import RoleConfirmationModal from "./RoleConfirmationModal"

type Role = {
  id: number
  name: string
}

type Props = {
  person: SplitScreenRow_person$data | undefined
  multiSelectAssignments: MultiSelectAssignments
  selectedRole: Role
  assignmentRole: Role
  isSaving: boolean
  disabled: boolean
  conflictMessage: string
  modeAction: SplitScreenModeAction
  showRoleConfirmation: boolean
  showPlaceholderTransferConfirmation: boolean
  setIsSaving: (val: boolean) => void
  setShowImpact: (val: boolean) => void
  setShowRoleConfirmation: (val: boolean) => void
  setShowPlaceholderTransferConfirmation: (val: boolean) => void
  entirePlaceholderTransfer: boolean
  selectedPersonName: string
  resetAll: () => void
  viewId?: number
  isPersonProjectMember: boolean
}
const SplitScreenActionButton = (props: Props) => {
  const {
    isSaving,
    modeAction,
    setShowImpact,
    conflictMessage,
    disabled,
    multiSelectAssignments,
    setIsSaving,
    selectedRole,
    assignmentRole,
    person,
    resetAll,
    setShowRoleConfirmation,
    showRoleConfirmation,
    setShowPlaceholderTransferConfirmation,
    showPlaceholderTransferConfirmation,
    entirePlaceholderTransfer = false,
    selectedPersonName,
    viewId,
    isPersonProjectMember,
  } = props

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const dispatch = useDispatch()

  const actionsDisabled = useAppSelector(
    (state) => state.plannerV2.splitScreen.actionsDisabled,
  )

  const listOfPeopleCloned = useAppSelector(
    (state) => state.plannerV2.splitScreen.peopleCloned,
  )

  const defaultViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )

  const multiSelect = useAppSelector((state) => state.multiSelect)

  const [confirmedRoleId, setConfirmedRoleId] = useState(null)

  const { can, subject } = usePermissions()
  const canCreateProjectMember = can(
    "create",
    subject("ProjectMember", {
      person: { id: person.id, email: person.email },
      project: { id: multiSelectAssignments[0]?.project_id, isTemplate: false },
    }),
  )

  const closeRoleConfirmation = () => setShowRoleConfirmation(false)

  const closePlaceholderTransferConfirmation = () =>
    setShowPlaceholderTransferConfirmation(false)

  const isTransferMode = modeAction === "transfer"

  const onSuccess = () => {
    const pastAssignments = getPastAssignments(multiSelectAssignments)

    const trackProperties = {
      number_of_assignments: multiSelectAssignments.length,
      includes_past_assignments: Boolean(pastAssignments.length),
      placeholder_status: multiSelect.personRequestStatus || "NONE",
    }
    switch (modeAction) {
      case "transfer":
        if (person.is_placeholder) {
          track("Transfer To Placeholder", trackProperties)
        } else {
          track("Transfer To Person", trackProperties)
        }
        break
      case "clone":
        if (person.is_placeholder) {
          track("Clone To Placeholder", trackProperties)
        } else {
          track("Clone To Person", trackProperties)
        }
        break
      default:
        modeAction satisfies never
        break
    }

    dispatch(enabledActionButtons())
    closePlaceholderTransferConfirmation()
    closeRoleConfirmation()
    setIsSaving(false)
    dispatch(
      peopleCloned({
        id: person.id,
        role_id: selectedRole.id,
        multiSelectAssignments,
      }),
    )
  }
  const personLink = () => {
    dispatch(
      setPeopleFilterSet({
        name: "",
        filters: fe.filters.personId({
          list: [person.id],
        }),
      }),
    )
    dispatch(setPeopleWildSearch(""))
    dispatch(changeActivePage("people"))
  }

  const [deletePerson] = useDeletePersonMutation()
  const { closePanel } = useSidePanel()

  const handleSubmit = async (confirmDeletePlaceholder: boolean) => {
    setIsSaving(true)
    dispatch(disabledActionButtons())

    await transferOrCloneAssignments(
      modeAction,
      multiSelectAssignments,
      person,
      confirmedRoleId || selectedRole.id,
      () => personLink(),
      isConsistentTimeOffEnabled,
      onSuccess,
    )

    await assignmentsTransferredRelay({
      values: {
        toPersonId: person.id,
        assignments: multiSelectAssignments.map((a) => ({
          id: a.id,
          project_id: a.project_id,
          person_id: a.person_id,
        })),
      },
    })

    const assignmentPersonID = multiSelectAssignments[0].person_id
    const projectId = multiSelectAssignments[0].project_id

    if (confirmDeletePlaceholder) {
      track("Placeholder Deleted")

      try {
        await deletePerson({ personId: assignmentPersonID })
        showToast({
          type: "success",
          message: `${selectedPersonName} has been deleted`,
        })
      } catch (error: unknown) {
        showToast({
          message: `Failed to delete "${selectedPersonName}".`,
          description: error instanceof Error ? error.message : undefined,
          type: "error",
        })
      }
      closePanel()
    }

    if (entirePlaceholderTransfer && !confirmDeletePlaceholder) {
      dispatch(showAllMembersInProject(projectId))
    }

    if (isTransferMode) {
      resetAll()
    }

    if (viewId !== defaultViewId) {
      dispatch(
        addAdditionalPlannerIds({
          viewId: defaultViewId,
          peopleIds: [person.id],
        }),
      )
    }
  }

  const handleRoleConfirmation = async () => {
    if (entirePlaceholderTransfer) {
      setShowPlaceholderTransferConfirmation(true)
      closeRoleConfirmation()
    } else {
      await handleSubmit(false)
    }
  }

  const isTransferAllowed = isPersonProjectMember || canCreateProjectMember

  const handleAction = async (e) => {
    e.stopPropagation()

    if (!isTransferAllowed) {
      return
    }

    if (isTransferMode && selectedRole.id !== assignmentRole.id) {
      setShowRoleConfirmation(true)
    } else if (isTransferMode && entirePlaceholderTransfer) {
      setShowPlaceholderTransferConfirmation(true)
    } else {
      await handleSubmit(false)
    }
  }

  const onMouseEnter = () => {
    if (!isSaving) {
      setShowImpact(true)
    }
  }

  const onMouseLeave = () => {
    if (!isSaving) {
      setShowImpact(false)
    }
  }

  const buttonText = isSaving ? getPresentAction(modeAction) : modeAction

  if (
    modeAction === "clone" &&
    listOfPeopleCloned.some(
      (p) =>
        p.id === person.id &&
        p.role_id === selectedRole.id &&
        isDeeplyEqual(p.multiSelectAssignments, multiSelectAssignments),
    )
  ) {
    return (
      <Button
        text={getPastAction(modeAction)}
        disabled={true}
        icon={<TickCircleIcon />}
        className={styles.actionButton}
      />
    )
  }

  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cc([styles.wrapper, { [styles.disabled]: disabled }])}
      >
        <Tooltip
          content={
            <div className={styles.tooltipConflict}>{conflictMessage}</div>
          }
          hoverOpenDelay={500}
          disabled={!Boolean(conflictMessage)}
          placement="top"
        >
          <Button
            data-test={`split-screen-${modeAction}-button-${person.first_name}-${person.last_name}`}
            text={isTransferAllowed ? buttonText : "Preview"}
            disabled={disabled || actionsDisabled}
            onClick={handleAction}
            icon={Boolean(conflictMessage) ? <WarningInfoIcon /> : null}
            className={styles.actionButton}
            forceAnchorButton={disabled}
          />
        </Tooltip>
      </div>
      {showRoleConfirmation && (
        <Dialog isOpen={showRoleConfirmation} onClose={closeRoleConfirmation}>
          <RoleConfirmationModal
            assignmentRole={assignmentRole}
            selectedRole={selectedRole}
            personName={{
              firstName: person.first_name,
              lastName: person.last_name,
            }}
            handleSubmit={handleRoleConfirmation}
            setConfirmedRoleId={setConfirmedRoleId}
            confirmedRoleId={confirmedRoleId}
            isSaving={isSaving}
            closeDialog={closeRoleConfirmation}
            hasMultipleAssignments={multiSelectAssignments.length > 1}
            entirePlaceholderTransfer={entirePlaceholderTransfer}
          />
        </Dialog>
      )}
      {showPlaceholderTransferConfirmation && (
        <Dialog
          isOpen={showPlaceholderTransferConfirmation}
          onClose={closePlaceholderTransferConfirmation}
        >
          <PlaceholderTransferConfirmationModal
            personName={formatName(person.first_name, person.last_name)}
            project={multiSelectAssignments[0]}
            placeholderName={selectedPersonName}
            isSaving={isSaving}
            handleSubmit={handleSubmit}
            closeDialog={closePlaceholderTransferConfirmation}
            numberOfAssignments={multiSelectAssignments.length}
          />
        </Dialog>
      )}
    </>
  )
}

export default SplitScreenActionButton
