import cc from "classcat"
import React from "react"

import styles from "./MilestoneIcon.module.css"

import { isEmoji } from "~/helpers/text-helpers"

import mileStoneIcons from "./MilestoneIcons"

type Props = {
  iconOrEmoji: "dollar" | "warning" | "start" | "end" | "flag" | "plus" | string
  deselected?: boolean
  size?: number
  className?: string
  onClick?: () => void
  noSymbol?: boolean
}

const MilestoneIcon = (props: Props) => {
  const {
    iconOrEmoji,
    deselected,
    size = 24,
    className,
    onClick,
    noSymbol,
  } = props

  if (noSymbol) {
    return <div className={styles.noSymbol} onClick={onClick} />
  }

  const component = isEmoji(iconOrEmoji)
    ? iconOrEmoji
    : mileStoneIcons.find((i) => i.name === iconOrEmoji).component

  return (
    <div
      className={cc([
        styles.icon,
        className,
        {
          [styles.deselected]: deselected,
          [styles.small]: size < 20,
        },
      ])}
      style={{
        minWidth: size,
        width: size,
        height: size,
        minHeight: size,
      }}
      onClick={onClick}
    >
      {component}
    </div>
  )
}

export default MilestoneIcon
