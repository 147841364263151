/**
 * @generated SignedSource<<d51eb92316028b84b9bd7e0b328f38e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderWorkflowMenu_placeholder$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderCommentModal_placeholder" | "PlaceholderSidePanel_placeholder">;
  readonly " $fragmentType": "PlaceholderWorkflowMenu_placeholder";
};
export type PlaceholderWorkflowMenu_placeholder$key = {
  readonly " $data"?: PlaceholderWorkflowMenu_placeholder$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderWorkflowMenu_placeholder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderWorkflowMenu_placeholder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderCommentModal_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderSidePanel_placeholder"
    }
  ],
  "type": "people",
  "abstractKey": null
};

(node as any).hash = "b76c18c2a9eb43ceb0e7b03d59d397b3";

export default node;
