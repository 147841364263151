import { Switch } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./ViewControls.module.css"

import { toggledShowTotalEffort } from "~/display_units/showTotalEffortSlice"
import { useAppSelector } from "~/hooks/redux"

const ShowTotalEffortToggle = () => {
  const showTotalEffort = useAppSelector(
    (state) => state.displayUnit.showTotalEffort,
  )
  const dispatch = useDispatch()
  const toggleShowTotalEffort = () => dispatch(toggledShowTotalEffort())

  return (
    <div className={styles.toggle}>
      <Switch
        checked={showTotalEffort}
        onChange={toggleShowTotalEffort}
        label="Show Total Effort"
        alignIndicator="left"
      />
    </div>
  )
}

export default ShowTotalEffortToggle
