import * as fe from "@runn/filter-engine"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { AddPersonToProject_account$key } from "./__generated__/AddPersonToProject_account.graphql"

import { track } from "~/helpers/analytics"
import {
  findNewMembers,
  forceShowMembers,
} from "~/helpers/project-member-helpers"

import ProjectClientIcon from "~/common/ProjectClientIcon"
import SelectPersonList from "~/common/SelectPersonList/SelectPersonList"
import { SelectorHeader, SelectorModal } from "~/common/SelectorModal"
import {
  SelectOption,
  toOptionIncludingArchived,
} from "~/common/SelectorModal/SelectorHelpers"

import { placeholderBulkCreateRelay } from "~/mutations/Placeholder"
import { projectMemberBulkCreateRelay } from "~/mutations/ProjectMember"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import {
  addAdditionalPlannerIds,
  resetTemporaryPlannerFilter,
} from "~/Planner/reducer2/viewsSlice"
import usePlannerFilters from "~/Planner/usePlannerFilters"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  account: AddPersonToProject_account$key
  projectId: number
  hideModal: () => void
  defaultFilters?: fe.engines.local.AllowedFilterListItem
  defaultRolePlaceholder?: number
  defaultWorkstream?: number
}

const AddPersonToProject = (props: Props) => {
  const account = useFragment(
    graphql`
      fragment AddPersonToProject_account on accounts
      @argumentDefinitions(
        plannerStartDate: { type: "date!" }
        projectsFilter: { type: "projects_bool_exp" }
      ) {
        id
        projects(where: $projectsFilter) {
          id
          name
          team_id
          emoji
          is_template
          client {
            id
            name
            image_key
            website
          }
          members {
            id
            person_id
            project_id
            role_id
            workstream_id
            is_placeholder
          }
          project_workstreams {
            workstream {
              id
              name
              archived
            }
          }
        }
        ...SelectPersonList_account
          @arguments(
            plannerStartDate: $plannerStartDate
            peopleFilter: $peopleFilter
            projectsFilter: $projectsFilter
          )
      }
    `,
    props.account,
  )
  const {
    projectId,
    defaultFilters,
    hideModal,
    defaultRolePlaceholder,
    defaultWorkstream,
  } = props

  const defaultViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )

  const { peopleFilter } = usePlannerFilters()

  const project = account.projects.find((p) => p.id === projectId)
  const client = project.client
  const currentProjectMembers = project.members || []
  const projectWorkstreamOptions =
    project.project_workstreams.map((pw) =>
      toOptionIncludingArchived(pw.workstream),
    ) || []

  const defaultWorkstreamOption = projectWorkstreamOptions.find(
    (w) => w.value === defaultWorkstream,
  )

  const [selectedWorkstream, setSelectedWorkstream] =
    useState<SelectOption | null>(defaultWorkstreamOption ?? null)

  const dispatch = useDispatch()

  const onModalClose = () => {
    hideModal()
    dispatch(resetTemporaryPlannerFilter())
    setSelectedWorkstream(null)
  }

  const handleCreateProjectMembers = (people) => {
    const workstreamId =
      typeof selectedWorkstream?.value === "number"
        ? selectedWorkstream.value
        : null

    const { newMembers, existingMembers } = findNewMembers(
      people,
      currentProjectMembers,
      projectId,
      workstreamId,
    )

    if (existingMembers.length > 0) {
      forceShowMembers(existingMembers)
    }

    if (newMembers.length > 0) {
      void projectMemberBulkCreateRelay({
        input: { project_members: newMembers },
      })
    }
  }

  const addPlaceholderToProject = async (role, quantity) => {
    track("Person Assigned to Project", {
      people_count: 0,
      placeholders_count: quantity,
      roles: [role],
      workstreams: [selectedWorkstream?.label],
    })

    const newPlaceholders = Array(quantity).fill({
      role_id: role.id,
    })

    const createdPlaceholders = await placeholderBulkCreateRelay({
      input: { placeholders: newPlaceholders },
      plannerStartDate: PLANNER_INITIAL_DATE,
      _peopleFilter: peopleFilter ? JSON.stringify(peopleFilter) : null,
    })
    track("Placeholder Created", { created_count: createdPlaceholders.length })

    handleCreateProjectMembers(createdPlaceholders)
  }

  const addPeopleToProject = (people, selectedViewId?: number) => {
    track("Person Assigned to Project", {
      people_count: people.length,
      placeholders_count: 0,
      roles: [...new Set(people.map((p) => p.role?.name).filter(Boolean))],
    })

    const realPeople = people
      .filter((p) => !p.is_placeholder)
      .map((person) => ({ ...person, projectId }))

    handleCreateProjectMembers(realPeople)

    if (selectedViewId !== defaultViewId) {
      dispatch(
        addAdditionalPlannerIds({
          viewId: defaultViewId,
          peopleIds: realPeople.map((p) => p.id),
        }),
      )
    }
  }

  return (
    <SelectorModal isOpen onClose={onModalClose} modalWidth={800}>
      <SelectorHeader
        title={project.name}
        subtitle={client.name}
        icon={<ProjectClientIcon project={project} client={client} />}
        onClose={onModalClose}
      />
      <SelectPersonList
        account={account}
        project={project}
        closeModal={onModalClose}
        addPeopleToProject={addPeopleToProject}
        addPlaceholderToProject={addPlaceholderToProject}
        handleWorkstreamChange={setSelectedWorkstream}
        selectedWorkstream={selectedWorkstream}
        workstreamOptions={projectWorkstreamOptions}
        defaultFilters={defaultFilters}
        defaultRolePlaceholder={defaultRolePlaceholder}
      />
    </SelectorModal>
  )
}

export default AddPersonToProject
