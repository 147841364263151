import { Icon } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import { useFeature } from "flagged"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./SplitScreenMain.module.css"

import { SplitScreenMain_user$key } from "./__generated__/SplitScreenMain_user.graphql"
import { SplitScreenPanel_user$data } from "./__generated__/SplitScreenPanel_user.graphql"

import { buildCustomSelectFilterOptions } from "~/helpers/custom-field-helpers"
import { prepareDataForFilterPersonList } from "~/helpers/filter-engine"
import { formatName, getCurrentContract } from "~/helpers/person"
import { getSortedAvailabilityIds } from "~/helpers/sorting-helpers"

import AddSection from "~/common/AddSection"
import { Assignment } from "~/common/PillActions/PillActions"
import BlueLink from "~/common/buttons/BlueLink"
import { ViewState } from "~/common/views.reducer"

import { plannerChangeDateRelay } from "~/mutations/Planner"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { SplitScreenModeAction } from "~/Mode.reducer"
import NoResultsForFilter from "~/Planner/NoResultsForFilter"
import { setSplitScreenFilterSet } from "~/Planner/Planner.actions"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

import SplitScreenRow from "./SplitScreenRow"
import NewPlaceholderRow from "./SplitScreenRow/NewPlaceholderRow"

type SSPanelAccount = SplitScreenPanel_user$data["account"]

type Props = {
  people: SSPanelAccount["people"]
  projects: SSPanelAccount["projects"]
  companyDefaultMinutes: SSPanelAccount["default_full_time_minutes"]
  favouritePersonIds: SplitScreenPanel_user$data["favourite_people"]
  filterSet: fe.engines.local.AllowedFilterSet
  listType: "people" | "placeholders"
  onResetFilters: () => void
  onSwitchToAllView: () => void
  viewState: ViewState
  user: SplitScreenMain_user$key
}

const MAX_NUMBER_OF_PEOPLE_TO_SHOW = 25

const SplitScreenMain = (props: Props) => {
  const {
    people,
    projects,
    favouritePersonIds,
    filterSet,
    onResetFilters,
    listType,
    companyDefaultMinutes,
    onSwitchToAllView,
    viewState: { showAllView, viewId },
  } = props

  const user = useFragment(
    graphql`
      fragment SplitScreenMain_user on users {
        ...SplitScreenRow_user
        id
        account {
          id
          views {
            id
          }
          custom_select_types {
            id
            name
          }
        }
      }
    `,
    props.user,
  )

  const { account } = user

  const dispatch = useDispatch()

  const calendar = useAppSelector((state) => state.calendar)
  const { calendarEndDate, calendarStartDate, calStartNum } = calendar

  const filterTypeList = fe.filters.asTypeList(filterSet.filters)
  const multiSelect = useAppSelector((state) => state.multiSelect)
  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  // Can only transfer/clone assignments (not timeOffs)
  const multiSelectAssignments = multiSelect.items as Array<
    Assignment & { minutes: number; minutesLessTimeOff: number }
  >
  const hasMultiSelectAssignments = Boolean(multiSelectAssignments.length)
  // Can only transfer or clone in Split Screen mode
  const modeAction = multiSelect.modeAction as SplitScreenModeAction
  const entirePlaceholderTransfer = multiSelect.entirePlaceholderTransfer

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const peopleWithContracts = useMemo(
    () => people.filter((p) => getCurrentContract(p.contracts)),
    [people],
  )

  const resetUserFilterSet = () => {
    dispatch(setSplitScreenFilterSet({ name: "Split Screen", filters: null }))
  }

  const handleClearFilters = () => {
    resetUserFilterSet()
    onResetFilters()
  }

  const firstSelectedItem = multiSelectAssignments[0]
  const [selectedPerson, setSelectedPerson] = useState(
    people.find((p) => firstSelectedItem?.person_id === p.id),
  )
  const [startingIndex, setStartingIndex] = useState(0)

  useEffect(() => {
    if (firstSelectedItem) {
      // Only update selected person when a new assignment is selected
      // To prevent a lot of jumping between rows
      setSelectedPerson(
        people.find((p) => firstSelectedItem?.person_id === p.id),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSelectedItem])

  const updateFilters = () => {
    let defaultFilters = null

    const defaultPlaceholderFilter = match({
      isTemplate: firstSelectedItem.is_template,
    })
      .with({ isTemplate: true }, () => [
        fe.filters.personProjectTemplateId({
          list: [firstSelectedItem.project_id],
        }),
      ])
      .otherwise(() => [
        fe.filters.personMembership({
          list: [firstSelectedItem.project_id],
        }),
      ])

    const customValuesFilterSet = buildCustomSelectFilterOptions(
      selectedPerson.custom_select_values,
      account.custom_select_types,
    )

    defaultFilters = match({
      modeAction,
      listType,
    })
      .with({ modeAction: "clone" }, () => {
        const project = projects.find(
          (p) => p.id === firstSelectedItem?.project_id,
        )
        if (project && !project.is_template) {
          return fe.filters.simplify(
            fe.filters.personMembership({
              list: [firstSelectedItem?.project_id],
            }),
          )
        }

        if (project && project.is_template) {
          return fe.filters.simplify(
            fe.filters.personProjectTemplateId({
              list: [firstSelectedItem?.project_id],
            }),
          )
        }
      })
      .with({ modeAction: "transfer" }, () => {
        // Filters that are set based on the items being transferred
        // Only apply project filter to placeholders on transfer
        const transferFiltersOnly = match(listType)
          .with("people", () => [
            selectedPerson.team
              ? fe.filters.personTeamId({ list: [selectedPerson.team?.id] })
              : undefined,
            selectedPerson.competencies.length && selectedPerson.is_placeholder
              ? fe.filters.personSkillId({
                  list: selectedPerson.competencies.map((competency) => {
                    return {
                      skillId: competency.skill.id,
                      level: [competency.level],
                    }
                  }),
                })
              : undefined,
            selectedPerson.tags.length && selectedPerson.is_placeholder
              ? fe.filters.personTagId({ list: [...selectedPerson.tags] })
              : undefined,
            selectedPerson.custom_select_values.length &&
            selectedPerson.is_placeholder
              ? customValuesFilterSet.map((filter) =>
                  fe.filters.personCustomSelect(filter),
                )
              : undefined,
          ])
          .with("placeholders", () => defaultPlaceholderFilter)
          .exhaustive()

        return fe.filters.simplify(
          fe.filters.and([
            fe.filters.personRoleId({
              list: [firstSelectedItem.role_id],
            }),
            ...transferFiltersOnly,
          ]),
        )
      })
      .with({ listType: "placeholders" }, () =>
        fe.filters.and([...defaultPlaceholderFilter]),
      )
      .otherwise(() => null)

    if (defaultFilters) {
      dispatch(
        setSplitScreenFilterSet({
          name: "Split Screen",
          filters: defaultFilters,
        }),
      )
    }
  }

  useEffect(() => {
    // Update on initial load
    if (hasMultiSelectAssignments) {
      updateFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Update when item (person, project, role) changes in Transfer to Placeholder
    // Because we have to force the role and project to match
    if (listType === "placeholders" && hasMultiSelectAssignments) {
      updateFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSelectedItem, listType === "placeholders"])

  const preparedList = useMemo(
    () =>
      prepareDataForFilterPersonList({
        filterTypeList,
        projects,
        people: peopleWithContracts,
        favouritePersonIds,
        calStartNum,
        includeArchivedProjects: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterTypeList.join(","),
      peopleWithContracts,
      projects,
    ],
  )

  const filteredPeople = useMemo(() => {
    const filteredPersonList = fe.engines.local.filterPersonList(
      preparedList,
      filterSet,
    )

    const filteredPersonIds = Object.fromEntries(
      filteredPersonList
        .filter((p) => p.id !== selectedPerson?.id)
        .map((person) => [person.id, true]),
    )

    return peopleWithContracts.filter((person) => filteredPersonIds[person.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparedList, filterSet])

  const visibleProjectIds = projects
    .filter(
      (p) =>
        p.confirmed ||
        isTentativeProjectEnabled(enabledTentativeProjects, p.id),
    )
    .map((p) => p.id)

  /*
  This function gets historical data to avoid issues where Runn can't merge data because it doesnt have past assignmetns.
  Basically when check if any assignment has a startdate before the planner date, and if so we load data back to then.
  See: https://github.com/Runn-Fast/runn/issues/6626
 */
  useEffect(() => {
    const earliestStartDate = multiSelectAssignments.reduce(
      (acc, assignment) =>
        assignment.start_date < acc ? assignment.start_date : acc,
      "99999999",
    )

    if (!earliestStartDate) {
      return
    }

    const isBeforePlannerStartDate =
      Number(earliestStartDate) < Number(PLANNER_INITIAL_DATE)

    if (isBeforePlannerStartDate) {
      void plannerChangeDateRelay({
        fromDate: earliestStartDate,
        toDate: PLANNER_INITIAL_DATE,
      })
    }
  }, [multiSelectAssignments]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortedPeople = useMemo(() => {
    const sortedIds = getSortedAvailabilityIds(
      filteredPeople,
      calendarStartDate,
      calendarEndDate,
      visibleProjectIds,
      isConsistentTimeOffEnabled,
    )
    return [...filteredPeople].sort(
      (a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPeople])

  useEffect(() => {
    setStartingIndex(0)
  }, [sortedPeople.length])

  if (
    filteredPeople.length === 0 &&
    peopleWithContracts.length > 0 &&
    listType === "people"
  ) {
    return (
      <NoResultsForFilter
        subject="people"
        onClearFilters={handleClearFilters}
        accountHasViews={account.views.length > 0}
        localViews={{
          onSwitchToAllView,
          showAllView,
        }}
      />
    )
  }

  const showNextGroupOfPeople = () => {
    setStartingIndex(startingIndex + MAX_NUMBER_OF_PEOPLE_TO_SHOW)
  }

  const showPreviousGroupOfPeople = () => {
    if (startingIndex - MAX_NUMBER_OF_PEOPLE_TO_SHOW >= 0) {
      setStartingIndex(startingIndex - MAX_NUMBER_OF_PEOPLE_TO_SHOW)
    } else {
      setStartingIndex(0)
    }
  }

  return (
    <>
      {listType === "placeholders" && (
        <NewPlaceholderRow
          multiSelectAssignments={multiSelectAssignments}
          companyDefaultMinutes={companyDefaultMinutes}
          projects={projects}
          modeAction={modeAction}
        />
      )}
      {sortedPeople.slice(startingIndex).map((person, i) => {
        if (i > MAX_NUMBER_OF_PEOPLE_TO_SHOW - 1) {
          return null
        }

        return (
          <SplitScreenRow
            key={person.id}
            user={user}
            person={person}
            projects={projects}
            multiSelectAssignments={multiSelectAssignments}
            modeAction={modeAction}
            entirePlaceholderTransfer={entirePlaceholderTransfer}
            selectedPersonName={formatName(
              selectedPerson.first_name,
              selectedPerson.last_name,
            )}
            listType={listType}
            companyDefaultMinutes={companyDefaultMinutes}
            countInView={sortedPeople.length}
            viewId={viewId}
          />
        )
      })}
      {sortedPeople.length > MAX_NUMBER_OF_PEOPLE_TO_SHOW && (
        <>
          <AddSection>
            <div className={styles.textContainer}>
              <p>
                Showing {startingIndex + 1} –{" "}
                {Math.min(
                  startingIndex + MAX_NUMBER_OF_PEOPLE_TO_SHOW,
                  sortedPeople.length,
                )}{" "}
                out of {sortedPeople.length} people based on availability.
              </p>
            </div>
            <div className={styles.buttonsContainer}>
              {startingIndex >= MAX_NUMBER_OF_PEOPLE_TO_SHOW && (
                <BlueLink onClick={() => showPreviousGroupOfPeople()}>
                  <div className={styles.backButton}>
                    <Icon icon="chevron-left" className={styles.icon} />
                    Back
                  </div>
                </BlueLink>
              )}
              {startingIndex + MAX_NUMBER_OF_PEOPLE_TO_SHOW <
                sortedPeople.length && (
                <BlueLink
                  onClick={() => showNextGroupOfPeople()}
                  className={styles.nextButton}
                >
                  <div className={styles.nextButton}>
                    Next
                    <Icon icon="chevron-right" className={styles.icon} />
                  </div>
                </BlueLink>
              )}
            </div>
          </AddSection>
        </>
      )}
    </>
  )
}

export default SplitScreenMain
