/**
 * @generated SignedSource<<1f984e4f019daff7f18c5b2342710b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountWelcome_user$data = {
  readonly account: {
    readonly account_type: string;
    readonly alternative_account: {
      readonly name: string;
      readonly users_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
    } | null | undefined;
    readonly name: string;
    readonly users_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
  };
  readonly id: number;
  readonly viewed_welcome: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingDialog_user" | "WelcomeVideoDialog_user">;
  readonly " $fragmentType": "AccountWelcome_user";
};
export type AccountWelcome_user$key = {
  readonly " $data"?: AccountWelcome_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountWelcome_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "users_aggregate",
  "kind": "LinkedField",
  "name": "users_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "users_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountWelcome_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "accounts",
          "kind": "LinkedField",
          "name": "alternative_account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed_welcome",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WelcomeVideoDialog_user"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "OnboardingDialog_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "6817059fc125ce48e1c931b9006c5ee0";

export default node;
