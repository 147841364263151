import React, { Fragment, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PlannerDatePicker.module.css"

import { PlannerDatePickerHourStats_account$key } from "./__generated__/PlannerDatePickerHourStats_account.graphql"

import { EffortInput } from "~/common/EffortInput/EffortInput"

import { TotalEffortInput } from "../EffortInput/TotalEffortInput"

type Prop = {
  account: PlannerDatePickerHourStats_account$key
  isTimeOff: boolean
  minutesPerDay: number
  setMinutesPerDay: (minutes: number) => void
  totalWorkingDays: {
    label: string
    value: number
  }
  contractMinutes: number
}

const PlannerDatePickerHourStats = (props: Prop) => {
  const {
    isTimeOff,
    minutesPerDay,
    setMinutesPerDay,
    totalWorkingDays,
    contractMinutes,
  } = props

  const account = useFragment(
    graphql`
      fragment PlannerDatePickerHourStats_account on accounts {
        ...EffortInput_account
        ...TotalEffortInput_account
      }
    `,
    props.account,
  )

  const [total, setTotal] = useState<{
    value: number
    label: string
  }>({
    value: totalWorkingDays.value * minutesPerDay,
    label: totalWorkingDays.label,
  })

  useEffect(() => {
    setTotal({
      value: totalWorkingDays.value * minutesPerDay,
      label: totalWorkingDays.label,
    })
  }, [totalWorkingDays, minutesPerDay])

  const minutesInDay = 24 * 60
  return (
    <div className={styles.stats}>
      {!isTimeOff && (
        <Fragment>
          <div>
            <EffortInput
              account={account}
              minutesPerDay={minutesPerDay}
              onChange={setMinutesPerDay}
              contractMinutes={contractMinutes}
            />
          </div>

          <TotalEffortInput
            account={account}
            onMinutesPerDayChange={setMinutesPerDay}
            minutesPerDay={total.value}
            workingDayCount={totalWorkingDays.value}
            maxMinutes={minutesInDay * totalWorkingDays.value}
            contractMinutes={contractMinutes}
          />
        </Fragment>
      )}
    </div>
  )
}

export default PlannerDatePickerHourStats
