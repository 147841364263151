import * as fe from "@runn/filter-engine"
import { useEffect } from "react"

import type { Option } from "~/types/helpers"

type Options<Value> = {
  filter: fe.filters.Filter<string, { list: Array<unknown> }>
  selectedOptions: Option<Value>[]
  onChange: (list: Value[]) => void
}

const useAutoRemoveMissingListValues = <Value>(options: Options<Value>) => {
  const { filter, selectedOptions, onChange } = options

  useEffect(
    () => {
      if (selectedOptions.length < filter.options.list.length) {
        onChange(selectedOptions.map((option) => option.value))
      }
    },
    // we pass an empty array as the useEffect dependencies.
    // this means that we only run autoRemove when the component is first mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

export default useAutoRemoveMissingListValues
