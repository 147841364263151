import React, { useEffect, useMemo, useState } from "react"

import { sortByString } from "~/helpers/sorting-helpers"

import Select, { GroupedOption, ReactSelectProps } from "~/common/Select"

import { usePermissions } from "~/Permissions/usePermissions"

type Option = {
  // heads up
  // value is sometimes the database ID of the tag
  // and sometimes it is the name of the tag
  value: number | string
  label: string
}

export type TagSelectorProps = {
  id?: string
  options: Option[] | GroupedOption[]
  onChange: (tags: Option[]) => void
  placeholder?: string
  existingTags?: readonly string[]
  label?: string
  tabIndex?: number
  autoFocus?: boolean
  height?: number
  minHeight?: number
  isCreatable?: boolean
  disableMenuPortalTarget?: boolean
} & ReactSelectProps

const TagSelector = (props: TagSelectorProps) => {
  const {
    options,
    existingTags,
    onChange,
    placeholder = "Select tags or type to create new",
    id = "tag-selector",
  } = props

  const { can, subject } = usePermissions()
  const isCreatable =
    props.isCreatable !== undefined
      ? props.isCreatable
      : can("create", subject("Tag"))

  const allSelectedOptions = useMemo(() => {
    const selectedOptions = Array.isArray(existingTags)
      ? options.filter((value) => existingTags.includes(value.label))
      : []

    // tags no longer in the database, but still attached to the project or person
    const missingSelectedOptions = Array.isArray(existingTags)
      ? existingTags
          .filter((tag) => !options.find((option) => option.label === tag))
          .map((value) => ({ value, label: value }))
      : []

    const combinedOptions = [...selectedOptions, ...missingSelectedOptions]

    return combinedOptions
  }, [existingTags]) // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedTags, setSelectedTags] = useState(allSelectedOptions)

  useEffect(() => {
    setSelectedTags(allSelectedOptions)
  }, [allSelectedOptions])

  const sortedOptions = options.sort((a, b) => sortByString(a.label, b.label))

  const handleChange = (selectedOptions: Option[]) => {
    setSelectedTags(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select
      {...props}
      name="tag-selector"
      id={id}
      isMulti
      placeholder={placeholder}
      options={sortedOptions}
      isCreatable={isCreatable}
      isClearable={false}
      onChange={handleChange}
      value={selectedTags}
      noOptionsMessage={() => "No Tags"}
    />
  )
}

export default TagSelector
