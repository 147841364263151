import { dateHelpers } from "@runn/calculations"
import { format as formatDate, isWeekend, startOfWeek } from "date-fns"
import flatten from "lodash-es/flatten"
import groupBy from "lodash-es/groupBy"
import merge from "lodash-es/merge"

import { groupSameWeekends } from "./CalendarHelper"
import DurationHelper from "./DurationHelper"

const format = "yyyy-MM-dd"

export const getMergedDailyData = (
  workloadGraphData: WorkloadDaysObject,
  capacityGraphData: CapacityDaysObject,
): mergedObject => {
  const mergedData = Object.values(
    merge(workloadGraphData, capacityGraphData),
  ).map((data) => ({
    ...data,
    timeOffPercentage: (data.timeOff / data.contractedCapacity) * 100 || 0,
    availability: data.effectiveCapacity - data.workloadTotal,
    utilizationBillable: data.effectiveCapacity
      ? (data.workloadBillable / data.effectiveCapacity) * 100
      : 0,
    utilizationNonbillable: data.effectiveCapacity
      ? (data.workloadNonbillable / data.effectiveCapacity) * 100
      : 0,
  }))

  return mergedData
}

export const getWeekendData = (
  data: mergedObject,
  calStartNum: number,
  calEndNum: number,
  calendarWeekendsExpanded: boolean,
) => {
  const weekends = data
    .filter((d) => d.isWeekend)
    .map((d) => ({ ...d, start_date: d.key, non_working_day: d.isWeekend }))

  let weekendData = []
  const groupedWeekends = groupSameWeekends(
    weekends,
    calStartNum,
    calEndNum,
    calendarWeekendsExpanded,
  )

  if (weekends.length > 1) {
    weekendData = groupedWeekends.map((we) => {
      // then map to see if there is anything assigned over the entire weekend
      const hasAssignedHours = we[0].workloadTotal || we[1].workloadTotal
      return {
        start_date: we[0].start_date,
        body: hasAssignedHours ? 85 : 0, // This is the "blue overbooked" goes up 85% on chart
        border: hasAssignedHours ? 15 : 0, // This is the "red border on top" stacks on top 15%
      }
    })
  }

  return weekendData
}

export const getMergedWeeklyData = (
  workloadGraphData: WorkloadDaysObject,
  capacityGraphData: CapacityDaysObject,
) => {
  const groupedWorkload = Object.values(
    groupBy(workloadGraphData, (day) =>
      startOfWeek(dateHelpers.parseRunnDate(day.key), {
        weekStartsOn: 1,
      }),
    ),
  )

  const weekWorkloadGraphData = flatten(
    groupedWorkload.map((week) => {
      const weeklyTotals = {
        workloadBillable: 0,
        workloadConfirmed: 0,
        workloadNonbillable: 0,
        workloadTentative: 0,
        workloadTotal: 0,
      }
      week.forEach((day) => {
        weeklyTotals.workloadBillable += day.workloadBillable
        weeklyTotals.workloadConfirmed += day.workloadConfirmed
        weeklyTotals.workloadNonbillable += day.workloadNonbillable
        weeklyTotals.workloadTentative += day.workloadTentative
        weeklyTotals.workloadTotal += day.workloadTotal
      })
      return { formattedDate: week[0].formattedDate, ...weeklyTotals }
    }),
  )

  const groupedCapacity = Object.values(
    groupBy(capacityGraphData, (day) =>
      startOfWeek(dateHelpers.parseRunnDate(day.key), {
        weekStartsOn: 1,
      }),
    ),
  )

  const weekCapacityGraphData = flatten(
    groupedCapacity.map((week) => {
      const weeklyTotals = {
        effectiveCapacity: 0,
        contractedCapacity: 0,
        timeOff: 0,
      }
      week.forEach((day) => {
        weeklyTotals.effectiveCapacity += day.effectiveCapacity
        weeklyTotals.contractedCapacity += day.contractedCapacity
        weeklyTotals.timeOff += day.timeOff
      })
      return { formattedDate: week[0].formattedDate, ...weeklyTotals }
    }),
  )

  const mergedWeekData = weekWorkloadGraphData.map((day) => {
    const capacity = weekCapacityGraphData.find(
      (v) => v.formattedDate === day.formattedDate,
    )

    return {
      ...day,
      ...capacity,
      timeOffPercentage:
        (capacity.timeOff / capacity.contractedCapacity) * 100 || 0,
      availability: capacity.effectiveCapacity - day.workloadTotal,
      utilizationBillable: capacity.effectiveCapacity
        ? (day.workloadBillable / capacity.effectiveCapacity) * 100
        : 0,
      utilizationNonbillable: capacity.effectiveCapacity
        ? (day.workloadNonbillable / capacity.effectiveCapacity) * 100
        : 0,
    }
  })

  return mergedWeekData
}

export const getWorkloadAssignments = (
  people: ReadonlyArray<{ assignments: ReadonlyArray<Assignment> }>,
  roleIds: number[],
  startDate: Date,
) => {
  const startDateNum = Number(dateHelpers.formatToRunnDate(startDate))

  const allPeopleAssignments = flatten(people.map((p) => p.assignments)).filter(
    (a) => a && Number(a.end_date) >= startDateNum,
  ) // Don't include past assignments

  return roleIds.length
    ? allPeopleAssignments.filter((a) => roleIds.includes(a.role_id))
    : allPeopleAssignments
}

type CapacityPerson = {
  active: boolean
  is_placeholder: boolean
  contracts: ReadonlyArray<Contract>
}

export const getCapacityPeople = <T extends CapacityPerson>(
  people: ReadonlyArray<T>,
  roleIds: number[],
  startDate: Date,
  endDate: Date,
  includePlaceholders?: boolean,
) => {
  const contractPeople: Array<T & { contract: Contract }> = []
  people.forEach((p) => {
    if (!p) {
      return
    }
    p.contracts.forEach((c) => {
      contractPeople.push({ ...p, contract: c })
    })
  })

  const startDateStr = dateHelpers.formatToRunnDate(startDate)
  const endDateStr = dateHelpers.formatToRunnDate(endDate)

  // Remove placeholders, out of contract and wrong role
  const capacityPeople = contractPeople.filter((p) => {
    if (!p) {
      return false
    }
    if (!p.active) {
      return false
    }
    if (p.is_placeholder) {
      return false
    }
    if (p.contract.start_date > endDateStr) {
      return false
    }
    if (p.contract.end_date && p.contract.end_date < startDateStr) {
      return false
    }
    if (roleIds.length === 0) {
      return true
    }
    return roleIds.includes(p.contract.role_id)
  })

  return capacityPeople
}

const rangeIntoWorkloadObject = (
  startDate: Date,
  endDate: Date,
): WorkloadDaysObject => {
  const daysObject = dateHelpers
    .eachDayOfInterval(
      { start: startDate, end: endDate },
      "rangeIntoWorkloadObject",
    )
    .reduce((acc, date) => {
      const formattedDate = formatDate(date, format)
      const runnDate = dateHelpers.formatToRunnDate(date)

      acc[runnDate] = {
        key: runnDate,
        formattedDate: formattedDate,
        isWeekend: isWeekend(date),
        workloadBillable: 0,
        workloadNonbillable: 0,
        workloadTotal: 0,
        workloadConfirmed: 0,
        workloadTentative: 0,
      }

      return acc
    }, {})

  return daysObject
}

const rangeIntoCapacityObject = (
  startDate: Date,
  endDate: Date,
): CapacityDaysObject => {
  const daysObject = dateHelpers
    .eachDayOfInterval(
      { start: startDate, end: endDate },
      "rangeIntoCapacityObject",
    )
    .reduce((acc, date) => {
      const formattedDate = formatDate(date, format)
      const runnDate = dateHelpers.formatToRunnDate(date)

      acc[runnDate] = {
        key: runnDate,
        formattedDate: formattedDate,
        isWeekend: isWeekend(date),
        effectiveCapacity: 0,
        contractedCapacity: 0,
        timeOff: 0,
      }

      return acc
    }, {})
  return daysObject
}

/* **************************
 * This is written in a ridiculous way for performance reasons
 * Basically we get an array of days as numbers, then filter the array to build
 * a new array for all the dates between a start and end date.
 * Its 10x faster than actually get date objects and building a new array
 * ****************************/
const assignmentInWorkloadObject = (
  days,
  assignment: Assignment,
  timeOffsMap: Map<string, TimeOffWithPersonId>,
) => {
  const assignmentDays = days.filter((day) => {
    if (day.key < assignment.start_date) {
      return false
    }
    if (day.key > assignment.end_date) {
      return false
    }
    return true
  })

  const workloadArray = assignmentDays.map((day) => {
    if (day.isWeekend && !assignment.non_working_day) {
      return day
    }

    const key = `${assignment.person_id}-${day.key}`
    // Ignore time offs unless the assignment occurs on a non-working day
    // and there are no other time offs on that day
    const timeOff = timeOffsMap.get(key)

    if (
      (!assignment.non_working_day && timeOff) ||
      timeOff?.leave_type === "rostered-off" ||
      timeOff?.leave_type === "annual"
    ) {
      return day
    }

    const workloadDay = {
      key: day.key,
      workloadTotal: DurationHelper.minutesToHours(assignment.minutes_per_day),
      workloadBillable: assignment.is_billable
        ? DurationHelper.minutesToHours(assignment.minutes_per_day)
        : 0,
      workloadNonbillable: assignment.is_billable
        ? 0
        : DurationHelper.minutesToHours(assignment.minutes_per_day),
      workloadConfirmed: assignment.confirmedProject
        ? DurationHelper.minutesToHours(assignment.minutes_per_day)
        : 0,
      workloadTentative: assignment.confirmedProject
        ? 0
        : DurationHelper.minutesToHours(assignment.minutes_per_day),
    }
    return workloadDay
  })

  const daysWithWorkload = {}

  workloadArray.forEach((day) => {
    daysWithWorkload[day.key] = day
  })

  return daysWithWorkload
}

/* **************************
 * This is written in a ridiculous way for performance reasons
 * Basically we get an array of days as numbers, then filter the array to build
 * a new array for all the dates between a start and end date.
 * Its 10x faster than actually get date objects and building a new array
 * ****************************/
const contractsToCapacityObject = (
  days: Array<{ effectiveCapacity: number; key: string; isWeekend: boolean }>,
  person: ContractPerson,
) => {
  // Get all the time off days, so they can be excluded
  const timeOffDays: {
    [key: string]: number | null
  } = {}
  const rosteredTimeOffDays: Set<string> = new Set()

  days.forEach((day) => {
    person.time_offs.forEach((time_off) => {
      if (day.key >= time_off.start_date && day.key <= time_off.end_date) {
        if (time_off.leave_type === "rostered-off") {
          rosteredTimeOffDays.add(day.key)
        } else {
          timeOffDays[day.key] = time_off.minutes_per_day
        }
      }
    })
  })

  const contractedDays = days.filter((day) => {
    if (day.key < person.contract.start_date) {
      return false
    }
    if (person.contract.end_date && day.key > person.contract.end_date) {
      return false
    }
    return true
  })

  const capacity = DurationHelper.minutesToHours(
    person.contract.minutes_per_day,
  )

  const capacityArray = contractedDays.map((day) => {
    const capacityValue =
      day.isWeekend || rosteredTimeOffDays.has(day.key) ? 0 : capacity

    const capacityDay = {
      key: day.key,
      effectiveCapacity: capacityValue,
      isWeekend: day.isWeekend,
      isTimeOff: false,
      isContracted: true,
      contractedCapacity: capacityValue,
      timeOff: 0,
    }

    if (timeOffDays[day.key] !== undefined) {
      // This day has a time off. If the value is falsy, it's a full day off. Else
      // we have a partial day off where we take the minutes per day off the
      // capacity
      if (!timeOffDays[day.key]) {
        // Full day off
        capacityDay.effectiveCapacity = 0
        capacityDay.isTimeOff = true
        capacityDay.timeOff = capacityValue
      } else {
        // Partial day off
        const timeOffInHours = DurationHelper.minutesToHours(
          timeOffDays[day.key],
        )
        capacityDay.effectiveCapacity = Math.max(
          0, // Don't go below zero
          capacityDay.contractedCapacity - timeOffInHours,
        )
        capacityDay.isTimeOff = true
        capacityDay.timeOff = timeOffInHours
      }
    }

    return capacityDay
  })

  const daysWithCapacity = {}

  capacityArray.forEach((day) => {
    daysWithCapacity[day.key] = day
  })

  return daysWithCapacity
}

// This must calculate the assignments from the role, not the people
export const calcWorkloadData = (
  graphStartDate: Date,
  graphEndDate: Date,
  assignments: Assignment[],
  timeOffs: readonly TimeOffWithPersonId[],
  projects: readonly Project[],
): WorkloadDaysObject => {
  // Get the days in the range as [date]: { date: date, workload: num } format
  const daysObject = rangeIntoWorkloadObject(graphStartDate, graphEndDate)
  const workloadDays = Object.values(daysObject)
  const graphStartDateNumeric = Number(
    dateHelpers.formatToRunnDate(graphStartDate),
  )
  const graphEndDateNumeric = Number(dateHelpers.formatToRunnDate(graphEndDate))

  // Preprocess timeOffs into a Map
  const timeOffMap = new Map()

  for (const timeOff of timeOffs) {
    if (
      // For one day time offs, just use the start date
      timeOff.start_date === timeOff.end_date
    ) {
      const key = `${timeOff.person_id}-${timeOff.start_date}`

      if (
        !timeOffMap.get(key) ||
        timeOff.leave_type === "rostered-off" ||
        timeOff.leave_type === "annual"
      ) {
        timeOffMap.set(key, timeOff)
      }
    } else {
      const start = dateHelpers.parseRunnDate(timeOff.start_date)
      const end = dateHelpers.parseRunnDate(timeOff.end_date)

      const timeOffDays = dateHelpers.eachDayOfInterval(
        { start, end },
        "calcWorkloadData",
      )

      timeOffDays.map((to) => {
        const date = dateHelpers.formatToRunnDate(to)

        const key = `${timeOff.person_id}-${date}`
        timeOffMap.set(key, timeOff)
      })
    }
  }

  const assignmentsDayObject = assignments
    .filter((a) => {
      if (!a) {
        return false
      } // When delete assignments we can get a null value
      // Remove assignments outside calendar range
      if (Number(a.start_date) > graphEndDateNumeric) {
        return false
      }
      if (Number(a.end_date) < graphStartDateNumeric) {
        return false
      }
      // Remove archived projects
      if (!projects.find((p) => p.id === a.project_id)) {
        return false
      }
      return true
    })
    .map((a) => ({
      ...a,
      // Reset the dates if they are longer than the graph. Saves us doing a bunch of work
      // on really long assignments
      start_date:
        Number(a.start_date) < graphStartDateNumeric
          ? graphStartDateNumeric
          : a.start_date,
      end_date:
        Number(a.end_date) > graphEndDateNumeric
          ? graphEndDateNumeric
          : a.end_date,
      confirmedProject: projects.find((p) => p.id === a.project_id).confirmed,
    }))
    .map((a) => assignmentInWorkloadObject(workloadDays, a, timeOffMap))

  // Merges the dates for the graph, with the assignments
  // Only collecting dates that matter, and adding the workload together
  const mergedWorkload = assignmentsDayObject.reduce((acc, a) => {
    // Update each date with whatever is already there plus the new assignments dates
    Object.keys(a).forEach((key) => {
      if (acc[key]) {
        // Only for days in the graph view
        acc[key] = {
          key: key,
          formattedDate: acc[key].formattedDate,
          isWeekend: acc[key].isWeekend,
          workloadBillable: a[key].workloadBillable + acc[key].workloadBillable,
          workloadNonbillable:
            a[key].workloadNonbillable + acc[key].workloadNonbillable,
          workloadConfirmed:
            a[key].workloadConfirmed + acc[key].workloadConfirmed,
          workloadTentative:
            a[key].workloadTentative + acc[key].workloadTentative,
          workloadTotal: a[key].workloadTotal + acc[key].workloadTotal,
        }
      }
    })
    return acc
  }, daysObject)

  return mergedWorkload
}

export const calcCapacityData = (
  graphStartDate: Date,
  graphEndDate: Date,
  people: readonly CalcCapacityPerson[],
): CapacityDaysObject => {
  // Get the days in the range as [date]: { date: date, isWeekend: boolean, capacity: num } format
  const daysObject = rangeIntoCapacityObject(graphStartDate, graphEndDate)
  const capacityDays = Object.values(daysObject)

  const graphStartDateNumeric = Number(
    dateHelpers.formatToRunnDate(graphStartDate),
  )
  const graphEndDateNumeric = Number(dateHelpers.formatToRunnDate(graphEndDate))

  // Change start dates and end dates to avoid extra calculations
  const refactoredPeople = people.map((p) => ({
    ...p,
    time_offs: !p.time_offs
      ? []
      : p.time_offs.filter((to) => {
          // remove timeoffs outside of visible graph
          if (Number(to.start_date) > graphEndDateNumeric) {
            return false
          }
          if (Number(to.end_date) < graphStartDateNumeric) {
            return false
          }
          return true
        }),
    contract: {
      ...p.contract,
      // Reset the dates if they are longer than the graph. Or else were will do very large calculations for perm staff
      // who have a start date of 30 years in the future
      start_date:
        Number(p.contract.start_date) < graphStartDateNumeric
          ? `${graphStartDateNumeric}`
          : p.contract.start_date,
      end_date:
        p.contract.end_date && Number(p.contract.end_date) > graphEndDateNumeric
          ? `${graphEndDateNumeric}`
          : p.contract.end_date,
    },
  }))

  // Get the contract days in the [date]: { date: date, workload: num } format
  const contractsDayObject = refactoredPeople.map((p) =>
    contractsToCapacityObject(capacityDays, p),
  )

  // Merges the dates for the graph, with the contracts
  // Only collecting dates that matter, and adding the workload together
  const mergedCapacity = contractsDayObject.reduce((acc, c) => {
    // Update each date with whatever is already there plus the new contract dates
    Object.keys(c).forEach((key) => {
      if (acc[key]) {
        // Only for days in the graph view
        acc[key] = {
          key: key,
          formattedDate: acc[key].formattedDate,
          isWeekend: c[key].isWeekend,
          isTimeOff: c[key].isTimeOff,
          isContracted: c[key].isContracted,
          effectiveCapacity: c[key].isWeekend
            ? 0
            : c[key].effectiveCapacity + acc[key].effectiveCapacity,
          contractedCapacity: c[key].isWeekend
            ? 0
            : c[key].contractedCapacity + acc[key].contractedCapacity,
          timeOff: c[key].timeOff + acc[key].timeOff,
        }
      }
    })
    return acc
  }, daysObject)

  return mergedCapacity
}

type ContractPerson = {
  id: number
  is_placeholder: boolean
  contract: Contract
  time_offs: readonly TimeOff[]
}

type Assignment = {
  id: number
  start_date: string | number
  end_date: string | number
  minutes_per_day: number
  project_id: number
  confirmedProject?: boolean
  person_id: number
  role_id: number
  is_billable: boolean
  non_working_day: boolean
}

type Contract = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  role_id: number
  standard_charge_out_rate?: number
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day?: number
  leave_type: string
}

type Project = {
  id: number
  confirmed: boolean
}

type TimeOffWithPersonId = {
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
}

type CalcCapacityPerson = {
  id: number
  is_placeholder: boolean
  time_offs: readonly TimeOff[]
  contract: Contract
}

type WorkloadDaysObject = {
  [key: string]: WorkloadObject
}

type WorkloadObject = {
  key: string
  isWeekend: boolean
  isTimeOff: boolean
  formattedDate: string
  workloadBillable: number
  workloadNonbillable: number
  workloadConfirmed: number
  workloadTentative: number
  workloadTotal: number
}

type CapacityDaysObject = {
  [key: string]: CapacityObject
}

type CapacityObject = {
  key: string
  formattedDate: string
  isWeekend: boolean
  isContracted?: boolean
  effectiveCapacity: number
  contractedCapacity: number
  timeOff: number
}

type mergedObject = Array<
  WorkloadObject &
    CapacityObject & {
      timeOffPercentage: number
      availability: number
      utilizationBillable: number
      utilizationNonbillable: number
    }
>
