import React, { useMemo } from "react"
import {
  Area,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { match } from "ts-pattern"

import styles from "~/Planner/ChartPanel/Chart/common.module.css"

import availabilityIcon from "@/images/availability-icon.svg"

import { roundTwoDecimals } from "~/helpers/DurationHelper"

import { ChartTooltipIcon } from "../common/TooltipContent/ChartTooltipIcon"
import TooltipContent from "~/Planner/ChartPanel/Chart/common/TooltipContent"

import { useChartData } from "~/Planner/ChartPanel/Chart/useChartData"
import type { ChartProps } from "~/Planner/ChartPanel/types"
import { useAppSelector } from "~/hooks/redux"

type Payload = {
  workloadConfirmed: number
  workloadTentative: number
  effectiveCapacity: number
  contractedCapacity: number
  timeOff: number
}

const getRoundedValue = (value: number): string => roundTwoDecimals(value) + "h"

const ChartCapacity = (props: ChartProps) => {
  const {
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
    includeTentative,
    enableAnimations,
    formatFTE,
    getTicks,
  } = props

  const barSize = period === "weekly" ? 250 : 25

  const data = useChartData({
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
  })

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const showFTE = effortDisplayUnit === "fullTimeEquivalent"

  const getToolTipIcon = (label: string) => () =>
    match(label)
      .with("Confirmed Workload", () => (
        <ChartTooltipIcon background="var(--runn-blue)" square />
      ))
      .with("Tentative Workload", () => (
        <ChartTooltipIcon background="var(--sky-blue)" square />
      ))
      .with("Effective Capacity", () => (
        <ChartTooltipIcon background="rgba(var(--runn-purple_rgb), 0.4)" />
      ))
      .with("Contracted Capacity", () => (
        <ChartTooltipIcon background="var(--shadow)" />
      ))
      .with("Time Off", () => (
        <ChartTooltipIcon background="var(--chart-stripes-legend)" />
      ))
      .with("Availability", () => <img src={availabilityIcon} />)
      .otherwise(() => <ChartTooltipIcon background="none" />)

  const formatText = (label: string, val: number) => {
    const icon = getToolTipIcon(label)

    if (showFTE) {
      return {
        icon: icon(),
        label,
        value: `${formatFTE(val)} FTE (${getRoundedValue(val)})`,
      }
    }

    return {
      icon: icon(),
      label,
      value: getRoundedValue(val),
    }
  }

  const getTooltipRows = (payload: Payload) => {
    const rows = [
      formatText("Contracted Capacity", payload.contractedCapacity),
      formatText("Time Off", payload.timeOff),
      formatText("Effective Capacity", payload.effectiveCapacity),
      formatText("Confirmed Workload", payload.workloadConfirmed),
      formatText("Tentative Workload", payload.workloadTentative),
      formatText(
        "Availability",
        payload.effectiveCapacity -
          payload.workloadConfirmed -
          payload.workloadTentative,
      ),
    ]

    if (includeTentative !== true) {
      return rows.filter((r) => r.label !== "Tentative Workload")
    }

    return rows
  }

  const [yAxisKey, largestValue] = useMemo(() => {
    // Figure out if workload or effective capacity is larger, then use that for YAxis
    const largestValues = data.reduce(
      (acc, i) => ({
        workloadTotal:
          i.workloadTotal > acc.workloadTotal
            ? i.workloadTotal
            : acc.workloadTotal,
        workloadTentative:
          i.workloadTotal > acc.workloadTotal
            ? i.workloadTotal
            : acc.workloadTotal,
        effectiveCapacity:
          i.effectiveCapacity > acc.effectiveCapacity
            ? i.effectiveCapacity
            : acc.effectiveCapacity,
      }),
      { workloadTotal: 0, effectiveCapacity: 0, workloadTentative: 0 },
    )

    if (largestValues.workloadTotal > largestValues.effectiveCapacity) {
      return ["workloadTotal", largestValues.workloadTotal]
    }
    return ["effectiveCapacity", largestValues.effectiveCapacity]
  }, [data])

  const getYAxisValue = (val: number): string => {
    if (!showFTE) {
      return `${val}h`
    }
    return `${formatFTE(val)} FTE`
  }

  const ticks = useMemo(
    () => getTicks(0, largestValue, showFTE),
    [getTicks, largestValue, showFTE],
  )

  // We create a YAxis separately so that we can move it around
  return (
    <>
      <div className={styles.yAxis}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: -10, bottom: 0 }}
          >
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              dataKey={yAxisKey}
              interval="preserveStartEnd"
              tickFormatter={getYAxisValue}
              ticks={ticks}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.chartContainer}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <pattern
                id="chartStripes"
                patternUnits="userSpaceOnUse"
                width="5"
                height="5"
                patternTransform="rotate(-45)"
              >
                <line
                  x1="0"
                  y="0"
                  x2="0"
                  y2="5"
                  stroke="rgba(var(--shadow_rgb), 0.4)"
                  strokeWidth="2"
                />
              </pattern>
            </defs>
            <Tooltip
              content={
                <TooltipContent
                  data={data}
                  getRows={getTooltipRows}
                  period={period}
                />
              }
              isAnimationActive={false}
              offset={10}
            />
            <XAxis dataKey="formattedDate" hide />
            {/* Y Axis */}
            <YAxis type="number" ticks={ticks} hide />

            {/* Chart */}
            <Area
              type="monotone"
              dataKey="contractedCapacity"
              fill="none"
              stroke="var(--shadow)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
              activeDot={false}
            />
            {/* This allows us to stack the timeoff stripes on top of the capacity area without showing underneath */}
            <Area
              type="monotone"
              dataKey="effectiveCapacity"
              fill="none"
              stroke="none"
              stackId="1"
            />
            <Area
              type="monotone"
              dataKey="timeOff"
              fill="url(#chartStripes)"
              activeDot={false}
              stackId="1"
              stroke="none"
            />
            <Bar
              dataKey="workloadConfirmed"
              barSize={barSize}
              stackId="a"
              fill="var(--runn-blue)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            />
            <Bar
              dataKey="workloadTentative"
              barSize={barSize}
              stackId="a"
              fill="var(--sky-blue)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            />
            {/* This is to make sure the dot is in front of the bars */}
            <Area
              type="monotone"
              dataKey="contractedCapacity"
              fill="none"
              stroke="none"
              activeDot={{ stroke: "white", fill: "var(--shadow)" }}
            />
            <Area
              type="monotone"
              dataKey="effectiveCapacity"
              fill="rgba(var(--runn-purple_rgb), 0.4)"
              stroke="var(--runn-purple)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export { ChartCapacity }
