/**
 * @generated SignedSource<<7c1a4cdc70c84130756f364564450b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSummaryGraph_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CapacityIndicator_account">;
  readonly " $fragmentType": "PersonSummaryGraph_account";
};
export type PersonSummaryGraph_account$key = {
  readonly " $data"?: PersonSummaryGraph_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSummaryGraph_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSummaryGraph_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CapacityIndicator_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "3943b131c3f710f8d82a983997efe308";

export default node;
