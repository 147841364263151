import { dateHelpers } from "@runn/calculations"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./BasicPill.module.css"

import { Overbookings_person$key } from "./__generated__/Overbookings_person.graphql"
import { Overbookings_user$key } from "./__generated__/Overbookings_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getDaysInRange } from "~/helpers/CalendarHelper"
import { getOverbookings } from "~/helpers/graph-helpers/assignmentHelpers"
import { getMergedHolidays } from "~/helpers/holiday-helpers"

import * as actionHelpers from "~/common/Pill/action_helpers"

import { withPlannerQuery } from "~/Planner/PlannerContainer"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  assignment: Assignment
  person: Overbookings_person$key
  calendarStartDate: Date
  calendarEndDate: Date
  visibleDays: number
  unconfirmed: boolean
  calendarWeekendsExpanded: boolean
}

const getOverbookingOffset = (
  overbooking: Overbooking,
  assignment: Assignment,
  calendarStartDate,
  visibleDays,
  calendarWeekendsExpanded: boolean,
) => {
  // Start date of the pill is different depending on if the assignment starts before or after calendar startdate
  const startDate = actionHelpers.getLatestDate(
    assignment.start_date,
    dateHelpers.formatToRunnDate(calendarStartDate),
  )

  const pillDayIndex =
    getDaysInRange({
      start: startDate,
      end: overbooking.date,
      includeWeekends: calendarWeekendsExpanded,
    }) - 1

  return `${(pillDayIndex / visibleDays) * 100}%`
}

const Overbookings = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment Overbookings_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        id
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          person_id
          role_id
          project_id
          non_working_day
          is_template
          workstream_id
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          leave_type
          ...ExtLinks_TimeOff @relay(mask: false)
        }
      }
    `,
    props.person,
  )

  const user = useFragment<Overbookings_user$key>(
    graphql`
      fragment Overbookings_user on users
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        account {
          projects(where: $projectsFilter) {
            id
            confirmed
          }
        }
      }
    `,
    useHasuraContext(),
  )

  const {
    calendarStartDate,
    calendarEndDate,
    visibleDays,
    calendarWeekendsExpanded,
  } = props

  const calendarStartDateString =
    dateHelpers.formatToRunnDate(calendarStartDate)
  const calendarEndDateString = dateHelpers.formatToRunnDate(calendarEndDate)

  // Shorten assignment length to match calendar
  const assignment = {
    ...props.assignment,
    start_date:
      props.assignment.start_date > calendarStartDateString
        ? props.assignment.start_date
        : calendarStartDateString,
    end_date:
      props.assignment.end_date < calendarEndDateString
        ? props.assignment.end_date
        : calendarEndDateString,
  }
  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const projects = user.account.projects
  const publicHolidaysDates = getMergedHolidays(person.time_offs).map(
    (to) => to.start_date,
  )

  const enabledProjectIds = projects
    .filter(
      (p) =>
        p.confirmed ||
        isTentativeProjectEnabled(enabledTentativeProjects, p.id),
    )
    .map((p) => p.id)

  // Remove and shorten assignments
  const assignments = person.assignments
    .filter((a) => !a.is_template && enabledProjectIds.includes(a.project_id))
    .filter(
      (
        a, // remove all assignments that fall outside range
      ) =>
        !(
          a.end_date < calendarStartDateString ||
          a.start_date > calendarEndDateString
        ),
    )
    .map((a) => ({
      // change start/end dates to match the calendar
      ...a,
      start_date:
        a.start_date > calendarStartDateString
          ? a.start_date
          : calendarStartDateString,
      end_date:
        a.end_date < calendarEndDateString ? a.end_date : calendarEndDateString,
    }))

  const overbookingDayWidth = 100.0 / visibleDays
  const allOverbookings =
    assignment.is_placeholder || assignment.is_template
      ? []
      : getOverbookings(assignment, person, assignments)

  const overbookings = allOverbookings.filter((i) => {
    // Remove overbooking dates that aren't on the calendar
    if (i.date < calendarStartDateString) {
      return false
    }
    if (i.date > calendarEndDateString) {
      return false
    }
    // Exclude public holidays from overbooking
    if (publicHolidaysDates.includes(i.date)) {
      return false
    }
    return true
  })

  if (!assignment.is_template && assignment.non_working_day) {
    return <div className={styles.overbooking} style={{ width: "100%" }} />
  }

  return (
    <>
      {overbookings.map((overbooking) => {
        const overbookingOffset = getOverbookingOffset(
          overbooking,
          assignment,
          calendarStartDate,
          visibleDays,
          calendarWeekendsExpanded,
        )
        return (
          <div
            key={overbooking.date}
            className={styles.overbooking}
            style={{
              width: `${overbookingDayWidth}%`,
              left: overbookingOffset,
            }}
          />
        )
      })}
    </>
  )
}

type Assignment = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_placeholder: boolean
  is_template: boolean
  project_id: number
  role_id: number
  non_working_day: boolean
  workstream_id: number
}

type Overbooking = {
  date: string
}

export default withPlannerQuery(Overbookings)
