import cc from "classcat"
import React from "react"

import styles from "./Checkbox.module.css"

import { Tick } from "~/common/react-icons"

type Props = React.HTMLProps<HTMLInputElement>

const Checkbox = (props: Props) => {
  const { className, ...rest } = props
  return (
    <div className={cc([className, styles.checkbox])}>
      <input
        data-component="Checkbox"
        type="checkbox"
        className={styles.checkboxInput}
        {...rest}
      />
      <Tick className={styles.checkboxTick} />
    </div>
  )
}

export default Checkbox
