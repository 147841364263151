import { Tooltip } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./CreateBulkPeopleForm.module.css"

import { CreateBulkPeopleForm_user$key } from "~/forms/CreateBulkPeople/__generated__/CreateBulkPeopleForm_user.graphql"

import { track } from "~/helpers/analytics"
import { DEFAULT_ROLE_TOOLTIP } from "~/helpers/role-helpers"

import Dialog from "~/common/Dialog"
import Input from "~/common/Input"
import InputLabel from "~/common/InputLabel"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import Select from "~/common/Select"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import AddButton from "~/common/buttons/AddButton"
import Button from "~/common/buttons/Button"
import InputEmail from "~/common/inputs/InputEmail"
import { Delete } from "~/common/react-icons"

import { personBulkCreateRelay } from "~/mutations/Person"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementQuantity,
  useIsInFreePlan,
} from "~/Entitlements/useEntitlements"
import { PLANNER_INITIAL_DATE, SUBSCRIPTIONS } from "~/GLOBALVARS"
import OnboardingStep from "~/Onboarding/OnboardingStep"
import { usePermissions } from "~/Permissions/usePermissions"

import UpgradePlanModal from "../CreatePerson/UpgradePlanModal"

type Props = {
  closeDialog: () => void
  onSuccess?: () => void
  onSubmit?: () => void
  user: CreateBulkPeopleForm_user$key
  initialPerson?: Person
  saveText?: string
  isOnboarding?: boolean
  totalSteps?: number
}
type Person = {
  firstName: string
  lastName: string
  role?: Option
  email?: string
  managerIds: number[]
}

type Option = {
  label: string
  value: number
}

const isEmptyPerson = (person: Person) =>
  !person.firstName && !person.lastName && !person.role?.value

const CreateBulkPeopleForm = (props: Props) => {
  const {
    closeDialog,
    saveText = "Create",
    initialPerson,
    onSubmit,
    onSuccess,
    isOnboarding = false,
    totalSteps,
  } = props

  const data = useFragment(
    graphql`
      fragment CreateBulkPeopleForm_user on users {
        id
        permissions
        account {
          id
          roles {
            id
            name
            active
          }
          people_aggregate(
            where: { active: { _eq: true }, is_placeholder: { _eq: false } }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    `,
    props.user,
  )
  const {
    id: userId,
    permissions,
    account: {
      roles,
      people_aggregate: {
        aggregate: { count: existingPeopleCount },
      },
    },
  } = data

  const existingRoles = roles
    .filter((r) => r.active)
    .map((x) => ({ label: x.name, value: x.id }))
  const emptyPerson: Person = {
    firstName: "",
    lastName: "",
    role: null,
    email: "",
    managerIds: permissions.manage_people === "restricted" ? [userId] : [],
  }

  const [people, setPeople] = useState(
    initialPerson
      ? [
          { ...initialPerson, autoFocus: false },
          { ...emptyPerson, autoFocus: false },
        ]
      : [{ ...emptyPerson, autoFocus: false }],
  )

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false)
  const { isAdmin } = usePermissions()
  const isFreePlan = useIsInFreePlan()
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const peopleEntitlement = useEntitlementQuantity(ChargebeeFeatures.people)
  const peopleLimit = entitlementsEnabled
    ? peopleEntitlement
    : SUBSCRIPTIONS.free_plan_limit

  const rowLimit = isFreePlan ? peopleLimit - existingPeopleCount : Infinity
  const nonEmptyPeople = people.filter((p) => !isEmptyPerson(p))
  const isLimited = nonEmptyPeople.length >= rowLimit

  const [isCreating, setIsCreating] = useState(false)
  const [emailInvalid, setEmailIsInvalid] = useState(false)
  const [emailValidations, setEmailValidations] = useState([])

  const roleOptions = people
    .filter((p) => !!p.role) // we return null role if we empty the select
    .map((p) => p.role)
    .concat(existingRoles)

  const handleInputChange = (e) => {
    const updatedPeople = [...people]
    updatedPeople[e.target.dataset.idx][e.target.id] = e.target.value
    setPeople(updatedPeople)
  }

  const addItemToList = (autoFocus = false) => {
    setPeople([...people, { autoFocus, ...emptyPerson }])
  }

  const removeItemFromList = (idx) => {
    setPeople(people.filter((p, personindex) => personindex !== idx))
  }

  const handleBlur = (e) => {
    if (
      e?.relatedTarget?.id !== "create-bulk-people" &&
      e.target.value.length &&
      !isEmptyPerson(people[people.length - 1]) &&
      !isLimited
    ) {
      return addItemToList()
    }
  }

  const handleRoleChange = (e, idx) => {
    const updatedPeople = [...people]
    updatedPeople[idx].role = e

    setPeople(updatedPeople)

    if (e?.value && !isEmptyPerson(people[people.length - 1]) && !isLimited) {
      addItemToList()
    }
  }

  const handleEmailValidation = (val: boolean, idx) => {
    const updateValidations = [...emailValidations]
    updateValidations[idx] = val
    setEmailValidations(updateValidations)

    const anyInvalidEmails = updateValidations.some((v) => v === true)
    setEmailIsInvalid(anyInvalidEmails)
  }

  const emails = people.map(({ email }) => email.trim().toLowerCase())
  const duplicateEmails = emails.filter(
    (email: string, index: number) =>
      email !== "" && emails.indexOf(email.trim().toLowerCase()) !== index,
  )

  const PeopleInputs = (idx: number) => {
    const isDuplicate = duplicateEmails.includes(
      people[idx].email.trim().toLowerCase(),
    )

    return (
      <div key={`people-${idx}`} className={styles.tableRow}>
        <Input
          name={`person-first-name-${idx}`}
          data-idx={idx}
          disabled={idx + 1 > rowLimit}
          id="firstName"
          value={people[idx].firstName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus={idx === 0}
          className="fs-exclude"
        />
        <Input
          name={`person-last-name-${idx}`}
          data-idx={idx}
          disabled={idx + 1 > rowLimit}
          id="lastName"
          value={people[idx].lastName}
          onBlur={handleBlur}
          onChange={handleInputChange}
          className="fs-exclude"
        />
        <Select
          isCreatable
          name={`person-role-name-${idx}`}
          id={`roleName-${idx}`}
          isDisabled={idx + 1 > rowLimit}
          value={people[idx].role}
          onChange={(e) => handleRoleChange(e, idx)}
          options={roleOptions}
          placeholder="Select role or type to create new"
          noOptionsMessage={() => (
            <div>
              Type to create role <br />
              E.g. Senior Designer, CEO
            </div>
          )}
        />
        <div>
          <InputEmail
            name={`person-email-${idx}`}
            data-idx={idx}
            id="email"
            disabled={idx + 1 > rowLimit}
            value={people[idx].email}
            onChange={handleInputChange}
            handleValidation={(val) => handleEmailValidation(val, idx)}
            className="fs-exclude"
            isDuplicate={isDuplicate}
          />
          {isDuplicate && (
            <InputLabel
              label=""
              error
              customErrorMessage="Email already exists"
              htmlFor="email"
            />
          )}
        </div>

        <div
          onClick={people.length > 1 ? () => removeItemFromList(idx) : null}
          className={`${styles.delete} ${people.length === 1 && styles.disabled}`}
        >
          <Delete color="var(--winter)" />
        </div>
      </div>
    )
  }

  const handleOnCreate = async () => {
    setIsCreating(true)
    onSubmit && onSubmit()
    const newPeople = people
      .filter((p) => p.firstName && p.lastName && p.role.label)
      .map((p) => ({
        first_name: p.firstName,
        last_name: p.lastName,
        role_name: p.role.label,
        email: p.email ? p.email.toLowerCase().trim() : null,
        manager_ids: p.managerIds,
      }))

    try {
      await personBulkCreateRelay({
        variables: {
          input: { people: newPeople },
          plannerStartDate: PLANNER_INITIAL_DATE,
        },
      })

      // Track event for each person to stay consistent with single create/update
      newPeople.map((person) => {
        track("Person Created", {
          role: person.role_name,
          is_onboarding: isOnboarding,
        })
      })

      window.userflow?.track("Person Create")

      onSuccess ? onSuccess() : closeDialog()
    } catch {
      setIsCreating(false)
    }
  }

  const fullyCompletedRows = people.filter(
    (p) => p.firstName.trim() && p.lastName.trim() && p.role && p.role.label,
  ).length
  const hasHalfCompletedRows =
    fullyCompletedRows !==
    people.filter(
      (p) =>
        p.firstName.trim() || p.lastName.trim() || (p.role && p.role.label),
    ).length

  const disableCreateButton =
    isCreating ||
    fullyCompletedRows < 1 ||
    hasHalfCompletedRows ||
    emailInvalid ||
    Boolean(duplicateEmails.length)

  return (
    <>
      <ModalBody wide>
        <div className={styles.wrapper}>
          <div className={`${styles.tableRow} ${styles.tableHeaderRow}`}>
            <div>First Name</div>
            <div>Last Name</div>
            <div>
              <TitledHelpTooltip
                title="Default Role"
                tooltipContent={DEFAULT_ROLE_TOOLTIP}
              />
            </div>
            <div>Email (optional)</div>
            <div>{/* leave empty for delete */}</div>
          </div>
          {people.map((val, idx) => PeopleInputs(idx))}
        </div>
        <div className={styles.footer}>
          <AddButton
            data-test="bulk-create-add-person"
            text="New Person"
            onClick={() => addItemToList(true)}
            disabled={isLimited}
          />
          {isLimited ? (
            <p>
              <b>
                <a
                  onClick={() => {
                    setOpenUpgradeDialog(true)
                  }}
                >
                  Start a 30 day free trial
                </a>{" "}
                for unlimited people.
              </b>{" "}
              No credit card required
            </p>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        {isOnboarding ? (
          <OnboardingStep step={2} totalSteps={totalSteps} />
        ) : (
          <Button text="Cancel" onClick={() => closeDialog()} />
        )}
        <Tooltip
          content="Everyone must have a First Name, Last Name and Role"
          autoFocus={false}
          disabled={!hasHalfCompletedRows}
        >
          <Button
            id="create-bulk-people"
            text={saveText}
            loading={isCreating}
            outlined={false}
            onClick={handleOnCreate}
            disabled={disableCreateButton}
            forceAnchorButton={disableCreateButton}
            style={{ minWidth: 100 }}
            className={`${isCreating ? "pulse" : ""} ${
              isOnboarding && !disableCreateButton
                ? "userflow-onboarding-people-button"
                : ""
            }`}
          />
        </Tooltip>
      </ModalFooter>
      <Dialog isOpen={openUpgradeDialog}>
        <UpgradePlanModal
          isAdmin={isAdmin}
          closeDialog={() => setOpenUpgradeDialog(false)}
          onSuccess={() => setOpenUpgradeDialog(false)}
          intent="ResourceLimit"
        />
      </Dialog>
    </>
  )
}

export default CreateBulkPeopleForm
