import React, { useRef } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectDetailsPersonRow.module.css"

import { ProjectDetailsDetectedPersonRow_account$key } from "./__generated__/ProjectDetailsDetectedPersonRow_account.graphql"
import { ProjectDetailsDetectedPersonRow_extPerson$key } from "./__generated__/ProjectDetailsDetectedPersonRow_extPerson.graphql"
import { ProjectDetailsDetectedPersonRow_project$key } from "./__generated__/ProjectDetailsDetectedPersonRow_project.graphql"
import { ProjectDetailsDetectedPersonRow_user$key } from "./__generated__/ProjectDetailsDetectedPersonRow_user.graphql"

import { valuesMap } from "~/helpers/custom-field-helpers"
import { dashify } from "~/helpers/general-helpers"
import { formatName, formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"
import { SkeletonRow } from "~/common/LoadingSkeleton"
import CalendarOutline from "~/common/calendar/CalendarOutline"

import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn } from "~/Planner/PlannerLayout"
import { PlannerRightColumn } from "~/Planner/PlannerLayout"
import { useIsInViewport } from "~/hooks/useIsInViewport"

import PersonNameDisplay from "./PersonNameDisplay"

type Props = {
  user: ProjectDetailsDetectedPersonRow_user$key
  account: ProjectDetailsDetectedPersonRow_account$key
  project: ProjectDetailsDetectedPersonRow_project$key
  extPerson: ProjectDetailsDetectedPersonRow_extPerson$key
}

const ProjectDetailsDetectedPersonRow = (props: Props) => {
  const rowRef = useRef()

  const isInViewport = useIsInViewport(rowRef, {
    delay: 15000,
    keepVisible: false,
    offsetY: 1000,
    rootQuerySelector: "#project-planner-list",
  })

  const user = useFragment(
    graphql`
      fragment ProjectDetailsDetectedPersonRow_user on users {
        id
        ...PlannerLeftColumn_user
      }
    `,
    props.user,
  )

  const account = useFragment(
    graphql`
      fragment ProjectDetailsDetectedPersonRow_account on accounts {
        id
        ...PlannerLeftColumn_account
        ...PlannerCalendarRow_account
      }
    `,
    props.account,
  )

  const project = useFragment(
    graphql`
      fragment ProjectDetailsDetectedPersonRow_project on projects {
        id
        name
        ...PlannerLeftColumn_project
      }
    `,
    props.project,
  )

  const extPerson = useFragment(
    graphql`
      fragment ProjectDetailsDetectedPersonRow_extPerson on ext_person {
        id
        first_name
        last_name
        email
        is_placeholder
        active
      }
    `,
    props.extPerson,
  )

  const personName = formatName(extPerson.first_name, extPerson.last_name)
  const personInitials = formatNameAsInitials(
    extPerson.first_name,
    extPerson.last_name,
  )

  const projectId = project.id

  const testId = dashify(`detected-ext-person-${project.name}-${personName}`)

  return (
    <PlannerGrid data-test={testId} ref={rowRef} disabled={false}>
      {isInViewport ? (
        <>
          <PlannerLeftColumn
            type="external-person"
            project={project}
            user={user}
            account={account}
            showMenuDots={true}
            className={styles.detectedPersonRow}
            data-test={`planner-left-column-${dashify(personName)}`}
            projectId={projectId}
            extPersonId={extPerson.id}
          >
            <Avatar
              email={extPerson.email}
              initials={personInitials}
              is_placeholder={extPerson.is_placeholder}
              isInactivePerson={true}
            />
            <span className={styles.personNameWrapper}>
              <PersonNameDisplay
                person={{
                  ...extPerson,
                  tags: [],
                  competencies: [],
                  links: [],
                }}
                isInactivePerson={true}
                showRequestIcon={false}
                isPlaceholderInTemplate={false}
                customTypes={[]}
                customValues={valuesMap({
                  custom_date_values: [],
                  custom_text_values: [],
                  custom_select_values: [],
                  custom_checkbox_values: [],
                })}
              />
            </span>
          </PlannerLeftColumn>

          <PlannerRightColumn
            rowId={String(extPerson.id)}
            isUnconfirmedProject={true}
            isPlaceholder={extPerson.is_placeholder}
          >
            <CalendarOutline type="standard" />
          </PlannerRightColumn>
        </>
      ) : (
        <SkeletonRow height={44} showCalenderGrid />
      )}
    </PlannerGrid>
  )
}

export default React.memo(ProjectDetailsDetectedPersonRow)
