import React, { ReactNode } from "react"
import { connect } from "react-redux"

import { FinancialType, UserType } from "~/helpers/permissions"

type Props = {
  children: ReactNode
  permissions: {
    financial?: FinancialType
    type?: UserType
  }
  financial?: FinancialType[]
  types?: UserType[]
}

export const Permission = (props: Props) => {
  const financialMatch =
    props.financial && props.permissions.financial
      ? props.financial.includes(props.permissions.financial)
      : true
  const userTypeMatch =
    props.types && props.permissions.type
      ? props.types.includes(props.permissions.type)
      : true

  return <>{financialMatch && userTypeMatch ? props.children : <></>}</>
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
})

const connector = connect(mapStateToProps)
const ConnectPermissions = connector(Permission)

export default ConnectPermissions
