import { Icon, IconName } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"
import { match } from "ts-pattern"

import styles from "./SmallAlert.module.css"

type Props = {
  children: React.ReactNode
  intent: "info" | "success" | "warning"
  className?: string
}

const SmallAlert = (props: Props) => {
  const { children, intent, className } = props

  const icon = match(intent)
    .with("info", () => "info-sign")
    .with("success", () => "tick-circle")
    .with("warning", () => "warning-sign")
    .otherwise(() => "")

  return (
    <div className={cc([styles.container, styles[intent], className])}>
      <Icon
        icon={icon as IconName}
        color={`var(--${intent})`}
        style={{ marginRight: "var(--spacing-xs)" }}
      />
      {children}
    </div>
  )
}

export default SmallAlert
