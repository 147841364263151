/**
 * @generated SignedSource<<6259e2e0569682b3472005860606a142>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useManagers_account$data = {
  readonly id: number;
  readonly users: ReadonlyArray<{
    readonly first_name: string | null | undefined;
    readonly id: number;
    readonly last_name: string | null | undefined;
    readonly permissions: any;
  }>;
  readonly " $fragmentType": "useManagers_account";
};
export type useManagers_account$key = {
  readonly " $data"?: useManagers_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useManagers_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useManagers_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "cfa21f5b47a33ead304e170d7ed433ff";

export default node;
