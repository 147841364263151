/**
 * @generated SignedSource<<dd2850a929cb4ae22ce56e4b74afe531>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useClients_account$data = {
  readonly clients: ReadonlyArray<{
    readonly active: boolean;
    readonly id: number;
    readonly name: string;
    readonly real_client: boolean | null | undefined;
  }>;
  readonly " $fragmentType": "useClients_account";
};
export type useClients_account$key = {
  readonly " $data"?: useClients_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useClients_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useClients_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "clients",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "real_client",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "da24b8f1bc589606b8a2a235c60cb7e3";

export default node;
