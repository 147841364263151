import { useContext } from "react"
import { useSelector } from "react-redux"

import { LimitedQueryContext } from "~/Planner/LimitedQuery/LimitedQueryContext"
import { useActivePlannerPage } from "~/Planner/useActivePlannerPage"

export const useQueryLimit = () => {
  const limit = useSelector((state: { plannerLimit }) => state.plannerLimit)
  const overflowStates = useContext(LimitedQueryContext)

  return {
    limit,
    ...overflowStates,
  }
}

export const useActiveQueryLimit = () => {
  const page = useActivePlannerPage()
  const state = useQueryLimit()

  return state[page]
}
