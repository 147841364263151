/**
 * @generated SignedSource<<a3417b7a4a685412c891ba9d68f9ca3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RoleSubscription$variables = Record<PropertyKey, never>;
export type RoleSubscription$data = {
  readonly roles: ReadonlyArray<{
    readonly account_id: number;
    readonly active: boolean;
    readonly default_hour_cost: number | null | undefined;
    readonly id: number;
    readonly name: string | null | undefined;
    readonly references: any;
  }>;
};
export type RoleSubscription = {
  response: RoleSubscription$data;
  variables: RoleSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "updated_at": "desc"
        }
      }
    ],
    "concreteType": "roles",
    "kind": "LinkedField",
    "name": "roles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": "default_hour_cost",
        "args": null,
        "kind": "ScalarField",
        "name": "default_hour_cost_private",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "references",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "account_id",
        "storageKey": null
      }
    ],
    "storageKey": "roles(limit:1,order_by:{\"updated_at\":\"desc\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoleSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "dbdf37f0b062846ee0a063836bb3bcbd",
    "id": null,
    "metadata": {},
    "name": "RoleSubscription",
    "operationKind": "subscription",
    "text": "subscription RoleSubscription {\n  roles(limit: 1, order_by: {updated_at: desc}) {\n    id\n    name\n    active\n    default_hour_cost: default_hour_cost_private\n    references\n    account_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "95a963b66e369634396c43be32327fb0";

export default node;
