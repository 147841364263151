import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./Onboarding.module.css"

import { OnboardingPeople_user$key } from "~/Onboarding/__generated__/OnboardingPeople_user.graphql"

import { ModalFormWrapper } from "~/common/ModalForm"

import CreateBulkPeopleForm from "~/forms/CreateBulkPeople/CreateBulkPeopleForm"
import PersonRunningTangram from "~/forms/CreatePerson/PersonRunningTangram"

type Props = {
  nextStep: () => void
  closeDialog: () => void
  currentUser: { first_name: string; last_name: string; email: string }
  user: OnboardingPeople_user$key
  totalSteps: number
}

const OnboardingPeople = (props: Props) => {
  const { closeDialog, nextStep, currentUser, totalSteps } = props

  const user = useFragment(
    graphql`
      fragment OnboardingPeople_user on users {
        account {
          id
        }

        ...CreateBulkPeopleForm_user
      }
    `,
    props.user,
  )

  const [activateAnimation, setActivateAnimation] = useState(false)
  const onSubmit = () => setActivateAnimation(true)

  const initialPerson = {
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
    email: currentUser.email,
    managerIds: [],
  }

  return (
    <ModalFormWrapper
      wide
      tangram={<PersonRunningTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <div className={`${styles.onboardingPeople} userflow-onboarding-people`}>
        <div className={styles.onboardingTextContainer}>
          <div className={styles.heading}>Nice! Now let's add some people!</div>
          <div className={styles.subheading}>
            These are the people you plan to schedule on projects.
          </div>
        </div>
        <CreateBulkPeopleForm
          onSubmit={onSubmit}
          onSuccess={totalSteps === 2 ? closeDialog : nextStep}
          initialPerson={initialPerson}
          saveText={totalSteps === 2 ? "Done" : "Next"}
          closeDialog={closeDialog}
          isOnboarding={true}
          user={user}
          totalSteps={totalSteps}
        />
      </div>
    </ModalFormWrapper>
  )
}

export default OnboardingPeople
