/**
 * @generated SignedSource<<df60cd66b32037043edc915798c35512>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitScreenPanel_user$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly people: ReadonlyArray<{
      readonly active: boolean;
      readonly assignments: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly is_billable: boolean | null | undefined;
        readonly minutes_per_day: number;
        readonly non_working_day: boolean;
        readonly note: string | null | undefined;
        readonly person_id: number;
        readonly phase_id: number | null | undefined;
        readonly project_id: number;
        readonly role_id: number;
        readonly start_date: string | null | undefined;
      }>;
      readonly competencies: ReadonlyArray<{
        readonly id: number;
        readonly level: number | null | undefined;
        readonly skill: {
          readonly id: number;
          readonly name: string;
        };
      }>;
      readonly contracts: ReadonlyArray<{
        readonly cost: number | null | undefined;
        readonly employment_type: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly job_title: string | null | undefined;
        readonly minutes_per_day: number | null | undefined;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly rostered_days: ReadonlyArray<number> | null | undefined;
        readonly start_date: string | null | undefined;
      }>;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly is_placeholder: boolean;
      readonly last_name: string;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly references: any;
      readonly tags: any;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
      readonly time_offs: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly ext_time_off_links: ReadonlyArray<{
          readonly active: boolean;
          readonly ext_time_off_id: number;
          readonly external: {
            readonly __typename: "ext_time_off";
            readonly id: number;
            readonly integration_id: number;
          } | null | undefined;
          readonly external_id: number;
          readonly time_off_id: number;
        }>;
        readonly id: number;
        readonly leave_type: string;
        readonly minutes_per_day: number | null | undefined;
        readonly person_id: number;
        readonly start_date: string | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"SplitScreenRow_person">;
    }>;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly client: {
        readonly id: number;
        readonly name: string;
      };
      readonly confirmed: boolean;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly id: number;
      readonly is_template: boolean;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly person_id: number;
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly name: string;
      readonly tags_computed: any | null | undefined;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"PlaceholderCapacityIndicator_projects">;
    }>;
    readonly views: ReadonlyArray<{
      readonly id: number;
      readonly people_filters: any;
    }>;
  };
  readonly favourite_people: any;
  readonly view: {
    readonly id: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SplitScreenMain_user">;
  readonly " $fragmentType": "SplitScreenPanel_user";
};
export type SplitScreenPanel_user$key = {
  readonly " $data"?: SplitScreenPanel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitScreenPanel_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_placeholder",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_select_values",
  "kind": "LinkedField",
  "name": "custom_select_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_option_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_date_values",
  "kind": "LinkedField",
  "name": "custom_date_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_date_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_text_values",
  "kind": "LinkedField",
  "name": "custom_text_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_text_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_checkbox_values",
  "kind": "LinkedField",
  "name": "custom_checkbox_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_checkbox_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user_id",
    "storageKey": null
  }
],
v14 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v15 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v16 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitScreenPanel_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitScreenMain_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlaceholderCapacityIndicator_projects"
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "teams",
              "kind": "LinkedField",
              "name": "team",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "members",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workstream_id",
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v13/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "people_filters",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "plannerStartDate",
                  "variableName": "plannerStartDate"
                }
              ],
              "kind": "FragmentSpread",
              "name": "SplitScreenRow_person"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "references",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image_key",
              "storageKey": null
            },
            (v7/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "teams",
              "kind": "LinkedField",
              "name": "team",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v14/*: any*/),
              "concreteType": "time_offs",
              "kind": "LinkedField",
              "name": "time_offs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "leave_type",
                  "storageKey": null
                },
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "active": {
                          "_eq": true
                        }
                      }
                    }
                  ],
                  "concreteType": "ext_time_off_link",
                  "kind": "LinkedField",
                  "name": "ext_time_off_links",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time_off_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ext_time_off_id",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": "external_id",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ext_time_off_id",
                      "storageKey": null
                    },
                    {
                      "alias": "external",
                      "args": null,
                      "concreteType": "ext_time_off",
                      "kind": "LinkedField",
                      "name": "ext_time_off",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "integration_id",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v14/*: any*/),
              "concreteType": "assignments",
              "kind": "LinkedField",
              "name": "assignments",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "is_billable",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "non_working_day",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "note",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phase_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "contracts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rostered_days",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employment_type",
                  "storageKey": null
                },
                (v17/*: any*/),
                {
                  "alias": "cost",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost_private",
                  "storageKey": null
                },
                (v15/*: any*/),
                (v16/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "job_title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "roles",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "competencies",
              "kind": "LinkedField",
              "name": "competencies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "skills",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "people_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v13/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "5c87e4b704cc13f5d9e48926416a50fd";

export default node;
