import cc from "classcat"
import React from "react"

import styles from "./AddSection.module.css"

import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import { useAppSelector } from "~/hooks/redux"

import CalendarOutline from "./calendar/CalendarOutline"

type Props = {
  className?: string
  children?: React.ReactNode
}

export const AddSection = (props: Props) => {
  const { className, children } = props

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const disabled = isSplitScreenMode(modeAction)

  return (
    <PlannerGrid gridRow100 disabled={disabled}>
      <div className={cc([styles.children, className])}>{children}</div>
      <div className={styles.outlineParent}>
        <div className={styles.outline}>
          <CalendarOutline type="standard" />
        </div>
      </div>
    </PlannerGrid>
  )
}

export default AddSection
