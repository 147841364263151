/**
 * @generated SignedSource<<9641300537d223d7aff481b3fd5650c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PlannerChangeDateMutation$variables = {
  fromDate?: any | null | undefined;
  toDate?: any | null | undefined;
};
export type PlannerChangeDateMutation$data = {
  readonly action_planner_change_date: {
    readonly account: {
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly assignments: ReadonlyArray<{
          readonly end_date: string | null | undefined;
          readonly id: number;
          readonly is_billable: boolean | null | undefined;
          readonly is_template: boolean;
          readonly minutes_per_day: number;
          readonly non_working_day: boolean;
          readonly note: string | null | undefined;
          readonly person_id: number;
          readonly phase_id: number | null | undefined;
          readonly project_id: number;
          readonly role_id: number;
          readonly start_date: string | null | undefined;
          readonly total_minutes: number | null | undefined;
          readonly updated_at: string;
          readonly workstream_id: number | null | undefined;
        }>;
        readonly id: number;
        readonly time_offs: ReadonlyArray<{
          readonly end_date: string | null | undefined;
          readonly ext_time_off_links: ReadonlyArray<{
            readonly active: boolean;
            readonly ext_time_off_id: number;
            readonly external: {
              readonly __typename: "ext_time_off";
              readonly id: number;
              readonly integration_id: number;
            } | null | undefined;
            readonly external_id: number;
            readonly time_off_id: number;
          }>;
          readonly id: number;
          readonly leave_type: string;
          readonly minutes_per_day: number | null | undefined;
          readonly person_id: number;
          readonly start_date: string | null | undefined;
        }>;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type PlannerChangeDateMutation = {
  response: PlannerChangeDateMutation$data;
  variables: PlannerChangeDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fromDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "toDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "fromDate"
          },
          {
            "kind": "Variable",
            "name": "_lte",
            "variableName": "toDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v4 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v5 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlannerChangeDateOutput",
    "kind": "LinkedField",
    "name": "action_planner_change_date",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "assignments",
                "kind": "LinkedField",
                "name": "assignments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "project_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phase_id",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total_minutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "is_billable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "is_template",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "non_working_day",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "workstream_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "time_offs",
                "kind": "LinkedField",
                "name": "time_offs",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "leave_type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "active": {
                            "_eq": true
                          }
                        }
                      }
                    ],
                    "concreteType": "ext_time_off_link",
                    "kind": "LinkedField",
                    "name": "ext_time_off_links",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time_off_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ext_time_off_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": "external_id",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ext_time_off_id",
                        "storageKey": null
                      },
                      {
                        "alias": "external",
                        "args": null,
                        "concreteType": "ext_time_off",
                        "kind": "LinkedField",
                        "name": "ext_time_off",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "integration_id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlannerChangeDateMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlannerChangeDateMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "65bf7b19d9fbf255da1a50eba909de62",
    "id": null,
    "metadata": {},
    "name": "PlannerChangeDateMutation",
    "operationKind": "mutation",
    "text": "mutation PlannerChangeDateMutation(\n  $fromDate: date\n  $toDate: date\n) {\n  action_planner_change_date {\n    account {\n      id\n      people {\n        id\n        assignments(where: {end_date_iso: {_gte: $fromDate, _lte: $toDate}}) {\n          id\n          person_id\n          project_id\n          phase_id\n          start_date: start_date_runn\n          end_date: end_date_runn\n          minutes_per_day\n          total_minutes\n          is_billable\n          is_template\n          note\n          role_id\n          updated_at\n          non_working_day\n          workstream_id\n        }\n        time_offs(where: {end_date_iso: {_gte: $fromDate, _lte: $toDate}}) {\n          id\n          person_id\n          start_date: start_date_runn\n          end_date: end_date_runn\n          minutes_per_day\n          leave_type\n          ext_time_off_links(where: {active: {_eq: true}}) {\n            time_off_id\n            ext_time_off_id\n            active\n            external_id: ext_time_off_id\n            external: ext_time_off {\n              __typename\n              id\n              integration_id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cecd5001ed96f32bc56b40e72dfb752";

export default node;
