import { Icon, Menu, MenuItem } from "@blueprintjs/core"
import React, { useState } from "react"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import Dialog from "~/common/Dialog"

import ExternalProjectMemberForm from "~/forms/ExternalProjectMemberForm/ExternalProjectMemberForm"

type ExternalPersonPlannerMenuProps = {
  projectId: number
  extProjectId: number
  closeMenu: () => void
  setDialogIsOpen: (val: boolean) => void
}

const ExternalPersonPlannerMenu = ({
  projectId,
  extProjectId,
  closeMenu,
  setDialogIsOpen,
}: ExternalPersonPlannerMenuProps) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleActivateClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowDialog(true)
    setDialogIsOpen(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
    setDialogIsOpen(false)
    closeMenu()
  }

  return (
    <>
      <Menu className={dropdownStyles.dropdown} style={{ padding: 0 }}>
        <MenuItem
          icon={<Icon icon="new-person" />}
          text="Activate all"
          onClick={handleActivateClick}
        />
      </Menu>

      <Dialog lazy usePortal isOpen={showDialog} onClose={handleCloseDialog}>
        <ExternalProjectMemberForm
          extProjectId={extProjectId}
          projectId={projectId}
          closeDialog={handleCloseDialog}
          formType="activate"
        />
      </Dialog>
    </>
  )
}

export default ExternalPersonPlannerMenu
