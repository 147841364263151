import { DailyActualsAndScheduled } from "~/actuals/helpers/actuals-helpers"
import { formatCurrency } from "~/helpers/CurrencyHelper"

import { BudgetedRole } from "~/ProjectDashboard/Header/ProjectBudget/BudgetEditor"
import { roundBudget } from "~/ProjectDashboard/Header/ProjectBudget/project_budget_helpers"

import { OTHER_EXPENSES_ROLE, extractRoles } from "./otherCosts"

type ID = string | number

type Project = {
  project_roles: ReadonlyArray<ProjectRole & { id: number }>
  members: ReadonlyArray<{ role_id: number }>
  expenses_budget: number
  other_costs: OtherCosts
}

type Role = {
  active: boolean
  id: number
  name: string
}

type ProjectRate = { role_id: ID; rate: number }

type ProjectRole = {
  role_id: ID
  estimated_minutes: number
}

type OtherCosts = ReadonlyArray<{
  charge: number
  cost: number
  date: string
  id: number
}>

export const isBillableProject = (pricing_model: string, internal: boolean) =>
  pricing_model !== "nb" && !internal

export const getBudgetedRoles = (
  project: Project,
  roles: ReadonlyArray<Role>,
  isBillable: boolean,
) => {
  // Important note:
  // project.project_roles can be read as Budgeted Roles in the project.
  // It is not a list of roles in the project.
  const budgetedRoleIds = project.project_roles.map((r) => r.role_id)
  const scheduledRoleIds = project.members.map((r) => r.role_id)
  const hasBudgetedExpenses =
    project.expenses_budget || project.expenses_budget === 0

  const budgetRoles = roles
    .filter(
      (r) => budgetedRoleIds.includes(r.id) || scheduledRoleIds.includes(r.id),
    )
    .map<BudgetedRole>((r) => {
      const existingBudgetedRole = project.project_roles.find(
        (pr) => r.id === pr.role_id,
      )
      const budgetedRole = {
        ...r,
        estimated_minutes: existingBudgetedRole?.estimated_minutes,
        project_role_id: existingBudgetedRole?.id,
        isScheduled: scheduledRoleIds.includes(r.id),
      }

      return budgetedRole
    })

  if (hasBudgetedExpenses || (project.other_costs.length && isBillable)) {
    budgetRoles.push({
      ...OTHER_EXPENSES_ROLE,
      project_role_id: OTHER_EXPENSES_ROLE.id,
      estimated_minutes: project.expenses_budget ?? 0,
      isScheduled: !!project.other_costs.length,
    })
  }

  return budgetRoles
}

export const getProjectTotalAllocation = (
  budgetRoles: ReadonlyArray<BudgetedRole>,
  rates: ReadonlyArray<ProjectRate>,
): number => {
  const { expenses } = extractRoles(budgetRoles)
  return rates.reduce((acc, val) => {
    const roleBudget = roundBudget(
      ((budgetRoles.find((br) => br.id === val.role_id)?.estimated_minutes ||
        0) /
        60) *
        val.rate,
    )
    return acc + roleBudget
  }, expenses?.estimated_minutes ?? 0)
}

export const getProjectBudgetMinutes = (
  project_roles: ReadonlyArray<ProjectRole>,
): number => project_roles.reduce((acc, pr) => acc + pr.estimated_minutes, 0)

export const calculateCombinedTotal = (
  data: DailyActualsAndScheduled[0],
  projectType: string,
  isBillable: boolean,
  multiFactor: number,
) => {
  if (projectType === "revenue") {
    if (isBillable) {
      return data.combined_billable_amount * multiFactor
    }
    return 0
  }

  if (projectType === "costs") {
    return data.combined_cost
  }

  if (projectType === "hours") {
    if (isBillable) {
      return data.combined_billable_minutes / 60
    }
    return data.combined_nonbillable_minutes / 60
  }
}

export const calculateScheduledTotal = (
  data: DailyActualsAndScheduled[0],
  projectType: string,
  isBillable: boolean,
  multiFactor: number,
) => {
  if (projectType === "revenue") {
    if (isBillable) {
      return data.scheduled_billable_amount * multiFactor
    }
    return 0
  }

  if (projectType === "costs") {
    return data.scheduled_cost
  }

  if (projectType === "hours") {
    if (isBillable) {
      return data.scheduled_billable_minutes / 60
    }
    return data.scheduled_nonbillable_minutes / 60
  }
}

export const getBudgetWarnings = (
  project: Project & {
    pricing_model: string
    client: { internal: boolean }
    total_budget: number
    project_rates: ReadonlyArray<ProjectRate>
  },
  roles: ReadonlyArray<Role>,
) => {
  const warnings = []

  const budgetRoles = getBudgetedRoles(
    project,
    roles,
    isBillableProject(project.pricing_model, project.client.internal),
  )
  const totalAllocated = getProjectTotalAllocation(
    budgetRoles,
    project.project_rates,
  )
  const budgetAllocationDifference = project.total_budget - totalAllocated

  if (budgetAllocationDifference < 0) {
    warnings.push(
      `Budget is overallocated by ${formatCurrency(
        Math.abs(budgetAllocationDifference),
      )}`,
    )
  } else if (budgetAllocationDifference > 0) {
    warnings.push(
      `Budget is underallocated by ${formatCurrency(
        Math.abs(budgetAllocationDifference),
      )}`,
    )
  }

  const hasRolesWithoutRates = budgetRoles
    .filter((role) => role.project_role_id !== OTHER_EXPENSES_ROLE.id)
    .some(
      (role) =>
        project.project_rates.find(
          (pr) => pr.role_id === role.id && pr.rate === 0,
        ) || !project.project_rates.find((pr) => pr.role_id === role.id),
    )

  if (hasRolesWithoutRates && project.pricing_model !== "nb") {
    warnings.push("Project rates are set to $0")
  }

  return warnings
}

export default {
  getProjectTotalAllocation,
  getProjectBudgetMinutes,
  isBillableProject,
}
