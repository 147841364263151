import { Button, Classes, Switch } from "@blueprintjs/core"
import cc from "classcat"
import intersection from "lodash-es/intersection"
import sortBy from "lodash-es/sortBy"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import isEqual from "react-fast-compare"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import useScrollbarSize from "react-scrollbar-size"

import styles from "./TentativeProjectsToggle.module.css"

import { TentativeProjectsToggle_account$key } from "~/common/PageControls/__generated__/TentativeProjectsToggle_account.graphql"

import { track } from "~/helpers/analytics"

import { Popover2 } from "~/common/Popover2"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import {
  disableAllProjects,
  disableProjects,
  enableOnly,
  enableProjects,
  toggleProject,
} from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { getSettings, setSetting } from "~/localsettings"

import FilterMenu from "./FilterMenu/FilterMenu"
import FilterMenuSearch from "./FilterMenu/FilterMenuSearch"
import FilterMenuSwitch from "./FilterMenu/FilterMenuSwitch"

type Project = {
  id: number
  name: string
  confirmed: boolean
  active: boolean
}

const fragment = graphql`
  fragment TentativeProjectsToggle_account on accounts
  @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
    projects(where: $projectsFilter) {
      id
      name
      confirmed
      active
    }
  }
`

const noop = () => void 0

const TentativeProjectSwitch = ({
  name,
  onChange,
  checked,
}: {
  name: string
  onChange: () => void
  checked: boolean
}) => {
  const handleChange = () => {
    track("Planner Tentative Projects Toggled", { value: checked })
    onChange()
  }

  return (
    <FilterMenuSwitch
      label={name}
      checked={checked}
      onChange={handleChange}
      switchClassName={styles.switch}
    />
  )
}

const TentativeProjectSwitchList = (props: {
  projects: ReadonlyArray<Project>
  enabledTentativeProjects: ReadonlyArray<number>
  onEnableProjects?: (projectIDs: Array<number>) => void
  onDisableProjects?: (projectIDs: Array<number>) => void
  onToggleProject?: (projectID: number) => void
}) => {
  const {
    projects,
    enabledTentativeProjects,
    onEnableProjects,
    onDisableProjects,
    onToggleProject,
  } = props

  const [searchValue, setSearchValue] = useState("")

  const filteredProjects = sortBy(
    projects
      .filter((p) => p.active)
      .filter((p) => !p.confirmed)
      .filter((p) => p.name.toLowerCase().includes(searchValue.toLowerCase())),
    (p) => p.name.toLowerCase(),
  )

  const allFilteredEnabled =
    intersection(
      enabledTentativeProjects,
      filteredProjects.map((p) => p.id),
    ).length === filteredProjects.length

  const handleToggleTentative = () => {
    const ids = filteredProjects.map((p) => p.id)
    if (allFilteredEnabled) {
      track("Planner Tentative Header Disabled")
    } else {
      track("Planner Tentative Header Enabled")
    }
    const toggleAction = allFilteredEnabled
      ? onDisableProjects
      : onEnableProjects
    toggleAction && toggleAction(ids)
  }

  const { width: scrollbarWidth } = useScrollbarSize()

  return (
    <FilterMenu
      heading={
        <FilterMenuSearch
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          clear={() => setSearchValue("")}
        >
          <Switch
            checked={allFilteredEnabled && filteredProjects.length > 0}
            disabled={filteredProjects.length < 1}
            onChange={handleToggleTentative}
            className={styles.switch}
            alignIndicator="right"
            style={{ marginRight: scrollbarWidth + 12 }}
          />
        </FilterMenuSearch>
      }
      footer={
        <div className={styles.done}>
          <Button
            text="Done"
            className={cc([Classes.POPOVER_DISMISS, styles.doneButton])}
            fill
            minimal
            intent="primary"
          />
        </div>
      }
    >
      {filteredProjects.map((project) => (
        <TentativeProjectSwitch
          key={project.id}
          name={project.name}
          checked={enabledTentativeProjects.includes(project.id)}
          onChange={() => {
            onToggleProject && onToggleProject(project.id)
          }}
        />
      ))}
    </FilterMenu>
  )
}

const checkedLabel = (props: { count: number; outOf: number }): string => {
  const { count, outOf } = props
  if (count === outOf) {
    return "All"
  }
  if (count >= 99) {
    return "#"
  }
  return count.toString()
}

export const TentativeProjectsToggleControlled = (props: {
  projects: ReadonlyArray<Project>
  enabledTentativeProjects: Array<number>
  onChange?: (enabledTentativeProjects: Array<number>) => void
  onApply?: (enabledTentativeProjects: Array<number>) => void
  onEnableProjects?: (projectIDs: Array<number>) => void
  onDisableProjects?: (projectIDs: Array<number>) => void
  onToggleProject?: (projectID: number) => void
  onInit?: (tentativeProjects: Array<number>) => void
  onToggleAllSelected?: (allSelected: boolean) => void
}) => {
  const {
    enabledTentativeProjects,
    projects,
    onChange = noop,
    onApply = noop,
    onEnableProjects = noop,
    onDisableProjects = noop,
    onToggleProject = noop,
    onInit,
    onToggleAllSelected,
  } = props
  const queryLimit = useQueryLimit()
  const isEnterprise = queryLimit.limit
  const tentativeProjects = useMemo(
    () =>
      projects
        .filter((project) => project.active)
        .filter((project) => !project.confirmed)
        .map((project) => project.id),
    [projects],
  )

  const [localEnabledProjects, setLocalEnabledProjects] = useState(
    enabledTentativeProjects,
  )

  const updateLocalEnabledProjects = useCallback(
    (newEnabledProjects: Array<number>) => {
      setLocalEnabledProjects((prev) => {
        if (isEqual(prev, newEnabledProjects)) {
          return prev
        }
        return newEnabledProjects
      })
    },
    [setLocalEnabledProjects],
  )

  const [toggleOn, setToggleOn] = useState(false)

  const enabled = intersection(localEnabledProjects, tentativeProjects)
  const noProjects = tentativeProjects.length < 1
  const anyEnabled = enabled.length > 0

  const handleToggleTentative = useCallback(() => {
    setToggleOn(!toggleOn)

    if (anyEnabled) {
      updateLocalEnabledProjects([])
    } else {
      updateLocalEnabledProjects(tentativeProjects)
    }

    track("Planner Tentative Toggled", { value: toggleOn })
  }, [anyEnabled, tentativeProjects, toggleOn, updateLocalEnabledProjects])

  const handleEnableProjects = useCallback(
    (projectIDs: Array<number>) => {
      const newIds = [...localEnabledProjects, ...projectIDs]
      updateLocalEnabledProjects(newIds)
      onEnableProjects(projectIDs)
    },
    [localEnabledProjects, onEnableProjects, updateLocalEnabledProjects],
  )

  const handleDisableProjects = useCallback(
    (projectIDs: Array<number>) => {
      const newIds = localEnabledProjects.filter((p) => !projectIDs.includes(p))
      updateLocalEnabledProjects(newIds)
      onDisableProjects(projectIDs)
    },
    [localEnabledProjects, onDisableProjects, updateLocalEnabledProjects],
  )

  const handleToggleProject = useCallback(
    (projectID: number) => {
      const newEnabledTentativeProjects = localEnabledProjects.includes(
        projectID,
      )
        ? localEnabledProjects.filter((p) => p !== projectID)
        : [...localEnabledProjects, projectID]
      updateLocalEnabledProjects(newEnabledTentativeProjects)
      onToggleProject(projectID)
    },
    [localEnabledProjects, onToggleProject, updateLocalEnabledProjects],
  )

  const handleClose = useCallback(() => {
    onApply(localEnabledProjects)
  }, [onApply, localEnabledProjects])

  // If Redux changes, update local enabled projects. This is used in the planner
  // where there is the tentative toggle up the top of the page but then also
  // toggles per project in the planner left column
  useEffect(() => {
    updateLocalEnabledProjects(enabledTentativeProjects)
  }, [enabledTentativeProjects, updateLocalEnabledProjects])

  useEffect(() => {
    onChange(localEnabledProjects)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localEnabledProjects])

  useEffect(() => {
    onApply(localEnabledProjects)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOn, onApply])

  useEffect(() => {
    onInit && onInit(tentativeProjects)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const allSelected = enabled.length === tentativeProjects.length
    onToggleAllSelected && onToggleAllSelected(allSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled.length, tentativeProjects.length])

  useEffect(() => {
    // In enterprise accounts we don't get all tentativeProjects due to limits
    // so we have to check if more were returned and turn enable those as well
    if (isEnterprise && toggleOn) {
      onChange(tentativeProjects)
    }
  }, [tentativeProjects.length]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div
      data-test="TentativeProjectsToggle"
      className={cc([
        styles.container,
        {
          [styles.hideDropdown]: isEnterprise,
        },
      ])}
    >
      <Switch
        className={cc([styles.switch, styles.tentativeSwitch])}
        checked={anyEnabled && !noProjects}
        onChange={handleToggleTentative}
        label="Tentative"
        innerLabel=""
        innerLabelChecked={
          isEnterprise
            ? null
            : checkedLabel({
                count: enabled.length,
                outOf: tentativeProjects.length,
              })
        }
        disabled={noProjects}
      />
      {!isEnterprise && (
        <Popover2
          onClose={handleClose}
          interactionKind="click"
          placement="bottom"
          content={
            <TentativeProjectSwitchList
              projects={projects}
              enabledTentativeProjects={localEnabledProjects}
              onEnableProjects={handleEnableProjects}
              onDisableProjects={handleDisableProjects}
              onToggleProject={handleToggleProject}
            />
          }
          usePortal
          className={styles.dropdownPopover}
          disabled={noProjects}
        >
          <Button
            icon="chevron-down"
            minimal
            small
            className={styles.dropdown}
            intent="none"
            disabled={noProjects}
          />
        </Popover2>
      )}
    </div>
  )
}

const TentativeProjectsToggle = (props: {
  account: TentativeProjectsToggle_account$key
}) => {
  const { projects } = useFragment(fragment, props.account)
  const dispatch = useDispatch()

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
    isDeeplyEqual,
  )

  const handleToggleTentative = useCallback(
    (projectIDs: Array<number>) => {
      if (!projectIDs.length) {
        dispatch(disableAllProjects())
      } else {
        dispatch(enableOnly(projectIDs))
      }
    },
    [dispatch],
  )

  const handleEnableProjects = (projectIDs: Array<number>) => {
    dispatch(enableProjects(projectIDs))
  }

  const handleDisableProjects = (projectIDs: Array<number>) => {
    dispatch(disableProjects(projectIDs))
  }

  const handleInit = (tentativeProjects: Array<number>) => {
    // Turn on all tentative projects if the user has used the tentative all toggle
    const showAllTentativeProjects = getSettings().enableAllTentative
    if (showAllTentativeProjects) {
      dispatch(enableOnly(tentativeProjects))
    }
  }

  const handleToggleProject = (projectID: number) => {
    dispatch(toggleProject(projectID))
  }
  const handleToggleAllSelected = (allSelected: boolean) => {
    if (allSelected) {
      setSetting("enableAllTentative", true)
    } else {
      setSetting("enableAllTentative", false)
    }
  }

  return (
    <TentativeProjectsToggleControlled
      projects={projects}
      enabledTentativeProjects={enabledTentativeProjects}
      onChange={handleToggleTentative}
      onEnableProjects={handleEnableProjects}
      onDisableProjects={handleDisableProjects}
      onToggleProject={handleToggleProject}
      onInit={handleInit}
      onToggleAllSelected={handleToggleAllSelected}
    />
  )
}

export default React.memo(TentativeProjectsToggle, isEqual)
