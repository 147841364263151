import React from "react"

import styles from "./PhaseHighlightedArea.module.css"
import phaseItemStyles from "./PhaseItem.module.css"

import PhaseName from "./PhaseName"

type Props = {
  gridStart: number
  gridSpan: number
  pillLength: number
  hideHoverPill?: boolean
}

const PhaseHighlightedArea = (props: Props) => {
  const { gridStart, gridSpan, hideHoverPill, pillLength } = props
  return (
    <div
      style={{ gridColumn: `${gridStart} / span ${gridSpan}` }}
      className={`${styles.highlightedArea} ${phaseItemStyles.phaseItem}`}
    >
      {!hideHoverPill && (
        <div
          className={phaseItemStyles.pill}
          style={{ backgroundColor: "var(--winter)" }}
        >
          <PhaseName phase={{ name: "Phase" }} pillLength={pillLength} />
        </div>
      )}
    </div>
  )
}

export default PhaseHighlightedArea
