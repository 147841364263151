import React, { ReactNode } from "react"

import styles from "./SelectorListContainer.module.css"

import { TangramProject } from "~/common/react-icons"
import { TangramRunnerEmpty } from "~/common/tangrams/tangramRunnerEmpty"

type Props = {
  type: string | "people" | "projects"
  noResultsFound: boolean
  children: ReactNode
  noActiveItems?: boolean
  filterValue?: string
  customNoResultsMessage?: string
  disableOverflow?: boolean
}

const SelectorListContainer = (props: Props) => {
  const {
    type,
    noResultsFound,
    noActiveItems,
    customNoResultsMessage,
    filterValue,
    children,
    disableOverflow = false,
  } = props

  if (noResultsFound || noActiveItems) {
    const defaultMessage = `No ${type} match ${
      filterValue ? `"${filterValue}"` : "the above criteria"
    }`
    return (
      <div className={styles.noResults}>
        <div style={{ marginTop: 30, marginBottom: 3, fontSize: 17 }}>
          {noActiveItems ? (
            <div className={styles.noItems}>
              {type === "people" ? <TangramRunnerEmpty /> : <TangramProject />}
              No {type} found
            </div>
          ) : (
            customNoResultsMessage || defaultMessage
          )}
        </div>
      </div>
    )
  }
  return (
    <div
      data-component="SelectorListContainer"
      className={styles.selectorListContainer}
      style={{ overflowY: disableOverflow ? "hidden" : "scroll" }}
    >
      {children}
    </div>
  )
}

export default SelectorListContainer
