import * as React from "react"

import DurationHelper from "../../../helpers/DurationHelper"

type HoursPerWeekLabelProps = {
  minutesPerDay: number
  pillWidth: number
}
export const HoursPerWeekLabel = ({
  minutesPerDay,
  pillWidth,
}: HoursPerWeekLabelProps) => {
  const duration = DurationHelper.formatDuration(minutesPerDay * 5)
  if (pillWidth < 200) {
    return <>{duration}/w</>
  }

  return <>{duration}/week</>
}
