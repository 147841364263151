import { graphql, useFragment } from "react-relay"

import type { useCustomSelect_account$key } from "./__generated__/useCustomSelect_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useCustomSelect_account on accounts {
    custom_select_types {
      id
      name
      model
      custom_select_options {
        id
        name
      }
    }
  }
`

type Options = {
  typeId: number
  user: SuperSearch_user$data
}

const useCustomSelect = (options: Options) => {
  const { typeId, user } = options

  const { custom_select_types: types } =
    useFragment<useCustomSelect_account$key>(fragment, user.account)

  const type = types.find((t) => t.id === typeId)

  // The type might've been deleted but is still referenced on the client
  if (!type) {
    return []
  }

  const availableOptions = [...type.custom_select_options]
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    .map((t) => ({
      value: t.id,
      label: t.name,
    }))

  return availableOptions
}

export default useCustomSelect
