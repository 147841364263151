import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./MultiPersonDetails.module.css"

import { buildImageUrl } from "~/helpers/image"
import { formatName } from "~/helpers/person"

import Avatar from "./Avatar"

type Props = {
  reverse?: boolean
  people?: {
    first_name: string
    last_name: string
    id: number
    email?: string
    is_placeholder?: boolean
    image_key?: string
  }[]
}

const MultiPersonDetails = (props: Props) => {
  const { people, reverse = false } = props

  const avatars =
    people.length <= 9 &&
    people.map((p) => (
      <div
        key={p.id}
        className={`${styles.multiPersonAvatar} ${
          reverse ? styles.reverseAvatars : ""
        }`}
      >
        <Tooltip
          content={formatName(p.first_name, p.last_name)}
          transitionDuration={100}
          placement="top"
        >
          <Avatar
            key={p.id}
            avatar={buildImageUrl(p.image_key, {
              width: 30,
              height: 30,
              quality: "auto",
            })}
            email={p.email}
            is_placeholder={p.is_placeholder}
            size={40}
          />
        </Tooltip>
      </div>
    ))

  return (
    <>
      {reverse ? null : avatars}
      <div className={styles.details}>
        <div className={styles.title}>{people.length} people</div>
      </div>
      {reverse ? (
        <div
          className={styles.avatarsContainer}
          style={{ width: 10 + avatars.length * 20 + "px" }}
        >
          {avatars}
        </div>
      ) : null}
    </>
  )
}

export default MultiPersonDetails
