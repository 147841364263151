import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { toast } from "react-toastify"

import styles from "./styles.module.css"

import type { ExternalProjectBulkImportQuery } from "./__generated__/ExternalProjectBulkImportQuery.graphql"

import { pluralize } from "~/helpers/plural"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { InfoCircle } from "~/common/react-icons"

import { ExtProjectListTable } from "./ExtProjectListTable"
import { useBulkImportExtProjectList } from "./hooks/useBulkImportExtProjectList"

const query = graphql`
  query ExternalProjectBulkImportQuery($integrationId: Int!) {
    integration_by_pk(id: $integrationId) {
      id
      ext_projects(where: { active: { _eq: true } }) {
        id
        remote_id
        name
      }
    }
  }
`
type Props = {
  integrationId: number

  onClose?: () => void
  onNavigateBack?: () => void
}

const ExternalProjectBulkImport = (props: Props) => {
  const { integrationId, onClose, onNavigateBack } = props

  const [checkedSet, setCheckedSet] = React.useState<Set<number>>(new Set())

  const numSelected = checkedSet.size
  const canImport = numSelected > 0

  const data = useLazyLoadQuery<ExternalProjectBulkImportQuery>(
    query,
    {
      integrationId,
    },
    {
      fetchPolicy: "store-and-network",
    },
  )

  const [bulkImportExtProjectList, inFlight] = useBulkImportExtProjectList()

  const handleImport = async () => {
    const extProjectIdList = Array.from(checkedSet)

    try {
      await bulkImportExtProjectList({
        variables: {
          input: {
            extProjectIdList,
          },
        },
      })

      // if successful, close the modal
      onClose?.()
    } catch (error) {
      console.error("Failed to import external projects", error)
      toast.error("Failed to import external projects")
    }
  }

  return (
    <ModalFormWrapper
      wide
      headerTitle="Select Projects to Import and Create"
      onClose={onClose}
    >
      <ModalBody style={{ maxWidth: "1200px" }}>
        <ExtProjectListTable
          extProjectList={data.integration_by_pk.ext_projects}
          checkedSet={checkedSet}
          onChange={setCheckedSet}
        />
      </ModalBody>

      <ModalFooter className={styles.modalFooter}>
        {numSelected > 0 ? (
          <div className={styles.selectedCount}>
            <InfoCircle /> {numSelected}{" "}
            {pluralize(numSelected, "Project", "Projects")} selected to import
          </div>
        ) : (
          <div />
        )}

        <div>
          {onNavigateBack ? (
            <Button text="Back" onClick={onNavigateBack} />
          ) : null}
          <Button
            text="Import"
            onClick={handleImport}
            loading={inFlight}
            outlined={false}
            disabled={!canImport}
          />
        </div>
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export { ExternalProjectBulkImport }
