import * as React from "react"
import { graphql, useFragment } from "react-relay"
import Select, {
  ActionMeta,
  CSSObjectWithLabel,
  MultiValueProps,
  OnChangeValue,
  OptionProps,
  StylesConfig,
  components,
} from "react-select"

import {
  PersonSelector_people$data,
  PersonSelector_people$key,
} from "./__generated__/PersonSelector_people.graphql"

import { buildImageUrl } from "../../helpers/image"
import { formatNameAsInitials } from "../../helpers/person"

import Avatar from "../../common/Avatar"
import { Cross } from "../../common/react-icons"

type Person = PersonSelector_people$data[number]

const customStyles: StylesConfig<Person, true> = {
  indicatorsContainer: (base) => {
    return {
      ...base,
    } as CSSObjectWithLabel
  },
  control: (base, props) => {
    return {
      ...base,
      borderColor: props.isDisabled ? "transparent" : base.borderColor,
      background: "white",
      borderRadius: 2,
      borderSize: 1,
      paddingLeft: 8,
      paddingRight: 0,
    } as CSSObjectWithLabel
  },
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isFocused ? "var(--cloud)" : base.backgroundColor,
      "&:hover": {
        backgroundColor: state.isDisabled ? "var(--cloud)" : undefined,
      },
      color: "var(--midnight)",
      fontSize: 13,
      padding: "12px 10px 12px 12px",
      display: "flex",
      flexDirection: "row",
      gap: 8,
    } as CSSObjectWithLabel
  },
  dropdownIndicator: (base, state) =>
    ({
      ...base,
      padding: 0,
      color: "var(--shadow)",
      opacity: state.isDisabled ? 0 : 1,
    }) as CSSObjectWithLabel,
  placeholder: (base) =>
    ({
      ...base,
      maxWidth: "calc(100% - 8px)",
      overflow: "hidden",
      position: "absolute",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "var(--shadow)",
      fontSize: 13,
    }) as CSSObjectWithLabel,
  menuPortal: (base) => ({ ...base, zIndex: 9999 }) as CSSObjectWithLabel,
  valueContainer: (base) =>
    ({
      ...base,
      padding: 0,
    }) as CSSObjectWithLabel,
  multiValue: (provided) =>
    ({
      ...provided,
      padding: 0,
      borderRadius: 2,
      backgroundColor: "var(--snow)",
      display: "flex",
      gap: 4,
    }) as CSSObjectWithLabel,
  multiValueLabel: (provided) =>
    ({
      ...provided,
      display: "flex",
      gap: 4,
      flexDirection: "row",
      alignItems: "center",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18px",
      fontStyle: "normal",
      letterSpacing: -0.01,
      color: "var(--midnight)",
    }) as CSSObjectWithLabel,
  multiValueRemove: (provided, props) =>
    ({
      ...provided,
      color: "var(--smoke)",
      cursor: "pointer",
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      visibility: props.isDisabled ? "hidden" : "visible",
    }) as CSSObjectWithLabel,
}

export function PersonSelector(props: {
  id?: string
  selected: number[]
  people: PersonSelector_people$key
  addPerson(id: number): void
  removePerson(id: number): void
  isDisabled?: boolean
  maxSelected?: number
}) {
  const people = useFragment(
    graphql`
      fragment PersonSelector_people on people @relay(plural: true) {
        id
        email
        first_name
        last_name
        image_key
      }
    `,
    props.people,
  )

  const selected = people.filter((p) => props.selected.includes(p.id))
  const options =
    props.maxSelected && props.selected.length >= props.maxSelected
      ? []
      : people

  function handleChange(
    _newValue: OnChangeValue<Person, true>,
    actionMeta: ActionMeta<Person>,
  ) {
    if (actionMeta.action === "select-option") {
      props.addPerson(actionMeta.option.id)
    } else if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      props.removePerson(actionMeta.removedValue.id)
    } else if (actionMeta.action === "deselect-option") {
      props.removePerson(actionMeta.option.id)
    }
  }

  return (
    <Select
      id={props.id}
      styles={customStyles}
      onChange={handleChange}
      components={{
        Option: CustomOption,
        MultiValue: CustomMultiValue,
        MultiValueRemove: CustomMultiValueRemove,
        IndicatorSeparator: null,
      }}
      menuPortalTarget={document.body}
      getOptionLabel={(option: Person) => option.first_name}
      getOptionValue={(option: Person) => option.id.toString()}
      options={options}
      value={selected}
      placeholder="Select or search for person"
      isMulti
      isClearable={false}
      isDisabled={props.isDisabled}
      noOptionsMessage={() => "Please remove a person before adding more"}
    />
  )
}

function CustomOption(props: OptionProps<Person, true>) {
  const initials = formatNameAsInitials(
    props.data.first_name,
    props.data.last_name,
  )
  return (
    <components.Option {...props}>
      <Avatar
        avatar={buildImageUrl(props.data.image_key)}
        email={props.data.email}
        initials={initials}
        is_placeholder={false}
        size={24}
      />
      <div>
        {props.data.first_name} {props.data.last_name}
      </div>
    </components.Option>
  )
}

function CustomMultiValue({
  children,
  ...props
}: MultiValueProps<Person, true>) {
  const initials = formatNameAsInitials(
    props.data.first_name,
    props.data.last_name,
  )
  return (
    <components.MultiValue {...props}>
      <Avatar
        avatar={buildImageUrl(props.data.image_key)}
        email={props.data.email}
        initials={initials}
        is_placeholder={false}
        size={20}
      />
      <div>
        {props.data.first_name} {props.data.last_name}
      </div>
    </components.MultiValue>
  )
}

function CustomMultiValueRemove(props: any) {
  return (
    <components.MultiValueRemove {...props}>
      <Cross size={8} color="currentColor" />
    </components.MultiValueRemove>
  )
}
