type Assignment = {
  project_id: number
}

type AssignmentWithDates = Assignment & {
  end_date: string
  start_date: string
}

type Project = {
  id: number
  confirmed: boolean
}

export const getConfirmedAssignments = <
  T extends Assignment,
  Q extends Project,
>(
  assignments: ReadonlyArray<T>,
  projects: ReadonlyArray<Q>,
) => {
  const confirmedProjectIds = Object.fromEntries(
    projects.map((p) => [p.id, p.confirmed]),
  )
  return assignments.filter((a) => confirmedProjectIds[a.project_id])
}

export const getVisibleAssignments = <T extends AssignmentWithDates>(
  assignments: ReadonlyArray<T>,
  calStartNum: number,
  calEndNum: number,
) =>
  assignments
    ?.filter((a) => Number(a.end_date) >= calStartNum)
    .filter((a) => Number(a.start_date) <= calEndNum) || []
