import { useFeature } from "flagged"
import React from "react"
import { graphql, useFragment } from "react-relay"

import { ProjectCustomEditor_project$key } from "./__generated__/ProjectCustomEditor_project.graphql"
import { ProjectCustomEditor_viewer$key } from "./__generated__/ProjectCustomEditor_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { getViewsProjectGroupedOptions } from "~/Views/helpers"
import {
  CustomValuesMap,
  typesMap,
  valuesMap,
} from "~/helpers/custom-field-helpers"

import { Option } from "~/common/Select"

import CustomEditor from "../CustomEditor"

const viewerFragment = graphql`
  fragment ProjectCustomEditor_viewer on users {
    id
    view {
      id
      name
      project_filters
    }
    account {
      id
      use_week_numbers
      custom_text_types_project: custom_text_types(
        where: { model: { _eq: "PROJECT" } }
      ) {
        id
        name
        description
        sort_order
      }
      custom_date_types_project: custom_date_types(
        where: { model: { _eq: "PROJECT" } }
      ) {
        id
        name
        description
        sort_order
      }
      custom_select_types_project: custom_select_types(
        where: { model: { _eq: "PROJECT" } }
      ) {
        id
        name
        single_select
        description
        sort_order
        options: custom_select_options {
          id
          name
        }
      }
      custom_checkbox_types_project: custom_checkbox_types(
        where: { model: { _eq: "PROJECT" } }
      ) {
        id
        name
        description
        sort_order
      }
    }
  }
`

const projectFragment = graphql`
  fragment ProjectCustomEditor_project on projects {
    id
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      value
      typeId: custom_checkbox_type_id
    }
  }
`

type Props = {
  project: ProjectCustomEditor_project$key
  onUpdate: (values: CustomValuesMap) => void
  itemClassName?: string
}

const ProjectCustomEditor = ({ project, onUpdate, itemClassName }: Props) => {
  const { account, view } = useFragment<ProjectCustomEditor_viewer$key>(
    viewerFragment,
    // Used globally since it's too much noise to prop drill down the component tree
    useHasuraContext(),
  )
  const projectData = useFragment<ProjectCustomEditor_project$key>(
    projectFragment,
    project,
  )

  const customValues = valuesMap(projectData)

  const defaultView =
    useFeature("pre_filtered_views") && view ? view : undefined

  const handleGroupedOptions = (
    options: Option[],
    customSelectName: string,
  ) => {
    return getViewsProjectGroupedOptions(
      defaultView,
      options,
      "project_custom_select",
      customSelectName,
    )
  }

  return (
    <CustomEditor
      key={projectData?.id}
      typesMap={typesMap({
        custom_text_types: account.custom_text_types_project,
        custom_date_types: account.custom_date_types_project,
        custom_select_types: account.custom_select_types_project,
        custom_checkbox_types: account.custom_checkbox_types_project,
      })}
      valuesMap={customValues}
      onUpdate={onUpdate}
      itemClassName={itemClassName}
      formatSelectOptions={handleGroupedOptions}
      showWeekNumbers={account.use_week_numbers}
    />
  )
}

export default ProjectCustomEditor
