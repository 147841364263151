import React, { ReactNode } from "react"

import styles from "./ProjectItem.module.css"

type Props = {
  children?: ReactNode
  width: number
  offset: number
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  isCollapsedWeekend?: boolean
}

const ProjectItem = (props: Props) => {
  const { children, width, offset, onClick, isCollapsedWeekend, ...rest } =
    props

  const collapsedWeekendWidth = width / 2
  return (
    <div
      className={styles.projectItem}
      style={{
        width: isCollapsedWeekend ? collapsedWeekendWidth : width,
        marginLeft: isCollapsedWeekend
          ? `calc(${offset}% - ${collapsedWeekendWidth / 2}px)`
          : `${offset}%`,
        zIndex: isCollapsedWeekend ? 11 : 10,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  )
}

export default ProjectItem
