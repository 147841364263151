/**
 * @generated SignedSource<<1c3ec55b4179e08401b6cdda6a226dda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTeams_account$data = {
  readonly teams: ReadonlyArray<{
    readonly id: number;
    readonly name: string;
  }>;
  readonly " $fragmentType": "useTeams_account";
};
export type useTeams_account$key = {
  readonly " $data"?: useTeams_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTeams_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTeams_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "teams",
      "kind": "LinkedField",
      "name": "teams",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "a98c80d3b2b676fba49e25344a7c57da";

export default node;
