import { TooltipProps } from "@blueprintjs/core"
import React from "react"

import styles from "./CompetencyPicker.module.css"

import { SkillsLabelsList_viewer$data } from "~/Skills/SkillsLabelsList/__generated__/SkillsLabelsList_viewer.graphql"

import { track } from "~/helpers/analytics"
import { dashify } from "~/helpers/general-helpers"
import { formatName } from "~/helpers/person"

import { CompetencyIcon } from "~/common/react-icons"

import { updateCompetencyRelay } from "~/mutations/Competency"

import CompetencyDropdown from "./CompetencyDropdown"
import CompetencyTooltipLevel from "./CompetencyTooltipLevel"

type Props = {
  competency: SkillsLabelsList_viewer$data["account"]["competencies"][0]
  canEdit?: boolean
  tooltipProps?: Omit<TooltipProps, "content">
  hideName?: boolean
  hideIcon?: boolean
  hideLevel?: boolean
}

const CompetencyPicker = (props: Props) => {
  const { canEdit, competency, hideName, hideIcon, hideLevel, tooltipProps } =
    props

  const personName = formatName(
    competency.person.first_name,
    competency.person.last_name,
  )

  const handleCompetencyChange = async (newLevel: number) => {
    track("Competency Updated")
    await updateCompetencyRelay({ id: competency.id, level: newLevel })
  }

  if (!canEdit) {
    return (
      <CompetencyTooltipLevel
        level={competency.level}
        tooltipProps={tooltipProps}
      >
        <div className={styles.container} style={{ cursor: "auto" }}>
          {!hideName && (
            <>
              <span className={styles.name}>{competency.skill.name}</span>
              <span className={styles.divider} />
            </>
          )}
          {!hideIcon && (
            <div className={styles.iconWrapper}>
              <CompetencyIcon level={competency.level} />
            </div>
          )}
          {!hideLevel && (
            <>
              <span className={styles.divider} />
              <span className={styles.levelNumber}>
                {competency.level || "-"}
              </span>
            </>
          )}
        </div>
      </CompetencyTooltipLevel>
    )
  }

  return (
    <CompetencyDropdown
      defaultLevel={competency.level}
      onSelectLevel={handleCompetencyChange}
      tooltipProps={tooltipProps}
    >
      <div
        className={styles.container}
        data-test={`${dashify(personName)}-competency-${dashify(
          competency.skill.name,
        )}-${competency.level || 0}`}
      >
        {!hideName && (
          <>
            <span className={styles.name}>{competency.skill.name}</span>
            <span className={styles.divider} />
          </>
        )}
        {!hideIcon && (
          <div className={styles.iconWrapper}>
            <CompetencyIcon level={competency.level} />
          </div>
        )}
        {!hideLevel && (
          <>
            <span className={styles.divider} />
            <span className={styles.levelNumber}>
              {competency.level || "-"}
            </span>
          </>
        )}
      </div>
    </CompetencyDropdown>
  )
}

export default CompetencyPicker
