import { match } from "ts-pattern"

import { Model } from "~/reports/shared_columns/CustomFieldsColumns"

import { sortByString } from "./sorting-helpers"

export enum CustomTypeName {
  TEXT = "text",
  SELECT = "select",
  CHECKBOX = "checkbox",
  DATE = "date",
}

// Type for making the compiler fail if a new custom field type is added in the CustomTypeName enum and not handled
export type CustomFieldsMap<Text, Select, Checkbox, Date> = {
  [K in CustomTypeName]: K extends CustomTypeName.TEXT
    ? Text
    : K extends CustomTypeName.SELECT
      ? Select
      : K extends CustomTypeName.CHECKBOX
        ? Checkbox
        : K extends CustomTypeName.DATE
          ? Date
          : `error: ${K} enum member not handled!`
}

// Type for matching the keys of the CustomFieldsMap returned by hasura
type CustomFieldsTypesMap<T, S, C, D> = {
  [K in keyof CustomFieldsMap<
    T,
    S,
    C,
    D
  > as `custom_${K}_types`]: CustomFieldsMap<T, S, C, D>[K]
}

// Type for matching the keys of the CustomFieldsMap returned by hasura
type CustomFieldsValuesMap<T, S, C, D> = {
  [K in keyof CustomFieldsMap<
    T,
    S,
    C,
    D
  > as `custom_${K}_values`]: CustomFieldsMap<T, S, C, D>[K]
}

export type CustomFieldsModel = "PROJECT" | "PERSON"

type TypeId = number
type OptionId = number

export type CustomSelectOptionInput = {
  id?: number
  name: string
  account_id: number
  custom_select_type_id: number
}

export type CustomTextValue = Readonly<{
  value: string | null
  typeId: TypeId
}>

export type CustomTextType = Readonly<{
  id: TypeId
  name: string
  description?: string
  model: "PERSON" | "PROJECT"
  typeName: CustomTypeName.TEXT
  required: boolean
  show_in_planner: boolean
  filterable_in_planner: boolean
  sort_order: number
}>

export type CustomDateValue = Readonly<{
  value: string | null
  typeId: TypeId
}>

export type CustomDateType = Readonly<{
  id: TypeId
  name: string
  description?: string
  model: "PERSON" | "PROJECT"
  typeName: CustomTypeName.DATE
  required: boolean
  show_in_planner: boolean
  filterable_in_planner: boolean
  sort_order: number
}>

export type CustomSelectOption = Readonly<{
  id?: OptionId | null
  name: string
  custom_select_type_id?: number
  account_id?: number
}>

export type CustomSelectValue = Readonly<{
  optionId: OptionId
  typeId: TypeId
}>

export type CustomCheckboxType = Readonly<{
  id: TypeId
  name: string
  description?: string
  model: "PERSON" | "PROJECT"
  typeName: CustomTypeName.CHECKBOX
  required: boolean
  show_in_planner: boolean
  filterable_in_planner: boolean
  sort_order: number
}>

export type CustomCheckboxValue = Readonly<{
  value: boolean
  typeId: TypeId
}>

export type CustomSelectType = Readonly<{
  id: TypeId
  name: string
  description?: string
  model: "PERSON" | "PROJECT"
  options: readonly CustomSelectOption[]
  typeName: CustomTypeName.SELECT
  single_select: boolean
  required: boolean
  show_in_planner: boolean
  filterable_in_planner: boolean
  sort_order: number
}>

export type CustomValue =
  | CustomTextValue
  | CustomDateValue
  | CustomSelectValue[]
  | CustomCheckboxValue

export type CustomValuesForAccount = Array<
  Readonly<{
    readonly id: number
  }> &
    CustomFieldsValuesMap<
      ReadonlyArray<Pick<CustomTextValue, "typeId">>,
      ReadonlyArray<Pick<CustomSelectValue, "typeId">>,
      ReadonlyArray<Pick<CustomCheckboxValue, "typeId">>,
      ReadonlyArray<Pick<CustomDateValue, "typeId">>
    >
>

export type CustomType =
  | CustomTextType
  | CustomSelectType
  | CustomCheckboxType
  | CustomDateType

export type CustomValuesMap = Readonly<
  CustomFieldsMap<
    ReadonlyArray<CustomTextValue>,
    ReadonlyArray<CustomSelectValue>,
    ReadonlyArray<CustomCheckboxValue>,
    ReadonlyArray<CustomDateValue>
  >
>

export type CustomFieldFormInput = {
  name: string
  description?: string
  show_in_planner: boolean
  filterable_in_planner: boolean
  required: boolean
  model: "PROJECT" | "PERSON"
  account_id: number
} & (
  | {
      custom_select_options: CustomSelectOptionInput[]
      single_select: boolean
    }
  | {
      custom_select_options: never
      single_select: never
    }
)

type FilterCustomSelectValues = ReadonlyArray<{
  custom_select_type_id: number
  custom_select_option_id: number
}>

type FilterCustomSelectTypes = ReadonlyArray<{
  id: number
  name: string
}>

export const withTypeName = <
  T extends CustomTypeName,
  O extends Readonly<Record<string, any>>,
>(
  objs: Readonly<O[]>,
  typeName: T,
) => {
  return objs.map((obj) => ({ ...obj, typeName }))
}

export const sortCustomFields = (a: CustomType, b: CustomType) => {
  if (a.sort_order === b.sort_order) {
    return sortByString(a.name, b.name)
  }
  return a.sort_order - b.sort_order
}

export const typesMap = <
  T extends Readonly<Record<string, any>>,
  S extends Readonly<Record<string, any>>,
  C extends Readonly<Record<string, any>>,
  D extends Readonly<Record<string, any>>,
>(
  account: CustomFieldsTypesMap<
    ReadonlyArray<T>,
    ReadonlyArray<S>,
    ReadonlyArray<C>,
    ReadonlyArray<D>
  >,
): CustomType[] => {
  const combinedCustomTypes = [
    ...withTypeName(account.custom_date_types, CustomTypeName.DATE),
    ...withTypeName(account.custom_text_types, CustomTypeName.TEXT),
    ...withTypeName(account.custom_select_types, CustomTypeName.SELECT),
    ...withTypeName(account.custom_checkbox_types, CustomTypeName.CHECKBOX),
  ]

  return combinedCustomTypes
    .map((field) => field as CustomType)
    .sort(sortCustomFields)
}

export const valuesMap = (
  data: CustomFieldsValuesMap<
    ReadonlyArray<CustomTextValue>,
    ReadonlyArray<CustomSelectValue>,
    ReadonlyArray<CustomCheckboxValue>,
    ReadonlyArray<CustomDateValue>
  >,
): CustomValuesMap => {
  return {
    [CustomTypeName.TEXT]: data?.custom_text_values || [],
    [CustomTypeName.DATE]: data?.custom_date_values || [],
    [CustomTypeName.SELECT]: data?.custom_select_values || [],
    [CustomTypeName.CHECKBOX]: data?.custom_checkbox_values || [],
  }
}

// Transform from (clunky) API format into a useable format for form processing, grouped by type
export const getValueForType = (
  typeName: CustomTypeName,
  typeId: number,
  values: CustomValuesMap,
): CustomValue => {
  return match(typeName)
    .with(CustomTypeName.TEXT, () => {
      return values[CustomTypeName.TEXT].find((v) => v.typeId === typeId)
    })
    .with(CustomTypeName.DATE, () => {
      return values[CustomTypeName.DATE].find((v) => v.typeId === typeId)
    })
    .with(CustomTypeName.SELECT, () => {
      return values[CustomTypeName.SELECT].filter((v) => v.typeId === typeId)
    })
    .with(CustomTypeName.CHECKBOX, () => {
      return values[CustomTypeName.CHECKBOX].find((v) => v.typeId === typeId)
    })
    .exhaustive()
}

export const getModelsWithCustomValue = (
  typeName: CustomTypeName,
  typeId: number,
  values: CustomValuesForAccount,
): number => {
  const hasSameTypeId = (v: { typeId: number }) => v.typeId === typeId

  const modelIds = values.reduce((previous, current) => {
    const hasValueForType = match(typeName)
      .with(CustomTypeName.SELECT, () =>
        current.custom_select_values.some(hasSameTypeId),
      )
      .with(CustomTypeName.TEXT, () =>
        current.custom_text_values.some(hasSameTypeId),
      )
      .with(CustomTypeName.CHECKBOX, () =>
        current.custom_checkbox_values.some(hasSameTypeId),
      )
      .with(CustomTypeName.DATE, () =>
        current.custom_date_values.some(hasSameTypeId),
      )
      .exhaustive()

    if (hasValueForType) {
      return [...previous, current.id]
    }

    return previous
  }, [])

  return modelIds.length
}

export const hasCustomFieldValues = (customFieldValues: CustomValuesMap) => {
  const hasCustomFieldObject = typeof customFieldValues !== "undefined"

  if (!hasCustomFieldObject) {
    return false
  }

  const hasValuesForTypes = Object.keys(customFieldValues).reduce(
    (acc: boolean[], typeName: CustomTypeName) => {
      const hasValuesForType = match(typeName)
        .with(CustomTypeName.TEXT, () =>
          customFieldValues[CustomTypeName.TEXT].some((v) =>
            Boolean(v.value.trim()),
          ),
        )
        .with(CustomTypeName.SELECT, () =>
          Boolean(Object.keys(customFieldValues[CustomTypeName.SELECT]).length),
        )
        .with(CustomTypeName.CHECKBOX, () =>
          Boolean(customFieldValues[CustomTypeName.CHECKBOX].length),
        )
        .with(CustomTypeName.DATE, () =>
          customFieldValues[CustomTypeName.DATE].some((v) => Boolean(v.value)),
        )
        .exhaustive()

      return acc.concat(hasValuesForType)
    },
    [],
  )

  return hasValuesForTypes.some((v) => v)
}

export const hasCustomValuesShowInPlanner = (
  customFieldTypes: CustomType[],
  customFieldValues: CustomValuesMap,
) => {
  const hasShowInPlanner = customFieldTypes.some((v) => !!v.show_in_planner)

  if (!hasShowInPlanner) {
    return false
  }

  return hasCustomFieldValues(customFieldValues)
}

export const isCustomFieldNameExist = <
  T extends {
    readonly name: string
    readonly model: CustomFieldsModel
  },
>(
  customFieldTypes: readonly T[],
  model: Model,
  newName: string,
  oldName?: string,
) => {
  if (oldName?.trim().toLowerCase() === newName.trim().toLowerCase()) {
    return false
  }
  // Allow duplicate names for different models
  return customFieldTypes.some(
    (t) =>
      t.name.trim().toLowerCase() === newName.trim().toLowerCase() &&
      t.model === model,
  )
}

// Transform the scalar form processing format back into API format for saving,
// providing (near) symmetry between loading and saving data structures
export const updateValuesMap = (
  _valuesMap: CustomValuesMap,
  type: CustomType,
  fieldValue: CustomValue,
): CustomValuesMap => {
  const updatedValues = match(type.typeName)
    .with(CustomTypeName.TEXT, () => {
      return [
        ..._valuesMap[CustomTypeName.TEXT].filter((v) => v.typeId !== type.id),
        fieldValue as CustomTextValue,
      ]
    })
    .with(CustomTypeName.DATE, () => {
      return [
        ..._valuesMap[CustomTypeName.DATE].filter((v) => v.typeId !== type.id),
        fieldValue as CustomDateValue,
      ]
    })
    .with(CustomTypeName.SELECT, () => {
      // First remove all matching optionId values, then add them all back.
      // This covers deletions and new additions
      return [
        ..._valuesMap[CustomTypeName.SELECT].filter(
          (v) => v.typeId !== type.id,
        ),
        ...(fieldValue as CustomSelectValue[]),
      ]
    })
    .with(CustomTypeName.CHECKBOX, () => {
      return [
        ..._valuesMap[CustomTypeName.CHECKBOX].filter(
          (v) => v.typeId !== type.id,
        ),
        fieldValue as CustomCheckboxValue,
      ]
    })
    .exhaustive()

  return {
    ..._valuesMap,
    [type.typeName]: updatedValues,
  }
}

export const buildCustomFieldFormInput = (args: {
  data: FormData
  accountId: number
  options: ReadonlyArray<{
    id?: number
    name: string
  }>
  inputType: string
  filterType: CustomFieldsModel
  fieldId: number | null
  isSingleSelect: boolean
}): CustomFieldFormInput => {
  const {
    data,
    filterType,
    accountId,
    inputType,
    isSingleSelect,
    options,
    fieldId,
  } = args

  const input: CustomFieldFormInput = {
    name: (data.get("field-name") as string).trim(),
    description: data.get("description") as string,
    show_in_planner: data.get("includeDetailsOnPlanner") === "on",
    filterable_in_planner: data.get("filterableInPlanner") === "on",
    required: data.get("isRequiredField") === "on",
    model: filterType,
    account_id: accountId,
    custom_select_options: undefined,
    single_select: undefined,
  }

  if (inputType === "select") {
    input.single_select = isSingleSelect

    input.custom_select_options = options.map((option) => {
      const optionInput: CustomSelectOptionInput = {
        name: option.name.trim(),
        account_id: accountId,
        custom_select_type_id: fieldId,
      }
      if (option.id !== null) {
        optionInput.id = option.id
      }
      return optionInput
    })
  }

  return input
}

export const buildCustomSelectFilterOptions = (
  customSelectValues: FilterCustomSelectValues,
  customSelectTypes: FilterCustomSelectTypes,
): { name: string; typeId: number; list: number[] }[] => {
  const typeMap = new Map(customSelectTypes.map(({ id, name }) => [id, name]))
  const typeListMap = new Map<number, number[]>()

  for (const {
    custom_select_type_id: typeId,
    custom_select_option_id: optionId,
  } of customSelectValues) {
    if (!typeListMap.has(typeId)) {
      typeListMap.set(typeId, [])
    }

    typeListMap.get(typeId).push(optionId)
  }

  return Array.from(typeListMap, ([typeId, list]) => ({
    name: typeMap.get(typeId),
    typeId,
    list,
  }))
}

export const customFieldTypesCounter = (
  types: CustomFieldsMap<number, number, number, number>,
) => Object.values(types).reduce((acc, count) => acc + count, 0)
