import * as React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./ChangeProjectRoleModal.module.css"

import { ChangeProjectRoleModal_person$key } from "./__generated__/ChangeProjectRoleModal_person.graphql"
import { ChangeProjectRoleModal_project$key } from "./__generated__/ChangeProjectRoleModal_project.graphql"
import { ChangeProjectRoleModal_roles$key } from "./__generated__/ChangeProjectRoleModal_roles.graphql"

import { reportError } from "~/helpers/error-helpers"
import { formatName } from "~/helpers/person"

import Select from "../../../common/Select"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import SelectRoleSingleValue from "~/common/SelectRoleSingleValue"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import Button from "~/common/buttons/Button"
import { WarningIcon } from "~/common/react-icons"

import { useProjectMemberChangeRoleMutation } from "../../../mutations/ProjectMember"

import { showToast } from "~/containers/ToasterContainer"

type ChangeProjectRoleModalProps = {
  currentRoleId: number
  workstreamId: number | null
  closeDialog: () => void

  person: ChangeProjectRoleModal_person$key
  project: ChangeProjectRoleModal_project$key
  roles: ChangeProjectRoleModal_roles$key
}

const ChangeProjectRoleModal = (props: ChangeProjectRoleModalProps) => {
  const {
    currentRoleId,
    person: personKey,
    project: projectKey,
    roles: rolesKey,
    workstreamId,
    closeDialog,
  } = props

  const person = useFragment(
    graphql`
      fragment ChangeProjectRoleModal_person on people {
        id
        first_name
        last_name
        project_memberships {
          id
          role_id
          workstream_id
          project_id
          has_assignments
        }
      }
    `,
    personKey,
  )

  const project = useFragment(
    graphql`
      fragment ChangeProjectRoleModal_project on projects {
        id
        name
        members {
          id
          role_id
        }
      }
    `,
    projectKey,
  )

  const roles = useFragment(
    graphql`
      fragment ChangeProjectRoleModal_roles on roles @relay(plural: true) {
        id
        name
        active
      }
    `,
    rolesKey,
  )

  const currentRole = roles.find((role) => role.id === currentRoleId)
  const currentProjectRoleIds = person.project_memberships.map(
    (pm) => pm.project_id === project.id && pm.role_id,
  )

  const rolesInProject = project.members.map((member) => member.role_id)

  const { existingProjectRoles, projectRoleOptions } = roles.reduce(
    (acc, role) => {
      if (!role.active && !rolesInProject.includes(role.id)) {
        return acc
      }

      const existingProjectRole = currentProjectRoleIds.includes(role.id)

      const option = {
        value: role.id,
        label: role.name,
        isDisabled: existingProjectRole,
        active: role.active,
      }

      if (existingProjectRole) {
        acc.existingProjectRoles.push(option)
      } else {
        acc.projectRoleOptions.push(option)
      }

      return acc
    },
    { existingProjectRoles: [], projectRoleOptions: [] },
  )

  const options = [
    {
      label: "",
      options: projectRoleOptions,
    },
    {
      label: (
        <TitledHelpTooltip
          title="Existing Project Roles"
          tooltipContent="You cannot transfer a person to a role they already have on this project"
        />
      ),
      options: existingProjectRoles,
    },
  ]

  const [selectedRole, setSelectedRole] = React.useState<{
    value: number
    label: string
    active: boolean
  }>({
    value: currentRole.id,
    label: currentRole.name,
    active: currentRole.active,
  })

  const [projectMemberChangeRoleRelay, isInFlight] =
    useProjectMemberChangeRoleMutation()

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    window.userflow?.track("Project Role Change")

    try {
      await projectMemberChangeRoleRelay({
        variables: {
          personId: person.id,
          projectId: project.id,
          workstreamId,
          roleId: currentRoleId,
          newRoleId: selectedRole.value,
        },
      })
      showToast({
        type: "success",
        message: `${formatName(
          person.first_name,
          person.last_name,
        )}'s role has been updated to ${selectedRole.label}`,
        description: project.name,
      })
      closeDialog()
    } catch (error) {
      void reportError("Error changing project role", error)
    }
  }

  const hasAssignments = person.project_memberships.find(
    (pm) =>
      pm.role_id === currentRoleId &&
      pm.workstream_id === workstreamId &&
      pm.project_id === project.id,
  )?.has_assignments

  return (
    <>
      <ModalFormWrapper
        headerTitle={`Update ${person.first_name || "Person"}'s Project Role`}
      >
        <form onSubmit={handleSubmit}>
          <ModalBody className={styles.modalBody}>
            <Select
              label="Role"
              placeholder="Select a role"
              value={{ ...selectedRole, archived: !selectedRole.active }}
              options={options}
              menuPortalTarget={document.body}
              noOptionsMessage={() => <>No roles</>}
              onChange={(a) => {
                setSelectedRole(a)
              }}
              required
              components={{ SingleValue: SelectRoleSingleValue }}
            />
            {hasAssignments ? (
              <p className={styles.assignmentUpdateWarning}>
                <WarningIcon />
                All assignments and actuals will be updated to the new role
              </p>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={isInFlight}
              type="button"
              onClick={() => closeDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={isInFlight}
              outlined={false}
              disabled={isInFlight || selectedRole.value === currentRoleId}
            >
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalFormWrapper>
    </>
  )
}

export { ChangeProjectRoleModal }
