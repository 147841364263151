import { MenuDivider } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import React, { useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./PlannerDatePicker.module.css"

import { FullPicker_account$key } from "./__generated__/FullPicker_account.graphql"

import { getItemMetaData } from "~/helpers/CalendarHelper"
import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"

import DatePicker from "~/common/DatePicker/DatePicker"
import BlueLink from "~/common/buttons/BlueLink"
import { Client } from "~/common/calendar/DragToCreateOutline"
import Checkbox from "~/common/inputs/Checkbox"

import { plannerChangeDateRelay } from "~/mutations/Planner"

import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { setHighlightedItemData } from "~/GlobalState"

import EditAssignmentNote from "../EditAssignmentNote"

import PhaseSelector from "./PhaseSelector"
import PlannerDatePickerButtons from "./PlannerDatePickerButtons"
import PlannerDatePickerDayStats from "./PlannerDatePickerDayStats"
import PlannerDatePickerHeader from "./PlannerDatePickerHeader"
import PlannerDatePickerHourStats from "./PlannerDatePickerHourStats"
import TimeOffEffortInputs from "./TimeOffEffortInputs"

const FullPicker = (props: Props) => {
  const {
    allowDelete,
    client,
    handleSelectDates,
    handleCancel,
    handleSubmit,
    isTimeOff,
    nonWorkingDay,
    minutesPerDay,
    setMinutesPerDay,
    modifiers,
    multiPerson,
    onDelete,
    person,
    project,
    roleName,
    saveDisabled,
    selectedDates,
    totalWorkingDays,
    currentPhase,
    setCurrentPhase,
    phases,
    onChangeWorkingDays,
    timeOffs,
    isBillable,
    isNonBillableProject,
    setIsBillable,
    editNote,
    itemNote,
  } = props

  const account = useFragment(
    graphql`
      fragment FullPicker_account on accounts {
        default_full_time_minutes
        ...PlannerDatePickerHourStats_account
        ...TimeOffEffortInputs_account
      }
    `,
    props.account,
  )

  const [itemStartDate, setItemStartDate] = useState(selectedDates[0])

  const handleDatePickerChange = (values) => {
    if (values[0] === null) {
      // Ignore when they click off a date and wait for the to click a new date.
      return
    }
    // Allow user to select a single date
    const adjustedValues = values.length === 1 ? [values[0], values[0]] : values

    const newMetaData = getItemMetaData({
      startDate: adjustedValues[0],
      endDate: adjustedValues[1],
      timeOffs,
      isTimeOff,
      includeWeekends: false, // always false as we don't allow weekends to be selected in FullPicker
      nonWorkingDay: nonWorkingDay,
    })

    onChangeWorkingDays(
      adjustedValues[1] === null ? null : newMetaData.totalWorkingDays,
    )

    handleSelectDates(adjustedValues)
  }

  /*
    This function gets historical data to avoid issues where Runn can't merge data because it doesnt have past assignmetns.
    Basically when someone clicks a date that is before the planner start date, and before the assignment
    we load the data for this date. Then when they save, we can corrrect handle any conflicts.
    See: https://github.com/Runn-Fast/runn/issues/6626
   */
  useEffect(() => {
    if (!selectedDates[0]) {
      return
    }
    const isBeforePlannerStartDate =
      Number(dateHelpers.formatToRunnDate(selectedDates[0])) <
      Number(PLANNER_INITIAL_DATE)
    const isBeingChangedBackwards =
      !itemStartDate ||
      Number(dateHelpers.formatToRunnDate(selectedDates[0])) <
        Number(dateHelpers.formatToRunnDate(itemStartDate))

    if (isBeforePlannerStartDate && isBeingChangedBackwards) {
      void plannerChangeDateRelay({
        fromDate: dateHelpers.formatToRunnDate(selectedDates[0]),
        toDate: PLANNER_INITIAL_DATE,
      })
      setItemStartDate(selectedDates[0])
    }
  }, [selectedDates[0]]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBillableToggle = () => setIsBillable(!isBillable)

  const showDelete = onDelete && allowDelete

  const [total, setTotal] = useState<{
    value: number
    label: string
  }>({
    value: totalWorkingDays.value * minutesPerDay,
    label: totalWorkingDays.label,
  })
  const [hoursPerDayError, setHoursPerDayError] = useState(false)

  const contractMinutes = getMinutesPerDayForDate(
    dateHelpers.formatToRunnDate(selectedDates[0]),
    person.contracts,
    account.default_full_time_minutes,
  )

  const handleMinutesChange = (minutes, error) => {
    setMinutesPerDay(Math.round(minutes))
    setHoursPerDayError(error)
  }

  useEffect(
    () => {
      setTotal({
        value: totalWorkingDays.value * minutesPerDay,
        label: totalWorkingDays.label,
      })

      return () => {
        if (hoursPerDayError) {
          setMinutesPerDay(minutesPerDay)
        }
        setHoursPerDayError(false)
        setHighlightedItemData(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalWorkingDays, minutesPerDay],
  )

  const hasMultiplePeople = multiPerson && multiPerson.length > 1
  const showPartialTimeOff = isTimeOff && !hasMultiplePeople

  return (
    <>
      <PlannerDatePickerHeader
        multiPerson={multiPerson}
        person={person}
        client={client}
        project={project}
        roleName={roleName}
        isTimeOff={isTimeOff}
      />
      <div className={styles.innerWrapper}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DatePicker
            value={selectedDates}
            onChange={handleDatePickerChange}
            modifiers={modifiers}
            mode="range"
            selectSameDate={true}
          />
        </div>
        <div
          className={cc([
            styles.statsWrapper,
            {
              [styles.spaceBetween]:
                (phases && phases.length !== 0) || showPartialTimeOff,
            },
          ])}
        >
          <PlannerDatePickerDayStats
            selectedDates={selectedDates}
            totalWorkingDays={totalWorkingDays.label}
            onChangeWorkingDays={onChangeWorkingDays}
            handleDatePickerChange={handleDatePickerChange}
          />
          {phases && phases.length !== 0 && (
            <div className={styles.phasePicker}>
              <label>Phase</label>
              <PhaseSelector
                currentPhase={currentPhase}
                phases={project.phases}
                onPhaseChange={setCurrentPhase}
              />
            </div>
          )}
          {showPartialTimeOff && (
            <TimeOffEffortInputs
              onMinutesPerDayChange={handleMinutesChange}
              personFirstName={person.first_name}
              maxMinutesPerDay={contractMinutes}
              total={total}
              account={account}
              minutesPerDay={minutesPerDay}
              onChangeWorkingDays={onChangeWorkingDays}
              selectedDates={selectedDates}
              totalWorkingDays={totalWorkingDays}
              hasWorkingDays={false}
            />
          )}
        </div>
        {isTimeOff && (
          <div className={styles.fullPickerNoteWrapper}>
            <MenuDivider className={styles.fullPickerNoteDivider} />
            <EditAssignmentNote note={itemNote} editNote={editNote} />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <PlannerDatePickerHourStats
          account={account}
          isTimeOff={isTimeOff}
          minutesPerDay={minutesPerDay}
          setMinutesPerDay={setMinutesPerDay}
          totalWorkingDays={totalWorkingDays}
          contractMinutes={contractMinutes}
        />
        <div className={styles.flex}>
          {!isNonBillableProject && !isTimeOff && (
            <span className={styles.nonBillableCheckbox}>
              <Checkbox
                id="FullPicker_Checkbox_nonBillable"
                onChange={handleBillableToggle}
                checked={!isBillable}
                value="nonBillable"
                label="Non-Billable"
              />
            </span>
          )}
          {showDelete && <BlueLink text="Delete" onClick={onDelete} />}
          <PlannerDatePickerButtons
            onSave={handleSubmit}
            onDelete={onDelete}
            showDelete={showDelete}
            saveDisabled={saveDisabled}
            handleCancel={handleCancel}
          />
        </div>
      </div>
    </>
  )
}

type Props = {
  account: FullPicker_account$key
  allowDelete?: boolean
  client?: Client
  handleSelectDates: (values: Date[]) => void
  handleCancel: () => void
  handleSubmit: () => void
  nonWorkingDay?: boolean
  isTimeOff: boolean
  minutesPerDay: number
  modifiers?: any
  multiPerson?: Person[]
  onDelete?: () => void
  person: Person
  project?: any
  roleName?: string
  saveDisabled: boolean
  selectedDates: [Date, Date]
  setMinutesPerDay: (minutes: number) => void
  totalWorkingDays: {
    value: number
    label: string
  }
  currentPhase: number
  setCurrentPhase: (phase: Phase) => void
  phases: ReadonlyArray<{
    id: number
    name: string
    color: string
  }>
  onChangeWorkingDays: (days: number) => void
  timeOffs: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    leave_type: string
    minutes_per_day: number
  }>

  isBillable: boolean
  isNonBillableProject: boolean
  setIsBillable: (isBillable: boolean) => void
  editNote?: (note: string) => void
  itemNote?: string
  timeOff?: TimeOff
}

type Person = {
  id: number
  contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      id: number
      name: string
    }
  }>
  first_name: string
  last_name: string
  email?: string
  assignments?: any
  initials?: string
  is_placeholder?: boolean
  avatar?: string
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  note?: string
  leave_type: string
}

type Phase = {
  value: number
  label: string
  color: string
}

export default FullPicker
