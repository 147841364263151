/**
 * @generated SignedSource<<c127777e4c17d4e5e1115f02a2584b46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitScreenRow_user$data = {
  readonly account: {
    readonly custom_checkbox_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_date_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_select_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_text_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly id: number;
    readonly roles: ReadonlyArray<{
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"PersonSummaryGraph_account" | "PlaceholderSidePanel_account">;
  };
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanel_user">;
  readonly " $fragmentType": "SplitScreenRow_user";
};
export type SplitScreenRow_user$key = {
  readonly " $data"?: SplitScreenRow_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitScreenRow_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PERSON"
      }
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitScreenRow_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonSidePanel_user"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": "custom_text_types_person",
          "args": (v3/*: any*/),
          "concreteType": "custom_text_types",
          "kind": "LinkedField",
          "name": "custom_text_types",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_date_types_person",
          "args": (v3/*: any*/),
          "concreteType": "custom_date_types",
          "kind": "LinkedField",
          "name": "custom_date_types",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_select_types_person",
          "args": (v3/*: any*/),
          "concreteType": "custom_select_types",
          "kind": "LinkedField",
          "name": "custom_select_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "options",
              "args": null,
              "concreteType": "custom_select_options",
              "kind": "LinkedField",
              "name": "custom_select_options",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_checkbox_types_person",
          "args": (v3/*: any*/),
          "concreteType": "custom_checkbox_types",
          "kind": "LinkedField",
          "name": "custom_checkbox_types",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonSummaryGraph_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlaceholderSidePanel_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "cc9613c3c0f9c481150e565db18199f7";

export default node;
