import React from "react"
import { components } from "react-select"

import styles from "./SelectOptionAdd.module.css"

import { Plus } from "~/common/react-icons"

const SelectOptionAdd = (optionProps) => {
  const { data } = optionProps
  const isNew = data.__isNew__

  return (
    <components.Option {...optionProps} className={isNew && styles.newOption}>
      <div className={styles.optionSelect}>
        {isNew && (
          <div className={styles.iconWrapper}>
            <Plus />
          </div>
        )}
        <span>{data.label}</span>
      </div>
    </components.Option>
  )
}

export default SelectOptionAdd
