import { Tooltip } from "@blueprintjs/core"
import React from "react"

import Legend from "~/Planner/ChartPanel/Chart/common/Legend"

type Props = {
  includeTentative?: boolean
}

const CONTRACTED_CAPACITY_TOOLTIP = "Total hours based on a person's contract"
const EFFECTIVE_CAPACITY_TOOLTIP =
  "Total time available for work (contracted capacity - time off)"

const LegendCapacity = (props: Props) => {
  const { includeTentative } = props

  return (
    <Legend>
      <Legend.Item background="var(--runn-blue)" square>
        Confirmed Workload
      </Legend.Item>
      {includeTentative && (
        <Legend.Item background="var(--sky-blue)" square>
          Tentative Workload
        </Legend.Item>
      )}
      <Tooltip content={EFFECTIVE_CAPACITY_TOOLTIP} placement="top">
        <Legend.Item background="rgba(var(--runn-purple_rgb), 0.4)">
          Effective Capacity
        </Legend.Item>
      </Tooltip>
      <Tooltip content={CONTRACTED_CAPACITY_TOOLTIP} placement="top">
        <Legend.Item background="var(--shadow)">
          Contracted Capacity
        </Legend.Item>
      </Tooltip>
      <Legend.Item background="var(--chart-stripes-legend)">
        Time Off
      </Legend.Item>
    </Legend>
  )
}

export { LegendCapacity }
