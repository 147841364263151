/**
 * @generated SignedSource<<61619f7412ebeccf900686dcf8c15643>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonTimeOffRow_person$data = {
  readonly assignments: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly is_billable: boolean | null | undefined;
    readonly is_template: boolean;
    readonly minutes_per_day: number;
    readonly non_working_day: boolean;
    readonly note: string | null | undefined;
    readonly person_id: number;
    readonly phase_id: number | null | undefined;
    readonly project_id: number;
    readonly role_id: number;
    readonly start_date: string | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly contracts: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly minutes_per_day: number | null | undefined;
    readonly role: {
      readonly id: number;
      readonly name: string | null | undefined;
    };
    readonly start_date: string | null | undefined;
  }>;
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly holidays_group: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
  readonly id: number;
  readonly image_key: string | null | undefined;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly time_offs: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly ext_time_off_links: ReadonlyArray<{
      readonly active: boolean;
      readonly ext_time_off_id: number;
      readonly external: {
        readonly __typename: "ext_time_off";
        readonly id: number;
        readonly integration_id: number;
      } | null | undefined;
      readonly external_id: number;
      readonly time_off_id: number;
    }>;
    readonly id: number;
    readonly leave_type: string;
    readonly minutes_per_day: number | null | undefined;
    readonly note: string | null | undefined;
    readonly person_id: number;
    readonly start_date: string | null | undefined;
  }>;
  readonly " $fragmentType": "PersonTimeOffRow_person";
};
export type PersonTimeOffRow_person$key = {
  readonly " $data"?: PersonTimeOffRow_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonTimeOffRow_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v3 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v7 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonTimeOffRow_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "time_offs",
      "kind": "LinkedField",
      "name": "time_offs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leave_type",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                }
              }
            }
          ],
          "concreteType": "ext_time_off_link",
          "kind": "LinkedField",
          "name": "ext_time_off_links",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time_off_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "external_id",
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            {
              "alias": "external",
              "args": null,
              "concreteType": "ext_time_off",
              "kind": "LinkedField",
              "name": "ext_time_off",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "integration_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "assignments",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phase_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_billable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_template",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "non_working_day",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "holidays_groups",
      "kind": "LinkedField",
      "name": "holidays_group",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "d8dc37dca946af3c326dc5a32a651326";

export default node;
