/**
 * @generated SignedSource<<36d65a71d3451ec42b033d5647397443>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GroupUtilizationBarRelayWrapperQuery$variables = {
  ids?: ReadonlyArray<number> | null | undefined;
  plannerStartDate: any;
};
export type GroupUtilizationBarRelayWrapperQuery$data = {
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly assignments: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly is_billable: boolean | null | undefined;
      readonly minutes_per_day: number;
      readonly non_working_day: boolean;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
    }>;
    readonly contracts: ReadonlyArray<{
      readonly employment_type: string;
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly minutes_per_day: number | null | undefined;
      readonly role: {
        readonly id: number;
        readonly name: string | null | undefined;
      };
      readonly role_id: number;
      readonly start_date: string | null | undefined;
    }>;
    readonly id: number;
    readonly is_placeholder: boolean;
    readonly tags: any;
    readonly team: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
    readonly time_offs: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly ext_time_off_links: ReadonlyArray<{
        readonly active: boolean;
        readonly ext_time_off_id: number;
        readonly external: {
          readonly __typename: "ext_time_off";
          readonly id: number;
          readonly integration_id: number;
        } | null | undefined;
        readonly external_id: number;
        readonly time_off_id: number;
      }>;
      readonly id: number;
      readonly leave_type: string;
      readonly minutes_per_day: number | null | undefined;
      readonly person_id: number;
      readonly start_date: string | null | undefined;
    }>;
  }>;
};
export type GroupUtilizationBarRelayWrapperQuery = {
  response: GroupUtilizationBarRelayWrapperQuery$data;
  variables: GroupUtilizationBarRelayWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "plannerStartDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v5 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v6 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v8 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "people",
    "kind": "LinkedField",
    "name": "people",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_placeholder",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "teams",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "contracts",
        "kind": "LinkedField",
        "name": "contracts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employment_type",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "roles",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "time_offs",
        "kind": "LinkedField",
        "name": "time_offs",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v9/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "leave_type",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  }
                }
              }
            ],
            "concreteType": "ext_time_off_link",
            "kind": "LinkedField",
            "name": "ext_time_off_links",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time_off_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ext_time_off_id",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": "external_id",
                "args": null,
                "kind": "ScalarField",
                "name": "ext_time_off_id",
                "storageKey": null
              },
              {
                "alias": "external",
                "args": null,
                "concreteType": "ext_time_off",
                "kind": "LinkedField",
                "name": "ext_time_off",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integration_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "assignments",
        "kind": "LinkedField",
        "name": "assignments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          (v9/*: any*/),
          (v7/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_billable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "non_working_day",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupUtilizationBarRelayWrapperQuery",
    "selections": (v10/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupUtilizationBarRelayWrapperQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "588309639dfc80bf062f92d323df0d3d",
    "id": null,
    "metadata": {},
    "name": "GroupUtilizationBarRelayWrapperQuery",
    "operationKind": "query",
    "text": "query GroupUtilizationBarRelayWrapperQuery(\n  $ids: [Int!]\n  $plannerStartDate: date!\n) {\n  people(where: {id: {_in: $ids}}) {\n    id\n    is_placeholder\n    active\n    tags\n    team {\n      id\n      name\n    }\n    contracts {\n      id\n      minutes_per_day\n      start_date: start_date_runn\n      end_date: end_date_runn\n      employment_type\n      role_id\n      role {\n        id\n        name\n      }\n    }\n    time_offs(where: {end_date_iso: {_gte: $plannerStartDate}}) {\n      id\n      person_id\n      start_date: start_date_runn\n      end_date: end_date_runn\n      leave_type\n      minutes_per_day\n      ext_time_off_links(where: {active: {_eq: true}}) {\n        time_off_id\n        ext_time_off_id\n        active\n        external_id: ext_time_off_id\n        external: ext_time_off {\n          __typename\n          id\n          integration_id\n        }\n      }\n    }\n    assignments(where: {end_date_iso: {_gte: $plannerStartDate}}) {\n      id\n      project_id\n      person_id\n      role_id\n      start_date: start_date_runn\n      end_date: end_date_runn\n      minutes_per_day\n      is_billable\n      non_working_day\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4925180312db95ff291d706af24b421c";

export default node;
