import { Tooltip } from "@blueprintjs/core"
import { toFullTimeEquivalentEffort } from "@runn/calculations"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetLine.module.css"

import { ProjectBudgetDays_project$key } from "~/ProjectPlanner/ProjectBudgetLine/__generated__/ProjectBudgetDays_project.graphql"

type Props = {
  isBillable: boolean
  project: ProjectBudgetDays_project$key
}

const ProjectBudgetDays = (props: Props) => {
  const { isBillable } = props

  const project = useFragment(
    graphql`
      fragment ProjectBudgetDays_project on projects {
        id
        account {
          id
          default_full_time_minutes
        }
        summary {
          role_id
          total_billable_minutes
          total_nonbillable_minutes
        }
        project_roles {
          id
          role_id
          estimated_minutes
        }
      }
    `,
    props.project,
  )

  const budgetMinutes = (project.project_roles || []).reduce(
    (acc, pr) => (pr.estimated_minutes ?? 0) + acc,
    0,
  )
  const billableMinutes = (project.summary || []).reduce(
    (acc, s) => (s.total_billable_minutes ?? 0) + acc,
    0,
  )
  const nonbillableMinutes = (project.summary || []).reduce(
    (acc, s) => (s.total_nonbillable_minutes ?? 0) + acc,
    0,
  )

  const minutes = isBillable ? billableMinutes : nonbillableMinutes
  const timeBudgetUsed = (minutes / budgetMinutes) * 100
  const budgetUsedPercentage = timeBudgetUsed > 100 ? 100 : timeBudgetUsed
  const overBudgetPercentage = timeBudgetUsed > 100 ? timeBudgetUsed - 100 : 0
  const overBudgetPercentageFixed =
    overBudgetPercentage > 100 ? 100 : overBudgetPercentage

  const days = toFullTimeEquivalentEffort({
    minutesOfEffort: minutes,
    fulltimeMinutesPerDay: project.account.default_full_time_minutes,
  })

  const budgetDays = toFullTimeEquivalentEffort({
    minutesOfEffort: budgetMinutes,
    fulltimeMinutesPerDay: project.account.default_full_time_minutes,
  })

  const TooltipContent = () => (
    <div>
      <b>{isBillable ? "Billable" : "Non-billable"} Days</b>
      <br />
      Actual + Scheduled: {days}d
      {!!budgetMinutes && (
        <>
          <br />
          Budget: {budgetDays}d
        </>
      )}
      {!!budgetMinutes && (
        <>
          <br />
          Budget Allocated: {timeBudgetUsed.toFixed(2)}%
        </>
      )}
    </div>
  )

  return (
    <>
      <div className={styles.lineContainer}>
        {!!budgetMinutes && (
          <Tooltip content={<TooltipContent />} placement="top">
            <div className={styles.budgetLine}>
              <div
                className={styles.budgetUsedLine}
                style={{ width: `${budgetUsedPercentage}%` }}
              ></div>
              <div
                className={styles.budgetOverLine}
                style={{ width: `${overBudgetPercentageFixed}%` }}
              ></div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.textContainer}>
        <Tooltip content={<TooltipContent />}>
          <span className={styles.text}>
            <span
              className={`${timeBudgetUsed > 100 ? styles.overbudgetText : ""}`}
            >
              {`${days}d`}
            </span>
            {!!budgetMinutes && <> / {budgetDays}d</>}
          </span>
        </Tooltip>
      </div>
    </>
  )
}

export default ProjectBudgetDays
