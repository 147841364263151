import React, { useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useDebouncedCallback } from "use-debounce"
import isURL from "validator/lib/isURL"

import {
  ClientForm_viewer$data,
  ClientForm_viewer$key,
} from "./__generated__/ClientForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import * as hashids from "~/helpers/hashids"
import { viewClientUrl } from "~/helpers/routes"

import Input from "~/common/Input"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import ReferencesTable from "~/common/ReferencesTable"
import UniqueInput from "~/common/UniqueInput"
import Button from "~/common/buttons/Button"

import { clientCreateRelay, clientUpdateRelay } from "~/mutations/Client"

export type ReturnClient = {
  id: number
  name: string
}

// Not using Relay fragments to allow create and update via the same form
type EditClient = {
  id: number
  name: string
  website: string
  references: any
}

type Props = {
  onCancel?: () => void
  onSuccess?: (client: ReturnClient) => void
  client?: EditClient
}

const fragment = graphql`
  fragment ClientForm_viewer on users {
    account {
      id
      name
      clients {
        id
        active
        name
      }
    }
  }
`

const ClientForm = (props: Props) => {
  const { onCancel, onSuccess, client } = props

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<ClientForm_viewer$key>(fragment, viewerQuery)
  const account = viewer.account
  const { clients } = account

  const [isSaving, setIsSaving] = useState(false)
  const [clientName, setClientName] = useState(client?.name || "")
  const [clientWebsite, setClientWebsite] = useState(client?.website || "")
  const [websiteIsInvalid, setWebsiteIsInvalid] = useState(false)
  const [references, setReferences] = useState(client?.references || {})

  // Don't allow editing of the "internal client"
  const isInternal = client && client.name === account.name

  const normalizeWebsite = (url: string) => {
    url = url.trim()
    // make sure that the urlString starts with `https://`
    if (url !== "" && /^\https?:\/\//.test(url) === false) {
      url = `https://${url}`
    }
    return url
  }

  const handleWebsiteValidation = (url: string) => {
    const isValid = isURL(normalizeWebsite(url))
    setWebsiteIsInvalid(!isValid)
  }

  const setWebsiteDebounced = useDebouncedCallback((url: string) => {
    handleWebsiteValidation(url)
  }, 300)

  useEffect(() => {
    if (clientWebsite) {
      setWebsiteDebounced(clientWebsite)
    } else {
      setWebsiteDebounced.cancel()
      setWebsiteIsInvalid(false)
    }
  }, [clientWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    setIsSaving(true)

    const referenceArr = Object.entries(references).map(([name, data]) => {
      return {
        referenceName: name,
        externalId: (data as { external_id: string }).external_id,
      }
    })

    const clientInput = {
      name: clientName.trim(),
      website: normalizeWebsite(clientWebsite),
      references: referenceArr.map((r) => ({
        referenceName: r.referenceName,
        // Convert to string to avoid GraphQL error caused by Harvest integration importing the external ID as a number
        externalId: r.externalId.toString(),
      })),
    }

    try {
      const updatedClient = client
        ? await clientUpdateRelay({
            variables: { ...clientInput, id: client.id },
          })
        : await clientCreateRelay({
            variables: {
              ...clientInput,
            },
          })
      onSuccess(updatedClient.client as ReturnClient)
    } catch {
      setIsSaving(false)
    }
  }

  const [existingClient, setExistingClient] =
    useState<ClientForm_viewer$data["account"]["clients"][0]>(undefined)

  const handleClientName = (e) => {
    const name = e.target.value
    const duplicateClient =
      clients &&
      clients.find(
        (c) =>
          c.name.toLowerCase().trim() === name.toLowerCase().trim() &&
          c.id !== client?.id,
      )
    setClientName(name)
    setExistingClient(duplicateClient)
  }

  return (
    <>
      <ModalBody>
        <UniqueInput
          name="client-name"
          id="client-name"
          duplicate={
            existingClient && {
              name: existingClient.name,
              link: viewClientUrl(hashids.clients.encode(existingClient.id)),
            }
          }
          label="Client Name"
          value={clientName}
          onChange={handleClientName}
          disabled={isInternal}
          autoComplete="off"
          autoFocus
          style={{ marginBottom: "15px" }}
        />
        <Input
          name="client-website"
          id="client-website"
          label="Client Website"
          value={clientWebsite}
          error={websiteIsInvalid}
          onChange={(e) => setClientWebsite(e.target.value)}
          autoComplete="off"
          style={{ marginBottom: "15px" }}
          optional
        />
        <ReferencesTable
          dataType="Client"
          references={references}
          setUpdatedReferences={setReferences}
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={() => onCancel()} />
        <Button
          text={!client ? "Create" : "Save"}
          loading={isSaving}
          id="create-client-button"
          outlined={false}
          onClick={handleSubmit}
          disabled={
            isSaving ||
            !clientName.trim() ||
            websiteIsInvalid ||
            !!existingClient
          }
          style={{ marginLeft: 7 }}
        />
      </ModalFooter>
    </>
  )
}

export default ClientForm
