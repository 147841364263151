import { toFullTimeEquivalentEffort } from "@runn/calculations"
import { hoursToMinutes } from "date-fns"
import * as React from "react"

type FTELabelProps = {
  minutesPerDay: number
  defaultFullTimeMinutes: number
  pillWidth: number
}

export const FTELabel = ({
  minutesPerDay,
  defaultFullTimeMinutes = hoursToMinutes(8),
  pillWidth,
}: FTELabelProps) => {
  const fte = toFullTimeEquivalentEffort({
    minutesOfEffort: minutesPerDay,
    fulltimeMinutesPerDay: defaultFullTimeMinutes,
  })

  if (pillWidth < 80) {
    return <>{fte}</>
  }

  return <>{fte} FTE</>
}
