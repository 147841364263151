import { Tooltip } from "@blueprintjs/core"
import capitalize from "lodash-es/capitalize"
import React from "react"

import styles from "./SidePanel.module.css"

import ClockifyLogo from "@/images/integrations/clockify-icon.png"
import DefaultIcon from "@/images/integrations/default-icon.png"
import HarvestLogo from "@/images/integrations/harvest-icon.png"
import WorkflowmaxLogo from "@/images/integrations/wfm-icon.png"

import { References } from "~/helpers/unknown-types"

type Props = {
  references: References
}

const getIcon = (integrationName: string) => {
  switch (integrationName) {
    case "Harvest":
      return HarvestLogo
    case "Clockify":
      return ClockifyLogo
    case "Workflowmax":
      return WorkflowmaxLogo
    default:
      return DefaultIcon
  }
}

const SidePanelReferences = (props: Props) => {
  const { references } = props
  return (
    <>
      <h4 className={styles.detailsTitle}>External References</h4>
      <div className={`${styles.detailsValue} ${styles.referencesContainer}`}>
        {Object.entries(references).map(
          ([integrationName, { external_id }]) => (
            <Tooltip
              key={external_id}
              content={`${integrationName}: ${external_id}`}
              position="top"
            >
              <div className={styles.reference}>
                <img
                  src={getIcon(integrationName)}
                  width={16}
                  alt={capitalize(integrationName)}
                  className={styles.referenceIcon}
                />
                <div>{external_id || "Not linked"}</div>
              </div>
            </Tooltip>
          ),
        )}
      </div>
    </>
  )
}

export default SidePanelReferences
