import { Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { format } from "date-fns"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import styles from "./SkinnyPill.module.css"

import { getItemOffsetPercentNum } from "~/helpers/CalendarHelper"
import { formatDuration } from "~/helpers/DurationHelper"
import {
  ASSIGNMENT_AND_PHASE_COLORS,
  LIGHT_VIOLET,
  SKY_BLUE,
} from "~/helpers/colors"

import { setHighlightedCellData } from "~/GlobalState"
import useCalendarWeekends from "~/hooks/useCalendarWeekends"
import { ReduxState } from "~/rootReducer"

type Props = {
  weekend: Assignment[]
  phases: ReadonlyArray<Phase>
  isTentative: boolean
}

type Phase = {
  id: number
  color: string
}

type Assignment = {
  start_date: string
  phase_id: number
  minutes_per_day: number
}

const SkinnyPill = (props: Props) => {
  const { weekend, phases, isTentative } = props
  const calendarStartDate: Date = useSelector(
    (state: ReduxState) => state.calendar.calendarStartDate,
  )
  const calendarEndDate: Date = useSelector(
    (state: ReduxState) => state.calendar.calendarEndDate,
  )
  const calendarView = useSelector(
    (state: ReduxState) => state.calendar.calendarView,
  )

  const [isHovering, setIsHovering] = useState(false)

  const dispatch = useDispatch()
  const { expandWeekends } = useCalendarWeekends()

  const fullWeekend = weekend.length === 2

  const offset = getItemOffsetPercentNum(
    calendarStartDate,
    calendarEndDate,
    weekend[0],
    false, // always false as weekends aren't expanded if skinnyPill shows
    true,
  )

  const handleMouseEnter = () => {
    setIsHovering(true)
    dispatch(
      setHighlightedCellData({
        type: "weekend",
        day: dateHelpers.parseRunnDate(weekend[0].start_date),
      }),
    )
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
    dispatch(setHighlightedCellData(null))
  }

  const getPillColor = (phaseId) => {
    const phase = phases.find((p) => p.id === phaseId)
    if (!phaseId) {
      return isTentative ? SKY_BLUE : LIGHT_VIOLET
    }
    return ASSIGNMENT_AND_PHASE_COLORS.find((p) => phase.color === p.color)
  }

  const getDayData = (assignment: Assignment) => (
    <span>
      <b>
        {format(dateHelpers.parseRunnDate(assignment.start_date), "EEE d MMM")}:
      </b>{" "}
      {formatDuration(assignment.minutes_per_day)}
      <br />
    </span>
  )

  const ColoredPill = () => {
    const firstPillColor = getPillColor(weekend[0].phase_id)
    const filter = isHovering ? "brightness(80%)" : ""

    if (fullWeekend) {
      const secondPillColor = getPillColor(weekend[1].phase_id)

      return (
        <div
          className={styles.skinnyPill}
          style={{ backgroundColor: secondPillColor.pillLabel, filter }}
        >
          <div
            className={styles.firstPill}
            style={{ backgroundColor: firstPillColor.pillLabel }}
          />
        </div>
      )
    }
    return (
      <div
        className={styles.skinnyPill}
        style={{ backgroundColor: firstPillColor.pillLabel, filter }}
      />
    )
  }

  return (
    <>
      <div
        className={styles.skinnyPillContainer}
        style={{ left: `calc(${offset}% - 2px)` }}
        onClick={() => expandWeekends(weekend[0].start_date)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ColoredPill />
        <Tooltip
          content={
            <div>
              {getDayData(weekend[0])}
              {fullWeekend && getDayData(weekend[1])}
              <br />
              {`Click to expand weekends${
                calendarView.type === "months" && calendarView.amount !== 1
                  ? " in month view"
                  : ""
              }`}
            </div>
          }
          popoverClassName={styles.tooltip}
          placement="top"
        >
          {/*
            We have this transparent pill to go over assignment pillLabels
            and also acts as the hover area
          */}
          <div
            className={styles.skinnyPill}
            style={{
              backgroundColor: "transparent",
              zIndex: 30,
            }}
          />
        </Tooltip>
      </div>
    </>
  )
}

export default SkinnyPill
