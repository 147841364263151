import { Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { isWeekend } from "date-fns"
import React, { useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PlannerCalendarRow.module.css"

import { PlannerCalendarRow_account$key } from "./__generated__/PlannerCalendarRow_account.graphql"

import { groupSameWeekends } from "~/helpers/CalendarHelper"
import { formatName } from "~/helpers/person"

import ConflictWarning, { conflictToLabel } from "~/common/ConflictWarning"
import OutOfContractCurtains from "~/common/OutOfContractCurtains/OutOfContractCurtains"
import AssignmentActionPill from "~/common/Pill/AssignmentActionPill"
import SkinnyPill from "~/common/Pill/SkinnyPill"
import TimeOffCurtain from "~/common/TimeOffCurtain/TimeOffCurtain"
import CalendarOutline from "~/common/calendar/CalendarOutline"
import DragToCreateOutline from "~/common/calendar/DragToCreateOutline"
import { Warning } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"
import { ReduxState } from "~/rootReducer"

const PlannerCalendarRow = (props: Props) => {
  const {
    companyDefaultMinutes,
    person,
    project,
    assignments,
    roleId,
    workstreamId = null,
    client,
    contracts,
    timeOffs,
    delayAssignmentRender,
    timeOffsWithWeekend,
    holidaysOverlappingTimeOffs,
    rowType,
  } = props

  const account = useFragment(
    graphql`
      fragment PlannerCalendarRow_account on accounts {
        id
        default_full_time_minutes
        ...DragToCreateOutline_account
        ...AssignmentActionPill_account
      }
    `,
    props.account,
  )
  const { can, subject } = usePermissions()
  const canCreate = can(
    "create",
    subject("Assignment", {
      project: {
        id: project.id,
        isTemplate: project.is_template,
      },
    }),
  )

  const calendarWeekendsExpanded = useSelector<ReduxState, boolean>(
    (state) => state.calendar.calendarWeekendsExpanded,
  )
  const calStartNum = useSelector<ReduxState, number>(
    (state) => state.calendar.calStartNum,
  )
  const calEndNum = useSelector<ReduxState, number>(
    (state) => state.calendar.calEndNum,
  )

  const [renderPlannerCalendarRow, setRenderPlannerCalendarRow] = useState(
    !delayAssignmentRender,
  )
  // Block concurrent updating of assignments to avoid race conditions and overlapping assignments
  const [isUpdatingAssignment, setIsUpdatingAssignment] = useState(false)

  useLayoutEffect(() => {
    if (renderPlannerCalendarRow === false) {
      const timeOut = setTimeout(() => {
        setRenderPlannerCalendarRow(true)
      }, 50)
      return () => clearTimeout(timeOut)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (renderPlannerCalendarRow === false) {
    return <CalendarOutline type="standard" />
  }

  const visibleAssignments = calendarWeekendsExpanded
    ? assignments
    : assignments.filter(
        (a) =>
          !a.non_working_day ||
          (a.non_working_day &&
            !isWeekend(dateHelpers.parseRunnDate(a.start_date))),
      )

  const groupedWeekends = groupSameWeekends(
    assignments,
    calStartNum,
    calEndNum,
    calendarWeekendsExpanded,
  )

  const timeOffsToBlock = timeOffs.filter(
    (timeOff) => timeOff.leave_type !== "holiday",
  )

  const overlapWarningPosition =
    rowType === "person" ? "-50px" : project.confirmed ? "-30px" : "-70px"

  return (
    <>
      {!person.is_placeholder && !project.is_template && (
        <ConflictWarning
          className={styles.warning}
          assignments={visibleAssignments}
          timeOffs={timeOffs}
          customLeftPosition={overlapWarningPosition}
          contracts={contracts}
        >
          {({ jumpToAssignment, conflict }) => (
            <Tooltip
              usePortal={true}
              hoverOpenDelay={500}
              popoverClassName={styles.warningToolTip}
              content={
                <span>
                  {conflictToLabel(
                    conflict,
                    formatName(person.first_name, person.last_name),
                  )}{" "}
                  <br />
                  Click to view next conflicts.
                </span>
              }
            >
              <span onClick={jumpToAssignment}>
                <Warning />
              </span>
            </Tooltip>
          )}
        </ConflictWarning>
      )}
      <div className={styles.plannerCalendarRow}>
        <DragToCreateOutline
          account={account}
          type="assignment"
          person={person}
          roleId={roleId}
          workstreamId={workstreamId}
          project={project}
          client={client}
          companyDefaultMinutes={companyDefaultMinutes}
          timeOffsWithWeekend={timeOffsWithWeekend}
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          canCreate={canCreate}
        />
        {groupedWeekends.map((weekend) => (
          <SkinnyPill
            key={weekend[0].start_date}
            weekend={weekend}
            phases={project.phases}
            isTentative={!project.confirmed}
          />
        ))}
        {visibleAssignments.map((a) => (
          <AssignmentActionPill
            key={a.id}
            account={account}
            assignment={a}
            person={person}
            phases={project.phases}
            unconfirmed={!project.confirmed}
            project={project}
            client={client}
            renderBasic={renderPlannerCalendarRow}
            defaultFullTimeMinutes={companyDefaultMinutes}
            isUpdatingAssignment={isUpdatingAssignment}
            setIsUpdatingAssignment={setIsUpdatingAssignment}
          />
        ))}
        {!person.is_placeholder && !project.is_template && (
          <>
            {timeOffsToBlock.map((to) => (
              <TimeOffCurtain
                key={to.id}
                timeOff={to}
                contracts={person.contracts}
                accountDefaultFullTimeMinutes={
                  account.default_full_time_minutes
                }
              />
            ))}
            <OutOfContractCurtains contracts={contracts} />
          </>
        )}
      </div>
    </>
  )
}

type Props = {
  account: PlannerCalendarRow_account$key
  person: {
    id: number
    first_name: string
    last_name: string
    is_placeholder: boolean
    time_offs: ReadonlyArray<TimeOff>
    assignments: ReadonlyArray<Assignment>
    contracts: ReadonlyArray<Contract>
    holidays_group?: {
      name: string
    }
  }
  project: {
    id: number
    confirmed: boolean
    pricing_model: string
    is_template: boolean
    phases?: ReadonlyArray<{
      id: number
      name: string
      color: string
      start_date: string
      end_date: string
    }>
  }
  assignments: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    is_billable: boolean
    is_template: boolean
    project_id: number
    non_working_day: boolean
    phase_id: number
  }>
  client: {
    id: number
    name: string
    image_key: string
    website: string
  }
  contracts: ReadonlyArray<Contract>
  timeOffs?: ReadonlyArray<TimeOff>
  roleId: number
  workstreamId: number
  companyDefaultMinutes: number
  delayAssignmentRender: boolean
  timeOffsWithWeekend?: number[]
  rowType?: "person" | "project" | "placeholder"
  holidaysOverlappingTimeOffs?: string[]
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
  minutes_per_day: number
  note?: string
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
  }>
}

export type Assignment = {
  id: number
  project_id: number
  person_id: number
  role_id: number
  phase_id: number
  workstream_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  non_working_day: boolean
}

type Contract = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  role: {
    id: number
    name: string
  }
}

export default PlannerCalendarRow
