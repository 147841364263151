import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { CSSObjectWithLabel } from "react-select"
import { match } from "ts-pattern"

import styles from "./ProjectBudgetLine.module.css"

import { ProjectBudgetLine_project$key } from "./__generated__/ProjectBudgetLine_project.graphql"
import { ProjectBudgetLine_user$key } from "./__generated__/ProjectBudgetLine_user.graphql"

import { FinancialType } from "~/helpers/permissions"
import { isBillableProject } from "~/helpers/project-helpers"

import Select from "~/common/Select"

import {
  PlannerStatsType,
  changeStatsType,
} from "~/Planner/reducer2/plannerStatsSlice"
import ProjectBudgetFinancial from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetFinancial"
import ProjectBudgetHours from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetHours"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import ProjectBudgetDays from "./ProjectBudgetDays"

type StatsTypeOption = { value: PlannerStatsType; label: string }

const statsTypeOptions: StatsTypeOption[] = [
  { value: "hours" as const, label: "Hours" },
  { value: "days" as const, label: "Days" },
  { value: "revenue" as const, label: "Revenue" },
] as const

type Props = {
  project: ProjectBudgetLine_project$key
  currentUser: ProjectBudgetLine_user$key
}

const ProjectBudgetLine = (props: Props) => {
  const dispatch = useDispatch()
  const statsType = useAppSelector(
    (state) => state.plannerV2.plannerStats.statsType,
  )
  const setStatsType = ({ value }: StatsTypeOption) => {
    dispatch(changeStatsType(value))
    setSetting("plannerStatsType", value)
  }

  const currentUser = useFragment(
    graphql`
      fragment ProjectBudgetLine_user on users {
        id
        permissions
      }
    `,
    props.currentUser,
  )

  const project = useFragment(
    graphql`
      fragment ProjectBudgetLine_project on projects {
        id
        pricing_model
        total_budget: total_budget_private
        client {
          id
          internal
        }
        ...ProjectBudgetFinancial_project
        ...ProjectBudgetHours_project
        ...ProjectBudgetDays_project
      }
    `,
    props.project,
  )

  const isBillable = isBillableProject(
    project.pricing_model,
    project.client.internal,
  )
  const financialPermission = currentUser.permissions.financial
  const hasFinancialPermissions = financialPermission !== FinancialType.None

  useEffect(() => {
    // Handle edge case where someone changes from financial to non-financial permissions
    if (statsType === "revenue" && !hasFinancialPermissions) {
      dispatch(changeStatsType("hours"))
      setSetting("plannerStatsType", "hours")
    }
  }, [hasFinancialPermissions, statsType, dispatch])

  return (
    <>
      <div className={styles.buttonContainer}>
        <Select
          isSearchable={false}
          fontSize={14}
          onChange={setStatsType}
          options={statsTypeOptions}
          value={statsTypeOptions.find(({ value }) => value === statsType)}
          controlStyles={{
            border: "none",
            padding: "0",
            ":hover": {
              div: {
                color: "var(--runn-blue)",
              },
            },
          }}
          styles={{
            valueContainer: (base) =>
              ({
                ...base,
                padding: "0",
              }) as CSSObjectWithLabel,
            singleValue: (base) =>
              ({
                ...base,
                fontSize: "11px",
                fontWeight: "500",
                margin: "0",
              }) as CSSObjectWithLabel,
            dropdownIndicator: (base) =>
              ({
                ...base,
                color: "var(--slate)",
                padding: "8px 4px",
                width: "20px",
              }) as CSSObjectWithLabel,
            menu: (base) =>
              ({
                ...base,
                minWidth: "100px",
              }) as CSSObjectWithLabel,
            option: (base) =>
              ({
                ...base,
                cursor: "pointer",
              }) as CSSObjectWithLabel,
          }}
        />
      </div>
      {match(statsType)
        .with("hours", () => (
          <ProjectBudgetHours project={project} isBillable={isBillable} />
        ))
        .with("revenue", () => (
          <ProjectBudgetFinancial project={project} isBillable={isBillable} />
        ))
        .with("days", () => (
          <ProjectBudgetDays project={project} isBillable={isBillable} />
        ))
        .exhaustive()}
    </>
  )
}

export default ProjectBudgetLine
