/**
 * @generated SignedSource<<53ded0ca46fe740b1d08299c8d624dcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderSummaryRow_user$data = {
  readonly account: {
    readonly custom_checkbox_types_person: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_date_types_person: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_select_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
      readonly show_in_planner: boolean;
    }>;
    readonly custom_text_types_person: ReadonlyArray<{
      readonly filterable_in_planner: boolean;
      readonly id: number;
      readonly name: string;
      readonly show_in_planner: boolean;
    }>;
    readonly default_full_time_minutes: number;
    readonly projects: ReadonlyArray<{
      readonly confirmed: boolean;
      readonly id: number;
      readonly is_template: boolean;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"PlaceholderCapacityIndicator_projects" | "PlannerLeftColumn_project">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"PlaceholderSidePanel_account" | "PlannerLeftColumn_account">;
  };
  readonly favouritePersonIds: any;
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_user">;
  readonly " $fragmentType": "PlaceholderSummaryRow_user";
};
export type PlaceholderSummaryRow_user$key = {
  readonly " $data"?: PlaceholderSummaryRow_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderSummaryRow_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PERSON"
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderSummaryRow_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_user"
    },
    (v0/*: any*/),
    {
      "alias": "favouritePersonIds",
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlaceholderSidePanel_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlannerLeftColumn_account"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlaceholderCapacityIndicator_projects"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlannerLeftColumn_project"
            }
          ],
          "storageKey": null
        },
        {
          "alias": "custom_text_types_person",
          "args": (v2/*: any*/),
          "concreteType": "custom_text_types",
          "kind": "LinkedField",
          "name": "custom_text_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_date_types_person",
          "args": (v2/*: any*/),
          "concreteType": "custom_date_types",
          "kind": "LinkedField",
          "name": "custom_date_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_select_types_person",
          "args": (v2/*: any*/),
          "concreteType": "custom_select_types",
          "kind": "LinkedField",
          "name": "custom_select_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "options",
              "args": null,
              "concreteType": "custom_select_options",
              "kind": "LinkedField",
              "name": "custom_select_options",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        },
        {
          "alias": "custom_checkbox_types_person",
          "args": (v2/*: any*/),
          "concreteType": "custom_checkbox_types",
          "kind": "LinkedField",
          "name": "custom_checkbox_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "aaeb70a4b3d636559ecc5005bb8ba106";

export default node;
