/**
 * @generated SignedSource<<8786985d4fc748df36aacda3b34b96de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetectedPersonRows_user$data = {
  readonly account: {
    readonly id: number;
    readonly people: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPersonRow_person">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_account">;
  };
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_user" | "ProjectDetailsPersonRow_user">;
  readonly " $fragmentType": "DetectedPersonRows_user";
};
export type DetectedPersonRows_user$key = {
  readonly " $data"?: DetectedPersonRows_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPersonRows_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "peopleFilter"
    },
    {
      "kind": "RootArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetectedPersonRows_user",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPersonRow_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsDetectedPersonRow_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectDetailsDetectedPersonRow_account"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "plannerStartDate",
                  "variableName": "plannerStartDate"
                }
              ],
              "kind": "FragmentSpread",
              "name": "ProjectDetailsPersonRow_person"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "b8f68c65b5a85c55514c8d7c8b4f996f";

export default node;
