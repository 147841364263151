import cc from "classcat"
import React, { ReactNode } from "react"

import styles from "./SelectorListItem.module.css"

type Props = {
  isPlaceholder?: boolean
  children?: ReactNode
  onClick?: () => void
  style?: Record<string, any>
  disabled?: boolean
  className?: string
  tall?: boolean
}

const SelectorListItem = (props: Props) => {
  const {
    onClick,
    isPlaceholder,
    children,
    disabled,
    className,
    tall,
    ...restProps
  } = props

  return (
    <div
      data-component="SelectorListItem"
      className={cc([
        styles.selectorListItem,
        className,
        {
          [styles.placeholder]: isPlaceholder,
          [styles.disabled]: disabled,
          [styles.tall]: tall,
        },
      ])}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </div>
  )
}

export default SelectorListItem
