import { Icon, Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import numbro from "numbro"
import React, { useEffect, useState } from "react"

import styles from "./ProjectBudget.module.css"

import currency, { formatCurrency } from "~/helpers/CurrencyHelper"
import { dashify } from "~/helpers/general-helpers"

import CurrencyInput from "~/common/CurrencyInput"
import Duration from "~/common/Duration"
import NumericInput from "~/common/NumericInput"
import { Delete } from "~/common/react-icons"

import { BudgetedRole } from "./BudgetEditor"
import { ProjectRate } from "./ProjectBudget"
import Column from "./TableColumn"
import Row from "./TableRow"
import { calculateBudgetFromRoleMinutesAndRate } from "./project_budget_helpers"

type Props = {
  budgetedRole: BudgetedRole
  rate_card_rate: number
  default_full_time_minutes: number
  project_rate: { id: number; role_id: number; rate: number }
  projectId: number
  hideFinancials: boolean
  onRoleChange: (budgetedRole: BudgetedRole) => void
  removeRole: (id: number) => void
  onRatesUpdate: (roleRate: ProjectRate[]) => void
  showDayView: boolean
  canDelete?: boolean
  deleteTooltip?: string
}

const RoleRateAndBudgetItem = (props: Props) => {
  const {
    budgetedRole,
    budgetedRole: { estimated_minutes },
    rate_card_rate,
    project_rate,
    projectId,
    hideFinancials,
    onRoleChange,
    removeRole,
    onRatesUpdate,
    showDayView,
    default_full_time_minutes,
    canDelete = false,
    deleteTooltip,
  } = props

  const hoursPerDay = default_full_time_minutes / 60

  const [days, setDays] = useState(
    estimated_minutes / default_full_time_minutes,
  )

  const originalRate = project_rate?.rate ?? rate_card_rate
  const [rateInput, setRateInput] = useState(originalRate)

  const budgetFromEstimatedMinutes = numbro(
    calculateBudgetFromRoleMinutesAndRate(estimated_minutes, rateInput),
  ).format({ thousandSeparated: true, mantissa: 0 })

  const [budgetInput, setBudgetInput] = useState(budgetFromEstimatedMinutes)

  const disableBudget = hideFinancials === true || !Number(rateInput)

  useEffect(() => {
    const rate = Number(rateInput)
    if (Number.isNaN(rate) || !rateInput) {
      return
    }

    setBudgetInput(budgetFromEstimatedMinutes)
  }, [rateInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRateInput(originalRate)
  }, [originalRate])

  useEffect(() => {
    // Make sure if we update the budget, we also save the rate input.
    // This is important if project_rate doesn't exist yet.
    onRatesUpdate([
      {
        id: project_rate ? project_rate.id : null,
        role_id: budgetedRole.id,
        project_id: projectId,
        rate: Number(rateInput),
      },
    ])
  }, [rateInput, budgetInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBudgetInput(budgetFromEstimatedMinutes)
    setDays(estimated_minutes / default_full_time_minutes)
  }, [estimated_minutes]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMinutes = (minutes) => {
    onRoleChange({ ...budgetedRole, estimated_minutes: minutes })
  }

  const handleBudget = (e) => {
    const budget = Number(numbro.unformat(e.target.value))

    if (Number.isNaN(budget)) {
      return setBudgetInput(budgetInput)
    }

    const minutes = Math.round((budget / Number(rateInput)) * 60)
    onRoleChange({ ...budgetedRole, estimated_minutes: minutes })
  }

  const onRateBlur = (e) => {
    if (Number.isNaN(numbro.unformat(e.target.value)) || !e.target.value) {
      setRateInput(rate_card_rate)
    }
  }

  const formatBudgetInput = (val) => {
    if (!val) {
      return setBudgetInput("0")
    }
    if (!Number(val)) {
      return setBudgetInput(budgetInput)
    }

    const value = numbro(val).format({ thousandSeparated: true, mantissa: 0 })
    setBudgetInput(value)
  }

  const handleRateChange = (val) => {
    if (showDayView) {
      setRateInput(val / hoursPerDay)
    } else {
      setRateInput(val)
    }
  }

  const onDayBlur = () => {
    handleMinutes(Math.round(days * default_full_time_minutes))
  }

  const projectRate = showDayView
    ? (Number(rateInput) * default_full_time_minutes) / 60
    : Number(rateInput)
  const formattedRoleName = dashify(budgetedRole.name)
  const transferRateCardRateDisabled = rate_card_rate === rateInput

  return (
    <Row dataTest={`${formattedRoleName}-row`}>
      <Column>{budgetedRole.name}</Column>
      {!hideFinancials && (
        <Column>
          <span data-test={`${formattedRoleName}-rate-card-rate`}>
            {formatCurrency(
              showDayView ? rate_card_rate * hoursPerDay : rate_card_rate,
            )}
          </span>
        </Column>
      )}
      {!hideFinancials && (
        <Column>
          {!transferRateCardRateDisabled && (
            <Tooltip content={"Copy rate to project"} placement="top">
              <Icon
                icon="arrow-right"
                className={styles.transferRateArrow}
                onClick={() => {
                  setRateInput(rate_card_rate)
                }}
              />
            </Tooltip>
          )}
        </Column>
      )}
      {!hideFinancials && (
        <Column>
          <CurrencyInput
            name={`${formattedRoleName}-project_rate`}
            dataTest={`${formattedRoleName}-project_rate`}
            value={projectRate}
            onChange={handleRateChange}
            onBlur={onRateBlur}
            currency={currency}
            height={30}
            selectAllOnFocus
          />
        </Column>
      )}
      <Column>
        {showDayView ? (
          <NumericInput
            value={days || 0}
            mantissa={2}
            trimMantissa
            onValueChange={(e) => setDays(e)}
            onBlur={onDayBlur}
            dataTest={`${formattedRoleName}-budget_days`}
          />
        ) : (
          <Duration
            minutesPerDay={estimated_minutes || null}
            handleMinutesPerDay={handleMinutes}
            allowZero
            defaultDuration={0}
            dataTest={`${formattedRoleName}-budget_hours`}
            height={30}
            style={{
              borderColor: "var(--winter)",
            }}
          />
        )}
      </Column>
      {!hideFinancials && (
        <Column>
          <CurrencyInput
            name={`${formattedRoleName}-budget_dollar`}
            dataTest={`${formattedRoleName}-budget_dollar`}
            value={budgetInput}
            onChange={formatBudgetInput}
            onBlur={handleBudget}
            currency={currency}
            disabled={disableBudget}
            height={30}
            selectAllOnFocus
          />
        </Column>
      )}
      <div
        className={cc([
          styles.deleteIconContainer,
          !canDelete && styles.disabled,
        ])}
        onClick={() => canDelete && removeRole(budgetedRole.id)}
        data-test="budget-role-delete"
      >
        <Delete alt={deleteTooltip} color="var(--winter)" />
      </div>
    </Row>
  )
}
export default RoleRateAndBudgetItem
