import React, { useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

import { TemplateNameUniqueInput_project$key } from "./__generated__/TemplateNameUniqueInput_project.graphql"

import UniqueInput from "../../common/UniqueInput"

type Props = {
  projectTemplatesQuery: TemplateNameUniqueInput_project$key
  onChange: (date: string) => void
  savedName?: string
}

const fragment = graphql`
  fragment TemplateNameUniqueInput_project on projects @relay(plural: true) {
    id
    name
  }
`

const TemplateNameUniqueInput = (props: Props) => {
  const { projectTemplatesQuery, savedName, onChange } = props

  const projectTemplates = useFragment<TemplateNameUniqueInput_project$key>(
    fragment,
    projectTemplatesQuery,
  )

  const [projectName, setName] = useState(savedName || "")

  const templateNameExists = projectTemplates.some(
    (pt) => pt.name !== savedName && pt.name === projectName.trim(),
  )

  useEffect(() => {
    templateNameExists ? onChange("") : onChange(projectName)
  }, [templateNameExists, projectName, onChange])

  return (
    <UniqueInput
      name="project-template-name"
      id="project-template-name"
      duplicate={
        templateNameExists
          ? {
              name: projectName,
            }
          : undefined
      }
      label="Project Template Name"
      value={projectName}
      onChange={(e) => setName(e.target.value)}
      autoComplete="off"
      autoFocus
    />
  )
}

export default TemplateNameUniqueInput
