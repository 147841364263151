import { Tooltip } from "@blueprintjs/core"
import React, { useState } from "react"
import isURL from "validator/lib/isURL"

import styles from "./LinkTable.module.css"

import { submitOnEnterOrSpace } from "~/helpers/hotkeys"

import Checkbox from "~/common/inputs/Checkbox"
import { Help } from "~/common/react-icons"

import Input from "./Input"
import AddButton from "./buttons/AddButton"
import { Delete } from "./react-icons"

export type Link = {
  readonly id?: number
  readonly href: string
  readonly name: string
  readonly show_in_planner: boolean
}

type LinkRowProps = {
  link: Link
  idx: number
  handleChange: (link: Link) => void
  handleDelete: (idx: number) => void
}

const LinkRow = (props: LinkRowProps) => {
  const { idx, link, handleChange, handleDelete } = props

  return (
    <>
      <div className={styles.row}>
        <Input
          name={`link-name-${idx}`}
          data-idx={idx}
          value={link.name}
          onChange={(e) => handleChange({ ...link, name: e.target.value })}
          style={{ marginBottom: 0 }}
        />
        <Input
          name={`link-href-${idx}`}
          data-idx={idx}
          value={link.href}
          onChange={(e) => handleChange({ ...link, href: e.target.value })}
          style={{ marginBottom: 0 }}
        />
        <div
          id={`link-delete-${idx}`}
          onClick={() => handleDelete(idx)}
          onKeyDown={(e) => submitOnEnterOrSpace(e, () => handleDelete(idx))}
          className={styles.delete}
          tabIndex={0}
        >
          <Delete color="var(--winter)" />
        </div>
      </div>
      <div className={styles.showInPlanner}>
        <Checkbox
          id="include-in-planner-checkbox"
          label="Include details on Planner"
          name="includeDetailsOnPlanner"
          defaultChecked={link.show_in_planner ?? false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({ ...link, show_in_planner: e.target.checked })
          }
        />
        <Tooltip
          content={`View and access the link via the planner`}
          placement="top"
        >
          <Help />
        </Tooltip>
      </div>
    </>
  )
}

type LinkTableProps = {
  links: readonly Link[]
  parent: "project" | "person"
  setUpdatedLinks: (links: readonly Link[]) => void
}

const LinkTable = (props: LinkTableProps) => {
  const { links, setUpdatedLinks } = props
  const [data, setData] = useState(links)

  const handleAdd = () => {
    setData([
      ...data,
      {
        name: "",
        href: "",
        show_in_planner: true,
      },
    ])
  }

  const handleChange = (link: Link, idx: number) => {
    const updatedLinks = [...data]
    updatedLinks[idx] = link

    setData(updatedLinks)
    setUpdatedLinks(updatedLinks)
  }

  const handleDelete = (idx) => {
    const updatedLinks = data.filter((p, index) => index !== idx)
    setData(updatedLinks)
    setUpdatedLinks(updatedLinks)
  }

  const numberOfLinks = data.filter(
    (d) => d.name.length && isURL(d.href),
  ).length

  return (
    <details className={styles.container}>
      <summary data-test="links-header">
        <span>Links{numberOfLinks ? ` (${numberOfLinks})` : null}</span>
      </summary>
      <div className={styles.description}>
        Link to external sites related to the {props.parent}.
      </div>
      {!!data.length && (
        <div className={styles.rows}>
          <div className={`${styles.row} ${styles.rowHeader}`}>
            <div>Name</div>
            <div>Link</div>
            <div />
          </div>
          {data.map((val, idx) => {
            return (
              <LinkRow
                key={idx}
                idx={idx}
                link={val}
                handleChange={(l) => handleChange(l, idx)}
                handleDelete={() => handleDelete(idx)}
              />
            )
          })}
        </div>
      )}
      <div>
        <AddButton
          className={styles.add}
          text="Add Link"
          onClick={() => handleAdd()}
        />
      </div>
    </details>
  )
}

export default LinkTable
