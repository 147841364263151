import cc from "classcat"
import React from "react"

import styles from "./SelectorHeader.module.css"

import { CrossIcon } from "~/common/react-icons"

type Props = {
  title: string
  subtitle?: string
  customStyles?: React.CSSProperties
  icon?: React.ReactNode
  onClose: () => void
}

const SelectorHeader = (props: Props) => {
  const { icon, title, subtitle, onClose, customStyles } = props

  const isMinimal = !subtitle

  return (
    <div className={cc([styles.wrapper, { [styles.hasIcon]: icon }])}>
      {icon && <div className={styles.icon}>{icon}</div>}

      {isMinimal ? (
        <span style={customStyles}>{title}</span>
      ) : (
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      )}

      <div className={styles.closeIcon} onClick={onClose}>
        <CrossIcon size={12} />
      </div>
    </div>
  )
}

export default SelectorHeader
