import { Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import React from "react"

import styles from "./FilterBlock.module.css"

import { Cross as CrossIcon } from "~/common/react-icons"

import FilterBlockTextMenu from "./FilterBlockTextMenu"
import FilterDropdown from "./FilterDropdown"

type Props = {
  icon: React.ReactNode
  label: string
  disabled?: boolean
  onRemove: () => void
  query: string
  searchType: fe.filters.TextSearchType
  onChange: (query: string, searchType: fe.filters.TextSearchType) => void
  isDropdownOpen: boolean
  onChangeDropdownOpen: (isOpen: boolean) => void
}

type TooltipContentProps = {
  query: string
}

const TooltipContent = (props: TooltipContentProps) => {
  const { query } = props
  return (
    <div className={styles.tooltip}>
      {query.length === 0 ? "⚠️ Missing value" : null}
      <div>{query}</div>
    </div>
  )
}

type TargetProps = {
  icon: React.ReactNode
  label: string
  disabled?: boolean
  isDropdownOpen: boolean
  query: string
  onRemove: () => void
}

const Target = (props: TargetProps) => {
  const { icon, label, disabled, isDropdownOpen, query, onRemove } = props

  const searchQuery = query.trim()

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!disabled) {
      onRemove()
    }
  }

  return (
    <button
      disabled={disabled}
      className={cc([
        styles.targetButton,
        { [styles.empty]: searchQuery.length === 0 },
        { [styles.isDropdownOpen]: isDropdownOpen },
      ])}
      data-test={`SuperSearch_FilterBlock_${label}_target`}
    >
      <Tooltip
        disabled={disabled}
        content={<TooltipContent query={searchQuery} />}
        placement="top"
      >
        <div className={styles.targetButtonText}>
          <span className={styles.targetIcon}>{icon}</span>
          <span className={styles.targetLabel}>{label}</span>
          <span className={styles.targetSelected}>{query}</span>
        </div>
      </Tooltip>

      {!disabled && (
        <Tooltip
          className={styles.removeButtonTooltip}
          content="Remove filter"
          placement="top"
        >
          <div
            className={styles.removeButton}
            onClick={handleRemove}
            data-test={`SuperSearch_FilterBlock_${label}_removeButton`}
          >
            <CrossIcon />
          </div>
        </Tooltip>
      )}
    </button>
  )
}

const FilterBlockText = (props: Props) => {
  const {
    icon,
    label,
    disabled,
    query,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
    searchType,
  } = props

  const handleOpenDropdown = () => onChangeDropdownOpen(true)
  const handleCloseDropdown = () => onChangeDropdownOpen(false)

  return (
    <FilterDropdown
      disabled={disabled}
      isOpen={isDropdownOpen}
      target={
        <Target
          icon={icon}
          label={label}
          query={query}
          disabled={disabled}
          onRemove={onRemove}
          isDropdownOpen={isDropdownOpen}
        />
      }
      onOpen={handleOpenDropdown}
      onClose={handleCloseDropdown}
    >
      <FilterBlockTextMenu
        label={label}
        query={query}
        onChange={onChange}
        onClose={handleCloseDropdown}
        searchType={searchType}
      />
    </FilterDropdown>
  )
}

export default FilterBlockText
