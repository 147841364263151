import { Menu, Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./PersonSummaryRow.module.css"
import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { PersonSummaryRow_person$key } from "./__generated__/PersonSummaryRow_person.graphql"
import { PersonSummaryRow_user$key } from "./__generated__/PersonSummaryRow_user.graphql"
import { PersonManagement_user$data } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "../../helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { dashify, isNewTabClick } from "~/helpers/general-helpers"
import { showPeopleAndPlaceholdersInGroup } from "~/helpers/group-helpers"
import * as hashids from "~/helpers/hashids"
import { formatName, getSecondaryPersonField } from "~/helpers/person"
import { excludeUntoggledAssignments } from "~/helpers/planner-helpers"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import ConflictWarning, { conflictToLabel } from "~/common/ConflictWarning"
import { Dropdown } from "~/common/Dropdown"
import MenuItem from "~/common/MenuItem"
import PersonDetails from "~/common/PersonDetails"
import { deleteTimeOffConflicts } from "~/common/Pill/AssignmentActionHelpers"
import PlaceholderIcon from "~/common/PlaceholderIcon"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import { highlightAssignments } from "~/common/calendar.reducer"
import { Warning } from "~/common/react-icons"

import { useEntitlementSwitch } from "~/Entitlements/useEntitlements"
import { usePermissions } from "~/Permissions/usePermissions"
import PersonSidePanel from "~/Planner/PersonSidePanel/PersonSidePanel"
import { setPeopleFilterSet } from "~/Planner/Planner.actions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { panelClosed } from "~/Planner/reducer2/panelSlice"
import { selectGroupPeopleBy } from "~/Planner/reducer2/peopleSortSlice"
import { useAppSelector } from "~/hooks/redux"
import useGroupUtilizationConfig from "~/hooks/useGroupUtilizationConfig"
import { useIsInViewport } from "~/hooks/useIsInViewport"

import useFavouritePerson from "../useFavouritePerson"

import PersonSummaryGraph from "./PersonSummaryGraph"

type Props = {
  personId: number
  personExpanded: boolean
  handleToggleRow: () => void
  groupCount: number
  person: PersonSummaryRow_person$key
  favourites: PersonManagement_user$data["favourite_people"]
  locatedInSchedulePanel?: boolean
  disabled?: boolean
  noPeopleHeadingBar?: boolean
}

const PersonSummaryRow = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment PersonSummaryRow_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        created_at
        id
        first_name
        last_name
        is_placeholder
        email
        image_key
        archivable
        tags
        active
        team_id
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          person_id
          role_id
          project_id
          workstream_id
          note
          is_billable
          is_template
          phase_id
          non_working_day
          total_minutes
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          role {
            id
            name
          }
          job_title
          rostered_days
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          person_id
          leave_type
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        team {
          id
          name
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        placeholder_suggestions {
          suggested_person_id
        }
        person_requests {
          id
          status
          updated_at
          user {
            id
            first_name
            last_name
          }
          person {
            id
            first_name
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              cost: cost_private
              minutes_per_day
              role {
                id
                name
              }
            }
          }
        }
        people_notes {
          id
          note
          created_at
          ...PersonNote_note
        }
        ...EditPersonForm_person
        ...PlannerLeftColumn_person
        ...PlaceholderActionButtons_placeholder
        ...PersonSidePanel_person
      }
    `,
    props.person,
  )

  const user = useFragment<PersonSummaryRow_user$key>(
    graphql`
      fragment PersonSummaryRow_user on users
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        id
        ...PersonSidePanel_user
        ...PlannerLeftColumn_user
        account {
          id
          ...PersonSummaryGraph_account
          ...PlannerLeftColumn_account
          default_full_time_minutes
          secondary_person_field
          projects(where: $projectsFilter) {
            id
            confirmed
            is_template
          }
          custom_text_types_person: custom_text_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          custom_date_types_person: custom_date_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          custom_select_types_person: custom_select_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            options: custom_select_options {
              id
              name
            }
            show_in_planner
            filterable_in_planner
          }
          custom_checkbox_types_person: custom_checkbox_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          users {
            id
            email
          }
          invitations {
            id
            email
          }
        }
      }
    `,
    useHasuraContext(),
  )

  const {
    personExpanded,
    handleToggleRow,
    favourites: rawFavourites,
    groupCount,
    disabled,
    locatedInSchedulePanel,
    noPeopleHeadingBar = false,
  } = props

  const ref = useRef(null)
  const isPersonSummaryRowInViewport = useIsInViewport(ref, {
    delay: 1000,
    keepVisible: false,
    offsetY: 1000,
    rootQuerySelector: "#people-planner-list",
  })

  const dispatch = useDispatch()
  const { openPanel } = useSidePanel()

  const { account } = user
  const projects = account.projects
  const collapsible = !locatedInSchedulePanel

  const { can, subject } = usePermissions()
  const userSubject = subject("User", user)
  const userPermissions = {
    edit: can("edit", userSubject),
    viewSidePanel: can("view", subject("Person")),
  }

  const canViewSidePanel = userPermissions.viewSidePanel

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_date_types: account.custom_date_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
  })
  const customFieldValues = valuesMap(person)

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const onlyToggledAssignments = excludeUntoggledAssignments(
    person.assignments,
    projects,
    enabledTentativeProjects,
  )

  const { isFavourite, favouritePerson } = useFavouritePerson({
    personId: person.id,
    userId: user.id,
    favouritePersonIds: rawFavourites,
  })

  const starPerson = (e: React.MouseEvent) => {
    e.stopPropagation()

    track("Person Starred")

    favouritePerson()
  }

  const viewPerson = (e: MouseEvent) => {
    if (!isNewTabClick(e)) {
      e.preventDefault()

      dispatch(panelClosed())
      dispatch(
        setPeopleFilterSet({
          name: "",
          filters: fe.filters.personId({ list: [person.id] }),
        }),
      )
      dispatch(changeActivePage("people"))
    }
  }

  const name = formatName(person.first_name, person.last_name)
  const secondaryPersonField = getSecondaryPersonField({
    contracts: person.contracts,
    secondaryPersonField: user.account.secondary_person_field,
  })

  const expandRow = () => {
    // Only expand don't collapse
    if (!personExpanded) {
      handleToggleRow()
    }
  }

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const { showGroupUtilization } = useGroupUtilizationConfig()

  const peopleAndPlaceholdersInGroup =
    showPeopleAndPlaceholdersInGroup(peopleGroupBy)

  const handleConflictClick = (e) => {
    e.stopPropagation()
  }

  const clearHighlights = () => {
    dispatch(highlightAssignments([]))
  }

  const handleRemoveAllConflicts = () => {
    void deleteTimeOffConflicts({
      ...person,
      assignments: onlyToggledAssignments.filter(
        (a) => a.non_working_day === false,
      ),
    })

    clearHighlights()
  }

  const BaseTarget = ({ content }: { content: string }) => (
    <Tooltip hoverOpenDelay={500} content={content}>
      <Warning />
    </Tooltip>
  )

  const TimeOffTarget = () => (
    <BaseTarget content={conflictToLabel({ type: "time-off" }, name)} />
  )

  const NoContractTarget = () => (
    <BaseTarget content={conflictToLabel({ type: "no-contract" }, name)} />
  )

  const TimeOffAndNoContractTarget = () => (
    <BaseTarget
      content={conflictToLabel({ type: "time-off-and-no-contract" }, name)}
    />
  )

  const openPersonSidePanel = () => {
    track("Person Side Panel Opened")
    openPanel(<PersonSidePanel user={user} person={person} />)
  }
  const entitledToResourceRequests = useEntitlementSwitch("resourcing-requests")

  return (
    <PlannerGrid
      ref={ref}
      className={cc([
        "userflow-person-row",
        styles.personSummaryRow,
        {
          [styles.rowExpanded]: personExpanded,
          [styles.utilizationGroup]:
            showGroupUtilization && !locatedInSchedulePanel,
          [styles.miniGroup]: !showGroupUtilization && !locatedInSchedulePanel,
          [styles.inGroup]: peopleAndPlaceholdersInGroup && !noPeopleHeadingBar,
        },
      ])}
      data-test="PeoplePlanner_PersonSummaryRow"
      disabled={disabled}
      allowInteraction={disabled}
    >
      <PlannerLeftColumn
        type="person"
        user={user}
        person={person}
        personLegacy={person}
        personQuery={person}
        account={account}
        accountLegacy={account}
        onClick={handleToggleRow}
        style={{ cursor: collapsible && "pointer" }}
        viewPerson={viewPerson}
        data-test={`show-person-roles-${dashify(name)}`}
        showStar={userPermissions.edit}
        isStarred={isFavourite}
        handleStar={starPerson}
        showMenuDots
        collapsible={collapsible}
        isCollapsed={!personExpanded}
        locatedInSchedulePanel={locatedInSchedulePanel}
        options={
          entitledToResourceRequests ? (
            <PlaceholderIcon iconSize={15} placeholder={person} />
          ) : null
        }
      >
        <PersonDetails
          person={person}
          subtitle={secondaryPersonField}
          size={32}
          link={`/people/${hashids.people.encode(person.id)}`}
          icons={
            <PlannerTooltipIcons
              icons={{
                tags: person.tags,
                competencies: person.competencies,
                customFields: {
                  values: customFieldValues,
                  types: customFieldTypes,
                },
                links: person.links
                  ? person.links.filter((link) => link.show_in_planner)
                  : [],
              }}
            />
          }
          onClick={canViewSidePanel ? openPersonSidePanel : null}
        />
        <ConflictWarning
          assignments={onlyToggledAssignments}
          timeOffs={person.time_offs}
          onClick={handleConflictClick}
          contracts={person.contracts}
        >
          {({
            jumpToAssignment,
            assignmentsOutOfContract,
            assignmentsOverlappingWithTimeOff,
            conflict,
          }) => (
            <div
              onMouseOver={() => {
                dispatch(
                  highlightAssignments(
                    assignmentsOutOfContract
                      .concat(assignmentsOverlappingWithTimeOff)
                      .map((a) => a.id),
                  ),
                )
              }}
              onMouseOut={clearHighlights}
            >
              <Dropdown
                Target={match(conflict)
                  .with({ type: "no-contract" }, (_) => NoContractTarget)
                  .with({ type: "time-off" }, (_) => TimeOffTarget)
                  .with(
                    { type: "time-off-and-no-contract" },
                    (_) => TimeOffAndNoContractTarget,
                  )
                  .exhaustive()}
                onClose={clearHighlights}
              >
                <Menu className={dropdownStyles.dropdown}>
                  <MenuItem
                    text="Jump to next conflict"
                    onClick={() => {
                      expandRow()
                      jumpToAssignment()
                    }}
                  />
                  {assignmentsOverlappingWithTimeOff.length > 0 &&
                    assignmentsOverlappingWithTimeOff.every((assignment) =>
                      can(
                        "delete",
                        subject("Assignment", {
                          account,
                          project: {
                            id: assignment.project_id,
                            isTemplate: false,
                          },
                        }),
                      ),
                    ) && (
                      <MenuItem
                        text={
                          assignmentsOverlappingWithTimeOff.length === 1
                            ? `Delete assignment conflict`
                            : `Delete all assignment conflicts (${assignmentsOverlappingWithTimeOff.length})`
                        }
                        onClick={handleRemoveAllConflicts}
                      />
                    )}
                </Menu>
              </Dropdown>
            </div>
          )}
        </ConflictWarning>
      </PlannerLeftColumn>
      <PlannerRightColumn showHighlight={false}>
        {isPersonSummaryRowInViewport && (
          <PersonSummaryGraph
            account={account}
            person={person}
            projects={projects}
            expandRow={expandRow}
            delayRender={groupCount > 15}
          />
        )}
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default React.memo(PersonSummaryRow)
