/**
 * @generated SignedSource<<ec0fdb67245d70d37e7acc628c9f04ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PhaseCreateMutation$variables = {
  color?: string | null | undefined;
  end_date: any;
  name: string;
  project_id: number;
  start_date: any;
};
export type PhaseCreateMutation$data = {
  readonly createPhase: {
    readonly phase: {
      readonly color: string;
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly project: {
        readonly calc_end_date: string | null | undefined;
        readonly calc_start_date: string | null | undefined;
        readonly id: number;
        readonly phases: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly phases_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      };
      readonly project_id: number;
      readonly start_date: string | null | undefined;
    } | null | undefined;
  };
};
export type PhaseCreateMutation = {
  response: PhaseCreateMutation$data;
  variables: PhaseCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "color"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "color",
        "variableName": "color"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "project_id"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "CreatePhaseResult",
    "kind": "LinkedField",
    "name": "createPhase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "phases",
        "kind": "LinkedField",
        "name": "phase",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": "start_date",
            "args": null,
            "kind": "ScalarField",
            "name": "start_date_runn",
            "storageKey": null
          },
          {
            "alias": "end_date",
            "args": null,
            "kind": "ScalarField",
            "name": "end_date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_start_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_end_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "phases",
                "kind": "LinkedField",
                "name": "phases",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "phases_aggregate",
                "kind": "LinkedField",
                "name": "phases_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "phases_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PhaseCreateMutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PhaseCreateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6d1a3e69d664332b8c546bc4a697e032",
    "id": null,
    "metadata": {},
    "name": "PhaseCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PhaseCreateMutation(\n  $name: String!\n  $color: String\n  $start_date: Date!\n  $end_date: Date!\n  $project_id: Int!\n) {\n  createPhase(name: $name, color: $color, startDate: $start_date, endDate: $end_date, projectId: $project_id) {\n    phase {\n      id\n      name\n      color\n      start_date: start_date_runn\n      end_date: end_date_runn\n      project_id\n      project {\n        id\n        calc_start_date\n        calc_end_date\n        phases {\n          id\n        }\n        phases_aggregate {\n          aggregate {\n            count\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "66613cfbdfa3c066b19bb8fff6323cd6";

export default node;
