import { format } from "date-fns"
import { graphql } from "react-relay"

import { MilestoneCreateMutation } from "./__generated__/MilestoneCreateMutation.graphql"
import { MilestoneDeleteMutation } from "./__generated__/MilestoneDeleteMutation.graphql"
import { MilestoneUpdateMutation } from "./__generated__/MilestoneUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

const milestoneCreateMutation = graphql`
  mutation MilestoneCreateMutation(
    $icon: String!
    $note: String
    $title: String!
    $date: Date!
    $project_id: Int!
  ) {
    createMilestone(
      icon: $icon
      note: $note
      title: $title
      date: $date
      projectId: $project_id
    ) {
      milestone {
        id
        title
        icon
        note
        date: date_runn
        project_id
        project {
          id
          calc_start_date
          calc_end_date
          milestones {
            id
          }
          milestones_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const milestoneCreateRelay = (
  variables: MilestoneCreateMutation["variables"],
) => {
  return commitMutationPromise<MilestoneCreateMutation>(environment, {
    mutation: milestoneCreateMutation,
    variables,
  })
}

const milestoneUpdateMutation = graphql`
  mutation MilestoneUpdateMutation(
    $id: Int!
    $icon: String
    $note: String
    $title: String
    $date: Date!
    $project_id: Int!
  ) {
    updateMilestone(
      icon: $icon
      note: $note
      title: $title
      date: $date
      projectId: $project_id
      id: $id
    ) {
      milestone {
        id
        title
        icon
        note
        date: date_runn
        project {
          id
          calc_start_date
          calc_end_date
        }
      }
    }
  }
`

export const updateMilestoneRelay = (
  variables: MilestoneUpdateMutation["variables"],
) => {
  return commitMutationPromise<MilestoneUpdateMutation>(environment, {
    mutation: milestoneUpdateMutation,
    variables,
  }).then(() => {
    showToast({
      message: `Milestone moved to ${format(variables.date, "EEE, d MMM yyyy")}`,
      type: "success",
    })
  })
}

const milestoneDeleteMutation = graphql`
  mutation MilestoneDeleteMutation($id: Int!) {
    deleteMilestone(id: $id) {
      project {
        id
        calc_start_date
        calc_end_date
        milestones {
          id
        }
        milestones_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const milestoneDeleteRelay = (
  variables: MilestoneDeleteMutation["variables"],
) => {
  return commitMutationPromise<MilestoneDeleteMutation>(environment, {
    mutation: milestoneDeleteMutation,
    variables,
  })
}
