/**
 * @generated SignedSource<<4108398791b0f538a3db9ac1f2fcea07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRow_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPeopleSection_account" | "ProjectAddPersonRow_account" | "ProjectDetailsSection_account" | "ProjectGroupRow_account" | "ProjectRowSummary_account">;
  readonly " $fragmentType": "ProjectRow_account";
};
export type ProjectRow_account$key = {
  readonly " $data"?: ProjectRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRow_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "peopleFilter",
  "variableName": "peopleFilter"
},
v1 = {
  "kind": "Variable",
  "name": "plannerStartDate",
  "variableName": "plannerStartDate"
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRow_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectRowSummary_account"
    },
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectAddPersonRow_account"
    },
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ProjectDetailsSection_account"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "ProjectGroupRow_account"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "DetectedPeopleSection_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "9c8d233a16988fffe79902eaea778968";

export default node;
