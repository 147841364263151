/**
 * @generated SignedSource<<e2ec12785b01805309fc1faa8de63cac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIntegrationList_account$data = {
  readonly activeIntegrations: ReadonlyArray<{
    readonly active: boolean;
    readonly category: string;
    readonly id: number;
    readonly integration_service: {
      readonly id: number;
      readonly name: string;
      readonly square_image_url: string | null | undefined;
    };
    readonly state: string;
  }>;
  readonly " $fragmentType": "useIntegrationList_account";
};
export type useIntegrationList_account$key = {
  readonly " $data"?: useIntegrationList_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIntegrationList_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIntegrationList_account",
  "selections": [
    {
      "alias": "activeIntegrations",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "state": {
              "_neq": "PENDING"
            }
          }
        }
      ],
      "concreteType": "integration",
      "kind": "LinkedField",
      "name": "integrationsV2",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "integration_service",
          "kind": "LinkedField",
          "name": "integration_service",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "square_image_url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "integrationsV2(where:{\"state\":{\"_neq\":\"PENDING\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "d1250a45d00360a206596c7ec2925b34";

export default node;
