import React, { useCallback, useState } from "react"

import styles from "./CustomEditorCheckboxField.module.css"

import {
  CustomCheckboxType,
  CustomCheckboxValue,
} from "~/helpers/custom-field-helpers"

import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import Checkbox from "~/common/inputs/Checkbox"

import { CustomEditorFieldProps } from "~/CustomEditor/types"

type Props = CustomEditorFieldProps<CustomCheckboxType, CustomCheckboxValue>

const CustomEditorTextField = ({ id, type, value, onChange }: Props) => {
  const [localValue, setLocalValue] = useState<Props["value"]>(value)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedValue = {
        ...localValue,
        value: e.currentTarget.checked,
        typeId: type.id,
      }
      onChange({ type, value: updatedValue })
      setLocalValue(updatedValue)
    },
    [type, localValue, onChange],
  )

  return (
    <div className={styles.checkboxEditor}>
      <Checkbox
        label={
          <TitledHelpTooltip
            title={type.name}
            tooltipContent={type.description}
          />
        }
        id={`${id}-field`}
        name={`${id}-field`}
        checked={localValue?.value}
        onChange={handleChange}
      />
    </div>
  )
}

export default CustomEditorTextField
