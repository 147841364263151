import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import { format as formatDate } from "date-fns"
import React, { useRef, useState } from "react"
import Linkify from "react-linkify"

import styles from "./Note.module.css"

import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"
import { SimpleLabel } from "~/common/labels"

import CreateNoteForm from "./CreateNoteForm"

type NoteProps = {
  noteId: number
  note: string
  createdDate: string
  modifiedDate: string
  createdBy: string
  onUpdate: (note: string) => void
  onDelete: (id: number) => void
  readonly?: boolean
  editable?: boolean
  deletable?: boolean
  isUserDeleted: boolean
  style?: {
    container?: React.CSSProperties
    note?: React.CSSProperties
    createdBy?: React.CSSProperties
  }
  timeShown?: boolean
}

const LinkifyTargetBlank = (props) => {
  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener">
      {text}
    </a>
  )

  return (
    <Linkify componentDecorator={componentDecorator}>{props.children}</Linkify>
  )
}

const Note = (props: NoteProps) => {
  const {
    noteId,
    note,
    createdBy,
    createdDate,
    modifiedDate,
    onUpdate,
    onDelete,
    readonly,
    editable,
    deletable,
    isUserDeleted,
    style,
    timeShown,
  } = props

  const noteTooLong = note.length > 300 || note.split(/\r\n|\r|\n/).length > 3
  const [showMore, setShowMore] = useState(noteTooLong)
  const [edit, setEdit] = useState(false)

  const noteRef = useRef<HTMLDivElement>(null)

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleUpdate = (newNote: string) => {
    setEdit(false)
    try {
      onUpdate(newNote)
    } finally {
      setEdit(false)
    }
  }

  if (edit) {
    return (
      <CreateNoteForm
        savedNote={note}
        closeForm={() => setEdit(false)}
        onSubmit={handleUpdate}
      />
    )
  }

  const hasUpdated = new Date(modifiedDate) > new Date(createdDate)
  const isoDate = new Date(
    hasUpdated ? modifiedDate : createdDate,
  ).toISOString()
  const fullDate = formatDate(
    new Date(hasUpdated ? modifiedDate : createdDate),
    "d MMM yyyy, HH:mm:ss",
  )
  const formattedDate = formatDate(
    new Date(hasUpdated ? modifiedDate : createdDate),
    timeShown ? "d MMM yyyy, HH:mm" : "d MMM yyyy",
  )

  return (
    <div
      className={cc([
        styles.container,
        {
          [styles.showAll]: !showMore,
          [styles.readonly]: readonly,
        },
      ])}
      style={style?.container}
    >
      <div>
        <div className={styles.noteWrapper} style={style?.note}>
          <div ref={noteRef} className={styles.note}>
            <LinkifyTargetBlank>{note}</LinkifyTargetBlank>
          </div>
        </div>
        {noteTooLong && (
          <span onClick={handleShowMore} className={styles.showMore}>
            Show {showMore ? "more" : "less"}
          </span>
        )}
        <div className={styles.details} style={style?.createdBy}>
          {createdBy}
          {isUserDeleted && (
            <SimpleLabel text="Deleted User" style={{ marginLeft: "3px" }} />
          )}{" "}
          • {hasUpdated ? "Updated" : ""}{" "}
          <time dateTime={isoDate} title={fullDate}>
            {formattedDate}
          </time>
        </div>
      </div>
      {!readonly && (editable || deletable) && (
        <>
          <Dropdown Target={({ active }) => <IconThreeDot active={active} />}>
            {editable && (
              <MenuItem
                icon={<Icon icon="cog" />}
                text="Edit"
                onClick={() => setEdit(true)}
              />
            )}
            {deletable && (
              <MenuItem
                icon={<Icon icon="trash" />}
                text="Delete"
                onClick={() => onDelete(noteId)}
              />
            )}
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default Note
