/**
 * @generated SignedSource<<918479754f3388b2958d75b1575973e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LoadingSeedDataModalSubscription$variables = Record<PropertyKey, never>;
export type LoadingSeedDataModalSubscription$data = {
  readonly current_account: {
    readonly copy_started_at: number | null | undefined;
    readonly copy_status: string | null | undefined;
    readonly id: number;
  } | null | undefined;
};
export type LoadingSeedDataModalSubscription = {
  response: LoadingSeedDataModalSubscription$data;
  variables: LoadingSeedDataModalSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "accounts",
    "kind": "LinkedField",
    "name": "current_account",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "copy_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "copy_started_at",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadingSeedDataModalSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoadingSeedDataModalSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0ae801aa35cf66b81972e23e467e99c6",
    "id": null,
    "metadata": {},
    "name": "LoadingSeedDataModalSubscription",
    "operationKind": "subscription",
    "text": "subscription LoadingSeedDataModalSubscription {\n  current_account {\n    id\n    copy_status\n    copy_started_at\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0dbfc7f992d89c778d6fbe6a7f351e4";

export default node;
