import { MenuDivider, Placement } from "@blueprintjs/core"
import React from "react"

import styles from "./FilterDropdownMenu.module.css"

import { Dropdown } from "~/common/Dropdown"

import { usePermissions } from "~/Permissions/usePermissions"

import AvailableFilterList from "./AvailableFilterList"
import FilterMenuButton from "./FilterMenuButton"
import IncludeArchivedProjectsToggle from "./IncludeArchivedProjectsToggle"
import ProjectMembersFilterListItem from "./ProjectMembersFilterListItem"
import SavedFilterSetList from "./SavedFilterSetList"
import type { Filter, FilterSet, SuperSearchConfig } from "./types"

type Props = {
  savedFilterSets: FilterSet[]
  config: SuperSearchConfig
  onClickSavedFilterSet: (filterSet: FilterSet) => void
  onClickAvailableFilter: (filter: Filter) => void
  onRemoveSavedFilterSet: (filterSet: FilterSet) => void
  onClickProjectMembersFilter: (filter: Filter) => void
  showSplitScreenMembersFilter: boolean
  projectName: string
  isTemplate: boolean
  children?: React.ReactNode
  customPlacement?: Placement
  showIncludeArchivedProjectsToggle?: boolean
  showSavedFilterSets?: boolean
  isSearchFilterActive?: boolean
}

const FilterDropdownMenu = (props: Props) => {
  const {
    config,
    onClickSavedFilterSet,
    onClickAvailableFilter,
    savedFilterSets,
    onRemoveSavedFilterSet,
    onClickProjectMembersFilter,
    showSplitScreenMembersFilter,
    projectName,
    isTemplate,
    children,
    customPlacement,
    showIncludeArchivedProjectsToggle = false,
    showSavedFilterSets = true,
    isSearchFilterActive,
  } = props

  const { Can, subject } = usePermissions()

  return (
    <Can I="view" this={subject("UserFilterSet")}>
      <Dropdown<typeof FilterMenuButton>
        disabled={!config.features.editFilter}
        className={styles.container}
        Target={FilterMenuButton}
        targetProps={{ config }}
        minWidth={250}
        modifiers={{ offset: { options: { offset: [10, 10] } } }}
        placement={customPlacement}
      >
        {showSplitScreenMembersFilter && (
          <>
            <ProjectMembersFilterListItem
              projectName={projectName}
              onClick={onClickProjectMembersFilter}
              isTemplate={isTemplate}
            />
          </>
        )}
        {children}
        {showSavedFilterSets && (
          <SavedFilterSetList
            savedFilterSets={savedFilterSets}
            config={config}
            onClick={onClickSavedFilterSet}
            onRemove={onRemoveSavedFilterSet}
          />
        )}
        <AvailableFilterList config={config} onClick={onClickAvailableFilter} />
        {showIncludeArchivedProjectsToggle && (
          <>
            <MenuDivider />
            <IncludeArchivedProjectsToggle disabled={!isSearchFilterActive} />
          </>
        )}
      </Dropdown>
    </Can>
  )
}

export default FilterDropdownMenu
