/**
 * @generated SignedSource<<897fa39599e48348f937b5334c20d779>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type user_filter_sets_insert_input = {
  filters?: any | null | undefined;
  name?: string | null | undefined;
  type?: string | null | undefined;
};
export type FilterSetCreateMutation$variables = {
  filterSet: user_filter_sets_insert_input;
};
export type FilterSetCreateMutation$data = {
  readonly insert_user_filter_sets_one: {
    readonly filters: any;
    readonly id: number;
    readonly name: string;
    readonly type: string;
  } | null | undefined;
};
export type FilterSetCreateMutation = {
  response: FilterSetCreateMutation$data;
  variables: FilterSetCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterSet"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "filterSet"
      }
    ],
    "concreteType": "user_filter_sets",
    "kind": "LinkedField",
    "name": "insert_user_filter_sets_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filters",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterSetCreateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterSetCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5bfb53c7891976c9b1915d4005153661",
    "id": null,
    "metadata": {},
    "name": "FilterSetCreateMutation",
    "operationKind": "mutation",
    "text": "mutation FilterSetCreateMutation(\n  $filterSet: user_filter_sets_insert_input!\n) {\n  insert_user_filter_sets_one(object: $filterSet) {\n    id\n    type\n    name\n    filters\n  }\n}\n"
  }
};
})();

(node as any).hash = "f40ead5868425da550de931f7acc5544";

export default node;
