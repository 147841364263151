import { graphql } from "react-relay"

import { ViewCreateMutation } from "./__generated__/ViewCreateMutation.graphql"
import { ViewDeleteMutation } from "./__generated__/ViewDeleteMutation.graphql"
import { ViewUpdateMutation } from "./__generated__/ViewUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const viewCreateMutation = graphql`
  mutation ViewCreateMutation($view: CreateViewInput!) {
    createView(input: $view) {
      view {
        id
        name
        description
        people_filters
        project_filters
      }
      account {
        id
        views {
          id
        }
      }
    }
  }
`

type CreateOptions = {
  view: ViewCreateMutation["variables"]["view"]
}

export const viewCreateRelay = async (options: CreateOptions) => {
  const { view } = options
  const data = await commitMutationPromise<ViewCreateMutation>(environment, {
    mutation: viewCreateMutation,
    variables: { view },
  })
  return data.createView.view
}

const viewUpdateMutation = graphql`
  mutation ViewUpdateMutation($input: EditViewInput!) {
    editView(input: $input) {
      view {
        id
        name
        description
        project_filters
        people_filters
      }
    }
  }
`

export const viewUpdateRelay = async (
  input: ViewUpdateMutation["variables"]["input"],
) => {
  const data = await commitMutationPromise<ViewUpdateMutation>(environment, {
    mutation: viewUpdateMutation,
    variables: { input },
  })
  return data.editView.view.name
}

const viewDeleteMutation = graphql`
  mutation ViewDeleteMutation($id: Int!) {
    deleteView(input: { id: $id }) {
      account {
        id
        views {
          id
        }
      }
    }
  }
`

export const viewDeleteRelay = async (
  variables: ViewDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<ViewDeleteMutation>(environment, {
    mutation: viewDeleteMutation,
    variables,
  })
  return data
}
