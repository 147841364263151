/**
 * @generated SignedSource<<becffedc8dde561e91ef1b51d3dd252b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RateTypeAccountMutation$variables = {
  id: number;
  rate_type: string;
};
export type RateTypeAccountMutation$data = {
  readonly update_accounts_by_pk: {
    readonly id: number;
    readonly rate_type: string;
  } | null | undefined;
};
export type RateTypeAccountMutation = {
  response: RateTypeAccountMutation$data;
  variables: RateTypeAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rate_type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "rate_type",
            "variableName": "rate_type"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "accounts",
    "kind": "LinkedField",
    "name": "update_accounts_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rate_type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateTypeAccountMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateTypeAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bd7bd1dcec5b7caa511c9bc5307fb841",
    "id": null,
    "metadata": {},
    "name": "RateTypeAccountMutation",
    "operationKind": "mutation",
    "text": "mutation RateTypeAccountMutation(\n  $id: Int!\n  $rate_type: String!\n) {\n  update_accounts_by_pk(pk_columns: {id: $id}, _set: {rate_type: $rate_type}) {\n    id\n    rate_type\n  }\n}\n"
  }
};
})();

(node as any).hash = "84bd3240ef069183ca2a56f979349812";

export default node;
