/**
 * @generated SignedSource<<c74be27a6a80549754bea4f12460139d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerCalendarRow_account$data = {
  readonly default_full_time_minutes: number;
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentActionPill_account" | "DragToCreateOutline_account">;
  readonly " $fragmentType": "PlannerCalendarRow_account";
};
export type PlannerCalendarRow_account$key = {
  readonly " $data"?: PlannerCalendarRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerCalendarRow_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerCalendarRow_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DragToCreateOutline_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignmentActionPill_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "13328252145537ed4a009d4d2fab9b03";

export default node;
