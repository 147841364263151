import React from "react"

import styles from "./ButtonGroup.module.css"

type Props = {
  children: React.ReactNode
}

const ButtonGroup = (props: Props) => {
  const { children } = props
  return <div className={styles.container}>{children}</div>
}

export default ButtonGroup
