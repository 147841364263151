import { dateHelpers } from "@runn/calculations"
import React from "react"
import { connect } from "react-redux"

import styles from "./OutOfContractCurtains.module.css"

import calendarHelper from "~/helpers/CalendarHelper"
import {
  calculateAllContractDays,
  calculateCalendarDays,
  mergeDaysObjects,
} from "~/helpers/daysObjectHelpers"

const OutOfContractCurtain = (props: PropsCurtain) => {
  const {
    outOfContract,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
  } = props

  const offSet = calendarHelper.getItemOffsetPercent(
    calendarStartDate,
    calendarEndDate,
    outOfContract,
    calendarWeekendsExpanded,
  )
  const width = calendarHelper.getItemWidthPercent(
    calendarStartDate,
    calendarEndDate,
    outOfContract,
    calendarWeekendsExpanded,
  )
  return (
    <div
      className={styles.outOfContractCurtain}
      style={{ left: offSet, width }}
    />
  )
}

export const getOutOfContracts = (
  contracts,
  calendarStartDate,
  calendarEndDate,
) => {
  const start = Number(dateHelpers.formatToRunnDate(calendarStartDate))
  const end = Number(dateHelpers.formatToRunnDate(calendarEndDate))

  const contractStartsInCalendar = !!contracts.find(
    (c) => Number(c.start_date) >= start && Number(c.start_date) <= end,
  )
  const contractEndsInCalendar = !!contracts.find(
    (c) => Number(c.end_date) >= start && Number(c.end_date) <= end,
  )
  const contractExtendsThroughCalendar = !!contracts.find(
    (c) => Number(c.end_date) >= end || Number(c.start_date) < start,
  )

  // Ignore contracts if no dates showing on calendar
  if (
    !contractStartsInCalendar &&
    !contractEndsInCalendar &&
    !contractExtendsThroughCalendar
  ) {
    return []
  }

  const calendarDays = calculateCalendarDays(calendarStartDate, calendarEndDate)
  const days = Object.values(calendarDays)

  const contractDays = calculateAllContractDays({
    days,
    contracts,
    timeOffs: [],
    rangeStartDate: calendarStartDate,
    rangeEndDate: calendarEndDate,
  })
  const mergedDays = mergeDaysObjects(calendarDays, [contractDays])

  const outOfContracts = []

  // We make into max 1 week long out of contract days
  Object.values(mergedDays)
    .filter((c) => !c.contracted && !c.isWeekend)
    .forEach((c) => {
      // First out of contract day. Make a new OOC object
      if (outOfContracts.length === 0) {
        return outOfContracts.push({
          start_date: c.formattedDate,
          end_date: c.formattedDate,
        })
      }

      const lastOoc = outOfContracts[outOfContracts.length - 1]

      // Check if the next day. If so extend out of contract
      if (Number(c.formattedDate) - 1 === Number(lastOoc.end_date)) {
        return (lastOoc.end_date = c.formattedDate)
      }

      // Else add it as a new Out Of Contract
      return outOfContracts.push({
        start_date: c.formattedDate,
        end_date: c.formattedDate,
      })
    })
  return outOfContracts
}

const OutOfContractCurtains = (props: PropsCurtains) => {
  const {
    contracts,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
  } = props

  const outOfContracts = getOutOfContracts(
    contracts,
    calendarStartDate,
    calendarEndDate,
  )

  return (
    <>
      {outOfContracts.map((ooc) => (
        <OutOfContractCurtain
          key={ooc.start_date}
          outOfContract={ooc}
          calendarStartDate={calendarStartDate}
          calendarEndDate={calendarEndDate}
          calendarWeekendsExpanded={calendarWeekendsExpanded}
        />
      ))}
    </>
  )
}

type PropsCurtains = {
  contracts: readonly Contract[]
  calendarStartDate: Date
  calendarEndDate: Date
  calendarWeekendsExpanded: boolean
}

type PropsCurtain = {
  outOfContract: Contract
  calendarStartDate: Date
  calendarEndDate: Date
  calendarWeekendsExpanded: boolean
}

type Contract = {
  start_date: string
  end_date: string
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
})

const connector = connect(mapStateToProps)
export default connector(OutOfContractCurtains)
