import { Icon } from "@blueprintjs/core"
import React from "react"

import { SkillsLabelsList_viewer$data } from "./__generated__/SkillsLabelsList_viewer.graphql"

import { track } from "~/helpers/analytics"

import { Dropdown } from "~/common/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"

import { competencyDeleteRelay } from "~/mutations/Competency"

type Props = {
  competency_id: SkillsLabelsList_viewer$data["account"]["competencies"][0]["id"]
}

const SkillLabelMore = (props: Props) => {
  const { competency_id } = props

  const removeSkill = () => {
    track("Competency Deleted")
    void competencyDeleteRelay({ id: competency_id })
  }
  return (
    <Dropdown
      Target={({ active }) => (
        <IconThreeDot
          color="var(--winter)"
          size={13}
          active={active}
          data-test="skill-label-more"
        />
      )}
      placement="bottom"
    >
      <MenuItem
        text="Remove Skill"
        icon={<Icon icon="delete" />}
        onClick={removeSkill}
      />
    </Dropdown>
  )
}

export default SkillLabelMore
