/*
  This file is a bit magic.
  It contains multiple components and you choose at the top level was component you want to render.
  This is a deliberate choice, as it allows us to do a single request to the backend
  to get all the data. If we used the normal method of separating the components
  it would end up creating multiple requests for the same data as each `useLazyLoadQuery` would
  fire at the same time independently.
  If you can figure out a better way -- please refactor :)
 */
import React, { Suspense } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { ProjectStatsComponentQuery } from "./__generated__/ProjectStatsComponentQuery.graphql"

import ProjectBudgetLine from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetLine"
import ProjectGroupStats from "~/ProjectPlanner/ProjectRow/ProjectGroupStats"

import { FallBackErrorBoundary } from "./FallbackErrorBoundry"

type Props = {
  projectId: number
  type: "ProjectBudgetLine" | "BillableHoursForRole"
  groupId?: number
}

const Loader = (props: Props) => {
  const query = useLazyLoadQuery<ProjectStatsComponentQuery>(
    graphql`
      query ProjectStatsComponentQuery($projectId: Int!) {
        projects_by_pk(id: $projectId) {
          id
          ...ProjectGroupStats_project
          ...ProjectBudgetLine_project
        }
        current_user {
          id
          ...ProjectBudgetLine_user
        }
      }
    `,
    { projectId: props.projectId },
  )

  const project = query.projects_by_pk

  if (project === null) {
    return null
  }

  if (props.type === "ProjectBudgetLine") {
    return (
      <ProjectBudgetLine project={project} currentUser={query.current_user} />
    )
  }

  if (props.type === "BillableHoursForRole") {
    return <ProjectGroupStats project={project} groupId={props.groupId} />
  }

  return null
}

const ProjectStatsComponent = (props: Props) => {
  return (
    <FallBackErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <Loader {...props} />
      </Suspense>
    </FallBackErrorBoundary>
  )
}

export default ProjectStatsComponent
