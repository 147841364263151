import cc from "classcat"
import numbro from "numbro"
import React from "react"

import styles from "./HeadingBar.module.css"

import { IconRotatingChevron } from "./IconRotatingChevron"
import TooltipEllipsis from "./TooltipEllipsis"

type Props = {
  id?: string
  text: string | React.ReactElement
  count?: string | number | React.ReactElement
  className?: string
  style?: Record<string, any>
  isCollapsed?: boolean
  onClick?: () => void
  indented?: boolean
  children?: React.ReactElement
}

export const HeadingBarText = ({ count, text, showIcon, isCollapsed }) => (
  <div className={styles.text}>
    {showIcon && (
      <IconRotatingChevron
        direction={isCollapsed ? "right" : "down"}
        color="var(--shadow)"
        style={{ marginRight: 5 }}
      />
    )}
    <TooltipEllipsis text={text} />
    {typeof count === "object" ? (
      <span data-test="heading-bar-count" className={styles.count}>
        {count}
      </span>
    ) : Boolean(count) ? (
      <span data-test="heading-bar-count" className={styles.count}>
        {numbro(count).format({
          thousandSeparated: true,
          mantissa: 0,
        })}
      </span>
    ) : null}
  </div>
)

const HeadingBar = (props: Props) => {
  const {
    text,
    className,
    isCollapsed,
    count,
    onClick,
    indented = false,
    children,
    ...rest
  } = props

  return (
    <div
      className={cc([
        styles.headingBar,
        className,
        {
          [styles.collapsible]: onClick,
          [styles.sticky]: onClick,
          [styles.indented]: indented,
        },
      ])}
      onClick={onClick}
      {...rest}
    >
      <HeadingBarText
        count={count}
        text={text}
        showIcon={Boolean(onClick)}
        isCollapsed={isCollapsed}
      />
      {children}
    </div>
  )
}

export default HeadingBar
