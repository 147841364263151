/**
 * @generated SignedSource<<6c7008270b313fca7a699ba0c0071965>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerLeftColumn_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PersonPlannerMenu_user" | "PersonSidePanel_user">;
  readonly " $fragmentType": "PlannerLeftColumn_user";
};
export type PlannerLeftColumn_user$key = {
  readonly " $data"?: PlannerLeftColumn_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerLeftColumn_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonSidePanel_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonPlannerMenu_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "55b5b721a37215785ac151c1126f2c55";

export default node;
