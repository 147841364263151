import React, { useMemo } from "react"
import {
  Area,
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import styles from "~/Planner/ChartPanel/Chart/common.module.css"

import { ChartTooltipIcon } from "../common/TooltipContent/ChartTooltipIcon"
import TooltipContent from "~/Planner/ChartPanel/Chart/common/TooltipContent"

import { useChartData } from "~/Planner/ChartPanel/Chart/useChartData"
import type { ChartProps } from "~/Planner/ChartPanel/types"

type Payload = {
  utilizationNonbillable: number
  utilizationBillable: number
  effectiveCapacity: number
  timeOff: number
  timeOffPercentage: number
}

const getTooltipRows = (payload: Payload) => {
  // There needs to be capacity to have utilization,
  // If there is no capacity, we show "--" instead of 0%
  return [
    {
      icon: <ChartTooltipIcon background="rgba(var(--runn-blue_rgb), 0.4)" />,
      label: "Billable Utilization",
      value: payload.effectiveCapacity
        ? Math.round(payload.utilizationBillable) + "%"
        : "--",
    },
    {
      icon: <ChartTooltipIcon background="rgba(var(--slate_rgb), 0.4)" />,
      label: "Non Billable Utilization",
      value: payload.effectiveCapacity
        ? Math.round(payload.utilizationNonbillable) + "%"
        : "--",
    },
    {
      icon: <ChartTooltipIcon background="var(--chart-stripes-legend)" />,
      label: "Time Off",
      value: payload.timeOff + "h",
    },
    {
      label: <strong>Total Utilization</strong>,
      value: (
        <strong>
          {payload.effectiveCapacity
            ? `${Math.round(
                payload.utilizationNonbillable + payload.utilizationBillable,
              )}%`
            : "--"}
        </strong>
      ),
    },
  ]
}

const ChartUtilization = (props: ChartProps) => {
  const {
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
    enableAnimations,
  } = props

  const data = useChartData({
    people,
    projects,
    startDate,
    endDate,
    period,
    includeWeekends,
  })

  const utilizationMaxValue = useMemo(() => {
    const maxValue = Math.round(
      Math.max(
        100,
        ...data.map(
          (d) =>
            d.utilizationBillable +
            d.utilizationNonbillable +
            d.timeOffPercentage,
        ),
      ),
    )

    return maxValue
  }, [data])

  // We create a YAxis separately so that we can move it around
  return (
    <>
      <div className={styles.yAxis}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: -10, bottom: 0 }}
          >
            <YAxis
              unit="%"
              axisLine={false}
              tickLine={false}
              type="number"
              dataKey="utilization"
              interval="preserveStart"
              domain={[0, utilizationMaxValue]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.chartContainer}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <pattern
                id="chartStripes"
                patternUnits="userSpaceOnUse"
                width="5"
                height="5"
                patternTransform="rotate(-45)"
              >
                <line
                  x1="0"
                  y="0"
                  x2="0"
                  y2="5"
                  stroke="rgba(var(--shadow_rgb), 0.4)"
                  strokeWidth="2"
                />
              </pattern>
            </defs>
            <Tooltip
              content={
                <TooltipContent
                  data={data}
                  getRows={getTooltipRows}
                  period={period}
                />
              }
              isAnimationActive={false}
              offset={10}
            />
            <XAxis dataKey="formattedDate" hide />
            {/* Y Axis */}
            <YAxis
              type="number"
              interval="preserveStart"
              hide
              domain={[0, utilizationMaxValue]}
            />
            {utilizationMaxValue > 100 && (
              <ReferenceLine
                y={100}
                stroke="var(--alert)"
                strokeDasharray="3 3"
              />
            )}
            <Area
              type="monotone"
              dataKey="utilizationBillable"
              stackId="1"
              fill="rgba(var(--runn-blue_rgb), 0.4)"
              stroke="var(--runn-blue)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            />
            <Area
              type="monotone"
              dataKey="utilizationNonbillable"
              stackId="1"
              fill="rgba(var(--slate_rgb), 0.4)"
              stroke="var(--slate)"
              isAnimationActive={enableAnimations}
              animationDuration={200}
            />
            <Area
              type="monotone"
              dataKey="timeOffPercentage"
              fill="url(#chartStripes)"
              activeDot={false}
              stackId="1"
              stroke="var(--shadow)"
            />
            /* This is just here to force the the mixed bar / area chart */
            <Bar
              dataKey="utilizationBillable"
              barSize={0}
              stackId="a"
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export { ChartUtilization }
