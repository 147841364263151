/**
 * @generated SignedSource<<0f8a86136ccc6735e095b85578b7fdba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TimeOffDeleteMutation$variables = {
  id: number;
};
export type TimeOffDeleteMutation$data = {
  readonly deleteTimeOff: {
    readonly time_off: {
      readonly id: number;
    } | null | undefined;
  };
};
export type TimeOffDeleteMutation = {
  response: TimeOffDeleteMutation$data;
  variables: TimeOffDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteTimeOffResult",
    "kind": "LinkedField",
    "name": "deleteTimeOff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "time_offs",
        "kind": "LinkedField",
        "name": "time_off",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeOffDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f958d401d062776864e50a4ac58065e1",
    "id": null,
    "metadata": {},
    "name": "TimeOffDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TimeOffDeleteMutation(\n  $id: Int!\n) {\n  deleteTimeOff(id: $id) {\n    time_off {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c0614d9558ecc84cf375f859e9c8457";

export default node;
