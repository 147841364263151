import React, { useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

import {
  TemplateSelector_project$data,
  TemplateSelector_project$key,
} from "./__generated__/TemplateSelector_project.graphql"

import { sortByString } from "../../helpers/sorting-helpers"
import {
  CustomCheckboxValue,
  CustomDateValue,
  CustomFieldsMap,
  CustomSelectValue,
  CustomTextValue,
} from "~/helpers/custom-field-helpers"

import Select from "../../common/Select"

import { usePermissions } from "~/Permissions/usePermissions"

const formatTemplateOption = (template) => {
  if (!template) {
    return null
  }

  return {
    value: template.id,
    label: template.name,
    client_id: template.client_id,
    team_id: template.team_id,
    rate_card_id: template.rate_card_id,
    pricing_model: template.pricing_model,
    tags_computed: template.tags_computed,
    workstreams_computed: template.workstreams_computed,
    custom_values: {
      text: template.custom_text_values,
      select: template.custom_select_values,
      checkbox: template.custom_checkbox_values,
      date: template.custom_date_values,
    } satisfies CustomFieldsMap<
      CustomTextValue,
      CustomSelectValue,
      CustomCheckboxValue,
      CustomDateValue
    >,
    ...template,
  }
}

type TemplateSelectorProps = {
  projectTemplatesQuery: TemplateSelector_project$key
  onChange: (selectedTemplate: TemplateSelector_project$data[0]) => void
}

const fragment = graphql`
  fragment TemplateSelector_project on projects @relay(plural: true) {
    id
    name
    tags_computed
    workstreams_computed
    active
    pricing_model
    client_id
    rate_card_id
    team_id
    # Referenced directly in <ProjectForm> as we need it in addition to the fragment inclusion
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      typeId: custom_checkbox_type_id
      value
    }
    custom_date_values {
      typeId: custom_date_type_id
      value
    }
    members {
      id
      person_id
    }
    ...ProjectCustomEditor_project
  }
`

const TemplateSelector = (props: TemplateSelectorProps) => {
  const { projectTemplatesQuery, onChange } = props

  const projectTemplates = useFragment<TemplateSelector_project$key>(
    fragment,
    projectTemplatesQuery,
  )

  const { can, subject } = usePermissions()

  const templateOptions = [...projectTemplates]
    .filter((template) =>
      can(
        "create-from-template",
        subject("ProjectTemplate", {
          ...template,
          members: template.members.map(({ person_id }) => person_id),
        }),
      ),
    )
    .sort((a, b) => sortByString(a.name, b.name))
    .map(formatTemplateOption)

  const [selectedTemplate, setSelectedTemplate] = useState(null)

  useEffect(() => {
    onChange(selectedTemplate)
  }, [selectedTemplate, onChange])

  return (
    <Select
      name="project-template"
      id="project-template"
      value={selectedTemplate}
      label="Template"
      onChange={setSelectedTemplate}
      options={templateOptions}
      placeholder="Select project template"
    />
  )
}

export default TemplateSelector
