import { Icon } from "@blueprintjs/core"
import React from "react"

import styles from "./PlannerTooltipIcons.module.css"

import {
  CustomType,
  CustomValuesMap,
  hasCustomValuesShowInPlanner,
} from "~/helpers/custom-field-helpers"

import CompetencyTooltipList from "~/Skills/Competency/CompetencyTooltipList"
import TagsTooltip from "~/tagging/TagsTooltip"

import CustomTooltip from "./CustomTooltip/CustomTooltip"

type Props = {
  icons: {
    tags?: string[]
    competencies?: ReadonlyArray<{
      level: number | null
      skill: {
        name: string
      }
    }>
    customFields?: {
      types: CustomType[]
      values: CustomValuesMap
    }
    links?: ReadonlyArray<{
      name: string
      href: string
    }>
  }
}

const PlannerTooltipIcons = (props: Props) => {
  const { tags, competencies, customFields, links } = props.icons

  // Links get bundled in with the custom fieds dropdown
  const hasCustomFieldsOrLinks =
    (links && links.length) ||
    hasCustomValuesShowInPlanner(customFields?.types, customFields?.values)

  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
      {tags && (
        <TagsTooltip tags={tags}>
          <Icon icon="tag" style={{ cursor: "default" }} />
        </TagsTooltip>
      )}
      {competencies && <CompetencyTooltipList competencies={competencies} />}
      {hasCustomFieldsOrLinks && (
        <CustomTooltip
          typesMap={customFields?.types}
          valuesMap={customFields?.values}
          links={links}
        />
      )}
    </div>
  )
}

export default PlannerTooltipIcons
