import { dateHelpers } from "@runn/calculations"

import { CalculatedActual } from "~/actuals/helpers/actuals-helpers"

import { getDaysInRange } from "./CalendarHelper"
import { calculateStartAndEndDatesWithinRange } from "./daysObjectHelpers"

export type Phase = {
  id: number
  name: string
  color: string
  start_date?: string
  end_date?: string
}

type PhaseMetricsInput = {
  hoursByDay: CalculatedActual[]
  multiFactor: number
}

type PhaseMetricsOutput = {
  revenue: number
  margin: number
  cost: number
  hours: number
}

export const getGridAttributes = ({
  start,
  end,
  calendarStartDate,
  calendarEndDate,
  dayWidth,
  calendarWeekendsExpanded,
}: {
  start: Date
  end: Date
  calendarStartDate: Date
  calendarEndDate: Date
  dayWidth: number
  calendarWeekendsExpanded: boolean
}) => {
  // only get dates within the calendarRange
  const datesWithinRange =
    start &&
    calculateStartAndEndDatesWithinRange(
      {
        start_date: dateHelpers.formatToRunnDate(start),
        end_date: dateHelpers.formatToRunnDate(end || start),
      },
      calendarStartDate,
      calendarEndDate,
    )

  const gridStart = start
    ? getDaysInRange({
        start: calendarStartDate,
        end: datesWithinRange.startDate,
        includeWeekends: calendarWeekendsExpanded,
      })
    : null

  const gridSpan =
    (end &&
      getDaysInRange({
        start: datesWithinRange.startDate,
        end: datesWithinRange.endDate,
        includeWeekends: calendarWeekendsExpanded,
      })) ||
    1
  const lengthInPx = Math.abs(gridSpan * dayWidth)

  return {
    gridStart,
    gridSpan,
    lengthInPx,
  }
}

export const calculatePhaseMetrics = ({
  hoursByDay,
  multiFactor,
}: PhaseMetricsInput): PhaseMetricsOutput => {
  const hours = hoursByDay.reduce(
    (acc: number, val) =>
      acc +
      (val.combined_nonbillable_minutes + val.combined_billable_minutes) / 60,
    0,
  )

  const revenue =
    hoursByDay.reduce(
      (acc: number, val) => acc + val.combined_billable_amount,
      0,
    ) * multiFactor
  const cost = hoursByDay.reduce((acc, val) => acc + val.combined_cost, 0)
  const margin = revenue && cost ? ((revenue - cost) / revenue) * 100 : 0

  return {
    revenue,
    cost,
    margin,
    hours,
  }
}
