import React, { useState } from "react"

import { ModalFormWrapper } from "~/common/ModalForm"

import AvailableOnPlansChip from "~/Entitlements/AvailableOnPlansChip"
import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import RocketTakeOffTangram from "~/forms/CreateProject/RocketTakeOffTangram"

import CreateProjectForm, {
  ProjectReturn,
} from "../CreateProjectForm/CreateProjectForm"

type Props = {
  closeDialog: () => void
  onSuccess?: (project: ProjectReturn) => void
}

const CreateProjectTemplateForm = (props: Props) => {
  const { onSuccess, closeDialog } = props

  const [activateAnimation, setActivateAnimation] = useState(false)

  return (
    <ModalFormWrapper
      headerTitle={
        <>
          New Project Template{" "}
          <AvailableOnPlansChip
            featureId={ChargebeeFeatures.projectTemplates}
          />
        </>
      }
      wide
      tangram={<RocketTakeOffTangram activate={activateAnimation} />}
      tangramStyles={{ marginBottom: 15 }}
    >
      <CreateProjectForm
        onSuccess={onSuccess}
        onClose={closeDialog}
        isTemplate
        activateAnimation={() => setActivateAnimation(true)}
      />
    </ModalFormWrapper>
  )
}

export default CreateProjectTemplateForm
