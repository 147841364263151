import cc from "classcat"
import React from "react"

import styles from "./Emoji.module.css"

type Props = {
  emoji: string
  style?: Record<string, any>
}
const Emoji = (props: Props) => {
  const { emoji, ...rest } = props
  return (
    <div className={cc(styles.wrapper)} {...rest}>
      {emoji}
    </div>
  )
}

export default Emoji
