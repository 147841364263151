import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./ProjectBudget.module.css"

import currency, { formatCurrency } from "~/helpers/CurrencyHelper"
import { getAllocationMessage } from "~/helpers/otherCosts"

import { Delete, Warning } from "~/common/react-icons"

import ExpenseBudgetInput from "./ExpenseBudgetInput"
import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  value: number
  canDelete?: boolean
  disabled?: boolean
  deleteTooltip?: string
  showNonBillableView?: boolean
  onUpdate?: (val: number) => void
  onDelete?: () => void
  expensesAllocationDifference: number
  expensesBudget: number
}

const OtherExpensesBudgetItem = (props: Props) => {
  const {
    value,
    canDelete = false,
    disabled = false,
    showNonBillableView = false,
    onUpdate,
    onDelete,
    deleteTooltip,
    expensesAllocationDifference,
    expensesBudget,
  } = props

  const overAllocated = expensesAllocationDifference < 0
  const overOrBelowWarning =
    !!expensesBudget && expensesAllocationDifference !== 0

  return (
    <Row
      dataTest="other-expenses-row"
      className={styles.otherExpenesAllocation}
    >
      <Column
        className={styles.otherExpensesLabel}
        flex
        span={showNonBillableView ? 1 : 5}
      >
        <span
          className={cc({
            [styles.otherExpensesWarning]: overAllocated && overOrBelowWarning,
          })}
        >
          Other Expenses
        </span>
        {overOrBelowWarning && (
          <Tooltip
            content={`${getAllocationMessage(expensesAllocationDifference)} ${formatCurrency(Math.abs(expensesAllocationDifference))}`}
            placement="top"
          >
            <Warning
              color="var(--warning)"
              data-test="budget-expenses-warning"
            />
          </Tooltip>
        )}
      </Column>
      <Column>
        <ExpenseBudgetInput
          name="other-expenses-budget_dollars"
          dataTest="other-expenses-budget_dollars"
          value={value}
          currency={currency}
          disabled={disabled}
          onUpdate={onUpdate}
        />
      </Column>
      <div
        className={cc([
          styles.deleteIconContainer,
          !canDelete && styles.disabled,
        ])}
        data-test="budget-expenses-delete"
        onClick={() => canDelete && onDelete && onDelete()}
      >
        <Delete alt={deleteTooltip} color="var(--winter)" />
      </div>
    </Row>
  )
}

export default OtherExpensesBudgetItem
