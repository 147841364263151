import React from "react"

import styles from "./SelectorModal.module.css"

import Dialog from "~/common/Dialog"

type Props = {
  className?: string
  style?: Record<string, any>
  children: React.ReactNode
  isOpen: boolean
  onClose?: () => void
  canOutsideClickClose?: boolean
  showCloseButton?: boolean
  modalWidth?: number
}

const SelectorModal = (props: Props) => {
  const { children, ...otherProps } = props
  const modalWidth = props.modalWidth ? `${props.modalWidth}px` : "635px"

  return (
    <Dialog usePortal autoFocus enforceFocus {...otherProps}>
      <div
        className={styles.selectorModal}
        style={{ width: modalWidth }}
        data-test="selector-modal"
      >
        {children}
      </div>
    </Dialog>
  )
}

export default SelectorModal
