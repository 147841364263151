import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type PlannerProjectMembersState = {
  showAllMembersProjects: number[]
  showAllMembershipsPeople: number[]
  toggleTimestampsProjects: Record<number, number>
  toggleTimestampsPeople: Record<number, number>
}

const getInitialState = (): PlannerProjectMembersState => ({
  showAllMembersProjects: [],
  showAllMembershipsPeople: [],
  toggleTimestampsProjects: {},
  toggleTimestampsPeople: {},
})

const plannerProjectMembersView = createSlice({
  name: "plannerProjectMembersView",
  initialState: getInitialState,
  reducers: {
    showAllMembersInProject: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const isDuplicate = state.showAllMembersProjects.includes(id)
      if (!isDuplicate) {
        state.showAllMembersProjects.push(id)
        state.toggleTimestampsProjects[id] = Date.now()
      }
    },
    showAllMembershipsInPerson: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const isDuplicate = state.showAllMembershipsPeople.includes(id)
      if (!isDuplicate) {
        state.showAllMembershipsPeople.push(id)
        state.toggleTimestampsPeople[id] = Date.now()
      }
    },

    hideInactiveMembersInProject: (state, action: PayloadAction<number>) => {
      const id = action.payload
      state.toggleTimestampsProjects[id] = Date.now()
      const index = state.showAllMembersProjects.indexOf(id)
      if (index !== -1) {
        state.showAllMembersProjects.splice(index, 1)
      }
    },
    hideInactiveMembershipsInPerson: (state, action: PayloadAction<number>) => {
      const id = action.payload
      state.toggleTimestampsPeople[id] = Date.now()
      const index = state.showAllMembershipsPeople.indexOf(id)
      if (index !== -1) {
        state.showAllMembershipsPeople.splice(index, 1)
      }
    },
  },
})

const { actions, reducer } = plannerProjectMembersView

export const {
  showAllMembersInProject,
  hideInactiveMembersInProject,
  showAllMembershipsInPerson,
  hideInactiveMembershipsInPerson,
} = actions
export default reducer

const isAllMembersShown = (
  showAllMembersProjects: number[],
  id: number,
): boolean => showAllMembersProjects.includes(id)

export const selectIsAllMembersShown = (
  state: PlannerProjectMembersState,
  id: number,
): boolean => isAllMembersShown(state.showAllMembersProjects, id)

const isAllMembershipsShown = (
  showAllMembershipsPeople: number[],
  id: number,
): boolean => showAllMembershipsPeople.includes(id)

export const selectIsAllMembershipsShown = (
  state: PlannerProjectMembersState,
  id: number,
): boolean => isAllMembershipsShown(state.showAllMembershipsPeople, id)

export const selectProjectLastToggled = (
  state: PlannerProjectMembersState,
  id: number,
): number => state.toggleTimestampsProjects[id] ?? 0

export const selectPersonLastToggled = (
  state: PlannerProjectMembersState,
  id: number,
): number => state.toggleTimestampsPeople[id] ?? 0
