/**
 * @generated SignedSource<<134f63eccc1e09a26a31d5301856ed1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderWorkflowMenu_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderSidePanel_account">;
  readonly " $fragmentType": "PlaceholderWorkflowMenu_account";
};
export type PlaceholderWorkflowMenu_account$key = {
  readonly " $data"?: PlaceholderWorkflowMenu_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderWorkflowMenu_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderWorkflowMenu_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderSidePanel_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "4c889a2969a392dcf4c7ae2c5e574d28";

export default node;
