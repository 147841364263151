import { dateHelpers, eachDay } from "@runn/calculations"
import {
  addBusinessDays,
  differenceInCalendarDays,
  isWeekend,
  parseISO,
  subBusinessDays,
} from "date-fns"
import React from "react"

import { TIME_OFF_TYPES } from "~/ENUMS"

export const dateIsAHoliday = <
  T extends {
    start_date?: string
    leave_type?: string
  },
>(
  timeOffs: readonly T[],
  date: string,
): boolean =>
  timeOffs.some(
    (timeOff) =>
      timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY &&
      timeOff.start_date === date,
  )

export const dateIsAHolidayOrRdo = <
  T extends {
    start_date: string
    leave_type: string
  },
>(
  timeOffs: readonly T[],
  date: string,
): boolean =>
  timeOffs.some(
    (timeOff) =>
      timeOff.start_date === date &&
      (timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY ||
        timeOff.leave_type === TIME_OFF_TYPES.ROSTERED),
  )

export const getMergedTimeOffs = <T extends TimeOffWithMinutesPerDay>(
  timeOffs: readonly T[],
  startDate: string | Date,
  endDate: string | Date,
): T[] => {
  if (!timeOffs.length) {
    return []
  }

  const startDateRange =
    typeof startDate === "string"
      ? dateHelpers.parseRunnDate(startDate)
      : startDate
  const endDateRange =
    typeof endDate === "string" ? dateHelpers.parseRunnDate(endDate) : endDate

  const timeOffsWithinRange = timeOffs.filter((to) => {
    const timeOffStart = dateHelpers.parseRunnDate(to.start_date)
    const timeOffEnd = dateHelpers.parseRunnDate(to.end_date)
    return timeOffStart <= endDateRange && timeOffEnd >= startDateRange
  })

  const mergedTimeOffs: T[] = []
  const timeOffMap = new Map<number, Set<T>>()

  for (const item of timeOffsWithinRange) {
    const timeOffStart = dateHelpers.parseRunnDate(item.start_date)
    const timeOffEnd = dateHelpers.parseRunnDate(item.end_date)

    const itemDates = new Set(
      Array.from(eachDay(timeOffStart, timeOffEnd)).map((date) =>
        date.getTime(),
      ),
    )

    let hasOverlap = false
    const overlappingTimeOffs: T[] = []

    for (const date of itemDates) {
      const existingTimeOffs = timeOffMap.get(date)
      if (existingTimeOffs) {
        for (const existingTimeOff of existingTimeOffs) {
          hasOverlap = true
          overlappingTimeOffs.push(existingTimeOff)
        }
      }
    }

    if (hasOverlap) {
      const itemDuration = differenceInCalendarDays(timeOffEnd, timeOffStart)
      let primaryTimeOff = item
      const secondaryTimeOffs = [...overlappingTimeOffs]

      for (const existingTimeOff of overlappingTimeOffs) {
        const existingDuration = differenceInCalendarDays(
          dateHelpers.parseRunnDate(existingTimeOff.end_date),
          dateHelpers.parseRunnDate(existingTimeOff.start_date),
        )

        // Make the longer time off the main time off
        if (existingDuration > itemDuration) {
          primaryTimeOff = existingTimeOff
        }

        const existingIndex = mergedTimeOffs.findIndex(
          (t) => t.id === existingTimeOff.id,
        )

        // This time off will now be part of primaryTimeOff.overlappingTimeOffs
        // so we remove it from mergedTimeOffs
        if (existingIndex !== -1) {
          mergedTimeOffs.splice(existingIndex, 1)
        }
      }

      const updatedPrimaryTimeOff = {
        ...primaryTimeOff,
        overlappingTimeOffs: [
          ...(primaryTimeOff.overlappingTimeOffs || []),
          ...secondaryTimeOffs.filter((to) => to.id !== primaryTimeOff.id),
        ],
      }

      const primaryTimeOffIndex = mergedTimeOffs.findIndex(
        (t) => t.id === primaryTimeOff.id,
      )
      if (primaryTimeOffIndex !== -1) {
        mergedTimeOffs[primaryTimeOffIndex] = updatedPrimaryTimeOff
      } else {
        mergedTimeOffs.push(updatedPrimaryTimeOff)
      }
    } else {
      mergedTimeOffs.push(item)
    }

    for (const date of itemDates) {
      if (!timeOffMap.has(date)) {
        timeOffMap.set(date, new Set())
      }
      timeOffMap.get(date)!.add(item)
    }
  }

  return mergedTimeOffs
}

export const getMergedHolidays = <T extends TimeOffWithMinutesPerDay>(
  holidays: readonly T[],
): T[] => {
  return holidays.reduce<T[]>((acc, item) => {
    if (item.leave_type !== TIME_OFF_TYPES.HOLIDAY) {
      return acc
    }
    const holidayWithDuplicateDate = acc.find(
      (h) => h.start_date === item.start_date,
    )

    if (holidayWithDuplicateDate) {
      return [
        ...acc.filter((a) => a.id !== holidayWithDuplicateDate.id),
        {
          ...holidayWithDuplicateDate,
          overlappingHolidays: holidayWithDuplicateDate.overlappingHolidays
            ? [...holidayWithDuplicateDate.overlappingHolidays, item]
            : [item],
        },
      ]
    }
    return [...acc, item]
  }, [])
}
type SortedHolidays<T extends TimeOff> = {
  holidays: T[]
  others: T[]
}
type MergedHolidays<T extends TimeOff> = {
  mergedHolidays: T[]
  others: T[]
}
export const separateHolidaysFromTimeOffs = <
  T extends TimeOffWithMinutesPerDay,
>(
  timeOffs: readonly T[],
): MergedHolidays<T> => {
  const all = timeOffs.reduce<SortedHolidays<T>>(
    (sortedObject, timeOff) => {
      timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY
        ? sortedObject.holidays.push(timeOff)
        : sortedObject.others.push(timeOff)

      return sortedObject
    },
    { holidays: [], others: [] } as SortedHolidays<T>,
  )

  return { mergedHolidays: getMergedHolidays(all.holidays), others: all.others }
}

export const groupTimeOffsByType = <T extends TimeOffWithMinutesPerDay>(
  timeOffs: readonly T[],
): { rosteredOffs: T[]; mergedHolidays: T[]; annualLeave: T[] } => {
  const rosteredOffs: T[] = []
  const holidays: T[] = []
  const annualLeave: T[] = []

  timeOffs.forEach((timeOff) => {
    if (timeOff.leave_type === TIME_OFF_TYPES.ROSTERED) {
      rosteredOffs.push(timeOff)
    } else if (timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY) {
      holidays.push(timeOff)
    } else {
      annualLeave.push(timeOff)
    }
  })

  const mergedHolidays = getMergedHolidays(holidays)

  return { rosteredOffs, mergedHolidays, annualLeave }
}

export const sortTimeOffsByHierarchy = <T extends TimeOffWithMinutesPerDay>(
  timeOffs: readonly T[],
): T[] => {
  const { rosteredOffs, mergedHolidays, annualLeave } =
    groupTimeOffsByType(timeOffs)

  return [...rosteredOffs, ...mergedHolidays, ...annualLeave]
}

const isNonWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date,
): boolean => {
  return (
    dateIsAHoliday(timeOffs, dateHelpers.formatToRunnDate(date)) ||
    isWeekend(date)
  )
}

// get the the earliest business day
// eg. if the date falls on a holiday that is occurring on a Wednesday, return Tuesday
// eg. if the date falls on a weekend, return Friday
export const getClosestPreviousWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date | string,
): DateSet => {
  let newDate = typeof date === "string" ? parseISO(date) : date

  while (isNonWorkingDay(timeOffs, newDate)) {
    newDate = subBusinessDays(newDate, 1)
  }

  return { date: newDate, stringDate: dateHelpers.formatToRunnDate(newDate) }
}

export const getClosestNextWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date | string,
): DateSet => {
  let newDate = typeof date === "string" ? parseISO(date) : date

  while (isNonWorkingDay(timeOffs, newDate)) {
    newDate = addBusinessDays(newDate, 1)
  }

  return { date: newDate, stringDate: dateHelpers.formatToRunnDate(newDate) }
}

const dateWithinRange = (
  date: string,
  range: { start: string; end: string },
) => {
  return date >= range.start && date <= range.end
}

export const getNumberOfHolidaysWithinTimeOff = <T extends TimeOff>(
  timeOff: T,
  holidays: ReadonlyArray<T>,
) => {
  return (
    timeOff.leave_type === "annual" &&
    holidays.filter(
      (h) =>
        timeOff.end_date &&
        dateWithinRange(h.start_date, {
          start: timeOff.start_date,
          end: timeOff.end_date,
        }),
    ).length
  )
}

type GetHolidaysOverlappingTimeOffs = {
  timeOffs: readonly TimeOffWithMinutesPerDay[]
  range?: {
    start: number
    end: number
  }
}

export const getHolidaysOverlappingTimeOffs = ({
  timeOffs,
  range,
}: GetHolidaysOverlappingTimeOffs): string[] => {
  const timeOffsWithinRange =
    (range &&
      timeOffs
        .filter((a) => Number(a.end_date) >= range.start)
        .filter((a) => Number(a.start_date) <= range.end)) ||
    timeOffs

  const { mergedHolidays, others } =
    separateHolidaysFromTimeOffs(timeOffsWithinRange)

  const overlappingHolidays = mergedHolidays.filter((h) =>
    others.some(
      (o) =>
        o.end_date &&
        dateWithinRange(h.start_date, {
          start: o.start_date,
          end: o.end_date,
        }),
    ),
  )

  return overlappingHolidays.map((h) => h.start_date)
}

export const getHolidayNote = (timeOff: TimeOffWithHoliday) => {
  const holidayGroupName = timeOff.holiday.holidays_group.name
  return (
    <>
      <span>
        {timeOff.note} {`(${holidayGroupName})`}
      </span>
      {timeOff.overlappingHolidays?.map((oh) => {
        return (
          <>
            <br />
            <span>
              {oh.note} {`(${holidayGroupName})`}
            </span>
          </>
        )
      })}
    </>
  )
}

export type TimeOff = {
  start_date: string
  leave_type: string
  end_date: string
  note?: string
  overlappingTimeOffs?: TimeOff[]
  holiday_id?: number
  overlappingHolidays?: TimeOff[]
}

export type TimeOffWithMinutesPerDay = TimeOff & {
  id: number
  minutes_per_day: number
}

type TimeOffWithHoliday = TimeOff & {
  holiday: {
    id: number
    name: string
    holidays_group: {
      id: number
      name: string
    }
  }
}

type DateSet = {
  date: Date
  stringDate: string
}

type Holiday = {
  uuid: string
  country: string
  date: string
  name: string
  weekday: {
    date: {
      name: string
      numeric: number
    }
    observed: {
      name: string
      numeric: number
    }
  }
  observed: string
  public: boolean
}

type DateObj = {
  date: string
  id?: number
}

export type PublicHoliday = {
  holiday: Holiday
  currentYear: Holiday
  secondYear: Holiday
  thirdYear: Holiday
}

export type CustomHoliday = {
  id: number | null
  name: string
  currentYear: DateObj | null
  secondYear: DateObj | null
  thirdYear: DateObj | null
  autoFocus: boolean
}

export default {
  dateIsAHoliday,
  separateHolidaysFromTimeOffs,
  getClosestPreviousWorkingDay,
}
