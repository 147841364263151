import { Tooltip } from "@blueprintjs/core"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "~/ProjectPlanner/ProjectBudgetLine/ProjectBudgetLine.module.css"

import { ProjectBudgetFinancial_project$key } from "~/ProjectPlanner/ProjectBudgetLine/__generated__/ProjectBudgetFinancial_project.graphql"

import { formatToMode } from "~/helpers/budgetHelper"
import { getTotalExpenses } from "~/helpers/otherCosts"

type Props = {
  project: ProjectBudgetFinancial_project$key
  isBillable: boolean
}

const ProjectBudgetFinancial = (props: Props) => {
  const { isBillable } = props
  const project = useFragment(
    graphql`
      fragment ProjectBudgetFinancial_project on projects {
        id
        is_template
        pricing_model
        total_budget: total_budget_private
        summary {
          role_id
          total_billable_minutes
          total_nonbillable_minutes
        }
        project_roles {
          id
          role_id
          estimated_minutes
        }
        project_rates {
          role_id
          rate: rate_private
        }
        other_costs(order_by: { date: asc }) {
          id
          charge
          cost
        }
      }
    `,
    props.project,
  )

  // Financials
  const billableAmount = project.summary.reduce((acc, s) => {
    const roleRate =
      project.project_rates.find((r) => r.role_id === s.role_id)?.rate || 0
    return acc + (s.total_billable_minutes / 60) * roleRate
  }, 0)

  const budget = project.total_budget
  const otherExpenses = getTotalExpenses(project.other_costs)
  const totalAmount = billableAmount + otherExpenses.charge

  const financialBudgetUsed = (totalAmount / budget) * 100
  const financialBudgetPercentage =
    financialBudgetUsed > 100 ? 100 : financialBudgetUsed
  const financialOverBudgetPercentage =
    financialBudgetUsed > 100 ? financialBudgetUsed - 100 : 0
  const financialOverBudgetPercentageFixed =
    financialOverBudgetPercentage > 100 ? 100 : financialOverBudgetPercentage

  const TooltipContent = () => (
    <div>
      <b>Revenue</b>
      <br />
      Predicted: {formatToMode(totalAmount, "$", false, 1)}
      {!!budget && (
        <>
          <br />
          Budget: {formatToMode(budget, "$", false, 1)}
        </>
      )}
      {!!budget && (
        <>
          <br />
          Allocated: {financialBudgetUsed.toFixed(2)}%
        </>
      )}
    </div>
  )

  if (!isBillable) {
    return null
  }

  return (
    <>
      <div className={styles.lineContainer}>
        {!!budget && (
          <Tooltip content={<TooltipContent />} placement="top">
            <div className={styles.budgetLine}>
              <div
                className={styles.budgetUsedLine}
                style={{ width: `${financialBudgetPercentage}%` }}
              ></div>
              <div
                className={styles.budgetOverLine}
                style={{ width: `${financialOverBudgetPercentageFixed}%` }}
              ></div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.textContainer}>
        <span className={styles.text}>
          <Tooltip content={<TooltipContent />}>
            <>
              <span
                className={`${
                  financialBudgetUsed > 100 ? styles.overbudgetText : ""
                }`}
              >
                {formatToMode(totalAmount, "$", false, 1)}
              </span>
              {!!budget && <> / {formatToMode(budget, "$", false, 1)}</>}
            </>
          </Tooltip>
        </span>
      </div>
    </>
  )
}

export default ProjectBudgetFinancial
