/**
 * @generated SignedSource<<ecc925cf7f72c7cfa98f20612382f274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonActionButtons_user$data = {
  readonly account: {
    readonly id: number;
    readonly invitations: ReadonlyArray<{
      readonly email: string;
      readonly id: number;
    }>;
    readonly users: ReadonlyArray<{
      readonly email: string;
      readonly id: number;
    }>;
  };
  readonly favourite_people: any;
  readonly id: number;
  readonly " $fragmentType": "PersonActionButtons_user";
};
export type PersonActionButtons_user$key = {
  readonly " $data"?: PersonActionButtons_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonActionButtons_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonActionButtons_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "users",
          "kind": "LinkedField",
          "name": "users",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "invitations",
          "kind": "LinkedField",
          "name": "invitations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "b796a9dd8353dfadb9e8844e1fea2bf7";

export default node;
