import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import {
  RoleSelector_user$data,
  RoleSelector_user$key,
} from "~/Planner/__generated__/RoleSelector_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { AllowedPeopleFilter } from "~/helpers/filter-engine"
import { isValidRole } from "~/helpers/role-helpers"
import { sortByString } from "~/helpers/sorting-helpers"

import Select, {
  GroupedOption,
  Option,
  ReactSelectProps,
} from "~/common/Select"
import SelectOptionAdd from "~/common/SelectOptionAdd"
import SelectRoleSingleValue from "~/common/SelectRoleSingleValue"

type Role = RoleSelector_user$data["account"]["roles"][number]

const RoleSelector = (props: Props) => {
  const user = useFragment<RoleSelector_user$key>(
    graphql`
      fragment RoleSelector_user on users {
        account {
          id
          roles {
            id
            name
            active
            default_hour_cost: default_hour_cost_private
          }
        }
      }
    `,
    useHasuraContext(),
  )
  const {
    placeholder = "Select role",
    label = "",
    name = "role-selector",
    height,
    defaultRole,
    filterPredicate,
    filterNonActive = true,
    formatSelectOptions,
    ...rest
  } = props

  const [selectedRole, setSelectedRole] = useState(defaultRole)

  const activeRoles = user.account.roles.filter(
    (r) => isValidRole(r.name) && (filterNonActive ? r.active : true),
  )

  const filteredRoles = filterPredicate
    ? activeRoles.filter((r) => filterPredicate(r))
    : activeRoles

  const options = filteredRoles
    .map((r) => ({ value: r.id, label: r.name, active: r.active }))
    .sort((a, b) => sortByString(a.label, b.label))

  const handleChange = (target) => {
    setSelectedRole(target)
    props.onChange(target)
  }

  const formattedOptions = formatSelectOptions
    ? formatSelectOptions(options, "person_role_id")
    : options

  return (
    <Select
      {...rest}
      classNamePrefix="roleSelector"
      name={name}
      label={label}
      placeholder={placeholder}
      components={{
        Option: SelectOptionAdd,
        SingleValue: SelectRoleSingleValue,
      }}
      options={formattedOptions}
      onChange={handleChange}
      value={
        selectedRole
          ? { ...selectedRole, archived: !selectedRole.active }
          : selectedRole
      }
      height={height}
    />
  )
}

type Props = {
  defaultRole?: RoleOptions
  onChange: (data: RoleOptions) => void
  filterNonActive?: boolean
  filterPredicate?: (r: Role) => boolean
  formatSelectOptions?: (
    options,
    filterType: AllowedPeopleFilter["type"],
  ) => Option[] | GroupedOption[]
} & ReactSelectProps

type RoleOptions = {
  value: number
  label: string
  active: boolean
}

export default RoleSelector
