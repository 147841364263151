import { DPDayRange, DPPropGetter } from "@rehookify/datepicker"
import cc from "classcat"
import React, { ReactNode } from "react"

type Props = DPPropGetter & {
  date: Date
  children: ReactNode
  rangeState: DPDayRange
  isToday?: boolean
  selected: boolean
  isTimeOff: boolean
}

const Day = ({
  date,
  children,
  tabIndex,
  rangeState = "",
  disabled = false,
  isToday = false,
  role,
  onClick,
  selected,
  isTimeOff,
  ...dayProps
}: Props) => {
  const classes = cc({
    "DayPicker-Day": true,
    "DayPicker-Day--selected": selected,
    "DayPicker-Day--selected-range": rangeState === "in-range",
    "DayPicker-Day--hovered-range": rangeState === "will-be-in-range",
    "DayPicker-Day--today": isToday,
    "DayPicker-Day--disabled": disabled,
    "DayPicker-Day--timeOff": isTimeOff,
  })

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      onClick && onClick(e)
    }
  }

  return (
    <div
      role={role}
      className={classes}
      tabIndex={tabIndex}
      aria-label={date.toDateString()}
      aria-disabled={disabled}
      aria-selected={rangeState !== ""}
      onClick={handleClick}
      {...dayProps}
    >
      <div className="bp5-datepicker-day-wrapper">{children}</div>
    </div>
  )
}

export default Day
