import { graphql, useFragment } from "react-relay"

import type { useWorkstreams_account$key } from "./__generated__/useWorkstreams_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useWorkstreams_account on accounts {
    workstreams {
      id
      name
      archived
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useWorkstreams = (options: Options) => {
  const { user } = options

  const { workstreams } = useFragment<useWorkstreams_account$key>(
    fragment,
    user.account,
  )

  const availableOptions = workstreams
    .filter((workstream) => !workstream.archived)
    .map((workstream) => ({
      value: workstream.id,
      label: workstream.name,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useWorkstreams
