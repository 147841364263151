import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./SchedulePanels.module.css"

import { PersonSchedulePanel_user$key } from "./__generated__/PersonSchedulePanel_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import PersonDetails from "~/PeoplePlanner/PersonDetails/PersonDetails"
import PersonSummaryRow from "~/PeoplePlanner/PersonSummaryRow/PersonSummaryRow"
import PlaceholderSummaryRow from "~/PeoplePlanner/PlaceholderRow/PlaceholderSummaryRow"
import ResizablePanel from "~/Planner/ResizablePanel"
import { panelClosed } from "~/Planner/reducer2/panelSlice"
import { useAppSelector } from "~/hooks/redux"

import SplitRow from "../PlannerLayout/SplitRow"

const fragment = graphql`
  fragment PersonSchedulePanel_user on users
  @argumentDefinitions(
    plannerStartDate: { type: "date!" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    favouritePersonIds: favourite_people
    account {
      people(where: $peopleFilter) {
        id
        is_placeholder
        ...PersonSummaryRow_person
          @arguments(plannerStartDate: $plannerStartDate)
        ...PersonDetails_person @arguments(plannerStartDate: $plannerStartDate)
        ...PlaceholderSummaryRow_person
          @arguments(plannerStartDate: $plannerStartDate)
      }
    }
  }
`

const PersonSchedulePanel = () => {
  const data = useFragment<PersonSchedulePanel_user$key>(
    fragment,
    useHasuraContext(),
  )
  const selectedPersonId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedPersonId,
  )

  const { favouritePersonIds, account } = data
  const selectedPerson = account.people.find((p) => p.id === selectedPersonId)

  const dispatch = useDispatch()

  if (!selectedPerson) {
    dispatch(panelClosed())
    return null
  }

  return (
    <ResizablePanel minHeight={150} maxHeight={800} isScrollable>
      <div className={styles.schedulePanels}>
        {selectedPerson.is_placeholder ? (
          <PlaceholderSummaryRow
            personId={selectedPersonId}
            placeholder={selectedPerson}
            personExpanded={true}
            handleToggleRow={null}
            locatedInSchedulePanel
          />
        ) : (
          <PersonSummaryRow
            personId={selectedPersonId}
            person={selectedPerson}
            personExpanded={true}
            favourites={favouritePersonIds}
            groupCount={null}
            handleToggleRow={null}
            locatedInSchedulePanel
          />
        )}
        <PersonDetails person={selectedPerson} />
        <SplitRow />
      </div>
    </ResizablePanel>
  )
}

export { PersonSchedulePanel }
