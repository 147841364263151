import { Icon } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import {
  addBusinessDays,
  differenceInBusinessDays,
  eachWeekendOfInterval,
  isWeekend,
  isWithinInterval,
  startOfISOWeek,
} from "date-fns"
import React, { ReactElement } from "react"
import { match } from "ts-pattern"

import styles from "./PillHelpers.module.css"

import {
  getDaysInRange,
  getItemOffsetPercentNum,
} from "~/helpers/CalendarHelper"
import DurationHelper from "~/helpers/DurationHelper"
import { getMergedHolidays, getMergedTimeOffs } from "~/helpers/holiday-helpers"
import { getCurrentContract } from "~/helpers/person"
import { getDifferenceInWorkingDays } from "~/helpers/workingDays-helpers"
import { isAssignment, isTimeOff } from "~/types/helpers"

import {
  CircleCrossIcon,
  NonBillableIcon,
  PalmTreeIcon,
} from "~/common/react-icons"

import { AssignmentLabel } from "./AssignmentLabel/AssignmentLabel"
import { getEarliestDate, getLatestDate } from "./action_helpers"

type GetLabelTextProps = {
  typedItem: TypedItem
  days: number
  pillWidth: number
  hoursPerWeekMode: boolean
  hoverLabel: boolean
  defaultFullTimeMinutes: number
  contractMinutesPerDay?: number
}

const getLabelText = ({
  typedItem,
  days,
  pillWidth,
  hoverLabel,
  defaultFullTimeMinutes,
  contractMinutesPerDay,
}: GetLabelTextProps) => {
  const { type, item } = typedItem

  if (!type) {
    return <></>
  }

  if (isTimeOff(item)) {
    if (pillWidth <= 60) {
      return <></>
    }

    if (pillWidth <= 60) {
      return <></>
    }

    if (item.minutes_per_day) {
      return <>{DurationHelper.minutesToHours(item.minutes_per_day, true)}h</>
    }

    return <>Off</>
  }

  const assignment = item as Assignment

  return (
    <AssignmentLabel
      non_working_day={assignment.non_working_day}
      minutes_per_day={assignment.minutes_per_day}
      days={days}
      isHovering={hoverLabel}
      pillWidth={pillWidth}
      defaultFullTimeMinutes={defaultFullTimeMinutes}
      contractMinutesPerDay={contractMinutesPerDay}
    />
  )
}

export const getTimeOffIcons = (
  leave_type: string | "rostered-off" | "holiday",
  hasNote: boolean,
) => {
  return match({ leave_type })
    .with({ leave_type: "rostered-off" }, () => <CircleCrossIcon />)
    .with({ leave_type: "holiday" }, () => <Icon icon="calendar" size={10} />)
    .otherwise(() => (
      <PalmTreeIcon
        className={cc([
          {
            [styles.timeoffWithNote]: hasNote,
          },
        ])}
      />
    ))
}

const getAssignmentIcons = (isBillable: boolean) => {
  return match({ isBillable })
    .with({ isBillable: false }, () => <NonBillableIcon />)
    .otherwise(() => null)
}

const getItemIcons = (
  typedItem: TypedItem,
  pillWidth: number,
  isConflict: boolean,
) => {
  const { item } = typedItem

  const assignmentIcons =
    isAssignment(item) && getAssignmentIcons(item.is_billable)

  const itemIsTimeOff = isTimeOff(item)
  const renderConflictIcon = itemIsTimeOff && isConflict
  const timeOffIcons =
    isTimeOff(item) && getTimeOffIcons(item.leave_type, !!item.note)

  // Only space for one icon
  if (pillWidth < 112 && isAssignment(item)) {
    return (
      getAssignmentIcons(item.is_billable) ||
      (item.note && <Icon icon="comment" size={10} />)
    )
  }

  const isHoliday = isTimeOff(item) && item.leave_type === "holiday"

  // Large space. Show multiple icons
  return (
    <>
      {timeOffIcons}
      {assignmentIcons}
      {item.note && !isHoliday && <Icon icon="comment" size={10} />}
      {renderConflictIcon && pillWidth > 60 && (
        <Icon icon="warning-sign" size={12} color="var(--warning)" />
      )}
    </>
  )
}

export const calculateVisibleDays = (
  item: { start_date: string; end_date: string },
  calendarStartDate: Date,
  calendarEndDate: Date,
  calendarWeekendsExpanded: boolean,
) => {
  const visibleStartDate = getLatestDate(
    item.start_date,
    dateHelpers.formatToRunnDate(calendarStartDate),
  )
  const visibleEndDate = getEarliestDate(
    item.end_date,
    dateHelpers.formatToRunnDate(calendarEndDate),
  )

  return getDaysInRange({
    start: visibleStartDate,
    end: visibleEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })
}

type getPillPositionProps = {
  item: { start_date: string; end_date: string }
  calendarStartDate: Date
  calendarEndDate: Date
  calendarWeekendsExpanded: boolean
  dayWidth?: number
  isCollapsedWeekend?: boolean
  mergedTimeOffs?: {
    start_date: string
    end_date: string
    minutes_per_day: number
  }[]
}

export const getPillPosition = (props: getPillPositionProps) => {
  const {
    item,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
    dayWidth = 0,
    isCollapsedWeekend,
    mergedTimeOffs,
  } = props

  const totalPillDays = getDaysInRange({
    start: item.start_date,
    end: item.end_date,
  })

  const visibleDays = calculateVisibleDays(
    item,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
  )

  const totalCalendarDays = getDaysInRange({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const offset = getItemOffsetPercentNum(
    calendarStartDate,
    calendarEndDate,
    item,
    calendarWeekendsExpanded,
    isCollapsedWeekend,
  )

  let totalBusinessDays = getDaysInRange({
    start: item.start_date,
    end: item.end_date,
    includeWeekends: false,
  })

  let pillLabelOffset = 0

  if (mergedTimeOffs?.length) {
    const numCalendarStartDate = Number(
      dateHelpers.formatToRunnDate(calendarStartDate),
    )
    const numCalendarEndDate = Number(
      dateHelpers.formatToRunnDate(calendarEndDate),
    )

    const pillExtendsBeforeCalendar =
      Number(item.start_date) < numCalendarStartDate

    const itemStartDate = dateHelpers.parseRunnDate(item.start_date)
    const itemEndDate = dateHelpers.parseRunnDate(item.end_date)

    let timeOffDaysOverlappingAssignmentStart = 0

    // If pill extends before calendar, check if there is
    //  a time off on the calendar start date instead
    let currentDate = pillExtendsBeforeCalendar
      ? startOfISOWeek(calendarStartDate)
      : itemStartDate

    while (currentDate <= itemEndDate) {
      if (!isWeekend(currentDate)) {
        const isOverlappingAssignmentStart = mergedTimeOffs.some((timeOff) => {
          const timeOffSpansCalendar =
            numCalendarStartDate >= Number(timeOff.start_date) &&
            numCalendarEndDate <= Number(timeOff.end_date)

          // Partial time offs do not cover the assignment pill
          if (Boolean(timeOff.minutes_per_day) || timeOffSpansCalendar) {
            return
          }

          const timeOffStartDate = dateHelpers.parseRunnDate(timeOff.start_date)
          const timeOffEndDate = dateHelpers.parseRunnDate(timeOff.end_date)

          return isWithinInterval(currentDate, {
            start: timeOffStartDate,
            end: timeOffEndDate,
          })
        })

        if (!isOverlappingAssignmentStart) {
          break
        }

        timeOffDaysOverlappingAssignmentStart++
      }

      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }

    const overlappingTimeOffsCount = mergedTimeOffs.reduce((count, timeOff) => {
      if (Boolean(timeOff.minutes_per_day)) {
        // Partial time off do not reduce assignment business days
        return count
      }

      const overlapStartDate = getLatestDate(
        item.start_date,
        timeOff.start_date,
      )
      const overlapEndDate = getEarliestDate(item.end_date, timeOff.end_date)

      if (overlapStartDate <= overlapEndDate) {
        const daysOverlap =
          differenceInBusinessDays(
            dateHelpers.parseRunnDate(overlapEndDate),
            dateHelpers.parseRunnDate(overlapStartDate),
          ) + 1
        return count + daysOverlap
      }
      return count
    }, 0)

    const pillLabelStartingDate = pillExtendsBeforeCalendar
      ? startOfISOWeek(calendarStartDate)
      : dateHelpers.parseRunnDate(item.start_date)

    const dayOfPillLabelLocation = addBusinessDays(
      pillLabelStartingDate,
      timeOffDaysOverlappingAssignmentStart,
    )

    const weekendDaysInBetween = eachWeekendOfInterval({
      start: pillLabelStartingDate,
      end: dayOfPillLabelLocation,
    }).length

    const pillLabelIsAffectedByWeekend =
      timeOffDaysOverlappingAssignmentStart && !!weekendDaysInBetween

    const weekendOffset =
      calendarWeekendsExpanded && pillLabelIsAffectedByWeekend
        ? weekendDaysInBetween
        : 0

    const numberOfDaysOffset =
      timeOffDaysOverlappingAssignmentStart + weekendOffset

    pillLabelOffset = visibleDays > 1 ? numberOfDaysOffset * dayWidth : 0

    totalBusinessDays = Math.max(
      0,
      totalBusinessDays - overlappingTimeOffsCount,
    )
  }

  const width = (visibleDays / totalCalendarDays) * 100
  const pillWidthPx = dayWidth * visibleDays
  const pillWidthLessLabelOffset = pillWidthPx - pillLabelOffset

  return {
    days: totalPillDays,
    businessDays: totalBusinessDays,
    visibleDays,
    offset,
    width,
    pillWidthPx,
    pillLabelOffset,
    pillWidthLessLabelOffset,
  }
}

export const getPillDetails = ({
  typedItem,
  calendarStartDate,
  calendarEndDate,
  dayWidth = 0,
  calendarWeekendsExpanded,
  hoursPerWeekMode,
  isConflict = false,
  mergedTimeOffs,
  defaultFullTimeMinutes,
  contractMinutesPerDay,
}: PillDetails) => {
  const { item } = typedItem

  const {
    days,
    businessDays,
    visibleDays,
    offset,
    width,
    pillWidthPx,
    pillLabelOffset,
    pillWidthLessLabelOffset,
  } = getPillPosition({
    item,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
    dayWidth,
    mergedTimeOffs,
  })

  const pillExtendsBeforeCalendar =
    Number(item.start_date) <
    Number(dateHelpers.formatToRunnDate(calendarStartDate))
  const pillExtendsPastCalendar =
    Number(item.end_date) >
    Number(dateHelpers.formatToRunnDate(calendarEndDate))

  const labelText = getLabelText({
    typedItem,
    days: businessDays,
    pillWidth: pillWidthLessLabelOffset,
    hoursPerWeekMode,
    hoverLabel: false,
    defaultFullTimeMinutes,
    contractMinutesPerDay,
  })
  const labelTextHover = getLabelText({
    typedItem,
    days: businessDays,
    pillWidth: pillWidthLessLabelOffset,
    hoursPerWeekMode,
    hoverLabel: true,
    defaultFullTimeMinutes,
    contractMinutesPerDay,
  })
  const labelIcons = getItemIcons(
    typedItem,
    pillWidthLessLabelOffset,
    isConflict,
  )

  const formatLabel = (label: ReactElement, hovering?: boolean) => {
    const assignmentItem = isAssignment(item)
    const cannotFitIcon = assignmentItem && pillWidthLessLabelOffset < 41
    const cannotFitIconHover =
      assignmentItem && pillWidthLessLabelOffset < 60 && hovering // to allow space for menu dots
    const hideIcons = cannotFitIconHover || cannotFitIcon

    const iconCmp = (
      <div className={styles.iconContainer}>{!hideIcons && labelIcons}</div>
    )
    const itemIsTimeOff = isTimeOff(item)

    const preIcon = itemIsTimeOff ? iconCmp : null
    const postIcon = !itemIsTimeOff ? iconCmp : null
    return (
      <div className={styles.labelContainer}>
        {preIcon}
        <div className={styles.labelText}>{label}</div>
        {postIcon}
      </div>
    )
  }

  const Label = formatLabel(labelText)
  const LabelHover = formatLabel(labelTextHover, true)
  const showLabel = pillWidthLessLabelOffset > 27 && !!labelText

  return {
    days,
    businessDays,
    visibleDays,
    offset,
    width,
    pillWidthPx,
    pillExtendsBeforeCalendar,
    pillExtendsPastCalendar,
    Label,
    LabelHover,
    showLabel,
    pillLabelOffset,
  }
}

type getItemTotalMinutesProps = {
  typedItem: TypedItem
  isConsistentTimeOffEnabled: boolean
  person: {
    is_placeholder: boolean
    contracts: ReadonlyArray<{
      start_date: string
      end_date: string
      minutes_per_day: number
    }>
    time_offs: ReadonlyArray<{
      id: number
      start_date: string
      end_date: string
      minutes_per_day: number
      leave_type: string
    }>
  }
}

export const getItemTotalMinutes = (
  props: getItemTotalMinutesProps,
): { combinedMinutes: number; combinedMinutesLessTimeOff: number } => {
  const { typedItem, person, isConsistentTimeOffEnabled } = props
  const { type, item } = typedItem
  const combinedDays = getDaysInRange({
    start: item.start_date,
    end: item.end_date,
    includeWeekends: false,
  })

  const timeOffs =
    type === "timeOff"
      ? person.time_offs.filter((to) => to.id !== item.id)
      : person.time_offs

  const combinedDaysLessTimeOff =
    getDifferenceInWorkingDays({
      dateLeft: dateHelpers.parseRunnDate(item.start_date),
      dateRight: dateHelpers.parseRunnDate(item.end_date),
      nonWorkingDays:
        person.is_placeholder || (type === "assignment" && item.non_working_day)
          ? []
          : isConsistentTimeOffEnabled
            ? getMergedTimeOffs(timeOffs, item.start_date, item.end_date).map(
                (t) => dateHelpers.parseRunnDate(t.start_date),
              )
            : getMergedHolidays(timeOffs).map((t) =>
                dateHelpers.parseRunnDate(t.start_date),
              ),
    }) + 1

  if (type === "assignment") {
    return {
      combinedMinutes: combinedDays * item.minutes_per_day,
      combinedMinutesLessTimeOff:
        combinedDaysLessTimeOff * item.minutes_per_day,
    }
  } else {
    // time off - use contract
    return {
      combinedMinutes:
        combinedDays * getCurrentContract(person?.contracts)?.minutes_per_day,
      combinedMinutesLessTimeOff:
        combinedDaysLessTimeOff *
        getCurrentContract(person?.contracts)?.minutes_per_day,
    }
  }
}

type PillDetails = {
  typedItem: TypedItem
  calendarStartDate: Date
  calendarEndDate: Date
  dayWidth?: number
  calendarWeekendsExpanded: boolean
  hoursPerWeekMode: boolean
  isConflict?: boolean
  mergedTimeOffs?: {
    start_date: string
    end_date: string
    minutes_per_day: number
  }[]
  defaultFullTimeMinutes: number
  contractMinutesPerDay?: number
}

type TypedItem =
  | {
      type: "assignment"
      item: Assignment
    }
  | {
      type: "timeOff"
      item: TimeOff
    }

export type Assignment = {
  id: number
  minutes_per_day: number
  start_date: string
  end_date: string
  is_billable: boolean
  note?: string
  non_working_day: boolean
  total_minutes: number
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  note: string
  leave_type: string
  minutes_per_day?: number
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
  }>
}
