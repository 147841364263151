/**
 * @generated SignedSource<<4fd1a486933c82f7539811c6ac2a766f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSelector_people$data = ReadonlyArray<{
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly id: number;
  readonly image_key: string | null | undefined;
  readonly last_name: string;
  readonly " $fragmentType": "PersonSelector_people";
}>;
export type PersonSelector_people$key = ReadonlyArray<{
  readonly " $data"?: PersonSelector_people$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSelector_people">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PersonSelector_people",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_key",
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};

(node as any).hash = "dec6c6581dab21479cdd2cc9ab67d892";

export default node;
