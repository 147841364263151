import * as fe from "@runn/filter-engine"

export const CLEAR_PERSON_FILTER = "CLEAR_PERSON_FILTER"
export const EXPAND_ALL_PROJECTS = "EXPAND_ALL_PROJECTS"
export const SET_SELECTED_PLACEHOLDER = "SET_SELECTED_PLACEHOLDER"

export const SET_PROJECT_GROUP_BY_EXPANDED = "SET_PROJECT_GROUP_BY_EXPANDED"
export const SET_EXPANDED_PEOPLE_GROUPS = "SET_EXPANDED_PEOPLE_GROUPS"
export const SET_PLACEHOLDER_BAR_COLLAPSED = "SET_PLACEHOLDER_BAR_COLLAPSED"
export const SET_PROJECT_TEMPLATE_BAR_COLLAPSED =
  "SET_PROJECT_TEMPLATE_BAR_COLLAPSED"
export const SET_TIME_OFF_BAR_COLLAPSED = "SET_TIME_OFF_BAR_COLLAPSED"
export const TOGGLE_EXPAND_ALL = "TOGGLE_EXPAND_ALL"
export const SET_SHOW_PROJECT_PHASES = "SET_SHOW_PROJECT_PHASES"

export const SET_PEOPLE_WILD_SEARCH = "SET_PEOPLE_WILD_SEARCH"

export const SET_PEOPLE_FILTER_SET = "SET_PEOPLE_FILTER_SET"
export const SET_CHART_FILTER_SET = "SET_CHART_FILTER_SET"
export const SET_SPLIT_SCREEN_FILTER_SET = "SET_SPLIT_SCREEN_FILTER_SET"

export const SET_PLANNER_PROJECTS_EXPANDED = "SET_PLANNER_PROJECTS_EXPANDED"
export const SET_PLANNER_PEOPLE_EXPANDED = "SET_PLANNER_PEOPLE_EXPANDED"

export const setPlannerPeopleExpanded = (payload: {
  id: number
  groupName: string
  isExpanded: boolean
}) => ({
  payload,
  type: SET_PLANNER_PEOPLE_EXPANDED,
})

export const setPlannerProjectsExpanded = (payload: {
  id: number
  groupName: string
  isExpanded: boolean
}) => ({
  payload,
  type: SET_PLANNER_PROJECTS_EXPANDED,
})

export const setExpandAllProjects = (payload: boolean) => ({
  type: EXPAND_ALL_PROJECTS,
  payload,
})

export const setTimeOffBarCollapsed = (value: boolean) => ({
  type: SET_TIME_OFF_BAR_COLLAPSED,
  payload: value,
})

export const setPeopleWildSearch = (query: string | undefined) => ({
  type: SET_PEOPLE_WILD_SEARCH,
  payload: query,
})

export const setPeopleFilterSet = (
  filterSet: fe.engines.local.AllowedFilterSet,
) => ({
  type: SET_PEOPLE_FILTER_SET,
  payload: filterSet,
})

export const setSplitScreenFilterSet = (
  filterSet: fe.engines.local.AllowedFilterSet,
) => ({
  type: SET_SPLIT_SCREEN_FILTER_SET,
  payload: filterSet,
})
