import truncate from "lodash-es/truncate"
import React, { useState } from "react"

import styles from "./SidePanel.module.css"

import {
  CustomCheckboxType,
  CustomCheckboxValue,
  CustomDateType,
  CustomDateValue,
  CustomSelectType,
  CustomSelectValue,
  CustomTextType,
  CustomTextValue,
  CustomType,
  CustomTypeName,
  CustomValuesMap,
  getValueForType,
} from "~/helpers/custom-field-helpers"

import BlueLink from "~/common/buttons/BlueLink"

import { ComponentMap } from "../../Planner/CustomTooltip/CustomTooltip"

type Props = {
  customFieldTypes: CustomType[]
  customFieldValues: CustomValuesMap
}

const CustomText = ({
  type,
  value,
}: {
  type: CustomTextType
  value: CustomTextValue
}) => {
  const textTooLong = (value?.value.length ?? 0) > 300
  const [showMoreShown, setShowMoreShown] = useState(textTooLong)
  if (!value?.value.trim()) {
    return null
  }

  const textValue =
    textTooLong && showMoreShown
      ? `${truncate(value?.value, { length: 300, separator: " " })}`
      : value?.value

  return (
    <>
      <h4 className={styles.detailsTitle}>{type.name}</h4>
      <p className={styles.detailsValue}>
        {textValue}
        {textTooLong ? (
          <BlueLink
            onClick={() => setShowMoreShown(!showMoreShown)}
            style={{ minHeight: 0, marginLeft: "2px" }}
          >
            Show {showMoreShown ? "all" : "less"}
          </BlueLink>
        ) : null}
      </p>
    </>
  )
}

const CustomSelect = ({
  type,
  value,
}: {
  type: CustomSelectType
  value: CustomSelectValue[]
}) => {
  const values = value.map(
    (v) => type.options.find((o) => o.id === v.optionId)?.name,
  )

  if (!values.length) {
    return null
  }

  return (
    <>
      <h4 className={styles.detailsTitle}>{type.name}</h4>
      <p className={styles.detailsValue}>{values.join(", ")}</p>
    </>
  )
}

const CustomCheckbox = ({
  type,
  value,
}: {
  type: CustomCheckboxType
  value: CustomCheckboxValue
}) => {
  return (
    <>
      <h4 className={styles.detailsTitle}>{type.name}</h4>
      <p className={styles.detailsValue}>{value.value ? "Yes" : "No"}</p>
    </>
  )
}

const CustomDate = ({
  type,
  value,
}: {
  type: CustomDateType
  value: CustomDateValue
}) => {
  return (
    <>
      <h4 className={styles.detailsTitle}>{type.name}</h4>
      <p className={styles.detailsValue}>{value.value}</p>
    </>
  )
}

const componentMap: ComponentMap = {
  [CustomTypeName.TEXT]: CustomText,
  [CustomTypeName.SELECT]: CustomSelect,
  [CustomTypeName.CHECKBOX]: CustomCheckbox,
  [CustomTypeName.DATE]: CustomDate,
}

const getComponentForType = <A extends CustomTypeName>(type: A) =>
  componentMap[type]

const SidePanelCustomFields = ({
  customFieldTypes,
  customFieldValues,
}: Props) => {
  return customFieldTypes.map((type) => {
    const typeName = type.typeName as CustomTypeName
    const typeId = type.id
    const Component = getComponentForType(typeName)
    const value = getValueForType(typeName, typeId, customFieldValues)

    if (value == null) {
      return null
    }

    return (
      <Component
        key={`custom-${typeName}-${typeId}`}
        type={type}
        value={value}
      />
    )
  })
}

export default SidePanelCustomFields
