import React, { ReactNode } from "react"

import styles from "./ToggleProjectMembersView.module.css"

import TitledHelpTooltip from "~/common/TitledHelpTooltip"

type Props = {
  totals: {
    all: number
    visible: number
    inactive: number
  }
  showAllText: (count: number) => ReactNode
  hideInactiveText: (count: number) => ReactNode
  showAllTooltip: string | JSX.Element
  hideInactiveTooltip: string | JSX.Element
  onToggle: (showAll: boolean) => void
  dataTestName: string
}

const ToggleProjectMembersView = (props: Props) => {
  const {
    totals,
    onToggle,
    showAllText,
    hideInactiveText,
    showAllTooltip,
    hideInactiveTooltip,
    dataTestName,
  } = props

  const showingAllMembers = totals.visible === totals.all

  // Don't show if there are no members at all
  if (!totals.all) {
    return null
  }

  // Don't show if there are no inactive members (meaning we're showing everything)
  if (!totals.inactive) {
    return null
  }

  const handleClick = () => onToggle(!showingAllMembers)

  const toggleText = showingAllMembers
    ? hideInactiveText(totals.inactive)
    : showAllText(totals.all)

  const tooltipContent = showingAllMembers
    ? hideInactiveTooltip
    : showAllTooltip

  return (
    <div className={styles.container}>
      <div
        className={styles.toggleText}
        data-test={`toggle-project-members-view-${dataTestName}`}
        onClick={handleClick}
      >
        <TitledHelpTooltip title={toggleText} tooltipContent={tooltipContent} />
      </div>
    </div>
  )
}

export default ToggleProjectMembersView
