import { MenuDivider, Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useEffect, useMemo, useReducer, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./SelectPersonList.module.css"

import {
  SelectPersonList_account$data,
  SelectPersonList_account$key,
} from "~/common/SelectPersonList/__generated__/SelectPersonList_account.graphql"
import { SelectPersonList_user$key } from "~/common/SelectPersonList/__generated__/SelectPersonList_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { typesMap } from "~/helpers/custom-field-helpers"
import { prepareDataForFilterPersonList } from "~/helpers/filter-engine"
import { formatName, getCurrentContract } from "~/helpers/person"
import { pluralize } from "~/helpers/plural"
import { isValidRole } from "~/helpers/role-helpers"
import { sortPeopleByPersonName } from "~/helpers/sorting-helpers"

import PersonWorkstreamSelector from "~/common/SelectPersonList/PersonWorkstreamSelector"
import { SelectorFooter, SelectorListContainer } from "~/common/SelectorModal"
import {
  SelectOption,
  filterOptionsWithValue,
} from "~/common/SelectorModal/SelectorHelpers"
import SuperSearch, { usePersonSearchConfig } from "~/common/SuperSearch"
import AddButton from "~/common/buttons/AddButton"
import Checkbox from "~/common/inputs/Checkbox"

import { usePermissions } from "~/Permissions/usePermissions"
import { buildPeopleFilter } from "~/Planner/buildHasuraFilter"
import {
  setTemporaryPlannerFilter,
  setTemporaryPlannerFilterToAll,
} from "~/Planner/reducer2/viewsSlice"
import { useAppSelector } from "~/hooks/redux"
import { useFilterSets } from "~/queries/FilterSet"

import { DropdownHeading } from "../Dropdown"
import { AvailableFilterSubList } from "../SuperSearch/AvailableFilterList"
import { Assignment, Workstream } from "../react-icons"
import viewsReducer from "../views.reducer"

import SelectPersonItem from "./SelectPersonItem"
import SelectPlaceholderItem from "./SelectPlaceholderItem"

type Props = {
  account: SelectPersonList_account$key
  closeModal: () => void
  addPeopleToProject: (
    people: PersonWithRole[],
    selectedViewId?: number,
  ) => void
  addPlaceholderToProject: (
    placeholderRole: { id: number },
    quantity: number,
  ) => void
  project: {
    name: string
    id: number
    is_template: boolean
    members: ReadonlyArray<{
      id: number
      person_id: number
      role_id: number
    }>
  }
  workstreamOptions?: SelectOption[]
  selectedWorkstream?: SelectOption
  handleWorkstreamChange?: (workstream: SelectOption) => void
  defaultFilters?: fe.engines.local.AllowedFilterListItem
  defaultRolePlaceholder?: number
}

type Person = SelectPersonList_account$data["people"][0]
type PersonWithRole = Person & { role: { id: number; name: string } }
type PlaceholderRow = { count: number; role: SelectOption; isSelected: boolean }

const peopleWithCurrentContractAlphabetical = (
  people: SelectPersonList_account$data["people"],
) => {
  const peopleWithCurrentContract = people.reduce<PersonWithRole[]>(
    (acc, person) => {
      const currentContract = getCurrentContract(person.contracts)
      if (currentContract) {
        acc.push({ ...person, role: currentContract.role })
      }
      return acc
    },
    [],
  )
  return sortPeopleByPersonName(peopleWithCurrentContract)
}

const SelectPersonList = (props: Props) => {
  const {
    closeModal,
    addPeopleToProject,
    addPlaceholderToProject,
    handleWorkstreamChange,
    project,
    selectedWorkstream,
    workstreamOptions,
    defaultFilters,
    defaultRolePlaceholder,
  } = props

  const user = useFragment<SelectPersonList_user$key>(
    graphql`
      fragment SelectPersonList_user on users {
        id
        view_id
        favourite_people
      }
    `,
    useHasuraContext(),
  )

  const account = useFragment(
    graphql`
      fragment SelectPersonList_account on accounts
      @argumentDefinitions(
        plannerStartDate: { type: "date!" }
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        id
        views {
          id
          people_filters
        }
        people(where: $peopleFilter) {
          id
          first_name
          last_name
          email
          active
          image_key
          is_placeholder
          tags
          team_id
          contracts {
            id
            role_id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            employment_type
            job_title
            role {
              id
              name
            }
          }
          team {
            id
            name
          }
          time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            person_id
            start_date: start_date_runn
            end_date: end_date_runn
            ...ExtLinks_TimeOff @relay(mask: false)
          }
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            person_id
            project_id
            role_id
            phase_id
            workstream_id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            is_billable
          }
          competencies {
            id
            level
            skill {
              id
              name
            }
          }
          custom_select_values {
            id
            custom_select_option_id
            custom_select_type_id
            optionId: custom_select_option_id
            typeId: custom_select_type_id
          }
          custom_text_values {
            id
            value
            custom_text_type_id
            typeId: custom_text_type_id
          }
          custom_checkbox_values {
            id
            value
            custom_checkbox_type_id
            typeId: custom_checkbox_type_id
          }
          custom_date_values {
            id
            value
            custom_date_type_id
            typeId: custom_date_type_id
          }
          project_memberships {
            id
            just_added_timestamp
            project_id
            is_placeholder
            workstream_id
            role_id
          }
          managers {
            id
            user_id
          }
        }
        roles {
          id
          name
          active
          default_hour_cost: default_hour_cost_private
        }
        projects(where: $projectsFilter) {
          id
          name
          confirmed
          is_template
          active
          tags_computed
          client {
            id
            name
          }
          team {
            id
            name
          }
          members {
            id
            person_id
            role_id
            project_id
            workstream_id
            is_placeholder
          }
          custom_select_values {
            id
            custom_select_option_id
            custom_select_type_id
          }
          custom_text_values {
            id
            value
            custom_text_type_id
          }
          custom_checkbox_values {
            id
            value
            custom_checkbox_type_id
          }
          custom_date_values {
            id
            value
            custom_date_type_id
          }
          managers {
            id
            user_id
          }
        }
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
      }
    `,
    props.account,
  )

  const { view_id: defaultViewId, favourite_people } = user
  const { people, roles, projects, views } = account

  const isFeatureViewEnabled = useFeature("pre_filtered_views")
  const isWorkstreamEnabled = useFeature("workstreams")
  const [viewState, setViewState] = useReducer(viewsReducer, {
    showAllView: !defaultViewId,
    viewId: defaultViewId ?? null,
  })

  const { viewId, showAllView } = viewState

  const { Can, subject } = usePermissions()
  const dispatch = useDispatch()

  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [wildSearchQuery, setWildSearchQuery] = useState("")
  const [localFilterSet, setLocalFilterSet] =
    useState<fe.engines.local.AllowedFilterSet>({
      name: "",
      filters: defaultFilters ?? null,
    })
  const [selectedPeople, setSelectedPeople] = useState([])

  const [peopleList, setPeopleList] = useState(
    peopleWithCurrentContractAlphabetical(people),
  )

  const viewPeopleFilters =
    isFeatureViewEnabled && viewId && !showAllView
      ? views.find((view) => view.id === viewId)?.people_filters
      : undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterSet = {
    name: "",
    filters: fe.filters.simplify(
      fe.filters.and([
        viewPeopleFilters,
        localFilterSet?.filters,
        fe.filters.personIsActive({ value: true }),
        fe.filters.personIsPlaceholder({ value: false }),
        wildSearchQuery
          ? fe.filters.personWildSearch({ query: wildSearchQuery })
          : undefined,
      ]),
    ),
  }
  const filterTypeList = fe.filters.asTypeList(filterSet.filters)
  const calStartNum = useAppSelector((state) => state.calendar.calStartNum)
  const preparedList = useMemo(
    () => {
      return prepareDataForFilterPersonList({
        filterTypeList,
        projects,
        people: peopleList,
        favouritePersonIds: favourite_people,
        calStartNum,
        includeArchivedProjects: true,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterTypeList.join(","),
      projects,
      peopleList,
      calStartNum,
    ],
  )

  const filteredPeople = useMemo(() => {
    const filteredPersonList = fe.engines.local.filterPersonList(
      preparedList,
      filterSet,
    )

    const filteredPersonIds = Object.fromEntries(
      filteredPersonList.map((person) => [person.id, true]),
    )

    return peopleList
      .filter((person) => filteredPersonIds[person.id])
      .filter((p) => !p.is_placeholder)
  }, [preparedList, filterSet, peopleList])

  const roleOptions = useMemo(
    () =>
      roles
        .filter((r) => isValidRole(r.name))
        .map((r) => ({
          value: r.id,
          label: r.name,
          active: r.active,
        })),
    [roles],
  )

  const filteredPlaceholderRoles = useMemo(
    () => filterOptionsWithValue(roleOptions, wildSearchQuery, false, false),
    [roleOptions, wildSearchQuery],
  )

  const [placeholderRoleOptions, setPlaceholderRoleOptions] = useState(
    filteredPlaceholderRoles,
  )

  const [placeholderRows, setPlaceholderRows] = useState<PlaceholderRow[]>([
    {
      count: 1,
      role:
        placeholderRoleOptions.find(
          (r) => r.value === defaultRolePlaceholder,
        ) ?? undefined,
      isSelected: false,
    },
  ])

  useEffect(() => {
    const existingPlaceholderRoleValues = placeholderRows.map(
      (p) => p.role?.value,
    )
    const unusedRoles = filteredPlaceholderRoles.filter(
      (role) => !existingPlaceholderRoleValues.includes(role.value),
    )
    setPlaceholderRoleOptions(unusedRoles)
  }, [placeholderRows, filteredPlaceholderRoles])

  const [placeholderCount, setPlaceholderCount] = useState(0)

  useEffect(() => {
    const totalPlaceholderCount = placeholderRows.reduce((acc, placeholder) => {
      if (placeholder.isSelected) {
        return acc + placeholder.count
      }
      return acc
    }, 0)
    setPlaceholderCount(totalPlaceholderCount)
  }, [placeholderRows])

  const handleAddPlaceholder = (placeholder) => {
    const placeholderRole = roles.find((r) => r.id === placeholder.role.value)

    addPlaceholderToProject(placeholderRole, placeholder.count)
  }

  const handleSubmit = () => {
    const placeholdersToAdd = placeholderRows.filter((p) => p.isSelected)

    if (placeholdersToAdd.length) {
      placeholdersToAdd.map(handleAddPlaceholder)
    }

    addPeopleToProject(selectedPeople, viewId)

    if (closeModal) {
      closeModal()
    }
  }

  const noResultsFound = !filteredPeople.length
  const hasSelectedPlaceholder = placeholderRows.some((p) => p.isSelected)
  const hasSelectedPeople = selectedPeople.length > 0

  const onKeyPress = (e) => {
    // 13 === enter key
    if (
      e.keyCode === 13 &&
      (hasSelectedPeople || hasSelectedPlaceholder) &&
      !noResultsFound
    ) {
      handleSubmit()
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress)
    return () => {
      document.removeEventListener("keypress", onKeyPress)
    }
  })

  const togglePlaceholderSelection = (index: number) => {
    const updatedPlaceholderRows = [...placeholderRows]
    const placeholder = updatedPlaceholderRows[index]

    placeholder.isSelected = !placeholder.isSelected

    if (!placeholder.isSelected && placeholderRows.length > 1) {
      updatedPlaceholderRows.splice(index, 1)
    }

    setPlaceholderRows(updatedPlaceholderRows)
  }

  const handlePlaceholderCountChange = (index: number, count: number) => {
    const updatedPlaceholderRows = [...placeholderRows]
    updatedPlaceholderRows[index].count = count
    setPlaceholderRows(updatedPlaceholderRows)
  }

  const handlePlaceholderRoleChange = (
    index: number,
    selectedRole: SelectOption,
  ) => {
    const updatedPlaceholderRows = [...placeholderRows]

    updatedPlaceholderRows[index].role = selectedRole

    setPlaceholderRows(updatedPlaceholderRows)
  }

  const handleRoleChange = (
    role: { value: number; label: string },
    person: PersonWithRole,
  ) => {
    const newRole = roles.find((r) => r.id === role.value)
    setPeopleList([
      ...peopleList.map((p) =>
        p.id === person.id ? { ...person, role: newRole } : p,
      ),
    ])

    setSelectedPeople(
      selectedPeople.map((p) =>
        p.id === person.id ? { ...p, role: newRole } : p,
      ),
    )
  }

  const handleQuickAdd = (personToAdd: PersonWithRole) => {
    const role = roles.find((r) => r.id === personToAdd.role.id)
    addPeopleToProject(
      [
        {
          ...personToAdd,
          role: { ...role },
        },
      ],
      viewId,
    )

    if (closeModal) {
      closeModal()
    }
  }

  const handleAddPlaceholderRow = () => {
    setPlaceholderRows([
      ...placeholderRows,
      { count: 1, role: undefined, isSelected: false },
    ])
  }

  const onPersonSelectionChange = (personSelected: Person) => {
    // If the person is already is the list, remove them.
    // If they aren't, add them
    selectedPeople.some(
      (selectedPerson) => selectedPerson.id === personSelected.id,
    )
      ? setSelectedPeople(
          selectedPeople.filter(({ id }) => id !== personSelected.id),
        )
      : setSelectedPeople([...selectedPeople, personSelected])
  }

  useEffect(() => {
    setPeopleList(peopleWithCurrentContractAlphabetical(people))
  }, [JSON.stringify(people.map((p) => p.id).sort())]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeViewId = (nextViewId: number) => {
    setViewState({ type: "VIEW_SET", payload: { viewId: nextViewId } })
    setSelectAllChecked(false)
    setSelectedPeople([])

    if (nextViewId !== user.view_id) {
      const peopleFilters = buildPeopleFilter(
        fe.filters.flat(views.find((v) => v.id === nextViewId)?.people_filters),
      )
      dispatch(
        setTemporaryPlannerFilter({
          peopleFilters,
        }),
      )
    }
  }

  const handleChangeShowAllView = () => {
    setViewState({ type: "VIEW_SHOW_ALL" })
    dispatch(setTemporaryPlannerFilterToAll())
  }

  const personSearchConfig = usePersonSearchConfig({
    excludedFilters: [
      "person_type",
      "person_id",
      "person_project_status",
      "person_state",
    ],
  })

  const superSearchConfig = {
    ...personSearchConfig,
    autoFocusWildSearch: true,
  }

  const savedFilterSets = useFilterSets({ type: "person" })

  const handleChangeFilterSet = (
    selectedFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    setLocalFilterSet(selectedFilterSet)
  }

  const appendToLocalFilterSet = (filter: fe.engines.local.AllowedFilter) => {
    const newFilterSet = {
      name: "",
      filters: fe.filters.simplify(
        fe.filters.and([localFilterSet?.filters, filter]),
      ),
    }

    setLocalFilterSet(newFilterSet)
  }

  const onSelectAllChange = () => {
    // handle deselect all
    if (selectAllChecked) {
      placeholderRows.map((p) => (p.isSelected = false))

      setSelectAllChecked(false)
      setSelectedPeople([
        // only uncheck the people in view
        ...selectedPeople.filter(
          (selectedPerson) =>
            !filteredPeople.some(({ id }) => id === selectedPerson.id),
        ),
      ])
      return
    }

    // handle select all - add all people in view (filtered results) and placeholders with role only
    placeholderRows.map((p) => {
      if (p.role) {
        p.isSelected = true
      }
    })

    const filteredPeopleWithUsableRole = filteredPeople.filter((p) => {
      const selectedRole = roles.find((r) => r.id === p.role.id)
      const isRoleArchived = !selectedRole.active
      const isRolePartOfProject = project.members?.some(
        (m) => m.role_id === selectedRole.id,
      )

      return !(isRoleArchived && !isRolePartOfProject)
    })

    setSelectAllChecked(true)
    setSelectedPeople([
      ...selectedPeople,
      ...filteredPeopleWithUsableRole.filter(
        (filteredPerson) =>
          !selectedPeople.some(({ id }) => id === filteredPerson.id),
      ),
    ])
  }

  useEffect(() => {
    setSelectAllChecked(
      Boolean(filteredPeople.length === selectedPeople.length),
    )
  }, [filteredPeople.length === selectedPeople.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
    custom_date_types: account.custom_date_types_person,
  })

  const rolesInProject = project.members?.map((m) => m.role_id)

  const renderPersonItem = (person) => {
    const isSelected = selectedPeople.some(({ id }) => id === person.id)
    const defaultRoleId = person.role.id
    const isMember = project.members?.some((m) => m?.person_id === person.id)
    const isRolePartOfProject = project.members?.some(
      (m) => m.role_id === defaultRoleId,
    )

    const defaultRole = roleOptions.find((r) => r.value === defaultRoleId)

    return (
      <Can
        I="create"
        this={subject("ProjectMember", {
          project: { id: project.id, isTemplate: project.is_template },
          person,
        })}
        key={person.id}
      >
        <SelectPersonItem
          person={person}
          defaultRoleValue={
            // The default role is null when it is an "Unknown" role created by an integration
            defaultRole
              ? {
                  ...defaultRole,
                  archived: !defaultRole.active,
                  disabled: !defaultRole.active && !isRolePartOfProject,
                }
              : {
                  value: defaultRoleId,
                  label: "Unknown",
                  active: false,
                  archived: false,
                  disabled: true,
                }
          }
          rolesInProject={rolesInProject}
          selected={isSelected}
          options={roleOptions}
          selectedPeople={selectedPeople}
          handleAdd={handleQuickAdd}
          setSelectedPeople={setSelectedPeople}
          onPersonSelectionChange={onPersonSelectionChange}
          handlePersonRoleSelect={handleRoleChange}
          isPlaceholder={person.is_placeholder}
          isMember={isMember}
          customFieldTypes={customFieldTypes}
        />
      </Can>
    )
  }

  const renderPlaceholderItem = (
    placeholder: PlaceholderRow,
    index: number,
  ) => {
    const { count, role, isSelected } = placeholder
    const lastPlaceholderRow = index === placeholderRows.length - 1
    const key = `${placeholder.role?.label}-placeholder-${index}`

    return (
      <SelectPlaceholderItem
        key={key}
        setPlaceholderCreateCount={handlePlaceholderCountChange}
        rolesInProject={rolesInProject}
        placeholderCreateCount={count}
        filteredRoleIds={placeholderRoleOptions.map((r) => r.value)}
        selectedPlaceholderRole={role}
        setSelectedPlaceholderRole={handlePlaceholderRoleChange}
        onPlaceholderSelectionChange={togglePlaceholderSelection}
        onAddPlaceholderRow={handleAddPlaceholderRow}
        selected={isSelected}
        index={index}
        isLastRow={lastPlaceholderRow}
      />
    )
  }

  const peopleTooltipText = selectedPeople
    .map((selectedPerson) =>
      formatName(selectedPerson.first_name, selectedPerson.last_name),
    )
    .join(", ")

  const placeholderTooltipText = placeholderRows
    .filter((placeholder) => placeholder.isSelected)
    .map(
      (placeholder) =>
        `${placeholder.count} ${placeholder.role.label} ${pluralize(placeholder.count, "Placeholder")}`,
    )
    .join(", ")

  const tooltipText = match({
    hasSelectedPeople,
    hasSelectedPlaceholder,
  })
    .with(
      { hasSelectedPeople: true, hasSelectedPlaceholder: true },
      () => `${peopleTooltipText}, ${placeholderTooltipText}`,
    )
    .with(
      { hasSelectedPeople: true, hasSelectedPlaceholder: false },
      () => peopleTooltipText,
    )
    .with(
      { hasSelectedPeople: false, hasSelectedPlaceholder: true },
      () => placeholderTooltipText,
    )
    .otherwise(() => undefined)

  const peopleFooterText = `${selectedPeople.length} ${pluralize(selectedPeople.length, "person", "people")}`
  const placeholderFooterText = `${placeholderCount} ${pluralize(placeholderCount, "placeholder")}`

  const footerText = match({
    hasSelectedPeople,
    hasSelectedPlaceholder,
  })
    .with(
      { hasSelectedPeople: true, hasSelectedPlaceholder: true },
      () => `${peopleFooterText} and ${placeholderFooterText} selected`,
    )
    .with(
      { hasSelectedPeople: true, hasSelectedPlaceholder: false },
      () => `${peopleFooterText} selected`,
    )
    .with(
      { hasSelectedPeople: false, hasSelectedPlaceholder: true },
      () => `${placeholderFooterText} selected`,
    )
    .otherwise(() => undefined)

  const showWorkstreamFilter =
    selectedWorkstream &&
    selectedWorkstream.value != null &&
    !selectedWorkstream.isArchived

  return (
    <>
      <div className={styles.filterContainer} data-test="select-person-list">
        <SuperSearch
          config={superSearchConfig}
          location="planner"
          wildSearchQuery={wildSearchQuery}
          onChangeWildSearchQuery={setWildSearchQuery}
          filterSet={localFilterSet}
          onChangeFilterSet={handleChangeFilterSet}
          savedFilterSets={savedFilterSets}
          viewState={viewState}
          handleChangeViewId={handleChangeViewId}
          handleChangeShowAllView={handleChangeShowAllView}
          filterDropdownChildren={
            <>
              <DropdownHeading style={{ paddingTop: "var(--spacing-xs)" }}>
                Project
              </DropdownHeading>
              <AvailableFilterSubList
                config={{
                  name: project.name,
                  filter: fe.filters.personMembership({
                    list: [project.id],
                  }),
                  icon: <Assignment />,
                }}
                onClick={appendToLocalFilterSet}
              />
              <MenuDivider />
              {showWorkstreamFilter && (
                <>
                  <DropdownHeading style={{ paddingTop: "var(--spacing-xs)" }}>
                    Workstream
                  </DropdownHeading>
                  <AvailableFilterSubList
                    config={{
                      name: selectedWorkstream.label,
                      filter: fe.filters.personProjectWorkstreamId({
                        list: [selectedWorkstream.value],
                      }),
                      icon: <Workstream />,
                    }}
                    onClick={appendToLocalFilterSet}
                  />
                  <MenuDivider />
                </>
              )}
            </>
          }
        />
        <Checkbox
          id="SelectPersonItem_Checkbox_selectAll"
          tickClassName={styles.checkboxTick}
          onChange={onSelectAllChange}
          checked={selectAllChecked && !noResultsFound}
        />
      </div>

      {isWorkstreamEnabled && Boolean(workstreamOptions?.length) && (
        <div className={styles.workstreamContainer}>
          <PersonWorkstreamSelector
            onChange={handleWorkstreamChange}
            workstreamOptions={workstreamOptions}
            selectedWorkstream={selectedWorkstream}
            className={styles.select}
          />
        </div>
      )}

      <SelectorListContainer
        type="people"
        noActiveItems={!people.some((p) => p.active)}
        noResultsFound={noResultsFound}
        filterValue={wildSearchQuery}
      >
        <>
          <Can
            I="create"
            this={subject("Placeholder", {
              project: { id: project.id, isTemplate: project.is_template },
            })}
          >
            {placeholderRows.map((placeholder, index) =>
              renderPlaceholderItem(placeholder, index),
            )}
          </Can>
          {filteredPeople.map(renderPersonItem)}
        </>
      </SelectorListContainer>
      <SelectorFooter>
        {(hasSelectedPeople || hasSelectedPlaceholder) && (
          <Tooltip
            content={
              <div className={cc([styles.tooltip, "fs-exclude"])}>
                {tooltipText}
              </div>
            }
          >
            <span>{footerText}</span>
          </Tooltip>
        )}
        <AddButton
          text={
            <Tooltip
              placement="top"
              content={
                Boolean(selectedPeople.length) || hasSelectedPlaceholder
                  ? undefined
                  : "Please select people to add"
              }
            >
              Add Selected
            </Tooltip>
          }
          data-test="add-selected-people-button"
          outlined={false}
          disabled={!selectedPeople.length && !hasSelectedPlaceholder}
          onClick={handleSubmit}
          style={{ marginLeft: 10 }}
        />
      </SelectorFooter>
    </>
  )
}

export default SelectPersonList
