import * as fe from "@runn/filter-engine"
import React from "react"

import type {
  FilterComponentProps,
  FilterValues,
} from "~/common/SuperSearch/types"
import { Archive as Icon } from "~/common/react-icons"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

import {
  FilterBlock,
  useAutoRemoveMissingListValues,
  useSelectedListOptions,
} from "./common"

const availableOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Archived",
    value: "archived",
  },
]

const useValues = (props: BaseProps): FilterValues => {
  const { filter } = props

  const selectedOptions = useSelectedListOptions({
    filter,
    availableOptions,
  })

  return {
    name: "Person State",
    availableOptions,
    selectedOptions,
  }
}

type BaseProps = {
  filter: fe.filters.FilterPersonState
}

type Props = FilterComponentProps & BaseProps

const Component = (props: Props) => {
  const {
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (list: fe.filters.FilterPersonStateOptions["list"]) => {
    return onChange(path, fe.filters.personState({ list }))
  }

  const handleRemove = () => onRemove(path)

  const { selectedOptions, name } = useValues({
    filter,
  })

  useAutoRemoveMissingListValues({
    filter,
    selectedOptions,
    onChange: handleChange,
  })

  return (
    <FilterBlock
      icon={<Icon />}
      label={name}
      filterLabelPlural="states"
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
      disabled={disabled}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { filter } = props

  const { selectedOptions, name } = useValues({
    filter,
  })

  return <FilterBlockReadonly name={name} selectedOptions={selectedOptions} />
}

export const PersonState = {
  Component,
  ReadonlyComponent,
}
