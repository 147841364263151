import React, { Suspense, useState } from "react"

import Dialog from "~/common/Dialog"

import { ExternalProjectBulkImport } from "~/forms/ExternalProjectBulkImport/ExternalProjectBulkImport"
import ExternalProjectMemberForm from "~/forms/ExternalProjectMemberForm/ExternalProjectMemberForm"

import { ProjectReturn } from "../CreateProjectForm/CreateProjectForm"

import CreateProjectWizardModal from "./CreateProjectWizardModal"

type Props = {
  onClose?: () => void
  onSuccess?: (project: ProjectReturn) => void
  defaultSelectedTabId?: "single" | "bulk" | "import"
}

/*
 * Track the state of the Wizard modal.
 * Each item in the union should represent a different step in the wizard.
 */
type WizardStep =
  | { type: "CreateProjectWizardModal" }
  | {
      type: "ExternalProjectMemberForm"
      extProjectId: number
      projectId: number
    }
  | { type: "ExternalProjectBulkImport"; integrationId: number }

const CreateProjectWizard = (props: Props) => {
  const { onSuccess, onClose, defaultSelectedTabId } = props

  const [step, setStep] = useState<WizardStep>({
    type: "CreateProjectWizardModal",
  })

  // track selected tab in it's own state,
  // so if we navigate away from the wizard and come back,
  // we can remember the last selected tab
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTabId)

  switch (step.type) {
    case "CreateProjectWizardModal": {
      return (
        <CreateProjectWizardModal
          selectedTab={selectedTab}
          onChangeTab={setSelectedTab}
          onClose={onClose}
          onSuccess={onSuccess}
          onLinkExtProject={({ extProjectId, projectId }) =>
            setStep({
              type: "ExternalProjectMemberForm",
              extProjectId,
              projectId,
            })
          }
          onOpenIntegration={({ integrationId }) =>
            setStep({
              type: "ExternalProjectBulkImport",
              integrationId,
            })
          }
        />
      )
    }
    case "ExternalProjectMemberForm": {
      return (
        <Dialog isOpen>
          <Suspense fallback="Loading…">
            <ExternalProjectMemberForm
              projectId={step.projectId}
              extProjectId={step.extProjectId}
              closeDialog={onClose}
              formType="import"
            />
          </Suspense>
        </Dialog>
      )
    }
    case "ExternalProjectBulkImport": {
      return (
        <Dialog isOpen>
          <Suspense fallback="Loading…">
            <ExternalProjectBulkImport
              integrationId={step.integrationId}
              onClose={onClose}
              onNavigateBack={() =>
                setStep({ type: "CreateProjectWizardModal" })
              }
            />
          </Suspense>
        </Dialog>
      )
    }
    default: {
      console.error("Invalid state:", step)
      return null
    }
  }
}

export default CreateProjectWizard
