import * as fe from "@runn/filter-engine"

import type { Option } from "~/types/helpers"

type Options = {
  filter: fe.filters.Filter<string, { list: (string | number)[] }>
  availableOptions: Option<string | number>[]
}

const useSelectedListOptions = (options: Options) => {
  const { filter, availableOptions } = options

  const selectedOptions = filter.options.list
    .map((selectedValue) => {
      return availableOptions.find((option) => option.value === selectedValue)
    })
    .filter(Boolean)

  return selectedOptions
}

export default useSelectedListOptions
