import React, { ReactNode } from "react"

import styles from "./FilterMenu.module.css"

type Props = {
  heading?: ReactNode
  children: ReactNode
  footer?: ReactNode
}

const FilterMenu = (props: Props) => {
  const { heading, children, footer } = props
  return (
    <div className={styles.container}>
      {heading}
      <div className={styles.list}>{children}</div>
      {footer}
    </div>
  )
}

export default FilterMenu
