/**
 * @generated SignedSource<<fbcf4c5710fbeb0213e603e72a17ca3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBudgetHours_project$data = {
  readonly id: number;
  readonly project_roles: ReadonlyArray<{
    readonly estimated_minutes: number | null | undefined;
    readonly id: number;
    readonly role_id: number;
  }>;
  readonly summary: ReadonlyArray<{
    readonly role_id: number | null | undefined;
    readonly total_billable_minutes: number | null | undefined;
    readonly total_nonbillable_minutes: number | null | undefined;
  }>;
  readonly " $fragmentType": "ProjectBudgetHours_project";
};
export type ProjectBudgetHours_project$key = {
  readonly " $data"?: ProjectBudgetHours_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetHours_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBudgetHours_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "v_summary_total",
      "kind": "LinkedField",
      "name": "summary",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total_billable_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total_nonbillable_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_roles",
      "kind": "LinkedField",
      "name": "project_roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimated_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "26881baf11b561b29bdf74f26ed06c07";

export default node;
