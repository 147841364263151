import {
  DatePickerStateProvider,
  useContextCalendars,
  useContextMonthsPropGetters,
} from "@rehookify/datepicker"
import { addYears, subYears } from "date-fns"
import React, { useState } from "react"

import styles from "./DatePicker.module.css"

import Calendar from "~/common/DatePicker/Calendar"
import CalendarNavButton from "~/common/DatePicker/CalendarNavButton"

export type DatePickerProps = {
  modifiers?: {
    timeOff?: Array<{ from: Date; to: Date }>
    notAllowed?: Date[]
  }
  defaultStartDate?: Date
  minDate?: Date
  maxDate?: Date
  onChange: (dates: Date[]) => void
  defaultStyles?: boolean
  selectSameDate?: boolean
  value: [Date, Date]
  mode?: "single" | "range"
}

type Props = {
  defaultStyles?: boolean
  defaultStartDate?: Date
  timeOff?: Array<{ from: Date; to: Date }>
}

export const getDefaultDateRange = (): [Date, Date] => {
  const defaultMinDate = subYears(new Date(), 5)
  const defaultMaxDate = addYears(new Date(), 5)
  return [defaultMinDate, defaultMaxDate] as const
}

const DatePickerUI = ({ defaultStyles, defaultStartDate, timeOff }: Props) => {
  const { calendars } = useContextCalendars()
  const { previousMonthButton, nextMonthButton } = useContextMonthsPropGetters()
  return (
    <div className={!defaultStyles ? styles.datePicker : null}>
      <div className="bp5-datepicker bp5-daterangepicker bp5-daterangepicker-contiguous">
        <div className="bp5-datepicker-content">
          <div className="bp5-daterangepicker-calendars">
            {calendars.map((calendar, i) => (
              <Calendar
                key={i}
                calendar={calendar}
                defaultStartDate={defaultStartDate}
                calendarIndex={i}
                timeOff={timeOff}
                prevButton={
                  i === 0 && (
                    <CalendarNavButton type="prev" {...previousMonthButton()} />
                  )
                }
                nextButton={
                  i === calendars.length - 1 && (
                    <CalendarNavButton type="next" {...nextMonthButton()} />
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
const DatePicker = (props: DatePickerProps) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(
    Array.isArray(props.value) && props.value.every((value) => Boolean(value))
      ? props.value
      : [],
  )
  const [defaultMinDate, defaultMaxDate] = getDefaultDateRange()

  const {
    minDate = defaultMinDate,
    maxDate = defaultMaxDate,
    mode,
    defaultStyles,
    defaultStartDate,
  } = props

  return (
    <DatePickerStateProvider
      config={{
        selectedDates,
        onDatesChange: (d) => {
          setSelectedDates(d)
          props.onChange(d)
        },
        dates: {
          mode,
          minDate,
          maxDate,
          selectSameDate: props.selectSameDate,
        },
        exclude: {
          day: defaultStyles ? [] : [0, 6],
        },
        calendar: {
          offsets: [1],
          startDay: 1,
        },
        locale: {
          day: "numeric",
        },
      }}
    >
      <DatePickerUI
        defaultStyles={defaultStyles}
        defaultStartDate={defaultStartDate}
        timeOff={props?.modifiers?.timeOff}
      />
    </DatePickerStateProvider>
  )
}

export default DatePicker
