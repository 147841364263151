import React from "react"

import styles from "./Badge.module.css"

type Props = {
  children: React.ReactNode
}

const Badge = (props: Props) => {
  const { children } = props

  return <span className={styles.badge}>{children}</span>
}

export { Badge }
