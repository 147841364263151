import { ContextMenu, ContextMenuChildrenProps, Menu } from "@blueprintjs/core"
import React, { useState } from "react"
import isDeeplyEqual from "react-fast-compare"

import styles from "~/common/PillActions/PillActions.module.css"

import MenuItem from "~/common/MenuItem"
import ItemTooltip from "~/common/PillActions/ItemTooltip"

import { useAppSelector } from "~/hooks/redux"

import BasicPill from "./BasicPill/BasicPill"
import { getPillDetails } from "./PillHelpers"
import { Person, TimeOff } from "./TimeOffActionPill"

type Props = {
  timeOff: TimeOff
  person: Person
  onDelete: () => void
  defaultFullTimeMinutes: number
}

const RDOPill = (props: Props) => {
  const { timeOff, person, onDelete, defaultFullTimeMinutes } = props

  const calendar = useAppSelector((state) => state.calendar, isDeeplyEqual)
  const {
    dayWidth,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
  } = calendar

  const multiSelect = useAppSelector(
    (state) => state.multiSelect,
    isDeeplyEqual,
  )
  const multiSelectEnabled = multiSelect.isEnabled

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const hoursPerWeekMode = effortDisplayUnit === "hoursPerWeek"

  const [isHovering, setIsHovering] = useState(false)

  const { days, offset, width, pillWidthPx } = getPillDetails({
    typedItem: { type: "timeOff", item: timeOff },
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
    hoursPerWeekMode,
    defaultFullTimeMinutes,
  })

  const handleMouseOver = () => {
    if (!multiSelectEnabled) {
      setIsHovering(true)
    }
  }

  return (
    <ContextMenu
      content={
        <Menu>
          <MenuItem text="Delete" onClick={onDelete} />
        </Menu>
      }
    >
      {(ctxMenuProps: ContextMenuChildrenProps) => {
        const onMenuClick = (e) => {
          e.stopPropagation()
          ctxMenuProps.onContextMenu(e)
        }
        return (
          <div
            className={styles.PillContainer}
            style={{
              left: `${offset}%`,
              width: `${width}%`,
              cursor: multiSelectEnabled ? "not-allowed" : "auto",
            }}
            onMouseOver={handleMouseOver}
            onMouseLeave={() => setIsHovering(false)}
            onContextMenu={onMenuClick}
            ref={ctxMenuProps.ref}
          >
            {ctxMenuProps.popover}
            <ItemTooltip
              typedItem={{ type: "timeOff", item: timeOff }}
              days={days}
              forceDelayTime={500}
              pillWidthPx={pillWidthPx}
              hideTooltip={multiSelectEnabled}
              person={person}
            >
              <BasicPill
                typedItem={{ type: "timeOff", item: timeOff }}
                person={person}
                wrappedPill
                calendarStartDate={calendarStartDate}
                calendarEndDate={calendarEndDate}
                dayWidth={dayWidth}
                isSelected={isHovering}
                onMenuClick={onMenuClick}
                calendarWeekendsExpanded={calendarWeekendsExpanded}
                defaultFullTimeMinutes={defaultFullTimeMinutes}
              />
            </ItemTooltip>
          </div>
        )
      }}
    </ContextMenu>
  )
}

export default RDOPill
