import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { ChartPeriod, ChartType } from "./types"

export type ChartsReducerState = {
  type: ChartType
  period: ChartPeriod
}

const initialState: ChartsReducerState = {
  type: "capacity",
  period: "daily",
}

const chartSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    setChartType: (state, action: PayloadAction<ChartType>) => {
      state.type = action.payload
    },
    setChartPeriod: (state, action: PayloadAction<ChartPeriod>) => {
      state.period = action.payload
    },
  },
})

const { actions, reducer } = chartSlice
export const { setChartType, setChartPeriod } = actions
export default reducer
