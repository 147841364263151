/**
 * @generated SignedSource<<e6a72bb2e0c40fc6bb9be479fe9e7c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsDetectedPersonRow_project$data = {
  readonly id: number;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_project">;
  readonly " $fragmentType": "ProjectDetailsDetectedPersonRow_project";
};
export type ProjectDetailsDetectedPersonRow_project$key = {
  readonly " $data"?: ProjectDetailsDetectedPersonRow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsDetectedPersonRow_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "534008c629c7fb3033a1c83f5b8f8f9d";

export default node;
