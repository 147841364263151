/**
 * @generated SignedSource<<9c9d9521227873d847a4d0342acf33c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewFilterValues_user$data = {
  readonly account: {
    readonly id: number;
    readonly " $fragmentSpreads": FragmentRefs<"useClients_account" | "useCustomSelect_account" | "useManagers_account" | "usePeople_account" | "useProjectTemplates_account" | "useProjects_account" | "useRoles_account" | "useSkills_account" | "useTags_account" | "useTeams_account">;
  };
  readonly id: number;
  readonly " $fragmentType": "ViewFilterValues_user";
};
export type ViewFilterValues_user$key = {
  readonly " $data"?: ViewFilterValues_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewFilterValues_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "projectsFilter",
    "variableName": "projectsFilter"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewFilterValues_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useClients_account"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "peopleFilter",
              "variableName": "peopleFilter"
            }
          ],
          "kind": "FragmentSpread",
          "name": "usePeople_account"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "useProjects_account"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "useProjectTemplates_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useRoles_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useSkills_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTags_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTeams_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCustomSelect_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useManagers_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "026e224a61c14e88f398fcbf46ee9e8c";

export default node;
