import { dateHelpers } from "@runn/calculations"
import React, { useEffect, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useDebouncedCallback } from "use-debounce"

import {
  PlannerContainerQuery,
  PlannerContainerQuery$data,
} from "./__generated__/PlannerContainerQuery.graphql"

import { HasuraContextProvider, useHasuraContext } from "~/store/hasura"

import ErrorBoundary from "~/helpers/ErrorBoundary"

import { SidePanelProvider } from "~/common/SidePanel/SidePanel"

import { plannerChangeDateRelay } from "~/mutations/Planner"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import PermissionsProvider from "~/Permissions/PermissionsProvider"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"
import { useAppSelector } from "~/hooks/redux"
import { useEffectDeepCompare } from "~/hooks/useEffectDeepCompare"

import ModeNotifications from "./ModeNotifications"
import usePlannerFilters from "./usePlannerFilters"

const PlannerRelayContext = React.createContext({})

type Props = {
  children: React.ReactNode
}

const QUERY = graphql`
  query PlannerContainerQuery(
    $todaysDate: date!
    $plannerStartDate: date!
    $projectsFilter: projects_bool_exp
    $peopleFilter: people_bool_exp
  ) {
    ...ServerFlagsProvider_gql
    billing(syncSubscription: false) {
      ...EntitlementsProvider_billing
    }
    current_user {
      ...PageManager_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)
      ...ProjectPlannerList_user
        @arguments(
          todaysDate: $todaysDate
          peopleFilter: $peopleFilter
          projectsFilter: $projectsFilter
        )

      ...PeoplePlannerList_user
        @arguments(
          plannerStartDate: $plannerStartDate
          projectsFilter: $projectsFilter
          peopleFilter: $peopleFilter
        )

      ...PersonSummaryRow_user @arguments(projectsFilter: $projectsFilter)
      ...PlaceholderSummaryRow_user @arguments(projectsFilter: $projectsFilter)
      ...PersonDetails_user @arguments(projectsFilter: $projectsFilter)
      ...PersonAddProjectRow_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)

      ...Overbookings_user @arguments(projectsFilter: $projectsFilter)

      ...SuperSearch_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)
      ...SuperSearchConfig_user

      ...ChartPanel_user
        @arguments(
          plannerStartDate: $plannerStartDate
          projectsFilter: $projectsFilter
          peopleFilter: $peopleFilter
        )
      ...SplitScreenPanel_user
        @arguments(
          peopleFilter: $peopleFilter
          projectsFilter: $projectsFilter

          plannerStartDate: $plannerStartDate
        )
      ...PersonSchedulePanel_user
        @arguments(
          peopleFilter: $peopleFilter

          plannerStartDate: $plannerStartDate
        )
      ...ProjectSchedulePanel_user
        @arguments(
          projectsFilter: $projectsFilter
          todaysDate: $todaysDate
          peopleFilter: $peopleFilter
          plannerStartDate: $plannerStartDate
        )

      ...SplitScreenRole_user

      ...RoleSelector_user
      ...PersonTagSelector_user
      ...SkillsSelector_viewer
      ...HolidaysGroupSelector_viewer
      ...AccountWelcome_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)
      ...OnboardingDialog_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)
      ...SelectViewDialog_user

      ...RoleForm_viewer
      ...CreatePersonForm_viewer
      ...PersonForm_viewer
      ...ProjectForm_viewer
      ...ProjectBudget_viewer
      ...PlaceholderCommentModal_viewer
      ...PersonNote_viewer

      ...CreateBulkProjectsForm_viewer
      ...ExternalProjectMemberForm_viewer
        @arguments(peopleFilter: $peopleFilter)
      ...SingleExternalProjectMemberForm_viewer
        @arguments(peopleFilter: $peopleFilter)

      ...ClientForm_viewer

      ...Timeline_user
      ...SelectPersonList_user
      ...FilterSetQuery_user

      ...PersonCustomEditor_viewer
      ...ProjectCustomEditor_viewer

      ...ModeMessage_viewer @arguments(peopleFilter: $peopleFilter)

      ...User_Account_Integrations

      ...ViewFieldsTooltip_user
        @arguments(projectsFilter: $projectsFilter, peopleFilter: $peopleFilter)

      account {
        id
        name
        default_full_time_minutes
      }
      ...PlaceholderForm_user
      ...PermissionsProvider_user
    }
  }
`

const PlannerContainer = ({ children }: Props) => {
  const calStartNum = useAppSelector((state) => state.calendar.calStartNum)
  const [oldestDate, setOldestDate] = useState(calStartNum)
  const plannerStartDate = PLANNER_INITIAL_DATE
  const todaysDate = dateHelpers.getTodaysDate()

  const { projectsFilter, peopleFilter } = usePlannerFilters()

  const data = useLazyLoadQuery<PlannerContainerQuery>(QUERY, {
    todaysDate,
    plannerStartDate,
    projectsFilter,
    peopleFilter,
  })
  const { current_user } = data

  const debouncedChangeDate = useDebouncedCallback(
    (startDate: number, endDate: number) => {
      void plannerChangeDateRelay({
        fromDate: String(startDate),
        toDate: String(endDate),
      })

      setOldestDate(startDate)
    },
    500,
  )

  useEffect(() => {
    if (calStartNum < oldestDate) {
      debouncedChangeDate(calStartNum, oldestDate)
    }
  }, [calStartNum]) // eslint-disable-line react-hooks/exhaustive-deps

  // Make sure we are loading assignments from the correct date when changing views
  useEffectDeepCompare(() => {
    if (calStartNum < Number(PLANNER_INITIAL_DATE)) {
      debouncedChangeDate(calStartNum, Number(PLANNER_INITIAL_DATE))
    }
  }, [projectsFilter, peopleFilter])

  return (
    <ErrorBoundary>
      <ServerFlagsProvider gql={data}>
        <PermissionsProvider user={current_user}>
          <EntitlementsProvider billing={data.billing}>
            <HasuraContextProvider value={current_user}>
              <SidePanelProvider>
                {children}
                <ModeNotifications />
              </SidePanelProvider>
            </HasuraContextProvider>
          </EntitlementsProvider>
        </PermissionsProvider>
      </ServerFlagsProvider>
    </ErrorBoundary>
  )
}

/**
 *
 * @deprecated instead use `useFragment` to define data requirements directly in
 * components use a fragment ref as a prop of the component
 *
 * Not colocating fragments removes the main benefits of relay
 * - Components define their own data requirements
 * - Data masking
 * - Preventing overfetching, only retrieve what is required
 *
 * Defined as an anti-pattern
 * @see https://github.com/relayjs/eslint-plugin-relay/blob/85b7a9b79f8d779099beff6522df6ad79372ecee/src/rule-must-colocate-fragment-spreads.js
 * Suggested reading
 * @see https://relay.dev/docs/principles-and-architecture/thinking-in-relay/
 *
 */
const withPlannerQuery = (Component) => (props) => {
  const data = useHasuraContext() as PlannerContainerQuery$data
  return <Component {...props} gql={data} />
}
export default PlannerContainer
export { PlannerRelayContext, withPlannerQuery }
