/**
 * @generated SignedSource<<41aea54b199cfa8d9ee33b51f7f6abec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuperSearch_user$data = {
  readonly account: {
    readonly id: number;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly confirmed: boolean;
      readonly id: number;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"TentativeProjectsToggle_account" | "useClients_account" | "useCustomSelect_account" | "useManagers_account" | "usePeople_account" | "useProjectTemplates_account" | "useProjects_account" | "useRoles_account" | "useSkills_account" | "useTags_account" | "useTeams_account" | "useWorkstreams_account">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ViewsDropdown_user">;
  readonly " $fragmentType": "SuperSearch_user";
};
export type SuperSearch_user$key = {
  readonly " $data"?: SuperSearch_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuperSearch_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "peopleFilter",
  "variableName": "peopleFilter"
},
v2 = {
  "kind": "Variable",
  "name": "projectsFilter",
  "variableName": "projectsFilter"
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuperSearch_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useClients_account"
        },
        {
          "args": [
            (v1/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "usePeople_account"
        },
        {
          "args": (v3/*: any*/),
          "kind": "FragmentSpread",
          "name": "useProjects_account"
        },
        {
          "args": (v3/*: any*/),
          "kind": "FragmentSpread",
          "name": "useProjectTemplates_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useRoles_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useSkills_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTags_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTeams_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCustomSelect_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useWorkstreams_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useManagers_account"
        },
        {
          "args": (v3/*: any*/),
          "kind": "FragmentSpread",
          "name": "TentativeProjectsToggle_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ViewsDropdown_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "ab56fee1db06a83256c0e045ce4461c5";

export default node;
