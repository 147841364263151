import difference from "lodash-es/difference"
import React, { useCallback, useMemo, useState } from "react"
import { graphql, useLazyLoadQuery, useMutation } from "react-relay"
import { components } from "react-select"
import type { OptionProps } from "react-select"

import styles from "./ExtProjectLinks.module.css"

import { ExtProjectLinksQuery } from "./__generated__/ExtProjectLinksQuery.graphql"
import { ExtProjectLinks_updateMutation } from "./__generated__/ExtProjectLinks_updateMutation.graphql"

import Select from "~/common/Select"
import { Delete } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"
import { useExtProjectLinks } from "~/queries/ExtLinks"
import type { ProjectRef } from "~/queries/ExtLinks"

type UseExtProjectLinkEditorOptions = {
  projectRef: ProjectRef | undefined
}

type ExtProjectLinkEditorState = {
  // value maps an integration to a list of extProject IDs
  // { [integrationId]: [ ...extProjectIds ] }
  value: ReadonlyMap<number, ReadonlyArray<number>>
  setValue: (
    integrationId: number,
    extProjectId: number | undefined,
    previousExtProjectId: number | undefined,
  ) => void

  save: (options: { projectId: number }) => void
  isSaving: boolean
}

const useExtProjectLinkEditor = (
  options: UseExtProjectLinkEditorOptions,
): ExtProjectLinkEditorState => {
  const { projectRef } = options
  const extProjectLinks = useExtProjectLinks(projectRef)

  const [commit, isSaving] = useMutation<ExtProjectLinks_updateMutation>(
    graphql`
      mutation ExtProjectLinks_updateMutation(
        $input: ExtProjectLinkUpdateInput!
      ) {
        action_ext_project_link_update(input: $input) {
          project {
            id
            ...ExtLinks_Project
          }
        }
      }
    `,
  )

  const initialValue = useMemo(() => {
    const map = new Map<number, number[]>()
    for (const link of extProjectLinks) {
      const integrationId = link.external.integration_id
      const extProjectId = link.ext_project_id
      if (map.has(integrationId)) {
        const array = map.get(integrationId)
        array.push(extProjectId)
      } else {
        map.set(integrationId, [extProjectId])
      }
    }
    return map
  }, [extProjectLinks])

  const [value, replaceValue] =
    useState<ReadonlyMap<number, ReadonlyArray<number>>>(initialValue)
  const setValue = useCallback(
    (
      integrationId: number,
      extProjectId: number | undefined,
      previousExtProjectId: number | undefined,
    ) => {
      replaceValue((currentValue) => {
        const nextList = currentValue.has(integrationId)
          ? [...currentValue.get(integrationId)]
          : []

        const prevItemIndex =
          typeof previousExtProjectId === "number"
            ? nextList.findIndex((item) => item === previousExtProjectId)
            : -1

        if (extProjectId) {
          if (prevItemIndex < 0) {
            nextList.push(extProjectId)
          } else {
            nextList.splice(prevItemIndex, 1, extProjectId)
          }
        } else if (prevItemIndex >= 0) {
          nextList.splice(prevItemIndex, 1)
        }

        const nextMap = new Map(currentValue)
        nextMap.set(integrationId, nextList)
        return nextMap
      })
    },
    [replaceValue],
  )

  const save = useCallback(
    ({ projectId }) => {
      const initialExtProjectIds = [
        ...new Set([...initialValue.values()].flat()),
      ]
      const extProjectIds = [...new Set([...value.values()].flat())]

      const toUnlink = difference(initialExtProjectIds, extProjectIds)
      const toLink = difference(extProjectIds, initialExtProjectIds)

      if (toUnlink.length > 0 || toLink.length > 0) {
        commit({
          variables: {
            input: {
              project_id: projectId,
              unlink_ext_project_ids: toUnlink,
              link_ext_project_ids: toLink,
            },
          },
        })
      }

      return toLink
    },
    [commit, initialValue, value],
  )

  return {
    value,
    setValue,

    save,
    isSaving,
  }
}

type AsyncSelectOption = {
  label: string
  value: number
  name: string
  remote_id: string
}

const CustomOption = (props: OptionProps<AsyncSelectOption>) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>{props.data.name}</span>
        <code>{props.data.remote_id}</code>
      </div>
    </components.Option>
  )
}

type AsyncSelectExtProjectProps = {
  integration: {
    id: number
    integration_service: {
      square_image_url: string
      name: string
    }
    ext_projects: ReadonlyArray<{
      id: number
      name: string
      remote_id: string
    }>
  }
  value: undefined | number
  onChange: (
    integrationId: number,
    extProjectId: undefined | number,
    previousExtProjectId: number | undefined,
  ) => void
}

const AsyncSelectExtProject = (props: AsyncSelectExtProjectProps) => {
  const { integration, value, onChange } = props

  const options = integration.ext_projects
    .map((extProject) => {
      return {
        ...extProject,
        value: extProject.id,
        label: `${extProject.name} (${extProject.remote_id})`,
      }
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  const selectedOption =
    typeof value === "number"
      ? options.find((option) => option.value === value)
      : null

  const handleChange = (option: AsyncSelectOption) => {
    onChange(integration.id, option.value, value)
  }

  const handleRemove = () => {
    onChange(integration.id, undefined, value)
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={integration.integration_service.square_image_url}
        width={40}
        height={40}
        style={{ verticalAlign: "middle" }}
        title={integration.integration_service.name}
      />
      <Select
        components={{ Option: CustomOption }}
        name="external-project"
        isMulti={false}
        placeholder="No external project"
        onChange={handleChange}
        value={selectedOption}
        options={options}
      />
      <button
        className={styles.removeButton}
        onClick={handleRemove}
        disabled={typeof value === "undefined"}
      >
        <Delete color="var(--winter)" />
      </button>
    </div>
  )
}

type IntegrationExtProjectLinksProps = {
  integration: {
    id: number
    integration_service: {
      square_image_url: string
      name: string
    }
    ext_projects: ReadonlyArray<{
      id: number
      name: string
      remote_id: string
    }>
  }
  value: ReadonlyArray<number>
  onChange: (
    integrationId: number,
    extProjectId: number | undefined,
    previousExtProjectId: number | undefined,
  ) => void
}

const IntegrationExtProjectLinks = (props: IntegrationExtProjectLinksProps) => {
  const { value, integration, onChange } = props

  const firstItemValue = value[0]
  const otherItems = value.slice(1)

  return (
    <>
      <AsyncSelectExtProject
        integration={integration}
        value={firstItemValue}
        onChange={onChange}
      />
      {otherItems.map((otherItemValue, index) => (
        <AsyncSelectExtProject
          key={index}
          integration={integration}
          value={otherItemValue}
          onChange={onChange}
        />
      ))}
    </>
  )
}

type ExtProject = {
  id: number
  name: string
  remote_id: string
}

type ExtProjectLinksContainerProps = {
  state: ExtProjectLinkEditorState
  onChange?: (extProject: ExtProject | undefined) => void
}

const ExtProjectLinkEditor = (props: ExtProjectLinksContainerProps) => {
  const { state, onChange } = props
  const { value, setValue } = state

  const queryData = useLazyLoadQuery<ExtProjectLinksQuery>(
    graphql`
      query ExtProjectLinksQuery {
        current_user {
          id
          account {
            id
            activeTicketingIntegrations: integrationsV2(
              where: {
                category: { _eq: "TICKETING" }
                state: { _neq: "PENDING" }
              }
            ) {
              id
              integration_service {
                id
                name
                square_image_url
              }
              ext_projects(where: { deleted: { _eq: false } }) {
                id
                name
                remote_id
              }
            }
          }
          permissions
        }
      }
    `,
    {},
  )
  const { can, subject } = usePermissions()
  const canViewIntegrations = can("view", subject("Integration"))
  const integrationList =
    queryData.current_user.account.activeTicketingIntegrations

  const handleChange: typeof setValue = useCallback(
    (integrationId, extProjectId, previousExtProjectId) => {
      if (onChange) {
        const extProjectList =
          queryData.current_user.account.activeTicketingIntegrations.flatMap(
            (integration) => integration.ext_projects,
          )
        const extProject = extProjectList.find(
          (project) => project.id === extProjectId,
        )
        onChange(extProject)
      }
      setValue(integrationId, extProjectId, previousExtProjectId)
    },
    [setValue, queryData, onChange],
  )

  if (integrationList.length === 0 || !canViewIntegrations) {
    // don't show anything if there are no integrations
    return null
  }

  return (
    <div className={styles.container}>
      <label
        style={{
          display: "block",
          fontSize: 12,
          color: "var(--midnight)",
          fontWeight: 600,
        }}
      >
        External Project
      </label>

      {integrationList.map((integration) => {
        return (
          <IntegrationExtProjectLinks
            key={integration.id}
            integration={integration}
            value={value.get(integration.id) ?? []}
            onChange={handleChange}
          />
        )
      })}
    </div>
  )
}

export { ExtProjectLinkEditor, useExtProjectLinkEditor }
