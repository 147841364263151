import {
  DPCalendar,
  useContextCalendars,
  useContextDaysPropGetters,
  useDatePickerStateContext,
} from "@rehookify/datepicker"
import { format } from "date-fns"
import { isWithinInterval } from "date-fns"
import { chunk, range } from "lodash-es"
import React, { ReactNode, useEffect } from "react"

import Day from "~/common/DatePicker/Day"

import { getDefaultDateRange } from "./DatePicker"

type Props = {
  prevButton?: ReactNode
  nextButton?: ReactNode
  calendar: DPCalendar
  calendarIndex: number
  defaultStartDate?: Date
  timeOff?: Array<{ from: Date; to: Date }>
}

const Calendar = ({
  prevButton,
  nextButton,
  calendar,
  calendarIndex,
  defaultStartDate,
  timeOff,
}: Props) => {
  const [defaultMinDate, defaultMaxDate] = getDefaultDateRange()
  const {
    dispatch,
    config: {
      dates: { minDate = defaultMinDate, maxDate = defaultMaxDate },
      selectedDates,
    },
  } = useDatePickerStateContext()
  const { weekDays } = useContextCalendars()
  const { dayButton } = useContextDaysPropGetters()
  const { days, month: selectedMonth, year: selectedYear } = calendar

  const minYear = minDate.getFullYear()
  const maxYear = maxDate.getFullYear()

  const yearSelection = range(minYear, maxYear + 1)

  const monthSelectionStart =
    Number(selectedYear) === minYear ? minDate.getMonth() : 0
  const monthSelectionEnd =
    Number(selectedYear) === maxYear ? maxDate.getMonth() : 12
  const monthSelection = range(monthSelectionStart, monthSelectionEnd)
  const currentMonthIndex = new Date(
    Date.parse(`1 ${selectedMonth} ${selectedYear}`),
  ).getMonth()

  // Show the given defaultStartDate or range start date's month in the calendar
  // By default, the calendar shows the month of the range's end date
  useEffect(() => {
    if (defaultStartDate || selectedDates[0]) {
      dispatch({
        type: "SET_OFFSET_DATE",
        date: defaultStartDate || selectedDates[0],
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="DayPicker" lang="en">
      <div className="DayPicker-wrapper">
        {prevButton}
        {nextButton}
        <div className="DayPicker-Months">
          <div className="DayPicker-Month" role="grid">
            <div className="DayPicker-Caption">
              <div className="bp5-datepicker-caption">
                <div className="bp5-html-select bp5-minimal bp5-datepicker-month-select">
                  <select
                    aria-label="Month"
                    value={currentMonthIndex}
                    onChange={(e) => {
                      const selectedDate = new Date(
                        Number(selectedYear),
                        Number(e.target.value) - calendarIndex,
                      )

                      dispatch({
                        type: "SET_OFFSET_DATE",
                        date: selectedDate,
                      })
                    }}
                  >
                    {monthSelection.map((m) => {
                      const label = format(
                        new Date(Number(selectedYear), m),
                        "MMMM",
                      )
                      return (
                        <option value={m} key={m}>
                          {label}
                        </option>
                      )
                    })}
                  </select>
                  <span
                    className="bp5-icon bp5-icon-double-caret-vertical"
                    style={{ right: 56 }}
                  >
                    <svg
                      data-icon="double-caret-vertical"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      aria-labelledby="iconTitle-17"
                      role="img"
                    >
                      <title id="iconTitle-17">Open dropdown</title>
                    </svg>
                  </span>
                </div>
                <div className="bp5-html-select bp5-minimal bp5-datepicker-year-select">
                  <select
                    aria-label="Year"
                    value={selectedYear}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_OFFSET_DATE",
                        date: new Date(
                          Number(e.target.value),
                          currentMonthIndex,
                        ),
                      })
                    }}
                  >
                    {yearSelection.map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <span className="bp5-icon bp5-icon-double-caret-vertical">
                    <svg
                      data-icon="double-caret-vertical"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      aria-labelledby="iconTitle-3"
                      role="img"
                    >
                      <title id="iconTitle-3">Open dropdown</title>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="bp5-divider"></div>
            </div>
            <div className="DayPicker-Weekdays" role="rowgroup">
              <div className="DayPicker-WeekdaysRow" role="row">
                {weekDays.map((d) => (
                  <div
                    key={d}
                    className="DayPicker-Weekday"
                    role="columnheader"
                  >
                    <abbr title={d}>{d.substring(0, 2)}</abbr>
                  </div>
                ))}
              </div>
            </div>
            <div className="DayPicker-Body" role="rowgroup">
              {chunk(days, 7).map((daysInWeek, i) => (
                <div key={`week-${i}`} className="DayPicker-Week" role="row">
                  {daysInWeek.map((d) =>
                    d.inCurrentMonth ? (
                      <Day
                        key={d.$date.toString()}
                        date={d.$date}
                        rangeState={d.range}
                        disabled={d.disabled}
                        isTimeOff={
                          timeOff?.some((t) =>
                            isWithinInterval(d.$date, {
                              start: t.from,
                              end: t.to,
                            }),
                          ) ?? false
                        }
                        isToday={d.now}
                        selected={d.selected}
                        {...dayButton(d)}
                      >
                        {format(d.$date, "d")}
                      </Day>
                    ) : (
                      <div
                        className="DayPicker-Day DayPicker-Day--outside"
                        key={d.$date.toString()}
                      />
                    ),
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar
