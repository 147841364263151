import React from "react"

import { track } from "~/helpers/analytics"
import { parseFavouritePersonIds } from "~/helpers/favourites"

import { userFavouritePeopleUpdateRelay } from "~/mutations/User"

const useFavouritePerson = ({
  personId,
  favouritePersonIds,
}: {
  userId: number
  personId: number
  favouritePersonIds: number[]
}) => {
  const favourites = parseFavouritePersonIds(favouritePersonIds)

  const isFavourite = React.useMemo(
    () =>
      favourites.length ? favourites.some((id) => id === personId) : false,
    [favourites, personId],
  )

  const favouritePerson = () => {
    const favouritePeople = isFavourite
      ? favourites.filter((id) => id !== personId)
      : [...favourites, personId]

    track("Person Starred")
    void userFavouritePeopleUpdateRelay({ input: { favouritePeople } })
  }

  return {
    isFavourite,
    favouritePerson,
  }
}

export default useFavouritePerson
