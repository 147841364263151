import { Tooltip } from "@blueprintjs/core"
import { toFullTimeEquivalentEffort } from "@runn/calculations"
import cc from "classcat"
import { minutesInHour } from "date-fns/constants"
import { useFeature } from "flagged"
import React, { useLayoutEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { P, match } from "ts-pattern"

import styles from "./CapacityIndicator.module.css"

import { CapacityIndicator_account$key } from "./__generated__/CapacityIndicator_account.graphql"

import DurationHelper from "~/helpers/DurationHelper"
import { getUtilizationPercentage } from "~/helpers/UtilizationHelper"

import CellDivider from "~/common/calendar/CellDivider"
import { Warning } from "~/common/react-icons"

import { SummaryUnit } from "~/Planner/reducer2/peopleSummarySlice"

import { EffortDisplayUnit } from "../display_units/effortDisplayUnitSlice"
import { useAppSelector } from "../hooks/redux"

const formatText = (text, type, textOverflows) =>
  textOverflows ? text : `${text} ${type}`

type Props = {
  assigned: number
  width?: number
  contracted: number
  enabledDaysInRange?: number
  timeOff?: number
  summaryUnit: SummaryUnit
  showWeekly: boolean
  expandRow?: () => void
  hasTimeOffConflict?: boolean
  isANonWorkingDay: boolean
  isWeekend: boolean
  weekHasNonWorkingDay?: boolean
  account: CapacityIndicator_account$key
  rosteredDays: number[]
  enabledWeekDaysInRange?: number
}

type CapacityData = {
  assigned: number
  contracted: number
  textOverflows: boolean
  summaryUnit: SummaryUnit
  enabledDaysInRange?: number
  isANonWorkingDay?: boolean
  timeOff?: number
  fulltimeMinutesPerDay: number
  effortDisplayUnit: EffortDisplayUnit
  showWeekly: boolean
  weekHasNonWorkingDay?: boolean
  rosteredDays: number[]
  enabledWeekDaysInRange?: number
}

const PERCENTAGE_RANGE = {
  FULL: 100,
  NEARLY_FULL: 80,
  COMPLETELY_FREE: 0,
} as const

const isOverbookedPercentage = (percentage: number) => {
  return percentage > PERCENTAGE_RANGE.FULL
}
const isFullPercentage = (percentage: number) => {
  return percentage === PERCENTAGE_RANGE.FULL
}
const isNearlyFullPercentage = (percentage: number) => {
  return (
    percentage >= PERCENTAGE_RANGE.NEARLY_FULL &&
    percentage < PERCENTAGE_RANGE.FULL
  )
}
const isPartiallyFullPercentage = (percentage: number) => {
  return percentage < PERCENTAGE_RANGE.NEARLY_FULL
}
const isCompletelyFreePercentage = (percentage: number) => {
  return percentage === PERCENTAGE_RANGE.COMPLETELY_FREE
}

export const getPercentageCapacityData = ({
  assigned,
  contracted,
  enabledDaysInRange,
  isANonWorkingDay,
  timeOff = 0,
}: Omit<
  CapacityData,
  | "summaryUnit"
  | "textOverflows"
  | "fulltimeMinutesPerDay"
  | "isFteEnabled"
  | "effortDisplayUnit"
  | "showWeekly"
  | "rosteredDays"
>) => {
  const percentage = getUtilizationPercentage({
    assignedHours: assigned,
    contractedHours: contracted,
    enabledDays: enabledDaysInRange,
    timeOff,
    round: true,
    isANonWorkingDay,
  })
  const barClassNames = cc({
    [styles.overbooked]: isOverbookedPercentage(percentage),
    [styles.full]: isFullPercentage(percentage),
    [styles.nearlyFull]: isNearlyFullPercentage(percentage),
    [styles.partiallyFull]: isPartiallyFullPercentage(percentage),
    [styles.completelyFree]: isCompletelyFreePercentage(percentage),
    [styles.nonWorkingDay]: isANonWorkingDay && !assigned,
    [styles.assignedNonWorkingDay]: isANonWorkingDay && assigned,
  })
  const barText = isANonWorkingDay ? "" : `${percentage}%`

  return {
    barText,
    barClassNames,
  }
}

/**
 * Round a number  to the nearest 0.5
 *
 * @example
 * roundToNearestHalfStep(0.49) // 0.5
 * roundToNearestHalfStep(0.1) // 0.0
 * roundToNearestHalfStep(127.9) // 128
 */
const roundToNearestHalfStep = (n: number): number => Math.round(n * 2) / 2

export const getTimeCapacityData = ({
  assigned, // daily for showWeekly=false, weekly aggregate for showWeekly=true
  contracted, // daily for showWeekly=false, weekly aggregate for showWeekly=true
  textOverflows,
  isANonWorkingDay,
  timeOff = 0,
  fulltimeMinutesPerDay,
  summaryUnit,
  showWeekly,
  weekHasNonWorkingDay,
  rosteredDays,
  enabledWeekDaysInRange,
}: Omit<CapacityData, "effortDisplayUnit">) => {
  if (showWeekly && enabledWeekDaysInRange > 5) {
    throw new Error(
      "showWeekly is true but enabledWeekDaysInRange is greater than 5",
    )
  }

  if (isANonWorkingDay && showWeekly) {
    throw new Error(
      "showWeekly isn't supported with non-working days, account for this in enabledWeekDaysInRange and weekHasNonWorkingDay",
    )
  }

  let barClassNames: string

  const contractedMinutes = isANonWorkingDay ? 0 : contracted
  const contractedHours = contractedMinutes / minutesInHour
  const availableMinutes =
    contractedMinutes - assigned - (isANonWorkingDay ? 0 : timeOff)
  const totalAvailableHours = availableMinutes / minutesInHour
  const workingDays = rosteredDays.filter((day) => day).length
  const availableHours =
    summaryUnit === "hoursPerDay" && showWeekly
      ? totalAvailableHours / workingDays
      : totalAvailableHours

  let barText = match({ summaryUnit, showWeekly })
    .with({ summaryUnit: "hoursPerWeek", showWeekly: false }, () =>
      formatText(
        `${DurationHelper.formatHours(Math.min(availableHours * enabledWeekDaysInRange, availableHours * workingDays))}/w`,
        "free",
        textOverflows,
      ),
    )
    .with({ summaryUnit: "hoursPerWeek", showWeekly: true }, () =>
      formatText(
        `${DurationHelper.formatHours(availableHours)}/w`,
        "free",
        textOverflows,
      ),
    )
    .otherwise(() =>
      formatText(
        `${roundToNearestHalfStep(availableHours)}h/d`,
        "free",
        textOverflows,
      ),
    )

  if (availableHours < 0) {
    barClassNames = styles.overbooked
    barText = match({ summaryUnit, showWeekly })
      .with({ summaryUnit: "hoursPerWeek", showWeekly: false }, () =>
        formatText(
          `${DurationHelper.formatHours(Math.min(-availableHours * enabledWeekDaysInRange, -availableHours * workingDays))}/w`,
          "over",
          textOverflows,
        ),
      )
      .with({ summaryUnit: "hoursPerWeek", showWeekly: true }, () =>
        formatText(
          `${DurationHelper.formatHours(-availableHours)}/w`,
          "over",
          textOverflows,
        ),
      )
      .otherwise(() =>
        formatText(
          `${DurationHelper.formatHours(-availableHours)}/d`,
          "over",
          textOverflows,
        ),
      )
  }

  if (availableHours <= -1) {
    barText = match({ summaryUnit, showWeekly })
      .with({ summaryUnit: "hoursPerWeek", showWeekly: false }, () =>
        formatText(
          `${DurationHelper.formatHours(Math.min(-availableHours * enabledWeekDaysInRange, -availableHours * workingDays))}/w`,
          "over",
          textOverflows,
        ),
      )
      .with({ summaryUnit: "hoursPerWeek", showWeekly: true }, () =>
        formatText(
          `${DurationHelper.formatHours(-availableHours)}/w`,
          "over",
          textOverflows,
        ),
      )
      .otherwise(() =>
        formatText(
          `${roundToNearestHalfStep(-availableHours)}h/d`,
          "over",
          textOverflows,
        ),
      )
  }

  if (availableHours <= 2.5 && availableHours >= 1) {
    barClassNames = styles.nearlyFull
  }

  if (availableHours < 1 && availableHours > 0) {
    barClassNames = styles.nearlyFull
    barText = match({ summaryUnit, showWeekly })
      .with({ summaryUnit: "hoursPerWeek", showWeekly: false }, () =>
        formatText(
          `${DurationHelper.formatHours(Math.min(availableHours * enabledWeekDaysInRange, availableHours * workingDays))}/w`,
          "free",
          textOverflows,
        ),
      )
      .with({ summaryUnit: "hoursPerWeek", showWeekly: true }, () =>
        formatText(
          `${DurationHelper.formatHours(availableHours)}/w`,
          "free",
          textOverflows,
        ),
      )
      .otherwise(() =>
        formatText(
          `${DurationHelper.formatHours(availableHours)}/d`,
          "free",
          textOverflows,
        ),
      )
  }

  if (totalAvailableHours === contractedHours) {
    barClassNames = styles.completelyFree
  }

  if (
    (totalAvailableHours < contractedHours && totalAvailableHours > 2.5) ||
    (totalAvailableHours === contractedHours && weekHasNonWorkingDay)
  ) {
    barClassNames = styles.partiallyFull
  }

  if (availableHours === 0) {
    barClassNames = styles.full
    barText = "Full"
  }

  if (summaryUnit === "fullTimeEquivalent") {
    const minutes = showWeekly
      ? fulltimeMinutesPerDay * 5
      : fulltimeMinutesPerDay
    const sign = Math.sign(availableMinutes)
    const effort = toFullTimeEquivalentEffort({
      minutesOfEffort: Math.abs(availableMinutes),
      fulltimeMinutesPerDay: minutes,
    })

    barText = match({ effort, sign })
      .with({ effort: 0 }, () => "Full")
      .with({ sign: -1, effort: P.select() }, (e) => `${e} FTE over`)
      .with({ effort: P.select() }, (e) => `${e} FTE free`)
      .exhaustive()
  }

  if (isANonWorkingDay) {
    if (assigned) {
      barClassNames = styles.overbooked
    } else {
      barClassNames = styles.nonWorkingDay
      barText = ""
    }
  }

  return { barText, barClassNames }
}

export const getCapacityData = ({
  assigned,
  contracted,
  textOverflows,
  summaryUnit,
  enabledDaysInRange,
  isANonWorkingDay,
  timeOff = 0,
  fulltimeMinutesPerDay,
  effortDisplayUnit: effortDisplayUnit,
  showWeekly,
  weekHasNonWorkingDay,
  rosteredDays,
  enabledWeekDaysInRange,
}: CapacityData) => {
  if (summaryUnit === "capacityPercentage") {
    return getPercentageCapacityData({
      assigned,
      contracted,
      enabledDaysInRange,
      isANonWorkingDay,
      timeOff,
      weekHasNonWorkingDay,
    })
  } else {
    return getTimeCapacityData({
      assigned,
      contracted,
      textOverflows,
      isANonWorkingDay,
      timeOff,
      fulltimeMinutesPerDay,
      summaryUnit,
      showWeekly,
      weekHasNonWorkingDay,
      rosteredDays,
      enabledWeekDaysInRange,
    })
  }
}

const TimeoffConflictWarning = ({
  hasTimeOffConflict,
  expandRow,
  showWeekly,
}: {
  hasTimeOffConflict: boolean
  expandRow: React.MouseEventHandler<HTMLDivElement>
  showWeekly: boolean
}) => {
  if (!hasTimeOffConflict) {
    return <></>
  }
  return (
    <div className={styles.warning} onClick={expandRow}>
      <Tooltip
        hoverOpenDelay={500}
        content="Assignments conflicting with time off"
      >
        <Warning innerColor={!showWeekly && "#fff"} />
      </Tooltip>
    </div>
  )
}

const CapacityIndicator = ({
  contracted, // daily for showWeekly=false, weekly aggregate for showWeekly=true
  enabledDaysInRange,
  assigned, // daily for showWeekly=false, weekly aggregate for showWeekly=true
  timeOff, // invalid for showWeekly=false, weekly aggregate for showWeekly=true
  width,
  summaryUnit,
  showWeekly,
  expandRow,
  hasTimeOffConflict,
  isANonWorkingDay,
  isWeekend,
  weekHasNonWorkingDay,
  rosteredDays = [],
  enabledWeekDaysInRange, // should be <=5 if showWeekly=true
  ...props
}: Props) => {
  const account = useFragment(
    graphql`
      fragment CapacityIndicator_account on accounts {
        default_full_time_minutes
      }
    `,
    props.account,
  )
  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)

  const showTimeOffConflict = hasTimeOffConflict && !isConsistentTimeOffEnabled

  const isWeeklyTimeOff =
    timeOff && !showTimeOffConflict && timeOff === contracted && assigned === 0

  const isTimeOff =
    !isANonWorkingDay &&
    ((timeOff && !showWeekly) ||
      (timeOff && isConsistentTimeOffEnabled) ||
      isWeeklyTimeOff)

  const isHolidayOrRDO = isANonWorkingDay && !isWeekend && assigned === 0

  const [textOverflows, setTextOverflows] = useState(false)
  const textRef = useRef(null)

  useLayoutEffect(() => {
    setTextOverflows(width < textRef.current?.scrollWidth + 30) // add 30 to give extra padding
  }, [textRef, width])

  if (
    (isTimeOff && timeOff === contracted) ||
    isHolidayOrRDO ||
    (!contracted && !isANonWorkingDay) ||
    (showWeekly && showTimeOffConflict)
  ) {
    let showText = width > 75
    if (showTimeOffConflict && width <= 100) {
      showText = false
    }

    const text = (() => {
      if (!showText) {
        return undefined
      }

      if (showWeekly && showTimeOffConflict) {
        return "Conflict"
      } else {
        return isTimeOff || isHolidayOrRDO ? "Off" : "No contract"
      }
    })()

    return (
      <div
        className={`
          ${styles.noCapacity}
          ${isTimeOff || isHolidayOrRDO ? styles.timeOff : styles.noContract}
          ${showTimeOffConflict ? styles.conflict : ""}
        `}
      >
        {Boolean(text) && (
          <div className={styles.label}>
            {text}
            <Tooltip
              hoverOpenDelay={500}
              content="Assignments conflicting with time off"
            >
              {""}
            </Tooltip>
          </div>
        )}
        <TimeoffConflictWarning
          hasTimeOffConflict={showTimeOffConflict}
          expandRow={expandRow}
          showWeekly={showWeekly}
        />
      </div>
    )
  }

  const barData = getCapacityData({
    assigned,
    contracted,
    textOverflows,
    summaryUnit,
    enabledDaysInRange,
    isANonWorkingDay,
    timeOff,
    fulltimeMinutesPerDay: account.default_full_time_minutes,
    effortDisplayUnit: effortDisplayUnit,
    showWeekly,
    weekHasNonWorkingDay,
    rosteredDays,
    enabledWeekDaysInRange,
  })

  return (
    <div className={styles.container}>
      <div className={cc([styles.capacityIndicator, barData.barClassNames])}>
        <div className={styles.capacityAmount}>
          <span ref={textRef}>{width > 35 && barData.barText}</span>
          <TimeoffConflictWarning
            hasTimeOffConflict={showTimeOffConflict}
            expandRow={expandRow}
            showWeekly={showWeekly}
          />
        </div>
      </div>
      {assigned ? <CellDivider color="#ffffff" /> : ""}
    </div>
  )
}

export default CapacityIndicator
