import cc from "classcat"
import { Feature, useFeature } from "flagged"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PageManager.module.css"

import { PageManager_user$key } from "~/__generated__/PageManager_user.graphql"

import { sessionStore, useHasuraContext } from "~/store/hasura"

import { page as trackPage } from "./helpers/analytics"
import { reportError } from "~/helpers/error-helpers"

import { useSidePanel } from "./common/SidePanel/SidePanel"

import AccountWelcome from "~/AccountWelcome/AccountWelcome"
import AuditLogSubscription from "~/subscriptions/AuditLogSubscription"
import RoleSubscription from "~/subscriptions/RoleSubscription"

import PeoplePlanner from "./PeoplePlanner/PeoplePlanner"
import ProjectPlanner from "./ProjectPlanner/ProjectPlanner"
import SelectViewDialog from "./Views/SelectViewDialog"
import { ReduxState } from "./rootReducer"

type Props = {
  subscriptionActive: boolean
}

const PageManager = (props: Props) => {
  const activePage = useSelector((state: ReduxState) => state.activePage)
  const hasuraContext = useHasuraContext()
  const user = useFragment<PageManager_user$key>(
    graphql`
      fragment PageManager_user on users
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        ...AccountWelcome_user
          @arguments(
            projectsFilter: $projectsFilter
            peopleFilter: $peopleFilter
          )
        account {
          account_type
          ...ProjectPlanner_account @arguments(projectsFilter: $projectsFilter)
          ...PeoplePlanner_account @arguments(projectsFilter: $projectsFilter)
        }
      }
    `,
    hasuraContext,
  )
  // Normalise page view tracking
  trackPage(`/${activePage}`)

  const realtimeSubscriptionsEnabled = useFeature("realtime_subscriptions")

  useEffect(() => {
    if (!realtimeSubscriptionsEnabled) {
      return
    }

    const subscriptions = [
      new AuditLogSubscription(sessionStore),
      new RoleSubscription(),
    ]

    Promise.all(subscriptions.map((s) => s.subscribe())).catch((error) => {
      void reportError(error)
    })

    return () => {
      subscriptions.forEach((s) => s.unsubscribe())
    }
  }, [realtimeSubscriptionsEnabled])

  const { isOpen } = useSidePanel()

  const page =
    activePage === "people" ? (
      <PeoplePlanner account={user.account} />
    ) : (
      <ProjectPlanner account={user.account} />
    )

  return (
    <div
      id="pageManager"
      className={cc([styles.pageManager, { [styles.sidePanelOpen]: isOpen }])}
    >
      {page}
      <AccountWelcome gql={user} />
      {props.subscriptionActive && (
        <Feature name="pre_filtered_views">
          <SelectViewDialog />
        </Feature>
      )}
    </div>
  )
}

export default PageManager
