import { dateHelpers } from "@runn/calculations"
import { useFeature } from "flagged"
import React, { useEffect, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { connect, useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "~/common/PillActions/PillActions.module.css"

import { AssignmentActionPill_account$key } from "./__generated__/AssignmentActionPill_account.graphql"

import { track } from "~/helpers/analytics"
import {
  getConsecutiveContractDates,
  getMinutesPerDayForDate,
} from "~/helpers/contract-helpers"
import {
  getClosestNextWorkingDay,
  getClosestPreviousWorkingDay,
  getMergedHolidays,
  getMergedTimeOffs,
} from "~/helpers/holiday-helpers"
import { isLocalId } from "~/helpers/local-id"
import { getReasonPillIsDisabled } from "~/helpers/planner-helpers"

import ItemTooltip from "~/common/PillActions/ItemTooltip"
import PillActions, {
  PopoverContextMenu,
} from "~/common/PillActions/PillActions"
import PlannerDatePicker, {
  OnSubmitProps,
} from "~/common/PlannerDatePicker/PlannerDatePicker"

import { setHighlightedItemData as setHighlightedItemDataDispatch } from "~/GlobalState"
import {
  disableMultiSelectAndResetItems,
  isSplitScreenMode,
} from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import { showToast } from "~/containers/ToasterContainer"
import { useAppSelector } from "~/hooks/redux"

import {
  bulkAssignmentDelete,
  bulkEditAssignment,
  deleteTimeOffConflict,
  updateAndCreateAssignments,
} from "./AssignmentActionHelpers"
import BasicPill from "./BasicPill/BasicPill"
import { getPillPosition } from "./PillHelpers"
import * as actionHelpers from "./action_helpers"

const canNotEdit = () => {
  showToast({
    message:
      "Unable to update as the server is processing your previous request. Please try your action again.",
    type: "warning",
  })
}

type Props = {
  account: AssignmentActionPill_account$key
  assignment: Assignment
  person: Person
  unconfirmed: boolean
  client: Client
  project: Project
  setHighlightedItemData: (any) => void
  showBasic: boolean
  calendarStartDate: Date
  calendarEndDate: Date
  dayWidth: number
  calendarWeekendsExpanded: boolean
  highlightedAssignments: Array<number>
  defaultFullTimeMinutes: number
  isUpdatingAssignment: boolean
  setIsUpdatingAssignment: (boolean) => void
}

type ActionType = "" | "move" | "changeStartDate" | "changeEndDate"

const AssignmentActionPill = (props: Props) => {
  const {
    assignment,
    person,
    setHighlightedItemData,
    client,
    project,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
    highlightedAssignments,
    defaultFullTimeMinutes,
    isUpdatingAssignment,
    setIsUpdatingAssignment,
  } = props

  const account = useFragment(
    graphql`
      fragment AssignmentActionPill_account on accounts {
        default_full_time_minutes
        ...PlannerDatePicker_account
      }
    `,
    props.account,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const defaultPhase = project.phases?.find(
    (phase) => phase.id === assignment.phase_id,
  )
  const [updatedEndDate, setUpdatedEndDate] = useState()
  const [isDeleted, setIsDeleted] = useState(false)
  const [defaultPhaseId, setDefaultPhaseId] = useState(defaultPhase?.id || null)
  const calendar = useAppSelector((state) => state.calendar, isDeeplyEqual)
  const dispatch = useDispatch()
  const multiSelect = useAppSelector(
    (state) => state.multiSelect,
    isDeeplyEqual,
  )
  const multiSelectEnabled = multiSelect.isEnabled
  const multiSelectItemIds = multiSelect.items.map((i) => i.id)
  const isHighlighted =
    highlightedAssignments.includes(assignment.id) ||
    (isSplitScreenMode(multiSelect.modeAction) &&
      multiSelectItemIds.includes(assignment.id))
  const isTransferPlaceholderMode = useAppSelector(
    (state) => state.plannerV2.splitScreen.transferEntirePlaceholder,
  )

  useEffect(() => {
    setDefaultPhaseId(defaultPhase?.id)
  }, [assignment.phase_id]) // eslint-disable-line react-hooks/exhaustive-deps

  const affectedTimeOffs = project.is_template ? [] : person.time_offs

  const isOutOfContract = React.useMemo(() => {
    if (person.is_placeholder) {
      return false
    }
    return getConsecutiveContractDates(person.contracts).every(
      (c) =>
        assignment.start_date < c.start_date ||
        (c.end_date && assignment.end_date > c.end_date),
    )
  }, [person.contracts, person.is_placeholder, assignment])

  const contractMinutes = getMinutesPerDayForDate(
    assignment.start_date,
    person.contracts,
    account.default_full_time_minutes,
  )

  const { can, subject } = usePermissions()

  const assignmentSubject = subject("Assignment", {
    project: {
      id: assignment.project_id,
      isTemplate: project.is_template,
    },
  })
  const assignmentPermissions = {
    edit: can("edit", assignmentSubject),
  }

  if (!assignmentPermissions.edit) {
    const mergedTimeOffs = isConsistentTimeOffEnabled
      ? getMergedTimeOffs(
          person.time_offs,
          assignment.start_date,
          assignment.end_date,
        )
      : getMergedHolidays(person.time_offs)

    const { dayWidth } = calendar
    const { businessDays, offset, width, pillWidthPx, pillLabelOffset } =
      getPillPosition({
        item: assignment,
        calendarStartDate,
        calendarEndDate,
        calendarWeekendsExpanded,
        dayWidth,
        mergedTimeOffs,
      })

    // Exclude items outside the calendar date
    if (
      Number(assignment.end_date) < calendar.calStartNum ||
      Number(assignment.start_date) > calendar.calEndNum
    ) {
      return null
    }

    const pillDisabledMessage = getReasonPillIsDisabled({
      multiSelectItems: multiSelect.items,
      pill: assignment,
      modeAction: multiSelect.modeAction,
      isTransferPlaceholderMode,
      pillPermissions: assignmentPermissions,
    })

    return (
      <div
        className={styles.PillContainer}
        style={{ left: `${offset}%`, width: `${width}%` }}
      >
        <ItemTooltip
          typedItem={{ type: "assignment", item: assignment }}
          phases={project?.phases}
          days={businessDays}
          forceDelayTime={500}
          pillWidthPx={pillWidthPx}
          person={person}
          pillDisabledMessage={pillDisabledMessage}
          contractMinutesPerDay={contractMinutes}
        >
          <BasicPill
            typedItem={{ type: "assignment", item: assignment }}
            basicPill
            wrappedPill
            person={person}
            dayWidth={dayWidth}
            isPlaceholder={person.is_placeholder}
            isTentative={!project.confirmed}
            isNonBillable={!assignment.is_billable}
            calendarStartDate={calendarStartDate}
            calendarEndDate={calendarEndDate}
            defaultPhaseId={defaultPhaseId}
            phases={project.phases}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
            isHighlighted={isHighlighted}
            defaultFullTimeMinutes={defaultFullTimeMinutes}
            contractMinutesPerDay={contractMinutes}
            pillLabelOffset={pillLabelOffset}
          />
        </ItemTooltip>
      </div>
    )
  }

  const changeAssignment = async (
    a: Assignment,
    movementType?: ActionType,
    reset?: (resetAssignment: Assignment) => void,
  ) => {
    // Reset any movements that end up overlapping a time off
    if (movementType === "move" && reset && !isConsistentTimeOffEnabled) {
      const overlapsTimeOff = affectedTimeOffs.some(
        (to) =>
          to.leave_type !== "holiday" && actionHelpers.itemsOverlap(a, to),
      )
      if (overlapsTimeOff) {
        reset(assignment)
        return
      }
    }

    try {
      setIsUpdatingAssignment(true)
      await updateAndCreateAssignments(a, person, isConsistentTimeOffEnabled)
    } finally {
      setIsUpdatingAssignment(false)
    }

    track("Assignment Dragged")
  }

  const splitAssignment = (a, splitDate) => {
    if (isLocalId(a.id)) {
      return canNotEdit()
    }

    const newEndDate = getClosestPreviousWorkingDay(
      affectedTimeOffs,
      dateHelpers.subBusDays(splitDate, 1),
    ).stringDate

    const newStartDate = getClosestNextWorkingDay(
      affectedTimeOffs,
      splitDate,
    ).stringDate

    const updatedAssignment = { ...a, end_date: newEndDate }
    const secondAssignment = { ...a, start_date: newStartDate }

    // If an assignment falls adjacent to a public holiday, because we use
    // getClosestPreviousWorkingDay, the updated end date might fall
    // before the start date (or vice versa with getClosestNextWorkingDay)
    // If this happens we dont need to create an assignment, just update the existing one
    if (secondAssignment.start_date > secondAssignment.end_date) {
      void bulkEditAssignment({
        update: [updatedAssignment],
        create: [],
        delete: [],
      })
    } else if (updatedAssignment.start_date > updatedAssignment.end_date) {
      void bulkEditAssignment({
        update: [secondAssignment],
        create: [],
        delete: [],
      })
    } else {
      void bulkEditAssignment({
        update: [updatedAssignment],
        create: [secondAssignment],
        delete: [],
      })
    }

    track("Assignment Split")
  }

  const saveUpdatedAssignment = async (a: OnSubmitProps) => {
    const updatedAssignment = {
      id: a.itemId,
      start_date: dateHelpers.formatToRunnDate(a.startDate),
      end_date: dateHelpers.formatToRunnDate(a.endDate),
      minutes_per_day: Math.round(a.minutesPerDay),
      person_id: assignment.person_id,
      project_id: a.projectId,
      role_id: a.roleId,
      is_billable: a.is_billable,
      is_template: a.is_template,
      note: a.note?.trim(),
      workstream_id: a.workstreamId ?? null,
      phase_id: a.phaseId,
      non_working_day: a.non_working_day,
    }

    try {
      await updateAndCreateAssignments(
        updatedAssignment,
        person,
        isConsistentTimeOffEnabled,
      )
    } finally {
      setIsUpdatingAssignment(false)
    }

    track("Assignment Created and Updated")
  }

  const onDeleteAssignment = (a: Assignment) => {
    if (isLocalId(a.id)) {
      return canNotEdit()
    }

    bulkAssignmentDelete([a])

    track("Assignment Deleted")
  }

  const onAssignmentUpdateFromForm = (
    a: OnSubmitProps,
    closePopover: () => void,
  ) => {
    void saveUpdatedAssignment(a)
    closePopover()
  }

  const handleSelectPhase = (phaseId: number) => {
    // Phases are project specific. Ignore selected assignments in other projects. Ignore selected time offs.
    const updateAssignments = multiSelectEnabled
      ? (multiSelect.items.filter(
          (item) => "project_id" in item && item.project_id === project.id,
        ) as unknown as Array<Assignment & { minutes: number }>[])
      : [assignment]
    void bulkEditAssignment({
      create: [],
      update: updateAssignments.map((a) => {
        const updated = { ...a, phase_id: phaseId }
        // Redux state adds more properties which aren't valid in Hasura
        delete updated.minutes
        delete updated.minutesLessTimeOff
        return updated
      }),
      delete: [],
    })
    dispatch(disableMultiSelectAndResetItems())
  }

  const onDelete = () => {
    onDeleteAssignment(assignment)
    setHighlightedItemData(null)
    setIsDeleted(true)
  }

  const handleDeleteConflict = (
    timeOff,
    selectedPerson,
    selectedAssignment,
  ) => {
    // Mock up a person with only the one assignment that is in scope
    const mockPerson = {
      ...selectedPerson,
      assignments: [selectedAssignment],
    }
    deleteTimeOffConflict(timeOff, mockPerson.assignments)
  }

  const handleMiniEndDateChange = (value) => {
    setUpdatedEndDate(value)
  }

  const generateAssignmentEditForm = (
    closePopover: () => void,
    isPopoverOpen: boolean,
    contextMenu: PopoverContextMenu,
  ) => (
    <PlannerDatePicker
      account={account}
      mini
      allowDelete
      allowScroll
      nonWorkingDay={assignment.non_working_day}
      itemId={assignment.id}
      assignment={assignment}
      startDate={assignment.start_date}
      endDate={assignment.end_date}
      minutesPerDay={assignment.minutes_per_day}
      projectId={assignment.project_id}
      roleId={assignment.role_id}
      person={person}
      client={client}
      project={project}
      onSubmit={(a: OnSubmitProps) =>
        onAssignmentUpdateFromForm(a, closePopover)
      }
      onDelete={onDelete}
      closePopover={closePopover}
      handleCancel={closePopover}
      isPopoverOpen={isPopoverOpen}
      handleMiniEndDateChange={handleMiniEndDateChange}
      defaultPhaseId={defaultPhaseId}
      popoverContextMenu={contextMenu}
    />
  )

  if (isDeleted) {
    return null
  }

  return (
    <>
      <PillActions
        type="assignment"
        item={assignment}
        project={project}
        person={person}
        phaseId={assignment.phase_id}
        minipickerUpdatedEndDate={updatedEndDate}
        onItemChange={changeAssignment}
        disableItemChange={isUpdatingAssignment}
        onItemSplit={splitAssignment}
        onDeleteConflict={handleDeleteConflict}
        onDelete={onDelete}
        onPhaseSelect={handleSelectPhase}
        popoverContent={generateAssignmentEditForm}
        calendarWeekendsExpanded={calendarWeekendsExpanded}
        isOutOfContract={isOutOfContract}
        render={(pillProps) => (
          <BasicPill
            {...pillProps}
            person={person}
            defaultPhaseId={defaultPhaseId}
            phases={project.phases}
            onMenuClick={pillProps.onMenuClick}
            wrappedPill
            isPlaceholder={person.is_placeholder}
            isTentative={!project.confirmed}
            isNonBillable={!pillProps.item.is_billable}
            typedItem={{ type: "assignment", item: pillProps.item }}
            calendarStartDate={pillProps.calendarStartDate}
            calendarEndDate={pillProps.calendarEndDate}
            isSelected={pillProps.isSelected}
            isFloating={pillProps.isFloating}
            isConflict={pillProps.isOverlappingTimeOff || isOutOfContract}
            isHighlighted={isHighlighted}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
            defaultFullTimeMinutes={defaultFullTimeMinutes}
            contractMinutesPerDay={contractMinutes}
            pillLabelOffset={pillProps.pillLabelOffset}
          />
        )}
      />
    </>
  )
}

type Person = {
  id: number
  email: string | null
  is_placeholder: boolean
  assignments: Assignment[]
  time_offs: TimeOff[]
  first_name: string
  last_name: string
  contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      id: number
      name: string
    }
  }>
}

type Assignment = {
  id: number
  person_id: number
  role_id: number
  project_id: number
  phase_id: number
  workstream_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  note: string
  is_placeholder: boolean
  total_minutes: number
  non_working_day: boolean
}

type Client = {
  id: number
  name: string
  image_key: string
  website: string
}

type Project = {
  id: number
  name: string
  confirmed: boolean
  is_template: boolean
  pricing_model: string
  phases: Phase[]
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  leave_type: string
  note: string
  total_minutes?: number
  minutes_per_day: number
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
    readonly external: {
      readonly integration_id: number
    }
  }>
}

type Phase = {
  id: number
  color: string
  name: string
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  dayWidth: state.calendar.dayWidth,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
  highlightedAssignments: state.calendar.highlightedAssignments,
})

const mapDispatchToProps = {
  setHighlightedItemData: setHighlightedItemDataDispatch,
}

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentActionPill)
