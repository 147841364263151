/**
 * @generated SignedSource<<f6e8e8565ca9801f503bb13ace727e44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsSection_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_user">;
  readonly " $fragmentType": "ProjectDetailsSection_user";
};
export type ProjectDetailsSection_user$key = {
  readonly " $data"?: ProjectDetailsSection_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsSection_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsSection_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetails_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "c20ee1f5964de0fafd5c1c393b63e250";

export default node;
