import React, { useEffect, useState } from "react"

import styles from "./CostToBusiness.module.css"

import CurrencyInput from "~/common/CurrencyInput"
import InputLabel from "~/common/InputLabel"

import { usePermissions } from "~/Permissions/usePermissions"

type Props = {
  currency: string
  selectedRole: {
    id: number
    name: string
    default_hour_cost: number
  }
  setCost: (cost: number) => void
  cost: number
  isNewPerson: boolean
  disabled?: boolean
}

const CostToBusiness = (props: Props) => {
  const { selectedRole, setCost, cost, currency, isNewPerson } = props
  const selectedRoleCost = selectedRole?.default_hour_cost
  const [customCost, setCustomCost] = useState(cost ?? selectedRoleCost)
  const { can, subject } = usePermissions()
  const hasRoleCost =
    selectedRoleCost !== null && selectedRoleCost !== undefined
  const showCopyRoleCost = hasRoleCost && customCost !== selectedRoleCost

  useEffect(() => {
    if (isNewPerson) {
      setCustomCost(selectedRoleCost)
    }
  }, [selectedRoleCost, isNewPerson])

  useEffect(() => {
    setCost(customCost)
  })

  const hideFinancials =
    !can("view", subject("Financial")) || !can("view", subject("Salary"))

  if (hideFinancials) {
    const noContractOrPerson = cost === null
    return (
      <div className={styles.wrapper}>
        <InputLabel label="Cost to Business" />
        <div className={styles.permissions}>
          You do not have permission to change hourly costs.
          {noContractOrPerson && " The default role costs will be used."}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <CurrencyInput
        label="Cost to Business"
        name="custom-cost"
        style={{ maxWidth: "200px" }}
        value={customCost}
        onChange={setCustomCost}
        autoComplete="off"
        rateUnit="/hour"
        dataTest="cost-to-business-input"
        disabled={props.disabled}
      />
      {showCopyRoleCost && (
        <div
          className={styles.roleCostContainer}
          onClick={() => setCustomCost(selectedRoleCost)}
        >
          Use default cost for a {selectedRole.name}: {currency}
          {selectedRoleCost}/hour
        </div>
      )}
    </div>
  )
}

export default CostToBusiness
