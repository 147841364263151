import React from "react"

import styles from "./NonBillableWarning.module.css"

import { Warning } from "~/common/react-icons"

const NonBillableWarning = () => {
  return (
    <div className={styles.warning}>
      <Warning />
      <div className={styles.message}>
        <b>Warning!</b>
        <br /> All billable assignments in this project will change to
        non-billable and the project budget will be set to $0.
        <br />
        This cannot be reverted.
      </div>
    </div>
  )
}

export default NonBillableWarning
