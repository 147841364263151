import { Radio, RadioGroup, Tooltip } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"

import styles from "./Availability.module.css"

import DurationHelper from "~/helpers/DurationHelper"

import Duration from "~/common/Duration"

type Props = {
  companyDefaultMinutes: number
  setMinutesPerDay: (minutes: number) => void
  minutesPerDay: number
  rosteredDays: readonly number[]
  setRosteredDays: (days: number[]) => void
  businessDays: any
  disabled?: boolean
}

const Availability = (props: Props) => {
  const {
    companyDefaultMinutes,
    setMinutesPerDay,
    minutesPerDay,
    rosteredDays,
    setRosteredDays,
    businessDays,
    disabled,
  } = props
  const [availabilityType, setAvailabilityType] = useState(
    rosteredDays.some((day) => day === 0) ? "part" : "full",
  )
  const [partTimeMinutes, setPartTimeMinutes] = useState(minutesPerDay)

  const fullTimeRoster = Array(businessDays.length).fill(companyDefaultMinutes)

  const handleMinutesPerDay = (totalMinutes) => {
    setPartTimeMinutes(totalMinutes)

    const updatedDays = rosteredDays.map((x) => (!x ? 0 : totalMinutes))
    setRosteredDays(updatedDays)
  }

  const handleMinutes = (type) => {
    switch (type) {
      case "full":
        setMinutesPerDay(companyDefaultMinutes)
        break
      case "part":
        setMinutesPerDay(partTimeMinutes || 0)
        break
      default:
        setMinutesPerDay(0)
        break
    }
  }

  const handleAvailabilityType = (e) => {
    setAvailabilityType(e.target.value)
    handleMinutes(e.target.value)

    setRosteredDays(fullTimeRoster)
    setPartTimeMinutes(companyDefaultMinutes)
  }

  useEffect(() => {
    handleMinutes(availabilityType)
  })

  useEffect(() => {
    if (minutesPerDay !== companyDefaultMinutes) {
      setAvailabilityType("part")
    }
  }, [partTimeMinutes]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleDay = (selectedIdx) => {
    if (disabled) {
      return
    }

    const updatedDays = rosteredDays.map((x, idx) => {
      const toggleMinutes = x ? 0 : minutesPerDay
      if (idx === selectedIdx) {
        return toggleMinutes
      }
      return x
    })

    setRosteredDays(updatedDays)
  }

  const numberOfRosteredDays = rosteredDays.filter((x) => x).length

  const fullTimeLabel =
    availabilityType === "full"
      ? `Full time: ${DurationHelper.formatDuration(companyDefaultMinutes)}`
      : "Full time"
  const partTimeLabel =
    availabilityType === "part" ? (
      <span>
        Custom{" "}
        <span style={{ color: "var(--shadow)" }}>
          ({numberOfRosteredDays} {numberOfRosteredDays > 1 ? "days" : "day"})
        </span>
      </span>
    ) : (
      "Custom"
    )

  const RosteredDay = (x) => {
    const { children, showTooltip } = x
    if (!showTooltip) {
      return children
    }
    return <Tooltip content="Cannot have less than 1 day">{children}</Tooltip>
  }

  return (
    <div className={styles.wrapper}>
      <RadioGroup
        label="Work Days"
        onChange={handleAvailabilityType}
        selectedValue={availabilityType}
      >
        <Radio label={fullTimeLabel} value="full" disabled={disabled} />
        <Radio value="part" disabled={disabled}>
          {partTimeLabel}
        </Radio>
      </RadioGroup>
      {availabilityType === "part" && (
        <div className={styles.partTime}>
          <div
            data-component="Availability/rosteredDays"
            className={styles.rosteredDays}
          >
            {rosteredDays.map((d, index) => {
              const lastDaySelected = !!d && numberOfRosteredDays === 1
              const selected = d !== 0

              return (
                <RosteredDay showTooltip={lastDaySelected} key={index}>
                  <div
                    onClick={() => toggleDay(index)}
                    className={`${styles.rosteredDay} ${
                      d === 0 ? styles.deselected : ""
                    } ${lastDaySelected || disabled ? styles.disabled : ""}`}
                    data-test={`RosteredDay-${businessDays[index]}`}
                  >
                    {businessDays[index].charAt(0)}
                  </div>
                  <input
                    name={businessDays[index]}
                    type="checkbox"
                    checked={selected}
                    hidden
                    readOnly
                  />
                </RosteredDay>
              )
            })}
          </div>
          <div style={{ marginBottom: 7 }}>Hours per day</div>
          <Duration
            handleMinutesPerDay={handleMinutesPerDay}
            minutesPerDay={partTimeMinutes}
            maxMinutes={24 * 60}
            className={styles.duration}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}

export default Availability
