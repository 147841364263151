import * as fe from "@runn/filter-engine"
import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import type { PlannerFilterQueryQuery } from "./__generated__/PlannerFilterQueryQuery.graphql"

import FilterQuery from "~/Planner/FilteredQuery/FilterQuery"

type Props = {
  children: React.ReactNode
}

const QUERY = graphql`
  query PlannerFilterQueryQuery {
    current_user {
      id
      view {
        id
        project_filters
        people_filters
      }
    }
  }
`

const PlannerFilterQuery = (props: Props) => {
  const { current_user: currentUser } =
    useLazyLoadQuery<PlannerFilterQueryQuery>(QUERY, {})

  const viewId = currentUser?.view?.id
  const view = typeof viewId === "number" ? currentUser?.view : undefined

  const projectFilters = view ? fe.filters.flat(view.project_filters) : []
  const peopleFilters = view ? fe.filters.flat(view.people_filters) : []

  return (
    <FilterQuery peopleFilters={peopleFilters} projectFilters={projectFilters}>
      {props.children}
    </FilterQuery>
  )
}

export default PlannerFilterQuery
