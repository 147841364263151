/**
 * @generated SignedSource<<1ff3ed301bea3580aa29d3dcb68b3668>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TimeOffCreateMutation$variables = {
  endDate: string;
  minutesPerDay?: number | null | undefined;
  note?: string | null | undefined;
  personId: number;
  startDate: string;
};
export type TimeOffCreateMutation$data = {
  readonly createTimeOff: {
    readonly time_off: {
      readonly end_date: string | null | undefined;
      readonly ext_time_off_links: ReadonlyArray<{
        readonly active: boolean;
        readonly ext_time_off_id: number;
        readonly external: {
          readonly __typename: "ext_time_off";
          readonly id: number;
          readonly integration_id: number;
        } | null | undefined;
        readonly external_id: number;
        readonly time_off_id: number;
      }>;
      readonly id: number;
      readonly leave_type: string;
      readonly minutes_per_day: number | null | undefined;
      readonly note: string | null | undefined;
      readonly person_id: number;
      readonly start_date: string | null | undefined;
    } | null | undefined;
  };
};
export type TimeOffCreateMutation = {
  response: TimeOffCreateMutation$data;
  variables: TimeOffCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minutesPerDay"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "minutesPerDay",
        "variableName": "minutesPerDay"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "concreteType": "CreateTimeOffResult",
    "kind": "LinkedField",
    "name": "createTimeOff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "time_offs",
        "kind": "LinkedField",
        "name": "time_off",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": "start_date",
            "args": null,
            "kind": "ScalarField",
            "name": "start_date_runn",
            "storageKey": null
          },
          {
            "alias": "end_date",
            "args": null,
            "kind": "ScalarField",
            "name": "end_date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "leave_type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "person_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minutes_per_day",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  }
                }
              }
            ],
            "concreteType": "ext_time_off_link",
            "kind": "LinkedField",
            "name": "ext_time_off_links",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time_off_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ext_time_off_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": "external_id",
                "args": null,
                "kind": "ScalarField",
                "name": "ext_time_off_id",
                "storageKey": null
              },
              {
                "alias": "external",
                "args": null,
                "concreteType": "ext_time_off",
                "kind": "LinkedField",
                "name": "ext_time_off",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integration_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffCreateMutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeOffCreateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "42c0fa4e9965b5062389d1b7648e9b92",
    "id": null,
    "metadata": {},
    "name": "TimeOffCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TimeOffCreateMutation(\n  $personId: Int!\n  $startDate: String!\n  $endDate: String!\n  $note: String\n  $minutesPerDay: Int\n) {\n  createTimeOff(personId: $personId, startDate: $startDate, endDate: $endDate, note: $note, minutesPerDay: $minutesPerDay) {\n    time_off {\n      id\n      start_date: start_date_runn\n      end_date: end_date_runn\n      leave_type\n      note\n      person_id\n      minutes_per_day\n      ext_time_off_links(where: {active: {_eq: true}}) {\n        time_off_id\n        ext_time_off_id\n        active\n        external_id: ext_time_off_id\n        external: ext_time_off {\n          __typename\n          id\n          integration_id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b96a0824d1acba21de123094e5b2c37";

export default node;
