import cc from "classcat"
import React from "react"

import styles from "./ProjectBudget.module.css"

import CurrencyInput from "~/common/CurrencyInput"
import Select from "~/common/Select"

import {
  PricingModelSelection,
  RATE_TYPES,
  RateTypeSelection,
} from "~/GLOBALVARS"
import SelectPricingModel from "~/forms/ProjectForm/SelectPricingModel"

import { RateCardOption } from "./ProjectBudget"

type Props = {
  rateCards: RateCardOption[]
  rateCard: RateCardOption
  billingMethod: PricingModelSelection
  rateType: RateTypeSelection
  totalBudget: number
  invalid?: boolean
  currency: string
  showNonBillableView?: boolean
  allowBillingMethodEdit?: boolean
  onChangeRateCard?: (rateCard: RateCardOption) => void
  onChangeRateType?: (rateType: RateTypeSelection) => void
  onChangeBillingMethod?: (method: PricingModelSelection) => void
  onChangeTotalBudget?: (budget: number) => void
}
const SELECT_HEIGHT = 40
const FONT_SIZE = 13

const BudgetSummary = (props: Props) => {
  const {
    rateCards,
    rateCard,
    billingMethod,
    rateType,
    totalBudget,
    invalid = false,
    currency,
    showNonBillableView = true,
    allowBillingMethodEdit = true,
    onChangeRateCard,
    onChangeRateType,
    onChangeBillingMethod,
    onChangeTotalBudget,
  } = props
  return (
    <div className={styles.budgetTopSection}>
      <div>
        <div className={styles.budgetLabel}>Pricing Model</div>
        <SelectPricingModel
          value={billingMethod}
          onChange={(e) => onChangeBillingMethod && onChangeBillingMethod(e)}
          height={SELECT_HEIGHT}
          fontSize={FONT_SIZE}
          isDisabled={!allowBillingMethodEdit}
          dataTest="budget-pricing-model"
        />
      </div>
      <div>
        <div className={styles.budgetLabel}>Rate Unit</div>
        <Select
          name="project-rate-types"
          dataTest="project-rate-types"
          value={rateType}
          onChange={onChangeRateType}
          options={RATE_TYPES}
          placeholder="Select Rate Unit"
          height={SELECT_HEIGHT}
          fontSize={FONT_SIZE}
        />
      </div>
      {!showNonBillableView && (
        <div>
          <div className={styles.budgetLabel}>Reference Rate Card</div>
          <Select
            name="project-rate-card"
            dataTest="project-rate-card"
            value={rateCard}
            onChange={onChangeRateCard}
            options={rateCards}
            placeholder="Select Reference Rate Card"
            height={SELECT_HEIGHT}
            fontSize={FONT_SIZE}
          />
        </div>
      )}
      {!showNonBillableView && (
        <div className={styles.totalBudgetContainer}>
          <div
            className={cc([styles.budgetLabel, invalid && styles.invalidLabel])}
          >
            Budget {invalid && "is invalid"}
          </div>
          <CurrencyInput
            name="total-budget"
            dataTest="total-budget"
            style={{ textAlign: "right" }}
            value={totalBudget}
            onChange={onChangeTotalBudget}
            currency={currency}
            height={40}
            fontSize={13}
            stepSize={1000}
            error={invalid}
            integer={true}
            selectAllOnFocus
          />
        </div>
      )}
    </div>
  )
}

export default BudgetSummary
