/**
 * @generated SignedSource<<bf30992ad9677a83bb94d1291224fc75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExtPersonLinkUpdateInput = {
  link_ext_person_ids?: ReadonlyArray<number> | null | undefined;
  person_id: number;
  unlink_ext_person_ids?: ReadonlyArray<number> | null | undefined;
};
export type ExtPersonLinks_updateMutation$variables = {
  input: ExtPersonLinkUpdateInput;
};
export type ExtPersonLinks_updateMutation$data = {
  readonly action_ext_person_link_update: {
    readonly person: {
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"ExtLinks_Person">;
    } | null | undefined;
  } | null | undefined;
};
export type ExtPersonLinks_updateMutation = {
  response: ExtPersonLinks_updateMutation$data;
  variables: ExtPersonLinks_updateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtPersonLinks_updateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExtPersonLinkUpdateOutput",
        "kind": "LinkedField",
        "name": "action_ext_person_link_update",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExtLinks_Person"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExtPersonLinks_updateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExtPersonLinkUpdateOutput",
        "kind": "LinkedField",
        "name": "action_ext_person_link_update",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "active": {
                        "_eq": true
                      }
                    }
                  }
                ],
                "concreteType": "ext_person_link",
                "kind": "LinkedField",
                "name": "ext_person_links",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "person_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ext_person_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": "external_id",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ext_person_id",
                    "storageKey": null
                  },
                  {
                    "alias": "external",
                    "args": null,
                    "concreteType": "ext_person",
                    "kind": "LinkedField",
                    "name": "ext_person",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remote_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "integration_id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c02282ec3597105340551ac9889ee5c",
    "id": null,
    "metadata": {},
    "name": "ExtPersonLinks_updateMutation",
    "operationKind": "mutation",
    "text": "mutation ExtPersonLinks_updateMutation(\n  $input: ExtPersonLinkUpdateInput!\n) {\n  action_ext_person_link_update(input: $input) {\n    person {\n      id\n      ...ExtLinks_Person\n    }\n  }\n}\n\nfragment ExtLinks_Person on people {\n  ext_person_links(where: {active: {_eq: true}}) {\n    person_id\n    ext_person_id\n    active\n    external_id: ext_person_id\n    external: ext_person {\n      id\n      __typename\n      first_name\n      last_name\n      email\n      remote_id\n      integration_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9794785840dc24e3a49a34d9e5c3e20f";

export default node;
