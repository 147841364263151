/**
 * @generated SignedSource<<0d018ddb267c0677b92e8cf3de5a4ceb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeOffSummaryCounter_account$data = {
  readonly people: ReadonlyArray<{
    readonly id: number;
    readonly time_offs: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly start_date: string | null | undefined;
    }>;
  }>;
  readonly " $fragmentType": "TimeOffSummaryCounter_account";
};
export type TimeOffSummaryCounter_account$key = {
  readonly " $data"?: TimeOffSummaryCounter_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeOffSummaryCounter_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeOffSummaryCounter_account",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "_gte",
                      "variableName": "plannerStartDate"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "end_date_iso"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "time_offs",
          "kind": "LinkedField",
          "name": "time_offs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "start_date",
              "args": null,
              "kind": "ScalarField",
              "name": "start_date_runn",
              "storageKey": null
            },
            {
              "alias": "end_date",
              "args": null,
              "kind": "ScalarField",
              "name": "end_date_runn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "16ba51740dd80134de816c27db9382ef";

export default node;
