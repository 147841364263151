import cc from "classcat"
import React, { PropsWithChildren } from "react"

import styles from "./ProjectBudget.module.css"

type Props = PropsWithChildren<{
  right?: boolean
  heading?: boolean
  style?: Record<string, any>
  dataTest?: string
  className?: string
  textAlign?: "left" | "right" | "center"
  flex?: boolean
  height?: number | string
  width?: number | string
}>

const TableRow = (props: Props) => {
  const {
    children,
    right = false,
    heading = false,
    style = {},
    dataTest,
    className,
    textAlign,
    flex,
    width,
    height,
  } = props

  const mutableStyle = { ...style }
  const classes = cc([
    right ? styles.tableRowRight : styles.tableRow,
    heading && styles.tableHeading,
    className,
  ])

  if (textAlign) {
    mutableStyle.textAlign = textAlign
  }
  if (flex) {
    mutableStyle.display = "flex"
  }
  if (width) {
    mutableStyle.width = width
  }
  if (height) {
    mutableStyle.height = height
  }

  return (
    <div className={classes} style={mutableStyle} data-test={dataTest}>
      {children}
    </div>
  )
}

export default TableRow
