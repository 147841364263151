import cc from "classcat"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PageControls.module.css"

import { PageControls_account$key } from "~/common/PageControls/__generated__/PageControls_account.graphql"

import { ModeAction, isSplitScreenMode } from "~/Mode.reducer"
import { useAppSelector } from "~/hooks/redux"

import OmniButton from "./OmniButton"
import PeoplePlannerOptions from "./PeoplePlannerOptions"
import ProjectPlannerOptions from "./ProjectPlannerOptions"
import TentativeProjectsToggle from "./TentativeProjectsToggle"

const PageControls = (props: { account: PageControls_account$key }) => {
  const activePage: string = useAppSelector((state) => state.activePage)
  const modeAction: ModeAction = useAppSelector(
    (state) => state.multiSelect.modeAction,
  )
  const timeOffBarCollapsed = useAppSelector(
    (state) => state.planner.timeOffBarCollapsed,
  )

  const account = useFragment(
    graphql`
      fragment PageControls_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        id
        ...ProjectPlannerOptions_account
        ...PeoplePlannerOptions_account
        ...TentativeProjectsToggle_account
          @arguments(projectsFilter: $projectsFilter)
      }
    `,
    props.account,
  )
  const timeOffBarOpen = !timeOffBarCollapsed && activePage === "people"

  const disabled = isSplitScreenMode(modeAction)

  return (
    <div
      className={cc([
        styles.pageControls,
        {
          [styles.disabled]: disabled,
        },
      ])}
    >
      <OmniButton />
      {!timeOffBarOpen && (
        <>
          <TentativeProjectsToggle account={account} />
          {activePage === "people" && (
            <PeoplePlannerOptions account={account} />
          )}
          {activePage === "projects" && (
            <ProjectPlannerOptions account={account} />
          )}
        </>
      )}
    </div>
  )
}

export default PageControls
