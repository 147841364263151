import * as fe from "@runn/filter-engine"
import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { ChartPanel_user$key } from "./__generated__/ChartPanel_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"

import { setPeopleFilterSet } from "~/Planner/Planner.actions"
import ResizablePanel from "~/Planner/ResizablePanel"

import { useAppSelector } from "../../hooks/redux"

import { setChartPeriod, setChartType } from "./Charts.slice"
import ChartHeader from "./Header"
import { ChartMain } from "./Main/Main"
import type { ChartPeriod, ChartType } from "./types"

const fragment = graphql`
  fragment ChartPanel_user on users
  @argumentDefinitions(
    plannerStartDate: { type: "date!" }
    peopleFilter: { type: "people_bool_exp" }
    projectsFilter: { type: "projects_bool_exp" }
  ) {
    id
    view {
      id
      people_filters
    }
    favourite_people
    account {
      id
      default_full_time_minutes
      views {
        id
      }
      projects(where: $projectsFilter) {
        id
        name
        active
        confirmed
        is_template
        client {
          id
          name
        }
        team {
          id
          name
        }
        tags_computed
        members {
          id
          person_id
          role_id
          workstream_id
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          value
          custom_checkbox_type_id
        }
        managers {
          id
          user_id
        }
      }
      people(where: $peopleFilter) {
        id
        is_placeholder
        first_name
        last_name
        email
        active
        tags
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          employment_type
          minutes_per_day
          role_id
          job_title
          role {
            id
            name
          }
        }
        team {
          id
          name
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          person_id
          start_date: start_date_runn
          end_date: end_date_runn
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          project_id
          person_id
          role_id
          non_working_day
        }
        project_memberships {
          id
          project_id
          just_added_timestamp
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        custom_select_values {
          id
          custom_select_option_id
          custom_select_type_id
        }
        custom_text_values {
          id
          value
          custom_text_type_id
        }
        custom_checkbox_values {
          id
          custom_checkbox_type_id
          value
        }
        custom_date_values {
          id
          value
          custom_date_type_id
        }
        person_requests {
          id
          status
          updated_at
        }
        managers {
          id
          user_id
        }
      }
    }
  }
`

const ChartPanel = () => {
  const dispatch = useDispatch()

  const data = useFragment<ChartPanel_user$key>(fragment, useHasuraContext())
  const { view, favourite_people: favouritePersonIds, account } = data
  const { people: allPeople, projects: allProjects } = account

  const isFeatureViewEnabled = useFeature("pre_filtered_views")
  const showAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )
  const viewPeopleFilters =
    isFeatureViewEnabled && !showAllView ? view?.people_filters : undefined

  const chartType = useAppSelector((state) => state.charts.type)
  const handleChangeChartType = (selectedChartType: ChartType) => {
    track("Chart Type Changed", { type: selectedChartType })
    dispatch(setChartType(selectedChartType))
  }

  const chartPeriod = useAppSelector((state) => state.charts.period)
  const handleChangeChartPeriod = (selectedChartPeriod: ChartPeriod) =>
    dispatch(setChartPeriod(selectedChartPeriod))

  const filterSet = useAppSelector((state) => state.planner.peopleFilterSet)

  const handleChangeFilterSet = (
    selectedFilterSet: fe.engines.local.AllowedFilterSet,
  ) => {
    dispatch(setPeopleFilterSet(selectedFilterSet))
  }

  return (
    <ResizablePanel minHeight={60} maxHeight={800}>
      {(isResizing: boolean) => (
        <>
          <ChartHeader
            filterSet={filterSet}
            onChangeFilterSet={handleChangeFilterSet}
            type={chartType}
            onChangeType={handleChangeChartType}
            period={chartPeriod}
            onChangePeriod={handleChangeChartPeriod}
          />
          <ChartMain
            chartType={chartType}
            chartPeriod={chartPeriod}
            enableAnimations={!isResizing}
            favouritePersonIds={favouritePersonIds}
            allPeople={allPeople}
            allProjects={allProjects}
            companyDefaultMinutes={account.default_full_time_minutes}
            accountHasViews={account.views.length > 0}
            viewPeopleFilters={viewPeopleFilters}
          />
        </>
      )}
    </ResizablePanel>
  )
}

export { ChartPanel }
