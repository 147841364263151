import React from "react"

import styles from "./AddNewPerson.module.css"

import AddSection from "~/common/AddSection"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import AddButton from "~/common/buttons/AddButton"
import { TangramCat } from "~/common/tangrams"

import { usePermissions } from "~/Permissions/usePermissions"
import CreatePersonFormPlanner from "~/forms/CreatePerson/CreatePersonFormPlanner"

type AddNewPersonButtonProps = {
  hasNoPeople: boolean
}

const AddNewPersonButton = (props: AddNewPersonButtonProps) => {
  const { hasNoPeople } = props

  const { openPanel, closePanel } = useSidePanel()
  const { Can, subject } = usePermissions()

  const handleClick = () => {
    openPanel(
      <CreatePersonFormPlanner
        formType="single"
        onClose={() => closePanel()}
      />,
    )
  }

  return (
    <Can I="create" this={subject("Person")}>
      <AddSection className={styles.container}>
        {hasNoPeople && (
          <div className={styles.hasNoPeople}>
            <TangramCat />
            <span>Oh meow… that's a cat, we need people!</span>
          </div>
        )}
        <AddButton
          text={hasNoPeople ? "Add my first person" : "New Person"}
          onClick={handleClick}
          data-test="create-person-button"
          stretch={!hasNoPeople}
        />
      </AddSection>
    </Can>
  )
}

export default AddNewPersonButton
