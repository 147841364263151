import { keyBy } from "lodash-es"
import React from "react"
import { graphql, useFragment } from "react-relay"

import { PersonNote_note$key } from "./__generated__/PersonNote_note.graphql"
import { PersonNote_viewer$key } from "./__generated__/PersonNote_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "../../../helpers/analytics"
import { formatName } from "../../../helpers/person"

import {
  personNoteDeleteRelay,
  personNoteUpdateRelay,
} from "../../../mutations/PersonNote"

import Note from "../../../forms/Notes/Note"

type NoteProps = {
  note: PersonNote_note$key
  readonly: boolean
  editable?: boolean
  deletable?: boolean
  style?: {
    container?: React.CSSProperties
    note?: React.CSSProperties
    createdBy?: React.CSSProperties
  }
  timeShown?: boolean
}

const PersonNote = (props: NoteProps) => {
  const { user_accounts } = useFragment<PersonNote_viewer$key>(
    graphql`
      fragment PersonNote_viewer on users {
        id
        user_accounts {
          id
          user {
            id
            first_name
            last_name
            email
          }
        }
      }
    `,
    useHasuraContext(),
  )

  const {
    id,
    note,
    created_at,
    updated_at,
    user_id,
    created_by,
    creator_email,
  } = useFragment(
    graphql`
      fragment PersonNote_note on people_notes {
        id
        note
        created_at
        updated_at
        user_id
        created_by
        creator_email
      }
    `,
    props.note,
  )

  const users = keyBy(
    user_accounts.map((ua) => ua.user),
    "id",
  )
  const user = users[user_id]

  const { readonly, editable, deletable, style, timeShown } = props

  const updateNote = async (newNote: string) => {
    await personNoteUpdateRelay({
      noteId: id,
      note: newNote,
    })
  }

  const deleteNote = async () => {
    await personNoteDeleteRelay({ id })
    track("Person Note Deleted")
  }

  const userName = user
    ? formatName(user.first_name, user.last_name)
    : created_by || "Deleted user"
  const createdBy = creator_email ? `${userName} (${creator_email})` : userName

  return (
    <Note
      noteId={id}
      note={note}
      createdBy={createdBy}
      createdDate={created_at}
      modifiedDate={updated_at}
      onUpdate={updateNote}
      onDelete={deleteNote}
      readonly={readonly}
      editable={editable}
      deletable={deletable}
      isUserDeleted={!user}
      style={style}
      timeShown={timeShown}
    />
  )
}

export default PersonNote
