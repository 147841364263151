/* eslint-disable @typescript-eslint/no-use-before-define */
import { MenuDivider } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { Fragment, useMemo } from "react"

import styles from "./AvailableFilterList.module.css"

import { DropdownHeading } from "~/common/Dropdown"
import MenuItem from "~/common/MenuItem"

import { EntitlementsContextData } from "~/Entitlements/EntitlementsContext"
import { features } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementQuantity,
  useEntitlementSwitch,
  useEntitlements,
  useIsInTrial,
} from "~/Entitlements/useEntitlements"
import { checkSwitchOrQuantityAsBoolean } from "~/Entitlements/utils"

import PaidFeatureMenuItemContainer from "../PaidFeatureMenuItemContainer"

import type { Filter, SuperSearchConfig } from "./types"

type FilterConfig = SuperSearchConfig["availableFilters"][number]

/**
 * A short helper function that splits up menu items into two arrays depending on
 * a filter's entitlement status.
 */
const splitEntitledOptions = (
  entitlementsEnabled: ReturnType<typeof useFeature>,
  entitlements: EntitlementsContextData["entitlements"],
  isInTrial: boolean,
  filterConfigs?: FilterConfig[],
) => {
  const nonHiddenFilters = filterConfigs
    ? filterConfigs.filter((f) => !f.hidden)
    : []

  let entitledFilters: FilterConfig[] = []
  let unentitledFilters: FilterConfig[] = []

  if (!entitlementsEnabled || entitlements === null || !entitlements?.length) {
    // No need to check for entitlements if the feature is not enabled or the account
    // has none
    entitledFilters = nonHiddenFilters
  } else {
    // Split depending on whether `checkSwitchOrQuantityAsBoolean` for the
    // filter's `featureEntitlementId` returns true or false
    const [entitled, unentitled] = nonHiddenFilters.reduce(
      ([e, u], filterConfig) => {
        const isAvailable = checkSwitchOrQuantityAsBoolean(
          entitlements,
          filterConfig.featureEntitlementId,
        )

        // If the user is in a trial then we put all filters with an entitlement
        // id under "unentitled" to make sure they get rendered in a different
        // spot
        if ((isAvailable && !isInTrial) || !filterConfig.featureEntitlementId) {
          e.push(filterConfig)
        } else {
          u.push(filterConfig)
        }

        return [e, u]
      },
      [[], []],
    )
    entitledFilters = entitled
    unentitledFilters = unentitled
  }

  return [entitledFilters, unentitledFilters]
}

type AvailableFilterMenuItemProps = {
  entitledFilters: FilterConfig[]
  unentitledFilters: FilterConfig[]
  onClick: (filter: Filter) => void
}

const AvailableFilterMenuItems = (props: AvailableFilterMenuItemProps) => {
  const { entitledFilters, unentitledFilters, onClick } = props

  return (
    <>
      {entitledFilters.map((filterConfig, index) => (
        <Fragment key={index}>
          {filterConfig.hasDivider && <MenuDivider />}
          <AvailableFilterSubList config={filterConfig} onClick={onClick} />
        </Fragment>
      ))}
      {!!unentitledFilters.length && (
        <PaidFeatureMenuItemContainer
          customStyles={{ marginBottom: "-5px" }}
          roundedButton
        >
          {unentitledFilters.map((filterConfig, index) => (
            <Fragment key={index}>
              {filterConfig.hasDivider && <MenuDivider />}
              <AvailableFilterSubList config={filterConfig} onClick={onClick} />
            </Fragment>
          ))}
        </PaidFeatureMenuItemContainer>
      )}
    </>
  )
}

type AvailableFilterSubListProps = {
  config: FilterConfig
  onClick: (filter: Filter) => void
  disabled?: boolean
}

export const AvailableFilterSubList = (props: AvailableFilterSubListProps) => {
  const { config, onClick } = props
  const { name, icon, filter, submenuFilters, featureEntitlementId } = config
  const handleClick = () => {
    // important: the filter must be cloned before adding it to the set
    // this allows us to identify the filter, even if there are multiple
    // filters with exactly the same type and options
    onClick({ ...filter })
  }

  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitlements = useEntitlements()
  const inInTrial = useIsInTrial()
  const [entitledFilters, unentitledFilters] = useMemo(
    () =>
      splitEntitledOptions(
        entitlementsEnabled,
        entitlements,
        inInTrial,
        submenuFilters,
      ),
    [entitlements, entitlementsEnabled, inInTrial, submenuFilters],
  )
  const hasFeatureSwitch = useEntitlementSwitch(featureEntitlementId)
  const hasFeatureQuantity = useEntitlementQuantity(featureEntitlementId)
  const feature = features[featureEntitlementId]

  const isEntitledToFeature =
    hasFeatureSwitch || hasFeatureQuantity > 0 || !feature

  return (
    <MenuItem
      text={name}
      icon={icon ? <div className={styles.icon}>{icon}</div> : null}
      onClick={filter ? handleClick : undefined}
      disabled={!isEntitledToFeature}
      data-test={`SuperSearch_AvailableFilterListItem_${name}`}
      popoverProps={{ hoverCloseDelay: 300 }}
    >
      {submenuFilters && (
        <AvailableFilterMenuItems
          entitledFilters={entitledFilters}
          unentitledFilters={unentitledFilters}
          onClick={onClick}
        />
      )}
    </MenuItem>
  )
}

type AvailableFilterListProps = {
  config: SuperSearchConfig
  onClick: (filter: Filter) => void
}

const AvailableFilterList = (props: AvailableFilterListProps) => {
  const { config, onClick } = props
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitlements = useEntitlements()
  const inInTrial = useIsInTrial()
  const [entitledFilters, unentitledFilters] = splitEntitledOptions(
    entitlementsEnabled,
    entitlements,
    inInTrial,
    config.availableFilters,
  )

  return (
    <>
      <DropdownHeading>{config.availableFiltersTitle}</DropdownHeading>
      <AvailableFilterMenuItems
        entitledFilters={entitledFilters}
        unentitledFilters={unentitledFilters}
        onClick={onClick}
      />
    </>
  )
}

export default AvailableFilterList
