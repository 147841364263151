import React from "react"
import { useDispatch } from "react-redux"

import { CreateTemplateFromProjectForm_project$key } from "../ProjectTemplateForm/__generated__/CreateTemplateFromProjectForm_project.graphql"
import { CreateProjectFromTemplateForm_project$key } from "./__generated__/CreateProjectFromTemplateForm_project.graphql"

import { ProjectType } from "~/helpers/group-helpers"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"

import { SET_PROJECT_TEMPLATE_BAR_COLLAPSED } from "~/Planner/Planner.actions"
import {
  filteredByProject,
  searchChanged,
} from "~/Planner/reducer2/projectFilterSlice"
import { showAllMembersInProject } from "~/Planner/reducer2/projectMembersViewSlice"
import { enableProject } from "~/Planner/reducer2/scenarioPlanningSlice"
import { addAdditionalPlannerIds } from "~/Planner/reducer2/viewsSlice"
import { showToast } from "~/containers/ToasterContainer"
import CreateProjectTemplateForm from "~/forms/ProjectTemplateForm/CreateProjectTemplateForm"
import { useAppSelector } from "~/hooks/redux"

import { ProjectReturn } from "../CreateProjectForm/CreateProjectForm"
import CreateTemplateFromProjectForm from "../ProjectTemplateForm/CreateTemplateFromProjectForm"

import CreateProjectFromTemplate from "./CreateProjectFromTemplate"
import CreateProjectWizard from "./CreateProjectWizard"

type Props = {
  closeDialog: () => void
  projectType: ProjectType
  createProjectFromTemplateQuery?: CreateProjectFromTemplateForm_project$key
  createTemplateFromProjectQuery?: CreateTemplateFromProjectForm_project$key
  defaultSelectedTabId?: "single" | "bulk" | "import"
}

const CreateProjectFormPlanner = (props: Props) => {
  const {
    closeDialog,
    projectType,
    createProjectFromTemplateQuery,
    createTemplateFromProjectQuery,
    defaultSelectedTabId,
  } = props

  const currentViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )
  const dispatch = useDispatch()

  const filterToNewTemplate = (template: ProjectReturn) => {
    dispatch(changeActivePage("projects"))
    dispatch({
      type: SET_PROJECT_TEMPLATE_BAR_COLLAPSED,
      payload: false,
    })
    dispatch(searchChanged(template.name))
  }

  const filterToNewProject = (project: ProjectReturn) => {
    dispatch(filteredByProject(project.id))
    dispatch(changeActivePage("projects"))
  }

  const onSuccess = (newlyCreatedProject: ProjectReturn) => {
    const { name, confirmed } = newlyCreatedProject

    showToast({
      type: "success",
      message: `New ${projectType} created`,
      description: name,
      actions: [
        {
          onClick: () =>
            projectType === "project"
              ? filterToNewProject(newlyCreatedProject)
              : filterToNewTemplate(newlyCreatedProject),
        },
      ],
    })
    dispatch(showAllMembersInProject(newlyCreatedProject.id))
    dispatch(
      addAdditionalPlannerIds({
        viewId: currentViewId,
        projectIds: [newlyCreatedProject.id],
      }),
    )

    if (!confirmed) {
      dispatch(enableProject(newlyCreatedProject.id))
    }
  }

  if (projectType === "project template") {
    if (createTemplateFromProjectQuery) {
      return (
        <CreateTemplateFromProjectForm
          createTemplateFromProjectQuery={createTemplateFromProjectQuery}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
        />
      )
    }
    return (
      <CreateProjectTemplateForm
        onSuccess={onSuccess}
        closeDialog={closeDialog}
      />
    )
  }

  if (createProjectFromTemplateQuery) {
    return (
      <CreateProjectFromTemplate
        templateQuery={createProjectFromTemplateQuery}
        closeDialog={closeDialog}
        onSuccess={onSuccess}
      />
    )
  }
  return (
    <CreateProjectWizard
      defaultSelectedTabId={defaultSelectedTabId}
      onSuccess={onSuccess}
      onClose={closeDialog}
    />
  )
}

export default CreateProjectFormPlanner
