import { MenuDivider, Switch, Tooltip } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"
import { match } from "ts-pattern"

import styles from "./ViewControls.module.css"

import { track } from "~/helpers/analytics"

import {
  SummaryUnit,
  setShowWeekly,
  setSummaryUnit,
} from "~/Planner/reducer2/peopleSummarySlice"
import {
  EffortDisplayUnit,
  changedEffortDisplayUnit,
} from "~/display_units/effortDisplayUnitSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import { DropdownHeading } from "../Dropdown"
import MenuItem from "../MenuItem"

import ShowTotalEffortToggle from "./ShowTotalEffortToggle"

type SummaryOption = {
  readonly value: SummaryUnit
  readonly label: string
}

type EffortOption = {
  readonly value: EffortDisplayUnit
  readonly label: string
}

type Props = {
  hideSummaryControl: boolean
}

type UnifiedEffortMenuProps = {
  summaryUnitOptions: readonly SummaryOption[]
  selectedSummaryUnitOption: SummaryOption
  handleUnitChange: (value: SummaryUnit) => void
  summaryUnit: SummaryUnit
}

const UnifiedEffortMenu = ({
  summaryUnitOptions,
  selectedSummaryUnitOption,
  handleUnitChange,
  summaryUnit,
}: UnifiedEffortMenuProps) => {
  return (
    <MenuItem
      text={selectedSummaryUnitOption.label}
      className={styles.menuItem}
    >
      {summaryUnitOptions.map((option) => (
        <MenuItem
          key={option.value}
          text={option.label}
          onClick={() => {
            handleUnitChange(option.value)
          }}
          shouldDismissPopover={false}
          active={option.value === summaryUnit}
        />
      ))}
    </MenuItem>
  )
}

type SummaryUnitMenuProps = {
  summaryUnitOptions: readonly SummaryOption[]
  selectedSummaryUnitOption: SummaryOption
  handleSummaryUnitChanged: (value: SummaryUnit) => void
  summaryUnit: SummaryUnit
}

const SummaryUnitMenu = ({
  summaryUnitOptions,
  selectedSummaryUnitOption,
  handleSummaryUnitChanged,
  summaryUnit,
}: SummaryUnitMenuProps) => {
  return (
    <div>
      <h3 className={styles.menuItemHeading}>Unit on Summary</h3>
      <MenuItem
        text={selectedSummaryUnitOption.label}
        className={styles.menuItem}
      >
        {summaryUnitOptions.map((option) => (
          <MenuItem
            key={option.value}
            text={option.label}
            onClick={() => {
              handleSummaryUnitChanged(option.value)
            }}
            shouldDismissPopover={false}
            active={option.value === summaryUnit}
          />
        ))}
      </MenuItem>
    </div>
  )
}

type EffortUnitMenuProps = {
  effortOptions: readonly EffortOption[]
  selectedEffortOption: EffortOption
  handleEffortDisplayUnitChanged: (value: EffortDisplayUnit) => void
  effortDisplayUnit: EffortDisplayUnit
}

const EffortUnitMenu = ({
  effortOptions,
  selectedEffortOption,
  handleEffortDisplayUnitChanged,
  effortDisplayUnit,
}: EffortUnitMenuProps) => {
  return (
    <div>
      <h3 className={styles.menuItemHeading}>Unit on Assignments</h3>
      <MenuItem text={selectedEffortOption.label} className={styles.menuItem}>
        {effortOptions.map((option) => (
          <MenuItem
            key={option.value}
            text={option.label}
            onClick={() => handleEffortDisplayUnitChanged(option.value)}
            shouldDismissPopover={false}
            active={option.value === effortDisplayUnit}
          />
        ))}
      </MenuItem>
    </div>
  )
}

const PeopleSummaryUnitControl = (props: Props) => {
  const dispatch = useDispatch()
  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const { summaryUnit, showWeekly } = useAppSelector(
    (state) => state.plannerV2.peopleSummary,
  )
  const { hideSummaryControl } = props
  const showSummaryControl = !hideSummaryControl

  // This can come from app selector in future. But currently is always off
  // Due to UX not being finished
  const isSummaryEffortUnified = false

  // Fix up invalid local state stored before disallowing these combinations
  match({ summaryUnit, showWeekly })
    .with({ summaryUnit: "hoursPerWeek", showWeekly: false }, () => {
      dispatch(setShowWeekly(true))
      setSetting("peopleSummaryWeekly", true)
    })
    .with({ summaryUnit: "hoursPerDay", showWeekly: true }, () => {
      dispatch(setShowWeekly(false))
      setSetting("peopleSummaryWeekly", false)
    })

  const summaryUnitOptions: readonly SummaryOption[] = [
    {
      value: "hoursPerDay" as const,
      label: "Available Hours per day",
    },
    {
      value: "hoursPerWeek" as const,
      label: "Available Hours per week",
    },
    {
      value: "fullTimeEquivalent" as const,
      label: "Available FTE",
    },
    {
      value: "capacityPercentage" as const,
      label: "Utilization %",
    },
  ]

  const effortOptions: readonly EffortOption[] = [
    { value: "hoursPerDay" as const, label: "Hours per day" },
    { value: "hoursPerWeek" as const, label: "Hours per week" },
    {
      value: "fullTimeEquivalent" as const,
      label: "FTE",
    },
    { value: "capacityPercentage" as const, label: "Capacity %" },
  ] as const

  const selectedSummaryUnitOption =
    summaryUnitOptions.find((v) => v.value === summaryUnit) ??
    summaryUnitOptions[1]

  const selectedEffortOption = effortOptions.find(
    (v) => v.value === effortDisplayUnit,
  )

  const handleSummaryUnitChanged = (value: SummaryUnit) => {
    track("Summary Unit Changed", { unit: value })

    dispatch(setSummaryUnit(value))
    setSetting("peopleSummaryUnit", value)

    // Enforce weekly or daily views since some of the calculations don't make sense otherwise
    if (value === "hoursPerDay") {
      dispatch(setShowWeekly(false))
      setSetting("peopleSummaryWeekly", false)
    } else if (value === "hoursPerWeek") {
      dispatch(setShowWeekly(true))
      setSetting("peopleSummaryWeekly", true)
    }
  }

  const handleEffortDisplayUnitChanged = (value: EffortDisplayUnit) => {
    track("Effort Unit Changed", { unit: value })

    dispatch(changedEffortDisplayUnit(value))
  }

  const handleUnitChange = (value: SummaryUnit) => {
    track("Effort Unit and Summary Unit Combination Changed", {
      effort: effortDisplayUnit,
      summary: value,
    })

    dispatch(setSummaryUnit(value))
    setSetting("peopleSummaryUnit", value)
    dispatch(changedEffortDisplayUnit(value))
  }

  const toggleWeekly = () => {
    // Prevent daily or weekly views since some of the calculations don't make sense otherwise
    if (summaryUnit === "hoursPerDay" || summaryUnit === "hoursPerWeek") {
      return
    }

    const newShowWeeklyValue = !showWeekly
    track("Weekly Summary Toggled", { value: newShowWeeklyValue })
    dispatch(setShowWeekly(newShowWeeklyValue))
    setSetting("peopleSummaryWeekly", newShowWeeklyValue)
  }

  const weeklyToggleTooltip = match(summaryUnit)
    .with(
      "hoursPerWeek",
      () =>
        "Weekly Summary must be turned on when 'Unit on Summary' is set to 'Available Hours per Week'",
    )
    .with(
      "hoursPerDay",
      () =>
        "Weekly Summary is not available when 'Unit on Summary' is set to 'Available Hours per Day'",
    )
    .otherwise(() => "")
  const weeklyToggleDisabled = match(summaryUnit)
    .with("hoursPerWeek", () => true)
    .with("hoursPerDay", () => true)
    .otherwise(() => false)
  const weeklyToggleComponent = (
    <>
      <MenuDivider />
      <ShowTotalEffortToggle />
      <div className={styles.toggle}>
        <Tooltip
          disabled={!weeklyToggleTooltip}
          content={weeklyToggleTooltip}
          position="bottom"
          hoverOpenDelay={100}
          usePortal={false}
        >
          <Switch
            checked={showWeekly}
            onChange={toggleWeekly}
            label="Weekly Summary"
            alignIndicator="left"
            disabled={weeklyToggleDisabled}
          />
        </Tooltip>
      </div>
    </>
  )

  return (
    <div className={styles.dropdownContainer}>
      <DropdownHeading>Display Options</DropdownHeading>
      {isSummaryEffortUnified ? (
        <UnifiedEffortMenu
          summaryUnitOptions={summaryUnitOptions}
          selectedSummaryUnitOption={selectedSummaryUnitOption}
          handleUnitChange={handleUnitChange}
          summaryUnit={summaryUnit}
        />
      ) : (
        <div className={styles.menuItemContainer}>
          {showSummaryControl && (
            <SummaryUnitMenu
              summaryUnitOptions={summaryUnitOptions}
              selectedSummaryUnitOption={selectedSummaryUnitOption}
              handleSummaryUnitChanged={handleSummaryUnitChanged}
              summaryUnit={summaryUnit}
            />
          )}
          <EffortUnitMenu
            effortOptions={effortOptions}
            selectedEffortOption={selectedEffortOption}
            handleEffortDisplayUnitChanged={handleEffortDisplayUnitChanged}
            effortDisplayUnit={effortDisplayUnit}
          />
        </div>
      )}
      {showSummaryControl && weeklyToggleComponent}
    </div>
  )
}

export default PeopleSummaryUnitControl
