/**
 * Supports undefined, because when we are dealing with the Relay Store, it is
 * possible that any of the fields can be undefined :(
 */

type Person = {
  first_name: string | undefined
  last_name: string | undefined
  email: string | undefined
}

const isMatchingPerson = (
  personA: Person | undefined,
  personB: Person | undefined,
): boolean => {
  if (!personA || !personB) {
    return false
  }

  const isSameEmail =
    typeof personA.email === "string" &&
    typeof personB.email === "string" &&
    personA.email.trim().length > 0 &&
    personA.email.toLowerCase() === personB.email.toLowerCase()

  if (isSameEmail) {
    return true
  }

  const isSameFirstAndLastName =
    typeof personA.first_name === "string" &&
    typeof personA.last_name === "string" &&
    typeof personB.first_name === "string" &&
    typeof personB.last_name === "string" &&
    (personA.first_name.trim().length > 0 ||
      personA.last_name.trim().length > 0) &&
    personA.first_name.toLowerCase() === personB.first_name.toLowerCase() &&
    personA.last_name.toLowerCase() === personB.last_name.toLowerCase()
  if (isSameFirstAndLastName) {
    return true
  }

  return false
}

export { isMatchingPerson }
