import cc from "classcat"
import React, { useEffect, useState } from "react"
import { UnmountClosed as ReactCollapse } from "react-collapse"
import isDeeplyEqual from "react-fast-compare"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectRow.module.css"

import { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"
import { ProjectRow_account$key } from "./__generated__/ProjectRow_account.graphql"
import { ProjectRow_user$key } from "./__generated__/ProjectRow_user.graphql"

import { Assignment } from "~/helpers/planner-helpers"

import { isSplitScreenMode } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import SplitRow from "~/Planner/PlannerLayout/SplitRow"
import { selectIsAllMembersShown } from "~/Planner/reducer2/projectMembersViewSlice"
import PhasesWrapper from "~/ProjectPlanner/Phases/PhasesWrapper"
import ProjectStatsSection from "~/ProjectPlanner/ProjectRow/ProjectStatsSection"
import { useAppSelector } from "~/hooks/redux"
import { getSettings, setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

import DetectedPeopleSection from "./DetectedPeopleSection"
import ProjectAddPersonRow from "./ProjectRow/ProjectAddPersonRow"
import ProjectDetailsSection from "./ProjectRow/ProjectDetailsSection"
import ProjectRowSummary from "./ProjectRowSummary"

type Props = {
  account: ProjectRow_account$key
  userId: number
  user: ProjectRow_user$key
  project: ProjectManagement_user$data["account"]["projects"][0]
  favourites: ProjectManagement_user$data["favourite_projects"]
  onlyOneResult?: boolean
  groupName: string
}

const ProjectRow = (props: Props) => {
  const { userId, project, favourites, onlyOneResult, groupName } = props

  const account = useFragment(
    graphql`
      fragment ProjectRow_account on accounts
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        id
        ...ProjectRowSummary_account
        ...ProjectAddPersonRow_account
          @arguments(
            projectsFilter: $projectsFilter
            plannerStartDate: $plannerStartDate
            peopleFilter: $peopleFilter
          )
        ...ProjectDetailsSection_account
          @arguments(
            peopleFilter: $peopleFilter
            plannerStartDate: $plannerStartDate
          )
        ...ProjectGroupRow_account @arguments(peopleFilter: $peopleFilter)
        ...DetectedPeopleSection_account @arguments(peopleFilter: $peopleFilter)
      }
    `,
    props.account,
  )

  const user = useFragment(
    graphql`
      fragment ProjectRow_user on users {
        id
        ...ProjectDetailsSection_user
        ...DetectedPeopleSection_user
      }
    `,
    props.user,
  )

  const { can, subject } = usePermissions()
  const canCreatePerson = can("create", subject("Person"))

  const showingAllMembers = useAppSelector(
    (state) =>
      selectIsAllMembersShown(state.plannerV2.projectMembersView, project.id),
    isDeeplyEqual,
  )

  const localStorageExpanded = getSettings().plannerProjectsExpanded?.some(
    (p) => p.id === project.id && p.groupName === groupName,
  )
  const [projectExpanded, setProjectExpanded] = useState(localStorageExpanded)

  const alwaysShowProjectPhases: boolean = useSelector(
    (state: ReduxState) => state.planner.showProjectPhases,
  )

  const saveToLocalStorage = (expanded: boolean) => {
    const plannerProjectsExpanded = getSettings()?.plannerProjectsExpanded || []
    if (expanded) {
      // add expanded row
      const newPlannerProjectExpanded = [
        ...plannerProjectsExpanded,
        { id: project.id, groupName },
      ]
      setSetting("plannerProjectsExpanded", newPlannerProjectExpanded)
    } else {
      // remove expanded row
      const newPlannerProjectExpanded = plannerProjectsExpanded.filter(
        (row) => row.id !== project.id,
      )
      setSetting("plannerProjectsExpanded", newPlannerProjectExpanded)
    }
  }

  const handleToggleRow = () => {
    setProjectExpanded(!projectExpanded)
    saveToLocalStorage(!projectExpanded)
  }

  useEffect(() => {
    if (onlyOneResult) {
      setProjectExpanded(true)
    }
  }, [onlyOneResult])

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const tentativeDisabled =
    !enabledTentativeProjects.includes(project.id) && !project.confirmed

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  const multiSelectItem = useAppSelector(
    (state) => state.multiSelect.items[0],
  ) as Assignment
  const disableRow =
    tentativeDisabled ||
    (isSplitScreenMode(modeAction) &&
      multiSelectItem &&
      multiSelectItem?.project_id !== project.id)
  const allowInteraction = tentativeDisabled

  return (
    <div data-test={`project-row-${project.name}`}>
      <div className="sticky-row-wrapper">
        <ProjectRowSummary
          userId={userId}
          project={project}
          account={account}
          projectExpanded={projectExpanded}
          handleToggleRow={handleToggleRow}
          favourites={favourites}
        />
        {alwaysShowProjectPhases && (
          <PhasesWrapper project={project} isCollapsed={!projectExpanded} />
        )}
      </div>
      <ReactCollapse
        isOpened={projectExpanded}
        theme={{
          collapse: cc([
            styles.collapse,
            {
              [styles.disabled]: disableRow,
              [styles.allowInteraction]: allowInteraction,
            },
          ]),
        }}
      >
        {!alwaysShowProjectPhases && <PhasesWrapper project={project} />}
        <ProjectStatsSection projectId={project.id} />
        <ProjectDetailsSection
          account={account}
          user={user}
          projectId={project.id}
          project={project}
        />
        {canCreatePerson && showingAllMembers && (
          <DetectedPeopleSection
            account={account}
            project={project}
            user={user}
          />
        )}
        <ProjectAddPersonRow account={account} project={project} />
      </ReactCollapse>
      {projectExpanded && <SplitRow />}
    </div>
  )
}

export default ProjectRow
