import * as React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./SuggestedPerson.module.css"

import { SuggestedPerson_person$key } from "./__generated__/SuggestedPerson_person.graphql"

import { buildImageUrl } from "../../helpers/image"
import { formatName, formatNameAsInitials } from "../../helpers/person"

import Avatar from "../../common/Avatar"

export function SuggestedPerson(props: { person: SuggestedPerson_person$key }) {
  const person = useFragment(
    graphql`
      fragment SuggestedPerson_person on people {
        first_name
        last_name
        image_key
      }
    `,
    props.person,
  )
  const name = formatName(person.first_name, person.last_name)
  const initials = formatNameAsInitials(person.first_name, person.last_name)

  return (
    <div className={styles.suggestedPerson}>
      <Avatar
        avatar={person.image_key ? buildImageUrl(person.image_key) : undefined}
        initials={initials}
        size={24}
      />
      <p className={styles.suggestedPerson__name}>{name}</p>
    </div>
  )
}
