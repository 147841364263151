import { useEffect, useRef, useState } from "react"

export default function useDetectOutsideClick(initState: boolean) {
  const triggerRef = useRef<HTMLDivElement>(null) // optional
  const nodeRef = useRef<HTMLDivElement>(null) // required

  const [show, setShow] = useState(initState)
  const handleClickOutside = (event) => {
    //if click is on trigger, toggle the element
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      return setShow(!show)
    }

    //if element is open and click is outside element, close it
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setShow(false)
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })
  return {
    triggerRef,
    nodeRef,
    show,
    setShow,
  }
}
