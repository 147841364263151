import { Tooltip } from "@blueprintjs/core"
import React, { useMemo, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./ProjectGroupMembersDisplay.module.css"

import { ProjectGroupMembersDisplay_account$key } from "./__generated__/ProjectGroupMembersDisplay_account.graphql"
import { ProjectGroupMembersDisplay_project$key } from "./__generated__/ProjectGroupMembersDisplay_project.graphql"

import { dashify } from "~/helpers/general-helpers"
import { formatName } from "~/helpers/person"
import { pluralize } from "~/helpers/plural"
import {
  Member,
  MemberWithName,
  countPeoplePlaceholderMembers,
  getMemberKey,
  separatePeoplePlaceholderMembers,
} from "~/helpers/project-member-helpers"
import { sortPeopleByPersonName } from "~/helpers/sorting-helpers"

import { IconThreeDot } from "~/common/IconThreeDot"
import { Popover2 } from "~/common/Popover2"
import { EmptyCircleIcon, FaceIcon, Plus } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"
import ExternalPersonPlannerMenu from "~/Planner/PlannerLayout/ExternalPersonPlannerMenu"
import { selectIsAllMembersShown } from "~/Planner/reducer2/projectMembersViewSlice"
import ProjectGroupTypeTooltip from "~/ProjectPlanner/ProjectRow/ProjectGroupTypeTooltip"
import { useAppSelector } from "~/hooks/redux"

import AddPersonToProject from "../AddPersonToProject/AddPersonToProject"
import ProjectStatsComponent from "../ProjectStatsComponent"

type Props = {
  account: ProjectGroupMembersDisplay_account$key
  project: ProjectGroupMembersDisplay_project$key
  groupName: string
  groupId?: number
  visibleMembers: ReadonlyArray<Member>
  groupMembers: ReadonlyArray<MemberWithName>
  showDotsMenu?: boolean
}

type IconProps = {
  type: "people" | "placeholder"
  count: number
  members: ReadonlyArray<MemberWithName>
  showingAllMembers: boolean
}

const MembershipIcon = (props: IconProps) => {
  const { type, count, members, showingAllMembers } = props
  const totalMembersCount = members.length

  if (totalMembersCount === 0) {
    return null
  }

  const countText = showingAllMembers
    ? `${totalMembersCount} / ${totalMembersCount}`
    : `${count} / ${totalMembersCount}`

  const tooltipHeading = match(type)
    .with(
      "people",
      () => `Project ${pluralize(totalMembersCount, "Team Member")}`,
    )
    .with("placeholder", () => pluralize(totalMembersCount, "Placeholder"))
    .exhaustive()

  const TooltipContent = () => {
    const uniqueMembersWithCount = (pm: ReadonlyArray<MemberWithName>) => {
      const uniqueMember = new Map<
        string,
        { member: MemberWithName; count: number }
      >()

      for (const member of pm) {
        const memberKey = `${member.person.first_name}-${member.person.last_name}`
        const existingEntry = uniqueMember.get(memberKey)

        if (existingEntry) {
          existingEntry.count++
        } else {
          uniqueMember.set(memberKey, { member, count: 1 })
        }
      }

      return Array.from(uniqueMember.values())
    }

    const uniqueMembers = uniqueMembersWithCount(Object.values(members))

    const sortedMembers = sortPeopleByPersonName(
      Object.values(uniqueMembers),
      (item) => item.member.person,
    )

    const groupByType = useAppSelector(
      (state) => state.plannerV2.plannerStats.groupByType,
    )

    const nameOfMemberAttribute =
      groupByType === "workstreams" ? "Roles" : "Workstreams"

    return (
      <div className={styles.popoverContainer}>
        <div className={styles.tooltipHeading}>
          {tooltipHeading.toUpperCase()}
        </div>
        {sortedMembers.map((m) => (
          <div key={getMemberKey(m.member)} className={styles.tooltipText}>
            {`${formatName(
              m.member.person.first_name,
              m.member.person.last_name,
            )} ${m.count > 1 ? `(${m.count} ${nameOfMemberAttribute})` : ""}`}
          </div>
        ))}
      </div>
    )
  }

  const icon = match(type)
    .with("people", () => <FaceIcon size={12} color="var(--shadow)" />)
    .with("placeholder", () => <EmptyCircleIcon />)
    .exhaustive()

  return (
    <Tooltip
      interactionKind="hover"
      lazy
      hoverOpenDelay={300}
      hoverCloseDelay={100}
      placement="top"
      content={<TooltipContent />}
      popoverClassName={styles.tooltipPopover}
    >
      <div className={styles.memberCount}>
        {icon}
        {countText}
      </div>
    </Tooltip>
  )
}

const ProjectGroupMembersDisplay = (props: Props) => {
  const {
    groupName,
    groupId,
    visibleMembers,
    groupMembers,
    showDotsMenu = false,
  } = props
  const { can, subject } = usePermissions()
  const [showAddPersonModal, setShowAddPersonModal] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const account = useFragment(
    graphql`
      fragment ProjectGroupMembersDisplay_account on accounts
      @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
        id
        ...AddPersonToProject_account
          @arguments(
            projectsFilter: $projectsFilter
            plannerStartDate: $plannerStartDate
          )
        people(where: $peopleFilter) {
          id
          email
        }
      }
    `,
    props.account,
  )
  const project = useFragment(
    graphql`
      fragment ProjectGroupMembersDisplay_project on projects {
        id
        name
        is_template
        ext_project_links(where: { active: { _eq: true } }) {
          project_id
          ext_project_id
        }
        project_workstreams {
          workstream_id
        }
      }
    `,
    props.project,
  )

  const groupedMembers = separatePeoplePlaceholderMembers(groupMembers)
  const showingAllMembers = useAppSelector(
    (state) =>
      selectIsAllMembersShown(state.plannerV2.projectMembersView, project.id),
    isDeeplyEqual,
  )
  const groupByType = useAppSelector(
    (state) => state.plannerV2.plannerStats.groupByType,
  )

  const visibleMembersCount = countPeoplePlaceholderMembers(visibleMembers)
  const projectHasWorkstreams = Boolean(project.project_workstreams?.length)

  const shownPeopleCount = visibleMembersCount.people
  const shownPlaceholdersCount = visibleMembersCount.placeholders

  const extProjectId = project?.ext_project_links?.[0]?.ext_project_id
  const Menu =
    showDotsMenu && typeof extProjectId === "number" ? (
      <ExternalPersonPlannerMenu
        projectId={project.id}
        extProjectId={extProjectId}
        closeMenu={() => setShowMenu(false)}
        setDialogIsOpen={setDialogIsOpen}
      />
    ) : null

  // NOTE: Project memberships isn't perfectly encapsulated by the "ProjectMember" subject, this subject
  // exludes project templates and placeholders from consideration so we have to reach out to those subjects to determine
  // if the user can add a membership
  const canAddProjectTemplateMembership = can(
    "edit",
    subject("ProjectTemplate"),
  )

  const canCreatePlaceholder = can(
    "create",
    subject("Placeholder", {
      project: { id: project.id, isTemplate: project.is_template },
    }),
  )

  // Can we create *some* project member for *this* project
  const canCreateSomeProjectMember = useMemo(
    () =>
      account.people.some((p) =>
        can(
          "create",
          subject("ProjectMember", {
            person: p,
            project: { id: project.id, isTemplate: project.is_template },
          }),
        ),
      ),
    [account.people, project], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Can we add a person or placeholder to a project or project template?
  const canAddToProject =
    (groupByType === "roles" ||
      (groupByType === "workstreams" && projectHasWorkstreams)) &&
    typeof groupId !== "undefined" &&
    (canCreatePlaceholder ||
      (project.is_template
        ? canAddProjectTemplateMembership
        : canCreateSomeProjectMember))

  const onMenuOpen = () => {
    setShowMenu(true)
  }

  const onMenuClose = () => {
    if (!dialogIsOpen) {
      setShowMenu(false)
    }
  }

  const onMenuClick = (event: React.MouseEvent) => {
    if (!dialogIsOpen) {
      event.stopPropagation()
    }

    if (!Menu) {
      return
    }
  }

  const tooltipText = match(groupByType)
    .with("workstreams", () =>
      groupName === "No workstream"
        ? `Add person or placeholder with unassigned workstream`
        : `Add person or placeholder to ${groupName} workstream`,
    )
    .with("roles", () => `Add a ${groupName}`)
    .exhaustive()

  return (
    <>
      <div
        className={styles.memberInfo}
        data-test={`GroupMembersDisplay-${groupName}`}
      >
        <div className={styles.contentWrapper}>
          {canAddToProject && (
            <Tooltip
              content={tooltipText}
              position="right"
              hoverOpenDelay={500}
            >
              <button
                data-test={`add-${dashify(groupName)}-to-${dashify(project.name)}`}
                onClick={() => setShowAddPersonModal(true)}
                className={styles.addPersonButton}
              >
                <Plus size={8} />
              </button>
            </Tooltip>
          )}
          <ProjectGroupTypeTooltip
            groupNameType={groupByType}
            groupName={groupName}
            fontSize={11}
            className={styles.groupNameTooltip}
          />
          <MembershipIcon
            type="placeholder"
            count={shownPlaceholdersCount}
            members={groupedMembers.placeholders}
            showingAllMembers={showingAllMembers}
          />
          <MembershipIcon
            type="people"
            count={shownPeopleCount}
            members={groupedMembers.people}
            showingAllMembers={showingAllMembers}
          />
        </div>
        {showDotsMenu ? (
          <div onClick={onMenuClick}>
            <Popover2
              isOpen={showMenu}
              content={Menu}
              onClose={onMenuClose}
              placement="right-start"
              minimal
            >
              <IconThreeDot
                active={showMenu}
                onClick={onMenuOpen}
                color="var(--smoke)"
                className={styles.moreIcon}
              />
            </Popover2>
          </div>
        ) : (
          <ProjectStatsComponent
            type="BillableHoursForRole"
            projectId={project.id}
            groupId={groupId}
          />
        )}
      </div>
      {showAddPersonModal && (
        <AddPersonToProject
          account={account}
          projectId={project.id}
          hideModal={() => setShowAddPersonModal(false)}
          defaultFilters={
            groupByType === "workstreams"
              ? null
              : {
                  type: "person_role_id",
                  options: { list: [groupId] },
                }
          }
          defaultRolePlaceholder={groupId}
          defaultWorkstream={groupId}
        />
      )}
    </>
  )
}

export default ProjectGroupMembersDisplay
