import { graphql } from "react-relay"

import {
  OtherCostsBulkCreateMutation,
  OtherCostsBulkCreateMutation$variables,
} from "./__generated__/OtherCostsBulkCreateMutation.graphql"
import {
  OtherCostsBulkUpdateMutation,
  OtherCostsBulkUpdateMutation$variables,
} from "./__generated__/OtherCostsBulkUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const otherCostsBulkCreateMutation = graphql`
  mutation OtherCostsBulkCreateMutation(
    $input: [OtherCostBulkCreateInput!]!
    $project_id: Int!
  ) {
    action_other_cost_bulk_create(input: $input, project_id: $project_id) {
      project {
        id
        other_costs(order_by: { date: asc }) {
          id
          name
          date: date_runn
          cost
          charge
        }
      }
      account {
        id
      }
    }
  }
`

type otherCostsBulkCreateRelayOptions = {
  variables: OtherCostsBulkCreateMutation$variables
}

export const otherCostsBulkCreateRelay = async (
  options: otherCostsBulkCreateRelayOptions,
) => {
  const { variables } = options
  const data = await commitMutationPromise<OtherCostsBulkCreateMutation>(
    environment,
    {
      mutation: otherCostsBulkCreateMutation,
      variables,
    },
  )
  return data.action_other_cost_bulk_create.project
}

const otherCostsBulkUpdateMutation = graphql`
  mutation OtherCostsBulkUpdateMutation(
    $input: [OtherCostBulkUpdateInput!]!
    $project_id: Int!
    $todaysDate: date!
  ) {
    action_other_cost_bulk_update(input: $input, project_id: $project_id) {
      project {
        id
        other_costs(order_by: { date: asc }) {
          id
          name
          date: date_runn
          cost
          charge
        }
        ...ProjectDashboardHeader_project @arguments(todaysDate: $todaysDate)
        ...ProjectDashboardBody_project
        ...ProjectCostsInsight_project
      }
      account {
        id
      }
    }
  }
`

type otherCostsBulkUpdateOptions = {
  variables: OtherCostsBulkUpdateMutation$variables
}

export const otherCostsBulkUpdateRelay = async (
  options: otherCostsBulkUpdateOptions,
) => {
  const { variables } = options

  const data = await commitMutationPromise<OtherCostsBulkUpdateMutation>(
    environment,
    {
      mutation: otherCostsBulkUpdateMutation,
      variables,
    },
  )
  return data.action_other_cost_bulk_update.project
}
