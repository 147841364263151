import { useEffect, useMemo, useRef } from "react"
import isEqual from "react-fast-compare"

export const useEffectDeepCompare = (
  callback: Parameters<typeof useEffect>[0],
  dependencies: Parameters<typeof useEffect>[1],
): void => {
  const dependencyRef = useRef(dependencies)
  const changeCountRef = useRef(0)

  if (!isEqual(dependencies, dependencyRef.current)) {
    dependencyRef.current = dependencies
    changeCountRef.current += 1
  }

  const memoizedDependencies = useMemo(
    () => dependencyRef.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeCountRef.current],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, memoizedDependencies)
}
