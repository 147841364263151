import { graphql, useFragment } from "react-relay"

import type { useTeams_account$key } from "./__generated__/useTeams_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useTeams_account on accounts {
    teams {
      id
      name
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useTeams = (options: Options) => {
  const { user } = options

  const { teams } = useFragment<useTeams_account$key>(fragment, user.account)

  const availableOptions = teams
    .map((team) => ({
      value: team.id,
      label: team.name,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useTeams
