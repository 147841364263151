import { dateHelpers } from "@runn/calculations"
import { addYears, format, isValid, subYears } from "date-fns"
import React, { useState } from "react"

import styles from "./CustomEditorDateField.module.css"

import { CustomDateType, CustomDateValue } from "~/helpers/custom-field-helpers"

import DateInput from "~/common/DateInput"

import { CustomEditorFieldProps } from "~/CustomEditor/types"
import { showToast } from "~/containers/ToasterContainer"

type Props = CustomEditorFieldProps<CustomDateType, CustomDateValue> & {
  showWeekNumbers: boolean
}

const CustomEditorDateField = ({
  id,
  type,
  value,
  showWeekNumbers,
  onChange,
}: Props) => {
  const [localValue, setLocalValue] = useState<Props["value"]>(value)

  const handleChangeDate = (selectedDate: Date | null) => {
    if (selectedDate != null && !isValid(selectedDate)) {
      showToast({
        message: "Please enter a valid date",
        type: "error",
      })

      return
    }

    const updatedValue = {
      ...localValue,
      value: selectedDate ? format(selectedDate, "yyyy-MM-dd") : null,
      typeId: type.id,
    }

    onChange({
      type,
      value: updatedValue,
    })
    setLocalValue(updatedValue)
  }

  return (
    <DateInput
      dataTest={`${id}-date-field`}
      label={type.name}
      highlightCurrentDay
      onChange={handleChangeDate}
      minDate={subYears(new Date(), 100)}
      maxDate={addYears(new Date(), 100)}
      showWeekNumbers={showWeekNumbers}
      popoverProps={{
        className: styles.dateInput,
        position: "bottom-left",
      }}
      placeholder="Select..."
      value={value?.value ? dateHelpers.parseDatabaseDate(value.value) : null}
    />
  )
}

export default CustomEditorDateField
