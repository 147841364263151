import { graphql } from "react-relay"

import { PersonNoteCreateMutation } from "./__generated__/PersonNoteCreateMutation.graphql"
import { PersonNoteDeleteMutation } from "./__generated__/PersonNoteDeleteMutation.graphql"
import { PersonNoteUpdateMutation } from "./__generated__/PersonNoteUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const personNoteCreateMutation = graphql`
  mutation PersonNoteCreateMutation($note: String!, $personId: Int!) {
    addPersonNote(note: $note, personId: $personId) {
      personNote {
        id
        note
        created_at
        updated_at
        created_by
        creator_email
        person {
          id
          people_notes {
            id
          }
          people_notes_aggregate {
            aggregate {
              count
            }
          }
        }
        user_id
      }
    }
  }
`

export const personNoteCreateRelay = async (
  variables: PersonNoteCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<PersonNoteCreateMutation>(
    environment,
    {
      mutation: personNoteCreateMutation,
      variables,
    },
  )

  return data
}

const personNoteUpdateMutation = graphql`
  mutation PersonNoteUpdateMutation($noteId: Int!, $note: String!) {
    updatePersonNote(noteId: $noteId, note: $note) {
      personNote {
        id
        note
        updated_at
        created_by
        creator_email
        user_id
      }
    }
  }
`

export const personNoteUpdateRelay = async (
  variables: PersonNoteUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<PersonNoteUpdateMutation>(
    environment,
    {
      mutation: personNoteUpdateMutation,
      variables,
    },
  )

  return data
}

const personNoteDeleteMutation = graphql`
  mutation PersonNoteDeleteMutation($id: Int!) {
    deletePersonNote(noteId: $id) {
      person {
        id
        people_notes {
          id
        }
        people_notes_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const personNoteDeleteRelay = async (
  variables: PersonNoteDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<PersonNoteDeleteMutation>(
    environment,
    {
      mutation: personNoteDeleteMutation,
      variables,
    },
  )

  return data
}
