import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { ProjectPlannerOptions_account$key } from "~/common/PageControls/__generated__/ProjectPlannerOptions_account.graphql"

import { track } from "~/helpers/analytics"
import {
  ProjectGroupByOption,
  getProjectGroupByOptions,
} from "~/helpers/group-helpers"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import {
  SET_PROJECT_GROUP_BY_EXPANDED,
  SET_SHOW_PROJECT_PHASES,
} from "~/Planner/Planner.actions"
import {
  groupByChanged,
  selectGroupProjectBy,
  selectSortProjectBy,
  sortChanged,
} from "~/Planner/reducer2/projectSortSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import ButtonGroup from "./ButtonGroup"
import ChartsToggle from "./ChartsToggle"
import GroupControl, { DIVIDER } from "./GroupControl"
import ProjectSortControl from "./ProjectSortControl"
import ViewControls from "./ViewControls"

const ProjectPlannerOptions = (props: {
  account: ProjectPlannerOptions_account$key
}) => {
  const {
    projects: { isOverflow },
  } = useQueryLimit()
  const dispatch = useDispatch()
  const showProjectPhases = useAppSelector(
    (state) => state.planner.showProjectPhases,
  )
  const groupBy = useAppSelector((state) =>
    selectGroupProjectBy(state.plannerV2.project),
  )
  const sortBy = useAppSelector((state) =>
    selectSortProjectBy(state.plannerV2.project),
  )

  const account = useFragment(
    graphql`
      fragment ProjectPlannerOptions_account on accounts {
        id
        workstreams {
          id
        }
        project_custom_select_types: custom_select_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          model
          single_select
          options: custom_select_options {
            id
            name
          }
        }
      }
    `,
    props.account,
  )

  const onGroupByChange = (e: {
    value: ProjectGroupByOption
    label: string
  }) => {
    dispatch(groupByChanged(e.value))
    setSetting("projectPlannerGroupBy", e)

    // set all groups to closed
    dispatch({
      type: SET_PROJECT_GROUP_BY_EXPANDED,
      payload: [{ groupByFilter: e.value, expanded: [] }],
    })
    setSetting("expandedProjectGroups", [
      { groupByFilter: e.value, expanded: [] },
    ])
    setSetting("plannerProjectsExpanded", [])
    if (e.value.startsWith("custom_")) {
      track("Custom Field Groupby Changed", {
        model: "PROJECT",
        fieldName: e.label,
      })
    }
  }

  // Reset disabled project planner options to default value when in overflow state
  const resetOptions = () => {
    if (showProjectPhases) {
      dispatch({
        type: SET_SHOW_PROJECT_PHASES,
        payload: false,
      })
      setSetting("showProjectPhases", false)
    }

    if (groupBy !== "default") {
      dispatch(groupByChanged("default"))
      setSetting("projectPlannerGroupBy", null)
    }

    if (sortBy !== "projectName") {
      dispatch(sortChanged("projectName"))
      setSetting("projectPlannerSort", {
        value: "projectName",
        label: "Project Name",
      })
    }
  }

  if (isOverflow) {
    resetOptions()
  }

  const workstreamsEnabled = useFeature("workstreams")

  const groupingOptions = getProjectGroupByOptions(
    account.project_custom_select_types,
    !!workstreamsEnabled,
  )
  const customIndex = groupingOptions.findIndex((o) =>
    o.value.startsWith("custom_"),
  )
  if (customIndex > -1) {
    groupingOptions.splice(customIndex, 0, DIVIDER)
  }

  const showViewGroupByControls =
    workstreamsEnabled && Boolean(account.workstreams.length)

  return (
    <ButtonGroup>
      <ChartsToggle />
      <ViewControls
        dataTest="planner-toggles"
        showViewGroupByControls={showViewGroupByControls}
      />
      <GroupControl
        dataTest="project-group-by"
        onChange={onGroupByChange}
        selectedOption={groupBy}
        options={groupingOptions}
        disabled={isOverflow}
      />
      <ProjectSortControl disabled={isOverflow} />
    </ButtonGroup>
  )
}

export default ProjectPlannerOptions
