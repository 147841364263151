import { graphql } from "react-relay"

import { FilterSetCreateMutation } from "./__generated__/FilterSetCreateMutation.graphql"
import { FilterSetDeleteMutation as DeleteMutation } from "./__generated__/FilterSetDeleteMutation.graphql"

import { environment } from "~/store/hasura"

import {
  addLinkedRecord,
  commitMutationPromise,
  removeLinkedRecord,
} from "./helpers"
import { getLocalId } from "~/helpers/local-id"
import * as relayIds from "~/helpers/relayids"

const CREATE_MUTATION = graphql`
  mutation FilterSetCreateMutation($filterSet: user_filter_sets_insert_input!) {
    insert_user_filter_sets_one(object: $filterSet) {
      id
      type
      name
      filters
    }
  }
`

type CreateOptions = {
  filterSet: FilterSetCreateMutation["variables"]["filterSet"]
}

const createFilterSet = (options: CreateOptions) => {
  const { filterSet } = options

  return commitMutationPromise<FilterSetCreateMutation>(environment, {
    mutation: CREATE_MUTATION,
    variables: { filterSet },
    optimisticUpdater: (store) => {
      const viewer = store.getRoot().getLinkedRecord("current_user")

      const id = getLocalId()
      const relayId = relayIds.userFilterSets.encode(id)
      const record = store.create(relayId, "user_filter_set")

      record.setValue(id, "id")
      record.setValue(filterSet.type, "type")
      record.setValue(filterSet.name, "name")
      record.setValue(id, "filters", filterSet.filters)

      addLinkedRecord(viewer, "filter_sets", record)
    },
    updater: (store) => {
      const viewer = store.getRoot().getLinkedRecord("current_user")
      const userFilterSet = store.getRootField("insert_user_filter_sets_one")
      addLinkedRecord(viewer, "filter_sets", userFilterSet)
    },
  })
}

const DELETE_MUTATION = graphql`
  mutation FilterSetDeleteMutation($filterSetId: Int!) {
    delete_user_filter_sets_by_pk(id: $filterSetId) {
      id
    }
  }
`

type DeleteOptions = {
  filterSetId: number
}

const deleteFilterSet = (options: DeleteOptions) => {
  const { filterSetId } = options

  return commitMutationPromise<DeleteMutation>(environment, {
    mutation: DELETE_MUTATION,
    variables: { filterSetId },
    optimisticUpdater: (store) => {
      const viewer = store.getRoot().getLinkedRecord("current_user")
      const record = store.get(relayIds.userFilterSets.encode(filterSetId))
      removeLinkedRecord(viewer, "filter_sets", record)
    },
    updater: (store) => {
      const viewer = store.getRoot().getLinkedRecord("current_user")
      const record = store.getRootField("delete_user_filter_sets_by_pk")
      removeLinkedRecord(viewer, "filter_sets", record)
    },
  })
}

export { createFilterSet, deleteFilterSet }
