/**
 * @generated SignedSource<<b9ae9aa712956ff7e033bf170c4e0752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCustomFieldTypes_accounts$data = {
  readonly custom_checkbox_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
    readonly sort_order: number | null | undefined;
  }>;
  readonly custom_date_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
    readonly sort_order: number | null | undefined;
  }>;
  readonly custom_select_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly model: any;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly required: boolean | null | undefined;
    readonly show_in_planner: boolean;
    readonly singleSelect: boolean;
    readonly sort_order: number | null | undefined;
  }>;
  readonly custom_text_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
    readonly sort_order: number | null | undefined;
  }>;
  readonly " $fragmentType": "ProjectCustomFieldTypes_accounts";
};
export type ProjectCustomFieldTypes_accounts$key = {
  readonly " $data"?: ProjectCustomFieldTypes_accounts$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCustomFieldTypes_accounts">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PROJECT"
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_order",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCustomFieldTypes_accounts",
  "selections": [
    {
      "alias": "custom_text_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_date_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_select_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": "singleSelect",
          "args": null,
          "kind": "ScalarField",
          "name": "single_select",
          "storageKey": null
        },
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_checkbox_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "2d99cb8a2d5d18cc8c3de52a2b244a7d";

export default node;
