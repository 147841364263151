import { Button, Icon, MenuDivider, Tooltip } from "@blueprintjs/core"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ViewsDropdown.module.css"

import { ViewsDropdown_user$key } from "~/Views/Planner/__generated__/ViewsDropdown_user.graphql"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"

import { Dropdown, DropdownHeading } from "~/common/Dropdown"
import MenuItem from "~/common/MenuItem"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import TooltipEllipsis from "~/common/TooltipEllipsis"
import { CrossIcon } from "~/common/react-icons"
import { ViewState } from "~/common/views.reducer"

import { userViewUpdateRelay } from "~/mutations/User"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import { useEntitlementSwitch } from "~/Entitlements/useEntitlements"
import { usePermissions } from "~/Permissions/usePermissions"
import {
  resetTemporaryPlannerFilter,
  setTemporaryPlannerFilterToAll,
  showAllViewDeselected,
  showAllViewSelected,
} from "~/Planner/reducer2/viewsSlice"
import { useAppSelector } from "~/hooks/redux"

import ViewFormDialog from "../ViewFormDialog"

const fragment = graphql`
  fragment ViewsDropdown_user on users
  @argumentDefinitions(
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    id
    view {
      id
      name
      description
      project_filters
      people_filters
    }
    account {
      id
      views {
        id
        name
        description
        project_filters
        people_filters
      }
    }
    ...ViewFormDialog_user
      @arguments(peopleFilter: $peopleFilter, projectsFilter: $projectsFilter)
  }
`

type Props = {
  user: ViewsDropdown_user$key
  hideEdit?: boolean
  viewState?: ViewState
  handleShowAllView?: () => void
  handleChangeViewId?: (viewId: number) => void
}

const ViewsDropdown = (props: Props) => {
  const { hideEdit = false } = props

  const user = useFragment(fragment, props.user)
  const {
    account: { views: accountViews },
  } = user

  const [searchValue, setSearchValue] = useState("")
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [viewToShow, setViewToShow] = useState(null)

  const entitledToViews = useEntitlementSwitch(ChargebeeFeatures.accountViews)

  const plannerViewId = useAppSelector(
    (state) => state.plannerV2.views.currentViewId,
  )
  const plannerShowAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )

  // NOTE: viewId used as "temporary" selected view and should take priority over the user's default view
  const { viewId, showAllView } = props.viewState ?? {
    viewId: plannerViewId,
    showAllView: plannerShowAllView,
  }

  const defaultView = viewId
    ? accountViews.find((v) => v.id === viewId)
    : (user.view ?? accountViews[0])

  const { can, subject } = usePermissions()
  const canCreate = can("create", subject("View"))
  const canEdit = can("edit", subject("View"))
  const dispatch = useDispatch()

  if (!defaultView) {
    return null
  }

  const filteredViews = accountViews.filter((view) =>
    view.name.toLowerCase().includes(searchValue.trim().toLowerCase()),
  )

  const handleShowAll = async () => {
    if (props.handleShowAllView) {
      props.handleShowAllView()
      return
    }

    dispatch(showAllViewSelected())
    try {
      await userViewUpdateRelay({
        input: { viewId: null },
      })
      dispatch(setTemporaryPlannerFilterToAll())
      track("Planner View Changed - All View")
    } catch (error) {
      void reportError("Update user default view error: ", error)
    }
  }

  const handleViewChange = async (nextViewId: number) => {
    if (props.handleChangeViewId) {
      props.handleChangeViewId(nextViewId)
      return
    }

    try {
      await userViewUpdateRelay({
        input: { viewId: nextViewId },
      })
      dispatch(showAllViewDeselected(nextViewId))
      dispatch(resetTemporaryPlannerFilter())
      track("Planner View Changed - Custom View")
    } catch (error) {
      void reportError("Update user default view error: ", error)
    }
  }

  const Target = () => {
    return (
      <div
        className={styles.viewButton}
        data-component="ViewsDropdown/selectedView"
      >
        <TooltipEllipsis
          className={styles.selectedViewName}
          text={showAllView ? "All" : defaultView.name}
          placement="top"
        />
        <Button minimal icon="chevron-down" className={styles.chevronButton} />
      </div>
    )
  }

  const paidFeaturesSectionTitle = "Ready to start using Views?"
  const paidFeaturesSectionSubtitle =
    "Views are part of our Standard and Premium plans"

  return (
    <>
      <Dropdown
        className={styles.dropdownContainer}
        Target={Target}
        minWidth={250}
        maxWidth={250}
        data-component="ViewsDropdown"
        preventRenderWhenClosed={true}
      >
        <div className={styles.searchInputContainer}>
          {searchValue ? (
            <div
              className={styles.clearButton}
              onClick={() => setSearchValue("")}
            >
              <CrossIcon />
            </div>
          ) : (
            <Icon icon="search" color="var(--shadow)" />
          )}
          <input
            type="text"
            placeholder="Search"
            className={styles.searchInput}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <MenuDivider />

        {Boolean(filteredViews.length) ? (
          <div className={styles.dropdownListContainer}>
            <DropdownHeading>Views</DropdownHeading>
            {filteredViews.map((view) => {
              if (view.id === defaultView.id && !showAllView) {
                return (
                  <MenuItem
                    key={view.id}
                    className={styles.defaultView}
                    data-component="ViewsDropdown/option"
                    active
                    text={
                      <div>
                        <p>{view.name}</p>
                        {!hideEdit && (
                          <button
                            type="button"
                            className={styles.defaultViewButton}
                            onClick={() => setViewToShow(view)}
                          >
                            {canEdit ? "Edit View" : "View Details"}
                          </button>
                        )}
                      </div>
                    }
                  />
                )
              }

              return (
                <Tooltip
                  key={view.id}
                  placement="right"
                  content={view.description}
                  openOnTargetFocus={false}
                  disabled={!view.description}
                  targetTagName="div"
                >
                  <MenuItem
                    text={view.name}
                    data-component="ViewsDropdown/option"
                    onClick={() => handleViewChange(view.id)}
                  />
                </Tooltip>
              )
            })}
          </div>
        ) : (
          <p className={styles.noResultsText}>No views found</p>
        )}

        <MenuDivider />
        <MenuItem
          text="All"
          onClick={handleShowAll}
          active={showAllView}
          data-component="ViewsDropdown/option"
        />
        <MenuDivider />
        {entitledToViews && !hideEdit && canEdit && (
          <MenuItem
            text="Create New View"
            onClick={() => setShowCreateForm(true)}
            className={styles.createButton}
          />
        )}
        {!entitledToViews && (
          <PaidFeatureMenuItemContainer
            roundedButton
            title={paidFeaturesSectionTitle}
            subtitle={paidFeaturesSectionSubtitle}
          />
        )}
      </Dropdown>
      {viewToShow && (
        <ViewFormDialog
          onClose={() => setViewToShow(null)}
          user={user}
          view={viewToShow}
          readonly={!canCreate}
        />
      )}
      {showCreateForm && (
        <ViewFormDialog
          onClose={() => setShowCreateForm(false)}
          user={user}
          readonly={!canEdit}
        />
      )}
    </>
  )
}

export default ViewsDropdown
