import { dateHelpers } from "@runn/calculations"
import { differenceInBusinessDays, isWeekend, startOfWeek } from "date-fns"
import groupBy from "lodash-es/groupBy"
import merge from "lodash-es/merge"
import mem from "mem"
import serializeJavascript from "serialize-javascript"

import { SummaryUnit } from "~/Planner/reducer2/peopleSummarySlice"

import { getTimeFrames } from "./CalendarHelper"
import { dateIsAHoliday } from "./holiday-helpers"

type ItemWithDate = {
  start_date: string
  end_date: string
  minutes_per_day?: number
}

type TimeOff = ItemWithDate & { leave_type: string }

type ItemWithDateAndMinutes = {
  minutes_per_day: number
  start_date: string
  end_date: string
  rostered_days?: readonly number[]
}

type Assignment = Omit<
  ItemWithDateAndMinutes & {
    non_working_day: boolean
  },
  "rostered_days"
>

// Makes sure we dont calculate days outside of the min and max range
export const calculateStartAndEndDatesWithinRange = (
  item: ItemWithDate,
  minDate: Date,
  maxDate: Date,
) => {
  if (Number(item.end_date) < Number(dateHelpers.formatToRunnDate(minDate))) {
    return {}
  }
  if (Number(item.start_date) > Number(dateHelpers.formatToRunnDate(maxDate))) {
    return {}
  }

  const startDate =
    Number(item.start_date) < Number(dateHelpers.formatToRunnDate(minDate))
      ? minDate
      : dateHelpers.parseRunnDate(`${item.start_date}`)

  const endDate =
    Number(item.end_date) > Number(dateHelpers.formatToRunnDate(maxDate))
      ? maxDate
      : dateHelpers.parseRunnDate(`${item.end_date}`)

  return { startDate, endDate }
}

export const calculateTimeOffDays = (
  days: Array<{ formattedDate: string }>,
  timeOff: TimeOff,
  rangeStartDate: Date,
  rangeEndDate: Date,
  includeHolidays = false,
): TimeOffDaysObject => {
  const rangeStart = dateHelpers.formatToRunnDate(rangeStartDate)
  const rangeEnd = dateHelpers.formatToRunnDate(rangeEndDate)

  const timeOffDays = days.filter((day) => {
    if (day.formattedDate < rangeStart) {
      return false
    }
    if (day.formattedDate > rangeEnd) {
      return false
    }
    if (day.formattedDate < timeOff.start_date) {
      return false
    }
    if (day.formattedDate > timeOff.end_date) {
      return false
    }
    if (isWeekend(dateHelpers.parseRunnDate(day.formattedDate))) {
      return false
    }

    return true
  })
  const daysObject = timeOffDays.reduce((acc, day) => {
    acc[day.formattedDate] = {
      formattedDate: day.formattedDate,
      timeOff: true,
      timeOffMinutes: timeOff.minutes_per_day,
    }

    if (timeOff.leave_type === "holiday" && !includeHolidays) {
      // Return nothing as we are going to treat holidays
      // as non working days - see calculateAllNonWorkingDays
      return {}
    }

    return acc
  }, {})

  return daysObject
}

type CalculateAllTimeOffDaysProps = {
  days: Array<{ formattedDate: string }>
  timeOffs: ReadonlyArray<TimeOff>
  rangeStartDate: Date
  rangeEndDate: Date
  includeHolidays?: boolean
}

export const calculateAllTimeOffDays = ({
  days,
  timeOffs,
  rangeStartDate,
  rangeEndDate,
  includeHolidays = false,
}: CalculateAllTimeOffDaysProps): TimeOffDaysObject => {
  const daysObject = timeOffs.reduce(
    (acc, to) => ({
      ...acc,
      ...calculateTimeOffDays(
        days,
        to,
        rangeStartDate,
        rangeEndDate,
        includeHolidays,
      ),
    }),
    {},
  )

  return daysObject
}

type CalculateAllNonWorkingDaysProps = {
  days: Array<{ formattedDate: string }>
  timeOffs: ReadonlyArray<TimeOff>
  rangeStartDate: Date
  rangeEndDate: Date
}

export const calculateAllNonWorkingDays = ({
  days,
  timeOffs,
  rangeStartDate,
  rangeEndDate,
}: CalculateAllNonWorkingDaysProps): NonWorkingDaysObject => {
  const rangeStart = dateHelpers.formatToRunnDate(rangeStartDate)
  const rangeEnd = dateHelpers.formatToRunnDate(rangeEndDate)
  const nonWorkingDays = days.filter((day) => {
    if (day.formattedDate < rangeStart) {
      return false
    }
    if (day.formattedDate > rangeEnd) {
      return false
    }

    if (
      !dateIsAHoliday(timeOffs || [], day.formattedDate) &&
      !isWeekend(dateHelpers.parseRunnDate(day.formattedDate))
    ) {
      return false
    }
    return true
  })

  const daysObject = nonWorkingDays.reduce((acc, day) => {
    acc[day.formattedDate] = {
      formattedDate: day.formattedDate,
      isANonWorkingDay: true,
    }
    return acc
  }, {})

  return daysObject
}

export const calculateContractDays = (
  days: Array<{ formattedDate: string }>,
  contract: ItemWithDateAndMinutes,
  timeOffs: ReadonlyArray<TimeOff>,
  rangeStartDate: Date,
  rangeEndDate: Date,
): ContractDaysObject => {
  const rangeStart = dateHelpers.formatToRunnDate(rangeStartDate)
  const rangeEnd = dateHelpers.formatToRunnDate(rangeEndDate)

  const contractedDays = days.filter((day) => {
    if (day.formattedDate < rangeStart) {
      return false
    }
    if (day.formattedDate > rangeEnd) {
      return false
    }
    if (day.formattedDate < contract.start_date) {
      return false
    }
    if (contract.end_date && day.formattedDate > contract.end_date) {
      return false
    }

    if (
      isWeekend(dateHelpers.parseRunnDate(day.formattedDate)) ||
      dateIsAHoliday(timeOffs || [], day.formattedDate)
    ) {
      // because contracts don't include weekends or non working_days
      return false
    }
    return true
  })

  const daysObject = contractedDays.reduce((acc, day) => {
    acc[day.formattedDate] = {
      formattedDate: day.formattedDate,
      contracted: true,
      contractedMinutes: contract.minutes_per_day,
      rosteredDays: contract.rostered_days,
    }
    return acc
  }, {})

  return daysObject
}

type CalculateAllContractDaysProps = {
  days: Array<{ formattedDate: string }>
  contracts: readonly ItemWithDateAndMinutes[]
  timeOffs: ReadonlyArray<TimeOff>
  rangeStartDate: Date
  rangeEndDate: Date
}
const calculateAllContractDaysFunc = ({
  days,
  contracts,
  timeOffs,
  rangeStartDate,
  rangeEndDate,
}: CalculateAllContractDaysProps): ContractDaysObject => {
  const contractDayObject = contracts
    .map((contract) =>
      calculateContractDays(
        days,
        contract,
        timeOffs,
        rangeStartDate,
        rangeEndDate,
      ),
    )
    .reduce(
      (acc, c) => ({
        ...acc,
        ...c,
      }),
      {},
    )

  return contractDayObject
}

export const calculateAllContractDays = mem(calculateAllContractDaysFunc, {
  cacheKey: serializeJavascript,
})

export const calculateAssignedMinutesDays = (
  days: Array<{ formattedDate: string }>,
  assignment: Assignment,
  timeOffs: ReadonlyArray<TimeOff>,
  rangeStartDate: Date,
  rangeEndDate: Date,
  isConsistentTimeOffEnabled: boolean,
): AssignedDaysObject => {
  const rangeStart = dateHelpers.formatToRunnDate(rangeStartDate)
  const rangeEnd = dateHelpers.formatToRunnDate(rangeEndDate)

  const assignmentDays = days.filter((day) => {
    if (day.formattedDate < rangeStart) {
      return false
    }
    if (day.formattedDate > rangeEnd) {
      return false
    }
    if (day.formattedDate < assignment.start_date) {
      return false
    }
    if (day.formattedDate > assignment.end_date) {
      return false
    }

    if (isConsistentTimeOffEnabled) {
      // Exclude days that overlap with full rostered-off and annual time offs
      // Holidays are dealt with in the daysObject
      const isTimeOff = timeOffs.some((timeOff) => {
        if (
          timeOff.leave_type === "rostered-off" ||
          (timeOff.leave_type === "annual" && !timeOff.minutes_per_day)
        ) {
          return (
            day.formattedDate >= timeOff.start_date &&
            day.formattedDate <= timeOff.end_date
          )
        }
      })

      if (isTimeOff) {
        return false
      }
    }

    // In other functions we omit weekend & non_working_days here
    // But we need to keep them, because an assignment
    // could still be assigned on a weekend/non_working_day
    // and we will need to get the assignedMinutes

    return true
  })

  const daysObject = assignmentDays.reduce((acc, day) => {
    const weekend = isWeekend(dateHelpers.parseRunnDate(day.formattedDate))
    const holiday = timeOffs && dateIsAHoliday(timeOffs, day.formattedDate)
    const assignedMinutes =
      (weekend || holiday) && !assignment.non_working_day
        ? 0
        : assignment.minutes_per_day

    acc[day.formattedDate] = {
      formattedDate: day.formattedDate,
      assignedMinutes,
    }

    return acc
  }, {})

  return daysObject
}

type CalculateAllAssignedMinutesDaysProps = {
  days: Array<{ formattedDate: string }>
  assignments: readonly Assignment[]
  timeOffs: ReadonlyArray<TimeOff>
  rangeStartDate: Date
  rangeEndDate: Date
  isConsistentTimeOffEnabled: boolean
}

export const calculateAllAssignedMinutesDays = ({
  days,
  assignments,
  timeOffs,
  rangeStartDate,
  rangeEndDate,
  isConsistentTimeOffEnabled,
}: CalculateAllAssignedMinutesDaysProps): AssignedDaysObject => {
  const daysObject = assignments.reduce((acc, a) => {
    if (!a) {
      return acc
    }

    const assignedMinutesDays = calculateAssignedMinutesDays(
      days,
      a,
      timeOffs,
      rangeStartDate,
      rangeEndDate,
      isConsistentTimeOffEnabled,
    )
    Object.keys(assignedMinutesDays).forEach((key) => {
      acc[key] = {
        formattedDate: assignedMinutesDays[key].formattedDate,
        assignedMinutes:
          assignedMinutesDays[key].assignedMinutes +
          (acc[key]?.assignedMinutes || 0),
      }
    })
    return acc
  }, {})

  return daysObject
}

export const calculateCalendarDaysFunc = (
  calStartDate: Date,
  calEndDate: Date,
  defaultToContracted?: boolean,
): DaysObject => {
  const { dailyDates } = getTimeFrames({
    start: calStartDate,
    end: calEndDate,
  })

  const daysObject = dailyDates.reduce((acc, date) => {
    const formattedDate = dateHelpers.formatToRunnDate(date)
    acc[formattedDate] = {
      formattedDate,
      contractedMinutes: 0,
      assignedMinutes: 0,
      timeOff: false,
      contracted: defaultToContracted || false,
      isWeekend: isWeekend(date),
      isANonWorkingDay: isWeekend(date),
    }
    return acc
  }, {})

  return daysObject
}

export const calculateCalendarDays = mem(calculateCalendarDaysFunc, {
  cacheKey: serializeJavascript,
})

export const mergeDaysObjects = (
  daysObject: DaysObject,
  objectsToMerge: DaysObjectAny[],
): DaysObject => merge({}, daysObject, ...objectsToMerge)

export const getTimeOffMinutes = (
  contractedMinutes: number,
  timeOffMinutes?: number | null,
) => {
  if (!timeOffMinutes) {
    return contractedMinutes
  }
  return timeOffMinutes > contractedMinutes ? contractedMinutes : timeOffMinutes
}

export const getDailyRangesCapacity = (
  dailyAssignmentData: DaysObject,
  calendarWeekendsExpanded: boolean,
) => {
  const summaryRanges = []
  const timeOffConflicts = []

  const dataArray = Object.values(dailyAssignmentData)
  dataArray.forEach((day, i) => {
    if (i === 0) {
      // There are conflicts only if there is a timeoff, an assignment, and the time
      // off is less than the contracted minutes (which is a full day time off)
      if (
        day.timeOff &&
        day.timeOffMinutes >= day.contractedMinutes &&
        day.assignedMinutes
      ) {
        timeOffConflicts.push({
          startDate: day.formattedDate,
          endDate: day.formattedDate,
        })
      }

      return summaryRanges.push({
        ...day,
        startDate: day.formattedDate,
        endDate: day.formattedDate,
      })
    }

    const prevItem = summaryRanges[summaryRanges.length - 1]
    if (
      day.timeOff &&
      // Is FTO as defined by no time off minutes or minutes > contracted minutes
      // which can happen if a PTO crosses a contract boundary
      (!day.timeOffMinutes || day.timeOffMinutes >= day.contractedMinutes)
    ) {
      if (day.assignedMinutes && !day.isANonWorkingDay) {
        // find assignments that are scheduled during the timeOff
        const prevTimeOff =
          timeOffConflicts.length >= 1
            ? timeOffConflicts[timeOffConflicts.length - 1]
            : null
        const nextToPrevious =
          prevTimeOff &&
          differenceInBusinessDays(
            dateHelpers.parseRunnDate(day.formattedDate),
            dateHelpers.parseRunnDate(prevTimeOff.endDate),
          ) === 1

        if (nextToPrevious) {
          // if bothDays are next to each other combine them
          timeOffConflicts[timeOffConflicts.length - 1] = {
            ...timeOffConflicts[timeOffConflicts.length - 1],
            endDate: day.formattedDate,
          }
        } else {
          timeOffConflicts.push({
            startDate: day.formattedDate,
            endDate: day.formattedDate,
          })
        }
      }

      // If both days are time off, extend but only if the time offs
      // have the same minutes and the contract has not changed
      if (
        prevItem.timeOff &&
        prevItem.timeOffMinutes === day.timeOffMinutes &&
        prevItem.contractedMinutes === day.contractedMinutes &&
        !prevItem.isANonWorkingDay
      ) {
        return (summaryRanges[summaryRanges.length - 1] = {
          ...prevItem,
          endDate: day.formattedDate,
        })
      }
    }

    if (!calendarWeekendsExpanded && day.isWeekend) {
      // Check if next assignment is in the same weekend
      const nextDay = dataArray[i + 1]

      const nextDayIsSameWeekend = nextDay?.isWeekend

      const combinedAssignedMinutes =
        day.assignedMinutes + (nextDay?.assignedMinutes || 0)

      if (nextDayIsSameWeekend && combinedAssignedMinutes > 0) {
        return summaryRanges.push({
          ...day,
          assignedMinutes: combinedAssignedMinutes,
          startDate: day.formattedDate,
          endDate: nextDay.formattedDate,
        })
      }

      const prevDay = dataArray[i - 1]
      const prevDayIsSameWeekend = prevDay?.isWeekend

      if (prevDayIsSameWeekend || day.assignedMinutes === 0) {
        return summaryRanges
      }
    } else {
      if (day.isWeekend !== prevItem.isWeekend) {
        return summaryRanges.push({
          ...day,
          startDate: day.formattedDate,
          endDate: day.formattedDate,
        })
      }
    }

    // If a person has  no contract. Don't split range.
    if (!day.contracted && !prevItem.contracted) {
      return (summaryRanges[summaryRanges.length - 1] = {
        ...prevItem,
        endDate: day.formattedDate,
      })
    }

    // If anything is different in the below options
    // we know that its different from the previous days and can create a new range
    if (day.contracted !== prevItem.contracted) {
      return summaryRanges.push({
        ...day,
        startDate: day.formattedDate,
        endDate: day.formattedDate,
      })
    }

    if (
      day.contractedMinutes !== prevItem.contractedMinutes &&
      !day.isANonWorkingDay
    ) {
      return summaryRanges.push({
        ...day,
        startDate: day.formattedDate,
        endDate: day.formattedDate,
      })
    }

    if (
      day.timeOff !== prevItem.timeOff ||
      day.timeOffMinutes !== prevItem.timeOffMinutes
    ) {
      return summaryRanges.push({
        ...day,
        startDate: day.formattedDate,
        endDate: day.formattedDate,
      })
    }

    if (day.assignedMinutes !== prevItem.assignedMinutes) {
      return summaryRanges.push({
        ...day,
        startDate: day.formattedDate,
        endDate: day.formattedDate,
      })
    }

    // Same as the last item. Extend it instead of adding
    summaryRanges[summaryRanges.length - 1] = {
      ...prevItem,
      endDate: day.formattedDate,
    }
  })

  const summaryRangesWithTimeOffConflicts = summaryRanges.map((sr) => ({
    ...sr,
    timeOffConflicts: timeOffConflicts.filter(
      (conflict) =>
        conflict.startDate >= sr.startDate && conflict.endDate <= sr.endDate,
    ),
  }))

  return summaryRangesWithTimeOffConflicts
}

type getWeeklyRangesCapacityProps = {
  dailyAssignmentData: DaysObject
  summaryUnit: SummaryUnit
}
export const getWeeklyRangesCapacity = ({
  dailyAssignmentData,
}: getWeeklyRangesCapacityProps) => {
  const dailyDataWithConflicts = Object.values(dailyAssignmentData).map(
    (d) => ({
      ...d,
      hasConflict:
        d.timeOff &&
        (d.timeOffMinutes === null ||
          d.timeOffMinutes >= d.contractedMinutes) && // Only show conflicts for FTO
        !!d.assignedMinutes,
    }),
  )

  // Group daily data based if on same week starting Monday
  const weeklyAssignmentData = Object.values(
    groupBy(dailyDataWithConflicts, (day) =>
      startOfWeek(dateHelpers.parseRunnDate(day.formattedDate), {
        weekStartsOn: 1,
      }),
    ),
  )

  const weeklyRangesCapacity = weeklyAssignmentData.map((week, i) =>
    week.reduce(
      (acc, a) => {
        const timeOff =
          a.timeOff && !a.isANonWorkingDay
            ? getTimeOffMinutes(a.contractedMinutes, a.timeOffMinutes)
            : 0

        const weekHasConflict = !!week.filter((w) => w.hasConflict).length
        const contracted = a.isANonWorkingDay ? 0 : a.contractedMinutes // weekend should have 0 contracted hours
        const weekHasNonWorkingDay = week.some(
          (w) => w.isANonWorkingDay && !w.isWeekend,
        )
        // Non-working days (e.g. holidays) do not include rostered days
        // This will search the week to find a day that does contain rostered days and use that.
        const rosteredDays = week.find((d) => d.rosteredDays)?.rosteredDays

        return {
          assignedMinutes: a.assignedMinutes + acc.assignedMinutes,
          contractedMinutes: contracted + acc.contractedMinutes,
          rosteredDays: rosteredDays,
          timeOffMinutes: timeOff + acc.timeOffMinutes,
          startDate: week[0].formattedDate,
          endDate: week[week.length - 1].formattedDate,
          weekHasConflict,
          weekHasNonWorkingDay,
        }
      },
      {
        assignedMinutes: 0,
        contractedMinutes: 0,
        timeOffMinutes: 0,
        startDate: "",
        endDate: "",
        weekHasConflict: false,
        weekHasNonWorkingDay: false,
      },
    ),
  )

  return weeklyRangesCapacity
}

type DaysObjectAny = {
  [key: string]: any
}

type DaysObject = {
  [key: string]: DayObject
}

type DayObject = {
  formattedDate: string
  contractedMinutes: number
  assignedMinutes: number
  timeOff: boolean
  timeOffMinutes?: number | null
  contracted: boolean
  isWeekend: boolean
  isANonWorkingDay: boolean
  rosteredDays: number[]
}

type TimeOffDaysObject = {
  [key: string]: TimeOffDayObject
}
type TimeOffDayObject = {
  formattedDate: string
  timeOff: boolean
  timeOffMinutes?: number | null
}

type NonWorkingDaysObject = {
  [key: string]: NonWorkingDayObject
}

type NonWorkingDayObject = {
  formattedDate: string
  isANonWorkingDay: boolean
}

type ContractDaysObject = {
  [key: string]: ContractDayObject
}

type ContractDayObject = {
  formattedDate: string
  contractedMinutes: number
  contracted: boolean
  rosteredDays: number[]
}

type AssignedDaysObject = {
  [key: string]: AssignedDayObject
}

type AssignedDayObject = {
  formattedDate: string
  assignedMinutes: number
}
