/**
 * @generated SignedSource<<955755538af5500ccaf7edd9871f097f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewFieldsTooltip_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ViewFilterValues_user">;
  readonly " $fragmentType": "ViewFieldsTooltip_user";
};
export type ViewFieldsTooltip_user$key = {
  readonly " $data"?: ViewFieldsTooltip_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewFieldsTooltip_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewFieldsTooltip_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ViewFilterValues_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "95ffe87528ebd26db42dbab3c4413e3c";

export default node;
