import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { ProjectGroupByOption } from "~/helpers/group-helpers"
import { ProjectSortOption } from "~/helpers/sorting-helpers"

import { getSettings } from "~/localsettings"

type PlannerProjectSortingState = {
  sortBy: ProjectSortOption
  groupBy: ProjectGroupByOption
}

const getInitialState = (): PlannerProjectSortingState => {
  const settings = getSettings()
  return {
    sortBy: settings?.projectPlannerSort?.value ?? "projectName",
    groupBy: settings?.projectPlannerGroupBy?.value ?? "default",
  }
}

const plannerProjectSorting = createSlice({
  name: "plannerProjectSorting",
  initialState: getInitialState,
  reducers: {
    sortChanged: (state, action: PayloadAction<ProjectSortOption>) => {
      const sortBy = action.payload
      state.sortBy = sortBy
    },
    groupByChanged: (state, action: PayloadAction<ProjectGroupByOption>) => {
      const groupBy = action.payload
      state.groupBy = groupBy
    },
  },
})

const { actions, reducer } = plannerProjectSorting

export const { sortChanged, groupByChanged } = actions
export default reducer

export const selectSortProjectBy = (state: PlannerProjectSortingState) =>
  state.sortBy

export const selectGroupProjectBy = (state: PlannerProjectSortingState) =>
  state.groupBy
