import cc from "classcat"
import { useFeature } from "flagged"
import React from "react"

import styles from "./ProjectDetailsPersonRow.module.css"

import { CustomType, CustomValuesMap } from "~/helpers/custom-field-helpers"

import TooltipEllipsis from "~/common/TooltipEllipsis"

import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { useAppSelector } from "~/hooks/redux"

type Link = {
  name: string
  href: string
}

type Competency = {
  level: number
  skill: {
    name: string
  }
}

type Person = {
  id: number
  first_name: string
  last_name: string
  tags: string[]
  competencies: readonly Competency[]
  links: readonly Link[]
}

type Props = {
  person: Person
  isInactivePerson: boolean
  onClick?: (event: React.MouseEvent) => void
  showRequestIcon: boolean
  customValues: CustomValuesMap
  customTypes: CustomType[]
  isPlaceholderInTemplate: boolean
}

const PersonNameDisplay = (props: Props) => {
  const {
    person,
    isInactivePerson,
    onClick,
    showRequestIcon,
    customValues,
    customTypes,
    isPlaceholderInTemplate,
  } = props
  const { tags, competencies } = person
  const workstreamsEnabled = useFeature("workstreams")

  const personName = `${person.first_name} ${person.last_name}`
  const customFields = {
    values: customValues,
    types: customTypes,
  }
  const schedulePanelPersonId = useAppSelector(
    (state) => state.plannerV2.schedulePreview.selectedPersonId,
  )
  const showPanel = useAppSelector((state) => state.plannerV2.panel.showPanel)
  const projectScheduleOpen = showPanel === "projectSchedule"

  const renderInactivePerson = () => {
    return (
      <div style={{ display: "flex" }}>
        <div
          onClick={onClick}
          className={cc([
            styles.inactive,
            {
              [styles.readonly]: isPlaceholderInTemplate || projectScheduleOpen,
              [styles.inactiveWithWorkstreams]: workstreamsEnabled,
            },
          ])}
        >
          <TooltipEllipsis text={personName} customMaxWidth={"230px"} />
        </div>
        <PlannerTooltipIcons
          icons={{
            tags,
            competencies,
            customFields,
          }}
        />
      </div>
    )
  }

  const renderPlaceholderInTemplate = () => (
    <div style={{ display: "flex" }}>
      <TooltipEllipsis text={personName} customMaxWidth="230px" />
      <PlannerTooltipIcons
        icons={{
          tags,
          competencies,
          customFields,
        }}
      />
    </div>
  )

  const renderDefaultPerson = () => (
    <div
      onClick={onClick}
      className={cc([
        styles.name,
        {
          [styles.readonly]: projectScheduleOpen,
          [styles.nameWithWorkstreams]: workstreamsEnabled,
        },
      ])}
    >
      <TooltipEllipsis
        text={personName}
        secondaryText={
          projectScheduleOpen
            ? null
            : schedulePanelPersonId === person.id
              ? `Hide schedule`
              : `View schedule`
        }
        customMaxWidth={showRequestIcon ? "200px" : "220px"}
      />
      <PlannerTooltipIcons
        icons={{
          links: person.links,
          tags,
          competencies,
          customFields,
        }}
      />
    </div>
  )

  if (isInactivePerson) {
    return renderInactivePerson()
  }

  if (isPlaceholderInTemplate) {
    return renderPlaceholderInTemplate()
  }

  return renderDefaultPerson()
}

export default PersonNameDisplay
