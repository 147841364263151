import { Icon } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import { track } from "~/helpers/analytics"

import MenuItem from "~/common/MenuItem"
import DashCircle from "~/common/react-icons/dashCircle"

import { confirmProject, tentativeProject } from "~/mutations/Project"

import { filteredByProject } from "~/Planner/reducer2/projectFilterSlice"
import { enableOnly } from "~/Planner/reducer2/scenarioPlanningSlice"
import { showToast } from "~/containers/ToasterContainer"

type Props = {
  project: { id: number; name: string; confirmed: boolean }
  closeMenu: () => void
  hidden?: boolean
}

const TentativeMenuItem = (props: Props) => {
  const dispatch = useDispatch()

  const { project, closeMenu, hidden } = props

  const filterToNewProject = (projectId: number, isTentative: boolean) => {
    dispatch(filteredByProject(projectId))

    if (isTentative) {
      dispatch(enableOnly([projectId]))
    }
  }

  const menuText = project.confirmed ? "Set Tentative" : "Set Confirmed"

  const toggleTentative = async () => {
    if (project.confirmed) {
      await tentativeProject({ id: project.id })
    } else {
      await confirmProject({ id: project.id })
    }

    if (!project.confirmed) {
      closeMenu()
    }

    track("Project Context Menu Item Selected", {
      item: menuText,
    })
    showToast({
      message: `Project set to ${
        project.confirmed ? "tentative" : "confirmed"
      }`,
      description: project.name,
      type: "success",
      actions: [
        { onClick: () => filterToNewProject(project.id, project.confirmed) },
      ],
    })
  }

  return (
    <MenuItem
      text={menuText}
      icon={
        project.confirmed ? (
          <DashCircle size={14} style={{ marginRight: "3px" }} />
        ) : (
          <Icon size={13} icon="tick-circle" />
        )
      }
      onClick={toggleTentative}
      shouldDismissPopover={false}
      hidden={hidden}
    />
  )
}

export default TentativeMenuItem
