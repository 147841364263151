/**
 * @generated SignedSource<<37853fd575344a2fb6b211b3c87b53ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PhaseUpdateMutation$variables = {
  color: string;
  end_date: any;
  id: number;
  name: string;
  project_id: number;
  start_date: any;
};
export type PhaseUpdateMutation$data = {
  readonly updatePhase: {
    readonly phase: {
      readonly color: string;
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly project: {
        readonly calc_end_date: string | null | undefined;
        readonly calc_start_date: string | null | undefined;
        readonly id: number;
      };
      readonly start_date: string | null | undefined;
    } | null | undefined;
  };
};
export type PhaseUpdateMutation = {
  response: PhaseUpdateMutation$data;
  variables: PhaseUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "color"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "color",
        "variableName": "color"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "project_id"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "UpdatePhaseResult",
    "kind": "LinkedField",
    "name": "updatePhase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "phases",
        "kind": "LinkedField",
        "name": "phase",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": "start_date",
            "args": null,
            "kind": "ScalarField",
            "name": "start_date_runn",
            "storageKey": null
          },
          {
            "alias": "end_date",
            "args": null,
            "kind": "ScalarField",
            "name": "end_date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_start_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_end_date",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PhaseUpdateMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "PhaseUpdateMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "e23840d13924234108ae073601c70f04",
    "id": null,
    "metadata": {},
    "name": "PhaseUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PhaseUpdateMutation(\n  $id: Int!\n  $color: String!\n  $start_date: Date!\n  $end_date: Date!\n  $name: String!\n  $project_id: Int!\n) {\n  updatePhase(id: $id, color: $color, startDate: $start_date, endDate: $end_date, name: $name, projectId: $project_id) {\n    phase {\n      id\n      name\n      color\n      start_date: start_date_runn\n      end_date: end_date_runn\n      project {\n        id\n        calc_start_date\n        calc_end_date\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9c264c1097ba1ade9ec236751d54de4";

export default node;
