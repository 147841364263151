/**
 * @generated SignedSource<<2765d6480e540067d3ec7958abfc671d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetectedPeopleSection_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPersonRows_user">;
  readonly " $fragmentType": "DetectedPeopleSection_user";
};
export type DetectedPeopleSection_user$key = {
  readonly " $data"?: DetectedPeopleSection_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPeopleSection_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetectedPeopleSection_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetectedPersonRows_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "e61373c61bec37154e3bae7a6e99fca1";

export default node;
