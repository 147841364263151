import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import React, { useEffect, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PhasesWrapper.module.css"

import { PhasesWrapper_project$key } from "./__generated__/PhasesWrapper_project.graphql"

import { getDaysInRange } from "~/helpers/CalendarHelper"
import { getGridAttributes } from "~/helpers/PhaseHelper"

import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn } from "~/Planner/PlannerLayout"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import NewPhaseItem from "./NewPhaseItem"
import PhaseItem from "./PhaseItem"

type Props = {
  project: PhasesWrapper_project$key
  isCollapsed?: boolean
  mini?: boolean
}

const Phases = (props: Props) => {
  const { isCollapsed, mini } = props

  const project = useFragment(
    graphql`
      fragment PhasesWrapper_project on projects {
        id
        is_template
        phases {
          id
          name
          color
          start_date: start_date_runn
          end_date: end_date_runn

          ...PhaseItem_phase
        }
      }
    `,
    props.project,
  )

  const phasesWrapperRef = useRef(null)
  const calendar = useAppSelector((state) => state.calendar, isDeeplyEqual)
  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const alwaysShowProjectPhases = useSelector(
    (state: ReduxState) => state.planner.showProjectPhases,
  )
  const disabled = isSplitScreenMode(modeAction)

  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calStartNum,
    calEndNum,
    calendarWeekendsExpanded,
  } = calendar
  const [overlayCursor, setOverlayCursor] = useState(null)
  const [numberOfDays, setNumberOfDays] = useState(null)

  useEffect(() => {
    // check when user changes from Month/Quarter/Half year based on dayWidth changes
    if (dayWidth) {
      const totalDays = getDaysInRange({
        start: calendarStartDate,
        end: calendarEndDate,
        includeWeekends: calendarWeekendsExpanded,
      })
      setNumberOfDays(totalDays)
    }
  }, [dayWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  const projectPhases = project.phases
  const phasesWithinRange = projectPhases.filter((phase) => {
    const notInCalendarView =
      Number(phase.end_date) < calStartNum ||
      Number(phase.start_date) > calEndNum
    return !notInCalendarView
  })

  const renderExistingPhases = () =>
    phasesWithinRange.map((phase) => (
      <PhaseItem
        phase={phase}
        key={phase.id}
        phasesWrapperRef={phasesWrapperRef}
        setOverlayCursor={setOverlayCursor}
        calendarWeekendsExpanded={calendarWeekendsExpanded}
        isTemplate={project.is_template}
      />
    ))

  const phasesGridTemplateColumns = `repeat(${numberOfDays}, minmax(${
    dayWidth - 1
  }px, 1fr))`

  if (mini) {
    return (
      <div
        className={styles.miniPhasesGrid}
        style={{ gridTemplateColumns: phasesGridTemplateColumns }}
      >
        {phasesWithinRange.map((phase) => {
          const basicPill = getGridAttributes({
            start: dateHelpers.parseRunnDate(phase.start_date),
            end: dateHelpers.parseRunnDate(phase.end_date),
            calendarStartDate,
            calendarEndDate,
            dayWidth,
            calendarWeekendsExpanded,
          })
          return (
            <div
              key={phase.id}
              className={styles.miniPill}
              style={{
                gridColumn: `${basicPill.gridStart} / span ${basicPill.gridSpan}`,
                backgroundColor: phase.color,
              }}
            />
          )
        })}
      </div>
    )
  }

  return (
    <>
      {overlayCursor && (
        <div
          className={styles.overlay}
          style={{ cursor: overlayCursor ? overlayCursor : "pointer" }}
        />
      )}
      <PlannerGrid
        className={cc([
          styles.phasesWrapper,
          {
            [styles.alwaysShowProjectPhases]: alwaysShowProjectPhases,
          },
        ])}
        disabled={disabled}
      >
        <PlannerLeftColumn className={styles.title} paddingHeight={10}>
          {!isCollapsed && "Phases"}
        </PlannerLeftColumn>
        <div ref={phasesWrapperRef} className={styles.phases}>
          <div className={styles.divider} />
          <div
            className={styles.phasesGrid}
            // minimum should be less than they dayWidth in case scrollbar appears
            style={{ gridTemplateColumns: phasesGridTemplateColumns }}
          >
            {renderExistingPhases()}

            <NewPhaseItem
              phasesWrapperRef={phasesWrapperRef}
              phaseCount={phasesWithinRange.length}
              setOverlayCursor={setOverlayCursor}
              projectId={project.id}
              isTemplate={project.is_template}
            />
          </div>
        </div>
      </PlannerGrid>
    </>
  )
}

export default React.memo(Phases)
