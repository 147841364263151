import numbro from "numbro"

import { CURRENCY_SYMBOLS_MAP } from "~/GLOBALVARS"

// @ts-ignore
const defaultCurrency = window.currency || "$"

// mantissa = decimal places
// optionalMantissa = mantissa is trimmed when 0

// for example: amount = 100.3
// optionalMantissa: true, mantissa: 2 = $100.3
// optionalMantissa: false, mantissa: 2 = $100.30

// for example: amount = 100.251
// optionalMantissa: true, mantissa: 2 = $100.25
// optionalMantissa: false, mantissa: 2 = $100.25

// for example: amount = 100
// optionalMantissa: true, mantissa: 2 = $100
// optionalMantissa: false, mantissa: 2 = $100.00

export const formatCurrency = (
  amount: number,
  {
    optionalMantissa = true,
    mantissa = 2,
    rounded = false,
    currency = defaultCurrency,
  }: {
    optionalMantissa?: boolean
    mantissa?: number
    rounded?: boolean
    currency?: string
  } = {},
) => {
  if (typeof amount !== "number" || Number.isNaN(amount)) {
    amount = 0
  }

  const optional = amount === 0 ? true : optionalMantissa
  const formattedAmount = rounded ? Math.round(amount) : amount

  if (formattedAmount < 0) {
    return `-${currency}${numbro(-formattedAmount).format({
      mantissa,
      thousandSeparated: true,
      optionalMantissa: optional,
    })}`
  } else {
    return `${currency}${numbro(formattedAmount).format({
      mantissa,
      thousandSeparated: true,
      optionalMantissa: optional,
    })}`
  }
}

export const getCurrencySymbolForCode = (currencyCode: string) => {
  return CURRENCY_SYMBOLS_MAP[currencyCode] || ""
}

export { defaultCurrency as currency }
export default defaultCurrency
