/**
 * @generated SignedSource<<bbab3eb8a4a04948b009dbc52aa58a68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Account_Integrations$data = {
  readonly integrationsV2: ReadonlyArray<{
    readonly id: number;
    readonly integration_service: {
      readonly id: number;
      readonly name: string;
      readonly square_image_url: string | null | undefined;
    };
  }>;
  readonly " $fragmentType": "Account_Integrations";
};
export type Account_Integrations$key = {
  readonly " $data"?: Account_Integrations$data;
  readonly " $fragmentSpreads": FragmentRefs<"Account_Integrations">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Account_Integrations",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "state": {
              "_neq": "PENDING"
            }
          }
        }
      ],
      "concreteType": "integration",
      "kind": "LinkedField",
      "name": "integrationsV2",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "integration_service",
          "kind": "LinkedField",
          "name": "integration_service",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "square_image_url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "integrationsV2(where:{\"state\":{\"_neq\":\"PENDING\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "d08cb05582fc0ef5475adeed5feb1a2f";

export default node;
