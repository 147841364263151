import { toFullTimeEquivalentEffort } from "@runn/calculations"
import cc from "classcat"
import { useFeature } from "flagged"
import React, { useLayoutEffect, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./PlaceholderCapacityIndicator.module.css"

import { PlaceholderCapacityIndicator_projects$key } from "./__generated__/PlaceholderCapacityIndicator_projects.graphql"
import { PlaceholderSummaryRow_person$data } from "./__generated__/PlaceholderSummaryRow_person.graphql"
import { PersonManagement_user$data } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"
import { SplitScreenRow_person$data } from "~/Planner/SplitScreenPanel/SplitScreenRow/__generated__/SplitScreenRow_person.graphql"

import { getDaysInRange } from "~/helpers/CalendarHelper"
import { getUtilizationPercentage } from "~/helpers/UtilizationHelper"
import {
  calculateAllAssignedMinutesDays,
  calculateCalendarDays,
  getDailyRangesCapacity,
  getWeeklyRangesCapacity,
  mergeDaysObjects,
} from "~/helpers/daysObjectHelpers"
import { Assignment as MultiSelectAssignment } from "~/helpers/planner-helpers"

import { getPillPosition } from "~/common/Pill/PillHelpers"

import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

type Placeholders = PersonManagement_user$data["account"]["people"]
type Assignment = Placeholders[number]["assignments"][number]

type Props = {
  projects: PlaceholderCapacityIndicator_projects$key
  placeholders:
    | Placeholders
    | Array<SplitScreenRow_person$data>
    | Array<PlaceholderSummaryRow_person$data>
  companyDefaultMinutes: number
  splitScreenImpactAssignments?:
    | Array<MultiSelectAssignment & { minutes: number }>
    | undefined
  isTemplate?: boolean
  inHeadingBar?: boolean
}

type TextProps = {
  barWidth: number
  assigned: number
  contracted: number
  days: number
  isWeekend: boolean
  fulltimeMinutesPerDay: number
}

const PlaceholderText = (textProps: TextProps) => {
  const {
    barWidth,
    assigned,
    contracted,
    days,
    isWeekend,
    fulltimeMinutesPerDay,
  } = textProps

  const { summaryUnit, showWeekly } = useAppSelector(
    (state) => state.plannerV2.peopleSummary,
  )
  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)

  const textRef = useRef(null)
  const hours = `${Math.ceil((assigned / 60) * 2) / 2}h` // round to nearest .5
  const fteMinutes = showWeekly ? assigned / 5 : assigned
  const effort = toFullTimeEquivalentEffort({
    minutesOfEffort: Math.abs(fteMinutes),
    fulltimeMinutesPerDay,
  })
  const contractedHours = showWeekly ? contracted * days : contracted

  const percentage = getUtilizationPercentage({
    assignedHours: assigned,
    contractedHours,
    enabledDays: days,
    round: true,
    isANonWorkingDay: isWeekend,
  })

  const textInView = match({ summaryUnit, effortDisplayUnit })
    .with({ summaryUnit: "capacityPercentage" }, () => `${percentage}%`)
    .with(
      {
        summaryUnit: "fullTimeEquivalent",
        effortDisplayUnit: "fullTimeEquivalent",
      },
      () => `${effort} FTE`,
    )
    .otherwise(() => `${hours}`)

  const [textOverflows, setTextOverflows] = useState(false)
  useLayoutEffect(() => {
    if (textRef.current) {
      setTextOverflows(barWidth < textRef.current?.offsetWidth + 35) // add 30 to give extra padding
    }
  }, [textRef, barWidth])

  if (!assigned) {
    return null
  }

  const barText = textOverflows ? `${textInView}` : `${textInView} required`

  return (
    barWidth > 35 && (
      <div ref={textRef} className={styles.barText}>
        {barText}
      </div>
    )
  )
}

const PlaceholderCapacityIndicator = (props: Props) => {
  const {
    placeholders,
    companyDefaultMinutes,
    splitScreenImpactAssignments = [],
    isTemplate = false,
    inHeadingBar = false,
  } = props

  const projects = useFragment(
    graphql`
      fragment PlaceholderCapacityIndicator_projects on projects
      @relay(plural: true) {
        id
        confirmed
        is_template
      }
    `,
    props.projects,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const [renderGraphs, setRenderGraphs] = useState(placeholders.length < 50)

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )
  const { summaryUnit } = useAppSelector(
    (state) => state.plannerV2.peopleSummary,
  )

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )

  const showWeekly = useAppSelector(
    (state) => state.plannerV2.peopleSummary.showWeekly,
  )

  useLayoutEffect(() => {
    const timeOut = setTimeout(() => {
      setRenderGraphs(true)
    }, 10)
    return () => clearTimeout(timeOut)
  }, [])

  if (!renderGraphs) {
    return null
  }

  if (isTemplate) {
    return (
      <div
        className={cc([styles.capacityAmount, styles.noAssignments])}
        style={{ width: "100%" }}
      ></div>
    )
  }

  const enabledProjects = projects
    .filter((project) => !project.is_template)
    .filter(
      (project) =>
        project.confirmed ||
        isTentativeProjectEnabled(enabledTentativeProjects, project.id),
    )
    .map((p) => p.id)

  const isAssignmentEnabled = (assignment: Assignment) =>
    enabledProjects.includes(assignment.project_id)

  const assignments = placeholders
    .flatMap((p) => p.assignments)
    .filter(isAssignmentEnabled)

  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
  } = calendar

  // Create placeholder days as contracted
  const daysObject = calculateCalendarDays(
    calendarStartDate,
    calendarEndDate,
    true,
  )
  const days = Object.values(daysObject)
  const assignmentDaysObject = calculateAllAssignedMinutesDays({
    days,
    assignments: [...assignments, ...splitScreenImpactAssignments],
    timeOffs: [], // placeholders don't have time offs
    rangeStartDate: calendarStartDate,
    rangeEndDate: calendarEndDate,
    isConsistentTimeOffEnabled,
  })
  const dailyAssignmentData = mergeDaysObjects(daysObject, [
    assignmentDaysObject,
  ])

  const dateRangeCapacities = showWeekly
    ? getWeeklyRangesCapacity({ dailyAssignmentData, summaryUnit })
    : getDailyRangesCapacity(dailyAssignmentData, calendarWeekendsExpanded)

  return (
    <>
      {dateRangeCapacities.map((cr, i) => {
        const numOfDays = getDaysInRange({
          start: cr.startDate,
          end: cr.endDate,
          includeWeekends: false,
        })
        const isCollapsedWeekend = !calendarWeekendsExpanded && cr.isWeekend

        const { width, offset } = getPillPosition({
          item: { start_date: cr.startDate, end_date: cr.endDate },
          calendarStartDate,
          calendarEndDate,
          calendarWeekendsExpanded,
          dayWidth,
          isCollapsedWeekend,
        })

        const minutes = cr.assignedMinutes
        const requiresRightBorder =
          minutes > 0 && !dateRangeCapacities[i + 1]?.assignedMinutes

        const collapsedWidth = 4
        const widthPx = isCollapsedWeekend
          ? collapsedWidth
          : numOfDays * dayWidth

        return (
          <div
            key={cr.startDate}
            style={{
              width: isCollapsedWeekend ? `${collapsedWidth}px` : `${width}%`,
              left: isCollapsedWeekend
                ? `calc(${offset}% - ${collapsedWidth / 2}px)`
                : "",
            }}
            className={cc([
              styles.capacityAmount,
              {
                [styles.noAssignments]: !minutes,
                [styles.borderRight]: requiresRightBorder,
                [styles.collapsedWeekend]: isCollapsedWeekend,
                [styles.inHeadingBar]: inHeadingBar,
              },
            ])}
          >
            <PlaceholderText
              barWidth={widthPx}
              assigned={minutes}
              contracted={companyDefaultMinutes}
              days={numOfDays}
              isWeekend={cr.isWeekend}
              fulltimeMinutesPerDay={companyDefaultMinutes}
            />
          </div>
        )
      })}
    </>
  )
}

export default React.memo(PlaceholderCapacityIndicator, isDeeplyEqual)
