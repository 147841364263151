import { Icon, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./MiniPicker.module.css"

import { TimeOffMiniPicker_account$key } from "./__generated__/TimeOffMiniPicker_account.graphql"

import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"

import BlueLink from "~/common/buttons/BlueLink"
import Button from "~/common/buttons/Button"

import { setHighlightedItemData as setHighlightedItemDataDispatch } from "~/GlobalState"

import EditAssignmentNote from "../EditAssignmentNote"
import { IconThreeDot } from "../IconThreeDot"
import CloneMenuItem from "../PillActions/MenuItems/CloneMenuItem"
import TransferMenuItem from "../PillActions/MenuItems/TransferMenuItem"
import { PopoverContextMenu } from "../PillActions/PillActions"

import TimeOffEffortInputs from "./TimeOffEffortInputs"

type Props = {
  account: TimeOffMiniPicker_account$key
  timeoff: TimeOff
  totalWorkingDays: {
    value: number
    label: string
  }
  minutesPerDay: number
  setMinutesPerDay: (number) => void
  selectedDates: Date[]
  closePopover: () => void
  handleCancel: () => void
  onDelete?: () => void
  person: Person
  accountDefaultMinutesPerDay: number
  setShowFullCalendar: (boolean) => void
  setHighlightedItemData: (any) => void
  handleSubmit: () => void
  editNote: (note: string) => void
  itemNote: string
  isPopoverOpen: boolean
  isNonBillableProject: boolean
  onChangeWorkingDays: (days: number) => void
  contextMenu?: PopoverContextMenu
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  note?: string
  leave_type: string
}

type Person = {
  id: number
  first_name: string
  contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
  }>
}

const TimeOffMiniPicker = (props: Props) => {
  const {
    timeoff,
    minutesPerDay,
    setMinutesPerDay,
    totalWorkingDays,
    selectedDates,
    handleCancel,
    onDelete,
    setShowFullCalendar,
    setHighlightedItemData,
    isPopoverOpen,
    handleSubmit,
    closePopover,
    editNote,
    itemNote,
    person,
    onChangeWorkingDays,
    contextMenu,
  } = props

  const account = useFragment(
    graphql`
      fragment TimeOffMiniPicker_account on accounts {
        id
        default_full_time_minutes
        ...EffortInput_account
        ...TotalEffortInput_account
        ...TimeOffEffortInputs_account
      }
    `,
    props.account,
  )

  const [hoursPerDayError, setHoursPerDayError] = useState(false)
  const [initialTimeOff, setInitialTimeOff] = useState<TimeOff>(undefined)
  const [total, setTotal] = useState<{
    value: number
    label: string
  }>({
    value: totalWorkingDays.value * minutesPerDay,
    label: totalWorkingDays.label,
  })

  const isSaveDisabled = React.useMemo(() => {
    return Number.isNaN(minutesPerDay)
  }, [minutesPerDay])

  const getMaxMinutesPerDay = () =>
    getMinutesPerDayForDate(
      dateHelpers.formatToRunnDate(selectedDates[0]),
      person.contracts,
      account.default_full_time_minutes,
    )

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Esc
      closePopover()
    }

    if (e.keyCode === 13 && e.target && e.target.nodeName === "INPUT") {
      document.getElementById("save-timeoff").focus()
    }
  }

  const handlePressEnter = (minutes) => {
    setMinutesPerDay(minutes)
  }

  const handleDelete = () => {
    if (timeoff) {
      if (onDelete) {
        onDelete()
      }
      setHighlightedItemData(null)
    }
  }

  useEffect(
    () => {
      document.addEventListener("keydown", onKeyDown)
      setHighlightedItemData(timeoff)
      setTotal({
        value: totalWorkingDays.value * minutesPerDay,
        label: totalWorkingDays.label,
      })

      if (!initialTimeOff) {
        // set initial item before any changes are made
        setInitialTimeOff(timeoff)
      }

      return () => {
        if (hoursPerDayError) {
          handlePressEnter(minutesPerDay)
        }
        document.removeEventListener("keydown", onKeyDown)
        setHoursPerDayError(false)
        setHighlightedItemData(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPopoverOpen, totalWorkingDays, minutesPerDay],
  )

  const handleMinutesChange = (minutes, error) => {
    setMinutesPerDay(Math.round(minutes))
    setHoursPerDayError(error)
  }

  const handleShowFullCalendar = () => {
    setShowFullCalendar(true)
  }

  const maxMinutesPerDay = getMaxMinutesPerDay()

  return (
    <>
      <div
        data-component="TimeoffMiniPicker"
        className={styles.timeOffMiniPicker}
      >
        <TimeOffEffortInputs
          onMinutesPerDayChange={handleMinutesChange}
          personFirstName={person.first_name}
          maxMinutesPerDay={maxMinutesPerDay}
          total={total}
          account={account}
          minutesPerDay={minutesPerDay}
          onChangeWorkingDays={onChangeWorkingDays}
          selectedDates={selectedDates}
          totalWorkingDays={totalWorkingDays}
        />
        <EditAssignmentNote note={itemNote} editNote={editNote} />
      </div>
      <div className={styles.footer}>
        {timeoff && onDelete ? (
          <BlueLink
            text="Delete"
            onClick={handleDelete}
            style={{ marginRight: "auto" }}
          />
        ) : (
          <BlueLink
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "auto" }}
          />
        )}

        <Tooltip content="View Full Calendar" hoverOpenDelay={500}>
          <Button
            icon={<Icon icon="calendar" size={12} />}
            onClick={handleShowFullCalendar}
          />
        </Tooltip>

        {contextMenu && (
          <Popover
            usePortal={true}
            isOpen={contextMenu.isContextMenuOpen}
            placement="right-start"
            modifiers={{ arrow: { enabled: false } }}
            interactionKind="click"
            fill={true}
            content={
              <Menu>
                {contextMenu.onSplit && (
                  <MenuItem text="Split" onClick={contextMenu.onSplit} />
                )}
                <TransferMenuItem
                  text="Transfer"
                  onClick={contextMenu.onTransfer}
                />
                <CloneMenuItem text="Clone" onClick={contextMenu.onClone} />
                <MenuItem
                  text="Select All to Right"
                  onClick={contextMenu.onSelectAllToRight}
                />
                <MenuItem
                  text="Enable Multi-Select Mode"
                  onClick={contextMenu.onEnableMultiSelect}
                />
              </Menu>
            }
          >
            <Button
              icon={<IconThreeDot size={12} />}
              onClick={() =>
                contextMenu.setIsContextMenuOpen(!contextMenu.isContextMenuOpen)
              }
            />
          </Popover>
        )}
        <Button
          id="save-timeoff"
          outlined={false}
          text="Save"
          onClick={handleSubmit}
          disabled={isSaveDisabled}
        />
      </div>
    </>
  )
}

const mapDispatchToProps = {
  setHighlightedItemData: setHighlightedItemDataDispatch,
}

export default connect<any, any, any>(
  undefined,
  mapDispatchToProps,
)(TimeOffMiniPicker)
