/**
 * @generated SignedSource<<ef7b63c44d7010d6a349b637044e2a64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonForm_viewer$data = {
  readonly account: {
    readonly business_days: ReadonlyArray<string>;
    readonly clients: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly currency: string | null | undefined;
    readonly default_full_time_minutes: number;
    readonly holidays_groups: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly teams: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly use_week_numbers: boolean | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CustomFieldSectionHeaderPerson_account" | "TeamSelector_account">;
  };
  readonly first_name: string | null | undefined;
  readonly id: number;
  readonly last_name: string | null | undefined;
  readonly permissions: any;
  readonly view: {
    readonly id: number;
    readonly name: string;
    readonly people_filters: any;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"PersonCustomEditor_viewer">;
  readonly " $fragmentType": "PersonForm_viewer";
};
export type PersonForm_viewer$key = {
  readonly " $data"?: PersonForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonForm_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonForm_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "people_filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "business_days",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "use_week_numbers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "teams",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "order_by",
              "value": {
                "name": "asc"
              }
            }
          ],
          "concreteType": "holidays_groups",
          "kind": "LinkedField",
          "name": "holidays_groups",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "clients",
          "kind": "LinkedField",
          "name": "clients",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "default_hour_cost",
              "args": null,
              "kind": "ScalarField",
              "name": "default_hour_cost_private",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TeamSelector_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CustomFieldSectionHeaderPerson_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonCustomEditor_viewer"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "8cfd30261f8e4c811c05573a09f1ed74";

export default node;
