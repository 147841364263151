export const splitString = (value: string, lowercase = false): string[] => {
  if (!value) {
    return [""]
  }

  const val = lowercase ? value.toLowerCase() : value

  const formatted = val
    .replace(/['’]/g, "") // remove apostrophes (also the kind that looks weird when pasted)
    .split(/[\s-]+/) // split and also remove dashes

  return formatted
}

export const splitStringLowerCase = (value: string): string[] => {
  return splitString(value, true)
}

type Skill = {
  id: number
  name: string
}

export const filterSkillsByName = (
  skills: ReadonlyArray<Skill>,
  filterValue: string,
): Skill[] => {
  return skills.filter((skill) => {
    if (!filterValue) {
      return true
    }

    const filterWords = splitStringLowerCase(filterValue)
    const skillNameSplit = splitStringLowerCase(skill.name)

    const checkIfWordsIncludeFilter = (val) =>
      skillNameSplit.filter((word) => word.startsWith(val)).length !== 0

    return filterWords.every(checkIfWordsIncludeFilter)
  })
}

export default {
  splitString,
  splitStringLowerCase,
  filterSkillsByName,
}
