import cc from "classcat"
import { isWeekend, parseISO } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PersonTimeOffRow.module.css"

import { PersonTimeOffRow_account$key } from "./__generated__/PersonTimeOffRow_account.graphql"
import { PersonTimeOffRow_person$key } from "./__generated__/PersonTimeOffRow_person.graphql"

import {
  separateHolidaysFromTimeOffs,
  sortTimeOffsByHierarchy,
} from "~/helpers/holiday-helpers"

import OutOfContractCurtains from "~/common/OutOfContractCurtains/OutOfContractCurtains"
import TimeOffActionPill from "~/common/Pill/TimeOffActionPill"
import TimeOffCurtain from "~/common/TimeOffCurtain/TimeOffCurtain"
import DragToCreateOutline from "~/common/calendar/DragToCreateOutline"
import { Clock } from "~/common/react-icons"

import { isSplitScreenMode } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

type PersonTimeOffRowProps = {
  account: PersonTimeOffRow_account$key
  person: PersonTimeOffRow_person$key
  timeOffs: ReadonlyArray<{
    readonly id: number
    readonly person_id: number
    readonly start_date: string
    readonly end_date: string
    readonly note: string
    readonly leave_type: string
    readonly minutes_per_day: number
    readonly ext_time_off_links: ReadonlyArray<{
      readonly active: boolean
      readonly external: {
        readonly integration_id: number
      }
    }>
  }>
  showBottomBorder?: boolean
  holidaysOverlappingTimeOffs?: string[]
  defaultFullTimeMinutes: number
}

const PersonTimeOffRow = (props: PersonTimeOffRowProps) => {
  const { timeOffs, showBottomBorder, holidaysOverlappingTimeOffs } = props

  const account = useFragment(
    graphql`
      fragment PersonTimeOffRow_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        id
        default_full_time_minutes
        projects(where: $projectsFilter) {
          id
        }
        ...DragToCreateOutline_account
        ...TimeOffActionPill_account
      }
    `,
    props.account,
  )

  const person = useFragment(
    graphql`
      fragment PersonTimeOffRow_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        id
        email
        first_name
        last_name
        image_key
        is_placeholder
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          note
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          role {
            id
            name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          person_id
          role_id
          project_id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          phase_id
          workstream_id
          is_billable
          is_template
          note
          non_working_day
        }
        holidays_group {
          id
          name
        }
      }
    `,
    props.person,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const { can, subject } = usePermissions()
  const canCreate = can("create", subject("TimeOff", { person }))

  const testId = `${person.first_name}-${person.last_name}`.replace(/ /g, "")

  const { calendarWeekendsExpanded } = useSelector(
    (state: ReduxState) => state.calendar,
    shallowEqual,
  )

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const disabled = splitScreenMode
  const { mergedHolidays, others } = separateHolidaysFromTimeOffs(timeOffs)

  const sortedTimeOffs = isConsistentTimeOffEnabled
    ? sortTimeOffsByHierarchy(timeOffs)
    : [...mergedHolidays, ...others]

  return (
    <PlannerGrid data-test={`timeoff-row-${testId}`} disabled={disabled}>
      <PlannerLeftColumn
        className={cc({ [styles.showBottomBorder]: showBottomBorder })}
      >
        <Clock size={24} />
        <span>Time Off</span>
      </PlannerLeftColumn>
      <PlannerRightColumn rowId={String(person.id)} type="timeOff">
        <DragToCreateOutline
          account={account}
          person={person}
          type="timeOff"
          holidaysOverlappingTimeOffs={holidaysOverlappingTimeOffs}
          canCreate={canCreate}
          companyDefaultMinutes={account.default_full_time_minutes}
        />
        {sortedTimeOffs.map((to) => {
          if (!calendarWeekendsExpanded && isWeekend(parseISO(to.start_date))) {
            return
          }
          return (
            <div key={to.id} className={styles.timeOffContainer}>
              {to.leave_type !== "holiday" && (
                <TimeOffCurtain
                  timeOff={to}
                  contracts={person.contracts}
                  accountDefaultFullTimeMinutes={
                    account.default_full_time_minutes
                  }
                />
              )}
              <TimeOffActionPill
                account={account}
                timeOff={to}
                person={person}
                defaultFullTimeMinutes={account.default_full_time_minutes}
              />
            </div>
          )
        })}
        <OutOfContractCurtains contracts={person.contracts} />
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default PersonTimeOffRow
