import { Tag } from "@blueprintjs/core"
import { useFeature } from "flagged"
import * as React from "react"

type Props = {
  projectId: number
  priority: string | null
}

const PriorityTag = (props: Props) => {
  const feature = useFeature("priority")
  const { priority } = props
  if (!feature || priority == null) {
    return null
  }

  return <Tag minimal>Priority: {priority}</Tag>
}

export { PriorityTag }
