import { PersonRelatedEntityWithPlaceholder } from "@runn/permissions/src/subjects/personNote"
import { ProjectRelatedEntity } from "@runn/permissions/src/subjects/project"
import { UserRelatedEntity } from "@runn/permissions/src/subjects/user"
import type { SetRequired } from "type-fest"

import { PermissionsContextType } from "~/Permissions/PermissionsProvider"
import { usePermissions } from "~/Permissions/usePermissions"

export type MenuType =
  | "project"
  | "project-template"
  | "person"
  | "placeholder-person"
  | "external-person"

export type ProjectMenuType = {
  canViewDashboard: boolean
  canEditDetails: boolean
  canEditBudget: boolean
  canTentative: boolean
  canReschedule: boolean
  canDuplicate: boolean
  canSaveAsTemplate: boolean
  canArchive: boolean
  canDelete: boolean
}

export type ProjectTemplateMenuType = {
  canCreateNewProjectFromTemplate: boolean
  canViewDashboard: boolean
  canEditDetails: boolean
  canEditBudget: boolean
  canDelete: boolean
}

export type PersonMenuType = {
  canViewDashboard: boolean
  canViewDetails: boolean
  canEditDetails: boolean
  canArchive: boolean
  canDelete: boolean
  canRemoveMembershipFromProject: boolean
  canInvite: boolean
  canChangeWorkstream: boolean
  canChangeProjectRole: boolean
}

export type PlaceholderPersonMenuType = {
  canViewDetails: boolean
  canRequest: boolean
  canFindPerson: boolean
  canDuplicate: boolean
  canEditDetails: boolean
  canDelete: boolean
  canComment: boolean
  canChangeWorkstream: boolean
  canChangeProjectRole: boolean
}

type User = UserRelatedEntity["user"]
type Person = PersonRelatedEntityWithPlaceholder["person"]
type Project = ProjectRelatedEntity["project"] & { members?: number[] }
type ProjectTemplate = SetRequired<Project, "members">

export type ExternalPersonMenuType = {
  canActivate: boolean
}

type GetAvailableMenu =
  | {
      type?: "project"
      project: Project
    }
  | {
      type?: "project-template"
      project: ProjectTemplate
    }
  | {
      type?: "person"
      project?: Project
      person: Person
    }
  | {
      type?: "placeholder-person"
      project: Project
      user: User
      person: Person
    }
  | {
      type?: "external-person"
    }

const getProjectMenu = (
  input: { project: Project } & Pick<PermissionsContextType, "can" | "subject">,
): ProjectMenuType => {
  const { project, can, subject } = input

  const projectSubject = subject("Project", project)
  const projectPermissions = {
    view: can("view", projectSubject),
    create: can("create", projectSubject),
    edit: can("edit", projectSubject),
    delete: can("delete", projectSubject),
  }

  const templatePermissions = {
    create: can("create", subject("ProjectTemplate")),
  }

  return {
    canViewDashboard: projectPermissions.view,
    canEditDetails: projectPermissions.edit,
    canEditBudget: projectPermissions.edit,
    canTentative: projectPermissions.edit,
    canReschedule: projectPermissions.edit,
    canDuplicate: projectPermissions.create,
    canSaveAsTemplate: templatePermissions.create,
    canArchive: projectPermissions.edit,
    canDelete: projectPermissions.delete,
  }
}

const getProjectTemplateMenu = (
  input: {
    project: ProjectTemplate
  } & Pick<PermissionsContextType, "can" | "subject">,
): ProjectTemplateMenuType => {
  const { project, can, subject } = input

  const templatePermissions = {
    view: can("view", subject("ProjectTemplate")),
    edit: can("edit", subject("ProjectTemplate")),
    delete: can("delete", subject("ProjectTemplate")),
    createProjectFromTemplate: can(
      "create-from-template",
      subject("ProjectTemplate", project),
    ),
  }
  const budgetPermissions = {
    edit: can("edit", subject("ProjectBudget", { project })),
  }

  return {
    canCreateNewProjectFromTemplate:
      templatePermissions.createProjectFromTemplate,
    canViewDashboard: templatePermissions.view,
    canEditDetails: templatePermissions.edit,
    canEditBudget: templatePermissions.edit && budgetPermissions.edit,
    canDelete: templatePermissions.delete,
  }
}

// NOTE: Sometimes we do *not* have a project; Which makes canRemoveMembershipFromProject invalid
const getPersonMenu = (
  input: {
    project?: Project
    person: Person
  } & Pick<PermissionsContextType, "can" | "subject">,
): PersonMenuType => {
  const { person, project, can, subject } = input

  const personSubject = subject("Person", person)
  const personPermissions = {
    view: can("view", personSubject),
    edit: can("edit", personSubject),
    delete: can("delete", personSubject),
  }

  const projectMemberSubject = subject("ProjectMember", { person, project })

  // Clamp to false when no project is present
  const projectMemberPermissions = {
    edit: project ? can("edit", projectMemberSubject) : false,
    delete: project ? can("delete", projectMemberSubject) : false,
  }

  const invitePermissions = {
    create: can("create", subject("Invitation")),
  }

  return {
    canViewDashboard: personPermissions.view,
    canViewDetails: personPermissions.view,
    canEditDetails: personPermissions.edit,
    canArchive: personPermissions.edit,
    canDelete: personPermissions.delete,
    canRemoveMembershipFromProject: projectMemberPermissions.delete,
    canInvite: invitePermissions.create,
    canChangeProjectRole: projectMemberPermissions.edit,
    canChangeWorkstream: projectMemberPermissions.edit,
  }
}

const getExternalPersonMenu = (
  input: Pick<PermissionsContextType, "can" | "subject">,
): ExternalPersonMenuType => {
  const { can, subject } = input
  return {
    canActivate: can("create", subject("Person")),
  }
}

const getPersonPlaceHolderMenu = (
  input: {
    project: Project
    placeholder: Person
    user: User
  } & Pick<PermissionsContextType, "can" | "subject">,
): PlaceholderPersonMenuType => {
  const { project, placeholder, user, can, subject } = input

  const projectSubject = subject("Project", project)
  const projectPermissions = {
    view: can("view", projectSubject),
    create: can("create", projectSubject),
    edit: can("edit", projectSubject),
    delete: can("delete", projectSubject),
  }

  const placeholderSubject = subject("Placeholder", { project })
  const placeholderPermissions = {
    create: can("create", placeholderSubject),
    edit: can("edit", placeholderSubject),
    delete: can("delete", placeholderSubject),
    view: can("view", placeholderSubject),
  }

  const personRequestSubject = subject("PersonRequest", { project })

  const personRequestPermissions = {
    edit: can("edit", personRequestSubject),
  }

  const personNotePermissions = {
    create: can(
      "create",
      subject("PersonNote", {
        person: placeholder,
        user,
      }),
    ),
  }
  return {
    canViewDetails: placeholderPermissions.view,
    canRequest: projectPermissions.edit && personRequestPermissions.edit,
    canFindPerson: projectPermissions.edit,
    canDuplicate: placeholderPermissions.create,
    canEditDetails: placeholderPermissions.edit,
    canDelete: placeholderPermissions.delete,
    canComment: personNotePermissions.create,
    canChangeProjectRole: placeholderPermissions.edit,
    canChangeWorkstream: placeholderPermissions.edit,
  }
}

export const useAvailableMenu = (input: GetAvailableMenu) => {
  const { can, subject } = usePermissions()
  if (!input.type) {
    return {}
  }

  switch (input.type) {
    case "project":
      return getProjectMenu({ can, subject, project: input.project })
    case "project-template":
      return getProjectTemplateMenu({ can, subject, project: input.project })
    case "person":
      return getPersonMenu({
        can,
        subject,
        project: input.project,
        person: input.person,
      })
    case "placeholder-person":
      return getPersonPlaceHolderMenu({
        can,
        subject,
        project: input.project,
        placeholder: input.person,
        user: input.user,
      })
    case "external-person":
      return getExternalPersonMenu({ can, subject })
  }
}
