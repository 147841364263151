import type { DefaultRowData, Model, RecordProxy } from "./types"

const applyFields = <T extends DefaultRowData>(
  $recordProxy: RecordProxy<T>,
  model: Model<T>,
  fields: T,
) => {
  for (const [key, value] of Object.entries(fields)) {
    if (model.transformFields.hasOwnProperty(key)) {
      const mappedFields = model.transformFields[key](fields)
      mappedFields.forEach(({ key: fieldKey, value: fieldValue }) =>
        $recordProxy.setValue(fieldValue, fieldKey),
      )
    } else {
      $recordProxy.setValue(value, key)
    }
  }
}

export default applyFields
