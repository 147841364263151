/**
 * @generated SignedSource<<553fbb6ef50ce4ec2349f29620d245ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSidePanelContract_person$data = {
  readonly contracts: ReadonlyArray<{
    readonly cost: number | null | undefined;
    readonly employment_type: string;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly job_title: string | null | undefined;
    readonly minutes_per_day: number | null | undefined;
    readonly role: {
      readonly default_hour_cost: number | null | undefined;
      readonly name: string | null | undefined;
    };
    readonly rostered_days: ReadonlyArray<number> | null | undefined;
    readonly start_date: string | null | undefined;
  }>;
  readonly id: number;
  readonly " $fragmentType": "PersonSidePanelContract_person";
};
export type PersonSidePanelContract_person$key = {
  readonly " $data"?: PersonSidePanelContract_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSidePanelContract_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSidePanelContract_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "cost",
          "args": null,
          "kind": "ScalarField",
          "name": "cost_private",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employment_type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "job_title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutes_per_day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rostered_days",
          "storageKey": null
        },
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": "default_hour_cost",
              "args": null,
              "kind": "ScalarField",
              "name": "default_hour_cost_private",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "99d47379204afe7819f344073ff97727";

export default node;
