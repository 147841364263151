export type Color = {
  color: string
  name: string
  darker: string
  lighter: string
  pillLabel: string
  lightHover: string
}

/**
 * PHASE COLORS
 * -----------------------------------------------------------------------------
 **/

export const SEA_BLUE = {
  color: "#6899F1",
  name: "Sea Blue",
  darker: "#6597F1",
  lighter: "#D1E2FF",
  pillLabel: "#4869A6",
  lightHover: "#99BEFF",
}
export const AQUA = {
  color: "#67D0D5",
  name: "Aqua",
  darker: "#5DC6CB",
  lighter: "#B3E8EA",
  pillLabel: "#178085",
  lightHover: "#B3DEEA",
}
export const REEF_GREEN = {
  color: "#9CC5BF",
  name: "Reef Green",
  darker: "#92BBB5",
  lighter: "#CEE2DF",
  pillLabel: "#4C756F",
  lightHover: "#C2D7D3",
}
export const LEAF_GREEN = {
  color: "#9CE277",
  name: "Leaf Green",
  darker: "#92D86D",
  lighter: "#CEF0BB",
  pillLabel: "#30820A",
  lightHover: "#CBF0B8",
}
export const MINT = {
  color: "#84DBA0",
  name: "Mint",
  darker: "#7AD196",
  lighter: "#C1EDCF",
  pillLabel: "#2A8146",
  lightHover: "#B6E3C4",
}
export const CANARY_YELLOW = {
  color: "#FDCD4F",
  name: "Canary Yellow",
  darker: "#F3C345",
  lighter: "#FEE6A7",
  pillLabel: "#916900",
  lightHover: "#F4E0A9",
}
export const SAND = {
  color: "#E8C681",
  name: "Sand",
  darker: "#E0B357",
  lighter: "#F5E6C7",
  pillLabel: "#976E1B",
  lightHover: "#EDD39B",
}
export const PEACHY_ORANGE = {
  color: "#FFB077",
  name: "Peachy Orange",
  darker: "#F5A66D",
  lighter: "#FFD7BB",
  pillLabel: "#AF6027",
  lightHover: "#F5CE95",
}
export const TERRACOTTA = {
  color: "#DDAE9F",
  name: "Terracotta",
  darker: "#D1907B",
  lighter: "#EED6CE",
  pillLabel: "#A25339",
  lightHover: "#E1B6A8",
}
export const CLASSIC_PINK = {
  color: "#F191CC",
  name: "Classic Pink",
  darker: "#E787C2",
  lighter: "#F8C8E5",
  pillLabel: "#AB4B86",
  lightHover: "#F0CCE2",
}
export const LILAC = {
  color: "#CD97DA",
  name: "Lilac",
  darker: "#C27FD1",
  lighter: "#F1E1F4",
  pillLabel: "#A253B6",
  lightHover: "#DCB4E4",
}
export const LAVENDER = {
  color: "#B19DE6",
  name: "Lavender",
  darker: "#A793DC",
  lighter: "#D8CEF3",
  pillLabel: "#7F6BB4",
  lightHover: "#D8D0EB",
}

export const PHASE_COLORS: Color[] = [
  AQUA,
  MINT,
  CANARY_YELLOW,
  PEACHY_ORANGE,
  CLASSIC_PINK,
  LAVENDER,
  REEF_GREEN,
  LEAF_GREEN,
  SAND,
  TERRACOTTA,
  LILAC,
  SEA_BLUE,
]

/**
 * ASSIGNMENT COLORS
 * -----------------------------------------------------------------------------
 **/

export const LIGHT_VIOLET = {
  color: "#9BADE1",
  name: "Light Violet",
  darker: "#788DD0",
  lighter: "#CDD6F0",
  pillLabel: "#55679B",
  lightHover: "#CED5EF",
}

export const SKY_BLUE = {
  color: "#6DC6EC",
  name: "Sky Blue",
  darker: "#63BCE2",
  lighter: "#B6E2F5",
  pillLabel: "#1D769C",
  lightHover: "#B3E6F5",
}

export const ASSIGNMENT_COLORS: Color[] = [
  LIGHT_VIOLET, // default
  SKY_BLUE, // tentative
  PEACHY_ORANGE, // weekends
]

/**
 * ASSIGNMENT AND PHASE COLORS
 * -----------------------------------------------------------------------------
 **/

export const ASSIGNMENT_AND_PHASE_COLORS: Color[] = [
  ...PHASE_COLORS,
  ...ASSIGNMENT_COLORS,
]

export const findColor = (queryColor: string): Color => {
  const object = ASSIGNMENT_AND_PHASE_COLORS.find((p) => queryColor === p.color)
  if (!object) {
    return {
      color: queryColor,
      name: "Unknown Color",
      darker: queryColor,
      lighter: queryColor,
      pillLabel: queryColor,
      lightHover: queryColor,
    }
  }
  return object
}
