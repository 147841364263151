/**
 * @generated SignedSource<<2304d6610666361fd4f52c407b053f21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsDetectedPersonRow_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerCalendarRow_account" | "PlannerLeftColumn_account">;
  readonly " $fragmentType": "ProjectDetailsDetectedPersonRow_account";
};
export type ProjectDetailsDetectedPersonRow_account$key = {
  readonly " $data"?: ProjectDetailsDetectedPersonRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsDetectedPersonRow_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerCalendarRow_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "f1fd567a31225c5543e602a0d6486baa";

export default node;
