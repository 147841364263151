import cc from "classcat"
import React, { useRef, useState } from "react"

import styles from "./ProjectBudget.module.css"

import { formatCurrency } from "~/helpers/CurrencyHelper"
import {
  OtherCost,
  OtherCostWithCalculations,
  addDerivedFields,
} from "~/helpers/otherCosts"

import CurrencyInput from "~/common/CurrencyInput"
import DateInput from "~/common/DateInput"
import Input from "~/common/Input"
import { Delete } from "~/common/react-icons"

import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  cost: OtherCost
  showNonBillableView?: boolean
  autofocus?: boolean
  onUpdate?: (cost: OtherCostWithCalculations) => void
  canDelete?: boolean
  onDelete?: () => void
}
const OtherCostEditRow = (props: Props) => {
  const {
    cost,
    showNonBillableView = false,
    onUpdate,
    canDelete = true,
    onDelete,
  } = props
  const [error, setError] = useState(false)

  const b = useRef(null)
  const [updatedCost, setUpdatedCost] = useState<OtherCostWithCalculations>(
    addDerivedFields(cost),
  )
  b.current = cost

  const handleItemNameRequired = (newCost) => {
    setError(!newCost.name || newCost.name === "")
  }

  const handleInputChange = (value, field) => {
    const newCost = addDerivedFields({ ...updatedCost, [field]: value })
    setUpdatedCost(newCost)
    handleItemNameRequired(newCost)
  }

  // Date change needs its own handler because the various ways of interacting
  // with it make the blur event on its own unreliable
  const handleDateChange = (value) => {
    const newCost = { ...updatedCost, date: value }
    setUpdatedCost(newCost)
    onUpdate && onUpdate(newCost)
  }

  const handleRowUpdate = () => {
    let update = updatedCost
    if (isNaN(updatedCost.cost + updatedCost.charge) || !updatedCost.date) {
      update = {
        ...updatedCost,
        cost: updatedCost.cost ?? 0,
        charge: updatedCost.charge ?? 0,
        date: updatedCost.date ?? new Date(),
      }
      setUpdatedCost(update)
    }
    onUpdate && onUpdate(update)
    handleItemNameRequired(updatedCost)
  }

  const handleRemove = () => onDelete && onDelete()
  const { id, date, margin, charge, profit, name } = updatedCost
  const CURRENCY_HEIGHT = 30

  return (
    <Row>
      <Column>
        <Input
          aria-label="Item"
          value={name}
          onChange={(e) => handleInputChange(e.target.value, "name")}
          onBlur={handleRowUpdate}
          style={{ textAlign: "left" }}
          dataTest={`other-cost-name`}
          name={`other-cost-name-${id}`}
          className={cc([
            styles.otherCostsTextInput,
            {
              [styles.errorBorder]: error,
            },
          ])}
        />
      </Column>
      <Column>
        <DateInput
          textAlignRight
          canClearSelection={false}
          value={date}
          aria-label="Date"
          dateFormat="d MMM yy"
          onChange={handleDateChange}
          inputProps={{
            name: `other-cost-date-${id}`,
            onBlur: handleRowUpdate,
            style: { borderColor: "var(--winter)" },
          }}
          className={styles.otherCostsDateInput}
          dataTest={`other-cost-date`}
        />
      </Column>

      {!showNonBillableView && (
        <Column textAlign="right">
          <span data-test={`other-cost-margin`}>{margin}%</span>
        </Column>
      )}
      {!showNonBillableView && (
        <Column textAlign="right">
          <span data-test={`other-cost-profit`}>{formatCurrency(profit)}</span>
        </Column>
      )}
      <Column>
        <CurrencyInput
          value={updatedCost.cost}
          aria-label="Unit Cost"
          onChange={(val) => handleInputChange(val, "cost")}
          onBlur={handleRowUpdate}
          min={0}
          height={CURRENCY_HEIGHT}
          dataTest="other-cost-cost"
          name={`other-cost-cost-${id}`}
          selectAllOnFocus
        />
      </Column>
      {!showNonBillableView && (
        <Column>
          <CurrencyInput
            value={charge}
            aria-label="Unit Charge"
            onChange={(val) => handleInputChange(val, "charge")}
            onBlur={handleRowUpdate}
            min={0}
            height={CURRENCY_HEIGHT}
            dataTest="other-cost-charge"
            name={`other-cost-charge-${id}`}
            selectAllOnFocus
          />
        </Column>
      )}
      <div
        data-test={`other-cost-delete`}
        onClick={canDelete ? handleRemove : null}
        className={cc([
          styles.deleteIconContainer,
          {
            [styles.disabled]: !canDelete,
          },
        ])}
      >
        <Delete />
      </div>
    </Row>
  )
}

export default OtherCostEditRow
