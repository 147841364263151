import { useFeature } from "flagged"
import React, { useDeferredValue, useEffect, useMemo, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useSelector } from "react-redux"
import {
  Area,
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"

import styles from "./GroupUtilizationBar.module.css"

import { GroupUtilizationBarRelayWrapperQuery$data } from "./__generated__/GroupUtilizationBarRelayWrapperQuery.graphql"

import {
  calcCapacityData,
  calcWorkloadData,
  getCapacityPeople,
  getMergedDailyData,
  getWeekendData,
  getWorkloadAssignments,
} from "~/helpers/workload-calculations"

import { TIME_OFF_TYPES } from "~/ENUMS"
import { ReduxState } from "~/rootReducer"

type Props = {
  isTransparent: boolean
  people: GroupUtilizationBarRelayWrapperQuery$data["people"]
  projects: readonly {
    id: number
    confirmed: boolean
    is_template: boolean
  }[]
  doNotRerender: boolean
  sidePanelIsOpen: boolean
}

type UtilizationData = {
  graphData?: {
    infiniteUtilization: number
    noCapacity: number
    noUtilization: number | null
    overUtilization: number
    utilization: number
  }[]
  weekendDataOnly?: {
    assigned: number
    border: number
  }[]
}

const GroupUtilizationBar = (props: Props) => {
  const { isTransparent, sidePanelIsOpen } = props
  const deferredPeople = useDeferredValue(props.people)
  const deferredProjects = useDeferredValue(props.projects)

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )

  const [isAnimationActive, setIsAnimationActive] = useState(true)

  useEffect(() => {
    if (sidePanelIsOpen) {
      setIsAnimationActive(false)
    }

    if (!sidePanelIsOpen) {
      // Wait for sidepanel to fully close before reverting back to true
      setTimeout(() => setIsAnimationActive(true), 2000)
    }
  }, [sidePanelIsOpen])

  const timeOffs = deferredPeople.flatMap((p) => p.time_offs)

  const timeOffData = isConsistentTimeOffEnabled
    ? timeOffs.filter((t) => !t.minutes_per_day)
    : timeOffs.filter((t) => t.leave_type === TIME_OFF_TYPES.HOLIDAY)

  const dayWidth = calendar.dayWidth

  const startDate = calendar.calendarStartDate
  const endDate = calendar.calendarEndDate

  const calendarWeekendsExpanded = useSelector<ReduxState, boolean>(
    (state) => state.calendar.calendarWeekendsExpanded,
  )

  const utilizationData = useMemo(
    (): UtilizationData => {
      const validProjectIds = deferredProjects.map((p) => p.id)

      const peopleWithVisibleAssignments = deferredPeople.map((p) => ({
        ...p,
        assignments: p.assignments.filter((a) =>
          validProjectIds.includes(a.project_id),
        ),
      }))

      const hasRealPeople = !!peopleWithVisibleAssignments.find(
        (p) => !p.is_placeholder,
      )

      const workloadAssignments = getWorkloadAssignments(
        peopleWithVisibleAssignments,
        [],
        startDate,
      )

      const workloadGraphData = calcWorkloadData(
        startDate,
        endDate,
        workloadAssignments,
        timeOffData,
        deferredProjects,
      )

      const capacityPeople = getCapacityPeople(
        peopleWithVisibleAssignments,
        [],
        startDate,
        endDate,
      )

      const capacityGraphData = calcCapacityData(
        startDate,
        endDate,
        capacityPeople,
      )

      let mergedData = getMergedDailyData(workloadGraphData, capacityGraphData) // The main chart
      let weekendDataOnly = [] // weekends absolutely positioned on top of the main chart

      if (!calendarWeekendsExpanded) {
        weekendDataOnly = getWeekendData(
          mergedData,
          calendar.calStartNum,
          calendar.calEndNum,
          calendarWeekendsExpanded,
        )
        mergedData = mergedData.filter((d) => !d.isWeekend)
      }

      const graphData = mergedData.map((data) => {
        const combinedUtilizationData =
          data.utilizationBillable + data.utilizationNonbillable

        // Show Infinite utilisation - 100% red bar
        if (
          (data.effectiveCapacity === 0 || data.isWeekend) &&
          data.workloadTotal > 0
        ) {
          return {
            utilization: 0,
            overUtilization: 0,
            noUtilization: null,
            infiniteUtilization: 100,
            noCapacity: 0,
          }
        }

        // Show nothing if weekend if within contract and isn't timeoff
        if (data.isWeekend && !data.isTimeOff && data.isContracted) {
          return {
            utilization: 0,
            overUtilization: 0,
            noUtilization: null,
            infiniteUtilization: 0,
            noCapacity: 0,
          }
        }

        // Show no availability & no work - 100% grey bar
        if (data.effectiveCapacity === 0 && hasRealPeople) {
          return {
            utilization: 0,
            overUtilization: 0,
            noUtilization: null,
            infiniteUtilization: 0,
            noCapacity: 100,
          }
        }

        // Show full availablity - Green line
        if (combinedUtilizationData === 0 && hasRealPeople) {
          return {
            utilization: 0,
            overUtilization: 0,
            noUtilization: 0,
            infiniteUtilization: 0,
            noCapacity: 0,
          }
        }
        // Show over utilised - Red graph in top half
        if (combinedUtilizationData > 100) {
          return {
            utilization: 100,
            overUtilization: combinedUtilizationData - 100,
            noUtilization: null,
            infiniteUtilization: 0,
            noCapacity: 0,
          }
        }

        // Show utilise 100 or below - blue graph in bottom half
        return {
          utilization: combinedUtilizationData,
          overUtilization: 0,
          noUtilization: null,
          infiniteUtilization: 0,
          noCapacity: 0,
        }
      })

      // We do this so that we can make the graph run off the end of the screens but including 2 extra days
      // with the same values as the 1st and last days of the calendar
      graphData.push(graphData[graphData.length - 1])
      graphData.unshift(graphData[0])

      return { graphData, weekendDataOnly }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, deferredProjects, deferredPeople],
  )

  // We made the graph go outside of the bounds so we can include two extra days
  // this lets the graph run to the end of the area. Then we just hide the edges of it
  // We also include a bar graphs to get the value points in the middle of each day. Not the start/end
  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <div
        className={`
          ${styles.rightColumn} 
          ${isTransparent ? styles.transparent : ""}
        `}
        style={{
          width: `calc(100% + ${dayWidth * 2}px)`,
          height: "100%",
          marginLeft: `-${dayWidth}px`,
        }}
      >
        <ResponsiveContainer>
          <ComposedChart
            data={utilizationData.graphData}
            margin={{ top: 2, right: 0, left: 0, bottom: 0 }}
            style={{ cursor: "pointer" }}
          >
            <defs>
              <pattern
                id="stripes"
                width="10"
                height="10"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(-45)"
                viewBox="0 0 10 10"
              >
                <rect width="100%" height="100%" fill="var(--white)" />
                <rect width="1" height="10" x="-1" fill="var(--winter)" />
                <rect width="1" height="10" x="1.5" fill="var(--winter)" />
                <rect width="1" height="10" x="4" fill="var(--winter)" />
                <rect width="1" height="10" x="6.5" fill="var(--winter)" />
                <rect width="1" height="10" x="9" fill="var(--winter)" />
              </pattern>
            </defs>
            <YAxis
              hide
              allowDataOverflow
              type="number"
              dataKey="utilization"
              domain={[0, 200]}
            />
            <XAxis dataKey="formattedDate" hide />
            <Bar dataKey="justToMoveStartPoint" barSize={0} />
            <Area
              type="monotone"
              dataKey="infiniteUtilization"
              fill="var(--candy-floss)"
              fillOpacity={1}
              stroke="none"
              isAnimationActive={isAnimationActive}
            />

            <Area
              type="monotone"
              dataKey="noCapacity"
              fill="url(#stripes)"
              fillOpacity={1}
              stroke="none"
              isAnimationActive={isAnimationActive}
            />
            <Area
              type="monotone"
              dataKey="utilization"
              stackId="1"
              fill="var(--light-violet)"
              fillOpacity={1}
              stroke="none"
              isAnimationActive={isAnimationActive}
            />
            <Area
              type="monotone"
              dataKey="overUtilization"
              stackId="1"
              fill="var(--candy-floss)"
              fillOpacity={1}
              stroke="none"
              isAnimationActive={isAnimationActive}
            />
            <Area
              type="monotone"
              dataKey="noUtilization"
              stroke="var(--mint)"
              fillOpacity={1}
              strokeWidth={3}
              isAnimationActive={isAnimationActive}
            />
            <ReferenceLine
              y={100}
              stroke="var(--cloud)"
              strokeDasharray="3 3"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {utilizationData.weekendDataOnly && (
        <div
          className={`
            ${styles.weekends}    
            ${isTransparent ? styles.transparent : ""}
          `}
          style={{
            marginLeft: `${dayWidth * 5 - dayWidth * 2.5}px`, // reposition weekends
          }}
        >
          <ResponsiveContainer>
            <ComposedChart
              data={utilizationData.weekendDataOnly}
              margin={{ top: 2, right: 0, left: 0, bottom: 0 }}
            >
              <YAxis
                hide
                allowDataOverflow
                type="number"
                dataKey="utilization"
                domain={[0, 200]}
              />
              <Bar
                dataKey="body"
                stackId="weekend"
                barSize={3}
                fill="rgb(39 56 126 / 50%)"
              />
              <Bar
                dataKey="border"
                stackId="weekend"
                barSize={3}
                fill="rgb(245 63 89 / 50%)"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  )
}

const propsAreEqual = (prevProps, nextProps) => {
  if (nextProps.doNotRerender === true) {
    return true
  }

  return isDeeplyEqual(prevProps, nextProps)
}

export default React.memo(GroupUtilizationBar, propsAreEqual)
