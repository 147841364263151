import * as fe from "@runn/filter-engine"
import { useMemo } from "react"

import { AllowedPeopleFilter } from "~/helpers/filter-engine"

import { usePeopleFilterSet } from "~/PeoplePlanner/usePeopleFilterSet"
import { selectAnyTentativeProjectsEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

const usePeoplePlannerFilters = (
  viewFilters: AllowedPeopleFilter | undefined,
) => {
  const peopleFilterSet = usePeopleFilterSet()

  const anyTentativeProjectsEnabled = useAppSelector((state) =>
    selectAnyTentativeProjectsEnabled(state.plannerV2.scenarioPlanning),
  )

  return useMemo(
    () => ({
      ...peopleFilterSet,
      filters: fe.filters.simplify(
        fe.filters.and([
          peopleFilterSet.filters,
          viewFilters,
          fe.filters.personIsActive({ value: true }),

          fe.filters.or([
            // Show if not placeholder,
            fe.filters.personIsPlaceholder({ value: false }),
            // or placeholder is on confirmed project
            fe.filters.personIsPlaceholder({ value: true }) &&
              fe.filters.personProjectIsConfirmed({ value: true }),
            // or placeholder is on tentative project with tentative projects being shown.
            anyTentativeProjectsEnabled &&
              fe.filters.personProjectIsConfirmed({ value: false }) &&
              fe.filters.personIsPlaceholder({ value: true }),
          ]),
        ]),
      ),
    }),
    [viewFilters, peopleFilterSet, anyTentativeProjectsEnabled],
  )
}

export default usePeoplePlannerFilters
