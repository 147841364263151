/**
 * @generated SignedSource<<f00651b2e9f344ef09fc8f867adc3fb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPersonForm_person$data = {
  readonly active: boolean;
  readonly actuals_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly archivable: boolean | null | undefined;
  readonly assignments_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly competencies: ReadonlyArray<{
    readonly id: number;
    readonly level: number | null | undefined;
    readonly skill: {
      readonly id: number;
      readonly name: string;
    };
  }>;
  readonly contracts: ReadonlyArray<{
    readonly cost: number | null | undefined;
    readonly employment_type: string;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly job_title: string | null | undefined;
    readonly minutes_per_day: number | null | undefined;
    readonly role: {
      readonly active: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
    };
    readonly rostered_days: ReadonlyArray<number> | null | undefined;
    readonly start_date: string | null | undefined;
  }>;
  readonly custom_checkbox_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: boolean;
  }>;
  readonly custom_date_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: any | null | undefined;
  }>;
  readonly custom_select_values: ReadonlyArray<{
    readonly optionId: number;
    readonly typeId: number;
  }>;
  readonly custom_text_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: string;
  }>;
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly holidays_group: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
  readonly id: number;
  readonly image_key: string | null | undefined;
  readonly last_name: string;
  readonly links: ReadonlyArray<{
    readonly href: string;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly managers: ReadonlyArray<{
    readonly id: number;
    readonly person: {
      readonly id: number;
    };
    readonly user_id: number;
  }>;
  readonly references: any;
  readonly tags: any;
  readonly team: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmContractForm_person" | "ExtLinks_Person" | "PersonActionButtons_person" | "PersonCustomEditor_person">;
  readonly " $fragmentType": "EditPersonForm_person";
};
export type EditPersonForm_person$key = {
  readonly " $data"?: EditPersonForm_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonForm_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPersonForm_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people_managers",
      "kind": "LinkedField",
      "name": "managers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "references",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "teams",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rostered_days",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employment_type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutes_per_day",
          "storageKey": null
        },
        {
          "alias": "cost",
          "args": null,
          "kind": "ScalarField",
          "name": "cost_private",
          "storageKey": null
        },
        {
          "alias": "start_date",
          "args": null,
          "kind": "ScalarField",
          "name": "start_date_runn",
          "storageKey": null
        },
        {
          "alias": "end_date",
          "args": null,
          "kind": "ScalarField",
          "name": "end_date_runn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "job_title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "competencies",
      "kind": "LinkedField",
      "name": "competencies",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "skills",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "holidays_groups",
      "kind": "LinkedField",
      "name": "holidays_group",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_values",
      "kind": "LinkedField",
      "name": "custom_text_values",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_text_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_values",
      "kind": "LinkedField",
      "name": "custom_date_values",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_date_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_values",
      "kind": "LinkedField",
      "name": "custom_select_values",
      "plural": true,
      "selections": [
        {
          "alias": "optionId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_option_id",
          "storageKey": null
        },
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_values",
      "kind": "LinkedField",
      "name": "custom_checkbox_values",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_checkbox_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "assignments_aggregate",
      "kind": "LinkedField",
      "name": "assignments_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "assignments_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "actuals_aggregate",
      "kind": "LinkedField",
      "name": "actuals_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "actuals_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people_links",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "href",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show_in_planner",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonCustomEditor_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExtLinks_Person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonActionButtons_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmContractForm_person"
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "26f3e1f5afdc6fd4475e1b748a9ff107";

export default node;
