import React from "react"

import type { ChartType } from "~/Planner/ChartPanel/types"

import { LegendAvailability } from "./Availability"
import { LegendCapacity } from "./Capacity"
import { LegendUtilization } from "./Utilization"

type Props = {
  type: ChartType
  includeTentative?: boolean
}

const Legend = (props: Props) => {
  const { type, includeTentative } = props

  switch (type) {
    case "availability":
      return <LegendAvailability />
    case "capacity":
      return <LegendCapacity includeTentative={includeTentative} />
    case "utilization":
      return <LegendUtilization />
    default:
      return null
  }
}

export { Legend }
