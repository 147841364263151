import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { MultiSelectAssignments } from "~/helpers/split-screen-helpers"

import { panelClosed } from "./panelSlice"

// TODO: At some point we should move the mode reducer split actions in here
type PersonRoleAssignment = {
  id: number
  role_id: number
  multiSelectAssignments: MultiSelectAssignments
}

type SplitScreenState = {
  actionsDisabled: boolean
  listType: "people" | "placeholders"
  peopleCloned: PersonRoleAssignment[]
  transferEntirePlaceholder: boolean
}

const initialState: SplitScreenState = {
  actionsDisabled: false,
  listType: "people",
  peopleCloned: [],
  transferEntirePlaceholder: false,
}

const splitScreen = createSlice({
  name: "splitScreen",
  initialState,
  reducers: {
    disabledActionButtons: (state) => {
      state.actionsDisabled = true
    },
    enabledActionButtons: (state) => {
      state.actionsDisabled = false
    },
    listTypeChanged: (
      state,
      action: PayloadAction<SplitScreenState["listType"]>,
    ) => {
      state.listType = action.payload
    },
    peopleCloned: (state, action: PayloadAction<PersonRoleAssignment>) => {
      state.peopleCloned = [...state.peopleCloned, action.payload]
    },
    transferEntirePlaceholderSelected: (state) => {
      state.transferEntirePlaceholder = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(panelClosed, (state) => {
      state.actionsDisabled = false
      state.peopleCloned = []
      state.transferEntirePlaceholder = false
    })
  },
})

const { actions, reducer } = splitScreen
export const {
  disabledActionButtons,
  enabledActionButtons,
  listTypeChanged,
  peopleCloned,
  transferEntirePlaceholderSelected,
} = actions
export default reducer
