// @ts-ignore
const accountMinutes = window.account_full_time_minutes || 480

export type RateType = "days" | "hours"

type RateCard = {
  role_charge_out_rates: ReadonlyArray<{
    role_id: number
    charge_out_rate: number
  }>
  blended_rate_card: boolean
  blended_rate: number
}

export const isStandardOrInternal = (name: string) => {
  return name === "Internal" || name === "Standard"
}

export const convertDayRatesToHourRates = (
  dayRate: number,
  companyMinutes: number = accountMinutes,
) => dayRate / (companyMinutes / 60)

export const convertHourRatesToDayRates = (
  hourRate: number,
  companyMinutes: number = accountMinutes,
) => hourRate * (companyMinutes / 60)

export const toggleRatesByType = (
  amount: number,
  type: RateType,
  companyMinutes: number = accountMinutes,
) => {
  if (!amount) {
    return null
  }
  if (type === "days") {
    return convertHourRatesToDayRates(amount, companyMinutes)
  }
  return convertDayRatesToHourRates(amount, companyMinutes)
}

/**
 * Provide the rate that is relevant to the company's rate type
 * given a canonical value from the database.
 */
export const companyRate = (
  canonicalRate: number,
  type: RateType,
  companyMinutes: number = accountMinutes,
) =>
  type === "days"
    ? convertHourRatesToDayRates(canonicalRate, companyMinutes)
    : canonicalRate

/**
 * Provide a rate that is relevant to the database given
 * a company rate, i.e. user input
 */
export const canonicalRate = (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  companyRate: number,
  type: RateType,
  companyMinutes: number = accountMinutes,
) =>
  type === "days"
    ? convertDayRatesToHourRates(companyRate, companyMinutes)
    : companyRate

export const getDefaultRate = (rateCard: RateCard, roleId: number) =>
  rateCard.blended_rate_card
    ? rateCard.blended_rate
    : rateCard.role_charge_out_rates.find((rc) => rc.role_id === roleId)
        ?.charge_out_rate || 0

export default {
  convertDayRatesToHourRates,
  convertHourRatesToDayRates,
  toggleRatesByType,
  companyRate,
  canonicalRate,
  getDefaultRate,
}
