import { dateHelpers } from "@runn/calculations"
import {
  addBusinessDays,
  addWeeks,
  differenceInCalendarDays,
  isWeekend,
  subBusinessDays,
} from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PlannerDatePicker.module.css"

import { PlannerDatePicker_account$key } from "./__generated__/PlannerDatePicker_account.graphql"

import { getItemMetaData } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"
import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"
import {
  getClosestNextWorkingDay,
  getClosestPreviousWorkingDay,
} from "~/helpers/holiday-helpers"

import Dialog from "~/common/Dialog"
import { moveDateRange } from "~/common/calendar.reducer"

import { setHighlightedItemData } from "~/GlobalState"
import { ReduxState } from "~/rootReducer"

import { PopoverContextMenu } from "../PillActions/PillActions"

import FullPicker from "./FullPicker"
import MiniPicker from "./MiniPicker"
import TimeOffMiniPicker from "./TimeOffMiniPicker"

const PlannerDatePicker = (props: Props) => {
  const {
    assignment,
    onDelete,
    allowScroll,
    isTimeOff,
    mini,
    moveDateRangeDispatch,
    closePopover,
    onSubmit,
    onMultiSubmit,
    person,
    personId,
    projectId,
    setNumOfDays,
    handleMiniEndDateChange,
    handleCancel,
    isPopoverOpen,
    allowDelete,
    client,
    project,
    roleName,
    multiPerson,
    timeOff,
    calendar,
    onRepeatChange,
    setDefaultPhaseId,
    defaultPhaseId,
    calendarWeekendsExpanded,
    nonWorkingDay,
    popoverContextMenu,
  } = props

  const account = useFragment(
    graphql`
      fragment PlannerDatePicker_account on accounts {
        id
        default_full_time_minutes
        ...MiniPicker_account
        ...TimeOffMiniPicker_account
        ...FullPicker_account
      }
    `,
    props.account,
  )

  let item

  if (timeOff) {
    item = timeOff
  }
  if (assignment) {
    item = assignment
  }

  const isNonBillableProject = project?.pricing_model === "nb"

  const contractMinutes = getMinutesPerDayForDate(
    props.startDate,
    person.contracts,
    account.default_full_time_minutes,
  )

  const initialMinutesPerDay =
    props.minutesPerDay || props.minutesPerDay === 0
      ? props.minutesPerDay
      : contractMinutes

  const [showFullCalendar, setShowFullCalendar] = useState(false)
  const [selectedDates, setSelectedDates] = useState<[Date, Date]>([
    props.startDate ? dateHelpers.parseRunnDate(props.startDate) : null,
    props.endDate ? dateHelpers.parseRunnDate(props.endDate) : null,
  ])
  const [minutesPerDay, setMinutesPerDay] = useState(initialMinutesPerDay)
  const [itemNote, setItemNote] = useState(item ? item.note : "")
  const [isBillable, setIsBillable] = useState(
    assignment ? assignment.is_billable : !isNonBillableProject,
  )
  const [repeat, setRepeat] = useState<{
    repeatEvery: number
    numberOfAssignments: number
  }>(undefined)

  const [currentPhase, setCurrentPhase] = useState(
    assignment?.phase_id || defaultPhaseId,
  )

  // These are relatively expensive and don't need to be recalculated on
  // render. Thus memo.
  const timeOffs = useMemo(() => {
    if (!person.time_offs || project?.is_template) {
      return []
    }

    return person.time_offs.map((to) => ({
      ...to,
      startDate: to.start_date,
      endDate: to.end_date,
    }))
  }, [person.id, projectId]) // eslint-disable-line react-hooks/exhaustive-deps

  const metaData = getItemMetaData({
    startDate: selectedDates[0] || new Date(),
    endDate: selectedDates[1],
    // Remove partial time offs so they don't interact with any pills
    timeOffs: timeOffs.filter((to) => !to.minutes_per_day),
    isTimeOff,
    includeWeekends: calendarWeekendsExpanded,
    nonWorkingDay: nonWorkingDay,
  })

  const initialWorkingDays = metaData.totalWorkingDays || 1

  const [workingDays, setWorkingDays] = useState<{
    value: number
    label: string
  }>({
    value: initialWorkingDays,
    label: String(initialWorkingDays),
  })

  const handleDelete = () => {
    track("Assignment Deleted")
    if (onDelete) {
      onDelete()
    }
  }

  const updateCurrentPhase = (phase) => {
    setCurrentPhase(phase.value)
    if (setDefaultPhaseId) {
      setDefaultPhaseId(phase.value)
    }
  }

  if (showFullCalendar) {
    track("Assignment Date Picker Selected")
  }

  const calculateEndDate = (numWorkingDays: number) => {
    // Fallback to start date if no end date is available
    let endDate = selectedDates[1] || selectedDates[0]
    const differencesInDays = numWorkingDays - metaData.totalWorkingDays || 0

    if (differencesInDays > 0) {
      endDate = getClosestNextWorkingDay(
        timeOffs,
        addBusinessDays(endDate, differencesInDays),
      ).date
    }

    if (differencesInDays < 0) {
      endDate = getClosestPreviousWorkingDay(
        timeOffs,
        subBusinessDays(endDate, Math.abs(differencesInDays)),
      ).date
    }

    return endDate
  }

  const saveNote = (note: string) => {
    track("Assignment Note Added")
    setItemNote(note)
  }

  useEffect(() => {
    if (onRepeatChange) {
      onRepeatChange(repeat)
    }
  }, [repeat]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Because we use 'useBackdrop' it stops scrolling.
    if (allowScroll) {
      const body = document.getElementsByTagName("body")[0]
      body.style.overflowX = "auto"
    }

    return () => {
      if (allowScroll) {
        const body = document.getElementsByTagName("body")[0]
        body.style.overflowX = null
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const modifiers = useMemo(() => {
    // get the timeoff in current assignment to display
    const formattedTimeOffs = timeOffs.map((tOff) => {
      if (props.itemId === tOff.id) {
        return false
      }
      return {
        from: dateHelpers.parseRunnDate(tOff.startDate),
        to: dateHelpers.parseRunnDate(tOff.endDate),
      }
    })

    return {
      timeOff: formattedTimeOffs.filter((t) => typeof t !== "boolean"),
      notAllowed: [],
    }
  }, [person.id, projectId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedDates = (values: [Date, Date]) => {
    // If no dates are selected. Dont change anything.
    if (values.every((v) => !v)) {
      return
    }

    // If only one day exists, always use that as start date
    if (values.includes(null)) {
      const dateToUse = values.find((v) => v)
      return setSelectedDates([dateToUse, null])
    }
    // Only change for mini picker, and when we have an end date.
    // Changing before save on Full Picker can crash app because
    // User can choose an end date before a start date.
    if (mini && !showFullCalendar && handleMiniEndDateChange && values[1]) {
      handleMiniEndDateChange(values[1])
    }

    setSelectedDates(values)
  }

  const handleSubmit = () => {
    if (!selectedDates[0]) {
      return
    }

    const submitStartDate = selectedDates[0]
    const submitEndDate = selectedDates[1] || selectedDates[0]

    const trackProperties = {
      non_working_day: nonWorkingDay,
      days_count: differenceInCalendarDays(submitStartDate, submitEndDate) + 1,
      is_time_off: isTimeOff,
      repeat: repeat,
      minutes_per_day: minutesPerDay,
    }

    if (assignment) {
      track("Assignment Updated", trackProperties)
    } else {
      track("Assignment Created", trackProperties)
    }

    if (isTimeOff) {
      const maxMinutes = contractMinutes
      onSubmit({
        itemId: props.itemId,
        personId,
        note: itemNote,
        minutesPerDay: minutesPerDay >= maxMinutes ? null : minutesPerDay,
        startDate: submitStartDate,
        endDate: submitEndDate,
      })
      props.handleCancel()
    } else {
      const newAssignment = {
        itemId: assignment ? assignment.id : props.itemId,
        projectId: props.projectId,
        roleId: props.roleId,
        personId,
        minutesPerDay,
        startDate: submitStartDate,
        endDate: submitEndDate,
        is_billable: isBillable,
        is_template: project.is_template,
        note: itemNote,
        phaseId: currentPhase,
        non_working_day: nonWorkingDay,
      }

      if (repeat) {
        const repeatingAssignments = []
        for (let i = 0; i < repeat.numberOfAssignments; i++) {
          const repeatingAssignment = {
            ...newAssignment,
            startDate: addWeeks(submitStartDate, repeat.repeatEvery * i),
            endDate: addWeeks(submitEndDate, repeat.repeatEvery * i),
          }
          repeatingAssignments.push(repeatingAssignment)
        }

        // For safety encase we forget onMultiSubmit somewhere. we just use onSubmit as a backup.
        onMultiSubmit
          ? onMultiSubmit(repeatingAssignments)
          : repeatingAssignments.forEach((a) => onSubmit(a))
      } else {
        onSubmit(newAssignment)
      }
    }

    if (
      Number(dateHelpers.formatToRunnDate(submitEndDate)) <
        calendar.calStartNum ||
      Number(dateHelpers.formatToRunnDate(submitStartDate)) > calendar.calEndNum
    ) {
      moveDateRangeDispatch(submitStartDate) // If the assignment is outside of the visible view. Move the calendar.
    }
  }

  const isSaveDisabled = () => {
    if (!selectedDates[0] || !selectedDates[1]) {
      return true
    }

    const [startDate, endDate] = selectedDates

    if (isWeekend(startDate) || isWeekend(endDate)) {
      return true
    }

    if (workingDays.value === 0) {
      return true
    }

    return !isTimeOff && minutesPerDay < 0
  }

  const stopEventBubbling = (e): void => {
    e.stopPropagation()
  }

  useEffect(() => {
    const startDate = selectedDates[0]
    let endDate = selectedDates[1]

    if (!endDate || nonWorkingDay) {
      return
    }

    if (setNumOfDays) {
      setNumOfDays(metaData.totalDays)
    }

    if (isTimeOff) {
      // No need to do the checks below if this is a time off
      return
    }

    // If days is changed via days input, then loop through
    // adding days until it's over timeoff.
    if (workingDays.value !== metaData.totalWorkingDays) {
      const fallbackEndDate = endDate || startDate
      // Only update if there has been a change
      if (workingDays.value > metaData.totalWorkingDays) {
        endDate = addBusinessDays(fallbackEndDate, 1)
      }
      if (workingDays.value < metaData.totalWorkingDays) {
        endDate = subBusinessDays(fallbackEndDate, 1)
      }
      return handleSelectedDates([startDate, endDate])
    }

    const pillDraggedOnFullDayTimeoff = metaData.timeOffsDaysWithinRange.find(
      (to) => to === dateHelpers.formatToRunnDate(endDate),
    )

    if (pillDraggedOnFullDayTimeoff) {
      // This is to handle situations where user changes input
      // and the end date lands on a timeoff by looping back until
      // its no longer on a top off.
      handleSelectedDates([startDate, subBusinessDays(endDate, 1)])
    }
  }, [selectedDates]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWorkingDays = (intValue: number) => {
    const label = String(intValue ?? "")
    // If the string value is empty "", just fallback on the previous value for the integer
    const value = label === "" ? workingDays.value : intValue
    setWorkingDays({ value, label })

    handleSelectedDates([selectedDates[0], calculateEndDate(intValue)])
  }

  if (mini) {
    return (
      <div className={styles.plannerCalendar} onMouseDown={stopEventBubbling}>
        {!showFullCalendar &&
          (!isTimeOff ? (
            <MiniPicker
              account={account}
              assignment={assignment}
              nonWorkingDay={nonWorkingDay}
              totalWorkingDays={workingDays}
              minutesPerDay={minutesPerDay}
              setMinutesPerDay={setMinutesPerDay}
              selectedDates={selectedDates}
              handleSelectedDates={handleSelectedDates}
              closePopover={closePopover}
              handleCancel={handleCancel}
              onDelete={onDelete && handleDelete}
              person={person}
              setShowFullCalendar={setShowFullCalendar}
              setHighlightedItemData={setHighlightedItemData}
              handleSubmit={handleSubmit}
              isBillable={isBillable}
              setIsBillable={setIsBillable}
              isNonBillableProject={isNonBillableProject}
              editNote={saveNote}
              itemNote={itemNote}
              isPopoverOpen={isPopoverOpen}
              setRepeat={setRepeat}
              repeat={repeat}
              currentPhase={currentPhase}
              setCurrentPhase={updateCurrentPhase}
              phases={project?.phases || []}
              onChangeWorkingDays={onChangeWorkingDays}
              contextMenu={popoverContextMenu}
            />
          ) : (
            <TimeOffMiniPicker
              account={account}
              timeoff={timeOff}
              nonWorkingDay={nonWorkingDay}
              totalWorkingDays={workingDays}
              minutesPerDay={minutesPerDay}
              setMinutesPerDay={setMinutesPerDay}
              selectedDates={selectedDates}
              handleSelectedDates={handleSelectedDates}
              closePopover={closePopover}
              handleCancel={handleCancel}
              onDelete={onDelete && handleDelete}
              person={person}
              setShowFullCalendar={setShowFullCalendar}
              setHighlightedItemData={setHighlightedItemData}
              handleSubmit={handleSubmit}
              editNote={saveNote}
              itemNote={itemNote}
              onChangeWorkingDays={onChangeWorkingDays}
              contextMenu={popoverContextMenu}
            />
          ))}
        <Dialog isOpen={showFullCalendar} onClose={handleCancel}>
          <FullPicker
            account={account}
            handleSelectDates={handleSelectedDates}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            isTimeOff={isTimeOff}
            minutesPerDay={minutesPerDay}
            setMinutesPerDay={setMinutesPerDay}
            modifiers={modifiers}
            onDelete={onDelete && handleDelete}
            person={person}
            saveDisabled={isSaveDisabled()}
            selectedDates={selectedDates}
            totalWorkingDays={workingDays}
            client={client}
            project={project}
            allowDelete={allowDelete}
            roleName={roleName}
            multiPerson={multiPerson}
            currentPhase={currentPhase}
            setCurrentPhase={updateCurrentPhase}
            phases={project?.phases || []}
            onChangeWorkingDays={onChangeWorkingDays}
            timeOffs={timeOffs}
            isBillable={isBillable}
            setIsBillable={setIsBillable}
            isNonBillableProject={isNonBillableProject}
          />
        </Dialog>
      </div>
    )
  }

  return (
    <div className={styles.plannerCalendar} onMouseDown={stopEventBubbling}>
      <FullPicker
        account={account}
        handleSelectDates={handleSelectedDates}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isTimeOff={isTimeOff}
        nonWorkingDay={nonWorkingDay}
        minutesPerDay={minutesPerDay}
        setMinutesPerDay={setMinutesPerDay}
        modifiers={modifiers}
        onDelete={onDelete && handleDelete}
        person={person}
        saveDisabled={isSaveDisabled()}
        selectedDates={selectedDates}
        totalWorkingDays={workingDays}
        client={client}
        project={project}
        allowDelete={allowDelete}
        roleName={roleName}
        multiPerson={multiPerson}
        currentPhase={currentPhase}
        setCurrentPhase={updateCurrentPhase}
        phases={project?.phases || []}
        onChangeWorkingDays={onChangeWorkingDays}
        timeOffs={timeOffs}
        isBillable={isBillable}
        setIsBillable={setIsBillable}
        isNonBillableProject={isNonBillableProject}
        editNote={saveNote}
        itemNote={itemNote}
      />
    </div>
  )
}

type Props = {
  account: PlannerDatePicker_account$key
  assignment?: Assignment
  nonWorkingDay?: boolean
  timeOff?: TimeOff
  onDelete?: () => void
  allowScroll?: boolean
  allowDelete?: boolean
  isTimeOff?: boolean
  mini?: boolean
  onSubmit: (assignment: OnSubmitProps) => void
  onMultiSubmit?: (assignments: OnSubmitProps[]) => void
  person: Person
  personId?: number
  projectId?: number
  closePopover?: () => void
  setNumOfDays?: (numOfDays: number) => void
  handleMiniEndDateChange?: (endDate: Date) => void
  moveDateRangeDispatch: (movedDateRange: Date) => void
  handleCancel?: () => void
  itemId?: number
  description?: string
  roleId?: number
  startDate?: string
  endDate?: string
  minutesPerDay?: number
  client?: Client
  project?: Project
  isPopoverOpen?: boolean
  roleName?: string
  multiPerson?: Person[]
  openNoteDialog?: () => void
  calendar: ReduxState["calendar"]
  onRepeatChange?: (repeats: {
    repeatEvery: number
    numberOfAssignments: number
  }) => void
  setDefaultPhaseId?: (phaseId: number) => void
  defaultPhaseId?: number
  calendarWeekendsExpanded: boolean
  popoverContextMenu?: PopoverContextMenu
}

type Assignment = {
  id?: number
  phase_id?: number
  minutes_per_day: number
  start_date: string
  end_date: string
  is_billable: boolean
  is_template: boolean
  note?: string
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  note?: string
  leave_type: string
  minutes_per_day: number
}

type Person = {
  id: number
  first_name: string
  last_name: string
  assignments: ReadonlyArray<{
    project_id: number
    role_id: number
    start_date: string
    end_date: string
  }>
  contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      id: number
      name: string
    }
  }>
  time_offs?: ReadonlyArray<TimeOff>
}

export type OnSubmitProps = {
  itemId: number
  projectId?: number
  roleId?: number
  personId: number
  minutesPerDay?: number
  startDate: Date
  endDate: Date
  is_template?: boolean
  description?: string
  is_billable?: boolean
  note?: string
  phaseId?: number
  workstreamId?: number
  non_working_day?: boolean
}

type Client = {
  id: number
  image_key: string
  website: string
  name: string
}

type Project = {
  id: number
  pricing_model: string
  is_template: boolean
  confirmed?: boolean
  name?: string
  assignments?: Assignment[]
  phases?: readonly Phases[]
}

type Phases = {
  id: number
  color: string
  name: string
  start_date?: string
  end_date?: string
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
})

const connector = connect(mapStateToProps, {
  moveDateRangeDispatch: moveDateRange,
})
export default connector(PlannerDatePicker)
