import { DatePicker } from "@blueprintjs/datetime"
import { dateHelpers } from "@runn/calculations"
import {
  addWeeks,
  addYears,
  isSameDay,
  isSameMonth,
  startOfISOWeek,
  subYears,
} from "date-fns"
import React, { useState } from "react"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

type Props = {
  closeDialog: () => void
  onSubmit: (date: Date) => void
  currentStartDate: string
  hasActuals: boolean
  projectName: string
}

const ModalReschedule = (props: Props) => {
  const { closeDialog, onSubmit, currentStartDate, hasActuals, projectName } =
    props

  const [rescheduledDate, setRescheduledDate] = useState(undefined)
  const [isRescheduling, setIsRescheduling] = useState(false)

  const headerTitle = `Reschedule ${projectName}`

  const handleClose = () => {
    closeDialog()
  }

  if (!currentStartDate) {
    return (
      <ModalFormWrapper
        headerTitle={headerTitle}
        helpLink="https://help.runn.io/en/articles/4721837-reschedule-a-project"
      >
        <ModalBody>
          <p>
            You can't reschedule a project that has no assignments or phases
          </p>
        </ModalBody>
        <ModalFooter>
          <Button text="Ok" tabIndex={-1} onClick={() => handleClose()} />
        </ModalFooter>
      </ModalFormWrapper>
    )
  }

  const currentStartWeek = startOfISOWeek(
    dateHelpers.parseRunnDate(currentStartDate),
  )

  const minDate = subYears(new Date(), 1)
  const maxDate = addYears(new Date(), 3)

  const handleDateChange = (date) => {
    setRescheduledDate(startOfISOWeek(date))
  }

  const handleSubmit = () => {
    onSubmit(rescheduledDate)
    setIsRescheduling(true)
  }

  const dayPickerProps = {
    disabledDays: [{ daysOfWeek: [0, 2, 3, 4, 5, 6] }],
    firstDayOfWeek: 1,
    onMonthChange: (date) => {
      const startOfTheWeek = startOfISOWeek(date)

      if (!isSameMonth(date, startOfTheWeek)) {
        return setRescheduledDate(addWeeks(startOfTheWeek, 1))
      }
    },
  }

  if (hasActuals) {
    return (
      <ModalFormWrapper
        headerTitle={headerTitle}
        helpLink="https://help.runn.io/en/articles/4721837-reschedule-a-project"
      >
        <ModalBody>
          <p>
            You can't reschedule a project that has actuals. You must manually
            move assignments instead.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button text="Ok" tabIndex={-1} onClick={() => handleClose()} />
        </ModalFooter>
      </ModalFormWrapper>
    )
  }

  const notChanged = isSameDay(rescheduledDate, currentStartWeek)

  return (
    <ModalFormWrapper
      headerTitle={headerTitle}
      helpLink="https://help.runn.io/en/articles/4721837-reschedule-a-project"
    >
      <ModalBody>
        <p>
          Rescheduling will move the first Assignment/Milestone/Phase to the
          selected week, and keep the same spacing between all items.
        </p>
        <p style={{ marginBottom: 2 }}>
          <b style={{ display: "inline-block", width: 150 }}>
            Current Start Week:{" "}
          </b>
          {currentStartWeek.toDateString()}
        </p>
        <p>
          <b style={{ display: "inline-block", width: 150 }}>
            New Start Week:{" "}
          </b>
          {rescheduledDate && rescheduledDate.toDateString()}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DatePicker
            onChange={handleDateChange}
            highlightCurrentDay={true}
            defaultValue={currentStartWeek}
            dayPickerProps={dayPickerProps}
            minDate={minDate}
            maxDate={maxDate}
            canClearSelection={false}
            value={rescheduledDate}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={closeDialog} tabIndex={-1} />
        <Button
          text="Reschedule"
          intent="primary"
          loading={isRescheduling}
          onClick={handleSubmit}
          outlined={false}
          disabled={isRescheduling || notChanged || !rescheduledDate}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default ModalReschedule
