import { graphql } from "react-relay"

import { PlaceholderBulkCreateMutation } from "./__generated__/PlaceholderBulkCreateMutation.graphql"
import { PlaceholderUpdateMutation } from "./__generated__/PlaceholderUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { addLinkedRecords, commitMutationPromise } from "./helpers"
import * as relayids from "~/helpers/relayids"

import { relayPeopleLocation } from "~/GLOBALVARS"

const placeholderBulkCreateMutation = graphql`
  mutation PlaceholderBulkCreateMutation(
    $input: PlaceholderBulkCreateInput!
    $plannerStartDate: date!
    $peopleFilter: people_bool_exp
  ) {
    action_placeholder_bulk_create(input: $input) {
      person {
        id
        active
        first_name
        last_name
        email
        is_placeholder
        account_id
        tags
        team_id
        references
        image_key
        placeholder_count
        created_at
        team {
          id
          name
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          cost: cost_private
          minutes_per_day
          employment_type
          role_id
          role {
            id
            name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          is_template
          project_id
          person_id
          role_id
          phase_id
          workstream_id
          non_working_day
          note
          total_minutes
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        competencies {
          id
          level
          skill_id
          skill {
            name
            id
          }
        }
        people_notes {
          id
        }
        person_requests {
          id
        }
        project_memberships {
          id
          is_placeholder
          project {
            id
            name
          }
        }
        placeholder_suggestions {
          suggested_person_id
          suggested_person {
            id
            ...SuggestedPerson_person
          }
        }
        ...PersonCustomEditor_person @relay(mask: false)
      }
      account {
        id
        people(where: $peopleFilter) {
          id
        }
        roles {
          id
          name
          active
          default_hour_cost: default_hour_cost_private
          contracts(
            where: { person: { is_placeholder: { _eq: true } } }
            limit: 1
            order_by: { created_at: desc }
          ) {
            person {
              first_name
              last_name
              placeholder_count
              created_at
            }
          }
        }
        ...TeamSelector_account
      }
    }
  }
`

export const placeholderBulkCreateRelay = async (
  variables: PlaceholderBulkCreateMutation["variables"] & {
    _peopleFilter?: string
  },
) => {
  const data = await commitMutationPromise<PlaceholderBulkCreateMutation>(
    environment,
    {
      mutation: placeholderBulkCreateMutation,
      variables,
      updater: (store, _data) => {
        if (variables._peopleFilter) {
          const createdPlaceholders = _data.action_placeholder_bulk_create.map(
            (res) => res.person,
          )
          const account = store.get(
            relayids.accounts.encode(
              _data.action_placeholder_bulk_create[0].account.id,
            ),
          )
          const placeholders = createdPlaceholders.map((placeholder) =>
            store.get(relayids.people.encode(placeholder.id)),
          )

          addLinkedRecords(
            account,
            relayPeopleLocation(variables._peopleFilter),
            placeholders,
          )
        }
      },
    },
  )

  const placeholders = data.action_placeholder_bulk_create.map(
    (res) => res.person,
  )

  return placeholders
}

const placeholderUpdateMutation = graphql`
  mutation PlaceholderUpdateMutation(
    $input: PlaceholderUpdateInput!
    $replacePlaceholderSuggestedPeopleInput: ReplacePlaceholderSuggestedPeopleInput!
  ) {
    replacePlaceholderSuggestedPeople(
      input: $replacePlaceholderSuggestedPeopleInput
    ) {
      suggestions {
        suggestedPersonId
      }
    }
    action_placeholder_update(input: $input) {
      person {
        id
        first_name
        last_name
        team_id
        tags
        assignments {
          id
          role_id
        }
        contracts {
          id
          role_id
          cost: cost_private
          role {
            id
            name
          }
        }
        team {
          id
          name
        }
        competencies {
          id
          level
          person_id
          skill {
            id
            name
          }
        }
        placeholder_suggestions {
          suggested_person_id
          suggested_person {
            id
            ...SuggestedPerson_person
          }
        }
        ...PersonCustomEditor_person @relay(mask: false)
      }
      account {
        id
        people_tags: tags(where: { model: { _eq: "person" } }) {
          id
          name
          model
          archived
        }
        tags {
          id
          name
          model
          archived
        }
        competencies {
          id
        }
        skills {
          id
        }
        id
        roles {
          id
          name
          active
          default_hour_cost: default_hour_cost_private
          contracts(
            where: { person: { is_placeholder: { _eq: true } } }
            limit: 1
            order_by: { created_at: desc }
          ) {
            person {
              first_name
              last_name
              placeholder_count
              created_at
            }
          }
        }
        ...TeamSelector_account
      }
    }
  }
`

export const placeholderUpdateRelay = async (
  variables: PlaceholderUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<PlaceholderUpdateMutation>(
    environment,
    {
      mutation: placeholderUpdateMutation,
      variables,
    },
  )

  return data.action_placeholder_update.person
}
