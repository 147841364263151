import {
  NumericInput as BlueprintNumericInput,
  Icon,
  NumericInputProps,
} from "@blueprintjs/core"
import numbro from "numbro"
import React, { useEffect, useState } from "react"

import styles from "./NumericInput.module.css"

import { showToast } from "~/containers/ToasterContainer"

// This custom NumericInput is to prevent the value from going up and down
// while pressing "enter" if the arrows are active.

type NumericInput = {
  id?: string
  dataTest?: string
  integer?: boolean
  name?: string
  tabIndex?: number
  style?: Record<string, any>
  showArrows?: boolean
  error?: boolean
  onBlur?: (e) => void
  mantissa?: number
  trimMantissa?: boolean
  invokeChangeOnClamp?: boolean
} & NumericInputProps &
  React.InputHTMLAttributes<any>

const formatInput = (value: string, mantissa = 0, trimMantissa = false) => {
  if (!value) {
    return ""
  }
  return numbro(value).format({
    thousandSeparated: true,
    mantissa,
    optionalMantissa: true,
    trimMantissa,
  })
}

const NumericInput = (props: NumericInput) => {
  const {
    dataTest,
    showArrows,
    error,
    integer,
    mantissa,
    trimMantissa,
    invokeChangeOnClamp = false,
    ...rest
  } = props
  const [value, setValue] = useState(
    formatInput(String(props.value), mantissa, trimMantissa),
  )

  useEffect(() => {
    setValue(formatInput(String(props.value), mantissa, trimMantissa))
  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const onValueChange = (_val, valueString) => {
    // Handle if they delete the entire value
    if (!valueString) {
      return props.onValueChange(undefined, "", null)
    }

    const val = numbro.unformat(valueString)
    if (isNaN(Number(val))) {
      return
    }

    if (props.min !== undefined && val < props.min) {
      const strValue = String(props.min)
      setValue(String(props.min))
      invokeChangeOnClamp && props.onValueChange(props.min, strValue, null)
      return
    }
    if (props.max !== undefined && val > props.max) {
      const strValue = String(props.max)
      setValue(String(props.max))
      invokeChangeOnClamp && props.onValueChange(props.max, strValue, null)
      return
    }

    if (!props.integer && valueString.includes(".")) {
      setValue(valueString)
    } else {
      setValue(formatInput(String(val), mantissa, trimMantissa))
    }

    if (props.integer) {
      if (!Number.isInteger(val)) {
        showToast({
          message: `Value has been rounded`,
          type: "warning",
        })
      }

      const roundedVal = Math.round(val)
      setValue(formatInput(`${roundedVal}`))
      return props.onValueChange(roundedVal, `${roundedVal}`, null)
    }

    props.onValueChange(val, valueString, null)
  }

  const stepSize = props.stepSize || 1

  const stepUp = () => {
    onValueChange(
      Number(props.value) + stepSize,
      `${Number(props.value) + stepSize}`,
    )
  }

  const stepDown = () => {
    onValueChange(
      Number(props.value) - stepSize,
      `${Number(props.value) - stepSize}`,
    )
  }

  const onClickUp = () => {
    if (props.disabled) {
      return
    }
    stepUp()
  }
  const onClickDown = () => {
    if (props.disabled) {
      return
    }
    if ((props.value as number) > 1) {
      stepDown()
    }
  }

  const handleKeyDown = (e) => {
    // if integer prevent decimal (190)
    if (e.keyCode === 190 && props.integer) {
      e.preventDefault()
    }

    // up arrow key
    if (e.keyCode === 38) {
      stepUp()
    }

    // down arrow key
    if (e.keyCode === 40) {
      stepDown()
    }
  }

  const hasError =
    error ||
    (value && numbro.unformat(value) < props.min) ||
    (value && numbro.unformat(value) > props.max)

  return (
    <div className={`${styles.inputWrapper} ${hasError && styles.error} `}>
      <BlueprintNumericInput
        {...rest}
        data-test={dataTest}
        id={props.id || props.name}
        name={props.name}
        // Pass min/max as undefined as blueprint wont allow us to format
        // if they have values. Instead we do a check in onValueChange
        min={undefined}
        max={undefined}
        value={value}
        onValueChange={onValueChange}
        selectAllOnFocus={props.selectAllOnFocus}
        disabled={props.disabled}
        // hide the exisiting up/down buttons as we create custom arrows below
        buttonPosition="none"
        className={props.className}
        tabIndex={props.tabIndex}
        style={props.style}
        stepSize={props.stepSize}
        majorStepSize={null}
        allowNumericCharactersOnly={integer || showArrows}
        onKeyDown={handleKeyDown}
        onBlur={props.onBlur}
      />
      {showArrows && (
        <div
          className={`${styles.inputArrows} ${
            props.disabled ? styles.disabled : ""
          }`}
        >
          <button onClick={onClickUp}>
            <Icon
              icon="chevron-up"
              title="up"
              size={14}
              color="var(--shadow)"
            />
          </button>
          <button onClick={onClickDown}>
            <Icon
              icon="chevron-down"
              title="down"
              size={14}
              color="var(--shadow)"
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default NumericInput
