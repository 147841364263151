import * as fe from "@runn/filter-engine"
import { useMemo } from "react"

import { AllowedPeopleFilterSet } from "~/helpers/filter-engine"

import { useAppSelector } from "~/hooks/redux"

const usePeopleFilterSet = () => {
  const peopleWildSearch = useAppSelector(
    (state) => state.planner.peopleWildSearch,
  )

  const peopleFilterSet = useAppSelector(
    (state) => state.planner.peopleFilterSet,
  )

  return useMemo(() => {
    return {
      name: peopleFilterSet.name,
      filters: fe.filters.simplify(
        fe.filters.and([
          peopleFilterSet.filters,
          peopleWildSearch
            ? fe.filters.personWildSearch({ query: peopleWildSearch })
            : undefined,
        ]),
      ),
    } as AllowedPeopleFilterSet
  }, [peopleWildSearch, peopleFilterSet])
}

export { usePeopleFilterSet }
