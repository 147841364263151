import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { OnboardingDialog_user$key } from "./__generated__/OnboardingDialog_user.graphql"

import { track } from "~/helpers/analytics"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import Dialog from "~/common/Dialog"

import OnboardingFinancials from "~/Onboarding/OnboardingFinancials"
import { usePermissions } from "~/Permissions/usePermissions"
import { EXPAND_ALL_PROJECTS } from "~/Planner/Planner.actions"
import { withPlannerQuery } from "~/Planner/PlannerContainer"

import OnboardingPeople from "./OnboardingPeople"
import OnboardingProjects from "./OnboardingProjects"

type Props = {
  gql: OnboardingDialog_user$key
}

const STEP_LABELS = {
  1: "Projects",
  2: "People",
  3: "Financials",
}

const fragment = graphql`
  fragment OnboardingDialog_user on users
  @argumentDefinitions(
    peopleFilter: { type: "people_bool_exp" }
    projectsFilter: { type: "projects_bool_exp" }
  ) {
    id
    first_name
    last_name
    email
    account {
      name
      account_type
      projects(where: $projectsFilter) {
        id
        client {
          id
        }
      }
      people(where: $peopleFilter) {
        id
        first_name
        last_name
      }
      ...OnboardingFinancials_account
        @arguments(projectsFilter: $projectsFilter)
    }
    ...OnboardingPeople_user
  }
`

const OnboardingDialog = (props: Props) => {
  const data = useFragment(fragment, props.gql)
  const { account } = data
  const currentUser = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
  }

  const { projects, people, account_type } = account

  const { can, subject } = usePermissions()

  const startingStep = projects.length && !people.length ? 2 : 1
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const isLiveAccount = account_type === "live"
  const showOnboarding =
    isLiveAccount &&
    (people.length === 0 || projects.length === 0) &&
    !urlParams.has("seed_data") &&
    can("crud", subject("Project"))

  const totalSteps = can("manage", subject("RateCard")) ? 3 : 2
  const [step, setStep] = useState(startingStep)
  const [showDialog, setShowDialog] = useState(showOnboarding)
  const dispatch = useDispatch()

  useEffect(() => {
    if (showDialog) {
      dispatch({ type: EXPAND_ALL_PROJECTS, payload: true })
      dispatch(changeActivePage("projects"))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Tracks every time the modal opens (to determine whether people dismiss it on first view)
  useEffect(() => {
    if (!showDialog || !isLiveAccount) {
      return
    }
    track("Onboarding Dialog Opened", { step: STEP_LABELS[step] })
  }, [showDialog, isLiveAccount, step])

  const closeDialog = useCallback(
    (button?: string) => {
      setShowDialog(false)
      track("Onboarding Dialog Closed", { step: STEP_LABELS[step], button })
    },
    [setShowDialog, step],
  )

  const closeDialogCancelButton = useCallback(() => {
    closeDialog("cancel")
  }, [closeDialog])

  const closeDialogCross = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  const nextStep = () => {
    // If they already have people they've done this before so close
    if (step === 1 && people.length > 0) {
      return setShowDialog(false)
    }
    track("Onboarding Dialog Next Step Selected", {
      step: STEP_LABELS[step + 1],
    })
    setStep(step + 1)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <OnboardingProjects
            nextStep={nextStep}
            closeDialog={closeDialogCancelButton}
            accountName={account.name}
            totalSteps={totalSteps}
          />
        )
      case 2:
        return (
          <OnboardingPeople
            nextStep={nextStep}
            closeDialog={closeDialogCancelButton}
            currentUser={currentUser}
            user={data}
            totalSteps={totalSteps}
          />
        )
      case 3:
        return (
          <OnboardingFinancials
            account={account}
            closeDialog={closeDialogCancelButton}
          />
        )
      default:
        // Something went wrong. Just hide the on-boarding dialog.
        setShowDialog(false)
        break
    }
  }

  if (!showDialog || !isLiveAccount) {
    return null
  }

  return (
    <Dialog
      isOpen={true}
      onClose={closeDialogCross}
      showCloseButton
      data-test="onboarding-dialog"
    >
      {renderStep()}
    </Dialog>
  )
}

export default withPlannerQuery(OnboardingDialog)
