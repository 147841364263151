import * as fe from "@runn/filter-engine"
import capitalize from "lodash-es/capitalize"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import styles from "./SplitScreenRow.module.css"

import { SplitScreenPanel_user$data } from "~/Planner/SplitScreenPanel/__generated__/SplitScreenPanel_user.graphql"

import { track } from "~/helpers/analytics"
import { transferOrCloneAssignments } from "~/helpers/split-screen-helpers"

import { changeActivePage } from "~/common/ActivePage/ActivePage.slice"
import Avatar from "~/common/Avatar"
import { Assignment } from "~/common/PillActions/PillActions"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import Button from "~/common/buttons/Button"

import {
  SplitScreenModeAction,
  disableMultiSelectAndResetItems,
} from "~/Mode.reducer"
import PlaceholderCapacityIndicator from "~/PeoplePlanner/PlaceholderRow/PlaceholderCapacityIndicator"
import { usePermissions } from "~/Permissions/usePermissions"
import {
  SET_PLACEHOLDER_BAR_COLLAPSED,
  SET_SELECTED_PLACEHOLDER,
  setPeopleFilterSet,
  setPeopleWildSearch,
} from "~/Planner/Planner.actions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import { panelClosed } from "~/Planner/reducer2/panelSlice"
import { peopleCloned } from "~/Planner/reducer2/splitScreenSlice"
import PlaceholderForm from "~/forms/PlaceholderForm/PlaceholderForm"

import SplitScreenRole from "./SplitScreenRole"

type SSPanelAccount = SplitScreenPanel_user$data["account"]
type Props = {
  projects: SSPanelAccount["projects"]
  companyDefaultMinutes: SSPanelAccount["default_full_time_minutes"]
  multiSelectAssignments: Array<
    Assignment & { minutes: number; minutesLessTimeOff: number }
  >
  modeAction: SplitScreenModeAction
}

const NewPlaceholderRow = (props: Props) => {
  const {
    projects,
    multiSelectAssignments,
    companyDefaultMinutes,
    modeAction,
  } = props
  const [showImpact, setShowImpact] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const firstSelectedItem = multiSelectAssignments[0]
  const isTemplate = multiSelectAssignments[0]?.is_template

  const { can, subject } = usePermissions()

  const canCreatePlaceholderAndAssignments = useMemo(
    () =>
      multiSelectAssignments.every(
        (a) =>
          can(
            "create",
            subject("Placeholder", {
              project: { id: a.project_id, isTemplate: a.is_template },
            }),
          ) &&
          can(
            "create",
            subject("Assignment", {
              project: { id: a.project_id, isTemplate: a.is_template },
            }),
          ),
      ),
    [multiSelectAssignments], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const dispatch = useDispatch()

  const onMouseEnter = () => {
    setShowImpact(true)
  }

  const onMouseLeave = () => {
    setShowImpact(false)
  }

  const capitalizedModeAction = capitalize(modeAction)
  const buttonText = canCreatePlaceholderAndAssignments
    ? `Create and ${capitalizedModeAction}`
    : "Preview"

  const { openPanel, closePanel } = useSidePanel()

  const placeholderLink = (placeholder, roleId) => {
    dispatch(changeActivePage("people"))

    dispatch({
      type: SET_SELECTED_PLACEHOLDER,
      payload: { placeholderId: placeholder.id },
    })
    dispatch({ type: SET_PLACEHOLDER_BAR_COLLAPSED, payload: false })

    dispatch(setPeopleWildSearch(""))
    dispatch(
      setPeopleFilterSet({
        name: "",
        filters: fe.filters.and([
          fe.filters.personRoleId({ list: [roleId] }),
          placeholder.team
            ? fe.filters.personTeamId({ list: [placeholder.team?.id] })
            : undefined,
        ]),
      }),
    )
  }

  const handleSubmit = async (placeholder) => {
    setIsSaving(true)
    const roleId = placeholder.contracts[0].role_id
    await transferOrCloneAssignments(
      modeAction,
      multiSelectAssignments,
      placeholder,
      roleId,
      () => placeholderLink(placeholder, roleId),
      true,
      () => {
        modeAction === "clone" &&
          dispatch(
            peopleCloned({
              id: placeholder.id,
              role_id: roleId,
              multiSelectAssignments,
            }),
          )
      },
    )

    switch (modeAction) {
      case "transfer":
        track("Transfer Placeholder To New", {
          number_of_assignments: multiSelectAssignments.length,
        })
        break
      case "clone":
        track("Clone Placeholder To New", {
          number_of_assignments: multiSelectAssignments.length,
        })
        break
      default:
        modeAction satisfies never
        break
    }

    setIsSaving(false)
    closePanel()

    if (modeAction === "transfer") {
      dispatch(disableMultiSelectAndResetItems())
      dispatch(panelClosed())
    }
  }

  const openNewPlaceholderForm = (e) => {
    e.stopPropagation()

    if (!canCreatePlaceholderAndAssignments) {
      return
    }

    if (Boolean(firstSelectedItem)) {
      openPanel(
        <PlaceholderForm
          placeholder={undefined}
          cost={undefined}
          onClose={closePanel}
          roleId={firstSelectedItem.role_id}
          saveButtonText={buttonText}
          onSuccess={handleSubmit}
          splitScreenProps={{
            assignmentRoleId: multiSelectAssignments[0].role_id,
            modeAction,
          }}
        />,
      )
    }
  }

  return (
    <>
      <PlannerGrid className={styles.row}>
        <PlannerLeftColumn type="person">
          <Avatar is_placeholder={true} size={32} />
          <div className={styles.personDetails}>
            <div className={styles.title}>New Placeholder</div>
            <SplitScreenRole
              selectedRoleId={firstSelectedItem?.role_id}
              modeAction={modeAction}
              listType="placeholders"
            />
          </div>
          <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Button
              text={buttonText}
              onClick={openNewPlaceholderForm}
              style={{ marginRight: 20 }}
              disabled={isSaving}
            />
          </div>
        </PlannerLeftColumn>
        <PlannerRightColumn showHighlight={false}>
          <PlaceholderCapacityIndicator
            projects={projects}
            isTemplate={isTemplate}
            placeholders={[]}
            splitScreenImpactAssignments={
              showImpact ? multiSelectAssignments : undefined
            }
            companyDefaultMinutes={companyDefaultMinutes}
          />
        </PlannerRightColumn>
      </PlannerGrid>
    </>
  )
}

export default NewPlaceholderRow
