/**
 * @generated SignedSource<<e3985e1d7bc762ae1cfc228c9aa83306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectPersonList_user$data = {
  readonly favourite_people: any;
  readonly id: number;
  readonly view_id: number | null | undefined;
  readonly " $fragmentType": "SelectPersonList_user";
};
export type SelectPersonList_user$key = {
  readonly " $data"?: SelectPersonList_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectPersonList_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectPersonList_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "view_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "cf7ce2580dee802dfd4897603f44c8b6";

export default node;
