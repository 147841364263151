import { Icon } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import { useFeature } from "flagged"
import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import type { SuperSearchConfig_user$key } from "./__generated__/SuperSearchConfig_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import {
  CustomFieldsMap,
  CustomTypeName,
  sortCustomFields,
} from "~/helpers/custom-field-helpers"
import { buildImageUrl } from "~/helpers/image"
import { formatName, formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"
import * as icons from "~/common/react-icons"

import { usePersonCustomFieldTypes } from "~/CustomFields/PersonCustomFieldTypes"
import { useProjectCustomFieldTypes } from "~/CustomFields/ProjectCustomFieldTypes"
import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementQuantity,
  useEntitlementSwitch,
  useIsInFreePlan,
} from "~/Entitlements/useEntitlements"
import { isSplitScreenMode } from "~/Mode.reducer"
import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { useAppSelector } from "~/hooks/redux"

import TitledHelpTooltip from "../TitledHelpTooltip"
import { SimpleLabel } from "../labels"

import type { SuperSearchConfig } from "./types"

const {
  personCustomSelect,
  projectCustomSelect,
  projectCustomText,
  projectCustomDate,
  personCustomText,
  personCustomDate,
  personProjectCustomSelect,
  personProjectCustomText,
  personProjectCustomDate,
  projectPersonCustomSelect,
  projectPersonCustomText,
  personCustomCheckbox,
  projectCustomCheckbox,
  personProjectCustomCheckbox,
  projectPersonCustomCheckbox,
  projectPersonCustomDate,
} = fe.filters

const fragment = graphql`
  fragment SuperSearchConfig_user on users {
    people(limit: 1) {
      id
      first_name
      last_name
      email
      image_key
      active
    }
    account {
      ...ProjectCustomFieldTypes_accounts
      ...PersonCustomFieldTypes_accounts
    }
  }
`

type CustomFieldType = ReadonlyArray<{
  name: string
  id: number
  show_in_planner: boolean
  filterable_in_planner: boolean
  singleSelect?: boolean
  sort_order: number
}>

type GetCustomFiltersOpts = {
  customFields: CustomFieldsMap<
    CustomFieldType,
    CustomFieldType,
    CustomFieldType,
    CustomFieldType
  >
  selectFilter:
    | typeof personCustomSelect
    | typeof projectCustomSelect
    | typeof personProjectCustomSelect
    | typeof projectPersonCustomSelect
  textFilter:
    | typeof projectCustomText
    | typeof personCustomText
    | typeof personProjectCustomText
    | typeof projectPersonCustomText
  checkboxFilter:
    | typeof personCustomCheckbox
    | typeof projectCustomCheckbox
    | typeof personProjectCustomCheckbox
    | typeof projectPersonCustomCheckbox
  dateFilter:
    | typeof projectCustomDate
    | typeof personCustomDate
    | typeof personProjectCustomDate
    | typeof projectPersonCustomDate
  isSubmenuFilter?: boolean
}

const getCustomFilters = ({
  selectFilter,
  textFilter,
  checkboxFilter,
  dateFilter,
  isSubmenuFilter,
  customFields,
}: GetCustomFiltersOpts) => {
  // exclude keys that are not in CustomTypeName
  const typeNameKeys = Object.keys(customFields).filter((key) =>
    Object.values(CustomTypeName).includes(key as CustomTypeName),
  ) as CustomTypeName[]

  const filters = typeNameKeys.reduce((acc, key: CustomTypeName) => {
    const filter = match(key)
      .with(CustomTypeName.SELECT, () =>
        customFields.select
          .filter((type) =>
            isSubmenuFilter ? type.filterable_in_planner : true,
          )
          .map((type) => ({
            name: type.name,
            sort_order: type.sort_order,
            icon: type.singleSelect ? <icons.Select /> : <icons.MultiSelect />,
            filter: selectFilter({
              name: type.name,
              typeId: type.id,
              list: [],
            }),
          })),
      )
      .with(CustomTypeName.TEXT, () =>
        customFields.text
          .filter((type) =>
            isSubmenuFilter ? type.filterable_in_planner : true,
          )
          .map((type) => ({
            name: type.name,
            sort_order: type.sort_order,
            icon: <icons.Equals />,
            filter: textFilter({
              name: type.name,
              typeId: type.id,
              query: "",
              searchType: "includes",
            }),
          })),
      )
      .with(CustomTypeName.DATE, () =>
        customFields.date
          .filter((type) =>
            isSubmenuFilter ? type.filterable_in_planner : true,
          )
          .map((type) => ({
            name: type.name,
            sort_order: type.sort_order,
            icon: <icons.Calendar />,
            filter: dateFilter({
              name: type.name,
              typeId: type.id,
              datePayload: {
                type: "exact",
                exact: null,
              },
            }),
          })),
      )
      .with(CustomTypeName.CHECKBOX, () =>
        customFields.checkbox
          .filter((type) =>
            isSubmenuFilter ? type.filterable_in_planner : true,
          )
          .map((type) => ({
            name: type.name,
            sort_order: type.sort_order,
            icon: <icons.Tick width={16} height={16} color="var(--midnight)" />,
            filter: checkboxFilter({
              typeId: type.id,
              query: false,
              name: type.name,
            }),
          })),
      )
      .exhaustive()

    return [...acc, ...filter]
  }, [])
  return filters.sort(sortCustomFields)
}

type ProjectConfigProps = {
  excludedFilters?: fe.engines.local.AllowedFilter["type"][]
  excludedSubmenus?: Array<
    "peopleAndPlaceholders" | "projectCustomFields" | "peopleCustomFields"
  >
  user?: SuperSearchConfig_user$key
  tentativeProjectsToggle?: boolean
  saveFilter?: boolean
}

const useProjectSearchConfig = (
  props: ProjectConfigProps = {},
): SuperSearchConfig => {
  const {
    excludedFilters = [],
    excludedSubmenus = [],
    tentativeProjectsToggle = false,
    user,
    saveFilter = true,
  } = props

  const hasura = useHasuraContext({ strict: false })
  const viewer = useFragment<SuperSearchConfig_user$key>(
    fragment,
    user ?? hasura,
  )
  const { account } = viewer

  const projectCustomFieldTypes = useProjectCustomFieldTypes(account)

  const personCustomFieldTypes = usePersonCustomFieldTypes(account)

  const myPerson = viewer?.people[0]

  const isInFreePlan = useIsInFreePlan()
  const entitledToResourceRequests = useEntitlementSwitch(
    ChargebeeFeatures.resourcingRequests,
  )
  const entitledToCustomFields = Boolean(
    useEntitlementQuantity(ChargebeeFeatures.customFields),
  )
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitledToWorkstreams = useEntitlementSwitch("workstreams")
  const workstreamIsEnabled = useFeature("workstreams")

  const nativeCustomFilters = getCustomFilters({
    selectFilter: projectCustomSelect,
    textFilter: projectCustomText,
    dateFilter: projectCustomDate,
    customFields: projectCustomFieldTypes,
    checkboxFilter: projectCustomCheckbox,
  })

  const personCustomFilters = getCustomFilters({
    selectFilter: projectPersonCustomSelect,
    textFilter: projectPersonCustomText,
    checkboxFilter: projectPersonCustomCheckbox,
    dateFilter: projectPersonCustomDate,
    isSubmenuFilter: true,
    customFields: personCustomFieldTypes,
  })

  const { limit } = useQueryLimit()
  const isEnterprise = Boolean(limit)

  const config = useMemo(
    (): SuperSearchConfig => ({
      features: {
        menu: true,
        tentativeProjectsToggle,
        saveFilter,
        editFilter: true,
        wildSearch: true,
        editWildSearch: true,
        clearable: true,
      },

      filterMenuButtonLabel: "Filter",

      splitScreenIsEnabled: false,

      wildSearchPlaceholder: "Search projects...",

      saveFilterPlaceholder: "Name saved projects filter",

      savedFiltersTitle: "My Saved Project Filters",
      savedFilterSets: [
        myPerson?.active
          ? {
              name: "My Projects",
              icon: (
                <Avatar
                  email={myPerson.email}
                  initials={formatNameAsInitials(
                    myPerson.first_name,
                    myPerson.last_name,
                  )}
                  avatar={buildImageUrl(myPerson.image_key)}
                />
              ),
              filters: fe.filters.projectPersonId({
                list: [myPerson.id],
              }),
            }
          : undefined,
      ].filter(Boolean),

      availableFiltersTitle: "Filter Projects By",
      availableFilters: [
        {
          name: "Starred",
          icon: <icons.Star />,
          filter: fe.filters.projectIsFavourite({ value: true }),
        },
        {
          name: "Project Name",
          icon: <icons.Assignment />,
          filter: fe.filters.projectId({ list: [] }),
          hidden: isEnterprise,
          disableDropdown: isEnterprise,
        },
        {
          name: "Client",
          icon: <icons.Client />,
          filter: fe.filters.projectClientId({ list: [] }),
        },
        {
          name: (
            <div>
              Manager <SimpleLabel text="Projects" />
            </div>
          ),
          icon: <icons.SquareFace />,
          filter: fe.filters.projectManagerId({ list: [] }),
        },
        {
          name: "Project Tags",
          icon: <Icon icon="tag" />,
          filter: fe.filters.projectTagId({ list: [] }),
        },
        {
          name: "Project State",
          icon: <Icon icon="archive" />,
          filter: fe.filters.projectState({ list: [] }),
        },
        {
          name: "Project Status",
          icon: <icons.ProjectStatus />,
          filter: fe.filters.projectStatus({ list: [] }),
        },
        {
          name: "Pricing Model",
          icon: <Icon icon="dollar" />,
          filter: fe.filters.projectPricingModel({ list: [] }),
        },
        {
          name: "Primary Team",
          icon: <icons.Social />,
          filter: fe.filters.projectTeamId({ list: [] }),
        },
        {
          name: "Project Template",
          icon: <icons.ProjectTemplateIcon />,
          filter: fe.filters.projectTemplateId({ list: [] }),
          featureEntitlementId: "project-templates",
        },
        {
          name: "Workstream",
          icon: <icons.Workstream />,
          filter: fe.filters.projectWorkstreamId({ list: [] }),
          hidden:
            !workstreamIsEnabled ||
            (entitlementsEnabled && !entitledToWorkstreams && !isInFreePlan),
          featureEntitlementId: "workstreams",
        },
        {
          name: "People & Placeholders",
          icon: <icons.Face />,
          hidden:
            isEnterprise || excludedSubmenus.includes("peopleAndPlaceholders"),
          submenuFilters: [
            {
              name: "Person on Project",
              filter: fe.filters.projectPersonId({ list: [] }),
              icon: <icons.Face />,
            },
            {
              name: "Project Role",
              filter: fe.filters.projectPersonRoleId({ list: [] }),
              icon: <icons.PersonCircleOutlineIcon />,
            },
            {
              name: (
                <div>
                  Manager <SimpleLabel text="People" />
                </div>
              ),
              icon: <icons.SquareFace />,
              filter: fe.filters.projectPersonManagerId({ list: [] }),
            },
            {
              name: "People in Team",
              filter: fe.filters.projectPersonTeamId({ list: [] }),
              icon: <icons.Social />,
            },
            {
              name: "Resourcing Request Status",
              icon: <Icon icon="person" />,
              filter: fe.filters.projectPersonPlaceholderStatus({ list: [] }),
              featureEntitlementId: "resourcing-requests",
              hidden: !entitledToResourceRequests && !isInFreePlan,
            },
            {
              name: "People Tags",
              icon: <Icon icon="tag" />,
              filter: fe.filters.projectPersonTagId({ list: [] }),
            },
            {
              name: "Job Title",
              icon: <Icon icon="briefcase" />,
              filter: fe.filters.projectPersonJobTitleName({
                query: "",
                searchType: "includes",
              }),
            },
            {
              name: "Skills",
              icon: <icons.SkillIcon />,
              filter: fe.filters.projectPersonSkillId({ list: [] }),
            },
          ],
        },
        {
          name: "Project Custom Fields",
          icon: <icons.CustomFields />,
          submenuFilters: !entitledToCustomFields ? null : nativeCustomFilters,
          hidden:
            excludedSubmenus.includes("projectCustomFields") ||
            (nativeCustomFilters.length === 0 &&
              (!entitlementsEnabled || !isInFreePlan)),
          featureEntitlementId: "custom-fields",
        },
        {
          name: "People Custom Fields",
          icon: <icons.CustomFields />,
          submenuFilters: !entitledToCustomFields ? null : personCustomFilters,
          hidden:
            excludedSubmenus.includes("peopleCustomFields") ||
            (personCustomFilters.length === 0 &&
              (!entitlementsEnabled || !isInFreePlan)),
          featureEntitlementId: "custom-fields",
        },
      ].filter(Boolean),
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Add a divider to the first submenu item
  for (const item of config.availableFilters) {
    if (!item.hidden && item.submenuFilters?.length) {
      item.hasDivider = true
      break
    }
  }

  if (excludedFilters) {
    config.availableFilters = config.availableFilters.filter(
      (f) => !excludedFilters.includes(f.filter?.type),
    )
  }

  return config
}

type PersonConfigProps = {
  location?: string
  excludedFilters?: fe.engines.local.AllowedFilter["type"][]
  excludedSubmenus?: Array<
    "projects" | "projectCustomFields" | "peopleCustomFields"
  >
  user?: SuperSearchConfig_user$key
  tentativeProjectsToggle?: boolean
  saveFilter?: boolean
}

const usePersonSearchConfig = (
  props: PersonConfigProps = {},
): SuperSearchConfig => {
  const {
    location,
    excludedFilters = [],
    excludedSubmenus = [],
    tentativeProjectsToggle = false,
    user,
    saveFilter = true,
  } = props

  const hasura = useHasuraContext({ strict: false })
  const viewer = useFragment<SuperSearchConfig_user$key>(
    fragment,
    user ?? hasura,
  )

  const myPerson = viewer?.people[0]
  const { account } = viewer

  const isInFreePlan = useIsInFreePlan()
  const entitledToResourceRequests = useEntitlementSwitch(
    ChargebeeFeatures.resourcingRequests,
  )
  const entitledToCustomFields = Boolean(
    useEntitlementQuantity(ChargebeeFeatures.customFields),
  )
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitledToWorkstreams = useEntitlementSwitch("workstreams")
  const workstreamIsEnabled = useFeature("workstreams")

  const personCustomFieldTypes = usePersonCustomFieldTypes(account)

  const projectCustomFieldTypes = useProjectCustomFieldTypes(account)

  const nativeCustomFilters = getCustomFilters({
    selectFilter: personCustomSelect,
    textFilter: personCustomText,
    dateFilter: personCustomDate,
    checkboxFilter: personCustomCheckbox,
    customFields: personCustomFieldTypes,
  })

  const projectCustomFilters = getCustomFilters({
    selectFilter: personProjectCustomSelect,
    textFilter: personProjectCustomText,
    dateFilter: personProjectCustomDate,
    checkboxFilter: personProjectCustomCheckbox,
    isSubmenuFilter: true,
    customFields: projectCustomFieldTypes,
  })

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenIsEnabled = isSplitScreenMode(modeAction)

  const { limit } = useQueryLimit()
  const isEnterprise = Boolean(limit)

  const config = useMemo(
    (): SuperSearchConfig => ({
      features: {
        menu: true,
        saveFilter,
        tentativeProjectsToggle,
        editFilter: true,
        wildSearch: true,
        editWildSearch: true,
        clearable: true,
      },

      filterMenuButtonLabel: location === "charts" ? "Filter People" : "Filter",

      splitScreenIsEnabled: splitScreenIsEnabled,

      wildSearchPlaceholder: "Search people...",

      saveFilterPlaceholder: "Name saved people filter",

      savedFiltersTitle: "My Saved People Filters",
      savedFilterSets: [
        myPerson?.active
          ? {
              name: formatName(myPerson.first_name, myPerson.last_name),
              icon: (
                <Avatar
                  email={myPerson.email}
                  initials={formatNameAsInitials(
                    myPerson.first_name,
                    myPerson.last_name,
                  )}
                  avatar={buildImageUrl(myPerson.image_key)}
                />
              ),
              filters: fe.filters.personId({ list: [myPerson.id] }),
            }
          : undefined,
      ].filter(Boolean),

      availableFiltersTitle: "Filter People By",
      availableFilters: [
        {
          name: "Starred",
          icon: <icons.Star />,
          filter: fe.filters.personIsFavourite({ value: true }),
        },
        {
          name: "Person",
          icon: <icons.Face />,
          filter: fe.filters.personId({ list: [] }),
          hidden: isEnterprise,
          disableDropdown: isEnterprise,
        },
        {
          name: "Default Role",
          icon: <icons.PersonCircle />,
          filter: fe.filters.personRoleId({ list: [] }),
        },
        {
          name: "Project Role",
          icon: <icons.PersonCircleOutlineIcon />,
          filter: fe.filters.personProjectRoleId({ list: [] }),
        },
        {
          name: (
            <div>
              Manager <SimpleLabel text="People" />
            </div>
          ),
          icon: <icons.SquareFace />,
          filter: fe.filters.personManagerId({ list: [] }),
        },
        {
          name: "People Tags",
          icon: <Icon icon="tag" />,
          filter: fe.filters.personTagId({ list: [] }),
        },
        {
          name: "Job Title",
          icon: <Icon icon="briefcase" />,
          filter: fe.filters.personJobTitleName({
            query: "",
            searchType: "includes",
          }),
        },
        {
          name: "Person State",
          icon: <Icon icon="archive" />,
          filter: fe.filters.personState({ list: [] }),
        },
        {
          name: "Skills",
          icon: <icons.SkillIcon />,
          filter: fe.filters.personSkillId({ list: [] }),
        },
        {
          name: "Team",
          icon: <icons.Social />,
          filter: fe.filters.personTeamId({ list: [] }),
        },
        {
          name: "Person Type",
          icon: <icons.IdCard />,
          filter: fe.filters.personType({ list: [] }),
        },
        {
          name: "Employment Type",
          icon: <icons.PersonPin />,
          filter: fe.filters.personEmploymentType({ list: [] }),
        },
        {
          name: "Resourcing Request Status",
          icon: <Icon icon="person" />,
          filter: fe.filters.personPlaceholderStatus({ list: [] }),
          featureEntitlementId: "resourcing-requests",
          hidden: splitScreenIsEnabled || !entitledToResourceRequests,
        },
        {
          name: "Project",
          icon: <icons.Assignment />,
          hidden: isEnterprise || excludedSubmenus.includes("projects"),
          submenuFilters: [
            {
              name: "Project Name",
              icon: <icons.Assignment />,
              filter: fe.filters.personMembership({ list: [] }),
            },
            {
              name: "Project Status",
              icon: <icons.ProjectStatus />,
              filter: fe.filters.personProjectStatus({ list: [] }),
            },
            {
              name: (
                <TitledHelpTooltip
                  title="Scheduled in the Future"
                  tooltipContent="Only project team members with future scheduled work"
                />
              ),
              filter: fe.filters.personProjectId({ list: [] }),
              icon: <icons.Calendar />,
            },
            {
              name: (
                <div>
                  Manager <SimpleLabel text="Projects" />
                </div>
              ),
              icon: <icons.SquareFace />,
              filter: fe.filters.personProjectManagerId({ list: [] }),
            },
            {
              name: "Client",
              icon: <icons.Client />,
              filter: fe.filters.personProjectClientId({ list: [] }),
            },
            {
              name: "Primary Team",
              icon: <icons.Social />,
              filter: fe.filters.personProjectTeamId({ list: [] }),
            },
            {
              name: "Project Tags",
              icon: <Icon icon="tag" />,
              filter: fe.filters.personProjectTagId({ list: [] }),
            },
            {
              name: "Workstream",
              icon: <icons.Workstream />,
              filter: fe.filters.personProjectWorkstreamId({ list: [] }),
              hidden:
                !workstreamIsEnabled ||
                (entitlementsEnabled &&
                  !entitledToWorkstreams &&
                  !isInFreePlan),
              featureEntitlementId: "workstreams",
            },
          ],
        },
        ,
        {
          name: "People Custom Fields",
          icon: <icons.CustomFields />,
          submenuFilters: !entitledToCustomFields ? null : nativeCustomFilters,
          hidden:
            excludedSubmenus.includes("peopleCustomFields") ||
            (nativeCustomFilters.length === 0 &&
              (!entitlementsEnabled || !isInFreePlan)),
          featureEntitlementId: "custom-fields",
        },
        {
          name: "Project Custom Fields",
          icon: <icons.CustomFields />,
          submenuFilters: !entitledToCustomFields ? null : projectCustomFilters,
          hidden:
            excludedSubmenus.includes("projectCustomFields") ||
            (projectCustomFilters.length === 0 &&
              (!entitlementsEnabled || !isInFreePlan)),
          featureEntitlementId: "custom-fields",
        },
      ].filter(Boolean),
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Add a divider to the first submenu item
  for (const item of config.availableFilters) {
    if (!item.hidden && item.submenuFilters?.length) {
      item.hasDivider = true
      break
    }
  }

  if (excludedFilters) {
    config.availableFilters = config.availableFilters.filter(
      (f) => !excludedFilters.includes(f.filter?.type),
    )
  }

  return config
}

export { usePersonSearchConfig, useProjectSearchConfig }

export type { SuperSearchConfig }
