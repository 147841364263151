import React, { useEffect, useState } from "react"

import { TangramPersonRunner } from "~/common/tangrams/tangramPersonRunner"

const PersonRunningTangram = (props: { activate: boolean }) => {
  const { activate } = props

  const [transformation, setTransformation] = useState("")
  const [transition, setTransition] = useState("all 0.5s")

  const firstTransform =
    "translateY(0%) scale(0.5) translateX(60px) rotate(-90deg)"
  const secondTransform =
    "translateY(-100vh) scale(0.5) translateX(60px) rotate(-90deg)"

  useEffect(() => {
    let timeOut
    if (activate) {
      setTransformation(firstTransform)

      timeOut = setTimeout(() => {
        setTransition("all 1s")
        setTransformation(secondTransform)
      }, 500)
    }

    return () => clearTimeout(timeOut)
  }, [activate])

  return (
    <div
      style={{
        width: "80px",
        height: "100px",
        transition: transition,
        transform: transformation,
      }}
    >
      <TangramPersonRunner />
    </div>
  )
}

export default PersonRunningTangram
