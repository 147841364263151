import flatten from "lodash-es/flatten"
import uniqBy from "lodash-es/uniqBy"

import { splitStringLowerCase } from "~/helpers/filter-helpers"
import { formatName, getCurrentContract } from "~/helpers/person"

export const filterOptionsWithValue = (
  options,
  value,
  team = false,
  allowPlaceholders = false,
) =>
  options.filter((x) => {
    if (!x) {
      return false
    }
    if (!allowPlaceholders && x.is_placeholder) {
      return false
    }
    if (!value) {
      return true
    }

    const filterValues = splitStringLowerCase(value)
    const label = x.label || formatName(x.first_name, x.last_name)
    const namesToFilter = [splitStringLowerCase(label)]
    if (team && x.team) {
      namesToFilter.push(splitStringLowerCase(x.team.name))
    }

    const checkIfNameHasValue = (val) =>
      flatten(namesToFilter).filter((name) => name.startsWith(val)).length > 0

    return filterValues.every(checkIfNameHasValue)
  })

type FilterPerson = {
  id: number
  first_name: string
  last_name: string
  is_placeholder: boolean
  active?: boolean
  email?: string
  image_key?: string
  team?: {
    name: string
  }
  tags: string[]
  contracts: ReadonlyArray<{
    start_date: string
    end_date: string
    role: {
      name
    }
  }>
  competencies: ReadonlyArray<{
    skill: {
      id: number
      name: string
    }
  }>
}

export const filterPeopleWithValue = <T extends FilterPerson>({
  people,
  filterValue,
  includePlaceholders,
}: {
  people: ReadonlyArray<T>
  filterValue: string
  includePlaceholders?: boolean
}): T[] =>
  people?.filter((p) => {
    if (!p) {
      return false
    }
    if (!includePlaceholders && p.is_placeholder) {
      return false
    }
    if (!filterValue) {
      return true
    }

    const filterValues = splitStringLowerCase(filterValue)
    const label = formatName(p.first_name, p.last_name)
    const wordsToFilter = splitStringLowerCase(label)

    if (p.team) {
      wordsToFilter.push(...splitStringLowerCase(p.team.name))
    }

    if (p.tags.length) {
      p.tags.map((tag) => wordsToFilter.push(...splitStringLowerCase(tag)))
    }

    if (p.competencies?.length) {
      p.competencies.map((c) =>
        wordsToFilter.push(...splitStringLowerCase(c.skill.name)),
      )
    }

    const contract = getCurrentContract(p.contracts)

    if (contract) {
      wordsToFilter.push(...splitStringLowerCase(contract.role.name))
    }

    const checkIfNameHasValue = (val) =>
      wordsToFilter.filter((name) => name.startsWith(val)).length > 0

    return filterValues.every(checkIfNameHasValue)
  })

export const filterByRole = <T extends PeopleRoles>(
  people: ReadonlyArray<T>,
  value: string,
  allowPlaceholders = false,
): T[] =>
  people.filter((person) => {
    const filterValues = splitStringLowerCase(value)

    if (person.is_placeholder && allowPlaceholders === false) {
      return false
    }

    const namesToFilter = flatten([
      splitStringLowerCase(formatName(person.first_name, person.last_name)),
    ])
    const rolesToFilter = flatten([
      splitStringLowerCase(getCurrentContract(person.contracts)?.role.name),
    ])

    const checkIfNameHasValue = (val) =>
      namesToFilter.filter((name) => name.startsWith(val)).length === 0 &&
      rolesToFilter.filter((name) => name.startsWith(val)).length > 0

    return filterValues.every(checkIfNameHasValue)
  })

export const filterByTags = <T extends PeopleTags>(
  people: ReadonlyArray<T>,
  value: string,
  allowPlaceholders = false,
): T[] =>
  people.filter((person) => {
    const filterValues = splitStringLowerCase(value)

    if (person.is_placeholder && allowPlaceholders === false) {
      return false
    }

    const namesToFilter = flatten([
      splitStringLowerCase(`${person.first_name} ${person.last_name}`),
    ])
    const tagsToFilter = person.tags
      ? flatten(person.tags.map((tag) => splitStringLowerCase(tag)))
      : []

    const checkIfNameHasValue = (val) =>
      namesToFilter.filter((name) => name.startsWith(val)).length === 0 &&
      tagsToFilter.filter((tag: string) => tag.startsWith(val)).length > 0

    return filterValues.every(checkIfNameHasValue)
  })

type PeopleRoles = {
  id: number
  is_placeholder: boolean
  first_name: string
  last_name: string
  contracts: ReadonlyArray<{
    start_date: string
    end_date: string
    role: {
      name: string
    }
  }>
}
type PeopleTags = {
  id: number
  first_name: string
  last_name: string
  is_placeholder: boolean
  tags: ReadonlyArray<string>
}

export enum FilterGroup {
  DEFAULT = "Name",
  SKILLS = "Skills",
  TAGS = "Tags",
  TEAMS = "Teams",
  ROLES = "Roles",
}

export const filterPeopleWithValueGrouped = <T extends FilterPerson>({
  people,
  filterValue,
  includePlaceholders,
}: {
  people: ReadonlyArray<T>
  filterValue: string
  includePlaceholders?: boolean
}): Array<{
  type:
    | FilterGroup.DEFAULT
    | FilterGroup.SKILLS
    | FilterGroup.TAGS
    | FilterGroup.TEAMS
    | FilterGroup.ROLES
  people: ReadonlyArray<T>
}> => {
  const filteredPeople = [] as Array<T & { type: FilterGroup }>

  if (!filterValue) {
    return [{ type: FilterGroup.DEFAULT, people }]
  }

  people.filter((p) => {
    if (!p) {
      return false
    }
    if (!includePlaceholders && p.is_placeholder) {
      return false
    }

    const filterValues = splitStringLowerCase(filterValue)

    const hasFilterValues = (wordsToFilter: string[]) =>
      filterValues.every(
        (val) =>
          wordsToFilter.filter((name) => name.startsWith(val)).length > 0,
      )

    if (p.team) {
      const teamName = splitStringLowerCase(p.team.name)

      if (hasFilterValues(teamName)) {
        filteredPeople.push({ ...p, type: FilterGroup.TEAMS })
      }
    }
    if (p.tags.length) {
      const tags = flatten(p.tags.map((tag) => splitStringLowerCase(tag)))

      if (hasFilterValues(tags)) {
        filteredPeople.push({ ...p, type: FilterGroup.TAGS })
      }
    }

    if (p.competencies?.length) {
      const skills = flatten(
        p.competencies.map((c) => splitStringLowerCase(c.skill.name)),
      )

      if (hasFilterValues(skills)) {
        filteredPeople.push({ ...p, type: FilterGroup.SKILLS })
      }
    }

    const contract = getCurrentContract(p.contracts)

    if (contract) {
      const contractName = splitStringLowerCase(contract.role.name)

      if (hasFilterValues(contractName)) {
        filteredPeople.push({ ...p, type: FilterGroup.ROLES })
      }
    }
  })

  const filteredPeopleIds = filteredPeople.map((x) => x.id)
  const restOfPeopleWithValue = filterPeopleWithValue({
    people: people.filter((p) => !filteredPeopleIds.includes(p.id)),
    filterValue,
    includePlaceholders,
  })

  if (Boolean(restOfPeopleWithValue.length)) {
    filteredPeople.push(
      ...restOfPeopleWithValue.map((x) => {
        return {
          ...x,
          type: FilterGroup.DEFAULT,
        }
      }),
    )
  }

  const uniquePeople = uniqBy(filteredPeople, "id")

  const formattedPeople = [
    {
      type: FilterGroup.DEFAULT,
      people: uniquePeople.filter((x) => x.type === FilterGroup.DEFAULT),
    },
    {
      type: FilterGroup.TAGS,
      people: uniquePeople.filter((x) => x.type === FilterGroup.TAGS),
    },
    {
      type: FilterGroup.ROLES,
      people: uniquePeople.filter((x) => x.type === FilterGroup.ROLES),
    },
    {
      type: FilterGroup.TEAMS,
      people: uniquePeople.filter((x) => x.type === FilterGroup.TEAMS),
    },
    {
      type: FilterGroup.SKILLS,
      people: uniquePeople.filter((x) => x.type === FilterGroup.SKILLS),
    },
  ]

  return formattedPeople
}

export type SelectOption = {
  label: string
  value: number
  isArchived?: boolean
}

export const toOption = (item: { id: number; name: string }): SelectOption => ({
  label: item.name,
  value: item.id,
})

export const toOptionIncludingArchived = (item: {
  id: number
  name: string
  archived: boolean
}): SelectOption => ({
  label: item.archived ? `${item.name} (Archived)` : item.name,
  value: item.id,
  isArchived: item.archived,
})
