import React from "react"
import { graphql, useFragment } from "react-relay"

import { DetectedPersonRows_project$key } from "./__generated__/DetectedPersonRows_project.graphql"
import { DetectedPersonRows_user$key } from "./__generated__/DetectedPersonRows_user.graphql"

import ProjectDetailsDetectedPersonRow from "./ProjectDetailsDetectedPersonRow"

type Props = {
  user: DetectedPersonRows_user$key
  project: DetectedPersonRows_project$key
}

const DetectedPersonRows = (props: Props) => {
  const user = useFragment(
    graphql`
      fragment DetectedPersonRows_user on users {
        id
        ...ProjectDetailsPersonRow_user
        ...ProjectDetailsDetectedPersonRow_user
        account {
          id
          ...ProjectDetailsDetectedPersonRow_account
          people(where: $peopleFilter) {
            ...ProjectDetailsPersonRow_person
              @arguments(plannerStartDate: $plannerStartDate)
          }
        }
      }
    `,
    props.user,
  )

  const project = useFragment(
    graphql`
      fragment DetectedPersonRows_project on projects {
        id
        members {
          id
          person_id
        }
        ...ProjectDetailsDetectedPersonRow_project
        ext_project_links(where: { active: { _eq: true } }) {
          ext_project_id
          project_id
          ext_project {
            id
            ext_project_members(where: { deleted: { _eq: false } }) {
              id
              ext_person {
                id
                ...ProjectDetailsDetectedPersonRow_extPerson
                ext_person_links(where: { active: { _eq: true } }) {
                  ext_person_id
                  person_id
                }
              }
            }
          }
        }
      }
    `,
    props.project,
  )

  const projectMemberPersonIds = new Set(
    project.members.map((member) => member.person_id),
  )

  const extPersonList = (
    project.ext_project_links?.[0]?.ext_project.ext_project_members ?? []
  )
    // Exclude any people that are already project members
    .filter(
      (member) =>
        !member.ext_person.ext_person_links.some((link) =>
          projectMemberPersonIds.has(link.person_id),
        ),
    )
    .map((member) => member.ext_person)

  return (
    <>
      {extPersonList?.map((extPerson) => (
        <ProjectDetailsDetectedPersonRow
          key={extPerson.id}
          extPerson={extPerson}
          project={project}
          user={user}
          account={user.account}
        />
      ))}
    </>
  )
}

export default React.memo(DetectedPersonRows)
