import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import { Feature, useFeature } from "flagged"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PlaceholderForm.module.css"

import { PlaceholderForm_user$data } from "~/forms/PlaceholderForm/__generated__/PlaceholderForm_user.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { CustomTypeName, CustomValuesMap } from "~/helpers/custom-field-helpers"
import { reportError } from "~/helpers/error-helpers"
import * as hashids from "~/helpers/hashids"
import { formatName } from "~/helpers/person"
import routes from "~/helpers/routes"
import { sortByString } from "~/helpers/sorting-helpers"

import InputLabel from "../../common/InputLabel"
import CustomFieldSectionHeader from "../common/CustomFieldSectionHeader"
import CurrencyInput from "~/common/CurrencyInput"
import Input from "~/common/Input"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import SidePanelHeader from "~/common/SidePanel/SidePanelHeader"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import SmallAlert from "~/common/alerts/SmallAlert"
import Button from "~/common/buttons/Button"

import {
  placeholderBulkCreateRelay,
  placeholderUpdateRelay,
} from "~/mutations/Placeholder"

import PersonCustomEditor from "~/CustomEditor/PersonCustomEditor/PersonCustomEditor"
import { PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import { SplitScreenModeAction } from "~/Mode.reducer"
import { usePermissions } from "~/Permissions/usePermissions"
import PersonTagSelector from "~/Planner/PersonTagSelector"
import PlaceholderActionButtons from "~/Planner/PlaceholderSidePanel/PlaceholderActionButtons"
import { Person } from "~/Planner/PlannerLayout/PlannerLeftColumn"
import RoleSelector from "~/Planner/RoleSelector"
import usePlannerFilters from "~/Planner/usePlannerFilters"
import SkillsSelector, {
  SkillOption,
} from "~/Skills/SkillsSelector/SkillsSelector"

import { PersonSelector } from "../../Modals/PersonRequest/PersonSelector"
import TeamSelector from "../../Planner/TeamSelector"

type Props = {
  placeholder: Person | undefined
  cost: number | undefined
  roleId: number
  onClose: () => void
  saveButtonText?: string
  project?: {
    id: number
    name: string
    is_template: boolean
  }
  onSuccess?: (placeholder: Omit<Person, "archivable" | "time_offs">) => void
  // ↓ Only for Placeholder Form in Split Screen
  splitScreenProps?: {
    assignmentRoleId: number
    modeAction: SplitScreenModeAction
  }
}

const getPlaceholderLastName = (
  roles: PlaceholderForm_user$data["account"]["roles"],
  roleId: number,
) => {
  const role = roles.find((r) => r.id === roleId)
  const newNumber = role.contracts[0]?.person?.placeholder_count || 0

  const identifier = String(newNumber + 1).padStart(3, "0")
  return `Placeholder ${identifier}`
}

const PlaceholderForm = (props: Props) => {
  const { cost, onClose, roleId, saveButtonText, splitScreenProps, project } =
    props
  const { assignmentRoleId, modeAction } = splitScreenProps ?? {}

  // Hack for PersonCustomEditor to avoid TS compiler errors because while this component
  // does receive a proper Relay fragment reference, it casts it as a non-Relay type (Person).
  const placeholder = props.placeholder
    ? { ...props.placeholder, " $fragmentSpreads": null }
    : undefined

  const context: any = useHasuraContext()
  const data = useFragment(
    graphql`
      fragment PlaceholderForm_user on users {
        account {
          id
          roles {
            id
            name
            active
            default_hour_cost: default_hour_cost_private
            contracts(
              where: { person: { is_placeholder: { _eq: true } } }
              limit: 1
              order_by: { created_at: desc }
            ) {
              person {
                first_name
                last_name
                placeholder_count
                created_at
              }
            }
          }
          real_active_people: people(
            where: { active: { _eq: true }, is_placeholder: { _eq: false } }
            order_by: { first_name: asc, last_name: asc }
          ) {
            id
            ...PersonSelector_people
          }
          ...TeamSelector_account
          ...CustomFieldSectionHeaderPerson_account
        }
      }
    `,
    context,
  )

  const [selectedPeople, setSelectedPeople] = useState(
    (placeholder?.placeholder_suggestions ?? []).map(
      (suggestion) => suggestion.suggested_person_id,
    ),
  )
  const placeholderSuggestedPeopleFeature = useFeature(
    "placeholder_suggested_people",
  )

  const editingPlaceholder = typeof placeholder !== "undefined"

  const { team, tags, last_name, first_name } = placeholder || {}

  const { roles } = data.account

  const { can, subject } = usePermissions()
  const showCosts = can("view", subject("Salary"))
  const role = roles.find((r) => r.id === roleId)
  const [selectedRole, setSelectedRole] = useState(
    role && {
      value: role.id,
      label: role.name,
      active: role.active,
      defaultHourCost: role.default_hour_cost,
    },
  )

  const newPlaceholderLastName = selectedRole
    ? getPlaceholderLastName(roles, selectedRole.value)
    : ""

  const [firstName, setFirstName] = useState(
    editingPlaceholder ? first_name : role?.name || "",
  )
  const [updatedFirstName, setUpdatedFirstName] = useState(false)
  const [lastName, setLastName] = useState(
    editingPlaceholder ? last_name : newPlaceholderLastName || "",
  )
  const [updatedLastName, setUpdatedLastName] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(
    team ? { value: team?.id, label: team?.name } : null,
  )

  const { peopleFilter } = usePlannerFilters()

  const defaultCost = cost ?? role.default_hour_cost
  const [contractCost, setContractCost] = useState(
    showCosts ? defaultCost : undefined,
  )
  const [selectedTags, setSelectedTags] = useState(
    tags?.map((value) => ({ value, label: value })) || [],
  )

  const [selectedSkills, setSelectedSkills] = useState<SkillOption[]>(
    placeholder?.competencies
      ?.map((c) => {
        return {
          value: c.skill.id,
          label: c.skill.name,
          level: c.level,
        }
      })
      .sort((a, b) => sortByString(a.label, b.label)) || [],
  )
  const [isSaving, setIsSaving] = useState(false)
  const competencies = selectedSkills.map((s) => {
    return {
      skill: { name: s.label },
      level: s.level || null,
    }
  })

  const [customValues, setCustomValues] = useState<CustomValuesMap>({
    [CustomTypeName.TEXT]: placeholder ? placeholder.custom_text_values : [],
    [CustomTypeName.DATE]: placeholder ? placeholder.custom_date_values : [],
    [CustomTypeName.SELECT]: placeholder
      ? placeholder.custom_select_values
      : [],
    [CustomTypeName.CHECKBOX]: placeholder
      ? placeholder.custom_checkbox_values
      : [],
  })

  // We get the state if user updates the names
  // so that we can figure out whether or not to
  // update the name based on role
  const onChangeFirstName = (e) => {
    setFirstName(e.target.value)
    setUpdatedFirstName(true)
  }

  const onChangeLastName = (e) => {
    setLastName(e.target.value)
    setUpdatedLastName(true)
  }

  const onChangeRole = (e) => {
    const defaultHourCost = roles.find(
      (r) => r.id === e.value,
    ).default_hour_cost
    setSelectedRole({ defaultHourCost, ...e })

    if (!updatedFirstName && !updatedLastName) {
      setFirstName(e.label)
      setLastName(getPlaceholderLastName(roles, e.value))
    }
  }

  const updatePlaceholder = async (formattedPlaceholder) => {
    try {
      await placeholderUpdateRelay({
        input: formattedPlaceholder,
        replacePlaceholderSuggestedPeopleInput: {
          placeholderId: placeholder.id,
          suggestedPeopleIds: Array.from(selectedPeople.values()),
        },
      })
      setIsSaving(false)
      onClose()
    } catch {
      setIsSaving(false)
    }
  }

  const onSubmit = async () => {
    setIsSaving(true)
    const teamId = selectedTeam ? selectedTeam.value : null

    const formattedPlaceholder = {
      first_name: firstName,
      last_name: lastName,
      role_id: selectedRole.value,
      team_id: teamId,
      cost: contractCost,
      tags: selectedTags.map((t) => t.label.trim()),
      competencies,
      custom_values: customValues,
    }

    try {
      if (editingPlaceholder) {
        void updatePlaceholder({
          person_id: placeholder.id,
          ...formattedPlaceholder,
        })

        track("Placeholder Updated", {
          tags: selectedTags,
          skills: competencies,
          team: selectedTeam,
        })
        return
      }

      const placeholders = await placeholderBulkCreateRelay({
        input: { placeholders: [formattedPlaceholder] },
        plannerStartDate: PLANNER_INITIAL_DATE,
        _peopleFilter: peopleFilter ? JSON.stringify(peopleFilter) : null,
      })

      if (placeholders) {
        props.onSuccess(placeholders[0])
        track("Placeholder Created", { created_count: 1 })
      }
    } catch (error) {
      void reportError(
        `placeholder ${
          editingPlaceholder
            ? "updatePlaceholder"
            : "placeholderBulkCreateRelay"
        } error: `,
        error,
      )
    }
  }

  const subtitle = `${selectedRole.label} ${project?.name ? `| ${project?.name}` : ""}`

  return (
    <>
      {placeholder && project.id ? (
        <PlaceholderActionButtons
          placeholder={placeholder}
          project={project}
          closePanel={onClose}
        />
      ) : null}
      <SidePanelHeader
        title={formatName(firstName, lastName)}
        subtitle={subtitle}
        type="placeholder"
      />
      <h4
        className={styles.title}
      >{`${editingPlaceholder ? "Edit Details" : "Details"}`}</h4>
      <ModalBody style={{ paddingTop: "24px" }}>
        <div className={styles.formWrapper}>
          <Input
            name="first-name"
            id="first-name"
            label="First Name"
            placeholder={firstName || "First name"}
            value={firstName}
            onChange={onChangeFirstName}
            autoFocus
            className="fs-exclude"
          />
          <Input
            name="last-name"
            id="last-name"
            label="Last Name"
            placeholder={lastName || "Last name"}
            value={lastName}
            onChange={onChangeLastName}
            className="fs-exclude"
          />
          <div
            className={cc({
              [styles.twoColumn]: !editingPlaceholder,
            })}
          >
            {!editingPlaceholder && (
              <RoleSelector
                label="Role"
                name="placeholder-role"
                defaultRole={selectedRole}
                value={selectedRole}
                onChange={onChangeRole}
              />
            )}
            <TeamSelector
              type="person"
              id="team"
              label="Team (optional)"
              account={data.account}
              alwaysShow
              defaultTeam={selectedTeam}
              onChange={(e) => setSelectedTeam(e)}
              isClearable
            />
          </div>
          {splitScreenProps && selectedRole.value !== assignmentRoleId && (
            <SmallAlert intent="warning">
              <span>
                The selected assignment(s) were originally scheduled to a{" "}
                <b>{roles.find((r) => assignmentRoleId === r.id).name}</b>.
                Selecting <b>{selectedRole.label}</b> will {modeAction} them to
                a new <b>{selectedRole.label}</b> placeholder.
              </span>
            </SmallAlert>
          )}
          <SkillsSelector
            selectedSkills={selectedSkills}
            setSelectedSkills={(updatedSkills) =>
              setSelectedSkills(updatedSkills)
            }
          />
          <PersonTagSelector
            label="People Tags (optional)"
            onChange={(e) => setSelectedTags(e)}
            existingTags={tags}
          />
          <Feature name="placeholder_suggested_people">
            <div>
              <InputLabel
                htmlFor="placeholder-suggested-people"
                label={
                  <TitledHelpTooltip
                    title="Suggested people"
                    tooltipContent="Suggest people you think would be appropriate to assign"
                  />
                }
              />
              <PersonSelector
                id="placeholder-suggested-people"
                selected={selectedPeople}
                people={data.account.real_active_people}
                maxSelected={5}
                addPerson={(id) => {
                  setSelectedPeople((v) => {
                    if (v.includes(id)) {
                      return v
                    }
                    return [...v, id]
                  })
                }}
                removePerson={(id) => {
                  setSelectedPeople((v) =>
                    v.filter((personId) => personId !== id),
                  )
                }}
              />
              {selectedPeople.length >= 5 ? (
                <div className={styles.tooManyPlaceholdersMessage}>
                  <Icon icon="small-info-sign" size={16} />
                  You can suggest up to 5 people
                </div>
              ) : null}
            </div>
          </Feature>
          {showCosts && (
            <div>
              <CurrencyInput
                name="contract-cost"
                value={contractCost}
                label={
                  <TitledHelpTooltip
                    title="Hourly Cost"
                    tooltipContent={
                      <>
                        <div>
                          Hourly costs are used to forecast
                          <br />
                          the expected internal cost of this role
                        </div>
                      </>
                    }
                  />
                }
                style={{
                  textAlign: "left",
                }}
                onChange={setContractCost}
                autoComplete="off"
              />
              <p className={styles.costStatus}>
                Default hourly cost for{" "}
                <a
                  href={routes.viewRoleUrl(
                    hashids.roles.encode(selectedRole.value),
                  )}
                  target="_blank"
                  rel="noopener"
                >
                  {selectedRole.label}
                </a>{" "}
                is ${selectedRole.defaultHourCost}
              </p>
            </div>
          )}
          <CustomFieldSectionHeader type="person" account={data.account} />
          <PersonCustomEditor person={placeholder} onUpdate={setCustomValues} />
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <Button text="Cancel" onClick={onClose} disabled={isSaving} />
          <Button
            text={saveButtonText || "Save"}
            id="placeholder-submit"
            loading={isSaving}
            outlined={false}
            onClick={onSubmit}
            disabled={
              !selectedRole ||
              isSaving ||
              !firstName.trim() ||
              !lastName.trim() ||
              (placeholderSuggestedPeopleFeature
                ? selectedPeople.length > 5
                : false)
            }
          />
        </div>
      </ModalFooter>
    </>
  )
}

export default PlaceholderForm
