/**
 * @generated SignedSource<<aebe5168d0e057141df152a679239009>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomFieldSectionHeaderProject_account$data = {
  readonly custom_checkbox_types_project: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_date_types_projects: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_select_types_project: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly custom_text_types_project: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly " $fragmentType": "CustomFieldSectionHeaderProject_account";
};
export type CustomFieldSectionHeaderProject_account$key = {
  readonly " $data"?: CustomFieldSectionHeaderProject_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomFieldSectionHeaderProject_account">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PROJECT"
      }
    }
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomFieldSectionHeaderProject_account",
  "selections": [
    {
      "alias": "custom_text_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_date_types_projects",
      "args": (v0/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_select_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_checkbox_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "15831dbe5c163e10ccb3752714844d2f";

export default node;
