import React from "react"

import styles from "./PhaseMenuItem.module.css"

import MenuItem from "~/common/MenuItem"
import PhaseIcon from "~/common/PhaseIcon"

import { NO_PHASE } from "~/ProjectDashboard/Body/ProjectPhases/constants"

const PhaseMenuItem = (props: PhaseMenuItem) => {
  const { phases, phaseId, onPhaseSelect } = props

  if (!phases || !phases.length) {
    return null
  }

  const sortedPhases = phases
    .slice()
    .sort((a, b) => Number(a.start_date) - Number(b.start_date))

  return (
    <MenuItem text="Phase">
      <MenuItem
        key="no-phase"
        className={`${styles.phasePickerItem} ${
          phaseId === "No Phase" ? styles.currentPhase : ""
        }`}
        icon={<PhaseIcon size={15} color={NO_PHASE.color} />}
        text="No Phase"
        onClick={() => onPhaseSelect(null)}
      />
      {sortedPhases.map((phase) => (
        <MenuItem
          key={phase.id}
          className={`${styles.phasePickerItem} ${
            phase.id === phaseId ? styles.currentPhase : ""
          }`}
          icon={<PhaseIcon size={15} color={phase.color} />}
          text={phase.name}
          onClick={() => onPhaseSelect(phase.id)}
        />
      ))}
    </MenuItem>
  )
}

type PhaseMenuItem = {
  phases: {
    id: number
    color: string
    name: string
    start_date?: string
    end_date?: string
  }[]
  phaseId?: number | "No Phase"
  onPhaseSelect: (phaseId: number | null) => void
}

export default PhaseMenuItem
