import * as fe from "@runn/filter-engine"

import { getCurrentContract } from "~/helpers/person"

type FilterablePerson = {
  id: number
  is_favourite: boolean
  active: boolean
  is_placeholder: boolean
  first_name: string
  last_name: string
  email: string | null
  current_contract: ReadonlyArray<{
    employment_type: string
    job_title: string
    role: {
      id: number
      name: string
    }
  }>
  team: null | {
    id: number
    name: string
  }
  tags: ReadonlyArray<string>
  projects: ReadonlyArray<{
    id: number
    name: string
    active: boolean
    confirmed: boolean
    is_template: boolean
    client: {
      id: number
      name: string
    }
    has_assignments?: boolean
    members: ReadonlyArray<{
      id: number
      person_id: number
      role_id: number
      workstream_id: number | null
    }>
    custom_text_values: ReadonlyArray<{
      id: number
      custom_text_type_id: number
      value: string
    }>
    custom_date_values: ReadonlyArray<{
      id: number
      custom_date_type_id: number
      value: string
    }>
    custom_select_values: ReadonlyArray<{
      id: number
      custom_select_option_id: number
      custom_select_type_id: number
    }>
    custom_checkbox_values: ReadonlyArray<{
      id: number
      custom_checkbox_type_id: number
      value: boolean
    }>
    managers: ReadonlyArray<{
      user_id: number
    }>
  }>
  assignments: ReadonlyArray<{
    project_id: number
    end_date: string
  }>
  competencies: ReadonlyArray<{
    level: null | number
    skill: {
      id: number
      name: string
    }
  }>
  person_requests?: ReadonlyArray<{
    id: number
    status: string
  }>
  custom_text_values: ReadonlyArray<{
    id: number
    custom_text_type_id: number
    value: string
  }>
  custom_date_values: ReadonlyArray<{
    id: number
    custom_date_type_id: number
    value: string
  }>
  custom_select_values: ReadonlyArray<{
    id: number
    custom_select_option_id: number
    custom_select_type_id: number
  }>
  custom_checkbox_values: ReadonlyArray<{
    id: number
    custom_checkbox_type_id: number
    value: boolean
  }>
  managers: ReadonlyArray<{
    user_id: number
  }>
}

type FilterableProject = {
  id: number
  is_favourite: boolean
  is_template: boolean
  active: boolean
  name: string
  confirmed: boolean
  client: {
    id: number
    name: string
  }
  pricing_model: string
  tags_computed: ReadonlyArray<string>
  team: null | {
    id: number
    name: string
  }
  people: ReadonlyArray<{
    id: number
    first_name: string
    last_name: string
    email: string | null
    is_placeholder: boolean
    team: null | {
      id: number
    }
    project_memberships: ReadonlyArray<{
      id: number
      project_id: number
    }>
    person_requests?: ReadonlyArray<{
      id: number
      status: string
    }>
    custom_text_values: ReadonlyArray<{
      id: number
      custom_text_type_id: number
      value: string
    }>
    custom_date_values: ReadonlyArray<{
      id: number
      custom_date_type_id: number
      value: string
    }>
    custom_select_values: ReadonlyArray<{
      id: number
      custom_select_option_id: number
      custom_select_type_id: number
    }>
    custom_checkbox_values: ReadonlyArray<{
      id: number
      custom_checkbox_type_id: number
      value: boolean
    }>
    managers: ReadonlyArray<{
      user_id: number
    }>
  }>
  members: ReadonlyArray<{
    id: number
    person_id: number
    role_id: number
    workstream_id: number | null
  }>
  project_workstreams: ReadonlyArray<{
    workstream: {
      id: number
    }
  }>
  custom_text_values: ReadonlyArray<{
    id: number
    custom_text_type_id: number
    value: string
  }>
  custom_date_values: ReadonlyArray<{
    id: number
    custom_date_type_id: number
    value: string
  }>
  custom_select_values: ReadonlyArray<{
    id: number
    custom_select_option_id: number
    custom_select_type_id: number
  }>
  custom_checkbox_values: ReadonlyArray<{
    id: number
    custom_checkbox_type_id: number
    value: boolean
  }>
  managers: ReadonlyArray<{
    user_id: number
  }>
}

type PrepareDataForFilterOptions<Project, Person> = {
  filterTypeList: string[]
  projects: readonly Project[]
  people: readonly Person[]
  favouriteProjectIds?: readonly number[]
  favouritePersonIds?: readonly number[]
  calStartNum: number
}

type PrepareDataForPeopleFilterOptions<Project, Person> =
  PrepareDataForFilterOptions<Project, Person> & {
    includeArchivedProjects: boolean
  }

type PDProjectListProject = Omit<FilterableProject, "is_favourite" | "people">

type PDProjectListPerson = FilterableProject["people"][0]
type AllowedFilter = fe.engines.local.AllowedFilter

type TypePrefix<T extends string, K> = K extends AllowedFilter
  ? K["type"] extends `${T}_${string}`
    ? K
    : never
  : never

export type AllowedProjectFilter = TypePrefix<"project", AllowedFilter>
export type AllowedPeopleFilter = TypePrefix<"person", AllowedFilter>

type PlannerFilterSet<T extends AllowedFilter> = {
  name: string
  filters: fe.filters.FilterListItem<T> | null
}
export type AllowedProjectFilterSet = PlannerFilterSet<AllowedProjectFilter>

export type AllowedPeopleFilterSet = PlannerFilterSet<AllowedPeopleFilter>

const prepareDataForFilterProjectList = <
  Project extends PDProjectListProject,
  Person extends PDProjectListPerson,
>(
  options: PrepareDataForFilterOptions<Project, Person>,
): FilterableProject[] => {
  const { filterTypeList, projects, people, favouriteProjectIds = [] } = options

  const favouriteProjectIdSet = new Set<number>(favouriteProjectIds)

  const peopleGroupedByProject = new Map<number, Set<Person>>()

  // optimization:
  // we only populate the peopleGroupedByProject array if the filter set requires it
  const hasProjectPersonFilter = filterTypeList.some(
    (filterType) =>
      filterType === "project_wild_search" ||
      filterType.startsWith("project_person_"),
  )

  if (hasProjectPersonFilter) {
    const peopleWithMemberships = people.filter(
      (person) => !!person.project_memberships,
    )

    for (const person of peopleWithMemberships) {
      for (const membership of person.project_memberships) {
        const projectId = membership.project_id
        const peopleInProject =
          peopleGroupedByProject.get(projectId) || new Set()
        peopleInProject.add(person)
        peopleGroupedByProject.set(projectId, peopleInProject)
      }
    }
  }

  const projectList = projects.map((project) => ({
    ...project,
    is_favourite: favouriteProjectIdSet.has(project.id),
    people:
      hasProjectPersonFilter && peopleGroupedByProject.has(project.id)
        ? Array.from(peopleGroupedByProject.get(project.id) ?? [])
        : [],
  }))

  return projectList
}

type PDPersonListPerson = Omit<
  FilterablePerson,
  "is_favourite" | "projects" | "current_contract"
> & {
  contracts: readonly {
    start_date: string
    end_date: string
    employment_type: string
    role: {
      id: number
      name: string
    }
  }[]
}

type PDPersonListProject = FilterablePerson["projects"][0]

const addToProjectsGroup = (projectsGroupedByPerson, personId, project) => {
  if (!projectsGroupedByPerson.has(personId)) {
    projectsGroupedByPerson.set(personId, new Set())
  }
  projectsGroupedByPerson.get(personId).add(project)
}

const getPersonList = (
  people,
  favouritePersonIdSet,
  hasProjectFilter,
  projectsGroupedByPerson,
) =>
  people.map((person) => {
    // NOTE(GC): person.contracts should always exist, but we have noticed
    // errors in production where it is undefined
    const currentContract = person.contracts
      ? getCurrentContract(person.contracts)
      : undefined

    const is_favourite = favouritePersonIdSet.has(person.id)

    return {
      ...person,
      current_contract: currentContract ? [currentContract] : [],
      is_favourite,
      projects:
        hasProjectFilter && projectsGroupedByPerson.has(person.id)
          ? Array.from(projectsGroupedByPerson.get(person.id))
          : [],
    }
  })

const prepareDataForFilterPersonList = <
  Person extends PDPersonListPerson,
  Project extends PDPersonListProject,
>(
  options: PrepareDataForPeopleFilterOptions<Project, Person>,
): FilterablePerson[] => {
  const {
    filterTypeList,
    projects = [],
    people = [],
    favouritePersonIds,
    calStartNum,
    includeArchivedProjects = false,
  } = options

  const calStartDate = String(calStartNum)

  const favouritePersonIdSet = new Set<number>(favouritePersonIds)

  const projectsGroupedByPerson = new Map<number, Set<Project>>()

  // we only filter by assignments if using the Scheduled in Future filter
  const hasPersonProjectIdFilter = filterTypeList.some((filterType) =>
    filterType.startsWith("person_project_id"),
  )

  const filterByProjectMembership = filterTypeList.some(
    (filterType) =>
      filterType === "person_wild_search" ||
      filterType.startsWith("person_membership") ||
      (filterType.startsWith("person_project_") &&
        filterType !== "person_project_id"),
  )

  // optimization:
  // we only populate the projectsGroupedByPerson array if the filter set requires it
  const hasProjectFilter = hasPersonProjectIdFilter || filterByProjectMembership

  if (!hasProjectFilter) {
    return getPersonList(
      people,
      favouritePersonIdSet,
      hasProjectFilter,
      projectsGroupedByPerson,
    )
  }

  const projectMemberMap = new Map<number, Project[]>()

  for (const project of projects) {
    if (!includeArchivedProjects && !project.active) {
      continue
    }

    for (const member of project.members) {
      const personId = member.person_id
      const projectsByPerson = projectMemberMap.get(personId) || []
      projectsByPerson.push(project)
      projectMemberMap.set(personId, projectsByPerson)
    }
  }

  for (const person of people) {
    const personId = person.id
    const personProjects = projectMemberMap.get(personId)

    if (!personProjects) {
      continue
    }

    if (filterByProjectMembership) {
      for (const project of personProjects) {
        addToProjectsGroup(projectsGroupedByPerson, personId, project)
      }
    }

    if (hasPersonProjectIdFilter) {
      // NOTE(GC): person.assignments should exist, but we have noticed errors
      // in production
      const assignments = person.assignments || []

      if (person.is_placeholder) {
        //Placeholders can only have one project
        const project = personProjects[0]
        const hasAssignments = assignments.length > 0
        addToProjectsGroup(projectsGroupedByPerson, personId, {
          ...project,
          has_assignments: hasAssignments,
        })
      } else {
        const filteredProjects = projects.filter((project) =>
          assignments.some(
            (assignment) =>
              assignment.end_date >= calStartDate &&
              assignment.project_id === project.id,
          ),
        )

        for (const project of filteredProjects) {
          addToProjectsGroup(projectsGroupedByPerson, personId, {
            ...project,
            has_assignments: true,
          })
        }
      }
    }
  }

  const personList = getPersonList(
    people,
    favouritePersonIdSet,
    hasProjectFilter,
    projectsGroupedByPerson,
  )

  return personList
}

export { prepareDataForFilterProjectList, prepareDataForFilterPersonList }

export type {
  FilterablePerson,
  FilterableProject,
  PDPersonListProject,
  PDPersonListPerson,
  PDProjectListPerson,
  PDProjectListProject,
}
