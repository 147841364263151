import { dateHelpers } from "@runn/calculations"
import {
  differenceInCalendarDays,
  isMonday,
  isSameDay,
  isSaturday,
  isSunday,
  subDays,
} from "date-fns"
import React, { useState } from "react"
import isDeeplyEqual from "react-fast-compare"

import styles from "./Splitters.module.css"

import { getTimeFrames } from "~/helpers/CalendarHelper"

import { getEarliestDate, getLatestDate } from "~/common/Pill/action_helpers"

const Splitter = (props: SplitterProps) => {
  const { date, pillDay, dayWidth, firstOffset, timeOffs, onClick } = props
  // In a week view, we make the very first offset different so the splitter always lines up with monday
  const offset = pillDay === 0 ? firstOffset : firstOffset + dayWidth * pillDay
  const parsedDate = dateHelpers.parseRunnDate(date)

  const [isCutting, setIsCutting] = useState(false)

  if (
    isSaturday(parsedDate) &&
    timeOffs.some(
      (timeOff) =>
        timeOff.start_date ===
        dateHelpers.formatToRunnDate(subDays(parsedDate, 1)),
    )
  ) {
    return null
  }

  if (isSunday(parsedDate)) {
    return null
  }

  const splitAssignment = (e) => {
    if (e.button === 0) {
      // only left click
      setIsCutting(true)
      setTimeout(() => {
        setIsCutting(false)
        onClick(date)
      }, 200)
    }
  }

  return (
    <div
      onMouseDown={splitAssignment}
      className={`${styles.splitter} ${isCutting ? styles.isCutting : ""}`}
      style={{ left: `${offset}%` }}
    >
      <div className={`${styles.arrow} ${styles.down}`} />
      <div className={styles.splitterLine} />
      <div className={`${styles.arrow} ${styles.up}`} />
    </div>
  )
}

const Splitters = (props: SplittersProps) => {
  const {
    item,
    calendarStartDate,
    calendarEndDate,
    itemVisibleDays,
    onClick,
    byWeek,
    calendarWeekendsExpanded,
    timeOffs,
  } = props

  const visibleStartDate = dateHelpers.parseRunnDate(
    getLatestDate(
      item.start_date,
      dateHelpers.formatToRunnDate(calendarStartDate),
    ),
  )
  const visibleEndDate = dateHelpers.parseRunnDate(
    getEarliestDate(
      item.end_date,
      dateHelpers.formatToRunnDate(calendarEndDate),
    ),
  )

  if (isSameDay(visibleStartDate, visibleEndDate)) {
    return null
  }

  const { dailyDates } = getTimeFrames({
    start: visibleStartDate,
    end: visibleEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  let dates = dailyDates.filter((date, i) => i !== 0) // remove first item and return new array

  let dayWidth = (1 / itemVisibleDays) * 100

  let firstOffset = dayWidth

  if (byWeek) {
    const daysInWeek = calendarWeekendsExpanded ? 7 : 5
    dates = dates.filter((d) => isMonday(d)) // Only include mondays
    const daysToMonday =
      differenceInCalendarDays(dates[0], visibleStartDate) - 2 // Always goes through a weekend. So remove the weeknds
    firstOffset = daysToMonday * dayWidth // the offset to the very first monday
    dayWidth = dayWidth * daysInWeek // we change the day width to be one week worth of space
  }

  return (
    <div className={styles.splittersContainer}>
      {dates.map((date, i) => {
        const dateString = dateHelpers.formatToRunnDate(date)
        return (
          <Splitter
            key={dateString}
            firstOffset={firstOffset}
            date={dateString}
            dayWidth={dayWidth}
            pillDay={i}
            onClick={onClick}
            byWeek={byWeek}
            timeOffs={timeOffs}
          />
        )
      })}
    </div>
  )
}

type SplittersProps = {
  item: Item
  calendarStartDate: Date
  calendarEndDate: Date
  itemVisibleDays: number
  onClick: (number) => void
  byWeek?: boolean
  calendarWeekendsExpanded: boolean
  timeOffs: TimeOff[]
}

type TimeOff = {
  start_date: string
  leave_type: string
}

type SplitterProps = {
  date: string
  pillDay: number
  dayWidth: number
  firstOffset: number
  onClick: (number) => void
  byWeek?: boolean
  timeOffs: TimeOff[]
}

type Item = {
  start_date: string
  end_date: string
  is_placeholder?: boolean
}

const areEqual = (prevProps, nextProps) => {
  if (nextProps.movementType === "move") {
    // If we are moving. We dont need to re-render splitters
    return true
  }

  // Compare everything except the onClick
  // Because onClick wont change but also and cant be deeply equal
  const updatedPrevProps = { ...prevProps, onClick: null }
  const updatedNextProps = { ...nextProps, onClick: null }
  return isDeeplyEqual(updatedPrevProps, updatedNextProps)
}

export default React.memo(Splitters, areEqual)
