/**
 * @generated SignedSource<<17bd1289fff7bdff8535fd86743339af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ViewDeleteMutation$variables = {
  id: number;
};
export type ViewDeleteMutation$data = {
  readonly deleteView: {
    readonly account: {
      readonly id: number;
      readonly views: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type ViewDeleteMutation = {
  response: ViewDeleteMutation$data;
  variables: ViewDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteViewPayload",
    "kind": "LinkedField",
    "name": "deleteView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "views",
            "kind": "LinkedField",
            "name": "views",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c17b63eefb9b3c7d2c1f35bcbea28deb",
    "id": null,
    "metadata": {},
    "name": "ViewDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ViewDeleteMutation(\n  $id: Int!\n) {\n  deleteView(input: {id: $id}) {\n    account {\n      id\n      views {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c59ac75aaf3ee85e48b5b8ce84f21310";

export default node;
