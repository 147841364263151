import * as relayids from "~/helpers/relayids"

import * as computedFields from "./common/computed-fields"
import * as relationships from "./common/relationships"

import { RELAY_ASSIGNMENT_LOCATION } from "~/GLOBALVARS"
import type { Model } from "~/subscriptions/AuditLogSubscription/types"

type Assignment = {
  id: number
  project_id: number
  role_id: number
  person_id: number
  phase_id: number
  start_date: string
  end_date: string
}

const assignmentSubscription: Model<Assignment> = {
  tableName: "assignments",
  relationships: [
    relationships.project(RELAY_ASSIGNMENT_LOCATION),
    relationships.person(RELAY_ASSIGNMENT_LOCATION),
    {
      getDataId: (row) => relayids.phases.encode(row.phase_id),
      pluralField: "assignments",
    },
    {
      getDataId: (row) => relayids.roles.encode(row.role_id),
      pluralField: "assignments",
    },
  ],
  getDataId: (row) => relayids.assignments.encode(row.id),
  transformFields: {
    id: computedFields.id("assignments"),
    start_date: computedFields.startDate,
    end_date: computedFields.endDate,
  },
}

export default assignmentSubscription
