import * as fe from "@runn/filter-engine"
import React from "react"

import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import type {
  FilterComponentProps,
  FilterValues,
} from "~/common/SuperSearch/types"
import { SkillIcon } from "~/common/react-icons"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

import {
  FilterBlockWithSkillLevel,
  useAutoRemoveMissingListValues,
  useSkills,
} from "./common"

type BaseProps = {
  user: SuperSearch_user$data
  filter: fe.filters.FilterProjectPersonSkillId
}

type Props = FilterComponentProps & BaseProps

const useValues = (
  props: BaseProps,
): FilterValues<fe.filters.SkillWithLevel> => {
  const { user, filter } = props

  /*
   * We need to be backwards compatible with the old list format (which is just
   * a list of skillIds).
   * We use the migrateLegacySkillIdList function to convert the old list
   * format to the new format, with all levels enabled.
   */
  const skillLevelList = fe.filters.migrateLegacySkillIdList(
    filter.options.list,
  )

  const availableOptions = useSkills({ user, skillLevelList })

  const selectedOptions = skillLevelList
    // here we use flatMap as an alternative to map + filter
    // (i.e. to avoid using .filter(Boolean))
    // this is why we return an empty array if the value is not found
    .flatMap((selectedValue) => {
      return (
        availableOptions.find(
          (option) => option.value.skillId === selectedValue.skillId,
        ) ?? []
      )
    })

  return {
    name: "Skill",
    availableOptions,
    selectedOptions,
  }
}

const Component = (props: Props) => {
  const {
    user,
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (list: fe.filters.SkillWithLevelList) => {
    return onChange(path, fe.filters.projectPersonSkillId({ list }))
  }

  const handleRemove = () => onRemove(path)

  const { availableOptions, selectedOptions, name } = useValues({
    user,
    filter,
  })

  useAutoRemoveMissingListValues({
    filter,
    selectedOptions,
    onChange: handleChange,
  })

  return (
    <FilterBlockWithSkillLevel
      competencyList={filter.options.list}
      icon={<SkillIcon />}
      label={name}
      filterLabelPlural="skills"
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { filter, user } = props

  const { selectedOptions, name } = useValues({
    user,
    filter,
  })

  return <FilterBlockReadonly name={name} selectedOptions={selectedOptions} />
}

export const ProjectPersonSkillId = {
  Component,
  ReadonlyComponent,
}
