import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { addWeeks, isWeekend, subBusinessDays } from "date-fns"
import { useFeature } from "flagged"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./DragToCreateOutline.module.css"
import pillStyles from "~/common/Pill/BasicPill/BasicPill.module.css"

import { DragToCreateOutline_account$key } from "./__generated__/DragToCreateOutline_account.graphql"

import {
  getItemOffsetPercentNum,
  getNewlyMovedDate,
  getTimeFrames,
} from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"
import { getMinutesPerDayForDate } from "~/helpers/contract-helpers"
import {
  getClosestNextWorkingDay,
  getClosestPreviousWorkingDay,
} from "~/helpers/holiday-helpers"
import { dateIsAHoliday } from "~/helpers/holiday-helpers"

import {
  bulkEditAssignment,
  calculateAssignmentsUpdates,
  updateAndCreateAssignments,
} from "~/common/Pill/AssignmentActionHelpers"
import { updateAndCreateTimeOffs } from "~/common/Pill/TimeOffActionHelpers"
import PlannerDatePicker, {
  OnSubmitProps,
} from "~/common/PlannerDatePicker/PlannerDatePicker"
import { Popover2 } from "~/common/Popover2"
import { CalendarPeriods } from "~/common/calendar.reducer"

import { TIME_OFF_TYPES } from "~/ENUMS"
import {
  setHighlightedCellData as setHighlightedCellDataDispatch,
  setHighlightedItemData as setHighlightedItemDataDispatch,
} from "~/GlobalState"
import { showToast } from "~/containers/ToasterContainer"
import { ReduxState } from "~/rootReducer"

import { getAssignmentPillColours } from "../Pill/BasicPill/BasicPill"

import CalendarOutline from "./CalendarOutline"
import { DragToCreateCell } from "./DragToCreateCell"

const DragToCreateOutline = (props: Props) => {
  const {
    person,
    calendarStartDate,
    calendarEndDate,
    calEndNum,
    dayWidth,
    type,
    roleId,
    workstreamId,
    project,
    client,
    setHighlightedItemData,
    companyDefaultMinutes,
    multiSelectItems,
    calendarWeekendsExpanded,
    periods,
    timeOffsWithWeekend,
    multiSelectEnabled,
    holidaysOverlappingTimeOffs,
    canCreate,
  } = props

  const account = useFragment(
    graphql`
      fragment DragToCreateOutline_account on accounts {
        ...PlannerDatePicker_account
      }
    `,
    props.account,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [showTemporaryPill, setShowTemporaryPill] = useState(false)
  const [defaultPhaseId, setDefaultPhaseId] = useState(null)
  const [pillOffset, setPillOffset] = useState(0)
  const [pillWidth, setPillWidth] = useState(0)
  const [startDate, setStartDate] = useState(undefined)
  const [numOfDays, setNumOfDays] = useState(1)
  const [nonWorkingDay, setIsNonWorkingDay] = useState(false)
  const [repeat, setRepeat] = useState<{
    repeatEvery: number
    numberOfAssignments: number
  }>(undefined)
  const multiSelectItemIds = multiSelectItems.map((i) => i.id)
  let mouseXStartingPosition = 0
  let movedBy = 0
  let startDateLocal = ""
  const getVisiblePillWidth = (offset, width) =>
    offset + width > 100 ? 100 - offset : width

  const { dailyDates, totalDays, weeklyDates } = getTimeFrames({
    start: props.calendarStartDate,
    end: props.calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const cells = periods === "weeks" ? weeklyDates : dailyDates
  const daysInWeek = calendarWeekendsExpanded ? 7 : 5
  const isTimeOff = type === "timeOff"
  const weeklyAssignments = periods === "weeks"
  const singleDayWidth = 100 / totalDays
  const weeklyWidth = singleDayWidth * daysInWeek
  const visiblePillWidth = getVisiblePillWidth(pillOffset, pillWidth)

  const formattedPhases: Phase[] = project?.phases?.map((phase) => ({
    id: phase.id,
    color: phase.color,
    name: phase.name,
  }))

  const assignmentPillColours = getAssignmentPillColours(
    defaultPhaseId,
    formattedPhases,
    !project?.confirmed,
  )
  const temporaryPillColor = person.is_placeholder
    ? assignmentPillColours.lighter
    : isTimeOff
      ? "var(--smoke)"
      : assignmentPillColours.color
  const affectedTimeOffs = project?.is_template ? [] : person.time_offs

  useEffect(() => {
    if (popoverOpen) {
      // The user can delete working days and make it 0 or undefined.
      // The input resets, but it never triggers a re-render.
      const correctedNumOfDays = numOfDays ? numOfDays : 1
      setPillWidth(correctedNumOfDays * singleDayWidth)
    }
  }, [numOfDays]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!canCreate) {
    return <CalendarOutline type="standard" timeOffs={person.time_offs} />
  }

  const getMovedBy = (e) => {
    const delta = e.screenX - mouseXStartingPosition
    return Math.round(delta / dayWidth)
  }

  const assignmentInput = (a) => ({
    start_date: dateHelpers.formatToRunnDate(a.startDate),
    end_date: dateHelpers.formatToRunnDate(a.endDate),
    minutes_per_day: Math.round(a.minutesPerDay),
    person_id: person.id,
    role_id: roleId,
    workstream_id: workstreamId || null,
    project_id: project && project.id,
    is_billable:
      project && project.pricing_model === "nb" ? false : a.is_billable,
    is_placeholder: a.is_placeholder,
    is_template: project && project.is_template,
    note: a.note,
    phase_id: a.phaseId,
    non_working_day: nonWorkingDay,
  })

  const closePopover = () => {
    setPopoverOpen(false)
    setNumOfDays(1)
    setShowTemporaryPill(false)
    setRepeat(undefined)
    setDefaultPhaseId(null)
    setIsNonWorkingDay(false)
  }

  const updateTimeOff = (newTimeOff: OnSubmitProps) => {
    track("Timeoff Drag Created", {
      timeoff_type: !newTimeOff.minutesPerDay ? "full-time" : "part-time",
    })
    const timeOff = {
      start_date: dateHelpers.formatToRunnDate(newTimeOff.startDate),
      end_date: dateHelpers.formatToRunnDate(newTimeOff.endDate),
      person_id: newTimeOff.personId,
      note: newTimeOff.note,
      minutes_per_day: newTimeOff.minutesPerDay,
    }
    void updateAndCreateTimeOffs(
      timeOff,
      person,
      multiSelectItemIds,
      !isConsistentTimeOffEnabled,
    )
  }

  const updateAssignment = (a: OnSubmitProps) => {
    track("Assignment Drag Created or Updated")

    let adjustedStartDate = a.startDate
    let adjustedEndDate = a.endDate

    if (!nonWorkingDay && !isConsistentTimeOffEnabled) {
      if (
        dateIsAHoliday(
          affectedTimeOffs,
          dateHelpers.formatToRunnDate(a.startDate),
        )
      ) {
        adjustedStartDate = getClosestNextWorkingDay(
          affectedTimeOffs,
          a.startDate,
        ).date
      }

      if (
        dateIsAHoliday(
          affectedTimeOffs,
          dateHelpers.formatToRunnDate(a.endDate),
        )
      ) {
        adjustedEndDate = getClosestPreviousWorkingDay(
          affectedTimeOffs,
          a.endDate,
        ).date
      }
    }

    void updateAndCreateAssignments(
      {
        ...assignmentInput(a),
        start_date: dateHelpers.formatToRunnDate(adjustedStartDate),
        end_date: dateHelpers.formatToRunnDate(adjustedEndDate),
        id: a.itemId,
      },
      person,
      isConsistentTimeOffEnabled,
    )
    setRepeat(undefined)
    closePopover()
  }

  const formDataToAssignment = (a) => ({
    id: a.itemId,
    start_date: dateHelpers.formatToRunnDate(a.startDate),
    end_date: dateHelpers.formatToRunnDate(a.endDate),
    minutes_per_day: a.minutesPerDay,
    person_id: person.id,
    project_id: a.projectId,
    role_id: a.roleId,
    phase_id: a.phaseId,
    is_billable:
      project && project.pricing_model === "nb" ? false : a.is_billable,
    is_template: project && project.is_template,
    note: a.note,
    non_working_day: a.non_working_day,
    workstream_id: workstreamId || null,
  })

  const onMultiSubmit = (formAssignments) => {
    const updates = formAssignments
      .map(formDataToAssignment)
      .map((a) =>
        calculateAssignmentsUpdates(a, person, isConsistentTimeOffEnabled),
      )

    // Combined all the updates for each assignment (e.g. the merging, updating, deleting etc)
    // So we can bulk all the changes in one go.
    const combinedUpdates = updates.reduce(
      (acc, changes) => {
        acc.create.push(...changes.create)
        acc.update.push(...changes.update)
        acc.delete.push(...changes.delete)
        return acc
      },
      { create: [], update: [], delete: [] },
    )

    // Check if there are multiple updates or deletes to the same assignment. Our logic can't handle this yet.
    // This happens if there is an existing 2 week assignment, and then two 1-day assignments appear inside it
    const updateIds = combinedUpdates.update.map((a) => a.id)
    const deleteIds = combinedUpdates.delete.map((a) => a.id)
    if (
      new Set(updateIds).size !== updateIds.length ||
      new Set(deleteIds).size !== deleteIds.length
    ) {
      return showToast({
        message:
          "Can not create repeating assignments. Too many conflicts to resolve.",
        type: "warning",
      })
    }

    void bulkEditAssignment(combinedUpdates)
    closePopover()
  }

  const updateHighlightData = (start: string, daysToEnd: number) => {
    const daysToAdd = daysToEnd > 0 ? daysToEnd : 0
    const eDate = getNewlyMovedDate({
      originalDate: start,
      cellsMoved: daysToAdd,
      calendarWeekendsExpanded,
    }).dateString
    setHighlightedItemData({
      start_date: start,
      end_date: eDate,
      projectConfirmed: project ? project.confirmed : false,
      type,
    })
  }

  const onMouseMove = (e) => {
    const movedByActual = getMovedBy(e)

    if (
      movedBy === movedByActual ||
      (periods === "days" && dateIsAHoliday(affectedTimeOffs, startDateLocal))
    ) {
      return // Do nothing as nothing has changed yet
    }

    movedBy = movedByActual
    if (weeklyAssignments) {
      const numOfWeeks =
        movedBy <= 1 ? 1 : Math.ceil((movedBy + 1) / daysInWeek)
      setNumOfDays(numOfWeeks * daysInWeek)
      setPillWidth(numOfWeeks * weeklyWidth)
      updateHighlightData(startDateLocal, numOfWeeks * daysInWeek - 1)
    } else {
      const width =
        movedBy >= 1 ? (movedBy + 1) * singleDayWidth : singleDayWidth
      setNumOfDays(movedBy + 1)
      setPillWidth(width)
      updateHighlightData(startDateLocal, movedBy)
    }
  }

  const onMouseUp = () => {
    if (weeklyAssignments) {
      const numOfWeeks =
        movedBy <= 1 ? 1 : Math.ceil((movedBy + 1) / daysInWeek)
      setNumOfDays(numOfWeeks * daysInWeek)
    }

    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)

    if (isTimeOff) {
      setShowTemporaryPill(true)
    }

    if (!popoverOpen) {
      setPopoverOpen(true)
    }
  }

  const onMouseDown = (e, day: Date) => {
    // Only call on left mouse click
    if (e.button === 0) {
      if (!day) {
        return
      }

      const dayString = dateHelpers.formatToRunnDate(day)

      if (
        isWeekend(day) ||
        (periods === "days" && dateIsAHoliday(affectedTimeOffs, dayString))
      ) {
        setIsNonWorkingDay(true)
      }
      mouseXStartingPosition = e.screenX
      setNumOfDays(1)

      const offset = getItemOffsetPercentNum(
        calendarStartDate,
        calendarEndDate,
        { start_date: dayString },
        calendarWeekendsExpanded,
      )
      const width = weeklyAssignments ? weeklyWidth : singleDayWidth
      setStartDate(dayString)
      startDateLocal = dayString

      if (project?.phases?.length) {
        const filteredPhases = project.phases.filter(
          (phase) =>
            dayString >= phase.start_date && dayString <= phase.end_date,
        )

        if (filteredPhases.length) {
          setDefaultPhaseId(filteredPhases[0].id)
        }
      }

      setPillOffset(offset)
      setPillWidth(width)
      setShowTemporaryPill(true)
      updateHighlightData(dayString, 0)

      if (!isWeekend(day)) {
        document.addEventListener("mousemove", onMouseMove)
      }
      document.addEventListener("mouseup", onMouseUp)
    }
  }

  let pillClasses
  if (isTimeOff) {
    pillClasses = pillStyles.timeOff
  } else if (project) {
    pillClasses = project.confirmed
      ? pillStyles.defaultAssignment
      : pillStyles.tentative
  }

  const renderRepeats = () => {
    const repeatPills = []

    for (let i = 0; i < repeat.numberOfAssignments; i++) {
      if (i) {
        const start = dateHelpers.formatToRunnDate(
          addWeeks(
            dateHelpers.parseRunnDate(startDate),
            repeat.repeatEvery * i,
          ),
        )
        const offset = getItemOffsetPercentNum(
          calendarStartDate,
          calendarEndDate,
          { start_date: start },
          calendarWeekendsExpanded,
        )

        if (Number(start) <= calEndNum) {
          repeatPills.push({ offset })
        }
      }
    }

    return (
      <>
        {repeatPills.map((pill) => {
          const repeatedPillWidth = getVisiblePillWidth(pill.offset, pillWidth)
          return (
            <div
              key={pill.offset}
              className={cc([
                "temporaryPill",
                pillClasses,
                pillStyles.temporaryPill,
                pillStyles.basicPill,
                pillStyles.selected,
                {
                  [pillStyles.offscreenPillRight]:
                    repeatedPillWidth !== pillWidth,
                  [pillStyles.nonWorkingDay]: nonWorkingDay,
                },
              ])}
              style={{
                left: `${pill.offset}%`,
                width: `${repeatedPillWidth}%`,
                backgroundColor: temporaryPillColor,
              }}
            />
          )
        })}
      </>
    )
  }

  const renderPopoverContent = () => {
    const minutesPerDay = getMinutesPerDayForDate(
      startDate,
      person.contracts,
      companyDefaultMinutes,
    )

    const newlyMovedEndDate = getNewlyMovedDate({
      originalDate: startDate,
      cellsMoved: numOfDays - 1,
      calendarWeekendsExpanded,
    })

    let endDate = newlyMovedEndDate.dateString

    if (isWeekend(newlyMovedEndDate.date)) {
      endDate = dateHelpers.formatToRunnDate(
        subBusinessDays(newlyMovedEndDate.date, 1),
      )
    }

    if (numOfDays <= 1) {
      endDate = startDate
    }

    return (
      <PlannerDatePicker
        account={account}
        mini
        allowScroll
        nonWorkingDay={nonWorkingDay}
        startDate={startDate}
        endDate={endDate}
        minutesPerDay={minutesPerDay}
        setNumOfDays={setNumOfDays}
        projectId={project && project.id}
        roleId={roleId}
        person={person}
        personId={person.id}
        client={client}
        project={project}
        onSubmit={isTimeOff ? updateTimeOff : updateAssignment}
        onMultiSubmit={onMultiSubmit}
        handleCancel={closePopover}
        isPopoverOpen={popoverOpen}
        closePopover={closePopover}
        onRepeatChange={setRepeat}
        setDefaultPhaseId={setDefaultPhaseId}
        defaultPhaseId={defaultPhaseId}
        isTimeOff={isTimeOff}
      />
    )
  }

  return (
    <>
      {showTemporaryPill && (
        <>
          {/* add overlay to prevent any hovers while dragging */}
          <div
            className={styles.overlay}
            style={{
              cursor: nonWorkingDay || popoverOpen ? "auto" : "e-resize",
            }}
          />
          {/* temporary pill shows onMouseDown/onMouseMove */}
          <div
            className={cc([
              "temporaryPill",
              pillClasses,
              pillStyles.temporaryPill,
              pillStyles.basicPill,
              pillStyles.selected,
              {
                [pillStyles.offscreenPillRight]: visiblePillWidth !== pillWidth,
                [pillStyles.nonWorkingDay]: nonWorkingDay,
              },
            ])}
            style={{
              left: `${pillOffset}%`,
              width: `${visiblePillWidth}%`,
              backgroundColor: temporaryPillColor,
            }}
          />
        </>
      )}
      {popoverOpen && (
        <div
          // popover placement - offset to center of newly created assignment
          style={{
            left: `${pillOffset + pillWidth / 2}%`,
            position: "absolute",
            paddingBottom: "40px",
          }}
        >
          <Popover2
            content={renderPopoverContent()}
            isOpen={popoverOpen}
            onClose={closePopover}
            canEscapeKeyClose={true}
            placement="top"
            rootBoundary="viewport"
            portalClassName={styles.popover}
          >
            <span />
          </Popover2>
        </div>
      )}
      {repeat && renderRepeats()}
      {cells.map((day: Date, index) => {
        const formattedCellDate = dateHelpers.formatToRunnDate(day)
        const disableWeekendIfOnTimeOff =
          !project?.is_template &&
          timeOffsWithWeekend?.includes(Number(formattedCellDate))
        const holidayOverlapsTimeOff =
          holidaysOverlappingTimeOffs?.includes(formattedCellDate)

        const scheduledHolidays = person.time_offs.filter(
          (timeOff) =>
            timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY &&
            timeOff.start_date === dateHelpers.formatToRunnDate(day),
        )

        return (
          <DragToCreateCell
            key={day.getTime()}
            day={day}
            type={type}
            handleMouseDown={onMouseDown}
            setHighlightedCellData={props.setHighlightedCellData}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
            isStartOfCalendar={index === 0}
            isInDaysCalendarView={periods === "days"}
            disabled={
              !Boolean(scheduledHolidays.length) &&
              (disableWeekendIfOnTimeOff || multiSelectEnabled)
            }
            holidayOverlapsTimeOff={holidayOverlapsTimeOff}
            scheduledHolidays={scheduledHolidays}
          />
        )
      })}
    </>
  )
}

type Props = {
  account: DragToCreateOutline_account$key
  allowDelete?: boolean
  person: Person
  calendarStartDate: Date
  calendarEndDate: Date
  calEndNum: number
  dayWidth: number
  multiSelectItems: ReduxState["multiSelect"]["items"]
  multiSelectEnabled: boolean
  setHighlightedCellData: (state: any) => void
  setHighlightedItemData: (item: any) => void
  type: "timeOff" | "assignment"
  project?: Project
  projectId?: string
  roleId?: number
  workstreamId?: number
  client?: Client
  companyDefaultMinutes: number
  calendarWeekendsExpanded: boolean
  periods: CalendarPeriods
  timeOffsWithWeekend?: number[]
  holidaysOverlappingTimeOffs?: string[]
  canCreate: boolean
}

export type Client = {
  id: number
  image_key: string
  website: string
  name: string
}

export type Assignment = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  note?: string
  non_working_day: boolean
}

export type Project = {
  id: number
  pricing_model: string
  confirmed?: boolean
  is_template: boolean
  name?: string
  assignments?: Assignment[]
  phases?: readonly Phases[]
}

type Phases = {
  id: number
  name: string
  start_date: string
  end_date: string
  color: string
}

type Phase = {
  id: number
  color: string
  name: string
}

type Contract = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  role: {
    id: number
    name: string
  }
}

type Person = {
  id: number
  first_name: string
  last_name: string
  contracts: readonly Contract[]
  assignments: readonly AssignmentPerson[]
  time_offs: readonly TimeOff[]
  is_placeholder: boolean
  holidays_group?: {
    name: string
  }
}

type AssignmentPerson = {
  id: number
  role_id: number
  workstream_id: number
  project_id: number
  person_id: number
  phase_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  note?: string
  non_working_day: boolean
}

type TimeOff = {
  id: number
  start_date: string
  end_date: string
  person_id: number
  leave_type: string
  minutes_per_day: number
  note?: string
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
  }>
}

const mapStateToProps = (state: ReduxState) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  calEndNum: state.calendar.calEndNum,
  dayWidth: state.calendar.dayWidth,
  multiSelectItems: state.multiSelect.items,
  multiSelectEnabled: state.multiSelect.isEnabled,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
  periods: state.calendar.periods,
})

const mapDispatchToProps = {
  setHighlightedCellData: setHighlightedCellDataDispatch,
  setHighlightedItemData: setHighlightedItemDataDispatch,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(DragToCreateOutline)
