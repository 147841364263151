/**
 * @generated SignedSource<<8f95170ea3614ffdaec722e7e6e7cdb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectMember = {
  is_placeholder: boolean;
  person_id: number;
  project_id: number;
  role_id: number;
  workstream_id?: number | null | undefined;
};
export type RemoveMembershipFromProjectMenuItem_DeleteMutation$variables = {
  input: ProjectMember;
};
export type RemoveMembershipFromProjectMenuItem_DeleteMutation$data = {
  readonly action_project_member_delete: {
    readonly message: string | null | undefined;
    readonly person: {
      readonly id: number;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly person_id: number;
        readonly project: {
          readonly id: number;
          readonly name: string;
        };
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
    readonly project: {
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly actuals_count: number | null | undefined;
        readonly assignments_count: number | null | undefined;
        readonly id: number;
        readonly is_placeholder: boolean;
        readonly person: {
          readonly first_name: string;
          readonly id: number;
          readonly last_name: string;
        };
        readonly person_id: number;
        readonly project_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
    readonly status: string;
  } | null | undefined;
};
export type RemoveMembershipFromProjectMenuItem_DeleteMutation = {
  response: RemoveMembershipFromProjectMenuItem_DeleteMutation$data;
  variables: RemoveMembershipFromProjectMenuItem_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_placeholder",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProjectMemberDeleteOutput",
    "kind": "LinkedField",
    "name": "action_project_member_delete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignments_count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actuals_count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "project_memberships",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveMembershipFromProjectMenuItem_DeleteMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveMembershipFromProjectMenuItem_DeleteMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "9b9c25386fd6943ead90016bd3fd5243",
    "id": null,
    "metadata": {},
    "name": "RemoveMembershipFromProjectMenuItem_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveMembershipFromProjectMenuItem_DeleteMutation(\n  $input: ProjectMember!\n) {\n  action_project_member_delete(input: $input) {\n    status\n    message\n    project {\n      id\n      members {\n        id\n        project_id\n        role_id\n        person_id\n        is_placeholder\n        workstream_id\n        assignments_count\n        actuals_count\n        person {\n          id\n          first_name\n          last_name\n        }\n      }\n    }\n    person {\n      id\n      project_memberships {\n        id\n        project_id\n        role_id\n        person_id\n        workstream_id\n        is_placeholder\n        project {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "002fba1c2d0bc0d930a6662f8cd8281e";

export default node;
