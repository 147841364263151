import React from "react"

import styles from "./PlannerDatePicker.module.css"

import Button from "~/common/buttons/Button"

const PlannerDatePickerButtons = (props: Props) => {
  const { showDelete, saveDisabled, onSave, handleCancel } = props

  return (
    <div className={styles.buttons}>
      <Button text="Cancel" onClick={handleCancel} />
      <Button
        disabled={saveDisabled}
        outlined={false}
        onClick={onSave}
        text={showDelete ? "Save" : "Add"}
      />
    </div>
  )
}

type Props = {
  showDelete: boolean
  saveDisabled: boolean
  onDelete: () => void
  onSave: () => void
  handleCancel: () => void
  isTimeOff?: boolean
}

export default PlannerDatePickerButtons
