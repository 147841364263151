import * as fe from "@runn/filter-engine"
import { isEmpty } from "lodash-es"
import { P, match } from "ts-pattern"

import {
  people_bool_exp,
  projects_bool_exp,
} from "./FilteredQuery/__generated__/FilterQuery.graphql"

import {
  AllowedPeopleFilter,
  AllowedProjectFilter,
} from "~/helpers/filter-engine"

const notEmpty = P.when(({ length }) => length > 0)

export const buildProjectsFilter = (
  filterSet: AllowedProjectFilter[],
): projects_bool_exp => {
  const clauses = filterSet.map((filter) =>
    match(filter)
      .returnType<projects_bool_exp>()
      .with({ type: "project_wild_search" }, (f) => {
        const query = f.options.query
        return {
          _or: [
            { name: { _ilike: `${query}%` } },
            { client: { name: { _ilike: `${query}%` } } },
            { tags_computed: { _contains: `${query}%` } },
          ],
        }
      })
      .with(
        {
          type: "project_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { id: { _in: [...f.options.list] } }
        },
      )
      .with({ type: "project_is_active" }, (f) => {
        return { active: { _eq: f.options.value } }
      })
      .with({ type: "project_is_confirmed" }, (f) => {
        return { confirmed: { _eq: f.options.value } }
      })
      .with({ type: "project_is_template" }, (f) => {
        return { is_template: { _eq: f.options.value } }
      })
      .with(
        { type: "project_template_id", options: { list: notEmpty } },
        (f) => {
          return {
            _and: [
              { id: { _in: [...f.options.list] } },
              { is_template: { _eq: true } },
            ],
          }
        },
      )
      .with(
        {
          type: "project_team_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { team_id: { _in: [...f.options.list] } }
        },
      )
      .with(
        {
          type: "project_status",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            confirmed: {
              _in: [...f.options.list.map((o) => o === "confirmed")],
            },
          }
        },
      )
      .with(
        {
          type: "project_client_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { client_id: { _in: [...f.options.list] } }
        },
      )
      .with(
        {
          type: "project_tag_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { tags_computed: { _has_keys_any: [...f.options.list] } }
        },
      )
      .with(
        {
          type: "project_person_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            members: {
              person_id: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_team_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            members: {
              person: {
                team_id: { _in: [...f.options.list] },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_skill_id",
          options: { list: notEmpty },
        },
        (f) => {
          /*
           * We need to be backwards compatible with the old list format (which
           * is just a list of skillIds). We use the migrateLegacySkillIdList
           * function to convert the old list format to the new format, with
           * all levels enabled.
           */
          const skillWithLevels = fe.filters.migrateLegacySkillIdList(
            f.options.list,
          )

          return {
            members: {
              person: {
                competencies: {
                  _or: skillWithLevels.flatMap((item) => {
                    const matchNullLevel = item.level.includes(null)
                    const matchLevelNumbers = item.level.filter(
                      (l) => l !== null,
                    )

                    return [
                      matchNullLevel
                        ? {
                            skill_id: { _eq: item.skillId },
                            level: { _is_null: true },
                          }
                        : undefined,
                      matchLevelNumbers.length > 0
                        ? {
                            skill_id: { _eq: item.skillId },
                            level: { _in: matchLevelNumbers },
                          }
                        : undefined,
                    ].filter(Boolean)
                  }),
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_tag_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            members: {
              person: {
                tags: {
                  _has_keys_any: [...f.options.list],
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_custom_text_value",
        },
        (f) => {
          return {
            custom_text_values: {
              value: {
                _ilike: `${f.options.query}%`,
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_custom_select",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            custom_select_values: {
              custom_select_type_id: { _eq: f.options.typeId },
              custom_select_option_id: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "project_custom_checkbox",
        },
        (f) => {
          return {
            custom_checkbox_values: {
              custom_checkbox_type_id: { _eq: f.options.typeId },
              value: { _eq: f.options.query },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_custom_checkbox",
        },
        (f) => {
          return {
            members: {
              person: {
                custom_checkbox_values: {
                  custom_checkbox_type_id: { _eq: f.options.typeId },
                  value: { _eq: f.options.query },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_role_id",
        },
        (f) => {
          return {
            members: {
              person: {
                project_memberships: {
                  role_id: { _in: [...f.options.list] },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_custom_date",
        },
        (f) => {
          if (f.options.datePayload.type === "exact") {
            return {
              custom_date_values: {
                custom_date_type_id: { _eq: f.options.typeId },
                value: {
                  _eq: f.options.datePayload.exact,
                },
              },
            }
          } else {
            const start = f.options.datePayload.start
            const end = f.options.datePayload.end

            return {
              custom_date_values: {
                custom_date_type_id: { _eq: f.options.typeId },
                value: {
                  _gte: start,
                  _lte: end,
                },
              },
            }
          }
        },
      )
      .with(
        {
          type: "project_person_custom_date",
        },
        (f) => {
          if (f.options.datePayload.type === "exact") {
            return {
              members: {
                person: {
                  custom_date_values: {
                    custom_date_type_id: { _eq: f.options.typeId },
                    value: {
                      _eq: f.options.datePayload.exact,
                    },
                  },
                },
              },
            }
          } else {
            const start = f.options.datePayload.start
            const end = f.options.datePayload.end

            return {
              members: {
                person: {
                  custom_date_values: {
                    custom_date_type_id: { _eq: f.options.typeId },
                    value: {
                      _gte: start,
                      _lte: end,
                    },
                  },
                },
              },
            }
          }
        },
      )
      .with(
        {
          type: "project_person_job_title_name",
        },
        (f) => {
          return {
            members: {
              person: {
                contracts: {
                  job_title: {
                    _ilike: `${f.options.query}%`,
                  },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "project_manager_id",
        },
        (f) => {
          return {
            managers: {
              user_id: { _in: f.options.list },
            },
          }
        },
      )
      .with(
        {
          type: "project_person_manager_id",
        },
        (f) => {
          return {
            members: {
              person: {
                managers: {
                  user_id: { _in: f.options.list },
                },
              },
            },
          }
        },
      )
      .with(P.string, (f) => {
        console.warn(
          `WARNING: Filter "${f.type}" is not yet implemented in buildPeopleFilter`,
          f,
        )
        return {}
      })
      .otherwise((f) => {
        console.warn(`Invalid filter ${JSON.stringify(f)}`)
        return {}
      }),
  )

  return { _and: clauses.filter((x) => !isEmpty(x)) }
}

export const buildPeopleFilter = (filterSet: AllowedPeopleFilter[]) => {
  const clauses = filterSet.map((filter) =>
    match(filter)
      .returnType<people_bool_exp>()
      .with({ type: "person_wild_search" }, (f) => {
        const query = f.options.query
        return {
          _or: [
            { first_name: { _ilike: `${query}%` } },
            { last_name: { _ilike: `${query}%` } },
            { email: { _ilike: `${query}%` } },
            { team: { name: { _ilike: `${query}%` } } },
            { contracts: { role: { name: { _ilike: `${query}%` } } } },
            {
              competencies: { skill: { name: { _ilike: `${query}%` } } },
            },
          ],
        }
      })
      .with(
        {
          type: "person_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { id: { _in: [...f.options.list] } }
        },
      )
      .with(
        {
          type: "person_team_id",
          options: { list: notEmpty },
        },
        (f) => {
          return { team_id: { _in: [...f.options.list] } }
        },
      )
      .with(
        {
          type: "person_role_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            contracts: {
              role_id: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "person_tag_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            tags: {
              _has_keys_any: [...f.options.list],
            },
          }
        },
      )
      .with(
        {
          type: "person_skill_id",
          options: { list: notEmpty },
        },
        (f) => {
          /*
           * We need to be backwards compatible with the old list format (which
           * is just a list of skillIds). We use the migrateLegacySkillIdList
           * function to convert the old list format to the new format, with
           * all levels enabled.
           */
          const skillWithLevels = fe.filters.migrateLegacySkillIdList(
            f.options.list,
          )

          return {
            competencies: {
              _or: skillWithLevels.flatMap((item) => {
                const matchNullLevel = item.level.includes(null)
                const matchLevelNumbers = item.level.filter((l) => l !== null)

                return [
                  matchNullLevel
                    ? {
                        skill_id: { _eq: item.skillId },
                        level: { _is_null: true },
                      }
                    : undefined,
                  matchLevelNumbers.length > 0
                    ? {
                        skill_id: { _eq: item.skillId },
                        level: { _in: matchLevelNumbers },
                      }
                    : undefined,
                ].filter(Boolean)
              }),
            },
          }
        },
      )
      .with(
        {
          type: "person_employment_type",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            contracts: {
              employment_type: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "person_type",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            is_placeholder: {
              _in: f.options.list.map((item) => {
                return item === "placeholder"
              }),
            },
          }
        },
      )
      .with(
        {
          type: "person_job_title_name",
        },
        (f) => {
          return {
            contracts: {
              job_title: {
                _ilike: `${f.options.query}%`,
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project_id: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_custom_select",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                custom_select_values: {
                  custom_select_type_id: { _eq: f.options.typeId },
                  custom_select_option_id: { _in: [...f.options.list] },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_custom_text",
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                custom_text_values: {
                  value: {
                    _ilike: `${f.options.query}%`,
                  },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_client_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                client_id: { _in: [...f.options.list] },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_team_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                team_id: { _in: [...f.options.list] },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_tag_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                tags_computed: {
                  _has_keys_any: [...f.options.list],
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_status",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                confirmed: {
                  _in: [...f.options.list.map((o) => o === "confirmed")],
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_custom_text_value",
        },
        (f) => {
          return {
            custom_text_values: {
              value: {
                _ilike: `${f.options.query}%`,
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_custom_select",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            custom_select_values: {
              custom_select_type_id: { _eq: f.options.typeId },
              custom_select_option_id: { _in: [...f.options.list] },
            },
          }
        },
      )
      .with(
        {
          type: "person_custom_checkbox",
        },
        (f) => {
          return {
            custom_checkbox_values: {
              custom_checkbox_type_id: { _eq: f.options.typeId },
              value: { _eq: f.options.query },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_custom_checkbox",
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                custom_checkbox_values: {
                  custom_checkbox_type_id: { _eq: f.options.typeId },
                  value: { _eq: f.options.query },
                },
              },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_role_id",
          options: { list: notEmpty },
        },
        (f) => {
          return {
            project_memberships: {
              person: {
                project_memberships: {
                  role_id: { _in: [...f.options.list] },
                },
              },
            },
          }
        },
      )

      .with(
        {
          type: "person_custom_date",
        },
        (f) => {
          if (f.options.datePayload.type === "exact") {
            return {
              custom_date_values: {
                custom_date_type_id: { _eq: f.options.typeId },
                value: {
                  _eq: f.options.datePayload.exact,
                },
              },
            }
          } else {
            const start = f.options.datePayload.start
            const end = f.options.datePayload.end

            return {
              custom_date_values: {
                custom_date_type_id: { _eq: f.options.typeId },
                value: {
                  _gte: start,
                  _lte: end,
                },
              },
            }
          }
        },
      )
      .with(
        {
          type: "person_project_custom_date",
        },
        (f) => {
          if (f.options.datePayload.type === "exact") {
            return {
              project_memberships: {
                project: {
                  custom_date_values: {
                    custom_date_type_id: { _eq: f.options.typeId },
                    value: {
                      _eq: f.options.datePayload.exact,
                    },
                  },
                },
              },
            }
          } else {
            const start = f.options.datePayload.start
            const end = f.options.datePayload.end

            return {
              project_memberships: {
                project: {
                  custom_date_values: {
                    custom_date_type_id: { _eq: f.options.typeId },
                    value: {
                      _gte: start,
                      _lte: end,
                    },
                  },
                },
              },
            }
          }
        },
      )
      .with(
        {
          type: "person_manager_id",
        },
        (f) => {
          return {
            managers: {
              user_id: { _in: f.options.list },
            },
          }
        },
      )
      .with(
        {
          type: "person_project_manager_id",
        },
        (f) => {
          return {
            project_memberships: {
              project: {
                managers: {
                  user_id: { _in: f.options.list },
                },
              },
            },
          }
        },
      )
      .with(P.string, (f) => {
        console.warn(
          `WARNING: Filter "${f.type}" is not yet implemented in buildPeopleFilter`,
          f,
        )
        return {}
      })
      .otherwise((f) => {
        console.warn(`Invalid filter ${JSON.stringify(f)}`)
        return {}
      }),
  )

  return { _and: clauses.filter((x) => !isEmpty(x)) }
}
