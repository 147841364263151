/**
 * @generated SignedSource<<5fcc2037d8ff11642351e761a0bf065f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectAddPersonRow_project$data = {
  readonly id: number;
  readonly is_template: boolean;
  readonly members: ReadonlyArray<{
    readonly id: number;
    readonly is_placeholder: boolean;
    readonly just_added_timestamp: number | null | undefined;
    readonly person: {
      readonly active: boolean;
      readonly id: number;
    };
    readonly person_id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useExtProjectMembersList_project">;
  readonly " $fragmentType": "ProjectAddPersonRow_project";
};
export type ProjectAddPersonRow_project$key = {
  readonly " $data"?: ProjectAddPersonRow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectAddPersonRow_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectAddPersonRow_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_placeholder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "just_added_timestamp",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useExtProjectMembersList_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "8abb232b8e07fb5e1ea8d09ba2825939";

export default node;
