/**
 * @generated SignedSource<<5196cc32581dc2333c169d86ef7e0ec5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectManagement_user$data = {
  readonly account: {
    readonly custom_select_types: ReadonlyArray<{
      readonly id: number;
      readonly model: any;
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
    }>;
    readonly id: number;
    readonly name: string;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly calc_end_date: string | null | undefined;
      readonly calc_start_date: string | null | undefined;
      readonly client: {
        readonly active: boolean;
        readonly id: number;
        readonly image_key: string | null | undefined;
        readonly name: string;
      };
      readonly confirmed: boolean;
      readonly created_at: string;
      readonly custom_select_values: ReadonlyArray<{
        readonly optionId: number;
        readonly typeId: number;
      }>;
      readonly has_actuals: boolean | null | undefined;
      readonly id: number;
      readonly is_template: boolean;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly person_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly name: string;
      readonly pricing_model_readable: string | null | undefined;
      readonly priority: string | null | undefined;
      readonly references: any;
      readonly tags_computed: any | null | undefined;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
      readonly team_id: number | null | undefined;
      readonly workstreams_computed: any | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DetectedPeopleSection_project" | "PhasesWrapper_project" | "ProjectAddPersonRow_project" | "ProjectDetailsSection_project" | "ProjectGroupRow_project" | "ProjectRowSummary_project">;
    }>;
    readonly views: ReadonlyArray<{
      readonly id: number;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupList_account" | "ProjectTemplateGroup_account">;
  };
  readonly favourite_projects: any;
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupList_user" | "ProjectTemplateGroup_user">;
  readonly " $fragmentType": "ProjectManagement_user";
};
export type ProjectManagement_user$key = {
  readonly " $data"?: ProjectManagement_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectManagement_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "peopleFilter",
    "variableName": "peopleFilter"
  },
  {
    "kind": "Variable",
    "name": "plannerStartDate",
    "variableName": "plannerStartDate"
  },
  {
    "kind": "Variable",
    "name": "projectsFilter",
    "variableName": "projectsFilter"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "peopleFilter"
    },
    {
      "kind": "RootArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "todaysDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectManagement_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectTemplateGroup_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectGroupList_user"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_projects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "custom_select_types",
          "kind": "LinkedField",
          "name": "custom_select_types",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "model",
              "storageKey": null
            },
            {
              "alias": "options",
              "args": null,
              "concreteType": "custom_select_options",
              "kind": "LinkedField",
              "name": "custom_select_options",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workstreams_computed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created_at",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calc_start_date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calc_end_date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "references",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pricing_model_readable",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "team_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "has_actuals",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "teams",
              "kind": "LinkedField",
              "name": "team",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image_key",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "members",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "person_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workstream_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "custom_select_values",
              "kind": "LinkedField",
              "name": "custom_select_values",
              "plural": true,
              "selections": [
                {
                  "alias": "typeId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_select_type_id",
                  "storageKey": null
                },
                {
                  "alias": "optionId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custom_select_option_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "todaysDate",
                  "variableName": "todaysDate"
                }
              ],
              "kind": "FragmentSpread",
              "name": "ProjectRowSummary_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PhasesWrapper_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectDetailsSection_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectAddPersonRow_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectGroupRow_project"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DetectedPeopleSection_project"
            }
          ],
          "storageKey": null
        },
        {
          "args": (v4/*: any*/),
          "kind": "FragmentSpread",
          "name": "ProjectGroupList_account"
        },
        {
          "args": (v4/*: any*/),
          "kind": "FragmentSpread",
          "name": "ProjectTemplateGroup_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "70478ea7b5b9111b330cdb6947f298a0";

export default node;
