import {
  toFullTimeEquivalentEffort,
  toMinutesPerDayOfEffort,
} from "@runn/calculations"
import React, { useCallback, useEffect, useRef } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./EffortInput.module.css"

import { FTEInput_account$key } from "./__generated__/FTEInput_account.graphql"

import { track } from "~/helpers/analytics"

import { showToast } from "~/containers/ToasterContainer"

export const FTEInput = (props: {
  account: FTEInput_account$key
  minutesPerDay: number
  onChange: (minutes: number, error?: any) => void
  maxMinutes: number
}) => {
  const { minutesPerDay, onChange } = props

  const maxMinutes = props.maxMinutes || 24 * 60 // 24hrs hours

  const account = useFragment(
    graphql`
      fragment FTEInput_account on accounts {
        default_full_time_minutes
      }
    `,
    props.account,
  )

  const value = toFullTimeEquivalentEffort({
    minutesOfEffort: minutesPerDay,
    fulltimeMinutesPerDay: account.default_full_time_minutes,
  })

  const singlePrecisionValue = value

  const propagateChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      ({ currentTarget: { valueAsNumber: effortInFTE } }) => {
        track("FTE Changed", { value: effortInFTE })

        const effortInMinutes = toMinutesPerDayOfEffort({
          fulltimeEquivalentEffort: effortInFTE,
          fulltimeMinutesPerDay: account.default_full_time_minutes,
        }).toNumber()

        if (effortInMinutes > maxMinutes) {
          showToast({
            message:
              "FTE cannot exceed 1 day and have been automatically adjusted",
            type: "warning",
          })
          onChange(maxMinutes, true)
          return
        }
        onChange(effortInMinutes)
      },
      [account.default_full_time_minutes, onChange, maxMinutes],
    )

  const maxFTE = toFullTimeEquivalentEffort({
    minutesOfEffort: maxMinutes,
    fulltimeMinutesPerDay: account.default_full_time_minutes,
  })

  // The local state is needed for allowing the  typing of a decimals e.g `.5`
  const [localValue, setLocalValue] = React.useState<string>(
    singlePrecisionValue.toString(),
  )

  // Due to the hack of syncing the local state with a prop, we need to check
  // if the localValue is greater than the maxFTE and re-update the localValue
  if (parseFloat(localValue) > maxFTE) {
    setLocalValue(singlePrecisionValue.toString())
  }

  useEffect(() => {
    setLocalValue(singlePrecisionValue.toString())
  }, [singlePrecisionValue])

  const inputRef = useRef(null)

  useEffect(() => {
    // only run on first render so that user can still "tab" out
    requestAnimationFrame(() => {
      if (inputRef.current !== null) {
        inputRef.current.select()
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  return (
    <input
      id="effort"
      type="number"
      step={0.1}
      min={0}
      max={maxFTE}
      value={localValue}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={(e) => {
        setLocalValue(e.target.value)

        // we propagate the change only when the value is a number
        // so that the user can write a decimal number e.g `.5`
        if (!Number.isNaN(e.target.valueAsNumber)) {
          propagateChange(e)
        }
      }}
      className={styles.duration}
    />
  )
}
