import { useEffect, useRef } from "react"

export const useEffectSkipFirstRender = (
  func: () => void,
  values: ReadonlyArray<any>,
) => {
  const firstRenderRef = useRef(true)

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
    } else {
      func()
    }
  }, values) // eslint-disable-line react-hooks/exhaustive-deps
}
