import { Tooltip } from "@blueprintjs/core"
import { format, isSameWeek } from "date-fns"
import React from "react"
import { useSelector } from "react-redux"

import styles from "./TimelineWeekendExpanders.module.css"

import { SCROLLBAR_WIDTH } from "~/helpers/scrollbar"

import useCalendarWeekends from "~/hooks/useCalendarWeekends"
import { ReduxState } from "~/rootReducer"

type Props = {
  weeklyDates: Date[]
  calendarWeekendsExpanded: boolean
}

const Expander = (props: { mirrored?: boolean }) => (
  <div
    className={`${styles.expander} ${props.mirrored ? styles.mirrored : ""}`}
  >
    <div className={styles.line} />
    <div className={styles.arrow}>
      <svg
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.59184 4.87031C5.81056 4.67188 5.81056 4.32812 5.59184 4.12969L1.58597 0.495302C1.26466 0.203795 0.75 0.431775 0.75 0.865609L0.75 8.13439C0.75 8.56822 1.26466 8.7962 1.58597 8.5047L5.59184 4.87031Z"
          fill="#AFB2C6"
        />
      </svg>
    </div>
  </div>
)

const TimelineWeekendExpanders = (props: Props) => {
  const { weeklyDates, calendarWeekendsExpanded } = props

  const highlightedCellData = useSelector(
    (state: ReduxState) => state.global.highlightedCellData,
    (prev, next) => {
      // If moving off weekend
      if (prev && prev.type === "weekend") {
        return false
      }
      // If moving onto weekend
      if (next && next.type === "weekend") {
        return false
      }

      return true
    },
  )

  const { toggleWeekends } = useCalendarWeekends()
  const onKeyDown = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      toggleWeekends()
    }
  }

  return (
    <div
      className={styles.timelineWeekendExpanders}
      style={{
        width: `calc(100% - ${SCROLLBAR_WIDTH}px)`,
        left: !calendarWeekendsExpanded ? 9 : 0, // 9px is width of the expanderContainer
      }}
    >
      {weeklyDates.map((date, i) => {
        const isHighlighted =
          highlightedCellData?.type === "weekend" &&
          isSameWeek(date, highlightedCellData?.day, {
            weekStartsOn: 1,
          })
        return (
          <div key={i} className={styles.weekendWrapper}>
            <div
              className={`
                  ${styles.weekend} 
                  ${calendarWeekendsExpanded ? styles.isExpanded : ""}
                  ${isHighlighted ? styles.isHighlighted : ""}`}
              style={{
                width: calendarWeekendsExpanded
                  ? `${Math.round((2 / 7) * 100)}%` // weekend is 2 days out of 7
                  : "",
              }}
              onClick={() => toggleWeekends()}
              onKeyDown={onKeyDown}
              data-test={`weekend-expander-${format(date, "yyyy-MM-dd")}`}
            >
              <Tooltip
                content={
                  calendarWeekendsExpanded
                    ? "Collapse Weekends"
                    : "Expand Weekends"
                }
                placement="bottom"
                className={styles.tooltip}
              >
                <div className={styles.expanderContainer}>
                  <Expander />
                  <Expander mirrored />
                </div>
              </Tooltip>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TimelineWeekendExpanders
