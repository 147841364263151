/**
 * @generated SignedSource<<290306d32adda3535694217c00b13662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddPersonToProject_account$data = {
  readonly id: number;
  readonly projects: ReadonlyArray<{
    readonly client: {
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly name: string;
      readonly website: string | null | undefined;
    };
    readonly emoji: string | null | undefined;
    readonly id: number;
    readonly is_template: boolean;
    readonly members: ReadonlyArray<{
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly name: string;
    readonly project_workstreams: ReadonlyArray<{
      readonly workstream: {
        readonly archived: boolean;
        readonly id: number;
        readonly name: string;
      };
    }>;
    readonly team_id: number | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SelectPersonList_account">;
  readonly " $fragmentType": "AddPersonToProject_account";
};
export type AddPersonToProject_account$key = {
  readonly " $data"?: AddPersonToProject_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddPersonToProject_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddPersonToProject_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "team_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_template",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "clients",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image_key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "project_members",
          "kind": "LinkedField",
          "name": "members",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "person_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "project_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workstream_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_placeholder",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workstreams",
          "kind": "LinkedField",
          "name": "project_workstreams",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "workstreams",
              "kind": "LinkedField",
              "name": "workstream",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SelectPersonList_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "318530bae9ddac9ff397e51765ef9507";

export default node;
