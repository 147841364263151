import { graphql, useFragment } from "react-relay"

import type { useExtProjectMembersList_project$key } from "./__generated__/useExtProjectMembersList_project.graphql"

const fragment = graphql`
  fragment useExtProjectMembersList_project on projects {
    id
    members {
      id
      person_id
    }
    ext_project_links(where: { active: { _eq: true } }) {
      project_id
      ext_project_id
      ext_project {
        id
        ext_project_members(where: { deleted: { _eq: false } }) {
          id
          ext_person {
            id
            first_name
            last_name
            email
            is_placeholder
            active
            ext_person_links(where: { active: { _eq: true } }) {
              ext_person_id
              person_id
            }
          }
        }
      }
    }
  }
`

type UseExtProjectMembersListOptions = {
  project: useExtProjectMembersList_project$key
}

type ExtProjectMember = {
  id: number // extPersonId
  first_name: string
  last_name: string
  email: string
  is_placeholder: boolean
  active: boolean
  project_id: number
  person_id: null
  role_id: null
  workstream_id: null
}

const useExtProjectMembersList = (
  options: UseExtProjectMembersListOptions,
): ExtProjectMember[] => {
  const project = useFragment(fragment, options.project)

  const extProjectMemberList =
    project.ext_project_links?.[0]?.ext_project?.ext_project_members
      ?.filter(
        (extProjectMember) =>
          // Filter out ext_person who are already linked as project members
          !extProjectMember.ext_person.ext_person_links.some((link) =>
            project.members.some(
              (projectMember) => projectMember.person_id === link.person_id,
            ),
          ),
      )
      .map((member) => ({
        id: member.ext_person.id,
        first_name: member.ext_person.first_name,
        last_name: member.ext_person.last_name,
        email: member.ext_person.email ?? "",
        is_placeholder: member.ext_person.is_placeholder,
        active: member.ext_person.active,
        project_id: project.id,
        person_id: null, // This is unlinked person, so no person_id is set
        role_id: null,
        workstream_id: null,
      })) ?? []

  return extProjectMemberList
}

export { useExtProjectMembersList }
