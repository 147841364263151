/**
 * @generated SignedSource<<91bb0d85aa20682c82d383f6a131a815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsDetectedPersonRow_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_user">;
  readonly " $fragmentType": "ProjectDetailsDetectedPersonRow_user";
};
export type ProjectDetailsDetectedPersonRow_user$key = {
  readonly " $data"?: ProjectDetailsDetectedPersonRow_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsDetectedPersonRow_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsDetectedPersonRow_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "d3f2633ec02e4f370cdb950ee158c0e8";

export default node;
