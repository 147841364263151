import { Portal } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { isWeekend } from "date-fns"
import React, { useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectMilestones.module.css"

import { ProjectMilestones_project$key } from "~/ProjectPlanner/ProjectOverview/__generated__/ProjectMilestones_project.graphql"

import {
  getDateOffsetPercentNum,
  getTimeFrames,
} from "~/helpers/CalendarHelper"
import { getMilestonesInRange } from "~/helpers/milestone-helpers"

import { Popover2 } from "~/common/Popover2"

import { setHighlightedCellData } from "~/GlobalState"
import { usePermissions } from "~/Permissions/usePermissions"
import { MilestoneForm, MilestoneIcon } from "~/milestones"
import { ReduxState } from "~/rootReducer"

import CalendarMilestone, { Milestone } from "./CalendarMilestone"
import ProjectItem from "./ProjectItem"

type Props = {
  project: ProjectMilestones_project$key
}

const ProjectMilestones = (props: Props) => {
  const project = useFragment(
    graphql`
      fragment ProjectMilestones_project on projects {
        id
        is_template
        confirmed
        pricing_model
        milestones {
          id
          project_id
          title
          icon
          date: date_runn
          note
        }
      }
    `,
    props.project,
  )
  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )
  const {
    calendarStartDate,
    calendarEndDate,
    calStartNum,
    calEndNum,
    dayWidth,
    periods,
    calendarWeekendsExpanded,
  } = calendar

  const { can, subject } = usePermissions()
  const milestoneSubject = subject("Milestone", {
    project: {
      id: project.id,
      isTemplate: project.is_template,
    },
  })
  const milestonePermissions = {
    edit: can("edit", milestoneSubject),
  }

  const iconSize = periods === "weeks" ? 15 : 20

  const [highlightedDate, setHighlightedDate] = useState<string>(null)
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [showPopover, setShowPopover] = useState(false)

  const [isDragging, setDragging] = useState(false)

  const dispatch = useDispatch()

  const milestoneWrapper = useRef(null)

  const projectMilestones = getMilestonesInRange(project.milestones, [
    calStartNum,
    calEndNum,
  ])

  const milestonesGroupedByDate = projectMilestones.reduce((grouped, item) => {
    const key = item.date
    if (!grouped[key]) {
      grouped[key] = []
    }
    grouped[key].push(item)
    return grouped
  }, {})

  const renderExistingMilestones = () =>
    Object.entries(milestonesGroupedByDate).map(([key, value]) => {
      return (
        <CalendarMilestone
          key={key}
          milestones={value as Milestone[]}
          calendarPeriods={periods}
          projectConfirmed={project.confirmed}
          projectPricingModel={project.pricing_model}
          isTemplate={project.is_template}
          isWeekend={isWeekend(dateHelpers.parseRunnDate(key))}
          milestoneWrapperRef={milestoneWrapper}
          setDragging={setDragging}
        />
      )
    })

  if (!milestonePermissions.edit) {
    return renderExistingMilestones()
  }

  const { dailyDates } = getTimeFrames({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const onMouseLeave = () => {
    setHighlightedDate(null)
    dispatch(setHighlightedCellData(null))
  }

  const onMouseMove = (e) => {
    if (showPopover || isDragging) {
      return
    }

    if (milestoneWrapper.current == null) {
      // we need to know where the milestoneWrapper is before we can handle
      // mouse movements
      return
    }

    const mousePositionOffset =
      e.pageX - milestoneWrapper.current.offsetParent.offsetLeft

    const milestoneWrapperWidth = milestoneWrapper.current.offsetWidth
    const realDayWidth = milestoneWrapperWidth / dailyDates.length

    const day = Math.ceil(mousePositionOffset / realDayWidth) - 1
    const date = dailyDates[day]

    if (date && highlightedDate !== dateHelpers.formatToRunnDate(date)) {
      setHighlightedDate(dateHelpers.formatToRunnDate(date))
      dispatch(setHighlightedCellData({ type: "milestone", day: date }))
    }
  }

  const onAddMilestone = () => {
    if (!selectedDate) {
      setShowPopover(true)
      setSelectedDate(highlightedDate)
    }
  }

  const closePopover = () => {
    setShowPopover(false)
    setSelectedDate(null)
    dispatch(setHighlightedCellData(null))
  }

  const iconClasses = cc({
    [styles.tentativeMilestone]: !project.confirmed,
    [styles.nonBillableMilestone]: project.pricing_model === "nb",
  })

  const renderCreateMilestoneForm = () => {
    const selectedOffset = getDateOffsetPercentNum(
      calendarStartDate,
      calendarEndDate,
      selectedDate,
      calendarWeekendsExpanded,
    )
    if (selectedDate) {
      const milestone = {
        id: undefined,
        date: selectedDate,
        title: "",
        icon: "dollar",
        note: "",
        project_id: project.id,
      }

      return (
        <ProjectItem width={dayWidth} offset={selectedOffset}>
          <Popover2
            isOpen={showPopover}
            content={
              <MilestoneForm
                type="Add"
                milestone={milestone}
                onClose={closePopover}
                hideCalendar
                projectId={project.id}
              />
            }
            placement="bottom"
            onClose={closePopover}
            hasBackdrop
            className={styles.iconPopover}
          >
            <MilestoneIcon
              iconOrEmoji="plus"
              size={iconSize}
              className={iconClasses}
            />
          </Popover2>
        </ProjectItem>
      )
    }
  }

  const highlightOffset = getDateOffsetPercentNum(
    calendarStartDate,
    calendarEndDate,
    highlightedDate,
    calendarWeekendsExpanded,
  )

  return (
    <>
      {isDragging && (
        <Portal>
          <div className={styles.overlay} />
        </Portal>
      )}
      <div
        ref={milestoneWrapper}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        className={styles.projectMilestonesWrapper}
      >
        {highlightedDate && !isDragging && (
          <ProjectItem
            width={dayWidth}
            offset={highlightOffset}
            onClick={onAddMilestone}
          >
            <div className={styles.iconWrapper}>
              <MilestoneIcon
                iconOrEmoji="plus"
                size={iconSize}
                className={iconClasses}
              />
            </div>
          </ProjectItem>
        )}
      </div>
      {renderCreateMilestoneForm()}
      {renderExistingMilestones()}
    </>
  )
}

export default ProjectMilestones
