import { Icon, Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./ProjectBudget.module.css"

import { EditProjectBudgetQuery$data } from "./__generated__/EditProjectBudgetQuery.graphql"

import { OTHER_EXPENSES_ROLE, extractRoles } from "~/helpers/otherCosts"
import { getDefaultRate } from "~/helpers/rate-helpers"

import { RateTypeSelection } from "~/GLOBALVARS"

import OtherExpensesBudgetItem from "./OtherExpensesBudgetItem"
import RoleRateAndBudgetItem from "./RoleRateAndBudgetItem"
import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  projectId: number
  rateType: RateTypeSelection
  rateCard: RateCard
  showNonBillableView?: boolean
  roles: ReadonlyArray<BudgetedRole>
  projectRates: ReadonlyArray<BudgetedRate>
  hideFinancials?: boolean
  defaultFullTimeMinutes: number
  updateDelay?: number
  onUpdateRole: (role: BudgetedRole) => void
  onUpdateRates: (rate: BudgetedRate[]) => void
  onDeleteRole: (roleId: number) => void
  expensesAllocationDifference: number
  expensesBudget: number
}

type RateCard = Readonly<{
  name: string | null
  blended_rate: number | null
  blended_rate_card: boolean
  role_charge_out_rates: ReadonlyArray<{
    role_id: number | null
    charge_out_rate: number | null
  }>
}>

export type BudgetedRate = {
  id: number
  project_id: number
  role_id: number
  rate: number | null
}

export type BudgetedRole = {
  id: number
  estimated_minutes: number
  name: string
  active: boolean
  project_role_id: number
  isScheduled?: boolean
  implicit?: boolean
}

export type ProjectRate =
  EditProjectBudgetQuery$data["projects_by_pk"]["project_rates"][0]

const BudgetEditor = (props: Props) => {
  const {
    projectId,
    rateCard,
    rateType,
    hideFinancials = true,
    showNonBillableView = true,
    roles,
    projectRates,
    defaultFullTimeMinutes,
    onUpdateRole,
    onUpdateRates,
    onDeleteRole,
    expensesAllocationDifference,
    expensesBudget,
  } = props

  const showDayView = rateType.value === "days"

  const handleUpdateExpenses = (val: number) => {
    const { expenses } = extractRoles(roles)
    if (expenses) {
      const updatedExpenses = {
        ...expenses,
        estimated_minutes: val,
      }
      onUpdateRole(updatedExpenses)
    }
  }

  const handleRemoveExpenses = () => {
    onDeleteRole(OTHER_EXPENSES_ROLE.id)
  }

  const roleData = extractRoles(roles)
  const expenseRole = roleData.expenses

  // Fetch the budgeted rate and rate card rate for a given role
  const ratesFromRole = (role: BudgetedRole) => {
    const projectRate = projectRates.find((rate) => rate.role_id === role.id)
    const rateCardRate = getDefaultRate(rateCard, role.id)

    return {
      projectRateObject: projectRate,
      projectRate: projectRate?.rate,
      rateCardRate,
    }
  }

  // Transfer all project rate card rates to project rates
  const transferAllRateCardRates = () => {
    const updatedRates = []
    roleData.people.forEach((role) => {
      const { projectRateObject, rateCardRate } = ratesFromRole(role)
      updatedRates.push({
        ...projectRateObject,
        rate: rateCardRate,
      })
    })
    onUpdateRates(updatedRates)
  }

  const transferProjectRatesEnabled = roleData.people.some((role) => {
    const { projectRate, rateCardRate } = ratesFromRole(role)
    return rateCardRate !== projectRate
  })

  return (
    <div className={styles.budgetEditorWrapper}>
      <Row heading>
        <Column>Role</Column>
        {!hideFinancials && !showNonBillableView && (
          <Column>{`${rateCard.name} Rate`}</Column>
        )}
        {!hideFinancials && !showNonBillableView && (
          <Column>
            {transferProjectRatesEnabled && (
              <Tooltip content={"Copy all rates to project"} placement="top">
                <Icon
                  icon="arrow-right"
                  className={styles.transferRateArrow}
                  data-test="transfer-all-rates-icon"
                  onClick={transferAllRateCardRates}
                />
              </Tooltip>
            )}
          </Column>
        )}
        {!hideFinancials && !showNonBillableView && (
          <Column>Project Rate</Column>
        )}
        <Column>{rateType.label}</Column>
        {!hideFinancials && !showNonBillableView && <Column>Budget</Column>}
      </Row>
      <div className={styles.tableBody}>
        {roleData.people.map((role) => {
          const project_rate = projectRates.find(
            (rate) => rate.role_id === role.id,
          )
          const rateCardRate = getDefaultRate(rateCard, role.id)
          const canDelete = !role.isScheduled
          return (
            <RoleRateAndBudgetItem
              key={role.id}
              projectId={projectId}
              budgetedRole={role}
              removeRole={onDeleteRole}
              onRoleChange={onUpdateRole}
              onRatesUpdate={onUpdateRates}
              project_rate={project_rate}
              rate_card_rate={rateCardRate}
              hideFinancials={hideFinancials || showNonBillableView}
              showDayView={showDayView}
              default_full_time_minutes={defaultFullTimeMinutes}
              canDelete={canDelete}
              deleteTooltip={
                canDelete
                  ? `Remove budget allocation for "${role.name}"`
                  : `Roles that are currently scheduled cannot be removed from the budget allocation.`
              }
            />
          )
        })}
        {!hideFinancials && expenseRole && (
          <OtherExpensesBudgetItem
            key={expenseRole.id}
            value={expenseRole.estimated_minutes}
            showNonBillableView={showNonBillableView}
            canDelete={!expenseRole.isScheduled}
            onUpdate={handleUpdateExpenses}
            onDelete={handleRemoveExpenses}
            deleteTooltip={
              !expenseRole.isScheduled
                ? "Remove budget allocation for other expenses"
                : "You cannot remove this from your budget because you already have other expenses recorded for this project."
            }
            expensesAllocationDifference={expensesAllocationDifference}
            expensesBudget={expensesBudget}
          />
        )}
      </div>
    </div>
  )
}

export default BudgetEditor
