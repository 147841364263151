import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import { Feature, useFeature } from "flagged"
import { keyBy } from "lodash-es"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { match } from "ts-pattern"
import isURL from "validator/lib/isURL"

import styles from "./ProjectForm.module.css"

import { CreateProjectFromTemplateForm_project$data } from "../CreateProject/__generated__/CreateProjectFromTemplateForm_project.graphql"
import { CreateTemplateFromProjectForm_project$data } from "../ProjectTemplateForm/__generated__/CreateTemplateFromProjectForm_project.graphql"
import { ProjectFormQuery } from "./__generated__/ProjectFormQuery.graphql"
import { ProjectForm_viewer$key } from "./__generated__/ProjectForm_viewer.graphql"
import { EditProjectForm_project$data } from "~/forms/EditProjectForm/__generated__/EditProjectForm_project.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "../../helpers/analytics"
import {
  FormFilterTypeValuesProject,
  getNonMatchingFilters,
  getViewsProjectGroupedOptions,
} from "~/Views/helpers"
import { CustomTypeName, CustomValuesMap } from "~/helpers/custom-field-helpers"
import { reportError } from "~/helpers/error-helpers"
import { AllowedProjectFilter } from "~/helpers/filter-engine"
import { formatName } from "~/helpers/person"
import { isEmoji } from "~/helpers/text-helpers"

import CustomFieldSectionHeader from "../common/CustomFieldSectionHeader"
import EmojiPicker from "~/common/Emoji/EmojiPicker"
import Input from "~/common/Input"
import LinkTable from "~/common/LinkTable"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import ManagerSelector from "~/common/ModalForm/ManagerSelector"
import ProjectClientIcon from "~/common/ProjectClientIcon"
import ReferencesTable from "~/common/ReferencesTable"
import Select from "~/common/Select"
import { toOption } from "~/common/SelectorModal/SelectorHelpers"
import Button from "~/common/buttons/Button"
import Checkbox from "~/common/inputs/Checkbox"

import ProjectCustomEditor from "~/CustomEditor/ProjectCustomEditor/ProjectCustomEditor"
import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementSwitch,
  useIsInFreePlan,
} from "~/Entitlements/useEntitlements"
import { PRICING_MODELS, STATUS_OPTIONS } from "~/GLOBALVARS"
import ProjectWorkstreamSelector from "~/Planner/ProjectWorkstreamSelector"
import TagSelector from "~/Planner/TagSelector"
import TeamSelector from "~/Planner/TeamSelector"
import NonBillableWarning from "~/ProjectDashboard/Header/ProjectBudget/NonBillableWarning"
import ViewFieldsTooltip from "~/forms/ProjectForm/ViewFieldsTooltip"

import TemplateSelector from "../CreateProject/TemplateSelector"

import {
  ExtProjectLinkEditor,
  useExtProjectLinkEditor,
} from "./ExtProjectLinks"
import SelectClient from "./SelectClient"
import SelectPricingModel from "./SelectPricingModel"
import SelectProjectStartDate from "./SelectProjectStartDate"
import TemplateNameUniqueInput from "./TemplateNameUniqueInput"

export type ProjectInput = {
  name: string
  rate_card_id: number
  client_id: number
  confirmed: boolean
  client_name: string
  pricing_model: string
  team_id: number
  tags: any
  references: any
  is_template: boolean
  manager_ids: number[]
  custom_values: CustomValuesMap
  emoji: string
  workstreams: string[]
  links: ReadonlyArray<{
    readonly href: string
    readonly name: string
    readonly show_in_planner: boolean
  }>
}

export type DuplicateInput = {
  confirmed: boolean
  name: string
  rate_card_id: number
  pricing_model: string
  startDate: string
  templateId?: number
  is_template: boolean
}

export type OnSubmitResponse = {
  input: ProjectInput
  duplicateInput?: DuplicateInput
}

type ProjectLinks = EditProjectForm_project$data["links"]

type Link = Readonly<
  Omit<ProjectLinks[number], "id"> & {
    id?: ProjectLinks[number]["id"]
  }
>

type Props = {
  onClose: (event: {
    projectId: number | undefined
    extProjectId: number | undefined
  }) => void
  activateAnimation?: () => void
  setIsDatePickerForm?: (arg: boolean) => void
  onSubmit?: (inputs: OnSubmitResponse) => Promise<{ projectId: number }>
  project?: EditProjectForm_project$data // Only needs to be passed if editing existing
  templateBlueprint?:
    | CreateTemplateFromProjectForm_project$data
    | CreateProjectFromTemplateForm_project$data // Passed if creating a project from a template or a template from a project
  isTemplate?: boolean
}

const query = graphql`
  query ProjectFormQuery {
    current_account {
      id
      user_accounts {
        id
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`
const fragment = graphql`
  fragment ProjectForm_viewer on users {
    id
    first_name
    last_name
    permissions
    view {
      id
      name
      project_filters
    }
    account {
      id
      billing_method
      project_tags: tags(where: { model: { _eq: "project" } }) {
        id
        name
        archived
      }
      projectTemplates: projects(where: { is_template: { _eq: true } }) {
        id
        ...TemplateSelector_project
        ...TemplateNameUniqueInput_project
      }
      rate_cards {
        id
        name
        active
      }
      clients {
        id
        active
        name
        image_key
        website
        internal
        real_client
      }
      ext_projects {
        id
        name
      }
      teams {
        id
        name
      }
      views {
        id
        name
        project_filters
      }
      workstreams {
        id
        name
        archived
      }
      ...TeamSelector_account
      ...CustomFieldSectionHeaderProject_account
    }
  }
`

const ProjectForm = (props: Props) => {
  const {
    project,
    templateBlueprint,
    onClose,
    onSubmit,
    setIsDatePickerForm,
    activateAnimation,
    isTemplate = false,
  } = props

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<ProjectForm_viewer$key>(fragment, viewerQuery)
  const account = viewer.account
  const permissions = viewer.permissions
  const isFeatureViewEnabled = useFeature("pre_filtered_views")

  const { current_account } = useLazyLoadQuery<ProjectFormQuery>(query, {})
  const { view } = viewer
  const {
    billing_method,
    projectTemplates,
    clients,
    rate_cards,
    teams,
    project_tags,
    workstreams,
  } = account

  const projectBlueprint = project || templateBlueprint
  const defaultView = isFeatureViewEnabled && view ? view : undefined

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [projectStartDate, setProjectStartDate] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [projectName, setProjectName] = useState(project?.name || "")
  const [useTemplate, setUseTemplate] = useState(Boolean(templateBlueprint))
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [customValues, setCustomValues] = useState<CustomValuesMap>({
    [CustomTypeName.TEXT]: projectBlueprint?.custom_text_values || [],
    [CustomTypeName.DATE]: projectBlueprint?.custom_date_values || [],
    [CustomTypeName.SELECT]: projectBlueprint?.custom_select_values || [],
    [CustomTypeName.CHECKBOX]: projectBlueprint?.custom_checkbox_values || [],
  })
  const [selectedEmoji, setSelectedEmoji] = useState(
    isEmoji(project?.emoji) ? project?.emoji : "",
  )
  const [links, setLinks] = useState<ReadonlyArray<Link>>(project?.links || [])
  const [importExternalProject, setImportExternalProject] = useState(false)

  const extProjectLinkEditorState = useExtProjectLinkEditor({
    projectRef: project,
  })

  const defaultProjectManagers = match({
    newProject: project === undefined,
    manageProjectsPermission: permissions.manage_projects,
  })
    .with({ newProject: false }, () => {
      const userMap = keyBy(
        current_account.user_accounts.map((ua) => ua.user),
        "id",
      )
      return project.managers.map((e) => {
        const user = userMap[e.user_id]
        return {
          value: user.id,
          label: formatName(user.first_name, user.last_name),
        }
      })
    })
    // Accounts with "restricted" permission automatically get added as default editor
    .with({ newProject: true, manageProjectsPermission: "restricted" }, () => [
      {
        value: viewer.id,
        label: formatName(viewer.first_name, viewer.last_name),
      },
    ])
    .otherwise(() => [])

  const [managers, setManagers] = useState(defaultProjectManagers)

  const clientOptions = clients
    // Only filter active clients when creating a new project.
    // Otherwise allow pre-selection of archived clients.
    .filter((c) =>
      project?.id ? c.active || c.id === project.client_id : c.active,
    )
    .map((ac) => ({
      ...ac,
      value: ac.id,
      label: ac.name,
      __isNew__: false,
    }))
  const [selectedClient, setSelectedClient] = useState(
    clientOptions.find((c) => c.value === projectBlueprint?.client_id),
  )

  const rateCardOptions = rate_cards
    // Only filter active rate cards when creating a new project.
    // Otherwise allow pre-selection of archived rate cards.
    .filter((rc) =>
      projectBlueprint?.id
        ? rc.active || rc.id === projectBlueprint.rate_card_id
        : rc.active,
    )
    .map((arc) => ({ value: arc.id, label: arc.name }))

  const [selectedRateCard, setSelectedRateCard] = useState(
    rateCardOptions.find((o) => {
      if (projectBlueprint) {
        return o.value === projectBlueprint.rate_card_id
      }
      return o.label === "Standard"
    }),
  )

  const [selectedStatus, setSelectedStatus] = useState(
    STATUS_OPTIONS.find((o) => {
      const defaultStatus = project ? project.confirmed : true
      return o.value === defaultStatus
    }),
  )

  const [selectedPricingModel, setSelectedPricingModel] = useState(
    PRICING_MODELS.find(
      (pm) => pm.value === (projectBlueprint?.pricing_model ?? billing_method),
    ),
  )

  const tagOptions = project_tags.filter((tag) => !tag.archived).map(toOption)

  const [selectedTags, setSelectedTags] = useState(
    projectBlueprint?.tags_computed?.map((value) => ({
      value,
      label: value,
    })) || [],
  )

  const teamOptions = teams.map(toOption)

  const [selectedTeam, setSelectedTeam] = useState(
    teamOptions.find((t) => t.value === projectBlueprint?.team_id) || undefined,
  )

  const workstreamsList = workstreams.map((w) => ({
    label: w.name,
    value: w.id,
    archived: w.archived,
    isFixed: project?.members?.some(
      (m) => m.project_id === project?.id && m.workstream_id === w.id,
    ),
  }))

  const [selectedWorkstreams, setSelectedWorkstreams] = useState(
    projectBlueprint?.workstreams_computed?.map((value) => ({
      value,
      label: value,
    })) || [],
  )

  const isNonBillable = selectedPricingModel.value === "nb"

  useEffect(() => {
    if (isNonBillable) {
      setSelectedRateCard(rateCardOptions.find((o) => o.label === "Internal"))
    }
  }, [selectedPricingModel]) // eslint-disable-line react-hooks/exhaustive-deps

  const showRateCardSelector = !isNonBillable

  const [references, setReferences] = useState(project?.references || {})

  const needToSetDate = setIsDatePickerForm && useTemplate && !showDatePicker

  const isInFreePlan = useIsInFreePlan()
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitledToWorkstreams = useEntitlementSwitch(
    ChargebeeFeatures.workstreams,
  )
  const entitledToTemplates = useEntitlementSwitch(
    ChargebeeFeatures.projectTemplates,
  )
  const canUseTemplate =
    Boolean(projectTemplates.length) &&
    !isTemplate &&
    !project &&
    entitledToTemplates

  const handleNext = () => {
    setShowDatePicker(true)

    if (setIsDatePickerForm) {
      setIsDatePickerForm(true)
    }
  }

  const handleSubmit = async () => {
    setIsSaving(true)
    activateAnimation && activateAnimation()

    const isNewClient =
      clientOptions.filter((x) => selectedClient?.value === x.value).length ===
      0
    const clientId = isNewClient ? null : selectedClient.value

    const projectInput = {
      name: projectName,
      rate_card_id: selectedRateCard.value,
      client_id: clientId,
      confirmed: selectedStatus.value,
      client_name: selectedClient?.label,
      pricing_model: selectedPricingModel.value,
      manager_ids: managers.map((m) => m.value),
      team_id: selectedTeam?.value,
      tags: selectedTags.map((t) => t.label.trim()),
      references: references,
      is_template: isTemplate,
      custom_values: customValues,
      emoji: selectedEmoji,
      workstreams: selectedWorkstreams.map((ws) => ws.label.trim()),
      links,
    }

    if (project) {
      await onSubmit({ input: projectInput })
      // you can only link an existing project to an external project
      extProjectLinkEditorState.save({ projectId: project.id })
      return onClose({
        projectId: project.id,
        extProjectId: undefined,
      })
    }

    let duplicateInput
    if (projectStartDate) {
      duplicateInput = {
        startDate: projectStartDate,
      }
    }
    if (selectedTemplate) {
      duplicateInput = {
        ...duplicateInput,
        templateId: selectedTemplate.value,
      }
      // Templates don't have links, remove this from the input object
      delete projectInput.links
    } else if (projectBlueprint) {
      duplicateInput = {
        ...duplicateInput,
        original_project_id: projectBlueprint.id,
      }
    }

    track("Project Created", {
      confirmed: projectInput.confirmed,
      pricing_model: projectInput.pricing_model,
      tags: projectInput.tags,
      emoji: projectInput.emoji,
      workstreams: projectInput.workstreams,
    })

    window.userflow?.track("Project Create")

    let projectId: number | undefined
    let extProjectId: number | undefined
    try {
      if (duplicateInput) {
        const result = await onSubmit({
          input: projectInput,
          duplicateInput: duplicateInput,
        })

        projectId = result.projectId
        const linkedExtProjectIds = extProjectLinkEditorState.save({
          projectId: projectId,
        })
        extProjectId = linkedExtProjectIds?.[0]
      } else {
        const result = await onSubmit({ input: projectInput })

        projectId = result.projectId
        const linkedExtProjectIds = extProjectLinkEditorState.save({
          projectId: projectId,
        })
        extProjectId = linkedExtProjectIds?.[0]
      }
    } catch (error: any) {
      void reportError(
        `Error ${duplicateInput ? "duplicating" : "creating"} project`,
        error,
      )
    }

    setIsSaving(false)

    onClose({ projectId, extProjectId })
  }

  const handleSelectClient = (e) => {
    if (e && !(e?.real_client || e?.__isNew__)) {
      return
    }

    if (e?.internal) {
      setSelectedPricingModel(PRICING_MODELS.find((pm) => pm.value === "nb"))
    }

    setSelectedClient(e)
  }

  const toggleUseTemplate = () => {
    const newState = !useTemplate
    setUseTemplate(newState)

    if (newState === false) {
      setSelectedTemplate(null)
    }

    if (importExternalProject) {
      setImportExternalProject(false)
    }
  }

  useEffect(() => {
    if (!selectedTemplate) {
      return
    }

    setSelectedClient(
      clientOptions.find((c) =>
        !c.real_client ? null : c.value === selectedTemplate.client_id,
      ),
    )
    setSelectedRateCard(
      rateCardOptions.find((o) => o.value === selectedTemplate.rate_card_id),
    )
    setSelectedPricingModel(
      PRICING_MODELS.find(
        (pm) => selectedTemplate.pricing_model === pm.value,
      ) || PRICING_MODELS[0],
    )
    setSelectedTags(
      selectedTemplate.tags_computed?.map((value) => ({ value, label: value })),
    )

    setSelectedWorkstreams(
      selectedTemplate.workstreams_computed?.map((value) => ({
        value,
        label: value,
      })),
    )

    setSelectedTeam(
      teamOptions.find((t) => t.value === selectedTemplate.team_id),
    )

    setCustomValues({
      [CustomTypeName.TEXT]: selectedTemplate.custom_text_values || [],
      [CustomTypeName.DATE]: selectedTemplate.custom_date_values || [],
      [CustomTypeName.SELECT]: selectedTemplate.custom_select_values || [],
      [CustomTypeName.CHECKBOX]: selectedTemplate.custom_checkbox_values || [],
    })
  }, [selectedTemplate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    setShowDatePicker(false)
    onClose({ projectId: undefined, extProjectId: undefined })
  }

  const updatingToNonBillable =
    project &&
    project.pricing_model !== "nb" &&
    selectedPricingModel.value === "nb"

  const defaultImage = () => {
    return <ProjectClientIcon client={selectedClient} clientLogoOnly />
  }

  const handleViewGrouping = (
    options,
    filterType: AllowedProjectFilter["type"],
  ) => {
    return getViewsProjectGroupedOptions(defaultView, options, filterType)
  }

  const formFilterTypeValues: FormFilterTypeValuesProject = [
    { type: "project_tag_id", values: selectedTags.map((t) => t.label) },
    { type: "project_status", values: [selectedStatus?.label] },
    { type: "project_custom_select", values: customValues["select"] },
    { type: "project_client_id", values: [selectedClient?.id] },
    { type: "project_team_id", values: [selectedTeam?.value] },
  ]

  const nonMatchingFilters =
    defaultView &&
    getNonMatchingFilters(defaultView.project_filters, formFilterTypeValues)

  const allLinksValid = links
    .filter((link) => link.name.trim().length || link.href.trim().length)
    .every((link) => {
      return (
        link.name.trim().length &&
        isURL(link.href.trim(), {
          require_protocol: true,
        })
      )
    })

  const disabledTooltipReason = (() => {
    const missingValues = []

    if (!projectName.trim()) {
      missingValues.push("Name")
    }
    if (
      !isTemplate &&
      (!selectedClient || selectedClient?.label === "No Client")
    ) {
      missingValues.push("Client")
    }

    if (!selectedRateCard) {
      missingValues.push("Rate Card")
    }

    if (missingValues.length > 0) {
      return `${missingValues.join(", ")} is required`
    }

    if (!allLinksValid) {
      return "At least one link has no name or an incorrect URL"
    }

    return null
  })()

  const hasExtProjectLinks =
    extProjectLinkEditorState.value.size > 0 &&
    Array.from(extProjectLinkEditorState.value.values()).every(
      (v: ReadonlyArray<number>) => v.length > 0,
    )
  const selectedExtProjectNameRef = useRef<string | undefined>(undefined)

  return (
    <>
      <ModalBody wide={!showDatePicker}>
        {showDatePicker ? (
          <SelectProjectStartDate onChange={setProjectStartDate} />
        ) : (
          <>
            {!templateBlueprint && (
              <>
                {canUseTemplate && (
                  <Checkbox
                    id="use-template-checkbox"
                    label="Use template"
                    onChange={toggleUseTemplate}
                    checked={useTemplate}
                    containerClassName={styles.checkboxContainer}
                    labelClassName={styles.checkboxLabel}
                    tickClassName={styles.checkboxTick}
                  />
                )}
                <div
                  className={cc([
                    styles.closed,
                    {
                      [styles.open]: useTemplate,
                    },
                  ])}
                >
                  {useTemplate && (
                    <TemplateSelector
                      onChange={setSelectedTemplate}
                      projectTemplatesQuery={projectTemplates}
                    />
                  )}
                </div>
              </>
            )}
            <div className={styles.oneColumnWrapper}>
              {isTemplate ? (
                <TemplateNameUniqueInput
                  savedName={project?.name}
                  onChange={setProjectName}
                  projectTemplatesQuery={projectTemplates}
                />
              ) : (
                <div className={styles.projectName}>
                  <Input
                    name="project-name"
                    id="project-name"
                    label="Project Name"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    autoFocus
                  />
                  <EmojiPicker
                    setSelectedEmoji={setSelectedEmoji}
                    selectedEmoji={selectedEmoji}
                    DefaultImage={defaultImage}
                  />
                </div>
              )}
            </div>
            {!isTemplate && (
              <Suspense fallback="">
                <ExtProjectLinkEditor
                  state={extProjectLinkEditorState}
                  onChange={(extProject) => {
                    const isSafeToSetProjectName =
                      projectName.trim().length === 0 ||
                      selectedExtProjectNameRef.current
                    if (isSafeToSetProjectName && extProject?.name) {
                      setProjectName(extProject.name)
                    }
                    selectedExtProjectNameRef.current = extProject?.name
                  }}
                />
              </Suspense>
            )}
            <div
              className={
                isTemplate ? styles.oneColumnWrapper : styles.twoColumnWrapper
              }
            >
              <SelectClient
                label={`Client ${isTemplate ? "(optional)" : ""}`}
                value={selectedClient}
                onChange={handleSelectClient}
                options={handleViewGrouping(clientOptions, "project_client_id")}
                className={styles.select}
              />
              {!isTemplate && (
                <Select
                  name="project-status"
                  id="project-status"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  label="Status"
                  options={handleViewGrouping(STATUS_OPTIONS, "project_status")}
                  placeholder="Select status"
                  className={styles.select}
                />
              )}
            </div>
            <div className={styles.twoColumnWrapper}>
              <Tooltip
                content="Projects for your own business cannot be made billable"
                disabled={!selectedClient?.internal}
                popoverClassName={styles.nonbillableTooltip}
                placement="bottom"
              >
                <SelectPricingModel
                  label="Pricing Model"
                  value={selectedPricingModel}
                  onChange={setSelectedPricingModel}
                  className={styles.select}
                  isDisabled={selectedClient?.internal}
                />
              </Tooltip>
              {showRateCardSelector && (
                <Select
                  name="project-rate-card"
                  id="project-rate-card"
                  value={selectedRateCard}
                  label="Reference Rate Card"
                  onChange={setSelectedRateCard}
                  options={rateCardOptions}
                  placeholder="Select rate card"
                  className={styles.select}
                />
              )}
            </div>
            {updatingToNonBillable && <NonBillableWarning />}
            {Boolean(teams?.length) && (
              <div className={styles.twoColumnWrapper}>
                <TeamSelector
                  label="Primary Team (optional)"
                  id="project-team"
                  account={account}
                  defaultTeam={selectedTeam}
                  placeholder="Select team"
                  onChange={(e) => setSelectedTeam(e)}
                  formatSelectOptions={handleViewGrouping}
                  type="project"
                  isClearable
                />
              </div>
            )}
            <div className={styles.oneColumnWrapper}>
              <TagSelector
                options={handleViewGrouping(tagOptions, "project_tag_id")}
                label="Project Tags (optional)"
                onChange={setSelectedTags}
                existingTags={
                  selectedTemplate?.tags_computed ||
                  projectBlueprint?.tags_computed
                }
              />
            </div>
            <Feature name="workstreams">
              {(entitledToWorkstreams || isInFreePlan) && (
                <div className={styles.oneColumnWrapper}>
                  <ProjectWorkstreamSelector
                    allWorkstreams={workstreamsList}
                    onChange={setSelectedWorkstreams}
                    existingWorkstreams={
                      selectedTemplate?.workstreams_computed ||
                      projectBlueprint?.workstreams_computed
                    }
                    selectProps={{
                      isDisabled: entitlementsEnabled && isInFreePlan,
                    }}
                  />
                </div>
              )}
            </Feature>

            {!isTemplate && (
              <Suspense
                fallback={
                  // This is a dummy Selector to prevent the form from jumping when the real one is loaded
                  <Select
                    id="project-managers"
                    value={null}
                    isMulti
                    onChange={() => null}
                    label="Project Managers (optional)"
                    options={[]}
                    placeholder="Select or search for user"
                  />
                }
              >
                <div className={styles.oneColumnWrapper}>
                  <ManagerSelector
                    selectedManagers={managers}
                    setSelectedManagers={setManagers}
                    type="projects"
                  />
                </div>
              </Suspense>
            )}
            <CustomFieldSectionHeader type="project" account={account} />
            <ProjectCustomEditor
              project={selectedTemplate || projectBlueprint}
              onUpdate={setCustomValues}
              itemClassName={styles.oneColumnWrapper}
            />
            {!isTemplate && (
              <LinkTable
                links={links}
                setUpdatedLinks={(updatedLinks) => setLinks(updatedLinks)}
                parent="project"
              />
            )}
            {!isTemplate && (
              <ReferencesTable
                dataType="Project"
                references={references}
                setUpdatedReferences={setReferences}
              />
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {Boolean(defaultView) && !showDatePicker && projectBlueprint && (
          <ViewFieldsTooltip
            type={isTemplate ? "project template" : "project"}
            viewName={defaultView.name}
            nonMatchingFilters={nonMatchingFilters}
          />
        )}
        <Button text="Cancel" onClick={handleCancel} />
        <Tooltip
          disabled={disabledTooltipReason === null}
          content={disabledTooltipReason}
          position="top"
        >
          <Button
            text={
              project
                ? "Save"
                : needToSetDate || hasExtProjectLinks
                  ? "Next"
                  : "Create"
            }
            loading={isSaving}
            id="create-project-button"
            outlined={false}
            onClick={needToSetDate ? handleNext : handleSubmit}
            disabled={
              isSaving ||
              !projectName.trim() ||
              (!isTemplate &&
                (!selectedClient || selectedClient?.label === "No Client")) ||
              !selectedRateCard ||
              !allLinksValid
            }
            style={{ marginLeft: 7 }}
          />
        </Tooltip>
      </ModalFooter>
    </>
  )
}

export default ProjectForm
