import * as fe from "@runn/filter-engine"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { ViewFilterValues_user$key } from "./__generated__/ViewFilterValues_user.graphql"

import {
  AllowedPeopleFilterSet,
  AllowedProjectFilterSet,
} from "~/helpers/filter-engine"

import { FilterSelections } from "~/common/SuperSearch/Filters"

type BaseProps = {
  user: ViewFilterValues_user$key
}

type FilterSetProps = BaseProps & {
  filterSet?: AllowedProjectFilterSet | AllowedPeopleFilterSet
  nonMatchingFilters?: never
}
type NonMatchingFilterProps = BaseProps & {
  nonMatchingFilters?: fe.filters.AnyFilter[]
  filterSet?: never
}

type Props = FilterSetProps | NonMatchingFilterProps

const fragment = graphql`
  fragment ViewFilterValues_user on users
  @argumentDefinitions(
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    id
    account {
      id
      ...useClients_account
      ...usePeople_account @arguments(peopleFilter: $peopleFilter)
      ...useProjects_account @arguments(projectsFilter: $projectsFilter)
      ...useProjectTemplates_account @arguments(projectsFilter: $projectsFilter)
      ...useRoles_account
      ...useSkills_account
      ...useTags_account
      ...useTeams_account
      ...useCustomSelect_account
      ...useManagers_account
    }
  }
`

const ViewFilterValues = (props: Props) => {
  const { filterSet, nonMatchingFilters } = props

  const user = useFragment<ViewFilterValues_user$key>(fragment, props.user)

  if (filterSet) {
    return (
      <div>
        {fe.filters.flatMap(filterSet.filters, (filter, path) => {
          const key = path.join(".")
          const FilterReadonlyComponent =
            FilterSelections[filter.type]?.ReadonlyComponent
          if (!FilterReadonlyComponent) {
            void reportError(
              `ViewFilterValues: Could not render values for filter with type "${filter.type}", as there is no ReadonlyComponent defined`,
            )
            return null
          }

          return (
            <FilterReadonlyComponent key={key} user={user} filter={filter} />
          )
        })}
      </div>
    )
  }

  if (nonMatchingFilters) {
    return (
      <div>
        {nonMatchingFilters.map((filter) => {
          const key = filter.type
          const FilterReadonlyComponent =
            FilterSelections[filter.type]?.ReadonlyComponent
          if (!FilterReadonlyComponent) {
            void reportError(
              `ViewFilterValues: Could not render values for filter with type "${filter.type}", as there is no ReadonlyComponent defined`,
            )
            return null
          }

          return (
            <FilterReadonlyComponent key={key} user={user} filter={filter} />
          )
        })}
      </div>
    )
  }
}

export default ViewFilterValues
