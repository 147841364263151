import { Icon, Tooltip } from "@blueprintjs/core"
import classcat from "classcat"
import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./PeopleSortControl.module.css"

import { track } from "~/helpers/analytics"
import {
  PeopleSortOption,
  PlaceholderSortOption,
  peopleSortOptions,
  placeholderSortOptions,
} from "~/helpers/sorting-helpers"

import Dropdown, { DropdownTargetProps } from "~/common/Dropdown/Dropdown"
import DropdownHeading from "~/common/Dropdown/DropdownHeading"
import MenuItem from "~/common/MenuItem"
import * as icons from "~/common/react-icons"

import {
  selectSortByOrderChanged,
  selectSortPeopleBy,
  selectSortPlaceholdersBy,
  sortByOrderChanged,
  sortPeopleChanged,
  sortPlaceholdersChanged,
} from "~/Planner/reducer2/peopleSortSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import IconButton from "./IconButton"
import OverflowTooltipContent from "./OverflowTooltipContent"

const PeopleSortControl = (props: { disabled?: boolean }) => {
  const dispatch = useDispatch()

  const priorityFeature = useFeature("priority")

  const sortPeopleBy = useAppSelector((state) =>
    selectSortPeopleBy(state.plannerV2.people),
  )

  const sortPlaceholderBy = useAppSelector((state) =>
    selectSortPlaceholdersBy(state.plannerV2.people),
  )

  const sortByOrder = useAppSelector((state) =>
    selectSortByOrderChanged(state.plannerV2.people),
  )

  const onPeopleSortChange = (option: {
    value: PeopleSortOption
    label: string
  }) => {
    dispatch(sortPeopleChanged(option.value))
    setSetting("peoplePlannerSort", option)
    track("Planner Sort Changed", { value: option.label })
  }

  const onSortOrderChange = () => {
    const sortBy = sortByOrder === "asc" ? "desc" : "asc"
    dispatch(sortByOrderChanged(sortBy))
    track("Planner Sort Direction Changed", { value: sortBy })
  }

  const onPlaceholderSortChange = (option: {
    value: PlaceholderSortOption
    label: string
  }) => {
    dispatch(sortPlaceholdersChanged(option.value))
    setSetting("placeholderPlannerSort", option)
    track("Planner Sort Changed", { value: option.label })
  }

  const SortControlTarget = (targetProps: DropdownTargetProps) => {
    const { disabled, active } = targetProps

    const tooltipContent = disabled ? (
      <OverflowTooltipContent functionality={`people sorting`} />
    ) : (
      "Sort people by"
    )
    return (
      <IconButton
        disabled={disabled ? "button" : false}
        active={active}
        icon={<icons.Sort />}
        tooltipContent={tooltipContent}
      />
    )
  }

  return (
    <Dropdown
      disabled={props.disabled}
      Target={SortControlTarget}
      placement="bottom-start"
      dataTest="people-sort-by"
      className={styles.dropdown}
    >
      <DropdownHeading>Sort People</DropdownHeading>
      <div>
        {peopleSortOptions.map((option) => (
          <MenuItem
            className={styles.menuItem}
            key={option.value}
            text={
              <div className={styles.menuItemContainer}>
                <div
                  className={styles.menuItemName}
                  onClick={() => onPeopleSortChange(option)}
                >
                  {option.label}
                </div>
                {sortPeopleBy === "availability" &&
                  option.value === "availability" && (
                    <div className={styles.iconsContainer}>
                      <Tooltip placement="top" content="Reverse order">
                        <Icon
                          icon="changes"
                          className={styles.icon}
                          onClick={onSortOrderChange}
                        />
                      </Tooltip>
                      <Tooltip placement="top" content="Refresh">
                        <div onClick={() => onPeopleSortChange(option)}>
                          <icons.UpdateIcon
                            className={classcat([
                              styles.icon,
                              styles.refreshIcon,
                            ])}
                            size={15}
                            alt=""
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
              </div>
            }
            active={sortPeopleBy === option.value}
          />
        ))}
      </div>
      <DropdownHeading>Sort Placeholders</DropdownHeading>
      <div>
        {placeholderSortOptions
          .filter((option) => option.value !== "priority" || priorityFeature)
          .map((option) => (
            <MenuItem
              key={option.value}
              text={
                <div className={styles.menuItemContainer}>
                  <div className={styles.menuItemName}>{option.label}</div>
                  {sortPlaceholderBy === "startDate" &&
                    option.value === "startDate" && (
                      <Tooltip placement="top" content="Refresh">
                        <icons.UpdateIcon
                          size={15}
                          className={classcat([
                            styles.icon,
                            styles.refreshIcon,
                          ])}
                          alt=""
                        />
                      </Tooltip>
                    )}
                </div>
              }
              active={sortPlaceholderBy === option.value}
              onClick={() => onPlaceholderSortChange(option)}
            />
          ))}
      </div>
    </Dropdown>
  )
}

export default PeopleSortControl
