import React from "react"
import { useDispatch } from "react-redux"

import { track } from "~/helpers/analytics"

import { isSplitScreenMode } from "~/Mode.reducer"
import {
  selectIsTentativeProjectEnabled,
  toggleProject,
} from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"

import { TentativeProjectEnabledSwitch } from "./TentativeProjectEnabledSwitch"

type Props = {
  /**
   * Project id of a tentative project
   */
  id: number
  /**
   * Display in a minimal style swapping the label for a tooltip
   */
  minimal?: boolean
}

const TentativeProjectEnabledSwitchContainer = ({
  id,
  minimal = false,
}: Props) => {
  const checked = useAppSelector((state) =>
    selectIsTentativeProjectEnabled(state.plannerV2.scenarioPlanning, id),
  )
  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  const disabled = isSplitScreenMode(modeAction)

  const dispatch = useDispatch()
  const handleChange = () => {
    track("Planner Tentative Project Planner Toggled")
    dispatch(toggleProject(id))
  }

  const tooltipContent = disabled ? (
    `Toggling disabled while in ${modeAction} mode`
  ) : checked ? (
    <>
      Exclude project from planner and chart calculations.
      <br />
      This will affect all people scheduled on this project
    </>
  ) : (
    <>
      Include project in planner and chart calculations.
      <br />
      This will affect all people scheduled on this project
    </>
  )

  return (
    <TentativeProjectEnabledSwitch
      minimal={minimal}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      tooltipContent={tooltipContent}
    />
  )
}

export { TentativeProjectEnabledSwitchContainer }
