import React from "react"

import styles from "./SidePanel.module.css"

import { track } from "~/helpers/analytics"
import { buildImageUrl } from "~/helpers/image"
import { formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"
import IconButton from "~/common/PageControls/IconButton"
import TooltipEllipsis from "~/common/TooltipEllipsis"
import * as icons from "~/common/react-icons"

import { useSidePanel } from "./SidePanel"

type Props = {
  title: string
  subtitle?: string
  type: "person" | "placeholder"
  person?: {
    email: string
    first_name: string
    last_name: string
    image_key: string
  }
}

const closeButtonProps = {
  color: "var(--smoke)",
}

const SidePanelHeader = ({
  title,
  subtitle,
  type: panelType,
  person,
}: Props) => {
  const { closePanel } = useSidePanel()

  const onClose = () => {
    switch (panelType) {
      case "person":
        track("Person Side Panel Closed")
        break
      case "placeholder":
        track("Placeholder Side Panel Closed")
        break
      default:
        panelType satisfies never
        break
    }
    closePanel()
  }
  const showAvatar = panelType === "placeholder" || person

  return (
    <>
      <div className={styles.closeIconContainer}>
        <IconButton
          onClick={onClose}
          icon={<icons.Cross size={16} />}
          buttonProps={closeButtonProps}
          tooltipContent={null}
          disabled="tooltip"
          className={styles.closeButton}
          dataTest="close-side-panel"
        />
      </div>

      <div className={styles.nameAndAvatar}>
        {showAvatar && (
          <Avatar
            email={person?.email}
            initials={formatNameAsInitials(
              person?.first_name,
              person?.last_name,
            )}
            avatar={buildImageUrl(person?.image_key)}
            is_placeholder={panelType === "placeholder"}
            size={46}
          />
        )}
        <div className={styles.title}>
          <TooltipEllipsis text={title} className={styles.name} />
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </div>
      </div>
    </>
  )
}

export default SidePanelHeader
