import { DatePicker } from "@blueprintjs/datetime"
import { dateHelpers } from "@runn/calculations"
import { addYears, startOfISOWeek, subYears } from "date-fns"
import React, { useEffect, useState } from "react"

type Props = {
  onChange: (date: string) => void
}

const SelectProjectStartDate = (props: Props) => {
  const { onChange } = props

  const [startDate, setStartDate] = useState(startOfISOWeek(new Date()))
  const minDate = subYears(new Date(), 1)
  const maxDate = addYears(new Date(), 3)

  const handleDateChange = (date) => {
    setStartDate(startOfISOWeek(date))
  }

  useEffect(() => {
    onChange(dateHelpers.formatToRunnDate(startDate))
  }, [startDate, onChange])

  const dayPickerProps = {
    disabledDays: [{ daysOfWeek: [0, 2, 3, 4, 5, 6] }],
    firstDayOfWeek: 1,
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <DatePicker
          onChange={handleDateChange}
          highlightCurrentDay={true}
          defaultValue={startDate}
          dayPickerProps={dayPickerProps}
          minDate={minDate}
          maxDate={maxDate}
          canClearSelection={false}
          value={startDate}
        />
      </div>
    </>
  )
}

export default SelectProjectStartDate
