import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"

import { ProjectCustomFieldTypes_accounts$key } from "~/CustomFields/__generated__/ProjectCustomFieldTypes_accounts.graphql"

import {
  CustomTypeName,
  sortCustomFields,
} from "~/helpers/custom-field-helpers"

import { UseCustomFieldsOutput } from "~/CustomFields/types"

export const useProjectCustomFieldTypes = (
  account: ProjectCustomFieldTypes_accounts$key,
): UseCustomFieldsOutput<"PROJECT"> => {
  const data = useFragment<ProjectCustomFieldTypes_accounts$key>(
    graphql`
      fragment ProjectCustomFieldTypes_accounts on accounts {
        custom_text_types_project: custom_text_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_date_types_project: custom_date_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_select_types_project: custom_select_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          model
          required
          singleSelect: single_select
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
          sort_order
        }
        custom_checkbox_types_project: custom_checkbox_types(
          where: { model: { _eq: "PROJECT" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
          sort_order
        }
      }
    `,
    account,
  )

  const {
    custom_text_types_project: text,
    custom_select_types_project: select,
    custom_checkbox_types_project: checkbox,
    custom_date_types_project: date,
  } = data

  return useMemo(
    () => ({
      model: "PROJECT" as const,
      text: text.slice().sort(sortCustomFields),
      select: select
        .map((item) => ({
          ...item,
          typeName: CustomTypeName.SELECT,
          single_select: item.singleSelect,
        }))
        .sort(sortCustomFields),
      checkbox: checkbox.slice().sort(sortCustomFields),
      date: date.slice().sort(sortCustomFields),
    }),
    [text, select, checkbox, date],
  )
}
