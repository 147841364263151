/**
 * @generated SignedSource<<92e3252bf1d680a9acce031a1cdc3bef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingFinancials_account$data = {
  readonly currency: string | null | undefined;
  readonly default_full_time_minutes: number;
  readonly id: number;
  readonly projects: ReadonlyArray<{
    readonly id: number;
    readonly rate_type: string;
  }>;
  readonly rate_cards: ReadonlyArray<{
    readonly id: number;
    readonly name: string | null | undefined;
    readonly role_charge_out_rates: ReadonlyArray<{
      readonly charge_out_rate: number | null | undefined;
      readonly rate_card_id: number;
      readonly role: {
        readonly id: number;
        readonly name: string | null | undefined;
      };
      readonly role_id: number;
    }>;
  }>;
  readonly rate_type: string;
  readonly " $fragmentType": "OnboardingFinancials_account";
};
export type OnboardingFinancials_account$key = {
  readonly " $data"?: OnboardingFinancials_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingFinancials_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate_type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingFinancials_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "rate_cards",
      "kind": "LinkedField",
      "name": "rate_cards",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "role_charge_out_rates",
          "kind": "LinkedField",
          "name": "role_charge_out_rates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate_card_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "roles",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": "charge_out_rate",
              "args": null,
              "kind": "ScalarField",
              "name": "charge_out_rate_private",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "2b61c3c6df62e4fd7b678cd5507bacfa";

export default node;
