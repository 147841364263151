/**
 * @generated SignedSource<<5dd77ddf72cd6a5164aaf5e093fd435c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MilestoneCreateMutation$variables = {
  date: any;
  icon: string;
  note?: string | null | undefined;
  project_id: number;
  title: string;
};
export type MilestoneCreateMutation$data = {
  readonly createMilestone: {
    readonly milestone: {
      readonly date: string | null | undefined;
      readonly icon: string | null | undefined;
      readonly id: number;
      readonly note: string | null | undefined;
      readonly project: {
        readonly calc_end_date: string | null | undefined;
        readonly calc_start_date: string | null | undefined;
        readonly id: number;
        readonly milestones: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly milestones_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      };
      readonly project_id: number;
      readonly title: string | null | undefined;
    } | null | undefined;
  };
};
export type MilestoneCreateMutation = {
  response: MilestoneCreateMutation$data;
  variables: MilestoneCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "icon"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "icon",
        "variableName": "icon"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "project_id"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "CreateMilestoneResult",
    "kind": "LinkedField",
    "name": "createMilestone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "milestones",
        "kind": "LinkedField",
        "name": "milestone",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": "date",
            "args": null,
            "kind": "ScalarField",
            "name": "date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_start_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calc_end_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "milestones",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "milestones_aggregate",
                "kind": "LinkedField",
                "name": "milestones_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "milestones_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MilestoneCreateMutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "MilestoneCreateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "7ebb3a43a68bdd0fb5731ca33aad14a1",
    "id": null,
    "metadata": {},
    "name": "MilestoneCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MilestoneCreateMutation(\n  $icon: String!\n  $note: String\n  $title: String!\n  $date: Date!\n  $project_id: Int!\n) {\n  createMilestone(icon: $icon, note: $note, title: $title, date: $date, projectId: $project_id) {\n    milestone {\n      id\n      title\n      icon\n      note\n      date: date_runn\n      project_id\n      project {\n        id\n        calc_start_date\n        calc_end_date\n        milestones {\n          id\n        }\n        milestones_aggregate {\n          aggregate {\n            count\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d5c8e51725da031ba3f070a2094aba6";

export default node;
