/**
 * @generated SignedSource<<2d340c6a47a5e448d0b3209a022ca929>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeOffActionPill_account$data = {
  readonly id: number;
  readonly projects: ReadonlyArray<{
    readonly confirmed: boolean;
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerDatePicker_account">;
  readonly " $fragmentType": "TimeOffActionPill_account";
};
export type TimeOffActionPill_account$key = {
  readonly " $data"?: TimeOffActionPill_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeOffActionPill_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeOffActionPill_account",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerDatePicker_account"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "confirmed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "8db1c4935feef50a54cac88c726a3f01";

export default node;
