/**
 * @generated SignedSource<<01b083021fb8c8f9c94d314a03fa67e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonRequestStatus = "NEED_TO_HIRE" | "PENDING" | "REQUESTED" | "%future added value";
export type CreatePersonRequestInput = {
  personId: number;
  projectId: number;
  status: PersonRequestStatus;
};
export type PersonRequestCreateMutation$variables = {
  input: CreatePersonRequestInput;
};
export type PersonRequestCreateMutation$data = {
  readonly createPersonRequest: {
    readonly person_request: {
      readonly id: number;
      readonly person: {
        readonly id: number;
        readonly person_requests: ReadonlyArray<{
          readonly id: number;
          readonly status: any;
          readonly updated_at: string;
        }>;
      };
      readonly person_id: number;
      readonly project_id: number;
      readonly status: any;
      readonly user: {
        readonly first_name: string | null | undefined;
        readonly id: number;
        readonly last_name: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  };
};
export type PersonRequestCreateMutation = {
  response: PersonRequestCreateMutation$data;
  variables: PersonRequestCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePersonRequest",
    "kind": "LinkedField",
    "name": "createPersonRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "person_requests",
        "kind": "LinkedField",
        "name": "person_request",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "person_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "person_requests",
                "kind": "LinkedField",
                "name": "person_requests",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonRequestCreateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonRequestCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "1a87642cd3bf00a8a36b9ddb429d6d96",
    "id": null,
    "metadata": {},
    "name": "PersonRequestCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonRequestCreateMutation(\n  $input: CreatePersonRequestInput!\n) {\n  createPersonRequest(input: $input) {\n    person_request {\n      id\n      status\n      project_id\n      person_id\n      person {\n        id\n        person_requests {\n          id\n          status\n          updated_at\n        }\n      }\n      user {\n        id\n        first_name\n        last_name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4fcba35bc5e9b9da7cf1abd52c36961";

export default node;
