/**
 * @generated SignedSource<<19b8e16da893eb09b1df8af0ede5155f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewsDropdown_user$data = {
  readonly account: {
    readonly id: number;
    readonly views: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string;
      readonly people_filters: any;
      readonly project_filters: any;
    }>;
  };
  readonly id: number;
  readonly view: {
    readonly description: string | null | undefined;
    readonly id: number;
    readonly name: string;
    readonly people_filters: any;
    readonly project_filters: any;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ViewFormDialog_user">;
  readonly " $fragmentType": "ViewsDropdown_user";
};
export type ViewsDropdown_user$key = {
  readonly " $data"?: ViewsDropdown_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewsDropdown_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "project_filters",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "people_filters",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewsDropdown_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "views",
          "kind": "LinkedField",
          "name": "views",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ViewFormDialog_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "0128d69e9885da1310f85368ce3f5c3c";

export default node;
