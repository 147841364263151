/**
 * @generated SignedSource<<e2023d863516d9d52199ff8aad38d898>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TrialModalQuery$variables = Record<PropertyKey, never>;
export type TrialModalQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly id: number;
      readonly name: string;
    };
    readonly id: number;
    readonly user_accounts: ReadonlyArray<{
      readonly account: {
        readonly account_type: string;
        readonly alternative_account_id: number | null | undefined;
        readonly id: number;
        readonly name: string;
      };
      readonly id: number;
    }>;
  } | null | undefined;
};
export type TrialModalQuery = {
  response: TrialModalQuery$data;
  variables: TrialModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "user_accounts",
        "kind": "LinkedField",
        "name": "user_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "account_type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alternative_account_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TrialModalQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TrialModalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9ef0ebf14d7e912dfea5b2d3eb9a400d",
    "id": null,
    "metadata": {},
    "name": "TrialModalQuery",
    "operationKind": "query",
    "text": "query TrialModalQuery {\n  current_user {\n    id\n    account {\n      id\n      name\n    }\n    user_accounts {\n      id\n      account {\n        id\n        name\n        account_type\n        alternative_account_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5db01bb34bb4ed29461278c57b69b8d7";

export default node;
