/**
 * @generated SignedSource<<a1b63c486457eabed89dbde23f2906ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBudgetLine_user$data = {
  readonly id: number;
  readonly permissions: any;
  readonly " $fragmentType": "ProjectBudgetLine_user";
};
export type ProjectBudgetLine_user$key = {
  readonly " $data"?: ProjectBudgetLine_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetLine_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBudgetLine_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "cfd8bc030ca8339b3f961b06db32bc3a";

export default node;
