import React from "react"
import { useSelector } from "react-redux"
import useScrollbarSize from "react-scrollbar-size"

import styles from "./OverflowBanner.module.css"

import FilterDownMessage from "~/Planner/LimitedQuery/FilterDownMessage"
import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { ReduxState } from "~/rootReducer"

const OverflowBanner = () => {
  const activePage = useSelector((state: ReduxState) => state.activePage)
  const queryLimit = useQueryLimit()
  const { limit } = queryLimit[activePage]
  const { width: scrollbarWidth } = useScrollbarSize()

  return (
    <div
      className={styles.banner}
      style={{ width: `calc(100% - ${scrollbarWidth}px)` }}
    >
      <FilterDownMessage
        limit={limit}
        functionality="all features (e.g. grouping and sorting)"
      />
    </div>
  )
}

export default OverflowBanner
