import * as fe from "@runn/filter-engine"
import React from "react"

import styles from "./FilterBlockWithSkillLevel.module.css"

import type { Option } from "~/types/helpers"

import FilterBlock from "./FilterBlock"

// order is important here, as it is used to serialize the levels
const ALL_LEVELS = [null, 1, 2, 3, 4] as const

type Props = {
  competencyList: fe.filters.SkillWithLevelList | fe.filters.LegacySkillIdList

  icon: React.ReactNode
  label: string
  disabled?: boolean
  selectedOptions: Option<fe.filters.SkillWithLevel>[]
  availableOptions: Option<fe.filters.SkillWithLevel>[]
  isDropdownOpen: boolean
  filterLabelPlural: string

  onChange: (list: fe.filters.SkillWithLevelList) => void
  onChangeDropdownOpen: (isOpen: boolean) => void
  onRemove: () => void
}

const FilterBlockWithSkillLevel = (props: Props) => {
  const {
    competencyList,
    icon,
    label,
    disabled,
    selectedOptions,
    availableOptions,
    isDropdownOpen,
    filterLabelPlural,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (list: fe.filters.SkillWithLevelList) => {
    const nextList = list.map((item) => {
      // if we've just added a skill, default to all levels
      if (item.level.length === 0) {
        return { ...item, level: [null, 1, 2, 3, 4] }
      }

      return item
    })
    return onChange(nextList)
  }

  const handleLevelChange = (
    skillId: number,
    level: number,
    enabled: boolean,
  ) => {
    let found = false

    /*
     * We need to be backwards compatible with the old list format (which is
     * just a list of skillIds). We use the migrateLegacySkillIdList function
     * to convert the old list format to the new format, with all levels
     * enabled.
     */
    const skillLevelList = fe.filters.migrateLegacySkillIdList(competencyList)

    const nextList = skillLevelList.flatMap((item) => {
      if (item.skillId !== skillId) {
        return item
      }

      found = true

      const currentState = item.level.includes(level)
      if (enabled === currentState) {
        // no change
        return item
      }

      const nextLevel = enabled
        ? [...item.level, level]
        : item.level.filter((l) => l !== level)

      // are all levels disabled? if so, remove the skill
      if (nextLevel.length === 0) {
        // remove the skill
        return []
      }

      return { skillId, level: nextLevel }
    })
    if (!found) {
      nextList.push({ skillId, level: [level] })
    }

    return onChange(nextList)
  }

  return (
    <FilterBlock
      icon={icon}
      label={label}
      filterLabelPlural={filterLabelPlural}
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={onRemove}
      classNames={{
        tooltipItem: styles.tooltipItem,
        targetSelected: styles.targetSelected,
      }}
      renderTargetOptionLabel={(option) => {
        const levelSet = new Set(option.value.level)

        // if all levels are selected, do not show the levels
        if (levelSet.size === 5) {
          return <div className={styles.targetOptionLabel}>{option.label}</div>
        }

        // serialize the selected levels into to human friendly string
        // e.g. "1,2,3,4" or "-,1,4"
        const levelString = ALL_LEVELS.flatMap((level): string | never[] => {
          // filter out any levels that are not selected
          if (!levelSet.has(level)) {
            return []
          }
          return level === null ? "-" : level.toString()
        }).join(",")

        return (
          <>
            <div className={styles.targetOptionLabel}>{option.label}</div>{" "}
            <div className={styles.targetOptionLevel}>{levelString}</div>
          </>
        )
      }}
      renderFilterBlockOption={(option) => {
        const skillId = option.value.skillId
        const levelSet = new Set(option.value.level)

        return (
          <div className={styles.levelCheckboxContainer}>
            {ALL_LEVELS.map((level) => {
              const optionLabel = level === null ? "-" : level.toString()
              return (
                <input
                  type="checkbox"
                  value={optionLabel}
                  data-label={optionLabel}
                  className={styles.levelCheckbox}
                  checked={levelSet.has(level)}
                  onChange={(event) =>
                    handleLevelChange(skillId, level, event.target.checked)
                  }
                />
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default FilterBlockWithSkillLevel
