import React, { useState } from "react"

import {
  CustomType,
  CustomTypeName,
  CustomValue,
  CustomValuesMap,
  getValueForType,
  updateValuesMap,
} from "~/helpers/custom-field-helpers"
import { dashify } from "~/helpers/general-helpers"

import CustomEditorCheckboxField from "./fields/CustomEditorCheckboxField"
import CustomEditorDateField from "./fields/CustomEditorDateField"
import CustomEditorSelectField from "./fields/CustomEditorSelectField"
import CustomEditorTextField from "./fields/CustomEditorTextField"

type ComponentMap = {
  [K in CustomTypeName]: (props: {
    id: string
    type: CustomType
    value: CustomValue
    showWeekNumbers?: boolean
    onChange: (props: { type: CustomType; value: CustomValue }) => void
    formatSelectOptions?: (options, typeName: string) => void
  }) => JSX.Element
}

const map: ComponentMap = {
  [CustomTypeName.TEXT]: CustomEditorTextField,
  [CustomTypeName.DATE]: CustomEditorDateField,
  [CustomTypeName.SELECT]: CustomEditorSelectField,
  [CustomTypeName.CHECKBOX]: CustomEditorCheckboxField,
}

const getComponentForType = <A extends CustomTypeName>(type: A) => map[type]

type Props = {
  typesMap: CustomType[]
  valuesMap: CustomValuesMap
  showWeekNumbers: boolean
  onUpdate: (values: CustomValuesMap) => void
  itemClassName?: string
  formatSelectOptions?: (options, typeName: string) => void
}

const CustomEditor = ({
  typesMap,
  valuesMap,
  onUpdate,
  itemClassName,
  formatSelectOptions,
  showWeekNumbers,
}: Props) => {
  const [localValuesMap, setLocalValuesMap] =
    useState<CustomValuesMap>(valuesMap)
  const handleChange = ({
    type,
    value,
  }: {
    type: CustomType
    value: CustomValue
  }) => {
    const updatedValuesMap = updateValuesMap(localValuesMap, type, value)
    setLocalValuesMap(updatedValuesMap)
    onUpdate(updatedValuesMap)
  }
  // Render all fields for which we have definitions
  return (
    <>
      {typesMap.map((type) => {
        const fieldTypeName = type.typeName as CustomTypeName

        const Component = getComponentForType(fieldTypeName)
        const localValue = getValueForType(
          fieldTypeName,
          type.id,
          localValuesMap,
        )
        const id = dashify(`custom-${fieldTypeName}-${type.name}`)
        return (
          <div id={id} key={id} className={itemClassName}>
            <Component
              type={type}
              value={localValue}
              id={id}
              onChange={handleChange}
              formatSelectOptions={
                fieldTypeName === "select" && formatSelectOptions
              }
              showWeekNumbers={showWeekNumbers}
            />
          </div>
        )
      })}
    </>
  )
}

export default CustomEditor
