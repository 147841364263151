import { PayloadAction, SliceCaseReducers, createSlice } from "@reduxjs/toolkit"
import { match } from "ts-pattern"

/**
 * The unit to display the total effort of work on the UI.
 * total effort is related to but not the same display unit as effort
 * Used to determine which unit to convert the stored value to
 *
 * Days are determined by account.default_fulltime_minutes
 *
 * | Unit  | Example  |
 * | ----- | -------- |
 * | hours | 80 hours |
 * | days  | 2 days   |
 */
export type TotalEffortDisplayUnit = "hours" | "days"

const totalEffortDisplayUnitSlice = createSlice<
  TotalEffortDisplayUnit,
  SliceCaseReducers<TotalEffortDisplayUnit>
>({
  name: "totalEffortDisplayUnit",
  initialState: "hours" as TotalEffortDisplayUnit,
  reducers: {
    changedTotalEffortDisplayUnit: (
      _state,
      action: PayloadAction<TotalEffortDisplayUnit>,
    ) => {
      return match(action.payload)
        .with("hours", () => "hours" as const)
        .with("days", () => "days" as const)
        .exhaustive()
    },
  },
})

export const { changedTotalEffortDisplayUnit } =
  totalEffortDisplayUnitSlice.actions

export default totalEffortDisplayUnitSlice.reducer
