import React from "react"

import * as hashids from "~/helpers/hashids"
import routes from "~/helpers/routes"

import { ModalFormWrapper } from "~/common/ModalForm"
import { TangramBuilding } from "~/common/tangrams/tangramBuilding"

import { showToast } from "~/containers/ToasterContainer"
import ClientForm, { ReturnClient } from "~/forms/ClientForm/ClientForm"

type Props = {
  closeDialog?: () => void
  onSuccess?: (client: ReturnClient) => void
}

// This wrapper is a bit verbose, but future proofed towards providing
// more ways to create clients (bulk create and import)
const CreateClientForm = (props: Props) => {
  const { closeDialog, onSuccess } = props

  const handleSuccess = (client: ReturnClient) => {
    onSuccess && onSuccess(client)

    const clientHashId = hashids.clients.encode(client.id)
    showToast({
      message: "New client created",
      description: client.name,
      type: "success",
      actions: [{ href: routes.viewClientUrl(clientHashId) }],
    })

    closeDialog()
  }

  return (
    <ModalFormWrapper
      headerTitle="New Client"
      tangram={<TangramBuilding />}
      tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
    >
      <ClientForm onCancel={closeDialog} onSuccess={handleSuccess} />
    </ModalFormWrapper>
  )
}

export default CreateClientForm
