import { requestSubscription } from "react-relay"
import { GraphQLSubscriptionConfig, OperationType } from "relay-runtime"
import { Disposable } from "relay-runtime"

import { environment, sessionStore } from "~/store/hasura"
import { getSubscriptionClient } from "~/store/subscriptionClient"

export default class Subscription {
  public getSubscriptionConfig: () => Promise<
    GraphQLSubscriptionConfig<OperationType>
  >

  private subscriptionDisposable: Disposable

  public subscribe = async () => {
    // ensure the client is available (has async connection params)
    await getSubscriptionClient(sessionStore)

    const subscriptionConfig = await this.getSubscriptionConfig()
    this.subscriptionDisposable = requestSubscription(environment, {
      ...subscriptionConfig,
      onError: (e) => {
        subscriptionConfig.onError?.(e)

        // Silence RelayObservable errors after graphql-ws retries are exhausted,
        // there's no point surfacing them in application error logs
        console.debug("RelayObservable.onError", e) // eslint-disable-line no-console
      },
    })
  }

  public unsubscribe = () => {
    if (!this.subscriptionDisposable) {
      return
    }
    this.subscriptionDisposable.dispose()
    this.subscriptionDisposable = undefined
  }
}
