import { TextAreaProps } from "@blueprintjs/core"
import React from "react"

import styles from "./TextArea.module.css"

import InputLabel from "./InputLabel"

type Props = {
  id: string
  onChange: (e: { target: { value: string } }) => void
  label?: string
  optional?: boolean
} & TextAreaProps

const TextArea = (props: Props) => {
  const { id, label, optional, onChange, ...rest } = props
  return (
    <div className={styles.wrapper}>
      {label && <InputLabel htmlFor={id} label={label} optional={optional} />}
      <textarea id={id} onChange={onChange} {...rest} />
    </div>
  )
}

export default TextArea
