/**
 * @generated SignedSource<<a49f8ad4370be41ff60d02b01866eabc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImportProjectViaIntegrationListQuery$variables = Record<PropertyKey, never>;
export type ImportProjectViaIntegrationListQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"useIntegrationList_account">;
    };
    readonly id: number;
  } | null | undefined;
};
export type ImportProjectViaIntegrationListQuery = {
  response: ImportProjectViaIntegrationListQuery$data;
  variables: ImportProjectViaIntegrationListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportProjectViaIntegrationListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useIntegrationList_account"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ImportProjectViaIntegrationListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": "activeIntegrations",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "state": {
                        "_neq": "PENDING"
                      }
                    }
                  }
                ],
                "concreteType": "integration",
                "kind": "LinkedField",
                "name": "integrationsV2",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "integration_service",
                    "kind": "LinkedField",
                    "name": "integration_service",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "square_image_url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "integrationsV2(where:{\"state\":{\"_neq\":\"PENDING\"}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d4ff9b90df81a8bcf2d28ee60a282c6",
    "id": null,
    "metadata": {},
    "name": "ImportProjectViaIntegrationListQuery",
    "operationKind": "query",
    "text": "query ImportProjectViaIntegrationListQuery {\n  current_user {\n    id\n    account {\n      id\n      ...useIntegrationList_account\n    }\n  }\n}\n\nfragment useIntegrationList_account on accounts {\n  activeIntegrations: integrationsV2(where: {state: {_neq: \"PENDING\"}}) {\n    id\n    category\n    state\n    active\n    integration_service {\n      id\n      name\n      square_image_url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91be1389ee63527464d85222f4922440";

export default node;
