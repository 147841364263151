import { Tooltip } from "@blueprintjs/core"
import { DatePicker } from "@blueprintjs/datetime"
import { dateHelpers } from "@runn/calculations"
import { addYears, isValid, subYears } from "date-fns"
import React, { useMemo } from "react"

import styles from "./FilterBlockMenu.module.css"

import Select from "~/common/Select"

import { showToast } from "~/containers/ToasterContainer"

import { DatePayload, formatDate } from "./FilterBlockDate"

const DATE_SELECT_OPTIONS = [
  { value: "exact" as const, label: "Exact" },
  { value: "range" as const, label: "Range" },
]

type Props = {
  label: string
  datePayload: DatePayload
  onChange: (datePayload: DatePayload) => void
  onClose: () => void
}

const FilterBlockDateMenu = (props: Props) => {
  const { onChange, onClose, datePayload } = props

  const selectedFilterType =
    DATE_SELECT_OPTIONS.find((option) => datePayload.type === option.value) ??
    DATE_SELECT_OPTIONS[0]

  const doneDisabled =
    (datePayload.type === "exact" && !datePayload.exact) ||
    (datePayload.type === "range" && (!datePayload.start || !datePayload.end))

  const handleChangeSelectedFilterType = (
    e: (typeof DATE_SELECT_OPTIONS)[number],
  ) => {
    if (e.value === "exact") {
      onChange({ type: "exact", exact: null })
    } else if (e.value === "range") {
      onChange({ type: "range", start: null, end: null })
    }
  }

  const setIfValidDate = (date: Date | null, fn: () => void) => {
    if (date == null || isValid(date)) {
      fn()
    } else {
      showToast({
        message: "Please select a valid date.",
        type: "error",
      })
    }
  }

  const { minDate, maxDate, exactDate, startDate, endDate } = useMemo(() => {
    return {
      minDate: subYears(new Date(), 100),
      maxDate: addYears(new Date(), 100),
      exactDate:
        datePayload.type === "exact" && datePayload.exact
          ? dateHelpers.parseDatabaseDate(datePayload.exact)
          : null,
      startDate:
        datePayload.type === "range" && datePayload.start
          ? dateHelpers.parseDatabaseDate(datePayload.start)
          : null,
      endDate:
        datePayload.type === "range" && datePayload.end
          ? dateHelpers.parseDatabaseDate(datePayload.end)
          : null,
    }
  }, [datePayload])

  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        <div className={styles.select}>
          <Select
            name="date-select-type"
            value={selectedFilterType}
            onChange={handleChangeSelectedFilterType}
            options={DATE_SELECT_OPTIONS}
          />
        </div>
        <div className={styles.date}>
          {datePayload.type === "exact" && (
            <DatePicker
              highlightCurrentDay={true}
              defaultValue={startDate}
              minDate={minDate}
              maxDate={maxDate}
              canClearSelection={false}
              value={exactDate}
              onChange={(selectedDate) => {
                setIfValidDate(selectedDate, () =>
                  onChange({ type: "exact", exact: formatDate(selectedDate) }),
                )
              }}
            />
          )}
          {datePayload.type === "range" && (
            <>
              <div className={styles.datePickerContainer}>
                <p>Start Date</p>
                <DatePicker
                  highlightCurrentDay={true}
                  defaultValue={startDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  canClearSelection={false}
                  value={startDate}
                  onChange={(selectedDate) => {
                    setIfValidDate(selectedDate, () =>
                      onChange({
                        type: "range",
                        start: formatDate(selectedDate),
                        end: datePayload.end,
                      }),
                    )
                  }}
                />
              </div>
              <div className={styles.datePickerContainer}>
                <p>End Date</p>
                <DatePicker
                  highlightCurrentDay={true}
                  defaultValue={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  canClearSelection={false}
                  value={endDate}
                  onChange={(selectedDate) => {
                    setIfValidDate(selectedDate, () =>
                      onChange({
                        type: "range",
                        start: datePayload.start,
                        end: formatDate(selectedDate),
                      }),
                    )
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Tooltip
        content="Please type in a search term"
        placement="bottom"
        disabled={true}
      >
        <button
          className={styles.doneButton}
          onClick={onClose}
          disabled={doneDisabled}
          data-test="SuperSearch_FilterBlockDateMenu_doneButton"
        >
          <span className={styles.buttonText}>
            {doneDisabled && "Filter"}
            {datePayload.type === "exact" &&
              datePayload.exact &&
              `Filter by "${formatDate(exactDate)}"`}
            {datePayload.type === "range" &&
              datePayload.start &&
              datePayload.end &&
              `Filter by "${formatDate(startDate)} - ${formatDate(endDate)}"`}
          </span>
        </button>
      </Tooltip>
    </div>
  )
}

export default FilterBlockDateMenu
