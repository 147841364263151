import { Icon, Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./CustomTooltip.module.css"

import {
  CustomType,
  CustomTypeName,
  CustomValue,
  CustomValuesMap,
  getValueForType,
} from "~/helpers/custom-field-helpers"

import CustomTooltipCheckbox from "./CustomTooltipCheckbox"
import CustomTooltipDate from "./CustomTooltipDate"
import CustomTooltipSelect from "./CustomTooltipSelect"
import CustomTooltipText from "./CustomTooltipText"
import CustomTooltipLink from "./Link"

type Props = {
  typesMap: CustomType[]
  valuesMap: CustomValuesMap
  links?: ReadonlyArray<{
    name: string
    href: string
  }>
}

export type ComponentMap = {
  [K in CustomTypeName]: (props: {
    type: CustomType
    value: CustomValue
  }) => JSX.Element
}

const componentMap: ComponentMap = {
  [CustomTypeName.TEXT]: CustomTooltipText,
  [CustomTypeName.DATE]: CustomTooltipDate,
  [CustomTypeName.SELECT]: CustomTooltipSelect,
  [CustomTypeName.CHECKBOX]: CustomTooltipCheckbox,
}

const getComponentForType = <A extends CustomTypeName>(type: A) =>
  componentMap[type]

const CustomTooltip = (props: Props) => {
  const { typesMap, valuesMap, links } = props

  const TooltipContent = () => (
    <div
      className={styles.popoverContainer}
      data-test="custom-fields-links-content"
    >
      {typesMap.map((type) => {
        if (!type.show_in_planner) {
          return null
        }

        const typeName = type.typeName as CustomTypeName
        const typeId = type.id

        const Component = getComponentForType(typeName)
        const localValue = getValueForType(typeName, typeId, valuesMap)

        return (
          <Component
            key={`custom-${typeName}-${typeId}`}
            type={type}
            value={localValue}
          />
        )
      })}
      {links && (
        <div className={styles.links}>
          {links.map((link, idx) => (
            <CustomTooltipLink key={idx} {...link} />
          ))}
        </div>
      )}
    </div>
  )

  return (
    <Tooltip
      interactionKind="hover"
      lazy
      hoverOpenDelay={300}
      hoverCloseDelay={100}
      content={<TooltipContent />}
      popoverClassName={styles.tooltipPopover}
      placement="right-start"
    >
      <Icon
        icon="info-sign"
        size={12}
        style={{ cursor: "default" }}
        data-test="custom-fields-links-icon"
      />
    </Tooltip>
  )
}

export default CustomTooltip
