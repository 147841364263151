import { Placement } from "@blueprintjs/core"
import React from "react"

import { Popover2 } from "~/common/Popover2"

type FilterDropdownProps = {
  children: React.ReactElement
  disabled?: boolean
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  target: React.ReactNode
  placement?: Placement
}

const FilterDropdown = (props: FilterDropdownProps) => {
  const {
    disabled,
    isOpen,
    children,
    onOpen,
    onClose,
    target,
    placement = "bottom-start",
  } = props

  return (
    <Popover2
      disabled={disabled}
      isOpen={isOpen}
      content={children}
      placement={placement}
      onInteraction={onOpen}
      onClose={onClose}
      modifiers={{ offset: { options: { offset: [10, 10] } } }}
    >
      {target}
    </Popover2>
  )
}

export default FilterDropdown
