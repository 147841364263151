import { ProjectPersonCustomSelect } from "~/common/SuperSearch/Filters/ProjectPersonCustomSelect"
import { ProjectPersonCustomText } from "~/common/SuperSearch/Filters/ProjectPersonCustomText"

import { PersonCustomCheckbox } from "./PersonCustomCheckbox"
import { PersonCustomDate } from "./PersonCustomDate"
import { PersonCustomSelect } from "./PersonCustomSelect"
import { PersonCustomText } from "./PersonCustomText"
import { PersonEmploymentType } from "./PersonEmploymentType"
import { PersonId } from "./PersonId"
import { PersonIsFavourite } from "./PersonIsFavourite"
import { PersonJobTitleName } from "./PersonJobTitleName"
import { PersonManagerId } from "./PersonManagerId"
import { PersonMembership } from "./PersonMembership"
import { PersonPlaceholderStatus } from "./PersonPlaceholderStatus"
import { PersonProjectClientId } from "./PersonProjectClientId"
import { PersonProjectCustomCheckbox } from "./PersonProjectCustomCheckbox"
import { PersonProjectCustomDate } from "./PersonProjectCustomDate"
import { PersonProjectCustomSelect } from "./PersonProjectCustomSelect"
import { PersonProjectCustomText } from "./PersonProjectCustomText"
import { PersonProjectId } from "./PersonProjectId"
import { PersonProjectManagerId } from "./PersonProjectManagerId"
import { PersonProjectRoleId } from "./PersonProjectRoleId"
import { PersonProjectStatus } from "./PersonProjectStatus"
import { PersonProjectTagId } from "./PersonProjectTagId"
import { PersonProjectTeamId } from "./PersonProjectTeamId"
import { PersonProjectTemplateId } from "./PersonProjectTemplateId"
import { PersonProjectWorkstreamId } from "./PersonProjectWorkstreamId"
import { PersonRoleId } from "./PersonRoleId"
import { PersonSkillId } from "./PersonSkillId"
import { PersonState } from "./PersonState"
import { PersonTagId } from "./PersonTagId"
import { PersonTeamId } from "./PersonTeamId"
import { PersonType } from "./PersonType"
import { ProjectClientId } from "./ProjectClientId"
import { ProjectCustomCheckbox } from "./ProjectCustomCheckbox"
import { ProjectCustomDate } from "./ProjectCustomDate"
import { ProjectCustomSelect } from "./ProjectCustomSelect"
import { ProjectCustomText } from "./ProjectCustomText"
import { ProjectId } from "./ProjectId"
import { ProjectIsFavourite } from "./ProjectIsFavourite"
import { ProjectManagerId } from "./ProjectManagerId"
import { ProjectPersonCustomCheckbox } from "./ProjectPersonCustomCheckbox"
import { ProjectPersonCustomDate } from "./ProjectPersonCustomDate"
import { ProjectPersonId } from "./ProjectPersonId"
import { ProjectPersonJobTitleName } from "./ProjectPersonJobTitleName"
import { ProjectPersonManagerId } from "./ProjectPersonManagerId"
import { ProjectPersonPlaceholderStatus } from "./ProjectPersonPlaceholderStatus"
import { ProjectPersonRoleId } from "./ProjectPersonRoleId"
import { ProjectPersonSkillId } from "./ProjectPersonSkillId"
import { ProjectPersonTagId } from "./ProjectPersonTagId"
import { ProjectPersonTeamId } from "./ProjectPersonTeamId"
import { ProjectPricingModel } from "./ProjectPricingModel"
import { ProjectState } from "./ProjectState"
import { ProjectStatus } from "./ProjectStatus"
import { ProjectTagId } from "./ProjectTagId"
import { ProjectTeamId } from "./ProjectTeamId"
import { ProjectTemplateId } from "./ProjectTemplateId"
import { ProjectWorkstreamId } from "./ProjectWorkstreamId"
import UnknownFilter from "./UnknownFilter"

type FilterSelection = {
  Component: React.FC
  ReadonlyComponent?: React.FC
}

const FilterSelections = {
  person_custom_select: PersonCustomSelect,
  person_custom_text: PersonCustomText,
  person_custom_date: PersonCustomDate,
  person_employment_type: PersonEmploymentType,
  person_type: PersonType,
  person_id: PersonId,
  person_is_favourite: PersonIsFavourite,
  person_project_id: PersonProjectId,
  person_membership: PersonMembership,
  person_project_template_id: PersonProjectTemplateId,
  person_project_status: PersonProjectStatus,
  person_role_id: PersonRoleId,
  person_skill_id: PersonSkillId,
  person_state: PersonState,
  person_tag_id: PersonTagId,
  person_team_id: PersonTeamId,
  person_placeholder_status: PersonPlaceholderStatus,
  project_client_id: ProjectClientId,
  project_custom_select: ProjectCustomSelect,
  person_project_custom_select: PersonProjectCustomSelect,
  person_project_custom_text: PersonProjectCustomText,
  person_project_custom_date: PersonProjectCustomDate,
  project_custom_text: ProjectCustomText,
  project_custom_date: ProjectCustomDate,
  project_id: ProjectId,
  project_is_favourite: ProjectIsFavourite,
  project_person_id: ProjectPersonId,
  project_person_team_id: ProjectPersonTeamId,
  project_person_placeholder_status: ProjectPersonPlaceholderStatus,
  project_person_custom_select: ProjectPersonCustomSelect,
  project_person_custom_text: ProjectPersonCustomText,
  project_person_custom_date: ProjectPersonCustomDate,
  person_job_title_name: PersonJobTitleName,

  project_state: ProjectState,
  project_status: ProjectStatus,
  project_tag_id: ProjectTagId,
  project_team_id: ProjectTeamId,
  project_template_id: ProjectTemplateId,
  project_pricing_model: ProjectPricingModel,
  person_project_team_id: PersonProjectTeamId,
  project_person_skill_id: ProjectPersonSkillId,
  project_person_tag_id: ProjectPersonTagId,
  person_project_client_id: PersonProjectClientId,
  person_project_tag_id: PersonProjectTagId,
  project_workstream_id: ProjectWorkstreamId,
  person_project_workstream_id: PersonProjectWorkstreamId,
  project_person_role_id: ProjectPersonRoleId,
  person_project_role_id: PersonProjectRoleId,
  person_custom_checkbox: PersonCustomCheckbox,
  project_custom_checkbox: ProjectCustomCheckbox,
  person_project_custom_checkbox: PersonProjectCustomCheckbox,
  project_person_custom_checkbox: ProjectPersonCustomCheckbox,
  project_person_job_title_name: ProjectPersonJobTitleName,
  project_manager_id: ProjectManagerId,
  person_manager_id: PersonManagerId,
  project_person_manager_id: ProjectPersonManagerId,
  person_project_manager_id: PersonProjectManagerId,
} satisfies Record<string, FilterSelection>

export { UnknownFilter, FilterSelections }
