import {
  toFullTimeEquivalentEffort,
  toMinutesPerDayOfEffort,
} from "@runn/calculations"
import { minutesInHour } from "date-fns/constants"
import { Decimal } from "decimal.js"

type Props = {
  smallestValue: number
  largestValue: number
  showFTE?: boolean
  companyDefaultMinutes: number
  period: string
}

const convertHoursToFTE = (
  companyDefaultMinutes: number,
  period: string,
  hoursOfEffort: number,
): number => {
  const fteDayMultiplier = period === "weekly" ? 5 : 1
  const fteEffort = toFullTimeEquivalentEffort({
    minutesOfEffort: Math.abs(hoursOfEffort) * minutesInHour,
    fulltimeMinutesPerDay: companyDefaultMinutes * fteDayMultiplier,
  })
  return hoursOfEffort < 0 ? -fteEffort : fteEffort
}

const convertFTEToHours = (
  companyDefaultMinutes: number,
  period: string,
  fulltimeEquivalentEffort: number,
): number => {
  const fteDayMultiplier = period === "weekly" ? 5 : 1
  const minutesEffort = toMinutesPerDayOfEffort({
    fulltimeEquivalentEffort,
    fulltimeMinutesPerDay: companyDefaultMinutes * fteDayMultiplier,
  }).toNumber()
  return minutesEffort / minutesInHour
}

const getFTETicks = ({
  smallestValue,
  largestValue,
  companyDefaultMinutes,
  period,
}: Props): number[] => {
  const conversionProps: [number, string] = [companyDefaultMinutes, period]
  // shift everything up to positive numbers (generously rounding to the max FTE) to make calculations simpler
  // we will minus this at the end
  let addedAmount =
    smallestValue < 0
      ? convertFTEToHours(
          ...conversionProps,
          Math.ceil(
            convertHoursToFTE(
              ...conversionProps,
              Math.abs(Math.ceil(smallestValue)),
            ),
          ),
        )
      : 0
  const adjustedLargestValue = largestValue + addedAmount
  const largestFTE = Math.ceil(
    convertHoursToFTE(...conversionProps, adjustedLargestValue),
  ) // e.g. 17 hours will become 3 FTE
  const ticksDivisibleBy = 5
  const order = Math.pow(ticksDivisibleBy, Math.ceil(Math.log10(largestFTE))) // we want readable numbers like 0,5,10,15,20
  const rounded = Math.ceil(largestFTE / order) * order // required so numbers like 9 FTE currently round to 10
  const part = rounded / ticksDivisibleBy
  const arr = [
    0,
    ...Array.from({ length: ticksDivisibleBy - 1 }, (_, i) =>
      convertFTEToHours(...conversionProps, part * (i + 1)),
    ),
    convertFTEToHours(...conversionProps, rounded),
  ]
    .map((x) => new Decimal(x).toDecimalPlaces(2).toNumber()) // handle any floating point issues with decimal.js
    .reduce((acc, val) => (isNaN(val) ? acc : [...acc, val]), []) // remove any NaN
    .reduce((acc, val) => {
      // If our max FTE was too low, we want to remove extra ticks
      const largestFTEHours = convertFTEToHours(...conversionProps, largestFTE)
      if (val >= largestFTEHours && acc[acc.length - 1] >= largestFTEHours) {
        return acc
      }
      return [...acc, val]
    }, [])
  // Adjust our added amount based on our ticks, this means the top and bottom of the chart cuts off cleanly
  addedAmount = arr.reduce((prev, curr) => (addedAmount > prev ? curr : prev))
  // minus our offset
  return arr.map((x) => x - addedAmount)
}

const getHourTicks = (
  smallestValue: number,
  largestValue: number,
): number[] => {
  // Shift all values up so they're positive
  let addedAmount = smallestValue < 0 ? Math.abs(smallestValue) : 0
  const adjustedLargestValue = largestValue + addedAmount
  const order = Math.max(
    8,
    Math.pow(8, Math.floor(Math.log10(adjustedLargestValue))),
  ) // Most hours are in 8 hour blocks
  const rounded = Math.ceil(adjustedLargestValue / order) * order // Round to the nearest power of 8
  const segments = rounded % 5 === 0 ? 5 : 4 // if its divisible by 5 then show 5 ticks
  const part = rounded / segments
  const arr = [
    0,
    ...Array.from({ length: segments - 1 }, (_, i) => part * (i + 1)),
    rounded,
  ]
    .map((x) => new Decimal(x).toDecimalPlaces(2).toNumber()) // handle any floating point issues with decimal.js
    .reduce((acc, val) => (isNaN(val) ? acc : [...acc, val]), []) // remove any NaN
    .reduce((acc, val) => {
      // If our max hours were too low, we want to remove extra ticks
      if (
        val >= adjustedLargestValue &&
        acc[acc.length - 1] >= adjustedLargestValue
      ) {
        return acc
      }
      return [...acc, val]
    }, [])

  // Adjust our added amount based on our ticks, this means the top and bottom of the chart cuts off cleanly
  addedAmount = arr.reduce((prev, curr) => (addedAmount > prev ? curr : prev))

  // minus our offset
  return arr.map((x) => x - addedAmount)
}

const calculateYAxisTicks = ({
  smallestValue,
  largestValue,
  showFTE = false,
  companyDefaultMinutes,
  period,
}: Props): number[] => {
  if (showFTE) {
    return getFTETicks({
      smallestValue,
      largestValue,
      companyDefaultMinutes,
      period,
    })
  }
  return getHourTicks(smallestValue, largestValue)
}

export { convertHoursToFTE, calculateYAxisTicks }
