type GetStatsByRole = {
  projectRole?: {
    id: number
    role_id: number
    estimated_minutes: number
  }
  projectRoleRate: number
  allocatedMinutes: number
  billableMinutes: number
}

type GetStatsByWorkstream = {
  groupSummary: readonly {
    role_id
    workstream_id
    total_billable_minutes
    total_nonbillable_minutes
  }[]
  projectRates: readonly {
    id: number
    role_id: number
    rate: number
  }[]
}

const calculateRevenue = (minutes: number, rate: number) =>
  (minutes / 60) * rate

export const getStatsByRole = ({
  projectRole,
  projectRoleRate,
  allocatedMinutes,
  billableMinutes,
}: GetStatsByRole) => {
  const budgetMinutes = projectRole?.estimated_minutes ?? 0
  const hasBudget = !!budgetMinutes
  const isOverBudget = !!(budgetMinutes && allocatedMinutes > budgetMinutes)

  const allocatedRevenue = calculateRevenue(billableMinutes, projectRoleRate)
  const budgetRevenue = calculateRevenue(budgetMinutes, projectRoleRate)

  return {
    hasBudget,
    isOverBudget,
    allocatedRevenue,
    budgetRevenue,
    budgetMinutes,
  }
}

export const getStatsByWorkstream = ({
  groupSummary,
  projectRates,
}: GetStatsByWorkstream) => {
  const allocatedRevenue = groupSummary.reduce((acc, s) => {
    const roleRate =
      projectRates.find((pr) => pr.role_id === s.role_id)?.rate ?? 0
    const roleBudget = calculateRevenue(s.total_billable_minutes, roleRate)
    return acc + roleBudget
  }, 0)

  return {
    hasBudget: false,
    isOverBudget: false,
    allocatedRevenue,
    budgetRevenue: null,
    budgetMinutes: null,
  }
}
