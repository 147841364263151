/**
 * @generated SignedSource<<2ba05bc50434e8c696f705d972bd4374>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerLeftColumn_person$data = {
  readonly email: string | null | undefined;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"PersonPlannerMenu_person" | "PlaceholderPlannerMenu_placeholder">;
  readonly " $fragmentType": "PlannerLeftColumn_person";
};
export type PlannerLeftColumn_person$key = {
  readonly " $data"?: PlannerLeftColumn_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerLeftColumn_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderPlannerMenu_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonPlannerMenu_person"
    }
  ],
  "type": "people",
  "abstractKey": null
};

(node as any).hash = "6c3f71e34ab54309686e02f5d3a55b24";

export default node;
