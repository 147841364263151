/**
 * @generated SignedSource<<1a3204fea85c4a453695b09aa15d85b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectPlannerList_user$data = {
  readonly account: {
    readonly id: number;
    readonly name: string;
    readonly people: ReadonlyArray<{
      readonly competencies: ReadonlyArray<{
        readonly id: number;
        readonly level: number | null | undefined;
        readonly skill: {
          readonly id: number;
          readonly name: string;
        };
      }>;
      readonly current_contract: ReadonlyArray<{
        readonly id: number;
        readonly job_title: string | null | undefined;
      }> | null | undefined;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly last_name: string;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly person_requests: ReadonlyArray<{
        readonly id: number;
        readonly status: any;
      }>;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
        readonly project_id: number;
      }>;
      readonly tags: any;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
    }>;
    readonly projects: ReadonlyArray<{
      readonly active: boolean;
      readonly client: {
        readonly id: number;
        readonly name: string;
      };
      readonly confirmed: boolean;
      readonly custom_checkbox_values: ReadonlyArray<{
        readonly custom_checkbox_type_id: number;
        readonly id: number;
        readonly value: boolean;
      }>;
      readonly custom_date_values: ReadonlyArray<{
        readonly custom_date_type_id: number;
        readonly id: number;
        readonly value: any | null | undefined;
      }>;
      readonly custom_select_values: ReadonlyArray<{
        readonly custom_select_option_id: number;
        readonly custom_select_type_id: number;
        readonly id: number;
      }>;
      readonly custom_text_values: ReadonlyArray<{
        readonly custom_text_type_id: number;
        readonly id: number;
        readonly value: string;
      }>;
      readonly id: number;
      readonly is_template: boolean;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly user_id: number;
      }>;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly person_id: number;
        readonly role_id: number;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly name: string;
      readonly pricing_model: string;
      readonly project_workstreams: ReadonlyArray<{
        readonly workstream: {
          readonly id: number;
        };
      }>;
      readonly tags_computed: any | null | undefined;
      readonly team: {
        readonly id: number;
        readonly name: string;
      } | null | undefined;
    }>;
  };
  readonly favourite_projects: any;
  readonly id: number;
  readonly view: {
    readonly id: number;
    readonly project_filters: any;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectManagement_user">;
  readonly " $fragmentType": "ProjectPlannerList_user";
};
export type ProjectPlannerList_user$key = {
  readonly " $data"?: ProjectPlannerList_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPlannerList_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "teams",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_select_values",
  "kind": "LinkedField",
  "name": "custom_select_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_option_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_select_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_text_values",
  "kind": "LinkedField",
  "name": "custom_text_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_text_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_checkbox_values",
  "kind": "LinkedField",
  "name": "custom_checkbox_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_checkbox_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "custom_date_values",
  "kind": "LinkedField",
  "name": "custom_date_values",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custom_date_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user_id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "todaysDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPlannerList_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_projects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "views",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_placeholder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "project_memberships",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "project_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "person_requests",
              "kind": "LinkedField",
              "name": "person_requests",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "competencies",
              "kind": "LinkedField",
              "name": "competencies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "skills",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "current_contract",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "job_title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "people_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "projectsFilter"
            }
          ],
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pricing_model",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags_computed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "clients",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_managers",
              "kind": "LinkedField",
              "name": "managers",
              "plural": true,
              "selections": (v9/*: any*/),
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "project_members",
              "kind": "LinkedField",
              "name": "members",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "person_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workstream_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_workstreams",
              "kind": "LinkedField",
              "name": "project_workstreams",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "workstreams",
                  "kind": "LinkedField",
                  "name": "workstream",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        },
        {
          "kind": "Variable",
          "name": "todaysDate",
          "variableName": "todaysDate"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectManagement_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "134ed1d479fddff0b973212c065365d0";

export default node;
