import React from "react"
import { components } from "react-select"

import styles from "./SelectClient.module.css"

import ProjectClientIcon from "~/common/ProjectClientIcon"
import Select, { ReactSelectProps } from "~/common/Select"
import { Plus } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"

type Client = {
  value: number | string
  label: string
  imageKey?: string
  internal?: boolean
  real_client?: boolean
  website?: string
  __isNew__?: boolean
}

type Props = {
  options: Client[]
} & ReactSelectProps

const Option = (optionProps) => {
  const { data } = optionProps

  const isNew = data.__isNew__

  return (
    <components.Option {...optionProps} className={isNew && styles.newClient}>
      <div className={styles.clientSelect}>
        {isNew ? (
          <div className={styles.iconWrapper}>
            <Plus />
          </div>
        ) : (
          <ProjectClientIcon clientLogoOnly client={data} size={25} />
        )}
        <span>{data.label}</span>
      </div>
    </components.Option>
  )
}

const Control = (props) => {
  const selectedValue = props.getValue()

  return (
    <components.Control {...props}>
      <ProjectClientIcon
        clientLogoOnly
        client={selectedValue[0]}
        size={25}
        style={{ marginLeft: 10 }}
      />
      {props.children}
    </components.Control>
  )
}

const SelectClient = (props: Props) => {
  const {
    onChange,
    label = "",
    name = "project-client",
    id = "project-client",
    options,
    value,
    isCreatable = true,
    ...rest
  } = props

  const { can, subject } = usePermissions()
  const canCreateClient = isCreatable && can("create", subject("Client"))
  const placeholderText = `Select client${canCreateClient ? " or type to create new" : ""}`

  // Hide the default project template client
  const client = value as Client
  const currentClient = client?.real_client || client?.__isNew__ ? client : ""

  return (
    <Select
      name={name}
      id={id}
      defaultValue={currentClient}
      value={currentClient}
      onChange={onChange}
      label={label}
      placeholder={placeholderText}
      components={{ Option, Control }}
      isCreatable={canCreateClient}
      options={options}
      noOptionsMessage={() => {
        if (!canCreateClient) {
          return null
        }

        return (
          <div>
            Type to create client <br /> E.g Google, Internal, Marketing
            Department
          </div>
        )
      }}
      {...rest}
    />
  )
}

export default SelectClient
