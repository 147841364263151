import { hoursToMinutes } from "date-fns"
import * as React from "react"
import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import styles from "./NonWorkingDayInput.module.css"

import { NonWorkingDayInput_account$key } from "./__generated__/NonWorkingDayInput_account.graphql"

import { useAppSelector } from "~/hooks/redux"

import Duration from "../Duration"

import { EffortSelect } from "./EffortSelect"
import { FTEInput } from "./FTEInput"

const AVAILABLE_UNIT_OPTIONS = ["fullTimeEquivalent", "hoursPerDay"]
const DEFAULT_UNIT = "hoursPerDay"

type Props = {
  account: NonWorkingDayInput_account$key
  isoDay: number
  minutesPerDay: number
  totalForPercentage: number
  maxMinutes?: number
  onChange: (minutes: number, error: boolean) => void
}

export const NonWorkingDayInput = ({
  isoDay,
  minutesPerDay,
  totalForPercentage,
  maxMinutes = 24 * hoursToMinutes(1),
  onChange,
  ...props
}: Props) => {
  const label = match(isoDay)
    .with(7, () => "Sunday")
    .with(6, () => "Saturday")
    .otherwise(() => "Public Holiday")

  const account = useFragment(
    graphql`
      fragment NonWorkingDayInput_account on accounts {
        ...FTEInput_account
      }
    `,
    props.account,
  )

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)

  const unit = AVAILABLE_UNIT_OPTIONS.includes(effortDisplayUnit)
    ? effortDisplayUnit
    : DEFAULT_UNIT

  return (
    <>
      <label htmlFor="effort">{label}</label>
      <div className={styles.inputContainer}>
        {match(effortDisplayUnit)
          .with("fullTimeEquivalent", () => (
            <div>
              <FTEInput
                account={account}
                minutesPerDay={minutesPerDay}
                onChange={onChange}
                maxMinutes={maxMinutes}
              />
            </div>
          ))
          .otherwise(() => (
            <Duration
              handleMinutesPerDay={onChange}
              minutesPerDay={minutesPerDay}
              autoFocus
              totalForPercentage={totalForPercentage}
              maxMinutes={maxMinutes}
              allowZero
            />
          ))}
        <div>
          <EffortSelect
            effortDisplayUnit={unit}
            availableOptions={AVAILABLE_UNIT_OPTIONS}
          />
        </div>
      </div>
    </>
  )
}
