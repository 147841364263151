import { Icon } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { getISODay } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import styles from "./BasicPill.module.css"

import { LIGHT_VIOLET, SKY_BLUE, findColor } from "~/helpers/colors"
import { getMergedHolidays, getMergedTimeOffs } from "~/helpers/holiday-helpers"
import { isAssignment as isAssignmentType, isTimeOff } from "~/types/helpers"

import { getPillDetails } from "~/common/Pill/PillHelpers"

import { SET_HIGHLIGHTED_ITEM_DATA } from "~/GlobalState"
import { ReduxState } from "~/rootReducer"

import { toggledEffortDisplayUnit } from "../../../display_units/effortDisplayUnitSlice"
import { useAppSelector } from "../../../hooks/redux"

import Overbookings from "./Overbookings"

type TypedItem =
  | {
      type: "assignment"
      item: Assignment
    }
  | {
      type: "timeOff"
      item: TimeOff
    }

type Props = {
  typedItem: TypedItem
  person: any
  wrappedPill?: boolean
  calendarStartDate: Date
  calendarEndDate: Date
  dayWidth: number
  isPlaceholder?: boolean
  isTentative?: boolean
  isNonBillable?: boolean
  isSelected?: boolean
  isFloating?: boolean
  isConflict?: boolean
  isDisabled?: boolean
  isHighlighted?: boolean
  defaultPhaseId?: number
  phases?: Phase[]
  basicPill?: boolean
  onMenuClick?: (e: React.MouseEvent) => void
  calendarWeekendsExpanded: boolean
  defaultFullTimeMinutes: number
  contractMinutesPerDay?: number
  pillLabelOffset?: number
}

type Assignment = {
  id: number
  project_id: number
  role_id: number
  phase_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_placeholder: boolean
  is_template: boolean
  note: string
  is_billable: boolean
  non_working_day: boolean
  total_minutes: number
  workstream_id: number
}

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
  note: string
  leave_type: string
  non_working_day?: boolean
  minutes_per_day: number
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
  }>
}

type Phase = {
  id: number
  color: string
  name: string
}

export const getAssignmentPillColours = (
  phaseId: number,
  phases: Phase[],
  isTentative: boolean,
) => {
  const assignmentPhase =
    phaseId && phases.length && phases.find((phase) => phase.id === phaseId)

  if (!assignmentPhase) {
    return isTentative ? SKY_BLUE : LIGHT_VIOLET
  }

  return findColor(assignmentPhase?.color)
}

const BasicPill = (props: Props) => {
  const {
    typedItem,
    person,
    defaultPhaseId,
    phases,
    wrappedPill,
    onMenuClick,
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    isPlaceholder,
    isTentative,
    isSelected,
    isFloating,
    isConflict,
    isDisabled,
    basicPill,
    isHighlighted = false,
    calendarWeekendsExpanded,
    defaultFullTimeMinutes,
    contractMinutesPerDay,
    pillLabelOffset = 0,
  } = props

  const { type, item } = typedItem

  const assignment = type === "assignment" ? item : undefined

  const isConsistentTimeOffEnabled = useFeature("consistent_time_off")
  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const hoursPerWeekMode = effortDisplayUnit === "hoursPerWeek"

  const affectedTimeOffs = assignment?.is_template
    ? []
    : person.time_offs.filter((to) => to.id !== item.id)

  const mergedTimeOffs = isConsistentTimeOffEnabled
    ? getMergedTimeOffs(affectedTimeOffs, item.start_date, item.end_date)
    : getMergedHolidays(affectedTimeOffs)

  const handleMenuClick = (e) => {
    if (onMenuClick) {
      onMenuClick(e)
    }
  }

  const {
    visibleDays,
    offset,
    width,
    pillExtendsBeforeCalendar,
    pillExtendsPastCalendar,
    Label,
    LabelHover,
    showLabel,
    pillWidthPx,
  } = getPillDetails({
    typedItem,
    calendarStartDate,
    calendarEndDate,
    dayWidth,
    calendarWeekendsExpanded,
    hoursPerWeekMode,
    isConflict,
    mergedTimeOffs,
    defaultFullTimeMinutes,
    contractMinutesPerDay,
  })

  const weekendWidth = dayWidth * 2 // 2 days
  const pillContentContainerWidth =
    pillExtendsPastCalendar && calendarWeekendsExpanded
      ? pillWidthPx - weekendWidth
      : pillWidthPx

  const startDate = dateHelpers.parseRunnDate(item.start_date)
  const periods = useSelector((state: ReduxState) => state.calendar.periods)

  let maxPillLabelWidth = (dayWidth * visibleDays - pillLabelOffset) * 0.8 // Max is 80% of pillWidth

  if (
    calendarWeekendsExpanded &&
    !pillExtendsBeforeCalendar &&
    isFloating &&
    periods === "days" &&
    !isTimeOff(item)
  ) {
    // This ensures that the pill label resizes based on it's position
    // of the week, in case weekends are on
    const numWeekDays = 5
    const weekDayPosition = numWeekDays - getISODay(startDate) + 1
    maxPillLabelWidth = (dayWidth * weekDayPosition - pillLabelOffset) * 0.8 // Max is 80% of pillWidth
  }

  const showLabelText = isSelected
    ? pillContentContainerWidth - pillLabelOffset > maxPillLabelWidth + 18 // 18 is the width of the menu dots
    : showLabel

  const classes = cc({
    [styles.basicPill]: true,
    [styles.wrappedPill]: wrappedPill,
    [styles.timeOff]: type === "timeOff",
    [styles.holidayTimeOff]:
      type === "timeOff" && item.leave_type === "holiday",
    [styles.rosteredTimeOff]:
      type === "timeOff" && item.leave_type === "rostered-off",
    [styles.externalTimeOff]:
      type === "timeOff" && item.ext_time_off_links.length > 0,
    [styles.disabled]: isDisabled,
    [styles.selected]: isSelected,
    [styles.floating]: isFloating,
    [styles.conflict]: isConflict,
    [styles.offscreenPillLeft]: pillExtendsBeforeCalendar,
    [styles.offscreenPillRight]: pillExtendsPastCalendar,
    [styles.nonWorkingDay]: item.non_working_day,
    [styles.highlight]: isHighlighted,
  })

  const assignmentPillColours = getAssignmentPillColours(
    defaultPhaseId,
    phases,
    isTentative,
  )

  const label = isSelected || isFloating ? LabelHover : Label

  const dispatch = useDispatch()

  const handleMouseOver = () => {
    dispatch({
      type: SET_HIGHLIGHTED_ITEM_DATA,
      payload: { ...item, projectConfirmed: !isTentative, type },
    })
  }

  const handleMouseLeave = () => {
    if (!isSelected) {
      dispatch({ type: SET_HIGHLIGHTED_ITEM_DATA, payload: null })
    }
  }

  const toggleHoursPerWeek = () => {
    if (basicPill && type === "assignment") {
      dispatch(toggledEffortDisplayUnit())
    }
  }

  let pillBackground = ""

  if (type === "assignment") {
    pillBackground = isSelected
      ? assignmentPillColours.darker
      : assignmentPillColours.color
  }

  if (isPlaceholder) {
    pillBackground = isSelected
      ? assignmentPillColours.lightHover
      : assignmentPillColours.lighter
  }

  return (
    <div
      style={{
        background: pillBackground,
        border: isPlaceholder
          ? `1px dashed ${assignmentPillColours.color}`
          : "",
        opacity: isFloating ? "0.8" : "",
        left: `${wrappedPill ? 0 : offset}%`,
        width: `${wrappedPill ? 100 : width}%`,
      }}
      className={classes}
      data-component="BasicPill"
      data-is-floating={isFloating}
      data-is-highlighted={isHighlighted}
      data-id={item.id}
      data-start-date={item.start_date}
      data-end-date={item.end_date}
      data-phase-id={(type === "assignment" && item.phase_id) ?? ""}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={styles.pillContentContainer}
        style={{ width: `${pillContentContainerWidth}px` }}
      >
        {/* container is important as it handles positions when weekends are on */}
        {showLabelText ? (
          <div
            onClick={toggleHoursPerWeek}
            className={styles.pillLabel}
            style={{
              background:
                type === "assignment"
                  ? isPlaceholder
                    ? "#fff"
                    : assignmentPillColours.pillLabel
                  : "",
              color: isPlaceholder ? assignmentPillColours.pillLabel : "#fff",
              border: isPlaceholder
                ? `1px solid ${assignmentPillColours.color}`
                : "",
              zIndex: isSelected ? 4 : 0,
              maxWidth: `${maxPillLabelWidth}px`,
              left: `${pillLabelOffset}px`,
            }}
          >
            {label}
          </div>
        ) : (
          <div data-comment="for flex space-between" />
        )}
        <div
          className={styles.menu}
          style={{
            background:
              type === "assignment"
                ? isPlaceholder
                  ? "#fff"
                  : assignmentPillColours.pillLabel
                : "",
            color: isPlaceholder ? assignmentPillColours.pillLabel : "#fff",
          }}
          onMouseDown={handleMenuClick}
        >
          <Icon icon="more" data-test="context-menu-dots" size={10} />
        </div>
      </div>
      {isAssignmentType(item) &&
        type === "assignment" &&
        !person.is_placeholder && (
          <Overbookings
            assignment={item}
            person={person}
            calendarStartDate={calendarStartDate}
            calendarEndDate={calendarEndDate}
            visibleDays={visibleDays}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
          />
        )}
    </div>
  )
}

export default BasicPill
