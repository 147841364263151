/**
 * @generated SignedSource<<4085aa58f4498bdc2d93393cc1d97895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderProjectRow_account$data = {
  readonly custom_checkbox_types: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_date_types: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_select_types: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_text_types: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerCalendarRow_account">;
  readonly " $fragmentType": "PlaceholderProjectRow_account";
};
export type PlaceholderProjectRow_account$key = {
  readonly " $data"?: PlaceholderProjectRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderProjectRow_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderProjectRow_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerCalendarRow_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "018876e9c25e1edae6e5a1d76f2e008b";

export default node;
