/**
 * @generated SignedSource<<a9cf4bfc86114fef0fd516f323132713>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonTagSelector_user$data = {
  readonly account: {
    readonly id: number;
    readonly people_tags: ReadonlyArray<{
      readonly archived: boolean;
      readonly id: number;
      readonly model: string;
      readonly name: string;
    }>;
  };
  readonly id: number;
  readonly " $fragmentType": "PersonTagSelector_user";
};
export type PersonTagSelector_user$key = {
  readonly " $data"?: PersonTagSelector_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonTagSelector_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonTagSelector_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "people_tags",
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "model": {
                  "_eq": "person"
                }
              }
            }
          ],
          "concreteType": "tags",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "model",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archived",
              "storageKey": null
            }
          ],
          "storageKey": "tags(where:{\"model\":{\"_eq\":\"person\"}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "0f880554a542742a26a8e3041dcff9fc";

export default node;
