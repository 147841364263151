import { Icon, Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { isMonday, isToday, isWeekend } from "date-fns"
import React from "react"

import styles from "./Cell.module.css"

import GLOBAL from "~/GLOBALVARS"

import CellDivider from "./CellDivider"

type Props = {
  day: Date
  extraClassName?: string
  style?: React.CSSProperties
  setHighlightedCellData?: any
  handleMouseDown?: (event: any, day: Date) => void
  type: "timeOff" | "assignment" | "placeholder" | "unconfirmed"
  calendarWeekendsExpanded: boolean
  disabled: boolean
  isStartOfCalendar: boolean
  isInDaysCalendarView?: boolean
  holidayOverlapsTimeOff?: boolean
  scheduledHolidays?: {
    note?: string
  }[]
}

export const DragToCreateCell = (props: Props) => {
  const {
    day,
    extraClassName,
    style,
    type,
    setHighlightedCellData,
    calendarWeekendsExpanded,
    disabled,
    isStartOfCalendar,
    isInDaysCalendarView,
    holidayOverlapsTimeOff,
    scheduledHolidays,
    handleMouseDown = () => void 0,
  } = props
  const formattedDate = dateHelpers.formatToRunnDate(day)

  const cellIsHoliday = Boolean((scheduledHolidays ?? []).length)
  const cellIsWeekend = isWeekend(day)
  const cellIsMonday = isMonday(day)
  const cellIsTimeOff = type === "timeOff"
  const cellIsDisabled =
    disabled ||
    (cellIsTimeOff && cellIsWeekend) ||
    (cellIsHoliday && cellIsTimeOff)

  const handleMouseEnter = () => {
    if (cellIsDisabled) {
      return
    }

    if (cellIsWeekend && cellIsTimeOff) {
      setHighlightedCellData({ type, day })
      return
    }

    if (!GLOBAL.INTERACTING_WITH_PILL) {
      // cleaner movement of top highlight when resizing an assignment
      setHighlightedCellData({ type, day })
    }
  }

  const handleMouseLeave = () => {
    setHighlightedCellData(null)
  }

  const onMouseDown = (e) => {
    if (cellIsDisabled) {
      return
    }
    handleMouseDown(e, day)
  }

  const renderDivider = () => {
    if (!cellIsMonday || isStartOfCalendar || holidayOverlapsTimeOff) {
      return null
    }

    return (
      <CellDivider
        onTopOfPills={
          (cellIsWeekend || cellIsMonday) && calendarWeekendsExpanded
        }
        bold={cellIsMonday && !calendarWeekendsExpanded}
      />
    )
  }

  return (
    <div
      data-test={`calendar-cell-${formattedDate}`}
      className={cc([
        styles.calendarCell,
        extraClassName,
        {
          [styles.today]: isToday(day),
          [styles.nonWorkingDay]:
            cellIsWeekend || (isInDaysCalendarView && cellIsHoliday),
          [styles.disabled]: cellIsDisabled,
          [styles.holidayOverlapsTimeOff]:
            isInDaysCalendarView && holidayOverlapsTimeOff,
        },
      ])}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={onMouseDown}
      style={style}
    >
      {renderDivider()}
      <Tooltip
        disabled={!(cellIsHoliday || cellIsWeekend) || cellIsDisabled}
        hoverOpenDelay={100}
        placement="top"
        content={
          <>
            Schedule on a{" "}
            {(scheduledHolidays ?? []).length ? (
              <>
                public holiday
                {(scheduledHolidays ?? []).map((h) => (
                  <span className={styles.holidayDetails}>
                    <Icon icon="calendar" size={12} />
                    {h.note}
                  </span>
                ))}
              </>
            ) : (
              "non-working day"
            )}
          </>
        }
        className={styles.tooltip}
      >
        <div
          className={cc([
            styles.plusIcon,
            {
              [styles.timeOff]: cellIsTimeOff,
            },
          ])}
        />
      </Tooltip>
    </div>
  )
}

export default DragToCreateCell
