/**
 * @generated SignedSource<<10e55914e089bdbf466207e7e1ea4bb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingPeople_user$data = {
  readonly account: {
    readonly id: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CreateBulkPeopleForm_user">;
  readonly " $fragmentType": "OnboardingPeople_user";
};
export type OnboardingPeople_user$key = {
  readonly " $data"?: OnboardingPeople_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingPeople_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingPeople_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateBulkPeopleForm_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "93f115bbfbaad4303ec99c535ed287e5";

export default node;
