/**
 * @generated SignedSource<<dfdbf1665346c82ada9382c16d0da37d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleExternalProjectMemberForm_viewer$data = {
  readonly account: {
    readonly id: number;
    readonly people: ReadonlyArray<{
      readonly active: boolean;
      readonly current_contract: ReadonlyArray<{
        readonly id: number;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
      }> | null | undefined;
      readonly email: string | null | undefined;
      readonly first_name: string;
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly last_name: string;
    }>;
    readonly roles: ReadonlyArray<{
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
  };
  readonly " $fragmentType": "SingleExternalProjectMemberForm_viewer";
};
export type SingleExternalProjectMemberForm_viewer$key = {
  readonly " $data"?: SingleExternalProjectMemberForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SingleExternalProjectMemberForm_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleExternalProjectMemberForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_placeholder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "contracts",
              "kind": "LinkedField",
              "name": "current_contract",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "roles",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "d307449c37c0b08d6d8163cb27595eeb";

export default node;
