import * as relayids from "~/helpers/relayids"

import * as computedFields from "./common/computed-fields"
import * as relationships from "./common/relationships"

import type { Model } from "~/subscriptions/AuditLogSubscription/types"

type TimeOff = {
  id: number
  person_id: number
  start_date: string
  end_date: string
}

const timeOffSubscription: Model<TimeOff> = {
  tableName: "time_offs",
  relationships: [relationships.person("time_offs")],
  getDataId: (row) => relayids.timeOffs.encode(row.id),
  transformFields: {
    id: computedFields.id("timeOffs"),
    start_date: computedFields.startDate,
    end_date: computedFields.endDate,
  },
  postInsert: ($recordProxy) => {
    $recordProxy.setLinkedRecords([], "ext_time_off_links", {
      where: { active: { _eq: true } },
    })
  },
}

export default timeOffSubscription
