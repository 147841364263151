import React, { ComponentType, PropsWithChildren, ReactElement } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { P, match } from "ts-pattern"

import { reportError } from "~/helpers/error-helpers"

type Props = PropsWithChildren<{
  fallback?: ComponentType<FallbackProps>
  fallbackRender?: (props: FallbackProps) => ReactElement
}>

const DefaultFallbackRender = () => <div />

export const FallBackErrorBoundary = ({ children, ...props }: Props) => {
  const onError = (error, info) => void reportError(error, info)

  return match(props)
    .with({ fallbackRender: P.nonNullable }, (p) => {
      return (
        <ErrorBoundary onError={onError} fallbackRender={p.fallbackRender}>
          {children}
        </ErrorBoundary>
      )
    })
    .with({ fallback: P.nonNullable }, (p) => {
      return (
        <ErrorBoundary onError={onError} FallbackComponent={p.fallback}>
          {children}
        </ErrorBoundary>
      )
    })
    .otherwise(() => {
      return (
        <ErrorBoundary onError={onError} fallbackRender={DefaultFallbackRender}>
          {children}
        </ErrorBoundary>
      )
    })
}
