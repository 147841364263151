import { Icon } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"

import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import type {
  FilterComponentProps,
  FilterValues,
} from "~/common/SuperSearch/types"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

import {
  FilterBlock,
  useAutoRemoveMissingListValues,
  useSelectedListOptions,
  useTags,
} from "./common"

type BaseProps = {
  user: SuperSearch_user$data
  filter: fe.filters.FilterProjectTagId
}

type Props = FilterComponentProps & BaseProps

const useValues = (props: BaseProps): FilterValues => {
  const { user, filter } = props

  const availableOptions = useTags({
    model: "project",
    user,
  })
  const selectedOptions = useSelectedListOptions({
    filter,
    availableOptions,
  })

  return {
    name: "Project Tags",
    availableOptions,
    selectedOptions,
  }
}

const Component = (props: Props) => {
  const {
    user,
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (list: string[]) => {
    onChange(path, fe.filters.projectTagId({ list }))
  }

  const handleRemove = () => onRemove(path)

  const { availableOptions, selectedOptions, name } = useValues({
    user,
    filter,
  })

  useAutoRemoveMissingListValues({
    filter,
    selectedOptions,
    onChange: handleChange,
  })

  return (
    <FilterBlock
      icon={<Icon icon="tag" />}
      label={name}
      filterLabelPlural="tags"
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { filter, user } = props

  const { selectedOptions, name } = useValues({
    user,
    filter,
  })

  return <FilterBlockReadonly name={name} selectedOptions={selectedOptions} />
}

export const ProjectTagId = {
  Component,
  ReadonlyComponent,
}
