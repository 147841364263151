import { Tooltip, TooltipProps } from "@blueprintjs/core"
import React from "react"

import styles from "./TooltipList.module.css"

type Props = {
  title: string
  content: React.ReactNode
  children?: React.ReactNode
  tooltipProps?: Omit<TooltipProps, "content">
}

export const TooltipListLabel = (props: {
  title: string
  icon?: React.ReactNode
}) => {
  const { title, icon } = props
  return (
    <div className={styles.label}>
      <span>{title}</span>
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
    </div>
  )
}

export const TooltipList = (props: Props) => {
  const { title, content, children, tooltipProps } = props
  if (!React.Children.count(children)) {
    return null
  }

  return (
    <Tooltip
      placement="right"
      content={
        <div className={styles.tooltip}>
          <b>{title}: </b>
          <div className={styles.listWrapper}>{content}</div>
        </div>
      }
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  )
}

export default TooltipList
