/**
 * @generated SignedSource<<36dfb93b2ed6cb8697e642086b358dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsSection_account$data = {
  readonly default_full_time_minutes: number;
  readonly people: ReadonlyArray<{
    readonly assignments: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly person_id: number;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly first_name: string;
    readonly id: number;
    readonly last_name: string;
  }>;
  readonly roles: ReadonlyArray<{
    readonly id: number;
    readonly name: string | null | undefined;
  }>;
  readonly workstreams: ReadonlyArray<{
    readonly archived: boolean;
    readonly id: number;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_account">;
  readonly " $fragmentType": "ProjectDetailsSection_account";
};
export type ProjectDetailsSection_account$key = {
  readonly " $data"?: ProjectDetailsSection_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsSection_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsSection_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "_gte",
                      "variableName": "plannerStartDate"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "end_date_iso"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "assignments",
          "kind": "LinkedField",
          "name": "assignments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "project_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workstream_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "person_id",
              "storageKey": null
            },
            {
              "alias": "start_date",
              "args": null,
              "kind": "ScalarField",
              "name": "start_date_runn",
              "storageKey": null
            },
            {
              "alias": "end_date",
              "args": null,
              "kind": "ScalarField",
              "name": "end_date_runn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "roles",
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "workstreams",
      "kind": "LinkedField",
      "name": "workstreams",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "peopleFilter",
          "variableName": "peopleFilter"
        },
        {
          "kind": "Variable",
          "name": "plannerStartDate",
          "variableName": "plannerStartDate"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectDetails_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "f654b96ebc8a7c762c8bc71a2b21b7f0";

export default node;
