import React from "react"

import styles from "./CustomTooltip.module.css"

import {
  CustomCheckboxType,
  CustomCheckboxValue,
} from "~/helpers/custom-field-helpers"

type Props = {
  type: CustomCheckboxType
  value: CustomCheckboxValue
}

const CustomTooltipCheckbox = ({ type, value }: Props) => {
  if (value == null) {
    return null
  }

  return (
    <div className={styles.fieldContainer}>
      <span className={styles.title}>{type.name}</span>
      <div className={styles.itemContainer}>{value.value ? "Yes" : "No"}</div>
    </div>
  )
}

export default CustomTooltipCheckbox
