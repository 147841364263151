import cc from "classcat"
import React from "react"
import { SingleValueProps, components } from "react-select"

import styles from "./SelectRoleSingleValue.module.css"

import { ArchiveIcon } from "./react-icons"

const SelectRoleSingleValue = ({
  children,
  ...props
}: SingleValueProps<{
  archived: boolean
  disabled: boolean
}>) => {
  return (
    <components.SingleValue
      {...props}
      className={cc({
        [styles.withIcon]: props.data.archived,
        [styles.disabled]: props.data.disabled,
      })}
    >
      <span>{props.data.archived && <ArchiveIcon />}</span>
      {children}
    </components.SingleValue>
  )
}

export default SelectRoleSingleValue
