import cc from "classcat"
import React from "react"
import isDeeplyEqual from "react-fast-compare"
import { useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectLine.module.css"

import { ProjectLine_project$key } from "./__generated__/ProjectLine_project.graphql"

import { getItemPositionWithinRange } from "~/helpers/CalendarHelper"

import { ReduxState } from "~/rootReducer"

type Props = {
  project: ProjectLine_project$key
  disabled?: boolean
}

const ProjectLine = (props: Props) => {
  const project = useFragment(
    graphql`
      fragment ProjectLine_project on projects {
        id
        confirmed
        pricing_model
        calc_start_date
        calc_end_date
      }
    `,
    props.project,
  )
  const { disabled } = props
  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )
  const {
    calendarStartDate,
    calendarEndDate,
    calStartNum,
    calEndNum,
    calendarWeekendsExpanded,
  } = calendar

  const lineStartDate = project.calc_start_date
  const lineEndDate = project.calc_end_date

  if (!lineStartDate || !lineEndDate) {
    return null
  }
  if (Number(lineStartDate) > calEndNum || Number(lineEndDate) < calStartNum) {
    return null
  }

  const itemPositionStyles = getItemPositionWithinRange(
    calendarStartDate,
    calendarEndDate,
    { start_date: lineStartDate, end_date: lineEndDate },
    calendarWeekendsExpanded,
  )

  const projectLineClasses = cc([
    styles.projectLine,
    {
      [styles.tentative]: !project.confirmed,
      [styles.nonBillable]: project.pricing_model === "nb",
    },
  ])

  return (
    <div
      className={cc([
        styles.projectLineWrapper,
        {
          [styles.disabled]: disabled,
        },
      ])}
    >
      <div className={projectLineClasses} style={itemPositionStyles} />
    </div>
  )
}

export default ProjectLine
