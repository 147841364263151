import * as fe from "@runn/filter-engine"
import React from "react"

import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import { Badge } from "./common/Badge"
import useManagers from "./common/useManagers"
import type {
  FilterComponentProps,
  FilterValues,
} from "~/common/SuperSearch/types"
import Icon from "~/common/react-icons/squareFace"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

import { FilterBlock, useSelectedListOptions } from "./common"

type BaseProps = {
  user: SuperSearch_user$data
  filter: fe.filters.FilterProjectManagerId
}
type Props = FilterComponentProps & BaseProps

const useValues = (props: BaseProps): FilterValues => {
  const { user, filter } = props

  const availableOptions = useManagers({ type: "project", user })
  const selectedOptions = useSelectedListOptions({
    filter,
    availableOptions,
  })

  return {
    name: "Manager",
    availableOptions,
    selectedOptions,
  }
}
const Component = (props: Props) => {
  const {
    user,
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const { availableOptions, selectedOptions, name } = useValues({
    user,
    filter,
  })

  const handleChange = (list: number[]) => {
    return onChange(path, fe.filters.projectManagerId({ list }))
  }

  const handleRemove = () => onRemove(path)

  return (
    <FilterBlock
      icon={<Icon />}
      label={
        <>
          {name}
          <Badge>Projects</Badge>
        </>
      }
      filterLabelPlural="managers"
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { user, filter } = props

  const { selectedOptions, name } = useValues({ user, filter })

  return <FilterBlockReadonly name={name} selectedOptions={selectedOptions} />
}

export const ProjectManagerId = {
  Component,
  ReadonlyComponent,
}
