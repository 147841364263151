import { Switch } from "@blueprintjs/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import styles from "./ViewControls.module.css"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { SET_SHOW_PROJECT_PHASES } from "~/Planner/Planner.actions"
import { setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

const PhasesToggle = () => {
  const showProjectPhases = useSelector(
    (state: ReduxState) => state.planner.showProjectPhases,
  )
  const dispatch = useDispatch()
  const {
    projects: { isOverflow },
  } = useQueryLimit()

  const togglePhases = () => {
    dispatch({
      type: SET_SHOW_PROJECT_PHASES,
      payload: !showProjectPhases,
    })
    setSetting("showProjectPhases", !showProjectPhases)
  }

  return (
    <div className={styles.toggle}>
      <Switch
        style={isOverflow ? { color: "var(--winter)" } : {}}
        checked={showProjectPhases}
        disabled={isOverflow}
        onChange={togglePhases}
        label="Always Show Phases"
        alignIndicator="left"
      />
    </div>
  )
}

export default PhasesToggle
