/**
 * @generated SignedSource<<d68d7a08d3194395f0b4bef8f1a66f66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroupMembersDisplay_project$data = {
  readonly ext_project_links: ReadonlyArray<{
    readonly ext_project_id: number;
    readonly project_id: number;
  }>;
  readonly id: number;
  readonly is_template: boolean;
  readonly name: string;
  readonly project_workstreams: ReadonlyArray<{
    readonly workstream_id: number;
  }>;
  readonly " $fragmentType": "ProjectGroupMembersDisplay_project";
};
export type ProjectGroupMembersDisplay_project$key = {
  readonly " $data"?: ProjectGroupMembersDisplay_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupMembersDisplay_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroupMembersDisplay_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "active": {
              "_eq": true
            }
          }
        }
      ],
      "concreteType": "ext_project_link",
      "kind": "LinkedField",
      "name": "ext_project_links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ext_project_id",
          "storageKey": null
        }
      ],
      "storageKey": "ext_project_links(where:{\"active\":{\"_eq\":true}})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_workstreams",
      "kind": "LinkedField",
      "name": "project_workstreams",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "7e5e8a8ee346d4791806e82076ae69f2";

export default node;
