import { Tooltip } from "@blueprintjs/core"
import { dateHelpers, toFullTimeEquivalentEffort } from "@runn/calculations"
import { format as formatDate } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import { P, match } from "ts-pattern"

import styles from "./PillActions.module.css"

import DurationHelper, { formatDuration } from "~/helpers/DurationHelper"
import { getFirstActiveExtTimeOff } from "~/helpers/ext-link"
import { formatName } from "~/helpers/person"

import { useIntegrationViaHasuraContext } from "~/queries/User"

import { useAppSelector } from "../../hooks/redux"

// @ts-ignore
const windowDefaultMinutes = window.account_full_time_minutes

type TimeOff = {
  readonly start_date: string
  readonly end_date: string
  readonly leave_type: string
  readonly note: string | undefined
  readonly minutes_per_day?: number
  readonly holiday?: {
    readonly id: number
    readonly name: string
    readonly holidays_group: {
      readonly id: number
      readonly name: string
    }
  } | null
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
    readonly external: {
      readonly integration_id: number
    }
  }>
  overlappingHolidays?: TimeOff[]
}

type Assignment = {
  readonly id: number
  readonly start_date: string
  readonly end_date: string
  readonly is_billable: boolean
  readonly minutes_per_day: number
  readonly non_working_day: boolean
  readonly note: string | undefined
  readonly phase_id?: number | undefined
  readonly project_id: number
}

type Phase = {
  readonly id: number
  readonly color: string
  readonly name: string
}

type TypedItem =
  | {
      readonly type: "assignment"
      readonly item: Assignment
    }
  | {
      readonly type: "timeOff"
      readonly item: TimeOff
    }

type ExtTernalTimeOffTooltipContentProps = {
  extTimeOff: TimeOff["ext_time_off_links"][number]["external"]
}

const ExternalTimeOffTooltipContent = (
  props: ExtTernalTimeOffTooltipContentProps,
) => {
  const { extTimeOff } = props
  const integrationId = extTimeOff.integration_id
  const integration = useIntegrationViaHasuraContext(integrationId)

  return (
    <div style={{ maxWidth: "300px" }}>
      <b>External Time Off: </b>
      Changes to this time off can only be made via{" "}
      {integration.integration_service.name}.
    </div>
  )
}

type TooltipProps = {
  typedItem: TypedItem
  days: number
  phases: Phase[]
  overlappingTimeOff: boolean
  pillDisabledMessage: string
  isOutOfContract: boolean
  personName: string
  contractMinutesPerDay?: number
}

const timeOffLabel = () =>
  "Assignment conflicting with a time off. This assignment will still count down your budget and stats. Please manually resolve conflict."
const outOfContractLabel = (personName: string) =>
  `Assignment scheduled while ${personName} has no contract. To resolve this conflict, please delete the assignment or update their contract. If left unresolved, the assignment hours will still be included in the project.`
const timeOffAndOutOfContractLabel = (personName: string) =>
  `Assignment scheduled while ${personName} has time off and no contract. Please resolve conflict. If left unresolved, the assignment hours will still be included in the project.`

const TooltipContent = (props: TooltipProps) => {
  const {
    typedItem,
    days,
    phases,
    overlappingTimeOff,
    pillDisabledMessage,
    isOutOfContract,
    personName,
    contractMinutesPerDay,
  } = props

  const { type, item } = typedItem

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const totalEffortDisplayUnit = useAppSelector(
    (state) => state.displayUnit.totalEffort,
  )

  const startDate = formatDate(
    dateHelpers.parseRunnDate(item.start_date),
    "dd MMM",
  )
  const endDate = formatDate(dateHelpers.parseRunnDate(item.end_date), "dd MMM")

  const totalEffortDays = () =>
    type === "assignment" &&
    toFullTimeEquivalentEffort({
      minutesOfEffort: (item.minutes_per_day ?? 0) * days,
      fulltimeMinutesPerDay: windowDefaultMinutes,
    })

  const fteEffort = () =>
    type === "assignment" &&
    toFullTimeEquivalentEffort({
      minutesOfEffort: item.minutes_per_day ?? 0,
      fulltimeMinutesPerDay: windowDefaultMinutes,
    })

  const isNonWorkingDay = type === "assignment" && Boolean(item.non_working_day)

  const effortTotal =
    type === "assignment" &&
    match(totalEffortDisplayUnit)
      .with("hours", () => formatDuration(days * item.minutes_per_day))
      .with("days", () => `${totalEffortDays()}d`)
      .exhaustive()

  const effortUnit =
    type === "assignment" &&
    match({
      effortDisplayUnit,
      isNonWorkingDay,
    })
      .with({ isNonWorkingDay: true }, () => "")
      .with(
        { effortDisplayUnit: "hoursPerWeek" },
        () => ` @ ${formatDuration(item.minutes_per_day * 5)}/week`,
      )
      .with(
        { effortDisplayUnit: "hoursPerDay" },
        () => ` @ ${formatDuration(item.minutes_per_day)}/day`,
      )
      .with({ effortDisplayUnit: "fullTimeEquivalent" }, () => {
        return ` @ ${fteEffort()} FTE`
      })
      .with({ effortDisplayUnit: "capacityPercentage" }, () => {
        return ` @ ${Math.round(((item.minutes_per_day ?? 0) / (contractMinutesPerDay ?? windowDefaultMinutes)) * 100)}%`
      })
      .exhaustive()

  const assignmentPhaseName =
    type === "assignment" && item.phase_id
      ? phases.find((phase) => phase.id === item.phase_id)?.name || "No Phase"
      : "No Phase"

  const extTimeOff = type === "timeOff" && getFirstActiveExtTimeOff(item)

  const isSameDay = item.start_date === item.end_date
  const itemTimeOff = item as TimeOff

  const onMouseDown = (e: React.SyntheticEvent) => {
    // Let people click on tooltip without interaction with assignment
    e.stopPropagation()
  }

  const getLabelText = (selectedItem: TimeOff) => {
    return match(selectedItem)
      .with({ leave_type: "rostered-off" }, () => "Rostered Day Off")
      .with(
        { leave_type: "holiday" },
        (holidayTimeOff) => `${selectedItem.note} (Holiday)`,
      )
      .with({ minutes_per_day: P.nullish }, () => "Scheduled Leave")
      .otherwise(() => "Partial Scheduled Leave")
  }

  return (
    <div className="tooltip-prevent-click" onMouseDown={onMouseDown}>
      {type === "timeOff" && (
        <div>
          <b>{getLabelText(item)}</b>
        </div>
      )}
      <div>
        <b>Dates: </b>
        {isSameDay
          ? `${startDate} (1 day)`
          : `${startDate} - ${endDate} (${days} days)`}
      </div>
      {type === "timeOff" && itemTimeOff.minutes_per_day && (
        <>
          <div>
            <b>Hours per Day: </b>
            {DurationHelper.minutesToHours(itemTimeOff.minutes_per_day, true)}h
          </div>
          <div>
            <b>Total Hours: </b>
            {DurationHelper.minutesToHours(itemTimeOff.minutes_per_day, true) *
              days}
            h
          </div>
        </>
      )}
      {type !== "timeOff" && (
        <>
          <div>
            <b>Total Effort:</b> {effortTotal}
            {effortUnit}
          </div>
          {!!phases?.length && (
            <div>
              <b>Phase:</b> {assignmentPhaseName}
            </div>
          )}
        </>
      )}
      {item.note && itemTimeOff.leave_type !== "holiday" && (
        <div style={{ maxWidth: "200px" }}>
          <b>Notes:</b> {item.note}
        </div>
      )}
      {type !== "timeOff" && !item.is_billable && (
        <div>
          <b>Non-Billable Assignment</b>
        </div>
      )}
      {(overlappingTimeOff || isOutOfContract) && (
        <div style={{ maxWidth: "300px" }}>
          <b>Conflict: </b>
          {match({ overlappingTimeOff, isOutOfContract })
            .with({ overlappingTimeOff: true, isOutOfContract: true }, () =>
              timeOffAndOutOfContractLabel(personName),
            )
            .with({ overlappingTimeOff: true }, () => timeOffLabel())
            .with({ isOutOfContract: true }, () =>
              outOfContractLabel(personName),
            )
            .otherwise(() => null)}
        </div>
      )}
      {extTimeOff && <ExternalTimeOffTooltipContent extTimeOff={extTimeOff} />}
      {pillDisabledMessage && (
        <>
          <br />
          <div>{pillDisabledMessage}</div>
        </>
      )}
    </div>
  )
}

type ItemTooltipProps = {
  typedItem: TypedItem
  days: number
  phases?: Phase[]
  pillWidthPx: number
  overlappingTimeOff?: boolean
  isOutOfContract?: boolean
  pillDisabledMessage?: string
  children: React.ReactElement
  hideTooltip?: boolean
  forceDelayTime?: number
  person: { first_name: string; last_name: string }
  contractMinutesPerDay?: number
}

const ItemTooltip = (props: ItemTooltipProps) => {
  const {
    typedItem,
    days,
    phases,
    pillDisabledMessage,
    pillWidthPx,
    children,
    hideTooltip,
    forceDelayTime,
    isOutOfContract,
    person,
    contractMinutesPerDay,
  } = props

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const { item } = typedItem

  if (hideTooltip) {
    return children
  }

  const overlappingTimeOff =
    props.overlappingTimeOff && !isConsistentTimeOffEnabled

  const toolTipHoverDelay = forceDelayTime
    ? forceDelayTime
    : pillWidthPx < 50 ||
        item.note ||
        overlappingTimeOff ||
        Boolean(pillDisabledMessage)
      ? 500
      : 1000

  return (
    <Tooltip
      content={
        <TooltipContent
          typedItem={typedItem}
          days={days}
          phases={phases}
          overlappingTimeOff={overlappingTimeOff}
          pillDisabledMessage={pillDisabledMessage}
          isOutOfContract={isOutOfContract}
          personName={formatName(person.first_name, person.last_name)}
          contractMinutesPerDay={contractMinutesPerDay}
        />
      }
      hoverOpenDelay={toolTipHoverDelay}
      className={styles.assignmentToolTipContainer}
      targetTagName="div"
      interactionKind="hover"
      hoverCloseDelay={100}
      placement="top"
    >
      {children}
    </Tooltip>
  )
}

export default ItemTooltip
