/**
 * @generated SignedSource<<25c8052857eb166028a6d3e5d3afd95c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonFormQuery$variables = Record<PropertyKey, never>;
export type PersonFormQuery$data = {
  readonly current_account: {
    readonly id: number;
    readonly user_accounts: ReadonlyArray<{
      readonly id: number;
      readonly user: {
        readonly first_name: string | null | undefined;
        readonly id: number;
        readonly last_name: string | null | undefined;
      };
    }>;
  } | null | undefined;
};
export type PersonFormQuery = {
  response: PersonFormQuery$data;
  variables: PersonFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "accounts",
    "kind": "LinkedField",
    "name": "current_account",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user_accounts",
        "kind": "LinkedField",
        "name": "user_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonFormQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3271b52b24d45cd414c30b6c51a6b116",
    "id": null,
    "metadata": {},
    "name": "PersonFormQuery",
    "operationKind": "query",
    "text": "query PersonFormQuery {\n  current_account {\n    id\n    user_accounts {\n      id\n      user {\n        id\n        first_name\n        last_name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c1b612102bbe94d4d16ff379186555e";

export default node;
