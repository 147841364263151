import { Radio, RadioGroup } from "@blueprintjs/core"
import React from "react"

import styles from "./RoleConfirmationModal.module.css"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

type Role = {
  id: number
  name: string
}
type Props = {
  assignmentRole: Role
  selectedRole: Role
  personName: {
    firstName: string
    lastName: string
  }
  isSaving: boolean
  handleSubmit: (id: number) => void
  closeDialog: () => void
  hasMultipleAssignments: boolean
  entirePlaceholderTransfer: boolean
  confirmedRoleId: number | null
  setConfirmedRoleId: (id: number) => void
}

const RoleConfirmationModal = (props: Props) => {
  const {
    selectedRole,
    assignmentRole,
    personName,
    isSaving,
    closeDialog,
    handleSubmit,
    hasMultipleAssignments,
    setConfirmedRoleId,
    confirmedRoleId,
    entirePlaceholderTransfer = false,
  } = props

  const { firstName, lastName } = personName

  const handleConfirmedRole = (e) => {
    setConfirmedRoleId(Number(e.target.value))
  }

  const startOfMessage = hasMultipleAssignments
    ? "These assignments were"
    : "This assignment was"

  return (
    <ModalFormWrapper headerTitle="Confirm Role">
      <ModalBody>
        <div className={styles.wrapper}>
          <p>
            {startOfMessage} originally scheduled to a{" "}
            <b>{assignmentRole.name}</b>, but you are transferring to{" "}
            <b>
              {firstName} {lastName}
            </b>{" "}
            as a <b>{selectedRole.name}</b>.
          </p>
          <p>
            Please confirm <b>{firstName}'s</b> role for the selected assignment
            {hasMultipleAssignments && "s"}:
          </p>
          <RadioGroup
            onChange={handleConfirmedRole}
            selectedValue={confirmedRoleId}
            className={styles.radioGroup}
          >
            <Radio
              label={selectedRole.name}
              id={`confirm-role-${selectedRole.name}`}
              value={selectedRole.id}
            />
            <Radio
              label={assignmentRole.name}
              id={`confirm-role-${assignmentRole.name}`}
              value={assignmentRole.id}
            />
          </RadioGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSaving} text="Cancel" onClick={closeDialog} />
        <Button
          text={entirePlaceholderTransfer ? "Next" : "Transfer"}
          outlined={false}
          loading={isSaving}
          disabled={isSaving || !confirmedRoleId}
          onClick={() => handleSubmit(confirmedRoleId)}
          data-test="confirm-role-button"
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default RoleConfirmationModal
