import * as relayids from "~/helpers/relayids"

import * as computedFields from "./common/computed-fields"
import * as relationships from "./common/relationships"

import type { Model } from "~/subscriptions/AuditLogSubscription/types"

type Phase = {
  id: number
  project_id: number
  start_date: string
  end_date: string
}

const phaseSubscription: Model<Phase> = {
  tableName: "phases",
  relationships: [relationships.project("phases")],
  getDataId: (row) => relayids.phases.encode(row.id),
  transformFields: {
    id: computedFields.id("phases"),
    start_date: computedFields.startDate,
    end_date: computedFields.endDate,
  },
}

export default phaseSubscription
