import React, { useCallback } from "react"

import type { ChartType } from "~/Planner/ChartPanel/types"
import type { ChartProps } from "~/Planner/ChartPanel/types"

import { ChartAvailability } from "./Availability"
import { ChartCapacity } from "./Capacity"
import { ChartUtilization } from "./Utilization"
import { calculateYAxisTicks, convertHoursToFTE } from "./chartCalculations"

type Props = ChartProps & {
  type: ChartType
  companyDefaultMinutes: number
}

const Chart = (props: Props) => {
  const {
    type,
    companyDefaultMinutes,
    period,
    includeTentative,
    ...restProps
  } = props

  const formatFTE = useCallback(
    (hoursOfEffort: number) =>
      convertHoursToFTE(companyDefaultMinutes, period, hoursOfEffort),
    [companyDefaultMinutes, period],
  )

  const getTicks = useCallback(
    (smallestValue: number, largestValue: number, showFTE: boolean) =>
      calculateYAxisTicks({
        smallestValue,
        largestValue,
        companyDefaultMinutes,
        period,
        showFTE,
      }),
    [companyDefaultMinutes, period],
  )

  switch (type) {
    case "availability":
      return (
        <ChartAvailability
          formatFTE={formatFTE}
          period={period}
          getTicks={getTicks}
          {...restProps}
        />
      )
    case "capacity":
      return (
        <ChartCapacity
          getTicks={getTicks}
          formatFTE={formatFTE}
          period={period}
          includeTentative={includeTentative}
          {...restProps}
        />
      )
    case "utilization":
      return <ChartUtilization period={period} {...restProps} />
    default:
      return null
  }
}

export { Chart }
