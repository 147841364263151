import cc from "classcat"
import React from "react"

import styles from "./ProjectOverview.module.css"

import { ProjectRowSummary_project$data } from "~/ProjectPlanner/__generated__/ProjectRowSummary_project.graphql"

import { isSplitScreenMode } from "~/Mode.reducer"
import { useAppSelector } from "~/hooks/redux"

import PhasesWrapper from "../Phases/PhasesWrapper"

import { ProjectLine, ProjectMilestones } from "./index"

type Props = {
  project: ProjectRowSummary_project$data
}

const ProjectOverview = (props: Props) => {
  const { project } = props

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  return (
    <div
      className={cc([
        styles.projectOverview,
        {
          [styles.disabled]: isSplitScreenMode(modeAction),
        },
      ])}
    >
      <ProjectMilestones project={project} />
      <ProjectLine project={project} />
      <PhasesWrapper project={project} mini />
    </div>
  )
}

export default ProjectOverview
