import { Tooltip } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React from "react"

import styles from "./AvailableOnPlansChip.module.css"

import { SimpleLabel } from "~/common/labels"

import { usePermissions } from "~/Permissions/usePermissions"

import {
  ChargebeeUIFeatureId,
  features,
  getPlanNameListForFeature,
} from "./plansAndFeatures"
import {
  useEntitlementQuantity,
  useEntitlementSwitch,
  useIsInTrial,
} from "./useEntitlements"

type Props = {
  featureId: ChargebeeUIFeatureId
  title?: string
}

const AvailableOnPlansChip = ({ featureId, title }: Props) => {
  const { can, subject } = usePermissions()
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const hasFeatureSwitch = useEntitlementSwitch(featureId)
  const hasFeatureQuantity = useEntitlementQuantity(featureId)
  const isAdmin = can("manage", subject("Account"))
  const feature = features[featureId]
  const isInTrial = useIsInTrial()

  const hasFeature = hasFeatureSwitch || hasFeatureQuantity > 0

  if ((hasFeature && !isInTrial) || !feature || !entitlementsEnabled) {
    return null
  }

  const planNamesForTooltip = getPlanNameListForFeature({ featureId })

  const planLink = isAdmin ? (
    <a className={styles.link} href="/account/billing">
      plans
    </a>
  ) : (
    "plans"
  )

  const featureTitle = title ?? feature.name

  const isOrAre =
    featureTitle.charAt(featureTitle.length - 1) === "s" ? "are" : "is"

  return (
    <Tooltip
      usePortal={false}
      hoverOpenDelay={300}
      hoverCloseDelay={100}
      placement="right"
      content={
        <div className={styles.tooltipText}>
          {featureTitle} {isOrAre} available on {planNamesForTooltip} {planLink}
        </div>
      }
    >
      <SimpleLabel
        type="gradient"
        className={`${styles.label} available-on-plans-chip`}
        text="Paid plan feature"
      />
    </Tooltip>
  )
}

export default AvailableOnPlansChip
