import React from "react"

import { TangramRocket } from "~/common/tangrams/tangramRocket"

const RocketTakeOffTangram = (props: { activate: boolean }) => {
  return (
    <div
      style={{
        transition: "all 1.5s",
        width: "100%",
        height: "100%",
        transform: `translateY(${props.activate ? "-100vh" : "0%"})`,
      }}
    >
      <TangramRocket />
    </div>
  )
}

export default RocketTakeOffTangram
