import React from "react"

import styles from "./ModeMessageWarning.module.css"

import { WarningIcon } from "~/common/react-icons"

type Props = {
  message: string
}

const ModeMessageWarning = (props: Props) => {
  return (
    <div className={styles.warning}>
      <div className={styles.icon}>
        <WarningIcon />
      </div>
      <span>Warning: {props.message}</span>
    </div>
  )
}

export default ModeMessageWarning
