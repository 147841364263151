/**
 * @generated SignedSource<<a6e7dec0fc4c6e9fc1e295ab635c60cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBudgetLine_project$data = {
  readonly client: {
    readonly id: number;
    readonly internal: boolean | null | undefined;
  };
  readonly id: number;
  readonly pricing_model: string;
  readonly total_budget: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetDays_project" | "ProjectBudgetFinancial_project" | "ProjectBudgetHours_project">;
  readonly " $fragmentType": "ProjectBudgetLine_project";
};
export type ProjectBudgetLine_project$key = {
  readonly " $data"?: ProjectBudgetLine_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBudgetLine_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBudgetLine_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": "total_budget",
      "args": null,
      "kind": "ScalarField",
      "name": "total_budget_private",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBudgetFinancial_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBudgetHours_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBudgetDays_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "e099919cb23ccb11f45c5f710b007cba";

export default node;
