/**
 * @generated SignedSource<<a342703fcbbc452a3c9384807302bcb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExternalProjectBulkImportQuery$variables = {
  integrationId: number;
};
export type ExternalProjectBulkImportQuery$data = {
  readonly integration_by_pk: {
    readonly ext_projects: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
      readonly remote_id: string | null | undefined;
    }>;
    readonly id: number;
  } | null | undefined;
};
export type ExternalProjectBulkImportQuery = {
  response: ExternalProjectBulkImportQuery$data;
  variables: ExternalProjectBulkImportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "integrationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "integrationId"
      }
    ],
    "concreteType": "integration",
    "kind": "LinkedField",
    "name": "integration_by_pk",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "active": {
                "_eq": true
              }
            }
          }
        ],
        "concreteType": "ext_project",
        "kind": "LinkedField",
        "name": "ext_projects",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remote_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": "ext_projects(where:{\"active\":{\"_eq\":true}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalProjectBulkImportQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalProjectBulkImportQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6f2645742dd034a0941f4a726d76ef63",
    "id": null,
    "metadata": {},
    "name": "ExternalProjectBulkImportQuery",
    "operationKind": "query",
    "text": "query ExternalProjectBulkImportQuery(\n  $integrationId: Int!\n) {\n  integration_by_pk(id: $integrationId) {\n    id\n    ext_projects(where: {active: {_eq: true}}) {\n      id\n      remote_id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc6d88a570ce543ccce02071f9aeb9e2";

export default node;
