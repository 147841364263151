import React from "react"
import { useDispatch } from "react-redux"

import {
  PlannerGroupByType,
  changeGroupByType,
} from "~/Planner/reducer2/plannerStatsSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import { DropdownHeading } from "../Dropdown"
import MenuItem from "../MenuItem"

type EffortOption = {
  readonly value: PlannerGroupByType
  readonly label: string
}

const groupByOptions: readonly EffortOption[] = [
  { value: "roles" as const, label: "Roles" },
  { value: "workstreams" as const, label: "Workstreams" },
] as const

const ProjectGroupByControl = () => {
  const dispatch = useDispatch()
  const groupByType = useAppSelector(
    (state) => state.plannerV2.plannerStats.groupByType,
  )

  const setGroupByType = (type: PlannerGroupByType) => {
    dispatch(changeGroupByType(type))
    setSetting("plannerStatsGroupBy", type)
  }

  const selectedEffortOption = groupByOptions.find(
    (v) => v.value === groupByType,
  )

  return (
    <>
      <DropdownHeading>View project teams by</DropdownHeading>
      <MenuItem text={selectedEffortOption.label}>
        {groupByOptions.map((option) => (
          <MenuItem
            key={option.value}
            text={option.label}
            onClick={() => setGroupByType(option.value)}
            shouldDismissPopover={false}
            active={option.value === groupByType}
          />
        ))}
      </MenuItem>
    </>
  )
}

export default ProjectGroupByControl
