import { MenuDivider } from "@blueprintjs/core"
import React from "react"

import Dropdown, { DropdownTargetProps } from "~/common/Dropdown/Dropdown"
import ProjectGroupByControl from "~/common/PageControls/ProjectGroupByControl"
import * as icons from "~/common/react-icons"

import { useAppSelector } from "~/hooks/redux"

import IconButton from "./IconButton"
import PeopleSummaryUnitControl from "./PeopleSummaryUnitControl"
import PhasesToggle from "./PhasesToggle"
import ShowTotalEffortToggle from "./ShowTotalEffortToggle"

const TargetIcon = (props: DropdownTargetProps) => {
  const { active, disabled } = props

  return (
    <IconButton
      disabled={disabled}
      active={active}
      icon={<icons.ToggleControl />}
      tooltipContent="Display Options"
    />
  )
}

type Props = {
  dataTest: string
  disabled?: boolean
  showViewGroupByControls?: boolean
}

const ViewControls = (props: Props) => {
  const { dataTest, disabled, showViewGroupByControls } = props

  const activePage = useAppSelector((state) => state.activePage)

  const PeopleToggles = () => {
    return <PeopleSummaryUnitControl hideSummaryControl={false} />
  }

  const ProjectToggles = () => {
    return (
      <>
        <PeopleSummaryUnitControl hideSummaryControl={true} />
        {showViewGroupByControls && (
          <>
            <MenuDivider />
            <ProjectGroupByControl />
          </>
        )}
        <MenuDivider />
        <ShowTotalEffortToggle />
        <PhasesToggle />
      </>
    )
  }

  const Toggles = () => {
    return activePage === "people" ? <PeopleToggles /> : <ProjectToggles />
  }

  return (
    <Dropdown
      disabled={disabled}
      Target={TargetIcon}
      placement="bottom-start"
      dataTest={dataTest}
    >
      <Toggles />
    </Dropdown>
  )
}

export default ViewControls
