/**
 * @generated SignedSource<<32dece771cd7654c26836eaa7ea0d755>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonSchedulePanel_user$data = {
  readonly account: {
    readonly people: ReadonlyArray<{
      readonly id: number;
      readonly is_placeholder: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"PersonDetails_person" | "PersonSummaryRow_person" | "PlaceholderSummaryRow_person">;
    }>;
  };
  readonly favouritePersonIds: any;
  readonly " $fragmentType": "PersonSchedulePanel_user";
};
export type PersonSchedulePanel_user$key = {
  readonly " $data"?: PersonSchedulePanel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSchedulePanel_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "plannerStartDate",
    "variableName": "plannerStartDate"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonSchedulePanel_user",
  "selections": [
    {
      "alias": "favouritePersonIds",
      "args": null,
      "kind": "ScalarField",
      "name": "favourite_people",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "peopleFilter"
            }
          ],
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "people",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_placeholder",
              "storageKey": null
            },
            {
              "args": (v0/*: any*/),
              "kind": "FragmentSpread",
              "name": "PersonSummaryRow_person"
            },
            {
              "args": (v0/*: any*/),
              "kind": "FragmentSpread",
              "name": "PersonDetails_person"
            },
            {
              "args": (v0/*: any*/),
              "kind": "FragmentSpread",
              "name": "PlaceholderSummaryRow_person"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "222a969ce7f40c2644a9cf8bf9927f0c";

export default node;
