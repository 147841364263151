import { MenuDivider } from "@blueprintjs/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import styles from "./GroupControl.module.css"

import { track } from "~/helpers/analytics"
import { useGroupByEntitlement } from "~/helpers/group-helpers"

import Dropdown, { DropdownTargetProps } from "~/common/Dropdown/Dropdown"
import DropdownHeading from "~/common/Dropdown/DropdownHeading"
import MenuItem from "~/common/MenuItem"
import * as icons from "~/common/react-icons"

import { useShowUpsellingSection } from "~/Entitlements/useEntitlements"
import { TOGGLE_EXPAND_ALL } from "~/Planner/Planner.actions"
import { ReduxState } from "~/rootReducer"

import PaidFeatureMenuItemContainer from "../PaidFeatureMenuItemContainer"

import GroupUtilizationToggle from "./GroupUtilizationToggle"
import IconButton from "./IconButton"
import OverflowTooltipContent from "./OverflowTooltipContent"

type GroupOption = {
  value: string
  label: string
  featureEntitlementId?: string
}

type Props = {
  onChange: (option: GroupOption) => void
  selectedOption: string
  options: GroupOption[]
  dataTest: string
  disabled?: boolean
}

export const DIVIDER = {
  label: "---",
  value: "---",
} as const

const GroupControlTarget = (
  props: DropdownTargetProps & { activePage: string },
) => {
  const { disabled, active, activePage } = props

  const tooltipContent = disabled ? (
    <OverflowTooltipContent functionality={`${activePage} grouping`} />
  ) : (
    `Group ${activePage} by`
  )

  return (
    <IconButton
      disabled={disabled ? "button" : false}
      active={active}
      icon={<icons.Group />}
      tooltipContent={tooltipContent}
    />
  )
}

const GroupControl = (props: Props) => {
  const { selectedOption, onChange, options, dataTest, disabled } = props

  const dispatch = useDispatch()
  const expandAll = useSelector((state: ReduxState) => state.planner.expandAll)
  const toggleExpandAll = () => dispatch({ type: TOGGLE_EXPAND_ALL })

  const handleChange = (option: GroupOption) => {
    track("Planner Groupby Selected", { value: option.label })
    onChange(option)
  }
  const activePage: "projects" | "people" = useSelector(
    (state: ReduxState) => state.activePage,
  )

  const showUpsellingSection = useShowUpsellingSection()
  const { entitledOptions, unentitledOptions } = useGroupByEntitlement(options)

  return (
    <Dropdown
      Target={(dropdownProps: DropdownTargetProps) => (
        <GroupControlTarget {...dropdownProps} activePage={activePage} />
      )}
      placement="bottom-start"
      dataTest={dataTest}
      disabled={disabled}
      className={styles.container}
      minWidth={200}
    >
      {activePage !== "projects" && (
        <div>
          <GroupUtilizationToggle />
          <MenuDivider />
        </div>
      )}

      <DropdownHeading className={styles.heading}>
        Group by
        <div
          className={styles.toggleIcon}
          onClick={toggleExpandAll}
          data-test={expandAll ? "collapse-icon" : "expand-icon"}
        >
          {expandAll ? <icons.Collapse /> : <icons.Expand />}
        </div>
      </DropdownHeading>
      <div>
        {entitledOptions.map((option, i) =>
          option === DIVIDER ? (
            <MenuDivider key={`divider--${i}`} />
          ) : (
            <MenuItem
              key={option.value}
              data-test={`group-by-${option.label}`}
              text={option.label}
              active={selectedOption === option.value}
              onClick={() => handleChange(option)}
            />
          ),
        )}
      </div>
      {showUpsellingSection && !!unentitledOptions.length && (
        <PaidFeatureMenuItemContainer roundedButton>
          {unentitledOptions.map((option) => (
            <MenuItem
              key={option.value}
              data-test={`group-by-${option.label}`}
              text={option.label}
              active={selectedOption === option.value}
              onClick={() => handleChange(option)}
              disabled
            />
          ))}
        </PaidFeatureMenuItemContainer>
      )}
    </Dropdown>
  )
}

export default GroupControl

export { GroupOption }
