import { dateHelpers } from "@runn/calculations"
import { format as formatDate } from "date-fns"
import numbro from "numbro"

import { ExpenseTotals } from "~/ProjectDashboard/Header/ProjectBudget/ExpenseTotalsRow"
import {
  ProjectView,
  TemplateView,
} from "~/ProjectDashboard/ProjectDashboardContainer"
import { LocalProjectReportData } from "~/reports/ProjectPeriodReport/ProjectPeriodReport"
import { OtherCost as ReportOtherCost } from "~/reports/reportHelpers/otherCostsHelpers"

import { sortByNumber, sortByString } from "./sorting-helpers"

export type OtherCost = {
  id?: number | string
  date: Date
  charge: number
  cost: number
  name?: string
}
export type OtherCostWithCalculations = OtherCost & {
  margin: number
  profit: number
}

type BudgetRoleTypes<T> = {
  people: ReadonlyArray<T>
  expenses?: T
}

export const OTHER_EXPENSES_ROLE = {
  id: -1,
  name: "Other Expenses",
  active: true,
}

export const calculateMargin = (
  item: Pick<OtherCostWithCalculations, "profit" | "charge">,
) => {
  const { profit, charge } = item
  const rawMargin = profit && charge ? (profit / charge) * 100 : 0
  return numbro(rawMargin).format({
    optionalMantissa: true,
    mantissa: 0,
  })
}

export const addDerivedFields = (otherCost): OtherCostWithCalculations => {
  const { cost, charge } = otherCost
  const calculated = {
    ...otherCost,
    profit: charge - cost,
  }
  calculated.margin = calculateMargin(calculated)

  return calculated
}

export const getTotalExpenses = (
  otherCosts: ReadonlyArray<Pick<OtherCost, "cost" | "charge">>,
) => {
  const totalExpenses = otherCosts.reduce<ExpenseTotals>(
    (acc, curr) => {
      const cost = addDerivedFields(curr)

      const ret = {
        ...acc,
        profit: acc.profit + cost.profit,
        cost: acc.cost + cost.cost,
        charge: acc.charge + cost.charge,
      }

      return ret
    },
    {
      margin: "",
      profit: 0,
      cost: 0,
      charge: 0,
    },
  )
  totalExpenses.margin = calculateMargin(totalExpenses)

  return totalExpenses
}

export const getCostForView = (
  projectView: ProjectView | TemplateView,
  otherCost: Pick<OtherCost, "charge" | "cost">,
) => {
  const field = projectView === "costs" ? "cost" : "charge"

  return otherCost[field]
}

export const getRemainingAllocation = (
  totalBudget: number,
  totalAllocated: number,
  expensesBudget: number,
) => totalBudget - (totalAllocated || 0) - (expensesBudget || 0)

export const extractRoles = <T extends { id: number; name: string }>(
  roles: ReadonlyArray<T>,
): BudgetRoleTypes<T> => {
  return {
    people: roles
      .filter((r) => r.id !== OTHER_EXPENSES_ROLE.id)
      .sort((a, b) => sortByString(a.name, b.name)),
    expenses: roles.find((r) => r.id === OTHER_EXPENSES_ROLE.id),
  }
}

export const getAllocationMessage = (allocation: number) => {
  if (allocation > 0) {
    return "Underallocated by"
  }
  if (allocation < 0) {
    return "Overallocated by"
  }

  return "Budget Allocated ✅"
}

export const addOtherCostsToTemplatePerformanceGraphData = (
  graphData: Array<{
    date: Date | string
    total_revenue: number
    total_hours: number
    total_cost: number
  }>,
  params: {
    otherCosts: ReadonlyArray<ReportOtherCost>
    budget: number
  },
) => {
  const { otherCosts, budget } = params
  const lastRecord = graphData[graphData.length - 1]
  const firstRecord = graphData[0]

  const createCostPlaceholder = (date) => {
    return {
      date,
      formattedDate: formatDate(dateHelpers.parseRunnDate(date), "d MMM"),
      total_revenue: 0,
      total_hours: 0,
      total_cost: 0,
      budget,
    }
  }

  let prepend = []
  let append = []

  const prependOtherCosts = otherCosts
    .filter((cost) => cost.date < firstRecord.date)
    .sort((a, b) => sortByNumber(a.date, b.date))

  const appendOtherCosts = otherCosts
    .filter((cost) => cost.date > lastRecord.date)
    .sort((a, b) => sortByNumber(a.date, b.date))

  if (prependOtherCosts.length) {
    const earliest = prependOtherCosts[0].date
    const prependDates = dateHelpers.getAllWeekdaysDatesBetween(
      earliest,
      firstRecord.date,
    )
    prepend = prependDates.map(createCostPlaceholder)
  }

  if (appendOtherCosts.length) {
    const latest = appendOtherCosts[appendOtherCosts.length - 1]
    const appendDates = dateHelpers.getAllWeekdaysDatesBetween(
      lastRecord.date,
      latest.date,
    )
    append = appendDates.map((date) => {
      return {
        ...createCostPlaceholder(date),
        total_revenue: lastRecord.total_revenue,
        total_hours: lastRecord.total_hours,
        total_cost: lastRecord.total_cost,
      }
    })
  }

  const graphHoursPreDataWithOtherCosts = [...prepend, ...graphData, ...append]
  const data = graphHoursPreDataWithOtherCosts.map((hrs) => {
    const appliedOtherCosts = otherCosts.filter((cost) => cost.date <= hrs.date)
    const additionalCost = appliedOtherCosts.reduce(
      (acc, cost) => acc + getCostForView("costs", cost),
      0,
    )
    const additionalRevenue = appliedOtherCosts.reduce(
      (acc, cost) => acc + getCostForView("revenue", cost),
      0,
    )

    return {
      ...hrs,
      total_revenue: hrs.total_revenue + additionalRevenue,
      total_hours: hrs.total_hours,
      total_cost: hrs.total_cost + additionalCost,
    }
  })

  return data
}

export const addOtherCostsToPerformanceGraphData = (
  graphData: Array<{
    date: Date | string
    total_actuals: number
    total_combined: number
    total_scheduled: number
  }>,
  params: {
    otherCosts: ReadonlyArray<ReportOtherCost>
    budget: number
    projectView: "revenue" | "costs"
    projected: number
  },
) => {
  const { otherCosts, budget, projectView, projected } = params
  const lastRecord = graphData[graphData.length - 1]
  const firstRecord = graphData[0]

  const createCostPlaceholder = (date) => {
    return {
      date,
      formattedDate: formatDate(dateHelpers.parseRunnDate(date), "d MMM"),
      is_complete: true,
      benchmark: 0,
      budget,
      total_actuals: 0,
      total_scheduled: 0,
      total_combined: 0,
    }
  }

  let prepend = []
  let append = []

  const prependOtherCosts = otherCosts
    .filter((cost) => cost.date < firstRecord.date)
    .sort((a, b) => sortByNumber(a.date, b.date))

  const appendOtherCosts = otherCosts
    .filter((cost) => cost.date > lastRecord.date)
    .sort((a, b) => sortByNumber(a.date, b.date))
  if (prependOtherCosts.length) {
    const earliest = prependOtherCosts[0].date
    const prependDates = dateHelpers.getAllWeekdaysDatesBetween(
      earliest,
      firstRecord.date,
    )
    prepend = prependDates.map(createCostPlaceholder)
  }
  if (appendOtherCosts.length) {
    const latest = appendOtherCosts[appendOtherCosts.length - 1]
    const appendDates = dateHelpers.getAllWeekdaysDatesBetween(
      lastRecord.date,
      latest.date,
    )
    append = appendDates.map((date) => {
      return {
        ...createCostPlaceholder(date),
        total_actuals: lastRecord.total_actuals,
        total_scheduled: lastRecord.total_scheduled,
        total_combined: lastRecord.total_combined,
      }
    })
  }

  const graphHoursPreDataWithOtherCosts = [...prepend, ...graphData, ...append]

  const data = graphHoursPreDataWithOtherCosts.map((hrs) => {
    const appliedOtherCosts = otherCosts.filter((cost) => cost.date <= hrs.date)
    const additional = appliedOtherCosts.reduce(
      (acc, cost) => acc + getCostForView(projectView, cost),
      0,
    )
    return {
      ...hrs,
      total_actuals: hrs.total_actuals + additional,
      total_scheduled: hrs.total_scheduled + additional,
      total_combined: hrs.total_combined + additional,
      projected,
    }
  })

  return data
}

export const createOtherExpensesPerson = (project: LocalProjectReportData) => ({
  first_name: "Other",
  last_name: "Expenses",
  ...project,
  personId: `${project.id}--other-expenses`,
  combinedHours: [],
  other_costs: project.other_costs,
  detailedData: true,
})
