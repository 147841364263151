import { dateHelpers } from "@runn/calculations"
import { format } from "date-fns"

const toTitleCase = (value: string) => {
  if (typeof value !== "string" || value.length === 0) {
    return ""
  }

  return value
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
}

const formatRunnDate = (runnDate: string) => {
  const dateFormat = "d MMM yyyy"
  return format(dateHelpers.parseRunnDate(runnDate), dateFormat)
}

export { toTitleCase, formatRunnDate }
