import { Icon, MenuDivider } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React, { Suspense, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./PersonSidePanel.module.css"

import { PersonActionButtons_person$key } from "./__generated__/PersonActionButtons_person.graphql"
import { PersonActionButtons_user$key } from "./__generated__/PersonActionButtons_user.graphql"

import { track } from "~/helpers/analytics"
import * as hashids from "~/helpers/hashids"
import { PERSON_ARCHIVE_HINT } from "~/helpers/hints"
import { formatName } from "~/helpers/person"
import { viewPersonUrl } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import Loading from "~/common/Loading"
import MenuItem from "~/common/MenuItem"
import IconButton from "~/common/PageControls/IconButton"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import SidePanelActionButtons from "~/common/SidePanel/SidePanelActionButtons"
import { DashboardIcon } from "~/common/react-icons"

import { toggleActiveStatus } from "~/mutations/ActiveStatus"
import { useDeletePersonMutation } from "~/mutations/useDeletePersonMutation"

import ArchiveModal from "~/Modals/ArchiveModal"
import DeleteModal from "~/Modals/Delete/DeleteModal"
import { isSplitScreenMode } from "~/Mode.reducer"
import useFavouritePerson from "~/PeoplePlanner/useFavouritePerson"
import { usePermissions } from "~/Permissions/usePermissions"
import { panelClosed, panelOpened } from "~/Planner/reducer2/panelSlice"
import { personIdSelected } from "~/Planner/reducer2/schedulePreviewSlice"
import UserModal from "~/Users/UserModal"
import { Mode } from "~/Users/userForm"
import { showToast } from "~/containers/ToasterContainer"
import InviteUserForm from "~/forms/UserForms/InviteUserForm"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import InvitationMenu from "../InvitationMenu"
import { PersonMenuType, useAvailableMenu } from "../PlannerLayout/ContextMenu"

type Props = {
  person: PersonActionButtons_person$key
  user: PersonActionButtons_user$key
  openPersonEditForm?: () => void
  inPlanner: boolean
}

type DialogType = "ARCHIVE" | "DELETE" | "INVITE"

const PersonActionButtons = (props: Props) => {
  const { openPersonEditForm, inPlanner = false } = props

  const person = useFragment(
    graphql`
      fragment PersonActionButtons_person on people {
        id
        is_placeholder
        email
        first_name
        last_name
        email
        active
        archivable
        assignments_aggregate {
          aggregate {
            count
          }
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    props.person,
  )

  const user = useFragment(
    graphql`
      fragment PersonActionButtons_user on users {
        id
        favourite_people
        account {
          id
          users {
            id
            email
          }
          invitations {
            id
            email
          }
        }
      }
    `,
    props.user,
  )

  const { closePanel } = useSidePanel()
  const { isFavourite, favouritePerson } = useFavouritePerson({
    personId: person.id,
    userId: user.id,
    favouritePersonIds: user.favourite_people,
  })
  const dispatch = useDispatch()
  const showPanel = useSelector((state: ReduxState) => state.panel.showPanel)
  const schedulePanelOpen = showPanel === "personSchedule"

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const selectedPersonId: number = useSelector(
    (state: ReduxState) => state.plannerV2.schedulePreview.selectedPersonId,
  )
  const [visibleDialogType, setVisibleDialogType] = useState<DialogType | null>(
    null,
  )
  const { can, subject } = usePermissions()
  const canDeletePerson = can("delete", subject("Person", person))
  const canInvite = can("create", subject("Invitation"))
  const canEdit = can("edit", subject("User", user))

  const permissionsUIEnabled = useFeature("permissions_ui")

  // NOTE: We do *not* have a project here, so we pass in null; this will default the "canRemoveMembershipFromProject" to false
  // Since it is invalid We remove it from the type used in the cast
  const menuItems = useAvailableMenu({
    type: "person",
    person,
    project: null,
  }) as Omit<PersonMenuType, "canRemoveMembershipFromProject">
  const { canViewDashboard, canEditDetails, canArchive, canDelete } = menuItems

  const name = formatName(person.first_name, person.last_name)
  const personHashId = hashids.people.encode(person.id)

  const viewPerson = () => {
    if (schedulePanelOpen && selectedPersonId === person.id) {
      dispatch(panelClosed())
    } else {
      dispatch(panelOpened("personSchedule"))
      dispatch(personIdSelected(person.id))
      track("Person Schedule Panel Opened")
    }
  }

  const [deletePerson] = useDeletePersonMutation()
  const handleDelete = async () => {
    try {
      await deletePerson({ personId: person.id })
      showToast({
        message: `${name} has been deleted`,
        type: "success",
      })
    } catch (error: unknown) {
      showToast({
        message: `Failed to delete "${name}".`,
        description: error instanceof Error ? error.message : undefined,
        type: "error",
      })
    }
    setVisibleDialogType(null)
    closePanel()
  }

  const handleCloseDialog = () => {
    setVisibleDialogType(null)
  }

  const renderDialog = () => {
    switch (visibleDialogType) {
      case "INVITE":
        return permissionsUIEnabled ? (
          <Suspense fallback={<Loading delayMs={50} embed />}>
            <UserModal
              mode={Mode.INVITE}
              email={person.email}
              onClose={handleCloseDialog}
            />
          </Suspense>
        ) : (
          <InviteUserForm
            email={person.email}
            closeDialog={handleCloseDialog}
          />
        )
      case "ARCHIVE":
        return (
          <ArchiveModal
            type="person"
            name={name}
            closeDialog={handleCloseDialog}
            customMessage={!person.archivable ? PERSON_ARCHIVE_HINT : ""}
            archivable={person.archivable}
            hashid={personHashId}
            id={person.id}
            active={person.active}
            inPlanner={true}
          />
        )
      case "DELETE":
        return (
          <DeleteModal
            type="person"
            name={name}
            isAdmin={canDeletePerson}
            deletable={
              person.assignments_aggregate.aggregate.count === 0 &&
              person.actuals_aggregate.aggregate.count === 0
            }
            closeDialog={handleCloseDialog}
            onSubmit={handleDelete}
            hint="People with existing assignments or timesheets can only be deleted by an admin. Please archive instead or contact an admin."
          />
        )
      default:
        break
    }
  }

  const handleArchiveStatus = () => {
    person.active
      ? setVisibleDialogType("ARCHIVE")
      : void toggleActiveStatus({
          variables: {
            type: "person",
            id: person.id,
            active: person.active,
            hashid: personHashId,
            name: formatName(person.first_name, person.last_name),
          },
        })
  }

  return (
    <>
      <SidePanelActionButtons>
        {canEdit && (
          <IconButton
            icon={<Icon icon={isFavourite ? "star" : "star-empty"} />}
            onClick={favouritePerson}
            tooltipPlacement="left"
            tooltipContent={
              isFavourite ? "Remove from Favorites" : "Add to Favorites"
            }
            className={styles.actionBtn}
            dataTest={
              isFavourite ? "person-is-starred" : "person-is-not-starred"
            }
          />
        )}
        {!splitScreenMode && inPlanner && (
          <IconButton
            icon={<Icon icon="horizontal-bar-chart-asc" size={14} />}
            onClick={viewPerson}
            tooltipPlacement="left"
            tooltipContent={
              selectedPersonId === person.id ? "Hide Schedule" : "View Schedule"
            }
            className={styles.actionBtn}
          />
        )}
        {canViewDashboard && inPlanner && (
          <IconButton
            icon={<DashboardIcon />}
            tooltipPlacement="left"
            tooltipContent="Open Dashboard"
            className={styles.dashboardBtn}
            // Using href so users have the ability to Right Click -> Open Link in new Tab
            buttonProps={{ href: viewPersonUrl(personHashId) }}
          />
        )}
        {canEditDetails ? (
          <Dropdown
            Target={() => (
              <IconButton
                icon={<IconThreeDot size={14} />}
                tooltipPlacement="left"
                tooltipContent="More Actions"
                className={styles.actionBtn}
              />
            )}
            placement="left"
            dataTest="side-panel-more-options"
          >
            {openPersonEditForm && (
              <MenuItem
                text="Edit Details"
                icon={<Icon icon="cog" />}
                onClick={openPersonEditForm}
              />
            )}
            {canInvite && (
              <>
                <MenuDivider />
                <InvitationMenu
                  person={person}
                  account={user.account}
                  handleMenuClick={() => setVisibleDialogType("INVITE")}
                />
              </>
            )}
            {canArchive && (
              <>
                <MenuDivider />
                <MenuItem
                  text={person.active ? "Archive" : "Unarchive"}
                  icon={<Icon icon={person.active ? "archive" : "unarchive"} />}
                  onClick={handleArchiveStatus}
                />
              </>
            )}
            {canDelete && (
              <MenuItem
                text="Delete"
                icon={<Icon icon="trash" />}
                onClick={() => setVisibleDialogType("DELETE")}
              />
            )}
          </Dropdown>
        ) : null}
      </SidePanelActionButtons>
      {visibleDialogType && (
        <Dialog
          isOpen={Boolean(visibleDialogType)}
          onClose={() => setVisibleDialogType(null)}
        >
          {renderDialog()}
        </Dialog>
      )}
    </>
  )
}

export default PersonActionButtons
