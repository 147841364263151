import { Icon, IconName } from "@blueprintjs/core"
import { format, parseISO } from "date-fns"
import { Feature, useFeature } from "flagged"
import React from "react"

import styles from "./PlaceholderSidePanel.module.css"

import { PlaceholderSidePanel_placeholder$data } from "./__generated__/PlaceholderSidePanel_placeholder.graphql"

import {
  CustomType,
  CustomValuesMap,
  hasCustomFieldValues,
} from "~/helpers/custom-field-helpers"
import { formatName, getCurrentOrLastContract } from "~/helpers/person"
import {
  getRequestStatusIcon,
  getResourceMenuItems,
} from "~/helpers/placeholder-helpers"
import routes from "~/helpers/routes"

import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"
import SidePanelCustomFields from "~/common/SidePanel/SidePanelCustomFields"
import BlueLink from "~/common/buttons/BlueLink"
import MidnightLinkButton from "~/common/buttons/MidnightLinkButton"

import { PERSON_REQUEST_STATUSES as STATUSES } from "~/ENUMS"
import { usePermissions } from "~/Permissions/usePermissions"
import SkillLabel from "~/Skills/SkillsLabelsList/SkillLabel"
import TagList from "~/dashboard/Detail/DashboardDetailSelectItems"

import { getRequestItemName } from "../PlannerLayout/PlaceholderWorkflowMenu"

import { SuggestedPerson } from "./SuggestedPerson"

type Props = {
  placeholder: PlaceholderSidePanel_placeholder$data
  placeholderProjectId: number
  customFieldTypes: CustomType[]
  customFieldValues: CustomValuesMap
  openPlaceholderForm: (openedInRequestForm: boolean) => void
  setSelectedRequestStatus: (status: STATUSES) => void
  noAssignments: boolean
  inRequestPanel?: boolean
}

const PlaceholderDetails = ({
  placeholder,
  placeholderProjectId,
  customFieldTypes,
  customFieldValues,
  openPlaceholderForm,
  setSelectedRequestStatus,
  noAssignments,
  inRequestPanel = false,
}: Props) => {
  const { can, subject } = usePermissions()
  const canViewSalary = can("view", subject("Salary"))

  // SAFETY: All placeholder project memberships will have a project_membership row
  const project = placeholder.project_memberships.find(
    ({ project: { id } }) => id === placeholderProjectId,
  ).project

  const canEditPersonRequest = can(
    "edit",
    subject("PersonRequest", {
      project: {
        id: project.id,
        isTemplate: project.is_template,
      },
    }),
  )

  const canEditPlaceholder = can(
    "edit",
    subject("Placeholder", {
      project: {
        id: project.id,
        isTemplate: project.is_template,
      },
    }),
  )

  const placeholderContract = getCurrentOrLastContract(placeholder.contracts)
  const cost = placeholderContract?.cost
  const roleName = placeholderContract?.role.name
  const competencies = (placeholder.competencies || []).map((competency) => ({
    ...competency,
    person: {
      id: placeholder.id,
      first_name: placeholder.first_name,
      last_name: placeholder.last_name,
    },
    person_id: placeholder.id,
  }))
  const placeholderSuggestedPeopleFeature = useFeature(
    "placeholder_suggested_people",
  )

  const addMoreDetailsShown =
    !placeholder.team?.name &&
    competencies.length === 0 &&
    placeholder.tags.length === 0 &&
    !hasCustomFieldValues(customFieldValues) &&
    !inRequestPanel &&
    // ignore suggested people if feature is off
    (placeholderSuggestedPeopleFeature
      ? placeholder.placeholder_suggestions.length === 0
      : true)

  const personRequest = placeholder.person_requests[0]
  const requestDetails = personRequest
    ? {
        updatedAt: format(parseISO(personRequest.updated_at), "eee d MMM"),
        by: formatName(
          personRequest.user.first_name,
          personRequest.user.last_name,
        ),
        icon: getRequestStatusIcon(personRequest.status),
      }
    : null
  const allMenuItems = getResourceMenuItems(roleName)
  const requiredMenuItems = [
    {
      ...allMenuItems.REQUESTED,
      hidden:
        personRequest?.status === STATUSES.REQUESTED ||
        personRequest?.status === STATUSES.PENDING,
    },
    {
      ...allMenuItems.NEED_TO_HIRE,
      hidden: personRequest?.status === STATUSES.NEED_TO_HIRE,
    },
    {
      ...allMenuItems.CANCEL,
      hidden: false,
    },
  ]

  return (
    <>
      {!inRequestPanel && requestDetails ? (
        <div className={styles.requestStatusContainer}>
          <Icon icon={requestDetails.icon} color="var(--midnight)" size={16} />
          <div className={styles.statusTextContainer}>
            <h4
              className={styles.detailsTitle}
              data-test="request-status-title"
            >
              {getRequestItemName(personRequest.status)}
            </h4>
            <p className={styles.requestSubTitle}>
              By {requestDetails.by} on {requestDetails.updatedAt}
            </p>
          </div>
          {canEditPersonRequest ? (
            <div className={styles.respondContainer}>
              {personRequest.status === STATUSES.PENDING ? (
                <BlueLink
                  text="Request"
                  onClick={() => setSelectedRequestStatus(STATUSES.REQUESTED)}
                  disabled={noAssignments}
                />
              ) : (
                <BlueLink
                  text="Respond"
                  onClick={() => setSelectedRequestStatus(STATUSES.PENDING)}
                  disabled={noAssignments}
                />
              )}

              <Dropdown
                Target={() => (
                  <IconThreeDot
                    color="var(--midnight)"
                    size={16}
                    role="button"
                    className={styles.tripleDots}
                    data-test="placeholder-request-menu"
                  />
                )}
              >
                {requiredMenuItems.map((item) => {
                  const icon = item.icon as IconName
                  const status = item.status
                  return (
                    <MenuItem
                      text={item.text}
                      icon={<Icon icon={icon} />}
                      onClick={() => setSelectedRequestStatus(status)}
                      shouldDismissPopover={false}
                      key={item.text}
                      hidden={item.hidden}
                      disabled={noAssignments}
                    />
                  )
                })}
              </Dropdown>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.detailsContainer}>
        {inRequestPanel && (
          <div className={styles.requestTitleContainer}>
            <h4 className={styles.requestDetailsTitle}>Placeholder Details</h4>
            <BlueLink
              icon={<Icon icon="edit" />}
              text="Edit"
              onClick={() => openPlaceholderForm(true)}
            />
          </div>
        )}
        {canViewSalary ? (
          <>
            <h4 className={styles.detailsTitle}>Hourly Cost</h4>
            <p className={styles.detailsValue}>${cost}</p>
          </>
        ) : null}
        {addMoreDetailsShown && canEditPlaceholder ? (
          <div className={styles.moreDetailsContainer}>
            <Icon icon="info-sign" color="var(--sea-blue)" size={16} />
            <div className={styles.moreDetailsTextContainer}>
              <p className={styles.moreDetailsText}>
                Add more details to help with resourcing
              </p>
              <MidnightLinkButton
                text="Edit details"
                onClick={() => openPlaceholderForm(false)}
              />
            </div>
          </div>
        ) : null}
        {placeholder.team?.name && (
          <>
            <h4 className={styles.detailsTitle}>Team</h4>
            <p className={styles.detailsValue}>{placeholder.team?.name}</p>
          </>
        )}
        {placeholder.competencies.length > 0 && (
          <>
            <h4 className={styles.detailsTitle}>Skills needed</h4>
            <div className={styles.skillsContainer}>
              {competencies.map((competency) => (
                <SkillLabel
                  key={competency.id}
                  competency={competency}
                  canEdit={false}
                  style={{ marginTop: 0 }}
                />
              ))}
            </div>
          </>
        )}
        {placeholder.tags.length > 0 && (
          <>
            <h4 className={styles.detailsTitle}>People Tags</h4>
            <div className={styles.tagsContainer}>
              <TagList
                href={routes.tagsListUrl()}
                selectValues={placeholder.tags}
              />
            </div>
          </>
        )}
        <Feature name="placeholder_suggested_people">
          {placeholder.placeholder_suggestions?.length > 0 ? (
            <>
              <h4 className={styles.detailsTitle}>Suggested People</h4>
              <ul className={styles.suggestedPeopleList}>
                {placeholder.placeholder_suggestions.map((suggestion) => (
                  <li key={suggestion.suggested_person_id}>
                    <SuggestedPerson person={suggestion.suggested_person} />
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </Feature>
        <SidePanelCustomFields
          customFieldTypes={customFieldTypes}
          customFieldValues={customFieldValues}
        />
      </div>
    </>
  )
}

export default PlaceholderDetails
