import { graphql, useFragment } from "react-relay"

import type { useRoles_account$key } from "./__generated__/useRoles_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useRoles_account on accounts {
    roles {
      id
      name
      active
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useRoles = (options: Options) => {
  const { user } = options

  const { roles } = useFragment<useRoles_account$key>(fragment, user.account)

  const availableOptions = roles
    .filter((role) => role.active === true)
    .map((role) => ({
      value: role.id,
      label: role.name,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useRoles
