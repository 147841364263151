/**
 * @generated SignedSource<<4c2f6fabf1c670cca434ce17c49bf79e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsSection_project$data = {
  readonly client: {
    readonly account: {
      readonly id: number;
      readonly name: string;
    };
    readonly account_id: number;
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly name: string;
    readonly website: string | null | undefined;
  };
  readonly confirmed: boolean;
  readonly id: number;
  readonly members: ReadonlyArray<{
    readonly id: number;
    readonly is_placeholder: boolean;
    readonly just_added_timestamp: number | null | undefined;
    readonly person: {
      readonly active: boolean;
      readonly first_name: string;
      readonly id: number;
      readonly last_name: string;
    };
    readonly person_id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly name: string;
  readonly pricing_model: string;
  readonly project_roles: ReadonlyArray<{
    readonly id: number;
    readonly role_id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_project">;
  readonly " $fragmentType": "ProjectDetailsSection_project";
};
export type ProjectDetailsSection_project$key = {
  readonly " $data"?: ProjectDetailsSection_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsSection_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsSection_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetails_project"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_placeholder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "just_added_timestamp",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_roles",
      "kind": "LinkedField",
      "name": "project_roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "clients",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "accounts",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "b1753c6b47a7583a30373c1424f4661e";

export default node;
