import React, { useEffect, useMemo, useState } from "react"
import { ActionMeta } from "react-select"

import styles from "./ProjectWorkstreamSelector.module.css"

import { sortByString } from "~/helpers/sorting-helpers"

import Select, { ReactSelectProps } from "~/common/Select"

import AvailableOnPlansChip from "~/Entitlements/AvailableOnPlansChip"
import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"

type Option = {
  value: number | string
  label: string
  archived: boolean
  isFixed: boolean
}

export type ProjectWorkstreamSelectorProps = {
  allWorkstreams: Option[]
  onChange: (workstreams: Option[]) => void
  existingWorkstreams: readonly string[]
  selectProps?: Partial<ReactSelectProps>
}

const ProjectWorkstreamSelector = (props: ProjectWorkstreamSelectorProps) => {
  const { allWorkstreams, onChange, existingWorkstreams, selectProps } = props

  const sortedOptions = allWorkstreams
    .filter((w) => !w.archived)
    .sort((a, b) => sortByString(a.label, b.label))

  const existingWorkstreamValues = useMemo(() => {
    return Array.isArray(existingWorkstreams)
      ? allWorkstreams.filter((value) =>
          existingWorkstreams.includes(value.label),
        )
      : []
  }, [existingWorkstreams]) // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedWorkstreams, setSelectedWorkstreams] = useState(
    existingWorkstreamValues,
  )

  useEffect(() => {
    setSelectedWorkstreams(existingWorkstreamValues)
  }, [existingWorkstreamValues])

  const handleChange = (
    selectedOptions: Option[],
    actionMeta?: ActionMeta<Option>,
  ) => {
    if (actionMeta) {
      switch (actionMeta.action) {
        case "remove-value":
        case "pop-value":
          if (actionMeta.removedValue?.isFixed) {
            return
          }
          break
        case "clear":
          selectedOptions = sortedOptions.filter((v) => v.isFixed)
          break
        default:
          break
      }
    }

    setSelectedWorkstreams(selectedOptions)
    onChange(selectedOptions)
  }

  const selectorStyles = {
    multiValue: (base, state) => {
      const newStyles = { ...base, backgroundColor: "var(--snow)" }

      return state.data.isFixed
        ? { ...newStyles, cursor: "not-allowed" }
        : newStyles
    },
    multiValueLabel: (base, state) => {
      const newStyles = { ...base, fontSize: "13px" }

      return state.data.isFixed
        ? {
            ...newStyles,
            color: "var(--shadow)",
            paddingRight: 6,
          }
        : newStyles
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base
    },
  }

  const hasDisabledWorkstreamOptions = sortedOptions.some((v) => v.isFixed)

  return (
    <>
      <Select
        name="project-workstream-selector"
        id="project-workstream-selector"
        label={
          <div className={styles.title}>
            <span>Workstreams (optional)</span>
            <AvailableOnPlansChip featureId={ChargebeeFeatures.workstreams} />
          </div>
        }
        isMulti
        styles={selectorStyles}
        placeholder="Select workstreams or type to create new"
        options={sortedOptions}
        isCreatable
        isClearable={!hasDisabledWorkstreamOptions}
        onChange={handleChange}
        noOptionsMessage={() => "No workstreams"}
        value={selectedWorkstreams}
        {...selectProps}
      />
      {hasDisabledWorkstreamOptions && (
        <p className={styles.infoText}>
          Workstreams with existing Project Members cannot be removed
        </p>
      )}
    </>
  )
}

export default ProjectWorkstreamSelector
