import * as relayids from "~/helpers/relayids"

import * as computedFields from "./common/computed-fields"
import * as relationships from "./common/relationships"

import type { Model } from "~/subscriptions/AuditLogSubscription/types"

type Milestone = {
  id: number
  project_id: number
  date: string
}

const milestoneSubscription: Model<Milestone> = {
  tableName: "milestones",
  relationships: [relationships.project("milestones")],
  getDataId: (row) => relayids.milestones.encode(row.id),
  transformFields: {
    id: computedFields.id("milestones"),
    date: computedFields.date,
  },
}

export default milestoneSubscription
