import { groupBy, startCase } from "lodash-es"
import React, { useMemo } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { ManagerSelectorQuery } from "./__generated__/ManagerSelectorQuery.graphql"

import { Permissions, UserType } from "~/helpers/permissions"
import { formatName } from "~/helpers/person"
import { Option } from "~/types/helpers"

import Select from "~/common/Select"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"

import { SimpleLabel } from "../labels"

type Props = {
  selectedManagers: Option<number>[]
  setSelectedManagers: (managers: any[]) => void
  type: "people" | "projects"
}

const QUERY = graphql`
  query ManagerSelectorQuery {
    current_account {
      id
      user_accounts {
        id
        permissions
        user {
          id
          permissions
          first_name
          last_name
        }
      }
    }
  }
`

const ManagerSelector = (props: Props) => {
  const { selectedManagers, setSelectedManagers } = props

  const singularType = props.type === "projects" ? "project" : "person"
  const { current_account } = useLazyLoadQuery<ManagerSelectorQuery>(QUERY, {})

  const userGroups = useMemo(() => {
    const selectableUsers = current_account.user_accounts.filter(
      (userAccount) =>
        (userAccount.user.permissions as Permissions).type !==
        UserType.Superuser,
    )

    const userAccountsGroupedByPermissions = groupBy(
      selectableUsers,
      (ua) =>
        ua.permissions[
          props.type === "projects" ? "manage_projects" : "manage_people"
        ],
    )

    const restrictedManagerUsersOptions = (
      userAccountsGroupedByPermissions.restricted ?? []
    ).map((userAccount) => ({
      value: userAccount.user.id,
      label: formatName(
        userAccount.user.first_name,
        userAccount.user.last_name,
      ),
      isDisabled: false,
    }))

    const allManagerUsersOptions = (
      userAccountsGroupedByPermissions.all ?? []
    ).map((userAccount) => ({
      value: userAccount.user.id,
      label: formatName(
        userAccount.user.first_name,
        userAccount.user.last_name,
      ),
      isDisabled: true,
    }))

    const groupOptions = [
      {
        label: (
          <TitledHelpTooltip
            title="Users that can be granted manager access"
            tooltipContent={`These users do not currently have access to edit this ${singularType}`}
          />
        ),
        options: restrictedManagerUsersOptions,
      },
      {
        label: (
          <TitledHelpTooltip
            title={`Users who can manage all ${props.type}`}
            tooltipContent="These users have Admin or Manager access"
          />
        ),
        options: allManagerUsersOptions,
      },
    ]

    return groupOptions
  }, [current_account.user_accounts, props.type, singularType])

  return (
    <Select
      id={`${singularType}-managers`}
      value={selectedManagers}
      isMulti
      onChange={setSelectedManagers}
      label={
        <TitledHelpTooltip
          title={
            <div
              style={{
                display: "flex",
                columnGap: "var(--spacing-xxs)",
                alignItems: "center",
              }}
            >
              <div>Manager</div>
              <SimpleLabel text={startCase(props.type)} />
              <div>(optional)</div>
            </div>
          }
          tooltipContent={`Assign users to manage this ${singularType}`}
        />
      }
      options={userGroups}
      placeholder="Select or search for user"
    />
  )
}

export default ManagerSelector
