import cc from "classcat"
import React from "react"

import styles from "./MilestoneIcon.module.css"

import EmojiPicker from "~/common/Emoji/EmojiPicker"

type Props = {
  deselected?: boolean
  emoji: string | null
  onClick?: (emoji: string | null) => void
  size?: number
  className?: string
}

const MilestoneEmojiPicker = (props: Props) => {
  const { deselected, size = 24, className, emoji, onClick } = props
  const defaultImage = () => {
    return <span>{emoji || "😀"}</span>
  }

  return (
    <div
      className={cc([
        styles.icon,
        {
          [styles.deselected]: deselected,
          [className]: className,
          [styles.small]: size < 20,
        },
      ])}
      style={{
        minWidth: size,
        width: size,
        height: size,
        minHeight: size,
      }}
    >
      <EmojiPicker
        className={styles.emojiIcon}
        setSelectedEmoji={onClick}
        selectedEmoji={emoji}
        DefaultImage={defaultImage}
      />
    </div>
  )
}

export default MilestoneEmojiPicker
