/**
 * @generated SignedSource<<a48a19884ac1a1ed8bc41f76b4df7b76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MilestoneDeleteMutation$variables = {
  id: number;
};
export type MilestoneDeleteMutation$data = {
  readonly deleteMilestone: {
    readonly project: {
      readonly calc_end_date: string | null | undefined;
      readonly calc_start_date: string | null | undefined;
      readonly id: number;
      readonly milestones: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly milestones_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
    } | null | undefined;
  };
};
export type MilestoneDeleteMutation = {
  response: MilestoneDeleteMutation$data;
  variables: MilestoneDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteMilestoneResult",
    "kind": "LinkedField",
    "name": "deleteMilestone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_start_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_end_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "milestones",
            "kind": "LinkedField",
            "name": "milestones",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "milestones_aggregate",
            "kind": "LinkedField",
            "name": "milestones_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "milestones_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MilestoneDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MilestoneDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f8f1f1e27e7c69c0d2feb89e64c08bfa",
    "id": null,
    "metadata": {},
    "name": "MilestoneDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation MilestoneDeleteMutation(\n  $id: Int!\n) {\n  deleteMilestone(id: $id) {\n    project {\n      id\n      calc_start_date\n      calc_end_date\n      milestones {\n        id\n      }\n      milestones_aggregate {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f511e8d8745acbd5f679a9c295a99e3a";

export default node;
