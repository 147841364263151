import cc from "classcat"
import React from "react"

import styles from "./TooltipContent.module.css"

type Props = {
  background: string
  square?: boolean
}

const ChartTooltipIcon = (props: Props) => {
  const { background, square = false } = props

  return (
    <div
      className={cc([
        styles.colorIcon,
        {
          [styles.colorIconSquare]: square,
        },
      ])}
      style={{ background }}
    />
  )
}

export { ChartTooltipIcon }
