import React, { useState } from "react"

import styles from "./SelectPersonItem.module.css"

import { SelectorListItem } from "~/common/SelectorModal"
import Checkbox from "~/common/inputs/Checkbox"

import RoleSelector from "~/Planner/RoleSelector"

import Avatar from "../Avatar"
import { SelectOption } from "../SelectorModal/SelectorHelpers"
import BlueLink from "../buttons/BlueLink"

type Props = {
  selected: boolean
  filteredRoleIds: number[]
  onPlaceholderSelectionChange: (index: number) => void
  onAddPlaceholderRow: () => void
  selectedPlaceholderRole: RoleOption
  setSelectedPlaceholderRole: (index: number, role: SelectOption) => void
  setPlaceholderCreateCount: (index: number, count: number) => void
  placeholderCreateCount: number
  index: number
  isLastRow: boolean
  rolesInProject: number[]
}

type RoleOption = {
  value: number
  label: string
}

const MAX_PLACEHOLDER_CREATE = 30

const SelectPlaceholderItem = (props: Props) => {
  const {
    selected,
    filteredRoleIds,
    onPlaceholderSelectionChange,
    selectedPlaceholderRole,
    setSelectedPlaceholderRole,
    setPlaceholderCreateCount,
    placeholderCreateCount,
    index,
    onAddPlaceholderRow,
    isLastRow,
    rolesInProject,
  } = props

  const [showPlaceholderMenu, setShowPlaceholderMenu] = useState(false)

  const onSelectPlaceholderRole = (role: RoleOption) => {
    if (!selectedPlaceholderRole) {
      onPlaceholderSelectionChange(index)
    }

    setSelectedPlaceholderRole(index, role)
  }

  const handlePlaceholderItemClick = () => {
    if (selectedPlaceholderRole) {
      onPlaceholderSelectionChange(index)
    } else {
      setShowPlaceholderMenu(true)
    }
  }

  const handlePlaceholderCountChange = (e) => {
    const absoluteValue = Math.min(
      Math.abs(Number(e.target.value)),
      MAX_PLACEHOLDER_CREATE,
    )

    if (!Number.isInteger(absoluteValue)) {
      return
    }

    setPlaceholderCreateCount(index, absoluteValue)
  }

  const showAddPlaceholderButton =
    selected && isLastRow && filteredRoleIds.length > 0

  const filterRolePredicate = (role: { id: number; active: boolean }) => {
    if (role.active === false) {
      return rolesInProject.includes(role.id)
    } else {
      return Array.isArray(filteredRoleIds)
        ? filteredRoleIds.includes(role.id)
        : true
    }
  }

  return (
    <div
      style={{ position: "relative" }}
      data-test={`add-placeholder-row-${index}`}
    >
      <SelectorListItem isPlaceholder tall={showAddPlaceholderButton}>
        <Avatar
          is_placeholder
          size={28}
          name={selected ? "" : "Placeholder"}
          maxTextWidth={"400px"}
        />
        {selected && (
          <>
            <span style={{ marginLeft: "10px" }}>Add</span>
            <input
              type="number"
              value={placeholderCreateCount || ""}
              className={styles.placeholderCreateCount}
              onClick={(e) => e.stopPropagation()}
              onChange={handlePlaceholderCountChange}
            />
            <span style={{ marginLeft: "5px" }}>
              Placeholder{placeholderCreateCount > 1 && "s"}
            </span>
          </>
        )}
      </SelectorListItem>
      <RoleSelector
        name="placeholder-role-select"
        defaultValue={selectedPlaceholderRole}
        onChange={onSelectPlaceholderRole}
        onFocus={() => setShowPlaceholderMenu(true)}
        onBlur={() => setShowPlaceholderMenu(false)}
        blurInputOnSelect
        filterPredicate={filterRolePredicate}
        filterNonActive={false}
        placeholder="Choose a role"
        className={styles.select}
        menuIsOpen={showPlaceholderMenu}
        onMenuClose={() => setShowPlaceholderMenu(false)}
        menuShouldBlockScroll={false}
        height={30}
      />
      <div className={styles.checkboxContainer}>
        <Checkbox
          data-test={`add-placeholder-checkbox-${selectedPlaceholderRole?.label ?? "no-role"}`}
          id={`SelectorListItem_Checkbox_isSelectedPlaceholder`}
          value="placeholder"
          onChange={handlePlaceholderItemClick}
          checked={selected}
        />
      </div>
      {showAddPlaceholderButton && (
        <div className={styles.addNewPlaceholderButton}>
          <BlueLink
            text="Add another placeholder"
            onClick={onAddPlaceholderRow}
          />
        </div>
      )}
    </div>
  )
}

export default SelectPlaceholderItem
