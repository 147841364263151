import numbro from "numbro"
import React, { useState } from "react"

import CurrencyInput from "~/common/CurrencyInput"

type Props = {
  onUpdate: (val: number) => void
  currency: string
  value: number
  name: string
  dataTest?: string
  maxWidth?: number
  disabled?: boolean
}

const ExpenseBudget = (props: Props) => {
  const { onUpdate, currency, value, name, dataTest, maxWidth, disabled } =
    props
  const [budget, setBudget] = useState<string>(String(value))
  const [prevValue, setPrevValue] = useState<number>(value)

  if (prevValue !== value) {
    setBudget(String(value))
    setPrevValue(value)
  }

  const formatBudget = (val) => {
    if (!val) {
      return setBudget("0")
    }
    if (!Number(val)) {
      return setBudget(budget)
    }

    const budgetValue = numbro(val).format({
      thousandSeparated: true,
      mantissa: 0,
    })
    setBudget(budgetValue)
  }

  const handleUpdate = (e) => {
    const val = Number(numbro.unformat(e.target.value))

    if (Number.isNaN(budget)) {
      return setBudget(budget)
    }

    onUpdate(val)
  }

  return (
    <CurrencyInput
      name={name}
      value={budget}
      currency={currency}
      height={30}
      onBlur={handleUpdate}
      onChange={formatBudget}
      dataTest={dataTest}
      maxWidth={maxWidth}
      disabled={disabled}
      selectAllOnFocus
    />
  )
}

export default ExpenseBudget
