import { Switch } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./FilterMenuSwitch.module.css"

type Props = {
  label: string
  checked: boolean
  onChange: React.FormEventHandler<HTMLInputElement>
  switchClassName?: string
}

const FilterMenuSwitch = ({
  label,
  checked,
  onChange,
  switchClassName,
}: Props) => {
  return (
    <div className={styles.container}>
      <Switch
        alignIndicator="right"
        label={label}
        checked={checked}
        onChange={onChange}
        className={cc([styles.switch, switchClassName])}
      />
    </div>
  )
}

export default FilterMenuSwitch
