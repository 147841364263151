/**
 * @generated SignedSource<<e3665334136061adec5e6e69fd4445cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DragToCreateOutline_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlannerDatePicker_account">;
  readonly " $fragmentType": "DragToCreateOutline_account";
};
export type DragToCreateOutline_account$key = {
  readonly " $data"?: DragToCreateOutline_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"DragToCreateOutline_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DragToCreateOutline_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerDatePicker_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "a531b42a953b7e0c93e2bd8e7359f2c4";

export default node;
