import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { getSettings, setSetting } from "~/localsettings"

const pages = ["projects", "people"] as const
type Page = (typeof pages)[number]

export const isValidPage = (page: any): page is Page => pages.includes(page)

const getInitialState = (): Page => getSettings().activePage ?? "projects"

export const activePageSlice = createSlice({
  name: "activePage",
  initialState: getInitialState,
  reducers: {
    changeActivePage: (_state, action: PayloadAction<Page>) => {
      // TODO remove this from the reducer as it is a side effect
      setSetting("activePage", action.payload)
      return action.payload
    },
  },
})

const { actions, reducer } = activePageSlice

export const { changeActivePage } = actions
export default reducer
