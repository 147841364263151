/**
 * @generated SignedSource<<74cfde85a538e34a21e9eefc85734549>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonTimeOffRow_account$data = {
  readonly default_full_time_minutes: number;
  readonly id: number;
  readonly projects: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"DragToCreateOutline_account" | "TimeOffActionPill_account">;
  readonly " $fragmentType": "PersonTimeOffRow_account";
};
export type PersonTimeOffRow_account$key = {
  readonly " $data"?: PersonTimeOffRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonTimeOffRow_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonTimeOffRow_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "projectsFilter"
        }
      ],
      "concreteType": "projects",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DragToCreateOutline_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeOffActionPill_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "a26cf6af85481cae8884a4085712c6e9";

export default node;
