import React from "react"

import styles from "./ProjectBudget.module.css"

import { formatCurrency } from "~/helpers/CurrencyHelper"

import Column from "./TableColumn"
import Row from "./TableRow"

export type ExpenseTotals = {
  margin: string
  profit: number
  cost: number
  charge: number
}

type Props = {
  title?: string
  showNonBillableView?: boolean
  totalExpenses: ExpenseTotals
}

const columnStyle = { paddingRight: "10px" }

const ExpenseTotalsRow = (props: Props) => {
  const { title = "Total", showNonBillableView = false, totalExpenses } = props
  return (
    <div className={styles.totalsWrapper}>
      <Row>
        <Column textAlign="left" span={showNonBillableView ? 5 : 2}>
          <span>{title}</span>
        </Column>
        {!showNonBillableView && (
          <Column dataTest="ProjectExpenseTotals_totalMargin" textAlign="right">
            {totalExpenses.margin}%
          </Column>
        )}
        {!showNonBillableView && (
          <Column dataTest="ProjectExpenseTotals_totalProfit" textAlign="right">
            {formatCurrency(totalExpenses.profit)}
          </Column>
        )}

        <Column
          dataTest="ProjectExpenseTotals_totalCost"
          style={columnStyle}
          textAlign="right"
        >
          {formatCurrency(totalExpenses.cost)}
        </Column>

        {!showNonBillableView && (
          <Column
            dataTest="ProjectExpenseTotals_totalCharge"
            style={columnStyle}
            textAlign="right"
          >
            {formatCurrency(totalExpenses.charge)}
          </Column>
        )}
      </Row>
    </div>
  )
}

export default ExpenseTotalsRow
