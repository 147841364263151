import cc from "classcat"
import React, { PropsWithChildren } from "react"

import styles from "./ProjectBudget.module.css"

type Props = PropsWithChildren<{
  sticky?: boolean
  className?: string
  offset?: number
  style?: Record<string, any>
}>

const TableInfo = (props: Props) => {
  const { sticky = false, children, className, offset = 0, style = {} } = props
  const classes = cc([styles.tableInfo, sticky && styles.sticky, className])
  if (sticky) {
    style.bottom = offset
  }

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

export default TableInfo
