import { hoursToMinutes } from "date-fns"
import * as React from "react"

import { roundToHalf } from "~/common/EffortInput/CapacityPercentageInput"

type CapacityPercentageLabelProps = {
  minutesPerDay: number
  defaultFullTimeMinutes: number
  contractMinutesPerDay?: number
}

export const CapacityPercentageLabel = ({
  minutesPerDay,
  defaultFullTimeMinutes = hoursToMinutes(8),
  contractMinutesPerDay,
}: CapacityPercentageLabelProps) => {
  const capacityPercentage = roundToHalf(
    (minutesPerDay / (contractMinutesPerDay ?? defaultFullTimeMinutes)) * 100,
  )

  return <>{capacityPercentage}%</>
}
