/**
 * @generated SignedSource<<c96a18d0a831394a77ae2ec4626664a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PlannerFilterQueryQuery$variables = Record<PropertyKey, never>;
export type PlannerFilterQueryQuery$data = {
  readonly current_user: {
    readonly id: number;
    readonly view: {
      readonly id: number;
      readonly people_filters: any;
      readonly project_filters: any;
    } | null | undefined;
  } | null | undefined;
};
export type PlannerFilterQueryQuery = {
  response: PlannerFilterQueryQuery$data;
  variables: PlannerFilterQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "views",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_filters",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "people_filters",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlannerFilterQueryQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlannerFilterQueryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "461f823a7d4f0c6af3396ffadb821a66",
    "id": null,
    "metadata": {},
    "name": "PlannerFilterQueryQuery",
    "operationKind": "query",
    "text": "query PlannerFilterQueryQuery {\n  current_user {\n    id\n    view {\n      id\n      project_filters\n      people_filters\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e13a588bc75ed4fd589a8ba5b40f929a";

export default node;
