import { DPMonthsPropGettersConfig, DPPropGetter } from "@rehookify/datepicker"
import React from "react"

type Props = DPPropGetter & {
  type: "prev" | "next"
} & DPMonthsPropGettersConfig

const NextIcon = () => (
  <path
    d="M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z"
    fillRule="evenodd"
  ></path>
)

const PrevIcon = () => (
  <path
    d="M7.41 8l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71l-4 4C5.11 7.47 5 7.72 5 8c0 .28.11.53.29.71l4 4a1.003 1.003 0 001.42-1.42L7.41 8z"
    fillRule="evenodd"
  ></path>
)

const CalendarNavButton = (props: Props) => {
  const { type, ...buttonProps } = props
  const dir = type === "prev" ? "left" : "right"
  return (
    <div className="bp5-datepicker-navbar DayPicker-NavBar">
      <button
        type="button"
        aria-label={`Go to ${type === "prev" ? "previous" : "next"} month`}
        {...buttonProps}
        className={`bp5-button bp5-minimal DayPicker-NavButton DayPicker-NavButton--${type}`}
      >
        <span aria-hidden="true" className={`bp5-icon bp5-icon-chevron-${dir}`}>
          <svg
            data-icon={`chevron-${dir}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            role="img"
          >
            {type === "prev" ? <PrevIcon /> : <NextIcon />}
          </svg>
        </span>
      </button>
    </div>
  )
}

export default CalendarNavButton
