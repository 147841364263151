/**
 * @generated SignedSource<<ee378c6e67a927f93cd4e21f3b0e80f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeOffSummary_account$data = {
  readonly default_full_time_minutes: number;
  readonly id: number;
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly archivable: boolean | null | undefined;
    readonly assignments: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly is_billable: boolean | null | undefined;
      readonly is_template: boolean;
      readonly minutes_per_day: number;
      readonly non_working_day: boolean;
      readonly note: string | null | undefined;
      readonly person_id: number;
      readonly phase_id: number | null | undefined;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
      readonly total_minutes: number | null | undefined;
      readonly workstream_id: number | null | undefined;
    }>;
    readonly competencies: ReadonlyArray<{
      readonly id: number;
      readonly level: number | null | undefined;
      readonly skill: {
        readonly id: number;
        readonly name: string;
      };
    }>;
    readonly contracts: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly minutes_per_day: number | null | undefined;
      readonly role: {
        readonly id: number;
        readonly name: string | null | undefined;
      };
      readonly start_date: string | null | undefined;
    }>;
    readonly email: string | null | undefined;
    readonly first_name: string;
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly is_placeholder: boolean;
    readonly last_name: string;
    readonly placeholder_suggestions: ReadonlyArray<{
      readonly suggested_person_id: number;
    }>;
    readonly tags: any;
    readonly team: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
    readonly time_offs: ReadonlyArray<{
      readonly end_date: string | null | undefined;
      readonly ext_time_off_links: ReadonlyArray<{
        readonly active: boolean;
        readonly ext_time_off_id: number;
        readonly external: {
          readonly __typename: "ext_time_off";
          readonly id: number;
          readonly integration_id: number;
        } | null | undefined;
        readonly external_id: number;
        readonly time_off_id: number;
      }>;
      readonly id: number;
      readonly leave_type: string;
      readonly minutes_per_day: number | null | undefined;
      readonly note: string | null | undefined;
      readonly person_id: number;
      readonly start_date: string | null | undefined;
    }>;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"TimeOffSummaryAddRow_account" | "TimeOffSummaryPersonRow_account">;
  readonly " $fragmentType": "TimeOffSummary_account";
};
export type TimeOffSummary_account$key = {
  readonly " $data"?: TimeOffSummary_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeOffSummary_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v2 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v3 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeOffSummary_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "peopleFilter"
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_placeholder",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archivable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "placeholder_suggestions",
          "kind": "LinkedField",
          "name": "placeholder_suggestions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggested_person_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "contracts",
          "kind": "LinkedField",
          "name": "contracts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "roles",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v6/*: any*/),
          "concreteType": "time_offs",
          "kind": "LinkedField",
          "name": "time_offs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leave_type",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "where",
                  "value": {
                    "active": {
                      "_eq": true
                    }
                  }
                }
              ],
              "concreteType": "ext_time_off_link",
              "kind": "LinkedField",
              "name": "ext_time_off_links",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time_off_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ext_time_off_id",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": "external_id",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ext_time_off_id",
                  "storageKey": null
                },
                {
                  "alias": "external",
                  "args": null,
                  "concreteType": "ext_time_off",
                  "kind": "LinkedField",
                  "name": "ext_time_off",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "integration_id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v6/*: any*/),
          "concreteType": "assignments",
          "kind": "LinkedField",
          "name": "assignments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_billable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_template",
              "storageKey": null
            },
            (v4/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "project_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phase_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workstream_id",
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "non_working_day",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total_minutes",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "teams",
          "kind": "LinkedField",
          "name": "team",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "competencies",
          "kind": "LinkedField",
          "name": "competencies",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "skills",
              "kind": "LinkedField",
              "name": "skill",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeOffSummaryPersonRow_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeOffSummaryAddRow_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "c54feef3f386501ad9018971139c63c9";

export default node;
