import * as fe from "@runn/filter-engine"
import { graphql, useFragment } from "react-relay"

import type { useSkills_account$key } from "./__generated__/useSkills_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

const fragment = graphql`
  fragment useSkills_account on accounts {
    skills {
      id
      name
    }
  }
`

type Options = {
  user: SuperSearch_user$data
  skillLevelList: fe.filters.SkillWithLevelList
}

const useSkills = (options: Options) => {
  const { user, skillLevelList } = options

  const { skills } = useFragment<useSkills_account$key>(fragment, user.account)

  const availableOptions = skills
    .map((skill) => {
      const level =
        skillLevelList.find((item) => item.skillId === skill.id)?.level ?? []
      return {
        value: {
          skillId: skill.id,
          level,
        },
        label: skill.name,
      }
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useSkills
