import React, { useEffect, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch } from "react-redux"

import styles from "./PeoplePlaceholderGroup.module.css"

import { PersonManagement_user$data } from "~/PeoplePlanner/__generated__/PersonManagement_user.graphql"

import { allPlaceholdersGroupName } from "~/helpers/group-helpers"
import { getVisiblePlaceholders } from "~/helpers/placeholder-helpers"

import HeadingBar from "~/common/HeadingBar"
import PeopleHeadingBar from "~/common/PeopleHeadingBar"
import { useSidePanel } from "~/common/SidePanel/SidePanel"

import PersonRow from "~/PeoplePlanner/PersonRow"
import { useFilteredPlanner } from "~/Planner/FilteredQuery/useFilteredPlanner"
import { useActiveQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import {
  SET_EXPANDED_PEOPLE_GROUPS,
  SET_SELECTED_PLACEHOLDER,
} from "~/Planner/Planner.actions"
import SplitRow from "~/Planner/PlannerLayout/SplitRow"
import { selectGroupPeopleBy } from "~/Planner/reducer2/peopleSortSlice"
import { useAppSelector } from "~/hooks/redux"
import useGroupUtilizationConfig from "~/hooks/useGroupUtilizationConfig"
import { useIsInViewport } from "~/hooks/useIsInViewport"
import { getSettings, setSetting } from "~/localsettings"

import PlaceholderCapacityIndicator from "../PlaceholderRow/PlaceholderCapacityIndicator"

import GroupUtilizationBar from "./GroupUtilizationBarRelayWrapper"
import PeopleGroupHeadingContainer from "./PeopleGroupHeadingContainer"

type Props = {
  visiblePeople: PersonManagement_user$data["account"]["people"]
  projects: PersonManagement_user$data["account"]["projects"]
  groupName: string
  favouritePersonIds: PersonManagement_user$data["favourite_people"]
  onlyOneResult: boolean
  companyDefaultMinutes: number
}

const closeOpenedPeople = (groupName: string) => {
  const plannerPeopleExpanded = getSettings()?.plannerPeopleExpanded || []
  // remove expanded people
  const newPlannerPeopleExpanded = plannerPeopleExpanded.filter(
    (row) => row.groupName !== groupName,
  )
  setSetting("plannerPeopleExpanded", newPlannerPeopleExpanded)
}

const PeoplePlaceholderGroup = (props: Props) => {
  const {
    groupName,
    visiblePeople,
    projects,
    favouritePersonIds,
    onlyOneResult,
    companyDefaultMinutes,
  } = props

  const peoplePlaceholderGroupRef = React.useRef<HTMLDivElement>(null)

  const { isOpen: sidePanelIsOpen } = useSidePanel()
  const isPeoplePlaceholderGroupInViewport = useIsInViewport(
    peoplePlaceholderGroupRef,
    {
      delay: 0,
      offsetY: 0,
      rootQuerySelector: null,
      keepVisible: !sidePanelIsOpen,
    },
  )

  const queryState = useActiveQueryLimit()

  const { totalPeopleCount: totalCount } = useFilteredPlanner()

  const dispatch = useDispatch()

  const calStartNum = useAppSelector((state) => state.calendar.calStartNum)
  const calEndNum = useAppSelector((state) => state.calendar.calEndNum)

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const expandedPeopleGroups = useAppSelector(
    (state) => state.planner.expandedPeopleGroups,
    isDeeplyEqual,
  )

  const expandAll: boolean = useAppSelector((state) => state.planner.expandAll)

  const selectedPlaceholder: number = useAppSelector(
    (state) => state.planner.selectedPlaceholder,
  )

  useEffect(
    () => () => {
      // on unmount
      if (selectedPlaceholder) {
        dispatch({
          type: SET_SELECTED_PLACEHOLDER,
          payload: { placeholderId: null, roleName: "" },
        })
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const selectedGroup = expandedPeopleGroups.find(
    (g) => g.groupByFilter === peopleGroupBy,
  )

  const { isDefaultGroupAll, showGroupUtilization } =
    useGroupUtilizationConfig()

  const startOpen =
    isDefaultGroupAll ||
    onlyOneResult ||
    selectedGroup?.expanded?.includes(groupName) ||
    expandAll

  const [isOpen, setIsOpen] = useState(startOpen)

  useEffect(() => {
    setIsOpen(expandAll)
  }, [expandAll]) // Expand all

  useEffect(() => {
    startOpen && setIsOpen(true)
  }, [startOpen]) // Open by default. Must go second

  useEffect(() => {
    if (groupName === allPlaceholdersGroupName) {
      setIsOpen(true)
    }
  }, [groupName])

  const filteredExpanded =
    selectedGroup?.expanded.filter((g) => g !== groupName) || []

  const placeholders = getVisiblePlaceholders(
    visiblePeople,
    projects,
    calStartNum,
    calEndNum,
  ) as any

  const peopleWithNoPlaceholders = visiblePeople.filter(
    (p) => !p.is_placeholder,
  )

  const isEmptyPlaceholderGroup = placeholders.length === 0
  const isEmptyPeopleGroup = peopleWithNoPlaceholders.length === 0

  const [showPlaceholders, setShowPlaceholders] = useState(false)
  const [showPeople, setShowPeople] = useState(true)

  useEffect(() => {
    if (selectedPlaceholder) {
      setShowPlaceholders(true)
      setIsOpen(true)
    }
  }, [selectedPlaceholder])

  useEffect(() => {
    if (isEmptyPeopleGroup && groupName !== allPlaceholdersGroupName) {
      setShowPlaceholders(true)
    }
  }, [isEmptyPeopleGroup, groupName])

  const selectedPlaceholderInGroup = placeholders.find(
    (p) => p.id === selectedPlaceholder,
  )

  const numberOfPlaceholdersInView = selectedPlaceholder
    ? !!selectedPlaceholderInGroup
      ? 1
      : 0
    : placeholders.length

  const count =
    isDefaultGroupAll && queryState.isOverflow
      ? totalCount
      : numberOfPlaceholdersInView + peopleWithNoPlaceholders.length

  const toggleGroup = () => {
    setIsOpen(!isOpen)

    const selectedGroupUpdated = {
      groupByFilter: peopleGroupBy,
      expanded: [],
    }

    if (isOpen) {
      // remove the selected groupName from expandedPeople
      selectedGroupUpdated.expanded = selectedGroup ? filteredExpanded : []
      closeOpenedPeople(groupName)
    } else {
      // add selected groupName to expandedPeople
      selectedGroupUpdated.expanded = selectedGroup
        ? [...filteredExpanded, groupName]
        : []
    }

    dispatch({
      type: SET_EXPANDED_PEOPLE_GROUPS,
      payload: [selectedGroupUpdated],
    })
    setSetting("expandedPeopleGroups", [selectedGroupUpdated])
  }

  const renderPlaceholders = () => {
    if (!isOpen || isEmptyPlaceholderGroup) {
      return null
    }

    return (
      <div>
        {groupName === allPlaceholdersGroupName ? (
          <PeopleHeadingBar
            text="Placeholders"
            placeholderCount={numberOfPlaceholdersInView}
            peopleCount={0}
            id="planner-placeholders-heading-bar"
            onClick={() => setShowPlaceholders(!showPlaceholders)}
            isCollapsed={!showPlaceholders}
            data-test={`${groupName}-${
              isOpen ? "expanded" : "collapsed"
            }-placeholders`}
          >
            <div className={styles.capacityWrapper}>
              <PlaceholderCapacityIndicator
                projects={projects}
                placeholders={placeholders}
                companyDefaultMinutes={companyDefaultMinutes}
                inHeadingBar
              />
            </div>
          </PeopleHeadingBar>
        ) : (
          <HeadingBar
            indented
            text="Placeholders"
            count={numberOfPlaceholdersInView}
            id="planner-placeholders-heading-bar"
            onClick={() => setShowPlaceholders(!showPlaceholders)}
            isCollapsed={!showPlaceholders}
            data-test={`${groupName}-${
              isOpen ? "expanded" : "collapsed"
            }-placeholders`}
            style={{ top: showGroupUtilization ? 55 : 30 }}
          >
            <div className={styles.capacityWrapper}>
              <PlaceholderCapacityIndicator
                projects={projects}
                placeholders={placeholders}
                companyDefaultMinutes={companyDefaultMinutes}
                inHeadingBar
              />
            </div>
          </HeadingBar>
        )}
        {showPlaceholders &&
          placeholders.map((placeholder) => (
            <div key={placeholder.id}>
              <PersonRow
                key={placeholder.id}
                personId={placeholder.id}
                person={placeholder}
                favourites={favouritePersonIds}
                onlyOneResult={onlyOneResult}
                groupCount={count}
                groupName={groupName}
              />
            </div>
          ))}
      </div>
    )
  }

  const renderPeople = () => {
    if (!isOpen || isEmptyPeopleGroup) {
      return null
    }

    return (
      <div>
        {!isEmptyPlaceholderGroup && (
          <HeadingBar
            indented
            text="People"
            count={peopleWithNoPlaceholders.length}
            id="planner-people-heading-bar"
            onClick={() => setShowPeople(!showPeople)}
            isCollapsed={!showPeople}
            data-test={`${groupName}-${
              isOpen ? "expanded" : "collapsed"
            }-people`}
            style={{ top: showGroupUtilization ? 55 : 30 }}
          />
        )}
        {showPeople &&
          peopleWithNoPlaceholders.map((person, index) => (
            // force the component to re-render when the sort order changes
            // so that the `useIsInViewport` inside the PersonRow can attach a new observer and keep the virtualization working.
            // This is needed because the Availability sort changes the order of the people asynchonously in an useEffect
            <div key={`${person.id}-${index}`}>
              <PersonRow
                key={person.id}
                personId={person.id}
                person={person}
                favourites={favouritePersonIds}
                onlyOneResult={onlyOneResult}
                groupCount={count}
                groupName={groupName}
                noPeopleHeadingBar={isEmptyPlaceholderGroup}
              />
            </div>
          ))}
      </div>
    )
  }

  if (groupName === allPlaceholdersGroupName) {
    return <div>{renderPlaceholders()}</div>
  }

  return (
    <div ref={peoplePlaceholderGroupRef}>
      <PeopleGroupHeadingContainer
        text={groupName}
        peopleCount={peopleWithNoPlaceholders.length}
        placeholderCount={placeholders.length}
        onClick={toggleGroup}
        isCollapsed={!isOpen}
        isMiniHeading={!showGroupUtilization}
        style={{ zIndex: 15 }}
        dataTest={`${groupName}-${isOpen ? "expanded" : "collapsed"}`}
      >
        {showGroupUtilization && isPeoplePlaceholderGroupInViewport && (
          <React.Suspense>
            <GroupUtilizationBar
              groupName={groupName}
              groupType={peopleGroupBy}
              peopleListIds={visiblePeople.map((p) => p.id)}
              projects={projects}
              isTransparent={false}
              sidePanelIsOpen={sidePanelIsOpen}
            />
          </React.Suspense>
        )}
      </PeopleGroupHeadingContainer>
      {renderPlaceholders()}
      {renderPeople()}
      {isOpen && <SplitRow />}
    </div>
  )
}

export default React.memo(PeoplePlaceholderGroup, isDeeplyEqual)
