import { dateHelpers } from "@runn/calculations"
import { differenceInDays } from "date-fns"
import sortBy from "lodash-es/sortBy"

type ContractDates = {
  start_date: string
  end_date: string
}

type Contract = {
  readonly id: number
  readonly start_date: string
  readonly end_date?: string
  readonly minutes_per_day?: number
}

export const getConsecutiveContractDates = <T extends ContractDates>(
  contracts: readonly T[],
): ContractDates[] => {
  // This only works if contracts are sorteed from oldest to latest
  const sortedContracts = sortBy(contracts, ["start_date"])
  return sortedContracts.reduce((acc, current) => {
    const currentRange = {
      start_date: current.start_date,
      end_date: current.end_date,
    }
    if (acc.length === 0) {
      return [currentRange]
    }
    const prev = acc[acc.length - 1]

    if (
      differenceInDays(
        dateHelpers.parseRunnDate(current.start_date),
        dateHelpers.parseRunnDate(prev.end_date),
      ) > 1
    ) {
      return [...acc, currentRange]
    } else {
      acc.pop()
      return [...acc, { ...prev, end_date: current.end_date }]
    }
  }, [] as Array<T>)
}

export const getMinutesPerDayForDate = (
  date: string,
  contracts: readonly Contract[],
  accountDefaultFullTimeMinutes: number,
): number => {
  const contractsWithDates = contracts.map((c) => {
    return {
      ...c,
      start_date: dateHelpers.parseRunnDate(c.start_date),
      end_date: c.end_date ? dateHelpers.parseRunnDate(c.end_date) : null,
    }
  })
  const dateAsDate = dateHelpers.parseRunnDate(date)

  const matchingContract = contractsWithDates.find((contract) => {
    if (contract.end_date) {
      return (
        dateAsDate >= contract.start_date && dateAsDate <= contract.end_date
      )
    } else {
      return dateAsDate >= contract.start_date
    }
  })

  return matchingContract
    ? (matchingContract.minutes_per_day ?? 0)
    : accountDefaultFullTimeMinutes
}
