import React from "react"
import { graphql } from "react-relay"
import { useFragment } from "react-relay"

import styles from "./WelcomeVideoDialog.module.css"

import { WelcomeVideoDialog_user$key } from "./__generated__/WelcomeVideoDialog_user.graphql"

import Dialog from "~/common/Dialog"
import {
  ModalBody,
  ModalFooter,
  ModalFormWrapper,
  ModalHeader,
} from "~/common/ModalForm"
import { Wistia } from "~/common/Wistia/Wistia"
import Button from "~/common/buttons/Button"

type Props = {
  gql: WelcomeVideoDialog_user$key
  onExploreTest: () => void
  onSetupAccount: () => void
  userCount: number
}

const WelcomeVideoDialog = ({
  gql,
  onExploreTest,
  onSetupAccount,
  userCount,
}: Props) => {
  const user = useFragment(
    graphql`
      fragment WelcomeVideoDialog_user on users {
        id
        first_name
        account {
          id
          name
        }
      }
    `,
    gql,
  )
  const { account, first_name: name } = user
  const accountIsSetup = userCount > 1
  return (
    <>
      <Dialog isOpen onClose={onSetupAccount} showCloseButton>
        <ModalFormWrapper wide>
          <ModalHeader text={`Welcome to Runn, ${name} 👋`} bold />
          <ModalBody wide>
            <div className={styles.textWrapper}>
              {accountIsSetup ? (
                <p>
                  You can view your {account.name} account, or play with the
                  sample data in the Test Account to explore how Runn works.
                </p>
              ) : (
                <p>
                  Get started by setting up your {account.name} account, or play
                  with the sample data in the Test Account to explore how Runn
                  works.
                </p>
              )}
              <br />

              <Wistia videoId="03psgus6yp" className={styles.iframeWrapper} />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={styles.footerButtonsContainer}>
              <Button
                text={accountIsSetup ? "View Account" : "Set Up Account"}
                outlined={false}
                onClick={onSetupAccount}
              />
              <b>OR</b>
              <Button
                text="Explore Shared Test Account"
                onClick={onExploreTest}
                outlined
              />
            </div>
          </ModalFooter>
        </ModalFormWrapper>
      </Dialog>
    </>
  )
}

export default WelcomeVideoDialog
