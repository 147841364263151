import React from "react"
import { graphql, useFragment } from "react-relay"

import { ProjectDetails_account$key } from "~/ProjectPlanner/ProjectRow/__generated__/ProjectDetails_account.graphql"
import { ProjectDetails_project$key } from "~/ProjectPlanner/ProjectRow/__generated__/ProjectDetails_project.graphql"
import { ProjectDetails_user$key } from "~/ProjectPlanner/ProjectRow/__generated__/ProjectDetails_user.graphql"

import { dashify } from "~/helpers/general-helpers"

import PersonRows from "~/ProjectPlanner/ProjectRow/PersonRows"
import ProjectGroupRow from "~/ProjectPlanner/ProjectRow/ProjectGroupRow"
import { useProjectMembersList } from "~/hooks/useProjectMembersList"

type Props = {
  groupName: string
  groupId: number
  project: ProjectDetails_project$key
  companyDefaultMinutes: number
  delayAssignmentRender: boolean
  locatedInSchedulePanel: boolean
  assignments: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    project_id: number
    role_id: number
    person_id: number
    workstream_id: number | null
  }>
  members: ReadonlyArray<{
    id: number
    project_id: number
    person_id: number
    role_id: number
    workstream_id: number
    is_placeholder: boolean
    just_added_timestamp: number
    person: {
      first_name: string
      last_name: string
      active: boolean
    }
  }>
  account: ProjectDetails_account$key
  user: ProjectDetails_user$key
}

const ProjectDetails = (props: Props) => {
  const {
    groupName,
    groupId,
    companyDefaultMinutes,
    delayAssignmentRender,
    locatedInSchedulePanel,
    assignments,
    members,
  } = props
  const account = useFragment(
    graphql`
      fragment ProjectDetails_account on accounts
      @argumentDefinitions(
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        id
        people(where: $peopleFilter) {
          id
          is_placeholder
          first_name
          last_name
          ...PersonRows_people @arguments(plannerStartDate: $plannerStartDate)
        }
        ...ProjectGroupRow_account @arguments(peopleFilter: $peopleFilter)
      }
    `,
    props.account,
  )
  const project = useFragment(
    graphql`
      fragment ProjectDetails_project on projects {
        id
        name
        ...PersonRows_project
        ...ProjectGroupRow_project
      }
    `,
    props.project,
  )

  const user = useFragment(
    graphql`
      fragment ProjectDetails_user on users {
        id
        ...PersonRows_user
      }
    `,
    props.user,
  )

  const projectMembers = (members ?? []).map(({ person, ...membership }) => ({
    ...membership,
    person,
    active: person.active,
  }))

  const { visible, all } = useProjectMembersList(project.id, {
    members: projectMembers,
    assignments,
  })
  const visibleMemberIDs = visible.map((m) => m.person_id)
  const visiblePeople = account.people.filter((p) =>
    visibleMemberIDs.includes(p.id),
  )

  return (
    <div
      key={groupId}
      data-test={`project-details-section-${dashify(
        `${project.name} ${groupName}`,
      )}`}
    >
      <ProjectGroupRow
        account={account}
        groupName={groupName}
        groupId={groupId}
        project={project}
        visibleMembers={visible}
        groupMembers={all}
      />
      <PersonRows
        user={user}
        people={visiblePeople}
        groupId={groupId}
        groupName={groupName}
        project={project}
        companyDefaultMinutes={companyDefaultMinutes}
        delayAssignmentRender={delayAssignmentRender}
        locatedInSchedulePanel={locatedInSchedulePanel}
      />
    </div>
  )
}

export default ProjectDetails
