import { PayloadAction, createSlice } from "@reduxjs/toolkit"

/**
 * The unit to display the effort of work on the UI in, used to convert the
 * stored value in hours if required.
 *
 * | Unit               | Example  |
 * | ------------------ | :------- |
 * | hoursPerDay        | 4h/day   |
 * | hoursPerWeek       | 20h/week |
 * | fullTimeEquivalent | 0.5FTE   |
 * | capacityPercentage | 80%      |
 */
export type EffortDisplayUnit =
  | "hoursPerDay"
  | "hoursPerWeek"
  | "fullTimeEquivalent"
  | "capacityPercentage"

const effortDisplayUnitSlice = createSlice({
  name: "effortDisplayUnit",
  initialState: "hoursPerDay" as EffortDisplayUnit,
  reducers: {
    /**
     * Toggle between the two modes of displaying effort
     */
    toggledEffortDisplayUnit: (state) => {
      if (state === "hoursPerDay") {
        return "hoursPerWeek"
      }
      if (state === "hoursPerWeek") {
        return "hoursPerDay"
      }
      return "hoursPerDay"
    },
    changedEffortDisplayUnit: (
      state,
      action: PayloadAction<EffortDisplayUnit | string>,
    ) => {
      if (
        action.payload === "hoursPerWeek" ||
        action.payload === "hoursPerDay" ||
        action.payload === "fullTimeEquivalent" ||
        action.payload === "capacityPercentage"
      ) {
        return action.payload
      }
      return state
    },
  },
})

export const {
  toggledEffortDisplayUnit: toggledEffortDisplayUnit,
  changedEffortDisplayUnit: changedEffortDisplayUnit,
} = effortDisplayUnitSlice.actions

export default effortDisplayUnitSlice.reducer
