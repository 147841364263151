/**
 * @generated SignedSource<<a74a80c5055aa4c89b5fc85ff746c50e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeOffSummaryPersonRow_account$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"DragToCreateOutline_account" | "TimeOffActionPill_account">;
  readonly " $fragmentType": "TimeOffSummaryPersonRow_account";
};
export type TimeOffSummaryPersonRow_account$key = {
  readonly " $data"?: TimeOffSummaryPersonRow_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeOffSummaryPersonRow_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeOffSummaryPersonRow_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeOffActionPill_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DragToCreateOutline_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "0474f43dad931f8eccfcc60e7394ab56";

export default node;
