import { useFeature } from "flagged"
import React from "react"
import { graphql, useFragment } from "react-relay"

import type { DetectedPeopleSection_account$key } from "./__generated__/DetectedPeopleSection_account.graphql"
import type { DetectedPeopleSection_project$key } from "./__generated__/DetectedPeopleSection_project.graphql"
import type { DetectedPeopleSection_user$key } from "./__generated__/DetectedPeopleSection_user.graphql"

import DetectedPersonRows from "./ProjectRow/DetectedPersonRows"
import ProjectGroupRow from "./ProjectRow/ProjectGroupRow"

const accountRef = graphql`
  fragment DetectedPeopleSection_account on accounts
  @argumentDefinitions(peopleFilter: { type: "people_bool_exp" }) {
    id
    ...ProjectGroupRow_account @arguments(peopleFilter: $peopleFilter)
  }
`

const userRef = graphql`
  fragment DetectedPeopleSection_user on users {
    ...DetectedPersonRows_user
  }
`

type DetectedPeopleSectionProps = {
  project: DetectedPeopleSection_project$key
  account: DetectedPeopleSection_account$key
  user: DetectedPeopleSection_user$key
}

const DetectedPeopleSection = (props: DetectedPeopleSectionProps) => {
  const featureEnabled = useFeature("project_integration")

  const project = useFragment(
    graphql`
      fragment DetectedPeopleSection_project on projects {
        id
        members {
          id
          person_id
        }
        ...ProjectGroupRow_project
        ...ProjectDetailsDetectedPersonRow_project
        ...DetectedPersonRows_project
        ext_project_links(where: { active: { _eq: true } }) {
          ext_project_id
          project_id
          ext_project {
            id
            integration {
              id
              integration_service {
                id
                name
              }
            }
            ext_project_members(where: { deleted: { _eq: false } }) {
              id
              ext_person {
                id
                first_name
                last_name
                email
                is_placeholder
                active
                ext_person_links(where: { active: { _eq: true } }) {
                  ext_person_id
                  person_id
                  active
                }
              }
            }
          }
        }
      }
    `,
    props.project,
  )

  const account = useFragment(accountRef, props.account)
  const user = useFragment(userRef, props.user)

  const isExtLinked =
    project?.ext_project_links && project.ext_project_links.length > 0

  if (!featureEnabled || !isExtLinked || !project) {
    return null
  }

  const formattedDetectedPeople =
    project.ext_project_links?.[0]?.ext_project?.ext_project_members
      ?.filter(
        (member) =>
          // Filter out ext_person who are already linked as project members
          !member.ext_person.ext_person_links.some((link) =>
            project.members.some(
              (projMember) => projMember.person_id === link.person_id,
            ),
          ),
      )
      .map((member) => ({
        id: member.ext_person.id,
        first_name: member.ext_person.first_name,
        last_name: member.ext_person.last_name,
        email: member.ext_person.email,
        is_placeholder: member.ext_person.is_placeholder,
        active: member.ext_person.active,
        project_id: project.id,
        person_id: null, // This is unlinked person, so no person_id is set
        role_id: null,
        workstream_id: null,
      })) ?? []

  if (formattedDetectedPeople.length === 0) {
    return null
  }

  const integrationServiceName =
    project?.ext_project_links?.[0]?.ext_project?.integration
      ?.integration_service?.name

  const unlinkedDetectedGroupMembers = formattedDetectedPeople.map(
    (member) => ({
      ...member,
      person: {
        first_name: member.first_name,
        last_name: member.last_name,
      },
    }),
  )

  return (
    <>
      <ProjectGroupRow
        account={account}
        project={project}
        groupName={`Detected in ${integrationServiceName}`}
        visibleMembers={formattedDetectedPeople}
        groupMembers={unlinkedDetectedGroupMembers}
        showDotsMenu={true}
      />
      <DetectedPersonRows user={user} project={project} />
    </>
  )
}

export default DetectedPeopleSection
