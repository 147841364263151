import React from "react"

import styles from "./styles.module.css"

import Checkbox from "~/common/inputs/Checkbox"

type ExtProject = {
  id: number
  name: string
  remote_id: string
}

type Props = {
  extProjectList: readonly ExtProject[]
  checkedSet: Set<number>
  onChange: (checkedSet: Set<number>) => void
}

const ExtProjectListTable = (props: Props) => {
  const { extProjectList, checkedSet, onChange } = props

  const isAllEnabled = extProjectList.every((extProject) =>
    checkedSet.has(extProject.id),
  )

  const handleToggleAll = (enabled: boolean) => {
    const nextCheckedSet = new Set<number>()
    if (enabled) {
      for (const extProject of extProjectList) {
        nextCheckedSet.add(extProject.id)
      }
    }
    onChange(nextCheckedSet)
  }

  const handleToggle = (id: number) => {
    const nextCheckedSet = new Set<number>(checkedSet)
    if (nextCheckedSet.has(id)) {
      nextCheckedSet.delete(id)
    } else {
      nextCheckedSet.add(id)
    }
    onChange(nextCheckedSet)
  }

  return (
    <table className={styles.tableContainer}>
      <thead className={styles.tableHeader}>
        <tr>
          <th>
            <Checkbox
              id="ext-project-bulk-import-toggle-all"
              checked={isAllEnabled}
              onChange={() => handleToggleAll(!isAllEnabled)}
            />
          </th>
          <th>Project Code</th>
          <th>Project Name</th>
        </tr>
      </thead>
      <tbody>
        {extProjectList.map((extProject) => {
          return (
            <tr key={extProject.id} className={styles.tableRow}>
              <td>
                <Checkbox
                  id={`ext-project-bulk-import-${extProject.id}`}
                  checked={checkedSet.has(extProject.id)}
                  onChange={() => handleToggle(extProject.id)}
                />
              </td>
              <td>{extProject.remote_id}</td>
              <td>{extProject.name}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export { ExtProjectListTable }
