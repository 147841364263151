import type { Model } from "~/subscriptions/AuditLogSubscription/types"

import assignmentSubscription from "./assignment"
import milestoneSubscription from "./milestone"
import phaseSubscription from "./phase"
import timeOffSubscription from "./time-off"

const models = [
  assignmentSubscription,
  phaseSubscription,
  timeOffSubscription,
  milestoneSubscription,
]

const getModelByTableName = (tableName: string): Model =>
  models.find((model) => model.tableName === tableName)

export { models, getModelByTableName }
