import { Icon } from "@blueprintjs/core"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./SelectViewDialog.module.css"

import { SelectViewDialog_user$key } from "./__generated__/SelectViewDialog_user.graphql"

import { reportError } from "~/helpers/error-helpers"

import Dialog from "~/common/Dialog"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Select from "~/common/Select"
import "~/common/SuperSearch"
import Button from "~/common/buttons/Button"

import { userViewUpdateRelay } from "~/mutations/User"

import { withPlannerQuery } from "~/Planner/PlannerContainer"
import {
  showAllViewDeselected,
  showAllViewSelected,
} from "~/Planner/reducer2/viewsSlice"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  gql: SelectViewDialog_user$key
}

type Option = {
  value: number
  label: string
}
const SelectViewDialog = (props: Props) => {
  const user = useFragment(
    graphql`
      fragment SelectViewDialog_user on users {
        id
        view {
          id
        }
        account {
          id
          views {
            id
            name
            description
          }
        }
      }
    `,
    props.gql,
  )

  const {
    account: { views },
  } = user

  const [isOpen, setIsOpen] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedView, setSelectedView] = useState<Option>(null)
  const dispatch = useDispatch()

  const showAllView = useAppSelector(
    (state) => state.plannerV2.views.showAllView,
  )

  const options = views
    .map((v) => {
      return { value: v.id, label: v.name }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const formattedOptions = [
    {
      options,
      pinned: true,
    },
    {
      options: [{ label: "All", value: "all" }],
    },
  ]

  const closeDialog = () => {
    setIsSaving(false)
    setIsOpen(false)
  }

  const onSubmit = async () => {
    setIsSaving(true)

    try {
      if (selectedView.label === "All") {
        dispatch(showAllViewSelected())
      } else {
        await userViewUpdateRelay({
          input: {
            viewId: selectedView.value,
          },
        })
        dispatch(showAllViewDeselected(selectedView.value))
      }
    } catch (error) {
      void reportError("user selecting view in dialog", error)
    } finally {
      closeDialog()
    }
  }

  const onChange = (selected: Option) => {
    setSelectedView(selected)
  }

  if (user.view || views.length === 0 || showAllView) {
    return null
  }

  const selectedViewDescription = selectedView
    ? views.find((v) => v.id === selectedView?.value)?.description
    : null

  return (
    <Dialog isOpen={isOpen} onClose={closeDialog}>
      <ModalFormWrapper headerTitle="Select a view to start planning">
        <ModalBody>
          <div className={styles.title}>
            You can switch your view at any time
          </div>
          <Select
            id="view-selector"
            onChange={onChange}
            options={formattedOptions}
            value={selectedView}
            placeholder="Select a view"
          />
          {selectedViewDescription && (
            <p className={styles.description}>{selectedViewDescription}</p>
          )}
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <a
            href="https://help.runn.io/en/articles/8226223-views"
            target="_blank"
            rel="noopener"
          >
            <span>Learn about views</span>
            <Icon icon="share" size={11} />
          </a>
          <Button
            text="Save"
            intent="primary"
            loading={isSaving}
            onClick={onSubmit}
            outlined={false}
            disabled={isSaving || !selectedView}
          />
        </ModalFooter>
      </ModalFormWrapper>
    </Dialog>
  )
}

export default withPlannerQuery(SelectViewDialog)
