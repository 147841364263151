import { Icon } from "@blueprintjs/core"
import React from "react"

import styles from "./SearchIconWithReset.module.css"

import { CrossIcon } from "./react-icons"

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  hasQuery: boolean
  onReset: () => void
}

export const SearchIconWithReset = (props: Props) => {
  const { hasQuery, onReset, ...rest } = props
  return (
    <>
      {hasQuery ? (
        <button onClick={onReset} className={styles.resetButton} {...rest}>
          <CrossIcon size={13} />
        </button>
      ) : (
        <Icon icon="search" color={"var(--shadow)"} />
      )}
    </>
  )
}
