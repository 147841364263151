import { dateHelpers } from "@runn/calculations"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList } from "react-window"

import styles from "./TimeOffSummary.module.css"

import {
  TimeOffSummary_account$data,
  TimeOffSummary_account$key,
} from "./__generated__/TimeOffSummary_account.graphql"

import { track } from "~/helpers/analytics"
import { dateTimeEqual } from "~/helpers/general-helpers"
import { sortPeopleByPersonName } from "~/helpers/sorting-helpers"

import AddSection from "~/common/AddSection"
import HeadingBar from "~/common/HeadingBar"

import { isSplitScreenMode } from "~/Mode.reducer"
import { setTimeOffBarCollapsed } from "~/Planner/Planner.actions"
import PlannerGrid from "~/Planner/PlannerGrid"
import SplitRow from "~/Planner/PlannerLayout/SplitRow"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import TimeOffSummaryAddRow from "./TimeOffSummaryAddRow"
import TimeOffSummaryPersonRow from "./TimeOffSummaryPersonRow"

type Props = {
  account: TimeOffSummary_account$key
  filteredPersonIds: Set<number>
}

const RowRenderer = ({
  index,
  style,
  data,
}: {
  index: number
  style: any
  data: {
    visiblePeople: TimeOffSummary_account$data["people"]
    account: TimeOffSummary_account$data
  }
}) => {
  const { visiblePeople, account } = data

  const person = visiblePeople[index]

  return (
    <div style={style}>
      <TimeOffSummaryPersonRow
        key={person.id}
        account={account}
        person={person}
        defaultFullTimeMinutes={account.default_full_time_minutes}
      />
    </div>
  )
}

const TimeOffCounter = ({
  account,
  filteredPersonIds,
  calendarStartDate,
  calendarEndDate,
}: {
  account: TimeOffSummary_account$key
  filteredPersonIds: Set<number>
  calendarStartDate: string
  calendarEndDate: string
}) => {
  const data = useFragment(
    graphql`
      fragment TimeOffSummaryCounter_account on accounts
      @argumentDefinitions(
        plannerStartDate: { type: "date!" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        people(where: $peopleFilter) {
          id
          time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            start_date: start_date_runn
            end_date: end_date_runn
          }
        }
      }
    `,
    account,
  )

  const counter = data.people
    .filter((person) => filteredPersonIds.has(person.id))
    .filter((person) =>
      person.time_offs.some(
        (timeOff) =>
          timeOff.start_date <= calendarEndDate &&
          timeOff.end_date >= calendarStartDate,
      ),
    ).length

  return <>{counter}</>
}

const ROW_HEIGHT = 38

const TimeOffList = (props: {
  account: TimeOffSummary_account$key
  filteredPersonIds: Set<number>
  calendarStartDate: string
  calendarEndDate: string
}) => {
  const { filteredPersonIds, calendarEndDate, calendarStartDate } = props

  const account = useFragment(
    graphql`
      fragment TimeOffSummary_account on accounts
      @argumentDefinitions(
        plannerStartDate: { type: "date!" }
        peopleFilter: { type: "people_bool_exp" }
      ) {
        id
        default_full_time_minutes
        people(where: $peopleFilter) {
          id
          email
          first_name
          last_name
          is_placeholder
          email
          active
          tags
          image_key
          archivable
          placeholder_suggestions {
            suggested_person_id
          }
          contracts {
            id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            role {
              id
              name
            }
          }
          time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            start_date: start_date_runn
            end_date: end_date_runn
            person_id
            note
            leave_type
            minutes_per_day
            ...ExtLinks_TimeOff @relay(mask: false)
          }
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
            is_billable
            is_template
            minutes_per_day
            start_date: start_date_runn
            end_date: end_date_runn
            person_id
            role_id
            project_id
            phase_id
            workstream_id
            note
            non_working_day
            total_minutes
          }
          team {
            id
            name
          }
          competencies {
            id
            level
            skill {
              id
              name
            }
          }
        }
        ...TimeOffSummaryPersonRow_account
        ...TimeOffSummaryAddRow_account
      }
    `,
    props.account,
  )

  const { people: allPeople } = account

  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)

  const visiblePeople = useMemo(() => {
    const people = allPeople.filter((person) =>
      filteredPersonIds.has(person.id),
    )

    return sortPeopleByPersonName(
      people.filter((person) =>
        person.time_offs.some(
          (timeOff) =>
            timeOff.start_date <= calendarEndDate &&
            timeOff.end_date >= calendarStartDate,
        ),
      ),
    )
  }, [allPeople, filteredPersonIds, calendarStartDate, calendarEndDate])

  if (allPeople.length === 0) {
    return null
  }

  const isDisabled = isSplitScreenMode(modeAction)

  return (
    <>
      <div
        className={`${isDisabled ? styles.disabled : ""} ${
          styles.timeOffSummary
        }`}
      >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={styles.virtualContainer}
              height={height}
              itemCount={visiblePeople.length}
              itemSize={ROW_HEIGHT}
              width={width}
              overscanCount={10}
              itemData={{
                visiblePeople,
                account,
                allPeople,
              }}
            >
              {RowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
        <div className={styles.calendarBackground}>
          <AddSection />
        </div>
      </div>
      <PlannerGrid disabled={isDisabled}>
        <TimeOffSummaryAddRow account={account} people={allPeople} />
      </PlannerGrid>
      <SplitRow />
    </>
  )
}

const TimeOffSummary = (props: Props) => {
  const { filteredPersonIds } = props

  const dispatch = useDispatch()

  const timeOffBarCollapsed = useSelector(
    (state: ReduxState) => state.planner.timeOffBarCollapsed,
  )

  const toggleRow = () => {
    if (timeOffBarCollapsed) {
      track("Time Off Summary in Planner - Expanded")
    } else {
      track("Time Off Summary in Planner - Collapsed")
    }

    return dispatch(setTimeOffBarCollapsed(!timeOffBarCollapsed))
  }

  const calendarStartDate = dateHelpers.formatToRunnDate(
    useSelector(
      (state: ReduxState) => state.calendar.calendarStartDate,
      dateTimeEqual,
    ),
  )

  const calendarEndDate = dateHelpers.formatToRunnDate(
    useSelector(
      (state: ReduxState) => state.calendar.calendarEndDate,
      dateTimeEqual,
    ),
  )

  return (
    <div
      className={styles.container}
      style={{ flex: !timeOffBarCollapsed ? 1 : "0 1 auto" }}
    >
      <HeadingBar
        text="Time Off"
        id="planner-time-off-bar"
        onClick={toggleRow}
        isCollapsed={timeOffBarCollapsed}
        data-test={`Time-off-${timeOffBarCollapsed ? "collapsed" : "expanded"}`}
        count={
          <TimeOffCounter
            account={props.account}
            calendarEndDate={calendarEndDate}
            calendarStartDate={calendarStartDate}
            filteredPersonIds={filteredPersonIds}
          />
        }
      />
      {!timeOffBarCollapsed && (
        <TimeOffList
          account={props.account}
          filteredPersonIds={filteredPersonIds}
          calendarStartDate={calendarStartDate}
          calendarEndDate={calendarEndDate}
        />
      )}
    </div>
  )
}

export default TimeOffSummary
