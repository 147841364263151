import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { format as formatDate, getDay, getWeek, isToday } from "date-fns"
import React from "react"
import { match } from "ts-pattern"

import styles from "./Timeline.module.css"

import CellDivider from "~/common/calendar/CellDivider"

const TimelineDay = (props: Props) => {
  const { date, visible, isHalfYear, calendarView, useWeekNumbers } = props
  const today = isToday(date)

  const dayNumber = getDay(date) // 0 = Sunday. 6 = Saturday
  const weekend = dayNumber === 0 || dayNumber === 6
  const monday = dayNumber === 1
  const saturday = dayNumber === 6

  const dateDisplay = match({
    visible,
    calendarView,
    today,
    useWeekNumbers,
  })
    .with(
      {
        useWeekNumbers: true,
        visible: true,
        calendarView: { type: "months", amount: 3 },
      },
      () => `W${getWeek(date)}`,
    )
    .with(
      {
        useWeekNumbers: true,
        visible: true,
        calendarView: { type: "months", amount: 6 },
      },
      () => `W${getWeek(date)}`,
    )
    .with(
      {
        useWeekNumbers: true,
        visible: true,
        calendarView: { type: "months", amount: 12 },
      },
      () => getWeek(date),
    )
    .with({ visible: true }, () => formatDate(date, "d"))
    .otherwise(() => "")

  return (
    <div
      data-test={dateHelpers.formatToRunnDate(date)}
      className={cc([
        styles.timelineDay,
        {
          [styles.today]: today,
          [styles.weekend]: weekend,
          [styles.startOfWeekHalfYear]: isHalfYear && visible,
          [styles.weekNumber]: useWeekNumbers,
        },
      ])}
    >
      {(monday || saturday) && <CellDivider />}
      <span className={styles.date}>{dateDisplay}</span>
    </div>
  )
}

type Props = {
  date: Date
  visible: boolean
  isHalfYear: boolean
  calendarView:
    | { type: "months"; amount: 1 | 3 | 6 | 12 }
    | { type: "weeks"; amount: 1 }
  useWeekNumbers: boolean
}

export default TimelineDay
