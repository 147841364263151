import * as fe from "@runn/filter-engine"
import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./PlannerHeader.module.css"

import { PlannerHeader_account$key } from "~/Planner/PlannerLayout/__generated__/PlannerHeader_account.graphql"

import CalendarAdjustment from "~/common/PageControls/CalendarAdjustment"
import PageControls from "~/common/PageControls/PageControls"
import SuperSearch, { SuperSearchConfig } from "~/common/SuperSearch"
import Timeline from "~/common/timespan/Timeline"

import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import { useAppSelector } from "~/hooks/redux"

type Props = {
  account: PlannerHeader_account$key
  disableSearch?: boolean
  // super search
  superSearchConfig: SuperSearchConfig
  wildSearchQuery: string | undefined
  filterSet: fe.engines.local.AllowedFilterSet
  savedFilterSets: fe.engines.local.AllowedFilterSet[]
  onChangeWildSearchQuery: (query: string | undefined) => void
  onChangeFilterSet: (value: fe.engines.local.AllowedFilterSet) => void
  onCreateSavedFilterSet: (value: fe.engines.local.AllowedFilterSet) => void
  onDeleteSavedFilterSet: (value: fe.engines.local.AllowedFilterSet) => void
  showIncludeArchivedProjectsToggle?: boolean
}

const PlannerHeader = (props: Props) => {
  const {
    disableSearch,
    superSearchConfig,
    wildSearchQuery,
    filterSet,
    savedFilterSets,
    onChangeWildSearchQuery,
    onChangeFilterSet,
    onCreateSavedFilterSet,
    onDeleteSavedFilterSet,
    showIncludeArchivedProjectsToggle = false,
  } = props
  const account = useFragment(
    graphql`
      fragment PlannerHeader_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        ...PageControls_account @arguments(projectsFilter: $projectsFilter)
      }
    `,
    props.account,
  )
  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const disabled = disableSearch || isSplitScreenMode(modeAction)

  return (
    <>
      <div id="filter-controls" className={styles.filterControls}>
        <SuperSearch
          location="planner"
          disabled={disabled}
          config={superSearchConfig}
          wildSearchQuery={wildSearchQuery}
          filterSet={filterSet}
          savedFilterSets={savedFilterSets}
          onChangeWildSearchQuery={onChangeWildSearchQuery}
          onChangeFilterSet={onChangeFilterSet}
          onCreateSavedFilterSet={onCreateSavedFilterSet}
          onDeleteSavedFilterSet={onDeleteSavedFilterSet}
          showIncludeArchivedProjectsToggle={showIncludeArchivedProjectsToggle}
        />
        <CalendarAdjustment />
      </div>
      <PlannerGrid className={styles.plannerHeader}>
        <PageControls account={account} />
        <Timeline calculateDayWidth />
      </PlannerGrid>
    </>
  )
}

export default PlannerHeader
