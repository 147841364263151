import React from "react"

import styles from "./styles.module.css"

type ActionItemProps = {
  href: string
  icon: React.ReactNode
  label: React.ReactNode
  onClick?: () => void
}

const ActionItem = (props: ActionItemProps) => {
  const { href, icon, label, onClick } = props

  const isExternal = !href.startsWith("/") && !href.startsWith("#")
  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = isExternal
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {}

  return (
    <a
      href={href}
      {...linkProps}
      className={styles.actionItem}
      onClick={onClick}
    >
      <span className={styles.icon} aria-hidden="true">
        {icon}
      </span>
      <span className={styles.label}>{label}</span>
    </a>
  )
}

export { ActionItem }
