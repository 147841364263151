import React from "react"

import styles from "./ExternalProjectMemberForm.module.css"

import Input from "~/common/Input"
import { ModalBody } from "~/common/ModalForm"
import Select from "~/common/Select"
import Checkbox from "~/common/inputs/Checkbox"

import SelectLinkedPerson from "./SelectLinkedPerson"

type Person = {
  id: number
  first_name: string
  last_name: string
  email: string
  role?: string
  role_id?: number
  checked?: boolean
}

export type SelectedExternalMember = {
  readonly id: number
  readonly ext_person: {
    readonly id: number
    readonly email: string | null
    readonly first_name: string
    readonly last_name: string
    readonly ext_person_links: readonly {
      readonly person_id: number
      readonly ext_person_id: number
      readonly active: boolean
      readonly external_id: number
    }[]
  }
}

export type SelectedExternalMembers = readonly SelectedExternalMember[]

export type Role = { readonly id: number; readonly name: string }

type Props = {
  extPersonToPersonMap: Record<string, Person>
  selectedExternalMembers: SelectedExternalMembers
  sortedPeople: Person[]
  roles: Role[]
  toggleAllMembers: (event: React.ChangeEvent<HTMLInputElement>) => void
  handlePersonCheckboxChange: (extPersonId: number) => void
  handleEmailChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    extPersonId: number,
  ) => void
  handleRunnPersonChange: (
    option: { label: string; value: number },
    extPersonId: number,
  ) => void
  handleRoleChange: (
    option: { label: string; value: number },
    extPersonId: number,
  ) => void
  getPersonValue: (
    extPersonId: number,
  ) => { label: string; value: any } | undefined
}

const ActivateExternalMembersForm = ({
  extPersonToPersonMap,
  selectedExternalMembers,
  sortedPeople,
  roles,
  toggleAllMembers,
  handlePersonCheckboxChange,
  handleEmailChange,
  handleRunnPersonChange,
  handleRoleChange,
  getPersonValue,
}: Props) => (
  <ModalBody style={{ maxWidth: "1800px" }}>
    <h5>Activate People</h5>
    <p>Link an existing person in Runn or create a new person</p>
    <table className={styles.tableContainer}>
      <thead className={styles.tableHeader}>
        <tr>
          <th>
            <Checkbox
              id="ext-project-member-all"
              defaultChecked
              onChange={toggleAllMembers}
            />
          </th>
          <th>ID</th>
          <th>Name</th>
          <th>Email (optional)</th>
          <th style={{ textAlign: "left" }}>Create or Link</th>
          <th>Default Role</th>
        </tr>
      </thead>
      <tbody>
        {selectedExternalMembers.map((member) => {
          const roleValue = extPersonToPersonMap[member.ext_person.id]?.role_id

          return (
            <tr key={member.id} className={styles.tableRow}>
              <td>
                <Checkbox
                  id="ext-project-member"
                  checked={extPersonToPersonMap[member.ext_person.id]?.checked}
                  onChange={() =>
                    handlePersonCheckboxChange(member.ext_person.id)
                  }
                />
              </td>
              <td>{member.ext_person.id}</td>
              <td>
                {member.ext_person.first_name} {member.ext_person.last_name}
              </td>
              <td>
                {!getPersonValue(member.ext_person.id)?.value ? (
                  <Input
                    name={`person-${member.ext_person.id}-email`}
                    id={`person-${member.ext_person.id}-email`}
                    label=""
                    style={{ width: "180px", margin: 0 }}
                    value={member.ext_person?.email}
                    onChange={(e) => handleEmailChange(e, member.ext_person.id)}
                  />
                ) : (
                  "--"
                )}
              </td>
              <td>
                <SelectLinkedPerson
                  options={sortedPeople.map((person) => ({
                    firstName: person.first_name,
                    lastName: person.last_name,
                    id: person.id,
                    isLinked: false,
                  }))}
                  value={extPersonToPersonMap[member.ext_person.id]?.id}
                  onChange={(option) =>
                    handleRunnPersonChange(option, member.ext_person.id)
                  }
                />
              </td>
              <td>
                <Select
                  id={`role-${member.ext_person.id}`}
                  required={true}
                  options={roles.map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
                  value={
                    roleValue
                      ? {
                          label:
                            extPersonToPersonMap[member.ext_person.id]?.role,
                          value: roleValue,
                        }
                      : null
                  }
                  placeholder="Select or create role"
                  width="200px"
                  onChange={(option) =>
                    handleRoleChange(option, member.ext_person.id)
                  }
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </ModalBody>
)

export default ActivateExternalMembersForm
