/**
 * @generated SignedSource<<c0449b77953752a084b4c95af8c62ce4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MiniPicker_account$data = {
  readonly default_full_time_minutes: number;
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"EffortInput_account" | "NonWorkingDayInput_account" | "TotalEffortInput_account">;
  readonly " $fragmentType": "MiniPicker_account";
};
export type MiniPicker_account$key = {
  readonly " $data"?: MiniPicker_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"MiniPicker_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MiniPicker_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default_full_time_minutes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EffortInput_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TotalEffortInput_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NonWorkingDayInput_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "6778a0771104f5a4ca6e23f280acc7be";

export default node;
