import { dateHelpers } from "@runn/calculations"
import { isWeekend } from "date-fns"
import mem from "mem"
import serializeJavascript from "serialize-javascript"

export const getOverlappingAssignments = (
  assignment: Assignment,
  assignments: readonly Assignment[],
) => {
  // remove bookings outside of this assignment
  const assingmentsThatOverlap = assignments.filter((a) => {
    if (!a) {
      return false
    }
    if (assignment.id === a.id) {
      return false
    }
    if (Number(a.start_date) > Number(assignment.end_date)) {
      return false
    }
    if (Number(a.end_date) < Number(assignment.start_date)) {
      return false
    }
    if (
      a.project_id === assignment.project_id &&
      a.role_id === assignment.role_id &&
      a.workstream_id === assignment.workstream_id
    ) {
      return false
    } // Remove assignments from current project+role
    return true
  })

  return assingmentsThatOverlap
}

const getContractsForAssignment = (
  assignment: Assignment,
  contracts: readonly Contract[],
) => {
  const filteredContracts = contracts.filter((c) => {
    // Only return a contracts if the assignment is within a contract date
    if (c.end_date && Number(c.end_date) < Number(assignment.start_date)) {
      return false
    }
    if (Number(c.start_date) > Number(assignment.end_date)) {
      return false
    }
    return true
  })
  return filteredContracts
}

const getContractedMinutesForDate = (
  contracts: Contract[],
  dateString: string,
) => {
  if (contracts.length === 1) {
    return contracts[0].minutes_per_day
  }

  const contract = contracts.find((c) => {
    // Find contract that matches the date
    if (c.end_date && Number(c.end_date) < Number(dateString)) {
      return false
    }
    if (Number(c.start_date) > Number(dateString)) {
      return false
    }
    return true
  })

  return contract ? contract.minutes_per_day : 0
}

const assignmentAndContractsToDaysObject = (
  assignment: Assignment,
  contracts: Contract[],
) => {
  const startDate = dateHelpers.parseRunnDate(`${assignment.start_date}`)
  const endDate = dateHelpers.parseRunnDate(`${assignment.end_date}`)

  const filteredContracts = getContractsForAssignment(assignment, contracts)

  const daysObject = dateHelpers
    .eachDayOfInterval(
      { start: startDate, end: endDate },
      "assignmentAndContractsToDaysObject",
      assignment,
    )
    .filter((date) => !isWeekend(date))
    .reduce(
      (acc, date) => ({
        ...acc,
        [dateHelpers.formatToRunnDate(date)]: {
          formattedDate: dateHelpers.formatToRunnDate(date),
          totalAssignedMinutes: assignment.minutes_per_day,
          contractedMinutesPerDay: getContractedMinutesForDate(
            filteredContracts,
            dateHelpers.formatToRunnDate(date),
          ),
          assignments: [assignment],
        },
      }),
      {},
    )
  return daysObject
}

const assignmentToDaysObject = (a, minDate, maxDate) => {
  // Change the start and end dates so we don't create huge objects for long assignments
  const modifiedStartDate = !minDate
    ? a.start_date
    : Number(a.start_date) < Number(minDate)
      ? minDate
      : a.start_date

  const modifiedEndDate = !maxDate
    ? a.end_date
    : Number(a.end_date) > Number(maxDate)
      ? maxDate
      : a.end_date

  const startDate = dateHelpers.parseRunnDate(`${modifiedStartDate}`)
  const endDate = dateHelpers.parseRunnDate(`${modifiedEndDate}`)

  const daysObject = dateHelpers
    .eachDayOfInterval(
      { start: startDate, end: endDate },
      "assignmentToDaysObject",
      a,
    )
    .filter((date) => !isWeekend(date))
    .reduce(
      (acc, date) => ({
        ...acc,
        [dateHelpers.formatToRunnDate(date)]: {
          formattedDate: dateHelpers.formatToRunnDate(date),
          totalAssignedMinutes: a.minutes_per_day,
          assignment: a,
        },
      }),
      {},
    )
  return daysObject
}

const assignmentAndContractsToDaysObjectMem = mem(
  assignmentAndContractsToDaysObject,
  { cacheKey: serializeJavascript },
)

const getOverbookingsFromDaysObjects = (
  assignment: DayObject,
  assignments: DayObject[],
) => {
  const daysWithAssignedMinutes = assignments.reduce((acc, a) => {
    const merged = { ...acc }
    Object.keys(a).forEach((key: keyof DayObject) => {
      if (merged[key]) {
        // Only care about days the assignment has
        const mergedAssignments = merged[key].assignments?.slice() ?? []
        const currentAssignment = a[key].assignment
        if (currentAssignment) {
          mergedAssignments.push(currentAssignment)
        }

        merged[key] = {
          formattedDate: merged[key].formattedDate,
          contractedMinutesPerDay: merged[key].contractedMinutesPerDay,
          totalAssignedMinutes:
            merged[key].totalAssignedMinutes + a[key].totalAssignedMinutes,
          assignments: mergedAssignments,
        }
      }
    })
    return merged
  }, assignment)

  const overbookings = Object.values(daysWithAssignedMinutes)
    .filter(
      (day) => day.totalAssignedMinutes > (day.contractedMinutesPerDay ?? 0),
    )
    .map((day) => ({
      date: day.formattedDate,
      assignedMinutes: day.totalAssignedMinutes,
      contractedMinutesPerDay: day.contractedMinutesPerDay,
      assignments: day.assignments,
    }))

  return overbookings
}

const getAssignmentsDayObject = (assignment, assignments) => {
  const assignmentsDaysObjects = assignments
    .filter((a) => {
      if (!a) {
        return false
      } // When delete assignments as we can get a null value
      if (a.id === assignment.id) {
        return false
      } // Remove the current assignment
      if (
        a.project_id === assignment.project_id &&
        a.role_id === assignment.role_id &&
        a.workstream_id === assignment.workstream_id
      ) {
        return false
      } // Remove assignments from current project+role
      return true
    })
    .map((a) =>
      assignmentToDaysObject(a, assignment.start_date, assignment.end_date),
    )

  return assignmentsDaysObjects
}

const getAssignmentsDayObjectMem = mem(getAssignmentsDayObject, {
  cacheKey: serializeJavascript,
})

const getOverbookingsFunc = (
  assignment: Assignment,
  person: Person,
  assignments: readonly Assignment[],
) => {
  // Get the assignments that we should
  const overlappingAssignments = getOverlappingAssignments(
    assignment,
    assignments,
  )
  const filteredContracts = getContractsForAssignment(
    assignment,
    person.contracts,
  )

  // Guard to make it faster most of the time.
  // No overlaping assignments and there is 1 or 0 contracts
  if (!overlappingAssignments.length && filteredContracts.length <= 1) {
    if (!filteredContracts.length) {
      return []
    } // No contract. Cant be overbooked
    // One assignment and one contract and its not overbooked
    if (assignment.minutes_per_day <= filteredContracts[0].minutes_per_day) {
      return []
    }
  }

  // Divide the current assignment into the days, with the persons capacity + the assignments usage
  const primaryAssignmentDaysObject = assignmentAndContractsToDaysObjectMem(
    assignment,
    filteredContracts,
  )

  const assignmentsDaysObjects = getAssignmentsDayObjectMem(
    assignment,
    overlappingAssignments,
  )

  // Return an array of days that have overbookings on them
  const overbookings = getOverbookingsFromDaysObjects(
    primaryAssignmentDaysObject,
    assignmentsDaysObjects,
  )

  return overbookings
}

export const getOverbookings = mem(getOverbookingsFunc, {
  cacheKey: serializeJavascript,
})

type Assignment = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  project_id: number
  role_id: number
  workstream_id: number
}

type Person = {
  id: number
  contracts: readonly Contract[]
  assignments: readonly Assignment[]
}

type Contract = {
  id: number
  start_date: string
  end_date: string
  minutes_per_day: number
}

type DaysWithAssignedMinutes = {
  formattedDate: string
  totalAssignedMinutes: number
  contractedMinutesPerDay?: number
  assignments?: Assignment[]
  assignment?: Assignment
}

type DayObject = {
  [key: string]: DaysWithAssignedMinutes
}

export default {
  getOverlappingAssignments,
  getOverbookings,
}
