import React, { ReactNode } from "react"
import { graphql } from "react-relay"

import FILTER_QUERY from "./__generated__/FilterQuery.graphql"

import {
  AllowedPeopleFilter,
  AllowedProjectFilter,
} from "~/helpers/filter-engine"

import { useHasuraQuery } from "~/hooks/useHasuraQuery"

import { buildPeopleFilter, buildProjectsFilter } from "../buildHasuraFilter"

import FilteredPlannerProvider from "./FilteredPlannerProvider"

type Props = {
  children: ReactNode
  projectFilters: AllowedProjectFilter[]
  peopleFilters: AllowedPeopleFilter[]
  limit?: number
}

graphql`
  query FilterQuery(
    $projectFilters: projects_bool_exp
    $peopleFilters: people_bool_exp
  ) {
    current_user {
      account {
        projects(where: $projectFilters, order_by: { name: asc, id: asc }) {
          id
        }
        projects_aggregate(where: $projectFilters) {
          aggregate {
            count
          }
        }
        people(
          where: $peopleFilters
          order_by: { first_name: asc, last_name: asc }
        ) {
          id
        }
        people_aggregate(where: $peopleFilters) {
          aggregate {
            count
          }
        }
      }
    }
  }
`
export type FilterQueryData = {
  projects: Array<{ id: number }>
  projects_aggregate: { aggregate: { count: number } }
  people: Array<{ id: number }>
  people_aggregate: { aggregate: { count: number } }
}

type FilterQueryResponse = {
  current_user: {
    account: FilterQueryData
  }
}

const FilterQuery = (props: Props) => {
  const { peopleFilters, projectFilters } = props

  const hasuraProjectFilters = buildProjectsFilter(projectFilters)
  const hasuraPeopleFilters = buildPeopleFilter(peopleFilters)

  const result = useHasuraQuery<FilterQueryResponse>(FILTER_QUERY, {
    projectFilters: hasuraProjectFilters,
    peopleFilters: hasuraPeopleFilters,
  })

  const account = result.data.current_user.account
  const peopleIds = account.people.map((person) => person.id)
  const projectIds = account.projects.map((project) => project.id)
  const projectCount = account.projects_aggregate.aggregate.count
  const peopleCount = account.people_aggregate.aggregate.count

  return (
    <FilteredPlannerProvider
      peopleIDs={peopleIds}
      projectIDs={projectIds}
      totalPeopleCount={peopleCount}
      totalProjectCount={projectCount}
    >
      {props.children}
    </FilteredPlannerProvider>
  )
}

export default FilterQuery
