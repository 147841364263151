/**
 * @generated SignedSource<<148baa0554ca1213d36e9ca38da91640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddTimeOffModal_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlannerDatePicker_account">;
  readonly " $fragmentType": "AddTimeOffModal_account";
};
export type AddTimeOffModal_account$key = {
  readonly " $data"?: AddTimeOffModal_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddTimeOffModal_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddTimeOffModal_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerDatePicker_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "7b593f4f056968a9f162d2544c122883";

export default node;
