import * as fe from "@runn/filter-engine"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { match } from "ts-pattern"

import styles from "./Main.module.css"

import {
  AllowedPeopleFilter,
  prepareDataForFilterPersonList,
} from "~/helpers/filter-engine"
import { getVisiblePlaceholders } from "~/helpers/placeholder-helpers"
import { pluralize } from "~/helpers/plural"
import { SCROLLBAR_WIDTH } from "~/helpers/scrollbar"

import CalendarOutline from "~/common/calendar/CalendarOutline"
import Timeline from "~/common/timespan/Timeline"

import usePeoplePlannerFilters from "~/PeoplePlanner/usePeoplePlannerFilters"
import { Chart, Legend } from "~/Planner/ChartPanel/Chart"
import type {
  ChartPeople,
  ChartPeriod,
  ChartProjects,
  ChartType,
} from "~/Planner/ChartPanel/types"
import NoResultsForFilter from "~/Planner/NoResultsForFilter"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

type Props = {
  enableAnimations: boolean
  favouritePersonIds: number[]
  allPeople: ChartPeople
  allProjects: ChartProjects

  chartType: ChartType
  chartPeriod: ChartPeriod

  companyDefaultMinutes: number
  accountHasViews: boolean

  viewPeopleFilters: AllowedPeopleFilter | undefined
}

const ChartMain = (props: Props) => {
  const {
    enableAnimations,
    favouritePersonIds,
    allProjects,
    allPeople,
    chartType,
    chartPeriod,
    companyDefaultMinutes,
    accountHasViews,
    viewPeopleFilters,
  } = props

  const calendarStartDate = useSelector(
    (state: ReduxState) => state.calendar.calendarStartDate,
  )

  const calendarEndDate = useSelector(
    (state: ReduxState) => state.calendar.calendarEndDate,
  )

  const includeWeekends = useSelector(
    (state: ReduxState) => state.calendar.calendarWeekendsExpanded,
  )

  const calStartNum = useSelector(
    (state: ReduxState) => state.calendar.calStartNum,
  )

  const calEndNum = useAppSelector((state) => state.calendar.calEndNum)

  const enabledTentativeProjects = useAppSelector(
    (state) => state.plannerV2.scenarioPlanning.enabledTentativeProjects,
  )

  const enabledProjects = allProjects.filter(
    ({ confirmed, is_template, id }) =>
      confirmed ||
      isTentativeProjectEnabled(enabledTentativeProjects, id) ||
      is_template,
  )

  const filterSet = usePeoplePlannerFilters(viewPeopleFilters)

  const filterTypeList = fe.filters.asTypeList(filterSet.filters)

  const preparedList = useMemo(
    () =>
      prepareDataForFilterPersonList({
        filterTypeList,
        projects: enabledProjects,
        people: allPeople,
        favouritePersonIds,
        calStartNum,
        includeArchivedProjects: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterTypeList.join(","),
      enabledProjects,
      allPeople,
      favouritePersonIds,
      calStartNum,
    ],
  )

  const filteredPeople = useMemo(() => {
    const filteredPersonList = fe.engines.local.filterPersonList(
      preparedList,
      filterSet,
    )

    const filteredPersonIds = Object.fromEntries(
      filteredPersonList.map((person) => [person.id, true]),
    )

    const filtered = allPeople.filter((person) => filteredPersonIds[person.id])

    const people = filtered.filter((p) => !p.is_placeholder)
    const placeholders = getVisiblePlaceholders(
      filtered.filter((p) => p.is_placeholder),
      allProjects,
      calStartNum,
      calEndNum,
    ) as any

    return [...people, ...placeholders]
  }, [preparedList, filterSet, allPeople, allProjects, calStartNum, calEndNum])

  const hasPeopleResults = Boolean(filteredPeople.length)

  const peopleDataMessage = match(hasPeopleResults)
    .with(false, () => `No people data found`)
    .otherwise(() => {
      const peopleSortedByType = filteredPeople.reduce(
        (acc, person) => {
          person.is_placeholder
            ? (acc.placeholders = acc.placeholders + 1)
            : (acc.people = acc.people + 1)

          return acc
        },
        { people: 0, placeholders: 0 },
      )

      const { people, placeholders } = peopleSortedByType

      return (
        <div>
          {"Displaying data for "}
          {people ? `${people} ${pluralize(people, "person", "people")}` : ""}
          {people && placeholders ? (
            <span>
              {" and "}
              <br />
            </span>
          ) : (
            ""
          )}
          {placeholders
            ? `${placeholders} ${pluralize(placeholders, "placeholder")}`
            : ""}
        </div>
      )
    })

  const includeTentative = enabledTentativeProjects.length > 0

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={styles.info}>
          <b>{peopleDataMessage}</b>
          {!hasPeopleResults && (
            <NoResultsForFilter
              subject="people"
              minimal
              accountHasViews={accountHasViews}
            />
          )}
        </div>
        <Legend type={chartType} includeTentative={includeTentative} />
      </div>
      <div className={styles.graphContainer}>
        <div className={styles.chart} style={{ marginRight: SCROLLBAR_WIDTH }}>
          <CalendarOutline type="standard" />
          <Chart
            type={chartType}
            people={filteredPeople}
            projects={enabledProjects}
            startDate={calendarStartDate}
            endDate={calendarEndDate}
            period={chartPeriod}
            includeWeekends={includeWeekends}
            enableAnimations={enableAnimations}
            companyDefaultMinutes={companyDefaultMinutes}
            includeTentative={includeTentative}
          />
        </div>
        <Timeline monthsBelow />
      </div>
    </div>
  )
}

export { ChartMain }
