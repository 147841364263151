import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import {
  endOfMonth,
  format as formatDate,
  getWeek,
  isAfter,
  isMonday,
  isThisMonth,
  startOfDay,
  startOfMonth,
  subDays,
} from "date-fns"
import React, { useLayoutEffect } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./Timeline.module.css"

import { Timeline_user$key } from "./__generated__/Timeline_user.graphql"

import { getDaysInRange, getTimeFrames } from "~/helpers/CalendarHelper"
import { SCROLLBAR_WIDTH } from "~/helpers/scrollbar"

import { setDayWidth } from "~/common/calendar.reducer"

import { withPlannerQuery } from "~/Planner/PlannerContainer"
import useDebouncedResizeObserver from "~/hooks/useDebouncedResizeObserver"
import { ReduxState } from "~/rootReducer"

import TimelineHighlight from "./TimelineHighlight"
import TimelineWeek from "./TimelineWeek"
import TimelineWeekendExpanders from "./TimelineWeekendExpanders"

export type Props = {
  gql: Timeline_user$key
  monthsBelow?: boolean
  // Only a single timeline should exist on the page with calculateDayWidth={true}
  // Or else it can cause infinite re-renders due to multiple components trying to update the width.
  calculateDayWidth?: boolean
}

const Timeline = (props: Props) => {
  const { monthsBelow, calculateDayWidth } = props

  const { account } = useFragment(
    graphql`
      fragment Timeline_user on users {
        id
        account {
          id
          default_full_time_minutes
          use_week_numbers
        }
      }
    `,
    props.gql,
  )
  const useWeekNumbers = account.use_week_numbers

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    isDeeplyEqual,
  )

  const {
    calendarStartDate,
    calendarEndDate,
    dayWidth: currentDayWidth,
    calendarView,
    calendarWeekendsExpanded,
  } = calendar

  const dispatch = useDispatch()

  const {
    dailyDates,
    totalDays: timelineDays,
    weeklyDates,
  } = getTimeFrames({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  // Measures the width on window resize.
  // Debounced and conditional to reduce performance impact.
  const { ref, width: timelineWidth } = useDebouncedResizeObserver(300, {
    box: "border-box",
  })

  useLayoutEffect(() => {
    if (calculateDayWidth && timelineWidth) {
      const dayWidthPx = timelineWidth / timelineDays
      // Only dispatch on changes to reduce performance impact
      if (currentDayWidth !== dayWidthPx) {
        dispatch(setDayWidth(dayWidthPx))
      }
    }
  }, [
    timelineWidth,
    timelineDays,
    currentDayWidth,
    calculateDayWidth,
    dispatch,
  ])

  if (!account) {
    return <div className={styles.timelineEmpty} />
  }

  const currentZoom = timelineDays
  const isMonthlyView =
    calendarView.type === "months" && calendarView.amount === 1
  const isWeeklyView =
    calendarView.type === "weeks" && calendarView.amount === 1

  // Complicated way to get the start of every month
  // Make every day the start of the month. Format it. Remove dupes using set. Turn back to date.
  const months = [
    ...new Set(
      dailyDates.map((day) => dateHelpers.formatToRunnDate(startOfMonth(day))),
    ),
  ].map((dateString) => dateHelpers.parseRunnDate(dateString))

  const renderMonths = () => {
    if (isMonthlyView || isWeeklyView) {
      // Get the first date of each unique month and mondays to display
      const uniqueDates = dailyDates.reduce((acc, dateString) => {
        const date = new Date(dateString)
        const dateMonthKey = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1)}`

        /* get the first date of each unique month so we can append the
        week number to the month display e.g. Dec | W42 */
        if (!acc[dateMonthKey]) {
          acc[dateMonthKey] = dateString
          return acc
        }

        if (isMonday(date)) {
          const dateWeekKey = `${date.getUTCFullYear()}-W${String(date.getUTCDate())}`
          acc[dateWeekKey] = dateString
          return acc
        }

        return acc
      }, {})

      const uniqueDatesArray: Date[] = Object.values(uniqueDates)

      return (
        <div
          className={styles.timelineRow}
          style={{ paddingRight: SCROLLBAR_WIDTH }}
        >
          {uniqueDatesArray.map((date: Date, idx) => {
            const isFirstDayOfWeek =
              date ===
              uniqueDates[
                `${date.getUTCFullYear()}-W${String(date.getUTCDate())}`
              ]
            const isFirstDayOfMonth =
              date ===
              uniqueDates[
                `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1)}`
              ]
            const weekNumber = useWeekNumbers ? getWeek(date) : null

            const nextDateBlock = uniqueDatesArray[idx + 1]
            const daysInEachBlock = getDaysInRange({
              start: date,
              end: nextDateBlock ? subDays(nextDateBlock, 1) : calendarEndDate,
              includeWeekends: calendarWeekendsExpanded,
            })

            return (
              <div
                key={idx}
                className={cc([
                  styles.timelineBlock,
                  {
                    [styles.currentMonth]: isThisMonth(date),
                  },
                ])}
                style={{
                  width: `${(daysInEachBlock / timelineDays) * 100}%`,
                }}
              >
                {isFirstDayOfMonth && formatDate(date, "MMM ''yy")}
                {weekNumber &&
                  (isFirstDayOfMonth
                    ? ` | W${weekNumber}`
                    : isFirstDayOfWeek
                      ? `W${weekNumber}`
                      : "")}
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <div
        className={styles.timelineRow}
        style={{ paddingRight: SCROLLBAR_WIDTH }}
      >
        {months.map((startOfMonthDate: Date, idx) => {
          // Refactor to start/end of calendar if month goes over the edges
          const calendarMonthStart = isAfter(
            calendarStartDate,
            startOfMonthDate,
          )
            ? calendarStartDate
            : startOfMonthDate
          const calendarMonthEnd = isAfter(
            calendarEndDate,
            endOfMonth(calendarMonthStart),
          )
            ? endOfMonth(calendarMonthStart)
            : calendarEndDate
          // There is a bug in datefns that some dates get parsed to be 1:00 instead of 0:00. parseDate("20180401") for example.
          // This just resets it to the start of the day, this cover cases of single day months without erroring.
          const daysInMonth = getDaysInRange({
            start: startOfDay(calendarMonthStart),
            end: startOfDay(calendarMonthEnd),
            includeWeekends: calendarWeekendsExpanded,
          })

          return (
            <div
              key={idx}
              className={`${styles.timelineBlock} ${
                isThisMonth(startOfMonthDate) && styles.currentMonth
              }`}
              style={{ width: `${(daysInMonth / timelineDays) * 100}%` }}
            >
              {formatDate(startOfMonthDate, "MMM ''yy")}{" "}
              {isMonthlyView &&
                useWeekNumbers &&
                `| W${getWeek(startOfMonthDate)}`}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div ref={ref} className={styles.timelineContainer}>
      {isMonthlyView && (
        <TimelineWeekendExpanders
          weeklyDates={weeklyDates}
          calendarWeekendsExpanded={calendarWeekendsExpanded}
        />
      )}
      {!monthsBelow && renderMonths()}
      <div
        className={`${styles.timelineWeeksWrapper} ${styles.timelineRow}`}
        style={{
          paddingRight: SCROLLBAR_WIDTH,
        }}
      >
        <div className={styles.timelineRowInner}>
          <TimelineHighlight
            defaultFullTimeMinutes={account.default_full_time_minutes}
            useWeekNumbers={useWeekNumbers}
          />
          {weeklyDates.map((week) => (
            <TimelineWeek
              key={week.getTime()}
              currentZoom={currentZoom}
              week={week}
              totalWeeks={weeklyDates.length}
              calendarWeekendsExpanded={calendarWeekendsExpanded}
              calendarView={calendarView}
              useWeekNumbers={useWeekNumbers}
            />
          ))}
        </div>
      </div>
      {monthsBelow && renderMonths()}
    </div>
  )
}

export default withPlannerQuery(Timeline)
