import React from "react"

import { BudgetedRole } from "./BudgetEditor"
import RoleBasedBudgetSelector from "./RoleBasedBudgetSelector"
import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  roles: Array<BudgetedRole>
  onAddRole?: (id: number) => void
  onShowAllRoles?: () => void
}
const RoleAdder = (props: Props) => {
  const { roles, onAddRole, onShowAllRoles } = props
  return (
    <Row flex>
      <Column flex height={50}>
        <div style={{ width: "215px" }}>
          <RoleBasedBudgetSelector
            placeholder="Add Roles and Expenses"
            roles={roles}
            addRole={onAddRole}
          />
        </div>
        <div style={{ width: "100px" }}>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault()
              onShowAllRoles && onShowAllRoles()
            }}
          >
            Add All
          </a>
        </div>
      </Column>
    </Row>
  )
}

export default RoleAdder
