import React from "react"
import { components } from "react-select"

import styles from "./PhaseSelector.module.css"

import Select from "~/common/Select"

import PhaseIcon from "../PhaseIcon"

const PhasePickerSingleValue = (valueProps) => (
  <components.SingleValue {...valueProps}>
    <div className={styles.phaseOption}>
      <PhaseIcon
        size={15}
        color={valueProps.data.color}
        className={styles.phaseIcon}
      />
      <div
        data-component="PhaseSelector/text"
        className={styles.phaseOptionText}
        style={{ whiteSpace: "nowrap" }}
      >
        {valueProps.data.label}
      </div>
    </div>
  </components.SingleValue>
)

const PhasePickerOption = (optionProps) => {
  const { data } = optionProps

  return (
    <components.Option {...optionProps}>
      <div className={styles.phaseOption}>
        <PhaseIcon size={15} color={data.color} className={styles.phaseIcon} />
        <div
          data-component="PhaseSelector/text"
          className={styles.phaseOptionText}
        >
          {data.label}
        </div>
      </div>
    </components.Option>
  )
}

const PhaseSelector = (props: Props) => {
  const { currentPhase, phases, onPhaseChange } = props
  const projectPhases = [...phases]

  if (!phases.length) {
    return null
  }

  const phaseOptions = projectPhases
    .sort((a, b) => Number(a.start_date) - Number(b.start_date))
    .map((phase) => ({
      value: phase.id,
      label: phase.name,
      color: phase.color,
    }))

  const noPhaseOption = {
    value: null,
    label: "No Phase",
    color: "var(--no-phase)",
  }

  const phaseValue =
    phaseOptions.find((opt) => opt.value === currentPhase) || noPhaseOption

  return (
    <Select
      name="phase"
      id={`phase-selector`}
      data-test="phase-selector"
      value={phaseValue}
      onChange={onPhaseChange}
      options={[noPhaseOption, ...phaseOptions]}
      isSearchable={false}
      components={{
        Option: PhasePickerOption,
        SingleValue: PhasePickerSingleValue,
      }}
      maxMenuHeight={200}
      doNotSortOptions={true}
    />
  )
}

/* TODO: Make id required. */
type Props = {
  currentPhase: any
  phases: ReadonlyArray<{
    id: number
    name: string
    color: string
    start_date?: string
    end_date?: string
  }>
  onPhaseChange: (phase: any) => void
}

export default PhaseSelector
