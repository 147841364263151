import { Tab, Tooltip } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { format as formatDate, isAfter, isBefore } from "date-fns"
import { useFeature } from "flagged"
import React, { Suspense, useEffect, useRef, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { match } from "ts-pattern"
import isURL from "validator/lib/isURL"

import styles from "./PersonForm.module.css"

import { PersonFormQuery } from "./__generated__/PersonFormQuery.graphql"
import { PersonForm_viewer$key } from "./__generated__/PersonForm_viewer.graphql"
import { EditPersonForm_person$data } from "~/forms/EditPersonForm/__generated__/EditPersonForm_person.graphql"

import { useHasuraContext } from "~/store/hasura"

import {
  FormFilterTypeValuesPeople,
  getNonMatchingFilters,
  getViewsPeopleGroupedOptions,
} from "~/Views/helpers"
import { track } from "~/helpers/analytics"
import { CustomTypeName, CustomValuesMap } from "~/helpers/custom-field-helpers"
import { AllowedPeopleFilter } from "~/helpers/filter-engine"
import { formatName, getCurrentOrLastContract } from "~/helpers/person"
import { DEFAULT_ROLE_TOOLTIP } from "~/helpers/role-helpers"
import { sortByString } from "~/helpers/sorting-helpers"

import CustomFieldSectionHeader from "../common/CustomFieldSectionHeader"
import DateInput from "~/common/DateInput"
import Input from "~/common/Input"
import LinkTable from "~/common/LinkTable"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import ManagerSelector from "~/common/ModalForm/ManagerSelector"
import ReferencesTable from "~/common/ReferencesTable"
import Select, { Option } from "~/common/Select"
import Tabs from "~/common/Tabs"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import SmallAlert from "~/common/alerts/SmallAlert"
import Button from "~/common/buttons/Button"
import InputEmail from "~/common/inputs/InputEmail"
import { ChevronDown } from "~/common/react-icons"

import { personCreateRelay, personUpdateRelay } from "~/mutations/Person"
import { roleCreateRelay } from "~/mutations/Role"

import PersonCustomEditor from "~/CustomEditor/PersonCustomEditor/PersonCustomEditor"
import { EMPLOYMENT_OPTIONS, PLANNER_INITIAL_DATE } from "~/GLOBALVARS"
import HolidayRemovalWarning from "~/Holidays/Forms/HolidayRemovalWarning"
import { usePermissions } from "~/Permissions/usePermissions"
import PersonTagSelector from "~/Planner/PersonTagSelector"
import RoleSelector from "~/Planner/RoleSelector"
import SkillsSelector, {
  SkillOption,
} from "~/Skills/SkillsSelector/SkillsSelector"
import Availability from "~/forms/CreatePerson/Availability"
import CostToBusiness from "~/forms/CreatePerson/CostToBusiness"

import HolidaysGroupSelector, {
  HolidaysOption,
} from "../../Holidays/Forms/HolidaysGroupSelector/HolidaysGroupSelector"
import TeamSelector from "../../Planner/TeamSelector"

import ViewFieldsTooltip from "../ProjectForm/ViewFieldsTooltip"

import ConfirmContractForm from "./ConfirmContractForm"
import { ExtPersonLinkEditor, useExtPersonLinkEditor } from "./ExtPersonLinks"

export type PersonFormLocation = "planner" | "person-dashboard" | "people-list"

export type PersonReturn = {
  id: number
  first_name: string
  last_name: string
}

export type PersonFormSelectedTab = "details" | "contract" | "notes"
type PersonLinks = EditPersonForm_person$data["links"]

type Link = Readonly<
  Omit<PersonLinks[number], "id"> & {
    id?: PersonLinks[number]["id"]
  }
>

type Props = {
  onClose: () => void
  onSaveClose?: () => void
  onSuccess?: (person: PersonReturn) => void
  person?: EditPersonForm_person$data
  initialTab?: PersonFormSelectedTab
  location: PersonFormLocation
}

const fragment = graphql`
  fragment PersonForm_viewer on users {
    id
    permissions
    first_name
    last_name
    view {
      id
      name
      people_filters
    }
    account {
      default_full_time_minutes
      currency
      business_days
      use_week_numbers
      teams {
        id
        name
      }
      holidays_groups(order_by: { name: asc }) {
        id
        name
      }
      clients {
        id
        name
      }
      roles {
        id
        name
        active
        default_hour_cost: default_hour_cost_private
      }
      ...TeamSelector_account
      ...CustomFieldSectionHeaderPerson_account
    }
    ...PersonCustomEditor_viewer
  }
`

const query = graphql`
  query PersonFormQuery {
    current_account {
      id
      user_accounts {
        id
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`

const hasChangedDate = (oldDate, newDate) => {
  if (!oldDate && !newDate) {
    return false
  }

  if ((!oldDate && newDate) || (oldDate && !newDate)) {
    return true
  }

  return oldDate !== formatDate(newDate, "yyyyMMdd") ? true : false
}

const PersonForm = (props: Props) => {
  const {
    person,
    onClose,
    onSaveClose,
    onSuccess,
    initialTab = "details",
    location,
  } = props
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<PersonForm_viewer$key>(fragment, viewerQuery)
  const { current_account } = useLazyLoadQuery<PersonFormQuery>(query, {})
  const { view, permissions } = viewer
  const {
    roles,
    teams,
    holidays_groups,
    default_full_time_minutes,
    currency,
    business_days,
    use_week_numbers,
  } = viewer.account

  const { can, subject } = usePermissions()
  const noFinancialPermissions =
    !can("view", subject("Financial")) && !can("view", subject("Salary"))

  const contractPermissions = {
    view: can("view", subject("Contract", { person })),
    create: can("create", subject("Contract", { person })),
    edit: can("edit", subject("Contract", { person })),
  }

  const personPermissions = {
    create: can("create", subject("Person")),
  }

  const formMode = person === undefined ? "create" : "edit"
  // NOTE: When we are creating a person we base contract permissions on if we are allowed to create a person
  // TODO: Contract permissions need to stand on their own instead of leveraging people permissions. Thus a refactor is required
  const contractFormDisabled = match(formMode)
    .with("create", () => !personPermissions.create)
    .with(
      "edit",
      () => !contractPermissions.create && !contractPermissions.edit,
    )
    .exhaustive()

  const currentContract = person
    ? getCurrentOrLastContract(person.contracts)
    : null

  const initialRender = useRef(true)
  const [isSaving, setIsSaving] = useState(false)
  const [hasEditedContract, setHasEditedContract] = useState(false)
  const [contractStartDate, setContractStartDate] = useState(null)
  const [hasEditedDate, setHasEditedDate] = useState(false)
  const [showConfirmContract, setShowConfirmContract] = useState(false)
  const [contractType, setContractType] = useState(null)
  const [firstName, setFirstName] = useState(person?.first_name || "")
  const [lastName, setLastName] = useState(person?.last_name || "")
  const [jobTitle, setJobTitle] = useState(currentContract?.job_title || "")
  const [email, setEmail] = useState(person?.email || "")
  const [emailIsInvalid, setEmailIsInvalid] = useState(false)
  const [links, setLinks] = useState<ReadonlyArray<Link>>(person?.links || [])
  const [role, setRole] = useState(
    currentContract?.role
      ? {
          label: currentContract.role.name,
          value: currentContract.role.id,
          active: currentContract.role.active,
        }
      : null,
  )
  const defaultProjectManagers = match({
    newPerson: person === undefined,
    managePeoplePermission: permissions.manage_people,
  })
    // Existing project managers
    .with({ newPerson: false }, () =>
      person.managers.map((e) => {
        const user = current_account.user_accounts.find(
          (ua) => ua.user.id === e.user_id,
        )?.user
        return {
          value: e.user_id,
          label: formatName(user.first_name, user.last_name),
        }
      }),
    )
    // Accounts with "restricted" permission automatically get added as default editor
    .with({ newPerson: true, managePeoplePermission: "restricted" }, () => [
      {
        value: viewer.id,
        label: formatName(viewer.first_name, viewer.last_name),
      },
    ])
    .otherwise(() => [])

  const [managers, setManagers] = useState(defaultProjectManagers)

  const defaultView =
    useFeature("pre_filtered_views") && view ? view : undefined

  const [team, setTeam] = useState(
    person?.team ? { label: person.team.name, value: person.team.id } : null,
  )
  const [tags, setTags] = useState(
    person?.tags?.map((value) => ({ value, label: value })) || [],
  )
  const [customValues, setCustomValues] = useState<CustomValuesMap>({
    [CustomTypeName.TEXT]: person ? person.custom_text_values : [],
    [CustomTypeName.DATE]: person ? person.custom_date_values : [],
    [CustomTypeName.SELECT]: person ? person.custom_select_values : [],
    [CustomTypeName.CHECKBOX]: person ? person.custom_checkbox_values : [],
  })
  const [selectedHolidaysGroup, setSelectedHolidaysGroup] =
    useState<HolidaysOption>(
      person?.holidays_group
        ? {
            label: person.holidays_group.name,
            value: person.holidays_group.id,
          }
        : null,
    )
  const [selectedSkills, setSelectedSkills] = useState<SkillOption[]>(
    person?.competencies
      ?.map((c) => {
        return {
          value: c.skill.id,
          label: c.skill.name,
          level: c.level,
        }
      })
      .sort((a, b) => sortByString(a.label, b.label)) || [],
  )
  const [startDate, setStartDate] = useState(
    !person || !currentContract?.start_date
      ? null
      : dateHelpers.parseRunnDate(currentContract.start_date),
  )
  const [endDate, setEndDate] = useState(
    currentContract?.end_date
      ? dateHelpers.parseRunnDate(currentContract.end_date)
      : null,
  )
  const [startDateError, setStartDateError] = useState("")
  const [endDateError, setEndDateError] = useState("")
  const [selectedRelationship, setSelectedRelationship] = useState(
    EMPLOYMENT_OPTIONS.find(
      (option) =>
        option.value === (currentContract?.employment_type || "employee"),
    ),
  )
  const [minutesPerDay, setMinutesPerDay] = useState(
    currentContract?.minutes_per_day || default_full_time_minutes,
  )
  const [rosteredDays, setRosteredDays] = useState(
    currentContract?.rostered_days || [],
  )
  const [cost, setCost] = useState(currentContract?.cost ?? null)
  const [references, setReferences] = useState(person?.references || {})

  const hasNewRole = role && !roles.some((x) => role.value === x.id)
  const selectedRole =
    role && !hasNewRole ? roles.find((r) => r.id === role.value) : null

  const extPersonLinkEditorState = useExtPersonLinkEditor({
    personRef: person,
  })

  useEffect(
    () => {
      if (!person || !currentContract) {
        return
      }

      // ignore state changes on initial render
      if (initialRender.current) {
        initialRender.current = false
      } else {
        // flag for changes in start date to show date input field in the
        // next step when it hasn't been edited
        let hasEditedDateVal = false
        // flag for changes in contract details
        let hasEditedContractVal = false

        if (hasChangedDate(currentContract.start_date, startDate)) {
          hasEditedDateVal = true
          hasEditedContractVal = true
        }

        const currentJobTitle =
          currentContract.job_title === null ? "" : currentContract.job_title

        if (
          role?.value !== currentContract.role?.id ||
          selectedRelationship.value !== currentContract.employment_type ||
          hasChangedDate(currentContract.start_date, startDate) ||
          hasChangedDate(currentContract.end_date, endDate) ||
          !isDeeplyEqual(currentContract.rostered_days, rosteredDays) ||
          (cost ?? 0) !== currentContract.cost ||
          currentJobTitle !== jobTitle
        ) {
          hasEditedContractVal = true
        }

        setHasEditedDate(hasEditedDateVal)
        setHasEditedContract(hasEditedContractVal)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      role,
      selectedRelationship,
      startDate,
      endDate,
      rosteredDays,
      cost,
      jobTitle,
    ],
  )

  const createPerson = async (personData) => {
    try {
      const newPerson = await personCreateRelay({
        variables: {
          input: {
            ...personData,
            links,
            manager_ids: managers.map((m) => m.value),
          },
          plannerStartDate: PLANNER_INITIAL_DATE,
        },
      })
      setIsSaving(false)
      onSuccess(newPerson)
      return newPerson
    } catch {
      setIsSaving(false)
    }
  }

  const updatePerson = async (personData, contractData, roleId: number) => {
    if (contractStartDate) {
      contractData.start_date = dateHelpers.formatToRunnDate(contractStartDate)
    }

    if (isAfter(contractStartDate, endDate)) {
      contractData.end_date = null
    }

    try {
      await personUpdateRelay({
        variables: {
          input: {
            person: {
              ...personData,
              person_id: person.id,
              links,
              manager_ids: managers.map((m) => m.value),
            },
            contract: {
              ...contractData,
              role_id: roleId,
              contract_type: contractType,
            },
          },
          plannerStartDate: PLANNER_INITIAL_DATE,
        },
      })
      setIsSaving(false)
      onSaveClose ? onSaveClose() : onClose()
    } catch {
      setIsSaving(false)
    }
  }

  const handleSubmit = async () => {
    setIsSaving(true)

    const isANewTeam = team && !teams.some((x) => team.value === x.id)
    const competencies = selectedSkills.map((s) => {
      return {
        skill: { name: s.label },
        level: s.level || null,
      }
    })

    const personData = {
      first_name: firstName,
      last_name: lastName,
      email: email ? email.toLowerCase().trim() : null,
      team_id: !isANewTeam && team ? team.value : null,
      team_name: isANewTeam && team ? team.label : null,
      tags: tags.map((t) => t.label.trim()),
      references,
      competencies,
      holidays_group_id: selectedHolidaysGroup?.value || null,
      custom_values: customValues,
      manager_ids: managers.map((m) => m.value),
    }

    const contractData = {
      employment_type: selectedRelationship.value,
      start_date: startDate ? dateHelpers.formatToRunnDate(startDate) : null,
      end_date: endDate ? dateHelpers.formatToRunnDate(endDate) : null,
      rostered_days: rosteredDays,
      minutes_per_day: minutesPerDay,
      cost: noFinancialPermissions
        ? null
        : (cost ?? selectedRole?.default_hour_cost ?? null),
      job_title: jobTitle,
    }

    const newRole = hasNewRole
      ? await roleCreateRelay({
          variables: {
            input: {
              name: role.label,
              defaultHourCost: 0,
              chargeOutRate: 0,
            },
            todaysDate: dateHelpers.getTodaysDate(),
            includeAggregateFields: false,
          },
        })
      : null

    const trackPersonProperties = {
      role: role?.label,
      tags: tags.map((t) => t.label),
      cost: contractData?.cost,
      rostered_days: contractData?.rostered_days?.filter((day) => day > 0)
        .length,
      manager_ids: managers.map((m) => m.value),
      employment_type: contractData?.employment_type,
      competency_names: competencies.map((c) => c.skill.name),
      competency_levels: competencies.map((c) => c.level),
      job_title: contractData?.job_title,
      form_location: location,
    }
    if (person) {
      track("Person Edited", trackPersonProperties)
    } else {
      track("Person Created", trackPersonProperties)
    }

    if (selectedHolidaysGroup && (!person || !person?.holidays_group?.id)) {
      track("Person Holidays Group Assigned")
    }

    if (person && person?.holidays_group?.id !== selectedHolidaysGroup?.value) {
      selectedHolidaysGroup
        ? track("Person Holidays Group Changed")
        : track("Person Holidays Group Removed")
    }

    window.userflow?.track("Person Create")

    if (person) {
      await updatePerson(
        personData,
        contractData,
        newRole ? newRole.id : role.value,
      )

      extPersonLinkEditorState.save({ personId: person?.id })
    } else {
      const newPerson = await createPerson({
        ...personData,
        ...contractData,
        role_id: newRole ? newRole.id : role.value,
      })
      extPersonLinkEditorState.save({ personId: newPerson?.id })
    }
  }

  const selectStartDate = (day) => {
    setStartDate(day)

    let message = ""
    if (person && !day) {
      message = "is required"
    }
    if (isAfter(day, endDate)) {
      message = "cannot be after end date"
    }

    setStartDateError(message)
    setEndDateError("")
  }

  const selectEndDate = (day) => {
    setEndDate(day)

    let message = ""
    if (isBefore(day, startDate)) {
      message = "cannot be before start date"
    }

    setEndDateError(message)
    setStartDateError("")
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.toLowerCase().trim())
  }

  const handleViewGrouping = (
    options: Option[],
    filterType: AllowedPeopleFilter["type"],
  ) => {
    return getViewsPeopleGroupedOptions(defaultView, options, filterType)
  }

  const formFilterTypeValues: FormFilterTypeValuesPeople = [
    { type: "person_employment_type", values: [selectedRelationship?.value] },
    { type: "person_role_id", values: [role?.value] },
    { type: "person_team_id", values: [team?.value] },
    { type: "person_skill_id", values: selectedSkills?.map((s) => s.value) },
    { type: "person_tag_id", values: tags?.map((t) => t.label) },
    { type: "person_custom_select", values: customValues["select"] },
  ]

  const nonMatchingFilters =
    defaultView &&
    getNonMatchingFilters(defaultView.people_filters, formFilterTypeValues)

  const showContractWarning =
    hasEditedContract && startDate && !showConfirmContract
  const showViewsWarning =
    !person && Boolean(defaultView) && nonMatchingFilters.length > 0

  const isCreatingNewPerson = !person

  // Switch the default tab to details when we do not have permissions to view the contract
  const initialTabWithPermissionsClamp =
    initialTab === "contract" && !contractPermissions.view
      ? "details"
      : initialTab
  const [selectedTab, setSelectedTab] = useState(initialTabWithPermissionsClamp)

  const allLinksValid = links
    .filter((link) => link.name.trim().length || link.href.trim().length)
    .every((link) => {
      return (
        link.name.trim().length &&
        isURL(link.href.trim(), {
          require_protocol: true,
        })
      )
    })

  const disabledTooltipReason = (() => {
    const missingValues = []

    if (!firstName.trim()) {
      missingValues.push("First Name")
    }

    if (!lastName.trim()) {
      missingValues.push("Last Name")
    }

    if (!role && selectedTab === "contract") {
      missingValues.push("Role")
    }

    if (!minutesPerDay) {
      missingValues.push("Hours per day")
    }

    if (missingValues.length === 1) {
      return `${missingValues[0]} is required`
    }

    if (missingValues.length > 0) {
      return `${missingValues.join(", ")} is required`
    }

    if (!allLinksValid) {
      return "At least one link has no name or an incorrect URL"
    }

    return null
  })()

  /**
   * Determines the next action and the text of the button, if action is null the button is disabled
   * Used when job titles are enabled
   */
  const nextAction = (): {
    action: () => void | null
    buttonText: string
  } => {
    const saveButtonText = isCreatingNewPerson ? "Create" : "Save"

    const onSave = (fn: () => void | null) => ({
      action: fn,
      buttonText: saveButtonText,
    })
    const onNext = (fn: () => void | null) => ({
      action: fn,
      buttonText: "Next",
    })

    const disabledSave = onSave(null)
    const disabledNext = onNext(null)
    const submitOnSave = onSave(() => void handleSubmit())

    if (isSaving) {
      return disabledSave
    }

    if (showConfirmContract) {
      if (
        contractType === "edit" ||
        (contractType === "new" && (contractStartDate || hasEditedDate))
      ) {
        return submitOnSave
      }

      return disabledSave
    }

    const areMandatoryPersonFieldsValid =
      firstName.trim() && lastName.trim() && !emailIsInvalid

    if (!areMandatoryPersonFieldsValid) {
      if (hasEditedContract || isCreatingNewPerson) {
        return disabledNext
      }

      return disabledSave
    }

    if (!allLinksValid) {
      if (hasEditedContract || isCreatingNewPerson) {
        return disabledNext
      }
      return disabledSave
    }

    if (selectedTab === "details") {
      if (isCreatingNewPerson) {
        return onNext(() => setSelectedTab("contract"))
      }

      if (hasEditedContract) {
        return onNext(() => setSelectedTab("contract"))
      }

      return submitOnSave
    }

    const areMandatoryContractFieldsValid =
      minutesPerDay && role && !startDateError && !endDateError

    if (!areMandatoryContractFieldsValid) {
      if (hasEditedContract) {
        return disabledNext
      }

      return disabledSave
    }

    if (selectedTab === "contract") {
      if (isCreatingNewPerson || !hasEditedContract) {
        return submitOnSave
      }

      return onNext(() => setShowConfirmContract(true))
    }

    return disabledSave
  }

  const isNextActionDisabled = nextAction().action === null

  const Email = (
    <InputEmail
      label="Email (optional)"
      id="person-email"
      onChange={handleEmailChange}
      value={email}
      handleValidation={(e) => setEmailIsInvalid(e)}
      className="fs-exclude"
    />
  )

  const Team = (
    <TeamSelector
      type="person"
      label="Team (optional)"
      alwaysShow
      id="person-team"
      account={viewer.account}
      defaultTeam={team}
      placeholder="Select or type to create new"
      onChange={(e) => setTeam(e)}
      formatSelectOptions={handleViewGrouping}
    />
  )

  const Holidays = Boolean(holidays_groups.length) && (
    <div>
      <HolidaysGroupSelector
        selectedHolidaysGroup={selectedHolidaysGroup}
        setSelectedHolidaysGroup={setSelectedHolidaysGroup}
      />
      {person && selectedHolidaysGroup && (
        <HolidayRemovalWarning
          personName={person.first_name}
          currentHolidayName={person.holidays_group?.name}
          newHolidayName={selectedHolidaysGroup.label}
          className={styles.holidayRemovalWarning}
        />
      )}
    </div>
  )

  const Role = (
    <RoleSelector
      label={
        <TitledHelpTooltip
          title="Default Role"
          tooltipContent={DEFAULT_ROLE_TOOLTIP}
        />
      }
      id="person-role"
      isCreatable={can("create", subject("Role"))}
      placeholder="Select or type to create new"
      onChange={(e) => setRole(e)}
      defaultRole={role}
      formatSelectOptions={handleViewGrouping}
      isDisabled={contractFormDisabled}
    />
  )

  const isContractTabDisabled =
    !contractPermissions.view || isNextActionDisabled

  return (
    <>
      <div className={styles.warningWrapper}>
        {showContractWarning && (
          <SmallAlert intent="warning">
            Contract details were changed. Please confirm details in the next
            step.
          </SmallAlert>
        )}
        {showViewsWarning && (
          <SmallAlert intent="info">
            <ViewFieldsTooltip
              minimal
              type="person"
              viewName={defaultView.name}
              nonMatchingFilters={nonMatchingFilters}
            />
          </SmallAlert>
        )}
      </div>
      <ModalBody
        wide
        className={styles.inSidePanelContainer}
        currentTab={selectedTab}
      >
        {showConfirmContract ? (
          <div className={styles.confirmContract}>
            <ConfirmContractForm
              person={person}
              selectedContractType={contractType}
              selectedStartDate={startDate}
              contractStartDate={contractStartDate}
              showDateInput={!hasEditedDate}
              setContractType={setContractType}
              setContractStartDate={setContractStartDate}
            />
          </div>
        ) : (
          <Tabs
            large
            id="CrPersonSubTabs"
            selectedTabId={selectedTab}
            onChange={(newTabId) =>
              setSelectedTab(newTabId as PersonFormSelectedTab)
            }
            className={styles.personTabs}
          >
            <Tab
              id="details"
              title={isCreatingNewPerson ? "1. DETAILS" : "DETAILS"}
              panel={
                <div className={cc([styles.tabContainer, styles.inSidePanel])}>
                  <div className={styles.twoColumnWrapper}>
                    <Input
                      name="first-name"
                      id="first-name"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoFocus
                      className="fs-exclude"
                    />
                    <Input
                      name="last-name"
                      id="last-name"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="fs-exclude"
                    />
                  </div>
                  <div className={styles.twoColumnWrapper}>
                    {Email}
                    {Team}
                  </div>
                  {Holidays}
                  <SkillsSelector
                    selectedSkills={selectedSkills}
                    setSelectedSkills={(updatedSkills) =>
                      setSelectedSkills(updatedSkills)
                    }
                    formatSelectOptions={handleViewGrouping}
                  />
                  <PersonTagSelector
                    label="People Tags (optional)"
                    onChange={(e) => setTags(e)}
                    existingTags={person?.tags}
                    formatSelectOptions={handleViewGrouping}
                  />
                  <Suspense
                    fallback={
                      // This is a dummy Selector to prevent the form from jumping when the real one is loaded
                      <Select
                        id="person-managers"
                        value={null}
                        isMulti
                        onChange={() => null}
                        label="Person Managers (optional)"
                        options={[]}
                        placeholder="Select or search for user"
                      />
                    }
                  >
                    <div className={styles.oneColumnWrapper}>
                      <ManagerSelector
                        selectedManagers={managers}
                        setSelectedManagers={setManagers}
                        type="people"
                      />
                    </div>
                  </Suspense>
                  <CustomFieldSectionHeader
                    type="person"
                    account={viewer.account}
                    style={{
                      marginTop: "var(--spacing-default)",
                      marginBottom: "initial",
                    }}
                  />
                  <PersonCustomEditor
                    person={person}
                    onUpdate={setCustomValues}
                  />
                  <Suspense fallback="">
                    <ExtPersonLinkEditor state={extPersonLinkEditorState} />
                  </Suspense>
                  <LinkTable
                    links={links}
                    setUpdatedLinks={(updatedLinks) => setLinks(updatedLinks)}
                    parent="person"
                  />
                  <ReferencesTable
                    dataType="Person"
                    references={references}
                    setUpdatedReferences={setReferences}
                  />
                </div>
              }
            />
            <Tab
              id="contract"
              title={isCreatingNewPerson ? "2. CONTRACT" : "CONTRACT"}
              disabled={isContractTabDisabled}
              panel={
                <div
                  className={cc([
                    styles.tabContainer,
                    styles.contractWrapper,
                    styles.inSidePanel,
                  ])}
                >
                  {Role}
                  <Input
                    name="job-title"
                    id="job-title"
                    label="Job Title (optional)"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    placeholder="Job position or function"
                    disabled={contractFormDisabled}
                  />
                  <div style={{ gridColumnStart: 1 }}>
                    <Select
                      isClearable={false}
                      name="person-relationship"
                      id="person-relationship"
                      label="Employment Type"
                      value={selectedRelationship}
                      onChange={(e) => setSelectedRelationship(e)}
                      options={handleViewGrouping(
                        [...EMPLOYMENT_OPTIONS],
                        "person_employment_type",
                      )}
                      placeholder="Select employment type.."
                      isDisabled={contractFormDisabled}
                    />
                  </div>
                  <DateInput
                    canClearSelection={false}
                    label={`Start Date ${person ? "" : "(optional)"}`}
                    error={startDateError}
                    onChange={selectStartDate}
                    placeholder="Type or select start date"
                    value={contractStartDate || startDate}
                    inputProps={{ id: "person-start-date" }}
                    disabled={contractFormDisabled}
                    showWeekNumbers={use_week_numbers}
                  />
                  <DateInput
                    label="End Date (optional)"
                    onChange={selectEndDate}
                    error={endDateError}
                    placeholder="Type or select end date"
                    value={endDate}
                    inputProps={{ id: "person-end-date" }}
                    disabled={contractFormDisabled}
                    showWeekNumbers={use_week_numbers}
                  />
                  <Availability
                    companyDefaultMinutes={default_full_time_minutes}
                    businessDays={business_days}
                    setMinutesPerDay={setMinutesPerDay}
                    minutesPerDay={minutesPerDay}
                    rosteredDays={rosteredDays}
                    setRosteredDays={setRosteredDays}
                    disabled={contractFormDisabled}
                  />
                  <CostToBusiness
                    currency={currency}
                    setCost={setCost}
                    cost={cost}
                    selectedRole={selectedRole}
                    isNewPerson={!person}
                    disabled={contractFormDisabled}
                  />
                </div>
              }
            />
          </Tabs>
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {showConfirmContract && (
            <div
              className={styles.back}
              onClick={() => setShowConfirmContract(false)}
            >
              <ChevronDown alt="back" />
              Back
            </div>
          )}
          <Button text="Cancel" onClick={() => onClose()} />
          <Tooltip
            disabled={disabledTooltipReason === null}
            content={disabledTooltipReason}
            position="top"
          >
            <Button
              text={nextAction().buttonText}
              id={`${person ? "edit" : "create"}-person-button`}
              loading={isSaving}
              outlined={false}
              onClick={() => {
                const action = nextAction().action
                action && action()
              }}
              disabled={isNextActionDisabled}
            />
          </Tooltip>
        </div>
      </ModalFooter>
    </>
  )
}

export default PersonForm
