/**
 * @generated SignedSource<<75d3e080ea3e19ec758f08c4f387b33b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectTemplatePlannerMenuDeleteMutation$variables = {
  id: number;
};
export type ProjectTemplatePlannerMenuDeleteMutation$data = {
  readonly deleteTemplate: {
    readonly account: {
      readonly id: number;
      readonly projectTemplates: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly projects: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type ProjectTemplatePlannerMenuDeleteMutation = {
  response: ProjectTemplatePlannerMenuDeleteMutation$data;
  variables: ProjectTemplatePlannerMenuDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "templateId",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteTemplateResult",
    "kind": "LinkedField",
    "name": "deleteTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": "projectTemplates",
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "is_template": {
                    "_eq": true
                  }
                }
              }
            ],
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": "projects(where:{\"is_template\":{\"_eq\":true}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectTemplatePlannerMenuDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectTemplatePlannerMenuDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4a563b0a507274af6d8cc79c22b5ee3e",
    "id": null,
    "metadata": {},
    "name": "ProjectTemplatePlannerMenuDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectTemplatePlannerMenuDeleteMutation(\n  $id: Int!\n) {\n  deleteTemplate(templateId: $id) {\n    account {\n      id\n      projects {\n        id\n      }\n      projectTemplates: projects(where: {is_template: {_eq: true}}) {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cb1323e8e50a28b8a2aa5e6c99104fb";

export default node;
