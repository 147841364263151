import { AnchorButton, Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React, { useState } from "react"
import { match } from "ts-pattern"

import styles from "./FilterSaveButton.module.css"

import { Popover2 } from "~/common/Popover2"
import { BookmarkOutlineIcon } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"

import type { FilterSet } from "./types"

export const hasInvalidFilters = (filterSet: FilterSet) =>
  fe.filters.some(filterSet.filters, (filter: fe.filters.AnyFilter) => {
    if (
      "datePayload" in filter.options &&
      fe.utils.isDatePayload(filter.options.datePayload)
    ) {
      const { datePayload } = filter.options

      return match(datePayload)
        .with({ type: "range" }, ({ start, end }) => {
          return start == null || end == null
        })
        .with({ type: "exact" }, ({ exact }) => {
          return exact == null
        })
        .otherwise(() => false)
    }

    return (
      Array.isArray(filter.options.list) && filter.options.list.length === 0
    )
  })

type FilterNameEditorProps = {
  placeholder: string
  onSave: (name: string) => void
}

const FilterNameEditor = (props: FilterNameEditorProps) => {
  const { placeholder, onSave } = props

  const [value, setValue] = useState("")

  const isValid = value.trim().length > 0

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleSave = () => {
    onSave(value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && event.key === "Enter") {
      event.stopPropagation()
      handleSave()
    }
  }

  return (
    <div
      className={styles.filterNameEditor}
      data-test="SuperSearch_FilterNameEditor"
    >
      <input
        autoFocus
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <button
        className={styles.saveButton}
        onClick={handleSave}
        disabled={!isValid}
      >
        Save
      </button>
    </div>
  )
}

type FilterSaveButtonProps = {
  filterSet: FilterSet
  disabled?: boolean
  inputPlaceholder?: string
  onSave: (filterSet: FilterSet) => void
}

const FilterSaveButton = (props: FilterSaveButtonProps) => {
  const {
    disabled: componentDisabled,
    inputPlaceholder,
    filterSet,
    onSave,
  } = props

  const { Can, subject } = usePermissions()

  const invalidFilters = hasInvalidFilters(filterSet)

  const hidden = !Boolean(filterSet.filters)
  const disabled = componentDisabled || invalidFilters

  const [isOpen, setIsOpen] = useState(false)

  const handleInteraction = (nextOpenState: boolean) => {
    setIsOpen(nextOpenState)
  }

  const handleSave = (name: string) => {
    onSave({
      name,
      filters: filterSet.filters,
    })
    setIsOpen(false)
  }

  if (hidden) {
    return null
  }

  const childButton = (
    // Use AnchorButton so Tooltip works if disabled https://github.com/palantir/blueprint/issues/1591
    <AnchorButton
      className={styles.button}
      disabled={disabled}
      data-test="SuperSearch_FilterSaveButton"
    >
      <BookmarkOutlineIcon />
      Save
    </AnchorButton>
  )

  if (invalidFilters) {
    return (
      <Can I="create" this={subject("UserFilterSet")}>
        <Tooltip
          content="⚠️ Cannot save filters with missing values"
          placement="top"
        >
          {childButton}
        </Tooltip>
      </Can>
    )
  }

  return (
    <Can I="create" this={subject("UserFilterSet")}>
      <Popover2
        className={`${styles.container} ${disabled ? styles.disabled : ""} ${
          isOpen ? styles.isOpen : ""
        }`}
        disabled={disabled}
        isOpen={isOpen}
        content={
          <FilterNameEditor
            onSave={handleSave}
            placeholder={inputPlaceholder}
          />
        }
        placement="bottom-end"
        onInteraction={handleInteraction}
      >
        {childButton}
      </Popover2>
    </Can>
  )
}

export default FilterSaveButton
