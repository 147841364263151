import { graphql } from "react-relay"

import * as relayids from "~/helpers/relayids"
import { addLinkedRecord } from "~/mutations/helpers"

import Subscription from "./Subscription"

class RoleSubscription extends Subscription {
  public subscription = graphql`
    subscription RoleSubscription {
      roles(limit: 1, order_by: { updated_at: desc }) {
        id
        name
        active
        default_hour_cost: default_hour_cost_private
        references
        account_id
      }
    }
  `

  public getSubscriptionConfig = () => {
    return Promise.resolve({
      subscription: this.subscription,
      variables: {},
      updater: (store, data) => {
        const payload = data.roles
        payload.forEach((roleData) => {
          const account = store.get(
            relayids.accounts.encode(roleData.account_id),
          )
          const role = store.get(relayids.roles.encode(roleData.id))
          addLinkedRecord(account, "all_roles", role)
          addLinkedRecord(account, "roles", role)
        })
      },
    })
  }
}

export default RoleSubscription
