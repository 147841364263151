import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./FilterBlock.module.css"

import { Cross as CrossIcon } from "~/common/react-icons"

type Props = {
  label: string
  icon: React.ReactNode
  disabled?: boolean
  onRemove: () => void
}

const FilterBlockBoolean = (props: Props) => {
  const { label, icon, disabled, onRemove } = props

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!disabled) {
      onRemove()
    }
  }

  return (
    <button
      disabled={disabled}
      className={`
        ${styles.targetButton}
    `}
    >
      <div className={styles.targetButtonText}>
        <span className={styles.targetIcon}>{icon}</span>
        <span className={styles.targetLabel}>{label}</span>
      </div>

      {!disabled && (
        <Tooltip
          className={styles.removeButtonTooltip}
          content="Remove filter"
          placement="top"
        >
          <div className={styles.removeButton} onClick={handleRemove}>
            <CrossIcon />
          </div>
        </Tooltip>
      )}
    </button>
  )
}

export default FilterBlockBoolean
