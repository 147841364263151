import {
  toFullTimeEquivalentEffort,
  toMinutesPerDayOfEffort,
} from "@runn/calculations"
import cc from "classcat"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useFragment } from "react-relay"
import { CSSObjectWithLabel } from "react-select"
import { graphql } from "relay-runtime"
import { match } from "ts-pattern"

import styles from "./TotalEffortInput.module.css"

import { TotalEffortInput_account$key } from "./__generated__/TotalEffortInput_account.graphql"

import { track } from "~/helpers/analytics"

import Select from "~/common/Select"

import { showToast } from "~/containers/ToasterContainer"

import {
  TotalEffortDisplayUnit,
  changedTotalEffortDisplayUnit,
} from "../../display_units/totalEffortDisplayUnitSlice"
import { useAppSelector } from "../../hooks/redux"

import Duration from "../Duration"

type TotalEffortOption = {
  readonly value: TotalEffortDisplayUnit
  readonly label: string
}

const totalEffortOptions: readonly TotalEffortOption[] = [
  { value: "hours" as const, label: "Hours" },
  { value: "days" as const, label: "Days" },
] as const

const TotalEffortSelect = () => {
  const dispatch = useDispatch()
  const totalEffortDisplayUnit = useAppSelector(
    (state) => state.displayUnit.totalEffort,
  )

  const handleTotalEffortDisplayUnitChanged = useCallback(
    (value: TotalEffortDisplayUnit) => {
      track("Total Effort Unit Changed", { unit: value })
      dispatch(changedTotalEffortDisplayUnit(value))
    },
    [dispatch],
  )
  const selectedDisplayOption = totalEffortOptions.find(
    (v) => v.value === totalEffortDisplayUnit,
  )
  return (
    <>
      <Select
        isSearchable={false}
        fontSize={14}
        width={72}
        controlStyles={{
          borderBottomLeftRadius: "var(--rounded-none)",
          borderTopLeftRadius: "var(--rounded-none)",
          maxHeight: 40,
        }}
        optionStyles={{
          padding: "var(--spacing-small)",
          paddingRight: "var(--spacing-medium)",
          whiteSpace: "nowrap",
          fontSize: 13,
        }}
        value={selectedDisplayOption}
        onChange={(newValue) =>
          handleTotalEffortDisplayUnitChanged(newValue.value)
        }
        options={totalEffortOptions}
        styles={{
          singleValue: (base) =>
            ({
              ...base,
              color: "var(--slate)",
            }) as CSSObjectWithLabel,
          valueContainer: (base) =>
            ({
              ...base,
              padding: "0 0 0 5px",
            }) as CSSObjectWithLabel,
          menu: (base) =>
            ({
              ...base,
              width: "auto",
              borderRadius: "var(--rounded)",
            }) as CSSObjectWithLabel,
          menuList: (base) =>
            ({
              ...base,
              padding: 0,
            }) as CSSObjectWithLabel,
          dropdownIndicator: (base) =>
            ({
              ...base,
              paddingLeft: "0",
              paddingRight: "2px",
            }) as CSSObjectWithLabel,
        }}
      />
    </>
  )
}

type TotalEffortInputProps = {
  minutesPerDay: number
  workingDayCount: number
  minMinutes?: number
  maxMinutes: number
  onMinutesPerDayChange: (minutes: number) => void
  account: TotalEffortInput_account$key
  hideSelect?: boolean
  label?: string
  contractMinutes: number
  isTimeOff?: boolean
}

export const TotalEffortInput = (props: TotalEffortInputProps) => {
  const {
    hideSelect,
    minutesPerDay,
    workingDayCount,
    onMinutesPerDayChange,
    minMinutes,
    maxMinutes,
    label,
    contractMinutes,
    isTimeOff,
  } = props

  const account = useFragment(
    graphql`
      fragment TotalEffortInput_account on accounts {
        default_full_time_minutes
      }
    `,
    props.account,
  )

  const totalEffortDisplayUnit = useAppSelector(
    (state) => state.displayUnit.totalEffort,
  )

  const totalEffort = toFullTimeEquivalentEffort({
    minutesOfEffort: minutesPerDay,
    fulltimeMinutesPerDay: account.default_full_time_minutes,
  })

  const [totalEffortDays, setTotalEffortDays] = useState(String(totalEffort))

  const handleMinutesPerDayChanged = useCallback(
    (minutes: number) => {
      track("Total Effort Changed", { unit: "minutes", value: minutes })
      onMinutesPerDayChange(minutes / workingDayCount)
    },
    [onMinutesPerDayChange, workingDayCount],
  )

  const onChangeDays = (e) => {
    setTotalEffortDays(e.target.value)
  }

  const saveDays = useCallback(
    (e) => {
      setTotalEffortDays(e.target.value)

      const days = Number(e.target.value)

      if (Number.isNaN(days)) {
        return
      }

      const perDay = toMinutesPerDayOfEffort({
        fulltimeEquivalentEffort: Number.isNaN(days) ? 0 : days,
        fulltimeMinutesPerDay: account.default_full_time_minutes,
      })
        .dividedBy(workingDayCount)
        .toNumber()

      // Update UI if perDay value requires rounding and therefore shifts the totalEffortDays value
      if (perDay !== Math.round(perDay)) {
        const roundedEffort = toFullTimeEquivalentEffort({
          minutesOfEffort: Math.round(perDay),
          fulltimeMinutesPerDay: account.default_full_time_minutes,
        })

        setTotalEffortDays(String(roundedEffort))
      }

      if (perDay * workingDayCount > maxMinutes) {
        showToast({
          message:
            "Total effort can not exceed 24hrs per day. Total effort has been adjusted",
          type: "warning",
        })

        const maxEffortInDays =
          ((24 * 60) / account.default_full_time_minutes) * workingDayCount

        setTotalEffortDays(`${maxEffortInDays}`)

        const updatedPerDay = toMinutesPerDayOfEffort({
          fulltimeEquivalentEffort: maxEffortInDays,
          fulltimeMinutesPerDay: account.default_full_time_minutes,
        })
          .dividedBy(workingDayCount)
          .toNumber()

        onMinutesPerDayChange(updatedPerDay)
        return
      }

      track("Total Effort Changed", { unit: "days", value: days })

      onMinutesPerDayChange(perDay)
      // if we include onMinutesPerDayChange this breaks as it re-renders all the time
    },
    [
      account.default_full_time_minutes,
      workingDayCount,
      maxMinutes,
      // if we don't include onMinutesPerDayChange this breaks as it re-renders all the time
      onMinutesPerDayChange,
    ],
  )

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      saveDays(event)
    }
  }

  useEffect(() => {
    setTotalEffortDays(`${totalEffort}`)
  }, [totalEffort, onMinutesPerDayChange])

  return (
    <div data-test="totalEffortContainer">
      <label htmlFor="totalEffort">{label || "Total Effort"}</label>
      <div className={styles.totalEffort}>
        {match(isTimeOff ? "hours" : totalEffortDisplayUnit)
          .with("days", () => (
            <input
              value={totalEffortDays}
              onChange={onChangeDays}
              onBlur={saveDays}
              onKeyUp={handleKeyUp}
              className={cc([
                styles.daysEffort,
                {
                  [styles.noSelect]: hideSelect,
                },
              ])}
            />
          ))
          .with("hours", () => (
            <Duration
              handleMinutesPerDay={handleMinutesPerDayChanged}
              minutesPerDay={minutesPerDay}
              className={cc([
                styles.duration,
                {
                  [styles.noSelect]: hideSelect,
                },
              ])}
              maxMinutes={maxMinutes}
              minMinutes={minMinutes}
              defaultDuration={contractMinutes}
              allowZero
              allowEmpty={false}
            />
          ))
          .exhaustive()}
        {!hideSelect && <TotalEffortSelect />}
      </div>
    </div>
  )
}
