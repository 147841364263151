import React from "react"

import FilteredPlannerContext from "./FilteredPlannerContext"

type Props = {
  children: React.ReactNode
  projectIDs: number[]
  peopleIDs: number[]
  totalProjectCount: number
  totalPeopleCount: number
}

const FilteredPlannerProvider = (props: Props) => {
  return (
    <FilteredPlannerContext.Provider
      value={{
        projectIDs: props.projectIDs,
        peopleIDs: props.peopleIDs,
        totalProjectCount: props.totalProjectCount,
        totalPeopleCount: props.totalPeopleCount,
      }}
    >
      {props.children}
    </FilteredPlannerContext.Provider>
  )
}

export default FilteredPlannerProvider
