import { graphql, useFragment } from "react-relay"

import type { useIntegrationList_account$key } from "./__generated__/useIntegrationList_account.graphql"

const accountFragment = graphql`
  fragment useIntegrationList_account on accounts {
    activeIntegrations: integrationsV2(where: { state: { _neq: "PENDING" } }) {
      id
      category
      state
      active
      integration_service {
        id
        name
        square_image_url
      }
    }
  }
`

type UseIntegrationListOptions = {
  account: useIntegrationList_account$key | null
}

const useIntegrationList = (options: UseIntegrationListOptions) => {
  const account = useFragment(accountFragment, options.account)
  return account.activeIntegrations
}

export { useIntegrationList }
