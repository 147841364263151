import React, { useEffect, useState } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { ProjectGroup_account$key } from "./__generated__/ProjectGroup_account.graphql"
import { ProjectGroup_user$key } from "./__generated__/ProjectGroup_user.graphql"
import { ProjectManagement_user$data } from "./__generated__/ProjectManagement_user.graphql"

import HeadingBar from "~/common/HeadingBar"

import { SET_PROJECT_GROUP_BY_EXPANDED } from "~/Planner/Planner.actions"
import { selectGroupProjectBy } from "~/Planner/reducer2/projectSortSlice"
import { useAppSelector } from "~/hooks/redux"
import { getSettings, setSetting } from "~/localsettings"
import { ReduxState } from "~/rootReducer"

import ProjectRow from "./ProjectRow"

type Props = {
  account: ProjectGroup_account$key
  userId: number
  user: ProjectGroup_user$key
  groupName: string
  visibleProjects: ProjectManagement_user$data["account"]["projects"]
  favourites: ProjectManagement_user$data["favourite_projects"]
  onlyOneResult: boolean
}

const closeOpenedProjects = (groupName) => {
  const plannerProjectsExpanded = getSettings()?.plannerProjectsExpanded || []
  // remove expanded row
  const newPlannerProjectExpanded = plannerProjectsExpanded.filter(
    (row) => row.groupName !== groupName,
  )
  setSetting("plannerProjectsExpanded", newPlannerProjectExpanded)
}

const ProjectGroup = (props: Props) => {
  const { userId, groupName, favourites, visibleProjects, onlyOneResult } =
    props

  const account = useFragment(
    graphql`
      fragment ProjectGroup_account on accounts
      @argumentDefinitions(
        projectsFilter: { type: "projects_bool_exp" }
        peopleFilter: { type: "people_bool_exp" }
        plannerStartDate: { type: "date!" }
      ) {
        ...ProjectRow_account
          @arguments(
            plannerStartDate: $plannerStartDate
            projectsFilter: $projectsFilter
            peopleFilter: $peopleFilter
          )
      }
    `,
    props.account,
  )

  const user = useFragment(
    graphql`
      fragment ProjectGroup_user on users {
        id
        ...ProjectRow_user
      }
    `,
    props.user,
  )

  const dispatch = useDispatch()
  const groupProjectsBy = useAppSelector((state) =>
    selectGroupProjectBy(state.plannerV2.project),
  )
  const { expandedProjectGroups, expandAll } = useSelector(
    (state: ReduxState) => {
      return {
        expandedProjectGroups: state.planner.expandedProjectGroups,
        expandAll: state.planner.expandAll,
      }
    },
    isDeeplyEqual,
  )

  const selectedGroup = expandedProjectGroups.find(
    (g) => g.groupByFilter === groupProjectsBy,
  )

  const isDefaultGroupAll = groupProjectsBy === "default"
  const startOpen =
    isDefaultGroupAll ||
    onlyOneResult ||
    selectedGroup?.expanded?.includes(groupName) ||
    expandAll

  const [isOpen, setIsOpen] = useState(startOpen)
  useEffect(() => {
    setIsOpen(expandAll)
  }, [expandAll]) // Expand all
  useEffect(() => {
    startOpen && setIsOpen(true)
  }, [startOpen]) // Open by default. Must go second

  const filteredExpanded =
    selectedGroup?.expanded.filter((g) => g !== groupName) || []

  const count = visibleProjects.length

  const toggleGroup = () => {
    setIsOpen(!isOpen)
    const selectedGroupUpdated = {
      groupByFilter: groupProjectsBy,
      expanded: [],
    }

    if (isOpen) {
      // remove the selected groupName from expandedProjects
      selectedGroupUpdated.expanded = selectedGroup ? filteredExpanded : []
      closeOpenedProjects(groupName)
    } else {
      // add selected groupName to expandedProjects
      selectedGroupUpdated.expanded = selectedGroup
        ? [...filteredExpanded, groupName]
        : []
    }

    dispatch({
      type: SET_PROJECT_GROUP_BY_EXPANDED,
      payload: [selectedGroupUpdated],
    })
    setSetting("expandedProjectGroups", [selectedGroupUpdated])
  }

  const renderProjects = () => {
    if (!isOpen) {
      return null
    }

    return visibleProjects.map((project) => (
      <ProjectRow
        key={project.id}
        user={user}
        account={account}
        project={project}
        favourites={favourites}
        onlyOneResult={onlyOneResult}
        userId={userId}
        groupName={groupName}
      />
    ))
  }

  return (
    <div>
      <HeadingBar
        text={isDefaultGroupAll ? "Projects" : groupName}
        count={count}
        id="planner-heading-bar"
        onClick={toggleGroup}
        isCollapsed={!isOpen}
        data-test={`${groupName}-${isOpen ? "expanded" : "collapsed"}`}
      />
      {renderProjects()}
    </div>
  )
}

export default ProjectGroup
