/**
 * @generated SignedSource<<5c4ad59d2e211d1b00600029b10b80f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonNote_note$data = {
  readonly created_at: any;
  readonly created_by: string | null | undefined;
  readonly creator_email: string | null | undefined;
  readonly id: number;
  readonly note: string;
  readonly updated_at: any;
  readonly user_id: number | null | undefined;
  readonly " $fragmentType": "PersonNote_note";
};
export type PersonNote_note$key = {
  readonly " $data"?: PersonNote_note$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonNote_note">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonNote_note",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_by",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator_email",
      "storageKey": null
    }
  ],
  "type": "people_notes",
  "abstractKey": null
};

(node as any).hash = "dcc5664d6e8af75cc420372cd63b0755";

export default node;
