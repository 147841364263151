import setValuesOnRecord from "./set-values-on-record"
import type { DefaultRowData, Model, Store } from "./types"

const applyUpdateAction = (
  store: Store,
  model: Model,
  rowData: DefaultRowData,
  changedFields: Partial<DefaultRowData>,
) => {
  const globalId = model.getDataId(rowData)
  const $recordProxy = store.get(globalId)
  if ($recordProxy) {
    setValuesOnRecord($recordProxy, model, changedFields)
    if (model.postUpdate) {
      model.postUpdate($recordProxy, changedFields)
    }
  }
}

export default applyUpdateAction
