/**
 * @generated SignedSource<<6af62a0b9b7334f9288db4235c5a161d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBudget_viewer$data = {
  readonly account: {
    readonly default_full_time_minutes: number;
    readonly id: number;
    readonly rate_cards: ReadonlyArray<{
      readonly blended_rate: number | null | undefined;
      readonly blended_rate_card: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
      readonly role_charge_out_rates: ReadonlyArray<{
        readonly charge_out_rate: number | null | undefined;
        readonly rate_card_id: number;
        readonly role_id: number;
      }>;
    }>;
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
  };
  readonly id: number;
  readonly permissions: any;
  readonly " $fragmentType": "ProjectBudget_viewer";
};
export type ProjectBudget_viewer$key = {
  readonly " $data"?: ProjectBudget_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBudget_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBudget_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "rate_cards",
          "kind": "LinkedField",
          "name": "rate_cards",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "blended_rate",
              "args": null,
              "kind": "ScalarField",
              "name": "blended_rate_private",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blended_rate_card",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "role_charge_out_rates",
              "kind": "LinkedField",
              "name": "role_charge_out_rates",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rate_card_id",
                  "storageKey": null
                },
                {
                  "alias": "charge_out_rate",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "charge_out_rate_private",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "441268f24c23a25450335e18c474b37e";

export default node;
