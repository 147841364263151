import * as fe from "@runn/filter-engine"

import { CustomValuesMap } from "~/helpers/custom-field-helpers"
import {
  AllowedPeopleFilter,
  AllowedProjectFilter,
} from "~/helpers/filter-engine"

import { SuperSearchConfig } from "~/common/SuperSearch"

type configProps = {
  defaultConfig: SuperSearchConfig
  hiddenFilters: string[]
  hiddenCustomFieldTypeIds: number[]
  filterMenuButtonLabel: string
}

const getViewConfig = (props: configProps): SuperSearchConfig => {
  const {
    defaultConfig,
    hiddenFilters,
    hiddenCustomFieldTypeIds,
    filterMenuButtonLabel,
  } = props
  return {
    ...defaultConfig,
    filterMenuButtonLabel,
    features: {
      ...defaultConfig.features,
      saveFilter: false,
      wildSearch: false,
      clearable: false,
    },
    availableFilters: defaultConfig.availableFilters
      .map((f) => {
        return {
          ...f,
          submenuFilters: f.submenuFilters?.filter((sf) => {
            return !hiddenFilters.includes(String(sf.filter.type))
          }),
        }
      })
      .filter((f) => {
        if (f.submenuFilters && !f.submenuFilters.length) {
          return false
        }

        if (f.filter?.type === "person_custom_select") {
          return !hiddenCustomFieldTypeIds.includes(f.filter?.options.typeId)
        }

        return !hiddenFilters.includes(String(f.filter?.type))
      }),
  }
}

const getGroupedOptions = (defaultView, options, filterList) => {
  const groupedOptions = [
    {
      label: `${defaultView.name} View`,
      options: options.filter((o) =>
        filterList.find((f) =>
          typeof f === "string"
            ? f.toLowerCase() === o.label.toLowerCase()
            : f === o.value,
        ),
      ),
      pinned: true,
    },
    {
      label: "All",
      options: options.filter(
        (o) =>
          !filterList.find((f) =>
            typeof f === "string"
              ? f.toLowerCase() === o.label.toLowerCase()
              : f === o.value,
          ),
      ),
    },
  ]

  return groupedOptions
}

const getViewsProjectGroupedOptions = (
  defaultView:
    | {
        name: string
        project_filters: fe.filters.AnyFilter
      }
    | undefined,
  options,
  filterType: AllowedProjectFilter["type"],
  customSelectName?: string,
) => {
  if (!defaultView) {
    return options
  }

  const defaultViewFilter = fe.filters.find(
    defaultView.project_filters,
    (f) => {
      if (filterType === "project_custom_select") {
        return f.options.name === customSelectName
      }
      return f.type === filterType
    },
  )

  if (!defaultViewFilter) {
    return options
  }

  const filterList = defaultViewFilter.options.list
  return getGroupedOptions(defaultView, options, filterList)
}

const getViewsPeopleGroupedOptions = (
  defaultView:
    | {
        name: string
        people_filters: fe.filters.AnyFilter
      }
    | undefined,
  options,
  filterType: AllowedPeopleFilter["type"],
  customSelectName?: string,
) => {
  if (!defaultView) {
    return options
  }

  const defaultViewFilter = fe.filters.find(defaultView.people_filters, (f) => {
    if (filterType === "person_custom_select") {
      return f.options.name === customSelectName
    }
    return f.type === filterType
  })

  if (!defaultViewFilter) {
    return options
  }

  const filterList = defaultViewFilter.options.list
  return getGroupedOptions(defaultView, options, filterList)
}

export type FormFilterTypeValuesProject = {
  type: AllowedProjectFilter["type"]
  values: string[] | number[] | CustomValuesMap["select"]
}[]

export type FormFilterTypeValuesPeople = {
  type: AllowedPeopleFilter["type"]
  values: string[] | number[] | CustomValuesMap["select"]
}[]

const getNonMatchingFilters = (
  filters: fe.filters.FilterList<fe.engines.local.AllowedFilter>,
  formFilterTypeValues:
    | FormFilterTypeValuesProject
    | FormFilterTypeValuesPeople,
) => {
  // If a view other than "All" is selected, get the non-matching filters
  // that are required for a person/project to be seen in the planner.
  const nonMatchingFilters = fe.filters
    .flatMap(filters, (filter: fe.filters.AnyFilter) => {
      if (!formFilterTypeValues.map((c) => c.type).includes(filter.type)) {
        // filter types that don't have form values
        return filter
      }

      const currentFormField = formFilterTypeValues.find(
        (x) => x.type === filter.type,
      )

      let valueIsSelected

      const filterOptionsList = Array.isArray(filter.options.list)
        ? filter.options.list
        : []

      if (
        filter.type === "project_custom_select" ||
        filter.type === "person_custom_select"
      ) {
        const customSelectValues =
          currentFormField.values as CustomValuesMap["select"]
        const selectedOptions = customSelectValues
          .filter((o) => o.typeId === filter.options.typeId)
          .map((o) => o.optionId)

        valueIsSelected = selectedOptions?.some((o) =>
          filterOptionsList.includes(o),
        )
      }

      if (typeof currentFormField.values[0] === "string") {
        valueIsSelected = currentFormField.values?.some((v) =>
          filterOptionsList.some((l) => l.toLowerCase() === v.toLowerCase()),
        )
      }

      if (typeof currentFormField.values[0] === "number") {
        valueIsSelected = currentFormField.values?.some((v) =>
          filterOptionsList.includes(v),
        )
      }

      return valueIsSelected ? null : filter
    })
    .filter(Boolean)

  return nonMatchingFilters
}

export {
  getViewConfig,
  getViewsProjectGroupedOptions,
  getViewsPeopleGroupedOptions,
  getNonMatchingFilters,
}
