import { Switch } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./ViewControls.module.css"

import { setCalendarWeekendsExpanded } from "~/common/calendar.reducer"

import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

type Props = {
  disabled?: boolean
}

const ShowWeekendsToggle = ({ disabled }: Props) => {
  const showWeekends = useAppSelector(
    (state) => state.calendar.calendarWeekendsExpanded,
  )
  const dispatch = useDispatch()
  const toggleShowWeekends = () => {
    dispatch(setCalendarWeekendsExpanded(!showWeekends))
    setSetting("expandWeekendsOnMonthView", !showWeekends)
  }

  return (
    <div className={styles.showWeekendsToggle}>
      <Switch
        checked={showWeekends}
        disabled={disabled}
        onChange={toggleShowWeekends}
        label="Show Weekends"
        alignIndicator="right"
      />
    </div>
  )
}

export default ShowWeekendsToggle
