import { graphql, useFragment } from "react-relay"

import { useProjectTemplates_account$key } from "./__generated__/useProjectTemplates_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

// @growing-pains-todo: Applying the filter here is self-referential insanity.
// Should be all_projects, but need a new approach for UX.
const fragment = graphql`
  fragment useProjectTemplates_account on accounts
  @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
    projects(where: $projectsFilter) {
      id
      name
      is_template
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useProjectTemplates = (options: Options) => {
  const { user } = options

  const { projects } = useFragment<useProjectTemplates_account$key>(
    fragment,
    user.account,
  )

  const availableOptions = projects
    .filter((project) => project.is_template)
    .map((project) => ({
      value: project.id,
      label: project.name,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useProjectTemplates
