import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type MembersGroupByOption = "role" | "workstream"

type ProjectDashboardReducerState = {
  projectDashboardMembersGroupBy: MembersGroupByOption
}

const getInitialState = (): ProjectDashboardReducerState => ({
  projectDashboardMembersGroupBy: "role",
})

const projectDashboardSlice = createSlice({
  name: "peopleSummary",
  initialState: getInitialState,
  reducers: {
    setProjectDashboardMembersGroupBy: (
      state,
      action: PayloadAction<MembersGroupByOption>,
    ) => {
      state.projectDashboardMembersGroupBy = action.payload
    },
  },
})

const { actions, reducer } = projectDashboardSlice
export const { setProjectDashboardMembersGroupBy } = actions
export default reducer
