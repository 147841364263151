import {
  timeOffCreateRelay,
  timeOffDeleteRelay,
  timeOffUpdateRelay,
} from "~/mutations/TimeOff"

import { updateAssignmentsFromTimeOff } from "./AssignmentActionHelpers"
import * as helpers from "./action_helpers"

const deleteTimeOff = (to: TimeOff) => {
  void timeOffDeleteRelay({ id: to.id })
}

const updateTimeOff = (to: TimeOff) => {
  void timeOffUpdateRelay({
    id: to.id,
    startDate: to.start_date,
    endDate: to.end_date,
    minutesPerDay: to.minutes_per_day,
    note: to.note,
  })
}

const createTimeOff = (to: TimeOff) => {
  void timeOffCreateRelay({
    startDate: to.start_date,
    endDate: to.end_date,
    personId: to.person_id,
    note: to.note,
    minutesPerDay: to.minutes_per_day,
  })
}

export const calculateTimeOffUpdates = (
  to: Omit<TimeOff, "ext_time_off_links">,
  person: Person,
  multiSelectItemIds: number[],
) => {
  const timeOffChanges = {
    create: [],
    update: [],
    delete: [],
  }

  const isNew = !to.id
  const timeOffsExcludingMultiSelect = person.time_offs.filter(
    (timeOff) => multiSelectItemIds.includes(timeOff.id) === false,
  )

  const overlappingTimeOffs = timeOffsExcludingMultiSelect
    .filter((to2) => {
      if (to.id === to2.id) {
        return false
      }
      const isExternalTimeOff = to2.ext_time_off_links.length > 0
      return (
        !isExternalTimeOff &&
        to2.leave_type !== "holiday" &&
        to2.leave_type !== "rostered-off" &&
        helpers.itemsOverlap(to, to2)
      )
    })
    .sort(helpers.sortByStartDate)

  // We handle full overlaps later to filter then out for the next overlap checks
  const nonFullOverlaps = overlappingTimeOffs.filter(
    (to2) => !helpers.itemFullyOverlaps(to, to2),
  )

  // Take care of if someone drags a time off into the middle of another time off.
  // In this case we have to create a new time off for the start of the overlap
  const firstOverlap = overlappingTimeOffs.length && overlappingTimeOffs[0]
  const overlapsInMiddle =
    to.start_date > firstOverlap.start_date &&
    to.end_date < firstOverlap.end_date

  if (overlapsInMiddle && to.minutes_per_day !== firstOverlap.minutes_per_day) {
    // Different minutes which means we have to split the time off around the one
    // tha has been dragged into the middle
    // Update firstOverlap's start_date to a.end_date + 1
    const updatedLastTimeOff = {
      ...overlappingTimeOffs.pop(),
      start_date: helpers.addCalendarDays(to.end_date, 1),
      person_id: person.id,
    }
    timeOffChanges.update.push(updatedLastTimeOff)

    // Create new timeoff that starts at firstOverlap.start_date and ends at a.start_date - 1
    const updatedFirstTimeOff = {
      ...firstOverlap,
      person_id: person.id,
      end_date: helpers.subtractCalendarDays(to.start_date, 1),
    }

    timeOffChanges.create.push(updatedFirstTimeOff)
    timeOffChanges.update.push(to)
    return timeOffChanges
  }

  if (overlapsInMiddle && to.minutes_per_day === firstOverlap.minutes_per_day) {
    // Overlaps in the middle but has the same minutes. Delete the one that has been
    //dragged into the middle to effectively create a merge
    timeOffChanges.delete.push(to)
    return timeOffChanges
  }

  // Time off end date overlaps with an timeoff's start date, but does not
  // fully overlap. There should be only one, if any
  const overlapsStart = nonFullOverlaps.filter(
    (to2) =>
      helpers.isBeforeOrSame(to2.start_date, to.end_date) &&
      helpers.isAfter(to2.end_date, to.end_date),
  )

  overlapsStart.forEach((to2) => {
    // If the minutes per day are the same, delete the old time and extend the
    // new one, effectively merging the time off
    if (to2.minutes_per_day === to.minutes_per_day) {
      timeOffChanges.delete.push(to2)
      timeOffChanges[isNew ? "create" : "update"].push({
        ...to,
        end_date: to2.end_date,
      })
      return
    }
    // Else shorten the old time off and leave the new one as is
    timeOffChanges[isNew ? "create" : "update"].push(to)
    timeOffChanges.update.push({
      ...to2,
      start_date: helpers.addCalendarDays(to.end_date, 1),
    })
  })
  // Time off start date overlaps with an timeoff's end date, but does not
  // fully overlap. There should be only one, if any
  const overlapsEnd = nonFullOverlaps.filter(
    (to2) =>
      helpers.isAfterOrSame(to2.end_date, to.start_date) &&
      helpers.isBefore(to2.start_date, to.start_date),
  )

  overlapsEnd.forEach((to2) => {
    // If the minutes per day are the same, delete the old time and extend the
    // new one, effectively merging the time off
    if (to2.minutes_per_day === to.minutes_per_day) {
      timeOffChanges.delete.push(to2)
      timeOffChanges[isNew ? "create" : "update"].push({
        ...to,
        start_date: to2.start_date,
      })
      return
    }
    // Else shorten the old time off and leave the new one as is
    timeOffChanges[isNew ? "create" : "update"].push(to)
    timeOffChanges.update.push({
      ...to2,
      end_date: helpers.subtractCalendarDays(to.start_date, 1),
    })
  })

  // If we don't overlap the start or end we can just create or update as normal
  if (!overlapsStart.length && !overlapsEnd.length) {
    timeOffChanges[isNew ? "create" : "update"].push(to)
  }

  // Deletions are time offs that are completely covered by the incoming time off
  const fullOverlaps = overlappingTimeOffs.filter((to2) =>
    helpers.itemFullyOverlaps(to, to2),
  )

  timeOffChanges.delete = [...timeOffChanges.delete, ...fullOverlaps]

  return timeOffChanges
}

export const updateAndCreateTimeOffs = async (
  timeOff: Omit<TimeOff, "ext_time_off_links">,
  person: Person,
  multiSelectItemIds: number[],
  deleteAssignments: boolean,
) => {
  const timeOffs = calculateTimeOffUpdates(timeOff, person, multiSelectItemIds)

  // Delete all time offs first, before creating new ones to avoid race condition errors
  // We deliberately await this (but fire all deletes at the same time) so we can ensure
  // all time offs have been deleted before creating new ones.
  await Promise.all(timeOffs.delete.map((to) => deleteTimeOff(to)))

  // Using void promises to process in parallel rather than sequentially
  timeOffs.create.forEach(createTimeOff)
  timeOffs.update.forEach(updateTimeOff)

  if (deleteAssignments) {
    timeOffs.create
      .filter((to) => !to.minutes_per_day) // Only update assignment if full day off
      .forEach(
        (to) =>
          void updateAssignmentsFromTimeOff(to, person, multiSelectItemIds),
      )
    timeOffs.update
      .filter((to) => !to.minutes_per_day) // Only update assignment if full day off
      .forEach(
        (to) =>
          void updateAssignmentsFromTimeOff(to, person, multiSelectItemIds),
      )
  }
}

type Person = {
  id: number
  is_placeholder: boolean
  assignments: readonly Assignment[]
  time_offs: ReadonlyArray<
    Required<Pick<TimeOff, "leave_type" | "minutes_per_day">> & TimeOffWithId
  >
}

type TimeOff = {
  person_id: number
  start_date: string
  end_date: string
  id?: number
  note?: string
  leave_type?: string
  minutes_per_day?: number

  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
  }>
}

type TimeOffWithId = TimeOff & {
  id: number
}

type Assignment = {
  id: number
  person_id: number
  role_id: number
  project_id: number
  workstream_id: number
  phase_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  non_working_day: boolean
}
