import { Radio, RadioGroup } from "@blueprintjs/core"
import cc from "classcat"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./Onboarding.module.css"

import { OnboardingFinancials_account$key } from "~/Onboarding/__generated__/OnboardingFinancials_account.graphql"

import { track } from "~/helpers/analytics"
import {
  convertDayRatesToHourRates,
  convertHourRatesToDayRates,
  toggleRatesByType,
} from "~/helpers/rate-helpers"
import { sortByString } from "~/helpers/sorting-helpers"

import CurrencyInput from "~/common/CurrencyInput"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import Button from "~/common/buttons/Button"

import { updateRateCardRelay } from "~/mutations/RateCard"
import {
  accountRateTypeUpdateRelay,
  projectRateTypeUpdateRelay,
} from "~/mutations/RateType"

import OnboardingStep from "./OnboardingStep"

type Props = {
  account: OnboardingFinancials_account$key
  closeDialog: () => void
}

const OnboardingFinancials = (props: Props) => {
  const { closeDialog } = props

  const account = useFragment(
    graphql`
      fragment OnboardingFinancials_account on accounts
      @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
        id
        currency
        rate_type
        default_full_time_minutes
        projects(where: $projectsFilter) {
          id
          rate_type
        }
        rate_cards {
          id
          name
          role_charge_out_rates {
            role_id
            rate_card_id
            role {
              id
              name
            }
            charge_out_rate: charge_out_rate_private
          }
        }
      }
    `,
    props.account,
  )
  const {
    id: accountId,
    currency,
    rate_type,
    default_full_time_minutes,
    projects,
    rate_cards,
  } = account

  const [isUpdating, setIsUpdating] = useState(false)
  const [accountRateType, setAccountRateType] = useState(rate_type)
  const [showNoRates, setShowNoRates] = useState(false)

  const standardRateCard = rate_cards.find((rc) => rc.name === "Standard")

  const formattedRates = standardRateCard.role_charge_out_rates
    .map((rate) => {
      const chargeOutRate =
        accountRateType === "days"
          ? convertHourRatesToDayRates(rate.charge_out_rate)
          : rate.charge_out_rate
      return {
        role_id: rate.role_id,
        rate_card_id: rate.rate_card_id,
        role_name: rate.role.name,
        charge_out_rate: chargeOutRate || null,
      }
    })
    .sort((a, b) => sortByString(a.role_name, b.role_name))

  const [rates, setRates] = useState(formattedRates)

  const updateChargeOutRate = (value, idx) => {
    const updatedRates = [...rates]
    updatedRates[idx].charge_out_rate = value || 0
    setRates(updatedRates)
  }

  const RateInputs = (rate, idx) => (
    <div
      key={`rate-${rate.role_id}-${rate.rate_card_id}`}
      className={styles.tableRow}
    >
      <div>{rate.role_name}</div>
      <CurrencyInput
        name={`role-default-rate-${idx}`}
        id="charge_out_rate"
        placeholder="Enter standard rate"
        data-idx={idx}
        currency={currency}
        value={rates[idx].charge_out_rate}
        onChange={(e) => updateChargeOutRate(e, idx)}
        autoFocus={idx === 0}
        error={rates[idx].charge_out_rate < 0}
      />
    </div>
  )

  const getChargeOutRate = (rate: number) => {
    switch (accountRateType) {
      case "days":
        return convertDayRatesToHourRates(rate, default_full_time_minutes)
      case "hours":
        return rate
      default:
        return rate
    }
  }

  const handleStart = async () => {
    setIsUpdating(true)

    track("Onboarding Financials Submited", {
      show_no_rates: showNoRates,
      account_rate_type: accountRateType,
    })

    // Update project rate_types
    projects.map(async (p) => {
      const projectRateTypeIsDifferent =
        accountRateType !== p.rate_type && accountRateType !== "none"

      if (projectRateTypeIsDifferent) {
        await projectRateTypeUpdateRelay({
          id: p.id,
          rateType: accountRateType,
        })
      }
    })

    const rateCardRates = rates.map((r) => ({
      role_id: r.role_id,
      charge_out_rate: getChargeOutRate(r.charge_out_rate),
    }))

    try {
      await updateRateCardRelay({
        input: {
          rate_card_id: standardRateCard.id,
          rate_type: accountRateType,
          roles: rateCardRates,
        },
        hideToaster: true,
      })

      await accountRateTypeUpdateRelay({
        id: accountId,
        rate_type: accountRateType,
      })

      track("Rate Card Created", {
        rate_type: accountRateType,
        is_onboarding: true,
      })

      setIsUpdating(false)
      closeDialog()
    } catch {
      setIsUpdating(false)
    }
  }

  const formattedRateType = accountRateType === "hours" ? "Hourly" : "Daily"

  const renderTooltip = () => {
    const message = (
      <div className={styles.tooltipMessage}>
        The {formattedRateType.toLowerCase()} rate is the rate that this role is
        charged to clients.
        <br />
        <br />
        It can be updated on each project or on rate cards.
      </div>
    )

    return (
      <TitledHelpTooltip
        title={`Standard ${formattedRateType} Rate`}
        tooltipContent={message}
      />
    )
  }

  const hasError = !!rates.filter((r) => r.charge_out_rate < 0).length

  const handleRateType = (e) => {
    const newRateType = e.target.value
    // handle "none" separately so that it doesn't affect the
    // input values if users switch between hourly/daily
    setShowNoRates(newRateType === "none")

    if (newRateType !== "none") {
      setAccountRateType(newRateType)
      const updatedRates = rates.map((r) => ({
        ...r,
        charge_out_rate:
          newRateType !== accountRateType // only toggle if rate type changed
            ? toggleRatesByType(r.charge_out_rate, newRateType)
            : r.charge_out_rate,
      }))
      setRates(updatedRates)
    }
  }

  return (
    <ModalFormWrapper wide>
      <div className={styles.onboardingFinancials}>
        <ModalBody wide>
          <div className={styles.heading}>Standard Charge Out Rate</div>
          <div className={styles.subtitle}>
            If you charge clients for your time, set up standard charge-out
            rates to track and forecast your revenue. You can still customize
            rates on a per-project basis.
            <br />
            <br />
            <b style={{ fontSize: "14px" }}>
              If you don't have external clients, select No rates required.
            </b>
            <br />
            <br />
          </div>
          <RadioGroup
            onChange={handleRateType}
            selectedValue={showNoRates ? "none" : accountRateType}
            className={cc([
              styles.radioGroup,
              "onboarding-financials-radio-group",
            ])}
          >
            <Radio label="Hourly rate" value="hours" />
            <Radio label="Daily rate" value="days" />
            <Radio label="No rates required" value="none" />
          </RadioGroup>
          {!showNoRates && (
            <div
              data-component="OnboardingFinancials/financialWrapper"
              className={styles.financialWrapper}
            >
              <div className={`${styles.tableRow} ${styles.tableHeaderRow}`}>
                <div>Role</div>
                {renderTooltip()}
              </div>
              {rates.map((rate, idx) => RateInputs(rate, idx))}
            </div>
          )}
          <br />
          <br />
        </ModalBody>
        <ModalFooter style={{ justifyContent: "flex-end" }}>
          <OnboardingStep step={3} totalSteps={3} />
          <Button
            text="Done"
            loading={isUpdating}
            outlined={false}
            onClick={handleStart}
            disabled={isUpdating || hasError}
            style={{ minWidth: 100 }}
          />
        </ModalFooter>
      </div>
    </ModalFormWrapper>
  )
}

export default OnboardingFinancials
