import { useFeature } from "flagged"
import React from "react"
import { useDispatch } from "react-redux"

import { track } from "~/helpers/analytics"
import {
  ProjectSortOption,
  projectSortOptions,
} from "~/helpers/sorting-helpers"

import Dropdown, { DropdownTargetProps } from "~/common/Dropdown/Dropdown"
import DropdownHeading from "~/common/Dropdown/DropdownHeading"
import MenuItem from "~/common/MenuItem"
import * as icons from "~/common/react-icons"

import {
  selectSortProjectBy,
  sortChanged,
} from "~/Planner/reducer2/projectSortSlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

import IconButton from "./IconButton"
import OverflowTooltipContent from "./OverflowTooltipContent"

const SortControlTarget = (props: DropdownTargetProps) => {
  const { disabled, active } = props

  const tooltipContent = disabled ? (
    <OverflowTooltipContent functionality={`project sorting`} />
  ) : (
    "Sort projects by"
  )

  return (
    <IconButton
      disabled={disabled ? "button" : false}
      active={active}
      icon={<icons.Sort />}
      tooltipContent={tooltipContent}
    />
  )
}

const ProjectSortControl = (props: { disabled?: boolean }) => {
  const dispatch = useDispatch()

  const priorityFeature = useFeature("priority")

  const selectedOption = useAppSelector((state) =>
    selectSortProjectBy(state.plannerV2.project),
  )

  const onSortChange = (option: {
    value: ProjectSortOption
    label: string
  }) => {
    dispatch(sortChanged(option.value))
    setSetting("projectPlannerSort", option)
    track("Planner Sort Changed", { value: option.label })
  }

  return (
    <Dropdown
      Target={SortControlTarget}
      placement="bottom-start"
      dataTest="projects-sort-by"
      disabled={props.disabled}
    >
      <DropdownHeading>Sort by</DropdownHeading>
      <div>
        {projectSortOptions
          .filter((option) => option.value !== "priority" || priorityFeature)
          .map((option) => (
            <MenuItem
              key={option.value}
              text={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "auto" }}>{option.label}</div>
                </div>
              }
              active={selectedOption === option.value}
              onClick={() => onSortChange(option)}
            />
          ))}
      </div>
    </Dropdown>
  )
}

export default ProjectSortControl
