import { dateHelpers } from "@runn/calculations"
import { differenceInBusinessDays as differenceInBusinessDaysDateFns } from "date-fns"

type Range = {
  start: string
  end: string | null | undefined
}

const hasOverlap = (a: Range, b: Range) =>
  (b.end ? a.start <= b.end : true) && (a.end ? a.end >= b.start : true)

const containsDate = (range: Range, date: string) =>
  range.start <= date && (range.end ? range.end >= date : true)

const differenceInBusinessDays = (dateLeft: string, dateRight: string) => {
  return differenceInBusinessDaysDateFns(
    dateHelpers.parseRunnDate(dateLeft),
    dateHelpers.parseRunnDate(dateRight),
  )
}

export { hasOverlap, containsDate, differenceInBusinessDays }
