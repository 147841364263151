import React from "react"

import styles from "./ProjectBudget.module.css"

import { formatCurrency } from "~/helpers/CurrencyHelper"
import { formatMinutesToDays } from "~/helpers/budgetHelper"
import { formatMinuteToHours } from "~/helpers/time"

import Column from "./TableColumn"
import Row from "./TableRow"

type Props = {
  title?: string
  totalMinutes: number
  totalDollars: number
  showDayView?: boolean
  defaultFullTimeMinutes: number
  showNonBillableView?: boolean
}
const BudgetTotalsRow = (props: Props) => {
  const {
    title = "Total",
    totalMinutes,
    totalDollars,
    showDayView = false,
    showNonBillableView = false,
    defaultFullTimeMinutes,
  } = props
  const totalTime = showDayView
    ? formatMinutesToDays(totalMinutes, defaultFullTimeMinutes)
    : formatMinuteToHours(totalMinutes, "")

  const columnStyle = { paddingRight: "10px" }
  const timeColumn = (
    <Column key="time" dataTest="time-total" style={columnStyle}>
      {totalTime}
    </Column>
  )

  const moneyColumn = (
    <Column key="money" dataTest="budget-total" style={columnStyle}>
      {formatCurrency(totalDollars)}
    </Column>
  )
  const columns = showNonBillableView
    ? [<div key="div" />, timeColumn]
    : [timeColumn, moneyColumn]

  return (
    <div className={styles.totalsWrapper} data-test="budget-allocation">
      <Row>
        <Column textAlign="left">
          <span>{title}</span>
        </Column>
        {columns}
      </Row>
    </div>
  )
}

export default BudgetTotalsRow
