import { isWeekend, parseISO } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import { TimeOffSummaryPersonRow_account$key } from "./__generated__/TimeOffSummaryPersonRow_account.graphql"
import { TimeOffSummary_account$data } from "./__generated__/TimeOffSummary_account.graphql"

import * as hashids from "~/helpers/hashids"
import {
  separateHolidaysFromTimeOffs,
  sortTimeOffsByHierarchy,
} from "~/helpers/holiday-helpers"
import { buildImageUrl } from "~/helpers/image"
import { formatName, formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"
import TimeOffActionPill from "~/common/Pill/TimeOffActionPill"
import TooltipEllipsis from "~/common/TooltipEllipsis"
import DragToCreateOutline from "~/common/calendar/DragToCreateOutline"

import { usePermissions } from "~/Permissions/usePermissions"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import { ReduxState } from "~/rootReducer"

type Props = {
  account: TimeOffSummaryPersonRow_account$key
  person: TimeOffSummary_account$data["people"][0]
  defaultFullTimeMinutes: number
}

const TimeOffSummaryPersonRow = (props: Props) => {
  const { person, defaultFullTimeMinutes } = props

  const account = useFragment(
    graphql`
      fragment TimeOffSummaryPersonRow_account on accounts {
        id
        ...TimeOffActionPill_account
        ...DragToCreateOutline_account
      }
    `,
    props.account,
  )

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))

  const { can, subject } = usePermissions()
  const canCreate = can("create", subject("TimeOff", { person }))

  const name = formatName(person.first_name, person.last_name)
  const initials = formatNameAsInitials(person.first_name, person.last_name)

  const { calendarWeekendsExpanded } = useSelector(
    (state: ReduxState) => state.calendar,
    shallowEqual,
  )

  const { mergedHolidays, others } = separateHolidaysFromTimeOffs(
    person.time_offs,
  )

  const sortedTimeOffs = isConsistentTimeOffEnabled
    ? sortTimeOffsByHierarchy(person.time_offs)
    : [...mergedHolidays, ...others]

  return (
    <PlannerGrid>
      <PlannerLeftColumn type="person" paddingHeight={7} personLegacy={person}>
        <Avatar
          email={person.email}
          initials={initials}
          avatar={buildImageUrl(person.image_key, {
            width: 30,
            height: 30,
            quality: "auto",
          })}
          is_placeholder={person.is_placeholder}
        />
        <TooltipEllipsis
          style={{ fontSize: 13, color: "var(--midnight)" }}
          className="fs-exclude"
          href={`/people/${hashids.people.encode(person.id)}`}
          text={name}
        />
      </PlannerLeftColumn>
      <PlannerRightColumn type="timeOff">
        <DragToCreateOutline
          account={account}
          person={person}
          type="timeOff"
          canCreate={canCreate}
          companyDefaultMinutes={defaultFullTimeMinutes}
        />
        {sortedTimeOffs.map((to) => {
          if (!calendarWeekendsExpanded && isWeekend(parseISO(to.start_date))) {
            return
          }
          return (
            <TimeOffActionPill
              key={to.id}
              account={account}
              timeOff={to}
              person={person}
              defaultFullTimeMinutes={defaultFullTimeMinutes}
            />
          )
        })}
      </PlannerRightColumn>
    </PlannerGrid>
  )
}

export default TimeOffSummaryPersonRow
