import { Icon, IconName, Tooltip } from "@blueprintjs/core"
import { format as formatDate } from "date-fns"
import React from "react"
import { match } from "ts-pattern"

import styles from "./PlaceholderIcon.module.css"

import { formatName, getCurrentOrLastContract } from "~/helpers/person"

import { PERSON_REQUEST_STATUSES } from "~/ENUMS"

type Contract = {
  start_date: string
  end_date: string
  role: {
    id: number
    name: string
  }
  minutes_per_day: number
  cost?: number
}

type WorkflowRequest = {
  id: number
  status: string
  updated_at: string
  user: {
    id: number
    first_name: string
    last_name: string
  }
  person: {
    id: number
    first_name: string
    contracts: readonly Contract[]
  }
}

export type Placeholder = {
  id: number
  person_requests: readonly WorkflowRequest[]
  people_notes: readonly {
    id: number
    note: string
    created_at: string
  }[]
}

type Props = {
  placeholder: Placeholder
  iconSize?: number
  style?: Record<string, any>
}

const getLatestComment = (comments) => (
  <span data-component="PlaceholderIcon/commentTooltip">
    {comments.reduce((a, b) => (a.created_at > b.created_at ? a : b)).note}
  </span>
)

const PlaceholderIcon = (props: Props) => {
  const { placeholder, iconSize = 13, style } = props
  const workflowRequest = placeholder?.person_requests?.[0]
  const comments = placeholder?.people_notes
  const hasComments = comments && Boolean(comments.length)

  if (!workflowRequest) {
    if (!hasComments) {
      return <></>
    }

    return (
      <Tooltip placement="right" content={getLatestComment(comments)}>
        <Icon
          icon="comment"
          size={iconSize}
          style={style}
          className={styles.icon}
          data-test="placeholder-comment-icon"
        />
      </Tooltip>
    )
  }

  const getTooltipContent = (status: string) => {
    const { user } = workflowRequest
    const userDetails = user
      ? formatName(user.first_name, user.last_name)
      : "Deleted user"
    const updatedDate = formatDate(
      new Date(workflowRequest.updated_at + "Z"),
      "E dd MMM",
    )

    const recentComment = hasComments && (
      <div className={styles.comment}>
        <span className={styles.bold}>Comment: </span>
        {getLatestComment(comments)}
      </div>
    )

    return match(status)
      .with(PERSON_REQUEST_STATUSES.NEED_TO_HIRE, () => (
        <>
          <div className={styles.bold} data-component="PlaceholderIcon/tooltip">
            Hire Proposed
            <span className={styles.normal}> by </span>
            {userDetails}
            <span className={styles.normal}> on </span>
            {updatedDate}
          </div>
          {recentComment}
        </>
      ))
      .with(PERSON_REQUEST_STATUSES.REQUESTED, () => {
        const placeholderContract = getCurrentOrLastContract(
          workflowRequest.person.contracts,
        )

        return (
          <>
            <div
              className={styles.bold}
              data-component="PlaceholderIcon/tooltip"
            >
              {placeholderContract.role.name} Requested
              <span className={styles.normal}> by </span>
              {userDetails}
              <span className={styles.normal}> on </span>
              {updatedDate}
            </div>
            {recentComment}
          </>
        )
      })
      .with(PERSON_REQUEST_STATUSES.PENDING, () => {
        return (
          <>
            <div className={styles.bold}>
              {userDetails}
              <span className={styles.normal}> changed the status to </span>
              Request Responded
              <span className={styles.normal}> on </span>
              {updatedDate}
            </div>
            {recentComment}
          </>
        )
      })
      .otherwise(() => "")
  }

  const getIcon = (status: string) =>
    match(status)
      .with(PERSON_REQUEST_STATUSES.NEED_TO_HIRE, () => "flag")
      .with(PERSON_REQUEST_STATUSES.REQUESTED, () => "redo")
      .with(PERSON_REQUEST_STATUSES.PENDING, () => "undo")
      .otherwise(() => "")

  return (
    <Tooltip
      placement="right"
      content={getTooltipContent(workflowRequest.status)}
    >
      <Icon
        icon={getIcon(workflowRequest.status) as IconName}
        size={iconSize}
        style={style}
        className={styles.icon}
        color="var(--warning)"
        data-test={`${workflowRequest.status}-workflow-icon`}
      />
    </Tooltip>
  )
}

export default PlaceholderIcon
