/**
 * @generated SignedSource<<aa9fdddda796d21aeb2387ef101bb208>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonActionButtons_person$data = {
  readonly active: boolean;
  readonly actuals_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly archivable: boolean | null | undefined;
  readonly assignments_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null | undefined;
  };
  readonly email: string | null | undefined;
  readonly first_name: string;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly " $fragmentType": "PersonActionButtons_person";
};
export type PersonActionButtons_person$key = {
  readonly " $data"?: PersonActionButtons_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonActionButtons_person">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonActionButtons_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "assignments_aggregate",
      "kind": "LinkedField",
      "name": "assignments_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "assignments_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "actuals_aggregate",
      "kind": "LinkedField",
      "name": "actuals_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "actuals_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "b457bfec2e0d39b1255961c899023505";

export default node;
