import { endOfISOWeek, isThisISOWeek, isToday, startOfISOWeek } from "date-fns"
import React from "react"

import styles from "./Timeline.module.css"

import { getTimeFrames } from "~/helpers/CalendarHelper"

import TimelineDay from "./TimelineDay"

export type Props = {
  week: Date
  currentZoom: number
  totalWeeks: number
  calendarWeekendsExpanded: boolean
  calendarView:
    | { type: "months"; amount: 1 | 3 | 6 | 12 }
    | { type: "weeks"; amount: 1 }
  useWeekNumbers: boolean
}

// The level of zoom before the day labels get hidden
const BREAK_POINT = 60 // visible days. 5d x 4w x 3m

export const PlainTimelineWeek = (props: Props) => {
  const {
    week,
    totalWeeks,
    currentZoom,
    calendarWeekendsExpanded,
    calendarView,
    useWeekNumbers,
  } = props
  const start = startOfISOWeek(week)
  const end = endOfISOWeek(week)

  const withinBreakZoom = currentZoom < BREAK_POINT

  const currentWeek = isThisISOWeek(week)

  const { dailyDates } = getTimeFrames({
    start,
    end,
    includeWeekends: calendarWeekendsExpanded,
  })

  return (
    <div
      className={`${currentWeek && styles.currentWeek} ${styles.timelineWeek}`}
      style={{ width: `${100 / totalWeeks}%` }}
      key={week.getTime()}
    >
      {dailyDates.map((day, index) => {
        const currentDay = isToday(day)
        const firstDay = index === 0
        const dayVisible =
          firstDay ||
          withinBreakZoom ||
          (currentDay &&
            currentZoom < 100 &&
            (!useWeekNumbers ||
              (calendarView.type === "months" && calendarView.amount === 1)))

        return (
          <TimelineDay
            key={day.getTime()}
            visible={dayVisible}
            date={day}
            isHalfYear={currentZoom > 100}
            calendarView={calendarView}
            useWeekNumbers={useWeekNumbers}
          />
        )
      })}
    </div>
  )
}

export default PlainTimelineWeek
