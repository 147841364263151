/**
 * @generated SignedSource<<c31358c7432bf012a906e3ef4a1b7fae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeoplePlannerOptions_account$data = {
  readonly people_custom_select_types: ReadonlyArray<{
    readonly id: number;
    readonly model: any;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly single_select: boolean;
  }>;
  readonly " $fragmentType": "PeoplePlannerOptions_account";
};
export type PeoplePlannerOptions_account$key = {
  readonly " $data"?: PeoplePlannerOptions_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeoplePlannerOptions_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeoplePlannerOptions_account",
  "selections": [
    {
      "alias": "people_custom_select_types",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "model": {
              "_eq": "PERSON"
            }
          }
        }
      ],
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "single_select",
          "storageKey": null
        },
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PERSON\"}})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "95a0c7c93e34e7b7175561d542c25e5a";

export default node;
