import React from "react"

import styles from "./FilterMenuButton.module.css"

import Button from "~/common/buttons/Button"
import FilterIcon from "~/common/react-icons/filter"

import type { SuperSearchConfig } from "./types"

type Props = {
  disabled?: boolean
  config: SuperSearchConfig
}

const FilterMenuButton = (props: Props) => {
  const { disabled, config } = props
  return (
    <Button
      disabled={disabled}
      className={styles.button}
      data-test="SuperSearch_FilterMenuButton_button"
    >
      <FilterIcon className={styles.icon} color="var(--runn-blue)" />
      <span className={styles.label}>{config.filterMenuButtonLabel}</span>
    </Button>
  )
}

export default FilterMenuButton
