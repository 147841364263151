/**
 * @generated SignedSource<<50171667cb891e7c2a99ed3fa9bd1870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TimeOffUpdateMutation$variables = {
  endDate: string;
  id: number;
  minutesPerDay?: number | null | undefined;
  note?: string | null | undefined;
  startDate: string;
};
export type TimeOffUpdateMutation$data = {
  readonly updateTimeOff: {
    readonly time_off: {
      readonly end_date_iso: string | null | undefined;
      readonly id: number;
      readonly leave_type: string;
      readonly minutes_per_day: number | null | undefined;
      readonly note: string | null | undefined;
      readonly person_id: number;
      readonly start_date_iso: string | null | undefined;
    } | null | undefined;
  };
};
export type TimeOffUpdateMutation = {
  response: TimeOffUpdateMutation$data;
  variables: TimeOffUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minutesPerDay"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "minutesPerDay",
        "variableName": "minutesPerDay"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "concreteType": "UpdateTimeOffResult",
    "kind": "LinkedField",
    "name": "updateTimeOff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "time_offs",
        "kind": "LinkedField",
        "name": "time_off",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "person_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "leave_type",
            "storageKey": null
          },
          {
            "alias": "start_date_iso",
            "args": null,
            "kind": "ScalarField",
            "name": "start_date_runn",
            "storageKey": null
          },
          {
            "alias": "end_date_iso",
            "args": null,
            "kind": "ScalarField",
            "name": "end_date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minutes_per_day",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeOffUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "814b130bfbf9e23dc31f02dba88c9173",
    "id": null,
    "metadata": {},
    "name": "TimeOffUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TimeOffUpdateMutation(\n  $id: Int!\n  $startDate: String!\n  $endDate: String!\n  $note: String\n  $minutesPerDay: Int\n) {\n  updateTimeOff(id: $id, startDate: $startDate, endDate: $endDate, note: $note, minutesPerDay: $minutesPerDay) {\n    time_off {\n      id\n      person_id\n      leave_type\n      start_date_iso: start_date_runn\n      end_date_iso: end_date_runn\n      note\n      minutes_per_day\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bb3e36a65981088db33e2990c3bc81b";

export default node;
