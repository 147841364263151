import { dateHelpers } from "@runn/calculations"
import { isSaturday } from "date-fns"
import { useFeature } from "flagged"
import React from "react"
import isDeeplyEqual from "react-fast-compare"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "~/common/PillActions/PillActions.module.css"

import { TimeOffActionPill_account$key } from "./__generated__/TimeOffActionPill_account.graphql"

import { track } from "~/helpers/analytics"
import { isLocalId } from "~/helpers/local-id"
import { getReasonPillIsDisabled } from "~/helpers/planner-helpers"

import ItemTooltip from "~/common/PillActions/ItemTooltip"
import PillActions from "~/common/PillActions/PillActions"
import PlannerDatePicker from "~/common/PlannerDatePicker/PlannerDatePicker"

import {
  timeOffCreateRelay,
  timeOffDeleteRelay,
  timeOffUpdateRelay,
} from "~/mutations/TimeOff"

import { setHighlightedItemData } from "~/GlobalState"
import { usePermissions } from "~/Permissions/usePermissions"
import { showToast } from "~/containers/ToasterContainer"
import { useAppSelector } from "~/hooks/redux"
import { ReduxState } from "~/rootReducer"

import { deleteTimeOffConflict } from "./AssignmentActionHelpers"
import BasicPill from "./BasicPill/BasicPill"
import { getPillDetails } from "./PillHelpers"
import RDOPill from "./RDOPill"
import { updateAndCreateTimeOffs } from "./TimeOffActionHelpers"

export type Person = {
  readonly id: number
  readonly email: string
  readonly first_name: string
  readonly last_name: string
  readonly is_placeholder: boolean
  readonly assignments: ReadonlyArray<Assignment>
  readonly time_offs: ReadonlyArray<TimeOff>
  readonly contracts: ReadonlyArray<{
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      id: number
      name: string
    }
  }>
}

type Assignment = {
  readonly id: number
  readonly person_id: number
  readonly role_id: number
  readonly project_id: number
  readonly phase_id: number
  readonly workstream_id: number
  readonly start_date: string
  readonly end_date: string
  readonly minutes_per_day: number
  readonly is_billable: boolean
  readonly is_template: boolean
  readonly note: string
  readonly non_working_day: boolean
}

export type TimeOff = {
  readonly id: number
  readonly person_id: number
  readonly start_date: string
  readonly end_date: string
  readonly note: string
  readonly leave_type: string
  readonly minutes_per_day: number
  readonly ext_time_off_links: ReadonlyArray<{
    readonly active: boolean
    readonly external: {
      readonly integration_id: number
    }
  }>
}

export type TimeOffWithHoliday = TimeOff & {
  readonly holiday:
    | {
        readonly id: number
        readonly name: string
        readonly holidays_group: {
          readonly id: number
          readonly name: string
        }
      }
    | null
    | undefined
}

const canNotEdit = () => {
  showToast({
    message:
      "Unable to update as the server is processing your previous request. Please try your action again.",
    type: "warning",
  })
}

const updateTimeOff = async (to: TimeOff) => {
  if (isLocalId(to.id)) {
    return canNotEdit()
  }

  await timeOffUpdateRelay({
    id: to.id,
    startDate: to.start_date,
    endDate: to.end_date,
    note: to.note,
    minutesPerDay: to.minutes_per_day,
  })
}

const createTimeOff = async (to: TimeOff) => {
  await timeOffCreateRelay({
    startDate: to.start_date,
    endDate: to.end_date,
    personId: to.person_id,
    minutesPerDay: to.minutes_per_day,
    note: to.note,
  })
}

const deleteTimeOff = (to: TimeOff) => {
  if (isLocalId(to.id)) {
    return canNotEdit()
  }

  void timeOffDeleteRelay({ id: to.id })
}

type TimeOffActionPillProps = {
  account: TimeOffActionPill_account$key
  timeOff: TimeOff
  person: Person
  defaultFullTimeMinutes: number
}

const TimeoffActionPill = (props: TimeOffActionPillProps) => {
  const { timeOff, person, defaultFullTimeMinutes } = props

  const account = useFragment(
    graphql`
      fragment TimeOffActionPill_account on accounts {
        id
        ...PlannerDatePicker_account
        projects(where: $projectsFilter) {
          id
          confirmed
        }
      }
    `,
    props.account,
  )

  const { can, subject } = usePermissions()
  const timeOffSubject = subject("TimeOff", { person })
  const timeOffPermissions = {
    edit: can("edit", timeOffSubject),
  }

  const isConsistentTimeOffEnabled = Boolean(useFeature("consistent_time_off"))
  const dispatch = useDispatch()

  const multiSelect = useAppSelector(
    (state) => state.multiSelect,
    isDeeplyEqual,
  )
  const multiSelectItemIds = multiSelect.items.map((i) => i.id)
  const isTransferPlaceholderMode = useAppSelector(
    (state) => state.plannerV2.splitScreen.transferEntirePlaceholder,
  )

  const calendar = useSelector(
    (state: ReduxState) => state.calendar,
    shallowEqual,
  )

  const {
    dayWidth,
    calendarStartDate,
    calendarEndDate,
    calendarWeekendsExpanded,
  } = calendar

  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const hoursPerWeekMode = effortDisplayUnit === "hoursPerWeek"

  // Exclude items outside the calendar date
  if (
    Number(timeOff.end_date) < calendar.calStartNum ||
    Number(timeOff.start_date) > calendar.calEndNum
  ) {
    return null
  }

  const isExternalTimeOff = timeOff?.ext_time_off_links?.length > 0 || false

  const isPillEditableByUser =
    // user cannot modify timeoffs for Public Holidays
    timeOff.leave_type !== "holiday" &&
    // user cannot modify timeoffs that are controlled via integrations
    !isExternalTimeOff &&
    timeOffPermissions.edit

  if (!isPillEditableByUser) {
    const { days, offset, width, pillWidthPx } = getPillDetails({
      typedItem: { type: "timeOff", item: timeOff },
      calendarStartDate,
      calendarEndDate,
      dayWidth,
      calendarWeekendsExpanded,
      hoursPerWeekMode,
      defaultFullTimeMinutes,
    })

    const pillDisabledMessage = getReasonPillIsDisabled({
      multiSelectItems: multiSelect.items,
      pill: timeOff,
      modeAction: multiSelect.modeAction,
      isTransferPlaceholderMode,
      pillPermissions: timeOffPermissions,
    })

    return (
      <div
        className={styles.PillContainer}
        style={{ left: `${offset}%`, width: `${width}%` }}
      >
        <ItemTooltip
          typedItem={{ type: "timeOff", item: timeOff }}
          days={days}
          forceDelayTime={500}
          pillWidthPx={pillWidthPx}
          person={person}
          pillDisabledMessage={pillDisabledMessage}
        >
          <BasicPill
            typedItem={{ type: "timeOff", item: timeOff }}
            person={person}
            wrappedPill
            calendarStartDate={calendarStartDate}
            calendarEndDate={calendarEndDate}
            dayWidth={dayWidth}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
            defaultFullTimeMinutes={defaultFullTimeMinutes}
          />
        </ItemTooltip>
      </div>
    )
  }

  const onDelete = () => {
    track("Timeoff Deleted")
    void deleteTimeOff(timeOff)
    dispatch(setHighlightedItemData(null))
  }

  if (timeOff.leave_type === "rostered-off") {
    return (
      <RDOPill
        timeOff={timeOff}
        person={person}
        onDelete={onDelete}
        defaultFullTimeMinutes={defaultFullTimeMinutes}
      />
    )
  }

  const changeTimeOff = (to) => {
    track("Timeoff Updated", {
      timeoff_type: !to.minutes_per_day ? "full-time" : "part-time",
    })
    void updateAndCreateTimeOffs(
      to,
      person,
      multiSelectItemIds,
      !isConsistentTimeOffEnabled,
    )
  }

  const splitTimeOff = (to, date) => {
    track("Timeoff Split", {
      timeoff_type: !to.minutes_per_day ? "full-time" : "part-time",
    })

    let newDate = date

    if (
      calendarWeekendsExpanded &&
      isSaturday(dateHelpers.parseRunnDate(date))
    ) {
      newDate = dateHelpers.addBusDays(date, 1) // monday
    }

    void updateTimeOff({
      ...to,
      end_date: dateHelpers.subBusDays(date, 1), // 1 day assignment = same as start date
    })
    void createTimeOff({
      ...to,
      start_date: newDate,
    })
  }

  const updateTimeOffFromForm = (updatedTimeOff) => {
    const to = {
      id: timeOff.id,
      start_date: dateHelpers.formatToRunnDate(updatedTimeOff.startDate),
      end_date: dateHelpers.formatToRunnDate(updatedTimeOff.endDate),
      person_id: updatedTimeOff.personId,
      note: updatedTimeOff.note,
      minutes_per_day: updatedTimeOff.minutesPerDay,
    }
    track("Timeoff Form Updated", {
      timeoff_type: !updatedTimeOff.minutesPerDay ? "full-time" : "part-time",
    })
    void updateAndCreateTimeOffs(
      to,
      person,
      multiSelectItemIds,
      !isConsistentTimeOffEnabled,
    )
  }

  const timeOffEditForm = () => (
    <PlannerDatePicker
      account={account}
      allowScroll
      timeOff={timeOff}
      allowDelete
      isTimeOff
      itemId={timeOff.id}
      startDate={timeOff.start_date}
      endDate={timeOff.end_date}
      person={person}
      personId={person && person.id}
      minutesPerDay={timeOff.minutes_per_day}
      onDelete={onDelete}
      onSubmit={updateTimeOffFromForm}
    />
  )

  return (
    <PillActions
      type="timeOff"
      item={timeOff}
      person={person}
      projects={account.projects}
      onItemChange={changeTimeOff}
      onItemSplit={splitTimeOff}
      itemEditForm={timeOffEditForm()}
      onDelete={onDelete}
      onDeleteConflict={deleteTimeOffConflict}
      calendarWeekendsExpanded={calendarWeekendsExpanded}
      render={(pillProps) => (
        <BasicPill
          {...pillProps}
          typedItem={{ type: "timeOff", item: pillProps.item }}
          person={person}
          wrappedPill
          isConflict={pillProps.isOverlappingTimeOff}
          calendarStartDate={pillProps.calendarStartDate}
          calendarEndDate={pillProps.calendarEndDate}
          isSelected={pillProps.isSelected}
          isFloating={pillProps.isFloating}
          calendarWeekendsExpanded={calendarWeekendsExpanded}
        />
      )}
    />
  )
}

export default TimeoffActionPill
