import { graphql, useFragment } from "react-relay"

import type { useProjects_account$key } from "./__generated__/useProjects_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

// @growing-pains-todo: Applying the filter here is self-referential insanity.
// Should be all_projects, but need a new approach for UX.
const fragment = graphql`
  fragment useProjects_account on accounts
  @argumentDefinitions(projectsFilter: { type: "projects_bool_exp" }) {
    projects(where: $projectsFilter) {
      id
      name
      active
      confirmed
      is_template
    }
  }
`

type Options = {
  user: SuperSearch_user$data
}

const useProjects = (options: Options) => {
  const { user } = options

  const { projects } = useFragment<useProjects_account$key>(
    fragment,
    user.account,
  )

  const availableOptions = projects
    .filter((project) => {
      // Project Templates are always active
      return project.active && !project.is_template
    })
    .map((project) => ({
      value: project.id,
      label: project.name,
      confirmed: project.confirmed,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useProjects
