/**
 * @generated SignedSource<<80ab0d68c593c439496ac49891ad8059>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExtPersonLinksQuery$variables = Record<PropertyKey, never>;
export type ExtPersonLinksQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly activeHRISIntegrations: ReadonlyArray<{
        readonly ext_people: ReadonlyArray<{
          readonly first_name: string;
          readonly id: number;
          readonly last_name: string;
          readonly remote_id: string | null | undefined;
        }>;
        readonly id: number;
        readonly integration_service: {
          readonly id: number;
          readonly name: string;
          readonly square_image_url: string | null | undefined;
        };
      }>;
      readonly id: number;
    };
    readonly id: number;
    readonly permissions: any;
  } | null | undefined;
};
export type ExtPersonLinksQuery = {
  response: ExtPersonLinksQuery$data;
  variables: ExtPersonLinksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "activeHRISIntegrations",
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "category": {
                    "_eq": "HRIS"
                  },
                  "state": {
                    "_neq": "PENDING"
                  }
                }
              }
            ],
            "concreteType": "integration",
            "kind": "LinkedField",
            "name": "integrationsV2",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "integration_service",
                "kind": "LinkedField",
                "name": "integration_service",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "square_image_url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ext_person",
                "kind": "LinkedField",
                "name": "ext_people",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remote_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "integrationsV2(where:{\"category\":{\"_eq\":\"HRIS\"},\"state\":{\"_neq\":\"PENDING\"}})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtPersonLinksQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExtPersonLinksQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "261da28d0796a7d059787559506bb446",
    "id": null,
    "metadata": {},
    "name": "ExtPersonLinksQuery",
    "operationKind": "query",
    "text": "query ExtPersonLinksQuery {\n  current_user {\n    id\n    account {\n      id\n      activeHRISIntegrations: integrationsV2(where: {category: {_eq: \"HRIS\"}, state: {_neq: \"PENDING\"}}) {\n        id\n        integration_service {\n          id\n          name\n          square_image_url\n        }\n        ext_people {\n          id\n          first_name\n          last_name\n          remote_id\n        }\n      }\n    }\n    permissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7f7784578545df47a56309119f3d7e8";

export default node;
