import cc from "classcat"
import React from "react"

import styles from "./MidnightLinkButton.module.css"

import Button, { ButtonProps } from "./Button"

export default (props: ButtonProps) => {
  const { className, ...rest } = props
  return <Button className={cc([className, styles.midnightLink])} {...rest} />
}
