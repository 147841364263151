import { match } from "ts-pattern"

type Status = "SUCCESS" | "ERROR" | "PENDING"

/**
 * In order to use <Suspense /> to handle a promise in a nested component,
 * a Promise is "thrown" to the <Suspense /> which acts like an error boundary.
 * This approach is pretty well documented, albeit highly unintuitive.
 *
 * https://17.reactjs.org/docs/concurrent-mode-suspense.html
 */
class Resource<T> {
  private status: Status = "PENDING"
  private response
  private promise: Promise<T>

  constructor(promise: Promise<T>) {
    this.promise = promise
    this.promise.then(
      (res) => {
        this.status = "SUCCESS"
        this.response = res
      },
      (err) => {
        this.status = "ERROR"
        this.response = err
      },
    )
  }

  public read(): T {
    return match(this.status)
      .with("PENDING", () => {
        // When still loading, "throw" to the parent <Suspense /> component
        // to block rendering and force fallback.
        throw this.promise
      })
      .with("ERROR", () => {
        // Throw an error to the <Suspense /> error boundary.
        throw this.response
      })
      .otherwise(() => {
        // Proceed with normal rendering once the promise is resolved.
        return this.response as T
      })
  }
}

export default Resource
