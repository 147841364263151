import { dateHelpers } from "@runn/calculations"
import {
  addDays,
  differenceInDays,
  format as formatDate,
  isToday,
  isWeekend,
} from "date-fns"
import React from "react"
import { connect } from "react-redux"

import styles from "./Timeline.module.css"

import { getPillDetails } from "~/common/Pill/PillHelpers"

import { useAppSelector } from "../../hooks/redux"

const DateHighlighter = (props) => {
  const { PillDetails, item } = props

  const width = PillDetails.width
  const offset = PillDetails.offset
  const days = PillDetails.visibleDays
  const itemStartDate = dateHelpers.parseRunnDate(item.start_date)
  const itemEndDate = dateHelpers.parseRunnDate(item.end_date)
  const startDay = formatDate(itemStartDate, "d")
  const endDay =
    item.start_date === item.end_date ? "" : formatDate(itemEndDate, "d")
  const dayWidth = width / days

  const startClasses = `${styles.timelineDate} ${
    isToday(itemStartDate) ? styles.today : ""
  }`
  const endClasses = `${styles.timelineDate} ${
    isToday(itemEndDate) ? styles.today : ""
  }`

  return (
    <>
      <div
        className={startClasses}
        style={{ minWidth: `${dayWidth}%`, left: `${offset}%` }}
      >
        {startDay}
      </div>
      {endDay && (
        <div
          className={endClasses}
          style={{
            minWidth: `${dayWidth}%`,
            left: `${offset + width - dayWidth}%`,
          }}
        >
          {endDay}
        </div>
      )}
    </>
  )
}

const TimelineHighlight = (props) => {
  const {
    highlightedItemData,
    highlightedCellData,
    calendarStartDate,
    calendarEndDate,
    calendarView,
    calendarWeekendsExpanded,
    defaultFullTimeMinutes,
    useWeekNumbers,
  } = props
  const effortDisplayUnit = useAppSelector((state) => state.displayUnit.effort)
  const hoursPerWeekMode = effortDisplayUnit === "hoursPerWeek"

  if (!highlightedCellData && !highlightedItemData) {
    return null
  }

  const calendarDaysDifference = differenceInDays(
    calendarEndDate,
    calendarStartDate,
  )
  let item
  let type

  if (highlightedCellData) {
    if (!calendarWeekendsExpanded && isWeekend(highlightedCellData.day)) {
      // If weekends are not expanded a person may have hovered on
      // a skinny weekend pill, which should highlight in TimelineWeekendExpanders.tsx
      return null
    }

    const sDate = dateHelpers.formatToRunnDate(highlightedCellData.day)
    const weeklyAssignments = calendarDaysDifference > 160
    const eDate =
      weeklyAssignments && highlightedCellData.type !== "milestone"
        ? dateHelpers.formatToRunnDate(addDays(highlightedCellData.day, 4))
        : sDate
    type = highlightedCellData.type
    item = { start_date: sDate, end_date: eDate, id: undefined }
  }

  if (highlightedItemData) {
    type = highlightedItemData.type
    if (type === "assignment" && !highlightedItemData.projectConfirmed) {
      type = "unconfirmed"
    }
    if (
      (type === "assignment" || type === "unconfirmed") &&
      highlightedItemData.is_placeholder
    ) {
      type = "placeholder"
    }
    item = highlightedItemData
  }

  const PillDetails = getPillDetails({
    typedItem: { type, item },
    calendarStartDate,
    calendarEndDate,
    dayWidth: 1,
    calendarWeekendsExpanded,
    hoursPerWeekMode,
    defaultFullTimeMinutes,
  })
  const width = PillDetails.width
  const offset = PillDetails.offset

  return (
    <>
      {!useWeekNumbers &&
        calendarView.type === "months" &&
        calendarView.amount === 3 && (
          <DateHighlighter PillDetails={PillDetails} item={item} />
        )}
      <div
        className={styles.timelineHighlight}
        style={{
          backgroundColor:
            type === "placeholder"
              ? "#abd3ff"
              : type === "time_off" || type === "timeOff"
                ? "rgb(117, 121, 145)"
                : type === "unconfirmed"
                  ? "var(--tentative)"
                  : type === "phase"
                    ? highlightedItemData.color
                    : "",
          width: `${width}%`,
          left: `${offset}%`,
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  highlightedItemData: state.global.highlightedItemData,
  highlightedCellData: state.global.highlightedCellData,
  calendarView: state.calendar.calendarView,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
})

const connector = connect(mapStateToProps, null)
export default connector(TimelineHighlight)
