import cc from "classcat"
import { Feature } from "flagged"
import React from "react"

import styles from "./ClientDetails.module.css"

import PlaceholderIcon, { Placeholder } from "~/common/PlaceholderIcon"
import ProjectClientIcon from "~/common/ProjectClientIcon"

import { PriorityTag } from "./PriorityTag"
import { TentativeProjectEnabledSwitchContainer } from "./TentativeProjectEnabledSwitch"
import TooltipEllipsis from "./TooltipEllipsis"

type Props = {
  id?: number
  projectEmoji: string | null
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  tooltipSecondaryText?: string | JSX.Element | null
  isMember?: boolean
  size?: number
  large?: boolean
  isInactive?: boolean
  hideLogo?: boolean
  tentative?: boolean
  onClick?: (e: MouseEvent) => void
  href?: string
  icons?: React.ReactNode
  subtitleIcons?: React.ReactNode
  reverse?: boolean
  imageKey?: string
  website?: string
  tentativePlacement?: "bottom" | "right"
  isTemplateRow?: boolean
  extended?: boolean
  priority?: string | null
  placeholder?: Placeholder
  isArchived?: boolean
}

const getWidth = (extended, tentative, tentativePlacement, showRequestIcon) => {
  let width = 200
  if (extended) {
    width = width + 20
  }
  if (tentative && tentativePlacement === "right") {
    width = width - 40
  }

  if (showRequestIcon) {
    width = width - 20
  }
  return `${width}px`
}

const ClientDetails = (props: Props) => {
  const {
    id,
    title,
    subtitle,
    tooltipSecondaryText,
    isMember,
    size,
    large,
    isInactive,
    onClick,
    hideLogo,
    tentative,
    href,
    icons,
    subtitleIcons,
    reverse,
    imageKey,
    website,
    tentativePlacement,
    isTemplateRow = false,
    extended = false,
    priority,
    placeholder,
    projectEmoji,
    isArchived = false,
  } = props

  const showRequestIcon = Boolean(
    placeholder?.person_requests?.length || placeholder?.people_notes?.length,
  )

  const width = getWidth(
    extended,
    tentative,
    tentativePlacement,
    showRequestIcon,
  )

  const TentativeInfoBottom = () => {
    const isTentativeBottom = tentativePlacement === "bottom" && tentative && id

    if (!isTentativeBottom) {
      return null
    }

    return <TentativeProjectEnabledSwitchContainer id={id} />
  }

  const renderInfo = () => (
    <>
      <div
        className={cc([
          styles.clientInfo,
          {
            [styles.reverse]: reverse,
            [styles.inactive]: isInactive,
          },
        ])}
      >
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <TooltipEllipsis
            text={title}
            secondaryText={tooltipSecondaryText}
            className={styles.title}
            style={{ fontSize: large && "16px" }}
            href={!isInactive ? href : undefined}
            onClick={onClick}
            customMaxWidth={width}
          />

          {!!icons && icons}
        </div>
        {subtitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {showRequestIcon && (
              <PlaceholderIcon
                iconSize={12}
                placeholder={placeholder}
                style={{ marginRight: 5 }}
              />
            )}
            <TooltipEllipsis
              text={subtitle}
              className={styles.subtitle}
              style={{
                fontSize: large && "14px",
                color: isTemplateRow && "var(--template-grey)",
              }}
              customMaxWidth={width}
            />
            {!!subtitleIcons && subtitleIcons}
          </div>
        )}
        {isMember && (
          <Feature name="workstreams">
            <span className={styles.isMember}>On project</span>
          </Feature>
        )}
        <TentativeInfoBottom />
        <div>
          <PriorityTag projectId={id} priority={priority} />
        </div>
      </div>
      {placeholder && tentative && tentativePlacement === "right" && <div />}
      {tentativePlacement === "right" && tentative && id && (
        <div style={{ marginRight: "-8px" }}>
          <TentativeProjectEnabledSwitchContainer id={id} minimal />
        </div>
      )}
    </>
  )

  const renderLogo = () => (
    <div
      className={cc([
        styles.icon,
        {
          [styles.inactive]: isInactive,
        },
      ])}
    >
      <ProjectClientIcon
        project={{ emoji: projectEmoji }}
        client={{ image_key: imageKey, website }}
        size={large ? 40 : size}
        isArchived={isArchived}
      />
    </div>
  )

  if (reverse) {
    return (
      <>
        {renderInfo()}
        {!hideLogo && renderLogo()}
      </>
    )
  }

  return (
    <>
      {!hideLogo && renderLogo()}
      {renderInfo()}
    </>
  )
}

export default ClientDetails
