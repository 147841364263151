import { Icon, IconName, Tab } from "@blueprintjs/core"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { match } from "ts-pattern"

import styles from "./PlaceholderSidePanel.module.css"

import { PlaceholderSidePanel_account$key } from "./__generated__/PlaceholderSidePanel_account.graphql"
import { PlaceholderSidePanel_placeholder$key } from "./__generated__/PlaceholderSidePanel_placeholder.graphql"
import { PersonRequestStatus } from "~/mutations/__generated__/PersonRequestUpdateMutation.graphql"

import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { formatName, getCurrentOrLastContract } from "~/helpers/person"
import {
  getRequestCommentBtnText,
  getRequestCommentPlaceholder,
  getResourceMenuItems,
} from "~/helpers/placeholder-helpers"

import Dropdown from "~/common/Dropdown/Dropdown"
import MenuItem from "~/common/MenuItem"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import SidePanelHeader from "~/common/SidePanel/SidePanelHeader"
import Tabs from "~/common/Tabs"
import TextArea from "~/common/TextArea"
import Button from "~/common/buttons/Button"

import { personNoteCreateRelay } from "~/mutations/PersonNote"
import {
  personRequestCreateRelay,
  personRequestDeleteMutationRelay,
  personRequestUpdateRelay,
} from "~/mutations/PersonRequest"

import { PERSON_REQUEST_STATUSES as STATUSES } from "~/ENUMS"
import { usePermissions } from "~/Permissions/usePermissions"
import { showToast } from "~/containers/ToasterContainer"
import PlaceholderForm from "~/forms/PlaceholderForm/PlaceholderForm"

import PlaceholderActionButtons from "./PlaceholderActionButtons"
import PlaceholderComments from "./PlaceholderComments"
import PlaceholderDetails from "./PlaceholderDetails"

type Props = {
  placeholder: PlaceholderSidePanel_placeholder$key
  closePanel: () => void
  project: {
    id: number
    name: string
    is_template: boolean
  }
  requestStatus?: STATUSES
  initialTab?: "details" | "comments"
  account: PlaceholderSidePanel_account$key
}

const PlaceholderSidePanel = ({
  requestStatus,
  initialTab,
  project,
  ...props
}: Props) => {
  const placeholder = useFragment(
    graphql`
      fragment PlaceholderSidePanel_placeholder on people {
        id
        is_placeholder
        active
        archivable
        first_name
        last_name
        tags
        project_memberships {
          id
          project {
            id
            is_template
          }
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        people_notes {
          id
        }
        person_requests {
          id
          status
          updated_at
          user {
            id
            first_name
            last_name
          }
        }
        team {
          id
          name
        }
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          cost: cost_private
          role {
            id
            name
          }
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          leave_type
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          project_id
          person_id
          phase_id
          role_id
          workstream_id
          start_date: start_date_runn
          end_date: end_date_runn
          is_billable
          is_template
          minutes_per_day
          note
          total_minutes
          non_working_day
        }
        placeholder_suggestions {
          suggested_person_id
          suggested_person {
            id
            ...SuggestedPerson_person
          }
        }
        ...PersonCustomEditor_person
        ...PlaceholderComments_placeholder
        ...PlaceholderActionButtons_placeholder
      }
    `,
    props.placeholder,
  )

  const account = useFragment(
    graphql`
      fragment PlaceholderSidePanel_account on accounts {
        id
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          description
          show_in_planner
          filterable_in_planner
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
      }
    `,
    props.account,
  )

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
    custom_date_types: account.custom_date_types_person,
  })
  const customFieldValues = valuesMap(placeholder)

  const { first_name, last_name } = placeholder
  const name = formatName(first_name, last_name)

  const { openPanel, closePanel, goBack } = useSidePanel()
  const { can, subject } = usePermissions()

  const placeholderContract = getCurrentOrLastContract(placeholder.contracts)
  const role = placeholderContract?.role?.name ?? "No Contract"
  const subtitle = `${role} ${project?.name ? `| ${project.name}` : ""}`

  const canEditPersonRequest = can(
    "edit",
    subject("PersonRequest", {
      project: { id: project.id, isTemplate: project.is_template },
    }),
  )
  const canEditPlaceholder = can(
    "edit",
    subject("Placeholder", {
      project: { id: project.id, isTemplate: project.is_template },
    }),
  )

  const [activeTab, setActiveTab] = useState(initialTab ?? "details")
  const [selectedRequestStatus, setSelectedRequestStatus] =
    useState<STATUSES | null>(requestStatus)
  const [comment, setComment] = useState("")

  const commentCount = placeholder.people_notes.length
  const noAssignments = placeholder.assignments.length === 0

  const openPlaceholderForm = (openedInRequestForm: boolean) => {
    openPanel(
      <PlaceholderForm
        placeholder={placeholder}
        project={project}
        cost={placeholderContract.cost}
        onClose={goBack}
        roleId={placeholderContract.role.id}
      />,
    )
  }

  const roleName = placeholderContract.role.name
  const allMenuItems = getResourceMenuItems(roleName)
  const footerRequestMenuItems = [
    allMenuItems.REQUESTED,
    allMenuItems.NEED_TO_HIRE,
  ]
  const placeholderRequestStatus = placeholder.person_requests[0]?.status
  const placeholderName = formatName(
    placeholder.first_name,
    placeholder.last_name,
  )

  const successMessage = () => {
    const message = match({ selectedRequestStatus })
      .with(
        { selectedRequestStatus: STATUSES.NEED_TO_HIRE },
        () => `Hire Proposed for ${placeholderName}`,
      )
      .with(
        { selectedRequestStatus: STATUSES.REQUESTED },
        () => `${roleName} requested for ${placeholderName}`,
      )
      .with(
        { selectedRequestStatus: STATUSES.PENDING },
        () => `${placeholderName} status changed to Request Responded`,
      )
      .otherwise(() => `Request for ${placeholderName} has been cancelled`)

    return showToast({ message, type: "success" })
  }

  const createRequest = async () => {
    await personRequestCreateRelay({
      personId: placeholder.id,
      projectId: Number(project.id),
      status: selectedRequestStatus as PersonRequestStatus,
    })
    successMessage()

    track("Resourcing Request Updated", {
      old_status: "NONE",
      new_status: selectedRequestStatus,
    })
  }

  const updateRequest = async () => {
    await personRequestUpdateRelay({
      id: placeholder.person_requests[0].id,
      status: selectedRequestStatus as PersonRequestStatus,
    })
    successMessage()

    track("Resourcing Request Updated", {
      old_status: placeholderRequestStatus,
      new_status: selectedRequestStatus,
    })
  }

  const cancelRequest = async () => {
    await personRequestDeleteMutationRelay({
      id: placeholder.person_requests[0].id,
    })
    successMessage()

    track("Resourcing Request Updated", {
      old_status: placeholderRequestStatus,
      new_status: "NONE",
    })
  }

  const handleRequest = async (e: React.FormEvent) => {
    e.preventDefault()

    const trimmedComment = comment.trim()

    if (trimmedComment) {
      await personNoteCreateRelay({
        note: trimmedComment,
        personId: placeholder.id,
      })
      track("Placeholder Comment Created")
      setComment("")
    }

    if (selectedRequestStatus === STATUSES.CANCEL) {
      await cancelRequest()
    } else if (!placeholderRequestStatus) {
      await createRequest()
    } else {
      await updateRequest()
    }

    setSelectedRequestStatus(null)
  }

  const handleShortcut = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      e.target.form.requestSubmit()
    }
  }

  return (
    <>
      <PlaceholderActionButtons
        placeholder={placeholder}
        onEditPlaceholder={openPlaceholderForm}
        closePanel={closePanel}
        project={project}
      />
      <section className={styles.container} data-test="placeholder-side-panel">
        <SidePanelHeader title={name} subtitle={subtitle} type="placeholder" />
        {selectedRequestStatus ? (
          <>
            <h4
              className={styles.title}
            >{`${allMenuItems[selectedRequestStatus].text}`}</h4>
            <form
              onSubmit={handleRequest}
              className={styles.requestForm}
              data-test="add-comment-form"
            >
              <ModalBody wide className={styles.requestBody}>
                <div className={styles.requestComment}>
                  <h4 className={styles.detailsTitle}>
                    Comments{" "}
                    {selectedRequestStatus !== STATUSES.PENDING
                      ? "(optional)"
                      : ""}
                  </h4>
                  <TextArea
                    name="comment"
                    id="add-comment"
                    data-test="workflow-comment-input"
                    value={comment}
                    optional={
                      Boolean(selectedRequestStatus) &&
                      selectedRequestStatus !== STATUSES.PENDING
                    }
                    placeholder={getRequestCommentPlaceholder(
                      selectedRequestStatus,
                    )}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={handleShortcut}
                    autoFocus
                    rows={6}
                    style={{ resize: "vertical" }}
                  />
                </div>
                <PlaceholderDetails
                  placeholder={placeholder}
                  placeholderProjectId={project.id}
                  customFieldTypes={customFieldTypes}
                  customFieldValues={customFieldValues}
                  openPlaceholderForm={() => openPlaceholderForm(true)}
                  setSelectedRequestStatus={setSelectedRequestStatus}
                  noAssignments={noAssignments}
                  inRequestPanel
                />
              </ModalBody>
              <ModalFooter style={{ justifyContent: "space-between" }}>
                <Button onClick={() => setSelectedRequestStatus(null)}>
                  Cancel
                </Button>
                <Button
                  outlined={false}
                  type="submit"
                  disabled={
                    selectedRequestStatus === STATUSES.PENDING && !comment
                  }
                >
                  {getRequestCommentBtnText({
                    requestType: selectedRequestStatus,
                    hasExistingRequest: !!placeholderRequestStatus,
                  })}
                </Button>
              </ModalFooter>
            </form>
          </>
        ) : (
          <>
            <ModalBody wide className={styles.tabContainer}>
              <Tabs
                large
                id="placeholderTabs"
                onChange={(tab: "details" | "comments") => {
                  track("Placeholder Side Panel Tab Clicked", { tab })
                  setActiveTab(tab)
                }}
                selectedTabId={activeTab}
                className={styles.placeholderTabs}
              >
                <Tab
                  id="details"
                  title="Details"
                  panel={
                    <PlaceholderDetails
                      placeholder={placeholder}
                      placeholderProjectId={project.id}
                      customFieldTypes={customFieldTypes}
                      customFieldValues={customFieldValues}
                      openPlaceholderForm={openPlaceholderForm}
                      setSelectedRequestStatus={setSelectedRequestStatus}
                      noAssignments={noAssignments}
                    />
                  }
                  style={{ paddingBottom: "18px" }}
                />
                <Tab
                  id="comments"
                  title={
                    commentCount ? `Comments (${commentCount})` : "Comments"
                  }
                  disabled={!can("view", subject("PersonNote"))}
                  panel={<PlaceholderComments placeholder={placeholder} />}
                  style={{ paddingBottom: "18px" }}
                />
              </Tabs>
            </ModalBody>
            {canEditPlaceholder && activeTab === "details" ? (
              <ModalFooter
                style={{
                  justifyContent: !placeholderRequestStatus
                    ? "space-between"
                    : "flex-end",
                }}
              >
                {!placeholderRequestStatus && canEditPersonRequest ? (
                  <Dropdown
                    Target={() => (
                      <Button icon="person" disabled={noAssignments}>
                        Request ...
                      </Button>
                    )}
                  >
                    {footerRequestMenuItems.map((item) => {
                      const icon = item.icon as IconName
                      const status = item.status
                      return (
                        <MenuItem
                          text={item.text}
                          icon={<Icon icon={icon} />}
                          onClick={() => setSelectedRequestStatus(status)}
                          shouldDismissPopover={false}
                          key={item.text}
                        />
                      )
                    })}
                  </Dropdown>
                ) : null}
                {canEditPlaceholder && (
                  <Button
                    onClick={() => openPlaceholderForm(false)}
                    icon="edit"
                  >
                    Edit Details
                  </Button>
                )}
              </ModalFooter>
            ) : null}
          </>
        )}
      </section>
    </>
  )
}

export default PlaceholderSidePanel
