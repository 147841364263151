import { MenuDivider } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"

import { DropdownHeading } from "~/common/Dropdown"
import * as icons from "~/common/react-icons"

import { AvailableFilterSubList } from "./AvailableFilterList"
import type { Filter } from "./types"

type ProjectMembersFilterListItemProps = {
  onClick: (filter: Filter) => void
  projectName: string
  isTemplate: boolean
}

const ProjectMembersFilterListItem = (
  props: ProjectMembersFilterListItemProps,
) => {
  const { onClick, projectName, isTemplate } = props

  const filterConfig = {
    name: projectName,
    filter: isTemplate
      ? fe.filters.personProjectTemplateId({
          list: [],
        })
      : fe.filters.personMembership({ list: [] }),
    icon: isTemplate ? <icons.ProjectTemplateIcon /> : <icons.Assignment />,
  }

  return (
    <>
      <DropdownHeading style={{ paddingTop: "var(--spacing-xs)" }}>
        Project Team Members
      </DropdownHeading>
      <AvailableFilterSubList config={filterConfig} onClick={onClick} />
      <MenuDivider />
    </>
  )
}

export default ProjectMembersFilterListItem
