/**
 * @generated SignedSource<<bb8d286c4f899e06f4f3de2ab8e36002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRowSummary_account$data = {
  readonly custom_checkbox_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_date_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_select_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly options: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly show_in_planner: boolean;
  }>;
  readonly custom_text_types_project: ReadonlyArray<{
    readonly filterable_in_planner: boolean;
    readonly id: number;
    readonly name: string;
    readonly show_in_planner: boolean;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerLeftColumn_account">;
  readonly " $fragmentType": "ProjectRowSummary_account";
};
export type ProjectRowSummary_account$key = {
  readonly " $data"?: ProjectRowSummary_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRowSummary_account">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "model": {
        "_eq": "PROJECT"
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_in_planner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterable_in_planner",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRowSummary_account",
  "selections": [
    {
      "alias": "custom_text_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_text_types",
      "kind": "LinkedField",
      "name": "custom_text_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_text_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_date_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_date_types",
      "kind": "LinkedField",
      "name": "custom_date_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_date_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_select_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_select_types",
      "kind": "LinkedField",
      "name": "custom_select_types",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": "options",
          "args": null,
          "concreteType": "custom_select_options",
          "kind": "LinkedField",
          "name": "custom_select_options",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": "custom_select_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "custom_checkbox_types_project",
      "args": (v0/*: any*/),
      "concreteType": "custom_checkbox_types",
      "kind": "LinkedField",
      "name": "custom_checkbox_types",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": "custom_checkbox_types(where:{\"model\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlannerLeftColumn_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "8e68dc4653b71112c151c63dc3474515";

export default node;
