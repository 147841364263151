/**
 * @generated SignedSource<<92132db5c81f185afdff334a8e305f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateNameUniqueInput_project$data = ReadonlyArray<{
  readonly id: number;
  readonly name: string;
  readonly " $fragmentType": "TemplateNameUniqueInput_project";
}>;
export type TemplateNameUniqueInput_project$key = ReadonlyArray<{
  readonly " $data"?: TemplateNameUniqueInput_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateNameUniqueInput_project">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TemplateNameUniqueInput_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "5d24e27e35481a16cb6473b8793e0d4a";

export default node;
