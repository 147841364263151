import { differenceInDays, fromUnixTime } from "date-fns"
import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./TrialModal.module.css"

import { TrialModalQuery } from "./__generated__/TrialModalQuery.graphql"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import * as hashids from "~/helpers/hashids"
import { Permissions, UserType, isAdminUser } from "~/helpers/permissions"
import { switchAccount } from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import Select from "~/common/Select"
import Button from "~/common/buttons/Button"

import BookADemoButton from "~/TrialModal/BookADemoButton"
import type { Props as AppProps } from "~/containers/RunnApp"
import DeleteAccountForm from "~/forms/AccountForms/DeleteAccountForm"
import { getSettings, setSetting } from "~/localsettings"

import ExpiredCallToAction from "./ExpiredCallToAction"
import { TrialImage } from "./TrialImage"

const QUERY = graphql`
  query TrialModalQuery {
    current_user {
      id
      account {
        id
        name
      }
      user_accounts {
        id
        account {
          id
          name
          account_type
          alternative_account_id
        }
      }
    }
  }
`

type Props = {
  in_trial: boolean
  trial_end: number
  trial_ended: boolean
  subscription_active: boolean
  permissions: Permissions
  account_name: string
  account_id: number
  subscription: AppProps["trial"]["subscription"]
}

const TrialModal = (props: Props) => {
  const {
    permissions,
    trial_end,
    trial_ended,
    subscription_active,
    in_trial,
    account_name,
    account_id,
    subscription,
  } = props

  const {
    current_user: { account, user_accounts },
  } = useLazyLoadQuery<TrialModalQuery>(QUERY, {})

  const hideTrialModal = getSettings().hideTrialModal
  const endDate = fromUnixTime(trial_end)
  // We add a day here because a "4 day trial" needs to show that the account
  // has 4 days on their trial, not the 3 days & x hours that there actually
  // are
  const remainingTrialDays = differenceInDays(endDate, new Date()) + 1

  const isExpired = trial_ended && !subscription_active

  const [showModal, setShowModal] = useState(
    (in_trial && !hideTrialModal && remainingTrialDays <= 5) || isExpired,
  )

  const [dialogType, setDialogType] = useState("default")
  const isSuperuser = permissions.type === UserType.Superuser
  const isAdmin = isAdminUser(permissions.type)
  const [switchedAccount, setSwitchedAccount] = useState(null)

  const trialRemainingText = remainingTrialDays
    ? `${remainingTrialDays} days left of your trial`
    : "Trial ends today"

  const title = isExpired
    ? `Want to keep using '${account.name}'?`
    : trialRemainingText

  const continueWithRunn = () => {
    setSetting("hideTrialModal", true)
    setShowModal(false)
  }

  const handleAccountSwitch = async (otherAccountId: number) => {
    const response = await fetchWithCsrf(switchAccount(), {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        account_id: hashids.accounts.encode(otherAccountId),
      }),
    })
    if (response.ok) {
      window.location.reload()
    }
  }

  const otherAccounts = user_accounts
    .filter(
      ({ account: otherAccount }) =>
        otherAccount.id !== account_id &&
        otherAccount.account_type !== "test" &&
        otherAccount.alternative_account_id !== account_id,
    )
    .map(({ account: a }) => ({ value: a.id, label: a.name }))
  const hasOtherAccounts = otherAccounts.length > 0

  if (!showModal) {
    return null
  }

  return (
    <Dialog isOpen={showModal} style={{ overflowX: "hidden" }}>
      {dialogType === "default" && (
        <div className={styles.trialModal}>
          <div className={styles.leftSection}>
            <div className={styles.info}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>
                {`Your trial ${
                  isExpired ? "has expired" : "is ending soon"
                } but don't worry! You can pick up where you
                left off when you subscribe.`}
              </div>
              <div className={styles.callsToAction}>
                {isExpired ? (
                  <ExpiredCallToAction
                    permissions={permissions}
                    hasOtherAccounts={hasOtherAccounts}
                  />
                ) : (
                  <>
                    <Button
                      onClick={continueWithRunn}
                      text="Continue with Runn"
                      active
                    />
                    <BookADemoButton permissions={permissions} />
                  </>
                )}
              </div>
              {isSuperuser && (
                <Button text="Superuser Close" onClick={continueWithRunn} />
              )}
            </div>
            <footer>
              <div className={styles.moreTime}>
                Need a bit longer? Email{" "}
                <a href="mailto: sales@runn.io">sales@runn.io</a> to get a trial
                extension.
              </div>
              {isExpired && hasOtherAccounts ? (
                <Select
                  name="account-switcher"
                  width="280px"
                  value={switchedAccount}
                  className={styles.accountDropdown}
                  onChange={(option) => {
                    setSwitchedAccount(option)
                    void handleAccountSwitch(option.value)
                  }}
                  placeholder="Switch to another account"
                  options={otherAccounts}
                  autoFocus
                />
              ) : null}
              {isExpired && isAdmin && (
                <div className={styles.deleteAccount}>
                  {!hasOtherAccounts ? "Runn’s not right for you?" : ""} You can
                  also{" "}
                  <a
                    onClick={(e) => {
                      e.stopPropagation()
                      setDialogType("deleteAccount")
                    }}
                  >
                    delete this account
                  </a>
                </div>
              )}
            </footer>
          </div>
          <TrialImage />
        </div>
      )}
      {dialogType === "deleteAccount" && (
        <DeleteAccountForm
          account={{ name: account_name, id: account_id, subscription }}
          onClose={() => setDialogType("default")}
        />
      )}
    </Dialog>
  )
}

export default TrialModal
