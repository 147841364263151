/**
 * @generated SignedSource<<2cb7cbc5818584fb8cd7e345e6868802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectMilestones_project$data = {
  readonly confirmed: boolean;
  readonly id: number;
  readonly is_template: boolean;
  readonly milestones: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly icon: string | null | undefined;
    readonly id: number;
    readonly note: string | null | undefined;
    readonly project_id: number;
    readonly title: string | null | undefined;
  }>;
  readonly pricing_model: string;
  readonly " $fragmentType": "ProjectMilestones_project";
};
export type ProjectMilestones_project$key = {
  readonly " $data"?: ProjectMilestones_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectMilestones_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectMilestones_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricing_model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "milestones",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": "date",
          "args": null,
          "kind": "ScalarField",
          "name": "date_runn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "0295e8e10f6f2af045f8654843d15d6e";

export default node;
