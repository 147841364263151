import { MenuItemProps } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import { track } from "~/helpers/analytics"

import MenuItem from "~/common/MenuItem"

import * as ModeReducer from "~/Mode.reducer"
import { panelOpened } from "~/Planner/reducer2/panelSlice"
import { listTypeChanged } from "~/Planner/reducer2/splitScreenSlice"

type TransferMenuItem = {
  text: "Transfer" | "Transfer All Selected" | "Find Person"
  onClick: () => void
  entirePlaceholderTransfer?: boolean
} & MenuItemProps

const TransferMenuItem = (props: TransferMenuItem) => {
  const { text, entirePlaceholderTransfer = false, onClick, ...rest } = props
  const dispatch = useDispatch()

  const handleTransfer = () => {
    dispatch(
      ModeReducer.setModeAction({
        modeAction: "transfer",
        entirePlaceholderTransfer,
      }),
    )
    onClick()
    dispatch(listTypeChanged("people"))
    dispatch(panelOpened("splitScreen"))

    switch (text) {
      case "Transfer":
        track("Assignment Context Menu Transfer Selected")
        break
      case "Transfer All Selected":
        track("Assignment Context Menu Transfer All Selected Selected")
        break
      case "Find Person":
        track("Assignment Context Menu Find Person Selected")
        break
      default:
        text satisfies never
        break
    }
  }

  return (
    <MenuItem
      {...rest}
      text={<div>{props.text}</div>}
      onClick={handleTransfer}
    />
  )
}

export default TransferMenuItem
