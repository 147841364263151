import { format as formatDate, parse as parseDate } from "date-fns"
import React from "react"

import styles from "./TooltipContent.module.css"

type TooltipRowData = {
  value: React.ReactNode
  label: React.ReactNode
  icon?: React.ReactNode
}

type Props<Item> = {
  label?: string
  active?: boolean
  data: Item[]
  getRows: (item: Item) => TooltipRowData[]
  period: "weekly" | "daily"
}

const TooltipContent = <Data extends Record<string, any>>(
  props: Props<Data>,
) => {
  const { label, active, data, getRows, period } = props

  if (!active) {
    return null
  }

  const payload = data.find((d) => label === d.formattedDate)
  if (!payload) {
    return null
  }

  return (
    <div className={styles.toolTip}>
      <div className={styles.date}>
        {period === "weekly" && "Week of "}{" "}
        {formatDate(parseDate(label, "yyyy-MM-dd", new Date()), "do MMMM yyyy")}
      </div>
      <table>
        <tbody>
          {getRows(payload).map((row, index) => (
            <tr key={index}>
              <td>{row.icon}</td>
              <td>{row.label}</td>
              <td>{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { TooltipContent }
