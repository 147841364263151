import React from "react"

import styles from "./FilterBlockReadonly.module.css"

import { sortByString } from "~/helpers/sorting-helpers"

import { SimpleLabel } from "~/common/labels"

type Props = {
  name: string
  selectedOptions: { label: string }[]
}

const FilterBlockReadonly = (props: Props) => {
  const { name, selectedOptions } = props

  const formattedOptions = selectedOptions
    .map((x) => x.label)
    .sort((a, b) => sortByString(a, b))

  return (
    <div className={styles.row}>
      <div className={styles.name}>{name}</div>
      <div>
        {formattedOptions.map((option, idx) => {
          return (
            <SimpleLabel
              text={option}
              key={option}
              style={{
                padding: "4px 6px",
                marginRight: "5px",
                marginBottom: "5px",
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FilterBlockReadonly
