/**
 * @generated SignedSource<<af7d0f08fd1afbbb71893cc1d37a7d9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EffortInput_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FTEInput_account">;
  readonly " $fragmentType": "EffortInput_account";
};
export type EffortInput_account$key = {
  readonly " $data"?: EffortInput_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"EffortInput_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EffortInput_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FTEInput_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "0e12e4d790a54b46ae3d31aa32b02e0c";

export default node;
