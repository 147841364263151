import cc from "classcat"
import React, { useState } from "react"
import { UnmountClosed as ReactCollapse } from "react-collapse"
import { useDispatch } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./SplitScreenRow.module.css"

import { SplitScreenRow_person$key } from "./__generated__/SplitScreenRow_person.graphql"
import { SplitScreenRow_user$key } from "./__generated__/SplitScreenRow_user.graphql"
import { SplitScreenPanel_user$data } from "~/Planner/SplitScreenPanel/__generated__/SplitScreenPanel_user.graphql"

import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { buildImageUrl } from "~/helpers/image"
import {
  formatName,
  formatNameAsInitials,
  getCurrentContract,
} from "~/helpers/person"
import {
  getAdjustedImpactAssignments,
  getConflictData,
} from "~/helpers/split-screen-helpers"

import Avatar from "~/common/Avatar"
import { Assignment } from "~/common/PillActions/PillActions"
import { useSidePanel } from "~/common/SidePanel/SidePanel"
import TooltipEllipsis from "~/common/TooltipEllipsis"

import {
  SplitScreenModeAction,
  disableMultiSelectAndResetItems,
} from "~/Mode.reducer"
import PersonDetails from "~/PeoplePlanner/PersonDetails/PersonDetails"
import PersonSummaryGraph from "~/PeoplePlanner/PersonSummaryRow/PersonSummaryGraph"
import PlaceholderCapacityIndicator from "~/PeoplePlanner/PlaceholderRow/PlaceholderCapacityIndicator"
import { usePermissions } from "~/Permissions/usePermissions"
import PersonSidePanel from "~/Planner/PersonSidePanel/PersonSidePanel"
import PlaceholderSidePanel from "~/Planner/PlaceholderSidePanel/PlaceholderSidePanel"
import PlannerGrid from "~/Planner/PlannerGrid"
import { PlannerLeftColumn, PlannerRightColumn } from "~/Planner/PlannerLayout"
import SplitRow from "~/Planner/PlannerLayout/SplitRow"
import PlannerTooltipIcons from "~/Planner/PlannerTooltipIcons"
import { panelClosed } from "~/Planner/reducer2/panelSlice"
import { useAppSelector } from "~/hooks/redux"

import SplitScreenActionButton from "./SplitScreenActionButton"
import SplitScreenRole from "./SplitScreenRole"

type SSPanelAccount = SplitScreenPanel_user$data["account"]

type Props = {
  person: SplitScreenRow_person$key
  projects: SSPanelAccount["projects"]
  companyDefaultMinutes: SSPanelAccount["default_full_time_minutes"]
  multiSelectAssignments: Array<
    Assignment & { minutes: number; minutesLessTimeOff: number }
  >
  modeAction: SplitScreenModeAction
  selectedPersonName: string
  entirePlaceholderTransfer: boolean
  listType: "people" | "placeholders"
  countInView: number
  viewId?: number
  user: SplitScreenRow_user$key
}

const fragment = graphql`
  fragment SplitScreenRow_person on people
  @argumentDefinitions(plannerStartDate: { type: "date!" }) {
    ...PersonSummaryRow_person @arguments(plannerStartDate: $plannerStartDate)
    ...PersonDetails_person @arguments(plannerStartDate: $plannerStartDate)
    ...PlaceholderSidePanel_placeholder
    ...PersonSidePanel_person
    id
    first_name
    last_name
    email
    tags
    references
    image_key
    is_placeholder
    time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
      id
      person_id
      leave_type
      start_date: start_date_runn
      end_date: end_date_runn
      minutes_per_day
      ...ExtLinks_TimeOff @relay(mask: false)
    }
    assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
      id
      start_date: start_date_runn
      end_date: end_date_runn
      minutes_per_day
      is_billable
      is_template
      project_id
      person_id
      role_id
      workstream_id
      non_working_day
      note
      phase_id
    }
    contracts {
      id
      rostered_days
      employment_type
      minutes_per_day
      cost: cost_private
      start_date: start_date_runn
      end_date: end_date_runn
      role {
        id
        name
      }
    }
    competencies {
      id
      level
      skill {
        id
        name
      }
    }
    custom_text_values {
      value
      typeId: custom_text_type_id
    }
    custom_date_values {
      value
      typeId: custom_date_type_id
    }
    custom_select_values {
      optionId: custom_select_option_id
      typeId: custom_select_type_id
    }
    custom_checkbox_values {
      value
      typeId: custom_checkbox_type_id
    }
    person_requests {
      id
      status
    }
    project_memberships {
      id
      project {
        id
        name
        is_template
      }
    }
  }
`

const SplitScreenRow = (props: Props) => {
  const {
    person: PersonQuery,
    projects,
    multiSelectAssignments,
    modeAction,
    entirePlaceholderTransfer = false,
    selectedPersonName,
    listType,
    companyDefaultMinutes,
    countInView,
    viewId,
  } = props
  const user = useFragment(
    graphql`
      fragment SplitScreenRow_user on users {
        ...PersonSidePanel_user
        id
        account {
          id
          roles {
            id
            name
          }
          custom_text_types_person: custom_text_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          custom_date_types_person: custom_date_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          custom_select_types_person: custom_select_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            options: custom_select_options {
              id
              name
            }
            show_in_planner
            filterable_in_planner
          }
          custom_checkbox_types_person: custom_checkbox_types(
            where: { model: { _eq: "PERSON" } }
          ) {
            id
            name
            show_in_planner
            filterable_in_planner
          }
          ...PersonSummaryGraph_account
          ...PlaceholderSidePanel_account
        }
      }
    `,
    props.user,
  )

  const person = useFragment<SplitScreenRow_person$key>(fragment, PersonQuery)
  const { account } = user
  const defaultRole = getCurrentContract(person.contracts)?.role

  const [personExpanded, setPersonExpanded] = useState(false)
  const [selectedRole, setSelectedRole] = useState(defaultRole)
  const [showImpact, setShowImpact] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showRoleConfirmation, setShowRoleConfirmation] = useState(false)
  const [
    showPlaceholderTransferConfirmation,
    setShowPlaceholderTransferConfirmation,
  ] = useState(false)

  const dispatch = useDispatch()

  const { openPanel, closePanel } = useSidePanel()

  const handleToggleRow = () => {
    // This makes sure that the row doesn't expand/collapse when the user is interacting with the modals
    if (!showRoleConfirmation && !showPlaceholderTransferConfirmation) {
      setPersonExpanded(!personExpanded)
    }
  }

  const resetAll = () => {
    setShowImpact(false)
    setIsSaving(false)
    setSelectedRole(defaultRole)
    dispatch(disableMultiSelectAndResetItems())
    dispatch(panelClosed())
  }

  const {
    hasProjectOverlap,
    conflictMessage,
    overlappingProjectAssignments,
    preventAction,
  } = getConflictData({
    personName: person.first_name,
    personAssignments: person.assignments,
    personTimeOffs: person.time_offs,
    multiSelectAssignments,
    selectedRoleId: selectedRole.id,
    modeAction,
  })

  const calendar = useAppSelector((state) => state.calendar)
  const isTemplate = multiSelectAssignments[0]?.is_template

  const splitScreenImpactAssignments =
    preventAction || isTemplate
      ? []
      : hasProjectOverlap
        ? getAdjustedImpactAssignments({
            multiSelectAssignments,
            overlappingProjectAssignments,
            calStartNum: calendar.calStartNum,
            calEndNum: calendar.calEndNum,
          })
        : multiSelectAssignments

  const showIcons =
    Boolean(person.tags.length) || Boolean(person.competencies.length)

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_date_types: account.custom_date_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
  })
  const customFieldValues = valuesMap(person)

  const handleViewDetails = () => {
    if (person.is_placeholder) {
      // Take first project_membership as placeholders can only have one project for now
      const placeholderProject = person.project_memberships[0]

      track("Placeholder Side Panel Opened")
      openPanel(
        <PlaceholderSidePanel
          account={account}
          placeholder={person}
          closePanel={closePanel}
          project={placeholderProject.project}
        />,
      )
    } else {
      track("Person Side Panel Opened")
      openPanel(<PersonSidePanel user={user} person={person} />)
    }
  }

  const { can, subject } = usePermissions()

  const isPersonProjectMember = person.project_memberships.some(
    (pm) => pm.project.id === multiSelectAssignments[0]?.project_id,
  )

  const canCreateProjectMember = can(
    "create",
    subject("ProjectMember", {
      person: { id: person.id, email: person.email },
      project: {
        id: multiSelectAssignments[0]?.project_id,
        isTemplate: multiSelectAssignments[0]?.is_template,
      },
    }),
  )

  return (
    <div
      data-test="split-screen-row"
      className={cc({
        [styles.backgroundBorderBottomOnly]: !personExpanded,
      })}
    >
      <PlannerGrid
        className={cc([
          styles.row,
          {
            [styles.rowExpanded]: personExpanded,
          },
        ])}
      >
        <PlannerLeftColumn
          type="person"
          onClick={handleToggleRow}
          style={{ cursor: "pointer" }}
          collapsible
          isCollapsed={!personExpanded}
        >
          <Avatar
            avatar={buildImageUrl(person.image_key, {
              width: 40,
              height: 40,
              quality: "auto",
            })}
            email={person.email}
            initials={formatNameAsInitials(person.first_name, person.last_name)}
            is_placeholder={person.is_placeholder}
            size={32}
          />
          <div className={`fs-exclude ${styles.personDetails}`}>
            <div className={styles.title}>
              <TooltipEllipsis
                text={formatName(person.first_name, person.last_name)}
                customMaxWidth={showIcons ? "150px" : "180px"}
                onClick={handleViewDetails}
              />
              <PlannerTooltipIcons
                icons={{
                  tags: person.tags,
                  competencies: person.competencies,
                  customFields: {
                    values: customFieldValues,
                    types: customFieldTypes,
                  },
                }}
              />
            </div>
            <SplitScreenRole
              listType={listType}
              defaultRole={defaultRole}
              selectedRoleId={selectedRole.id}
              setSelectedRole={setSelectedRole}
              personName={person.first_name}
              modeAction={modeAction}
              preventRoleChange={
                isPersonProjectMember && !canCreateProjectMember
              }
            />
          </div>
          <SplitScreenActionButton
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            conflictMessage={!isTemplate ? conflictMessage : ""}
            disabled={preventAction}
            setShowImpact={setShowImpact}
            multiSelectAssignments={multiSelectAssignments}
            person={person}
            isPersonProjectMember={person.project_memberships.some(
              (pm) => pm.project.id === multiSelectAssignments[0]?.project_id,
            )}
            selectedPersonName={selectedPersonName}
            selectedRole={selectedRole}
            resetAll={resetAll}
            modeAction={modeAction}
            entirePlaceholderTransfer={entirePlaceholderTransfer}
            assignmentRole={account.roles.find(
              (r) => r.id === multiSelectAssignments[0]?.role_id,
            )}
            setShowRoleConfirmation={setShowRoleConfirmation}
            showRoleConfirmation={showRoleConfirmation}
            setShowPlaceholderTransferConfirmation={
              setShowPlaceholderTransferConfirmation
            }
            showPlaceholderTransferConfirmation={
              showPlaceholderTransferConfirmation
            }
            viewId={viewId}
          />
        </PlannerLeftColumn>
        <PlannerRightColumn showHighlight={false}>
          {listType === "placeholders" ? (
            <PlaceholderCapacityIndicator
              projects={projects}
              placeholders={[person]}
              splitScreenImpactAssignments={
                showImpact ? splitScreenImpactAssignments : undefined
              }
              isTemplate={isTemplate}
              companyDefaultMinutes={companyDefaultMinutes}
            />
          ) : (
            <PersonSummaryGraph
              account={account}
              person={person}
              projects={projects}
              expandRow={handleToggleRow}
              splitScreenImpactAssignments={
                showImpact ? splitScreenImpactAssignments : undefined
              }
              delayRender={countInView > 15}
            />
          )}
        </PlannerRightColumn>
      </PlannerGrid>
      <ReactCollapse
        isOpened={personExpanded}
        theme={{ collapse: styles.collapse }}
      >
        <PersonDetails person={person} />
        <SplitRow />
      </ReactCollapse>
    </div>
  )
}

export { SplitScreenRow }
