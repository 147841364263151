import { graphql } from "react-relay"

import { SubscriptionPlanUpdateDevMutation } from "./__generated__/SubscriptionPlanUpdateDevMutation.graphql"
import { SubscriptionUpdateMutation } from "./__generated__/SubscriptionUpdateMutation.graphql"
import { SubscriptionUpdateSeatsCountMutation } from "~/mutations/__generated__/SubscriptionUpdateSeatsCountMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "~/mutations/helpers"

export const subscriptionUpdateMutation = graphql`
  mutation SubscriptionUpdateMutation($input: SubscriptionUpdateInput!) {
    action_subscription_update(input: $input) {
      account {
        id
        subscription
      }
    }
  }
`

export const subscriptionUpdate = async (
  variables: SubscriptionUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<SubscriptionUpdateMutation>(
    environment,
    {
      mutation: subscriptionUpdateMutation,
      variables,
    },
  )
  return data
}

const subscriptionPlanUpdateDevMutation = graphql`
  mutation SubscriptionPlanUpdateDevMutation(
    $input: UpdateSubscriptionPlanDevInput!
  ) {
    updateSubscriptionPlanDev(input: $input) {
      id
    }
  }
`

export const subscriptionPlanUpdateDevRelay = async (
  variables: SubscriptionPlanUpdateDevMutation["variables"],
) => {
  const data = await commitMutationPromise<SubscriptionPlanUpdateDevMutation>(
    environment,
    {
      mutation: subscriptionPlanUpdateDevMutation,
      variables,
    },
  )

  return data.updateSubscriptionPlanDev.id
}

const subscriptionUpdateSeatsCountMutation = graphql`
  mutation SubscriptionUpdateSeatsCountMutation($count: Int!) {
    updateSeatsCount(count: $count) {
      account {
        id
      }
    }
  }
`

export const subscriptionUpdateSeatsCountRelay = async (
  variables: SubscriptionUpdateSeatsCountMutation["variables"],
) => {
  const data =
    await commitMutationPromise<SubscriptionUpdateSeatsCountMutation>(
      environment,
      {
        mutation: subscriptionUpdateSeatsCountMutation,
        variables,
      },
    )

  return data.updateSeatsCount
}
