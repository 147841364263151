import React from "react"

import styles from "./DashboardDetail.module.css"

import { sortAlphaNumeric } from "~/helpers/sorting-helpers"

type Props = {
  selectValues: readonly string[] | readonly number[]
  href?: string
}
const DashboardDetailSelectItems = (props: Props) => {
  const { selectValues, href } = props

  if (!selectValues.length) {
    return null
  }

  const sortedSelectValues = [...selectValues].sort((a, b) =>
    sortAlphaNumeric(a, b),
  )

  return (
    <div>
      {sortedSelectValues.map((value, index) => {
        return href ? (
          <a
            href={href}
            key={index}
            className={`${styles.item} ${styles.box}`}
            title={String(value)}
          >
            <span className={styles.itemContent}>{value}</span>
          </a>
        ) : (
          <div
            key={index}
            className={`${styles.item} ${styles.box}`}
            title={String(value)}
          >
            <span className={styles.itemContent}>{value}</span>
          </div>
        )
      })}
    </div>
  )
}

export default DashboardDetailSelectItems
