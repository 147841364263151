/**
 * @generated SignedSource<<13f9f7f0095ffd1cefd6182015377009>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectMemberChangeRoleMutation$variables = {
  newRoleId: number;
  personId: number;
  projectId: number;
  roleId: number;
  workstreamId?: number | null | undefined;
};
export type ProjectMemberChangeRoleMutation$data = {
  readonly changePersonAssignmentRole: {
    readonly account: {
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly contracts: ReadonlyArray<{
          readonly end_date: string | null | undefined;
          readonly id: number;
          readonly role: {
            readonly id: number;
            readonly name: string | null | undefined;
          };
          readonly start_date: string | null | undefined;
        }>;
        readonly id: number;
      }>;
    } | null | undefined;
    readonly project: {
      readonly assignments: ReadonlyArray<{
        readonly id: number;
        readonly role_id: number;
      }>;
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly role_id: number;
      }>;
      readonly project_roles: ReadonlyArray<{
        readonly id: number;
        readonly role_id: number;
      }>;
      readonly summary: ReadonlyArray<{
        readonly role_id: number | null | undefined;
        readonly total_billable_minutes: number | null | undefined;
        readonly total_nonbillable_minutes: number | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
  };
};
export type ProjectMemberChangeRoleMutation = {
  response: ProjectMemberChangeRoleMutation$data;
  variables: ProjectMemberChangeRoleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newRoleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roleId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workstreamId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "roles",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newRoleId",
        "variableName": "newRoleId"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
      },
      {
        "kind": "Variable",
        "name": "roleId",
        "variableName": "roleId"
      },
      {
        "kind": "Variable",
        "name": "workstreamId",
        "variableName": "workstreamId"
      }
    ],
    "concreteType": "ChangePersonAssignmentRoleResult",
    "kind": "LinkedField",
    "name": "changePersonAssignmentRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_roles",
            "kind": "LinkedField",
            "name": "project_roles",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "assignments",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "v_summary_total",
            "kind": "LinkedField",
            "name": "summary",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstream_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total_billable_minutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total_nonbillable_minutes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "contracts",
                "kind": "LinkedField",
                "name": "contracts",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": "start_date",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start_date_runn",
                    "storageKey": null
                  },
                  {
                    "alias": "end_date",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end_date_runn",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectMemberChangeRoleMutation",
    "selections": (v9/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectMemberChangeRoleMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "104c75c8163c45b5aec0a0c0720f0543",
    "id": null,
    "metadata": {},
    "name": "ProjectMemberChangeRoleMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectMemberChangeRoleMutation(\n  $projectId: Int!\n  $personId: Int!\n  $workstreamId: Int\n  $roleId: Int!\n  $newRoleId: Int!\n) {\n  changePersonAssignmentRole(projectId: $projectId, personId: $personId, workstreamId: $workstreamId, roleId: $roleId, newRoleId: $newRoleId) {\n    project {\n      id\n      project_roles {\n        id\n        role_id\n      }\n      members {\n        id\n        role_id\n        role {\n          id\n          name\n        }\n      }\n      assignments {\n        id\n        role_id\n      }\n      summary {\n        role_id\n        workstream_id\n        total_billable_minutes\n        total_nonbillable_minutes\n      }\n    }\n    account {\n      id\n      people {\n        id\n        contracts {\n          id\n          start_date: start_date_runn\n          end_date: end_date_runn\n          role {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5f2ce2f6fff3aca0ef0a1ed8999b1a3";

export default node;
