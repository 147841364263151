/**
 * @generated SignedSource<<869c930872d8df855596ce9495076d78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderSidePanel_placeholder$data = {
  readonly active: boolean;
  readonly archivable: boolean | null | undefined;
  readonly assignments: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly is_billable: boolean | null | undefined;
    readonly is_template: boolean;
    readonly minutes_per_day: number;
    readonly non_working_day: boolean;
    readonly note: string | null | undefined;
    readonly person_id: number;
    readonly phase_id: number | null | undefined;
    readonly project_id: number;
    readonly role_id: number;
    readonly start_date: string | null | undefined;
    readonly total_minutes: number | null | undefined;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly competencies: ReadonlyArray<{
    readonly id: number;
    readonly level: number | null | undefined;
    readonly skill: {
      readonly id: number;
      readonly name: string;
    };
  }>;
  readonly contracts: ReadonlyArray<{
    readonly cost: number | null | undefined;
    readonly end_date: string | null | undefined;
    readonly id: number;
    readonly minutes_per_day: number | null | undefined;
    readonly role: {
      readonly id: number;
      readonly name: string | null | undefined;
    };
    readonly start_date: string | null | undefined;
  }>;
  readonly custom_checkbox_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: boolean;
  }>;
  readonly custom_date_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: any | null | undefined;
  }>;
  readonly custom_select_values: ReadonlyArray<{
    readonly optionId: number;
    readonly typeId: number;
  }>;
  readonly custom_text_values: ReadonlyArray<{
    readonly typeId: number;
    readonly value: string;
  }>;
  readonly first_name: string;
  readonly id: number;
  readonly is_placeholder: boolean;
  readonly last_name: string;
  readonly people_notes: ReadonlyArray<{
    readonly id: number;
  }>;
  readonly person_requests: ReadonlyArray<{
    readonly id: number;
    readonly status: any;
    readonly updated_at: string;
    readonly user: {
      readonly first_name: string | null | undefined;
      readonly id: number;
      readonly last_name: string | null | undefined;
    } | null | undefined;
  }>;
  readonly placeholder_suggestions: ReadonlyArray<{
    readonly suggested_person: {
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"SuggestedPerson_person">;
    };
    readonly suggested_person_id: number;
  }>;
  readonly project_memberships: ReadonlyArray<{
    readonly id: number;
    readonly project: {
      readonly id: number;
      readonly is_template: boolean;
    };
  }>;
  readonly tags: any;
  readonly team: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
  readonly time_offs: ReadonlyArray<{
    readonly end_date: string | null | undefined;
    readonly ext_time_off_links: ReadonlyArray<{
      readonly active: boolean;
      readonly ext_time_off_id: number;
      readonly external: {
        readonly __typename: "ext_time_off";
        readonly id: number;
        readonly integration_id: number;
      } | null | undefined;
      readonly external_id: number;
      readonly time_off_id: number;
    }>;
    readonly id: number;
    readonly leave_type: string;
    readonly minutes_per_day: number | null | undefined;
    readonly person_id: number;
    readonly start_date: string | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PersonCustomEditor_person" | "PlaceholderActionButtons_placeholder" | "PlaceholderComments_placeholder">;
  readonly " $fragmentType": "PlaceholderSidePanel_placeholder";
};
export type PlaceholderSidePanel_placeholder$key = {
  readonly " $data"?: PlaceholderSidePanel_placeholder$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderSidePanel_placeholder">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_template",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v7 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v8 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutes_per_day",
  "storageKey": null
},
v10 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gte",
            "variableName": "plannerStartDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "end_date_iso"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "plannerStartDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderSidePanel_placeholder",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_placeholder",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivable",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_text_values",
      "kind": "LinkedField",
      "name": "custom_text_values",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_text_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_select_values",
      "kind": "LinkedField",
      "name": "custom_select_values",
      "plural": true,
      "selections": [
        {
          "alias": "optionId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_option_id",
          "storageKey": null
        },
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_select_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_checkbox_values",
      "kind": "LinkedField",
      "name": "custom_checkbox_values",
      "plural": true,
      "selections": [
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_checkbox_type_id",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "custom_date_values",
      "kind": "LinkedField",
      "name": "custom_date_values",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": "typeId",
          "args": null,
          "kind": "ScalarField",
          "name": "custom_date_type_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people_notes",
      "kind": "LinkedField",
      "name": "people_notes",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "person_requests",
      "kind": "LinkedField",
      "name": "person_requests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updated_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "users",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "teams",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "contracts",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": "cost",
          "args": null,
          "kind": "ScalarField",
          "name": "cost_private",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v10/*: any*/),
      "concreteType": "time_offs",
      "kind": "LinkedField",
      "name": "time_offs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leave_type",
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                }
              }
            }
          ],
          "concreteType": "ext_time_off_link",
          "kind": "LinkedField",
          "name": "ext_time_off_links",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time_off_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": "external_id",
              "args": null,
              "kind": "ScalarField",
              "name": "ext_time_off_id",
              "storageKey": null
            },
            {
              "alias": "external",
              "args": null,
              "concreteType": "ext_time_off",
              "kind": "LinkedField",
              "name": "ext_time_off",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "integration_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "ext_time_off_links(where:{\"active\":{\"_eq\":true}})"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "competencies",
      "kind": "LinkedField",
      "name": "competencies",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "skills",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v10/*: any*/),
      "concreteType": "assignments",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phase_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_billable",
          "storageKey": null
        },
        (v4/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total_minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "non_working_day",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "placeholder_suggestions",
      "kind": "LinkedField",
      "name": "placeholder_suggestions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "suggested_person_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "people",
          "kind": "LinkedField",
          "name": "suggested_person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SuggestedPerson_person"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonCustomEditor_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderComments_placeholder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderActionButtons_placeholder"
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "cddbdcf9e48163934d117a960a244b6a";

export default node;
