import cc from "classcat"
import React from "react"

import styles from "./PhaseIcon.module.css"

import { NO_PHASE } from "~/ProjectDashboard/Body/ProjectPhases/constants"

type Props = {
  size: number
  color: string
  className?: string
}

const PhaseIcon = (props: Props) => {
  const { size, color, className } = props
  const isNoPhase = color === NO_PHASE.color

  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: "10px",
        backgroundColor: `${color}`,
      }}
      className={cc([className, { [styles.noPhase]: isNoPhase }])}
    ></div>
  )
}

export default PhaseIcon
