import { AnchorButtonProps, Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./IconButton.module.css"

import Button from "../buttons/Button"

type Props = {
  icon: React.ReactNode
  tooltipContent: string | JSX.Element
  className?: string
  classes?: {
    active?: string
  }
  active?: boolean
  buttonProps?: Omit<Partial<AnchorButtonProps>, "onClick" | "disabled">
  onClick?: AnchorButtonProps["onClick"]
  disabled?: boolean | "tooltip" | "button"
  dataTest?: string
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
}

const IconButton = (props: Props) => {
  const {
    icon,
    className,
    classes = {},
    active,
    tooltipContent,
    disabled,
    buttonProps,
    dataTest,
    onClick,
    tooltipPlacement = "top",
  } = props

  const disabledTooltip = disabled === true || disabled === "tooltip"
  const disabledButton = disabled === true || disabled === "button"

  return (
    <Tooltip
      content={tooltipContent}
      placement={tooltipPlacement}
      hoverOpenDelay={300}
      disabled={disabledTooltip}
    >
      <Button
        forceAnchorButton={true}
        {...buttonProps}
        disabled={disabledButton}
        onClick={onClick}
        data-test={dataTest}
        className={cc([
          styles.button,
          active && classes?.active,
          {
            [styles.active]: active,
          },
          className,
        ])}
      >
        {icon}
      </Button>
    </Tooltip>
  )
}

export default IconButton
