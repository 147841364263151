import { configureStore } from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { omit } from "lodash-es"
import { CombinedState, PreloadedState } from "redux"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
  persistReducer,
  persistStore,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer, { ReduxState } from "~/rootReducer"

type PreloadedReduxState = PreloadedState<CombinedState<NoInfer<ReduxState>>>

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 3,
    storage,
    whitelist: ["displayUnit"],
    migrate: createMigrate(
      {
        // Clear all stored state
        0: (_state) => undefined,
        // Clear all stored state
        1: (_state) => undefined,
        // Clear all stored display units
        2: (state) => omit(state, "displayUnit"),
        // Clear all stored display units
        3: (state) => omit(state, "displayUnit"),
      },
      { debug: false },
    ),
  },
  rootReducer,
)

export const getStore = (preloadedState: PreloadedReduxState) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // TODO: Dont store dates in redux
          // Ignore these action types
          ignoredActions: [
            "SET_HIGHLIGHTED_COLUMN_DATA",
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
          ],
          // Ignore these paths in the state
          ignoredPaths: [
            "calendar.calendarStartDate",
            "calendar.calendarEndDate",
            "global",
          ],
        },
      }),
  })
  const persistor = persistStore(store)
  return { store, persistor }
}
