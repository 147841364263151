import React from "react"
import { useDispatch } from "react-redux"

import { track } from "~/helpers/analytics"

import MenuItem from "~/common/MenuItem"

import * as ModeReducer from "~/Mode.reducer"
import { panelOpened } from "~/Planner/reducer2/panelSlice"
import { listTypeChanged } from "~/Planner/reducer2/splitScreenSlice"

type CloneMenuItem = {
  text: "Clone" | "Clone All Selected"
  onClick: () => void
}

const CloneMenuItem = (props: CloneMenuItem) => {
  const { onClick, text } = props
  const dispatch = useDispatch()

  const handleClone = () => {
    dispatch(ModeReducer.setModeAction({ modeAction: "clone" }))
    onClick()
    dispatch(listTypeChanged("people"))
    dispatch(panelOpened("splitScreen"))

    switch (text) {
      case "Clone":
        track("Assignment Context Menu Clone")
        break
      case "Clone All Selected":
        track("Assignment Context Menu Clone All Selected")
        break
      default:
        text satisfies never
        break
    }
  }

  return <MenuItem text={<div>{props.text}</div>} onClick={handleClone} />
}

export default CloneMenuItem
