import { Icon } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React from "react"

import FilterBlockDate, { DatePayload } from "./common/FilterBlockDate"
import type { FilterComponentProps } from "~/common/SuperSearch/types"

import FilterBlockReadonly from "~/Views/FilterBlockReadonly"

type BaseProps = {
  filter: fe.filters.FilterPersonCustomDate
}

type Props = FilterComponentProps & BaseProps

const Component = (props: Props) => {
  const {
    filter,
    path,
    disabled,
    isDropdownOpen,
    onChange,
    onRemove,
    onChangeDropdownOpen,
  } = props

  const handleChange = (datePayload: DatePayload) => {
    onChange(
      path,
      fe.filters.personCustomDate({
        name: filter.options.name,
        typeId: filter.options.typeId,
        datePayload,
      }),
    )
  }

  const handleRemove = () => onRemove(path)

  return (
    <FilterBlockDate
      icon={<Icon icon="calendar" />}
      label={filter.options.name}
      disabled={disabled}
      isDropdownOpen={isDropdownOpen}
      datePayload={filter.options.datePayload}
      onChange={handleChange}
      onChangeDropdownOpen={onChangeDropdownOpen}
      onRemove={handleRemove}
    />
  )
}

const ReadonlyComponent = (props: Props) => {
  const { name } = props.filter.options

  return <FilterBlockReadonly name={name} selectedOptions={[]} />
}

export const PersonCustomDate = {
  Component,
  ReadonlyComponent,
}
