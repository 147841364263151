import cc from "classcat"
import React, { useState } from "react"

import styles from "./PlaceholderTransferConfirmationModal.module.css"

import { MultiSelectAssignments } from "~/helpers/split-screen-helpers"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import PermissionsTooltip from "~/common/PermissionsTooltip"
import Button from "~/common/buttons/Button"
import Checkbox from "~/common/inputs/Checkbox"
import { Warning } from "~/common/react-icons"

import { usePermissions } from "~/Permissions/usePermissions"

type Props = {
  personName: string
  project: Pick<MultiSelectAssignments[number], "project_id" | "is_template">
  placeholderName: string
  isSaving: boolean
  handleSubmit: (deletePlaceholder: boolean) => void
  closeDialog: () => void
  numberOfAssignments: number
}

const PlaceholderTransferConfirmationModal = (props: Props) => {
  const {
    personName,
    project,
    placeholderName,
    numberOfAssignments,
    isSaving,
    closeDialog,
    handleSubmit,
  } = props

  const { can, subject } = usePermissions()
  const canDeletePlaceholder = can(
    "delete",
    subject("Placeholder", {
      project: { id: project.project_id, isTemplate: project.is_template },
    }),
  )

  const [confirmDeletePlaceholder, setConfirmDeletePlaceholder] =
    useState(canDeletePlaceholder)

  const handleToggleDeletePlaceholder = () => {
    setConfirmDeletePlaceholder((prevState) => !prevState)
  }

  const buttonText = confirmDeletePlaceholder
    ? "Transfer and Delete"
    : "Transfer"

  return (
    <ModalFormWrapper headerTitle="Confirm Transfer">
      <ModalBody>
        <div className={styles.wrapper}>
          <p>
            All assignments ({numberOfAssignments}) from{" "}
            <b>{placeholderName}</b> will be transferred to <b>{personName}</b>{" "}
          </p>
          <PermissionsTooltip
            action="delete"
            subject="Placeholders"
            disabled={canDeletePlaceholder}
          >
            <>
              <Checkbox
                id="placeholder-delete"
                label="Delete placeholder after transfer"
                onChange={handleToggleDeletePlaceholder}
                checked={confirmDeletePlaceholder}
                disabled={!canDeletePlaceholder}
                labelClassName={cc([
                  styles.checkboxLabel,
                  { [styles.disabled]: !canDeletePlaceholder },
                ])}
                tickClassName={styles.checkboxTick}
              />
              {confirmDeletePlaceholder && (
                <div className={styles.warning}>
                  <Warning />
                  <div className={styles.message}>
                    {placeholderName} will be deleted
                  </div>
                </div>
              )}
            </>
          </PermissionsTooltip>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSaving} text="Cancel" onClick={closeDialog} />
        <Button
          text={buttonText}
          outlined={false}
          loading={isSaving}
          disabled={isSaving}
          onClick={() => handleSubmit(confirmDeletePlaceholder)}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default PlaceholderTransferConfirmationModal
