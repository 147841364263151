/**
 * @generated SignedSource<<850312df489eb5359150f2253b89a498>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoleSelector_user$data = {
  readonly account: {
    readonly id: number;
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
  };
  readonly " $fragmentType": "RoleSelector_user";
};
export type RoleSelector_user$key = {
  readonly " $data"?: RoleSelector_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoleSelector_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleSelector_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "default_hour_cost",
              "args": null,
              "kind": "ScalarField",
              "name": "default_hour_cost_private",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "c4081901538c3327a31a72c47028b207";

export default node;
