/**
 * @generated SignedSource<<d8fa83c500dec5f47f71b55ea33fb870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitScreenRole_user$data = {
  readonly account: {
    readonly roles: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
  };
  readonly " $fragmentType": "SplitScreenRole_user";
};
export type SplitScreenRole_user$key = {
  readonly " $data"?: SplitScreenRole_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitScreenRole_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitScreenRole_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "f818b7aaf98b2ec7ae25bfd3799d7fbb";

export default node;
