import cc from "classcat"
import { useFeature } from "flagged"
import React, { useEffect, useRef } from "react"
import isDeeplyEqual from "react-fast-compare"
import { useDispatch, useSelector } from "react-redux"
import { graphql, useFragment } from "react-relay"

import styles from "./ProjectDetailsPersonRow.module.css"

import { ProjectDetailsPersonRow_account$key } from "./__generated__/ProjectDetailsPersonRow_account.graphql"
import { ProjectDetailsPersonRow_person$key } from "./__generated__/ProjectDetailsPersonRow_person.graphql"
import { ProjectDetailsPersonRow_project$key } from "./__generated__/ProjectDetailsPersonRow_project.graphql"
import { ProjectDetailsPersonRow_user$key } from "./__generated__/ProjectDetailsPersonRow_user.graphql"

import { getTimeOffWeekends } from "~/helpers/CalendarHelper"
import { track } from "~/helpers/analytics"
import { typesMap, valuesMap } from "~/helpers/custom-field-helpers"
import { dashify } from "~/helpers/general-helpers"
import { buildImageUrl } from "~/helpers/image"
import { formatNameAsInitials } from "~/helpers/person"
import { scrollToRef } from "~/helpers/planner-helpers"

import Avatar from "~/common/Avatar"
import { SkeletonRow } from "~/common/LoadingSkeleton"
import PlaceholderIcon from "~/common/PlaceholderIcon"

import { useEntitlementSwitch } from "~/Entitlements/useEntitlements"
import { isSplitScreenMode } from "~/Mode.reducer"
import PlannerGrid from "~/Planner/PlannerGrid"
import {
  PlannerCalendarRow,
  PlannerLeftColumn,
  PlannerRightColumn,
} from "~/Planner/PlannerLayout"
import { panelClosed, panelOpened } from "~/Planner/reducer2/panelSlice"
import { personIdSelected } from "~/Planner/reducer2/schedulePreviewSlice"
import ProjectGroupTypeTooltip from "~/ProjectPlanner/ProjectRow/ProjectGroupTypeTooltip"
import { useAppSelector } from "~/hooks/redux"
import { isFirefox } from "~/hooks/useIsInViewport"
import { useIsInViewport } from "~/hooks/useIsInViewport"
import { ReduxState } from "~/rootReducer"

import PersonNameDisplay from "./PersonNameDisplay"
import { Assignment } from "./ProjectDetailsSection"

const ProjectDetailsPersonRow = (props: Props) => {
  const person = useFragment(
    graphql`
      fragment ProjectDetailsPersonRow_person on people
      @argumentDefinitions(plannerStartDate: { type: "date!" }) {
        ...Overbookings_person @arguments(plannerStartDate: $plannerStartDate)
        ...EditPersonForm_person
        id
        first_name
        last_name
        image_key
        is_placeholder
        email
        active
        archivable
        team {
          id
          name
        }
        tags
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          cost: cost_private
          minutes_per_day
          role {
            id
            name
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          is_template
          note
          role_id
          person_id
          phase_id
          project_id
          workstream_id
          updated_at
          total_minutes
          non_working_day
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          person_id
          leave_type
          note
          minutes_per_day
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
        }
        people_notes {
          id
          note
          created_at
          ...PersonNote_note
        }
        person_requests {
          id
          status
          project_id
          updated_at
          user {
            id
            first_name
            last_name
          }
          person {
            id
            first_name
            contracts {
              id
              start_date: start_date_runn
              end_date: end_date_runn
              cost: cost_private
              minutes_per_day
              role {
                id
                name
              }
            }
          }
        }
        holidays_group {
          id
          name
        }
        custom_text_values {
          value
          typeId: custom_text_type_id
        }
        custom_date_values {
          value
          typeId: custom_date_type_id
        }
        custom_select_values {
          optionId: custom_select_option_id
          typeId: custom_select_type_id
        }
        custom_checkbox_values {
          typeId: custom_checkbox_type_id
          value
        }
        placeholder_suggestions {
          suggested_person_id
        }
        ...PersonCustomEditor_person
        ...PlannerLeftColumn_person
        ...PlaceholderActionButtons_placeholder
      }
    `,
    props.person,
  )
  const project = useFragment(
    graphql`
      fragment ProjectDetailsPersonRow_project on projects {
        id
        name
        confirmed
        pricing_model
        is_template
        emoji
        client {
          id
          name
          image_key
          website
        }
        phases {
          id
          color
          name
          start_date: start_date_runn
          end_date: end_date_runn
        }
        members {
          id
          workstream_id
          role_id
          person_id
          role {
            id
            name
          }
          workstream {
            id
            name
          }
        }
        ...PlannerLeftColumn_project
      }
    `,
    props.project,
  )
  const account = useFragment(
    graphql`
      fragment ProjectDetailsPersonRow_account on accounts {
        id
        custom_text_types_person: custom_text_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_date_types_person: custom_date_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        custom_select_types_person: custom_select_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          options: custom_select_options {
            id
            name
          }
          show_in_planner
          filterable_in_planner
        }
        custom_checkbox_types_person: custom_checkbox_types(
          where: { model: { _eq: "PERSON" } }
        ) {
          id
          name
          show_in_planner
          filterable_in_planner
        }
        users {
          id
          email
        }
        invitations {
          id
          email
        }

        ...PlannerCalendarRow_account
        ...PlannerLeftColumn_account
      }
    `,
    props.account,
  )

  const user = useFragment(
    graphql`
      fragment ProjectDetailsPersonRow_user on users {
        id
        ...PlannerLeftColumn_user
      }
    `,
    props.user,
  )

  const {
    roleId,
    workstreamId,
    groupName,
    justAdded,
    isInactivePerson,
    companyDefaultMinutes,
    delayAssignmentRender,
    locatedInSchedulePanel,
    archived = false,
  } = props

  const doubleCheckIsInViewPort = isFirefox()
  const dispatch = useDispatch()
  const rowRef = useRef()
  const isInViewport = useIsInViewport(rowRef, {
    delay: 15000,
    keepVisible: false,
    offsetY: 1000,
    rootQuerySelector: "#project-planner-list",
    fallbackCheckTrigger: doubleCheckIsInViewPort,
  })

  const showPanel: string | null = useSelector(
    (state: ReduxState) => state.panel.showPanel,
  )
  const selectedPersonId: number = useSelector(
    (state: ReduxState) => state.plannerV2.schedulePreview.selectedPersonId,
  )
  const schedulePanelOpen = showPanel === "personSchedule"

  const multiSelectItem = useAppSelector(
    (state) => state.multiSelect.items[0],
    isDeeplyEqual,
  ) as Assignment
  const modeAction = useAppSelector((state) => state.multiSelect.modeAction)
  const splitScreenMode = isSplitScreenMode(modeAction)

  const disabledRow =
    splitScreenMode &&
    Boolean(multiSelectItem) &&
    (multiSelectItem?.person_id !== person.id ||
      multiSelectItem?.project_id !== project.id)

  useEffect(() => {
    if (splitScreenMode && !disabledRow) {
      scrollToRef(rowRef)
    }
  }, [splitScreenMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const customFieldTypes = typesMap({
    custom_text_types: account.custom_text_types_person,
    custom_date_types: account.custom_date_types_person,
    custom_select_types: account.custom_select_types_person,
    custom_checkbox_types: account.custom_checkbox_types_person,
  })
  const customFieldValues = valuesMap(person)

  const personName = `${person.first_name} ${person.last_name}`
  const personInitials = formatNameAsInitials(
    person.first_name,
    person.last_name,
  )

  const viewScheduleInPanel = () => {
    if (schedulePanelOpen && selectedPersonId === person.id) {
      dispatch(panelClosed())
    } else {
      dispatch(panelOpened("personSchedule"))
      dispatch(personIdSelected(person.id))
      track("Person Schedule Panel Opened")
    }
  }

  const dataTestName = person.is_placeholder ? person.last_name : personName

  const testId = dashify(
    `project-details-${project.name}-${groupName}-${dataTestName}`,
  )

  const groupByType = useAppSelector(
    (state) => state.plannerV2.plannerStats.groupByType,
  )

  const assignments = person.assignments.filter((a) => {
    if (a.project_id !== project.id) {
      return false
    }

    const projectMember = project.members.find(
      (m) => m.id === props.projectMemberId,
    )

    if (!projectMember) {
      return false
    }

    if (groupByType === "roles") {
      return (
        a.role_id === roleId && a.workstream_id === projectMember.workstream_id
      )
    }

    if (groupByType === "workstreams") {
      return (
        a.workstream_id === workstreamId && a.role_id === projectMember.role_id
      )
    }

    return false
  })

  const calStartNum: number = useSelector(
    (state: ReduxState) => state.calendar.calStartNum,
  )
  const calEndNum: number = useSelector(
    (state: ReduxState) => state.calendar.calEndNum,
  )
  const isWorkstreamsEnabled = useFeature("workstreams")
  const entitledToResourceRequests = useEntitlementSwitch("resourcing-requests")

  const timeOffsWithWeekend = getTimeOffWeekends(
    person.time_offs,
    calStartNum,
    calEndNum,
  )

  const showRequestIcon =
    entitledToResourceRequests &&
    Boolean(person?.person_requests?.length || person?.people_notes?.length)

  const showSubGroupInfo = () => {
    const projectMember = project.members.find(
      (pm) => pm.id === props.projectMemberId,
    )

    const subGroupName =
      groupByType === "roles"
        ? projectMember.workstream?.name
        : projectMember.role?.name

    if (!subGroupName) {
      return ""
    }

    return (
      <ProjectGroupTypeTooltip
        groupName={subGroupName}
        groupNameType={groupByType === "roles" ? "workstreams" : "roles"}
        dataTestName={`subgroup-info-${dashify(personName)}`}
        className={cc([
          styles.subGroupInfo,
          {
            [styles.subGroupInfoInactive]: isInactivePerson,
          },
        ])}
      />
    )
  }

  return (
    <PlannerGrid
      data-test={testId}
      data-component="ProjectDetailsPersonRow"
      ref={rowRef}
      disabled={disabledRow || archived}
      tooltipText={
        archived && "Archived people are not editable in the planner"
      }
    >
      {isInViewport ? (
        <>
          <PlannerLeftColumn
            type="person"
            project={project}
            user={user}
            account={account}
            accountLegacy={account}
            paddingHeight={10}
            personLegacy={person}
            projectId={project.id}
            roleId={roleId}
            workstreamId={workstreamId}
            person={person}
            personQuery={person}
            className={justAdded ? "fadeInBlueToWhite" : ""}
            viewPerson={viewScheduleInPanel}
            showMenuDots={!locatedInSchedulePanel}
            data-test={`planner-left-column-${dashify(personName)}`}
            isInProjectTemplate={project.is_template}
          >
            <Avatar
              email={person.email}
              initials={personInitials}
              avatar={buildImageUrl(person.image_key, {
                width: 24,
                height: 24,
                quality: "auto",
              })}
              is_placeholder={person.is_placeholder}
              isInactivePerson={isInactivePerson}
              isArchived={archived}
            />
            <span className={`${styles.personNameWrapper} fs-exclude`}>
              <PersonNameDisplay
                person={person}
                isInactivePerson={isInactivePerson}
                onClick={viewScheduleInPanel}
                showRequestIcon={showRequestIcon}
                customValues={customFieldValues}
                customTypes={customFieldTypes}
                isPlaceholderInTemplate={
                  person.is_placeholder && project.is_template
                }
              />
              {isWorkstreamsEnabled && showSubGroupInfo()}
            </span>
            {showRequestIcon && <PlaceholderIcon placeholder={person} />}
          </PlannerLeftColumn>
          <PlannerRightColumn
            rowId={String(person.id)}
            isUnconfirmedProject={!project.confirmed}
            isPlaceholder={person.is_placeholder}
          >
            <PlannerCalendarRow
              account={account}
              person={person}
              project={project}
              assignments={assignments}
              roleId={roleId}
              workstreamId={workstreamId}
              timeOffs={person.time_offs}
              contracts={person.contracts}
              client={project.client}
              companyDefaultMinutes={companyDefaultMinutes}
              delayAssignmentRender={delayAssignmentRender}
              timeOffsWithWeekend={timeOffsWithWeekend}
              rowType="person"
            />
          </PlannerRightColumn>
        </>
      ) : (
        <SkeletonRow height={44} showCalenderGrid />
      )}
    </PlannerGrid>
  )
}

type Props = {
  account: ProjectDetailsPersonRow_account$key
  user: ProjectDetailsPersonRow_user$key
  roleId: number
  workstreamId: number
  archived: boolean
  groupName: string
  person: ProjectDetailsPersonRow_person$key
  project: ProjectDetailsPersonRow_project$key
  justAdded?: boolean
  isInactivePerson: boolean
  companyDefaultMinutes: number
  assignments?: Assignment[]
  delayAssignmentRender: boolean
  locatedInSchedulePanel?: boolean
  projectMemberId: number
}

export default React.memo(ProjectDetailsPersonRow)
