/**
 * @generated SignedSource<<3e9c959b85b55e4b61d7f426c4547d2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeProjectRoleModal_person$data = {
  readonly first_name: string;
  readonly id: number;
  readonly last_name: string;
  readonly project_memberships: ReadonlyArray<{
    readonly has_assignments: boolean | null | undefined;
    readonly id: number;
    readonly project_id: number;
    readonly role_id: number;
    readonly workstream_id: number | null | undefined;
  }>;
  readonly " $fragmentType": "ChangeProjectRoleModal_person";
};
export type ChangeProjectRoleModal_person$key = {
  readonly " $data"?: ChangeProjectRoleModal_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_person">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeProjectRoleModal_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "project_memberships",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workstream_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_assignments",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "people",
  "abstractKey": null
};
})();

(node as any).hash = "fd676e70b71fde772794a0d2dbedc786";

export default node;
