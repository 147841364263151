/**
 * @generated SignedSource<<dd1c2c96234e8c7ef00b61ffa7185544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditProjectBudgetQuery$variables = {
  projectId: number;
};
export type EditProjectBudgetQuery$data = {
  readonly projects_by_pk: {
    readonly active: boolean;
    readonly client: {
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly internal: boolean | null | undefined;
      readonly website: string | null | undefined;
    };
    readonly emoji: string | null | undefined;
    readonly expenses_budget: number | null | undefined;
    readonly id: number;
    readonly is_template: boolean;
    readonly members: ReadonlyArray<{
      readonly id: number;
      readonly role_id: number;
    }>;
    readonly name: string;
    readonly other_costs: ReadonlyArray<{
      readonly charge: number;
      readonly cost: number;
      readonly date: string | null | undefined;
      readonly id: number;
      readonly name: string;
    }>;
    readonly pricing_model: string;
    readonly project_rates: ReadonlyArray<{
      readonly id: number;
      readonly project_id: number;
      readonly rate: number | null | undefined;
      readonly role_id: number;
    }>;
    readonly project_roles: ReadonlyArray<{
      readonly estimated_minutes: number | null | undefined;
      readonly id: number;
      readonly role_id: number;
    }>;
    readonly rate_card: {
      readonly blended_rate: number | null | undefined;
      readonly blended_rate_card: boolean;
      readonly id: number;
      readonly name: string | null | undefined;
      readonly role_charge_out_rates: ReadonlyArray<{
        readonly charge_out_rate: number | null | undefined;
        readonly rate_card_id: number;
        readonly role_id: number;
      }>;
    };
    readonly rate_type: string;
    readonly total_budget: number | null | undefined;
  } | null | undefined;
};
export type EditProjectBudgetQuery = {
  response: EditProjectBudgetQuery$data;
  variables: EditProjectBudgetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectId"
      }
    ],
    "concreteType": "projects",
    "kind": "LinkedField",
    "name": "projects_by_pk",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pricing_model",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rate_type",
        "storageKey": null
      },
      {
        "alias": "total_budget",
        "args": null,
        "kind": "ScalarField",
        "name": "total_budget_private",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expenses_budget",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emoji",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_template",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "project_rates",
        "kind": "LinkedField",
        "name": "project_rates",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": "rate",
            "args": null,
            "kind": "ScalarField",
            "name": "rate_private",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "rate_cards",
        "kind": "LinkedField",
        "name": "rate_card",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": "blended_rate",
            "args": null,
            "kind": "ScalarField",
            "name": "blended_rate_private",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blended_rate_card",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "role_charge_out_rates",
            "kind": "LinkedField",
            "name": "role_charge_out_rates",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rate_card_id",
                "storageKey": null
              },
              {
                "alias": "charge_out_rate",
                "args": null,
                "kind": "ScalarField",
                "name": "charge_out_rate_private",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "project_roles",
        "kind": "LinkedField",
        "name": "project_roles",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimated_minutes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "project_members",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "clients",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internal",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image_key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order_by",
            "value": {
              "date": "asc"
            }
          }
        ],
        "concreteType": "other_costs",
        "kind": "LinkedField",
        "name": "other_costs",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": "date",
            "args": null,
            "kind": "ScalarField",
            "name": "date_runn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "charge",
            "storageKey": null
          }
        ],
        "storageKey": "other_costs(order_by:{\"date\":\"asc\"})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProjectBudgetQuery",
    "selections": (v4/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProjectBudgetQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9ed61c9cb3fb69eedb0f54bb0796420b",
    "id": null,
    "metadata": {},
    "name": "EditProjectBudgetQuery",
    "operationKind": "query",
    "text": "query EditProjectBudgetQuery(\n  $projectId: Int!\n) {\n  projects_by_pk(id: $projectId) {\n    id\n    name\n    active\n    pricing_model\n    rate_type\n    total_budget: total_budget_private\n    expenses_budget\n    emoji\n    is_template\n    project_rates {\n      id\n      project_id\n      role_id\n      rate: rate_private\n    }\n    rate_card {\n      id\n      name\n      blended_rate: blended_rate_private\n      blended_rate_card\n      role_charge_out_rates {\n        role_id\n        rate_card_id\n        charge_out_rate: charge_out_rate_private\n      }\n    }\n    project_roles {\n      id\n      role_id\n      estimated_minutes\n    }\n    members {\n      id\n      role_id\n    }\n    client {\n      internal\n      id\n      image_key\n      website\n    }\n    other_costs(order_by: {date: asc}) {\n      id\n      name\n      date: date_runn\n      cost\n      charge\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e43e1ae8f7e6274db66ab9a927f1b762";

export default node;
