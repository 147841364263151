import React from "react"

import styles from "./CustomTooltip.module.css"

import { CustomDateType, CustomDateValue } from "~/helpers/custom-field-helpers"

type CustomTooltipDateProps = {
  type: CustomDateType
  value: CustomDateValue
}

const CustomTooltipDate = ({ type, value }: CustomTooltipDateProps) => {
  if (!value?.value) {
    return null
  }

  return (
    <div className={styles.fieldContainer}>
      <span className={styles.title}>{type.name}</span>
      <div className={styles.itemContainer}>{value.value}</div>
    </div>
  )
}

export default CustomTooltipDate
