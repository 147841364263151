/**
 * @generated SignedSource<<bdda81886e2e9b3753015244faf5e0b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoleForm_viewer$data = {
  readonly account: {
    readonly all_roles: ReadonlyArray<{
      readonly active: boolean;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
    }>;
    readonly currency: string | null | undefined;
    readonly id: number;
    readonly rate_type: string;
  };
  readonly permissions: any;
  readonly " $fragmentType": "RoleForm_viewer";
};
export type RoleForm_viewer$key = {
  readonly " $data"?: RoleForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoleForm_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate_type",
          "storageKey": null
        },
        {
          "alias": "all_roles",
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": "default_hour_cost",
              "args": null,
              "kind": "ScalarField",
              "name": "default_hour_cost_private",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "d3a940dcce79dc542efdaddf2c8aa8a7";

export default node;
