/**
 * @generated SignedSource<<05017645473c4c118ab6217e31b5745a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsSelector_viewer$data = {
  readonly account: {
    readonly allSkills: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
    readonly id: number;
  };
  readonly permissions: any;
  readonly " $fragmentType": "SkillsSelector_viewer";
};
export type SkillsSelector_viewer$key = {
  readonly " $data"?: SkillsSelector_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillsSelector_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillsSelector_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "allSkills",
          "args": null,
          "concreteType": "skills",
          "kind": "LinkedField",
          "name": "skills",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "be87cee36f203d84d03a4ced8e04ab13";

export default node;
