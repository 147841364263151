/**
 * @generated SignedSource<<06f1cac7a31c36b6bc3d04317b730424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeoplePlanner_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlannerHeader_account">;
  readonly " $fragmentType": "PeoplePlanner_account";
};
export type PeoplePlanner_account$key = {
  readonly " $data"?: PeoplePlanner_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeoplePlanner_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeoplePlanner_account",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PlannerHeader_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "ed8294a34f847137e3c45b433b93b456";

export default node;
