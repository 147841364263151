import cc from "classcat"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./ChartsToggle.module.css"

import { track } from "~/helpers/analytics"

import * as icons from "~/common/react-icons"

import { panelOpened } from "~/Planner/reducer2/panelSlice"
import { useAppSelector } from "~/hooks/redux"

import IconButton from "./IconButton"

const ChartsToggle = () => {
  const dispatch = useDispatch()
  const chartPanelOpen =
    useAppSelector((state) => state.plannerV2.panel.showPanel) === "charts"

  const handleClick = () => {
    track("Planner Charts Toggled", { value: !chartPanelOpen })
    dispatch(panelOpened(chartPanelOpen ? null : "charts"))
  }

  const checked = chartPanelOpen
  const tooltipContent = checked ? "Turn charts off" : "Turn charts on"

  return (
    <IconButton
      tooltipContent={tooltipContent}
      icon={<icons.Charts />}
      className={cc([styles.button, checked ? styles.active : styles.inactive])}
      onClick={handleClick}
      dataTest="ChartsToggle"
    />
  )
}

export default ChartsToggle
