import { Tooltip } from "@blueprintjs/core"
import classcat from "classcat"
import React from "react"
import { match } from "ts-pattern"

import styles from "./ProjectGroupTypeTooltip.module.css"

type Props = {
  groupName: string
  groupNameType: "roles" | "workstreams"
  fontSize?: number
  className?: string
  dataTestName?: string
}
const ProjectGroupTypeTooltip = (props: Props) => {
  const { groupNameType, groupName, fontSize = 13 } = props

  const nameType = match(groupNameType)
    .with("roles", () => "Role")
    .with("workstreams", () => "Workstream")
    .otherwise(() => "")

  return (
    <div className={styles.container}>
      <Tooltip
        content={
          <div>
            {nameType}:{" "}
            {<span className={styles.groupHeaderTooltipText}>{groupName}</span>}
          </div>
        }
        position="right"
        hoverOpenDelay={360}
        hoverCloseDelay={100}
      >
        <div
          className={classcat([styles.groupHeaderText, props.className])}
          style={{ fontSize: fontSize }}
          data-test={props.dataTestName}
        >
          {groupName}
        </div>
      </Tooltip>
    </div>
  )
}

export default ProjectGroupTypeTooltip
