import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import {
  people_bool_exp,
  projects_bool_exp,
} from "../FilteredQuery/__generated__/FilterQuery.graphql"

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> }

export type TemporaryPlannerFilter = {
  projectFilters: DeepWriteable<projects_bool_exp> | "all" | null
  peopleFilters: DeepWriteable<people_bool_exp> | "all" | null
}

export type ViewSliceState = {
  showAllView: boolean
  currentViewId?: number | null
  enabled: boolean
  // Additional IDs that are required on the planner as the result of an action from a temporary view
  additionalPlannerIds: Record<
    number,
    {
      projectIds: number[]
      peopleIds: number[]
    }
  >
  // Temporary filters that are used in a component using independent views
  temporaryPlannerFilter: TemporaryPlannerFilter
}

export type viewsType = "hours" | "financials"

const getInitialState = (): ViewSliceState => {
  return {
    showAllView: true,
    currentViewId: null,
    enabled: false,
    additionalPlannerIds: {},
    temporaryPlannerFilter: { peopleFilters: null, projectFilters: null },
  }
}

const viewsSlice = createSlice({
  name: "views",
  initialState: getInitialState,
  reducers: {
    showAllViewSelected: (state) => {
      state.showAllView = true
      state.currentViewId = null
    },
    showAllViewDeselected: (state, action: PayloadAction<number | null>) => {
      state.showAllView = false
      state.currentViewId = action.payload
    },
    setTemporaryPlannerFilter: (
      state,
      action: PayloadAction<Partial<TemporaryPlannerFilter>>,
    ) => {
      state.temporaryPlannerFilter = {
        ...state.temporaryPlannerFilter,
        ...action.payload,
      }
    },
    setTemporaryPlannerFilterToAll: (state) => {
      state.temporaryPlannerFilter = {
        peopleFilters: "all",
        projectFilters: "all",
      }
    },
    resetTemporaryPlannerFilter: (state) => {
      state.temporaryPlannerFilter = {
        peopleFilters: null,
        projectFilters: null,
      }
    },
    addAdditionalPlannerIds: (
      state,
      action: PayloadAction<{
        viewId: number
        projectIds?: number[]
        peopleIds?: number[]
      }>,
    ) => {
      // Skip adding additional ids to show all view as the planner will container a full set of people/projects already
      if (typeof action.payload.viewId !== "number") {
        return
      }

      state.additionalPlannerIds[action.payload.viewId] ??= {
        projectIds: [],
        peopleIds: [],
      }

      const current = state.additionalPlannerIds[action.payload.viewId]
      state.additionalPlannerIds[action.payload.viewId] = {
        projectIds: [
          ...current.projectIds,
          ...(action.payload?.projectIds ?? []),
        ],
        peopleIds: [...current.peopleIds, ...(action.payload?.peopleIds ?? [])],
      }
    },
  },
})

const { actions, reducer } = viewsSlice
export const {
  showAllViewSelected,
  showAllViewDeselected,
  setTemporaryPlannerFilter,
  setTemporaryPlannerFilterToAll,
  resetTemporaryPlannerFilter,
  addAdditionalPlannerIds,
} = actions
export default reducer
