import { useMemo } from "react"
import isDeeplyEqual from "react-fast-compare"

import { getVisibleAssignments } from "~/helpers/assignment-helpers"
import {
  MemberWithArchiveStatus,
  MembershipWithArchiveStatus,
  ProjectMember,
  isNew,
  isSameMember,
  separateActiveInactiveMembers,
} from "~/helpers/project-member-helpers"

import {
  selectIsAllMembersShown,
  selectIsAllMembershipsShown,
  selectPersonLastToggled,
  selectProjectLastToggled,
} from "~/Planner/reducer2/projectMembersViewSlice"
import { useAppSelector } from "~/hooks/redux"

type Assignment = {
  id: number
  project_id: number
  person_id: number
  role_id: number
  workstream_id: number
  start_date: string
  end_date: string
}

type MemberListState<M extends ProjectMember> = {
  visible: ReadonlyArray<M>
  active: ReadonlyArray<M>
  inactive: ReadonlyArray<M>
  inactiveNew: ReadonlyArray<M>
  all: ReadonlyArray<M>
}

type Options<M extends ProjectMember, A extends Assignment> = {
  members: ReadonlyArray<M>
  assignments: ReadonlyArray<A>
}
type MemberListOptions<M extends ProjectMember, A extends Assignment> = Options<
  M,
  A
> & {
  showAll?: boolean
  lastChanged?: number
  calStartNum: number
  calEndNum: number
}

const getList = <
  M extends MemberWithArchiveStatus | MembershipWithArchiveStatus,
  A extends Assignment,
>(
  opts: MemberListOptions<M, A>,
): MemberListState<M> => {
  const {
    members,
    assignments: unfilteredAssignments,
    showAll = false,
    lastChanged = 0,
    calStartNum,
    calEndNum,
  } = opts
  const assignments = getVisibleAssignments(
    unfilteredAssignments,
    calStartNum,
    calEndNum,
  )

  const { activeMembers, inactiveMembers } = separateActiveInactiveMembers(
    members,
    assignments,
  )

  const inactiveNewMembers = inactiveMembers.filter(isNew)

  // This does not include archived members without assignments in view
  const currentMembers = [...activeMembers, ...inactiveMembers]

  let visibleMembers

  if (showAll) {
    visibleMembers = currentMembers
  } else {
    visibleMembers = members.reduce((list, member) => {
      const isActiveMember = activeMembers.some((m) => isSameMember(m, member))
      const isForcedVisible =
        isNew(member) && lastChanged < member.just_added_timestamp
      if (isActiveMember || isForcedVisible) {
        return [...list, member]
      }
      return list
    }, [])
  }

  return {
    visible: visibleMembers,
    active: activeMembers,
    inactive: inactiveMembers,
    inactiveNew: inactiveNewMembers,
    all: currentMembers,
  }
}

export const useProjectMembersList = <
  M extends MemberWithArchiveStatus,
  A extends Assignment,
>(
  projectId: number,
  opts: Options<M, A>,
) => {
  const showAll = useAppSelector((state) =>
    selectIsAllMembersShown(state.plannerV2.projectMembersView, projectId),
  )

  const lastChanged = useAppSelector((state) =>
    selectProjectLastToggled(state.plannerV2.projectMembersView, projectId),
  )
  const { calStartNum, calEndNum } = useAppSelector(
    (state) => state.calendar,
    isDeeplyEqual,
  )
  return useMemo(() => {
    return getList({
      members: opts.members,
      assignments: opts.assignments,
      showAll,
      lastChanged,
      calStartNum,
      calEndNum,
    })
  }, [
    calEndNum,
    calStartNum,
    lastChanged,
    opts.assignments,
    opts.members,
    showAll,
  ])
}

export const useProjectMembershipsList = <
  M extends MembershipWithArchiveStatus,
  A extends Assignment,
>(
  personId: number,
  opts: Options<M, A>,
) => {
  const members = opts.members.filter((m) => !m.is_template)

  const showAll = useAppSelector((state) =>
    selectIsAllMembershipsShown(state.plannerV2.projectMembersView, personId),
  )

  const lastChanged = useAppSelector((state) =>
    selectPersonLastToggled(state.plannerV2.projectMembersView, personId),
  )
  const { calStartNum, calEndNum } = useAppSelector(
    (state) => state.calendar,
    isDeeplyEqual,
  )

  return useMemo(() => {
    return getList({
      members,
      assignments: opts.assignments,
      showAll,
      lastChanged,
      calStartNum,
      calEndNum,
    })
  }, [calEndNum, calStartNum, lastChanged, members, opts.assignments, showAll])
}
