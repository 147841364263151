/**
 * @generated SignedSource<<d88fc997625c197314c2b16c475a674f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaceholderPlannerMenu_account$data = {
  readonly id: number;
  readonly roles: ReadonlyArray<{
    readonly id: number;
    readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_roles">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderSidePanel_account" | "PlaceholderWorkflowMenu_account">;
  readonly " $fragmentType": "PlaceholderPlannerMenu_account";
};
export type PlaceholderPlannerMenu_account$key = {
  readonly " $data"?: PlaceholderPlannerMenu_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaceholderPlannerMenu_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderPlannerMenu_account",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderSidePanel_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaceholderWorkflowMenu_account"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "roles",
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChangeProjectRoleModal_roles"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "ab006d9742420b6497148e2b2b8a07af";

export default node;
