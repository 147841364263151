import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./SelectorSearch.module.css"

import { SearchIconWithReset } from "~/common/SearchIconWithReset"
import Checkbox from "~/common/inputs/Checkbox"

type Props = {
  placeholder?: string
  searchValue: string
  setSearchValue: (value: string) => void
  setCreateNewPerson?: (value: boolean) => void
  favouritesProps?: {
    favouritesOn: boolean
    setFavouritesOn: (value: boolean) => void
  }
  checkboxProps?: {
    id: string
    checked: boolean
    onChange: (e) => void
  }
  isPersonSearch?: boolean
}

const SelectorSearch = (props: Props) => {
  const {
    placeholder,
    searchValue,
    setSearchValue,
    setCreateNewPerson,
    favouritesProps,
    checkboxProps,
    isPersonSearch,
  } = props

  const placeholderText =
    placeholder ||
    (isPersonSearch ? "Search people, roles, teams, tags or skills" : "Search")

  const { favouritesOn, setFavouritesOn } = favouritesProps || {}

  const handleChange = (e) => {
    setSearchValue(e.target.value)
    if (setCreateNewPerson) {
      setCreateNewPerson(false)
    }
  }

  const clearSearch = () => {
    setSearchValue("")
  }

  return (
    <div
      data-component="SelectorSearch"
      className={styles.selectorSearchContainer}
    >
      <SearchIconWithReset hasQuery={!!searchValue} onReset={clearSearch} />
      <input
        className={styles.selectorSearchInput}
        name={"selector-search"}
        value={searchValue}
        onChange={handleChange}
        placeholder={placeholderText}
        autoFocus
        autoComplete="off"
        data-test="SelectorSearch_input"
      />

      <div className={styles.iconsContainer}>
        {favouritesProps && (
          <Icon
            icon={favouritesOn ? "star" : "star-empty"}
            onClick={() => setFavouritesOn(!favouritesOn)}
            className={cc([
              styles.favourites,
              {
                [styles.active]: favouritesOn,
              },
            ])}
          />
        )}
        {checkboxProps && (
          <Checkbox
            id={checkboxProps.id}
            onChange={(e) => checkboxProps.onChange(e)}
            checked={checkboxProps.checked}
          />
        )}
      </div>
    </div>
  )
}

export default SelectorSearch
