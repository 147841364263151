/**
 * @generated SignedSource<<baa32e87cebd60c4c430731f1e5e8b0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannerHeader_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageControls_account">;
  readonly " $fragmentType": "PlannerHeader_account";
};
export type PlannerHeader_account$key = {
  readonly " $data"?: PlannerHeader_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannerHeader_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlannerHeader_account",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsFilter",
          "variableName": "projectsFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PageControls_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "d61404ae8f3947a80a9cbb446d0625ef";

export default node;
