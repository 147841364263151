import React, { Suspense } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { EditProjectBudgetQuery } from "./__generated__/EditProjectBudgetQuery.graphql"

import Loading from "~/common/Loading"

import { FallBackErrorBoundary } from "~/ProjectPlanner/FallbackErrorBoundry"

import ProjectBudget from "./ProjectBudget"

type Props = {
  onClose: () => void
  projectId: number
}

const EditProjectQuery = graphql`
  query EditProjectBudgetQuery($projectId: Int!) {
    projects_by_pk(id: $projectId) {
      id
      name
      active
      pricing_model
      rate_type
      total_budget: total_budget_private
      expenses_budget
      emoji
      is_template
      project_rates {
        id
        project_id
        role_id
        rate: rate_private
      }
      rate_card {
        id
        name
        blended_rate: blended_rate_private
        blended_rate_card
        role_charge_out_rates {
          role_id
          rate_card_id
          charge_out_rate: charge_out_rate_private
        }
      }
      project_roles {
        id
        role_id
        estimated_minutes
      }
      members {
        id
        role_id
      }
      client {
        internal
        id
        image_key
        website
      }
      other_costs(order_by: { date: asc }) {
        id
        name
        date: date_runn
        cost
        charge
      }
    }
  }
`

const Loader = (props: Props) => {
  const { onClose, projectId } = props

  const query = useLazyLoadQuery<EditProjectBudgetQuery>(EditProjectQuery, {
    projectId: projectId,
  })

  const project = query.projects_by_pk

  return <ProjectBudget project={project} onClose={onClose} />
}

const EditProjectBudget = (props: Props) => {
  return (
    <FallBackErrorBoundary fallback={null}>
      <Suspense fallback={<Loading delayMs={1000} embed={true} />}>
        <Loader {...props} />
      </Suspense>
    </FallBackErrorBoundary>
  )
}

export default EditProjectBudget
