import { IconName } from "@blueprintjs/core"
import { match } from "ts-pattern"

import { PERSON_REQUEST_STATUSES as STATUSES } from "~/ENUMS"

import { isJustAdded } from "./project-member-helpers"

type People = ReadonlyArray<{
  id: number
  is_placeholder: boolean
  assignments: ReadonlyArray<{
    start_date: string
    end_date: string
    project_id: number
  }>
  project_memberships: ReadonlyArray<{
    just_added_timestamp?: number
    project_id: number
  }>
}>

type Projects = ReadonlyArray<{
  id: number
  is_template: boolean
}>

export const getVisiblePlaceholders = (
  people: People,
  projects: Projects,
  calStartNum: number,
  calEndNum: number,
) =>
  people
    .filter((p) => p.is_placeholder)
    .filter((placeholder) => {
      // Placeholders can exist in a temp state without a project.
      if (!placeholder.project_memberships[0]) {
        return false
      }

      const placeholderProject = projects.find(
        (p) => p.id === placeholder.project_memberships[0].project_id,
      )

      const visibleAssignments = placeholder.assignments
        .filter((a) => Number(a.end_date) >= calStartNum)
        .filter((a) => Number(a.start_date) <= calEndNum)

      const hasBeenJustAdded = placeholder.project_memberships.some((pm) =>
        isJustAdded(pm),
      )

      return (
        (!placeholderProject.is_template && visibleAssignments.length > 0) ||
        hasBeenJustAdded
      )
    })

export const getRequestStatusIcon = (status: STATUSES): IconName => {
  switch (status) {
    case STATUSES.PENDING:
      return "undo"
    case STATUSES.REQUESTED:
      return "redo"
    case STATUSES.NEED_TO_HIRE:
      return "flag"
    case STATUSES.CANCEL:
      return "cross"
  }
}

export const getRequestCommentPlaceholder = (requestType: STATUSES | null) => {
  if (!requestType) {
    return "e.g. Additional requirements"
  }

  return match(requestType)
    .with(STATUSES.CANCEL, () => "e.g. No longer required")
    .with(
      STATUSES.PENDING,
      () => "e.g. Changes that need to be made to the request",
    )
    .with(STATUSES.REQUESTED, () => "e.g. Preferred person")
    .with(STATUSES.NEED_TO_HIRE, () => "e.g. Additional requirements")
    .exhaustive()
}

export const getRequestCommentBtnText = ({
  requestType,
  hasExistingRequest,
}: {
  requestType: STATUSES
  hasExistingRequest: boolean
}) => {
  return match({ requestType, hasExistingRequest })
    .with({ requestType: STATUSES.CANCEL }, () => "Confirm Cancellation")
    .with({ requestType: STATUSES.PENDING }, () => "Respond to Request")
    .with({ hasExistingRequest: true }, () => "Update Request")
    .otherwise(() => "Confirm Request")
}

export const getResourceMenuItems = (roleName: string) => {
  return {
    PENDING: {
      text: `Respond to Request`,
      icon: "undo",
      status: STATUSES.PENDING,
    },
    REQUESTED: {
      text: `Request ${roleName}`,
      icon: "redo",
      status: STATUSES.REQUESTED,
    },
    NEED_TO_HIRE: {
      text: "Propose to Hire",
      icon: "flag",
      status: STATUSES.NEED_TO_HIRE,
    },
    CANCEL: {
      text: "Cancel Request",
      icon: "cross",
      status: STATUSES.CANCEL,
    },
  }
}
