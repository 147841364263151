import { graphql, useFragment } from "react-relay"
import { match } from "ts-pattern"

import { useManagers_account$key } from "./__generated__/useManagers_account.graphql"
import type { SuperSearch_user$data } from "~/common/SuperSearch/__generated__/SuperSearch_user.graphql"

import { Permissions } from "~/helpers/permissions"
import { formatName } from "~/helpers/person"

// @growing-pains-todo: Applying the filter here is self-referential insanity.
// Should be all_users, but need a new approach for UX.
const fragment = graphql`
  fragment useManagers_account on accounts {
    id
    users {
      id
      first_name
      last_name
      permissions
    }
  }
`

type Options = {
  user: SuperSearch_user$data
  type: "person" | "project"
}

const useManagers = (options: Options) => {
  const {
    user: { account },
  } = options
  const { users } = useFragment<useManagers_account$key>(fragment, account)

  const availableOptions = users
    .filter((user) => {
      const permissions = user.permissions as Permissions
      const managerPermissions = match(options.type)
        .with("project", () => permissions.manage_projects)
        .with("person", () => permissions.manage_people)
        .exhaustive()

      return managerPermissions === "restricted"
    })

    .map((user) => ({
      value: user.id,
      label: formatName(user.first_name, user.last_name),
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  return availableOptions
}

export default useManagers
