/**
 * @generated SignedSource<<273bddb5fce7ad65039e49a3c15bb2ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetectedPeopleSection_project$data = {
  readonly ext_project_links: ReadonlyArray<{
    readonly ext_project: {
      readonly ext_project_members: ReadonlyArray<{
        readonly ext_person: {
          readonly active: boolean;
          readonly email: string | null | undefined;
          readonly ext_person_links: ReadonlyArray<{
            readonly active: boolean;
            readonly ext_person_id: number;
            readonly person_id: number;
          }>;
          readonly first_name: string;
          readonly id: number;
          readonly is_placeholder: boolean;
          readonly last_name: string;
        };
        readonly id: number;
      }>;
      readonly id: number;
      readonly integration: {
        readonly id: number;
        readonly integration_service: {
          readonly id: number;
          readonly name: string;
        };
      };
    };
    readonly ext_project_id: number;
    readonly project_id: number;
  }>;
  readonly id: number;
  readonly members: ReadonlyArray<{
    readonly id: number;
    readonly person_id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPersonRows_project" | "ProjectDetailsDetectedPersonRow_project" | "ProjectGroupRow_project">;
  readonly " $fragmentType": "DetectedPeopleSection_project";
};
export type DetectedPeopleSection_project$key = {
  readonly " $data"?: DetectedPeopleSection_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetectedPeopleSection_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "active": {
        "_eq": true
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetectedPeopleSection_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "project_members",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectGroupRow_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsDetectedPersonRow_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetectedPersonRows_project"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "ext_project_link",
      "kind": "LinkedField",
      "name": "ext_project_links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ext_project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ext_project",
          "kind": "LinkedField",
          "name": "ext_project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "integration",
              "kind": "LinkedField",
              "name": "integration",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "integration_service",
                  "kind": "LinkedField",
                  "name": "integration_service",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "where",
                  "value": {
                    "deleted": {
                      "_eq": false
                    }
                  }
                }
              ],
              "concreteType": "ext_project_member",
              "kind": "LinkedField",
              "name": "ext_project_members",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ext_person",
                  "kind": "LinkedField",
                  "name": "ext_person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "first_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "is_placeholder",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": (v2/*: any*/),
                      "concreteType": "ext_person_link",
                      "kind": "LinkedField",
                      "name": "ext_person_links",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ext_person_id",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": "ext_person_links(where:{\"active\":{\"_eq\":true}})"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "ext_project_members(where:{\"deleted\":{\"_eq\":false}})"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ext_project_links(where:{\"active\":{\"_eq\":true}})"
    }
  ],
  "type": "projects",
  "abstractKey": null
};
})();

(node as any).hash = "1112173d00cd3b9eafcb3fd7a7990fd1";

export default node;
