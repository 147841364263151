/**
 * @generated SignedSource<<f550933a24f782ae0d9c872d18ed1cba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhaseItem_phase$data = {
  readonly color: string;
  readonly end_date: string | null | undefined;
  readonly id: number;
  readonly name: string;
  readonly project_id: number;
  readonly start_date: string | null | undefined;
  readonly " $fragmentType": "PhaseItem_phase";
};
export type PhaseItem_phase$key = {
  readonly " $data"?: PhaseItem_phase$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhaseItem_phase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhaseItem_phase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "start_date",
      "args": null,
      "kind": "ScalarField",
      "name": "start_date_runn",
      "storageKey": null
    },
    {
      "alias": "end_date",
      "args": null,
      "kind": "ScalarField",
      "name": "end_date_runn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "project_id",
      "storageKey": null
    }
  ],
  "type": "phases",
  "abstractKey": null
};

(node as any).hash = "cf462fb0f4d878b23a7cfb0fa9970071";

export default node;
