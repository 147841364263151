import flatten from "lodash-es/flatten"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./CreateBulkProjectsForm.module.css"

import { CreateBulkProjectsForm_viewer$key } from "./__generated__/CreateBulkProjectsForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"

import Input from "~/common/Input"
import { ModalBody, ModalFooter } from "~/common/ModalForm"
import AddButton from "~/common/buttons/AddButton"
import Button from "~/common/buttons/Button"
import { Delete } from "~/common/react-icons"

import { projectBulkCreateRelay } from "~/mutations/Project"

import { PRICING_MODELS } from "~/GLOBALVARS"
import OnboardingStep from "~/Onboarding/OnboardingStep"
import SelectClient from "~/forms/ProjectForm/SelectClient"
import SelectPricingModel from "~/forms/ProjectForm/SelectPricingModel"

type Props = {
  closeDialog: () => void
  onSuccess?: () => void
  activateAnimation: () => void
  saveText?: string
  isOnboarding?: boolean
  totalSteps?: number
}

type Project = {
  projectName: string
  client?: ClientOption
  pricingModel: string
  managerIds: number[]
}

type ClientOption = {
  label: string
  value: string | number
  imageKey: string
  website: string
  internal: boolean
  real_client: boolean
}

const fragment = graphql`
  fragment CreateBulkProjectsForm_viewer on users {
    id
    permissions
    account {
      name
      billing_method
      clients {
        id
        name
        image_key
        website
        internal
        real_client
      }
    }
  }
`

const CreateBulkProjectsForm = (props: Props) => {
  const {
    closeDialog,
    saveText = "Create",
    isOnboarding,
    activateAnimation,
    totalSteps,
  } = props

  const viewerQuery = useHasuraContext()
  const viewer = useFragment<CreateBulkProjectsForm_viewer$key>(
    fragment,
    viewerQuery,
  )
  const { account, permissions } = viewer

  const emptyProject: Project = {
    projectName: "",
    client: null,
    pricingModel: account.billing_method,
    managerIds: permissions.manage_projects === "restricted" ? [viewer.id] : [],
  }

  const [projects, setProjects] = useState([
    { ...emptyProject, autoFocus: false },
  ])

  const [isCreating, setIsCreating] = useState(false)

  const clients = isOnboarding
    ? account.clients.filter((c) => c.name !== account.name)
    : account.clients

  const existingClientOptions = flatten(
    clients.map((c) => [
      {
        value: c.id,
        label: c.name,
        imageKey: c.image_key,
        website: c.website,
        internal: c.internal,
        real_client: c.real_client,
      },
    ]),
  )

  const clientOptions = projects
    .filter((p) => !!p.client && !!p.client.real_client)
    .map((p) => p.client)
    .concat(existingClientOptions)

  const addItemToList = (autoFocus = false) => {
    setProjects([...projects, { autoFocus, ...emptyProject }])
  }

  const removeItemFromList = (idx) => {
    setProjects(projects.filter((p, projectIndex) => projectIndex !== idx))
  }

  const handleInputChange = (value, field, idx) => {
    const updatedProjects = [...projects]
    updatedProjects[idx][field] = value

    setProjects(updatedProjects)
  }

  const handleBlur = (e) => {
    if (
      e?.relatedTarget?.id !== "create-bulk-projects" &&
      e.target.value.length &&
      projects[projects.length - 1].projectName !== "" &&
      projects[projects.length - 1].client?.value
    ) {
      return addItemToList()
    }
  }

  const handleClientChange = (e, idx) => {
    const updatedProjects = [...projects]
    updatedProjects[idx].client = e

    if (e?.internal) {
      handleInputChange("nb", "pricingModel", idx)
    }

    setProjects(updatedProjects)

    if (
      e?.value &&
      projects[projects.length - 1].projectName !== "" &&
      projects[projects.length - 1].client?.value
    ) {
      addItemToList()
    }
  }

  const ProjectInputs = (idx) => (
    <div key={`project-${idx}`} className={styles.tableRow}>
      <Input
        name={`project-name-${idx}`}
        data-idx={idx}
        value={projects[idx].projectName}
        onChange={(e) => handleInputChange(e.target.value, "projectName", idx)}
        onBlur={handleBlur}
        autoFocus={idx === 0 || projects[idx].autoFocus}
      />
      <SelectClient
        name={`client-name-${idx}`}
        id={`clientName-${idx}`}
        value={projects[idx].client}
        onChange={(e) => handleClientChange(e, idx)}
        options={clientOptions}
      />
      <SelectPricingModel
        id={`pricing-model-${idx}`}
        name={`project-pricing-models-${idx}`}
        onChange={(opt) => handleInputChange(opt.value, "pricingModel", idx)}
        className={styles.select}
        value={PRICING_MODELS.find(
          (pm) => pm.value === projects[idx].pricingModel,
        )}
        isDisabled={projects[idx].client?.internal}
      />
      <div
        onClick={projects.length > 1 ? () => removeItemFromList(idx) : null}
        className={`${styles.delete} ${
          projects.length === 1 && styles.disabled
        }`}
      >
        <Delete color="var(--winter)" />
      </div>
    </div>
  )

  const handleClickCreate = async () => {
    setIsCreating(true)
    activateAnimation()

    const newProjects = projects
      .filter((p) => p.projectName && p.client.label)
      .map((p) => ({
        name: p.projectName.trim(),
        client_name: p.client.label.trim(),
        pricing_model: p.pricingModel,
        manager_ids: p.managerIds,
      }))

    const onSuccess = () => {
      setIsCreating(false)
      props.onSuccess ? props.onSuccess() : closeDialog()
    }

    try {
      await projectBulkCreateRelay({
        variables: {
          input: { projects: newProjects },
        },
      })

      // Track event for each project to stay consistent with single create/update
      newProjects.forEach((_) => {
        track("Project Created", {
          is_onboarding: isOnboarding,
        })
      })

      window.userflow?.track("Project Create")

      onSuccess()
    } catch {
      setIsCreating(false)
    }
  }

  const fullyCompletedRows = projects.filter(
    (p) => p.projectName.trim() && p.client && p.client.label,
  ).length
  const hasHalfCompletedRows =
    fullyCompletedRows !==
    projects.filter((p) => p.projectName.trim() || (p.client && p.client.label))
      .length

  return (
    <>
      <ModalBody wide>
        <div className={styles.wrapper}>
          <div className={`${styles.tableRow} ${styles.tableHeaderRow}`}>
            <div>Project Name</div>
            <div>Client Name</div>
            <div>Pricing Model</div>
            <div>{/* leave empty for delete */}</div>
          </div>
          {projects.map((val, idx) => ProjectInputs(idx))}
        </div>
        <AddButton text="New Project" onClick={() => addItemToList(true)} />
      </ModalBody>
      <ModalFooter>
        <div>
          {isOnboarding ? (
            <OnboardingStep step={1} totalSteps={totalSteps} />
          ) : (
            <Button text="Cancel" onClick={() => closeDialog()} />
          )}
          <Button
            id="create-bulk-projects"
            outlined={false}
            text={saveText}
            loading={isCreating}
            onClick={handleClickCreate}
            disabled={
              isCreating || fullyCompletedRows < 1 || hasHalfCompletedRows
            }
          />
        </div>
      </ModalFooter>
    </>
  )
}

export default CreateBulkProjectsForm
