import { graphql } from "react-relay"

import type { useDeletePersonMutation as Mutation } from "./__generated__/useDeletePersonMutation.graphql"

import * as relayids from "~/helpers/relayids"
import { removeLinkedRecord, useMutationPromise } from "~/mutations/helpers"

import usePlannerFilters from "~/Planner/usePlannerFilters"

type DeletePersonOptions = {
  personId: number
}

const useDeletePersonMutation = () => {
  const [commit, isInFlight] = useMutationPromise<Mutation>(graphql`
    mutation useDeletePersonMutation($input: PersonDeleteInput!) {
      action_person_delete(input: $input) {
        account_id
        affected_projects {
          project {
            id
            assignments {
              id
            }
            members {
              id
            }
          }
        }
      }
    }
  `)

  const { peopleFilter } = usePlannerFilters()

  const deletePerson = async (options: DeletePersonOptions) => {
    const { personId } = options

    await commit({
      variables: {
        input: {
          person_id: personId,
        },
      },
      updater: (store, data) => {
        if (!data) {
          return
        }
        const { account_id: accountId } = data.action_person_delete
        const $account = store.get(relayids.accounts.encode(accountId))
        const $person = store.get(relayids.people.encode(personId))
        if ($account && $person) {
          removeLinkedRecord($account, "people", $person, {
            where: peopleFilter,
          })
        }
      },
    })
  }

  return [deletePerson, isInFlight] as const
}

export { useDeletePersonMutation }
