import { Icon, Menu, MenuItem } from "@blueprintjs/core"
import React, { useState } from "react"
import { graphql } from "react-relay"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { ProjectTemplatePlannerMenuDeleteMutation } from "./__generated__/ProjectTemplatePlannerMenuDeleteMutation.graphql"
import { CreateProjectFromTemplateForm_project$key } from "~/forms/CreateProject/__generated__/CreateProjectFromTemplateForm_project.graphql"
import { EditProjectForm_project$key } from "~/forms/EditProjectForm/__generated__/EditProjectForm_project.graphql"

import { track } from "~/helpers/analytics"
import * as hashids from "~/helpers/hashids"
import { viewTemplateUrl } from "~/helpers/routes"
import { useMutationPromise } from "~/mutations/helpers"

import Dialog from "~/common/Dialog"
import { ModalFormWrapper } from "~/common/ModalForm"
import { DashboardIcon } from "~/common/react-icons"
import { TangramRocket } from "~/common/tangrams"

import "../../mutations/Project"

import DeleteModal from "~/Modals/Delete/DeleteModal"
import { usePermissions } from "~/Permissions/usePermissions"
import EditProjectBudget from "~/ProjectDashboard/Header/ProjectBudget/EditProjectBudget"
import CreateProjectFormPlanner from "~/forms/CreateProject/CreateProjectPlanner"
import EditProjectForm from "~/forms/EditProjectForm/EditProjectForm"

import { ProjectTemplateMenuType } from "./ContextMenu"

type Props = {
  project: {
    id: number
    confirmed: boolean
    name: string
    archivable: boolean
    calc_start_date: string
    has_actuals: boolean
    active: boolean
  }
  editTemplateQuery: EditProjectForm_project$key
  createProjectFromTemplateQuery: CreateProjectFromTemplateForm_project$key
  closeMenu: () => void
  availableMenuItems: ProjectTemplateMenuType
  setDialogIsOpen: (val: boolean) => void
}

const DIALOG_TYPE = {
  CREATE_PROJECT: "CREATE_PROJECT",
  EDIT: "EDIT",
  EDIT_BUDGET: "EDIT_BUDGET",
  DELETE: "DELETE",
}

const MENU_ITEM_TYPE = {
  ...DIALOG_TYPE,
  OPEN_DASHBOARD: "OPEN_DASHBOARD",
}

const MENU_ITEM_NAME = {
  [MENU_ITEM_TYPE.CREATE_PROJECT]: "New Project from Template",
  [MENU_ITEM_TYPE.OPEN_DASHBOARD]: "Open Dashboard",
  [MENU_ITEM_TYPE.EDIT]: "Edit Details",
  [MENU_ITEM_TYPE.EDIT_BUDGET]: "Edit Budget",
  [MENU_ITEM_TYPE.DELETE]: "Delete",
}

const ProjectTemplatePlannerMenu = (props: Props) => {
  const {
    project,
    editTemplateQuery,
    createProjectFromTemplateQuery,
    closeMenu,
    availableMenuItems,
    setDialogIsOpen,
  } = props
  const [showDialog, setShowDialog] = useState(false)
  const [dialogType, setDialogType] = useState<keyof typeof DIALOG_TYPE>(null)

  const [deleteTemplateMutation] =
    useMutationPromise<ProjectTemplatePlannerMenuDeleteMutation>(graphql`
      mutation ProjectTemplatePlannerMenuDeleteMutation($id: Int!) {
        deleteTemplate(templateId: $id) {
          account {
            id
            projects {
              id
            }
            projectTemplates: projects(where: { is_template: { _eq: true } }) {
              id
            }
          }
        }
      }
    `)
  const {
    canCreateNewProjectFromTemplate,
    canViewDashboard,
    canEditDetails,
    canEditBudget,
    canDelete,
  } = availableMenuItems
  const { isAdmin } = usePermissions()

  const openDialog = (type) => {
    setShowDialog(true)
    setDialogIsOpen(true)
    setDialogType(type)
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false)
    setShowDialog(false)
    closeMenu()
  }

  const handleDelete = () => {
    void deleteTemplateMutation({
      variables: {
        id: project.id,
      },
    })
  }

  const renderDialog = () => {
    switch (dialogType) {
      case "CREATE_PROJECT":
        return (
          <CreateProjectFormPlanner
            createProjectFromTemplateQuery={createProjectFromTemplateQuery}
            closeDialog={handleCloseDialog}
            projectType="project"
          />
        )
      case "EDIT":
        return (
          <ModalFormWrapper
            headerTitle={`Edit ${project.name}`}
            wide
            tangram={<TangramRocket />}
            tangramStyles={{ marginBottom: 15 }}
          >
            <EditProjectForm
              projectQuery={editTemplateQuery}
              onClose={handleCloseDialog}
            />
          </ModalFormWrapper>
        )
      case "EDIT_BUDGET":
        return (
          <EditProjectBudget
            projectId={project.id}
            onClose={handleCloseDialog}
          />
        )
      case "DELETE":
        return (
          <DeleteModal
            type="project template"
            name={project.name}
            isAdmin={isAdmin}
            deletable
            closeDialog={handleCloseDialog}
            onSubmit={handleDelete}
          />
        )
      default:
        break
    }
  }

  const handleMenuItemClick = (menuType: keyof typeof MENU_ITEM_TYPE, e?) => {
    track("Project Context Menu Item Selected", {
      item: MENU_ITEM_NAME[menuType],
    })

    if (menuType === "OPEN_DASHBOARD") {
      return true // Return true to execute the href
    }

    openDialog(menuType)
  }

  return (
    <>
      <Menu className={dropdownStyles.dropdown} style={{ padding: 0 }}>
        {canCreateNewProjectFromTemplate && (
          <MenuItem
            text={MENU_ITEM_NAME.CREATE_PROJECT}
            onClick={() => handleMenuItemClick("CREATE_PROJECT")}
            shouldDismissPopover={false}
            icon={<Icon icon="duplicate" />}
          />
        )}
        {canViewDashboard && (
          <MenuItem
            text={MENU_ITEM_NAME.OPEN_DASHBOARD}
            icon={<DashboardIcon />}
            onClick={() => handleMenuItemClick("OPEN_DASHBOARD")}
            // Use href so users have the ability to Right Click -> Open Link in new Tab
            href={viewTemplateUrl(hashids.projects.encode(project.id))}
          />
        )}
        {canEditDetails && (
          <MenuItem
            text={MENU_ITEM_NAME.EDIT}
            onClick={() => handleMenuItemClick("EDIT")}
            shouldDismissPopover={false}
            icon={<Icon icon="cog" />}
          />
        )}
        {canEditBudget && (
          <MenuItem
            text={MENU_ITEM_NAME.EDIT_BUDGET}
            onClick={() => handleMenuItemClick("EDIT_BUDGET")}
            shouldDismissPopover={false}
            icon={<Icon icon="dollar" />}
          />
        )}
        {canDelete && (
          <MenuItem
            text={MENU_ITEM_NAME.DELETE}
            onClick={() => handleMenuItemClick("DELETE")}
            shouldDismissPopover={false}
            icon={<Icon icon="trash" />}
          />
        )}
      </Menu>
      <Dialog isOpen={showDialog} onClose={handleCloseDialog}>
        {renderDialog()}
      </Dialog>
    </>
  )
}

export default ProjectTemplatePlannerMenu
