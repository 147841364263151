import orderBy from "lodash-es/orderBy"
import React, { useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./PlaceholderComments.module.css"

import { PlaceholderComments_placeholder$key } from "./__generated__/PlaceholderComments_placeholder.graphql"

import { track } from "~/helpers/analytics"

import Button from "~/common/buttons/Button"
import * as icons from "~/common/react-icons"

import { personNoteCreateRelay } from "~/mutations/PersonNote"

import { usePermissions } from "~/Permissions/usePermissions"
import PersonNote from "~/PersonDashboard/Body/PersonNotes/PersonNote"
import { useEffectSkipFirstRender } from "~/hooks/useEffectSkipFirstRender"

type Props = {
  placeholder: PlaceholderComments_placeholder$key
}

const PlaceholderCommentBox = ({
  placeholderId,
}: {
  placeholderId: number
}) => {
  const [comment, setComment] = useState("")

  const createComment = async () => {
    const trimmedComment = comment.trim()

    if (!trimmedComment) {
      return
    }

    await personNoteCreateRelay({
      note: trimmedComment,
      personId: placeholderId,
    })
    track("Placeholder Comment Created")
    setComment("")
  }

  const handleShortcut = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      await createComment()
    }
  }

  return (
    <div className={styles.commentBox}>
      <textarea
        className={styles.commentTextArea}
        placeholder="Add notes, important links, etc."
        data-test="comment-input"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onSubmit={createComment}
        onKeyDown={handleShortcut}
      />
      <Button
        className={styles.sendBtn}
        onClick={createComment}
        outlined={false}
        icon={<icons.Send />}
      />
    </div>
  )
}

const PlaceholderComments = (props: Props) => {
  const placeholder = useFragment(
    graphql`
      fragment PlaceholderComments_placeholder on people {
        id
        email
        is_placeholder
        people_notes {
          id
          created_at
          ...PersonNote_note
        }
      }
    `,
    props.placeholder,
  )

  const sortedNotes = orderBy(
    placeholder.people_notes,
    (note) => note.created_at,
    "asc",
  )

  const containerRef = useRef<HTMLDivElement>(null)

  useEffectSkipFirstRender(() => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [placeholder.people_notes.length])

  const { can, subject } = usePermissions()
  const canCreateComment = can(
    "create",
    subject("PersonNote", {
      // new note, type requires a user
      user: { id: null },
      person: placeholder,
    }),
  )

  return (
    <section>
      <div className={styles.container} ref={containerRef}>
        {sortedNotes.map((note) => (
          <PersonNote
            key={note.id}
            note={note}
            readonly
            style={{
              container: {
                borderBottom: "none",
                paddingBottom: "24px",
                paddingTop: 0,
              },
              createdBy: {
                marginTop: "4px",
                fontSize: "12px",
              },
              note: {
                margin: 0,
                wordBreak: "break-word",
              },
            }}
            timeShown
          />
        ))}
      </div>
      {canCreateComment && (
        <PlaceholderCommentBox placeholderId={placeholder.id} />
      )}
    </section>
  )
}

export default PlaceholderComments
