import { Divider } from "@blueprintjs/core"
import { dateHelpers } from "@runn/calculations"
import { format as formatDate } from "date-fns"
import React from "react"

import styles from "./CalendarMilestone.module.css"

import IconPlus from "~/common/IconPlus"

import { Milestone } from "~/ProjectPlanner/ProjectOverview/CalendarMilestone"
import { MilestoneIcon } from "~/milestones"

type Props = {
  milestones: Milestone[]
  iconClasses: string
  canEdit: boolean
  handleEdit: (milestone: Milestone) => void
  handleAdd: () => void
}

const MilestoneDetailsForm = (props: Props) => {
  const { milestones, canEdit, iconClasses, handleEdit, handleAdd } = props

  return (
    <div className={styles.calendarMilestone}>
      <div className={styles.date}>
        {formatDate(
          dateHelpers.parseRunnDate(milestones[0].date),
          "d MMMM yyyy",
        )}
      </div>
      <Divider />
      <div className={styles.details}>
        {milestones.map((m) => (
          <div key={m.id}>
            <div className={styles.itemContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <MilestoneIcon
                    iconOrEmoji={m.icon}
                    size={25}
                    className={iconClasses}
                  />
                  <p>{m.title}</p>
                </div>
                {canEdit && (
                  <div
                    className={styles.editButton}
                    onClick={() => handleEdit(m)}
                  >
                    Edit
                  </div>
                )}
              </div>
              <div className={styles.note}>{m.note}</div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
      {canEdit && (
        <div className={styles.footer} onClick={handleAdd}>
          <IconPlus color="var(--slate)" /> <p>Add Milestone</p>
        </div>
      )}
    </div>
  )
}

export default MilestoneDetailsForm
