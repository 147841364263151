/**
 * @generated SignedSource<<c35f96040416abe9b0116d33bd68f535>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuperSearchConfig_user$data = {
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"PersonCustomFieldTypes_accounts" | "ProjectCustomFieldTypes_accounts">;
  };
  readonly people: ReadonlyArray<{
    readonly active: boolean;
    readonly email: string | null | undefined;
    readonly first_name: string;
    readonly id: number;
    readonly image_key: string | null | undefined;
    readonly last_name: string;
  }>;
  readonly " $fragmentType": "SuperSearchConfig_user";
};
export type SuperSearchConfig_user$key = {
  readonly " $data"?: SuperSearchConfig_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuperSearchConfig_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuperSearchConfig_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        }
      ],
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "people",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image_key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        }
      ],
      "storageKey": "people(limit:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectCustomFieldTypes_accounts"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonCustomFieldTypes_accounts"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "48708c4b12ab0d5d28b93024ba0e8602";

export default node;
