import { graphql, useFragment } from "react-relay"

import type {
  ExtLinks_Person$data,
  ExtLinks_Person$key,
} from "./__generated__/ExtLinks_Person.graphql"
import type {
  ExtLinks_Project$data,
  ExtLinks_Project$key,
} from "./__generated__/ExtLinks_Project.graphql"

type GenericExtLink = {
  active: boolean
  external_id: number
  external: {
    __typename: "ext_time_off" | "ext_person" | "ext_project"
    id: number
    integration_id: number
  }
}

// TimeOff -> ExtTimeOffLink → ExtTimeOff
graphql`
  fragment ExtLinks_TimeOff on time_offs {
    ext_time_off_links(where: { active: { _eq: true } }) {
      time_off_id
      ext_time_off_id
      active

      external_id: ext_time_off_id
      external: ext_time_off {
        __typename
        id
        integration_id
      }
    }
  }
`

// Person -> ExtPersonLink → ExtPerson
const extPersonLinksFragment = graphql`
  fragment ExtLinks_Person on people {
    ext_person_links(where: { active: { _eq: true } }) {
      person_id
      ext_person_id
      active

      external_id: ext_person_id
      external: ext_person {
        id
        __typename

        first_name
        last_name
        email
        remote_id
        integration_id
      }
    }
  }
`

// Project -> ExtProjectLink → ExtProject
const extProjectLinksFragment = graphql`
  fragment ExtLinks_Project on projects {
    ext_project_links(where: { active: { _eq: true } }) {
      project_id
      ext_project_id
      active

      external_id: ext_project_id
      external: ext_project {
        id
        __typename

        name
        remote_url
        remote_id
        integration_id
      }
    }
  }
`

type PersonRef = ExtLinks_Person$key
type ExtPersonLink = ExtLinks_Person$data["ext_person_links"][number]

const useExtPersonLinks = (
  personRef: PersonRef,
): ReadonlyArray<ExtPersonLink> => {
  const data = useFragment(extPersonLinksFragment, personRef)
  return data?.ext_person_links ?? []
}

type ProjectRef = ExtLinks_Project$key
type ExtProjectLink = ExtLinks_Project$data["ext_project_links"][number]

const useExtProjectLinks = (
  projectRef: ProjectRef,
): ReadonlyArray<ExtProjectLink> => {
  const data = useFragment(extProjectLinksFragment, projectRef)
  return data?.ext_project_links ?? []
}

export { useExtPersonLinks, useExtProjectLinks }
export type {
  PersonRef,
  ProjectRef,
  GenericExtLink,
  ExtPersonLink,
  ExtProjectLink,
}
