/**
 * @generated SignedSource<<ece093379fd79fc74ed15eca3f937f3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGroupRow_project$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupMembersDisplay_project">;
  readonly " $fragmentType": "ProjectGroupRow_project";
};
export type ProjectGroupRow_project$key = {
  readonly " $data"?: ProjectGroupRow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGroupRow_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGroupRow_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectGroupMembersDisplay_project"
    }
  ],
  "type": "projects",
  "abstractKey": null
};

(node as any).hash = "6fbd9bb1e1006f5f3392e24fa3490ffc";

export default node;
