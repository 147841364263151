import { AnchorButton, Tooltip } from "@blueprintjs/core"
import * as fe from "@runn/filter-engine"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./ViewFormDialog.module.css"

import { ViewFormDialog_user$key } from "./__generated__/ViewFormDialog_user.graphql"
import { ViewsList_user$data } from "./__generated__/ViewsList_user.graphql"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"
import {
  AllowedPeopleFilterSet,
  AllowedProjectFilterSet,
} from "~/helpers/filter-engine"

import Dialog from "~/common/Dialog"
import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import "~/common/SuperSearch"
import { hasInvalidFilters } from "~/common/SuperSearch/FilterSaveButton"
import { emptyFilterSet } from "~/common/SuperSearch/SuperSearch"
import UniqueInput from "~/common/UniqueInput"
import Button from "~/common/buttons/Button"
import { InfoCircle } from "~/common/react-icons"

import { userViewUpdateRelay } from "~/mutations/User"
import { viewCreateRelay, viewUpdateRelay } from "~/mutations/View"

import { showAllViewDeselected } from "~/Planner/reducer2/viewsSlice"
import { showToast } from "~/containers/ToasterContainer"

import ViewFilterValues from "./ViewFilterValues"
import ViewFormPeopleFilter from "./ViewFormPeopleFilter"
import ViewFormProjectsFilter from "./ViewFormProjectsFilter"

type Props = {
  user: ViewFormDialog_user$key
  readonly: boolean
  onClose: () => void
  view?: ViewsList_user$data["account"]["views"][0]
  viewInPlanner?: () => void
}

const fragment = graphql`
  fragment ViewFormDialog_user on users
  @argumentDefinitions(
    projectsFilter: { type: "projects_bool_exp" }
    peopleFilter: { type: "people_bool_exp" }
  ) {
    id
    account {
      id
      views {
        id
        name
      }
    }
    ...ViewFilterValues_user
      @arguments(peopleFilter: $peopleFilter, projectsFilter: $projectsFilter)
  }
`

const getUniqueFilterTypes = (filterSet) => {
  return [...new Set(fe.filters.asTypeList(filterSet))]
}

/** Database constraint for view name length */
const MAX_NAME_LENGTH = 40

const ViewFormDialog = (props: Props) => {
  const user = useFragment<ViewFormDialog_user$key>(fragment, props.user)
  const {
    account: { views },
  } = user

  const { view: viewToEdit, onClose, readonly, viewInPlanner } = props

  const dispatch = useDispatch()

  const [isSaving, setIsSaving] = useState(false)
  const [viewName, setViewName] = useState(viewToEdit?.name || "")
  const [description, setDescription] = useState(viewToEdit?.description || "")
  const [projectFilterSet, setProjectFilterSet] =
    useState<AllowedProjectFilterSet>(
      viewToEdit
        ? { name: "", filters: viewToEdit.project_filters }
        : emptyFilterSet,
    )
  const [peopleFilterSet, setPeopleFilterSet] =
    useState<AllowedPeopleFilterSet>(
      viewToEdit
        ? { name: "", filters: viewToEdit.people_filters }
        : emptyFilterSet,
    )

  const closeDialog = () => {
    setIsSaving(false)
    onClose()
  }

  const handleSwitchToView = (viewId) => {
    try {
      void userViewUpdateRelay({
        input: { viewId: viewId },
      })
      dispatch(showAllViewDeselected(viewId))
      track("Planner View Changed - Custom View")

      if (viewInPlanner) {
        viewInPlanner()
      }
    } catch (error) {
      void reportError("Update user default view error: ", error)
    }
  }

  const handleSubmit = async () => {
    setIsSaving(true)
    const data = {
      name: viewName.trim(),
      description,
      project_filters: projectFilterSet.filters,
      people_filters: peopleFilterSet.filters,
    }

    if (viewToEdit) {
      try {
        const editedViewName = await viewUpdateRelay({
          id: viewToEdit.id,
          name: data.name,
          description: data.description,
          projectFilters: data.project_filters,
          peopleFilters: data.people_filters,
        })
        showToast({
          message: "Updated View",
          type: "success",
          description: editedViewName,
        })
        track("View Edited", {
          project_filters: getUniqueFilterTypes(data.project_filters),
          people_filters: getUniqueFilterTypes(data.people_filters),
        })
      } catch (error) {
        void reportError(`editing view`, error)
      } finally {
        closeDialog()
      }
    } else {
      try {
        const createdView = await viewCreateRelay({
          view: {
            name: data.name,
            description: data.description,
            projectFilters: data.project_filters,
            peopleFilters: data.people_filters,
          },
        })
        showToast({
          message: "New View Created",
          type: "success",
          description: createdView.name,
          actions: [{ onClick: () => handleSwitchToView(createdView.id) }],
        })
        track("View Created", {
          name: createdView.name,
          project_filters: getUniqueFilterTypes(data.project_filters),
          people_filters: getUniqueFilterTypes(data.people_filters),
        })
      } catch (error) {
        void reportError(`creating view`, error)
      } finally {
        closeDialog()
      }
    }
  }

  const viewWithDuplicateName = views.find((view) => {
    return view.name.trim() === viewName.trim() && view.id !== viewToEdit?.id
  })
  const isViewNameAll = viewName.trim().toLowerCase() === "all"

  const onChangeName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setViewName(e.currentTarget.value)
    },
    [],
  )

  const missingValues = [
    (isViewNameAll || viewWithDuplicateName || !viewName) && "Name",
    !projectFilterSet.filters && "Project filters",
    !peopleFilterSet.filters && "People filters",
  ].filter(Boolean)

  const invalidFilters =
    hasInvalidFilters(projectFilterSet) || hasInvalidFilters(peopleFilterSet)
  const hasErrors = Boolean(missingValues.length) || invalidFilters
  const displayWarning =
    !projectFilterSet.filters || !peopleFilterSet.filters || invalidFilters

  const headerTitle = viewToEdit
    ? `${!readonly ? "Edit " : ""}${viewToEdit.name}`
    : "New View"

  return (
    <Dialog isOpen onClose={closeDialog}>
      <ModalFormWrapper headerTitle={headerTitle} wide>
        <ModalBody wide className={styles.wrapper}>
          <div className={styles.inputs}>
            <UniqueInput
              name="view-name"
              id="view-name"
              label="Name"
              duplicate={(() => {
                if (viewWithDuplicateName) {
                  return { name: viewWithDuplicateName.name }
                }
                if (isViewNameAll) {
                  return { name: "All" }
                }
                return null
              })()}
              placeholder="e.g. IT Department"
              value={viewName}
              onChange={onChangeName}
              autoComplete="off"
              autoFocus
              maxLength={MAX_NAME_LENGTH}
              disabled={readonly}
            />
            {viewName.length >= MAX_NAME_LENGTH ? (
              <div className={styles.maxLimit}>
                Maximum {MAX_NAME_LENGTH} characters
              </div>
            ) : null}
            <Input
              label="Description"
              name="view-description"
              type="text"
              value={description}
              placeholder={readonly ? "" : "e.g. IT projects and people"}
              onChange={(e) => setDescription(e.target.value)}
              autoComplete="off"
              optional
              disabled={readonly}
            />
          </div>
          <section>
            <div className={styles.header}>Projects</div>
            {readonly ? (
              <ViewFilterValues filterSet={projectFilterSet} user={user} />
            ) : (
              <ViewFormProjectsFilter
                filterSet={projectFilterSet}
                setProjectFilterSet={setProjectFilterSet}
              />
            )}
          </section>
          <section>
            <div className={styles.header}>People</div>
            {readonly ? (
              <ViewFilterValues filterSet={peopleFilterSet} user={user} />
            ) : (
              <ViewFormPeopleFilter
                filterSet={peopleFilterSet}
                setPeopleFilterSet={setPeopleFilterSet}
              />
            )}
          </section>
        </ModalBody>
        <ModalFooter>
          {displayWarning && (
            <div className={styles.infoContainer}>
              <InfoCircle />
              <p>At least one project and person filter is required</p>
            </div>
          )}

          <div className={styles.buttonsContainer}>
            <Button
              text={readonly ? "Close" : "Cancel"}
              onClick={closeDialog}
            />
            {!readonly && (
              <Tooltip
                disabled={!hasErrors}
                content={
                  <>
                    {Boolean(missingValues.length) && (
                      <div>{missingValues.join(", ")} required</div>
                    )}
                    {invalidFilters && (
                      <div>Please remove any invalid filters</div>
                    )}
                  </>
                }
                position="top"
                hoverOpenDelay={500}
              >
                <AnchorButton
                  text={viewToEdit ? "Save" : "Create"}
                  intent="primary"
                  loading={isSaving}
                  onClick={handleSubmit}
                  outlined={false}
                  disabled={isSaving || hasErrors}
                />
              </Tooltip>
            )}
          </div>
        </ModalFooter>
      </ModalFormWrapper>
    </Dialog>
  )
}

export default ViewFormDialog
