import { Icon, IconProps } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./IconRotatingChevron.module.css"

type Props = { direction: "left" | "right" | "down" | "up" } & Omit<
  IconProps,
  "icon"
>

/**
 * An animated icon the transitions
 */
const IconRotatingChevron = ({ direction, ...rest }: Props) => {
  return (
    <Icon
      {...rest}
      className={cc([
        styles.icon,
        {
          [styles.left]: direction === "left",
          [styles.right]: direction === "right",
          [styles.up]: direction === "up",
          [styles.down]: direction === "down",
        },
        rest.className,
      ])}
      icon="chevron-right"
    />
  )
}

export { IconRotatingChevron }
