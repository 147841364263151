import React from "react"

import styles from "./PhaseName.module.css"

import { findColor } from "~/helpers/colors"

type Props = {
  pillLength: number
  phase: {
    name: string
    color?: string
  }
}

const PhaseName = (props: Props) => {
  const { pillLength, phase } = props

  const phaseColor = phase.color
    ? findColor(phase.color).pillLabel
    : "rgba(60, 66, 96, 0.4)"

  if (pillLength <= 25) {
    return null
  }
  return (
    <div className={styles.phaseName} style={{ backgroundColor: phaseColor }}>
      <span>{phase.name}</span>
    </div>
  )
}

export default PhaseName
