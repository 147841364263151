/**
 * @generated SignedSource<<d53355ad41dda07320b347f5b5b67ca8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonPlannerMenu_user$data = {
  readonly account: {
    readonly id: number;
    readonly roles: ReadonlyArray<{
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_roles">;
    }>;
  };
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonForm_user" | "PersonSidePanel_user">;
  readonly " $fragmentType": "PersonPlannerMenu_user";
};
export type PersonPlannerMenu_user$key = {
  readonly " $data"?: PersonPlannerMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonPlannerMenu_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonPlannerMenu_user",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonSidePanel_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditPersonForm_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "roles",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ChangeProjectRoleModal_roles"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "1b8dc702c43048dcbbee0b8c6d8d674c";

export default node;
