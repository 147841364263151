import { Switch, Tooltip } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import styles from "./ViewControls.module.css"

import { useQueryLimit } from "~/Planner/LimitedQuery/useQueryLimit"
import { selectGroupPeopleBy } from "~/Planner/reducer2/peopleSortSlice"
import { setShowGroupUtilization } from "~/Planner/reducer2/peopleSummarySlice"
import { useAppSelector } from "~/hooks/redux"
import { setSetting } from "~/localsettings"

const GroupUtilizationToggle = () => {
  const dispatch = useDispatch()
  const {
    people: { isOverflow },
  } = useQueryLimit()
  const showGroupUtilization = useAppSelector(
    (state) => state.plannerV2.peopleSummary.showGroupUtilization,
  )

  const toggleGroupUtilization = () => {
    dispatch(setShowGroupUtilization(!showGroupUtilization))
    setSetting("peopleUtilizationGroup", !showGroupUtilization)
  }

  const peopleGroupBy = useAppSelector((state) =>
    selectGroupPeopleBy(state.plannerV2.people),
  )

  const disabled = peopleGroupBy === "default" || isOverflow

  const toggleChecked = showGroupUtilization && !disabled

  return (
    <div className={styles.toggle}>
      <Tooltip
        disabled={!disabled}
        placement="top"
        content="Not available when grouping by All. Use charts instead."
      >
        <Switch
          style={disabled ? { color: "var(--winter)" } : {}}
          disabled={disabled}
          checked={toggleChecked}
          onChange={toggleGroupUtilization}
          label="Group Utilization"
          alignIndicator="left"
        />
      </Tooltip>
    </div>
  )
}

export default GroupUtilizationToggle
